import { useFlags } from "launchdarkly-react-client-sdk";
import { useUser } from "src/contexts/user-context";
import {
  useSubsetAccessForModelQuery,
  useSubsetAccessForSyncQuery,
} from "src/graphql";
import { ResourceToPermission } from "./types";

interface Options {
  suspense: boolean;
}

export const useSubsetPermissions = <Resource extends ResourceToPermission>(
  resource: Resource,
  id?: number | string | null,
  options?: Options,
): { isLoading: boolean; permission: boolean } => {
  const { user } = useUser();
  const { appSubsetsEnabled } = useFlags();

  if (user?.is_impersonating || user?.permissions_v2_enabled) {
    return {
      permission: true,
      isLoading: false,
    };
  }

  const { data: subsetSyncGranted, isLoading: syncLoading } =
    useSubsetAccessForSyncQuery(
      {
        syncId: String(id),
      },
      {
        select: (data) =>
          !data?.syncs?.[0]?.segment?.subsets?.length ||
          data.syncs[0].segment.subsets.every((s) =>
            s.subset_value.access_grants.some((g) => g.user_id === user?.id),
          ),
        enabled:
          appSubsetsEnabled &&
          resource === ResourceToPermission.Sync &&
          Boolean(user?.id && id),
        suspense: options?.suspense,
      },
    );

  const { data: subsetAudienceGranted, isLoading: audienceLoading } =
    useSubsetAccessForModelQuery(
      {
        segmentId: String(id),
      },
      {
        select: (data) => {
          return (
            !data?.segments_by_pk?.subsets?.length ||
            data?.segments_by_pk?.subsets.every((s) =>
              s.subset_value.access_grants.some((g) => g.user_id === user?.id),
            )
          );
        },
        enabled:
          appSubsetsEnabled &&
          resource === ResourceToPermission.Audience &&
          Boolean(user?.id && id),
        suspense: options?.suspense,
      },
    );

  if (!appSubsetsEnabled) {
    return {
      isLoading: false,
      permission: true,
    };
  }

  if (
    resource !== ResourceToPermission.Audience &&
    resource !== ResourceToPermission.Sync
  ) {
    return {
      isLoading: false,
      permission: true,
    };
  }

  return resource === ResourceToPermission.Audience
    ? { isLoading: audienceLoading, permission: subsetAudienceGranted ?? false }
    : { isLoading: syncLoading, permission: subsetSyncGranted ?? false };
};
