import { FC } from "react";

import { Column, Row, Text, Tooltip } from "@hightouchio/ui";
import { ChannelType } from "@hightouch/lib/resource-monitoring/types";
import { groupBy } from "lodash";

import { ChannelIcon } from "./channel-icon";
import { channelName } from "src/components/notification-channels/channel-definitions";

export const ChannelBubbles: FC<{
  channels: Array<{ id: string; config: any; channel_type: string }>;
}> = ({ channels }) => {
  const groupedChannels = groupBy(channels, "channel_type");

  return (
    <Row align="center" gap={1}>
      {Object.entries(groupedChannels).map(([channelType, channels]) => (
        <Tooltip
          key={channelType}
          message={
            <Column gap={1}>
              {channels?.map((recipient) => (
                <Row key={recipient.id}>
                  <Text color="white" fontWeight="medium">
                    {channelName(recipient)}
                  </Text>
                </Row>
              ))}
            </Column>
          }
        >
          <Column
            align="center"
            justify="center"
            bg="white"
            borderRadius="50%"
            w="24px"
            h="24px"
            flexShrink={0}
            border="1px"
            borderColor="base.border"
          >
            <ChannelIcon channelType={channelType as ChannelType} size={4} />
          </Column>
        </Tooltip>
      ))}
    </Row>
  );
};
