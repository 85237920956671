import { FC, ReactNode, useEffect, useMemo, useState } from "react";

import {
  Alert,
  AlertProps,
  Box,
  Button,
  Checkbox,
  CodeSnippet,
  Column,
  Combobox,
  FileInput,
  FileUploadError,
  FormField,
  GroupedCombobox,
  IconButton,
  MultiSelect,
  Paragraph,
  Radio,
  RadioGroup,
  RefreshIcon,
  Row,
  SectionHeading,
  Select,
  Switch,
  TagInput,
  Text,
  Textarea,
  TextInput,
  Tooltip,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { get, isEqual, uniqBy } from "lodash";
import { Controller, useFormContext } from "react-hook-form";

import { LinkButton } from "src/router";
import { Card } from "src/components/card";
import { Editor } from "src/components/editor";
import { FieldError } from "src/components/field-error";
import { usePermissionContext } from "src/components/permission/permission-context";
import { fileToBase64, fileToString } from "src/ui/file/fileUploader";
import { Markdown } from "src/ui/markdown";
import { AccordionSection } from "src/components/accordion-section";

import {
  ComponentType,
  FormkitComponent,
  FormkitNode,
  FormkitSection,
  getLiquidEngine,
  getUnaryBooleanValue,
  hasLiquid,
  isComponent,
  LayoutType,
  NodeType,
  toExtendedOption,
} from "@hightouch/formkit";
import { toExtendedAssociationOption } from "@hightouch/formkit/src/api/components/associationOption";
import { KeyValueMapping } from "src/components/destinations/key-value-mapping";
import { FormkitTable } from "src/components/destinations/table";
import { TunnelTable } from "src/components/destinations/tunnel-table";
import { SecretInput } from "src/components/secret-input";
import { Collapsible } from "./components/collapsible";
import { isFieldLocked } from "src/components/destinations/utils";
import { OverrideConfig } from "src/components/destinations/types";
import { Form } from "./components/form";
import { useFormkitContext } from "./components/formkit-context";
import useDrivePicker from "./components/google-picker";
import { FormkitInput } from "./components/input";
import { AssociationMappings as AssociationMappingsV2 } from "./components/mapper-v2/association-mappings";
import { Mapper } from "./components/mapper-v2/mapper";
import { Mapping } from "./components/mapper-v2/mapping";
import { Mappings } from "./components/mapper-v2/mappings";
import { Modifier } from "./components/modifier";
import { NestedCheckBoxGroup } from "./components/nested-checkbox-group";
import { NestedRadioGroup } from "./components/nested-radio-group";
import { FormkitRTE } from "./components/rich-text-editor";
import { JsonColumnProps } from "./components/types";
import { VALUE_LABEL_FUNCTION_MAP } from "./constants";
import { SyncTemplateLock } from "./components/sync-template-lock";
import { Section } from "./components/section";
import { graphQLFetch, useQuery } from "./hooks";
import { processReferences } from "./component-utils";
import { SyncOverride } from "./components/sync-override";

type FormComponentProps = any & { disable?: boolean };

const FormComponentMap: Record<ComponentType, FC<FormComponentProps>> = {
  [ComponentType.Collapsible]: ({ label, children, name }) => {
    return (
      <Controller
        name={name}
        render={({ field }) => (
          <Collapsible
            label={label}
            value={field.value}
            onChange={field.onChange}
          >
            <Card>
              <Form>{children}</Form>
            </Card>
          </Collapsible>
        )}
      />
    );
  },
  [ComponentType.Switch]: ({ name, label, error, disable }) => {
    const permission = usePermissionContext();

    return (
      <>
        <Controller
          name={name}
          render={({ field }) => {
            return (
              <Box alignItems="center" display="flex" gap={3}>
                <Switch
                  isChecked={Boolean(field.value)}
                  isDisabled={
                    getUnaryBooleanValue(disable) ||
                    Boolean(permission?.unauthorized)
                  }
                  onChange={(value) => field.onChange(value)}
                />
                <Text fontWeight="semibold">{label}</Text>
              </Box>
            );
          }}
        />
        <FieldError error={error} />
      </>
    );
  },
  [ComponentType.Checkbox]: ({ name, label, error, disable }) => {
    const permission = usePermissionContext();

    return (
      <>
        <Controller
          name={name}
          render={({ field }) => {
            return (
              <Checkbox
                isChecked={Boolean(field.value)}
                isDisabled={
                  getUnaryBooleanValue(disable) || permission.unauthorized
                }
                label={label}
                onChange={field.onChange}
              />
            );
          }}
        />
        <FieldError error={error} />
      </>
    );
  },
  [ComponentType.Select]: ({
    isSetup,
    creatable,
    default: defaultValue,
    disable,
    grouped,
    createLabelPrefix = "object",
    error,
    multi,
    name,
    options,
    placeholder,
    searchable,
    valueLabelFunctionKey,
    width,
  }) => {
    const [customOptions, setCustomOptions] = useState<
      Array<{ label: string; value: string }>
    >([]);
    const [inputValue, setInputValue] = useState<string>("");
    const { destination, model } = useFormkitContext();

    if (searchable && options?.variables?.input?.variables) {
      options.variables.input.variables = {
        ...options.variables.input.variables,
        inputValue,
      };
    }
    const {
      data,
      error: queryError,
      refetch,
      isFetching,
    } = useQuery<any>(JSON.stringify({ name, variables: options?.variables }), {
      enabled: !Array.isArray(options),
      fetchProps: {
        destinationId: destination?.id,
        modelId: model?.id,
        query: options?.query,
        variables: options?.variables,
      },
    });

    const permission = usePermissionContext();

    const useDynamicOptions = !Array.isArray(options);

    // Destinations like Google Cloud Functions have duplicate options with the same label and value,
    // which cause React errors about duplicate `key` props, so they need to be removed
    const uniqueStaticOptions = uniqBy(options, "value");

    // Destinations like `sfmc` can return objects without `label` or `value`, so they need to be removed
    const nonEmptyDynamicOptions = (data ?? []).filter((option) => {
      return "label" in option && "value" in option;
    });

    const staticOrDynamicOptions = useDynamicOptions
      ? nonEmptyDynamicOptions
      : uniqueStaticOptions;

    return (
      <>
        <Controller
          name={name}
          render={({ field }) => {
            const combinedOptions = [
              ...staticOrDynamicOptions,
              ...customOptions,
            ];

            if (searchable && inputValue && !isFetching) {
              if (data?.length) {
                const firstLookup = data[0].value;
                // If async lookups and search returned a value, set field to trigger modifiers
                if (!field.value) {
                  field.onChange(firstLookup);
                }
              } else {
                setInputValue("");
              }
            }

            const hasValue = multi
              ? Array.isArray(field.value) && field.value.length > 0
              : Boolean(field.value);

            // When user creates a custom option while creating a new destination,
            // this option must be added to a list of static/dynamic options on edit page,
            // otherwise selected option won't be displayed in a combobox
            if (!isSetup && creatable && hasValue) {
              const values = multi ? field.value : [field.value];

              for (const value of values) {
                const selectedOptionExists = combinedOptions.some((option) => {
                  return option.value === value;
                });

                if (!selectedOptionExists) {
                  combinedOptions.push({
                    label: value,
                    value,
                  });
                }
              }
            }

            const sharedProps = {
              isDisabled:
                permission.unauthorized || getUnaryBooleanValue(disable),
              isInvalid: Boolean(error),
              isLoading: useDynamicOptions ? isFetching : false,
              placeholder,
              width,
              valueLabel:
                valueLabelFunctionKey &&
                VALUE_LABEL_FUNCTION_MAP[valueLabelFunctionKey],
            };

            const sharedCreatableProps = {
              supportsCreatableOptions: creatable || searchable,
              createOptionMessage: (inputValue: string) => {
                return searchable
                  ? `Search for "${inputValue}"`
                  : `Create ${createLabelPrefix} "${inputValue}"`;
              },
            };

            let component:
              | "Select"
              | "Combobox"
              | "GroupedCombobox"
              | "MultiSelect"
              | "TagInput"
              | undefined;

            if (multi) {
              component =
                creatable || useDynamicOptions ? "TagInput" : "MultiSelect";
            } else {
              component =
                creatable || useDynamicOptions ? "Combobox" : "Select";
            }

            // Grouped options are only used by `salesforce` sync form, and
            // it's configured to allow single selection and disable creatable options.
            // Until more destinations use grouped options, it's not necessary to
            // implement multi selection and creatable options for grouped options
            if (grouped) {
              component = "GroupedCombobox";
            }

            return (
              <Box alignItems="center" display="flex" gap={3}>
                {component === "Select" && (
                  <Select
                    {...sharedProps}
                    isClearable
                    options={combinedOptions}
                    value={field.value ?? defaultValue ?? undefined}
                    onChange={(newValue) => {
                      field.onChange(newValue ?? defaultValue ?? null);
                    }}
                  />
                )}

                {component === "Combobox" && (
                  <Combobox
                    {...sharedProps}
                    {...sharedCreatableProps}
                    isClearable
                    options={combinedOptions}
                    value={field.value ?? defaultValue ?? undefined}
                    onChange={(newValue) => {
                      setInputValue("");
                      field.onChange(newValue ?? null);
                    }}
                    onCreateOption={async (inputValue) => {
                      if (searchable) {
                        setInputValue(inputValue);
                        return;
                      }
                      const newOption = {
                        label: inputValue,
                        value: inputValue,
                      };

                      setCustomOptions((previousCustomOptions) => {
                        return [...previousCustomOptions, newOption];
                      });
                      field.onChange(inputValue);
                    }}
                  />
                )}

                {component === "GroupedCombobox" && (
                  <GroupedCombobox
                    {...sharedProps}
                    optionGroups={data ?? []}
                    value={field.value ?? defaultValue ?? undefined}
                    onChange={(newValue) => {
                      field.onChange(newValue ?? null);
                    }}
                  />
                )}

                {component === "MultiSelect" && (
                  <Box flex="1" minWidth={0}>
                    <MultiSelect
                      {...sharedProps}
                      isClearable
                      options={combinedOptions}
                      value={field.value ?? defaultValue ?? []}
                      width="100%"
                      onChange={(newValue) => {
                        field.onChange(newValue ?? []);
                      }}
                    />
                  </Box>
                )}

                {component === "TagInput" && (
                  <Box flex="1" minWidth={0}>
                    <TagInput
                      {...sharedProps}
                      {...sharedCreatableProps}
                      options={combinedOptions}
                      value={field.value ?? defaultValue ?? []}
                      width="100%"
                      onChange={(newValue) => {
                        field.onChange(newValue ?? []);
                      }}
                      onCreateOption={async (inputValue) => {
                        const newOption = {
                          label: inputValue,
                          value: inputValue,
                        };

                        setCustomOptions((previousCustomOptions) => {
                          return [...previousCustomOptions, newOption];
                        });

                        field.onChange([...(field.value ?? []), inputValue]);
                      }}
                    />
                  </Box>
                )}

                {useDynamicOptions && !searchable && (
                  <Button icon={RefreshIcon} onClick={() => refetch()}>
                    Refresh
                  </Button>
                )}
              </Box>
            );
          }}
        />

        <FieldError error={queryError || error} />
      </>
    );
  },
  [ComponentType.Input]: ({
    default: defaultValue,
    disable,
    error,
    max,
    min,
    name,
    placeholder,
    prefix,
    readOnly,
    style,
    type,
    value,
  }) => {
    const { destination, model } = useFormkitContext();
    const { control, setValue } = useFormContext();
    const { data: queriedValue, error: queryError } = useQuery<any>(
      JSON.stringify({ name, variables: value?.variables }),
      {
        enabled: value && typeof value !== "string",
        fetchProps: {
          destinationId: destination?.id,
          modelId: model?.id,
          query: value?.query,
          variables: value?.variables,
        },
        keepPreviousData: true,
      },
    );
    const hardcodedValue = value
      ? typeof value === "string"
        ? value
        : queriedValue
      : undefined;

    useEffect(() => {
      if (hardcodedValue != null) {
        setValue(name, hardcodedValue);
      }
    }, [hardcodedValue]);

    const permission = usePermissionContext();

    return (
      <>
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <>
              <FormkitInput
                prefix={prefix}
                disabled={
                  permission.unauthorized || getUnaryBooleanValue(disable)
                }
                invalid={Boolean(error)}
                readonly={readOnly}
                min={min}
                max={max}
                placeholder={placeholder}
                type={type}
                defaultValue={defaultValue}
                value={hardcodedValue ?? field.value ?? ""}
                onBlur={field.onBlur}
                style={style}
                onChange={(event) => {
                  const value = event.target.value;
                  if (type === "number") {
                    field.onChange(Number(value) ?? undefined);
                  } else {
                    field.onChange(value ?? undefined);
                  }
                }}
              />
            </>
          )}
        />

        <FieldError error={queryError || error} />
      </>
    );
  },
  [ComponentType.Secret]: ({
    name,
    disable,
    error,
    isSetup,
    multiline,
    placeholder,
  }) => {
    const permission = usePermissionContext();
    return (
      <>
        <Controller
          name={name}
          render={({ field }) => (
            <SecretInput
              isDisabled={
                getUnaryBooleanValue(disable) || permission.unauthorized
              }
              isHidden={!isSetup}
              multiline={multiline}
              onChange={field.onChange}
              value={field.value}
              placeholder={placeholder}
            />
          )}
        />
        <FieldError error={error} />
      </>
    );
  },
  [ComponentType.KeyValueMapping]: ({
    name,
    error,
    enableEncryption,
    disable,
  }) => {
    return (
      <>
        <Controller
          name={name}
          render={({ field }) => (
            <KeyValueMapping
              isDisabled={getUnaryBooleanValue(disable)}
              enableEncryption={enableEncryption}
              mapping={field.value}
              setMapping={field.onChange}
            />
          )}
        />
        <FieldError error={error} />
      </>
    );
  },
  [ComponentType.Table]: ({
    name,
    error,
    columns,
    addRow,
    addRowLabel,
    disable,
  }) => {
    return (
      <>
        <Controller
          name={name}
          render={({ field }) => (
            <FormkitTable
              isDisabled={getUnaryBooleanValue(disable)}
              addRow={addRow}
              addRowLabel={addRowLabel}
              columns={columns}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <FieldError error={error} />
      </>
    );
  },
  [ComponentType.TunnelTable]: ({
    name,
    error,
    columns,
    addRow,
    addRowLabel,
    createTunnel,
    disable,
  }) => {
    return (
      <>
        <Controller
          name={name}
          render={({ field }) => (
            <TunnelTable
              isDisabled={getUnaryBooleanValue(disable)}
              addRow={addRow}
              addRowLabel={addRowLabel}
              createTunnel={createTunnel}
              columns={columns}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <FieldError error={error} />
      </>
    );
  },
  [ComponentType.File]: ({
    name,
    error,
    acceptedFileTypes,
    transformation,
    disable,
  }) => {
    const permission = usePermissionContext();

    return (
      <>
        <Controller
          name={name}
          render={({ field }) => {
            return (
              <FileInput
                accept={acceptedFileTypes}
                isDisabled={
                  getUnaryBooleanValue(disable) || permission.unauthorized
                }
                onUpload={async (file) => {
                  if (transformation === "base64") {
                    const result = await fileToBase64(file);
                    field.onChange(result);
                  }

                  if (transformation === "string") {
                    const result = await fileToString(file);
                    field.onChange(result);
                  }

                  if (transformation === "JSONParse") {
                    try {
                      const result = JSON.parse(await file.text());
                      field.onChange(result);
                    } catch {
                      throw new FileUploadError(
                        "Uploaded file is not a valid JSON",
                      );
                    }
                  }

                  if (typeof transformation === "function") {
                    const result = await transformation(file);
                    field.onChange(result);
                  }
                }}
              />
            );
          }}
        />
        <FieldError error={error} />
      </>
    );
  },
  [ComponentType.Textarea]: ({ name, error, placeholder, disable }) => {
    const { control } = useFormContext();
    const permission = usePermissionContext();

    return (
      <>
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <Textarea
              isDisabled={
                getUnaryBooleanValue(disable) || permission.unauthorized
              }
              isInvalid={Boolean(error)}
              placeholder={placeholder}
              resize="vertical"
              rows={10}
              width="100%"
              value={field.value}
              onBlur={field.onBlur}
              onChange={field.onChange}
            />
          )}
        />
        <FieldError error={error} />
      </>
    );
  },
  [ComponentType.RichTextEditor]: ({
    name,
    error,
    placeholder,
    profile,
    handler,
    disable,
  }) => {
    const permission = usePermissionContext();
    const { enableSlackRte } = useFlags();
    if (enableSlackRte) {
      return (
        <>
          <Controller
            name={name}
            render={({ field }) => (
              <Box border="1px" borderColor="base.border" borderRadius="md">
                <FormkitRTE
                  isDisabled={
                    getUnaryBooleanValue(disable) || permission.unauthorized
                  }
                  onChange={
                    permission.unauthorized
                      ? undefined
                      : (v) => field.onChange(v)
                  }
                  value={field.value}
                  profile={profile}
                  placeholder={placeholder}
                  handler={handler}
                />
              </Box>
            )}
          />
          <FieldError error={error} />
        </>
      );
    }

    return (
      <>
        <Controller
          name={name}
          render={({ field }) => (
            <Column
              border="1px"
              borderColor="base.border"
              borderRadius="md"
              maxHeight="300px"
              minHeight="200px"
              height="100%"
              overflow="hidden"
            >
              <Editor
                minHeight="200px"
                readOnly={
                  getUnaryBooleanValue(disable) || permission.unauthorized
                }
                value={field.value || ""}
                language="liquid"
                placeholder={placeholder}
                onChange={permission.unauthorized ? undefined : field.onChange}
              />
            </Column>
          )}
        />
        <FieldError error={error} />
      </>
    );
  },
  [ComponentType.Editor]: ({
    beautifyJson,
    name,
    error,
    language,
    placeholder,
    disable,
  }) => {
    const beautifyJSON = (body) => {
      const obj = JSON.parse(body);
      return JSON.stringify(obj, null, 4);
    };

    const permission = usePermissionContext();

    return (
      <>
        <Controller
          name={name}
          render={({ field }) => (
            <>
              <Column
                border="1px"
                borderColor="base.border"
                borderRadius="md"
                maxHeight="300px"
                minHeight="200px"
                height="100%"
                overflow="hidden"
              >
                <Editor
                  minHeight="200px"
                  readOnly={
                    getUnaryBooleanValue(disable) || permission.unauthorized
                  }
                  value={field.value || ""}
                  language={language}
                  placeholder={placeholder}
                  onChange={
                    permission.unauthorized ? undefined : field.onChange
                  }
                />
              </Column>
              {beautifyJson && (
                <Button
                  isDisabled={
                    getUnaryBooleanValue(disable) || permission.unauthorized
                  }
                  mt={2}
                  onClick={() => {
                    field.onChange(beautifyJSON(field.value));
                  }}
                >
                  Beautify
                </Button>
              )}
            </>
          )}
        />
        <FieldError error={error} />
      </>
    );
  },
  [ComponentType.Code]: ({ name, title, content, error }) => {
    const { destination, model } = useFormkitContext();
    const { data = [], error: queryError } = useQuery<any>(
      JSON.stringify({ name, variables: content?.variables }),
      {
        enabled: !Array.isArray(content),
        fetchProps: {
          destinationId: destination?.id,
          modelId: model?.id,
          query: content?.query,
          variables: content?.variables,
        },
        keepPreviousData: true,
      },
    );

    return (
      <>
        <Controller
          name={name}
          render={() => {
            const code = Array.isArray(content) ? content : data;
            return <CodeSnippet code={code.join("\n")} label={title} />;
          }}
        />
        <FieldError error={queryError || error} />
      </>
    );
  },
  [ComponentType.Message]: ({
    error,
    message,
    title,
    variant,
  }: {
    error?: Error | ReactNode;
    message: string;
    title?: string;
    variant?: AlertProps["type"];
  }) => {
    let titleToUse = title || "Information";

    if (variant && variant !== "subtle") {
      const firstLetter = variant.charAt(0).toUpperCase();
      const remainingLetters = variant.slice(1);
      titleToUse = firstLetter + remainingLetters;
    }

    return (
      <>
        <Alert
          variant="inline"
          type={variant ?? "info"}
          title={titleToUse}
          message={message && <Markdown>{message}</Markdown>}
        />
        <FieldError error={error} />
      </>
    );
  },
  [ComponentType.Mapping]: ({
    name,
    error,
    options,
    fromOptions,
    fromLabel,
    fromIcon,
    creatable,
    creatableTypes,
    advanced,
    templates,
    mappingTypes,
  }) => {
    const { destination, model } = useFormkitContext();
    const {
      data,
      error: toQueryError,
      refetch,
      isFetching,
    } = useQuery<any, any>(
      JSON.stringify({ name, variables: options?.variables }),
      {
        enabled: !Array.isArray(options),
        fetchProps: {
          destinationId: destination?.id,
          modelId: model?.id,
          query: options?.query,
          variables: options?.variables,
        },
      },
    );

    const customizingFromOptionsWithReference =
      fromOptions && !Array.isArray(fromOptions);
    const {
      data: fromData,
      error: fromQueryError,
      refetch: fromRefetch,
      isFetching: isFetchingFromData,
    } = useQuery<any, any>(
      JSON.stringify({ name, variables: fromOptions?.variables }),
      {
        enabled: customizingFromOptionsWithReference,
        fetchProps: {
          destinationId: destination?.id,
          modelId: model?.id,
          query: fromOptions?.query,
          variables: fromOptions?.variables,
        },
      },
    );

    return (
      <>
        <Mapping
          // TODO(samuel): figure out how to pass the disabled state to the component
          // May need to use the custom controller component in the Mapping component too.
          advanced={advanced}
          creatable={getUnaryBooleanValue(creatable)}
          creatableTypes={creatableTypes}
          error={error}
          fromType={fromOptions ? "destinationOnlyMapping" : undefined}
          fromIcon={fromIcon}
          fromLabel={fromLabel}
          fromLoadingOptions={
            customizingFromOptionsWithReference ? isFetchingFromData : undefined
          }
          fromOptions={
            fromOptions
              ? toExtendedOption(
                  Array.isArray(fromOptions) ? fromOptions : fromData || [],
                )
              : undefined
          }
          fromReloadOptions={
            customizingFromOptionsWithReference ? fromRefetch : undefined
          }
          loading={isFetching}
          mappingTypes={mappingTypes}
          name={name}
          options={toExtendedOption(Array.isArray(options) ? options : data)}
          reload={Array.isArray(options) ? undefined : refetch}
          templates={templates || []}
        />
        {fromQueryError ? (
          <FieldError error={toQueryError} prefix="From options query error:" />
        ) : toQueryError ? (
          <FieldError error={toQueryError} prefix="To options query error:" />
        ) : (
          <FieldError error={error} />
        )}
      </>
    );
  },
  [ComponentType.Mappings]: ({
    name,
    allEnabled,
    autoSyncColumnsDefault,
    allEnabledKey,
    allEnabledLabel,
    creatable,
    creatableTypes,
    options,
    error,
    advanced,
    enableInLineMapper,
    required,
    excludeMappings,
    associationOptions,
    templates,
    componentSupportsMatchBoosting,
    matchboosterSemanticColumnsToMap,
    allowCreatableAutomapperWithFields,
    allowIgnoreNullForAssociations,
  }) => {
    const { destination, model } = useFormkitContext();
    const asyncOptions =
      !Array.isArray(options) && options !== null && options !== undefined;
    const {
      data,
      error: queryError,
      refetch,
      isFetching,
    } = useQuery<any>(JSON.stringify({ name, variables: options?.variables }), {
      enabled: asyncOptions,
      fetchProps: {
        destinationId: destination?.id,
        modelId: model?.id,
        query: options?.query,
        variables: options?.variables,
      },
    });

    return (
      <>
        <Mappings
          // TODO(samuel): figure out how to pass the disabled state to the component
          // May need to use the custom controller component in the Mappings component too.
          advanced={advanced}
          allEnabled={allEnabled}
          allEnabledKey={allEnabledKey}
          allEnabledLabel={allEnabledLabel}
          allowCreatableAutomapperWithFields={
            allowCreatableAutomapperWithFields
          }
          allowIgnoreNullForAssociations={allowIgnoreNullForAssociations}
          associationOptions={toExtendedAssociationOption(associationOptions)}
          asyncOptions={asyncOptions}
          autoSyncColumnsDefault={autoSyncColumnsDefault}
          creatable={getUnaryBooleanValue(creatable)}
          creatableTypes={toExtendedOption(creatableTypes)}
          enableInLineMapper={enableInLineMapper}
          componentSupportsMatchBoosting={componentSupportsMatchBoosting}
          matchboosterSemanticColumnsToMap={matchboosterSemanticColumnsToMap}
          error={error}
          excludeMappings={excludeMappings}
          loading={isFetching}
          name={name}
          options={toExtendedOption(asyncOptions ? data : options)}
          reload={asyncOptions ? refetch : undefined}
          required={required}
          templates={templates}
        />
        <FieldError error={queryError || error} />
      </>
    );
  },
  [ComponentType.AssociationMappings]: ({
    name,
    options,
    error,
    excludeMappings,
    ascOptions,
  }) => {
    const { destination, model } = useFormkitContext();
    const AssociationMappings = AssociationMappingsV2;
    const asyncOptions =
      !Array.isArray(options) && options !== null && options !== undefined;
    const {
      data,
      error: queryError,
      refetch,
      isFetching,
    } = useQuery<any>(JSON.stringify({ name, variables: options?.variables }), {
      enabled: asyncOptions,
      fetchProps: {
        destinationId: destination?.id,
        modelId: model?.id,
        query: options?.query,
        variables: options?.variables,
      },
    });

    return (
      <>
        <AssociationMappings
          // TODO(samuel): figure out how to pass the disabled state to the component
          // May need to use the custom controller component in the AssociationMappings component too.
          ascOptions={toExtendedOption(ascOptions)}
          error={error}
          excludeMappings={excludeMappings}
          loading={isFetching}
          name={name}
          options={toExtendedOption(asyncOptions ? data : options)}
          reload={asyncOptions ? refetch : undefined}
        />
        <FieldError error={queryError || error} />
      </>
    );
  },
  [ComponentType.RadioGroup]: ({ name, options, error, disable }) => {
    const { destination, model } = useFormkitContext();
    const { data, error: queryError } = useQuery<any>(
      JSON.stringify({ name, variables: options?.variables }),
      {
        enabled: !Array.isArray(options),
        fetchProps: {
          destinationId: destination?.id,
          modelId: model?.id,
          query: options?.query,
          variables: options?.variables,
        },
      },
    );
    const permission = usePermissionContext();
    const staticOrDynamicOptions = Array.isArray(options)
      ? options
      : (data ?? []);

    return (
      <>
        <Controller
          name={name}
          render={({ field }) => {
            // `RadioGroup` component requires values to be strings, but
            // field options can be either completely missing or be booleans
            // so we're using option indexes as `RadioGroup` value instead
            const selectedIndex = staticOrDynamicOptions.findIndex(
              (option) => option.value === (field.value ?? undefined),
            );

            return (
              <RadioGroup
                isDisabled={
                  getUnaryBooleanValue(disable) || permission.unauthorized
                }
                orientation="vertical"
                value={String(selectedIndex)}
                onChange={(indexString) => {
                  const option = staticOrDynamicOptions.find(
                    (_, index) => String(index) === indexString,
                  );
                  field.onChange(option?.value ?? null);
                }}
              >
                {staticOrDynamicOptions.map((option, index) => (
                  <Radio
                    key={option.value ?? index}
                    description={
                      option.description && (
                        <Markdown>{option.description}</Markdown>
                      )
                    }
                    label={option.label}
                    value={String(index)}
                  />
                ))}
              </RadioGroup>
            );
          }}
        />
        <FieldError error={queryError || error} />
      </>
    );
  },
  [ComponentType.Button]: ({
    error,
    label,
    mode,
    onClickUrlQuery,
    url,
    newTab,
  }) => {
    const { destination, model } = useFormkitContext();
    const { data: queriedValue = "", error: queryError } = useQuery<any>(
      JSON.stringify({ name, variables: url?.variables }),
      {
        enabled: url && typeof url !== "string",
        fetchProps: {
          destinationId: destination?.id,
          modelId: model?.id,
          query: url?.query,
          variables: url?.variables,
        },
        keepPreviousData: true,
      },
    );

    const generatedUrl = url
      ? typeof url === "string"
        ? url
        : queriedValue
      : undefined;

    const permission = usePermissionContext();

    if (mode === "link" && (generatedUrl || onClickUrlQuery)) {
      return (
        <>
          <Button
            // TODO(samuel): does this need to be disabled with overrides? I don't think so.
            isDisabled={permission.unauthorized}
            variant="primary"
            onClick={async () => {
              let onClickUrl;
              // For the edge case in which the page rerenders and reruns the above GraphQL query, causing credentials to be reset in OAuth
              // i.e. Google Sheets SA
              if (onClickUrlQuery) {
                const data = await graphQLFetch({
                  destinationId: destination?.id,
                  query: onClickUrlQuery?.query,
                  modelId: model?.id,
                  variables: onClickUrlQuery?.variables,
                });
                onClickUrl = data;
              }
              if (newTab) {
                window?.open(
                  generatedUrl || onClickUrl,
                  "_blank",
                  "noreferrer",
                );
              } else {
                location.href = generatedUrl || onClickUrl;
              }
            }}
          >
            {label}
          </Button>

          <FieldError error={queryError || error} />
        </>
      );
    }

    return (
      <>
        {mode === "link" && (
          <LinkButton href={generatedUrl}>{label}</LinkButton>
        )}
        <FieldError error={queryError || error} />
      </>
    );
  },
  [ComponentType.Column]: ({
    name,
    error,
    useStringColumnValue,
    advanced,
    templates,
    type,
    disable,
  }) => {
    const { columns, reloadModel, loadingModel, model } = useFormkitContext();
    const permission = usePermissionContext();
    const [jsonColumnProperties, setJsonColumnProperties] =
      useState<JsonColumnProps>({
        selectedColumnProps: undefined,
        allColumnsProps: undefined,
      });

    const reloadJsonColumnsProps = () => {
      Sentry.captureException(
        new Error("reloadJsonColumnProps called for column formkit component"),
      );
    };

    // Items in `columns` have `options` field as optional,
    // but `GroupedCombobox` expects it to be required
    const optionGroups = useMemo(() => {
      return (columns ?? []).map((group) => ({
        ...group,
        options: group.options ?? [],
      }));
    }, [columns]);

    return (
      <>
        <Controller
          name={name}
          render={({ field }) => (
            <>
              {advanced ? (
                <Mapper
                  isClearable
                  isDisabled={
                    getUnaryBooleanValue(disable) || permission.unauthorized
                  }
                  isError={Boolean(error)}
                  jsonColumnProperties={jsonColumnProperties}
                  placeholder="Select a value..."
                  selectedOption={undefined}
                  templates={templates ?? []}
                  value={
                    field.value
                      ? field.value
                      : advanced
                        ? { type: "standard" }
                        : undefined
                  }
                  onChange={(value) => {
                    if (!value) {
                      field.onChange({ type: "standard" });
                      return;
                    }
                    field.onChange(value);
                  }}
                  onChangeJsonColumnProperties={setJsonColumnProperties}
                  onReloadEligibleInlineMapperColumns={reloadJsonColumnsProps}
                />
              ) : model ? (
                <Box alignItems="center" display="flex" gap={2}>
                  <GroupedCombobox
                    isClearable
                    isDisabled={
                      getUnaryBooleanValue(disable) || permission.unauthorized
                    }
                    isInvalid={Boolean(error)}
                    isLoading={loadingModel}
                    optionGroups={optionGroups}
                    placeholder="Select a column..."
                    value={
                      useStringColumnValue ? field.value : field.value?.from
                    }
                    onChange={(value) => {
                      if (!value) {
                        field.onChange(null);
                        return;
                      }

                      field.onChange(
                        useStringColumnValue ? value : { from: value },
                      );
                    }}
                  />

                  <Button icon={RefreshIcon} onClick={reloadModel}>
                    Refresh
                  </Button>
                </Box>
              ) : (
                <TextInput
                  isDisabled={
                    getUnaryBooleanValue(disable) || permission.unauthorized
                  }
                  isInvalid={Boolean(error)}
                  placeholder="Enter a column..."
                  value={field.value?.from}
                  onChange={({ target: { value } }) => {
                    if (!value) {
                      field.onChange(null);
                      return;
                    }

                    field.onChange(
                      useStringColumnValue ? value : { from: value },
                    );
                  }}
                  type={type}
                />
              )}
            </>
          )}
        />
        <FieldError error={error} />
      </>
    );
  },
  [ComponentType.ColumnOrConstant]: ({
    constantComponentType,
    creatable,
    createLabelPrefix = "object",
    error,
    name,
    options,
    type,
    multi,
    disable,
  }) => {
    const { columns, reloadModel, loadingModel } = useFormkitContext();
    const asyncOptions =
      !Array.isArray(options) && options !== null && options !== undefined;
    const { destination, model } = useFormkitContext();
    const {
      data,
      error: queryError,
      refetch,
      isFetching,
    } = useQuery<any>(JSON.stringify({ name, variables: options?.variables }), {
      enabled: asyncOptions && !!model,
      fetchProps: {
        destinationId: destination?.id,
        modelId: model?.id,
        query: options?.query,
        variables: options?.variables,
      },
    });

    const updatePermission = usePermissionContext();

    const [combinedOptions, setCombinedOptions] = useState<
      {
        label: string;
        value: string | number;
      }[]
    >([]);

    useEffect(() => {
      if (data) {
        setCombinedOptions((currentOptions) => {
          const filteredOptions: {
            label: string;
            value: string | number;
          }[] = [];
          for (const option of currentOptions) {
            // skip any option that already exists in the data
            if (data.some((dataOption) => dataOption.value === option.value)) {
              continue;
            }

            filteredOptions.push(option);
          }

          return [...(data ?? []), ...filteredOptions];
        });
      }
    }, [data]);

    // Items in `columns` have `options` field as optional,
    // but `GroupedCombobox` expects it to be required
    const optionGroups = useMemo(() => {
      return (columns ?? []).map((group) => ({
        ...group,
        options: group.options ?? [],
      }));
    }, [columns]);

    const isMulti =
      multi &&
      (constantComponentType === ComponentType.Select ||
        constantComponentType === undefined);

    return (
      <>
        <Controller
          name={name}
          render={({ field }) => {
            const isColumn =
              // Multi select is only avaliable on Select component.
              !(isMulti && Array.isArray(field.value)) &&
              typeof field.value === "object" &&
              field.value !== null &&
              field.value !== undefined;

            // `RadioGroup` component requires values to be strings, but
            // field options can be either completely missing or be booleans
            // so we're using option indexes as `RadioGroup` value instead
            const selectedRadioGroupIndex =
              Array.isArray(options) &&
              constantComponentType === ComponentType.RadioGroup
                ? options.findIndex(
                    (option) => option.value === (field.value ?? undefined),
                  )
                : -1;

            useEffect(() => {
              // When user creates a custom option while creating a new destination,
              // this option must be added to a list of static/dynamic options on edit page,
              // otherwise selected option won't be displayed in a combobox
              if (creatable && field.value) {
                const values = multi ? field.value : [field.value];

                for (const value of values) {
                  const selectedOptionExists = combinedOptions.some(
                    (option) => {
                      return option.value === value;
                    },
                  );

                  if (!selectedOptionExists) {
                    setCombinedOptions((currentOptions) => [
                      ...currentOptions,
                      {
                        label: value,
                        value,
                      },
                    ]);
                  }
                }
              }
            }, [combinedOptions, field.value]);

            return (
              <Row display="flex" justifyContent="space-between">
                {isColumn ? (
                  model ? (
                    <Box alignItems="center" display="flex" gap={2}>
                      <GroupedCombobox
                        isClearable
                        isDisabled={
                          getUnaryBooleanValue(disable) ||
                          updatePermission.unauthorized
                        }
                        isInvalid={Boolean(error)}
                        isLoading={loadingModel}
                        optionGroups={optionGroups}
                        placeholder="Select a column..."
                        value={field.value?.from}
                        onChange={(value) => {
                          field.onChange({
                            from: value || undefined,
                          });
                        }}
                      />

                      <Button icon={RefreshIcon} onClick={reloadModel}>
                        Refresh
                      </Button>
                    </Box>
                  ) : (
                    <TextInput
                      isDisabled={
                        getUnaryBooleanValue(disable) ||
                        updatePermission.unauthorized
                      }
                      isInvalid={Boolean(error)}
                      placeholder="Enter a column..."
                      value={field.value?.from ?? ""}
                      onChange={({ target: { value } }) => {
                        field.onChange({
                          from: value || undefined,
                        });
                      }}
                      type={type}
                    />
                  )
                ) : options &&
                  (constantComponentType === ComponentType.Select ||
                    constantComponentType === undefined) ? (
                  <>
                    {creatable ? (
                      <Row align="center" gap={2}>
                        <Combobox
                          createOptionMessage={(value) =>
                            `Create ${createLabelPrefix} "${value}"...`
                          }
                          isClearable
                          isInvalid={Boolean(error)}
                          isDisabled={
                            getUnaryBooleanValue(disable) ||
                            updatePermission.unauthorized
                          }
                          isLoading={isFetching}
                          onChange={(option) => field.onChange(option)}
                          onCreateOption={async (value) => {
                            field.onChange(value);
                            setCombinedOptions((currentOptions) => [
                              ...currentOptions,
                              { label: value, value },
                            ]);
                          }}
                          options={combinedOptions}
                          placeholder="Select an option..."
                          supportsCreatableOptions={creatable}
                          value={field.value}
                        />
                        {asyncOptions && (
                          <Tooltip message="Reload options">
                            <IconButton
                              aria-label="Reload options"
                              isLoading={isFetching}
                              isDisabled={getUnaryBooleanValue(disable)}
                              icon={RefreshIcon}
                              onClick={() => refetch()}
                            />
                          </Tooltip>
                        )}
                      </Row>
                    ) : asyncOptions ? (
                      <Box alignItems="center" display="flex" gap={2}>
                        {isMulti ? (
                          <MultiSelect<Record<string, unknown>, unknown>
                            isDisabled={
                              getUnaryBooleanValue(disable) ||
                              updatePermission.unauthorized
                            }
                            isInvalid={Boolean(error)}
                            isLoading={isFetching}
                            options={data ?? []}
                            placeholder="Select options..."
                            value={
                              Array.isArray(field.value) ? field.value : []
                            }
                            onChange={(value) => {
                              field.onChange(value || []);
                            }}
                          />
                        ) : (
                          <Combobox
                            isDisabled={
                              getUnaryBooleanValue(disable) ||
                              updatePermission.unauthorized
                            }
                            isInvalid={Boolean(error)}
                            isLoading={isFetching}
                            options={data ?? []}
                            placeholder="Select an option..."
                            value={field.value}
                            onChange={(value) => {
                              field.onChange(value || undefined);
                            }}
                          />
                        )}

                        <Button
                          icon={RefreshIcon}
                          variant="secondary"
                          isDisabled={getUnaryBooleanValue(disable)}
                          onClick={() => {
                            void refetch();
                          }}
                        >
                          Refresh
                        </Button>
                      </Box>
                    ) : isMulti ? (
                      <MultiSelect<Record<string, unknown>, unknown>
                        isClearable
                        isDisabled={
                          getUnaryBooleanValue(disable) ||
                          updatePermission.unauthorized
                        }
                        isInvalid={Boolean(error)}
                        options={options}
                        value={Array.isArray(field.value) ? field.value : []}
                        placeholder="Select options..."
                        onChange={(newValue) => {
                          field.onChange(newValue ?? []);
                        }}
                      />
                    ) : (
                      <Select
                        isClearable
                        isDisabled={
                          getUnaryBooleanValue(disable) ||
                          updatePermission.unauthorized
                        }
                        isInvalid={Boolean(error)}
                        options={options}
                        placeholder="Select an option..."
                        value={field.value}
                        onChange={(value) => {
                          field.onChange(value || undefined);
                        }}
                      />
                    )}
                  </>
                ) : options &&
                  constantComponentType === ComponentType.RadioGroup ? (
                  <RadioGroup
                    isDisabled={
                      getUnaryBooleanValue(disable) ||
                      updatePermission.unauthorized
                    }
                    value={String(selectedRadioGroupIndex)}
                    onChange={(indexString) => {
                      const option = options.find(
                        (_, index) => String(index) === indexString,
                      );
                      field.onChange(option?.value ?? null);
                    }}
                  >
                    {options.map((option, index) => (
                      <Radio
                        key={option.value ?? index}
                        description={option.description}
                        label={option.label}
                        value={String(index)}
                      />
                    ))}
                  </RadioGroup>
                ) : (
                  <TextInput
                    isDisabled={
                      getUnaryBooleanValue(disable) ||
                      updatePermission.unauthorized
                    }
                    isInvalid={Boolean(error)}
                    placeholder="Enter a value..."
                    value={field.value}
                    onChange={field.onChange}
                    type={type}
                  />
                )}

                <Box alignItems="center" display="flex" gap={2}>
                  <Text
                    textTransform="uppercase"
                    size="sm"
                    color="text.secondary"
                    fontWeight="semibold"
                  >
                    Use column
                  </Text>

                  <Switch
                    isChecked={isColumn}
                    isDisabled={
                      getUnaryBooleanValue(disable) ||
                      Boolean(updatePermission?.unauthorized)
                    }
                    onChange={(value) => {
                      if (constantComponentType === ComponentType.RadioGroup) {
                        value
                          ? field.onChange({ from: undefined })
                          : field.onChange(options[0].value);
                      } else if (options) {
                        // If options exist and constantComponentType is not equal to RadioGroup, the component is a drop down.
                        value
                          ? field.onChange({ from: undefined })
                          : field.onChange(isMulti ? [] : null);
                      } else {
                        // Component is an input field
                        value
                          ? field.onChange({ from: undefined })
                          : field.onChange("");
                      }
                    }}
                  />
                </Box>
              </Row>
            );
          }}
        />
        <FieldError error={queryError || error} />
      </>
    );
  },
  [ComponentType.NestedRadioGroup]: ({
    name,
    rootKey,
    listKey,
    disable,
    options,
    error,
  }) => {
    const { getValues, setValue } = useFormContext();
    const { destination, model } = useFormkitContext();
    const handlerEnabled = !Array.isArray(options);
    const {
      data,
      error: queryError,
      refetch,
      isFetching,
    } = useQuery<any>(JSON.stringify({ name, variables: options?.variables }), {
      enabled: handlerEnabled,
      fetchProps: {
        destinationId: destination?.id,
        modelId: model?.id,
        query: options?.query,
        variables: options?.variables,
      },
      keepPreviousData: true,
    });

    return (
      <>
        <Controller
          name={name}
          render={({ field }) => {
            return (
              <NestedRadioGroup
                loading={isFetching}
                isDisabled={getUnaryBooleanValue(disable)}
                options={toExtendedOption(handlerEnabled ? data : options)}
                reload={handlerEnabled ? refetch : undefined}
                value={getValues(listKey)}
                onChange={(value: string[]) => {
                  field.onChange(value[value.length - 1]);
                  setValue(listKey, value);
                  setValue(rootKey, value[0]);
                }}
              />
            );
          }}
        />
        <FieldError error={queryError || error} />
      </>
    );
  },
  [ComponentType.NestedCheckboxGroup]: ({ name, disable, options, error }) => {
    const { destination, model } = useFormkitContext();
    const handlerEnabled = !Array.isArray(options);
    const {
      data,
      error: queryError,
      refetch,
      isFetching,
    } = useQuery<any>(JSON.stringify({ name, variables: options?.variables }), {
      enabled: handlerEnabled,
      fetchProps: {
        destinationId: destination?.id,
        modelId: model?.id,
        query: options?.query,
        variables: options?.variables,
      },
      keepPreviousData: true,
    });

    return (
      <>
        <Controller
          name={name}
          render={({ field }) => {
            return (
              <NestedCheckBoxGroup
                loading={isFetching}
                isDisabled={getUnaryBooleanValue(disable)}
                options={toExtendedOption(handlerEnabled ? data : options)}
                reload={handlerEnabled ? refetch : undefined}
                value={field.value || []}
                onChange={(newValue: string[]) => {
                  field.onChange(newValue ?? []);
                }}
              />
            );
          }}
        />
        <FieldError error={queryError || error} />
      </>
    );
  },
  [ComponentType.GooglePicker]: ({ name, viewId, disable }) => {
    const [fileOptions, setFileOptions] = useState<
      { label: string; value: string }[]
    >([]);
    const { control } = useFormContext();
    const [openPicker, _authResult] = useDrivePicker();
    const handleOpenPicker = (onChange) => {
      openPicker({
        appId: import.meta.env.VITE_GOOGLE_APP_ID?.toString() ?? "",
        clientId: import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID?.toString() ?? "",
        developerKey: import.meta.env.VITE_GOOGLE_API_KEY?.toString() ?? "",
        viewId: viewId || "SPREADSHEETS",
        showUploadView: true,
        showUploadFolders: true,
        supportDrives: true,
        multiselect: false,
        callbackFunction: (data) => {
          if (data.action === "cancel") {
            return;
          }
          if (data?.docs?.[0]?.id) {
            setFileOptions([
              { label: data.docs[0].name, value: data.docs[0].id },
            ]);
            onChange({
              id: data.docs[0].id,
              name: data.docs[0].name,
              parent: data.docs[0].parentId,
            });
          }
        },
      });
    };

    return (
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          if (
            field?.value?.id &&
            !fileOptions.some((opt) => opt.value === field.value.id)
          ) {
            setFileOptions([
              ...fileOptions,
              { label: field.value.name, value: field.value.id },
            ]);
          }
          return (
            <>
              <Box gap={2}>
                <Row>
                  <Select
                    isDisabled={getUnaryBooleanValue(disable)}
                    placeholder="Click here to open the Google Drive picker..."
                    options={fileOptions}
                    value={field.value?.id ?? undefined}
                    onChange={(_newValue) => {
                      // Do nothing. change handled by google drive picker
                      return;
                    }}
                    onOpen={() => {
                      handleOpenPicker(field.onChange);
                    }}
                  />
                </Row>
              </Box>
            </>
          );
        }}
      />
    );
  },
};

const getComponent = (node: FormkitComponent) =>
  FormComponentMap[node.component];

const Node: FC<{ node: FormkitComponent; children?: ReactNode }> = ({
  node,
  children,
}) => {
  const context = useFormkitContext();
  const {
    watch,
    resetField,
    formState: { errors },
    getValues,
  } = useFormContext();

  const props = processReferences(
    node.props,
    { ...context, formState: getValues() },
    watch,
  ) as Record<string, unknown>;

  // RULE: If the sync is attached to a template and this field is locked
  // then disable the field
  const isFieldDisabled =
    context.isSyncAttachedToTemplate &&
    context.hasUnlockedFields &&
    isFieldLocked(context.sync?.sync_template?.override_config, node.key);

  const Component = getComponent(node);

  const rawError = get(errors, node.key)?.message;
  let error =
    typeof rawError === "string"
      ? rawError.replace(node.key, "This")
      : rawError;
  // Doing this because columns `{ from: string }` gets validate from inside out.
  // For example: a returned validation errors is `errors: { "eventId.from": "eventId.from is required."}`
  if (
    !error &&
    node.type === NodeType.Component &&
    [ComponentType.Column, ComponentType.ColumnOrConstant].includes(
      node.component,
    )
  ) {
    const key = `${node.key}.from`;

    const rawError = get(errors, key)?.message;
    error =
      typeof rawError === "string"
        ? rawError.replace(node.key, "This")
        : rawError;
  }

  useEffect(() => {
    const value = watch(node.key);
    if (node.props?.default !== undefined && value === undefined) {
      resetField(node.key, { defaultValue: node.props.default });
    }
  }, []);

  const isChangedInDraft =
    !children &&
    context?.draftChanges?.find(({ key }) => {
      const nodePath = node.key.split(".");
      const keyPath = key.split(".");
      return isEqual(nodePath, keyPath.slice(0, nodePath.length));
    })?.op;

  return (
    <Box
      sx={
        isChangedInDraft
          ? {
              position: "relative",
              "::after": {
                content: '""',
                top: 0,
                left: -5,
                display: "block",
                width: "4px",
                height: "100%",
                position: "absolute",
                borderRadius: "2px",
                backgroundColor:
                  isChangedInDraft === "add" ? "success.base" : "danger.base",
              },
            }
          : {}
      }
    >
      <Component
        {...props}
        disable={isFieldDisabled || props.disable}
        error={error}
        isSetup={context.isSetup}
        name={node.key}
      >
        {children}
      </Component>
    </Box>
  );
};

interface FormNodeProps {
  node: FormkitNode;
  depth: number;
  context: Record<string, unknown>;
}

export const FormNode: FC<FormNodeProps> = ({ node, depth, context }) => {
  const { enableSyncTemplateOverrides } = useFlags();

  if (node.type === NodeType.Layout) {
    if (
      node.layout === LayoutType.Section ||
      node.layout === LayoutType.Accordion
    ) {
      const { heading, subheading } = parseHeadings(node, context);

      if (node.parent) {
        return (
          <Box
            p="0 !important"
            border="none !important"
            bg="transparent !important"
          >
            <Box mb={2}>
              <SectionHeading>{heading}</SectionHeading>
              {subheading && <Paragraph>{subheading}</Paragraph>}
            </Box>
            <Form>
              {node.children.map((node, index) => (
                <FormNode
                  key={index}
                  context={context}
                  depth={depth + 1}
                  node={node}
                />
              ))}
            </Form>
          </Box>
        );
      }

      // A section may have multiple fields
      // If a section has one field that controls other fields, then the lock will show on the header.
      const componentChildren = node.children.filter(isComponent);

      if (node.layout === LayoutType.Accordion) {
        return (
          <AccordionSection
            label={node.heading ?? ""}
            description={node.subheading}
          >
            <Column gap={6}>
              {node.children.map((node, index) => (
                <FormNode
                  key={index}
                  context={context}
                  depth={depth + 1}
                  node={node}
                />
              ))}
            </Column>
          </AccordionSection>
        );
      }

      // When dealing with sync templates, only render sections that are visible (are unlocked)
      // or render everything if the
      const isSectionVisible =
        !context.isSyncAttachedToTemplate ||
        (enableSyncTemplateOverrides &&
          (context.shouldShowFullConfiguration ||
            hasVisibleChild(
              node,
              context?.overrideConfig as OverrideConfig | null,
            )));

      if (!isSectionVisible) return null;

      if (node.size === "small") {
        return (
          <FormField
            isOptional={node.optional}
            label={heading && <Markdown disableParagraphs>{heading}</Markdown>}
            description={subheading ? <Markdown>{subheading}</Markdown> : null}
            rightContent={
              componentChildren?.[0] ? (
                // TODO(samuel): add support for multiple children for sync template UI
                <>
                  <SyncTemplateLock node={componentChildren[0]} />
                  <SyncOverride
                    childNodeKeys={componentChildren.map(({ key }) => key)}
                  />
                </>
              ) : null
            }
          >
            {node.children.map((node, index) => (
              <FormNode
                key={index}
                context={context}
                depth={depth + 1}
                node={node}
              />
            ))}
          </FormField>
        );
      }

      return (
        <Section
          heading={heading}
          subheading={subheading}
          rightContent={
            componentChildren?.[0] ? (
              // TODO(samuel): add support for multiple children for sync template UI
              <>
                <SyncTemplateLock node={componentChildren[0]} />
                <SyncOverride
                  childNodeKeys={componentChildren.map(({ key }) => key)}
                />
              </>
            ) : null
          }
          isOptional={node.optional}
        >
          <Form>
            {node.children.map((node, index) => (
              <FormNode
                key={index}
                context={context}
                depth={depth + 1}
                node={node}
              />
            ))}
          </Form>
        </Section>
      );
    }

    if (node.layout === LayoutType.Form) {
      return (
        <>
          {node.children.map((node, index) => (
            <FormNode
              key={index}
              context={context}
              depth={depth + 1}
              node={node}
            />
          ))}
        </>
      );
    }
  }

  if (node.type === NodeType.Component) {
    return (
      <Node node={node}>
        {node.children?.map((node, index) => (
          <FormNode
            key={index}
            context={context}
            depth={depth + 1}
            node={node}
          />
        ))}
      </Node>
    );
  }

  if (node.type === NodeType.Modifier) {
    return (
      <Modifier node={node}>
        {node.children.map((node, index) => (
          <FormNode
            key={index}
            context={context}
            depth={depth + 1}
            node={node}
          />
        ))}
      </Modifier>
    );
  }

  return null;
};

type ProcessFormNodeProps = {
  node: FormkitNode;
  depth?: number;
  context?: Record<string, unknown>;
};

export const ProcessFormNode: FC<ProcessFormNodeProps> = ({
  node,
  depth = 0,
  context = {},
}) => {
  return <FormNode context={context} depth={depth} node={node} />;
};

function parseHeadings(
  node: FormkitSection,
  context: Record<string, unknown>,
): { heading: string; subheading: string } {
  const engine =
    hasLiquid(node.heading) || hasLiquid(node.subheading)
      ? getLiquidEngine()
      : null;
  const heading =
    node.heading && engine
      ? engine.parseAndRenderSync(node.heading, context)
      : node.heading
        ? node.heading
        : "";
  const subheading =
    node.subheading && engine
      ? engine.parseAndRenderSync(node.subheading, context)
      : node.subheading
        ? node.subheading
        : "";
  return { heading, subheading };
}

/**
 * Show card if this is a sync attached to a template and
 * 1. The field is unlocked or
 * 2. At least one child is unlocked
 */
function hasVisibleChild(
  node: FormkitNode,
  overrideConfig: OverrideConfig | null | undefined,
) {
  if (!overrideConfig) {
    return true;
  }

  if (node.type === NodeType.Component) {
    return Boolean(get(overrideConfig, node.key)?.overridable);
  }

  return node.children.some((child) => hasVisibleChild(child, overrideConfig));
}
