import { Strike } from "@tiptap/extension-strike";
import { markInputRule, markPasteRule } from "@tiptap/react";

export const inputRegex = /(?:^|\s)((?:~)((?:[^~]+))(?:~))$/;
export const pasteRegex = /(?:^|\s)((?:~)((?:[^~]+))(?:~))/g;

export const SlackStrike = Strike.extend({
  addOptions() {
    return { HTMLAttributes: { class: "rte-slack-strike-class" } };
  },
  addInputRules() {
    return [markInputRule({ type: this.type, find: inputRegex })];
  },
  addPasteRules() {
    return [markPasteRule({ type: this.type, find: pasteRegex })];
  },
});
