import { FC, useRef } from "react";

import {
  BreakdownIcon,
  Button,
  Column,
  FunnelIcon,
  GroupedSelect,
  PerformanceIcon,
  PlusIcon,
  RefreshIcon,
  Row,
  SettingsIcon,
  TableIcon,
  useToast,
} from "@hightouchio/ui";
import { Link } from "src/router";
import { Controller, useFormContext } from "react-hook-form";
import { isPresent } from "ts-extras";

import { PageSidebar, SidebarKey } from "src/components/layout/page-sidebar";
import {
  PermissionedButton,
  PermissionedLinkButton,
  usePermissionContext,
} from "src/components/permission";
import * as analytics from "src/lib/analytics";
import { useAnalyticsContext } from "src/pages/analytics/state";
import { DEFAULT_GROUP_BY_COLUMNS } from "src/pages/analytics/state/constants";
import { ChartFormState, GraphType } from "src/pages/analytics/types";

import { GroupBy } from "./group-by";
import { Metrics } from "./metrics";
import { SegmentBy } from "./segment-by";
import { Steps } from "./steps";
import { Measurements } from "./measurements";

type AnalyticsSidebarProps = {
  funnelsErrors: Record<string, string>;
  isLoading?: boolean;
  isSavedView: boolean;
  metricSeriesErrors: Record<string, string>;
  ignoreHeaderHeight?: boolean;
};

export const AnalyticsSidebar: FC<AnalyticsSidebarProps> = ({
  funnelsErrors,
  isSavedView,
  metricSeriesErrors,
  ignoreHeaderHeight = false,
}) => {
  const { toast } = useToast();
  const { unauthorized } = usePermissionContext();

  const {
    parentModelLoading,
    parentModelOptionsLoading,
    parentModelOptions,

    resetView,
    selectParentModel,
  } = useAnalyticsContext();

  const form = useFormContext<ChartFormState>();

  const parentModelId = form.watch("parentModelId");
  const graphType = form.watch("graphType");
  const selectedAudiences = form.watch("selectedAudiences");

  const graphOptions = [
    {
      label: "Performance",
      value: GraphType.Performance,
      icon: (props) => <PerformanceIcon {...props} color="text.secondary" />,
    },
    {
      label: "Breakdown",
      value: GraphType.Breakdown,
      icon: (props) => <BreakdownIcon {...props} color="text.secondary" />,
    },
    {
      label: "Funnel",
      value: GraphType.Funnel,
      icon: (props) => <FunnelIcon {...props} color="text.secondary" />,
    },
    {
      label: "Table",
      value: GraphType.Table,
      icon: (props) => <TableIcon {...props} color="text.secondary" />,
    },
  ];

  const audiencesRef = useRef(selectedAudiences);
  audiencesRef.current = selectedAudiences;

  return (
    <>
      <PageSidebar
        ignoreHeaderHeight={ignoreHeaderHeight}
        stateKey={SidebarKey.Analytics}
        width="346px"
        contentStyles={{
          height: "100%",
          p: 0,
        }}
        header={
          <Column gap={2} width="100%">
            <GroupedSelect
              isLoading={parentModelLoading || parentModelOptionsLoading}
              isDisabled={
                unauthorized || parentModelLoading || parentModelOptionsLoading
              }
              optionAccessory={(option) => ({
                type: "image",
                url: option.connection?.definition.icon ?? "",
              })}
              placeholder="Select a parent model..."
              onChange={(value) => {
                if (!isPresent(value)) {
                  return;
                }

                const option = parentModelOptions.find(
                  (option) => option.value === value,
                );

                analytics.track("Parent Model Selected", {
                  parent_model_id: value,
                  parent_model_name: option?.label,
                  parent_model_source_name: option?.connection?.name,
                  parent_model_source_type: option?.connection?.definition.name,
                });

                selectParentModel(value, option?.label);
              }}
              optionGroups={[
                { label: "Parent Models", options: parentModelOptions },
              ]}
              value={parentModelId}
            />

            <Controller
              control={form.control}
              name="graphType"
              render={({ field }) => (
                <GroupedSelect
                  value={field.value}
                  optionAccessory={(option) => ({
                    type: "icon",
                    icon: option.icon,
                  })}
                  optionGroups={[
                    { label: "Chart Types", options: graphOptions },
                  ]}
                  onChange={(value) => {
                    field.onChange(value ?? GraphType.Performance);
                    if (value === GraphType.Funnel) {
                      // TODO(samuel): may be good to centralize this in context
                      form.setValue("groupByColumns", DEFAULT_GROUP_BY_COLUMNS);
                    }
                  }}
                />
              )}
            />
          </Column>
        }
      >
        <Column justify="space-between" height="100%">
          <Column gap={4} p={4} overflow="auto" flex={1} minHeight={0}>
            {graphType === GraphType.Funnel && <Steps />}

            {graphType !== GraphType.Funnel && (
              <>
                <Measurements />
                <Metrics metricSeriesErrors={metricSeriesErrors} />
              </>
            )}

            <SegmentBy
              funnelsErrors={funnelsErrors}
              metricSeriesErrors={metricSeriesErrors}
            />

            <GroupBy />
          </Column>

          <Column borderTop="1px solid" borderColor="base.border" px={6} py={4}>
            <Row align="center" justify="space-between">
              <PermissionedLinkButton
                permission={{
                  v1: { resource: "audience", grant: "preview" },
                  v2: {
                    resource: "model",
                    grant: "can_preview",
                    id: parentModelId ?? "",
                  },
                }}
                href="/metrics"
                icon={SettingsIcon}
                variant="tertiary"
              >
                Manage metrics
              </PermissionedLinkButton>
              {/* TODO(samuel): figure out what to do here to make more generic */}
              {isSavedView ? (
                <Link href="/analytics" isExternal>
                  <Button icon={PlusIcon} variant="tertiary">
                    New chart
                  </Button>
                </Link>
              ) : (
                <PermissionedButton
                  permission={{
                    v1: { resource: "audience", grant: "preview" },
                    v2: {
                      resource: "model",
                      grant: "can_preview",
                      id: parentModelId ?? "",
                    },
                  }}
                  icon={RefreshIcon}
                  isDisabled={!form.formState.isDirty}
                  variant="tertiary"
                  onClick={() => {
                    resetView(graphType);
                    toast({
                      id: "metric-form-reset",
                      title: "View reset",
                      variant: "success",
                    });
                  }}
                >
                  Reset view
                </PermissionedButton>
              )}
            </Row>
          </Column>
        </Column>
      </PageSidebar>
    </>
  );
};
