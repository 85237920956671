import get from "lodash/get";

import { isMapping } from "src/formkit/components/types";
import { OverrideConfig } from "./types";

// Used to remove a mapping where the destination and source columns have not been selected
// For association mappings, custom mappings, mappings, etc.
function cleanMappings(mappings) {
  const cleanedMappings: any[] = [];

  for (const mapping of mappings) {
    // For object inline mapper
    if (Array.isArray(mapping.from)) {
      const cleanedNestedMappings = cleanMappings(mapping.from);
      if (cleanedNestedMappings.length > 0) {
        cleanedMappings.push({ ...mapping, from: cleanedNestedMappings });
      }
      // For array inline mapper
    } else if (Array.isArray(mapping.children)) {
      const cleanedNestedMappings = cleanMappings(mapping.children);
      if (cleanedNestedMappings.length > 0) {
        cleanedMappings.push({ ...mapping, children: cleanedNestedMappings });
      }
    } else if (!Object.keys(mapping).every((key) => key === "type")) {
      cleanedMappings.push(mapping);
    }
  }

  return cleanedMappings;
}

export function cleanConfig(config) {
  for (const key in config) {
    const value = config[key];
    if (Array.isArray(value) && value.every(isMapping)) {
      config[key] = cleanMappings(value);
    } else if (value !== null && typeof value === "object") {
      cleanConfig(value);
    }
  }
  return config;
}

// We don't want to send back "REDACTED" values. Otherwise, we are saving/testing the new values as "REDACTED" and the test connection fails
export function cleanRedactedConfig(config) {
  const redactedFields = ["REDACTED"];

  for (const key in config) {
    if (redactedFields.includes(config[key])) {
      delete config[key];
    } else if (typeof config[key] === "object") {
      cleanRedactedConfig(config[key]);
    }
  }

  return config;
}

/**
 * Strip the properties with undefined values from the config
 */
export function keepDefined(config: any) {
  if (!config || typeof config !== "object") {
    return config;
  }

  return Object.fromEntries(
    Object.entries(config).filter(([, v]) => v !== undefined),
  );
}

/**
 * Check if a field is editable
 */
export function isFieldLocked(
  overrideConfig: OverrideConfig | null | undefined,
  key: string,
) {
  return !get(overrideConfig, key)?.overridable;
}

/**
 * Check if there are any unlocked fields in the override config
 */
export function hasUnlockedFields(
  overrideConfig: OverrideConfig | null | undefined,
) {
  if (!overrideConfig) {
    return false;
  }

  for (const value of Object.values(overrideConfig)) {
    if (value.overridable) {
      return true;
    }
  }

  return false;
}

/**
 * Clean the overrides by specifying only fields that are locked
 * If keys are not provided, all fields will be included
 */
export function cleanOverrides(
  overrides: OverrideConfig | null,
  keys?: string[],
): OverrideConfig | null {
  if (!overrides) {
    return null;
  }

  // Only include keys that are in the config object
  const keysSet = new Set(keys ?? Object.keys(overrides));

  return Object.entries(overrides).reduce((all, [key, override]) => {
    if (override.overridable && keysSet.has(key)) {
      all[key] = { overridable: true };
    }

    return all;
  }, {} as OverrideConfig);
}
