import { FC, useEffect, useState } from "react";

import { SearchInput } from "@hightouchio/ui";
import { useSearchBox } from "react-instantsearch-hooks-web";

type Props = {
  placeholder: string;
};

export const AlgoliaSearchInput: FC<Props> = (props) => {
  const { query, refine } = useSearchBox();

  const [input, setInput] = useState<string>(query);

  useEffect(() => {
    refine(input);
  }, [input]);

  return (
    <SearchInput
      {...props}
      size="lg"
      width="100%"
      value={input}
      onChange={(e) => setInput(e.target.value)}
    />
  );
};
