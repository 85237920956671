export type GrantableResource = "source" | "destination" | "parent_model";

export const RESOURCE_GRANTS = {
  source: [
    "can_draft",
    "can_sync",
    "can_edit",
    "can_manage_schema",
    "can_access_data",
  ] as const,
  destination: ["can_draft", "can_sync", "can_edit", "can_run"] as const,
  parent_model: ["can_access_data", "can_sync"] as const,
} as const satisfies Record<GrantableResource, readonly string[]>;

export const RESOURCE_GRANT_OPTIONS = (approvalsRequired: boolean) =>
  ({
    source: approvalsRequired
      ? [
          {
            label: "View source data",
            value: "can_access_data",
            description:
              "Access row-level customer data from this source via model previews, sync debugger, etc.",
          },
          {
            label: "Draft models & syncs",
            value: "can_draft",
            description: `Create drafts for models and syncs that rely on this source. These drafts must be approved by someone with the corresponding "Approve" permission.`,
          },
          {
            label: "Approve models & syncs",
            value: "can_sync",
            description: `Approve drafts for models and syncs that rely on this source. (Note: users must have both the "Draft" and "Approve" permissions to publish a model or sync. In other words, "Approve" is not a superset of "Draft".)`,
          },
          {
            label: "Configure schema",
            value: "can_manage_schema",
            description:
              "Set up or modify Customer Studio schema resources, such as parent models, traits, and sync templates.",
          },
          {
            label: "Manage source",
            value: "can_edit",
            description:
              "Manage the configuration, credentials, and permissions for this source.",
          },
        ]
      : [
          {
            label: "View source data",
            value: "can_access_data",
            description:
              "Access row-level customer data from the source via model previews, sync debugger, etc.",
          },
          {
            label: "Configure models & syncs",
            value: "can_sync",
            description:
              "Configure models and syncs that rely on this source. This includes control over the sync configuration, model query, sync alerts, and other sync-level settings.",
          },
          {
            label: "Configure schema",
            value: "can_manage_schema",
            description:
              "Set up or modify Customer Studio schema resources, such as parent models, traits, and sync templates.",
          },
          {
            label: "Manage source",
            value: "can_edit",
            description:
              "Manage the configuration, credentials, and permissions for this source.",
          },
        ],
    destination: approvalsRequired
      ? [
          {
            label: "Trigger syncs",
            value: "can_run",
            description: "Manually trigger syncs to this destination.",
          },
          {
            label: "Draft models & syncs",
            value: "can_draft",
            description: `Create drafts for models and syncs that rely on this destination. These drafts must be approved by someone with the corresponding "Approve" permission.`,
          },
          {
            label: "Approve models & syncs",
            value: "can_sync",
            description: `Approve drafts for models and syncs that rely on this destination. (Note: users must have both the "Draft" and "Approve" permissions to publish a model or sync. In other words, "Approve" is not a superset of "Draft".)`,
          },
          {
            label: "Manage destination",
            value: "can_edit",
            description:
              "Manage the configuration, credentials, and permissions for this destination.",
          },
        ]
      : [
          {
            label: "Trigger syncs",
            value: "can_run",
            description: "Manually trigger syncs to this destination.",
          },
          {
            label: "Configure models & syncs",
            value: "can_sync",
            description:
              "Configure models and syncs that rely on this destination. This includes control over the sync configuration, model query, sync alerts, and other sync-level settings.",
          },
          {
            label: "Manage destination",
            value: "can_edit",
            description:
              "Manage the configuration, credentials, and permissions for this destination.",
          },
        ],
    parent_model: [
      {
        label: "View parent model data",
        value: "can_access_data",
        description:
          "Access row-level customer data from this parent model via audience previews, sync debugger, etc.",
      },
      {
        label: "Configure audiences & syncs",
        value: "can_sync",
        description:
          "Create, edit, or delete audiences that pull data from the parent model, and use these audiences for syncing (provided the user has permissions to sync data to destinations).",
      },
    ],
  }) satisfies {
    [key in GrantableResource]: readonly {
      label: string;
      value: ResourceGrantsFor<key>;
      description?: string;
    }[];
  };

export type ResourceGrantsFor<R extends GrantableResource> =
  (typeof RESOURCE_GRANTS)[R][number];

export type GrantObject<R extends GrantableResource> = Record<
  ResourceGrantsFor<R>,
  boolean
>;

export type Grants<R extends GrantableResource> = Record<
  string,
  GrantObject<R>
>;
