import { FC } from "react";

import round from "lodash/round";

const styleAttrs = {
  fontSize: "13px",
  fontWeight: 600,
};

type LabelProps = {
  viewBox?: { x: number; y: number; width: number; height: number };
  offset?: number;
  parentViewBox?: boolean;
  value?: number;
};

export const Label: FC<LabelProps> = ({
  offset = 0,
  parentViewBox,
  value,
  viewBox = { x: 0, y: 0, width: 0, height: 0 },
}) => {
  if (value === undefined) {
    return null;
  }

  const { x, y, width, height } = viewBox;
  const verticalSign = height >= 0 ? 1 : -1;
  const verticalOffset = verticalSign * offset;

  const sizeAttrs = parentViewBox ? { width, height } : {};

  const positionAttrs = {
    x: x + width / 2,
    y: y + verticalOffset,
    dy: value < 0.15 ? -16 : 16,
    textAnchor: "middle",
    fill: value < 0.15 ? "black" : "white",
    ...sizeAttrs,
  };

  return value || value === 0 ? (
    <text {...positionAttrs} {...styleAttrs}>
      {round(value * 100, 2)}%
    </text>
  ) : null;
};
