import orderBy from "lodash/orderBy";

import {
  JourneyNode,
  JourneyNodeDetails,
  SegmentPriorityList,
} from "src/pages/journeys/types";
import { isSegmentBranchNodeDetails } from "src/pages/journeys/utils";
import { SegmentBranchConfig } from "src/types/journeys";
import {
  AndOrCondition,
  ConditionType,
  PropertyCondition,
} from "src/types/visual";

/**
 * Ensure that the property conditions are wrapped in a top level AND condition
 */
export const getFormattedSubconditions = (
  subcondition: AndOrCondition<PropertyCondition>,
): AndOrCondition<PropertyCondition> => {
  // If top level condition is an AND condition, return
  if (subcondition.type === ConditionType.And) {
    return subcondition;
  }

  return {
    type: ConditionType.And,
    conditions: [subcondition],
  };
};

export const getOrderedEditableSegments = (
  nodes: JourneyNode[],
): SegmentPriorityList => {
  const nodeDetails = nodes.map(({ data }) => data);

  const segmentBranchNodes: JourneyNodeDetails<SegmentBranchConfig>[] =
    nodeDetails.filter(isSegmentBranchNodeDetails);

  const branchNodes = orderBy(
    segmentBranchNodes
      // Catch all node is omitted
      .filter((node) => !node.config.segment_is_catch_all),
    ["config.segment_priority_rank"],
    ["asc"],
  );

  return branchNodes.map((node) => ({
    id: node.id,
    name: node.name,
  }));
};
