import { Heading, Row } from "@hightouchio/ui";
import { Page } from "src/components/layout";
import { RouteTabs } from "src/components/route-tabs";
import { useEntitlements } from "src/hooks/use-entitlement";
import { Outlet } from "src/router";

export const MetricsLayout = () => {
  const { data: entitlementsData } = useEntitlements(false);
  const campaignsEnabled = entitlementsData.entitlements.campaigns;

  return (
    <Page title="Metrics" fullWidth>
      <Row mb={campaignsEnabled ? 4 : 0}>
        <Heading size="xl">Metrics</Heading>
      </Row>

      {campaignsEnabled && (
        <RouteTabs
          tabs={[
            {
              title: "Metrics",
              path: "",
            },
            ...(campaignsEnabled
              ? [
                  {
                    title: "Attribution methods",
                    path: "attributions",
                  },
                ]
              : []),
          ]}
        />
      )}

      <Outlet />
    </Page>
  );
};
