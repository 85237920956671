import { FC, useState } from "react";

import { useNavigate } from "src/router";
import { Column } from "@hightouchio/ui";
import {
  HandleProps,
  NodeProps,
  Position,
  Handle as ReactflowHandle,
} from "reactflow";

import { getParams } from "src/pages/schema/graph/utils";
import { SchemaModelType } from "src/types/schema";
import { Menu } from "./menu";
import { MenuButton } from "./menu-button";
import { useCanUpdateSchema } from "./utils";

import { useGraphContext } from "./context";
import { Node } from "./node";

const NodeGenerator = ({ type }: { type: SchemaModelType }) => {
  const node = (node: NodeProps): JSX.Element => {
    const { id, data } = node;
    const navigate = useNavigate();
    const [showAdd, setShowAdd] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const { queryString } = getParams();
    const { setHighlight, highlight, highlightedNodes } = useGraphContext();
    const isActive = showAdd || showMenu;
    const isDimmed = Boolean(highlight && !highlightedNodes.includes(id));

    const hasUpdatePermission = useCanUpdateSchema();

    return (
      <Column
        display="contents"
        onMouseEnter={() => {
          setShowAdd(true);
          setHighlight(node);
        }}
        onMouseLeave={() => {
          if (!showMenu) {
            setShowAdd(false);
          }
          setHighlight(null);
        }}
      >
        <Handle
          isDimmed={isDimmed}
          isVisible={data.isTarget}
          type="target"
          position={Position.Left}
        />
        <Handle
          isDimmed={isDimmed}
          isVisible={data.isSource}
          type="source"
          position={Position.Right}
        />
        <Node
          node={node}
          isActive={isActive}
          isDimmed={isDimmed}
          type={type}
          onClick={() => {
            if (type !== SchemaModelType.Group) {
              navigate({
                pathname: "view/query",
                search: `${queryString}&id=${id}`,
              });
            }
          }}
        >
          {isActive && type !== SchemaModelType.Group && (
            <MenuButton
              isActive={showMenu}
              onClick={() => {
                setShowMenu((state) => !state);
              }}
            />
          )}
          <Menu
            type={type}
            isDisabled={!hasUpdatePermission}
            isVisible={showMenu}
            onChange={async (option) => {
              navigate({
                pathname: "new",
                search: `${queryString}&id=${id}&type=${option.type}`,
              });
            }}
            onClose={() => {
              setShowMenu(false);
              setShowAdd(false);
            }}
          />
        </Node>
      </Column>
    );
  };

  return node;
};

export const ParentNode = NodeGenerator({ type: SchemaModelType.Parent });
export const EventNode = NodeGenerator({ type: SchemaModelType.Event });
export const GroupNode = NodeGenerator({ type: SchemaModelType.Group });
export const AdStatsNode = NodeGenerator({ type: SchemaModelType.AdStats });
export const RelatedNode = NodeGenerator({ type: SchemaModelType.Related });
export const CatalogNode = NodeGenerator({ type: SchemaModelType.Catalog });
export const InteractionNode = NodeGenerator({
  type: SchemaModelType.Interaction,
});
export const AssetNode = NodeGenerator({
  type: SchemaModelType.Asset,
});

export const Handle: FC<
  Readonly<HandleProps & { isVisible?: boolean; isDimmed?: boolean }>
> = ({ isVisible, isDimmed, ...props }) => {
  return (
    <ReactflowHandle
      {...props}
      style={{
        visibility: isVisible ? "visible" : "hidden",
        transition: "opacity 200ms ease-in-out",
        opacity: isDimmed ? 0.25 : 1,
        backgroundColor: "white",
        width: "8px",
        height: "8px",
        zIndex: 1,
        border: "2px solid var(--chakra-colors-teal-400)",
        left: props.position === Position.Left ? 0 : undefined,
        right: props.position === Position.Right ? 0 : undefined,
        transform:
          props.position === Position.Left
            ? "translate(-50%, -50%)"
            : "translate(50%, -50%)",
      }}
    />
  );
};
