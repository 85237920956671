import { AudienceIcon } from "@hightouchio/ui";
import pluralize from "pluralize";

import { useGraphContext } from "src/pages/journeys/graph";
import { JourneyNodeDetails } from "src/pages/journeys/types";
import { SegmentsConfig } from "src/types/journeys";

import { DescriptionRow } from "./description-row";
import { EmptyStateDescription } from "./empty-state-description";

export const SegmentNodeDescription = ({
  id,
}: JourneyNodeDetails<SegmentsConfig>) => {
  const { getBranchNodes } = useGraphContext();
  const segments = getBranchNodes(id);

  return segments.length === 1 ? (
    <EmptyStateDescription />
  ) : (
    <DescriptionRow icon={AudienceIcon}>
      {pluralize("segments", segments.length, true)}
    </DescriptionRow>
  );
};
