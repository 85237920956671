import { FC, useState } from "react";

import {
  CloseIcon,
  Column,
  ConfirmationDialog,
  DeleteIcon,
  DrawerBody,
  DrawerHeader,
  ExternalLinkIcon,
  IconButton,
  Menu,
  MenuActionsButton,
  MenuItem,
  MenuList,
  Paragraph,
  PlusIcon,
  Row,
  Text,
  useToast,
} from "@hightouchio/ui";
import { useFormContext } from "react-hook-form";
import { useNavigate, useSearchParams } from "src/router";

import capitalize from "lodash/capitalize";
import { useHightouchFormContext } from "src/components/form";
import {
  PermissionedButton,
  PermissionedEditableHeading,
  PermissionedMenuItem,
} from "src/components/permission";
import { SyncRunStatusBadge } from "src/components/syncs/sync-run-status-badge";
import { useSyncNodeQuery } from "src/graphql";
import {
  JOURNEY_DELETE_PERMISSION,
  JOURNEY_UPDATE_PERMISSION,
} from "src/pages/journeys/constants";
import { useGraphContext } from "src/pages/journeys/graph";
import { JourneyGraph, NodeDetailFormProps } from "src/pages/journeys/types";
import { getCreateJourneySyncParams } from "src/pages/journeys/utils";
import { SyncConfig } from "src/types/journeys";
import { Table } from "src/ui/table";
import { formatDatetime } from "src/utils/time";
import { openUrlInNewTab } from "src/utils/urls";

export const SyncForm: FC<NodeDetailFormProps<SyncConfig>> = ({
  id, // id techincally lives in data too, redundant to have both :/
  data,
  onClose,
}) => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [, setSearchParams] = useSearchParams();

  const { submit } = useHightouchFormContext();
  const { isEditMode, versionId, onRemoveSyncConfigFromNode, onUpdateNode } =
    useGraphContext();

  const [submitCount, setSubmitCount] = useState(0);
  const [confirmSave, showConfirmSave] = useState(false);
  const [syncToDelete, setSyncToDelete] = useState<string | null>(null);
  const parentForm = useFormContext<JourneyGraph>();

  const syncConfigIds = new Set(
    (data.sync_configs ?? []).map(({ destination_instance_id }) =>
      destination_instance_id.toString(),
    ),
  );

  // an array of nodes. Each node is the same, but each has a different sync
  const syncNodeQuery = useSyncNodeQuery(
    {
      journeyNodeId: id,
      journeyVersionId: versionId,
    },
    {
      enabled: Boolean(versionId),
      select: (data) => data.journey_syncs,
    },
  );

  const syncs = syncNodeQuery.data?.filter((syncNode) =>
    syncConfigIds.has(syncNode.sync?.id.toString()),
  );
  const segmentId = data.segment_id ?? "";

  const updateName = (newName: string) => {
    if (!newName) {
      toast({
        id: "update-error",
        title: "Name is required",
        variant: "error",
      });

      setSubmitCount(submitCount + 1);
    } else {
      onUpdateNode(id, { name: newName });

      toast({
        id: "tile-update",
        title: "Tile name updated",
        variant: "success",
      });
    }
  };

  const addNewSync = () => {
    if (parentForm.formState.isDirty) {
      // form needs to be saved first, so show confirmation dialog
      showConfirmSave(true);
    } else {
      // Node already exists so navigate to wizard
      navigate(
        `new-sync?${getCreateJourneySyncParams({
          segmentId,
        })}`,
      );
    }
  };

  const deleteSync = async () => {
    if (!syncToDelete) return;

    onRemoveSyncConfigFromNode(id, syncToDelete);

    // Remove the sync from the journey
    setSyncToDelete(null);

    toast({
      id: "tile-update",
      title: "Sync removed from tile",
      variant: "info",
    });
  };

  const goToSync = (syncId: string) => {
    openUrlInNewTab(`/syncs/${syncId}`);
  };

  return (
    <>
      <DrawerHeader>
        <Row align="center" justify="space-between" flex={1} minWidth={0}>
          <PermissionedEditableHeading
            isDisabled={!isEditMode}
            // Key forces remount if submission fails
            key={submitCount}
            permission={JOURNEY_UPDATE_PERMISSION}
            value={data.name}
            onChange={updateName}
          />
          <IconButton
            aria-label="Close drawer."
            icon={CloseIcon}
            onClick={onClose}
          />
        </Row>
      </DrawerHeader>

      <DrawerBody>
        <Column minHeight={0} flex={1} gap={6} pb={4}>
          <Row align="center" justify="space-between">
            <Text fontWeight="medium" size="lg">
              Syncs
            </Text>
            <PermissionedButton
              isDisabled={!isEditMode}
              permission={JOURNEY_UPDATE_PERMISSION}
              icon={PlusIcon}
              onClick={addNewSync}
            >
              Add sync
            </PermissionedButton>
          </Row>

          <Table
            columns={[
              {
                name: "Last run",
                max: "150px",
                headerSx: { pl: "16px !important" },
                cellSx: { pl: "16px !important" },
                cell: ({ sync }) => (
                  <Column gap={2}>
                    <SyncRunStatusBadge status={sync?.status} />
                    {sync?.last_run_at && (
                      <Text color="text.secondary" size="sm">
                        {formatDatetime(sync.last_run_at)}
                      </Text>
                    )}
                  </Column>
                ),
              },
              {
                name: "Destination",
                min: "250px",
                cell: ({ sync }) => (
                  <Row align="center" gap={2}>
                    {sync?.destination?.definition.icon && (
                      <img
                        src={sync.destination.definition.icon}
                        alt={sync?.destination?.definition.name}
                        height="24px"
                        width="24px"
                      />
                    )}
                    <Text>{sync?.destination?.definition.name}</Text>
                  </Row>
                ),
              },
              {
                name: "Type",
                cell: ({ mode }) => <Text>{capitalize(mode)}</Text>,
              },
              {
                max: "80px",
                cell: ({ sync }) =>
                  sync ? (
                    <Menu>
                      <MenuActionsButton
                        onClick={(event) => event.stopPropagation()}
                      />

                      <MenuList>
                        {sync && (
                          <MenuItem
                            icon={ExternalLinkIcon}
                            onClick={(event) => {
                              event.stopPropagation();
                              goToSync(sync.id);
                            }}
                          >
                            Go to sync details
                          </MenuItem>
                        )}
                        <PermissionedMenuItem
                          isDisabled={!isEditMode}
                          permission={JOURNEY_DELETE_PERMISSION}
                          icon={DeleteIcon}
                          variant="danger"
                          onClick={(event) => {
                            event.stopPropagation();
                            setSyncToDelete(sync.id.toString());
                          }}
                        >
                          Delete sync
                        </PermissionedMenuItem>
                      </MenuList>
                    </Menu>
                  ) : null,
              },
            ]}
            loading={syncNodeQuery.isLoading}
            data={syncs}
            rowHeight="74px"
            placeholder={{
              title: "This tile contains no syncs",
              body: "Add a sync to send users to a destination or trigger a campaign when they reach this step in the journey.",
              error: "Audiences failed to load, please try again.",
            }}
            onRowClick={({ sync }) => setSearchParams({ sync: sync?.id })}
          />
        </Column>
      </DrawerBody>

      <ConfirmationDialog
        isOpen={confirmSave}
        title="Unsaved changes"
        onConfirm={async () => {
          await submit();
          showConfirmSave(false);
        }}
        confirmButtonText="Save changes"
        variant="warning"
        onClose={() => showConfirmSave(false)}
      >
        <Paragraph>
          To add a sync to this journey, you must first save your changes.
        </Paragraph>
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={Boolean(syncToDelete)}
        title="Delete sync"
        onConfirm={deleteSync}
        confirmButtonText="Delete sync"
        variant="danger"
        onClose={() => setSyncToDelete(null)}
      >
        <Paragraph>
          This sync will be fully deleted when the journey changes are saved.
          Are you sure you want to delete this sync?
        </Paragraph>
      </ConfirmationDialog>
    </>
  );
};
