import { FC } from "react";

import {
  ConditionType,
  PropertyCondition,
  TraitCondition,
} from "@hightouch/lib/query/visual/types";
import { Box, Row, Text } from "@hightouchio/ui";

import { toSingleCondition } from "src/components/audiences/utils";

import { colors } from "./colors";
import {
  formatValue,
  getOperatorLabel,
  getPropertyNameFromProperty,
} from "./utils";

type Props = {
  conditions: PropertyCondition[] | TraitCondition[];
};

export const PropertyConditions: FC<Readonly<Props>> = ({
  conditions: _conditions,
}) => {
  const conditions = toSingleCondition(_conditions) ?? [];

  if (conditions.length === 0) {
    return null;
  }

  const topLevelConditionType = conditions[0]?.type || ConditionType.And;
  const subconditions = conditions[0]?.conditions;

  return (
    <>
      {subconditions?.map((condition, index) => (
        <Row key={index} gap={1} alignItems="baseline">
          {index === 0 ? (
            <Text color="text.secondary">Where</Text>
          ) : (
            <Box
              as={Text}
              bg={colors.base[topLevelConditionType]}
              borderRadius={6}
              minWidth={0}
              p={1}
              textTransform="uppercase"
              fontWeight="medium"
            >
              {topLevelConditionType}
            </Box>
          )}

          <Row as={Text} gap={1} color="text.secondary" flexWrap="wrap">
            <Text color="text.primary" fontWeight="medium">
              {getPropertyNameFromProperty(condition.property)}
            </Text>{" "}
            {getOperatorLabel(
              condition.operator,
              condition.propertyType,
              Boolean(condition.propertyOptions?.parameterize),
            )}{" "}
            {formatValue(condition, condition.value, {
              showParameterizedLabel: true,
            })}
          </Row>
        </Row>
      ))}
    </>
  );
};
