import { PlusIcon, Row } from "@hightouchio/ui";
import { FC } from "react";
import { NODE_HEIGHT } from "src/pages/schema/graph/utils";
import { CHILD_NODE_HEIGHT } from "./utils";

export const MenuButton: FC<{
  onClick: () => void;
  isActive: boolean;
  position?: number;
}> = ({ onClick, isActive, position }) => {
  return (
    <Row
      position="absolute"
      right={0}
      top={
        position !== undefined
          ? `${NODE_HEIGHT + position * CHILD_NODE_HEIGHT - 24}px`
          : "50%"
      }
      width="32px"
      height="32px"
      borderRadius="50%"
      bg="white"
      justify="center"
      align="center"
      border="1px"
      borderColor="base.border"
      outline="3px solid"
      outlineColor={isActive ? "teal.200" : "transparent"}
      transform={
        isActive ? "translate(50%,-50%) rotate(45deg)" : "translate(50%,-50%)"
      }
      transition="transform 0.2s, outline-color 0.2s, background-color 0.2s"
      zIndex={2}
      _hover={{
        bg: "teal.50",
      }}
      onClick={(event) => {
        event.stopPropagation();
        onClick();
      }}
    >
      <PlusIcon color="teal.base" />
    </Row>
  );
};
