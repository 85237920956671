import { StatusIndicator } from "@hightouchio/ui";
import { ActiveSyncProgressIndicator } from "src/pages/syncs/sync/components/active-sync-progress-indicator";
import {
  SyncRunStatus,
  SyncRunStatusOrUnknownStatus,
  SyncStatusToText,
  UnknownSyncRunStatus,
  isActiveSyncStatus,
  syncRunStatusToIndicatorVariant,
} from "src/utils/syncs";

const syncStatusToIndicatorText = (
  status: SyncRunStatusOrUnknownStatus,
): string => {
  switch (status) {
    case SyncRunStatus.SUCCESS:
      return "Completed";
    case SyncRunStatus.WARNING:
      return "Completed with errors";
    case SyncRunStatus.FAILED:
      return "Failed";
    case SyncRunStatus.CANCELLED:
      return "Cancelled by user";
    case SyncRunStatus.INTERRUPTED:
      return "Interrupted";
    case SyncRunStatus.ENQUEUED:
    case SyncRunStatus.QUERYING:
    case SyncRunStatus.PROCESSING:
    case SyncRunStatus.REPORTING:
    case SyncRunStatus.ACTIVE:
    case SyncRunStatus.IN_PROGRESS:
    case SyncRunStatus.ABORTED:
    case SyncRunStatus.PENDING:
    case SyncRunStatus.PREPARING:
    case SyncRunStatus.UNPROCESSABLE:
    case SyncRunStatus.DISABLED:
    case SyncRunStatus.INCOMPLETE:
      return SyncStatusToText[status];
    case UnknownSyncRunStatus:
      return "Unknown status";
  }
};

export const SyncRunStatusIndicator = ({
  status,
  completionRatio,
}: {
  status: SyncRunStatusOrUnknownStatus;
  completionRatio: number | null;
}) => {
  if (status === UnknownSyncRunStatus) {
    return (
      <StatusIndicator variant="inactive" ml={1}>
        Unknown status
      </StatusIndicator>
    );
  }
  if (isActiveSyncStatus(status)) {
    return (
      <ActiveSyncProgressIndicator
        status={status}
        completionRatio={completionRatio}
      />
    );
  } else {
    return (
      <StatusIndicator variant={syncRunStatusToIndicatorVariant(status)} ml={1}>
        {syncStatusToIndicatorText(status)}
      </StatusIndicator>
    );
  }
};
