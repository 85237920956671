import { useCallback, useEffect, useLayoutEffect, useMemo } from "react";

import { useToast } from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Sentry from "@sentry/react";
import uniq from "lodash/uniq";
import { useNavigate, useParams } from "src/router";
import {
  Edge,
  IsValidConnection,
  MarkerType,
  NodeDragHandler,
  OnConnect,
  OnEdgesChange,
  OnNodesChange,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  getConnectedEdges,
  getIncomers,
  getOutgoers,
  useReactFlow,
  useStoreApi,
} from "reactflow";
import { isPresent } from "ts-extras";
import { v4 as uuidv4 } from "uuid";

import { useHightouchForm } from "src/components/form";
import { Schedule } from "src/components/schedule/types";
import { useRunJourneyMutation, useUpdateJourneyMutation } from "src/graphql";
import {
  DEFAULT_INTERVAL_SCHEDULE,
  JOURNEY_BRANCH_NODES,
  JOURNEY_NODES_WITH_BRANCH_NODES,
  NOOP_REACTFLOW_CHANGES,
  PARENT_NODE_TO_BRANCH_NODE,
} from "src/pages/journeys/constants";
import { JourneySchema } from "src/pages/journeys/forms/validation-schemas";
import { JourneyLineType } from "src/pages/journeys/reactflow-types";
import {
  ExitCriteria,
  JourneyGraph,
  JourneyNode,
  JourneyNodeDetails,
  SyncConfigDetails,
} from "src/pages/journeys/types";
import {
  getBranchNodes,
  getCenteredNodePosition,
  getCreateJourneySyncParams,
  getInitialBranchNodes,
  getInitialNodeConfiguration,
  isSegmentBranchNodeDetails,
  reposition,
  transformJourneyGraphToPayload,
  updateSegmentPriority,
} from "src/pages/journeys/utils";
import {
  JourneyNodeConfig,
  JourneyNodeType,
  JourneyStatus,
  SegmentBranchConfig,
} from "src/types/journeys";
import { ConditionType } from "src/types/visual";

const edgeProps = {
  markerEnd: {
    type: MarkerType.ArrowClosed,
  },
  type: JourneyLineType.Arrow,
};

type UseJourneyGraph = {
  id: string;
  name: string;
  description: string | null;
  exitCriteria: ExitCriteria;
  status: JourneyStatus;
  schedule: Schedule;
  selectedNodeId?: string;
  nodes: JourneyNode[];
  edges: Edge[];
  onSave?: () => void;
};

export const useJourneyGraph = ({
  id,
  name,
  description,
  exitCriteria,
  status,
  schedule,
  selectedNodeId,
  nodes: initialNodes,
  edges: initialEdges,
  onSave,
}: UseJourneyGraph) => {
  const { node_id } = useParams<{
    node_id?: string;
  }>();

  const { toast } = useToast();
  const navigate = useNavigate();

  const updateJourneyMutation = useUpdateJourneyMutation();
  const runJourneyMutation = useRunJourneyMutation();

  const saveJourney = async (data: JourneyGraph) => {
    const {
      updateJourney: {
        result: { nodes },
        id_map,
      },
    } = await updateJourneyMutation.mutateAsync({
      journey_graph: transformJourneyGraphToPayload(data),
    });

    const basePath = `/journeys/${id}`;

    const currentPath = location.pathname.split("/").pop();

    onSave?.();

    if (currentPath === "new-sync") {
      // new sync was just created, so navigate to current node
      navigate(`${basePath}/${node_id}`);
    } else if (node_id) {
      // If there was a node selected when saving, it was a sync node.
      // In this case, we need to navigate to the create sync flow.
      // (would be better to do it in the sync form itself)
      const newNodeId = id_map[node_id];
      const syncNode = nodes.find((node) => node.id === newNodeId);

      if (syncNode) {
        syncNode.segment_id
          ? navigate(
              `${basePath}/${newNodeId}/new-sync?${getCreateJourneySyncParams({
                segmentId: syncNode.segment_id,
              })}`,
            )
          : navigate(`${basePath}/${newNodeId}`);
      }
    } else {
      navigate(basePath);
    }
  };

  const runJourney = async () => {
    try {
      await runJourneyMutation.mutateAsync({ id });
      toast({
        id: "journey-run",
        title: "Journey run triggered successfully",
        variant: "success",
      });
    } catch (error) {
      Sentry.captureException(error);

      toast({
        id: "journey-run",
        title: "Failed to trigger a run of the journey",
        variant: "error",
      });
    }
  };

  const form = useHightouchForm<JourneyGraph>({
    onSubmit: saveJourney,
    success: "Journey saved successfully",
    error: "Failed to save journey",
    resolver: yupResolver(JourneySchema),
    onError: (error) => {
      // Only log to sentry if not a validation error.
      if (!error?.nodes) {
        Sentry.captureException(error);
      }

      toast({
        id: "save-error",
        title: "Failed to save journey",
        message: "Check the tiles outlined in red and try again.",
        variant: "error",
      });

      if (node_id) {
        // Focus entire journey if node selected
        navigate(`/journeys/${id}`);
      }
    },
    values: {
      journey: {
        id,
        name,
        description,
        status,
        schedule: schedule ?? DEFAULT_INTERVAL_SCHEDULE,
        exitCriteria,
      },
      nodes: [],
      edges: [],
    },
  });

  const store = useStoreApi();
  const { fitView, setCenter, zoomIn, zoomOut, getZoom } = useReactFlow();

  const onUpdateJourneyStatus = async (
    journeyStatus: JourneyStatus,
    hardStop = false,
  ) => {
    const isStarting = journeyStatus === JourneyStatus.Enabled;
    const startOrStopText = isStarting ? "start" : "stop";
    const isValid = await form.trigger();

    if (!isValid) {
      toast({
        id: "journey-save",
        title: `Failed to ${startOrStopText} journey`,
        message: "Check the tiles outlined in red and try again",
        variant: "error",
      });

      return;
    }

    try {
      await updateJourneyMutation.mutateAsync({
        journey_graph: {
          journey: {
            id,
            status: journeyStatus,
            archived: false, // archival was removed, so always set this to false for now.
            // TODO(samuel): Graphql type is incorrect. null should be allowed but default to in string for now
            description: description ?? "",
            name,
            schedule: schedule ?? DEFAULT_INTERVAL_SCHEDULE,
            force_remove_rows: hardStop,
          },
        },
      });

      toast({
        id: "journey-save",
        title: isStarting ? "Journey started" : `Journey stopped`,
        variant: "success",
      });
    } catch (error) {
      const isValidationError = error.message.includes("Validation");

      if (!isValidationError) {
        Sentry.captureException(error);
      }

      toast({
        id: "journey-save",
        title: `Failed to ${startOrStopText} journey`,
        // If it's a network error this error message is not helpful.
        message: isValidationError
          ? "Check the tiles outlined in red and try again."
          : error.message,
        variant: "error",
      });
    }
  };

  const onDeleteJourney = async () => {
    try {
      await updateJourneyMutation.mutateAsync({
        journey_graph: {
          journey: {
            id,
            // status must be disabled for the journey to be deleted
            status: JourneyStatus.Disabled,
            deleted: true, // Triggers journey deletion
            archived: false, // archival was removed, so always set this to false for now.
            // TODO(samuel): Graphql type is incorrect. null should be allowed but default to in string for now
            description: description ?? "",
            name,
            schedule: schedule ?? DEFAULT_INTERVAL_SCHEDULE,
          },
        },
      });

      toast({
        id: "journey-save",
        title: "Journey deleted",
        variant: "success",
      });

      navigate("/journeys");
    } catch (error) {
      Sentry.captureException(error);
      toast({
        id: "journey-save",
        title: "Failed to delete journey",
        variant: "error",
      });
    }
  };

  const onResetJourney = async () => {
    try {
      await updateJourneyMutation.mutateAsync({
        journey_graph: {
          journey: {
            id,
            // status must be disabled for the journey to be reset
            status: JourneyStatus.Disabled,
            deleted: false,
            archived: false, // archival was removed, so always set this to false for now.
            reset: true, // triggers the reset
            // TODO(samuel): Graphql type is incorrect. null should be allowed but default to in string for now
            description: description ?? "",
            name,
            schedule: schedule ?? DEFAULT_INTERVAL_SCHEDULE,
          },
        },
      });

      toast({
        id: "journey-save",
        title: "Journey reset",
        variant: "success",
      });

      navigate("/journeys");
    } catch (error) {
      Sentry.captureException(error);
      toast({
        id: "journey-save",
        title: "Failed to reset journey",
        variant: "error",
      });
    }
  };

  const setNodes = (
    nodes: JourneyNode[] | ((currentEdges: JourneyNode[]) => JourneyNode[]),
    {
      shouldDirty,
      shouldValidate,
    }: { shouldDirty: boolean; shouldValidate: boolean } = {
      shouldDirty: true,
      shouldValidate: false,
    },
  ) => {
    let newNodes: JourneyNode[];
    if (typeof nodes === "function") {
      newNodes = nodes(form.getValues("nodes"));
    } else {
      newNodes = nodes;
    }

    form.setValue("nodes", newNodes, { shouldDirty, shouldValidate });
  };

  const setEdges = (
    edges: Edge[] | ((currentEdges: Edge[]) => Edge[]),
    { shouldDirty }: { shouldDirty: boolean } = { shouldDirty: true },
  ) => {
    let newEdges: Edge[];
    if (typeof edges === "function") {
      newEdges = edges(form.getValues("edges"));
    } else {
      newEdges = edges;
    }

    form.setValue(
      "edges",
      newEdges.map((edge) => ({
        ...edge,
        ...edgeProps,
      })),
      { shouldDirty },
    );
  };

  const onNodesChange: OnNodesChange = (changes) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - Circular reference problem with Column types
    const nodes = form.getValues("nodes");
    const updatedNodes = applyNodeChanges(changes, nodes);

    const shouldDirty = changes.some(
      (change) => !NOOP_REACTFLOW_CHANGES.includes(change.type),
    );

    form.setValue("nodes", updatedNodes, { shouldDirty });
  };

  const onEdgesChange: OnEdgesChange = (changes) => {
    const edges = form.getValues("edges");
    const updatedEdges = applyEdgeChanges(changes, edges);

    form.setValue("edges", updatedEdges, { shouldDirty: true });
  };

  const clearNodeSelection = () => {
    store.getState().resetSelectedElements();
  };

  const hasOutgoers = (nodeId: string) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - Circular reference problem with Column types
    const nodes = form.getValues("nodes");
    const edges = form.getValues("edges");

    const node = nodes.find(({ id }) => id === nodeId);

    if (!node) return false;

    return getOutgoers(node, nodes, edges).length > 0;
  };

  const isValidConnection: IsValidConnection = useCallback(
    (connection) => {
      // Use store to prevent infinite re-rendering, since form changes often.
      const { getNodes, edges } = store.getState();
      const nodes = getNodes();

      const source = nodes.find(({ id }) => id === connection.source);
      const target = nodes.find(({ id }) => id === connection.target);

      // Should never hit this case but adding to appease the typescript gods
      if (!source || !target) return false;

      if (target.id === connection.source) return false;

      // Branch nodes may not be targets and parent nodes of branch nodes may not be sources
      if (
        JOURNEY_BRANCH_NODES.includes(target.type as JourneyNodeType) ||
        JOURNEY_NODES_WITH_BRANCH_NODES.includes(source.type as JourneyNodeType)
      ) {
        return false;
      }

      const edgesFromSource = edges.filter(
        (edge) => edge.source === connection.source,
      );

      // Only allow one edge from a source node
      if (edgesFromSource.length > 0) {
        return false;
      }

      const hasCycle = (node: JourneyNode, visited = new Set()) => {
        if (visited.has(node.id)) return false;

        visited.add(node.id);

        return getOutgoers(node, nodes, edges).some(
          (outgoer) =>
            outgoer.id === connection.source || hasCycle(outgoer, visited),
        );
      };

      const journeyHasCycle = hasCycle(target);

      if (journeyHasCycle) {
        toast({
          id: "journey-connection",
          title: "Invalid connection",
          message: "This connection will cause a cycle in this journey",
          variant: "error",
        });
      }

      return !journeyHasCycle;
    },
    [store],
  );

  const onConnect: OnConnect = (connection) =>
    setEdges((eds) =>
      addEdge(
        {
          ...connection,
          ...edgeProps,
        },
        eds,
      ),
    );

  const onDragNode: NodeDragHandler = (event, node) => {
    const idsToUpdate: Set<string> = new Set();

    // Calculate the offset considering the current zoom scale
    // TODO(Samuel): this zoom approach doesn't work with reactflow snapToGrid
    const zoom = getZoom();
    const xOffset = event.movementX / zoom;
    const yOffset = event.movementY / zoom;

    if (JOURNEY_NODES_WITH_BRANCH_NODES.includes(node.data.config.type)) {
      const branchNodes = getOutgoers(
        node,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - conditions type is circular
        form.getValues("nodes"),
        form.getValues("edges"),
      );

      idsToUpdate.add(node.id);
      branchNodes.forEach(({ id }) => idsToUpdate.add(id));
    } else if (JOURNEY_BRANCH_NODES.includes(node.data.config.type)) {
      const nodes = form.getValues("nodes");
      const edges = form.getValues("edges");

      const parentNodes = getIncomers(node, nodes, edges); // should only be one
      const parentNode = parentNodes[0];

      if (parentNode) {
        const branchNodes = getOutgoers(parentNode, nodes, edges);

        idsToUpdate.add(parentNode.id);
        branchNodes.forEach(({ id }) => idsToUpdate.add(id));
      }
    }

    setNodes((nodes) => {
      return nodes.map((node) => {
        if (idsToUpdate.has(node.id)) {
          return {
            ...node,
            position: {
              x: node.position.x + xOffset,
              y: node.position.y + yOffset,
            },
          };
        }

        return node;
      });
    });
  };

  const onRefitView = () => {
    fitView({ padding: 1.25, duration: 500 });
  };

  const onCleanUp = async () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - no circular types until react-hook-form v8
    const nodes = form.getValues("nodes");
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - no circular types until react-hook-form v8
    const edges = form.getValues("edges");

    await updateGraph(nodes, edges);

    onRefitView();
  };

  // Used for repositioning the nodes. Does not dirty the form.
  const updateGraph = async (nodes: JourneyNode[], edges: Edge[]) => {
    const repositionedNodes = await reposition(nodes, edges);

    setEdges(edges, { shouldDirty: false });
    setNodes(repositionedNodes, { shouldDirty: false, shouldValidate: false });
  };

  const startFlow = async (nodes: JourneyNode[], edges: Edge[]) => {
    await updateGraph(nodes, edges);

    // Set a checkpoint here for discarding changes
    form.reset(form.getValues());

    onRefitView();
  };

  const onAddNode = ({
    position,
    type,
  }: {
    type: JourneyNodeType;
    position: { x: number; y: number };
  }) => {
    const data = getInitialNodeConfiguration(type);

    const newNode: JourneyNode = {
      id: data.id,
      type,
      data,
      position,
    };

    let newBranchNodes: JourneyNode[] = [];
    const newEdges: Edge[] = [];

    if (JOURNEY_NODES_WITH_BRANCH_NODES.includes(type)) {
      newBranchNodes = getInitialBranchNodes(type, position);

      newEdges.push(
        ...newBranchNodes.map(({ id: branchNodeId }) => {
          return {
            id: uuidv4(),
            source: data.id,
            target: branchNodeId,
            sourceHandle: null,
            targetHandle: null,
          };
        }),
      );
    }

    setNodes((nodes) => [...nodes, newNode, ...newBranchNodes]);
    setEdges((currentEdges) => [...currentEdges, ...newEdges]);
  };

  const getFormBranchNodes = (nodeId: string) => {
    return getBranchNodes(
      nodeId,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - Conditions is a circular type
      form.getValues("nodes"),
      form.getValues("edges"),
    );
  };

  const onAddSegmentBranch = (parentId: string) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - no circular types until react-hook-form v8
    const nodes = form.getValues("nodes");
    const edges = form.getValues("edges");

    const parentNode = nodes.find(
      (node) =>
        node.data.config.type === JourneyNodeType.Segments &&
        node.data.id === parentId,
    );

    if (parentNode) {
      const outgoingNodeDetails: JourneyNodeDetails<JourneyNodeConfig>[] =
        getBranchNodes(parentId, nodes, edges).map(({ data }) => data);
      const segmentBranchNodes: JourneyNodeDetails<SegmentBranchConfig>[] =
        outgoingNodeDetails.filter(isSegmentBranchNodeDetails);

      // New priority rank is the number of segments without the catch all
      // since the catch all segment will always have priority rank that is
      // equal to the number of segments
      const maxPriorityRank = segmentBranchNodes.length - 1;

      const newId = uuidv4();
      const newNode: JourneyNode = {
        id: newId,
        type: JourneyNodeType.SegmentBranch,
        data: {
          id: newId,
          name: `Segment ${maxPriorityRank + 1}`,
          segment_id: null,
          event_relationship_id: null,
          number_users: null,
          sync_configs: null,
          config: {
            type: JourneyNodeType.SegmentBranch,
            segment_priority_rank: maxPriorityRank,
            segment_is_catch_all: false,
            segment_conditions: { type: ConditionType.And, conditions: [] },
          },
        },
        position: parentNode.position,
      };

      const newEdge: Edge = {
        id: uuidv4(),
        source: parentId,
        target: newId,
        sourceHandle: null,
        targetHandle: null,
      };

      setNodes((currentNodes) => {
        const newNodes = [...currentNodes, newNode];

        return updateSegmentPriority(parentNode.id, newNodes, [
          ...edges,
          newEdge,
        ]);
      });

      setEdges((currentEdges) => [...currentEdges, newEdge]);

      return newId;
    }

    return undefined;
  };

  const onCloneNode = (id: string) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - Circular reference problem with Column types
    const nodes = form.getValues("nodes");
    const oldNode = nodes.find((node) => node.id === id);

    // No cloning of start tile
    if (
      !oldNode ||
      !oldNode.type ||
      oldNode.type === JourneyNodeType.EntryCohort
    ) {
      return;
    }

    const ephemeralNodeId = uuidv4();
    const newNode = {
      ...oldNode,
      id: ephemeralNodeId,
      position: { x: oldNode.position.x, y: oldNode.position.y + 100 },
      data: {
        ...oldNode.data,
        id: ephemeralNodeId,
        name: oldNode.data.name,
        sync_configs: null, // TODO(samuel): sync cloning in v1
      },
    };

    let newBranchNodes: JourneyNode[] = [];
    let newEdges: Edge[] = [];

    // Cloning sub nodes of cloned node
    if (
      JOURNEY_NODES_WITH_BRANCH_NODES.includes(oldNode.type as JourneyNodeType)
    ) {
      const edgesToClone = getConnectedEdges(
        [oldNode],
        form.getValues("edges"),
      ).filter((edge) => edge.source === id); // only want branch nodes of cloned parent

      const branchNodeIds = edgesToClone.map(({ target }) => target);
      const branchNodesToClone = nodes.filter(({ id }) =>
        branchNodeIds.includes(id),
      );

      const oldBranchNodeIdToNewBranchNodeIdMapping = new Map<string, string>();

      newBranchNodes = branchNodesToClone.map((branchNode) => {
        const newBranchNodeId = uuidv4();

        oldBranchNodeIdToNewBranchNodeIdMapping.set(
          branchNode.id,
          newBranchNodeId,
        );

        return {
          ...branchNode,
          id: newBranchNodeId,
          position: {
            x: branchNode.position.x,
            y: branchNode.position.y + 100,
          },
          data: {
            ...branchNode.data,
            id: newBranchNodeId,
            name: branchNode.data.name,
          },
        };
      });

      newEdges = edgesToClone
        .map((edge) => {
          const newEdgeId = uuidv4();
          const targetId =
            oldBranchNodeIdToNewBranchNodeIdMapping.get(edge.target) || "";

          if (!targetId) return null;

          return {
            ...edge,
            id: newEdgeId,
            source: ephemeralNodeId,
            target: targetId,
          };
        })
        .filter(isPresent);
    }

    setNodes((currentNodes) => [...currentNodes, newNode, ...newBranchNodes]);
    setEdges((currentEdges) => [...currentEdges, ...newEdges]);
  };

  const onRemoveNode = (id: string) => {
    // TODO(samuel): should we use parentNode or add our own parent property?

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - Circular reference problem with Column types
    const nodes = form.getValues("nodes");
    const node = nodes.find((node) => node.id === id);
    if (!node || !node.type) {
      return;
    }

    // Should always delete _all_ edges to other nodes
    const edgesToDelete = getConnectedEdges([node], form.getValues("edges"));

    // If deleting a parent node, also delete all branch nodes and edges.
    let branchNodeIdsToDelete: string[] = [];
    let branchNodeEdgeIdsToDelete: string[] = [];

    if (
      JOURNEY_NODES_WITH_BRANCH_NODES.includes(node.type as JourneyNodeType)
    ) {
      const branchNodeTypeToDelete: JourneyNodeType | undefined =
        PARENT_NODE_TO_BRANCH_NODE[node.type];

      if (branchNodeTypeToDelete) {
        // Get possible node ids to delete by filtering edges by source id
        const connectedTargetNodeIds = uniq(
          edgesToDelete
            .filter(({ source }) => source === id)
            .map(({ target }) => target),
        );

        // Then find the nodes with the correct type
        const branchNodesToDelete = nodes.filter(({ id, type }) => {
          return (
            type === branchNodeTypeToDelete &&
            connectedTargetNodeIds.includes(id)
          );
        });

        // get branchNode edges to delete
        branchNodeEdgeIdsToDelete = getConnectedEdges(
          branchNodesToDelete,
          form.getValues("edges"),
        ).map(({ id }) => id);

        branchNodeIdsToDelete = branchNodesToDelete.map(({ id }) => id);
      }
    }

    const edgeIdsToDelete = new Set(edgesToDelete.map(({ id }) => id));

    setNodes((nodes) => {
      const newNodes = nodes.filter(
        (node) => node.id !== id && !branchNodeIdsToDelete.includes(node.id),
      );

      // If deleting a segment branch, ensure catch all element is last
      if (node.type === JourneyNodeType.SegmentBranch) {
        // find parent node
        const parentNode: JourneyNode | undefined = getIncomers(
          node,
          newNodes,
          form.getValues("edges"),
        )[0];

        if (!parentNode) return nodes;

        return updateSegmentPriority(
          parentNode.id,
          newNodes,
          form.getValues("edges").filter(({ id }) => !edgeIdsToDelete.has(id)),
        );
      }

      return newNodes;
    });

    setEdges((edges) =>
      edges.filter(
        (edge) =>
          !edgeIdsToDelete.has(edge.id) &&
          !branchNodeEdgeIdsToDelete.includes(edge.id),
      ),
    );

    // Close drawer if node is deleted
    if (id === selectedNodeId) {
      navigate("./");
    }
  };

  const onUpdateNode = (
    id: string,
    changes: Partial<JourneyNodeDetails<JourneyNodeConfig>>,
  ) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - no circular types until react-hook-form v8
    const nodes = form.getValues("nodes");
    const indexToUpdate = nodes.findIndex((node) => node.id === id);

    if (indexToUpdate === -1) {
      return;
    }

    const newNodes = [...nodes];
    newNodes[indexToUpdate]!.data = {
      ...newNodes[indexToUpdate]!.data,
      ...changes,
    };

    setNodes(newNodes, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const onAddSyncConfigToNode = (nodeId: string, config: SyncConfigDetails) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - no circular types until react-hook-form v8
    const nodes: JourneyNode[] = form.getValues("nodes");
    const indexToUpdate = nodes.findIndex((node) => node.id === nodeId);

    if (indexToUpdate === -1) return;

    const newNodes = [...nodes];

    newNodes[indexToUpdate]!.data.sync_configs = [
      ...(newNodes[indexToUpdate]!.data.sync_configs ?? []),
      config,
    ];

    setNodes(newNodes);
  };

  const onRemoveSyncConfigFromNode = (nodeId: string, syncId: string) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - no circular types until react-hook-form v8
    const nodes: JourneyNode[] = form.getValues("nodes");
    const indexToUpdate = nodes.findIndex((node) => node.id === nodeId);

    if (indexToUpdate === -1) return;

    const newNodes = [...nodes];

    const newSyncConfigs = newNodes[indexToUpdate]!.data.sync_configs!.filter(
      (config) => config.destination_instance_id.toString() !== syncId,
    );

    newNodes[indexToUpdate]!.data.sync_configs =
      newSyncConfigs.length > 0 ? newSyncConfigs : null;

    setNodes(newNodes, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const onUpdateSegmentPriority = (
    parentNodeId: string,
    newPriorityListIds: string[],
  ) => {
    const nodes = form.getValues("nodes");
    const newNodes = [...nodes];

    // update the segment priority rank for each segment
    newPriorityListIds.forEach((id, index) => {
      const nodeToUpdate = newNodes.find((node) => node.id === id);

      // Priority rank is the index
      if (nodeToUpdate && isSegmentBranchNodeDetails(nodeToUpdate.data)) {
        nodeToUpdate.data.config.segment_priority_rank = index;
      }
    });

    setNodes(
      updateSegmentPriority(parentNodeId, newNodes, form.getValues("edges")),
    );
  };

  const onUpdateSyncConfig = (
    nodeId: string,
    syncId: number,
    changes: Partial<SyncConfigDetails>,
  ) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - no circular types until react-hook-form v8
    const newNodes = [...form.getValues("nodes")];

    const nodeIndex = newNodes.findIndex((node) => node.id === nodeId);
    const node = newNodes[nodeIndex];

    if (node && node.data.sync_configs) {
      const syncConfigIndex = node.data.sync_configs.findIndex(
        ({ destination_instance_id }) => destination_instance_id === syncId,
      );

      const oldSyncConfig = node.data.sync_configs?.[syncConfigIndex];

      if (oldSyncConfig && newNodes[nodeIndex]?.data.sync_configs) {
        newNodes[nodeIndex]!.data.sync_configs![syncConfigIndex] = {
          ...oldSyncConfig,
          ...changes,
          destination_instance_id: syncId,
        };

        setNodes(newNodes);
      }
    }
  };

  const onUpdateJourneySettings = ({
    schedule,
    exitCriteria,
  }: {
    schedule: Schedule;
    exitCriteria: ExitCriteria;
  }) => {
    form.setValue("journey.schedule", schedule, { shouldDirty: true });
    form.setValue("journey.exitCriteria", exitCriteria, { shouldDirty: true });
  };

  // Calculate the initial layout on mount or when the nodes/edges are updated
  useLayoutEffect(() => {
    let nodes: JourneyNode[] = initialNodes;
    let newId: string | null = null;

    if (nodes.length === 0) {
      newId = uuidv4();

      nodes = [
        {
          id: newId,
          type: JourneyNodeType.EntryCohort,
          data: {
            id: newId,
            name: "Start",
            segment_id: null,
            event_relationship_id: null,
            number_users: null,
            sync_configs: null,
            config: getInitialNodeConfiguration(JourneyNodeType.EntryCohort)
              .config,
          },
          position: { x: 0, y: 0 },
        },
      ];
    }

    startFlow(nodes, initialEdges);

    // Focus new node if journey is empty
    if (initialNodes.length === 0 && newId) {
      navigate(`${newId}`);
    }
  }, [initialNodes, initialEdges]);

  // Move selected node to the center of the view, or refit the view when deselecting the node.
  useEffect(() => {
    if (selectedNodeId) {
      const { nodeInternals } = store.getState();

      const node = Array.from(nodeInternals.values()).find(
        (node) => node.id === selectedNodeId,
      );

      if (node) {
        const { x, y } = getCenteredNodePosition(node);

        setCenter(x, y, { zoom: 1, duration: 1000 });
      }
    }
  }, [selectedNodeId, store]);

  // Identify nodes with errors
  const nodeErrors: Record<string, boolean> = useMemo(() => {
    const errors = {};
    const nodes = form.getValues("nodes");

    form.formState.errors.nodes?.forEach?.((_, index) => {
      // map index of node to id of node
      const node = nodes[index]!;

      errors[node.id] = true;
    });

    return errors;
  }, [form.formState.errors.nodes]);

  return {
    form,
    nodeErrors,

    // actions
    getBranchNodes: getFormBranchNodes,
    isValidConnection,
    hasOutgoers,
    onAddNode,
    onAddSegmentBranch,
    onAddSyncConfigToNode,
    onDeleteJourney,
    onResetJourney,
    onCleanUp,
    onClearNodeSelection: clearNodeSelection,
    onCloneNode,
    onConnect,
    onDragNode,
    onEdgesChange,
    onFitView: fitView,
    onNodesChange,
    onRefitView,
    onRemoveNode,
    onRemoveSyncConfigFromNode,
    onRunJourney: runJourney,
    onSetCenter: setCenter,
    onUpdateJourneySettings,
    onUpdateJourneyStatus,
    onUpdateNode,
    onUpdateSegmentPriority,
    onUpdateSyncConfig,
    onZoomIn: zoomIn,
    onZoomOut: zoomOut,
  };
};
