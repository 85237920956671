import { useSearchParams } from "src/router";

/**
 * Get/Set just one search param in the URL without affecting the others.
 */
export function useSearchParam<
  ParamValue extends string | undefined = string | undefined,
>(param: string) {
  const [searchParams, setSearchParams] = useSearchParams();
  return [
    searchParams.get(param),
    (value: ParamValue) =>
      setSearchParams((oldParams) => {
        if (value === undefined || value === "") {
          oldParams.delete(param);
          return oldParams;
        }
        oldParams.set(param, value);
        return oldParams;
      }),
  ] as const;
}
