import { FC, useState, useEffect } from "react";

import {
  Select,
  Column,
  useToast,
  Textarea,
  FormField,
  TextInput,
  Heading,
  Text,
} from "@hightouchio/ui";
import Helmet from "react-helmet";
import { useNavigate, useParams } from "src/router";

import { Destinations } from "src/components/clone/destinations";
import { Explore } from "src/components/explore/explore";
import { DraftSubmissionModal } from "src/components/modals/draft-submission-modal";
import { useUser } from "src/contexts/user-context";
import {
  DraftOperation,
  ResourceToPermission,
  useCreateModelMutation,
  useDestinationDefinitionsQuery,
  useModelColumnsQuery,
  useModelWithoutColumnsQuery,
} from "src/graphql";
import { PageSpinner } from "src/components/loading";
import { DeprecatedWizard, WizardStep } from "src/components/wizard";
import { useModelRun, getModelInputFromState } from "src/utils/models";
import {
  generateSlug,
  SlugResourceType,
  useResourceSlug,
} from "src/utils/slug";
import { useModelState } from "src/hooks/use-model-state";

export const CloneModel: FC = () => {
  const { model_id: id } = useParams<{ model_id: string }>();
  const { toast } = useToast();
  const navigate = useNavigate();
  const { workspace } = useUser();
  const { getSlug } = useResourceSlug(SlugResourceType.Segments);
  const [step, setStep] = useState(0);
  const [selectedSyncs, setSelectedSyncs] = useState<Set<string>>(new Set());
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [submitDraftModalOpen, setSubmitDraftModalOpen] =
    useState<boolean>(false);

  const { data: model, isLoading: modelLoading } = useModelWithoutColumnsQuery(
    { id: String(id) },
    { enabled: Boolean(id), select: (data) => data.segments_by_pk },
  );

  const { data: modelColumns, isLoading: columnLoading } = useModelColumnsQuery(
    { modelId: String(id) },
    { enabled: Boolean(id), select: (data) => data.model_columns },
  );

  const source = model?.connection;
  const syncs = model?.syncs;

  const modelState = useModelState({ connection: source });

  const { mutateAsync: createModel, isLoading: creating } =
    useCreateModelMutation();

  const goToModel = (id: string) => {
    navigate(`/models/${id}`);
  };

  const { data: destinationDefinitions } = useDestinationDefinitionsQuery(
    undefined,
    {
      select: (data) => data.getDestinationDefinitions,
    },
  );

  const create = async () => {
    const slug = await getSlug(name);

    const mergedModelColumns = columns?.map((column) => {
      const oldColumn = modelColumns?.find((c) => c.name === column.name);
      return {
        ...column,
        // this doesn't come from the preview query result, it's user defined, so we want to keep it from the old model if it was set.
        semantic_type: oldColumn?.semantic_type,
      };
    });
    const res = await createModel({
      input: {
        name,
        description,
        ...getModelInputFromState(modelState.state),
        slug,
        columns: { data: mergedModelColumns },
        draft: workspace?.approvals_required,
        matchboosting_enabled: model?.matchboosting_enabled,
        destination_instances: {
          data: selectedSyncs.size
            ? (syncs
                ?.filter(({ id }) => selectedSyncs.has(String(id)))
                ?.map(
                  ({
                    destination,
                    config,
                    schedule,
                    sync_alerts,
                    row_threshold_attempted,
                    row_threshold_total,
                  }) => ({
                    destination_id: destination?.id,
                    config,
                    schedule,
                    slug: generateSlug(`${name}-to-${destination?.name}`),
                    schedule_paused: true,
                    row_threshold_attempted,
                    row_threshold_total,
                    alert_instances: {
                      data: sync_alerts.map((alert) => ({
                        alert_id: alert.id,
                        fatal_error: alert.fatal_error,
                        row_error: alert.row_error,
                      })),
                    },
                  }),
                ) ?? [])
            : [],
        },
      },
    });

    toast({
      id: "clone-model",
      title: `Cloned ${model!.name} and ${selectedSyncs?.size} syncs`,
      variant: "success",
    });

    return res;
  };

  const {
    runQuery,
    cancelQuery,
    getSchema,
    rows,
    numRowsWithoutLimit,
    isResultTruncated,
    columns,
    loading: queryLoading,
    error: queryError,
    errorAtLine: queryErrorAtLine,
    rowsCount,
    asyncPagination,
    page,
    setPage,
  } = useModelRun(modelState.state);

  useEffect(() => {
    if (model) {
      setName("");
      modelState.reset(model);
    }
  }, [model]);

  useEffect(() => {
    if (syncs) {
      setSelectedSyncs(new Set(syncs.map((sync) => String(sync.id))));
    }
  }, [syncs]);

  useEffect(() => {
    if (columns?.length && !queryError) {
      modelState.setIsPreviewFresh(true);
    }
  }, [rows, columns]);

  if (modelLoading || columnLoading) {
    return <PageSpinner />;
  }

  const steps: WizardStep[] = [
    {
      title: "Change model",
      disabled:
        !modelState.isValid ||
        Boolean(queryError) ||
        (source?.definition?.supportsResultSchema
          ? false
          : !modelState.isPreviewFresh),
      onContinue: async () => {
        if (
          source?.definition?.supportsResultSchema &&
          !modelState.isPreviewFresh
        ) {
          await getSchema();
        }
        setStep((step) => step + 1);
      },
      render: () => (
        <Explore
          modelState={modelState}
          asyncPagination={asyncPagination}
          cancelQuery={cancelQuery}
          columns={columns}
          isResultTruncated={Boolean(isResultTruncated)}
          numRowsWithoutLimit={numRowsWithoutLimit}
          page={page}
          rows={rows}
          rowsCount={rowsCount}
          runQuery={runQuery}
          source={source}
          onPageChange={setPage}
          error={queryError}
          errorAtLine={queryErrorAtLine}
          loading={queryLoading}
          rowsPerPage={100}
        />
      ),
    },
  ];

  if (syncs?.length) {
    steps.push({
      title: "Select destinations",
      header: (
        <Column gap={2}>
          <Heading>Select destinations to clone associated syncs</Heading>
          <Text fontWeight="medium" color="text.secondary">
            New syncs to these destinations will be automatically created using
            the cloned model. These new syncs will be disabled by default.
          </Text>
        </Column>
      ),
      render: () => (
        <Destinations
          definitions={destinationDefinitions}
          selected={selectedSyncs}
          setSelected={setSelectedSyncs}
          syncs={syncs}
        />
      ),
    });
  }

  steps.push({
    title: "Finalize",
    disabled: !name || !modelState?.state?.primary_key,
    submitting: creating,
    header: <Heading>Finalize your settings</Heading>,
    render: () => {
      const columnOptions: { value: string; label: string }[] = columns
        ? columns.map(({ name }) => ({ value: name, label: name }))
        : [];
      return (
        <Column gap={6}>
          <FormField label="Name">
            <TextInput
              placeholder={model?.name}
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </FormField>
          <FormField isOptional label="Description">
            <Textarea
              placeholder="Enter a description..."
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </FormField>

          <FormField
            tip="A primary key is column that contains unique values to identify each of the rows (e.g, email, id)."
            label="Primary key"
          >
            <Select
              options={columnOptions}
              placeholder="Select a column..."
              value={modelState?.state?.primary_key ?? ""}
              onChange={(primary_key) => {
                modelState.onChange({ primary_key });
              }}
            />
          </FormField>
        </Column>
      );
    },
  });

  return (
    <>
      <Helmet>
        <title>
          {model?.name ? `Clone "${model.name}" model` : "Clone model"}
        </title>
      </Helmet>

      <DraftSubmissionModal
        draft={{
          _set: {
            draft: false, // the draft change is to set the column `draft` to false.
          },
        }}
        approverOptions={{
          type: "model",
          sourceId: source?.id,
        }}
        permission={{
          v2: {
            resource: "model",
            grant: "can_approve",
            creationOptions: {
              type: "model",
              sourceId: source?.id?.toString ?? "",
            },
          },
        }}
        getResourceId={async () => {
          const model = await create();
          return model?.insert_segments_one?.id;
        }}
        open={submitDraftModalOpen}
        operation={DraftOperation.Create}
        resource={ResourceToPermission.Model}
        onClose={() => setSubmitDraftModalOpen(false)}
        onSubmit={(id) => {
          goToModel(id);
        }}
      />
      <DeprecatedWizard
        setStep={setStep}
        step={step}
        steps={steps}
        title="Clone model"
        onCancel={() => {
          navigate(`/models/${id}`);
        }}
        onSubmit={async () => {
          if (workspace?.approvals_required) {
            setSubmitDraftModalOpen(true);
            return;
          }

          const model = await create();
          if (model?.insert_segments_one?.id) {
            goToModel(model?.insert_segments_one.id);
          }
        }}
      />
    </>
  );
};
