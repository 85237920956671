import { ReactNode, useEffect } from "react";

import { useToast } from "@hightouchio/ui";
import { generatePath, useNavigate, useParams } from "src/router";

import { ResourceToPermission as V1ResourceToPermission } from "src/components/permission/types";
import { V2ResourceToPermission } from "src/components/permission/types-v2";
import {
  ResourcePermissionInput,
  useResourcePermission,
} from "src/components/permission/use-resource-permission";

type Props<
  V2Resource extends V2ResourceToPermission,
  V1Resource extends V1ResourceToPermission,
> = {
  permission: ResourcePermissionInput<V2Resource, V1Resource>;
  children: ReactNode;
  redirect: string;
};

export function PermissionedRoute<
  V2Resource extends V2ResourceToPermission,
  V1Resource extends V1ResourceToPermission,
>({ children, permission, redirect }: Props<V2Resource, V1Resource>) {
  const params = useParams();

  const input = { ...permission };

  if ("v1" in input && "id" in input.v1 && input.v1.id) {
    input.v1 = { ...input.v1, id: params[input.v1.id] };
  }

  if ("v2" in input && "id" in input.v2 && input.v2.id) {
    input.v2 = { ...input.v2, id: params[input.v2.id] };
  }

  const { isPermitted, isLoading } = useResourcePermission(input);
  const navigate = useNavigate();
  const { toast } = useToast();

  useEffect(() => {
    if (!isLoading && !isPermitted) {
      const to = generatePath(redirect, params);
      toast({
        id: "unauthorized-navigation",
        title: "You do not have permission to view this page",
        variant: "warning",
      });
      navigate(to);
    }
  }, [permission, isLoading]);

  if (isLoading || !permission) {
    return null;
  }

  return <>{children}</>;
}
