import { Column, Row } from "@hightouchio/ui";
import { ReactNode } from "react";
import { SIZES } from "src/ui/box";

// The editor page has some special styling requirements.
// This component replicates a lot of the styling provided in <DetailPage>,
// but gives a little more control to the child components.
// Also, we have a grey background on the left side, and default/white background on the right.
export const CodePageWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <Row height="100%">
      <Column flex={1} backgroundColor="base.lightBackground"></Column>
      <Column
        paddingBottom="72px"
        maxWidth={SIZES["page"]}
        width="100%"
        mx="auto"
      >
        {children}
      </Column>
      <Column flex={1}></Column>
    </Row>
  );
};
