export const cardinalityOptions = [
  {
    value: "one-to-one",
    label: "1:1",
  },
  { value: "one-to-many", label: "1:many" },
  { value: "many-to-one", label: "many:1" },
];

export const canColumnsBeMerged = (cardinality: string) => {
  return cardinality === "one-to-one" || cardinality === "many-to-one";
};
