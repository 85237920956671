import { Column, FormField, TextInput } from "@hightouchio/ui";
import { useOutletContext } from "src/router";
import { useUpdateOrganizationMutation } from "src/graphql";
import { OutletContext } from "..";
import { ActionBar } from "src/components/action-bar";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { Controller } from "react-hook-form";

export const Organization = () => {
  const { organization } = useOutletContext<OutletContext>();
  const { mutateAsync: updateOrganization } = useUpdateOrganizationMutation();

  const form = useHightouchForm({
    onSubmit: async ({ name }) =>
      updateOrganization({
        id: organization.id,
        input: { name },
      }),
    values: {
      name: organization.name,
    },
  });

  return (
    <Form form={form}>
      <Column gap={6}>
        <FormField label="Organization name">
          <Controller
            name="name"
            render={({ field }) => <TextInput {...field} />}
          />
        </FormField>
        <ActionBar center={false}>
          <FormActions />
        </ActionBar>
      </Column>
    </Form>
  );
};
