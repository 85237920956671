import {
  Box,
  Checkbox,
  Column,
  EmptyState,
  FormField,
  NumberInput,
  Row,
  Text,
} from "@hightouchio/ui";
import { Controller } from "react-hook-form";

export const CollectionSelect = () => {
  return (
    <Controller
      name="collections"
      render={({ field }) => (
        <FormField
          label="Collections"
          description="The collections to recommend from for this message."
          isOptional
        >
          {field.value?.length ? (
            <Column gap={4}>
              {field.value?.map((collection, index) => (
                <Column key={collection.id}>
                  <Column gap={2}>
                    <Row gap={2} align="center">
                      <Controller
                        name={`collections.${index}.enabled`}
                        render={({ field }) => (
                          <Checkbox
                            label={collection.name}
                            isChecked={field.value}
                            onChange={field.onChange}
                          />
                        )}
                      />
                    </Row>
                    <Row align="center" gap={1} ml={6}>
                      <Text>Recommend</Text>
                      <Controller
                        name={`collections.${index}.item_count`}
                        defaultValue={1}
                        render={({ field }) => (
                          <Box
                            as={NumberInput}
                            {...field}
                            size="sm"
                            width="50px"
                            min={1}
                          />
                        )}
                      />
                      <Text>item(s) per message</Text>
                    </Row>
                  </Column>
                </Column>
              ))}
            </Column>
          ) : (
            <EmptyState
              title="No collections found"
              message="Add a collection to start using personalized item recommendations"
            />
          )}
        </FormField>
      )}
    />
  );
};
