import { Schedule } from "src/components/schedule/types";
import {
  IdentityResolutionGraphQuery,
  IdrModelOptionsWithQueryFieldsQuery,
  IdrModelQuery,
} from "src/graphql";
import {
  BlockRule,
  MergeRule,
  Operator,
  Transformation,
  Stats,
  StatsForModelType,
  IDRv2IdentifierMergeRule,
} from "src/types/idr";
import { ColumnType } from "src/types/visual";
import { GraphVersion } from "./utils";

export enum IdrRunStatus {
  Cancelled = "CANCELLED",
  Cancelling = "CANCELLING",
  Failure = "FAILURE",
  Processing = "PROCESSING",
  Queued = "QUEUED",
  Success = "SUCCESS",
}

// TODO: centralize in backend?
export const defaultv1Identifiers = [
  "Email",
  "Phone number",
  "First name",
  "Last name",
  "Full name",
  "Address",
  "Anonymous ID",
  "User ID",
  "Adobe ID",
  "Segment ID",
  "IP address",
];

export const defaultv2Identifiers = [
  "user_id",
  "email",
  "phone",
  "anonymous_id",
];

export const getDefaultIdentifiers = (isIDRv2: boolean) =>
  isIDRv2 ? defaultv2Identifiers : defaultv1Identifiers;

export const transformationOptions: Record<
  Transformation,
  { label: string; description?: string }
> = {
  "case-insensitive": { label: "Case Insensitive" },
  "normalize-whitespace": {
    label: "Normalize",
    description: "Ignore whitespace",
  },
  "extract-number": {
    label: "Number",
    description: "Ignore non-numeric characters",
  },
};

export const transformationsByIdentifier: Record<string, Transformation[]> = {
  Email: ["case-insensitive", "normalize-whitespace"],
  Phone: ["extract-number"],
  Name: ["case-insensitive", "normalize-whitespace"],
  Address: ["case-insensitive", "normalize-whitespace"],
  "Anonymous ID": [],
  "Adobe ID": [],
  "Segment ID": [],
};

export const allTransformationOptions = Object.entries(
  transformationOptions,
).map(([value, option]) => ({
  value,
  ...option,
}));

export const transformationOptionsByIdentifier: Record<
  string,
  { value: Transformation; label: string; description?: string }[]
> = Object.fromEntries(
  Object.entries(transformationsByIdentifier).map(
    ([identifier, transformations]) => [
      identifier,
      transformations.map((transformation) => ({
        value: transformation,
        ...transformationOptions[transformation],
      })),
    ],
  ),
);

export type SupportedSource = "bigquery" | "snowflake" | "databricks";

export const operatorOptions: {
  value: Operator;
  label: string;
  sources?: SupportedSource[];
}[] = [
  {
    value: "eq",
    label: "Exact",
  },
  {
    value: "levenstein_90",
    label: "Levenshtein distance (90% match ratio)",
  },
  {
    value: "levenstein_70",
    label: "Levenshtein distance (70% match ratio)",
  },
  {
    value: "jaro_winkler_90",
    label: "Jaro-Winkler distance (90% confidence)",
    sources: ["bigquery", "snowflake"],
  },
  {
    value: "jaro_winkler_70",
    label: "Jaro-Winkler distance (70% confidence)",
    sources: ["bigquery", "snowflake"],
  },
  {
    value: "soundex",
    label: "Phonetic (Soundex)",
  },
];

export type LegacyStats = {
  events: {
    total: number;
    resolved: number;
    new: number;
  };
  profiles: {
    total: number;
    resolved: number;
    new: number;
  };
};

// isLegacy indicates legacy stats format. The entire stats object is v1 (i.e. legacy) IDR
export type IdrStats = Stats & { isLegacy?: boolean | undefined };

export type {
  BlockRule,
  MergeRule,
  Operator,
  Transformation,
  StatsForModelType,
};
export { ColumnType };

export type GoldenRecordOutletContext = {
  onClose: () => void;
};

export type InspectorOutletContext = {
  onClose: () => void;
};

export type ModelOptionWithQueryFields =
  IdrModelOptionsWithQueryFieldsQuery["segments"][number];
export type ModelDetails = NonNullable<IdrModelQuery["segments_by_pk"]>;

export type MergeRuleSet = {
  identifier: string;
  type: "and" | "or";
  conditions: MergeRuleCondition[];
};

export type MergeRuleCondition = {
  type: "and" | "or";
  rules: MergeRule[];
};

export type RulesFormStatev1 = {
  version: 1;
  merge_rules: MergeRuleSet[];
  block_rules: BlockRule[];
  resolution_rules: any[];
};

export type RulesFormStatev2 = {
  version: 2;
  merge_rules: IDRv2IdentifierMergeRule[];
  block_rules: BlockRule[];
  resolution_rules: any[];
};

export type RulesFormState = RulesFormStatev1 | RulesFormStatev2;

export type ModelState = {
  type: "profile" | "event";
  model: { id: string; name: string };
  order_by: string;
  mappings: { column: string; identifier: string }[];
};

export type IDRGraphWizardFormState = {
  name: string;
  description: string;
  output_table: string;
  models: ModelState[];
  schedule: Schedule;
} & RulesFormState;

export type IDRSummaryStats = {
  totalHtIds: number | null;
  totalSrcRows: number | null;
  diffHtIds: number | null;
  models: {
    id: string;
    modelName: string;
    numHtIds: number;
    sourceRows: number;
  }[];
  identifiers: { name: string; numHtIds: number; sourceRows: number }[];
};

export type IdentityGraph = Omit<
  NonNullable<IdentityResolutionGraphQuery["idr_by_pk"]>,
  "merge_rules" | "block_rules"
> &
  (
    | {
        version: GraphVersion.V1 | null;
        merge_rules: MergeRuleSet | MergeRuleSet[] | null;
        block_rules: BlockRule[] | null;
      }
    | {
        version: GraphVersion.V2;
        merge_rules: IDRv2IdentifierMergeRule[] | null;
        block_rules: BlockRule[] | null;
      }
  );
