export const InspectorData = [
  {
    ht_id: "delta1a2b3c4d5e",
    email: ["danny@email.com", "gary@email.com"],
    phone: ["666-666-6666"],
    maid: ["bowling123"],
    first_name: ["Gary", "Gery", "Jerry"],
    last_name: ["Tynnifer"],
  },
  {
    ht_id: "delta6f7g8h9i0j",
    email: ["sam@email.com"],
    phone: ["777-777-7777", "888-888-8888"],
    maid: ["running456", "swimming789"],
    first_name: ["Sam", "Alex"],
    last_name: ["Smith", "Johnson"],
  },
  {
    ht_id: "delta1234abcd",
    email: [],
    phone: [],
    maid: ["running456"],
    first_name: ["Danny"],
    last_name: ["Williams"],
  },
  {
    ht_id: "delta5678efgh",
    email: ["pat@gmail.com", "alex@yahoo.com"],
    phone: ["666-666-6666"],
    maid: [],
    first_name: ["Pat", "Alex"],
    last_name: ["Brown", "Jones"],
  },
  {
    ht_id: "delta90ijklmn",
    email: ["chris@gmail.com"],
    phone: ["888-888-8888"],
    maid: ["bowling123", "running456"],
    first_name: ["Chris"],
    last_name: ["Garcia", "Miller"],
  },
  {
    ht_id: "deltab1c2d3e4f",
    email: ["danny@email.com", "jerry@yahoo.com"],
    phone: ["777-777-7777"],
    maid: ["swimming789"],
    first_name: ["Danny", "Jerry"],
    last_name: ["Smith"],
  },
  {
    ht_id: "deltag5h6i7j8k",
    email: [],
    phone: [],
    maid: [],
    first_name: ["Sam"],
    last_name: ["Johnson"],
  },
  {
    ht_id: "delta12345abc",
    email: ["pat@email.com"],
    phone: ["666-666-6666", "777-777-7777"],
    maid: ["bowling123"],
    first_name: ["Pat"],
    last_name: ["Williams"],
  },
  {
    ht_id: "delta67890def",
    email: ["chris@gmail.com", "gary@gmail.com"],
    phone: ["888-888-8888"],
    maid: ["running456"],
    first_name: ["Chris", "Gary"],
    last_name: ["Brown", "Jones"],
  },
  {
    ht_id: "deltag1h2i3j4k",
    email: ["alex@gmail.com"],
    phone: ["666-666-6666", "888-888-8888"],
    maid: ["swimming789"],
    first_name: ["Alex"],
    last_name: ["Garcia"],
  },
  {
    ht_id: "delta5678ghij",
    email: ["danny@gmail.com", "sam@yahoo.com"],
    phone: ["777-777-7777"],
    maid: ["bowling123", "running456"],
    first_name: ["Danny", "Sam"],
    last_name: ["Miller"],
  },
  {
    ht_id: "delta1234ijkl",
    email: ["jerry@gmail.com"],
    phone: ["666-666-6666"],
    maid: ["swimming789"],
    first_name: ["Jerry"],
    last_name: ["Smith"],
  },
  {
    ht_id: "delta5678mnop",
    email: ["pat@gmail.com", "alex@yahoo.com"],
    phone: [],
    maid: [],
    first_name: ["Pat", "Alex"],
    last_name: ["Johnson"],
  },
  {
    ht_id: "delta90qrstuv",
    email: ["chris@yahoo.com"],
    phone: ["777-777-7777"],
    maid: ["bowling123"],
    first_name: ["Chris"],
    last_name: ["Williams"],
  },
  {
    ht_id: "delta1234wxyz",
    email: ["danny@gmail.com"],
    phone: ["666-666-6666"],
    maid: ["running456", "swimming789"],
    first_name: ["Danny"],
    last_name: ["Brown"],
  },
  {
    ht_id: "deltab1c2d3e4f",
    email: ["sam@gmail.com", "jerry@yahoo.com"],
    phone: ["888-888-8888"],
    maid: ["bowling123"],
    first_name: ["Sam", "Jerry"],
    last_name: ["Jones"],
  },
  {
    ht_id: "deltag5h6i7j8k",
    email: ["pat@gmail.com"],
    phone: ["666-666-6666", "777-777-7777"],
    maid: ["running456"],
    first_name: ["Pat"],
    last_name: ["Garcia"],
  },
  {
    ht_id: "delta12345abc",
    email: ["alex@yahoo.com"],
    phone: [],
    maid: ["swimming789"],
    first_name: ["Alex"],
    last_name: ["Miller"],
  },
  {
    ht_id: "delta67890def",
    email: ["chris@gmail.com", "gary@gmail.com"],
    phone: ["888-888-8888"],
    maid: ["bowling123", "running456"],
    first_name: ["Chris", "Gary"],
    last_name: ["Smith"],
  },
  {
    ht_id: "deltag1h2i3j4k",
    email: ["danny@gmail.com"],
    phone: ["666-666-6666"],
    maid: [],
    first_name: ["Danny"],
    last_name: ["Johnson"],
  },
  {
    ht_id: "delta5678ghij",
    email: ["sam@yahoo.com"],
    phone: ["777-777-7777", "888-888-8888"],
    maid: ["swimming789"],
    first_name: ["Sam"],
    last_name: ["Williams"],
  },
  {
    ht_id: "delta1234ijkl",
    email: ["pat@gmail.com"],
    phone: ["666-666-6666"],
    maid: ["bowling123"],
    first_name: ["Pat"],
    last_name: ["Brown"],
  },
  {
    ht_id: "delta5678mnop",
    email: ["alex@gmail.com", "gary@yahoo.com"],
    phone: ["888-888-8888"],
    maid: ["running456"],
    first_name: ["Alex", "Gary"],
    last_name: ["Jones"],
  },
  {
    ht_id: "delta90qrstuv",
    email: ["chris@gmail.com"],
    phone: ["777-777-7777"],
    maid: ["swimming789"],
    first_name: ["Chris"],
    last_name: ["Garcia"],
  },
  {
    ht_id: "delta1234wxyz",
    email: ["danny@gmail.com"],
    phone: ["666-666-6666", "777-777-7777"],
    maid: ["bowling123"],
    first_name: ["Danny"],
    last_name: ["Miller"],
  },
  {
    ht_id: "deltab1c2d3e4f",
    email: ["sam@gmail.com", "jerry@yahoo.com"],
    phone: ["888-888-8888"],
    maid: ["running456", "swimming789"],
    first_name: ["Sam", "Jerry"],
    last_name: ["Smith"],
  },
  {
    ht_id: "deltag5h6i7j8k",
    email: ["pat@gmail.com"],
    phone: ["666-666-6666"],
    maid: ["bowling123"],
    first_name: ["Pat"],
    last_name: ["Johnson"],
  },
  {
    ht_id: "delta12345abc",
    email: ["alex@yahoo.com", "chris@gmail.com"],
    phone: ["777-777-7777"],
    maid: ["running456"],
    first_name: ["Alex", "Chris"],
    last_name: ["Williams"],
  },
  {
    ht_id: "delta67890def",
    email: ["danny@gmail.com"],
    phone: ["888-888-8888"],
    maid: ["swimming789"],
    first_name: ["Danny"],
    last_name: ["Brown"],
  },
  {
    ht_id: "deltag1h2i3j4k",
    email: ["sam@gmail.com", "gary@yahoo.com"],

    phone: ["666-666-6666"],
    maid: ["bowling123", "running456"],
    first_name: ["Sam", "Gary"],
    last_name: ["Jones"],
  },
];

export const EventData = [
  {
    modelName: "Click events",
    latestTimestamp: "2024-07-09T14:53:07.464Z",
    count: 99,
  },
  {
    modelName: "Order events",
    latestTimestamp: "2024-07-09T14:53:07.464Z",
    count: 3,
  },
];

export const ObjectData = [
  { modelName: "Users", count: 4 },
  { modelName: "Contacts", count: 3 },
  { modelName: "SFDC Contacts", count: 1 },
];
