import { isEqual } from "lodash";
import { isPresent } from "ts-extras";

import {
  getModelIdFromColumn,
  getPropertyNameFromColumn,
} from "src/components/explore/visual/utils";
import { RelationshipFragment } from "src/graphql";
import {
  GroupByColumn,
  GroupByOption,
  GroupByOptionColumnReference,
  GroupByValue,
  ParentModel,
} from "src/pages/analytics/types";
import { isGroupByColumnRelatedToParent } from "src/pages/analytics/utils";

// Group the shared event properties by name into one since we will treat this
// as one groupBy in the graph
export const transformGroupByColumns = (
  groupByColumns: (GroupByColumn | undefined)[],
  parent: ParentModel | null,
): GroupByValue => {
  if (!groupByColumns || !groupByColumns.length) return [];

  // Make a map of the column name -> column references (name can point to
  // multiple columns for shared event properties). Note that this can
  // be undefined to represent an empty GroupBy to be filled out
  const columnsByNameMap = new Map<string, GroupByColumn[] | undefined>();
  for (const gb of groupByColumns) {
    // Don't group event columns with parent columns
    const columnModelId = getModelIdFromColumn(gb);
    const colNamePrefix = isGroupByColumnRelatedToParent(parent, gb)
      ? `${columnModelId}-`
      : "";

    const columnName = colNamePrefix + getPropertyNameFromColumn(gb);

    if (!gb) {
      columnsByNameMap.set(columnName, undefined);
    } else {
      const columns = columnsByNameMap.get(columnName) ?? [];
      columns.push(gb);
      columnsByNameMap.set(columnName, columns);
    }
  }

  const groupBys: GroupByValue = [];
  for (const [_name, columns] of columnsByNameMap) {
    if (!columns || !columns.length) {
      groupBys.push(undefined);
    } else {
      const groupBy = columns.length === 1 ? columns[0] : columns;
      if (groupBy) groupBys.push(groupBy);
    }
  }

  return groupBys;
};

export const getSameNameEventColumnsOptions = (
  events: RelationshipFragment[],
): GroupByOption[] => {
  if (events.length === 1) {
    return (events[0]?.to_model?.filterable_audience_columns ?? []).map(
      (column) => ({
        groupLabel: null,
        name: column.alias ?? column.name,
        columnReference: column.column_reference,
      }),
    );
  }

  const columnsByNameMap = new Map<string, GroupByColumn[]>();
  for (const event of events) {
    for (const col of event.to_model.filterable_audience_columns ?? []) {
      if (!col) continue;

      const columnName = getPropertyNameFromColumn(col.column_reference);
      if (!columnName) continue;

      const columns = columnsByNameMap.get(columnName) ?? [];
      columns.push(col.column_reference);
      columnsByNameMap.set(columnName, columns);
    }
  }

  const groupByOptions: GroupByOption[] = [];
  for (const [name, columns] of columnsByNameMap) {
    if (columns.length === events.length) {
      groupByOptions.push({
        groupLabel: null,
        name,
        columnReference: columns,
      });
    }
  }

  return groupByOptions;
};

export const convertValueToArray = (
  value: Array<unknown> | unknown | undefined,
): Array<unknown> => {
  return Array.isArray(value) ? value : [value];
};

// XXX: Current groupByColumn options contain the transformed index while the
// state's groupByColumn is a flattened array of GroupBys. We need to make sure
// we update the state groupByColumn at the correct index and remove any flattened
// shared events (i.e. multiple groupByColumns that are grouped together in the
// the graph by name)
export const getColumnsAndIndexToUpdate = ({
  value,
  optionIndex,
  groupByColumns,
  groupByValues,
}: {
  value: GroupByOptionColumnReference | undefined;
  optionIndex: number;
  groupByColumns: (GroupByColumn | undefined)[];
  groupByValues: GroupByValue;
}): { columns: (GroupByColumn | undefined)[]; startIndex: number } => {
  const currentGroupByValue = convertValueToArray(groupByValues[optionIndex]);
  const firstGroupByIndex = groupByColumns.findIndex((gb) =>
    isEqual(gb, currentGroupByValue[0]),
  );

  // Default to end of the list if we are not replacing existing groupByColumns
  const startIndex =
    firstGroupByIndex !== -1 ? firstGroupByIndex : groupByColumns.length - 1;

  const arrayValue = convertValueToArray(value) as (
    | GroupByColumn
    | undefined
  )[];

  // Grab the rest of the array so we can easily remove the current GroupByColumns
  // in the case there are multiple underlying columns
  const restOfGroupBys = groupByColumns
    .slice(firstGroupByIndex + currentGroupByValue.length)
    .filter(
      // Filter out undefined unless we know there's an empty one in the next option
      (gb) => groupByValues[optionIndex + 1] != undefined && isPresent(gb),
    ) as (GroupByColumn | undefined)[];

  return {
    columns: arrayValue.concat(restOfGroupBys),
    startIndex,
  };
};
