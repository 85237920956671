import { EditableText } from "@hightouchio/ui";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { EventSchemaFormState } from "src/events/contracts/types";

export const DescriptionField: FC = () => {
  const { control } = useFormContext<EventSchemaFormState>();

  return (
    <Controller
      control={control}
      name="description"
      render={({ field }) => (
        <EditableText
          placeholder="Add a description..."
          value={field.value ?? ""}
          onChange={field.onChange}
        />
      )}
    />
  );
};
