import { AreaChart, Area } from "recharts";

const CustomizedDot = (props: {
  cx?: number;
  cy?: number;
  points?: any[];
  index?: number;
  color: string;
}) => {
  const { cx, cy, index } = props;
  if (props.points && index === props.points.length - 1) {
    return <circle r="2" cx={cx} cy={cy} fill={props.color} />;
  } else return <></>;
};

export const Sparkline = ({
  data,
  color = "var(--chakra-colors-primary-base)",
}: {
  data: { value: number }[];
  color?: string;
}) => {
  return (
    <AreaChart width={120} height={25} data={data}>
      <defs>
        <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
          <stop stopColor={color} stopOpacity="0.3" />
          <stop offset="1" stopColor={color} stopOpacity="0" />
        </linearGradient>
      </defs>
      <Area
        type="monotone"
        stroke={color}
        fill="url(#gradient)"
        fillOpacity={1}
        strokeWidth={1}
        dataKey="value"
        dot={<CustomizedDot color={color} />}
      />
    </AreaChart>
  );
};
