import { FC } from "react";

import { Column, Box } from "@hightouchio/ui";

export type Customer = {
  name: string;
  logo: string;
};

const b2cCustomers: Customer[] = [
  {
    name: "Spotify",
    logo: "https://cdn.sanity.io/images/pwmfmi47/production/c79ef630644336a446eff2ccdc7afded6cd54c02-240x72.svg",
  },
  {
    name: "Betterment",
    logo: "https://cdn.sanity.io/images/pwmfmi47/production/eddd51924e7b2dc880144c98fd559a8e10718804-538x72.svg",
  },
  {
    name: "NBA",
    logo: "https://cdn.sanity.io/images/pwmfmi47/production/07791cf17a1edc8a7995ccab5c2501eb24f7eb44-900x531.png",
  },
  {
    name: "PetSmart",
    logo: "https://cdn.sanity.io/images/pwmfmi47/production/375df10710b9b2fbd2cf9c2ae5f669672753022d-607x150.png",
  },
  {
    name: "Tripadvisor",
    logo: "https://cdn.sanity.io/images/pwmfmi47/production/edd01e0e1e00136d6d50d8fa46ec6d5fd4a77165-3354x713.svg",
  },
  {
    name: "GameStop",
    logo: "https://cdn.sanity.io/images/pwmfmi47/production/ae0dee359e218bad5b70b1a91eff75208c7fe25b-3840x816.png",
  },
  {
    name: "Cars.com",
    logo: "https://cdn.sanity.io/images/pwmfmi47/production/61a11fb34f9505cb127d15b02974f48870c4eb1e-801x314.png",
  },
  {
    name: "Weight Watchers",
    logo: "https://cdn.sanity.io/images/pwmfmi47/production/1c993a34a3ad1221ddd64f839ff62135fab76089-469x125.png",
  },
];

const b2bCustomers: Customer[] = [
  {
    name: "Iterable",
    logo: "https://cdn.sanity.io/images/pwmfmi47/production/390c5397fd12a45ff789cd04b79899cb387ea0c9-254x76.png",
  },
  {
    name: "Ramp",
    logo: "https://cdn.sanity.io/images/pwmfmi47/production/acaf90882a1ded8a3796cc3972219355a42d009c-270x72.svg",
  },
  {
    name: "Plaid",
    logo: "https://cdn.sanity.io/images/pwmfmi47/production/64ac3d884b1f663719ed83a72910b195ad24819e-189x72.svg",
  },
  {
    name: "Calendly",
    logo: "https://cdn.sanity.io/images/pwmfmi47/production/112c71ceb6cab04381cd1e1561945bc7d3d4de9e-300x72.svg",
  },
  {
    name: "GitLab",
    logo: "https://cdn.sanity.io/images/pwmfmi47/production/98bc0d8b67a8be902a9d9c5991fc5bd6a81e2a48-695x152.png",
  },
  {
    name: "Malwarebytes",
    logo: "https://cdn.sanity.io/images/pwmfmi47/production/553ae1b035930d07fd4511ba57786564bd249cc7-3090x487.png",
  },
  {
    name: "Greenhouse",
    logo: "https://cdn.sanity.io/images/pwmfmi47/production/89b5878ca02d8d533e7e838a37b390c974574d86-2212x497.png",
  },
  {
    name: "Grammarly",
    logo: "https://cdn.sanity.io/images/pwmfmi47/production/debeecfa1c62c364743cb39c9243c77f54cb7931-566x143.png",
  },
];

const allCustomers = [...b2cCustomers, ...b2bCustomers];

type Props = {
  logos?: { name: string; logo: string }[];
};

export const SplashLogos: FC<Readonly<Props>> = ({ logos = allCustomers }) => {
  return (
    <Box
      display="grid"
      gridTemplateColumns="repeat(auto-fill, minmax(100px, 1fr))"
      alignItems="center"
      gridAutoRows="40px"
      gridGap={{ base: 6, md: 8, lg: 12 }}
      width="100%"
    >
      {logos.map((customer) => (
        <Column
          key={customer.name}
          align="center"
          justify="center"
          flex={1}
          pos="relative"
        >
          <Box
            as="img"
            src={`${customer.logo}?w=300`}
            alt={`${customer.name}.`}
            width="100%"
            height="auto"
            maxHeight={{ base: "36px", md: "48px" }}
            objectFit="contain"
          />
        </Column>
      ))}
    </Box>
  );
};
