import { FC, PropsWithChildren } from "react";

import {
  Alert,
  Box,
  BoxProps,
  Column,
  InformationIcon,
  Row,
  SortVerticalDownIcon,
  Spinner,
  Text,
  TextProps,
  Tooltip,
} from "@hightouchio/ui";
import pluralize from "pluralize";
import { useFormContext } from "react-hook-form";

import PlaceholderImage from "src/assets/placeholders/generic.svg";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { LinkButton, useOutletContext } from "src/router";
import { ColumnType } from "src/types/visual";
import { Pagination, Table } from "src/ui/table";
import { formatDateOrDatetime } from "src/utils/time";

import { CampaignFilters } from "./campaign-filters";
import { CampaignsLoadingCircles } from "./campaigns-loading-circles";
import {
  CampaignResultsLimit,
  cellStyles,
  headerStyles,
  NumberColumns,
} from "./constants";
import { CampaignFiltersContextProvider } from "./filters/context";
import { useCampaignsFilterContext } from "./filters/hook";
import { useAssetType, useCampaignPage } from "./filters/use-campaign-page";
import { CampaignsOutletContext, CampaignTableRow, FilterState } from "./types";
import { transformTableData, getAssetNoun } from "./utils";
import { isPresent } from "ts-extras";
import { formatNumericMetric } from "src/pages/metrics/formatting/format-metric";
import {
  MetricDisplayFormatting,
  predefinedMetricConfigs,
} from "@hightouch/lib/query/visual/types/goals";

const LoadingState = () => (
  <Column align="center" justify="center" flex={1} minHeight={0}>
    <Spinner size="lg" />
  </Column>
);

export const CampaignsTable: FC = () => {
  const {
    sourceId,
    parentModelId,
    interactionModelIds,
    savedView,
    savedViewLoading,
  } = useOutletContext<CampaignsOutletContext>();

  const assetType = useAssetType();

  const {
    assetModel,
    isLoading: isCampaignsPageLoading,
    conversionMetrics,
    predefinedMetrics,
  } = useCampaignPage({
    parentModelId,
    interactionModelIds,
  });

  if (isCampaignsPageLoading) {
    return <LoadingState />;
  }

  const noAssetModels = !assetModel;
  const noConversionMetrics = conversionMetrics.length === 0;

  if (noAssetModels) {
    return (
      <Alert
        type="warning"
        mx={6}
        my={4}
        title={`No ${assetType} asset models`}
        message={`To run campaign analytics, the parent model must have at least one related ${assetType} asset model. Please create an ${assetType} asset model in the schema.`}
        actions={
          <LinkButton href={`/schema-v2?source=${sourceId}`}>
            Go to schema
          </LinkButton>
        }
      />
    );
  }

  return (
    <CampaignFiltersContextProvider
      assetModel={assetModel!}
      parentModelId={parentModelId}
      savedView={savedView}
      isSavedViewLoading={savedViewLoading}
      predefinedMetrics={predefinedMetrics}
      conversionMetrics={conversionMetrics}
    >
      <Column gap={4}>
        <CampaignFilters assetModel={assetModel!} />

        {parentModelId && noConversionMetrics && (
          <Alert
            mx={6}
            type="warning"
            title="No conversion metrics"
            message="Create a metric and add an attribution method to see how your audiences are performing."
            actions={<LinkButton href="/metrics">Go to metrics</LinkButton>}
          />
        )}

        <CampaignsTableContent />
      </Column>
    </CampaignFiltersContextProvider>
  );
};

const CampaignsTableContent = () => {
  const assetType = useAssetType();
  const { assetModel, data, error, isPolling, onPageChange } =
    useCampaignsFilterContext();

  const form = useFormContext<FilterState>();

  const {
    selectedConversionMetrics,
    selectedPredefinedMetrics,
    selectedColumns,
    orderBy,
  } = form.watch();

  const noSelections =
    assetModel &&
    selectedPredefinedMetrics.length === 0 &&
    selectedConversionMetrics.length === 0;

  const primaryKeyAndLabelColumnsMatch =
    assetModel.primary_label_column === assetModel.primary_key_column;

  const primaryColumns = [
    {
      headerSx: headerStyles,
      cellSx: cellStyles,
      max: "min-content",
      header: () => (
        <HeaderCell
          label={assetModel.primary_label_column}
          isSorted={orderBy?.assetColumn === assetModel.primary_label_column}
        />
      ),
      cell: (rowData: CampaignTableRow) => {
        if (!rowData) return null;

        const { [assetModel.primary_label_column]: primaryLabelColumn } =
          rowData;

        return (
          <Cell>
            <ColumnValue column={primaryLabelColumn} fontWeight="semibold" />
          </Cell>
        );
      },
    },
    // Sometimes the primary key and label columns match
    primaryKeyAndLabelColumnsMatch
      ? null
      : {
          headerSx: headerStyles,
          cellSx: cellStyles,
          max: noSelections ? undefined : "min-content",
          header: () => (
            <HeaderCell
              label={assetModel.primary_key_column}
              isSorted={orderBy?.assetColumn === assetModel.primary_key_column}
            />
          ),
          cell: (rowData: CampaignTableRow) => {
            if (!rowData) return null;

            const { [assetModel.primary_key_column]: primaryKeyColumn } =
              rowData;

            return (
              <Cell>
                <ColumnValue column={primaryKeyColumn} />
              </Cell>
            );
          },
        },
  ].filter(isPresent);

  return isPolling ? (
    <Row px={6}>
      <CampaignsLoadingCircles />
    </Row>
  ) : (
    <>
      <Column
        px={6}
        overflowX="auto"
        sx={{ "> table": { overflowX: "unset" } }}
      >
        <Table
          scrollable
          data={transformTableData(
            data?.assets,
            selectedColumns,
            selectedPredefinedMetrics,
            selectedConversionMetrics,
          )}
          primaryKey="__reactKey"
          headerHeight="48px"
          placeholder={{
            title: `No ${pluralize(getAssetNoun(assetType))}`,
            body:
              data && data.assets
                ? `No ${pluralize(
                    getAssetNoun(assetType),
                  )} match the selected filters. Please adjust the filters to try another query.`
                : `Monitor the performance of your ${pluralize(
                    getAssetNoun(assetType),
                  )}. To get started, create an interaction model in the schema.`,
            image: PlaceholderImage,
            error: error ?? undefined,
          }}
          error={Boolean(error)}
          columns={
            !assetModel
              ? []
              : [
                  ...primaryColumns,

                  ...selectedColumns.map(({ alias, name, type }) => ({
                    divider: false,
                    headerSx: headerStyles,
                    cellSx: cellStyles,
                    header: () => (
                      <HeaderCell
                        label={alias ?? name}
                        justifyContent={
                          NumberColumns.includes(type as ColumnType)
                            ? "flex-end"
                            : undefined
                        }
                        isSorted={name === orderBy?.assetColumn}
                      />
                    ),
                    cell: (rowData: CampaignTableRow) => {
                      const column = rowData[name];

                      return (
                        <Cell>
                          <ColumnValue column={column} />
                        </Cell>
                      );
                    },
                  })),
                  // Predefined metrics do not have attribution methods
                  ...selectedPredefinedMetrics.map(
                    ({ id, name, config: { predefinedMetric } }) => ({
                      divider: false,
                      headerSx: headerStyles,
                      cellSx: cellStyles,
                      header: () => (
                        <HeaderCell
                          label={name}
                          justifyContent="flex-end"
                          isSorted={id === orderBy?.goalId}
                        />
                      ),
                      cell: (rowData: CampaignTableRow) => {
                        const column = rowData[id];

                        return (
                          <Cell>
                            <ColumnValue
                              displayFormatting={
                                predefinedMetricConfigs[predefinedMetric]
                              }
                              column={
                                {
                                  ...column,
                                  type: ColumnType.Number, // force type to be number, even if column does not exist in row data
                                } as CampaignTableRow[keyof CampaignTableRow]
                              }
                            />
                          </Cell>
                        );
                      },
                    }),
                  ),
                  ...selectedConversionMetrics.flatMap(
                    (conversionMetric, index) => ({
                      divider: false,
                      headerSx: headerStyles,
                      cellSx: cellStyles,
                      header: () => {
                        const { id, name, attributionMethod } =
                          conversionMetric;
                        return (
                          <HeaderCell
                            label={name}
                            description={attributionMethod.name}
                            borderLeft={index === 0 ? "1px solid" : "none"}
                            justifyContent="flex-end"
                            isSorted={
                              id === orderBy?.goalId &&
                              attributionMethod.id ===
                                orderBy.attributionMethodId
                            }
                          />
                        );
                      },
                      cell: (rowData: CampaignTableRow) => {
                        const column =
                          rowData[
                            `${conversionMetric.id}-${conversionMetric.attributionMethod.id}`
                          ];

                        return (
                          <Cell
                            borderLeft={index === 0 ? "1px solid" : "none"}
                            borderColor="base.border"
                          >
                            <ColumnValue
                              displayFormatting={
                                conversionMetric.config.displayFormatting
                              }
                              column={
                                {
                                  ...column,
                                  type: ColumnType.Number, // force type to be number, even if column does not exist in row data
                                } as CampaignTableRow[keyof CampaignTableRow]
                              }
                            />
                          </Cell>
                        );
                      },
                    }),
                  ),
                ]
          }
        />
      </Column>

      {!noSelections && data?.paginationResults && (
        <Row mb={6} ml={6}>
          <Pagination
            count={data.paginationResults.totalCount}
            label={pluralize(getAssetNoun(assetType))}
            page={data.paginationResults.page - 1}
            rowsPerPage={CampaignResultsLimit}
            setPage={onPageChange}
          />
        </Row>
      )}
    </>
  );
};

const HeaderCell: FC<
  {
    label: string | boolean;
    description?: string | null;
    isSorted: boolean;
  } & BoxProps
> = ({ label, description, isSorted, ...props }) => {
  return (
    <Row
      align="center"
      p={4}
      flex={1}
      minHeight={0}
      {...props}
      borderColor="base.border"
      gap={1}
    >
      <TextWithTooltip
        color="text.secondary"
        size="sm"
        textTransform="uppercase"
      >
        {label}
      </TextWithTooltip>

      {description && (
        <Tooltip message={`Attribution method: ${description}`}>
          <Row as={Text} color="text.secondary">
            <InformationIcon ml={1} />
          </Row>
        </Tooltip>
      )}

      {isSorted && (
        <Box fontSize={18}>
          <SortVerticalDownIcon />
        </Box>
      )}
    </Row>
  );
};

const Cell: FC<PropsWithChildren<BoxProps>> = ({ children, ...props }) => {
  return (
    <Row align="center" height="100%" width="100%" px={4} gap={2} {...props}>
      {children}
    </Row>
  );
};

const ColumnValue: FC<
  {
    displayFormatting?: MetricDisplayFormatting;
    column?: CampaignTableRow[keyof CampaignTableRow];
  } & Omit<TextProps, "children">
> = ({ column, displayFormatting, ...textProps }) => {
  if (!column || column.value == null) {
    if (NumberColumns.includes(column?.type as ColumnType)) {
      return (
        <Row
          width="100%"
          justify="flex-end"
          sx={{
            span: {
              fontVariantNumeric: "lining-nums tabular-nums slashed-zero",
              fontFamily: "Inter",
            },
          }}
        >
          <Text {...textProps}>--</Text>
        </Row>
      );
    }

    return <Text {...textProps}>--</Text>;
  }

  if (column.type === ColumnType.Boolean) {
    return (
      <TextWithTooltip {...textProps}>
        {column.value.toString()}
      </TextWithTooltip>
    );
  }

  if (column.type === ColumnType.Date || column.type === ColumnType.Timestamp) {
    return (
      <TextWithTooltip {...textProps}>
        {formatDateOrDatetime(
          column.value.toString(),
          column.type === ColumnType.Timestamp,
        ) ?? column.value}
      </TextWithTooltip>
    );
  }

  if (NumberColumns.includes(column.type as ColumnType)) {
    return (
      <Row
        width="100%"
        justify="flex-end"
        sx={{
          span: {
            fontVariantNumeric: "lining-nums tabular-nums slashed-zero",
            fontFamily: "Inter",
          },
        }}
      >
        <TextWithTooltip {...textProps}>
          {formatNumericMetric(Number(column.value), displayFormatting)}
        </TextWithTooltip>
      </Row>
    );
  }

  return <TextWithTooltip {...textProps}>{column.value}</TextWithTooltip>;
};
