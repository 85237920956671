import { useEffect, useState, FC } from "react";

import {
  Column,
  Row,
  IconButton,
  Spinner,
  Text,
  SearchInput,
  RefreshIcon,
} from "@hightouchio/ui";

import { Link } from "src/router";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { LookerIcon } from "src/ui/icons";

import {
  LookerLook,
  useGetLookerLookQuery,
  useSearchLookerLooksQuery,
} from "src/graphql";
import { SimplePagination } from "src/ui/table";
import { LookerQuery } from "./preview/looker-query";
import { SelectorRow } from "./selector-row";

type Props = {
  lookerLookId?: string | null;
  onChange: (lookerLookId: string) => void;
  source: {
    name: string;
    definition: {
      name: string;
      icon: string;
    };
  };
};

export const LookerSelector: FC<Readonly<Props>> = ({
  lookerLookId,
  onChange,
  source,
}) => {
  const [page, setPage] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  const [nextLoading, setNextLoading] = useState<boolean>(false);
  const [previousLoading, setPreviousLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [debouncedSearch, setDebouncedSearch] = useState<string>("");
  const [autoFillSearch, setAutoFillSearch] = useState<boolean>(true);
  const [exactSearch, setExactSearch] = useState<boolean>(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearch(search);
    }, 350);

    return () => {
      clearTimeout(timer);
    };
  }, [search]);

  // Looker instances with a lot of Looks can choke on larger page sizes
  const pageSize = 5;

  const { data: lookerLookQuery } = useGetLookerLookQuery(
    { id: lookerLookId ?? "", withSql: true },
    { enabled: lookerLookId != null && lookerLookId !== "" },
  );
  const look = lookerLookQuery?.getLookerLook?.look as
    | (Omit<LookerLook, "sql"> & { sql: string })
    | undefined;

  // Exact search when we first load page with existing Look set for a model.
  // Otherwise make every search a substring search.
  // NOTE: This condition works because we when we have a currently selected Look,
  // we set `search` to the Look's title upon loading. Else `search` is an empty string
  // to begin with. Additionally, `exactSearch` is set to true when we first load the page but is
  // reset to false when we change anything in the search input.
  let searchTitle: string;
  if (exactSearch && search) {
    searchTitle = look?.title ?? "";
  } else {
    searchTitle = "%" + (debouncedSearch ? debouncedSearch + "%" : "");
  }

  const {
    data: searchQuery,
    error: searchError,
    isLoading: searchLoading,
    refetch: searchAgain,
  } = useSearchLookerLooksQuery({
    title: searchTitle,
    limit: pageSize,
    offset,
  });

  const looks = searchQuery?.searchLookerLooks?.looks ?? [];
  const noLooks = !searchLoading && !looks?.length;
  const activeLookOnPage = !!looks?.find(({ id }) => id === lookerLookId);

  useEffect(() => {
    if (nextLoading) {
      setPage((page) => page + 1);
      setNextLoading(false);
    }
    if (previousLoading) {
      setPage((page) => page - 1);
      setPreviousLoading(false);
    }
  }, [looks, setPage, setPreviousLoading, setNextLoading]);

  // Auto-fills the search field with the currently selected Looker Look title.
  function getSearchValue(look: LookerLook | undefined) {
    if (autoFillSearch && look) {
      setAutoFillSearch(false);
      setSearch(look.title);
    }

    return search;
  }

  // TODO structured error codes
  const narrowYouSearchError =
    searchError?.message?.includes("narrow your search");

  if (searchError && !narrowYouSearchError) {
    return (
      <Column
        sx={{
          pt: 10,
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Text color="danger.base">
          We were not able to connect to your Looker instance.
        </Text>
        <Text color="danger.base">
          Please check that your Looker credentials are configured correctly in{" "}
          <Link href="/extensions/looker">settings</Link>.
        </Text>
      </Column>
    );
  }

  return (
    <Row gap={4} overflow="hidden">
      <Column
        width="100%"
        border="1px"
        borderColor="base.border"
        borderRadius="md"
        overflow="hidden"
        minWidth={0}
      >
        <Row
          align="center"
          px={4}
          py={4}
          borderBottom="1px"
          borderColor="base.border"
          gap={4}
          justify="space-between"
        >
          <Row align="center" gap={2}>
            <IntegrationIcon
              src={source?.definition.icon}
              name={source?.definition.name}
            />
            <Text fontWeight="medium" size="lg">
              {source?.name ?? "Private source"}
            </Text>
          </Row>

          <Row align="center" gap={2}>
            <SearchInput
              placeholder="Search for Looks by title..."
              value={getSearchValue(look)}
              onChange={(event) => {
                // Ensures that if we do have a currently selected Look, we only
                // perform an exact search for it on the first render.
                if (exactSearch) {
                  setExactSearch(false);
                }
                setSearch(event.target.value);
                setPage(0);
                setOffset(0);
              }}
            />
            <IconButton
              variant="secondary"
              icon={RefreshIcon}
              aria-label="Refresh Looks"
              isDisabled={searchLoading}
              onClick={() => searchAgain()}
            />
          </Row>
        </Row>
        {searchLoading ? (
          <Column
            sx={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              p: 10,
            }}
          >
            <Spinner size="lg" />
          </Column>
        ) : noLooks && page === 0 ? (
          <Column
            sx={{
              pt: 10,
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            {narrowYouSearchError ? (
              <Text color="danger.base">{searchError?.message}</Text>
            ) : search ? (
              <Text color="danger.base">
                We could not find any Looks matching your search.
              </Text>
            ) : (
              <Text>Enter a title in the search bar to find Looks.</Text>
            )}
          </Column>
        ) : (
          <>
            <Row sx={{ overflow: "hidden", flex: 1 }}>
              <Column sx={{ overflow: "auto", flex: 1 }}>
                {page !== 0 && !looks?.length ? (
                  <Row sx={{ alignItems: "center", justifyContent: "center" }}>
                    <Text>
                      No more Looks! Why don&apos;t you go back a page?
                    </Text>
                  </Row>
                ) : (
                  looks?.map((look) => {
                    const activeLook = look?.id === lookerLookId;
                    return (
                      <SelectorRow
                        key={look?.id}
                        icon={<LookerIcon size={16} />}
                        selected={activeLook}
                        onClick={() => {
                          // Prevents auto-fill when clicking on different Looks.
                          if (autoFillSearch) {
                            setAutoFillSearch(false);
                          }
                          onChange(look.id);
                        }}
                      >
                        {look.title}
                      </SelectorRow>
                    );
                  })
                )}
                {looks.length >= pageSize && (
                  <SimplePagination
                    nextLoading={nextLoading}
                    page={page}
                    previousLoading={previousLoading}
                    onNext={() => {
                      if (looks.length < pageSize) {
                        // no more Looks
                        return;
                      }
                      setNextLoading(true);
                      setOffset(offset + pageSize);
                    }}
                    onPrevious={() => {
                      setPreviousLoading(true);
                      setOffset(offset - pageSize);
                    }}
                  />
                )}
              </Column>
            </Row>
          </>
        )}
      </Column>
      {activeLookOnPage && look && (
        <Column width="400px">
          <LookerQuery look={look} />
        </Column>
      )}
    </Row>
  );
};
