import {
  Checkbox,
  CheckboxGroup,
  Column,
  Row,
  Text,
  Tooltip,
} from "@hightouchio/ui";
import { Controller, useFormContext } from "react-hook-form";
import { Card } from "src/components/card";
import { GrantableResource } from "src/components/grant-select/types";
import { getCheckboxProps } from "src/components/grant-select/util";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { DEFAULT_GRANT_PLACEHOLDER, names } from "./grant-form";
import { AdditionalConfigurationProps, ResourceDefinition } from "./types";

export function GrantCardForm({
  grantOptions,
  isWorkspaceAdmin,
  resourceType,
  resources,
}: {
  grantOptions: Array<{ value: string; label: string; description: string }>;
  isWorkspaceAdmin: boolean;
  resourceType: GrantableResource;
  resources: Array<ResourceDefinition>;
}) {
  return (
    <Column gap={4}>
      <GrantCard
        id={DEFAULT_GRANT_PLACEHOLDER}
        isWorkspaceAdmin={isWorkspaceAdmin}
        grantOptions={grantOptions}
        isDefaultGrant
      >
        <Column>
          <TextWithTooltip fontWeight="medium">
            {`All ${names[resourceType]} in this workspace`}
          </TextWithTooltip>
          <Text color="text.secondary">
            This includes any new {names[resourceType]} created in the future.
          </Text>
        </Column>
      </GrantCard>
      <Row borderBottom="1px solid" borderColor="base.border" />

      {resources.map((resource) => {
        return (
          <GrantGroup
            key={resource.id}
            isWorkspaceAdmin={isWorkspaceAdmin}
            grantOptions={grantOptions}
            resource={resource}
          />
        );
      })}
    </Column>
  );
}

type GrantGroupProps = {
  resource: ResourceDefinition;
  isWorkspaceAdmin: boolean;
  grantOptions: Array<{ value: string; label: string; description: string }>;
};

function GrantGroup({
  resource,
  isWorkspaceAdmin,
  grantOptions,
}: GrantGroupProps) {
  return (
    <GrantCard
      id={resource.id}
      isWorkspaceAdmin={isWorkspaceAdmin}
      grantOptions={grantOptions}
      additionalConfiguration={resource.additionalConfiguration}
    >
      <Row align="center" gap={2}>
        <IntegrationIcon
          src={resource.definition.icon}
          name={resource.definition.name}
        />
        <TextWithTooltip fontWeight="medium">{resource.name}</TextWithTooltip>
      </Row>
    </GrantCard>
  );
}

type GrantCardProps = {
  id: string;
  children: React.ReactNode;
  isWorkspaceAdmin: boolean;
  grantOptions: Array<{ label: string; description: string; value: string }>;
  isDefaultGrant?: boolean;
  additionalConfiguration?: React.FC<AdditionalConfigurationProps>;
};

function GrantCard({
  id,
  isWorkspaceAdmin,
  grantOptions,
  children,
  isDefaultGrant = false,
  additionalConfiguration,
}: GrantCardProps) {
  const form = useFormContext();

  const defaultGrant = !isDefaultGrant
    ? form.watch(`grants.${DEFAULT_GRANT_PLACEHOLDER}`)
    : undefined;

  const AdditionalConfigurationComponent =
    additionalConfiguration ?? (() => null);

  return (
    <Card background={isDefaultGrant ? "base.lightBackground" : undefined}>
      <Column gap={4}>
        <Row align="center" gap={2}>
          {children}
        </Row>
        <Column>
          <Text fontWeight="medium">Users can:</Text>
          <CheckboxGroup mt={2}>
            {grantOptions.map(({ value, label, description }) => {
              return (
                <Column key={`${id}-${value}`}>
                  <Controller
                    name={`grants.${id}.${value}`}
                    render={({ field }) => {
                      const { checkboxProps, tooltipProps } = getCheckboxProps({
                        value: field.value,
                        defaultValue: defaultGrant?.[value],
                        isWorkspaceAdmin,
                        isBuiltIn: false, // Built-in roles cannot access this form
                      });

                      return (
                        <Tooltip {...tooltipProps}>
                          <Checkbox
                            description={description}
                            label={label}
                            {...checkboxProps}
                            onChange={field.onChange}
                          />
                        </Tooltip>
                      );
                    }}
                  />
                </Column>
              );
            })}
          </CheckboxGroup>
        </Column>
      </Column>
      {Boolean(additionalConfiguration) && (
        <Controller
          name={`additional_data.${id}`}
          render={({ field }) => {
            return (
              <AdditionalConfigurationComponent
                value={field.value}
                onChange={field.onChange}
              />
            );
          }}
        ></Controller>
      )}
    </Card>
  );
}
