import { useEffect, useMemo, useRef, useState } from "react";

import { useToast } from "@hightouchio/ui";
import { isPresent } from "ts-extras";
import * as Sentry from "@sentry/react";

import { validatePropertyCondition } from "src/components/explore/visual/condition-validation";
import { getRawColumn } from "src/components/explore/visual/utils";
import {
  FunnelMetricDataForCohort,
  FunnelMetricDataForCohortOrError,
  useCancelAnalyticsQueryMutation,
  useEvaluateFunnelMetricQuery,
  useGetFunnelMetricResultBackgroundQuery,
} from "src/graphql";
import { ConditionType, PropertyCondition } from "src/types/visual";

import {
  filterMetricDataForCohort,
  getInclusiveTimeWindow,
  isValidDateRange,
  removeDisabledSplitGroupsFromFunnels,
  separateCohortsAndCohortDefinitions,
} from "src/pages/analytics/utils";
import {
  FunnelStep,
  GroupByColumn,
  SelectedAudience,
  TimeOptions,
} from "src/pages/analytics/types";
import { TimeMap } from "src/pages/analytics/state/constants";

type UseFunnelMetrics = {
  completionWindow: number;
  customDateRange: Date[];
  enabled?: boolean;
  groupByColumns: GroupByColumn[];
  parentModelId: string | number | undefined | null;
  selectedAudiences: SelectedAudience[];
  stages: FunnelStep[];
  timeValue: TimeOptions;
};

export const useFunnelMetrics = ({
  completionWindow,
  customDateRange,
  enabled = true,
  groupByColumns,
  parentModelId,
  selectedAudiences,
  stages,
  timeValue,
}: UseFunnelMetrics) => {
  const { toast } = useToast();
  const [isPolling, setIsPolling] = useState(false);
  const previousJobIds = useRef<string[]>([]);

  const cancelAnalyticsQuery = useCancelAnalyticsQueryMutation({
    onSuccess: () => {
      // set `onSuccess` as a noop so running the mutation
      // does not invalidate cache on cancellations
    },
  });

  const { cohortIds, cohortDefinitions } = useMemo(
    () =>
      separateCohortsAndCohortDefinitions(
        // parentModelId _is_ a number, but the type system doesn't know that
        parentModelId as unknown as number | null | undefined,
        selectedAudiences,
      ),
    [selectedAudiences, parentModelId],
  );

  const areStagesValid = stages
    .flatMap(
      ({
        eventModelId,
        relationshipId,
        subconditions,
      }): Record<string, string | null>[] => {
        if (!eventModelId || !relationshipId) {
          return [{ id: "No step has been selected yet" }];
        }

        // Will be wrapped in an 'and' condition
        if (subconditions?.[0]?.type === ConditionType.And) {
          const propertyConditions = subconditions?.[0]
            ?.conditions as PropertyCondition[];
          return propertyConditions?.map((propertyCondition) =>
            validatePropertyCondition(propertyCondition),
          );
        }

        // If not wrapped in an 'and' condition, then the conditions are malformed.
        if (subconditions.length > 0) {
          Sentry.captureException(
            `Conditions are malformed. First item condition type is 'type: ${subconditions?.[0]?.type}'`,
          );
        }
        return [];
      },
    )
    .every(
      (validationResult) =>
        !validationResult || !Object.values(validationResult).some(Boolean),
    );

  const formattedStages = stages
    .filter(
      ({ eventModelId, relationshipId }) => eventModelId && relationshipId,
    )
    .map(({ eventModelId, relationshipId, subconditions }) => ({
      eventModelId: eventModelId!.toString(),
      relationshipId: relationshipId!.toString(),
      filter: { subconditions },
    }));

  // TODO(samuel)
  // Spoke to ernest about a wrapper hook that could
  // apply the on settled, errors, and cancellation logic automatically
  const evaluateFunnelMetricQuery = useEvaluateFunnelMetricQuery(
    {
      cohortDefinitions,
      cohortIds,
      completionWindow, // seconds
      groupByColumns: groupByColumns
        .map(getRawColumn)
        .filter(isPresent)
        .map((column) => ({
          column: { ...column, modelId: column.modelId.toString() },
        })),
      lookbackWindow:
        timeValue !== TimeOptions.Custom ? TimeMap[timeValue] : undefined,
      timeWindow:
        timeValue === TimeOptions.Custom && isValidDateRange(customDateRange)
          ? getInclusiveTimeWindow(customDateRange)
          : undefined,
      stages: formattedStages,
    },
    {
      enabled:
        enabled &&
        areStagesValid &&
        (cohortDefinitions.length > 0 || cohortIds.length > 0) &&
        formattedStages.length > 1,
      keepPreviousData: true,
      notifyOnChangeProps: "tracked",
      onSettled: (data) => {
        if (data) {
          const backgroundJobs = data.evaluateFunnelMetric.backgroundJobs;

          // cancel previous requests
          const newJobsIds = backgroundJobs.map((job) => job.jobId);

          if (newJobsIds.some((id) => !previousJobIds.current.includes(id))) {
            previousJobIds.current.forEach((jobId) =>
              cancelAnalyticsQuery.mutate({ jobId }),
            );
          }

          previousJobIds.current = newJobsIds;
        }
      },
    },
  );

  const backgroundJobs =
    evaluateFunnelMetricQuery.data?.evaluateFunnelMetric.backgroundJobs;

  // If data is empty, keep polling.
  const polledFunnelMetricQuery = useGetFunnelMetricResultBackgroundQuery(
    {
      jobIds: backgroundJobs?.map(({ jobId }) => jobId) ?? [],
    },
    {
      enabled: isPolling && Boolean(backgroundJobs?.length),
      refetchInterval: 3000,
      // when this finishes we should remove the old job ids.
      onSettled: (data) => {
        if (
          data &&
          data.getFunnelMetricResultBackground.data.length ===
            previousJobIds.current.length
        ) {
          previousJobIds.current = [];
        }
      },
    },
  );

  // XXX: Re CohortDefinition type compile issue in `use-metric-series.ts` for
  // `MetricResultMaybeFromCache`
  const immediateData: FunnelMetricDataForCohortOrError[] | undefined = useMemo(
    () =>
      evaluateFunnelMetricQuery.data?.evaluateFunnelMetric?.immediateData?.map(
        (data) => ({
          ...data,
          cohort: {
            ...data.cohort,
            cohortDefinition:
              data.cohort.cohortDefinition != null
                ? { name: null, ...data.cohort.cohortDefinition }
                : null,
          },
        }),
      ),
    [evaluateFunnelMetricQuery.data],
  );
  const delayedData: FunnelMetricDataForCohortOrError[] | undefined = useMemo(
    () =>
      polledFunnelMetricQuery.data?.getFunnelMetricResultBackground.data.flatMap(
        (data) => data.data,
      ) as FunnelMetricDataForCohort[], // this type is right but the system doesn't know it
    [polledFunnelMetricQuery.data],
  );
  const pollingError = polledFunnelMetricQuery.error;

  // Create a stable reference of allData so that there
  // is a stable reference for side effects.
  const allData: FunnelMetricDataForCohort[] = useMemo(() => {
    let result = filterMetricDataForCohort(immediateData ?? []);

    if (backgroundJobs?.length) {
      result = result.concat(filterMetricDataForCohort(delayedData ?? []));
    }

    result = removeDisabledSplitGroupsFromFunnels(result, selectedAudiences);

    return result;
  }, [immediateData, backgroundJobs?.length, delayedData, selectedAudiences]);

  const errorMessagesByCohortId = useMemo(() => {
    let hasError = false;
    const errorMessagesDictionary = {};

    immediateData?.forEach(({ cohort, ...rest }) => {
      if ("error" in rest) {
        const id = cohort.cohortDefinition?.parentModelId ?? cohort.cohortId;

        if (id) {
          errorMessagesDictionary[id] = rest.error;
        }

        hasError = true;
      }
    });

    delayedData?.forEach(({ cohort, ...rest }) => {
      if ("error" in rest) {
        const id = cohort.cohortDefinition?.parentModelId ?? cohort.cohortId;

        if (id) {
          errorMessagesDictionary[id] = rest.error;
        }

        hasError = true;
      }
    });

    if (hasError) {
      toast({
        id: "metric-series",
        title: "There was an error in one or more calculations",
        message: "Check the sidebar for more information",
        variant: "error",
      });
    }

    return errorMessagesDictionary;
  }, [immediateData, delayedData]);

  // Poll data as long as data array is empty and the initial request didn't
  // return any data in immediateData.
  useEffect(() => {
    const analyticsStarted =
      evaluateFunnelMetricQuery.isLoading ||
      evaluateFunnelMetricQuery.isRefetching ||
      (backgroundJobs !== undefined && backgroundJobs.length > 0);
    const shouldPoll =
      !pollingError &&
      analyticsStarted &&
      (!delayedData ||
        !backgroundJobs ||
        delayedData.length < backgroundJobs.length);

    setIsPolling(shouldPoll);
  }, [delayedData, immediateData, backgroundJobs, pollingError]);

  return {
    isPolling:
      isPolling ||
      evaluateFunnelMetricQuery.isLoading ||
      evaluateFunnelMetricQuery.isRefetching ||
      polledFunnelMetricQuery.isLoading,
    data: allData,
    pollingError,
    errors: errorMessagesByCohortId,
  };
};
