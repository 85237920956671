import { FC, useMemo } from "react";

import {
  Column,
  Combobox,
  Checkbox,
  FormField,
  IconButton,
  RefreshIcon,
  Row,
  TextInput,
  GroupedCombobox,
} from "@hightouchio/ui";
import * as Yup from "yup";

import { useDestinationForm } from "src/contexts/destination-form-context";
// eslint-disable-next-line no-restricted-imports
import { Section } from "src/ui/section";
import { COMMON_SCHEMAS } from "src/utils/destinations";

import { MappingsField } from "src/components/destinations/mappings-field";

export const validation = Yup.object().shape(
  {
    eventType: Yup.string().required(),
    addEventName: Yup.string().when("eventType", {
      is: "track",
      then: Yup.string().required(),
      otherwise: Yup.string().notRequired(),
    }),
    removeEventName: Yup.string().when("eventType", {
      is: "track",
      then: Yup.string().required(),
      otherwise: Yup.string().notRequired(),
    }),
    anonymousIdFrom: Yup.mixed().when("userIdFrom", {
      is: (v) => v,
      then: Yup.mixed().notRequired(),
      otherwise: Yup.mixed().required(
        "Either anonymous ID or user ID column must be selected",
      ),
    }),
    userIdFrom: Yup.mixed().when("anonymousIdFrom", {
      is: (v) => v,
      then: Yup.mixed().notRequired(),
      otherwise: Yup.mixed().required(
        "Either anonymous ID or user ID column must be selected",
      ),
    }),
    sendInitialEvents: Yup.boolean().notRequired(),
    mappings: COMMON_SCHEMAS.mappings,
  },
  [["anonymousIdFrom", "userIdFrom"]],
);

const EVENTS = [
  { label: "Track", value: "track" },
  { label: "Identify", value: "identify" },
];

export const RudderStackForm: FC = () => {
  const {
    hightouchColumns,
    errors,
    config,
    setConfig,
    loadingModel,
    reloadModel,
  } = useDestinationForm();

  const hightouchColumnGroups = useMemo(() => {
    return hightouchColumns.map((group) => ({
      ...group,
      options: group.options ?? [],
    }));
  }, [hightouchColumns]);

  return (
    <>
      <Section>
        <FormField
          error={errors?.eventType}
          label="Which event type would you like to send?"
        >
          <Combobox
            isInvalid={errors?.eventType}
            options={EVENTS}
            placeholder="Select event type..."
            value={config?.eventType}
            onChange={(val) => {
              setConfig({ eventType: val });
            }}
          />
        </FormField>
      </Section>

      {config?.eventType && (
        <Section>
          <Column gap={8}>
            <FormField
              description="Either anonymous ID or user ID column must be selected."
              error={errors?.userIdFrom}
              label="Which column contains the event user ID?"
            >
              <Row align="center" gap={2}>
                <GroupedCombobox
                  isClearable
                  isInvalid={Boolean(errors?.userIdFrom)}
                  isLoading={loadingModel}
                  optionGroups={hightouchColumnGroups}
                  placeholder="Select a column..."
                  value={config?.userIdFrom}
                  onChange={(val) => {
                    setConfig({ ...config, userIdFrom: val });
                  }}
                />
                <IconButton
                  icon={RefreshIcon}
                  aria-label="Refresh columns"
                  isLoading={loadingModel}
                  onClick={reloadModel}
                />
              </Row>
            </FormField>

            <FormField
              description="Either anonymous ID or user ID column must be selected."
              error={errors?.anonymousIdFrom}
              label="Which column contains the event anonymous ID?"
            >
              <Row align="center" gap={2}>
                <GroupedCombobox
                  isClearable
                  isInvalid={Boolean(errors?.anonymousIdFrom)}
                  isLoading={loadingModel}
                  optionGroups={hightouchColumnGroups}
                  placeholder="Select a column..."
                  value={config?.anonymousIdFrom}
                  onChange={(val) => {
                    setConfig({ ...config, anonymousIdFrom: val });
                  }}
                />
                <IconButton
                  icon={RefreshIcon}
                  aria-label="Refresh columns"
                  isLoading={loadingModel}
                  onClick={reloadModel}
                />
              </Row>
            </FormField>
            {config.eventType === "track" && (
              <>
                <Checkbox
                  label="Send events for initial result set?"
                  isChecked={Boolean(config.sendInitialEvents)}
                  onChange={(event) => {
                    setConfig({
                      ...config,
                      sendInitialEvents: event.target.checked,
                    });
                  }}
                />

                <FormField
                  error={errors?.addEventName}
                  label="What is the added rows event name?"
                >
                  <TextInput
                    value={config?.addEventName}
                    placeholder="Enter event name..."
                    onChange={(e) => {
                      setConfig({
                        ...config,
                        addEventName: e.target.value,
                      });
                    }}
                  />
                </FormField>

                <FormField
                  error={errors?.removeEventName}
                  label="What is the removed rows event name?"
                >
                  <TextInput
                    value={config?.removeEventName}
                    placeholder="Enter event name..."
                    onChange={(e) => {
                      setConfig({
                        ...config,
                        removeEventName: e.target.value,
                      });
                    }}
                  />
                </FormField>
              </>
            )}
          </Column>
        </Section>
      )}

      {config?.eventType && (
        <Section>
          <MappingsField />
        </Section>
      )}
    </>
  );
};

export default {
  form: RudderStackForm,
  validation,
};
