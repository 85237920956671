import { FC } from "react";

import { Box, Text, CheckIcon, BoxProps } from "@hightouchio/ui";

export const CheckList: FC<Readonly<BoxProps>> = ({ children, ...props }) => {
  return (
    <Box
      as="ul"
      display="flex"
      flexDirection="column"
      gap={3}
      mx="auto"
      my={6}
      p={0}
      {...props}
    >
      {children}
    </Box>
  );
};

export const CheckListItem: FC<Readonly<BoxProps>> = ({
  children,
  ...props
}) => {
  return (
    <Box as="li" display="flex" gap={2}>
      <Box
        alignItems="center"
        bg="grass.200"
        borderRadius="full"
        boxSize={5}
        display="flex"
        flex="none"
        fontSize="12px"
        justifyContent="center"
        position="relative"
        {...props}
      >
        <CheckIcon color="grass.800" />
      </Box>

      <Text>{children}</Text>
    </Box>
  );
};
