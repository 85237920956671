import { useToast } from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import immutableUpdate from "immutability-helper";

import { useUpdateAudienceMutation } from "src/graphql";
import {
  AdditionalColumn,
  EventCondition,
  VisualQueryFilter,
} from "src/types/visual";

type MinimalAudience = {
  id: string;
  visual_query_filter: VisualQueryFilter;
};

export type FilteredEvent = Omit<EventCondition, "operator" | "value">;

export const useAddAdditionalColumn = () => {
  const { mutateAsync: updateAudience } = useUpdateAudienceMutation();
  const { toast } = useToast();

  const addAdditionalColumn = async ({
    audience,
    additionalColumn,
    onSuccess,
  }: {
    audience: MinimalAudience;
    additionalColumn: AdditionalColumn;
    onSuccess?: () => void;
  }) => {
    try {
      await updateAudience({
        id: audience.id,
        input: {
          visual_query_filter: {
            ...(audience.visual_query_filter || {}),
            additionalColumns: [
              additionalColumn,
              ...(audience.visual_query_filter?.additionalColumns || []),
            ],
          },
        },
      });

      toast({
        id: "create-trait-enrichment",
        variant: "success",
        title: "Trait enrichment was saved successfully",
      });

      onSuccess?.();
    } catch (error) {
      toast({
        id: "create-trait-enrichment",
        variant: "error",
        title: "Trait enrichment failed to be saved",
        message: error.message,
      });
      Sentry.captureException(error);
    }
  };

  return addAdditionalColumn;
};

export const useUpdateAdditionalColumn = () => {
  const { mutateAsync: updateAudience } = useUpdateAudienceMutation();
  const { toast } = useToast();

  const updateAdditionalColumn = async ({
    audience,
    additionalColumn,
    index,
    onSuccess,
  }: {
    audience: MinimalAudience;
    additionalColumn: AdditionalColumn;
    index: number;
    onSuccess?: () => void;
  }) => {
    try {
      const updateVisualQueryFilter = immutableUpdate(
        audience.visual_query_filter,
        {
          additionalColumns: {
            [index]: {
              $set: additionalColumn,
            },
          },
        },
      );

      await updateAudience({
        id: audience.id,
        input: {
          visual_query_filter: updateVisualQueryFilter,
        },
      });

      toast({
        id: "edit-trait-enrichment",
        variant: "success",
        title: "Trait enrichment was saved successfully",
      });

      onSuccess?.();
    } catch (error) {
      toast({
        id: "edit-trait-enrichment",
        variant: "error",
        title: "Trait enrichment failed to be saved",
        message: error.message,
      });
      Sentry.captureException(error);
    }
  };

  return updateAdditionalColumn;
};

export const useRemoveAdditionalColumn = () => {
  const { mutateAsync: updateAudience } = useUpdateAudienceMutation();
  const { toast } = useToast();

  const removeAdditionalColumn = async ({
    audience,
    index,
    onSuccess,
  }: {
    audience: MinimalAudience;
    index: number;
    onSuccess?: () => void;
  }) => {
    try {
      const updateVisualQueryFilter = immutableUpdate(
        audience.visual_query_filter,
        {
          additionalColumns: {
            $splice: [[index, 1]],
          },
        },
      );

      await updateAudience({
        id: audience.id,
        input: {
          visual_query_filter: updateVisualQueryFilter,
        },
      });

      toast({
        id: "delete-trait-enrichment",
        variant: "success",
        title: "Trait enrichment was deleted successfully",
      });

      onSuccess?.();
    } catch (error) {
      toast({
        id: "delete-trait-enrichment",
        variant: "error",
        title: "Trait enrichment failed to be deleted",
        message: error.message,
      });
      Sentry.captureException(error);
    }
  };

  return removeAdditionalColumn;
};
