import { FC, useRef } from "react";

import {
  Column,
  Heading,
  Row,
  Box,
  SectionHeading,
  EmptyState,
  Paragraph,
  Spinner,
} from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import Helmet from "react-helmet";
import { Controller, FormProvider } from "react-hook-form";
import { useNavigate } from "src/router";
import * as yup from "yup";

import { ScheduleManager } from "src/components/schedule";
import { Schedule, ScheduleType } from "src/components/schedule/types";
import {
  eventWarehouseDestinationDefinitions,
  eventSourceDefinitions,
  eventForwardingDestinationDefinitions,
} from "src/events/types";
import {
  DestinationsQuery,
  EventSourcesQuery,
  useCreateEventForwardingSyncMutation,
  useCreateEventWarehouseSyncMutation,
  useDestinationsQuery,
  useEventForwardingEventDefinitionQuery,
  useEventForwardingEventTypesQuery,
  useEventForwardingSharedDefinitionQuery,
  useEventSourcesQuery,
  useEventWarehouseDestinationsQuery,
  useFormkitEventConfigValidationQuery,
} from "src/graphql";
import { TrackView } from "src/lib/analytics";
import { Selectable } from "src/ui/selectable";
import { DeprecatedWizard, WizardStep } from "src/components/wizard";
import { SlugResourceType, useSlug } from "src/utils/slug";
import { useWizardStepper } from "src/utils/use-wizard-stepper";
import { useHightouchForm } from "src/components/form";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { useFlags } from "launchdarkly-react-client-sdk";
import { EventForwardingConfig } from "./forwarding-sync/types";
import EventConfigurationForm from "./forwarding-sync/components/events/event-configuration-form";
import ConfigurationForm from "./forwarding-sync/components/events/configuration-form";
import { TextWithTooltip } from "src/components/text-with-tooltip";

const IntegrationSelect = ({
  name,
  icon,
  onSelect,
}: {
  name: string;
  icon: string;
  onSelect: () => void;
}) => {
  return (
    <Selectable selected={false} onSelect={onSelect}>
      <Row align="center" p={5} gap={4} overflow="hidden">
        <IntegrationIcon name={name} src={icon} size={8} />
        <TextWithTooltip fontWeight="medium" size="lg">
          {name}
        </TextWithTooltip>
      </Row>
    </Selectable>
  );
};

export const CreateEventWarehouseSync: FC = () => {
  const navigate = useNavigate();
  const [step, setStep] = useWizardStepper(0);
  const { getSlug } = useSlug();
  const { eventForwarding } = useFlags();
  const submitConfig = useRef<() => Promise<any | undefined>>();

  const {
    data: warehouseDestinations,
    isLoading: warehouseDestinationsLoading,
  } = useEventWarehouseDestinationsQuery(undefined, {
    select: (data) => data.event_warehouse_destinations,
  });
  const {
    data: forwardingDestinations,
    isLoading: forwardingDestinationsLoading,
  } = useDestinationsQuery(
    {
      filters: {
        type: {
          _in: Object.keys(eventForwardingDestinationDefinitions),
        },
      },
    },
    { select: (data) => data.destinations },
  );
  const { data: sources, isLoading: sourcesLoading } = useEventSourcesQuery(
    undefined,
    {
      select: (data) => data.event_sources,
    },
  );

  const createWarehouseSyncMutation = useCreateEventWarehouseSyncMutation();
  const createEventForwardingSyncMutation =
    useCreateEventForwardingSyncMutation();

  const form = useHightouchForm({
    success: "Event sync created",
    error: "Event sync could not be created",
    onSubmit: async ({ source, destination, schedule }) => {
      let id;
      const isForwarding = Boolean(
        eventForwardingDestinationDefinitions[destination.type],
      );
      const slug = await getSlug(
        isForwarding
          ? SlugResourceType.EventForwardingSyncs
          : SlugResourceType.EventWarehouseSyncs,
        `${source?.name}-${destination?.name}`,
      );
      if (isForwarding) {
        let cleanedConfig: EventForwardingConfig;
        if (submitConfig.current) {
          cleanedConfig = await submitConfig.current();
        } else {
          cleanedConfig = {
            subscriptions: [],
          };
        }

        const response = await createEventForwardingSyncMutation.mutateAsync({
          input: {
            slug,
            event_source_id: source.id,
            destination_id: destination.id,
            config: cleanedConfig,
          },
        });
        id = response.insert_event_forwarding_syncs_one?.id;
      } else {
        const response = await createWarehouseSyncMutation.mutateAsync({
          input: {
            slug,
            event_source_id: source.id,
            event_warehouse_destination_id: destination.id,
            schedule: schedule,
          },
        });
        id = response.createEventWarehouseSync.id;
      }
      navigate(`/events/syncs/${id}`);
    },
    // Rethrow all errors to wizard
    onError: (e) => {
      throw e;
    },
    defaultValues: {
      source: {} as EventSourcesQuery["event_sources"][0],
      destination: {} as DestinationsQuery["destinations"][0],
      schedule: { type: ScheduleType.INTERVAL } as Schedule,
    },
    resolver: yupResolver(validationSchema),
  });

  const destination = form.watch("destination");
  const source = form.watch("source");
  const definition = eventForwardingDestinationDefinitions[destination.type];
  const isForwarding = Boolean(definition);

  const {
    error: formkitDefinitionError,
    data,
    isLoading: formkitDefinitionLoading,
  } = useEventForwardingSharedDefinitionQuery(
    {
      type: destination.type,
    },
    { enabled: isForwarding },
  );
  const { data: eventData, isLoading: formkitEventDefinitionLoading } =
    useEventForwardingEventDefinitionQuery(
      {
        type: destination.type,
      },
      { enabled: isForwarding },
    );
  const { data: eventTypes, isLoading: eventsLoading } =
    useEventForwardingEventTypesQuery(
      {
        type: destination.type,
      },
      { enabled: isForwarding },
    );
  const formkitDefinition =
    data?.eventForwardingSharedDefinition ??
    (!eventTypes?.eventForwardingEvents?.length &&
      eventData?.eventForwardingEventDefinition);

  const steps: WizardStep[] = [
    {
      title: "Select a source",
      header: <Heading>Select a source</Heading>,
      continue: "Click a source to continue",
      render: () => {
        if (sourcesLoading) {
          return <Spinner size="lg" m="auto" />;
        }
        return (
          <Controller
            name="source"
            render={({ field }) => (
              <Box
                display="grid"
                gridTemplateColumns="repeat(auto-fit, minmax(200px, 400px))"
                gridAutoRows="64px"
                gap={4}
                maxWidth="800px"
              >
                {sources?.map((source) => {
                  const definition = eventSourceDefinitions[source.type];

                  const onSelect = () => {
                    field.onChange(source);
                    setStep((step) => step + 1);
                  };
                  return (
                    <IntegrationSelect
                      key={source.id}
                      name={source.name}
                      icon={definition?.icon}
                      onSelect={onSelect}
                    />
                  );
                })}
              </Box>
            )}
          />
        );
      },
    },
    {
      title: "Select a destination",
      header: <Heading>Select a destination</Heading>,
      continue: "Click a destination to continue",
      render: () => {
        if (warehouseDestinationsLoading || forwardingDestinationsLoading) {
          return <Spinner size="lg" m="auto" />;
        }
        return (
          <Controller
            name="destination"
            render={({ field }) => (
              <Column gap={8} maxWidth="800px">
                <Column gap={4}>
                  <SectionHeading>Event storage</SectionHeading>
                  {warehouseDestinations?.length ? (
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(auto-fit, 300px)"
                      gridAutoRows="64px"
                      gap={4}
                    >
                      {warehouseDestinations?.map((destination) => {
                        const { icon } =
                          eventWarehouseDestinationDefinitions[
                            destination.type
                          ];

                        const onSelect = () => {
                          field.onChange(destination);
                          setStep((step) => step + 1);
                        };
                        return (
                          <IntegrationSelect
                            key={destination.id}
                            name={destination.name}
                            icon={icon}
                            onSelect={onSelect}
                          />
                        );
                      })}
                    </Box>
                  ) : (
                    <EmptyState message="No event storage destinations exist in this workspace." />
                  )}
                </Column>
                {eventForwarding && (
                  <Column gap={4}>
                    <SectionHeading>Event forwarding</SectionHeading>
                    {forwardingDestinations?.length ? (
                      <Box
                        display="grid"
                        gridTemplateColumns="repeat(auto-fit, 300px)"
                        gridAutoRows="64px"
                        gap={4}
                      >
                        {forwardingDestinations.map((destination) => {
                          const { icon } =
                            eventForwardingDestinationDefinitions[
                              destination.type
                            ];
                          const onSelect = () => {
                            field.onChange(destination);
                            setStep((step) => step + 1);
                          };
                          return (
                            <IntegrationSelect
                              key={destination.id}
                              name={destination.name || "Destination"}
                              icon={icon}
                              onSelect={onSelect}
                            />
                          );
                        })}
                      </Box>
                    ) : (
                      <EmptyState message="No forwarding destinations" />
                    )}
                  </Column>
                )}
              </Column>
            )}
          />
        );
      },
    },
    isForwarding
      ? {
          title: "Configure",
          header: (
            <Heading>
              <Row gap={4} align="center">
                <IntegrationIcon
                  src={destination.definition.icon}
                  name={destination.definition.name}
                />
                Configure event forwarding to {destination.definition.name}
              </Row>
            </Heading>
          ),
          disabled: formkitDefinitionLoading && !formkitDefinitionError,
          render: () => {
            return (
              <Column gap={8} width="100%">
                {(formkitDefinitionLoading && !formkitDefinitionError) ||
                eventsLoading ||
                formkitEventDefinitionLoading ? (
                  <Spinner size="lg" m="auto" />
                ) : (
                  <>
                    {formkitDefinition &&
                      (data?.eventForwardingSharedDefinition ? (
                        <ConfigurationForm
                          destination={destination}
                          source={source}
                          formkitDefinition={formkitDefinition}
                          config={{}}
                          submit={async (sharedConfig) => ({
                            sharedConfig,
                            subscriptions: [],
                          })}
                          submitTrigger={submitConfig}
                        />
                      ) : (
                        <EventConfigurationForm
                          destination={destination}
                          source={source}
                          formkitDefinition={formkitDefinition}
                          hideActions={true}
                          config={{
                            syncConfig: {},
                          }}
                          otherFilters={false}
                          validation={useFormkitEventConfigValidationQuery}
                          submit={async ({ filter, syncConfig }) => ({
                            sharedConfig: {},
                            subscriptions: [
                              {
                                trackedEvent: "",
                                filter,
                                syncConfig,
                              },
                            ],
                          })}
                          submitTrigger={submitConfig}
                        />
                      ))}
                    {(eventTypes?.eventForwardingEvents?.length ?? 0) > 0 && (
                      <>
                        {formkitDefinition && (
                          <Row
                            borderTop="1px solid"
                            borderColor="base.border"
                          />
                        )}
                        <Column gap={8} maxWidth="336px">
                          <Column gap={2}>
                            <SectionHeading>
                              Next steps to configure
                            </SectionHeading>
                            <Paragraph>
                              Within the configuration tab of the sync configure
                              standard and custom events. No events will be
                              forwarded until they’ve been defined.
                            </Paragraph>
                          </Column>
                          <Column gap={2}>
                            <SectionHeading>1. Standard events</SectionHeading>
                            <Paragraph>
                              These are supported standard events for{" "}
                              {destination.definition.name}. Select and
                              configure applicable matching events
                            </Paragraph>
                          </Column>
                          <Column gap={2}>
                            <SectionHeading>2. Custom events</SectionHeading>
                            <Paragraph>
                              Any remaining events not mapped to standard events
                              can be processed through custom events.
                            </Paragraph>
                          </Column>
                        </Column>
                      </>
                    )}
                  </>
                )}
              </Column>
            );
          },
        }
      : {
          title: "Configure",
          header: <Heading>Set your schedule</Heading>,
          render: () => {
            return (
              <Column gap={8} maxWidth="576px" width="100%">
                <Controller
                  name="schedule"
                  render={({ field }) => (
                    <ScheduleManager
                      resource="event_sync"
                      schedule={field.value}
                      setSchedule={field.onChange}
                      types={[
                        ScheduleType.MANUAL,
                        ScheduleType.INTERVAL,
                        ScheduleType.CRON,
                      ]}
                      includeStartAndEnd={false} // Bounded schedules are too confusing for users.
                    />
                  )}
                />
              </Column>
            );
          },
        },
  ];

  return (
    <>
      <Helmet>
        <title>New event sync</title>
      </Helmet>
      <TrackView name="New Event Sync Page" />
      <FormProvider {...form}>
        <DeprecatedWizard
          setStep={setStep}
          step={step}
          steps={steps}
          title="New event sync"
          onCancel={() => {
            navigate("/events/syncs");
          }}
          onSubmit={form.submit}
        />
      </FormProvider>
    </>
  );
};

const validationSchema = yup.object().shape({
  schedule: yup.object().required(),
});
