import { FC } from "react";

import { ArrowRightIcon, BoxProps, Row, RowProps } from "@hightouchio/ui";

type ArrowProps = {
  color?: string;
  size?: string;
} & BoxProps;

export const Arrow: FC<Readonly<ArrowProps & Omit<RowProps, "color">>> = ({
  color = "text.tertiary",
  size = "16px",
  ...props
}) => (
  <Row fontSize={size} mx={4} {...props}>
    <ArrowRightIcon color={color} />
  </Row>
);
