import { JourneyStatus } from "src/types/journeys";
import { JourneyState } from "src/pages/journeys/types";

type GetJourneyState = {
  reset?: boolean;
  status: JourneyStatus;
  numberOfUsersInJourney: number;
  numberOfPreviousRuns: number;
};

/**
 * Determines the visible state of the journey
 */
export const getJourneyState = ({
  reset,
  status,
  numberOfUsersInJourney,
  numberOfPreviousRuns,
}: GetJourneyState): JourneyState => {
  if (reset) {
    return "resetting";
  }

  // If enabled, it's live
  if (status === JourneyStatus.Enabled) {
    return "live";
  }

  if (status === JourneyStatus.Draining) {
    return "draining";
  }

  if (numberOfUsersInJourney === 0) {
    if (numberOfPreviousRuns === 0) {
      return "draft";
    }

    return "off";
  }

  return "paused";
};
