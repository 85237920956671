import {
  Column,
  DrawerBody,
  DrawerFooter,
  SectionHeading,
} from "@hightouchio/ui";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Form, FormActions, useHightouchForm } from "src/components/form";
import { useParams } from "src/router";
import { Fallback } from "./components/fallback";
import { useCollectionQuery, useUpdateCollectionMutation } from "src/graphql";

const schema = yup.object().shape({
  config: yup.object().required(),
});

export const CollectionConfiguration = () => {
  const { collectionId } = useParams<{ collectionId: string }>();
  const { data: collection } = useCollectionQuery(
    {
      id: collectionId ?? "",
    },
    {
      select: (data) => data.decision_engine_collections_by_pk,
      suspense: true,
    },
  );

  const updateCollectionMutation = useUpdateCollectionMutation();

  const form = useHightouchForm({
    resolver: yupResolver(schema),
    onSubmit: async (data) => {
      await updateCollectionMutation.mutateAsync({
        catalogCollectionId: collection!.collection.id,
        config: data.config,
        filter: collection!.collection.filter,
      });
    },
    values: {
      config: collection!.config,
    },
  });

  return (
    <Form form={form}>
      <DrawerBody>
        <Column gap={6}>
          <SectionHeading>Configuration</SectionHeading>
          <Fallback />
        </Column>
      </DrawerBody>
      <DrawerFooter>
        <FormActions />
      </DrawerFooter>
    </Form>
  );
};
