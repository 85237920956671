import { Suspense } from "react";
import { Column, EmptyState, Row, Spinner } from "@hightouchio/ui";

import genericPlaceholder from "src/assets/placeholders/generic.svg";
import { Outlet, useSearchParams } from "src/router";
import { RecipientChannels } from "./components/channels";
import { Configuration } from "./components/configuration";

export const Recipients = () => {
  const [params] = useSearchParams();
  const channelId = params.get("channel");

  return (
    <Row w="100%" minH={0} flex={1}>
      <RecipientChannels />
      <Suspense fallback={<Spinner size="lg" m="auto" />}>
        {channelId ? (
          <Configuration />
        ) : (
          <Column p={6} flex={1}>
            <EmptyState
              imageUrl={genericPlaceholder}
              title="Select a recipient to configure"
              message=""
            />
          </Column>
        )}
      </Suspense>
      <Outlet />
    </Row>
  );
};
