import { FC } from "react";

import { Position } from "./use-node-positions";
import { UNHIGHLIGHTED_OPACITY } from "./utils";

type Props = {
  isHighlighted: boolean;
  source: Position;
  target: Position;
};

export const ConnectionLine: FC<Readonly<Props>> = ({
  isHighlighted,
  source,
  target,
}) => (
  <path
    d={generateBezierCurve({ source, target })}
    fill="none"
    stroke="#A5E0F9"
    strokeOpacity={isHighlighted ? 1 : UNHIGHLIGHTED_OPACITY}
    strokeWidth="2px"
  />
);

const generateBezierCurve = ({
  source,
  target,
}: {
  source: Position;
  target: Position;
}) => {
  // Create a quadratic bezier curve consisting of (4) points:
  //  - source
  //  - control 1
  //  - control 2
  //  - target
  // Start at `source` and end at `target`.
  // The two control points in between define the curvature.
  // The control points are placed in the middle, but pulled vertically on opposite ends
  // which creates an equally distributed curve.
  return `
    M ${source.x},${source.y} 
    C ${(target.x - source.x) / 2},${source.y}
      ${(target.x - source.x) / 2},${target.y}
      ${target.x},${target.y}
  `;
};
