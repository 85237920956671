import { BoxProps } from "@hightouchio/ui";

export interface Option {
  [key: string]: any;
  label: string;
  subLabel?: string;
  value: any;
  sx?: BoxProps["sx"];
}

export const flattenOptions = (options: Option[]): Option[] => {
  const flattenedOptions: Option[] = [];

  for (const item of options) {
    if (item.options) {
      flattenedOptions.unshift(...item.options);
    } else {
      flattenedOptions.unshift(item);
    }
  }

  return flattenedOptions;
};
