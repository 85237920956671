import { useMemo, useState } from "react";

import {
  Button,
  ButtonGroup,
  ConfirmationDialog,
  DeleteIcon,
  Paragraph,
  Row,
  SearchInput,
  Text,
  useToast,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import pluralize from "pluralize";
import { useNavigate } from "src/router";

import searchPlaceholder from "src/assets/placeholders/search.svg";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { PermissionedLinkButton } from "src/components/permission";
import { useResourcePermission } from "src/components/permission/use-resource-permission";
import {
  GoalsOrderBy,
  OrderBy,
  useDeleteGoalsMutation,
  useGoalsQuery,
} from "src/graphql";
import {
  Pagination,
  SortOption,
  Table,
  TableColumn,
  useTableConfig,
  useTableSort,
} from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { useRowSelect } from "src/ui/table/use-row-select";
import { openUrl } from "src/utils/urls";

const initialSort: SortOption<keyof GoalsOrderBy> = {
  key: "updated_at",
  direction: OrderBy.Desc,
  label: "Recently updated",
};
const sortOptions: SortOption<keyof GoalsOrderBy>[] = [
  { key: "name", direction: OrderBy.Asc, label: "Name A -> Z" },
  { key: "name", direction: OrderBy.Desc, label: "Name Z -> A" },
  { key: "parent_model", direction: OrderBy.Asc, label: "Parent model A -> Z" },
  {
    key: "parent_model",
    direction: OrderBy.Desc,
    label: "Parent model Z -> A",
  },
  { key: "created_at", direction: OrderBy.Desc, label: "Newest" },
  { key: "created_at", direction: OrderBy.Asc, label: "Oldest" },
];

export const Metrics = () => {
  const navigate = useNavigate();
  const { toast } = useToast();

  const [search, setSearch] = useState("");
  const [confirmDeletion, setConfirmDeletion] = useState(false);
  const { selectedRows, onRowSelect } = useRowSelect();

  const { isPermitted: hasDeletePermissions } = useResourcePermission({
    v1: { resource: "audience_schema", grant: "delete" },
  });

  const { limit, offset, page, setPage } = useTableConfig<GoalsOrderBy>();
  const orderBy = useTableSort<GoalsOrderBy>(initialSort, sortOptions);

  const goalsQuery = useGoalsQuery(
    {
      filters: {
        name: { _ilike: `%${search}%` },
        // We don't want to show any predefiend metrics because
        // these metrics are created by the backend, so we don't want users to update them.
        // They also have a completely different configuration than user-created metrics,
        // so the UI will look broken.
        _not: { config: { _has_key: "predefinedMetric" } },
      },
      offset,
      limit,
      orderBy,
    },
    {
      notifyOnChangeProps: "tracked",
      keepPreviousData: true,
    },
  );

  const deleteGoalsMutation = useDeleteGoalsMutation();

  const goals = goalsQuery.data?.goals ?? [];
  const goalsCount = goalsQuery.data?.goals_aggregate.aggregate?.count ?? 0;

  const goalsWithEventNames = useMemo(
    () =>
      goals.map((goal) => {
        const event = goal.parent_model.relationships?.find(
          ({ to_model: { id, event } }) =>
            event && id.toString() === goal.config?.eventModelId?.toString(),
        );
        return { ...goal, eventName: event?.to_model.name };
      }),
    [goals],
  );

  const bulkDeleteMetrics = async () => {
    if (!hasDeletePermissions) {
      toast({
        id: "bulk-delete-metrics",
        title: "You don't have permissions to delete metrics",
        variant: "error",
      });
      return;
    }

    try {
      await deleteGoalsMutation.mutateAsync({ ids: selectedRows.map(String) });

      toast({
        id: "bulk-delete-metrics",
        title: "Selected metrics were deleted",
        variant: "success",
      });

      onRowSelect([]);
    } catch (error) {
      toast({
        id: "bulk-delete-metrics",
        title: "Failed to delete metrics",
        message: error.message,
        variant: "error",
      });

      Sentry.captureException(error);
    }
  };

  const columns: TableColumn[] = [
    {
      name: "Name",
      cell: ({ name }) => (
        <Text isTruncated fontWeight="medium">
          {name}
        </Text>
      ),
    },
    {
      name: "Parent model",
      cell: ({ parent_model }) => (
        <Row align="center" gap={2} overflow="hidden">
          <IntegrationIcon
            src={parent_model?.connection?.definition?.icon}
            name={parent_model?.connection?.definition?.name}
          />
          <Text isTruncated>{parent_model?.name ?? "Private model"}</Text>
        </Row>
      ),
    },
    {
      name: "Event",
      cell: ({ eventName }) => <Text isTruncated>{eventName}</Text>,
    },
    { ...LastUpdatedColumn, breakpoint: "md" },
  ];

  return (
    <>
      <Row justify="space-between" my={8}>
        <SearchInput
          placeholder="Search by name..."
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
        <ButtonGroup size="lg">
          {hasDeletePermissions && selectedRows.length > 0 && (
            <Button
              icon={DeleteIcon}
              isDisabled={deleteGoalsMutation.isLoading}
              variant="warning"
              onClick={() => {
                setConfirmDeletion(true);
              }}
            >
              Delete
            </Button>
          )}
          <PermissionedLinkButton
            permission={{
              v1: {
                resource: "audience_schema",
                grant: "create",
              },
            }}
            href="/metrics/new"
            variant="primary"
          >
            Add metric
          </PermissionedLinkButton>
        </ButtonGroup>
      </Row>

      <Table
        columns={columns}
        data={goalsWithEventNames}
        loading={
          goalsQuery.isLoading ||
          goalsQuery.isPreviousData ||
          goalsQuery.isRefetching
        }
        placeholder={{
          image: searchPlaceholder,
          title: "No metrics",
          error: "Metrics failed to load, please try again",
        }}
        selectedRows={selectedRows}
        sortOptions={sortOptions}
        onRowClick={({ id }, event) =>
          openUrl(`/metrics/${id}`, navigate, event)
        }
        onSelect={onRowSelect}
      />
      <Row justify="flex-end" width="100%" mt={4} px={4}>
        <Pagination
          count={goalsCount}
          page={page}
          rowsPerPage={limit}
          setPage={setPage}
        />
      </Row>

      <ConfirmationDialog
        isOpen={confirmDeletion}
        title={`Delete ${pluralize("metrics", selectedRows.length, true)}`}
        confirmButtonText={`Delete ${pluralize(
          "metrics",
          selectedRows.length,
        )}`}
        variant="danger"
        onClose={() => setConfirmDeletion(false)}
        onConfirm={bulkDeleteMetrics}
      >
        <Paragraph>
          Metric deletion is not reversible. Deleting a metric will also delete
          its analytics and performance data. Are you sure you want to delete
          the selected metrics?
        </Paragraph>
      </ConfirmationDialog>
    </>
  );
};
