import {
  Button,
  Dialog,
  FormField,
  Radio,
  RadioGroup,
  Textarea,
  Column,
  Row,
  Text,
  MultiSelect,
  Badge,
  Box,
  ButtonGroup,
} from "@hightouchio/ui";

import { useResourcePermission } from "src/components/permission/use-resource-permission";
import { useUser } from "src/contexts/user-context";
import { switchWorkspace } from "src/utils/workspaces";
import { useUserGroupsForWorkspaceQuery } from "src/graphql";
import { useHightouchForm } from "src/components/form";
import { Controller } from "react-hook-form";
import { keyBy } from "lodash";
import { PageSpinner } from "src/components/loading";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const DEFAULT_UPGRADE_REASONS = [
  "Troubleshooting an issue (per customer request)",
  "Troubleshooting an issue (proactively/internal request)",
  "Understanding usage to prepare for a customer call",
  "Learning about a feature",
];

const OTHER_UPGRADE_REASON = "Other";

type FormState = {
  impersonationType: "admin" | "viewer" | "user_groups";
  userGroupIds: string[]; // only for user_groups
  upgradeReason: string;
  otherUpgradeReason: string;
};

const validationSchema = Yup.object().shape({
  impersonationType: Yup.string().required("Impersonation type is required"),
  userGroupIds: Yup.array().when("impersonationType", {
    is: "user_groups",
    then: Yup.array().min(1, "Select at least one user group"),
  }),
  upgradeReason: Yup.string().required("Upgrade reason is required"),
  otherUpgradeReason: Yup.string().when("upgradeReason", {
    is: OTHER_UPGRADE_REASON,
    then: Yup.string().required("Reason is required"),
  }),
});

export const ImpersonationPermissionsModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { user, workspace } = useUser();
  const permissionsV2Enabled = user?.permissions_v2_enabled;

  const { data: userGroups, isLoading: userGroupsLoading } =
    useUserGroupsForWorkspaceQuery(
      {
        workspaceId: workspace?.id ?? "",
      },
      {
        select: (data) =>
          // Note: I found at least one instance of a user_group being null
          // for a workspace not using permissions v2.
          // This null case might not exist in v2, but still handling it out of caution.
          data.user_group_workspaces.filter((ugw) => Boolean(ugw.user_group)),
        enabled: Boolean(workspace?.organization) && permissionsV2Enabled,
      },
    );

  const {
    isPermitted: hasUpdatePermission,
    isLoading: updatePermissionLoading,
  } = useResourcePermission({
    v2: { resource: "workspace", grant: "can_update" },
  });

  const { control, watch, submit } = useHightouchForm<FormState>({
    defaultValues: {
      impersonationType: "admin",
      userGroupIds: [],
      upgradeReason: DEFAULT_UPGRADE_REASONS[0],
      otherUpgradeReason: "",
    },
    success: "Reloading with new permissions",
    resolver: yupResolver(validationSchema),
    onSubmit: async (values) => {
      const requestWritePermission =
        values.impersonationType === "admin" ||
        values.impersonationType === "user_groups";

      const requestUserGroups =
        values.impersonationType === "user_groups"
          ? values.userGroupIds
          : undefined;

      const upgradeReason =
        values.upgradeReason === OTHER_UPGRADE_REASON
          ? values.otherUpgradeReason
          : values.upgradeReason;

      const workspaceId = user?.current_workspace_id;

      if (!workspaceId) return;

      await switchWorkspace(workspaceId, window.location.pathname, {
        requestWritePermission,
        requestUserGroups,
        upgradeReason,
      });
    },
  });

  const impersonationType = watch("impersonationType");
  const upgradeReason = watch("upgradeReason");

  const userGroupsById = keyBy(userGroups ?? [], (ug) =>
    String(ug.user_group.id),
  );

  const isLoading = userGroupsLoading || updatePermissionLoading;

  if (isLoading) {
    return <PageSpinner />;
  }

  return (
    <Dialog
      width="lg"
      variant="form"
      isOpen={isOpen}
      onClose={onClose}
      title="Request impersonation permissions"
      actions={
        <ButtonGroup>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={submit}>
            Submit
          </Button>
        </ButtonGroup>
      }
    >
      <Column gap={4}>
        <Column>
          <Text fontWeight="semibold">Current permissions</Text>
          <Box as="ul">
            <Box as="li">
              <Row alignItems="center" gap={1}>
                <Text>Workspace admin</Text>
                <Badge variant={hasUpdatePermission ? "warning" : undefined}>
                  {hasUpdatePermission ? "true" : "false"}
                </Badge>
              </Row>
            </Box>
            {permissionsV2Enabled && (
              <>
                <Box as="li">User groups</Box>
                <Box as="ul">
                  {user?.user_group_ids?.map((groupId) => {
                    return (
                      <Box as="li" key={groupId}>
                        <Text>
                          {userGroupsById[groupId]?.user_group.name} ({groupId})
                        </Text>
                      </Box>
                    );
                  })}
                </Box>
              </>
            )}
          </Box>
        </Column>
        <Divider />

        <Column gap={4}>
          <Controller
            control={control}
            name="impersonationType"
            render={({ field }) => (
              <FormField label="Requested permissions">
                <RadioGroup value={field.value} onChange={field.onChange}>
                  <Radio label="Admin" value="admin" />
                  <Radio label="Viewer" value="viewer" />
                  {permissionsV2Enabled && (
                    <Radio label="Specific user groups" value="user_groups" />
                  )}
                </RadioGroup>
              </FormField>
            )}
          />

          {impersonationType === "user_groups" && (
            <Controller
              control={control}
              name="userGroupIds"
              render={({ field, fieldState }) => (
                <MultiSelect
                  placeholder="Select user groups"
                  isInvalid={Boolean(fieldState.error)}
                  options={
                    userGroups?.map((ug) => ({
                      label: `${ug.user_group.name} (${ug.user_group.id})`,
                      value: String(ug.user_group.id),
                    })) ?? []
                  }
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          )}
        </Column>

        {(impersonationType === "admin" ||
          impersonationType === "user_groups") && (
          <>
            <Divider />
            <Controller
              control={control}
              name="upgradeReason"
              render={({ field }) => (
                <FormField
                  description="Activity will be logged."
                  label="Why do you need additional permissions?"
                >
                  <RadioGroup
                    mt={3}
                    value={field.value}
                    onChange={field.onChange}
                  >
                    {DEFAULT_UPGRADE_REASONS.map((option) => {
                      return (
                        <Radio key={option} label={option} value={option} />
                      );
                    })}
                    <Radio label="Other" value={OTHER_UPGRADE_REASON} />
                  </RadioGroup>
                </FormField>
              )}
            />
            {upgradeReason === OTHER_UPGRADE_REASON && (
              <Controller
                control={control}
                name="otherUpgradeReason"
                render={({ field, fieldState }) => (
                  <Textarea
                    isInvalid={Boolean(fieldState.error)}
                    autoFocus
                    width="100%"
                    rows={2}
                    placeholder="Enter reason..."
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            )}
          </>
        )}
      </Column>
    </Dialog>
  );
};

const Divider = () => <Box borderTop="1px solid" borderColor="base.border" />;
