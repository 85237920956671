import { FC, ReactNode } from "react";

import { Column } from "@hightouchio/ui";

interface SubSectionProps {
  children: ReactNode;
}

export const SubSection: FC<Readonly<SubSectionProps>> = ({ children }) => {
  return (
    <Column
      gap={2}
      py={2}
      px={3}
      borderTop="1px solid"
      borderLeft="3px solid"
      borderColor="base.border"
    >
      {children}
    </Column>
  );
};
