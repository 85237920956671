import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const TraitIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props} viewBox="0 0 24 24">
      <path
        d="M16.8 15.1792L18.6868 14L20.53 15.152C20.8224 15.3347 21 15.6552 21 16C21 16.3448 20.8224 16.6652 20.53 16.848L12.53 21.848C12.2057 22.0506 11.7943 22.0506 11.47 21.848L3.47 16.848C3.17762 16.6652 3 16.3448 3 16C3 15.6552 3.17762 15.3347 3.47 15.152L5.31319 14L7.19999 15.1792L5.8868 16L12 19.8207L18.1132 16L16.8 15.1792Z"
        fill="white"
        fillOpacity="0.3"
      />
      <path
        d="M5.31318 10L3.47 11.152C3.17762 11.3347 3 11.6552 3 12C3 12.3448 3.17762 12.6652 3.47 12.848L11.47 17.848C11.7943 18.0507 12.2057 18.0507 12.53 17.848L20.53 12.848C20.8224 12.6652 21 12.3448 21 12C21 11.6552 20.8224 11.3347 20.53 11.152L18.6868 10L16.8 11.1792L18.1132 12L12 15.8207L5.8868 12L7.19998 11.1792L5.31318 10Z"
        fill="white"
        fillOpacity="0.3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.47 2.152C11.7943 1.94933 12.2057 1.94933 12.53 2.152L20.53 7.152C20.8224 7.33474 21 7.65521 21 8C21 8.34479 20.8224 8.66526 20.53 8.848L12.53 13.848C12.2057 14.0507 11.7943 14.0507 11.47 13.848L3.47 8.848C3.17762 8.66526 3 8.34479 3 8C3 7.65521 3.17762 7.33474 3.47 7.152L11.47 2.152ZM5.8868 8L12 11.8208L18.1132 8L12 4.17925L5.8868 8Z"
      />
    </Icon>
  );
};
