import { FC } from "react";

import { Column } from "@hightouchio/ui";
import { format as formatDate } from "date-fns";
import { TooltipProps } from "recharts";
import { isPresent } from "ts-extras";

import { GraphTooltip } from "../graph-tooltip";
import {
  formatMetricValue,
  getTooltipSuffixTextFromAggregation,
} from "./utils";

type Props = {
  hoveredLine: string | undefined;
  showPercentSign?: boolean;
} & TooltipProps<number, string>;

const Tooltip: FC<Readonly<Props>> = ({
  payload,
  hoveredLine,
  showPercentSign = false,
}) => {
  if (!payload || !hoveredLine) return null;

  const tooltipsToShow = payload.filter(
    ({ payload: metric }) => metric.seriesKey === hoveredLine,
  );

  if (tooltipsToShow.length === 0) return null;

  return (
    <Column bg="gray.900" padding={2} borderRadius="md" gap={2}>
      {tooltipsToShow.map(({ payload: metric, color, name, value }, index) => {
        const displayedValue = isPresent(value)
          ? formatMetricValue(value, showPercentSign)
          : "Not available";

        return (
          <GraphTooltip
            key={`${metric.id}-${index}`}
            color={color}
            title={metric.metricName}
            subtitles={[
              name,
              formatDate(new Date(metric.calculatedAt), "LLL d, yyyy"),
            ]}
            value={[
              {
                value: displayedValue,
                description: metric.aggregation
                  ? getTooltipSuffixTextFromAggregation(metric.aggregation)
                  : undefined,
              },
            ]}
          />
        );
      })}
    </Column>
  );
};

export default Tooltip;
