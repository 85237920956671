import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const FaradayIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props} viewBox="0 0 30 32">
      <path
        d="M15.2944 0.680389L0.5 18.9932H8.39929L15.2944 0.680389Z"
        fill="#E85EA5"
      />
      <path
        d="M8.66699 25.3196L23.4614 7.00674H15.5621L8.66699 25.3196Z"
        fill="#C569FF"
      />
    </Icon>
  );
};
