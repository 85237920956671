import { FC } from "react";

import { Badge, Box, Row } from "@hightouchio/ui";
import { Link } from "src/router";
import { useFlags } from "launchdarkly-react-client-sdk";

import { TraitQuery } from "src/graphql";
import { IntegrationIcon } from "src/components/integrations/integration-icon";

type Props = {
  parentModel: NonNullable<
    TraitQuery["trait_definitions_by_pk"]
  >["parent_model"];
  isTemplate: boolean;
  isArchived: boolean;
};

export const Metadata: FC<Readonly<Props>> = ({
  parentModel,
  isTemplate,
  isArchived,
}) => {
  const { schemaV2 } = useFlags();

  return (
    <Row
      sx={{
        mt: 2,
        "& > *:not(:last-child)": {
          pr: 2,
          mr: 2,
          borderRight: "1px solid",
          borderColor: "base.border",
        },
      }}
    >
      <Row alignItems="center" gap={2}>
        <IntegrationIcon
          src={parentModel?.connection?.definition.icon}
          name={parentModel?.connection?.definition.name}
        />
        <Link
          href={
            schemaV2
              ? `/schema-v2/view?source=${parentModel?.connection?.id}&id=${parentModel?.id}`
              : `/schema/parent-models/${parentModel?.id}`
          }
        >
          {parentModel?.name}
        </Link>
      </Row>
      {isArchived && (
        <Box
          as={Badge}
          color="danger.base"
          borderColor="danger.border"
          background="danger.background"
        >
          Archived
        </Box>
      )}
      {isTemplate && <Badge>Template</Badge>}
    </Row>
  );
};
