import { FC } from "react";

import { Row, Column, Box, SectionHeading, Text } from "@hightouchio/ui";
import { capitalize, orderBy } from "lodash";

import { IntegrationBadge } from "src/components/integrations/integration-badge";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { useUser } from "src/contexts/user-context";
import {
  SourceCategory as SourceCategoryType,
  SourceDefinition,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { newPylonMessage } from "src/lib/pylon";
import { Selectable } from "src/ui/selectable";

interface Props {
  sampleDefinitions: SourceDefinition[];
  definitions: SourceDefinition[];
  selection?: SourceDefinition;
  onSelect: (definition: SourceDefinition) => void;
}

export const SourceCatalog: FC<Readonly<Props>> = ({
  sampleDefinitions,
  definitions,
  selection,
  onSelect,
}) => {
  const { categories } = useSourceCatalog(definitions);

  return (
    <Column gap={6}>
      {Object.values(categories).map((category) => (
        <SourceCategory
          key={category.name}
          name={category.name}
          description={category.shortDescription}
          selection={selection}
          definitions={category.definitions}
          onSelect={onSelect}
        />
      ))}
      {sampleDefinitions?.length > 0 && (
        <SourceCategory
          name="Sample datasets"
          description="Try setting up your first sync using one of our sample datasets."
          selection={selection}
          definitions={sampleDefinitions}
          onSelect={onSelect}
        />
      )}
    </Column>
  );
};

export const SourceCategory: FC<
  Readonly<{
    name: string;
    description: string;
    definitions: SourceDefinition[];
    selection?: SourceDefinition;
    onSelect: (definition: SourceDefinition) => void;
  }>
> = ({ name, description, definitions, selection, onSelect }) => (
  <Box>
    <SectionHeading>{capitalize(name)}</SectionHeading>
    <Text>{description}</Text>
    <Box
      mt={5}
      display="grid"
      gridTemplateColumns={[
        "1fr",
        "repeat(2, 1fr)",
        "repeat(3, 1fr)",
        "repeat(3, 1fr)",
        "repeat(4, 1fr)",
      ]}
      gridGap={4}
    >
      {orderBy(definitions, ["name"], ["asc"]).map((definition) => (
        <Source
          key={definition.type}
          definition={definition}
          selected={definition.type === selection?.type}
          onSelect={onSelect}
        />
      ))}
    </Box>
  </Box>
);

export const Source: FC<
  Readonly<{
    definition: SourceDefinition;
    onSelect: (definition: SourceDefinition) => void;
    selected: boolean;
  }>
> = ({ definition, onSelect, selected }) => {
  return (
    <Selectable
      selected={selected}
      p={4}
      bg="white"
      overflow="visible"
      height="64px"
      onSelect={() => {
        analytics.track("Source Type Clicked", {
          source_type: definition.name,
          source_slug: definition.type,
          source_status: definition.status,
        });
        if (
          definition.status === "alpha" ||
          definition.status === "coming_soon"
        ) {
          newPylonMessage(
            `Hi, I would like to participate in the private preview for the ${definition.name} source.`,
          );
        } else {
          onSelect(definition);
        }
      }}
    >
      <Row align="center" gap={4} textAlign="left">
        <IntegrationIcon src={definition.icon} name={definition.name} />
        <Text fontWeight="medium">{definition.name}</Text>
      </Row>
      <Box pos="absolute" right={-2} top={-2}>
        <IntegrationBadge
          definition={definition}
          integrationType="source"
          isSample={definition.isSampleDataSource}
        />
      </Box>
    </Selectable>
  );
};

export const useSourceCatalog = (
  definitions: SourceDefinition[],
  released?: boolean,
) => {
  const { featureFlags } = useUser();

  const availableDefinitions = definitions.filter((definition) => {
    if (definition.type === "hubspotLegacy") {
      return (
        featureFlags?.hubspot_multi_merge_rule &&
        featureFlags?.hubspot_legacy_destination
      );
    }
    if (definition.status === "shadow") {
      return false;
    }
    if (definition.status === "deprecated") {
      return false;
    }
    if (released) {
      if (
        definition.status === "alpha" ||
        definition.status === "coming_soon"
      ) {
        return false;
      }
    }
    return true;
  });

  const categories: Record<
    string,
    SourceCategoryType & { definitions: SourceDefinition[] }
  > = {
    "data systems": { definitions: [] } as any,
    spreadsheets: { definitions: [] } as any,
    other: { definitions: [] } as any,
  };

  for (const definition of availableDefinitions) {
    const firstCategory = definition?.categories?.[0];

    if (firstCategory) {
      const name = firstCategory.name.toLowerCase();
      categories[name] = {
        ...firstCategory,
        definitions: [...categories[name]!.definitions, definition],
      };
    }
  }

  return { categories, definitions: availableDefinitions };
};
