import { FC, useEffect, useState, useRef } from "react";

import { Alert, Box, Column, Row, Spinner, Text } from "@hightouchio/ui";
import Ajv from "ajv";
import { isNil, omit, omitBy, pick } from "lodash";
import { useQueryClient } from "react-query";
import * as Yup from "yup";

import { useDestinationForm } from "src/contexts/destination-form-context";
import {
  useCustomValidateConfigurationQuery,
  useCustomFieldsQuery,
  useCustomModesQuery,
  useCustomObjectsQuery,
  useCustomOptionsQuery,
} from "src/graphql";
import { ReloadButton } from "src/components/reload-button";
import { FieldError } from "src/components/field-error";
import { JsonForm } from "src/ui/jsonForm";
import { FormRef } from "src/ui/jsonForm/form";
import { Section } from "src/ui/section";
import { COMMON_SCHEMAS } from "src/utils/destinations";
import { IdMappingField } from "src/components/destinations/id-mapping-field";
import { MappingsField } from "src/components/destinations/mappings-field";
import { ModeField } from "src/components/destinations/mode-field";
import { ObjectField } from "src/components/destinations/object-field";

const compact = <T extends Record<string, unknown>>(
  obj: T,
): Record<keyof T, NonNullable<T[keyof T]>> => {
  return omitBy(obj, isNil) as Record<keyof T, NonNullable<T[keyof T]>>;
};

export const validation = Yup.object().shape({});

export const CustomForm: FC = () => {
  const client = useQueryClient();
  const { config, setConfig, destination, setCustomValidation } =
    useDestinationForm();
  const [customError, setCustomError] = useState<string[]>();

  const {
    data: objectsData,
    error: objectsError,
    isFetching: loadingObjects,
    refetch: listObjects,
  } = useCustomObjectsQuery({
    destinationId: String(destination?.id),
  });

  const OBJECTS = objectsData?.customListObjects;
  const objectOptions =
    OBJECTS?.objects?.map((o) => ({ label: o.label, value: o.id })) || [];
  const objectComplete = !objectOptions.length || config?.object; // objects array does not exist or is empty, or object has been selected

  const {
    data: modesData,
    error: modesError,
    isFetching: loadingModes,
    refetch: listModes,
  } = useCustomModesQuery({
    object: config?.object,
    destinationId: String(destination?.id),
  });
  const MODES = modesData?.customListModes;
  const modeOptions =
    MODES?.modes?.map((o) => ({
      label: o.label,
      value: o.id,
      description: o.description,
    })) || [];
  const modeComplete = !modeOptions.length || config?.mode;

  const {
    data: fieldsData,
    error: fieldsError,
    isFetching: loadingFields,
    refetch: listFields,
  } = useCustomFieldsQuery({
    object: config?.object,
    mode: config?.mode,
    destinationId: String(destination?.id),
  });
  const FIELDS = fieldsData?.customListFields;
  const externalIdFieldOptions =
    FIELDS?.fields
      ?.filter?.((o) => o.identifier)
      ?.map((o) => ({ label: o.label, value: o.id, type: o.type })) || [];

  const standardFieldOptions =
    FIELDS?.fields?.map((o) => ({
      label: o.label,
      value: o.id,
      type: o.type,
      required: o.required,
    })) || [];

  const isCustomMappingsEnabled = Boolean(FIELDS?.freeform);

  const {
    data: optionsData,
    error: optionsError,
    isFetching: loadingOptions,
    refetch: listOptions,
  } = useCustomOptionsQuery({
    object: config?.object,
    mode: config?.mode,
    destinationId: String(destination?.id),
  });

  const reloadForm = async () => {
    setConfig({});
    await listObjects();
    await listModes();
    await listFields();
    await listOptions();
  };

  const OPTIONS = optionsData?.customListOptions;

  const schema = OPTIONS?.options?.schema
    ? JSON.parse(OPTIONS?.options?.schema)
    : {};
  const uiSchema = OPTIONS?.options?.uiSchema
    ? JSON.parse(OPTIONS?.options?.uiSchema)
    : {};

  const HT_FIELDS = [
    "object",
    "mode",
    "identifierMapping",
    "mappings",
    "customMappings",
  ];

  const optionsValue = config?.options
    ? config?.options
    : omit(config, HT_FIELDS);

  const onOptionsValueChange = (value) => {
    if (value instanceof Object && !Array.isArray(value)) {
      setConfig((oldConfig) => {
        return { ...value, ...pick(oldConfig, HT_FIELDS) };
      });
    } else {
      setConfig((oldConfig) => {
        return { options: value, ...pick(oldConfig, HT_FIELDS) };
      });
    }
  };

  useEffect(() => {
    const validate = async (config) => {
      const yupSchema = Yup.object().shape(
        compact({
          mode: modeOptions.length ? Yup.string().required() : undefined,
          object: objectOptions.length ? Yup.string().required() : undefined,
          identifierMapping: externalIdFieldOptions?.length
            ? COMMON_SCHEMAS.externalIdMapping
            : undefined,
          mappings: standardFieldOptions.length
            ? COMMON_SCHEMAS.mappings
            : undefined,
          customMappings: isCustomMappingsEnabled
            ? COMMON_SCHEMAS.mappings
            : undefined,
        }),
      );

      const optionsValue = config?.options
        ? config?.options
        : omit(config, HT_FIELDS);
      let yupError;
      let otherError;
      try {
        await yupSchema.validate(config, {
          abortEarly: false,
        });
      } catch (err) {
        yupError = err;
      }

      if (schema && typeof schema === "object" && Object.keys(schema).length) {
        const ajv = new Ajv();
        const valid = ajv.validate(schema, optionsValue);
        formRef?.current?.triggerValidation();
        otherError = !valid;
      }

      const variables = {
        destinationId: String(destination?.id),
        jsonInstanceConfiguration: JSON.stringify(config),
      };

      try {
        const { customValidateConfiguration } = await client.fetchQuery(
          useCustomValidateConfigurationQuery.getKey(variables),
          {
            queryFn: useCustomValidateConfigurationQuery.fetcher(variables),
          },
        );
        if (customValidateConfiguration?.errors?.length) {
          setCustomError(customValidateConfiguration?.errors);
          otherError = true;
        } else {
          setCustomError(undefined);
        }
      } catch (e) {
        otherError = true;
        setCustomError(e.message);
      }

      return { yupError, otherError };
    };
    setCustomValidation({ validate });
  }, [OPTIONS, FIELDS, MODES, OBJECTS]);

  const formRef = useRef<FormRef>(null);

  return (
    <>
      <Column gap={8}>
        {customError && (
          <Alert
            variant="inline"
            type="warning"
            title="Warning"
            message={
              <>
                {(customError as any)?.map((e, i) => <Text key={i}>{e}</Text>)}
              </>
            }
          />
        )}
        {(!!objectOptions.length || objectsError) && (
          <ObjectField
            error={objectsError?.message}
            loading={loadingObjects}
            options={objectOptions}
            reload={listObjects}
            onChange={async (object) => {
              setConfig({ object });
              await listOptions();
            }}
          />
        )}

        {objectComplete && (!!modeOptions.length || modesError) && (
          <ModeField
            error={modesError?.message}
            loading={loadingModes}
            options={modeOptions}
            reload={listModes}
            onChange={async (mode) => {
              setConfig({ object: config?.object, mode });
              await listOptions();
            }}
          />
        )}

        {objectComplete &&
          modeComplete &&
          (!!externalIdFieldOptions.length || fieldsError) && (
            <Section>
              <IdMappingField
                error={fieldsError?.message}
                loading={loadingFields}
                options={externalIdFieldOptions}
                property="identifierMapping"
                reload={listFields}
              />
            </Section>
          )}

        {objectComplete &&
          modeComplete &&
          (!!standardFieldOptions.length || fieldsError) && (
            <Section>
              <MappingsField
                error={fieldsError?.message}
                loading={loadingFields}
                options={standardFieldOptions}
                reload={listFields}
              />
            </Section>
          )}

        {objectComplete &&
          modeComplete &&
          (isCustomMappingsEnabled || fieldsError) && (
            <Section>
              <MappingsField
                isCustom
                error={fieldsError?.message}
                loading={loadingFields}
                reload={listFields}
              />
            </Section>
          )}
        {objectComplete &&
          modeComplete &&
          (!!Object.entries(schema).length || optionsError) && (
            <Section sx={{ position: "relative" }}>
              <Box sx={{ position: "absolute", right: 6, top: 6 }}>
                <ReloadButton
                  onClick={async () => {
                    await listOptions();
                  }}
                />
              </Box>
              {!loadingOptions ? (
                <JsonForm
                  ref={formRef}
                  schema={schema}
                  uiSchema={uiSchema}
                  value={optionsValue}
                  onChange={onOptionsValueChange}
                />
              ) : (
                <Spinner />
              )}
              {!!optionsError && <FieldError error={optionsError?.message} />}
            </Section>
          )}
      </Column>
      <Row justifyContent="flex-end" align="center" gap={2}>
        <Text>Reload form</Text>
        <ReloadButton onClick={reloadForm} />
      </Row>
    </>
  );
};

export default { form: CustomForm, validation };
