import { useMemo, useState } from "react";

import { ConfirmationDialog, Row, SearchInput, Text } from "@hightouchio/ui";
import { useNavigate, useSearchParams } from "src/router";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { SegmentsBoolExp, useEventsQuery } from "src/graphql";
import useQueryState from "src/hooks/use-query-state";
import { Table } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { SourceBadges } from "src/utils/sources";
import { openUrl } from "src/utils/urls";
import { PermissionedLinkButton } from "src/components/permission";

export const EventModels = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useQueryState("search");
  const [searchParams] = useSearchParams();
  const eventCreated = searchParams.get("eventCreated");

  const [showEventWarning, setShowEventWarning] = useState(
    Boolean(eventCreated),
  );

  const hideEventWarning = () => setShowEventWarning(false);

  const hasuraFilters = useMemo(() => {
    const filters: SegmentsBoolExp = {
      _and: [{ is_schema: { _eq: true } }, { event: {} }],
    };

    if (search) {
      filters._and!.push({ name: { _ilike: `%${search}%` } });
    }

    return filters;
  }, [search]);

  const eventsQuery = useEventsQuery(
    { filters: hasuraFilters },
    { keepPreviousData: true },
  );

  const columns = [
    {
      name: "Name",
      cell: ({ name, connection }) => {
        return (
          <Row align="center" gap={2}>
            <IntegrationIcon
              name={connection?.definition?.name}
              src={connection?.definition?.icon}
            />
            <Text isTruncated fontWeight="medium">
              {name ?? "Private source"}
            </Text>
            <SourceBadges
              isSampleDataSource={connection?.definition?.isSampleDataSource}
            />
          </Row>
        );
      },
    },
    LastUpdatedColumn,
  ];

  return (
    <>
      <Row py={8} justify="space-between">
        <SearchInput
          placeholder="Search by name..."
          value={search ?? ""}
          onChange={(event) => setSearch(event.target.value)}
        />
        <PermissionedLinkButton
          permission={{
            v1: { resource: "audience_schema", grant: "create" },
          }}
          href="/schema/events/new"
          variant="primary"
        >
          Add event
        </PermissionedLinkButton>
      </Row>

      <Table
        columns={columns}
        data={eventsQuery.data?.segments}
        error={Boolean(eventsQuery.error)}
        loading={eventsQuery.isLoading || eventsQuery.isRefetching}
        placeholder={{
          title: "No events",
          error: "Events failed to load, please try again.",
        }}
        onRowClick={({ id }, event) =>
          openUrl(`/schema/events/${id}`, navigate, event)
        }
      />

      <ConfirmationDialog
        isOpen={showEventWarning}
        confirmButtonText="Go to parent models"
        title="Finish event setup"
        variant="warning"
        onClose={hideEventWarning}
        onConfirm={() => {
          navigate("/schema/parent-models");
          hideEventWarning();
        }}
      >
        <Text>
          To use this event, please add a relationship to the event from the
          parent model.
        </Text>
      </ConfirmationDialog>
    </>
  );
};
