import { FC, useState } from "react";

import {
  Column,
  FormErrorMessage,
  FormField,
  Heading,
  Row,
  Select,
  useToast,
} from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { uniqueId } from "lodash";
import { Controller } from "react-hook-form";
import { useQueryClient } from "react-query";
import * as yup from "yup";

import { Outlet, useOutletContext } from "src/router";
import { ActionBar } from "src/components/action-bar";
import monitoringImage from "src/components/extensions/assets/monitoring.png";
import { Overview } from "src/components/extensions/overview";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { DetailPage } from "src/components/layout";
import { SecretInput } from "src/components/secret-input";
import {
  DatadogCredentialsQuery,
  useCreateDatadogCredentialsMutation,
  useDatadogCredentialsQuery,
  useUpdateDatadogCredentialsMutation,
  useValidateDatadogCredentialsQuery,
} from "src/graphql";
import { DatadogIcon, MonitoringIcon } from "src/ui/icons";
import { PageSpinner } from "src/components/loading";
import { DocsLink } from "src/components/docs-link";
import { Card } from "src/components/card";

export const Monitoring: FC = () => {
  const { data: credentials, isLoading: loading } = useDatadogCredentialsQuery(
    undefined,
    {
      select: (data) => data?.datadog_credentials?.[0],
    },
  );

  return (
    <DetailPage
      bg="base.lightBackground"
      crumbs={[{ label: "Extensions", link: "/extensions" }]}
      title="Monitoring - Extensions"
      header={<Heading size="xl">Monitoring</Heading>}
      tabs={[
        {
          path: "",
          title: "Overview",
        },
        {
          path: "configuration",
          title: "Configuration",
        },
      ]}
    >
      <Outlet context={{ credentials, loading }} />
    </DetailPage>
  );
};

export const MonitoringOverview: FC = () => {
  return (
    <Overview
      description="Hightouch integrates with tools like Datadog so that you can monitor sync health in the same place as your other data infrastructure. Hightouch emits real-time events (like sync completion) and aggregated metrics (like the number of rows processed)."
      icon={MonitoringIcon}
      image={monitoringImage}
      integrations={[{ name: "Datadog", icon: DatadogIcon }]}
      subtitle="Send metrics and events to other tools"
      title="Monitoring"
    />
  );
};

const siteOptions = [
  { label: "US1", value: "datadoghq.com" },
  { label: "US3", value: "us3.datadoghq.com" },
  { label: "US5", value: "us5.datadoghq.com" },
  { label: "EU1", value: "datadoghq.eu" },
  { label: "US1-FED", value: "app.ddog-gov.com" },
];

type DatadogCredentials = DatadogCredentialsQuery["datadog_credentials"][0];

interface OutletContext {
  credentials: DatadogCredentials;
  loading: boolean;
}

const validation = yup
  .object({
    api_key: yup.string(),
    site: yup.string().required(),
  })
  .required();

export const MonitoringConfiguration: FC = () => {
  const client = useQueryClient();
  const { credentials, loading } = useOutletContext<OutletContext>();
  const { toast } = useToast();

  const [error, setError] = useState<string | undefined>();
  const { mutateAsync: create } = useCreateDatadogCredentialsMutation();
  const { mutateAsync: update } = useUpdateDatadogCredentialsMutation();

  const form = useHightouchForm({
    onSubmit: async (data) => {
      const { ValidateDatadogCredentials } = await client.fetchQuery(
        uniqueId(),
        {
          queryFn: useValidateDatadogCredentialsQuery.fetcher({
            site: data.site,
            apiKey: data.api_key,
          }),
        },
      );

      if (ValidateDatadogCredentials?.success) {
        setError(undefined);
      } else {
        setError("Datadog configuration is invalid");
        toast({
          id: "save-monitoring",
          title: "There was an error saving your configuration",
          variant: "error",
        });
        return;
      }

      if (!credentials) {
        await create({
          input: data,
        });
      } else {
        await update({
          id: credentials.id,
          credentials: data,
        });
      }
    },
    resolver: yupResolver(validation),
    values: {
      site: credentials?.site ?? "",
      api_key: "",
    },
  });
  const { control } = form;

  if (loading) {
    return <PageSpinner />;
  }

  return (
    <Form form={form}>
      <Column gap={6} maxW="2xl">
        <Row align="center" justify="space-between">
          <Heading>Datadog configuration</Heading>
          <DocsLink href="syncs/datadog" name="Datadog extension" />
        </Row>
        <Card gap={6}>
          <Controller
            control={control}
            name="site"
            render={({ field, fieldState: { error } }) => (
              <FormField
                error={error?.type === "required" ? "Site is required" : ""}
                label="Site"
              >
                <Select
                  isInvalid={Boolean(error)}
                  options={siteOptions}
                  placeholder="Select a datadog site for your datadog account"
                  value={field.value}
                  onChange={field.onChange}
                />
              </FormField>
            )}
          />

          <Controller
            control={control}
            name="api_key"
            render={({ field, fieldState: { error } }) => (
              <FormField
                error={error?.type === "required" ? "API key is required" : ""}
                label="API key"
              >
                <SecretInput
                  isHidden={Boolean(credentials?.id)}
                  value={field.value}
                  onChange={field.onChange}
                />
              </FormField>
            )}
          />

          <FormErrorMessage>{error}</FormErrorMessage>
        </Card>
      </Column>

      <ActionBar>
        <FormActions
          permission={{
            v2: { resource: "workspace", grant: "can_update" },
          }}
        />
      </ActionBar>
    </Form>
  );
};
