import { Row, Text, Spinner } from "@hightouchio/ui";
import { formatFriendlyDistanceToNow } from "src/utils/time";
import { ActiveSyncProgressIndicator } from "./active-sync-progress-indicator";
import {
  SyncRunStatusOrUnknownStatus,
  UnknownSyncRunStatus,
  isActiveSyncStatus,
} from "src/utils/syncs";

export const SyncLastRunDisplay = ({
  isStreaming,
  status,
  completionRatio,
  createdAt,
  finishedAt,
}: {
  isStreaming: boolean;
  status: SyncRunStatusOrUnknownStatus;
  completionRatio: number | null;
  finishedAt: string | null;
  createdAt: string | null;
}) => {
  if (status === UnknownSyncRunStatus) return <Text>Unknown</Text>;

  if (isStreaming) {
    return (
      <Row align="center" gap={2}>
        <Spinner size="sm" speed="slow" />
        <Text>Streaming...</Text>
      </Row>
    );
  }

  if (!createdAt && !finishedAt) return <Text>No runs yet</Text>;

  const isActivelyRunning = isActiveSyncStatus(status);

  if (isActivelyRunning) {
    return (
      <ActiveSyncProgressIndicator
        status={status}
        completionRatio={completionRatio}
      />
    );
  } else if (finishedAt)
    return <Text>{formatFriendlyDistanceToNow(finishedAt)}</Text>;
  return <Text>Unknown</Text>;
};
