import { FC, useState } from "react";

import { Row, Text } from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import moment from "moment";

import { useUser } from "src/contexts/user-context";
import { useOrganizationActiveSyncsQuery } from "src/graphql";
import { BasicPagination, Table } from "src/ui/table";
import { commaNumber } from "src/utils/numbers";

const placeholder = {
  title: "No active syncs",
  error: "Organization active sync data failed to load, please try again.",
};

const PAGE_SIZE = 6;

export const OrganizationActiveSyncsTable: FC = () => {
  const user = useUser();
  const [page, setPage] = useState(0);

  const { data: activeSyncs, isLoading } = useOrganizationActiveSyncsQuery(
    {
      organizationId: user?.workspace?.organization?.id || 0,
    },
    {
      enabled: !!user?.workspace?.organization?.id,
      select: (data) => data.organization_active_syncs,
    },
  );

  const activeSyncsColumns = [
    {
      name: "Month",
      cell: ({ calendar_month }) => {
        try {
          return (
            <Text>
              {moment(calendar_month.replaceAll('"', ""))
                .utc()
                .format("MMMM yyyy")}
            </Text>
          );
        } catch (e) {
          Sentry.captureException(e);
        }
        return null;
      },
    },
    {
      name: "Active syncs",
      cell: ({ active_syncs }) => {
        return (
          <Row align="center" gap={2}>
            {active_syncs !== 0 ? commaNumber(active_syncs) : <Text>--</Text>}
          </Row>
        );
      },
    },
  ];

  return (
    <>
      <Table
        primaryKey="pkey"
        columns={activeSyncsColumns}
        data={activeSyncs?.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE)}
        placeholder={placeholder}
        loading={isLoading}
        scrollable
      />
      <BasicPagination
        page={page}
        setPage={setPage}
        disableNextPage={(page + 1) * PAGE_SIZE > (activeSyncs?.length || 0)}
      />
    </>
  );
};
