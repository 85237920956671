export type Session = {
  workspaceId: number;
  name: string;
  ttl: number;
  metadata: Record<string, any>;
};

export type Brand = {
  name: string;
  logo: string;
};

export enum ConfigType {
  CreateSource = "create-source",
  ViewSource = "view-source",
  CreateSync = "create-sync",
  ViewSync = "view-sync",
  ViewSyncRuns = "view-sync-runs",
  ViewSyncConfiguration = "view-sync-configuration",
  ViewSyncSchedule = "view-sync-schedule",
  ViewSyncModel = "view-sync-model",
}

export type ViewSourceConfig = {
  type: ConfigType.ViewSource;
  sourceId: string;
};

export type ViewSyncConfig = {
  type: ConfigType.ViewSync;
  syncId: string;
};

export type ViewSyncRunsConfig = {
  type: ConfigType.ViewSyncRuns;
  syncId: string;
};

export type ViewSyncConfigurationConfig = {
  type: ConfigType.ViewSyncConfiguration;
  syncId: string;
};

export type ViewSyncScheduleConfig = {
  type: ConfigType.ViewSyncSchedule;
  syncId: string;
};

export type ViewSyncModelConfig = {
  type: ConfigType.ViewSyncModel;
  syncId: string;
};

export type CreateSyncConfig = {
  type: ConfigType.CreateSync;
  destinationId: string;
  sourceId?: string;
  allowedSourceTypes: string[];
  allowedModelingMethods: string[];
};

export type CreateSourceConfig = {
  type: ConfigType.CreateSource;
  sourceType?: string;
  allowedSourceTypes: string[];
};

export type Config =
  | CreateSourceConfig
  | ViewSourceConfig
  | CreateSyncConfig
  | ViewSyncConfig
  | ViewSyncRunsConfig
  | ViewSyncConfigurationConfig
  | ViewSyncScheduleConfig
  | ViewSyncModelConfig;
