import { FC } from "react";

import { Text } from "@hightouchio/ui";
import { Link } from "src/router";
import moment from "moment";
import pluralize from "pluralize";

import { useUser } from "src/contexts/user-context";
import { Table } from "src/ui/table";
import { OrganizationMetricsQuery } from "src/graphql";
import { TextWithTooltip } from "src/components/text-with-tooltip";

const placeholder = {
  title: "No workspaces data",
  error: "Usage failed to load, please try again.",
};

export type WorkspaceMetrics =
  OrganizationMetricsQuery["getOrganizationMetrics"]["metrics"][0] & {
    roles:
      | OrganizationMetricsQuery["getOrganizationMetrics"]["roles"]
      | undefined;
  };

type Props = {
  metrics: WorkspaceMetrics[];
};

export const WorkspaceMetricsTable: FC<Props> = ({ metrics }) => {
  const { workspace } = useUser();

  return (
    <Table
      columns={[
        {
          name: "Name",
          cell: ({ name, slug }) => {
            return workspace?.slug === slug ? (
              <TextWithTooltip message={`${name} (this workspace)`}>
                <Text>{name} (this workspace)</Text>
              </TextWithTooltip>
            ) : (
              <TextWithTooltip message={name}>
                <Link href={`${window.location.origin}/${slug}`}>{name}</Link>
              </TextWithTooltip>
            );
          },
        },
        {
          name: "Members",
          max: "180px",
          cell: ({ roles }) => {
            if (!roles) {
              return "--";
            }

            const joinedRoles = roles
              .map((r) => `${r.count} ${pluralize(r.name, r.count ?? 0)}`)
              .join(", ");

            return <TextWithTooltip>{joinedRoles}</TextWithTooltip>;
          },
        },
        {
          name: "Destinations",
          max: "120px",
          cell: ({ destinations }) => {
            return destinations || 0;
          },
        },
        {
          name: "Syncs",
          max: "120px",
          cell: ({ syncs }) => {
            return syncs || 0;
          },
        },
        {
          name: "Created",
          max: "140px",
          cell: ({ created_at }) => {
            return moment(parseInt(created_at)).fromNow();
          },
        },
      ]}
      data={metrics}
      placeholder={placeholder}
    />
  );
};
