import { type ExtendedTypesSync, validatorSync as allValidator } from ".";
import type { BaseType, Props, ValidatorSync } from "./base";
import * as yup from "yup";

export interface ArrayType extends BaseType {
  type: "ARRAY";
  /**
   * Inclusive max array size.
   */
  max?: number;
  /**
   * Inclusive min array size.
   */
  min?: number;
  /**
   * Valid items types.
   */
  items?: ExtendedTypesSync;
}

export function array(opts?: Props<ArrayType>): ArrayType {
  return {
    ...opts,
    type: "ARRAY",
  };
}

export const validator: ValidatorSync<ArrayType> = (type) => {
  let schema = yup.array();

  if (typeof type.max === "number") schema = schema.max(type.max);
  if (typeof type.min === "number") schema = schema.min(type.min);

  if (type.items) {
    const validate = allValidator(type.items);
    schema = schema.test((v) => {
      v?.forEach((value, i) => {
        try {
          validate(value);
        } catch (e) {
          throw new Error(`invalid value at index ${i}: ${e?.message || e}`);
        }
      });
      return true;
    });
  }

  return (value) => {
    schema.validateSync(value, { strict: true });
  };
};
