import { Box, Row } from "@hightouchio/ui";
import { times } from "lodash";

export const SentimentIcon = ({
  variant,
  level,
}: {
  variant: "positive" | "negative";
  level: number;
}) => {
  return (
    <Row
      p={1}
      gap="3px"
      align={variant === "negative" ? "flex-start" : "flex-end"}
      transform="scale(.8)"
    >
      {times(3).map((_, i) => {
        const height = 2 + (i + 1) * 5;
        return (
          <Box
            width="4px"
            height={`${height}px`}
            borderRadius="2px"
            key={i}
            bg={variant === "negative" ? "danger.base" : "success.base"}
            opacity={i < level ? 1 : 0.2}
          />
        );
      })}
    </Row>
  );
};
