import { FC, useMemo } from "react";

import * as Yup from "yup";

import { LookupMapper } from "src/components/destinations/lookup-mapper";
import { useDestinationForm } from "src/contexts/destination-form-context";
import { useUser } from "src/contexts/user-context";
import {
  useHubSpotLegacyColumnsQuery,
  useHubSpotLegacyObjectsQuery,
} from "src/graphql";
import { Arrow } from "src/ui/arrow";
import { Section } from "src/ui/section";
import { COMMON_SCHEMAS } from "src/utils/destinations";

import { MappingsField } from "src/components/destinations/mappings-field";
import { ModeField } from "src/components/destinations/mode-field";
import { ObjectField } from "src/components/destinations/object-field";
import {
  Box,
  Combobox,
  FormField,
  GroupedCombobox,
  IconButton,
  RefreshIcon,
  Row,
} from "@hightouchio/ui";

export const validation = Yup.object().shape(
  {
    mode: Yup.string()
      .required()
      .when("object", {
        is: (object) => isEngagementType(object),
        then: Yup.string().oneOf(["insert"]),
        otherwise: Yup.string().default("upsert").oneOf(["upsert", "update"]),
      }),
    object: Yup.string().required().default("contacts"),
    objectName: Yup.string().required().default("contacts"),
    association: Yup.boolean().notRequired(),
    associationType: Yup.string().when("association", {
      is: true,
      then: Yup.string().required(),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    associatedObject: Yup.string().when("association", {
      is: true,
      then: Yup.string().required(),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    associatedObjectExternalIdMapping: Yup.object().when("association", {
      is: true,
      then: COMMON_SCHEMAS.externalIdMapping,
      otherwise: Yup.object().nullable().notRequired().default(undefined),
    }),

    externalIdMapping: Yup.object().when(["externalIdMappings", "mode"], {
      is: (externalIdMappings, mode) =>
        !externalIdMappings && mode !== "insert",
      then: COMMON_SCHEMAS.externalIdMapping,
      otherwise: Yup.object().notRequired().default(undefined),
    }),

    externalIdMappings: Yup.array().when(["mode", "externalIdMapping"], {
      is: (mode, externalIdMapping) =>
        (mode === "upsert" || mode === "update") && !externalIdMapping,
      then: Yup.array()
        .of(COMMON_SCHEMAS.externalIdMapping)
        .min(1)
        .required(
          "There must be at least one merge rule when using the update or upsert mode",
        ),
      otherwise: Yup.array().notRequired(),
    }),

    mappings: COMMON_SCHEMAS.mappings,
    secondaryEmailsFrom: Yup.mixed().notRequired(),
  },
  [["externalIdMapping", "externalIdMappings"]],
);

const MODES = [
  { label: "Upsert", value: "upsert" },
  { label: "Update", value: "update" },
];

const ENGAGEMENT_OBJECT_MODES = [{ label: "Insert", value: "insert" }];

// https://developers.hubspot.com/docs/api/crm/crm-custom-objects
// "Take note of the new id. This is used in combination with the metaType (which is always 2 for portal-specific objects) to generate the objectTypeId. The objectTypeId for the car object is: 2-529881."
const objectIds = {
  contacts: "0-1",
  companies: "0-2",
  deals: "0-3",
  tasks: "0-27",
  notes: "0-46",
  meetings: "0-47",
  calls: "0-48",
  emails: "0-49",
};

// Want to use static fields for engagement type objects because we need to use the v1 api
// when inserting (some properties that we need to write to are read only with v3)
// However, we can still use the v3 api to associate emails with other objects
const emailFields = [
  "metadata.from.email",
  "metadata.from.firstName",
  "metadata.from.lastName",
  "metadata.to.email",
  "metadata.to.firstName",
  "metadata.to.lastName",
  "metadata.cc",
  "metadata.bcc",
  "metadata.subject",
  "metadata.html",
  "metadata.text",
  "metadata.emailSendEventId.id",
  "engagement.timestamp",
];

const isEngagementType = (object: string) => {
  return (
    object === "emails" ||
    object === "calls" ||
    object === "meetings" ||
    object === "tasks" ||
    object === "notes"
  );
};

const getEngagementObjectFields = (object: string) => {
  switch (object) {
    case "emails":
      return emailFields.map((field) => ({
        label: field.replace(/engagement.|metadata./, ""),
        value: field,
      }));
    default:
      return [];
  }
};

export const HubspotLegacyForm: FC = () => {
  const {
    config,
    setConfig,
    errors,
    hightouchColumns,
    destination,
    reloadModel,
    loadingModel,
  } = useDestinationForm();
  const { featureFlags } = useUser();

  const {
    data: objectsData,
    error: objectsError,
    isFetching: loadingObjects,
    refetch: getObjects,
  } = useHubSpotLegacyObjectsQuery({
    destinationId: String(destination?.id),
  });

  const objects = objectsData?.hubspotLegacyDescribeGlobal?.objectsV2;

  const {
    data: columnsData,
    error: columnsError,
    isFetching: loadingColumns,
    refetch: getColumns,
  } = useHubSpotLegacyColumnsQuery(
    {
      destinationId: String(destination?.id),
      object: config?.object,
    },
    { enabled: Boolean(config?.object) },
  );

  const columns = columnsData?.hubspotLegacyDescribeObject;

  const {
    data: associatedColumnsData,
    error: associatedColumnsError,
    isFetching: loadingAssociatedColumns,
    refetch: getAssociatedColumns,
  } = useHubSpotLegacyColumnsQuery(
    {
      destinationId: String(destination?.id),
      object: config?.associatedObject,
    },
    { enabled: Boolean(config?.associatedObject) },
  );

  const associatedColumns = associatedColumnsData?.hubspotLegacyDescribeObject;

  const objectOptions = objects?.map((a) => ({
    label: a.name,
    value: a.id,
    object: a,
  }));

  const selectedObject = config?.object
    ? objectOptions?.find((s) => config?.object === s.value)
    : null;

  const associatedKeyColOpts =
    associatedColumns?.fields?.map((field) => {
      return {
        label: field.name,
        value: field.name,
      };
    }) || [];

  const sfKeyCols = columns?.fields?.map((field) => field.name) || [];

  const allAssociationOpts =
    columns?.associations.map((asc) => {
      return {
        label: asc.name,
        value: asc.name,
        association: asc,
      };
    }) || [];

  const associationOpts = allAssociationOpts.filter((a) => {
    const id = selectedObject?.object?.id;
    const associationObjectId =
      typeof id === "string" ? objectIds[id] || id : id;
    return a.association.fromObjectTypeId === associationObjectId;
  });

  const hightouchColumnGroups = useMemo(() => {
    return hightouchColumns.map((group) => ({
      ...group,
      options: group.options ?? [],
    }));
  }, [hightouchColumns]);

  const fieldMapperOpts = isEngagementType(config?.object)
    ? getEngagementObjectFields(config?.object)
    : columns?.fields?.map((field) => ({
        label: field.name,
        value: field.name,
      })) || [];

  return (
    <>
      <ObjectField
        error={errors?.objectName || objectsError?.message}
        loading={loadingObjects}
        options={objectOptions}
        reload={getObjects}
        onChange={(object) =>
          setConfig({
            object,
            objectName:
              objectOptions?.find((s) => object === s.value)?.label ||
              undefined,
            mode: isEngagementType(object) ? "insert" : config?.mode,
          })
        }
      />

      {config?.object && (
        <ModeField
          options={
            isEngagementType(config?.object) ? ENGAGEMENT_OBJECT_MODES : MODES
          }
          onChange={(mode) => {
            setConfig({
              object: config?.object,
              objectName: config?.objectName,
              mode,
            });
          }}
        />
      )}

      {config?.object &&
        (config?.mode === "upsert" || config?.mode === "update") && (
          <Section>
            <FormField
              isRequired
              error={errors?.externalIdMappings || columnsError?.message}
              tip="These rules will be used to match rows from Hightouch with rows on Hubspot. If there are multiple rules, Hightouch will try each rule in order until there is a match. Each rule will be used as a field mapping as well."
              label="How should records between query results and destination be matched?"
            >
              <LookupMapper
                destColumns={sfKeyCols}
                destColumnsDisabled={!columns}
                destColumnsLoading={loadingColumns}
                destName="Hubspot"
                errors={errors?.externalIdMappings}
              />
            </FormField>
          </Section>
        )}
      {config?.object && (
        <>
          <Section>
            <FormField
              key={config?.associationType || columnsError?.message}
              isOptional
              label="Would you like to add an object association?"
            >
              <Row gap={2} align="center">
                <Combobox
                  isClearable
                  isDisabled={!selectedObject}
                  isLoading={loadingColumns}
                  options={associationOpts}
                  placeholder="Select an association..."
                  value={config?.associationType}
                  onChange={(value) => {
                    if (!value) {
                      setConfig({
                        ...config,
                        association: false,
                        associationType: undefined,
                        associatedObject: undefined,
                        associatedObjectExternalIdMapping: undefined,
                      });
                      return;
                    }

                    const opt = associationOpts.find(
                      (o) => o.association?.name === value,
                    );

                    setConfig({
                      ...config,
                      association: true,
                      associationType: value,
                      associatedObject: opt?.association?.toObjectTypeId,
                      associatedObjectExternalIdMapping: undefined,
                    });
                  }}
                />
                <IconButton
                  aria-label="Refresh association options"
                  isLoading={loadingColumns}
                  icon={RefreshIcon}
                  onClick={() => getColumns()}
                />
              </Row>
            </FormField>
          </Section>

          {config?.association && config?.associationType && (
            <Section>
              <FormField
                error={
                  errors?.associatedObjectExternalIdMapping ||
                  errors?.["associatedObjectExternalIdMapping.from"] ||
                  errors?.["associatedObjectExternalIdMapping.to"] ||
                  associatedColumnsError?.message
                }
                label="How should records between query results and the associated object be matched?"
              >
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(3, max-content)"
                  gap={8}
                  sx={{ alignItems: "center" }}
                >
                  <Row gap={2}>
                    <GroupedCombobox
                      isInvalid={
                        errors?.associatedObjectExternalIdMapping ||
                        errors?.["associatedObjectExternalIdMapping.from"]
                      }
                      isLoading={loadingModel}
                      optionGroups={hightouchColumnGroups}
                      placeholder="Select a column..."
                      width="3xs"
                      value={config?.associatedObjectExternalIdMapping?.from}
                      onChange={(value) => {
                        const mp = config?.associatedObjectExternalIdMapping
                          ? { ...config?.associatedObjectExternalIdMapping }
                          : {};

                        mp.from = value;

                        setConfig({
                          ...config,
                          associatedObjectExternalIdMapping: mp,
                        });
                      }}
                    />
                    <IconButton
                      aria-label="Refresh model columns"
                      isLoading={loadingModel}
                      icon={RefreshIcon}
                      onClick={() => reloadModel()}
                    />
                  </Row>
                  <Arrow />
                  <Row gap={2}>
                    <Combobox
                      isDisabled={!associatedColumns}
                      isInvalid={
                        errors?.associatedObjectExternalIdMapping ||
                        errors?.["associatedObjectExternalIdMapping.to"]
                      }
                      isLoading={loadingAssociatedColumns}
                      options={associatedKeyColOpts}
                      placeholder="Select a field..."
                      width="3xs"
                      value={config?.associatedObjectExternalIdMapping?.to}
                      onChange={(value) => {
                        const mp = config?.associatedObjectExternalIdMapping
                          ? { ...config?.associatedObjectExternalIdMapping }
                          : {};

                        mp.to = value;

                        setConfig({
                          ...config,
                          associatedObjectExternalIdMapping: mp,
                        });
                      }}
                    />
                    <IconButton
                      aria-label="Refresh associated columns"
                      isLoading={loadingAssociatedColumns}
                      icon={RefreshIcon}
                      onClick={() => getAssociatedColumns()}
                    />
                  </Row>
                </Box>
              </FormField>
            </Section>
          )}
        </>
      )}

      {config?.object && (
        <>
          <Section>
            <MappingsField
              error={columnsError?.message}
              loading={loadingColumns}
              options={fieldMapperOpts}
              reload={getColumns}
            />
          </Section>

          {featureFlags?.hubspot_secondary_email &&
            config?.object === "contacts" && (
              <Section>
                <FormField
                  description="Optional - select a column used to drive secondary emails in HubSpot. To send multiple emails, return a comma-separated string from your query."
                  label="Hubspot - Secondary email column (optional)"
                >
                  <Row gap={2} align="center">
                    <GroupedCombobox
                      isClearable
                      isLoading={loadingModel}
                      optionGroups={hightouchColumnGroups}
                      placeholder="Select a column..."
                      value={config?.secondaryEmailsFrom}
                      onChange={(value) => {
                        setConfig({ ...config, secondaryEmailsFrom: value });
                      }}
                    />
                    <IconButton
                      aria-label="Refresh model columns"
                      isLoading={loadingModel}
                      icon={RefreshIcon}
                      onClick={() => reloadModel()}
                    />
                  </Row>
                </FormField>
              </Section>
            )}
        </>
      )}
    </>
  );
};

export default {
  form: HubspotLegacyForm,
  validation,
};
