import {
  useToast,
  ConfirmationDialog,
  Button,
  ButtonProps,
  IconButton,
  DeleteIcon,
  MenuItem,
} from "@hightouchio/ui";
import { captureException } from "@sentry/react";
import { capitalize } from "lodash";
import { useState } from "react";

export const DeleteButton = ({
  label,
  onDelete,
  isDisabled,
  size = "lg",
  variant = "button",
  onSuccess,
}: {
  label: string;
  onDelete: () => Promise<unknown>;
  onSuccess?: () => void;
  isDisabled?: ButtonProps["isDisabled"];
  size?: ButtonProps["size"];
  variant?: "button" | "icon" | "menu";
}) => {
  const { toast } = useToast();
  const [isDeleting, setIsDeleting] = useState(false);

  const onClick = () => {
    setIsDeleting(true);
  };

  return (
    <>
      {variant === "icon" ? (
        <IconButton
          aria-label="Delete"
          variant="danger"
          icon={DeleteIcon}
          onClick={onClick}
          isDisabled={isDisabled}
        />
      ) : variant === "menu" ? (
        <MenuItem
          icon={DeleteIcon}
          variant="danger"
          onClick={onClick}
          isDisabled={isDisabled}
        >
          Delete
        </MenuItem>
      ) : (
        <Button
          size={size}
          variant="warning"
          isDisabled={isDisabled}
          onClick={onClick}
        >
          Delete
        </Button>
      )}
      <ConfirmationDialog
        isOpen={isDeleting}
        onClose={() => setIsDeleting(false)}
        onConfirm={async () => {
          try {
            await onDelete();
            toast({
              id: "delete-resource",
              title: `${capitalize(label)} deleted`,
              variant: "success",
            });
            onSuccess?.();
          } catch (e) {
            captureException(e);
            toast({
              id: "delete-resource",
              title: `Failed to delete ${label}`,
              variant: "error",
            });
          }
        }}
        title={`Delete ${label}`}
        confirmButtonText="Delete"
        variant="danger"
      >
        Are you sure you want to delete this {label}?
      </ConfirmationDialog>
    </>
  );
};
