import { FC } from "react";

import { EditableText, Text } from "@hightouchio/ui";

type Props = {
  name: string;
  isDisabled?: boolean;
  isEditable?: boolean;
  onSubmit: (name: string) => void;
};

export const SplitGroupName: FC<Readonly<Props>> = ({
  name,
  isDisabled,
  isEditable = true,
  onSubmit,
}) => {
  return isEditable ? (
    <EditableText
      fontWeight="medium"
      isDisabled={isDisabled}
      value={name}
      onChange={onSubmit}
    />
  ) : (
    <Text color={isDisabled ? "text.secondary" : undefined} fontWeight="medium">
      {name}
      {isDisabled && " (disabled)"}
    </Text>
  );
};
