import { useMemo } from "react";

import { Row, SearchInput, Text } from "@hightouchio/ui";
import { useNavigate } from "src/router";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { SegmentsBoolExp, useMinimalParentModelsQuery } from "src/graphql";
import useQueryState from "src/hooks/use-query-state";
import { Table } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { SourceBadges } from "src/utils/sources";
import { openUrl } from "src/utils/urls";
import { PermissionedLinkButton } from "src/components/permission";

export const ParentModels = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useQueryState("search");

  const hasuraFilters = useMemo(() => {
    const filters: SegmentsBoolExp = {
      _and: [
        { is_schema: { _eq: true } },
        { _not: { event: {} } },
        { primary_key: { _is_null: false } },
      ],
    };

    if (search) {
      filters._and!.push({ name: { _ilike: `%${search}%` } });
    }

    return filters;
  }, [search]);

  const parentModelsQuery = useMinimalParentModelsQuery(
    { filters: hasuraFilters },
    { keepPreviousData: true },
  );

  const columns = [
    {
      name: "Name",
      cell: ({ name, connection }) => {
        return (
          <Row align="center" gap={2}>
            <IntegrationIcon
              name={connection?.definition?.name}
              src={connection?.definition?.icon}
            />
            <Text isTruncated fontWeight="medium">
              {name ?? "Private source"}
            </Text>
            <SourceBadges
              isSampleDataSource={connection?.definition?.isSampleDataSource}
            />
          </Row>
        );
      },
    },
    LastUpdatedColumn,
  ];

  return (
    <>
      <Row py={8} justify="space-between">
        <SearchInput
          placeholder="Search by name..."
          value={search ?? ""}
          onChange={(event) => setSearch(event.target.value)}
        />
        <PermissionedLinkButton
          permission={{
            v1: { resource: "audience_schema", grant: "create" },
          }}
          href="/schema/parent-models/new"
          variant="primary"
        >
          Add parent model
        </PermissionedLinkButton>
      </Row>

      <Table
        columns={columns}
        data={parentModelsQuery.data?.segments}
        error={Boolean(parentModelsQuery.error)}
        loading={parentModelsQuery.isLoading || parentModelsQuery.isRefetching}
        placeholder={{
          title: "No parent models",
          error: "Parent models failed to load, please try again.",
        }}
        onRowClick={({ id }, event) =>
          openUrl(`/schema/parent-models/${id}`, navigate, event)
        }
      />
    </>
  );
};
