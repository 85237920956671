import { Button, RefreshIcon } from "@hightouchio/ui";
import { useState } from "react";
import { useHightouchFormContext } from "src/components/form";
import { useDecisionEngineEmailPreviewQuery } from "src/graphql";
import { EmailPreview } from "src/pages/decision-engines/components/email-preview";

export const Email = ({ error, variables, isDisabled = false, previewId }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const form = useHightouchFormContext();

  const { data, error: previewError } = useDecisionEngineEmailPreviewQuery(
    {
      previewId: previewId ?? "",
    },
    {
      enabled: !error && Boolean(previewId && isSubmitting),
      refetchInterval: isSubmitting ? 1000 : undefined,
      select: (data) => data.getEmailPreview,
      keepPreviousData: true,
      onSettled: (data, error) => {
        if (data || error) {
          // Stop polling after getting the preview
          setIsSubmitting(false);
        }
      },
    },
  );

  const preview = async () => {
    setIsSubmitting(true);
    try {
      await form.submit();
    } catch (e) {
      setIsSubmitting(false);
    }
  };

  if (isSubmitting && error) {
    setIsSubmitting(false);
  }

  return (
    <EmailPreview
      subject={variables?.subject}
      error={previewError?.message || error}
      isLoading={isSubmitting}
      actions={
        data || error ? (
          <Button
            icon={RefreshIcon}
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
            onClick={preview}
          >
            Refresh
          </Button>
        ) : null
      }
      body={data}
      placeholder={
        <Button
          isDisabled={isDisabled}
          variant="primary"
          size="lg"
          onClick={preview}
        >
          Preview
        </Button>
      }
    />
  );
};
