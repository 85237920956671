import {
  MonitorConditionProperties,
  MonitorConditionTypes,
  MonitoredResourceType,
  MonitorStatus,
  Threshold,
  SupportedConditionsForResourceTypes,
  SupportedConditionsForResourceType,
} from "@hightouch/lib/resource-monitoring/types";
import {
  ChakraStyledOptions,
  Column,
  DeleteIcon,
  IconButton,
  PlusIcon,
  Row,
  Select,
  Switch,
  SwitchProps,
  Text,
  Tooltip,
} from "@hightouchio/ui";
import { isEqual, isNull, isUndefined } from "lodash";
import { FC, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { SlowSyncsField } from "./slow-syncs";
import { UnscheduledSyncsField } from "./unscheduled-syncs";
import { SyncSequentialFailuresField } from "./sequential-sync-failures";
import { SyncRejectedRowsField } from "./sync-rejected-rows";
import { SyncThroughputField } from "./sync-throughput";
import { ModelSizeField } from "./model-size";
import { MonitorConditionFormValue } from "src/components/resource-alert-triggers/types";
import { ElementOf } from "ts-essentials";
import { isEnum, isPresent } from "src/types/utils";
import { getMinimalCondition } from "src/components/resource-alert-triggers/utils";

export type MonitorConditionFormProps = {
  name?: "bulk" | `destinations.${number}`;
  conditionType: BulkEditableMonitorConditionTypes;
  resourceType: MonitoredResourceType;
  title?: React.ReactNode;
  action?: React.ReactNode;
  isObscured?: boolean;
  sx?: ChakraStyledOptions;
  size?: SwitchProps["size"];
  mode?: MonitorModeType;
};

export type ManualMonitorConditionFormValue = {
  warning_value?: any;
  enabled: boolean;
  error_value?: any;
};

export const BulkEditableResourceTypes = [MonitoredResourceType.Sync] as const;

export const BulkEditableMonitorConditionTypes =
  BulkEditableResourceTypes.flatMap(
    (type) => SupportedConditionsForResourceTypes[type],
  );

export type BulkEditableMonitorConditionTypes = ElementOf<
  typeof BulkEditableMonitorConditionTypes
>;

export type BulkEditableResourceTypes = ElementOf<
  typeof BulkEditableResourceTypes
>;

export const isBulkEditableResourceType = isEnum(
  Object.fromEntries(BulkEditableResourceTypes.map((r) => [r, r])),
);

export type SyncResourceType =
  | MonitoredResourceType.EventSync
  | MonitoredResourceType.Sync;

export const SyncMonitorConditionFields: Record<
  SupportedConditionsForResourceType<SyncResourceType>,
  ({ name }: { name: any }) => JSX.Element
> = {
  [MonitorConditionTypes.SyncDuration]: SlowSyncsField,
  [MonitorConditionTypes.SyncSequentialFailures]: SyncSequentialFailuresField,
  [MonitorConditionTypes.SyncMissedSchedules]: UnscheduledSyncsField,
  [MonitorConditionTypes.SyncRejectedRows]: SyncRejectedRowsField,
  [MonitorConditionTypes.SyncRejectedRowsV2]: SyncRejectedRowsField,
  [MonitorConditionTypes.SyncThroughput]: SyncThroughputField,
  [MonitorConditionTypes.ModelSize]: ModelSizeField,
} as const;

export const MonitorModes = {
  UNHEALTHY: [MonitorStatus.Unhealthy],
  WARNING: [MonitorStatus.Warning],
  BOTH: [MonitorStatus.Unhealthy, MonitorStatus.Warning],
} as const;

type MonitorModeType = (typeof MonitorModes)[keyof typeof MonitorModes];

export const valueIsEmpty = (
  conditionType: MonitorConditionTypes,
  value: any,
) => {
  if (conditionType === MonitorConditionTypes.SyncRejectedRows) {
    return (
      isUndefined(value?.rejectedRowCount) &&
      isUndefined(value?.pctRejectedRows)
    );
  }
  if (conditionType === MonitorConditionTypes.SyncThroughput) {
    return isUndefined(value?.count) && isUndefined(value?.sign);
  }
  return isUndefined(value) || isNull(value);
};

export type ThresholdType<T> = T extends Threshold<infer U> ? U : never;

export type MonitorValueType<T extends MonitorConditionTypes> = ThresholdType<
  MonitorConditionProperties[T]["Threshold"]
>;

export const defaultValueForSyncConditionType: {
  [k in SupportedConditionsForResourceType<SyncResourceType>]: MonitorValueType<k>;
} = {
  [MonitorConditionTypes.SyncDuration]: 60,
  [MonitorConditionTypes.SyncSequentialFailures]: 1,
  [MonitorConditionTypes.SyncMissedSchedules]: 60,
  [MonitorConditionTypes.SyncThroughput]: {
    interval: "7 days",
    count: 1,
    sign: "lt",
  },
  [MonitorConditionTypes.SyncRejectedRows]: {
    pctRejectedRows: 1,
    rejectedRowCount: null,
  },
  [MonitorConditionTypes.ModelSize]: [
    {
      sign: "lt",
      count: 1,
    },
  ],
  [MonitorConditionTypes.SyncRejectedRowsV2]: {
    method: "percentage",
    value: 1,
  },
} as const;

export const isMandatoryCondition = ({
  conditionType,
  resourceType,
}: {
  conditionType: MonitorConditionTypes;
  resourceType: MonitoredResourceType;
}) => {
  return (
    resourceType === MonitoredResourceType.Sync &&
    (conditionType === MonitorConditionTypes.SyncSequentialFailures ||
      conditionType === MonitorConditionTypes.SyncRejectedRows)
  );
};

export const MonitorConditionForm: FC<MonitorConditionFormProps> = ({
  name,
  conditionType,
  resourceType,
  title,
  action,
  isObscured,
  sx: propSx,
  size = "md",
  mode: controlledMode,
}) => {
  const field = name
    ? (`${conditionType}.${name}` as const)
    : (`${conditionType}` as const);

  const isMandatory = isMandatoryCondition({ conditionType, resourceType });

  const { watch, setValue } = useFormContext();
  const condition = watch(field as any) ?? {};

  const errorValueEmpty = valueIsEmpty(conditionType, condition.error_value);
  const warningValueEmpty = valueIsEmpty(
    conditionType,
    condition.warning_value,
  );

  const mode = useMemo(() => {
    if (controlledMode) return controlledMode;
    if (!warningValueEmpty) {
      if (!errorValueEmpty) {
        return MonitorModes.BOTH;
      }
      return MonitorModes.WARNING;
    }
    return MonitorModes.UNHEALTHY;
  }, [errorValueEmpty, warningValueEmpty]);

  const onModeChange = (mode: MonitorModeType) => {
    if (controlledMode) return;
    const { error_value, warning_value } = condition;
    const defaultValue = defaultValueForSyncConditionType[conditionType];

    const newCondition: MonitorConditionFormValue = (() => {
      const value = error_value ?? warning_value ?? defaultValue;
      switch (mode) {
        case MonitorModes.BOTH:
          return {
            ...condition,
            error_value: value,
            warning_value: value,
          };
        case MonitorModes.UNHEALTHY:
          return {
            ...condition,
            error_value: error_value ?? warning_value ?? defaultValue,
            warning_value: null,
          };
        case MonitorModes.WARNING:
          return {
            ...condition,
            error_value: null,
            warning_value: warning_value ?? error_value ?? defaultValue,
          };
      }
    })();
    setValue(field, newCondition, { shouldDirty: true });
  };

  const sx = useMemo(() => {
    if (isObscured) {
      return {
        opacity: 0.5,
        pointerEvents: "none",
        ...propSx,
      };
    }
    return propSx ?? {};
  }, [isObscured, propSx]);

  return (
    <>
      <Column
        gap={2}
        p={4}
        pl={size === "sm" ? 4 : 6}
        sx={sx}
        justify="space-between"
      >
        <Row align="center" gap={4}>
          <Controller
            name={`${field}.enabled`}
            render={({ field }) => {
              return (
                <Tooltip
                  isDisabled={!(isMandatory && field.value === true)} // Only disable the switch if it's mandatory _and_ on; mandatory switches *shouldn't* ever be off, but just in case they are, don't get stuck
                  message="This default trigger cannot be disabled, but you can edit its thresholds."
                >
                  <Switch
                    size={size}
                    isDisabled={isMandatory && field.value === true}
                    isChecked={field.value}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                  />
                </Tooltip>
              );
            }}
          />
          {title}
        </Row>
        {action}
      </Column>
      <Column gap={2} p={4} sx={sx}>
        <MonitorConditionField
          conditionType={conditionType}
          name={field}
          mode={mode}
        />
      </Column>
      {isPresent(controlledMode) ? null : (
        <Row p={4} sx={sx}>
          <MonitorConditionAction mode={mode} onChange={onModeChange} />
        </Row>
      )}
    </>
  );
};

export const MonitorConditionField: FC<
  Readonly<{
    mode: MonitorModeType;
    name: string;
    conditionType: MonitorConditionTypes;
  }>
> = ({ conditionType, name, mode }) => {
  return (
    <>
      {mode.map((status: MonitorStatus) => {
        const valueFormFieldName = `${name}.${
          status === MonitorStatus.Unhealthy ? "error" : "warning"
        }_value`;
        const Field = SyncMonitorConditionFields[conditionType];
        if (Field) {
          return <Field name={valueFormFieldName} key={status} />;
        }
        return null;
      })}
    </>
  );
};

export const MonitorConditionAction = ({ mode, onChange }) => {
  if (isEqual(mode, MonitorModes.BOTH)) {
    return (
      <Column flex={1}>
        <Row flex={1} gap={2} justify="space-between" align="center">
          <Text fontWeight="medium">
            Mark sync as <Text color="danger.base">unhealthy</Text> (and send
            alert)
          </Text>
          <IconButton
            aria-label=""
            icon={DeleteIcon}
            variant="danger"
            onClick={() => {
              onChange(MonitorModes.WARNING);
            }}
          />
        </Row>
        <Row flex={1} gap={2} justify="space-between" align="center">
          <Text fontWeight="medium">
            Only display in-app <Text color="warning.base">warning</Text> (no
            alert)
          </Text>
          <IconButton
            aria-label=""
            icon={DeleteIcon}
            variant="danger"
            onClick={() => {
              onChange(MonitorModes.UNHEALTHY);
            }}
          />
        </Row>
      </Column>
    );
  }

  return (
    <Column gap={2} flex={1} align="flex-start" justify="center">
      <Select
        width="100%"
        value={mode}
        onChange={onChange}
        options={[
          {
            label: "Mark sync as unhealthy (and send alert)",
            value: MonitorModes.UNHEALTHY,
          },
          {
            label: "Only display in-app warning (no alert)",
            value: MonitorModes.WARNING,
          },
        ]}
      />
      <Row
        gap={2}
        cursor="pointer"
        role="button"
        color="link.default"
        fontWeight="medium"
        onClick={() => {
          onChange(MonitorModes.BOTH);
        }}
      >
        <PlusIcon />
        <Text color="inherit">
          {mode === MonitorModes.UNHEALTHY
            ? "Set a different trigger for in-app warnings"
            : "Set a different trigger for unhealthy alerts"}
        </Text>
      </Row>
    </Column>
  );
};

export function makeConditionMap<T extends ManualMonitorConditionFormValue>(
  conditions: (T & {
    type: string;
  })[],
) {
  return Object.fromEntries(
    conditions.map((condition) => [
      condition.type,
      getMinimalCondition(condition),
    ]),
  );
}
