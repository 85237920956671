import { useEffect } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, UseFormReturn } from "react-hook-form";
import { array, number, object, string } from "yup";

import {
  AttributionWindow,
  ConditionType,
  IntervalUnit,
  RawColumn,
  RelatedColumn,
  UserDefinedMetricConfig,
} from "src/types/visual";

import {
  AggregationOption,
  AggregationOptionsWithColumns,
  DEFAULT_ATTRIBUTION_WINDOW,
} from "./constants";
import type { MetricDisplayFormatting } from "@hightouch/lib/query/visual/types/goals";
import { DefaultDisplayFormatting } from "./formatting/format-metric";

export type MetricFormData = {
  config?: {
    type: ConditionType.Event;
    eventModelId: UserDefinedMetricConfig["eventModelId"] | null;
    relationshipId: UserDefinedMetricConfig["relationshipId"] | null;
    filter?: UserDefinedMetricConfig["filter"];
    displayFormatting?: MetricDisplayFormatting;
  };
  column?: RawColumn | RelatedColumn | null;
  aggregationType: AggregationOption;
  attributionWindow: AttributionWindow;
  // id is optional to support a default empty case, but it is validated on submit
  attributionMethods: Array<{ id?: string }>;
};

const metricValidationSchema = object().shape({
  aggregationType: string()
    .oneOf(Object.values(AggregationOption))
    .required("Aggregation type required"),
  column: object()
    .nullable()
    .when("aggregationType", {
      is: (val) => AggregationOptionsWithColumns.includes(val),
      then: object().required("Column is required"),
    }),
  config: object().shape({
    relationshipId: string().nullable(),
    eventModelId: string().nullable().required("Event is required"),
    displayFormatting: object()
      .shape({
        type: string().oneOf(["percentage", "currency", "number"]).required(),
      })
      .nullable(),
  }),
  attributionWindow: object()
    .shape({
      quantity: number().required(),
      unit: string()
        .oneOf([IntervalUnit.Day, IntervalUnit.Week, IntervalUnit.Month])
        .required(),
    })
    .required("Attribution window required"),
  attributionMethods: array().of(
    object().shape({
      id: string().required("Attribution method is required"),
    }),
  ),
});

export const metricFormDefaultValues: MetricFormData = {
  aggregationType: AggregationOption.Count,
  column: null,
  attributionWindow: DEFAULT_ATTRIBUTION_WINDOW,
  config: {
    type: ConditionType.Event,
    eventModelId: null,
    relationshipId: null,
    filter: { subconditions: [] },
    displayFormatting: DefaultDisplayFormatting,
  },
  attributionMethods: [],
};

export const useMetricForm = (
  metricData?: MetricFormData,
): UseFormReturn<MetricFormData> => {
  const formData = useForm({
    resolver: yupResolver(metricValidationSchema),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - Circular type problem with Condition[]
    defaultValues: metricFormDefaultValues,
  });

  useEffect(() => {
    if (metricData) {
      formData.reset(metricData);
    }
  }, [metricData]);

  return formData;
};
