import { FC } from "react";

import {
  Row,
  Column,
  Tooltip,
  InformationIcon,
  Text,
  SectionHeading,
  Box,
} from "@hightouchio/ui";

import { Card } from "src/components/card";

type Props = {
  value: number | undefined | null;
};

export const Latency: FC<Readonly<Props>> = ({ value }) => {
  return (
    <Card>
      <SectionHeading mb={4}>Past 24 hours</SectionHeading>
      <Column gap={1}>
        <Row gap={1} align="center">
          <Text
            fontWeight="semibold"
            color="text.secondary"
            textTransform="uppercase"
          >
            Latency (avg)
          </Text>
          <Tooltip message="Average time to deliver events to the configured destination">
            <InformationIcon />
          </Tooltip>
        </Row>

        <Box fontWeight="semibold" fontSize="4xl">
          {value ? `${(value / 1000).toFixed(2)}s` : "--"}
        </Box>
      </Column>
    </Card>
  );
};
