import { useMemo, useState } from "react";

import { SegmentsBoolExp, useMinimalParentModelsQuery } from "src/graphql";

export const useParentModelSelect = () => {
  const [search, setSearch] = useState("");

  const filters = useMemo(() => {
    const baseFilters: SegmentsBoolExp = {
      is_schema: { _eq: true },
      primary_key: { _is_null: false },
    };

    if (search) {
      baseFilters._and = [{ name: { _ilike: `%${search}%` } }];
    }

    return baseFilters;
  }, [search]);

  const minimalParentModelsQuery = useMinimalParentModelsQuery(
    {
      filters,
    },
    { select: (data) => data.segments },
  );

  return {
    options: minimalParentModelsQuery.data,
    isLoading: minimalParentModelsQuery.isLoading,
    error: minimalParentModelsQuery.error,
    search,
    onSearch: setSearch,
  };
};
