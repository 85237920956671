import { FC, ReactNode } from "react";

import { Column } from "@hightouchio/ui";

type Props = {
  children: ReactNode;
};

export enum GridTemplateColumn {
  SplitGroupsStart = "split-groups-start",
  SplitGroupsEnd = "split-groups-end",
  ConnectionsStart = "connections-start",
  ConnectionsEnd = "connections-end",
  SyncsStart = "syncs-start",
  SyncsEnd = "syncs-end",
}

export const Grid: FC<Readonly<Props>> = ({ children }) => (
  <Column
    display="grid"
    gridTemplateColumns={`
      [${GridTemplateColumn.SplitGroupsStart}]
        2fr
      [${GridTemplateColumn.SplitGroupsEnd} ${GridTemplateColumn.ConnectionsStart}]
        1fr
      [${GridTemplateColumn.ConnectionsEnd} ${GridTemplateColumn.SyncsStart}]
        2fr
      [${GridTemplateColumn.SyncsEnd}]
    `}
  >
    {children}
  </Column>
);

export const CONNECTIONS_CONTAINER_ID = "connections-container";
