import { FC, ReactNode } from "react";

import {
  Box,
  Row,
  Column,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  IconButton,
  BoxProps,
} from "@hightouchio/ui";

import { useHeaderHeight } from "src/contexts/header-height-context";
import { usePersistedState } from "src/hooks/use-persisted-state";

export enum SidebarKey {
  Analytics = "analytics-sidebar-open",
  General = "sidebar-open", // non-page-specific
}

type PageSidebarProps = {
  children: ReactNode;
  stateKey?: string;
  width?: string;
  header: ReactNode;
  contentStyles?: BoxProps;
  ignoreHeaderHeight?: boolean;
};

export const PageSidebar: FC<Readonly<PageSidebarProps>> = ({
  header,
  children,
  stateKey = SidebarKey.General,
  width = "280px",
  contentStyles = {},
  ignoreHeaderHeight = false,
}) => {
  const { value: open, set: setOpen } = usePersistedState<boolean>(
    stateKey,
    true,
  );
  const { headerHeight } = useHeaderHeight();

  const toggle = (
    <Box
      pos="absolute"
      right={open ? -4 : 0}
      top={4}
      sx={{ button: { borderRadius: "50%" } }}
    >
      <IconButton
        variant="secondary"
        aria-label={open ? "Close sidebar" : "Open sidebar"}
        icon={open ? ChevronDoubleLeftIcon : ChevronDoubleRightIcon}
        onClick={() => setOpen(!open)}
      />
    </Box>
  );

  return (
    <Column
      zIndex={1}
      pos="sticky"
      top={ignoreHeaderHeight ? 0 : `${headerHeight}px`}
      bg="gray.50"
      flexShrink={0}
      height={
        ignoreHeaderHeight ? undefined : `calc(100vh - ${headerHeight}px)`
      }
      overscrollBehavior="none"
      width={open ? width : "24px"}
      borderRight="1px solid"
      borderColor="base.border"
    >
      {header ? (
        <>
          <Row
            align="center"
            p={4}
            pr={6}
            pos="relative"
            borderBottom="1px"
            borderColor={open ? "base.border" : "transparent"}
          >
            {open ? header : null}
            {toggle}
          </Row>
          <Column
            gap={4}
            overflow="auto"
            visibility={open ? "visible" : "hidden"}
            p={2}
            {...contentStyles}
          >
            {children}
          </Column>
        </>
      ) : (
        <Column gap={4} overflow="auto" p={4} pos="relative">
          {toggle}
          <Box display="contents" visibility={open ? "visible" : "hidden"}>
            {children}
          </Box>
        </Column>
      )}
    </Column>
  );
};
