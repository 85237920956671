import { FC, ReactNode } from "react";

import {
  Box,
  IconButton,
  BoldIcon,
  ItalicIcon,
  StrikethroughSIcon,
  LinkIcon,
  // ListNumberedIcon,
  // ListBulletedIcon,
  CodeIcon,
  CodeBlockIcon,
} from "@hightouchio/ui";
import { Editor } from "@tiptap/react";

export const SlackToolbar: FC<{
  editor: Editor | null;
  additionalComponents: ReactNode;
  isDisabled?: boolean;
}> = ({ editor, additionalComponents, isDisabled }) => {
  const setLink = () => {
    const previousUrl = editor?.getAttributes("link").href;
    const url = window.prompt("URL", previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === "") {
      editor?.chain().focus().extendMarkRange("link").unsetLink().run();

      return;
    }

    // update link
    editor
      ?.chain()
      .focus()
      .extendMarkRange("link")
      .setLink({ href: url })
      .run();
  };

  return (
    <Box opacity={0.5}>
      <Box float="left">
        <IconButton
          isDisabled={isDisabled}
          icon={BoldIcon}
          variant={editor?.isActive("bold") ? "secondary" : undefined}
          aria-label="Bold"
          size="lg"
          onClick={() => {
            editor?.chain().focus().toggleBold().run();
          }}
        />
        <IconButton
          isDisabled={isDisabled}
          icon={ItalicIcon}
          variant={editor?.isActive("italic") ? "secondary" : undefined}
          aria-label="Italic"
          size="lg"
          onClick={() => {
            editor?.chain().focus().toggleItalic().run();
          }}
        />
        <IconButton
          isDisabled={isDisabled}
          icon={StrikethroughSIcon}
          aria-label="Strike"
          variant={editor?.isActive("strike") ? "secondary" : undefined}
          size="lg"
          onClick={() => {
            editor?.chain().focus().toggleStrike().run();
          }}
        />
        <IconButton
          isDisabled={isDisabled}
          icon={LinkIcon}
          variant={editor?.isActive("link") ? "secondary" : undefined}
          aria-label="Link"
          size="lg"
          onClick={() => {
            setLink();
          }}
        />
        {/* 
        // This is temporarily disabled until we can get it's behavior stable.
        <IconButton
          isDisabled={isDisabled}
          icon={ListNumberedIcon}
          variant={editor?.isActive("orderedList") ? "secondary" : undefined}
          aria-label="Ordered list"
          size="lg"
          onClick={() => {
            editor?.chain().focus().toggleOrderedList().run();
          }}
        />
        <IconButton
          isDisabled={isDisabled}
          icon={ListBulletedIcon}
          variant={editor?.isActive("bulletList") ? "secondary" : undefined}
          aria-label="Unordered list"
          size="lg"
          onClick={() => {
            editor?.chain().focus().toggleBulletList().run();
          }}
        /> */}
        <IconButton
          isDisabled={isDisabled}
          icon={CodeIcon}
          variant={editor?.isActive("code") ? "secondary" : undefined}
          aria-label="Code"
          size="lg"
          onClick={() => {
            editor?.chain().focus().toggleCode().run();
          }}
        />
        <IconButton
          isDisabled={isDisabled}
          icon={CodeBlockIcon}
          variant={editor?.isActive("codeBlock") ? "secondary" : undefined}
          aria-label="Codeblock"
          size="lg"
          onClick={() => {
            editor?.chain().focus().toggleCodeBlock().run();
          }}
        />
      </Box>

      <Box textAlign="right" mr={5} h={10}>
        {additionalComponents}
      </Box>
    </Box>
  );
};
