import { useState } from "react";

import "react-phone-number-input/style.css";
import {
  Row,
  Column,
  FormField,
  Button,
  TextInput,
  CloseIcon,
  IconButton,
} from "@hightouchio/ui";
import PhoneInput from "react-phone-number-input";
import validator from "validator";

import { SmsAlert, SmsAlertConfig } from "./types";

export const SmsForm = ({
  config,
  setConfig,
}: {
  config: SmsAlertConfig;
  setConfig: (config: SmsAlertConfig) => void;
}) => {
  const [newSms, setNewSms] = useState<string | undefined | null>();

  const recipients = config?.recipients || [];

  const setRecipients = (recipients: string[]) => {
    setConfig({ ...config, recipients });
  };

  return (
    <FormField label="Recipients">
      {!!recipients?.length && (
        <Column gap={2} mb={2}>
          {recipients?.map((r, idx) => (
            <Row key={idx} alignItems="center" gap={2}>
              <TextInput
                isReadOnly
                placeholder="Enter a phone number..."
                value={r || ""}
              />
              <IconButton
                aria-label="Remove recipient"
                icon={CloseIcon}
                onClick={() => {
                  const a = [...recipients];
                  a.splice(idx, 1);
                  setRecipients(a);
                }}
              />
            </Row>
          ))}
        </Column>
      )}
      <Row alignItems="center" gap={2}>
        <Row ml={1}>
          <PhoneInput
            defaultCountry="US"
            inputComponent={TextInput}
            placeholder="Enter a phone number..."
            sx={{ width: "300px", ml: 1 }}
            value={newSms || ""}
            onChange={(phone) => {
              setNewSms(phone);
            }}
          />
        </Row>
        <Button
          isDisabled={
            !newSms ||
            !validator.isMobilePhone(newSms, "any", { strictMode: true })
          }
          onClick={() => {
            if (newSms) {
              setRecipients([...recipients, newSms]);
            }

            setNewSms(null);
          }}
        >
          Add
        </Button>
      </Row>
    </FormField>
  );
};

export const smsValidator = (alert: SmsAlert): boolean => {
  const config = alert?.config;
  return Boolean(config?.recipients?.length);
};
