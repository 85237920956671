import { Box, DeleteIcon, Row, Text, UserIcon } from "@hightouchio/ui";
import { Link } from "src/router";
import pluralize from "pluralize";
import { FC, useState } from "react";
import { PermissionedIconButton } from "src/components/permission";
import { getGrantType } from "./util";
import { useIsPermissionsV2Enabled } from "src/components/permission/utils";
import { DeleteSubsetModal } from "./delete-subset-modal";

type Props = {
  id: string;
  name: string;
  categoryName: string;
  parentModelId: string;
  userCount: number;
  audiencesSubsets: {
    audience: {
      id: any;
      name: string;
    };
    created_at: string;
    created_by_user: {
      name;
    } | null;
  }[];
};

export const SubsetPreview: FC<Readonly<Props>> = ({
  id,
  name,
  categoryName,
  parentModelId,
  userCount,
  audiencesSubsets,
}) => {
  const permissionsV2Enabled = useIsPermissionsV2Enabled();
  const [showDeletionConfirmation, setShowDeletionConfirmation] =
    useState(false);

  return (
    <>
      <Row
        alignItems="center"
        borderRadius="sm"
        background="base.lightBackground"
        border="1px solid"
        borderColor="base.border"
        justifyContent="space-between"
        px={6}
        py={4}
      >
        <Link href={`subsets/${id}${window.location.search}`}>{name}</Link>
        <Row>
          <Row
            alignItems="center"
            borderRight="1px solid"
            borderColor="base.divider"
            pr={4}
            mr={4}
            gap={2}
          >
            <Box as={UserIcon} color="text.secondary" fontSize={20} />
            <Text color="text.secondary">
              {userCount}{" "}
              {pluralize(getGrantType(permissionsV2Enabled), userCount)}{" "}
              {pluralize("has", userCount)} access
            </Text>
          </Row>
          <PermissionedIconButton
            permission={{
              v1: { resource: "audience_schema", grant: "update" },
              v2: {
                resource: "model",
                grant: "can_update",
                id: parentModelId ?? "",
              },
            }}
            aria-label="Delete subset"
            icon={DeleteIcon}
            variant="danger"
            onClick={() => setShowDeletionConfirmation(true)}
          />
        </Row>
      </Row>
      <DeleteSubsetModal
        id={id}
        name={`"${categoryName}" = "${name}"`}
        isOpen={showDeletionConfirmation}
        audiencesSubsets={audiencesSubsets}
        onClose={() => setShowDeletionConfirmation(false)}
      />
    </>
  );
};
