import { FC } from "react";

import { ChakraModal, ChakraModalOverlay } from "@hightouchio/ui";

import { BillingOrganizationQuery } from "src/graphql";

import { PricingDisplay } from "../pricing/pricing-display";

export type PricingModalProps = {
  organization: BillingOrganizationQuery["getBillingOrganization"] | undefined;
  isOpen: boolean;
  onClose: () => void;
};

export const PricingModal: FC<Readonly<PricingModalProps>> = ({
  isOpen,
  onClose,
  organization,
}) => {
  return (
    <ChakraModal
      isCentered
      size="4xl"
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
    >
      <ChakraModalOverlay />
      <PricingDisplay organization={organization} onClose={onClose} />
    </ChakraModal>
  );
};
