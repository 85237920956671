import {
  addDays,
  addHours,
  formatDuration,
  intervalToDuration,
} from "date-fns";
import pluralize from "pluralize";
import { IntervalSchedule } from "src/components/schedule/types";
import { FormkitModel } from "src/formkit/components/formkit-context";
import {
  ModelWithoutColumnsQuery,
  ObjectQuery,
  SyncQuery,
} from "src/graphql/types";
import { isSyncMatchBoosted } from "src/pages/syncs/sync/matchbooster";

export function formatDurationDisplay({
  start,
  end,
}: {
  start: number | Date;
  end: number | Date;
}) {
  return overrideDateTimeUnits(
    formatDuration(
      intervalToDuration({
        start,
        end,
      }),
      { zero: false, format: ["days", "hours", "minutes"] },
    ),
    { hour: "hr", minute: "min" },
  );
}

// override date-fn formattedDuration specific units spelling
function overrideDateTimeUnits(
  formattedDuration: string,
  unitFormats: { hour: string; minute: string },
) {
  return formattedDuration
    .replace(/\bhour(s?)\b/g, `${unitFormats.hour}$1`)
    .replace(/\bminute(s?)\b/g, `${unitFormats.minute}$1`);
}

export function getNextScheduledEnrichment(
  enrichmentSchedule: IntervalSchedule | undefined,
  lastEnrichedAt: string | undefined,
) {
  const interval = enrichmentSchedule?.schedule?.interval;

  if (!lastEnrichedAt || !interval?.quantity) return null;

  switch (interval?.unit) {
    case "hour":
      return addHours(new Date(lastEnrichedAt), interval.quantity);
    case "day":
      return addDays(new Date(lastEnrichedAt), interval.quantity);
    case "week":
      return addDays(new Date(lastEnrichedAt), interval.quantity * 7);
    default:
      return addDays(new Date(lastEnrichedAt), 14);
  }
}

export function getEnrichmentSchedule(
  enrichmentSchedule: IntervalSchedule | undefined,
) {
  const interval = enrichmentSchedule?.schedule?.interval;
  // Default to every day if there's no schedule or we don't have all the
  // info for some reason
  if (!interval || !interval.quantity || !interval.unit) {
    return "Every day";
  }

  const { unit, quantity } = interval;

  return `Every ${numberAsWords(quantity)} ${pluralize(unit, quantity)}`;
}

function numberAsWords(num: number): string | number {
  if (num === 1) return "one";
  if (num === 2) return "two";
  if (num === 3) return "three";
  if (num === 4) return "four";
  if (num === 5) return "five";
  if (num === 6) return "six";
  if (num === 7) return "seven";
  if (num === 8) return "eight";
  if (num === 9) return "nine";
  if (num === 10) return "ten";
  return num;
}

export function doesModelContainBoostedSyncs(
  model:
    | ModelWithoutColumnsQuery["segments_by_pk"]
    | ObjectQuery["segments_by_pk"]
    | null
    | undefined,
) {
  // Assert as ModelQuery to check for syncs property. Audience's parent model
  // does not return the syncs property so this will always be false
  const syncs =
    (model as ModelWithoutColumnsQuery["segments_by_pk"])?.syncs ?? [];
  if (!model || !syncs.length) return false;

  for (const sync of syncs) {
    if (isSyncMatchBoosted(sync.config)) {
      return true;
    }
  }

  return false;
}

export type IdentifierField = {
  id: string;
  columnName: string;
  identifier: string;
};

export function containsModelColumn(fields: IdentifierField[]): boolean {
  if (!fields.length) return false;

  return fields.some((f) => f.columnName != null);
}

export function getModelToBoostLink(
  model: SyncQuery["syncs"][0]["segment"] | FormkitModel | null | undefined,
): string {
  if (!model || !model?.id) return "";

  const { id, visual_query_parent_id, connection } = model;

  if (visual_query_parent_id) {
    if (!connection?.id) return "";

    return `/schema-v2/view/match-booster?source=${connection.id}&id=${visual_query_parent_id}`;
  }

  return `/models/${id}/match-booster`;
}

export function isConversionSyncType(
  configType: string | undefined,
  destinationType: string | undefined,
) {
  if (!configType) return false;

  // Meta CAPI is its own destination
  if (destinationType === "facebookConversions") return true;

  return [
    // TikTok
    "event",
    "offline-event",
    // GAds
    "ClickConversions",
    "OfflineStoreConversions",
  ].includes(configType);
}

type DestinationMetadata =
  | { status: string | undefined; [x: string]: unknown }
  | null
  | undefined;

export function isAudienceStatusProcessing(metadata: DestinationMetadata) {
  return metadata?.status === "processing";
}

/**
 * If the destination doesn't provide us an explicit processing state, we assume
 * the matched count can still be settling if it's been under 72 hours since the
 * first sync run ran (based on our own testing) so we can't trust this number yet.
 */
export function isMatchedUsersCountSettling(
  syncFirstRunAt: string | null | undefined,
  metadata: DestinationMetadata,
  useExistingSegment: boolean | undefined,
) {
  if (
    !syncFirstRunAt ||
    isAudienceStatusProcessing(metadata) ||
    useExistingSegment
  )
    return false;

  return Date.now() - new Date(syncFirstRunAt).getTime() < 72 * 60 * 60 * 1000;
}
