import { DecisionEngineStatus } from "@hightouch/lib/customer-data/decision-engine/types";
import {
  FormField,
  Column,
  TextInput,
  NumberInput,
  Combobox,
  Button,
  Dialog,
  ButtonGroup,
} from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller } from "react-hook-form";
import { useNavigate, useOutletContext } from "src/router";
import * as yup from "yup";

import { Form, SaveButton, useHightouchForm } from "src/components/form";
import {
  useCreateDecisionEngineFlowMutation,
  useEligibleTargetAudiencesQuery,
} from "src/graphql";
import { OutletContext } from "src/pages/decision-engines";

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  audience_id: yup.string().required("Target audience is required"),
  holdout: yup.number().required("Holdout is required"),
});

export const AddFlow = () => {
  const { engine } = useOutletContext<OutletContext>();
  const navigate = useNavigate();

  const create = useCreateDecisionEngineFlowMutation();

  const modelsQuery = useEligibleTargetAudiencesQuery(
    {
      parentModelId: engine.segment.id,
    },
    {
      select: (data) => data.segments,
    },
  );

  const form = useHightouchForm({
    success: "Flow added",
    resolver: yupResolver(schema),
    onSubmit: async (data) => {
      const response = await create.mutateAsync({
        input: {
          decision_engine_id: engine.id,
          name: data.name,
          audience_id: data.audience_id,
          status: DecisionEngineStatus.PENDING,
          config: {
            holdout: data.holdout,
          },
        },
      });
      navigate(`/ai/flows/${response.insert_decision_engine_flows_one?.id}`);
    },
    defaultValues: {
      name: "",
      audience_id: "",
      holdout: 0.2,
    },
  });

  const onClose = () => {
    navigate("/ai/flows");
  };

  return (
    <Form form={form}>
      <Dialog
        variant="form"
        isOpen
        title="Add flow"
        onClose={onClose}
        actions={
          <ButtonGroup>
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
            <SaveButton size="md">Add flow</SaveButton>
          </ButtonGroup>
        }
      >
        <Column gap={6}>
          <Controller
            name="name"
            render={({ field, fieldState: { error } }) => (
              <FormField label="Flow name" error={error?.message}>
                <TextInput isInvalid={Boolean(error)} {...field} />
              </FormField>
            )}
          />
          <Controller
            name="audience_id"
            render={({ field, fieldState: { error } }) => (
              <FormField label="Target audience" error={error?.message}>
                <Combobox
                  {...field}
                  isInvalid={Boolean(error)}
                  isLoading={modelsQuery.isLoading}
                  optionValue={(option) => option.id}
                  optionLabel={(option) => option.name}
                  options={modelsQuery.data ?? []}
                />
              </FormField>
            )}
          />
          <Controller
            name="holdout"
            render={({ field, fieldState: { error } }) => (
              <FormField label="Holdout" error={error?.message}>
                <NumberInput
                  {...field}
                  isInvalid={Boolean(error)}
                  placeholder="Set a holdout..."
                  formatOptions={{ style: "percent" }}
                  min={0}
                  max={1}
                />
              </FormField>
            )}
          />
        </Column>
      </Dialog>
    </Form>
  );
};
