import { FC } from "react";

import { Heading } from "@hightouchio/ui";

import { CreateWorkspace } from "src/components/workspaces/create-workspace";
import { Card } from "src/components/card";
import { switchWorkspace } from "src/utils/workspaces";

export const Page: FC = () => {
  return (
    <>
      <Heading mb={10} size="2xl">
        Create a new workspace
      </Heading>
      <Card width="536px">
        <CreateWorkspace
          onSubmit={({ id, slug }) => {
            switchWorkspace(id, `/${slug}`);
          }}
        />
      </Card>
    </>
  );
};
