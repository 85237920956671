import { FC } from "react";

import {
  Box,
  Column,
  Row,
  Text,
  Heading,
  SectionHeading,
  SuccessIcon,
} from "@hightouchio/ui";
import { Link } from "src/router";
import Helmet from "react-helmet";
import { useNavigate } from "src/router";

import { AuthLayout } from "src/components/auth/auth-layout";
import {
  EmailButton,
  GoogleButton,
  MicrosoftButton,
} from "src/components/auth/buttons";
import { Terms } from "src/components/auth/terms";
import { useAuthState } from "src/components/auth/use-auth-state";
import { Logo } from "src/ui/brand";

export const Signup: FC = () => {
  const navigate = useNavigate();
  const { googleAuthUrl, microsoftAuthUrl } = useAuthState();

  return (
    <>
      <Helmet>
        <title>Sign up</title>
      </Helmet>

      <AuthLayout logo={false} pt={10}>
        <Column
          flex={1}
          maxWidth="960px"
          minHeight="500px"
          my="auto"
          width="100%"
        >
          <Box alignSelf="flex-start" mb={16}>
            <Logo size="160px" variant="full" theme="light" />
          </Box>

          <Row justify="space-between" gap={4}>
            <Column gap={12} maxW="440px" sx={{ h2: { color: "white" } }}>
              <Heading size="2xl">
                Join thousands of companies who{" "}
                <Box as="span" color="brand.accent">
                  activate their data
                </Box>{" "}
                using Hightouch
              </Heading>

              <Column as="ul" gap={3} p={0}>
                <Row align="center" as="li" gap={2}>
                  <Box as={SuccessIcon} fontSize="20px" color="brand.accent" />
                  <Text fontWeight="medium" color="white">
                    Generous free tier (no credit card required)
                  </Text>
                </Row>
                <Row align="center" as="li" gap={2}>
                  <Box as={SuccessIcon} fontSize="20px" color="brand.accent" />
                  <Text fontWeight="medium" color="white">
                    Easy setup, plus 24/7 technical support
                  </Text>
                </Row>
                <Row align="center" as="li" gap={2}>
                  <Box as={SuccessIcon} fontSize="20px" color="brand.accent" />
                  <Text fontWeight="medium" color="white">
                    {import.meta.env.VITE_SOURCES_COUNT}+ data sources and{" "}
                    {import.meta.env.VITE_DESTINATIONS_COUNT}+ destinations
                  </Text>
                </Row>
              </Column>
            </Column>
            <Column gap={8} width="350px">
              <Column gap={4} sx={{ "> span": { fontSize: "16px" } }}>
                <Text fontWeight="semibold" color="white">
                  Sign up with your work email
                </Text>
                <Column gap={2}>
                  <GoogleButton
                    action="Sign up"
                    onClick={() => {
                      window.location.href = googleAuthUrl;
                    }}
                  />
                  <MicrosoftButton
                    action="Sign up"
                    onClick={() => {
                      window.location.href = microsoftAuthUrl;
                    }}
                  />
                  <EmailButton
                    action="Sign up"
                    onClick={() => {
                      navigate("email");
                    }}
                  />
                </Column>
              </Column>
              <Box
                sx={{
                  a: {
                    color: "brand.accent",
                    fontWeight: "medium",
                    ":hover": { color: "#FFF" },
                    ":active": { color: "#FFF" },
                  },
                }}
              >
                <Text fontWeight="medium" color="white">
                  Already have an account?
                </Text>{" "}
                <Link href="/login">Sign in</Link>
              </Box>

              <Terms variant="dark" />
            </Column>
          </Row>
        </Column>

        <Column
          align="center"
          bg="white"
          color="forest.600"
          css={`
            @media (max-height: 700px) {
              display: none;
            }
          `}
          flex={1}
          gap={8}
          maxHeight="360px"
          px={8}
          py={12}
          width="100vw"
        >
          <SectionHeading>Trusted by teams at</SectionHeading>

          <Row align="center" gap={16} height="36px">
            <Icon src="https://cdn.sanity.io/images/pwmfmi47/production/c79ef630644336a446eff2ccdc7afded6cd54c02-240x72.svg" />
            <Icon src="https://cdn.sanity.io/images/pwmfmi47/production/fa94dc0889963d5ee32788ce2b199486d0c5a261-346x72.svg" />
            <Icon src="https://cdn.sanity.io/images/pwmfmi47/production/07791cf17a1edc8a7995ccab5c2501eb24f7eb44-900x531.png" />
            <Icon src="https://cdn.sanity.io/images/pwmfmi47/production/64ac3d884b1f663719ed83a72910b195ad24819e-189x72.svg" />
            <Icon src="https://cdn.sanity.io/images/pwmfmi47/production/f0bb1eeceb7f0085f184ecdb8c610dc58a1b8dd9-336x72.svg" />
            <Icon src="https://cdn.sanity.io/images/pwmfmi47/production/f2b9e1cbc5aa8bd511324a7487f5c0127fbc9d6a-328x72.svg" />
          </Row>
        </Column>
      </AuthLayout>
    </>
  );
};

const Icon = ({ src }) => (
  <Box
    as="img"
    src={src}
    sx={{
      objectFit: "contain",
      height: "100%",
      width: "100%",
      maxWidth: "120px",
      filter: "brightness(0)",
    }}
  />
);
