import { DecisionEngineOutcomeReward } from "@hightouch/lib/customer-data/decision-engine/types";
import { Row, Text } from "@hightouchio/ui";
import { SentimentIcon } from "src/components/sentiment-icon";

export const classifications = {
  best: {
    label: "Best",
    priority: 1,
    type: "positive",
    level: 3,
  },
  veryGood: {
    label: "Very good",
    priority: 0.5,
    type: "positive",
    level: 2,
  },
  good: {
    label: "Good",
    priority: 0.1,
    type: "positive",
    level: 1,
  },
  bad: {
    label: "Bad",
    priority: 0.1,
    type: "negative",
    level: 1,
  },
  veryBad: {
    label: "Very bad",
    priority: 0.5,
    type: "negative",
    level: 2,
  },
  worst: {
    label: "Worst",
    priority: 1,
    type: "negative",
    level: 3,
  },
} as const;

export const OutcomeClassification = ({
  weight,
  showLabel,
}: {
  weight: DecisionEngineOutcomeReward;
  showLabel?: boolean;
}) => {
  const classification = getOutcomeClassificationFromWeight(weight);
  return (
    <Row gap={2} align="center">
      <SentimentIcon variant={weight.type} level={classification.level} />
      {showLabel && <Text fontWeight="medium">{classification.label}</Text>}
    </Row>
  );
};

export const getOutcomeClassificationFromWeight = (
  weight: DecisionEngineOutcomeReward,
) => {
  if (weight.type === "positive") {
    if (weight.priority < 0.5) {
      return classifications.good;
    }
    if (weight.priority < 1) {
      return classifications.veryGood;
    }
    return classifications.best;
  } else {
    if (weight.priority < 0.5) {
      return classifications.bad;
    }
    if (weight.priority < 1) {
      return classifications.veryBad;
    }
    return classifications.worst;
  }
};

export const getOutcomeWeightFromClassificationLabel = (label: string) => {
  return Object.values(classifications).find(
    (classification) => classification.label === label,
  )!;
};
