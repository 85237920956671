import { FC } from "react";

import { Box, IconButton, AddBoxIcon, chakra } from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import Placeholder from "@tiptap/extension-placeholder";
import { EditorContent, EditorEvents, useEditor, Editor } from "@tiptap/react";
import { StarterKit } from "@tiptap/starter-kit";
import { LiquidError } from "liquidjs";
import { debounce } from "lodash";

import { ProfileProps } from "./common";
import { liquidSlackProfile } from "./profiles/liquid-slack";
import { SlackToolbar } from "./toolbar";

export type EditorToolBar = FC<{ editor: Editor; profileProps?: ProfileProps }>;

const CustomEditorContent = chakra(EditorContent);

type Props = {
  isDisabled?: boolean;
  onChange?: (value: string) => void;
  onError?: (error: Error) => void;
  value: string;

  placeholder?: string;

  toolbar?: EditorToolBar;

  /**
   * Ready to use profile built on the editor.
   * A profile is a collection of extensions that has a specific behaviour.
   * @default StarterKit
   */
  profileProps?: ProfileProps;
};

export const RichTextEditor = ({
  onChange,
  onError,
  value,
  profileProps,
  placeholder,
  isDisabled,
}: Props) => {
  const { extensions, serializer } = profileProps
    ? liquidSlackProfile(profileProps)
    : { extensions: [StarterKit], serializer: undefined };

  const captureSerializerError = (error: unknown) => {
    if (error instanceof LiquidError) {
      // Liquid errors are somewhat expected if there while user is typing
      // so so surface them to the upstream component.
      onError?.(error);
    } else {
      // This case is when the serializer hits an unexpected error.
      Sentry.captureException(error);
      onError?.(error as Error);
    }
  };

  const debouncedChangeHandler = debounce(
    ({ editor }: EditorEvents["update"]) => {
      try {
        const serialized = serializer
          ? serializer.serialize(editor.getJSON())
          : editor.getHTML();

        // Run deserialization here to catch things like syntax errors.
        if (serialized) serializer?.deserialize(serialized);

        onChange?.(serialized);
      } catch (e) {
        captureSerializerError(e);
      }
    },
    200,
  );

  const safeDeserialize = () => {
    try {
      return serializer ? serializer.deserialize(value) : value;
    } catch (e) {
      captureSerializerError(e);
      return "";
    }
  };

  const editor = useEditor({
    extensions: [...extensions, Placeholder.configure({ placeholder })],
    content: safeDeserialize(),
    onUpdate: debouncedChangeHandler,
    injectCSS: false,
    editable: !isDisabled,
  });

  const Toolbar = editor && (
    <SlackToolbar
      editor={editor}
      isDisabled={isDisabled}
      additionalComponents={
        false && (
          // TODO add later
          <>
            <Box display="contents" pr={3}>
              <IconButton
                isDisabled={isDisabled || !profileProps?.handler}
                icon={AddBoxIcon}
                aria-label="Add variable"
                size="lg"
                onClick={() => {
                  // TODO
                }}
              />
              Variable
            </Box>
            <Box display="contents" pr={3}>
              <IconButton
                isDisabled={isDisabled || !profileProps?.handler}
                icon={AddBoxIcon}
                aria-label="Add channel"
                size="lg"
                onClick={() => {
                  // TODO
                }}
              />
              Channel
            </Box>
          </>
        )
      }
    />
  );

  if (!editor) return null;

  return (
    <>
      {Toolbar}
      <CustomEditorContent
        editor={editor}
        sx={{
          ".ProseMirror": {
            minHeight: "150px",
            padding: 3,
          },
          ".ProseMirror:focus": {
            outline: "none",
            padding: 3,
          },
          ".ProseMirror-separator": {
            display: "none", // Hide the trailing sperator that automatically gets added to node without trailing text.
          },
          ".ProseMirror p.is-editor-empty:first-of-type::before": {
            content: "attr(data-placeholder)",
            float: "left",
            height: 0,
            pointerEvents: "none",
            opacity: 0.5,
          },

          ".rte-node-view": {
            display: "inline",
          },

          ".rte-slack-bold-class": {
            fontWeight: "bold",
          },

          ".rte-slack-code-class": {
            backgroundColor: "rgba(var(--sk_foreground_min, 29, 28, 29), 0.04)",
            border: "1px solid var(--saf-0)",
            borderRadius: "sm",
            color: "rgba(var(--sk_raspberry_red, 224, 30, 90), 1)",
            padding: "2px 3px 1px",
            "--saf-0": "rgba(var(--sk_foreground_low, 29, 28, 29), 0.13)",
            wordWrap: "break-word",
            fontSize: "12px",
            lineHeight: 1.50001,
            "-webkit-tab-size": "4",
            MozTabSize: "4",
            tabSize: "4",
            whiteSpace: "pre-wrap",
            wordBreak: "normal",
          },

          ".rte-slack-italics-class": {
            fontStyle: "italic",
          },

          ".rte-code-block-class": {
            borderTop:
              "1px solid rgba(var(--sk_foreground_low_solid, 221, 221, 221), 1)",
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
            marginTop: "4px",
            paddingTop: "8px",
            borderBottom:
              "1px solid rgba(var(--sk_foreground_low_solid, 221, 221, 221), 1)",
            borderBottomLeftRadius: "4px",
            borderBottomRightRadius: "4px",
            paddingBottom: "8px",
            wordWrap: "break-word",
            background:
              "rgba(var(--sk_foreground_min_solid, 248, 248, 248), 1)",
            borderLeft:
              "1px solid rgba(var(--sk_foreground_low_solid, 221, 221, 221), 1)",
            borderRight:
              "1px solid rgba(var(--sk_foreground_low_solid, 221, 221, 221), 1)",
            fontVariantLigatures: "none",
            lineHeight: "1.50001",
            marginBottom: "14px",
            paddingLeft: "8px",
            paddingRight: "8px",
            position: "relative",
            "-webkit-tab-size": "4",
            "-moz-tab-size": "4",
            tabSize: "4",
            whiteSpace: "pre-wrap",
            wordBreak: "normal",
          },

          ".rte-slack-strike-class": {
            textDecoration: "line-through",
          },

          ".rte-link-class": {
            color: "rgba(var(--sk_highlight, 18, 100, 163), 1)",
            textDecoration: "none",
          },

          ".rte-list-item-class::marker": {
            display: "inline-block",
            left: "-3px",
            lineHeight: "normal",
            marginLeft: "-24px",
            marginRight: "0",
            position: "relative",
            textAlign: "center",
            whiteSpace: "nowrap",
            width: "24px",
          },
        }}
      />
    </>
  );
};
