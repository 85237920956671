import { Navigate, Route, Routes } from "src/router";
import { PermissionedRoute } from "src/components/router/permissioned-route";

import { CreateSequence } from "./create-sequence";
import { Sequence, SequenceLoader } from "./sequence";
import { Sequences } from "./sequences";
import { SequenceRuns } from "./sequence/runs";
import { SequenceConfiguration } from "./sequence/configuration";
import { SequenceSchedule } from "./sequence/schedule";

export const SequenceRoutes = () => {
  return (
    <Routes>
      <Route index element={<Sequences />} />
      <Route
        path="/new"
        element={
          <PermissionedRoute
            redirect="/sequences"
            permission={{
              v1: { resource: "sync", grant: "create" },
            }}
          >
            <CreateSequence />
          </PermissionedRoute>
        }
      />
      <Route path="/:id/*" element={<SequenceLoader />}>
        <Route element={<Sequence />}>
          <Route
            index
            element={
              <Navigate
                to={{ pathname: "runs", search: location.search }}
                replace
              />
            }
          />
          <Route path="runs" element={<SequenceRuns />} />
          <Route path="configuration" element={<SequenceConfiguration />} />
          <Route path="schedule" element={<SequenceSchedule />} />
        </Route>
      </Route>
    </Routes>
  );
};
