import { NAV_EXPANDED_WIDTH } from "src/components/layout/constants";
import {
  DRAWER_WIDTH,
  NODE_SPACING,
  NODE_WIDTH,
  MINI_NODE_MIN_WIDTH,
  MINI_NODE_MIN_HEIGHT,
} from "src/pages/journeys/constants";

const padding = 48;

/**
 * Get the x, y coordinates to place the node in the center of the graph
 */
export const getCenteredNodePosition = (node: {
  position: { x: number; y: number };
  width?: number | null;
  height?: number | null;
}) => {
  const x = node.position.x + Number(node?.width ?? MINI_NODE_MIN_WIDTH) / 2;
  const y = node.position.y + Number(node?.height ?? MINI_NODE_MIN_HEIGHT) / 2;

  // Account for nav and drawer widths
  let offsetX = x + DRAWER_WIDTH / 2;
  const availableWidth = window.innerWidth - DRAWER_WIDTH - NAV_EXPANDED_WIDTH;

  if (availableWidth > NODE_WIDTH * 2 + NODE_SPACING + padding) {
    offsetX -= NODE_SPACING;
  }

  return { x: offsetX, y };
};
