import { FC } from "react";
import { Node } from "reactflow";

import { Model, Relationship, ObjectQuery } from "src/graphql";

export type { Model as GraphModel, Relationship as GraphRelationship };

export enum EdgeType {
  Custom = "custom",
}

export type NodeData = {
  label?: string;
  isEphemeral?: boolean;
  isSelected?: boolean;
  isSource?: boolean;
  isTarget?: boolean;
  isMissingAsset?: boolean;
  isExpanded?: boolean;
  children?: Node<NodeData>[];
};

export type SchemaObjectType = NonNullable<ObjectQuery["segments_by_pk"]>;

export type SchemaFieldForm = FC<{
  object: SchemaObjectType;
  hasUpdatePermission: boolean;
}>;

export type SchemaField = FC<{
  object?: SchemaObjectType;
  hasUpdatePermission?: boolean;
  columns?: Array<{ value: string; label: string }>;
  isDisabled?: boolean;
  isLoading?: boolean;
}>;
