import { useEffect } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useUser } from "src/contexts/user-context";

export const Pylon = () => {
  const { user, isEmbedded } = useUser();
  const { appPylonDisabled } = useFlags();

  const APP_ID = import.meta.env.VITE_PYLON_APP_ID ?? "";

  useEffect(() => {
    if (!appPylonDisabled && !isEmbedded) {
      // Copied from: https://docs.usepylon.com/chat/setup
      // With a few modifications to make typescript happy
      (function () {
        const e = window;
        if (e.Pylon) return;

        const t = document;
        const n = function (...rest) {
          n.e(...rest);
        } as any;
        n.q = [];
        n.e = function (e) {
          n.q.push(e);
        };
        e.Pylon = n;
        const r = function () {
          const e = t.createElement("script");
          e.setAttribute("type", "text/javascript");
          e.setAttribute("async", "true");
          e.setAttribute("src", `https://widget.usepylon.com/widget/${APP_ID}`);
          const n: any = t.getElementsByTagName("script")[0];
          n.parentNode?.insertBefore(e, n);
        };
        if (t.readyState === "complete") {
          r();
        } else if (e.addEventListener) {
          e.addEventListener("load", r, false);
        }
      })();
    }
  }, [appPylonDisabled, isEmbedded]);

  useEffect(() => {
    if (user && user.pylon_email_hash) {
      window.pylon = {
        chat_settings: {
          app_id: APP_ID,
          name: user.name,
          email: user.email,
          user_id: user.id,
          email_hash: user.pylon_email_hash || "",
        },
      };
    }
  }, [Boolean(user)]);

  return null;
};

export const newPylonMessage = (message: string) => {
  window.Pylon?.("showNewMessage", message, { isHtml: true });
};

const getChatBubbleElement = () =>
  document.querySelector(".PylonChat-bubbleFrameContainer");

const getChatWindowElement = () =>
  document.querySelector(".PylonChat-chatWindowFrameContainer");

const chatElementsExist = () =>
  Boolean(getChatBubbleElement() && getChatWindowElement());

const CHAT_BUBBLE_RIGHT = 10;
const CHAT_WINDOW_RIGHT = 14;
export const updatePylonPosition = (position: number) => {
  // This is the only way to update the position of the Pylon widget
  // https://support.usepylon.com/articles/4033853157-left-align-the-chat-window
  if (window.Pylon && chatElementsExist()) {
    getChatBubbleElement()?.setAttribute(
      "style",
      `right: ${position + CHAT_BUBBLE_RIGHT}px !important`,
    );

    getChatWindowElement()?.setAttribute(
      "style",
      `right: ${position + CHAT_WINDOW_RIGHT}px !important`,
    );
  } else {
    setTimeout(() => updatePylonPosition(position), 500);
  }
};

const setChatVisibility = (visible: boolean) => {
  const display = visible ? "initial" : "none";
  getChatBubbleElement()?.setAttribute(
    "style",
    `display: ${display} !important`,
  );
  getChatWindowElement()?.setAttribute(
    "style",
    `display: ${display} !important`,
  );
};

// Pylon doesn't have a way to completely hide the widget, so instead we set the element to display: none
// In practice, we almost never need to hide it, so even though it's hacky it's not too bad
export const hidePylon = () => {
  if (window.Pylon && chatElementsExist()) {
    setChatVisibility(false);
  } else {
    setTimeout(hidePylon, 500);
  }
};

export const showPylon = () => {
  if (window.Pylon && chatElementsExist()) {
    setChatVisibility(true);
  } else {
    setTimeout(showPylon, 500);
  }
};
