import { FC, useEffect, useState } from "react";

import { useToast, Row, Column, ButtonGroup, Button } from "@hightouchio/ui";
import { isEqual } from "lodash";
import { useOutletContext } from "src/router";

import { ActionBar } from "src/components/action-bar";
import { ScheduleManager } from "src/components/schedule";
import { Schedule, ScheduleType } from "src/components/schedule/types";
import { useUpdateSequenceMutation } from "src/graphql";
import * as analytics from "src/lib/analytics";

import { SequenceContext } from ".";
import { useScheduleState } from "src/components/schedule/schedule-manager";

export const SequenceSchedule: FC = () => {
  const { sequence } = useOutletContext<SequenceContext>();
  const [schedule, setSchedule] = useState<Schedule | null>(
    sequence.schedule ?? { type: ScheduleType.MANUAL },
  );
  const { toast } = useToast();
  const updateMutation = useUpdateSequenceMutation();
  const { validateSchedule } = useScheduleState("sequence");

  const isDirty = !isEqual(
    schedule,
    sequence.schedule ?? { type: ScheduleType.MANUAL },
  );

  const reset = () => {
    setSchedule(sequence.schedule ?? { type: ScheduleType.MANUAL });
  };

  const update = async () => {
    await updateMutation.mutateAsync({
      id: sequence.id,
      object: {
        schedule: schedule?.type === "manual" ? null : schedule,
      },
    });

    analytics.track("Sequence Edited", {
      sync_sequence_id: sequence.id,
    });

    toast({
      id: "update-sequence",
      title: "Sequence schedule updated",
      variant: "success",
    });
  };

  useEffect(() => {
    reset();
  }, [sequence]);

  return (
    <Row width="100%">
      <Column flex={1}>
        <ScheduleManager
          resource="sequence"
          schedule={schedule}
          setSchedule={setSchedule}
        />
        <ActionBar>
          <ButtonGroup>
            <Button
              variant="primary"
              isDisabled={!validateSchedule(schedule) || !isDirty}
              isLoading={updateMutation.isLoading}
              onClick={update}
            >
              Save changes
            </Button>
            <Button isDisabled={!isDirty} onClick={reset}>
              Discard changes
            </Button>
          </ButtonGroup>
        </ActionBar>
      </Column>
    </Row>
  );
};
