import { FC, useEffect, useRef } from "react";

import { DESTINATION_TYPE_TO_CMS_SLUG } from "@hightouch/lib/sanity/constants";
import { Column, Box, Spinner } from "@hightouchio/ui";
import { createInfiniteHitsSessionStorageCache } from "instantsearch.js/es/lib/infiniteHitsCache";
import { times } from "lodash";
import {
  useInfiniteHits,
  UseInfiniteHitsProps,
} from "react-instantsearch-hooks-web";

import { DestinationDefinition } from "src/graphql";
import { Skeleton } from "src/components/loading";

import { DestinationHit } from "./destination-hit";
import { DestinationShadow } from "./destination-shadow";
import { RequestDestination } from "./request-destination";

type Props = {
  category: string;
  destinations: DestinationDefinition[];
  selection: DestinationDefinition | undefined;
  onSelect: (definition: DestinationDefinition, hit: any) => void;
};

const sessionStorageCache = createInfiniteHitsSessionStorageCache();

const transformItems: UseInfiniteHitsProps["transformItems"] = (
  items,
  { results },
) => {
  if (results?.query?.length == 0) {
    return items.filter((item) => item.status !== "shadow");
  } else {
    return items;
  }
};

export const DestinationHits: FC<Readonly<Props>> = ({
  category,
  destinations,
  selection,
  onSelect,
}) => {
  const { hits, results, isLastPage, showMore, sendEvent } = useInfiniteHits({
    transformItems,
    cache: sessionStorageCache,
  });

  const sentinelRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    const pageScrollObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !isLastPage) {
          showMore();
        }
      });
    });

    if (sentinelRef?.current) {
      pageScrollObserver.observe(sentinelRef.current);
    }

    return () => {
      pageScrollObserver.disconnect();
    };
  }, [isLastPage, showMore]);

  if (results?.nbHits === 0) {
    // Algolia doesn't have a specific loading state, so we infer the loading state from the fact that
    // destinations should never be empty if the category is all and there is no search
    if (category === "All" && !results?.query) {
      return (
        <Column gap={3}>
          {times(8).map((i) => (
            <Skeleton key={i} height="32px" />
          ))}
        </Column>
      );
    } else {
      return (
        <>
          <Box
            fontWeight="semibold"
            color="text.placeholder"
            py={3}
            borderBottom="1px"
            borderColor="base.border"
          >
            No results found.
          </Box>
          <RequestDestination category={category} />
        </>
      );
    }
  }
  return (
    <Box>
      <ul
        aria-label="Available destinations"
        style={{ listStyle: "none", padding: 0 }}
      >
        {hits
          ?.filter((hit) => hit.status !== "shadow")
          .map((hit) => {
            const definition = getDefinitionFromHit(destinations, hit);
            return (
              <li key={hit.objectID}>
                <DestinationHit
                  category={category}
                  definition={definition}
                  hit={hit}
                  selected={
                    selection
                      ? DESTINATION_TYPE_TO_CMS_SLUG[selection.type] ===
                          hit.slug || selection.type === hit.slug
                      : false
                  }
                  onSelect={onSelect}
                  onSendEvent={sendEvent}
                />
              </li>
            );
          })}
      </ul>
      <ul
        aria-label="Not yet available destinations"
        style={{ listStyle: "none", padding: 0 }}
      >
        {hits
          ?.filter((hit) => hit.status === "shadow")
          .map((hit) => (
            <li key={hit.objectID}>
              <DestinationShadow
                category={category}
                hit={hit}
                selected={hit.objectID === selection?.type}
                onSendEvent={sendEvent}
              />
            </li>
          ))}
        <li
          ref={sentinelRef}
          aria-hidden={isLastPage ? "true" : "false"}
          aria-label="Loading more results"
          style={{ marginTop: 12, marginLeft: 20 }}
        >
          {!isLastPage && <Spinner />}
        </li>
      </ul>
      {isLastPage && <RequestDestination category={category} />}
    </Box>
  );
};

export const getDefinitionFromHit = (
  definitions: DestinationDefinition[] | undefined,
  hit: any,
) => {
  return definitions?.find(
    (definition) =>
      DESTINATION_TYPE_TO_CMS_SLUG[definition.type] === hit.slug ||
      definition.type === hit.slug,
  );
};
