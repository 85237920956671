import { SchemaField, SchemaFieldForm } from "src/pages/schema/types";
import { useUpdateCatalogMutation } from "src/graphql";
import { Form, useHightouchForm } from "src/components/form";
import { ColumnField } from "./column-field";

export const CatalogPrimaryKeyField: SchemaField = (props) => {
  return (
    <ColumnField
      name="catalog.primary_key"
      label="Primary key"
      tip="Key that is unique and consistent across queries. E.g. “id” or “email”"
      {...props}
    />
  );
};

export const CatalogPrimaryLabelField: SchemaField = (props) => {
  return (
    <ColumnField
      name="catalog.primary_label"
      label="Primary label"
      tip="Used when displaying catalog items e.g. product_name"
      {...props}
    />
  );
};

export const CatalogSecondaryLabelField: SchemaField = (props) => {
  return (
    <ColumnField
      name="catalog.secondary_label"
      label="Secondary label"
      tip="Used when displaying catalog items e.g. product_category"
      {...props}
    />
  );
};

export const CatalogThumbnailField: SchemaField = (props) => {
  return (
    <ColumnField
      isOptional
      name="catalog.thumbnail"
      label="Thumbnail URL"
      tip="Used when displaying catalog items e.g. product_url"
      {...props}
    />
  );
};

export const CatalogForm: SchemaFieldForm = ({
  object,
  hasUpdatePermission,
}) => {
  const { mutateAsync: update } = useUpdateCatalogMutation();
  const form = useHightouchForm({
    submitOnChange: true,
    onSubmit: async ({ catalog }) => {
      await update({
        id: object.id,
        input: {
          primary_key: catalog.primary_key,
          primary_label: catalog.primary_label,
          secondary_label: catalog.secondary_label,
          thumbnail: catalog.thumbnail,
        },
      });
    },
    values: {
      catalog: object.catalog ?? {
        primary_key: "",
        thumbnail: "",
        primary_label: "",
        secondary_label: "",
      },
    },
  });

  return (
    <Form form={form}>
      <CatalogPrimaryKeyField
        object={object}
        isDisabled={!hasUpdatePermission}
        isLoading={form.formState.isSubmitting}
      />
      <CatalogPrimaryLabelField
        object={object}
        isDisabled={!hasUpdatePermission}
        isLoading={form.formState.isSubmitting}
      />
      <CatalogSecondaryLabelField
        object={object}
        isDisabled={!hasUpdatePermission}
        isLoading={form.formState.isSubmitting}
      />
      <CatalogThumbnailField
        object={object}
        isDisabled={!hasUpdatePermission}
        isLoading={form.formState.isSubmitting}
      />
    </Form>
  );
};

export const CatalogFields: SchemaField = (props) => {
  return (
    <>
      <CatalogPrimaryKeyField {...props} />
      <CatalogPrimaryLabelField {...props} />
      <CatalogSecondaryLabelField {...props} />
      <CatalogThumbnailField {...props} />
    </>
  );
};
