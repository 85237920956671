import { Cron } from "croner";
import { ScheduleType } from "src/components/schedule/types";

export const validCronExpression = (exp: string | undefined): boolean => {
  if (!exp) {
    return false;
  }

  try {
    return Boolean(Cron(exp.trim()).nextRun());
  } catch (error) {
    return false;
  }
};

export const getScheduleLabel = (type) => {
  switch (type) {
    case ScheduleType.INTERVAL:
      return "Interval";
    case ScheduleType.CUSTOM:
      return "Custom";
    case ScheduleType.CRON:
      return "Cron";
    case ScheduleType.DBT_CLOUD:
      return "dbt Cloud";
    case ScheduleType.MATCH_BOOSTER:
      return "Match Booster";
    default:
      return "Manual";
  }
};
