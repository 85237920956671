import { FC, ReactNode } from "react";

import { Text, Heading, Row, Column, ButtonGroup } from "@hightouchio/ui";
import { Customer, SplashLogos } from "./logos";
import { Page } from "../layout";

type SplashComponentProps = {
  backgroundGradient?: ReactNode;
  icon: ReactNode;
  eyebrow: string;
  heading: string;
  description: ReactNode;
  actions: ReactNode;
  visual: ReactNode;
  hideLogos?: boolean;
  logoHeader?: string;
  customerLogos?: Customer[];
  infoSection?: ReactNode;
};

export const SplashPage: FC<Readonly<SplashComponentProps>> = (props) => {
  return (
    <Page fullWidth title={props.eyebrow} sx={{ p: 0, position: "relative" }}>
      <Column
        maxWidth="1200px"
        px={{ base: 6, md: 9, lg: 16, xl: 24 }}
        height="100%"
        mt="64px"
        alignSelf="center"
      >
        <SplashComponent {...props} />
      </Column>
    </Page>
  );
};

export const SplashComponent: FC<Readonly<SplashComponentProps>> = ({
  backgroundGradient,
  icon,
  eyebrow,
  heading,
  description,
  actions,
  visual,
  hideLogos,
  logoHeader = "Hightouch is used by",
  customerLogos,
  infoSection,
}) => {
  return (
    <>
      {backgroundGradient}
      <Column align="center" justify="start" flex={1} minWidth={0} minH={0}>
        <Row
          position="relative"
          gap={{ base: 12, lg: 16 }}
          justify="space-between"
          w="100%"
          align="center"
        >
          <Column flex={1} gap={6} maxW="400px">
            <Column gap={2}>
              <Row
                as={Text}
                align="center"
                gap={2}
                fontSize="16px"
                fontWeight="semibold"
                textTransform="uppercase"
              >
                {icon} {eyebrow}
              </Row>
              <Heading size="2xl">{heading}</Heading>
              <Text as="div" color="text.secondary" size="lg">
                {description}
              </Text>
            </Column>
            <ButtonGroup>{actions}</ButtonGroup>
          </Column>
          <Column
            flex={{ base: 0.75, md: 1 }}
            maxW={{ base: "400px", md: "534px" }}
          >
            {visual}
          </Column>
        </Row>
        {infoSection}
        {!hideLogos && (
          <Row position="relative" w="100%" mt={16} mb={24}>
            <Column gap={6} width="100%">
              <Row>
                <Text
                  color="text.placeholder"
                  size="sm"
                  textTransform="uppercase"
                  fontWeight="medium"
                  letterSpacing="wide"
                >
                  {logoHeader}
                </Text>
              </Row>
              <SplashLogos logos={customerLogos} />
            </Column>
          </Row>
        )}
      </Column>
    </>
  );
};
