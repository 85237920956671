import { Alert, Column, Combobox, FormField, TextInput } from "@hightouchio/ui";
import { LinkButton } from "src/router";
import { Controller } from "react-hook-form";
import { useOutletContext } from "src/router";

import { FlowMessageContext } from "..";
import {
  DestinationResourceLink,
  useDestinationResource,
} from "./campaign-link";
import { getChannelDefinition } from "src/pages/decision-engines/definitions";
import { FC } from "react";
import { DecisionEngineChannelConfig } from "@hightouch/lib/customer-data/decision-engine/types";
import { useDecisionEngineDestinationResourcesQuery } from "src/graphql";

export const MessageCampaign: FC<
  Readonly<{
    channel: {
      type: string;
      config: DecisionEngineChannelConfig;
      destination: {
        type: string;
        id: string;
        config: Record<string, unknown>;
        definition: {
          icon: string;
          name: string;
        };
      };
    };
  }>
> = ({ channel }) => {
  const { destination, config } = channel;
  const { flowMessage } = useOutletContext<FlowMessageContext>();

  const { resource, url } = useDestinationResource({
    channel,
    resourceId: flowMessage.config.campaignId ?? flowMessage.config.resourceId,
  });

  const channelDefinition = getChannelDefinition(destination.type);
  const campaignSetup = channelDefinition.campaignSetup(config);
  const resourceName = channelDefinition.getResourceName(config);

  const { data, isLoading } = useDecisionEngineDestinationResourcesQuery(
    {
      destinationId: destination.id,
      type: channelDefinition.getResourceType(channel),
    },
    { select: (data) => data.getDecisionEngineDestinationResources },
  );

  if (campaignSetup === "event-triggered") {
    return (
      <Column gap={4}>
        <Controller
          name="flowMessageConfig.resourceId"
          render={({ field }) => (
            <FormField
              label={`${channel.destination.definition.name} ${resourceName}`}
              description={`The ${resourceName} associated with this message.`}
            >
              <Combobox
                {...field}
                isLoading={isLoading}
                options={data ?? []}
                optionLabel={(option) => option.name ?? option.id}
                optionValue={(option) => option.id}
                placeholder={`Select a ${resourceName}...`}
              />
            </FormField>
          )}
        />
        <Controller
          name="flowMessageConfig.campaignId"
          render={({ field }) => (
            <FormField
              label="Event name"
              description="A unique identifier for the event that will trigger this message."
            >
              <TextInput {...field} placeholder="Identifier..." />
            </FormField>
          )}
        />
      </Column>
    );
  }

  if (campaignSetup === "freeform") {
    return (
      <Controller
        name="config.baseMessageId"
        render={({ field }) => (
          <FormField
            label="Identifier"
            description="A unique identifier you can use to reference this message."
          >
            <TextInput {...field} placeholder="Identifier..." />
          </FormField>
        )}
      />
    );
  }

  return (
    <Column gap={4}>
      <FormField
        label={`${channel.destination.definition.name} ${resourceName}`}
        description={`The ${destination.definition.name} ${resourceName} associated with this message.`}
      >
        <DestinationResourceLink
          channel={channel}
          resourceId={
            flowMessage.config.resourceId ?? flowMessage.config.campaignId
          }
        />
      </FormField>
      {resource && channelDefinition.isInactive(resource) && (
        <Alert
          variant="inline"
          type="warning"
          title="Activate the campaign"
          message="Hightouch has created a campaign that must be manually activated."
          actions={<LinkButton href={url}>Go to campaign</LinkButton>}
        />
      )}
    </Column>
  );
};
