// Filter out emotion warnings
const WARNINGS_TO_IGNORE = [/The pseudo class ":nth-child" is/];

const consoleError = console.error;

export function filterConsoleErrors() {
  console.error = function filterErrors(msg: string, ...args) {
    if (
      WARNINGS_TO_IGNORE.some((messageToFilter) => messageToFilter.test(msg))
    ) {
      return console.debug(msg, ...args);
    }
    consoleError(msg, ...args);
  };
}
