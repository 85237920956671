const FOOTER_HEIGHT = "72px";

const WIZARD_CONTAINER_STYLES = {
  bg: "white",
  borderRadius: "md",
  border: "1px",
  borderColor: "base.border",
  p: 6,
};

export { FOOTER_HEIGHT, WIZARD_CONTAINER_STYLES };
