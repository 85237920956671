// Shared types for sources and event destinations. Separated because fast refresh
// only works when a file only exports components.

export enum Section {
  AuthMethod = "authMethod",
  Tunneling = "tunneling",
  Providers = "providers",
  Form = "form",
  Diffing = "diffing",
  CredentialsForm = "credentials",
}
