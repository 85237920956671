import { CodeBlock } from "@tiptap/extension-code-block";
import { Extension } from "@tiptap/react";

import { SlackBold } from "./slack-bold";
import { SlackCode } from "./slack-code";
import { SlackItalic } from "./slack-italic";
import { SlackLink } from "./slack-link";
import { SlackStrike } from "./slack-strike";

export const SlackMarkdown = Extension.create({
  name: "slackMarkdown",

  addExtensions() {
    return [
      SlackCode,
      SlackBold,
      SlackItalic,
      SlackStrike,
      CodeBlock.configure({
        exitOnArrowDown: true,
        HTMLAttributes: { class: "rte-code-block-class" },
      }),
      SlackLink,
    ];
  },
});
