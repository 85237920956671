import { FC, useState } from "react";

import {
  Button,
  ButtonGroup,
  Column,
  Dialog,
  Radio,
  RadioGroup,
  Spinner,
  Text,
  useToast,
} from "@hightouchio/ui";
import { LinkButton } from "src/router";
import * as Sentry from "@sentry/react";
import { formatDistance, parseISO } from "date-fns";
import { round } from "lodash";

import {
  RowExportFormat,
  RowExportType,
  useRowExportStorageUrlQuery,
  useStartRowExportJobMutation,
} from "src/graphql";
import { usePersistedState } from "src/hooks/use-persisted-state";

const EXPORT_TYPE_LABELS: Record<RowExportType, string> = {
  all: "All rows",
  error: "Rejected rows",
  success: "Successful rows",
};

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  runId: string;
}

function formatBytes(bytes: number): string {
  // == is deliberate here
  if (bytes == 0) return "No rows";
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  // round to 2 decimal places
  return round(bytes / Math.pow(1024, i), 1) + " " + sizes[i];
}

export const RowExportModal: FC<Props> = ({ open, setOpen, runId }) => {
  const [completedRowExportDetails, setCompletedRowExportDetails] = useState<{
    url: string;
    size: number | null;
    format: RowExportFormat;
  } | null>(null);

  const {
    value: rowExportJob,
    set: setRowExportJob,
    clear: resetExportJob,
  } = usePersistedState<{
    jobId?: string;
    format: RowExportFormat;
    startedAt?: string;
  } | null>(`row-export-id-${runId}`, null);

  const [exportPolling, setExportPolling] = useState<boolean>(
    !completedRowExportDetails && !!rowExportJob,
  );

  const [exportType, setExportType] = useState<RowExportType>("all");

  const { toast } = useToast();

  const { mutateAsync: startRowExportJob, isLoading: exportJobStarting } =
    useStartRowExportJobMutation();
  useRowExportStorageUrlQuery(
    { jobId: rowExportJob?.jobId || "" },
    {
      enabled: !!rowExportJob?.jobId && exportPolling,
      refetchInterval: 1000,
      async onSuccess(data) {
        if (
          data.rowExportBackgroundResult.__typename ===
          "FailedRowExportResponse"
        ) {
          toast({
            id: "row-export",
            variant: "error",
            title: "Row export failed",
          });
          setExportPolling(false);
          Sentry.captureException(data.rowExportBackgroundResult.error);
          return;
        } else if (
          data.rowExportBackgroundResult.__typename ===
          "SuccessfulRowExportResponse"
        ) {
          const { url, headUrl } = data.rowExportBackgroundResult;

          // now we try to make an HTTP HEAD request to get the size of the file
          let size: string | null;
          try {
            const result = await fetch(headUrl || url, { method: "HEAD" });
            size = result.headers.get("content-length");
          } catch {
            size = null;
          }
          setCompletedRowExportDetails({
            url,
            size: size ? Number(size) : null,
            format: rowExportJob?.format || "csv",
          });
          setExportPolling(false);
          toast({
            id: "row-export",
            variant: "success",
            title: "Row export complete",
          });
        }
      },
      onError: () => {
        toast({
          id: "row-export",
          variant: "error",
          title: "Row export failed",
          message: "Please try again later.",
        });
        setExportPolling(false);
      },
    },
  );

  /**
   * Starts a row export job in the customer data worker. On receiving a job UUID from the CDW
   * we begin polling for the job status.
   */
  const downloadRows = async (format: "csv" | "json") => {
    try {
      setExportPolling(true);
      setRowExportJob({
        jobId: undefined,
        startedAt: undefined,
        format,
      });
      const data = await startRowExportJob({
        syncRequestId: runId || "",
        format,
        type: exportType,
      });
      setRowExportJob({
        jobId: data.rowExportBackground.jobId,
        startedAt: new Date().toISOString(),
        format,
      });
      toast({
        id: "row-export",
        variant: "info",
        title: "Row export started",
      });
    } catch (e) {
      toast({
        id: "row-export",
        variant: "error",
        title: "Row export failed",
        message: "Please try again later.",
      });
      Sentry.captureException(e);
    }
  };

  return (
    <Dialog
      isOpen={open}
      variant="info"
      title="Export rows"
      onClose={() => setOpen(false)}
      actions={
        <>
          {exportPolling && rowExportJob?.jobId ? (
            <Button
              variant="danger"
              onClick={() => {
                setCompletedRowExportDetails(null);
                resetExportJob();
                setExportPolling(false);
              }}
            >
              Cancel export
            </Button>
          ) : (
            <>
              {completedRowExportDetails ? (
                <ButtonGroup>
                  <LinkButton
                    download={completedRowExportDetails?.url || ""}
                    href={completedRowExportDetails?.url || ""}
                    isDisabled={completedRowExportDetails?.size == 0}
                    variant="primary"
                  >
                    Download {completedRowExportDetails.format.toUpperCase()}{" "}
                    {completedRowExportDetails?.size !== null
                      ? "(" +
                        formatBytes(completedRowExportDetails?.size || 0) +
                        ")"
                      : ""}
                  </LinkButton>
                  <Button
                    variant="warning"
                    onClick={() => {
                      setCompletedRowExportDetails(null);
                      resetExportJob();
                    }}
                  >
                    Clear export
                  </Button>
                </ButtonGroup>
              ) : (
                <ButtonGroup>
                  <Button
                    isLoading={
                      exportJobStarting && rowExportJob?.format === "json"
                    }
                    isDisabled={exportJobStarting}
                    variant="primary"
                    onClick={async () => {
                      downloadRows("json");
                    }}
                  >
                    Export as JSON
                  </Button>
                  <Button
                    isLoading={
                      exportJobStarting && rowExportJob?.format === "csv"
                    }
                    isDisabled={exportJobStarting}
                    variant="primary"
                    onClick={() => {
                      downloadRows("csv");
                    }}
                  >
                    Export as CSV
                  </Button>
                </ButtonGroup>
              )}
            </>
          )}
        </>
      }
    >
      <Column gap={8}>
        {exportPolling && rowExportJob?.jobId ? (
          <>
            <Spinner size="md" mx="auto" />

            <Text>
              {rowExportJob.format.toUpperCase()} export started{" "}
              {rowExportJob?.startedAt &&
                formatDistance(parseISO(rowExportJob.startedAt), new Date(), {
                  addSuffix: true,
                })}
              . You can return to this dialog later to download your export.
            </Text>
          </>
        ) : (
          <>
            <Text>
              Download all of your synced rows for custom analysis. This can
              take several minutes.
            </Text>

            {!completedRowExportDetails && (
              <RadioGroup
                isDisabled={exportJobStarting}
                value={exportType}
                onChange={(v) => setExportType(v as RowExportType)}
              >
                {Object.values(RowExportType).map((type) => (
                  <Radio
                    label={EXPORT_TYPE_LABELS[type]}
                    key={type}
                    value={type}
                  />
                ))}
              </RadioGroup>
            )}
          </>
        )}
      </Column>
    </Dialog>
  );
};
