import * as Yup from "yup";
import { ColumnOrConstant, Field, Input, Section } from "@hightouch/formkit";

export const FieldMappingSection = Section({
  children: [
    Field({
      heading: "Event Name",
      subheading: "The value or field to use as the event name.",
      component: ColumnOrConstant("eventName", {
        default: "",
        validation: Yup.lazy((val) =>
          typeof val !== "string"
            ? Yup.object()
                .shape({ from: Yup.mixed().nullable().required() })
                .required()
                .default(null)
                .nullable()
            : Yup.string(),
        ),
      }),
      optional: true,
    }),

    Field({
      heading: "User ID",
      subheading: "The field to use as the user ID.",
      component: Input("userId", {
        validation: Yup.string(),
        placeholder: "userId",
      }),
      optional: true,
    }),

    Field({
      heading: "Anonymous ID",
      subheading: "The field to use as the anonymous ID.",
      component: Input("anonymousId", {
        validation: Yup.string(),
        placeholder: "anonymousId",
      }),
      optional: true,
    }),

    Field({
      heading: "Message ID",
      subheading: "The field to use as the message ID.",
      component: Input("messageId", {
        validation: Yup.string(),
        placeholder: "messageId",
      }),
      optional: true,
    }),

    Field({
      heading: "Timestamp",
      subheading: "The field to use as the timestamp.",
      component: Input("timestamp", {
        validation: Yup.string(),
        placeholder: "timestamp",
      }),
      optional: true,
    }),
  ],
});
