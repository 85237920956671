export interface NumberRangeValue {
  min: number;
  max: number;
}

export function isNumberRangeType(range: unknown): range is NumberRangeValue {
  return (
    typeof range === "object" &&
    range != null &&
    (range as NumberRangeValue).min != null &&
    (range as NumberRangeValue).max != null
  );
}
