export const Icon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20px"
    height="20px"
    viewBox="0 0 24 24"
    fill={color}
  >
    <path
      fillRule="evenodd"
      d="M2 4.17A2.17 2.17 0 0 1 4.17 2h15.66A2.17 2.17 0 0 1 22 4.17v15.66A2.17 2.17 0 0 1 19.83 22H4.17A2.17 2.17 0 0 1 2 19.83V4.17ZM4.17 3h15.66c.646 0 1.17.524 1.17 1.17v1.76l-4.734 4.667a1.534 1.534 0 0 1-1.077.442H10.21c-.673 0-1.317.267-1.793.742L3 17.199V4.17C3 3.524 3.524 3 4.17 3ZM3 18.613v1.217c0 .646.524 1.17 1.17 1.17h2.651L3.72 17.894l-.719.72Zm1.427-1.426L8.235 21h2.912l-5.263-5.27-1.457 1.457ZM15.467 21H12.56l-5.97-5.977 1.457-1.457L15.467 21Zm-6.713-8.14L16.88 21h2.918l-8.95-8.961h-.637c-.407 0-.798.161-1.085.449l-.372.371ZM21 19.83c0 .27-.09.517-.244.715l-8.495-8.506h2.912L21 17.874v1.956Zm-4.68-8.058L21 16.458v-2.915l-3.124-3.13-.908.896a2.51 2.51 0 0 1-.648.463ZM21 7.334v4.794l-2.412-2.416L21 7.334Z"
      clipRule="evenodd"
    />
  </svg>
);
