import { keyframes } from "@emotion/react";
import { Box, BoxProps, Row } from "@hightouchio/ui";

const duration = 1.5;
const size = 8;

const dot = keyframes`
  0% {
    opacity: 0.2;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
`;

const sharedStyles: BoxProps = {
  height: `${size}px`,
  width: `${size}px`,
  borderRadius: "lg",
  bg: "primary.base",
  animation: `${dot} ${duration}s ease-in infinite`,
};

export const LoadingDots = () => {
  return (
    <Row gap={1}>
      <Box {...sharedStyles} />
      <Box
        {...sharedStyles}
        sx={{
          animationDelay: `${duration / 4}s`,
        }}
      />
      <Box
        {...sharedStyles}
        sx={{
          animationDelay: `${duration / 2}s`,
        }}
      />
    </Row>
  );
};
