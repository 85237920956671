import { Box, Column, Row, Text } from "@hightouchio/ui";
import { isSameDay, addHours, isAfter, format } from "date-fns";
import { last } from "lodash";
import { FC, useMemo } from "react";
import {
  TooltipProps,
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  Bar,
  Cell,
  Label,
} from "recharts";
import {
  ChartColors,
  ChartGranularity,
  ChartGranularityMetadata,
  padTimeseriesDataWithBuckets,
  quantityTickFormatter,
} from "src/components/charts/chart-utils";
import { useChartsContext } from "src/hooks/use-charts-context";

export function EventMetricChart<DataType extends string>({
  data,
  colors,
  tooltip,
  granularity,
  padWith,
  startTime,
  emptyMessage,
}: {
  data?: {
    timestamp: Date;
    data: Record<DataType, number>;
  }[];
  padWith: Record<DataType, number>;
  startTime: Date;
  colors: {
    [k in DataType]: (typeof ChartColors)[keyof typeof ChartColors];
  };
  tooltip: FC<TooltipProps<number, string>>;
  granularity: ChartGranularity;
  emptyMessage: string;
}) {
  const { handleMouseMove, handleMouseLeave, activeIndex } = useChartsContext();

  const hasData = !!(data && data.length);

  const paddedData = padTimeseriesDataWithBuckets(
    data || [],
    padWith,
    granularity,
    startTime,
  );
  const lastPoint = last(paddedData) || { timestamp: startTime, data: padWith };
  const metrics = lastPoint ? (Object.keys(lastPoint.data) as DataType[]) : [];
  const granularityMetadata = ChartGranularityMetadata[granularity];

  const metricsBars = useMemo(
    () =>
      metrics.map((metric, metricIndex) => (
        <Bar
          dataKey={`data.${metric}`}
          stackId="stack"
          key={`bar-${metricIndex}`}
        >
          {paddedData.map((_, pointIndex) => (
            <Cell
              key={`cell-${pointIndex}`}
              fill={
                activeIndex === -1 || activeIndex === pointIndex
                  ? colors[metric].hover
                  : colors[metric].base
              }
            />
          ))}
        </Bar>
      )),
    [metrics, paddedData],
  );

  return (
    <Column height="100%">
      <Box position="relative" height="100%">
        <Box position="absolute" left={0} right={0} top={0} bottom={0}>
          <ResponsiveContainer minWidth="100%">
            <BarChart
              syncId="eventMetrics"
              data={paddedData}
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
              margin={{ top: 20, right: 0, bottom: 20, left: 0 }}
            >
              <CartesianGrid vertical={false} />
              <Label value="beans" position="center" />
              <XAxis
                dataKey="timestamp"
                interval={granularityMetadata.tickInterval}
                tickLine={false}
                tickFormatter={(t) => timeTickFormatter(t, granularity)}
                height={50}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                allowDecimals={false}
                domain={hasData ? undefined : [0, 40000]}
                tickFormatter={(t) => quantityTickFormatter(t)}
              />
              {hasData && (
                <Tooltip
                  content={tooltip}
                  wrapperStyle={{ outline: "none" }}
                  cursor={false}
                />
              )}
              {hasData && (
                <ReferenceLine
                  x={paddedData[activeIndex]?.timestamp.valueOf()}
                  strokeWidth="4%"
                  stroke="rgba(105, 119, 134, 0.3)"
                  style={{ cursor: "pointer" }}
                />
              )}
              {metricsBars}
            </BarChart>
          </ResponsiveContainer>
        </Box>
        {(!data || !data.length) && (
          <Box
            position="absolute"
            left="53%"
            top="40%"
            backgroundColor="white"
            textAlign="center"
            p={2}
            transform="translate(-50%, -50%)"
          >
            <Text color="text.secondary" fontWeight="medium">
              {emptyMessage}
            </Text>
          </Box>
        )}
      </Box>
    </Column>
  );
}

export const MetricTooltip = <Metric extends string>(
  metricDisplay: {
    [k in Metric]: {
      descriptor: { singular: string; plural: string };
      color: string;
    };
  },
  granularity: ChartGranularity,
) => {
  const EventsReceivedTooltip: FC<TooltipProps<number, string>> = ({
    payload,
  }) => {
    const point = payload?.[0]?.payload as {
      timestamp: Date;
      data: { [k in Metric]: number };
    };
    if (!point) return null;
    const startOfBucket = point.timestamp;
    const endOfBucket = addHours(
      point.timestamp,
      ChartGranularityMetadata[granularity].binHours,
    );

    return (
      <Column
        bg="gray.900"
        padding={2}
        borderRadius="md"
        gap={2}
        color="white"
        fontWeight="medium"
        userSelect="none"
      >
        <Text color="text.placeholder">
          {isAfter(endOfBucket, new Date())
            ? `Since ${format(point.timestamp, "E LLL do h:mmaaa")}`
            : `${format(point.timestamp, "E LLL do h:mmaaa")} - ${format(
                endOfBucket,
                isSameDay(startOfBucket, endOfBucket)
                  ? "h:mmaaa"
                  : "E LLL do h:mmaaa",
              )}`}
        </Text>
        {(Object.keys(metricDisplay) as Metric[]).map((metric) => {
          if (point.data[metric] === undefined) return null;
          return (
            <Row gap={1} key={metric}>
              <Box
                borderRadius="full"
                h={4}
                w={2}
                backgroundColor={metricDisplay[metric].color}
              />
              {point.data[metric].toLocaleString()}{" "}
              {point.data[metric] === 1
                ? metricDisplay[metric].descriptor.singular
                : metricDisplay[metric].descriptor.plural}
            </Row>
          );
        })}
      </Column>
    );
  };
  return EventsReceivedTooltip;
};

function timeTickFormatter(
  tick: string | number,
  granularity: ChartGranularity,
): string {
  try {
    const parsed = new Date(tick);

    switch (granularity) {
      case ChartGranularity.OneDay:
        return format(parsed, "h:mmaaa");
      case ChartGranularity.ThreeDays:
      case ChartGranularity.SevenDays:
        return format(
          addHours(parsed, ChartGranularityMetadata[granularity].binHours),
          "EEE-dd",
        );
    }
  } catch {
    return tick.toString();
  }
}
