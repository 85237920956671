const prefix = (key: string) => `hightouch-${key}`;

export const clear = () => {
  try {
    // Entry point for local storage
    // eslint-disable-next-line no-restricted-globals
    localStorage.clear();
  } catch (err) {
    console.warn("Failed to clear local storage", err);
  }
};

export const save = (key: string, value: any) => {
  try {
    // Entry point for local storage
    // eslint-disable-next-line no-restricted-globals
    localStorage.setItem(prefix(key), JSON.stringify(value));
  } catch (err) {
    console.warn("Failed to save value to `%s` in local storage", key, err);
  }
};

export const remove = (key: string) => {
  try {
    // Entry point for local storage
    // eslint-disable-next-line no-restricted-globals
    localStorage.removeItem(prefix(key));
  } catch (err) {
    console.warn("Failed to remove `%s` from local storage", key, err);
  }
};

export const load = (key: string) => {
  try {
    // Entry point for local storage
    // eslint-disable-next-line no-restricted-globals
    const value = localStorage.getItem(prefix(key));

    if (value) {
      return JSON.parse(value);
    }
  } catch (err) {
    return null;
  }

  return null;
};
