import { FC, useState, useMemo, useEffect } from "react";

import {
  EditableDescription,
  Pill,
  SectionHeading,
  useToast,
  Select,
  Column,
  Row,
  SearchInput,
  Text,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";

import { useDraft } from "src/contexts/draft-context";
import {
  type ColumnFragment,
  type ModelColumnsOrderBy,
  type SourceColumnDescription,
  useUpdateModelColumnDescriptionMutation,
  useUpdateModelColumnMutation,
} from "src/graphql";
import { ColumnType } from "src/types/visual";
import {
  Pagination,
  Table,
  useTableConfig,
  truncateData,
  getLodashOrderBy,
} from "src/ui/table";
import { orderBy as lodashOrderBy } from "lodash";
import { useBigintSupport } from "src/components/audiences/utils";
import { getColumnDescription } from "src/utils/models";

type Props = {
  columns: ColumnFragment[];
  loading: boolean;
  modelId: string | undefined;
  isDraft: boolean;
  sourceType: string;
  dbtColumns?: { name: string; description?: string }[];
  sourceColumnDescriptions?: SourceColumnDescription[] | null;
};

const getTypeOptions = (
  warehouseType,
  opts: {
    allowBigints: boolean;
  },
) => {
  const typeOptions = [
    {
      value: ColumnType.Boolean,
      label: "Boolean",
    },
    {
      value: ColumnType.Number,
      label: "Number",
    },
    {
      value: ColumnType.BigInt,
      label: "Big Integer",
    },
    {
      value: ColumnType.String,
      label: "String",
    },
    {
      value: ColumnType.Timestamp,
      label: "Timestamp",
    },
    {
      value: ColumnType.Date,
      label: "Date",
    },
    {
      value: ColumnType.Json,
      label: "Object / Array",
    },
  ];
  return typeOptions
    .filter((option) => {
      if (option.value === ColumnType.BigInt) {
        return opts.allowBigints;
      }
      return true;
    })
    .map((option) => {
      if (option.value === warehouseType) {
        return {
          ...option,
          label: `${option.label} (Default)`,
        };
      }
      return option;
    });
};

export const ColumnSettings: FC<Readonly<Props>> = ({
  columns,
  loading,
  modelId,
  isDraft,
  dbtColumns,
  sourceColumnDescriptions,
  sourceType,
}) => {
  const { allowBigints } = useBigintSupport(sourceType);

  const [search, setSearch] = useState("");
  const { updateResourceOrDraft, draft } = useDraft();
  const { toast } = useToast();

  const updateModelColumnDescriptionMutation =
    useUpdateModelColumnDescriptionMutation();

  const filteredColumns = columns.filter(({ name }) =>
    name.toLowerCase().includes(search.toLowerCase()),
  );

  const {
    limit,
    offset,
    page,
    onSort,
    orderBy,
    setPage,
    sortKey,
    sortDirection,
  } = useTableConfig<ModelColumnsOrderBy>({
    defaultSortKey: "name",
    defaultSortDirection: "asc",
    limit: 10,
    sortOptions: ["name", "type"],
  });

  const { lodashSortKey, lodashSortDirection } = getLodashOrderBy(
    sortKey,
    sortDirection,
  );

  const sortedFilteredColumns = lodashOrderBy(
    filteredColumns,
    lodashSortKey,
    lodashSortDirection,
  );

  const updateColumnMutation = useUpdateModelColumnMutation({
    onSuccess: () => {
      // Prevents invalidation of cache
    },
  });

  return (
    <Column gap={4} pb={20}>
      <Row alignItems="center" justifyContent="space-between">
        <Row alignItems="center">
          <SectionHeading mr={2}>Columns</SectionHeading>
          <Pill>{columns?.length}</Pill>
        </Row>
        <SearchInput
          placeholder="Search columns..."
          value={search}
          onChange={(evt) => setSearch(evt.target.value)}
        />
      </Row>
      <Table
        primaryKey="name"
        columns={[
          {
            name: "Name",
            max: "1fr",
            sortDirection: orderBy?.name,
            onClick: () => onSort("name"),
            cell: ({ name }) => (
              <Text isTruncated fontWeight="medium">
                {name}
              </Text>
            ),
          },
          {
            name: "Type",
            sortDirection: orderBy?.type,
            onClick: () => onSort("type"),
            max: "250px",
            cell: ({ name, type, custom_type }) => {
              // For rETL models, we treat both of these types as if they were
              // regular JSON types. These types have special meaning in
              // customer studio schema models, but for rETL they are treated
              // effectively the same as JSON, so don't make a distinction in
              // the UI.
              const detectedType = [
                ColumnType.JsonArrayNumbers,
                ColumnType.JsonArrayStrings,
              ].includes(type as ColumnType)
                ? ColumnType.Json
                : type;
              const [value, setValue] = useState(custom_type || detectedType);

              // This useEffect is needed when the type or custom_type changes, for example
              // when the user toggles between draft and production, or if the columns load
              // after the draft loads
              useEffect(() => {
                setValue(custom_type || detectedType);
              }, [custom_type, detectedType]);

              return (
                <Select
                  width="3xs"
                  options={getTypeOptions(detectedType, { allowBigints })}
                  value={value}
                  isLoading={updateColumnMutation.isLoading}
                  onChange={async (option) => {
                    if (!modelId || !option) {
                      return;
                    }
                    const oldValue = value;
                    setValue(option);

                    const column = columns.find(
                      (column) => column.name === name,
                    );
                    const newColumn = {
                      ...column,
                      custom_type: option,
                    };
                    const newColumns = columns.map((column) => {
                      if (column.name === name) {
                        return newColumn;
                      }
                      return column;
                    });

                    try {
                      if (updateResourceOrDraft) {
                        await updateResourceOrDraft(
                          {
                            _set: draft?.new_resource?._set || {},
                            modelColumns: newColumns,
                          },
                          () => {
                            toast({
                              id: "update-column",
                              title: "Column type updated",
                              variant: "success",
                            });
                          },
                          () =>
                            updateColumnMutation.mutateAsync({
                              id: modelId,
                              name,
                              input: { custom_type: option },
                            }),
                          isDraft,
                        );
                      }
                    } catch (err) {
                      Sentry.captureException(err);
                      toast({
                        id: "update-column",
                        title: "Column type failed to update",
                        variant: "error",
                      });
                      setValue(oldValue);
                    }
                  }}
                />
              );
            },
          },
          {
            name: "Description",
            max: "450px",
            cell: ({ name, description }) => {
              const lowerName = name.toLowerCase();

              const dbtColumnDescription = useMemo(
                () =>
                  dbtColumns?.find(
                    (column) => column.name.toLowerCase() === lowerName,
                  )?.description,
                [dbtColumns],
              );

              const sourceColumnDescription = useMemo(
                () =>
                  sourceColumnDescriptions?.find(
                    ({ name: colName }) => colName === lowerName,
                  )?.description,
                [sourceColumnDescriptions],
              );

              const colDescription =
                getColumnDescription(
                  description,
                  dbtColumnDescription,
                  sourceColumnDescription,
                ) || "";

              return (
                <Row marginY={4}>
                  <EditableDescription
                    value={colDescription}
                    width="md"
                    onChange={async (option) => {
                      if (modelId) {
                        try {
                          await updateModelColumnDescriptionMutation.mutateAsync(
                            {
                              model_id: modelId,
                              name,
                              description: option,
                            },
                          );
                          toast({
                            id: "update-column-desc",
                            title: "Column description updated",
                            variant: "success",
                          });
                        } catch (err) {
                          Sentry.captureException(err);
                          toast({
                            id: "update-column-desc",
                            title: "Column description failed to update",
                            variant: "error",
                          });
                        }
                      }
                    }}
                  />
                </Row>
              );
            },
          },
        ]}
        data={truncateData(sortedFilteredColumns, limit, offset)}
        disabled={({ disable }) => disable}
        loading={loading}
        rowHeight="auto"
      />
      <Row justifyContent="flex-end">
        <Pagination
          count={sortedFilteredColumns.length}
          label="columns"
          page={page}
          rowsPerPage={limit}
          setPage={setPage}
        />
      </Row>
    </Column>
  );
};
