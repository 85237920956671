import { useMemo } from "react";

import { FolderViewType } from "src/components/folders/types";
import { useFolders } from "src/components/folders/use-folders";
import { FolderType } from "src/graphql";

export const useFolder = ({
  folderId,
  folderType,
  viewType,
}: {
  folderId: string | null;
  folderType: FolderType;
  viewType: FolderViewType;
}) => {
  const {
    state: { flattenedFolders },
  } = useFolders({ folderType, viewType });

  const folder = useMemo(() => {
    return flattenedFolders?.find((folder) => folder.id === folderId);
  }, [flattenedFolders, folderId]);

  return folder;
};
