import { FC, useState } from "react";

import {
  Box,
  ButtonGroup,
  CloseIcon,
  Column,
  ConfirmationDialog,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  IconButton,
  Paragraph,
  Row,
  Text,
} from "@hightouchio/ui";
import { LinkButton } from "src/router";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useFormContext } from "react-hook-form";
import { getIncomers } from "reactflow";

import { QueryBuilder } from "src/components/explore";
import { isAndOrCondition } from "src/components/explore/utils/type-guards";
import { ErrorMessage } from "src/components/explore/visual/error-message";
import {
  DiscardButton,
  Form,
  SaveButton,
  useHightouchForm,
} from "src/components/form";
import {
  PermissionedButton,
  PermissionedEditableHeading,
  PermissionedEditableText,
} from "src/components/permission";
import { useFormErrorContext } from "src/contexts/form-error-context";
import {
  JOURNEY_DELETE_PERMISSION,
  JOURNEY_UPDATE_PERMISSION,
} from "src/pages/journeys/constants";
import { useGraphContext } from "src/pages/journeys/graph";
import { JourneyGraph, NodeDetailFormProps } from "src/pages/journeys/types";
import { SegmentBranchConfig } from "src/types/journeys";
import { ConditionType } from "src/types/visual";

import { Navigate, useNavigate } from "src/router";
import { SegmentBranchFormSchema } from "./validation-schemas";

export const SegmentForm: FC<NodeDetailFormProps<SegmentBranchConfig>> = ({
  id, // id techincally lives in data too, redundant to have both :/
  data,
  onClose,
}) => {
  const navigate = useNavigate();

  const { parentModel, isEditMode, onRemoveNode, onUpdateNode } =
    useGraphContext();
  const { hasValidationErrors } = useFormErrorContext();
  const parentForm = useFormContext<JourneyGraph>();

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - Circular reference problem with Condition types
  const nodes = parentForm.watch("nodes");
  const currentNode = nodes.find((node) => node.id === id);
  const edges = parentForm.watch("edges");

  const incomingNodes = currentNode
    ? getIncomers(currentNode, nodes, edges)
    : [];
  const priorityListId = incomingNodes[0]?.data.id;

  const goBackToPriorityList = () => {
    if (priorityListId) {
      navigate(`../${priorityListId}`);
    } else {
      onClose();
    }
  };

  const form = useHightouchForm({
    onSubmit: (segmentNodeData) => {
      // TODO(samuel): one day transition query builder to yup validation or external validation.
      // This could be bug prone, since the query builder uses a different validation
      // approach than the yup resolvers.
      if (hasValidationErrors()) {
        throw new Error("Form has validation errors");
      }

      onUpdateNode(id, segmentNodeData);
      goBackToPriorityList();

      return Promise.resolve();
    },
    success: "Tile was saved",
    onError: () => {
      // Defined `onError` to ignore sentry tracking in `useHightouchForm`
    },
    values: data,
    resolver: yupResolver(SegmentBranchFormSchema),
  });

  if (data.config.segment_is_catch_all) {
    return <Navigate to={`../${priorityListId}`} />;
  }

  return (
    <Form form={form}>
      <DrawerHeader>
        <Row align="center" justify="space-between" flex={1} minWidth={0}>
          <Controller
            name="name"
            control={form.control}
            render={({ field, fieldState }) => (
              <Column>
                <PermissionedEditableHeading
                  isDisabled={!isEditMode}
                  permission={JOURNEY_UPDATE_PERMISSION}
                  value={field.value}
                  onChange={field.onChange}
                />
                {fieldState.error?.message && (
                  <ErrorMessage fontWeight="normal">
                    {fieldState.error.message}
                  </ErrorMessage>
                )}
              </Column>
            )}
          />
          <IconButton
            aria-label="Close drawer."
            icon={CloseIcon}
            onClick={onClose}
          />
        </Row>
      </DrawerHeader>

      <DrawerBody>
        <Column minHeight={0} flex={1} gap={6} pb={4}>
          <Column>
            <Row align="center" gap={2}>
              <Box
                as={LinkButton}
                href={`../${priorityListId}`}
                px={2}
                mx={-2}
                fontWeight="normal"
                variant="tertiary"
              >
                <Text color="text.secondary">Segment</Text>
              </Box>
              <Text color="text.secondary" size="lg">
                /
              </Text>
              <Text color="text.secondary" fontWeight="medium">
                {form.watch("name")}
              </Text>
            </Row>

            <Controller
              name="name"
              control={form.control}
              render={({ field, fieldState }) => (
                <Column mt={4}>
                  <PermissionedEditableText
                    isDisabled={!isEditMode}
                    fontWeight="semibold"
                    permission={JOURNEY_UPDATE_PERMISSION}
                    value={field.value}
                    onChange={field.onChange}
                  />
                  {fieldState.error?.message && (
                    <ErrorMessage fontWeight="normal">
                      {fieldState.error.message}
                    </ErrorMessage>
                  )}
                </Column>
              )}
            />
            <Paragraph>
              Filter down customers using anything in your underlying data
            </Paragraph>
          </Column>

          <Controller
            control={form.control}
            name="config.segment_conditions"
            render={({ field }) => (
              <QueryBuilder
                filter={
                  // TODO(samuel): Query builder types will one day be updated to AndOrCondition<RootCondition>, but today is not that day.
                  // These will _always_ be a top level AND or OR condition
                  isAndOrCondition(field.value)
                    ? field.value
                    : { type: ConditionType.And, conditions: [] }
                }
                parent={parentModel}
                setConditions={field.onChange}
              />
            )}
          />
        </Column>
      </DrawerBody>

      {isEditMode && (
        <DrawerFooter>
          <ButtonGroup>
            <SaveButton
              permission={JOURNEY_UPDATE_PERMISSION}
              onClick={() => hasValidationErrors()}
            >
              Update segment
            </SaveButton>
            <DiscardButton />
          </ButtonGroup>

          <PermissionedButton
            permission={JOURNEY_DELETE_PERMISSION}
            size="lg"
            variant="warning"
            onClick={() => setShowConfirmationModal(true)}
          >
            Delete segment
          </PermissionedButton>
        </DrawerFooter>
      )}

      <ConfirmationDialog
        isOpen={showConfirmationModal}
        confirmButtonText="Delete"
        title={`Delete segment '${form.watch("name")}'?`}
        variant="danger"
        onClose={() => setShowConfirmationModal(false)}
        onConfirm={() => onRemoveNode(id)}
      >
        <Paragraph>Are you sure you want to delete this segment?</Paragraph>
      </ConfirmationDialog>
    </Form>
  );
};
