import { FC, Suspense, useEffect } from "react";

import {
  Column,
  Heading,
  Row,
  StarBadgeIcon,
  Tooltip,
  Spinner,
  PlusIcon,
} from "@hightouchio/ui";
import { LinkButton } from "src/router";
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "src/router";

import { useUserGroupsQuery } from "src/graphql";

import { OutletContext as OrganizationOutletContext } from "..";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { useOrganizationPermissions } from "src/components/permission/use-organization-permissions";

export const Roles: FC = () => {
  const { roleId } = useParams();
  const location = useLocation();
  const context = useOutletContext<OrganizationOutletContext>();
  const navigate = useNavigate();

  const { isOrganizationAdmin } = useOrganizationPermissions();

  const { data: userGroups } = useUserGroupsQuery(
    {
      organizationId: context.organization.id,
    },
    {
      select: (data) => data.user_groups,
      suspense: true,
    },
  );

  useEffect(() => {
    if (!roleId && !location.pathname.endsWith("/new")) {
      const defaultRole = userGroups?.[0];
      if (defaultRole) {
        navigate(`${defaultRole.id}/workspaces`);
      }
    }
  }, [roleId, location.pathname, userGroups]);

  return (
    <Row width="100%" flex={1} minH={0} m={-6}>
      <Column
        maxW="300px"
        borderRight="1px"
        borderColor="base.border"
        pt={8}
        pb={12}
        px={6}
        h="100%"
        flex={1}
        align="flex-start"
        gap={4}
      >
        <Heading>Groups</Heading>
        <Column overflow="auto" w="100%">
          {userGroups?.map((userGroup) => {
            const { id, name, is_organization_admin } = userGroup;
            const isSelected = roleId === String(id);
            return (
              <Row
                px={3}
                py={2}
                bg={isSelected ? "primary.background" : "transparent"}
                key={id}
                onClick={() => {
                  navigate(`${id}/workspaces`);
                }}
                gap={2}
                align="center"
                border="1px"
                borderColor={isSelected ? "primary.border" : "transparent"}
                borderRadius="md"
                cursor="pointer"
                justify="space-between"
                _hover={isSelected ? undefined : { bg: "gray.100" }}
              >
                <TextWithTooltip fontWeight="medium">{name}</TextWithTooltip>
                {is_organization_admin && (
                  <Tooltip message="Organization admin">
                    <StarBadgeIcon />
                  </Tooltip>
                )}
              </Row>
            );
          })}
        </Column>
        <LinkButton
          isDisabled={!isOrganizationAdmin}
          href="new"
          icon={PlusIcon}
        >
          Add group
        </LinkButton>
      </Column>

      <Column flex={1} minH={0} mr={-12}>
        <Suspense fallback={<Spinner size="lg" m="auto" />}>
          <Outlet context={context} />
        </Suspense>
      </Column>
    </Row>
  );
};
