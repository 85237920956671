import { FC, useMemo } from "react";

import { Select, ImageSelectOptionAccessory } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";

import { HightouchRegions, regionFlags } from "src/utils/regions";

type Props = {
  value: string;
  onChange: (value: string | undefined) => void;
  isDisabled?: boolean;
};

export const SelectRegion: FC<Readonly<Props>> = ({
  value,
  onChange,
  isDisabled,
}) => {
  const { multiRegionBetaRegions } = useFlags();
  const options = useMemo(() => {
    return (
      Object.entries(HightouchRegions)
        // Filter out beta regions if the user is not flagged to see them
        .filter(
          ([_, config]) =>
            !config.beta || (config.beta && multiRegionBetaRegions),
        )
        .map(([id, config]) => {
          return {
            value: id,
            label: config.friendlyName,
            flag: regionFlags[config.flag],
          };
        })
    );
  }, [multiRegionBetaRegions]);

  return (
    <Select
      isDisabled={isDisabled}
      optionAccessory={optionAccessory}
      options={options}
      placeholder="Select a region"
      value={value}
      width="100%"
      onChange={onChange}
    />
  );
};

const optionAccessory = (option): ImageSelectOptionAccessory => ({
  type: "image",
  url: option.flag,
});
