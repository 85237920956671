import {
  Box,
  Button,
  ButtonGroup,
  Column,
  DrawerBody,
  Row,
  SectionHeading,
  Tag,
  Text,
  useToast,
} from "@hightouchio/ui";
import { capitalize } from "lodash";
import { Fragment, useEffect, useState } from "react";
import { useOutletContext } from "src/router";
import { Card } from "src/components/card";
import { LoadingCircles } from "src/components/loading";
import {
  useAnalyzeDecisionEngineTagsForMessageQuery,
  useRunSqlResultQuery,
  useUpdateDecisionEngineMessageMutation,
} from "src/graphql";
import { cdn } from "src/utils/sanity";
import { OutletContext } from "src/pages/decision-engines/index";
import { FlowMessageContext } from ".";
import { parseTagsFromSource } from "src/pages/decision-engines/admin";
const hashCode = (str: string): number => {
  return str
    .split("")
    .reduce(
      (prevHash, currVal) => (prevHash << 5) - prevHash + currVal.charCodeAt(0),
      0,
    );
};

type MessageCortexContext = OutletContext & FlowMessageContext;

export const MessageCortex = () => {
  const { engine, flowMessage } = useOutletContext<MessageCortexContext>();
  const { toast } = useToast();
  const [shouldPoll, setShouldPoll] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [messageTags, setMessageTags] = useState<Record<string, string>>();

  const channelConfig = flowMessage.message.channel.config;
  const cortexTagOptions: Record<string, string[]> =
    channelConfig?.cortexTagOptions || {};

  const { data: jobId, refetch: fetchTags } =
    useAnalyzeDecisionEngineTagsForMessageQuery(
      {
        messageId: flowMessage.message.id,
        tags: cortexTagOptions,
      },
      {
        select: (data) => data.analyzeDecisionEngineTagsForMessage,
        enabled: false,
      },
    );

  const updateMutation = useUpdateDecisionEngineMessageMutation();

  useEffect(() => {
    if (jobId) {
      setShouldPoll(true);
    }
  }, [jobId]);

  useRunSqlResultQuery(
    {
      jobId: String(jobId),
      page: 0,
    },
    {
      enabled: Boolean(jobId),
      refetchInterval: shouldPoll ? 1000 : 0,
      onError: (err) => {
        setShouldPoll(false);
        setIsLoading(false);
        toast({
          id: "tags",
          title: "Error",
          message: err.message,
          variant: "error",
        });
      },
      onSuccess: (data) => {
        if (!data.backgroundPreviewQueryResult) {
          return;
        }
        if (
          data.backgroundPreviewQueryResult.__typename !== "FailedQueryResponse"
        ) {
          const parsedTags = parseTagsFromSource(data);
          setMessageTags(parsedTags);
        }
        setShouldPoll(false);
        setIsLoading(false);
      },
    },
  );

  const analyzeWithCortex = () => {
    const source = engine.flows?.[0]?.audience?.connection?.type;
    switch (source) {
      case "snowflake":
        return {
          icon: "a4ac9f5f978ab2446fc17bf116067cb7c74116a2-960x952.png",
          name: "Snowflake Cortex",
        };
      case "bigquery":
        return {
          icon: "2a4f3d940e21dfa356bd993177586dab5e1b628f-2500x2500.svg",
          name: "BigQuery Vertex",
        };
      default:
        throw new Error("Unsupported source " + source);
    }
  };

  const { icon, name } = analyzeWithCortex();

  return (
    <DrawerBody bg="gray.50">
      <Column gap={6} maxW="2xl" mx="auto">
        <Column>
          <Row gap={2} alignItems="center">
            <Box as="img" height="18px" src={cdn(icon, 48)} />
            <SectionHeading>Analyze message with {name}</SectionHeading>
          </Row>
          <Text>
            Help Hightouch make better recommendations by analyzing the content
            and structure of your message with {name}.
          </Text>
        </Column>
        {messageTags ? (
          <Card>
            <Column gap={4}>
              <Text fontWeight="medium">
                Cortex has analyzed your message and identified the following
                attributes:
              </Text>
              <Box
                display="grid"
                gridTemplateColumns="max-content 1fr"
                columnGap={8}
                alignItems="center"
                rowGap={2}
              >
                {Object.entries(messageTags).map(([key, value]) => {
                  const valueHash = hashCode(value);
                  const colors = ["danger.200", "warning.200", "grass.200"];
                  const color = colors[Math.abs(valueHash) % colors.length];

                  return (
                    <Fragment key={key}>
                      <Text>{capitalize(key)}</Text>
                      <Box
                        sx={{
                          span: { background: color },
                          p: { fontWeight: "medium" },
                        }}
                      >
                        <Tag>{value}</Tag>
                      </Box>
                    </Fragment>
                  );
                })}
              </Box>
              <ButtonGroup size="lg">
                <Button
                  variant="primary"
                  onClick={async () => {
                    await updateMutation.mutateAsync({
                      id: flowMessage.message.id,
                      input: {
                        tags: messageTags,
                      },
                    });
                    toast({
                      id: "tags",
                      title: "Message tags applied successfully",
                      variant: "success",
                    });
                  }}
                  isLoading={updateMutation.isLoading}
                >
                  Apply to message
                </Button>
                <Button
                  onClick={async () => {
                    setMessageTags(undefined);
                    setIsLoading(true);
                    await fetchTags();
                  }}
                >
                  Re-analyze
                </Button>
              </ButtonGroup>
            </Column>
          </Card>
        ) : isLoading ? (
          <Card>
            <Column align="center" justify="center" height="100%" width="100%">
              <LoadingCircles />

              <Row align="center" justify="center" mb={10}>
                <Text color="text.secondary" fontWeight="medium">
                  Querying {name}...
                </Text>
              </Row>
            </Column>
          </Card>
        ) : (
          <Column alignItems="flex-start">
            <Button
              onClick={async () => {
                setIsLoading(true);
                await fetchTags();
              }}
              size="lg"
              variant="primary"
            >
              Analyze message
            </Button>
          </Column>
        )}
      </Column>
    </DrawerBody>
  );
};
