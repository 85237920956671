import { FC, useMemo } from "react";

import {
  Column,
  FormField,
  GroupedCombobox,
  IconButton,
  RefreshIcon,
  Row,
} from "@hightouchio/ui";
import * as Yup from "yup";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { Section } from "src/ui/section";
import { COMMON_SCHEMAS } from "src/utils/destinations";

import { FromIdField } from "src/components/destinations/from-id-field";
import { MappingsField } from "src/components/destinations/mappings-field";
import { ModeField } from "src/components/destinations/mode-field";
import { ObjectField } from "src/components/destinations/object-field";

export const validation = Yup.object().shape({
  mode: Yup.string().required("upsert"),
  object: Yup.string().required(),
  fromId: Yup.mixed().required(),
  accountFromId: Yup.mixed().when("object", {
    is: "users",
    then: Yup.mixed().required(),
    otherwise: Yup.mixed().notRequired(),
  }),
  mappings: COMMON_SCHEMAS.mappings,

  customMappings: COMMON_SCHEMAS.mappings,
});

const USERS_OPTIONS = [
  { label: "Name", value: "name" },
  { label: "First Name", value: "First Name" },
  { label: "Last Name", value: "Last Name" },
  { label: "Email", value: "Email" },
  { label: "Title", value: "Title" },
];

const OPTIONS = [
  { label: "Name", value: "name" },
  { label: "Type", value: "type" },
  { label: "Status", value: "status" },
  { label: "Company Size", value: "Company Size" },
  { label: "Contract Value", value: "Contract Value" },
  { label: "Cancellation Date", value: "Cancellation Date" },
  { label: "Contract Renewal Date", value: "Contract Renewal Date" },
  { label: "Contract Start Date", value: "Contract Start Date" },
];

const MODES = [{ label: "Upsert", value: "upsert" }];

const OBJECTS = [
  { label: "Accounts", value: "accounts" },
  { label: "Account Users", value: "users" },
];

export const TotangoForm: FC = () => {
  const {
    hightouchColumns,
    config,
    setConfig,
    errors,
    reloadModel,
    loadingModel,
  } = useDestinationForm();

  const hightouchColumnGroups = useMemo(() => {
    return hightouchColumns.map((group) => ({
      ...group,
      options: group.options ?? [],
    }));
  }, [hightouchColumns]);

  return (
    <>
      <ObjectField options={OBJECTS} />

      <ModeField
        options={MODES}
        onChange={(mode) => setConfig({ object: config?.object, mode })}
      />
      {config?.object && (
        <Section>
          <Column gap={8}>
            <FromIdField
              fieldName={`Totango ${
                config?.object === "users" ? "User" : "Account"
              } ID`}
            />

            {config?.object === "users" && (
              <FormField
                error={errors?.accountFromId}
                label="Which column contains the account ID that the user belongs to?"
              >
                <Row align="center" gap={2}>
                  <GroupedCombobox
                    isInvalid={Boolean(errors?.accountFromId)}
                    isLoading={loadingModel}
                    optionGroups={hightouchColumnGroups}
                    placeholder="Select a column..."
                    value={config?.accountFromId}
                    onChange={(val) => {
                      setConfig({ ...config, accountFromId: val });
                    }}
                  />
                  <IconButton
                    icon={RefreshIcon}
                    aria-label="Refresh columns"
                    isLoading={loadingModel}
                    onClick={reloadModel}
                  />
                </Row>
              </FormField>
            )}
          </Column>
        </Section>
      )}
      {config?.object && (
        <>
          <Section>
            <MappingsField
              options={config?.object === "users" ? USERS_OPTIONS : OPTIONS}
            />
          </Section>
          <Section>
            <MappingsField isCustom />
          </Section>
        </>
      )}
    </>
  );
};

export default {
  form: TotangoForm,
  validation,
};
