import { useEffect, useState } from "react";

import {
  Column,
  Row,
  TextInput,
  Box,
  Button,
  FormField,
  RefreshIcon,
} from "@hightouchio/ui";
import { captureException } from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";

import { SourceForm } from "src/components/sources/setup";
import { Source } from "src/components/sources/source-catalog";
import { Testing } from "src/components/sources/testing";
import { useSourceTesting } from "src/components/sources/testing/hooks";
import {
  SourceDefinition,
  useCreateSourceV2Mutation,
  useSourceDefinitionsQuery,
} from "src/graphql";
import { Welcome } from "src/partner/welcome";
import {
  PartnerWizard,
  PartnerWizardStep,
  PartnerWizardStickyHeader,
} from "src/partner/wizard";
import { SlugResourceType, useResourceSlug } from "src/utils/slug";
import { useWizardStepper } from "src/utils/use-wizard-stepper";
import { sendPostMessage } from "./send-post-message";
import { WIZARD_CONTAINER_STYLES } from "./utils";
import { usePartner } from "./context";
import {
  DEFAULT_LIGHTNING_SCHEMA_CONFIG,
  WarehouseSchemaConfig,
} from "src/components/sources/setup/constants";
import { CreateSourceConfig } from "./types";

const LIGHTNING_ONLY_TEST_IDS = [
  "validatePlannerSchemaPermissions",
  "validateAuditSchemaPermissions",
];

export const CreateSource = () => {
  const { config: partnerConfig, cobranded } = usePartner<CreateSourceConfig>();
  const { getSlug: getSourceSlug } = useResourceSlug(
    SlugResourceType.Connections,
  );
  const [sourceDefinition, setSourceDefinition] = useState<SourceDefinition>();
  const [tunnelId, setTunnelId] = useState<string | null>();
  const [credentialId, setCredentialId] = useState<string>();
  const [sourceConfig, setSourceConfig] = useState<any>({});
  const [name, setName] = useState("");
  const [isWelcome, setIsWelcome] = useState(cobranded);
  const [step, setStep] = useWizardStepper(0);
  const { appBypassSourceTests } = useFlags();

  const [plannerDatabase, setPlannerDatabase] = useState<string | undefined>();
  const [schema, setSchema] = useState<WarehouseSchemaConfig>(
    DEFAULT_LIGHTNING_SCHEMA_CONFIG,
  );

  const [lightningEnabled, setLightningEnabled] = useState<boolean | undefined>(
    false,
  );
  const [lightningSchemaMode, setLightningSchemaMode] = useState<
    "shared" | "separate"
  >("separate");

  const {
    results: testResults,
    steps: testSteps,
    getTestSteps,
    runTest,
    timeElapsed,
  } = useSourceTesting();
  const allNonLightningTestsPassed = testResults?.stepResults
    ?.filter((r) => !LIGHTNING_ONLY_TEST_IDS.includes(r.id))
    .every((r) => r.success);
  const canProgressWithFailedTest =
    allNonLightningTestsPassed && sourceDefinition?.supportsInWarehouseDiffing;

  const sourceDefinitionsQuery = useSourceDefinitionsQuery(undefined, {
    select: (data) => data.getSourceDefinitions,
    suspense: true,
  });

  const createSourceMutation = useCreateSourceV2Mutation({
    onSuccess: () => {
      // skip query invalidation
    },
  });

  const submit = async () => {
    const slug = await getSourceSlug(name || sourceDefinition?.name || "");

    try {
      await createSourceMutation.mutateAsync({
        source: {
          slug,
          name: name !== "" ? name : sourceDefinition?.name,
          config: sourceConfig,
          type: sourceDefinition?.type,
          setup_complete: true,
          tunnel_id: tunnelId,
          credential_id: credentialId ? String(credentialId) : undefined,
          sample_data_source_id: null,
          plan_in_warehouse: lightningEnabled,
          plan_in_warehouse_config: lightningEnabled
            ? { plannerDatabase, schema }
            : null,
        },
      });

      sendPostMessage("create_source_finished");
    } catch (error) {
      captureException(error);
    }
  };

  const variables = {
    sourceId: undefined,
    sourceType: sourceDefinition?.type,
    configuration: sourceConfig,
    credentialId: credentialId ? Number(credentialId) : undefined,
    tunnelId: tunnelId ? String(tunnelId) : undefined,
    warehousePlanConfig: lightningEnabled
      ? { plannerDatabase, schema }
      : undefined,
  };

  const steps: Array<PartnerWizardStep | boolean | undefined> = [
    !partnerConfig.sourceType && {
      label: "Select a new data source type",
      description:
        "Connect to your data warehouse, transactional database, object storage, etc.",
      next: "Select a data source to continue",
      render: () => {
        const sourceDefinitions: SourceDefinition[] = [];
        partnerConfig.allowedSourceTypes.forEach((type) => {
          const definition = sourceDefinitionsQuery.data?.find(
            (d) => d.type === type,
          );
          if (definition) {
            sourceDefinitions.push(definition);
          }
        });

        sendPostMessage("create_source_started");

        return (
          <>
            <PartnerWizardStickyHeader>
              Select a new data source type
            </PartnerWizardStickyHeader>
            <Box
              display="grid"
              gridTemplateColumns="repeat(auto-fit, minmax(200px, 1fr))"
              gridAutoRows="64px"
              gap={4}
            >
              {sourceDefinitions
                ?.sort((a, b) => a.name.localeCompare(b.name))
                .map((definition) => {
                  return (
                    <Source
                      key={`${definition.type}`}
                      definition={definition}
                      onSelect={(definition) => {
                        setSourceDefinition(definition);
                        setStep((step) => step + 1);
                      }}
                      selected={false}
                    />
                  );
                })}
            </Box>
          </>
        );
      },
    },
    {
      label: "Configure your data source",
      description:
        "Provide credentials and connection details for your chosen data source.",
      onNext: async () => {
        await getTestSteps(variables);
        runTest(variables);
        setStep((step) => step + 1);
      },
      render: () =>
        sourceDefinition ? (
          <>
            <PartnerWizardStickyHeader>
              Configure your data source
            </PartnerWizardStickyHeader>
            <Column gap={6} {...WIZARD_CONTAINER_STYLES}>
              <SourceForm
                config={sourceConfig}
                credentialId={credentialId}
                definition={sourceDefinition}
                disableAuthMethod={false}
                isSetup={true}
                lightningEnabled={lightningEnabled}
                setLightningEnabled={setLightningEnabled}
                setConfig={setSourceConfig}
                setCredentialId={setCredentialId}
                setTunnelId={setTunnelId}
                sourceId={undefined}
                tunnelId={tunnelId}
                plannerDatabase={plannerDatabase}
                setPlannerDatabase={setPlannerDatabase}
                schema={schema}
                setSchema={setSchema}
                lightningSchemaMode={lightningSchemaMode}
                setLightningSchemaMode={setLightningSchemaMode}
                hasSetupLightning={false}
                onConnectClick={() => {}}
              />
            </Column>
          </>
        ) : null,
    },
    {
      label: "Test your connection",
      description:
        "Verify that you can query your data source with the provided credentials.",
      isDisabled:
        !testResults?.success &&
        !canProgressWithFailedTest &&
        !appBypassSourceTests,
      tooltip:
        !testResults?.success &&
        !canProgressWithFailedTest &&
        !appBypassSourceTests
          ? "Cannot continue until tests pass"
          : undefined,
      onNext: async () => {
        setStep((step) => step + 1);
      },

      render: () => {
        return (
          <>
            <PartnerWizardStickyHeader>
              Test your connection
            </PartnerWizardStickyHeader>
            <Column {...WIZARD_CONTAINER_STYLES}>
              <Testing
                config={sourceConfig}
                credentialId={credentialId}
                isSetup={true}
                plannerDatabase={undefined}
                results={testResults}
                sourceDefinition={sourceDefinition!}
                steps={testSteps}
                timeElapsed={timeElapsed}
              />
              {testResults?.success === false && (
                <Row>
                  <Button
                    icon={RefreshIcon}
                    mt={10}
                    onClick={() => runTest(variables)}
                  >
                    Test again
                  </Button>
                </Row>
              )}
            </Column>
          </>
        );
      },
    },
    {
      label: "Name your data source",
      description:
        "Finalize your configuration and give your new source a descriptive name.",
      render: () => (
        <>
          <PartnerWizardStickyHeader>
            Name your data source
          </PartnerWizardStickyHeader>
          <Column gap={6} {...WIZARD_CONTAINER_STYLES}>
            <FormField label="Source name">
              <TextInput
                autoFocus
                placeholder="Enter a name..."
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </FormField>
          </Column>
        </>
      ),
    },
  ].filter(Boolean);

  useEffect(() => {
    setSourceConfig(undefined);
    setTunnelId(undefined);
    setCredentialId("");
  }, [sourceDefinition]);

  useEffect(() => {
    if (partnerConfig.sourceType && sourceDefinitionsQuery.data) {
      const definition = sourceDefinitionsQuery.data.find(
        (d) => d.type === partnerConfig.sourceType,
      );
      if (definition) {
        setSourceDefinition(definition);
      } else {
        captureException(
          new Error(
            `Source definition not found for type ${partnerConfig.sourceType}`,
          ),
        );
      }
    }
  }, [partnerConfig.sourceType, sourceDefinitionsQuery.data]);

  if (isWelcome) {
    return (
      <Welcome
        onContinue={() => {
          setIsWelcome(false);
        }}
      />
    );
  }

  return (
    <PartnerWizard
      step={step}
      steps={steps as any}
      onStepChange={setStep}
      onSubmit={submit}
    />
  );
};
