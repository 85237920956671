import { FC, useEffect, useRef, useState } from "react";

import { ArrowUpIcon, Box, Row, Spinner, chakra } from "@hightouchio/ui";

import { CopilotWithGradientIcon } from "src/ui/icons/copilot-with-gradient";

import { CopilotSubmitButton } from "./copilot-submit-button";
import { LowerGlowBlue, LowerGlowPurple } from "./glows";

const initialHeight = 38;

type CopilotTextAreaProps = Omit<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  "onSubmit"
> & {
  isLoading?: boolean;
  isDisabled?: boolean;
  value?: string;
  clearOnSubmit?: boolean;
  onSubmit: (value: string) => void;
};

export const CopilotTextArea: FC<CopilotTextAreaProps> = ({
  placeholder = "What do you want to see?",
  isLoading = false,
  isDisabled = false,
  clearOnSubmit = true,
  value: externalValue = "",
  onSubmit,
  ...props
}) => {
  const [value, setValue] = useState(externalValue);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const submit = () => {
    if (!value) return;

    onSubmit(value);
    if (clearOnSubmit) {
      setValue("");
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    submit();
  };

  useEffect(() => {
    // Override internal state if external value changes
    setValue(externalValue);
  }, [externalValue]);

  useEffect(() => {
    if (!textAreaRef.current) return;

    function resize() {
      if (!textAreaRef.current) return;

      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height =
        textAreaRef.current.scrollHeight + "px";
    }

    textAreaRef.current.addEventListener("input", resize);

    resize();

    return () => {
      textAreaRef.current?.removeEventListener("input", resize);
    };
  }, []);

  return (
    <Row
      alignItems="center"
      position="relative"
      borderRadius="md"
      boxShadow="sm"
      border="1px solid"
      borderColor="base.border"
      transition="border-color 200ms ease-in-out, box-shadow 200ms ease-in-out"
      _hover={{
        borderColor: isDisabled ? "transparent" : "#BD85FA",
        boxShadow: isDisabled
          ? "sm"
          : "0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10)",
      }}
      _focusWithin={{
        borderColor: isDisabled ? "transparent" : "#BD85FA",
        boxShadow: isDisabled
          ? "sm"
          : " 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 0px 0px 3px rgba(242, 231, 254, 0.80)",
        ".copilot-text-area-gradient": {
          opacity: isDisabled ? 0 : 1,
        },
      }}
    >
      <Box position="absolute" left={2} top="9px">
        {/* The copilot icon exists in hightouch ui without the gradient */}
        <CopilotWithGradientIcon size={20} />
      </Box>
      <chakra.textarea
        autoFocus
        ref={textAreaRef}
        disabled={isDisabled}
        placeholder={placeholder}
        px={9}
        py="9px"
        borderRadius="md"
        fontSize="13px"
        fontWeight="medium"
        height="auto"
        lineHeight="20px"
        minHeight={`${initialHeight}px`}
        outline="none"
        overflowY="hidden"
        resize="none"
        rows={1}
        width="100%"
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            handleFormSubmit(event);
          }
        }}
        value={value}
        onChange={(event) => setValue(event.target.value)}
        {...props}
      />
      {!isLoading ? (
        <Box position="absolute" right="7px" bottom="7px">
          <CopilotSubmitButton
            aria-label="Submit."
            icon={ArrowUpIcon}
            isDisabled={isDisabled}
            isLoading={isLoading}
            type="submit"
            size="sm"
            variant="secondary"
            onClick={handleFormSubmit}
          />
        </Box>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="24px"
          width="24px"
          position="absolute"
          right="7px"
          bottom="7px"
          sx={{
            "& > svg > circle:nth-child(2)": {
              stroke: "var(--chakra-colors-electric-base)",
            },
          }}
        >
          <Spinner size="sm" />
        </Box>
      )}

      <Box
        className="copilot-text-area-gradient"
        position="absolute"
        opacity={0}
        borderRadius="md"
        bottom="-1px"
        left="-1px"
        height={`${initialHeight}px`}
        overflow="hidden"
        pointerEvents="none"
        transition="opacity 250ms ease-in"
      >
        <LowerGlowPurple />
      </Box>
      <Box
        className="copilot-text-area-gradient"
        position="absolute"
        opacity={0}
        bottom="-1px"
        height={`${initialHeight}px`}
        overflow="hidden"
        pointerEvents="none"
        transition="opacity 250ms ease-in"
      >
        <LowerGlowBlue />
      </Box>
    </Row>
  );
};
