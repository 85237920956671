import { FC } from "react";

import { Column, Dialog } from "@hightouchio/ui";
import pluralize from "pluralize";

import { Form, FormActions, useHightouchForm } from "src/components/form";

import { getLabelsFromTags, getTagsFromLabels, LabelForm } from "./label-form";
import { ResourceType } from "./use-labels";

type Props = {
  rows?: number;
  isOpen: boolean;
  labels?: Record<string, string>;
  resourceType: ResourceType;
  onClose: () => void;
  onSubmit: (labels: Record<string, string>) => Promise<unknown>;
};

export const EditLabelModal: FC<Readonly<Props>> = ({
  isOpen,
  rows,
  labels,
  resourceType,
  onClose,
  onSubmit,
}) => {
  const form = useHightouchForm({
    success: rows
      ? ({ labels }) => {
          const labelCount = Object.keys(labels).length;
          return `Added ${labelCount} ${pluralize(
            "label",
            labelCount,
          )} to ${rows} ${pluralize(resourceType, rows)}`;
        }
      : "Labels updated",
    onSubmit: async ({ labels }) => {
      const response = await onSubmit(getTagsFromLabels(labels));
      onClose();
      return response;
    },
    values: {
      labels: getLabelsFromTags(labels),
    },
  });

  return (
    <Form form={form}>
      <Dialog
        isOpen={isOpen}
        variant="info"
        width="xl"
        title={
          rows
            ? `Add labels to ${rows} ${pluralize(resourceType, rows)}`
            : "Edit labels"
        }
        actions={<FormActions size="md" />}
        onClose={onClose}
      >
        <Column gap={4}>
          <LabelForm
            heading={`You can label ${pluralize(
              resourceType,
            )} that have similar properties`}
            hint="Example keys: team, project, region, env."
            resourceType={resourceType}
          />
        </Column>
      </Dialog>
    </Form>
  );
};
