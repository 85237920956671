import { useState, FC } from "react";

import { Alert, Button, Dialog, Text } from "@hightouchio/ui";
import moment from "moment";
import pluralize from "pluralize";

import { useUser } from "src/contexts/user-context";
import * as analytics from "src/lib/analytics";

export const TrialBanner: FC = () => {
  const { workspace } = useUser();
  const [showContactModal, setShowContactModal] = useState<boolean>(false);

  if (!workspace || !workspace?.trial_ends_at) {
    return null;
  }

  const hasTrialExpired = moment(
    workspace.trial_ends_at,
    "YYYY-MM-DD",
  ).isBefore(moment());

  analytics.track("Trial Banner Shown", {
    workspace_id: workspace.id,
    has_trial_expired: hasTrialExpired,
  });

  const onContactClick = () => {
    analytics.track("Trial Banner CTA Clicked", {
      workspace_id: workspace.id,
      has_trial_expired: hasTrialExpired,
    });
    setShowContactModal(true);
  };

  const closeModal = () => {
    setShowContactModal(false);
  };

  const daysLeft = workspace.trial_ends_at
    ? moment(workspace.trial_ends_at, "YYYY-MM-DD").diff(moment(), "days") + 1
    : 0;

  const alertTitle = hasTrialExpired
    ? "Free trial expired"
    : "Free trial expiring soon";

  const alertMessage = hasTrialExpired
    ? "Your free trial of the Team plan has expired."
    : `You have ${daysLeft} ${pluralize(
        "day",
        daysLeft,
      )} left on your free trial of the Team plan.`;

  return (
    <>
      <Alert
        variant="banner"
        type="upsell"
        justify="center"
        title={alertTitle}
        message={alertMessage}
        actions={
          <Button variant="secondary" onClick={onContactClick}>
            Contact us to upgrade
          </Button>
        }
      />
      <Dialog
        isOpen={showContactModal}
        variant="info"
        title="Thank you"
        actions={<Button onClick={closeModal}>Close</Button>}
        onClose={closeModal}
      >
        <Text>
          A member of our team will reach out to you in the next business day to
          help upgrade your workspace.
        </Text>
      </Dialog>
    </>
  );
};
