/**
 * XXX: This is meant to be imported from `match-booster/types but the app
 * typecheck will fail since backend code doesn't have strict null check.
 * For now, define the SemanticColumnType here as we don't expect this to
 * change frequently
 */
export enum SemanticColumnType {
  Email = "email",
  Phone = "phone",
  MaidAaid = "AAID",
  MaidIdfa = "IDFA",
  MobileID = "MAID",
  IpAddress = "ip_address",
  EmailMd5 = "email_md5",
  EmailSha256 = "email_sha256",
  FirstName = "first_name",
  LastName = "last_name",
  Zip = "zip",
  City = "city",
  LgID = "lg_id",
}

export type BaseType = {
  type: string;
  /**
   * `true` if value cannot be `undefined`.
   * @default false
   */
  required?: boolean;
  /**
   * `true` if value cannot be `null`.
   * @default false
   */
  notNull?: boolean;
  /**
   * `true` if the type contains metadata for retrieving
   * remote context for validation, data-coersion, etc.
   * @default false
   */
  async?: boolean;

  // @presentation non-functional metadata
  // these are piece of information no used for validation or data coercion
  // but rather only for things like data presentation
  /**
   * @presentation
   */
  label?: string;
  /**
   * @presentation
   */
  description?: string;

  identifier?: boolean;

  /**
   * `true` means we cannot write to this field.
   * This is useful for declaring readonly identifier fields that are used for record-matching.
   */
  readonly?: boolean;

  /* Used for auto-mapping with match boosted columns */
  semanticColumnType?: SemanticColumnType;

  /**
   * By default, all MB columns are mapped but this allows user to remove
   * specified columns for special cases (e.g. TTD only allows one identifier
   * type per segment so a user can choose which boosted column to map here)
   */
  matchBoosterOptional?: boolean;
};

export type Props<T extends BaseType> = Readonly<Omit<T, "type" | "async">>;

export const validator: ValidatorSync<BaseType> =
  (type) => (value: unknown) => {
    if (type.required && value === undefined) {
      throw new Error(`undefined value is not allowed`);
    }
    if (type.notNull && value === null) {
      throw new Error(`null value is not allowed`);
    }
  };

/**
 * Generates syncrounous validator.
 */
export type ValidatorSync<T extends BaseType> = (
  type: Readonly<T>,
) => (value: unknown) => void;

/**
 * Generates async validator.
 */
export type Validator<T extends BaseType> = (
  type: Readonly<T>,
) => (value: unknown) => Promise<void>;
