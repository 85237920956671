import { FC } from "react";
import { useParams } from "src/router";

import { Warning } from "src/components/warning";
import { EventSyncQuery, useEventSyncQuery } from "src/graphql";
import { PageSpinner } from "src/components/loading";

import { WarehouseSync } from "../warehouse-sync";
import { ForwardingSync } from "../forwarding-sync";
import { useUser } from "src/contexts/user-context";
import { DeepNonNullable } from "ts-essentials";

export type ForwardingOutletContext = {
  sync: DeepNonNullable<EventSyncQuery["event_forwarding_syncs_by_pk"]>;
  type: "forwarding";
};

export type WarehouseOutletContext = {
  sync: DeepNonNullable<EventSyncQuery["event_warehouse_syncs_by_pk"]>;
  type: "warehouse";
};

export type OutletContext = ForwardingOutletContext | WarehouseOutletContext;

export const EventSync: FC = () => {
  const { workspace } = useUser();
  const { id } = useParams<{ id: string }>();

  const { data, isLoading } = useEventSyncQuery(
    { id: String(id), workspaceId: workspace?.id },
    {
      enabled: Boolean(id),
    },
  );

  if (isLoading) {
    return <PageSpinner />;
  }

  if (data?.event_warehouse_syncs_by_pk) {
    return <WarehouseSync sync={data.event_warehouse_syncs_by_pk as any} />;
  } else if (data?.event_forwarding_syncs_by_pk) {
    return <ForwardingSync sync={data.event_forwarding_syncs_by_pk as any} />;
  } else {
    return (
      <Warning
        subtitle="It may have been deleted"
        title="Event sync not found"
      />
    );
  }
};
