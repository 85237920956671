import { FC, useEffect, useMemo, useRef, useState } from "react";

import {
  CloseIcon,
  Column,
  ConfirmationDialog,
  DeleteIcon,
  Drawer,
  DrawerBody,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Paragraph,
  RefreshIcon,
  Row,
  SearchInput,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useToast,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import pluralize from "pluralize";

import { TextWithTooltip } from "src/components/text-with-tooltip";
import {
  OrderBy,
  SavedCampaignViewsBoolExp,
  SavedCampaignViewsOrderBy,
  useBulkArchiveSavedCampaignViewsMutation,
  useBulkDeleteSavedCampaignViewsMutation,
  useBulkRestoreSavedCampaignViewsMutation,
  useSavedCampaignViewsQuery,
} from "src/graphql";
import { MinimalSavedCampaignView } from "src/pages/campaigns/types";
import { useNavigate, useParams } from "src/router";
import {
  Pagination,
  SortOption,
  Table,
  TableColumn,
  useTableConfig,
  useTableSort,
} from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { PaginationProps } from "src/ui/table/pagination";
import { useRowSelect } from "src/ui/table/use-row-select";
import { AssetType } from "src/types/visual";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { getAssetNoun } from "../utils";

enum SortKeys {
  Name = "name",
  ViewType = "type",
  UpdatedAt = "updated_at",
}

enum DrawerTabs {
  Saved = 0,
  Archived = 1,
}

const initialSort: SortOption<keyof SavedCampaignViewsOrderBy> = {
  key: "updated_at",
  direction: OrderBy.Desc,
  label: "Recently updated",
};
const sortOptions: SortOption<keyof SavedCampaignViewsOrderBy>[] = [
  { key: "name", direction: OrderBy.Asc, label: "Name A -> Z" },
  { key: "name", direction: OrderBy.Desc, label: "Name Z -> A" },
  initialSort,
  {
    key: "updated_at",
    direction: OrderBy.Asc,
    label: "Least recently updated",
  },
];

type SavedCampaignViewsDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const SavedCampaignViewsDrawer: FC<SavedCampaignViewsDrawerProps> = ({
  isOpen,
  onClose,
}) => {
  const { id, assetType } = useParams<{ id: string; assetType: string }>();
  const navigate = useNavigate();
  const { toast } = useToast();

  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const [search, setSearch] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [archiveViewModalOpen, setArchiveViewModalOpen] = useState(false);
  const [deleteViewModalOpen, setDeleteViewModalOpen] = useState(false);

  const bulkDeleteSavedCampaignViewsMutation =
    useBulkDeleteSavedCampaignViewsMutation();
  const bulkArchiveSavedCampaignViewsMutation =
    useBulkArchiveSavedCampaignViewsMutation();
  const bulkRestoreSavedCampaignViewsMutation =
    useBulkRestoreSavedCampaignViewsMutation();

  const { limit, offset, page, setPage } =
    useTableConfig<SavedCampaignViewsOrderBy>({
      defaultSortKey: "updated_at",
      sortOptions: Object.values(SortKeys),
    });
  const orderBy = useTableSort<SavedCampaignViewsOrderBy>(
    initialSort,
    sortOptions,
  );
  const { selectedRows, onRowSelect } = useRowSelect();

  const hasuraFilters = useMemo(() => {
    const filters: SavedCampaignViewsBoolExp = {
      archived_at: { _is_null: true },
    };

    if (activeTab === DrawerTabs.Archived) {
      filters.archived_at = { _is_null: false };
    }

    if (search) {
      return {
        ...filters,
        _or: [
          { name: { _ilike: `%${search}%` } },
          { asset_model: { asset: { type: { _ilike: `%${search}%` } } } },
        ],
      };
    }

    return filters;
  }, [activeTab, search]);

  const savedCampaignViewsQuery = useSavedCampaignViewsQuery(
    { limit, filters: hasuraFilters, orderBy, offset },
    {
      notifyOnChangeProps: "tracked",
      keepPreviousData: true,
    },
  );

  const savedViews: MinimalSavedCampaignView[] =
    savedCampaignViewsQuery.data?.saved_campaign_views ?? [];
  const savedCampaignViewsCount =
    savedCampaignViewsQuery.data?.saved_campaign_views_aggregate.aggregate
      ?.count ?? 0;

  useEffect(() => {
    if (!isOpen) {
      setSearch("");
    }
  }, [isOpen]);

  const changeTab = (index: number) => {
    onRowSelect([]);
    setActiveTab(index);
  };

  const closeDrawer = () => {
    setActiveTab(0);
    onClose();
  };

  const archiveViews = async () => {
    try {
      await bulkArchiveSavedCampaignViewsMutation.mutateAsync({
        ids: selectedRows as string[],
      });

      toast({
        id: "archive-view-error",
        title: `Archived ${pluralize("view", selectedRows.length, true)}`,
        variant: "success",
      });

      onRowSelect([]);
    } catch (error) {
      Sentry.captureException(error);
      toast({
        id: "archive-view-error",
        title: `Failed to archive ${pluralize(
          "view",
          selectedRows.length,
          true,
        )}`,
        message: error.message,
        variant: "error",
      });
    }
  };

  const restoreViews = async () => {
    try {
      await bulkRestoreSavedCampaignViewsMutation.mutateAsync({
        ids: selectedRows as string[],
      });

      toast({
        id: "restore-view-error",
        title: `Restored ${pluralize("view", selectedRows.length, true)}`,
        variant: "success",
      });

      onRowSelect([]);
    } catch (error) {
      Sentry.captureException(error);
      toast({
        id: "restore-view-error",
        title: `Failed to restore ${pluralize(
          "view",
          selectedRows.length,
          true,
        )}`,
        message: error.message,
        variant: "error",
      });
    }
  };

  const deleteViews = async () => {
    try {
      await bulkDeleteSavedCampaignViewsMutation.mutateAsync({
        ids: selectedRows as string[],
      });

      toast({
        id: "delete-view-error",
        title: `Deleted ${pluralize("view", selectedRows.length, true)}`,
        variant: "success",
      });

      onRowSelect([]);

      if (id && selectedRows.includes(id)) {
        navigate(`/campaigns/${assetType ?? AssetType.Email}`);
      }

      closeDrawer();
    } catch (error) {
      Sentry.captureException(error);
      toast({
        id: "delete-view-error",
        title: `Failed to delete ${pluralize(
          "view",
          selectedRows.length,
          true,
        )}`,
        message: error.message,
        variant: "error",
      });
    }
  };

  const columns: TableColumn<MinimalSavedCampaignView>[] = [
    {
      name: "Name",
      cell: ({ name }) => (
        <TextWithTooltip message={name}>{name}</TextWithTooltip>
      ),
    },
    {
      name: "Parent model",
      cell: ({ parent_model }) => (
        <Row align="center" gap={2} overflow="hidden">
          <IntegrationIcon
            src={parent_model?.connection?.definition?.icon}
            name={parent_model?.connection?.definition?.name}
          />
          <Column gap={1} overflow="hidden">
            <Text isTruncated fontWeight="medium">
              {parent_model?.name ?? "Private model"}
            </Text>
          </Column>
        </Row>
      ),
    },
    {
      name: "Type",
      min: "min-content",
      max: "min-content",
      cell: ({ asset_model }) => (
        <TextWithTooltip message={asset_model?.asset?.type ?? ""}>
          {asset_model?.asset?.type
            ? getAssetNoun(asset_model.asset.type as AssetType, {
                capitalize: true,
                shorthand: true,
              })
            : "--"}
        </TextWithTooltip>
      ),
    },
    LastUpdatedColumn,
  ];

  const pagination: PaginationProps = {
    page,
    setPage,
    count: savedCampaignViewsCount,
    rowsPerPage: limit,
  };

  const clickRow = ({ id }) => {
    const row = savedViews.find((view) => view.id === id);
    const assetType = row?.asset_model?.asset?.type;

    if (assetType) {
      navigate(`/campaigns/${assetType}/${id}`);
    }

    closeDrawer();
  };

  return (
    <>
      <Drawer
        isOpen={isOpen}
        initialFocusRef={closeButtonRef}
        size="lg"
        showOverlay={false}
        onClose={closeDrawer}
      >
        <Column gap={4} p={6}>
          <Row align="center" justify="space-between">
            <Heading>Saved views</Heading>
            <Tooltip message="Close drawer">
              <IconButton
                aria-label="Close drawer."
                icon={CloseIcon}
                onClick={closeDrawer}
              />
            </Tooltip>
          </Row>
          <Tabs index={activeTab} onChange={changeTab}>
            <TabList>
              <Tab>Saved</Tab>
              <Tab>Archived</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <DrawerBody p={0}>
                  <Column gap={6} width="100%">
                    <Row justify="space-between">
                      <SearchInput
                        placeholder="Search views..."
                        value={search}
                        onChange={(event) => setSearch(event.target.value)}
                      />

                      <Menu>
                        <MenuButton isDisabled={selectedRows.length === 0}>
                          Actions
                        </MenuButton>
                        <MenuList>
                          <MenuItem
                            icon={DeleteIcon}
                            variant="danger"
                            onClick={() => setArchiveViewModalOpen(true)}
                          >
                            Archive view
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Row>
                    <Table
                      columns={columns}
                      data={savedViews}
                      selectedRows={selectedRows}
                      loading={
                        savedCampaignViewsQuery.isLoading ||
                        savedCampaignViewsQuery.isRefetching ||
                        bulkRestoreSavedCampaignViewsMutation.isLoading
                      }
                      placeholder={{
                        title: "No saved views",
                        body: (
                          <Text color="text.secondary" whiteSpace="nowrap">
                            {search
                              ? "No views found matching that search. Please update the search to try again."
                              : "Create a new view to see it here."}
                          </Text>
                        ),
                        error: "Saved views failed to load.",
                      }}
                      onSelect={onRowSelect}
                      onRowClick={clickRow}
                    />
                    <Row justify="flex-end" width="100%" mt={4} px={4}>
                      <Pagination {...pagination} />
                    </Row>
                  </Column>
                </DrawerBody>
              </TabPanel>
              <TabPanel>
                <DrawerBody p={0}>
                  <Column gap={6} width="100%">
                    <Row justify="space-between">
                      <SearchInput
                        placeholder="Search views..."
                        value={search}
                        onChange={(event) => setSearch(event.target.value)}
                      />
                      <Menu>
                        <MenuButton isDisabled={selectedRows.length === 0}>
                          Actions
                        </MenuButton>
                        <MenuList>
                          <MenuItem icon={RefreshIcon} onClick={restoreViews}>
                            Restore {pluralize("view", selectedRows.length)}
                          </MenuItem>
                          <MenuItem
                            icon={DeleteIcon}
                            variant="danger"
                            onClick={() => setDeleteViewModalOpen(true)}
                          >
                            Delete {pluralize("view", selectedRows.length)}
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Row>
                    <Table
                      columns={columns}
                      data={savedViews}
                      selectedRows={selectedRows}
                      loading={
                        savedCampaignViewsQuery.isLoading ||
                        savedCampaignViewsQuery.isRefetching ||
                        bulkRestoreSavedCampaignViewsMutation.isLoading
                      }
                      placeholder={{
                        title: "No archived saved views found",
                        body: (
                          <Text color="text.secondary" whiteSpace="nowrap">
                            {search
                              ? "No views found matching that search. Please update the search to try again"
                              : ""}
                          </Text>
                        ),
                        error: "Archived views failed to load.",
                      }}
                      onSelect={onRowSelect}
                      onRowClick={clickRow}
                    />
                    <Row justify="flex-end" width="100%" mt={4} px={4}>
                      <Pagination {...pagination} />
                    </Row>
                  </Column>
                </DrawerBody>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Column>
      </Drawer>

      <ConfirmationDialog
        confirmButtonText="Archive"
        isOpen={archiveViewModalOpen}
        title={`Archive ${pluralize("view", selectedRows.length)}`}
        variant="danger"
        onClose={() => setArchiveViewModalOpen(false)}
        onConfirm={archiveViews}
      >
        <Column gap={2}>
          <Paragraph>
            Are you sure you want to archive the{" "}
            {pluralize("view", selectedRows.length, selectedRows.length > 1)}?
          </Paragraph>
          <Paragraph>You may restore it from the archived tab.</Paragraph>
        </Column>
      </ConfirmationDialog>

      <ConfirmationDialog
        confirmButtonText="Delete"
        isOpen={deleteViewModalOpen}
        title={`Delete ${pluralize("view", selectedRows.length)}`}
        variant="danger"
        onClose={() => setDeleteViewModalOpen(false)}
        onConfirm={deleteViews}
      >
        <Column gap={4}>
          <Paragraph>
            Are you sure you want to delete the{" "}
            {pluralize("view", selectedRows.length, selectedRows.length > 1)}?
          </Paragraph>
          <Paragraph>
            Deletion of a view is not reversible, you will not be able to
            restore {selectedRows.length > 1 ? "these" : "it"} afterwards.
          </Paragraph>
        </Column>
      </ConfirmationDialog>
    </>
  );
};
