import { FormField, Select } from "@hightouchio/ui";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useWorkspaceNotificationChannelsQuery } from "../../../graphql";

const severityOptions = [
  { label: "Critical", value: "critical" },
  { label: "Error", value: "error" },
  { label: "Warning", value: "warning" },
  { label: "Info", value: "info" },
];

export const PagerdutyRecipientForm: FC = () => {
  const { watch } = useFormContext();

  const { data: workspaceChannels } = useWorkspaceNotificationChannelsQuery(
    undefined,
    {
      select(data) {
        return data.workspace_notification_channels;
      },
    },
  );

  const channelId = watch("channelId");

  return (
    <Controller
      name="config.severity"
      rules={{
        required: "Severity level is required",
        validate: (value) =>
          workspaceChannels?.some(
            (c) => c.config.severity === value && c.id !== channelId,
          )
            ? "Severity level already configured"
            : true,
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormField
          label="Severity level"
          error={error?.message}
          description="Choose a severity level for the Alert Event that will be sent to PagerDuty."
        >
          <Select
            options={severityOptions}
            width="100%"
            value={value}
            onChange={(severity) => onChange(severity)}
          />
        </FormField>
      )}
    />
  );
};
