import { Box, BoxProps } from "@hightouchio/ui";
import {
  CSSProperties,
  FC,
  MouseEvent,
  MouseEventHandler,
  ReactNode,
} from "react";

export type IconProps = {
  color?: string;
  strokeWidth?: string;
  sx?: BoxProps["sx"];
  size?: number;
  viewBox?: string;
  width?: number | string;
  height?: number | string;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  onMouseEnter?: MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: MouseEventHandler<HTMLDivElement>;
  style?: CSSProperties;
};

interface Props extends IconProps {
  children: ReactNode;
}

export const Icon: FC<Readonly<Props>> = ({
  color = "currentColor",
  viewBox = "0 0 24 24",
  strokeWidth,
  children,
  size = 24,
  width,
  height,
  sx = {},
  onClick,
  onMouseEnter,
  onMouseLeave,
  style,
}) => (
  <Box
    style={style}
    sx={{
      svg: {
        color: color,
        fill: color,
        strokeWidth,
        stroke: strokeWidth ? color : undefined,
      },
      flexShrink: 0,
      ...sx,
    }}
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    <svg
      height={height ?? size}
      viewBox={viewBox}
      width={width ?? size}
      xmlns="http://www.w3.org/2000/svg"
    >
      {children}
    </svg>
  </Box>
);
