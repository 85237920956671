import { useEffect, useMemo, useState } from "react";

import {
  Alert,
  Column,
  FormField,
  Heading,
  Spinner,
  Textarea,
  TextInput,
  useToast,
} from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { captureException } from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import uniq from "lodash/uniq";
import { Helmet } from "react-helmet";
import { Controller, useFieldArray } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "src/router";

import { Card } from "src/components/card";
import { Form, useHightouchForm } from "src/components/form";
import { ScheduleManager } from "src/components/schedule";
import { useScheduleState } from "src/components/schedule/schedule-manager";
import { ScheduleType } from "src/components/schedule/types";
import { SourceSelect } from "src/components/sources/source-select";
import { DeprecatedWizard, WizardStep } from "src/components/wizard";
import {
  ConnectionsBoolExp,
  useBulkCreateIdentityResolutionModelsMutation,
  useCreateIdentityResolutionGraphMutation,
  useIdrModelsQuery,
  useUpdateIdentityResolutionGraphMutation,
} from "src/graphql";
import { IDRv2IdentifierMergeRule } from "src/types/idr";
import { QueryableSource } from "src/types/models";
import { useWizardStepper } from "src/utils/use-wizard-stepper";

import { ModelSelect, OutputTable, RulesForm } from "./forms";
import { ModelConfigurationWizard } from "./model-configuration-wizard";
import {
  getDefaultIdentifiers,
  IDRGraphWizardFormState,
  ModelOptionWithQueryFields,
  ModelState,
} from "./types";
import {
  getDefaultMergeRuleSet,
  getDefaultMergeRuleV2,
  getGraphVersion,
  getIdentifiersFromModels,
  GraphVersion,
  GraphVersionEnabled,
  IdentityGraphSchema,
  IDRv1IdentifieRulesSchema,
  IDRv2IdentifieRulesSchema,
} from "./utils";
import { useOutputTable } from "./forms/hooks";

const defaultv1FormState: IDRGraphWizardFormState = {
  version: GraphVersion.V1,
  name: "",
  description: "",
  output_table: "",
  models: [],
  merge_rules: [getDefaultMergeRuleSet()],
  block_rules: [],
  resolution_rules: [],
  schedule: { type: ScheduleType.MANUAL },
};

const defaultv2FormState: IDRGraphWizardFormState = {
  version: GraphVersion.V2,
  name: "",
  description: "",
  output_table: "",
  models: [],
  merge_rules: [getDefaultMergeRuleV2()],
  block_rules: [],
  resolution_rules: [],
  schedule: { type: ScheduleType.MANUAL },
};

export const CreateIdentityResolutionGraph = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { idrGraphVersionEnabled } = useFlags();

  const [searchParams, setSearchParams] = useSearchParams();

  const urlParamVersion = searchParams.get("version");

  const version = getGraphVersion(
    urlParamVersion ? parseInt(urlParamVersion) : null,
    idrGraphVersionEnabled,
  );
  const isIDRv2 = version === GraphVersion.V2;

  // Update the url param to reflect the current version, if not initially provided
  // This is only needed for workspaces that have both the legacy v1 and v2 flow
  useEffect(() => {
    if (
      idrGraphVersionEnabled === GraphVersionEnabled.Bothv2AndLegacy &&
      !urlParamVersion
    ) {
      setSearchParams({
        version: isIDRv2
          ? GraphVersion.V2.toString()
          : GraphVersion.V1.toString(),
      });
    }
  }, [idrGraphVersionEnabled, urlParamVersion, isIDRv2]);

  const [source, setSource] = useState<QueryableSource | undefined | null>();
  const [step, setStep] = useWizardStepper(0);
  const [modelMappingStep, setModelMappingStep] = useWizardStepper(0);

  const { validateSchedule } = useScheduleState("identity graph");

  const createIdentityResolutionGraphMutation =
    useCreateIdentityResolutionGraphMutation({
      onSuccess: () => {
        // Prevents unnecessary re-fetches after creating the graph
      },
    });
  const bulkCreateIdentityResolutionModelsMutation =
    useBulkCreateIdentityResolutionModelsMutation({
      onSuccess: () => {
        // No-op to prevent unnecessary re-fetches after creating the models
      },
    });
  const updateIdentityResolutionGraphMutation =
    useUpdateIdentityResolutionGraphMutation({
      onSuccess: () => {
        // No-op to prevent unnecessary re-fetches after creating the models and rules
      },
    });

  const defaultFormState = isIDRv2 ? defaultv2FormState : defaultv1FormState;

  const form = useHightouchForm<IDRGraphWizardFormState>({
    success: "Identity graph created",
    values: defaultFormState,
    resolver:
      step === 3
        ? // Ignore rest of graph for rules step
          yupResolver(
            isIDRv2 ? IDRv2IdentifieRulesSchema : IDRv1IdentifieRulesSchema,
          )
        : yupResolver(IdentityGraphSchema),
    onSubmit: async ({
      models,
      merge_rules,
      block_rules,
      resolution_rules,
      schedule,
      ...graphData
    }) => {
      // Must be done in steps, since each step depends on the previous one
      // 1. Create the graph
      const graphResponse =
        await createIdentityResolutionGraphMutation.mutateAsync({
          input: {
            ...graphData,
            connection_id: source?.id,
            state: "enabled",
            version,
            schedule: schedule?.type === ScheduleType.MANUAL ? null : schedule,
            // Manual means schedule is paused
            schedule_paused: schedule?.type === ScheduleType.MANUAL,
            schedule_updated_at: new Date().toISOString(),
          },
        });

      const navigateToGraph = () =>
        navigate(`/idr/${graphResponse.insert_idr_one?.id}`);

      try {
        // 2. Create the models
        await bulkCreateIdentityResolutionModelsMutation.mutateAsync({
          objects: models.map((model, index) => ({
            type: model.type,
            order_by:
              model.type === "event"
                ? { column: model.order_by, order: "asc" }
                : null,
            idr_id: graphResponse.insert_idr_one?.id,
            mappings: {
              data: model.mappings.map((mapping) => ({
                column: mapping.column,
                identifier: mapping.identifier,
                model_id: model.model.id,
              })),
            },
            rank: index,
            segment_id: model.model.id,
          })),
        });
      } catch (e) {
        // Don't throw error so that navigation happens
        toast({
          id: "save-models-error",
          title: "Error creating models",
          message: e.message,
          variant: "error",
        });
        captureException(e);

        return navigateToGraph();
      }

      try {
        // 3. Add rules to graph
        // Only do this if the models were added successfully, otherwise the identifiers won't exist yet
        await updateIdentityResolutionGraphMutation.mutateAsync({
          id: graphResponse.insert_idr_one?.id ?? "",
          input: { merge_rules, block_rules, resolution_rules },
        });
      } catch (e) {
        // Don't throw error so that navigation happens
        toast({
          id: "save-rules-error",
          title: "Error creating rules",
          message: e.message,
          variant: "error",
        });
        captureException(e);
      }

      navigateToGraph();
    },
  });

  const {
    fields: selectedModelConfigs,
    append: appendModelConfig,
    remove: removeModelConfig,
    replace: replaceModelConfigs,
    update: updateModelConfig,
    insert: insertModelConfig,
  } = useFieldArray({
    control: form.control,
    name: "models",
  });

  const { validationState } = useOutputTable({
    outputTableName: form.watch("output_table"),
  });

  const resetVerticalWizardState = () => {
    replaceModelConfigs(
      selectedModelConfigs.map((modelConfig) => ({
        ...modelConfig,
        type: isIDRv2 ? "event" : "profile",
        order_by: "",
        mappings: [{ column: "", identifier: "" }],
      })),
    );
    setModelMappingStep(0);
    setStep(2);
  };

  const addEmptyIdentifierToModelConfig = (index: number) => {
    const modelConfig = selectedModelConfigs[index];
    if (modelConfig) {
      updateModelConfig(index, {
        ...modelConfig,
        mappings: [...modelConfig.mappings, { column: "", identifier: "" }],
      });
    }
  };

  const goToPreviousModelConfig = (index: number) => {
    // Add extra identifier row to the previous model config
    // so that they may add more identifiers
    addEmptyIdentifierToModelConfig(index);
    setModelMappingStep((currentStep) => currentStep - 1);
  };

  // TODO(samuel): figure out if we want this to run on every checkbox click _or_ when the step increments
  const idrModelsQuery = useIdrModelsQuery(
    {
      ids: selectedModelConfigs.map(({ model }) => model.id),
    },
    {
      select: (data) =>
        data.segments.sort((a, b) => a.name.localeCompare(b.name)),
    },
  );

  const selectedModelDetails = idrModelsQuery.data ?? [];

  const models = form.watch("models");

  // Get all identifiers from all models
  const identifiers = getIdentifiersFromModels(models, isIDRv2);

  const createInitialRulesFromModelDefinitions = () => {
    if (isIDRv2) {
      const models = form.getValues("models");
      const newRules: IDRv2IdentifierMergeRule[] = getIdentifiersFromModels(
        models,
        isIDRv2,
      ).map((identifier) => ({ identifier, transformations: [] }));

      form.setValue("merge_rules", newRules);
    }
  };

  const selectModelRow = (
    value: ModelOptionWithQueryFields | ModelOptionWithQueryFields[],
  ) => {
    if (Array.isArray(value)) {
      replaceModelConfigs(
        value.map((model) => ({
          model: { id: model.id, name: model.name },
          type: "event",
          order_by: "",
          mappings: [{ column: "", identifier: "" }],
          identifiers: getDefaultIdentifiers(isIDRv2),
        })),
      );

      return;
    }

    const model = value;

    const currentModelIds = selectedModelConfigs.map(({ model }) => model.id);
    const isModelSelected = currentModelIds.includes(model.id);

    if (isModelSelected) {
      removeModelConfig(
        selectedModelConfigs.findIndex(
          ({ model: savedModel }) => savedModel.id === model.id,
        ),
      );
    } else {
      // Find the correct index to insert the model alphabetically by name
      const insertIndex = selectedModelConfigs.findIndex(
        ({ model: savedModel }) =>
          savedModel.name.localeCompare(model.name) > 0,
      );

      const newModelConfig: ModelState = {
        model: { id: model.id, name: model.name },
        type: "event",
        order_by: "",
        mappings: [{ column: "", identifier: "" }],
      };

      if (insertIndex === -1) {
        // If no larger name is found, append to the end
        appendModelConfig(newModelConfig);
      } else {
        // Insert at the found index
        insertModelConfig(insertIndex, newModelConfig);
      }
    }
  };

  const identifierOptions = useMemo(() => {
    const defaultIdentifiers = getDefaultIdentifiers(isIDRv2);
    const usedIdentifiers = models.flatMap(({ mappings }) =>
      mappings.flatMap(({ identifier }) => identifier),
    );

    return uniq([...defaultIdentifiers, ...usedIdentifiers]).filter(Boolean);
  }, [models]);

  useEffect(() => {
    if (step === 0) {
      setSource(undefined);
    }
  }, [step]);

  useEffect(() => {
    form.reset(defaultFormState);
  }, [source]);

  const steps: WizardStep[] = [
    {
      title: "Select source",
      continue: "Click on a source to continue",
      header: <Heading>Select a data source</Heading>,
      render: () => (
        <Column gap={6}>
          <Alert
            type="info"
            justify="center"
            variant="inline"
            title="Lightning engine is required"
            message={
              <>
                Only sources with{" "}
                <Link
                  href={`${
                    import.meta.env.VITE_DOCS_URL
                  }/syncs/lightning-sync-engine`}
                  isExternal
                >
                  Lightning engine
                </Link>{" "}
                enabled will be shown
              </>
            }
          />
          <SourceSelect
            onSelect={(source) => {
              setSource(source);
              setStep(1);
            }}
            filter={sourceFilter}
          />
        </Column>
      ),
    },
    {
      title: "Select models",
      disabled: selectedModelConfigs.length === 0,
      header: <Heading>Select models</Heading>,
      onContinue: resetVerticalWizardState,
      render: () => (
        <Controller
          name="models"
          render={({ field }) => (
            <ModelSelect
              isMultiSelect
              graphVersion={version}
              sourceId={source?.id}
              selectedModels={field.value.map(
                ({ model }: ModelState) => model.id,
              )}
              onSelectModel={selectModelRow}
            />
          )}
        />
      ),
    },
    {
      title: "Configure models",
      continue: "Fill out the forms to continue",
      onBack:
        modelMappingStep > 0
          ? () => goToPreviousModelConfig(modelMappingStep - 1)
          : undefined,
      header: <Heading>Configure model mappings</Heading>,
      render: () => {
        if (idrModelsQuery.isLoading) {
          return (
            <Column align="center" height="100%">
              <Spinner size="lg" my="auto" />
            </Column>
          );
        }

        return (
          <ModelConfigurationWizard
            isIDRv2={isIDRv2}
            identifierOptions={identifierOptions}
            model={selectedModelConfigs[modelMappingStep]!}
            activeStep={modelMappingStep}
            steps={selectedModelDetails}
            source={source ?? undefined}
            onSubmit={(modelConfig, index) => {
              updateModelConfig(index, modelConfig);
              if (index < selectedModelConfigs.length - 1) {
                setModelMappingStep((currentStep) => currentStep + 1);
              } else {
                createInitialRulesFromModelDefinitions();
                setStep(3);
              }
            }}
          />
        );
      },
    },
    {
      title: "Configure identifier rules",
      header: <Heading>Configure identifier rules</Heading>,
      onBack: () => {
        addEmptyIdentifierToModelConfig(modelMappingStep);
        setStep(2);
      },
      onContinue: form.handleSubmit(() => setStep(4)),
      render: () => (
        <RulesForm
          identifiers={identifiers}
          sourceType={source!.type}
          showVersion2Rules={isIDRv2}
        />
      ),
    },
    {
      title: "Finalize",
      disabled:
        !validateSchedule(form.watch("schedule")) ||
        validationState !== "valid",
      header: <Heading>Finalize graph</Heading>,
      render: () => {
        return (
          <Column gap={6}>
            <Card gap={6}>
              <Controller
                name="name"
                render={({ field, fieldState: { error } }) => (
                  <FormField label="Identity graph name" error={error?.message}>
                    <TextInput
                      {...field}
                      isInvalid={Boolean(error)}
                      placeholder="Enter a name..."
                    />
                  </FormField>
                )}
              />
              <Controller
                name="description"
                render={({
                  field: { ref, ...field },
                  fieldState: { error },
                }) => (
                  <FormField
                    isOptional
                    label="Description"
                    description="Include details about the business purpose."
                    error={error?.message}
                  >
                    <Textarea
                      {...field}
                      isInvalid={Boolean(error)}
                      placeholder="Enter a description..."
                    />
                  </FormField>
                )}
              />
            </Card>

            <OutputTable isIDRv2={isIDRv2} validationState={validationState} />

            <Controller
              name="schedule"
              render={({ field }) => (
                <ScheduleManager
                  headingSize="sm"
                  resource="identity graph"
                  schedule={field.value}
                  setSchedule={field.onChange}
                  types={[
                    ScheduleType.MANUAL,
                    ScheduleType.INTERVAL,
                    ScheduleType.CUSTOM,
                    ScheduleType.CRON,
                  ]}
                  includeStartAndEnd={false}
                />
              )}
            />
          </Column>
        );
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title>Create identity graph</title>
      </Helmet>

      <Form form={form}>
        <DeprecatedWizard
          steps={steps}
          step={step}
          setStep={setStep}
          title="Create identity graph"
          onCancel={() => {
            navigate("/idr");
          }}
          onSubmit={form.submit}
        />
      </Form>
    </>
  );
};

const sourceFilter: ConnectionsBoolExp = {
  _and: [
    {
      plan_in_warehouse: { _eq: true },
    },
    {
      type: {
        _in: [
          "snowflake",
          "bigquery",
          "databricks",
          // only allow postgres as a source for local dev
          ...(import.meta.env.DEV ? ["postgres"] : []),
        ],
      },
    },
  ],
};
