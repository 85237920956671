import { FC } from "react";

import { CodeSnippet, Heading, Text } from "@hightouchio/ui";

import { Link } from "src/router";

import { CodeParams, SetupProps } from "./types";

function getCSharpCode(params: CodeParams): string {
  return `
  using Hightouch.Events;
  using Hightouch.Events.Serialization;

  var configuration = new Configuration("${params.writeKey}",
    apiHost: "https://${params.apiHost}",
    flushAt: 1);

  var analytics = new Analytics(configuration);

  analytics.Track("Order Completed", new JsonObject
  {
    ["userId"] = "abc",
    ["productId"] = "123"
  });
  `;
}

const install = "dotnet add package Hightouch.Events.CSharp";

export const SetupCSharp: FC<SetupProps> = (props: SetupProps) => {
  return (
    <>
      <Heading>Installing the SDK</Heading>
      <Text>
        This SDK is available via{" "}
        <Link href="https://www.nuget.org/packages/Hightouch.Events.CSharp">
          NuGet
        </Link>
        .
      </Text>
      <CodeSnippet code={install} />
      <Heading>Using the SDK</Heading>
      <CodeSnippet label="Program.cs" code={getCSharpCode(props)} />
    </>
  );
};
