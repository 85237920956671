import { DecisionEngineStatus } from "@hightouch/lib/customer-data/decision-engine/types";
import { Heading, Column, Row, StatusBadge } from "@hightouchio/ui";
import { Link } from "src/router";
import { FC } from "react";
import { Flow } from "..";
import { Audience } from "./audience";
import { FlowActionButton } from "./flow-action-button";
import { MessageFeed } from "./message-feed";
import { Messages } from "./messages";
import { Outcomes } from "./outcomes";
import { Card } from "src/components/card";

export const FlowCard: FC<
  Readonly<{
    flow: Flow;
  }>
> = ({ flow }) => {
  return (
    <Column gap={2} height="320px">
      <Row align="center" gap={4} justify="space-between">
        <Row gap={4} align="center">
          <Link href={`/ai/flows/${flow.id}`}>
            <Heading color="inherit">{flow.name}</Heading>
          </Link>
          <FlowStatus
            status={flow.status as DecisionEngineStatus}
            enabled={flow.enabled}
          />
        </Row>
        <FlowActionButton
          id={flow.id}
          status={flow.status as DecisionEngineStatus}
          enabled={flow.enabled}
          isValid={flow.messages.length > 0 && flow.outcomes.length > 0}
        />
      </Row>
      <Row flex={1} overflow="hidden" gap={4}>
        <Card
          minW={0}
          flex="1 0 80%"
          href={`/ai/flows/${flow.id}`}
          p={0}
          flexDirection="row"
          sx={{
            "& > div": {
              borderRight: "1px",
              borderColor: "base.border",
              padding: 6,
              flex: 1,
              gap: 6,
              align: "flex-start",
            },
            "div:last-of-type": {
              borderRight: "none",
            },
          }}
        >
          <Audience flow={flow} />
          <Messages flow={flow} />
          <Outcomes flow={flow} />
        </Card>
        <MessageFeed flow={flow} />
      </Row>
    </Column>
  );
};

const FlowStatus = ({
  status,
  enabled,
}: {
  status: DecisionEngineStatus;
  enabled: boolean;
}) => {
  if (!enabled && status === DecisionEngineStatus.READY) {
    return <StatusBadge variant="inactive">Paused</StatusBadge>;
  }
  return (
    <StatusBadge
      variant={
        status === DecisionEngineStatus.TRAINING
          ? "processing"
          : status === DecisionEngineStatus.READY
            ? "success"
            : "inactive"
      }
    >
      {status === DecisionEngineStatus.TRAINING
        ? "Initializing"
        : status === DecisionEngineStatus.READY
          ? "Running"
          : "Pending"}
    </StatusBadge>
  );
};
