import { FC, ReactNode } from "react";

import { Box, Row, ChevronDownIcon } from "@hightouchio/ui";

import { useUser } from "src/contexts/user-context";
import { Logo } from "src/ui/brand";
import { Circle } from "src/ui/circle";
import { getInitials } from "src/utils/user";

import { Header } from "./header";
import { UserMenu } from "./user-menu";

export const LogoHeader: FC<Readonly<{ children?: ReactNode }>> = ({
  children,
}) => {
  const { user } = useUser();

  return (
    <Header>
      <Row
        align="center"
        border="1px solid"
        borderColor="gray.300"
        color="gray.600"
        height="64px"
        px={12}
        width="100%"
      >
        <Row flex={1}>
          <Logo size="130px" variant="full" theme="dark" />
        </Row>
        {children}
        <Row align="center" justify="flex-end" flex={1}>
          <UserMenu
            user={user || { name: "unknown user", email: "<no email>" }}
          >
            <Row
              _hover={{
                borderColor: "rgba(255,255,255,.5)",
              }}
              align="center"
              border="1px solid rgba(255,255,255,.3)"
              borderRadius="6px"
              gap={2.5}
              justify={["center", "center", "center", "flex-start"]}
              px={[0, 0, 0, 2.5]}
              py={2}
            >
              <Circle
                color="avocado.100"
                radius="32px"
                bg="forest.base"
                fontWeight="semibold"
              >
                {getInitials(user?.name)}
              </Circle>
              <Box
                color="inherit"
                display={["none", "none", "none", "block"]}
                fontSize="sm"
                fontWeight={500}
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {user?.email}
              </Box>
            </Row>
          </UserMenu>
          <Row fontSize="18px">
            <ChevronDownIcon />
          </Row>
        </Row>
      </Row>
    </Header>
  );
};
