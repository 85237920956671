import { FC, useState } from "react";

import {
  AudienceIcon,
  Badge,
  Box,
  Button,
  CloseIcon,
  Column,
  DrawerBody,
  DrawerFooter,
  IconButton,
  Paragraph,
  Row,
} from "@hightouchio/ui";
import { uniq } from "lodash";
import {
  Link,
  Navigate,
  useNavigate,
  useOutletContext,
  useParams,
} from "src/router";

import { Drawer } from "src/components/drawer";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { DeleteConfirmationModal } from "src/components/modals/delete-confirmation-modal";
import {
  useDeleteIdentityResolutionModelMutation,
  useUpdateIdentityResolutionModelMutation,
} from "src/graphql";
import {
  getDefaultIdentifiers,
  ModelState,
} from "src/pages/identity-resolution/types";
import eventIcon from "src/pages/schema/assets/event.svg";
import profileIcon from "src/pages/schema/assets/parent.svg";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { abbreviateNumber } from "src/utils/numbers";
import { MapperField, OrderByField } from "src/pages/identity-resolution/forms";
import {
  GraphVersion,
  modelConfigValidationResolver,
} from "src/pages/identity-resolution/utils";

import { OutletContext } from ".";

export const Model: FC = () => {
  const { modelId: id } = useParams<{ modelId: string }>();
  const { graph, identifiers } = useOutletContext<OutletContext>();
  const navigate = useNavigate();
  const [isDeleting, setIsDeleting] = useState(false);

  const isIDRv2 = graph.version === GraphVersion.V2;
  const identifierOptions = uniq([
    ...getDefaultIdentifiers(isIDRv2),
    ...identifiers,
  ]);

  const onClose = () => {
    navigate(`/idr/${graph.id}/models`);
  };

  const model = graph.models.find((model) => String(model.id) === String(id));

  const values: ModelState = {
    model: model?.model ?? { id: "", name: "" },
    type: model?.type as "profile" | "event",
    order_by: model?.order_by?.column,
    mappings: [...(model?.mappings ?? []), { identifier: "", column: "" }],
  };

  const updateMutation = useUpdateIdentityResolutionModelMutation();
  const deleteMutation = useDeleteIdentityResolutionModelMutation();

  const form = useHightouchForm({
    onSubmit: async (model) => {
      if (!model) return;

      const { mappings, order_by } = model;

      await updateMutation.mutateAsync({
        id: String(id),
        model: {
          order_by: order_by ? { column: order_by, order: "asc" } : undefined,
        },
        mappings: mappings.map(({ identifier, column }) => ({
          idr_model_id: String(id),
          model_id: String(model?.model.id),
          identifier,
          column,
        })),
      });

      onClose();
    },
    values,
    resolver: (data, context, options) =>
      modelConfigValidationResolver(
        data,
        graph.version === GraphVersion.V2,
        context,
        options,
      ),
    success: "Model updated",
  });

  if (!model) {
    return <Navigate to={`/idr/${graph.id}/models`} replace />;
  }

  return (
    <Form form={form}>
      <Drawer closeOnEsc trapFocus={false} size="xl" isOpen onClose={onClose}>
        <Column
          height="100%"
          borderLeft="1px"
          borderTop="1px"
          borderColor="base.border"
        >
          <Row
            justify="space-between"
            align="flex-start"
            p={6}
            borderBottom="1px solid"
            borderColor="base.border"
            width="100%"
          >
            <Column gap={2}>
              <Row align="center" gap={2} overflow="hidden" height="24px">
                <Box
                  as="img"
                  src={model.type === "event" ? eventIcon : profileIcon}
                  width="24px"
                />
                <TextWithTooltip fontWeight="medium" isTruncated size="lg">
                  {model.model.name}
                </TextWithTooltip>
              </Row>
              <Row align="center" gap={2} flexShrink={0}>
                <Row
                  borderRight="1px"
                  borderColor="base.divider"
                  borderStyle="solid"
                  pr={2}
                  align="center"
                >
                  <Badge icon={AudienceIcon}>
                    {model.model.query_runs?.[0]
                      ? `${abbreviateNumber(
                          model.model.query_runs?.[0]?.size,
                        )} rows`
                      : "Unknown size"}
                  </Badge>
                </Row>
                <Link href={`/models/${model.model.id}`}>View model</Link>
              </Row>
            </Column>
            <IconButton icon={CloseIcon} aria-label="Close" onClick={onClose} />
          </Row>

          <DrawerBody>
            <Column gap={6} flex={1} minH={0} overflow="auto">
              <MapperField
                columns={model.model.columns}
                identifierOptions={identifierOptions}
              />
              {model.type === "event" ? (
                <OrderByField columns={model.model.columns} />
              ) : null}
            </Column>
          </DrawerBody>

          <DrawerFooter>
            <FormActions />
            <Button
              size="lg"
              variant="warning"
              onClick={() => {
                setIsDeleting(true);
              }}
            >
              Delete
            </Button>
          </DrawerFooter>
        </Column>
      </Drawer>

      <DeleteConfirmationModal
        label="model"
        content={
          <Paragraph>
            Deleting this model will remove its data from the identity graph.
          </Paragraph>
        }
        isOpen={isDeleting}
        onClose={() => setIsDeleting(false)}
        onDelete={async () => {
          await deleteMutation.mutateAsync({ id: String(id) });
          setIsDeleting(false);
          onClose();
        }}
      />
    </Form>
  );
};
