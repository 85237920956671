import { FC } from "react";
import {
  MonitoredResourceType,
  ParentResourceTypes,
} from "@hightouch/lib/resource-monitoring/types";
import { ResourceMonitoring } from "src/components/resource-alert-triggers";
import { useOutletContext } from "src/router";
import { Context } from ".";

export const Monitoring: FC = () => {
  const { sync } = useOutletContext<Context>();
  const id = String(sync.id);
  const destination = sync.destination;

  return (
    <ResourceMonitoring
      resourceId={id ?? ""}
      resourceType={MonitoredResourceType.Sync}
      parentResourceType={ParentResourceTypes.Destination}
      parentResourceId={destination?.id ?? ""}
    />
  );
};
