import { useFlags } from "launchdarkly-react-client-sdk";
import { SchemaModelType } from "src/types/schema";
import { SchemaObjectType } from "src/pages/schema/types";

const TYPES_WITH_CONFIGURATION = [
  SchemaModelType.Parent,
  SchemaModelType.Event,
  SchemaModelType.Interaction,
  SchemaModelType.Asset,
  SchemaModelType.Catalog,
  SchemaModelType.AdStats,
];

export function shouldShowConfigurationSection(type: SchemaModelType) {
  return TYPES_WITH_CONFIGURATION.includes(type);
}

const TYPES_WITH_SAMPLING = [
  SchemaModelType.Parent,
  SchemaModelType.Event,
  SchemaModelType.Related,
];

const SUPPORTED_SAMPLING_SOURCES = [
  "postgres",
  "snowflake",
  "redshift",
  "databricks",
  "bigquery",
];

export function useTabRoutes(opts: {
  schemaModelType: SchemaModelType;
  sourceType: string;
}) {
  const { schemaModelType, sourceType } = opts;
  const { appModelSamplingEnabled } = useFlags();

  return [
    { title: "Query", path: "query" },
    { title: "Relationships", path: "relationships" },
    { title: "Columns", path: "columns" },
    {
      title: "Activity",
      path: "activity",
      isHidden: schemaModelType !== SchemaModelType.Parent,
    },
    {
      title: "Match Booster",
      path: "match-booster",
      isHidden: schemaModelType !== SchemaModelType.Parent,
    },
    {
      title: "Sampling",
      path: "sampling",
      isHidden:
        !appModelSamplingEnabled ||
        !TYPES_WITH_SAMPLING.includes(schemaModelType) ||
        !SUPPORTED_SAMPLING_SOURCES.includes(sourceType),
    },
  ];
}

export function showMissingModelWarning(obj: SchemaObjectType) {
  if (obj.type !== SchemaModelType.Interaction) {
    return false;
  }

  const hasAssetRelationship = obj.relationships.some(
    (relationship) => relationship.to_model.type === SchemaModelType.Asset,
  );

  return !hasAssetRelationship;
}
