import { FC, useState } from "react";

import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Column,
  DeleteIcon,
  Dialog,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Paragraph,
  Radio,
  RadioGroup,
  Row,
  Text,
  Tooltip,
  UpsellButton,
  useToast,
} from "@hightouchio/ui";
import { captureException } from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import cloneDeep from "lodash/cloneDeep";
import pluralize from "pluralize";
import { LinkButton, useNavigate } from "src/router";
import { isPresent } from "ts-extras";

import bottomRightGradient from "src/assets/backgrounds/bottom-right-green-gradient.svg";
import topLeftGradient from "src/assets/backgrounds/top-left-blue-gradient.svg";
import genericPlaceholder from "src/assets/placeholders/generic.svg";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { Page } from "src/components/layout";
import { BulkDeleteConfirmationModal } from "src/components/modals/bulk-delete-confirmation-modal";
import { ErrorJsonModal } from "src/components/modals/error-json-modal";
import { SplashPage } from "src/components/splash-page";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import {
  ConnectionsOrderBy,
  OrderBy,
  useBulkDeleteIdentityResolutionGraphsMutation,
  useIdentityResolutionGraphsQuery,
} from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import * as analytics from "src/lib/analytics";
import { newPylonMessage } from "src/lib/pylon";
import { IdentityResolutionIcon } from "src/ui/icons";
import { PageTable, SortOption, useTableSort } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { useRowSelect } from "src/ui/table/use-row-select";
import { commaNumber } from "src/utils/numbers";
import { openUrl } from "src/utils/urls";

import { transformLegacyStatistics } from "./graph";
import { GraphStatusIndicator } from "./graph/graph-status-indicator";
import { IDRSummaryStats } from "./types";
import {
  getLegacySummaryValues,
  getv2SummaryValues,
  GraphVersion,
  GraphVersionEnabled,
} from "./utils";

const initialSort: SortOption<keyof ConnectionsOrderBy> = {
  key: "updated_at",
  direction: OrderBy.Desc,
  label: "Recently updated",
};
const sortOptions: SortOption<keyof ConnectionsOrderBy>[] = [
  { key: "name", direction: OrderBy.Asc, label: "Name A -> Z" },
  { key: "name", direction: OrderBy.Desc, label: "Name Z -> A" },
  initialSort,
  { key: "created_at", direction: OrderBy.Desc, label: "Newest" },
  { key: "created_at", direction: OrderBy.Asc, label: "Oldest" },
];

const placeholder = {
  image: genericPlaceholder,
  title: "No identity graphs",
  body: "Create a new identity graph to start resolving profiles.",
  error: "Identity graphs failed to load, please try again.",
};

export const Content: FC = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { idrGraphVersionEnabled } = useFlags();

  const { selectedRows, onRowSelect } = useRowSelect();
  const [confirmingDelete, setConfirmingDelete] = useState(false);

  const [showVersionSelector, setShowVersionSelector] = useState(false);
  const [runError, setRunError] = useState<
    { graphId: string; message: string } | undefined
  >();
  const orderBy = useTableSort<ConnectionsOrderBy>(initialSort, sortOptions);
  const hasLegacyAndv2GraphsEnabled =
    idrGraphVersionEnabled === GraphVersionEnabled.Bothv2AndLegacy;
  const showLegacyBadge = idrGraphVersionEnabled !== GraphVersionEnabled.Legacy;

  const bulkDeleteIdentityResolutionGraphsMutation =
    useBulkDeleteIdentityResolutionGraphsMutation();

  const {
    data: graphs,
    isLoading,
    error,
  } = useIdentityResolutionGraphsQuery(
    { orderBy },
    {
      select: (data) => {
        return data.idr.map((_graph) => {
          const graph = cloneDeep(_graph);

          graph.runs = graph.runs.map((_run) => {
            const run = cloneDeep(_run);
            if (graph.version === GraphVersion.V2) {
              run.stats = getv2SummaryValues(run.stats);
            } else {
              run.stats = getLegacySummaryValues(
                // Legacy graphs:
                // Transform the stats property of each run inside of each graph to match the newer IdrStats structure.
                transformLegacyStatistics(run.stats),
              );
            }

            return run;
          });

          return graph;
        });
      },
    },
  );

  const clickAddIdentityGraph = () => {
    if (hasLegacyAndv2GraphsEnabled) {
      setShowVersionSelector(true);
    } else {
      navigate("/idr/new");
    }
  };

  const bulkDeleteAudiences = async () => {
    const pluralizedLabel = pluralize(
      "identity graph",
      selectedRows.length,
      true,
    );

    try {
      await bulkDeleteIdentityResolutionGraphsMutation.mutateAsync({
        ids: selectedRows.map(String),
      });

      toast({
        id: "bulk-delete-identity-graphs",
        title: `Deleted ${pluralizedLabel}`,
        variant: "success",
      });

      onRowSelect([]);
    } catch (e) {
      toast({
        id: "bulk-delete-audiences",
        title: `Failed to delete ${pluralizedLabel}`,
        variant: "error",
      });

      captureException(e);
    }
  };

  return (
    <>
      <Page fullWidth sx={{ p: 0 }} title="Identity resolution graphs">
        <PageTable
          header={
            <>
              <Heading isTruncated size="xl">
                Identity resolution
              </Heading>
              <ButtonGroup size="sm">
                {selectedRows.length > 0 && (
                  <Row align="center" flexShrink={0} gap={2}>
                    <Text>{`${pluralize(
                      "identity graph",
                      selectedRows.length,
                      true,
                    )} selected`}</Text>
                    <Menu>
                      <MenuButton>Actions</MenuButton>
                      <MenuList>
                        <MenuItem
                          icon={DeleteIcon}
                          variant="danger"
                          onClick={() => {
                            setConfirmingDelete(true);
                          }}
                        >
                          Delete
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Row>
                )}
                <Button variant="primary" onClick={clickAddIdentityGraph}>
                  Add identity graph
                </Button>
              </ButtonGroup>
            </>
          }
          columns={[
            {
              name: "Identity graph",
              min: "160px",
              cell: ({ name, source, version }) => (
                <Row gap={2} overflow="hidden">
                  <Tooltip message={source.name}>
                    <Row flexShrink={0}>
                      <IntegrationIcon
                        src={source.definition.icon}
                        name={source.definition.name}
                      />
                    </Row>
                  </Tooltip>
                  <TextWithTooltip fontWeight="medium">
                    {name ?? "Private graph"}
                  </TextWithTooltip>
                  {showLegacyBadge && version !== GraphVersion.V2 && (
                    <Row align="center" flexShrink={0}>
                      <Badge size="sm">Legacy</Badge>
                    </Row>
                  )}
                </Row>
              ),
            },
            {
              name: "Last run",
              max: "min-content",
              cellSx: { overflow: "hidden" },
              cell: ({ runs }) => {
                return (
                  <GraphStatusIndicator
                    showTimeOnlyOnExtraLargeScreens
                    lastRun={runs[0]}
                  />
                );
              },
            },
            {
              name: "Unique profiles",
              min: "min-content",
              max: "min-content",
              cellSx: { div: { width: "100%", textAlign: "right" } },
              cell: ({ runs }) => {
                const lastRunStats: IDRSummaryStats | undefined =
                  runs[0]?.stats;

                if (!lastRunStats || !isPresent(lastRunStats.totalHtIds)) {
                  return (
                    <TextWithTooltip color="text.secondary" fontWeight="medium">
                      --
                    </TextWithTooltip>
                  );
                }

                return (
                  <TextWithTooltip fontWeight="medium">
                    {commaNumber(lastRunStats.totalHtIds)}
                  </TextWithTooltip>
                );
              },
            },
            {
              name: "Source rows",
              min: "min-content",
              max: "min-content",
              cellSx: { div: { width: "100%", textAlign: "right" } },
              cell: ({ runs }) => {
                const lastRunStats: IDRSummaryStats | undefined =
                  runs[0]?.stats;

                if (!lastRunStats || !isPresent(lastRunStats.totalSrcRows)) {
                  return (
                    <TextWithTooltip color="text.secondary" fontWeight="medium">
                      --
                    </TextWithTooltip>
                  );
                }

                return (
                  <TextWithTooltip fontWeight="medium">
                    {commaNumber(lastRunStats.totalSrcRows)}
                  </TextWithTooltip>
                );
              },
            },
            {
              ...LastUpdatedColumn,
              breakpoint: "md",
            },
            {
              name: "",
              max: "min-content",
              cell: ({ id, runs }) => {
                const lastRun = runs[0];
                const error = lastRun?.error?.error;
                if (error) {
                  return (
                    <Button
                      size="sm"
                      ml="auto"
                      variant="warning"
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setRunError({ graphId: id, message: error });
                      }}
                    >
                      View error
                    </Button>
                  );
                }

                return null;
              },
            },
          ]}
          data={graphs}
          error={Boolean(error)}
          loading={isLoading}
          placeholder={placeholder}
          onRowClick={({ id }, event) => openUrl(`/idr/${id}`, navigate, event)}
          selectedRows={selectedRows}
          onSelect={onRowSelect}
          sortOptions={sortOptions}
        />
      </Page>

      <BulkDeleteConfirmationModal
        count={selectedRows.length}
        isOpen={confirmingDelete}
        label="identity graph"
        content={
          <Column gap={2}>
            <Paragraph>
              Are you sure you want to delete {selectedRows.length}{" "}
              {pluralize("identity graph", selectedRows.length)}? You will not
              be able to undo this.
            </Paragraph>
          </Column>
        }
        onClose={() => setConfirmingDelete(false)}
        onDelete={bulkDeleteAudiences}
      />

      <VersionSelector
        isOpen={showVersionSelector}
        onClose={() => setShowVersionSelector(false)}
      />

      <ErrorJsonModal
        title="Identity graph error"
        error={runError?.message}
        pylonMessage={`I'm experiencing an issue with my identity graph and could use some assistance. The error message I'm receiving is: "${runError?.message}". Here's a link to the graph: <a href="${window.location.href}/${runError?.graphId}">${window.location.href}/${runError?.graphId}</a>.`}
        onClose={() => setRunError(undefined)}
      />
    </>
  );
};

export const IdentityResolutionGraphs = () => {
  const { data: entitlementsData } = useEntitlements(false);
  const showIdr = entitlementsData.entitlements.idr;

  const unlockIdentityResolution = () => {
    newPylonMessage("Hi, I'd like to unlock Identity Resolution!");
  };

  const trackLearnMoreClick = () => {
    analytics.track("Identity Resolution Learn More Clicked");
  };

  if (showIdr) {
    return <Content />;
  }

  return (
    <SplashPage
      // hideLogos
      icon={<IdentityResolutionIcon />}
      eyebrow="Identity Resolution"
      heading="Create 360° user profiles directly in your warehouse"
      description="Stitch your existing customer data into rich, actionable profiles directly in your data warehouse without writing a single line of code."
      actions={
        <>
          <UpsellButton onClick={unlockIdentityResolution}>
            Unlock Identity Resolution
          </UpsellButton>
          <LinkButton
            href="https://hightouch.com/platform/identity-resolution"
            onClick={trackLearnMoreClick}
          >
            Learn more
          </LinkButton>
        </>
      }
      visual={
        <Box
          as="video"
          autoPlay
          loop
          muted
          playsInline
          poster="https://cdn.sanity.io/images/pwmfmi47/production/ffb3feb8e73dcbef1b96adc3c7998c67e62bff09-1470x1232.webp"
          src="https://cdn.sanity.io/files/pwmfmi47/production/3e5c582e5f6d4844bd9610cb44c05b2e7048ef2c.mp4"
          maxHeight="100%"
          width="100%"
        />
      }
      backgroundGradient={
        <>
          <Box
            as="img"
            position="absolute"
            top={0}
            left={0}
            src={topLeftGradient}
          />
          <Box
            as="img"
            position="absolute"
            bottom={0}
            right={0}
            src={bottomRightGradient}
          />
        </>
      }
    />
  );
};

const VersionSelector = ({ isOpen, onClose }) => {
  const [version, setVersion] = useState(2);

  return (
    <Dialog
      isOpen={isOpen}
      title="Choose a version use for this identity graph"
      variant="form"
      actions={
        <ButtonGroup>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <LinkButton href={`/idr/new?version=${version}`} variant="primary">
            Continue
          </LinkButton>
        </ButtonGroup>
      }
      onClose={onClose}
    >
      <RadioGroup orientation="vertical" value={version} onChange={setVersion}>
        <Radio
          value={2}
          label="New"
          badge={
            <Badge size="sm" variant="primary">
              Recommended
            </Badge>
          }
          description="Performance-optimized matching with future support for survivorship rules"
        />
        <Radio
          value={1}
          label="Legacy"
          description="Complex boolean matching (will be deprecated)"
        />
      </RadioGroup>
    </Dialog>
  );
};
