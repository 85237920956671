import { FC, ReactNode, useEffect, useState } from "react";

import {
  Box,
  ChakraModalBody,
  ChakraModalContent,
  ChakraModalHeader,
  CloseIcon,
  Column,
  Heading,
  IconButton,
  Row,
  Tab,
  TabList,
  Tabs,
  Text,
  useToast,
} from "@hightouchio/ui";
import { Link } from "src/router";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useQueryClient } from "react-query";

import GrafanaLogo from "src/components/logos/grafana.svg";
import NbaLogo from "src/components/logos/nba.svg";
import NgrokLogo from "src/components/logos/ngrok.svg";
import { PricingCard } from "src/components/pricing/pricing-card";
import { useUser } from "src/contexts/user-context";
import { BillingOrganizationQuery, useCheckoutUrlQuery } from "src/graphql";
import * as analytics from "src/lib/analytics";

import { AddOrganizationNameModal } from "../modals/add-organization-name-modal";

type Organization = BillingOrganizationQuery["getBillingOrganization"];
interface Props {
  organization: Organization | undefined;
  onClose: () => void;
}

enum BillingIntervals {
  MONTHLY = "monthly",
  YEARLY = "yearly",
}

const TABS = [BillingIntervals.YEARLY, BillingIntervals.MONTHLY];

export const PricingDisplay: FC<Readonly<Props>> = ({
  organization,
  onClose,
}) => {
  const { workspace } = useUser();
  const { toast } = useToast();
  const client = useQueryClient();
  const [addOrgNameState, setAddOrgNameState] = useState<
    "closed" | "open" | "loading"
  >("closed");
  const [selectedSku, setSelectedCta] = useState<string | undefined>();
  const [loadingSku, setLoadingSku] = useState<string | undefined>();
  const [isIntervalMonthly, setInterval] = useState(
    Boolean(organization?.plan?.sku?.endsWith("month")),
  );
  const [tabIndex, setTabIndex] = useState<number>(0);

  const { pricingProPlan } = useFlags();
  const pricingPlans = getPricingPlans({
    isIntervalMonthly,
    showProPlan: pricingProPlan,
  });

  useEffect(() => {
    setInterval(Boolean(organization?.plan?.sku?.endsWith("month")));
  }, [organization]);

  const changeInterval = (value: number) => {
    analytics.track("Annual/Monthly Pricing Switched", {
      workspace_id: workspace?.id,
      viewing: TABS[value],
    });
    setTabIndex(value);
    setInterval(TABS[value] === BillingIntervals.MONTHLY);
  };

  function ctaClick(sku: string) {
    analytics.track("Plan Checkout Clicked", {
      workspace_id: workspace?.id,
      sku,
    });
    const plan = pricingPlans.find((pricingPlan) => pricingPlan.sku === sku);
    if (plan?.ctaUrl) {
      return window.open(plan.ctaUrl, "_blank");
    }
    if (organization) {
      setLoadingSku(sku);
      return goToCheckout(sku, organization.name, () => {
        setLoadingSku(undefined);
      });
    } else {
      setSelectedCta(sku);
      return setAddOrgNameState("open");
    }
  }

  const goToCheckout = async (
    sku: string,
    orgName: string,
    fallback: () => void,
  ) => {
    const variables = {
      lookup_key: sku,
      workspace_id: workspace?.id,
      org_name: orgName,
    };

    analytics.track("Sending To Checkout", variables);

    try {
      const { getCheckoutUrl } = await client.fetchQuery(
        useCheckoutUrlQuery.getKey(variables),
        useCheckoutUrlQuery.fetcher(variables),
      );

      if (getCheckoutUrl.redirect) {
        location.href = getCheckoutUrl.redirect;
      } else {
        toast({
          id: "go-to-checkout",
          title: "Couldn't generate checkout URL",
          variant: "error",
        });

        analytics.track("Error Generating Checkout", variables);
        fallback();
      }
    } catch (e) {
      toast({
        id: "go-to-checkout",
        title: e.message,
        variant: "error",
      });

      analytics.track("Error Generating Checkout", variables);
      fallback();
    }
  };

  return (
    <ChakraModalContent height="90%" maxH="100%" my="auto">
      <ChakraModalHeader
        flexDir="column"
        alignItems="stretch"
        justifyContent="start"
        w="100%"
      >
        <Box position="absolute" right={4} top={4}>
          <IconButton
            aria-label="Close pricing modal"
            icon={CloseIcon}
            onClick={onClose}
          ></IconButton>
        </Box>
        <Column mb={2}>
          <Heading size="xl">Plans</Heading>
          <Text fontWeight="semibold" color="text.secondary">
            Select a plan that best fits your needs.
          </Text>
        </Column>
        <Tabs index={tabIndex} onChange={changeInterval}>
          <TabList>
            <Tab>
              <Row alignItems="center">
                Yearly&nbsp;
                <Text color="ocean.base">(save 20%)</Text>
              </Row>
            </Tab>
            <Tab>Monthly</Tab>
          </TabList>
        </Tabs>
      </ChakraModalHeader>
      <ChakraModalBody>
        <Row align="stretch" gap={4} justify="center">
          {pricingPlans?.map((card) => {
            return (
              <PricingCard
                key={card.sku}
                checkList={card.checkList}
                checkListHeading={card.checkListHeading}
                ctaDisabled={card.sku === organization?.plan?.sku}
                ctaLoading={loadingSku === card.sku}
                ctaText={
                  card.sku === organization?.plan?.sku
                    ? "Current plan"
                    : card.ctaText
                }
                ctaUrl={card.ctaUrl}
                partnerLogos={card.partnerLogos}
                plan={card.plan}
                price={card.price}
                priceHint={card.priceHint}
                priceUnit={card.priceUnit}
                sku={card.sku}
                onCtaClick={ctaClick}
              />
            );
          })}
        </Row>
      </ChakraModalBody>

      <AddOrganizationNameModal
        isLoading={addOrgNameState === "loading"}
        isOpen={addOrgNameState !== "closed"}
        onCancel={() => {
          analytics.track("Org Name Modal Canceled", {
            workspace_id: workspace?.id,
          });
          setAddOrgNameState("closed");
        }}
        onSubmit={(orgName) => {
          setAddOrgNameState("loading");
          analytics.track("Org Name Entered", {
            workspace_id: workspace?.id,
            orgName,
          });

          if (selectedSku) {
            goToCheckout(selectedSku, orgName, () =>
              setAddOrgNameState("open"),
            );
          }
        }}
      />
    </ChakraModalContent>
  );
};

export interface PricingPlan {
  plan: string;
  sku: string;
  price: string;
  priceUnit?: string;
  priceHint?: string;
  checkList: (string | ReactNode)[];
  checkListHeading?: string;
  partnerLogos: { img: string; alt: string }[];
  ctaText: string;
  ctaUrl?: string;
}

export const getPricingPlans = ({
  isIntervalMonthly,
  showProPlan,
}: {
  isIntervalMonthly: boolean;
  showProPlan?: boolean;
}): PricingPlan[] => {
  const plans = [
    {
      plan: "Starter",
      sku: isIntervalMonthly ? "starter_plan_month" : "starter_plan_year",
      ctaText: "Select plan",
      price: isIntervalMonthly ? "$450" : "$350",
      priceUnit: isIntervalMonthly ? " / month" : " / month*",
      priceHint: isIntervalMonthly ? "" : "*Billed annually",
      checkListHeading: "Included:",
      checkList: [
        "2 billable destinations",
        "Up to 5 active syncs per billable destination",
        "Unlimited 30-day trials of any additional destination",
        <Text key="starter-1">
          25 free syncs to lite destinations (Slack, Google Sheets,{" "}
          <Link href="https://hightouch.com/docs/pricing/ss-pricing#lite-destinations">
            and more
          </Link>
          )
        </Text>,
        "Unlimited data fields",
        "Unlimited sources",
        "Unlimited source rows",
        "5 user seats",
      ],
      partnerLogos: [{ img: GrafanaLogo, alt: "Grafana logo." }],
    },
    {
      sku: "",
      plan: "Enterprise",
      ctaText: "Talk to us",
      ctaUrl: "https://hightouch.com/demo?ref=/pricing",
      price: "Custom pricing",
      priceHint: isIntervalMonthly ? "" : "Consult with a solutions specialist",
      checkListHeading: `Everything in the ${
        showProPlan ? "Pro" : "Starter"
      } plan, plus:`,
      checkList: [
        "Custom # of destinations",
        "Custom # of syncs",
        <Text key="enterprise-1">
          Premium destinations: NetSuite, SFMC,{" "}
          <Link href="https://hightouch.com/docs/pricing/ss-pricing#premium-destinations">
            and more
          </Link>
        </Text>,
        "Audience builder (add on)",
        "Granular RBAC and label-based access controls",
        "SAML SSO (Okta, Azure AD, Auth0, OneLogin, PingIdentity)",
        "Git version control",
        "Approval flows and drafts",
        "Real-time syncing (sub-hourly)",
        "Bring-your-own storage for sync planning",
        "Warehouse sync logs",
        "Unlimited user seats",
        "Dedicated solutions architect",
        "White glove onboarding",
        "Custom procurement and SLAs",
        "Audit logs",
        "Fivetran sync scheduler",
      ],
      partnerLogos: [{ img: NbaLogo, alt: "N.B.A. logo." }],
    },
  ];

  if (showProPlan) {
    const proPlan = {
      plan: "Pro",
      sku: isIntervalMonthly ? "pro_plan_month" : "pro_plan_year",
      ctaText: "Select plan",
      price: isIntervalMonthly ? "$1,000" : "$800",
      priceUnit: isIntervalMonthly ? " / month" : " / month*",
      priceHint: isIntervalMonthly ? "" : "*Billed annually",
      checkListHeading: "Included:",
      checkList: [
        "4 billable destinations",
        "Up to 5 active syncs per billable destination",
        "Unlimited 30-day trials of any additional destination",
        <Text key="pro-1">
          25 free syncs to lite destinations (Slack, Google Sheets,{" "}
          <Link href="https://hightouch.com/docs/pricing/ss-pricing#lite-destinations">
            and more
          </Link>
          )
        </Text>,
        "Unlimited data fields",
        "Unlimited sources",
        "Unlimited source rows",
        "5 user seats",
        "Custom alerting",
      ],
      partnerLogos: [{ img: NgrokLogo, alt: "Ngrok logo." }],
    };

    plans.splice(1, 0, proPlan);
  }
  return plans;
};

export const pricingPlans = [
  ...getPricingPlans({ isIntervalMonthly: false, showProPlan: true }),
  ...getPricingPlans({ isIntervalMonthly: true, showProPlan: true }),
];
