import * as Yup from "yup";
import {
  BooleanType,
  Checkbox,
  ContextReference,
  Field,
  Form,
  Input,
  RadioGroup,
  Secret,
  Section,
  Select,
  ShowModifier,
  UnaryBooleanOperator,
  showIfKeyEqualsValue,
  showIfKeyIsOneOf,
} from "@hightouch/formkit";
import { FieldMappingSection } from "./field-mapping";

const ConnectionSection = Section({
  children: [
    ShowModifier({
      condition: {
        type: BooleanType.Unary,
        operator: UnaryBooleanOperator.Not,
        operand: ContextReference({ key: "tunnel" }),
      },
      children: [
        Field({
          heading: "Broker URL",
          subheading:
            "The URL of the Kafka broker(s) to connect to. Use commas to separate multiple URLs.",
          component: Input("brokers", {
            validation: Yup.string().required(),
            placeholder: "broker:9092",
          }),
        }),
      ],
    }),

    Field({
      heading: "Topic",
      subheading:
        "The topic to consume events from, must contain properly formatted JSON strings.",
      component: Input("topic", {
        validation: Yup.string().required(),
        placeholder: "my-topic",
      }),
    }),

    Field({
      heading: "DLQ Topic",
      subheading:
        "Topic that Hightouch will publish messages back to if it encounters a fatal error while processing a message. If omitted, Hightouch will drop messages that encounter processing errors.",
      component: Input("dlqTopic", {
        validation: Yup.string(),
        placeholder: "error-topic",
      }),
      optional: true,
    }),

    Field({
      heading: "Authentication Method",
      component: RadioGroup("authMethod", {
        default: "sasl",
        options: [
          {
            value: "sasl",
            label: "SASL (recommended)",
          },
          {
            value: "none",
            label: "None",
          },
        ],
        validation: Yup.string().oneOf(["sasl", "none"]).required(),
      }),
    }),

    showIfKeyEqualsValue({
      key: "authMethod",
      value: "sasl",
      children: [
        Field({
          heading: "Mechanism",
          subheading:
            "How should Hightouch authenticate to your Kafka cluster?",
          component: Select("mechanism", {
            default: "plain",
            options: [
              { label: "PLAIN", value: "plain" },
              { label: "SCRAM-SHA-256", value: "scram-sha-256" },
              { label: "SCRAM-SHA-512", value: "scram-sha-512" },
              { label: "AWS IAM", value: "aws" },
            ],
            validation: Yup.string()
              .oneOf(["plain", "scram-sha-256", "scram-sha-512", "aws"])
              .required(),
          }),
        }),

        showIfKeyIsOneOf({
          key: "mechanism",
          values: ["plain", "scram-sha-256", "scram-sha-512"],
          children: [
            Field({
              heading: "Username",
              component: Input("username", {
                type: "text",
                validation: Yup.string().required(),
                placeholder: "kafka-user",
              }),
            }),

            Field({
              heading: "Password",
              component: Secret("password", {
                type: "password",
                validation: Yup.string().required(),
                placeholder: "kafka-password",
              }),
            }),
          ],
        }),

        showIfKeyEqualsValue({
          key: "mechanism",
          value: "aws",
          children: [
            Field({
              heading: "Authorization identity",
              subheading: "The unique identifier for IAM authorization.",
              component: Input("authorizationIdentity", {
                validation: Yup.string().required(),
              }),
            }),

            Field({
              heading: "Access key ID",
              component: Input("accessKeyId", {
                validation: Yup.string().required(),
                placeholder: "AWS_ACCESS_KEY_ID",
              }),
            }),

            Field({
              heading: "Secret access key",
              component: Secret("secretAccessKey", {
                type: "password",
                validation: Yup.string().required(),
                placeholder: "AWS_SECRET_ACCESS_KEY",
              }),
            }),

            Field({
              heading: "Session token",
              subheading:
                "The optional session token for temporary credentials.",
              component: Secret("sessionToken", {
                validation: Yup.string().optional(),
              }),
              optional: true,
            }),
          ],
        }),

        Field({
          heading: "SSL",
          subheading: "Connect to Kafka using SSL.",
          component: Checkbox("ssl", {
            label: "Enabled",
            validation: Yup.boolean(),
            default: true,
          }),
        }),
      ],
    }),
  ],
});

export const KafkaForm = Form({
  children: [ConnectionSection, FieldMappingSection],
});
