import { FC, ReactNode } from "react";

import {
  Column,
  ErrorIcon,
  ExternalLinkIcon,
  Row,
  SkeletonBox,
  SubtractIcon,
  SuccessIcon,
  Text,
  Tooltip,
  WarningIcon,
} from "@hightouchio/ui";
import { Link as UiLink } from "src/router";
import { formatDistance, parseISO } from "date-fns";
import { times } from "lodash";

import {
  ExtensionHealthCheckCategory,
  ExtensionHealthCheckNames,
  ExtensionHealthCheckStatus,
  ExtensionHealthQuery,
  useExtensionHealthQuery,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import {
  AlertingIcon,
  DatadogIcon,
  DBTIcon,
  FivetranIcon,
  GitIcon,
  LookerIcon,
  SigmaIcon,
} from "src/ui/icons";
import { Card } from "src/components/card";
import { DashboardSidebarItem } from "./dashboard-sidebar-item";

const extensionToIcon: Record<ExtensionHealthCheckNames, ReactNode> = {
  [ExtensionHealthCheckNames.DbtCloud]: <DBTIcon size={16} />,
  [ExtensionHealthCheckNames.DbtExposures]: <DBTIcon size={16} />,
  [ExtensionHealthCheckNames.DbtModelSync]: <DBTIcon size={16} />,
  [ExtensionHealthCheckNames.DbtCommitChecks]: <DBTIcon size={16} />,
  [ExtensionHealthCheckNames.Fivetran]: <FivetranIcon size={16} />,
  [ExtensionHealthCheckNames.Sigma]: <SigmaIcon size={16} />,
  [ExtensionHealthCheckNames.GitSync]: <GitIcon size={16} />,
  [ExtensionHealthCheckNames.GitSyncCommitChecks]: <GitIcon size={16} />,
  [ExtensionHealthCheckNames.Looker]: <LookerIcon size={16} />,
  [ExtensionHealthCheckNames.SyncAlerts]: (
    <AlertingIcon height="16px" width="16px" />
  ),
  [ExtensionHealthCheckNames.Datadog]: (
    <DatadogIcon height="16px" width="16px" />
  ),
};

const extensionToLink: Record<ExtensionHealthCheckNames, string> = {
  [ExtensionHealthCheckNames.DbtCloud]: "/dbt-cloud/configuration",
  [ExtensionHealthCheckNames.DbtExposures]: "/dbt-models/configuration",
  [ExtensionHealthCheckNames.DbtModelSync]: "/dbt-models/configuration",
  [ExtensionHealthCheckNames.DbtCommitChecks]: "/dbt-models/configuration",
  [ExtensionHealthCheckNames.Fivetran]: "/fivetran/configuration",
  [ExtensionHealthCheckNames.Sigma]: "/sigma/",
  [ExtensionHealthCheckNames.GitSync]: "/git-sync/configuration",
  [ExtensionHealthCheckNames.GitSyncCommitChecks]: "/git-sync/configuration",
  [ExtensionHealthCheckNames.Looker]: "/looker/configuration",
  [ExtensionHealthCheckNames.SyncAlerts]: "/alerting/configuration",
  [ExtensionHealthCheckNames.Datadog]: "/monitoring/configuration",
};

const statusToColor: Record<ExtensionHealthCheckStatus, string> = {
  [ExtensionHealthCheckStatus.Healthy]: "success.base",
  [ExtensionHealthCheckStatus.Warning]: "warning.base",
  [ExtensionHealthCheckStatus.Failing]: "danger.base",
  [ExtensionHealthCheckStatus.Unconfigured]: "gray.base",
  [ExtensionHealthCheckStatus.Disabled]: "gray.base",
};

const statusToIcon: Record<ExtensionHealthCheckStatus, ReactNode> = {
  [ExtensionHealthCheckStatus.Healthy]: (
    <SuccessIcon color={statusToColor[ExtensionHealthCheckStatus.Healthy]} />
  ),
  [ExtensionHealthCheckStatus.Warning]: (
    <WarningIcon color={statusToColor[ExtensionHealthCheckStatus.Warning]} />
  ),
  [ExtensionHealthCheckStatus.Failing]: (
    <ErrorIcon color={statusToColor[ExtensionHealthCheckStatus.Failing]} />
  ),
  [ExtensionHealthCheckStatus.Unconfigured]: (
    <SubtractIcon
      color={statusToColor[ExtensionHealthCheckStatus.Unconfigured]}
    />
  ),
  [ExtensionHealthCheckStatus.Disabled]: (
    <SubtractIcon color={statusToColor[ExtensionHealthCheckStatus.Disabled]} />
  ),
};

const categoryToName: Record<ExtensionHealthCheckCategory, string> = {
  [ExtensionHealthCheckCategory.Dbt]: "dbt",
  [ExtensionHealthCheckCategory.DbtCloud]: "dbt Cloud",
  [ExtensionHealthCheckCategory.Fivetran]: "Fivetran",
  [ExtensionHealthCheckCategory.GitSync]: "Git",
  [ExtensionHealthCheckCategory.Looker]: "Looker",
  [ExtensionHealthCheckCategory.Alerts]: "Alerting",
  [ExtensionHealthCheckCategory.Sigma]: "Sigma",
  [ExtensionHealthCheckCategory.Monitoring]: "Datadog",
};

const extensionToName: Record<ExtensionHealthCheckNames, string> = {
  [ExtensionHealthCheckNames.DbtCloud]: "dbt Cloud",
  [ExtensionHealthCheckNames.DbtExposures]: "dbt Exposures",
  [ExtensionHealthCheckNames.DbtModelSync]: "dbt model sync",
  [ExtensionHealthCheckNames.DbtCommitChecks]: "dbt commit checks",
  [ExtensionHealthCheckNames.Fivetran]: "Fivetran",
  [ExtensionHealthCheckNames.Sigma]: "Sigma",
  [ExtensionHealthCheckNames.GitSync]: "Git sync",
  [ExtensionHealthCheckNames.GitSyncCommitChecks]: "Git sync commit checks",
  [ExtensionHealthCheckNames.Looker]: "Looker",
  [ExtensionHealthCheckNames.SyncAlerts]: "Alerting",
  [ExtensionHealthCheckNames.Datadog]: "Datadog",
};

const ExtensionElement: FC<{
  extension: NonNullable<
    ExtensionHealthQuery["getWorkspaceExtensionHealth"]
  >[0];
  category?: boolean;
}> = ({ extension, category }) => {
  const normalizedName = extensionToName[extension.extension];
  const normalizedCategory = category
    ? categoryToName[extension.category]
    : null;

  return (
    <Card
      p={0}
      href={`/extensions${extensionToLink[extension.extension]}`}
      onClick={() => {
        analytics.track("[App Dashboard] Sidebar Extension Item Clicked", {
          extension: extension.extension,
          extensionStatus: extension.status,
        });
      }}
    >
      <Row justifyContent="space-between" alignItems="center" px={4} py={3}>
        <Row gap={2} alignItems="center">
          {extensionToIcon[extension.extension]}
          <Text
            fontWeight="medium"
            color={
              extension.status !== ExtensionHealthCheckStatus.Healthy
                ? statusToColor[extension.status]
                : undefined
            }
          >
            {normalizedCategory || normalizedName}
          </Text>
        </Row>

        <Tooltip
          isDisabled={!extension.lastStatusCheckTime}
          message={
            extension.lastStatusCheckTime
              ? "Last checked " +
                formatDistance(
                  parseISO(extension.lastStatusCheckTime),
                  new Date(),
                  { addSuffix: true },
                )
              : ""
          }
        >
          <Row sx={{ svg: { boxSize: "16px" } }}>
            {statusToIcon[extension.status]}
          </Row>
        </Tooltip>
      </Row>
    </Card>
  );
};

export const ExtensionHealthItem: FC = () => {
  const extensionsByCategory: Record<
    ExtensionHealthCheckCategory,
    NonNullable<ExtensionHealthQuery["getWorkspaceExtensionHealth"]>[0][]
  > = Object.values(ExtensionHealthCheckCategory).reduce(
    (acc, category) => {
      acc[category] = [];
      return acc;
    },
    {} as Record<
      ExtensionHealthCheckCategory,
      NonNullable<ExtensionHealthQuery["getWorkspaceExtensionHealth"]>[0][]
    >,
  );

  const { isLoading } = useExtensionHealthQuery(
    {},
    {
      refetchOnMount: "always",
      select(data) {
        const extensions = data.getWorkspaceExtensionHealth
          ?.filter((e) => e.status !== ExtensionHealthCheckStatus.Unconfigured)
          .filter(
            (e) =>
              e.extension !== ExtensionHealthCheckNames.DbtCommitChecks &&
              e.extension !== ExtensionHealthCheckNames.GitSyncCommitChecks,
          )
          .sort((ext1, ext2) => ext1.extension.localeCompare(ext2.extension));
        if (!extensions) return [];
        for (const extension of extensions) {
          extensionsByCategory[extension.category].push(extension);
        }
        return extensions;
      },
    },
  );

  const extensions = Object.values(extensionsByCategory).filter(
    (exts) => exts.length > 0,
  );

  return (
    <DashboardSidebarItem
      title="Extensions"
      header={
        <UiLink href="/extensions">
          View all <ExternalLinkIcon />
        </UiLink>
      }
      isLoading={isLoading}
    >
      <Column gap={2}>
        {extensions?.length ? (
          extensions.map((exts, idx) => {
            // We 'roll up' extensions into their category
            const unhealthyExtensions =
              exts?.filter(
                (ext) => ext.status !== ExtensionHealthCheckStatus.Healthy,
              ) || [];
            if (unhealthyExtensions.length > 0 && unhealthyExtensions[0])
              return (
                <ExtensionElement
                  key={idx}
                  extension={unhealthyExtensions[0]}
                  category
                />
              );
            if (exts.length > 0 && exts[0])
              return (
                <ExtensionElement key={idx} extension={exts[0]} category />
              );
            return null;
          })
        ) : isLoading ? (
          times(5, (idx) => <ExtensionSkeleton key={idx} />)
        ) : (
          <Text color="text.secondary">No extensions configured</Text>
        )}
      </Column>
    </DashboardSidebarItem>
  );
};

const ExtensionSkeleton: FC = () => {
  return (
    <Row gap={2} alignContent="start" p={2}>
      <SkeletonBox width="24px" height="24px" borderRadius="full" />
      <Column gap={1}>
        <SkeletonBox height="20px" width="180px" />
        <SkeletonBox height="16px" width="120px" />
      </Column>
    </Row>
  );
};
