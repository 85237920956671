import { FC } from "react";
import { Box, BoxProps, Tab, TabList, Tabs } from "@hightouchio/ui";
import { RouterLink, useLocation } from "src/router";

export type RouteTab = {
  path: string;
  title: React.ReactNode;
  isDisabled?: boolean;
  isHidden?: boolean;
  count?: number;
};

type Props = BoxProps & {
  tabs: Array<RouteTab>;
  depth?: number;
};

export const useTabState = (tabs: Array<RouteTab>, depth = 3) => {
  const location = useLocation();
  const pathTabs = location.pathname.split("/");

  const visibleTabs = tabs.filter((tab) => !tab.isHidden);

  const activeTabIndex = visibleTabs.findIndex((tab) =>
    tab.path === "" ? pathTabs.length === depth : tab.path === pathTabs[depth],
  );

  const activeTab = visibleTabs[activeTabIndex];

  return {
    visibleTabs,
    activeTab,
    activeTabIndex,
  };
};

export const RouteTabs: FC<Readonly<Props>> = ({
  tabs,
  depth = 3,
  ...props
}) => {
  const { visibleTabs, activeTabIndex } = useTabState(tabs, depth);

  return (
    <Tabs index={activeTabIndex} onChange={() => null}>
      <Box as={TabList} {...props}>
        {visibleTabs.map((tab, index) => (
          <RouterLink
            preload={index === activeTabIndex ? undefined : "mount"}
            key={tab.path === "" ? "index" : tab.path}
            to={{ pathname: tab.path, search: location.search }}
          >
            <Tab isDisabled={tab.isDisabled} count={tab.count}>
              {tab.title}
            </Tab>
          </RouterLink>
        ))}
      </Box>
    </Tabs>
  );
};
