import algoliasearch from "algoliasearch/lite";
import { InstantSearch, Configure } from "react-instantsearch-hooks-web";

import { InsightsMiddleware } from "./search-insights";

const searchClient = algoliasearch(
  import.meta.env.VITE_ALGOLIA_INTEGRATIONS_APP_ID as string,
  import.meta.env.VITE_ALGOLIA_INTEGRATIONS_SEARCH_API_KEY as string,
);

export const SearchProvider = ({
  children,
  filters = "type:destination AND NOT status:deprecated",
}) => {
  return (
    <InstantSearch
      indexName={`${import.meta.env.VITE_ALGOLIA_INDEX}`}
      searchClient={searchClient}
    >
      <Configure
        //@ts-expect-error TODO what's going on here?
        distinct
        exactOnSingleWordQuery="none"
        filters={filters}
        hitsPerPage={200}
        page={0}
      />
      <InsightsMiddleware>{children}</InsightsMiddleware>
    </InstantSearch>
  );
};
