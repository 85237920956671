import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import { Box } from "@hightouchio/ui";
import { SuggestionProps } from "@tiptap/suggestion";

type OptionListProps = SuggestionProps<any>;

export const OptionsList = forwardRef<any, OptionListProps>((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = (index) => {
    const item = props.items[index];

    if (item) {
      props.command({ id: item.value, label: item.label });
    }
  };

  const upHandler = () => {
    setSelectedIndex(
      (selectedIndex + props.items.length - 1) % props.items.length,
    );
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === "ArrowUp") {
        upHandler();
        return true;
      }

      if (event.key === "ArrowDown") {
        downHandler();
        return true;
      }

      if (event.key === "Enter") {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  return (
    <Box pos="relative" p={1} background="base.lightBackground">
      {props.items?.map((item, index) => (
        <Box
          key={index}
          cursor="pointer"
          display="block"
          m={0}
          p={1}
          textAlign="left"
          borderRadius="lg"
          width="100%"
          borderColor="base.background"
          border={index === selectedIndex ? "solid" : "none"}
          onClick={(e) => {
            selectItem(index);
            e?.preventDefault();
          }}
          onPointerOver={() => {
            setSelectedIndex(index);
          }}
        >
          {item?.label}
        </Box>
      ))}
    </Box>
  );
});

OptionsList.displayName = "OptionsList";
