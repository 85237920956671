import { FC } from "react";

import { Tooltip, Box, EyeIcon, BoxProps } from "@hightouchio/ui";

import { useUser } from "src/contexts/user-context";
import { DraftOperation, DraftsQuery } from "src/graphql";

import { DraftCircle } from "./draft-circle";

type DraftIconProps = {
  sx?: BoxProps["sx"];
  draft: DraftsQuery["drafts"][0];
};

export const DraftIcon: FC<DraftIconProps> = ({ sx = {}, draft }) => {
  const { user } = useUser();

  if (draft.operation === DraftOperation.Create) {
    return null;
  }

  if (
    draft.approval_requests.some(
      ({ user_id: id }) => String(id) === String(user?.id),
    )
  ) {
    return (
      <Tooltip
        placement="right"
        message={`Your approval has been requested on changes to this ${draft.resource_type}.`}
      >
        <Box as={EyeIcon} color="warning.border" fontSize="16px" />
      </Tooltip>
    );
  }

  return (
    <Tooltip
      placement="right"
      message={`Draft changes have been made to this ${draft.resource_type}.`}
    >
      <DraftCircle sx={sx} />
    </Tooltip>
  );
};
