import { FC } from "react";

import {
  ErrorOriginInfo,
  isDestinationErrorOriginInfo,
  isSourceErrorOriginInfo,
} from "@hightouch/lib/sync/error-origin-types";
import { Tooltip, Row, ArrowRightIcon, Box, Badge } from "@hightouchio/ui";

import { Maybe } from "src/graphql";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { LinkWithTooltip } from "src/components/link-with-tooltip";

export type Props = {
  destination:
    | Maybe<{
        id: string;
        name: Maybe<string>;
        definition: {
          icon: string;
          name: string;
        };
      }>
    | undefined;
  originInfo: ErrorOriginInfo;
  source:
    | Maybe<{
        id: string;
        name: string;
        definition: {
          icon: string;
          name: string;
        };
      }>
    | undefined;
  isNonEnrichedRowError?: boolean; // XXX: temp, remove later
  disableRedirectToResources?: boolean;
};

export const ErrorSyncName: FC<Readonly<Props>> = ({
  destination,
  originInfo,
  source,
  isNonEnrichedRowError,
  disableRedirectToResources = false,
}) => {
  const isDestinationError = isDestinationErrorOriginInfo(originInfo);
  const isSourceError = isSourceErrorOriginInfo(originInfo);

  const Source = () => {
    const name = source?.definition?.name || source?.name || "Private source";

    const content = disableRedirectToResources ? (
      <TextWithTooltip fontWeight="medium" size="lg" color="inherit">
        {name}
      </TextWithTooltip>
    ) : (
      <LinkWithTooltip
        href={`/sources/${source?.id}`}
        fontWeight="medium"
        fontSize="lg"
        color="inherit"
      >
        {name}
      </LinkWithTooltip>
    );
    return (
      <Row
        sx={{
          gap: 2,
          alignItems: "center",
          overflow: "hidden",
          flex: 1,
          maxW: "max-content",
        }}
      >
        <IntegrationIcon
          name={source?.definition.name}
          src={source?.definition?.icon}
        />
        {!source ? (
          <Tooltip message="This source is only visible to some users">
            {name}
          </Tooltip>
        ) : (
          content
        )}
        {isSourceError && <Badge variant="danger">Error</Badge>}
      </Row>
    );
  };

  const Destination = () => {
    const name =
      destination?.definition?.name ||
      destination?.name ||
      "Private destination";
    const content = disableRedirectToResources ? (
      <TextWithTooltip fontWeight="medium" size="lg" color="inherit">
        {name}
      </TextWithTooltip>
    ) : (
      <LinkWithTooltip
        href={`/destinations/${destination?.id}`}
        fontWeight="medium"
        fontSize="lg"
        color="inherit"
      >
        {name}
      </LinkWithTooltip>
    );
    return (
      <Row
        sx={{
          gap: 2,
          alignItems: "center",
          overflow: "hidden",
          flex: 1,
          maxW: "max-content",
        }}
      >
        <IntegrationIcon
          name={destination?.definition?.name}
          src={destination?.definition?.icon}
        />
        {!destination ? (
          <Tooltip message="This destination is only visible to some users">
            {name}
          </Tooltip>
        ) : (
          content
        )}
        {isDestinationError && (
          <Badge variant="danger">
            {isNonEnrichedRowError ? "Alert" : "Error"}
          </Badge>
        )}
      </Row>
    );
  };

  return (
    <Row
      sx={{
        alignItems: "center",
        flex: 1,
        gap: 4,
        a: {
          color: "text.primary",
        },
        "a:hover": {
          color: "link.default",
        },
        overflow: "hidden",
      }}
    >
      <Source />
      <Box
        as={ArrowRightIcon}
        boxSize={6}
        color="text.secondary"
        flexShrink={0}
      />
      <Destination />
    </Row>
  );
};
