import { Column, Heading } from "@hightouchio/ui";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Outlet, useOutletContext } from "src/router";
import { ActionBar } from "src/components/action-bar";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { Page } from "src/components/layout";
import { useUpdateDecisionEngineWithChannelsMutation } from "src/graphql";
import { Channels, formatChannel, channelsSchema } from "./components/channels";
import { Timing, timingSchema } from "./components/timing";
import { Targeting } from "./components/targeting";
import { OutletContext } from "..";

const schema = yup.object().shape({
  channels: channelsSchema,
  config: yup.object().shape({
    timing: timingSchema,
  }),
});

export const Configuration = () => {
  const context = useOutletContext<OutletContext>();
  const { engine } = context;
  const update = useUpdateDecisionEngineWithChannelsMutation();

  const form = useHightouchForm({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    onSubmit: async (data) => {
      const channels = data.channels.map((channel) => ({
        ...channel,
        decision_engine_id: engine.id,
      }));

      await update.mutateAsync({
        id: engine.id,
        input: {
          config: {
            ...engine.config,
            ...data.config,
          },
          segment_id: data.segment_id,
        },
        channels,
      });
    },
    values: {
      segment_id: engine.segment.id,
      config: {
        timing: engine.config?.timing ?? { frequency: [], days: [], times: [] },
      },
      channels: engine.channels
        .map(formatChannel)
        .sort((a, b) => Number(b.enabled) - Number(a.enabled)),
    },
  });

  return (
    <Page title="Configuration">
      <Form form={form}>
        <Column gap={6} maxW="xl" pb={12}>
          <Heading isTruncated size="xl">
            Configuration
          </Heading>
          <Targeting />
          <Timing />
          <Channels />
        </Column>
        <ActionBar>
          <FormActions />
        </ActionBar>
      </Form>
      <Outlet context={context} />
    </Page>
  );
};
