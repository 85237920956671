import {
  FormField,
  Dialog,
  Button,
  Spinner,
  Column,
  ExternalLinkIcon,
} from "@hightouchio/ui";
import { Controller } from "react-hook-form";

import { useNavigate, Link } from "src/router";
import { Form, SaveButton, useHightouchForm } from "src/components/form";
import { SecretInput } from "src/components/secret-input";
import {
  useAlertingCredentialsQuery,
  useCreatePagerDutyCredentialsMutation,
} from "src/graphql";

export const PagerDutyForm = (props: { onClose?: () => void }) => {
  const mutation = useCreatePagerDutyCredentialsMutation();
  const { data: credentials, isLoading } = useAlertingCredentialsQuery(
    undefined,
    { select: (data) => data.pagerduty_credentials?.[0] },
  );

  const onClose = props.onClose || (() => navigate("/alerting/recipients"));

  const form = useHightouchForm({
    onSubmit: async (data) => {
      await mutation.mutateAsync({
        api_key: data.api_key,
      });
      onClose();
    },
    values: { api_key: "" },
  });

  const navigate = useNavigate();

  const Content = (
    <Column gap={6}>
      <FormField
        label="PagerDuty API key"
        description={
          <>
            Don't have an API key?{" "}
            <Link
              isExternal
              href={`${import.meta.env.VITE_DOCS_URL}/syncs/pagerduty/`}
            >
              Read the docs <ExternalLinkIcon />
            </Link>{" "}
            to learn how to generate one.
          </>
        }
      >
        <Controller
          name="api_key"
          render={({ field }) => (
            <SecretInput
              isHidden={Boolean(credentials?.id)}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </FormField>
    </Column>
  );

  return (
    <Form form={form}>
      <Dialog
        isOpen
        variant="form"
        width="lg"
        title="Manage PagerDuty credentials"
        actions={
          <>
            <Button onClick={onClose}>Cancel</Button>
            <SaveButton size="md" />
          </>
        }
        onClose={onClose}
      >
        {isLoading ? <Spinner size="lg" m="auto" /> : Content}
      </Dialog>
    </Form>
  );
};
