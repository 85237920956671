import { FC } from "react";
import {
  Button,
  ButtonGroup,
  Column,
  Dialog,
  Paragraph,
  Text,
  useToast,
} from "@hightouchio/ui";
import { captureException } from "@sentry/react";
import { useDeleteSubsetMutation } from "src/graphql";

import {
  DependenciesTable,
  DependencyTableData,
} from "src/components/modals/dependencies-modal";

type Props = {
  id: string;
  name: string;
  audiencesSubsets: {
    audience: {
      id: any;
      name: string;
    };
    created_at: string;
    created_by_user: {
      name;
    } | null;
  }[];
  isOpen: boolean;
  onClose: () => void;
};

export const DeleteSubsetModal: FC<Readonly<Props>> = ({
  id,
  name,
  audiencesSubsets,
  isOpen,
  onClose,
}) => {
  const { toast } = useToast();

  const deleteSubset = useDeleteSubsetMutation();
  const onDelete = async () => {
    try {
      await deleteSubset.mutateAsync({ id });
      toast({
        id: "delete-subset",
        title: "Successfully deleted subset.",
        variant: "success",
      });
    } catch (error) {
      toast({
        id: "delete-subset",
        title: "Could not delete subset. Please try again.",
        message: error.message,
        variant: "error",
      });
      captureException(error);
    }
  };

  const audiencesWithSubset: DependencyTableData["resources"] =
    audiencesSubsets.map(({ audience, created_at, created_by_user }) => ({
      id: audience.id,
      name: audience.name,
      created_at,
      created_by_user: created_by_user,
      updated_at: null,
      updated_by_user: null,
      url: `/audiences/${audience.id}`,
    })) ?? [];

  const isActiveSubset = audiencesWithSubset.length > 0;

  return (
    <Dialog
      isOpen={isOpen}
      title={`Delete subset: ${name}`}
      variant="info"
      width="xl"
      onClose={onClose}
      actions={
        <ButtonGroup>
          <Button isDisabled={deleteSubset.isLoading} onClick={onClose}>
            Cancel
          </Button>
          <Button
            isDisabled={deleteSubset.isLoading}
            isLoading={deleteSubset.isLoading}
            variant="danger"
            onClick={onDelete}
          >
            Delete
          </Button>
        </ButtonGroup>
      }
    >
      <Column gap={4}>
        <Paragraph textAlign="left">
          Are you sure you want to delete the subset{" "}
          <Text fontWeight="medium">{name}</Text>? This subset will no longer be
          available to use in audiences.
        </Paragraph>

        {isActiveSubset && (
          <Column>
            <Text fontWeight="medium">
              This subset selection will be removed from the following
              audiences:
            </Text>
            <DependenciesTable
              dependencies={[
                { name: "Audiences", resources: audiencesWithSubset },
              ]}
            />
          </Column>
        )}
      </Column>
    </Dialog>
  );
};
