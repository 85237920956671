import { FC, useMemo, useState } from "react";

import {
  Alert,
  Box,
  CodeSnippet,
  Column,
  DrawerBody,
  ErrorIcon,
  Heading,
  Row,
  SearchInput,
  SectionHeading,
  Spinner,
  Text,
} from "@hightouchio/ui";
import { Navigate, useParams } from "src/router";

import { Drawer } from "src/components/drawer";
import { Page } from "src/components/layout";
import { Warning } from "src/components/warning";
import {
  useEventPayloadQuery,
  useEventWarehouseSyncRunQuery,
  useWarehouseSyncErrorSummaryQuery,
} from "src/graphql";
import useQueryState from "src/hooks/use-query-state";
import { PageSpinner } from "src/components/loading";
import { SimplePagination, Table } from "src/ui/table";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { commaNumber } from "src/utils/numbers";
import { formatDatetime } from "src/utils/time";

export const WarehouseSyncRun: FC = () => {
  const { id: syncId, runId } = useParams<{ id: string; runId: string }>();

  const {
    data: run,
    isLoading,
    error,
  } = useEventWarehouseSyncRunQuery(
    { id: runId ?? "" },
    {
      select: (data) => data.getEventWarehouseSyncRun,
      enabled: Boolean(runId),
    },
  );

  const { data: errors } = useWarehouseSyncErrorSummaryQuery(
    { input: { runId: runId ?? "" } },
    {
      enabled: Boolean(runId),
      select: (data) => data.getWarehouseSyncErrorSummary.errors,
    },
  );

  const tableData = useMemo(
    () =>
      Object.keys(run?.table_summary ?? {}).map((name) => {
        const tableSummary: { numRows: number; numFailedRows: number } =
          run?.table_summary[name];
        return {
          name,
          numRows: tableSummary.numRows,
          numFailedRows: tableSummary.numFailedRows,
        };
      }),
    [run?.table_summary],
  );

  if (isLoading) {
    return <PageSpinner />;
  }

  if (!runId) {
    return <Navigate to={`/events/syncs/${syncId}`} replace />;
  }

  if (error || !run) {
    return <Warning title="Failed to load run" subtitle="Please try again" />;
  }

  return (
    <Page
      title="Run"
      crumbs={[
        { label: "All syncs", link: "/events/syncs" },
        { label: "Sync", link: `/events/syncs/${syncId}` },
      ]}
    >
      <Column gap={6}>
        <Column gap={2}>
          <Heading>Run {runId}</Heading>
          <Text color="text.secondary">
            {commaNumber(run.num_failed_events ?? 0)} events failed
          </Text>
        </Column>
        {run.error?.message && (
          <Alert
            variant="inline"
            type="error"
            title="Run stopped due to a fatal error"
            message={run.error.message}
          />
        )}
        <RunErrors errors={errors} />
        <RunTables tables={tableData} />
      </Column>
    </Page>
  );
};

const ErrorDrawer: FC<
  Readonly<{ error: Error | undefined; onClose: () => unknown }>
> = ({ error, onClose }) => {
  const [index, setIndex] = useState(0);

  const next = () => {
    setIndex((index) => index + 1);
  };

  const previous = () => {
    setIndex((index) => index - 1);
  };

  const eventKey = error?.samplePayloadKeys[index];

  const { data: payload, isFetching } = useEventPayloadQuery(
    { input: { eventKey: eventKey ?? "" } },
    {
      enabled: Boolean(eventKey),
      select: (data) => data.getEventPayload.payload,
    },
  );

  return (
    <Drawer isOpen={Boolean(error)} onClose={onClose} size="lg">
      <Row
        p={6}
        borderBottom="1px solid"
        borderColor="base.border"
        width="100%"
      >
        <Heading>{error?.error ?? "Error"}</Heading>
      </Row>
      <DrawerBody>
        <Column gap={2}>
          <Column gap={1}>
            <SectionHeading>Error message</SectionHeading>
            <Text color="text.secondary">
              {error &&
                `Last received at: ${formatDatetime(error.lastReceivedAt)}`}
            </Text>
          </Column>
          <Box
            as={CodeSnippet}
            overflow="auto"
            code={error?.error ?? ""}
            maxH="300px"
          />
        </Column>
        <Column gap={2} mt={8}>
          <SectionHeading>Sample payloads</SectionHeading>
          {isFetching ? (
            <Column maxH="500px" align="center" justify="center">
              <Spinner size="lg" />
            </Column>
          ) : (
            <Box
              as={CodeSnippet}
              height="100%"
              maxH="500px"
              overflow="auto"
              code={payload ? JSON.stringify(payload, null, 4) : ""}
            />
          )}
          <SimplePagination
            page={index}
            pages={error?.samplePayloadKeys.length}
            onNext={next}
            onPrevious={previous}
          />
        </Column>
      </DrawerBody>
    </Drawer>
  );
};

type Error = {
  error: string;
  numEvents: number;
  lastReceivedAt: string;
  samplePayloadKeys: string[];
};

const RunErrors: FC<
  Readonly<{
    errors: Error[] | undefined;
  }>
> = ({ errors }) => {
  const [selectedError, setSelectedError] = useState<Error>();

  if (!errors || !errors.length) {
    return null;
  }

  return (
    <Column gap={4}>
      <SectionHeading>Errors</SectionHeading>
      <Table
        data={errors}
        primaryKey="error"
        onRowClick={(row) => {
          setSelectedError(row);
        }}
        columns={[
          {
            name: "Error",
            max: "800px",
            cell: ({ error }) => (
              <Row gap={2} color="danger.base" fontSize="lg" maxW="100%">
                <Row flex={0}>
                  <ErrorIcon />
                </Row>
                <TextWithTooltip fontWeight="medium">{error}</TextWithTooltip>
              </Row>
            ),
          },
          {
            name: "Events",
            cell: ({ numEvents }) => (
              <Text fontWeight="medium">{commaNumber(numEvents)}</Text>
            ),
          },
        ]}
      />
      <ErrorDrawer
        error={selectedError}
        onClose={() => {
          setSelectedError(undefined);
        }}
      />
    </Column>
  );
};

const RunTables: FC<
  Readonly<{
    tables: { name: string; numRows: number; numFailedRows: number }[];
  }>
> = ({ tables }) => {
  const [search, setSearch] = useQueryState("search");

  const filteredTables = useMemo(() => {
    if (!search) return tables;

    const lowerCaseSearch = search.toLowerCase();
    return tables.filter((table) =>
      table.name.toLowerCase().includes(lowerCaseSearch),
    );
  }, [search, tables]);

  return (
    <Column gap={4}>
      <SectionHeading>Output tables</SectionHeading>
      <SearchInput
        placeholder="Search output tables..."
        value={search ?? ""}
        onChange={(e) => setSearch(e.target.value)}
      />
      <Table
        data={filteredTables}
        placeholder={{
          title: "No output tables",
          body: "No events have been synced.",
          error: "Output tables failed to load, please try again.",
        }}
        primaryKey="name"
        columns={[
          {
            name: "Name",
            max: "800px",
            cell: ({ name }) => (
              <TextWithTooltip fontWeight="medium">{name}</TextWithTooltip>
            ),
          },
          {
            name: "Events",
            cell: ({ numRows }) => (
              <Text fontWeight="medium">{commaNumber(numRows)}</Text>
            ),
          },
          {
            name: "Failures",
            cell: ({ numFailedRows }) => (
              <Text fontWeight="medium">{commaNumber(numFailedRows)}</Text>
            ),
          },
        ]}
      />
    </Column>
  );
};
