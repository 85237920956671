import { Box, Column, Paragraph, SectionHeading } from "@hightouchio/ui";

import { Link } from "src/router";

import analyticsPlaceholder from "src/assets/placeholders/analytics.svg";

import { placeholderContentWidthPx } from "./constants";

export const AnalyticsPlaceholderImage = () => (
  <Box
    as="img"
    boxSize={4}
    alignSelf="center"
    width="380px"
    height="200px"
    src={analyticsPlaceholder}
  />
);

export const AnalyticsBasePlaceholder = () => {
  return (
    <Column justify="center" align="center" flex={1} minWidth={0}>
      <Column
        align="center"
        justify="center"
        flex={1}
        minHeight={0}
        textAlign="center"
        width={placeholderContentWidthPx}
      >
        <AnalyticsPlaceholderImage />
        <SectionHeading mb={2}>Analytics are not available yet</SectionHeading>
        <Paragraph>
          This page allows you to create visualizations of audiences across
          user-defined metrics such as “Total revenue”, “Count of purchase
          events”, or “Average lifetime value”. You can compare different
          audiences, analyze a single audience, or compare split groups within
          an audience.
          <br />
          Check out our{" "}
          <Link
            isExternal
            href={`${
              import.meta.env.VITE_DOCS_URL
            }/campaign-intelligence/charts`}
          >
            Analytics documentation
          </Link>{" "}
          to learn more.
        </Paragraph>
      </Column>
    </Column>
  );
};

export const UnconfiguredPlaceholder = () => {
  return (
    <Column justify="center" align="center" flex={1} minWidth={0}>
      <Column
        align="center"
        justify="center"
        flex={1}
        minHeight={0}
        textAlign="center"
        width={placeholderContentWidthPx}
      >
        <AnalyticsPlaceholderImage />
        <SectionHeading mb={2}>Select a metric to get started</SectionHeading>
        <Paragraph>
          A chart is comprised of a metric and one or more audiences.
          <br />
          Check out our{" "}
          <Link
            isExternal
            href={`${
              import.meta.env.VITE_DOCS_URL
            }/campaign-intelligence/charts`}
          >
            Analytics documentation
          </Link>{" "}
          to learn more.
        </Paragraph>
      </Column>
    </Column>
  );
};

export const FunnelsPlaceholder = () => {
  return (
    <Column justify="center" align="center" flex={1} minWidth={0}>
      <Column
        align="center"
        justify="center"
        flex={1}
        minHeight={0}
        textAlign="center"
        width={placeholderContentWidthPx}
      >
        <AnalyticsPlaceholderImage />
        <SectionHeading mb={2}>
          Select multiple steps to get started
        </SectionHeading>
        <Paragraph>
          A chart is comprised of two or more steps and one or more audiences.
          <br />
          Check out our{" "}
          <Link
            isExternal
            href={`${
              import.meta.env.VITE_DOCS_URL
            }/campaign-intelligence/charts`}
          >
            Analytics documentation
          </Link>{" "}
          to learn more.
        </Paragraph>
      </Column>
    </Column>
  );
};

export const PermissionsPlaceholder = () => {
  return (
    <Column justify="center" align="center" flex={1} minWidth={0}>
      <Column
        align="center"
        justify="center"
        flex={1}
        minHeight={0}
        textAlign="center"
        width={placeholderContentWidthPx}
      >
        <AnalyticsPlaceholderImage />
        <SectionHeading mb={2}>
          You do not have permissions to use Analytics
        </SectionHeading>
        <Paragraph>
          Contact a workspace admin to gain access or check out our{" "}
          <Link
            isExternal
            href={`${
              import.meta.env.VITE_DOCS_URL
            }/campaign-intelligence/charts`}
          >
            Analytics documentation
          </Link>{" "}
          to learn more.
        </Paragraph>
      </Column>
    </Column>
  );
};
