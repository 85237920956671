import {
  useOrganizationSourcesQuery,
  useUpdateUserGroupGrantsMutation,
  useUserGroupGrantsByTypeQuery,
} from "src/graphql";
import { WorkspaceResourceGrantsForm } from "./components/grant-form";
import { useParams } from "src/router";

export const SourceGrants = () => {
  const { workspaceId, roleId } = useParams();

  const { data } = useOrganizationSourcesQuery(
    { workspaceId: workspaceId ?? "" },
    {
      enabled: Boolean(workspaceId),
      select: (data) => data.getOrganizationSources.connections,
      suspense: true,
    },
  );

  const { data: grants } = useUserGroupGrantsByTypeQuery(
    {
      workspaceId: workspaceId ?? "",
      userGroupId: roleId ?? "",
      resourceType: "source",
    },
    {
      enabled: Boolean(workspaceId && roleId),
      select: (data) => data.user_group_grants,
      suspense: true,
    },
  );

  const { mutateAsync: updateGrants } = useUpdateUserGroupGrantsMutation();

  return (
    <WorkspaceResourceGrantsForm
      grants={grants ?? []}
      resourceType="source"
      resources={data ?? []}
      onSubmit={async ({ objects }) => {
        await updateGrants({ objects });
      }}
    />
  );
};
