import { FormField, Textarea, Select } from "@hightouchio/ui";

import { usePagerDutyCredentialsQuery } from "src/graphql";

import { PagerdutyAlert, PagerdutyAlertConfig } from "./types";

const severityOptions = [
  { label: "Critical", value: "critical" },
  { label: "Error", value: "error" },
  { label: "Warning", value: "warning" },
  { label: "Info", value: "info" },
];

export const PagerdutyForm = ({
  alert,
  setAlert,
  config,
  setConfig,
}: {
  alert: PagerdutyAlert;
  setAlert: (alert: PagerdutyAlert) => void;
  config: PagerdutyAlertConfig;
  setConfig: (config: PagerdutyAlertConfig) => void;
}) => {
  const {
    data: pagerdutyCredentialsData,
    isLoading: pagerdutyCredentialsLoading,
    error: pagerdutyCredentialsError,
  } = usePagerDutyCredentialsQuery();

  return (
    <>
      <FormField error={pagerdutyCredentialsError?.message} label="Credentials">
        <Select
          isLoading={pagerdutyCredentialsLoading}
          options={
            pagerdutyCredentialsData?.pagerduty_credentials?.map(
              (credential) => ({
                label: `PagerDuty account (${credential.id})`,
                value: credential.id,
              }),
            ) ?? []
          }
          placeholder="Select a PagerDuty account..."
          value={alert?.pagerduty_credential_id}
          onChange={(pagerduty_credential_id) => {
            setAlert({ ...alert, pagerduty_credential_id });
          }}
        />
      </FormField>

      <FormField label="Row error severity">
        <Select
          options={severityOptions}
          placeholder="Select severity..."
          value={config?.rowErrorSeverity || "warning"}
          onChange={(row_error_severity) => {
            setAlert({ ...alert, row_error_severity });
          }}
        />
      </FormField>

      <FormField label="Fatal error severity">
        <Select
          options={severityOptions}
          placeholder="Select severity..."
          value={config?.rowErrorSeverity || "error"}
          onChange={(row_error_severity) => {
            setAlert({ ...alert, row_error_severity });
          }}
        />
      </FormField>

      <FormField isOptional label="Custom fatal error message">
        <Textarea
          placeholder="Sync {{ id }} failed with error: {{ error }}"
          rows={3}
          value={config?.fatalErrorMessage || ""}
          onChange={(event) =>
            setConfig({
              ...config,
              fatalErrorMessage: event.currentTarget.value,
            })
          }
        />
      </FormField>

      <FormField isOptional label="Custom row error message">
        <Textarea
          placeholder="Sync {{ id }} failed with error: {{ error }}"
          rows={3}
          value={config?.rowErrorMessage || ""}
          onChange={(event) =>
            setConfig({ ...config, rowErrorMessage: event.currentTarget.value })
          }
        />
      </FormField>
    </>
  );
};
