import { sourceIdLike } from "@hightouch/lib/resource-monitoring/composite-ids";
import { MonitorStatus } from "@hightouch/lib/resource-monitoring/types";
import { minutesToMilliseconds } from "date-fns";
import {
  useRenderedConditionChangesQuery,
  useStatusesForEventSourceQuery,
} from "src/graphql";
import { isPresent } from "src/types/utils";

type ActiveAlert = {
  title: string | null;
  description: string;
  eventTime: Date;
  eventTypeResourceId: string;
  conditionChangeId: string;
};

export const useActiveAlertsForSource = (opts: {
  sourceId: string;
}):
  | {
      alerts: ActiveAlert[];
      isLoading: false;
    }
  | { isLoading: true; alerts: undefined } => {
  const sourceIdWhere = {
    sourceIdLike: sourceIdLike(opts.sourceId),
  };

  const { data: resourceStatuses, isLoading: statusesLoading } =
    useStatusesForEventSourceQuery(sourceIdWhere, {
      select: (data) => data.resource_monitor_statuses,
      refetchOnMount: false,
      staleTime: minutesToMilliseconds(2),
    });

  const unhealthyEventTypeStatusChanges = resourceStatuses?.filter(
    (status) =>
      status.new_status === MonitorStatus.Unhealthy ||
      status.new_status === MonitorStatus.Warning,
  );

  const { data: alerts, isLoading } = useRenderedConditionChangesQuery(
    {
      resourceId: "",
      resourceType: "EventSource",
      conditionChangeIds:
        unhealthyEventTypeStatusChanges?.flatMap(
          (status) => status.condition_changes as string[],
        ) || [],
    },
    {
      enabled: !!unhealthyEventTypeStatusChanges,
      refetchOnMount: false,
      staleTime: minutesToMilliseconds(2),
      select: (data) =>
        resourceStatuses &&
        data.renderedMonitorConditionStatuses
          .map((conditionStatus) => {
            const eventTypeResourceId = resourceStatuses.find(
              (resourceStatus) =>
                resourceStatus.condition_changes.includes(conditionStatus.id),
            )?.resource_id;
            if (!eventTypeResourceId) return null;
            return {
              title: conditionStatus.title,
              description: conditionStatus.text,
              eventTime: new Date(conditionStatus.eventTime),
              conditionChangeId: conditionStatus.id,
              eventTypeResourceId,
            };
          })
          .filter(isPresent),
    },
  );

  return !(isLoading || statusesLoading) && alerts
    ? { isLoading, alerts }
    : { isLoading: true, alerts: undefined };
};
