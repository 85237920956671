import { FC } from "react";

import { Box, Row, Text, Paragraph, ChatIcon } from "@hightouchio/ui";

import { Link } from "src/router";

import { newPylonMessage } from "src/lib/pylon";

type HelpProps = {
  docs?: string;
  label?: string;
  mt?: number;
};

export const Help: FC<Readonly<HelpProps>> = ({ docs, label, ...props }) => (
  <Box {...props}>
    <Row alignItems="center" gap={1}>
      <Box as={ChatIcon} fontSize="20px" color="text.secondary" />
      <Text fontWeight="medium">Need help?</Text>
    </Row>

    {docs && label ? (
      <Paragraph mt={1}>
        Read our <Link href={docs}>docs</Link> or{" "}
        <Box
          display="inline"
          onClick={() => {
            newPylonMessage(`Could you help me with ${label}?`);
          }}
        >
          <Link href="">send us a message</Link>
        </Box>
        .
      </Paragraph>
    ) : (
      <Box
        mt={1}
        onClick={() => {
          newPylonMessage(`Could you help me with ${label}?`);
        }}
      >
        <Link href="">Send us a message</Link>.
      </Box>
    )}
  </Box>
);
