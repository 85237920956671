import { FC } from "react";

import { Badge, BadgeGroup, BoxProps, Row } from "@hightouchio/ui";

type Props = {
  labels: Record<string, string>;
} & BoxProps;

export const Labels: FC<Readonly<Props>> = ({ labels = {}, ...props }) => {
  const entries = Object.entries(labels).sort(([a], [b]) => a.localeCompare(b));

  return (
    <Row
      {...props}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <BadgeGroup size="sm" mt={1}>
        {entries.map(([label, value]) => (
          <Badge key={label} size="sm">
            {label}: {String(value)}
          </Badge>
        ))}
      </BadgeGroup>
    </Row>
  );
};
