import { Children, FC, ReactNode, useState } from "react";

import {
  ChevronDownIcon,
  ChevronUpIcon,
  Column,
  DragIcon,
  Row,
  Text,
} from "@hightouchio/ui";
import { Reorder as Framer, useDragControls } from "framer-motion";
import { get } from "lodash";

import { swap } from "src/utils/array";

const boxShadowColor = "rgba(219, 225, 232, 0.20)";

type Props<T> = {
  items: T[];
  children: ReactNode;
  onChange: (items: T[]) => void;
};

export function MergeRulesReorder<T>({ children, items, onChange }: Props<T>) {
  if (!Children.count(children)) {
    return null;
  }

  return (
    <Column gap={4}>
      <Framer.Group
        layoutScroll
        axis="y"
        values={items}
        style={{
          listStyle: "none",
          paddingLeft: 0,
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
        onReorder={onChange}
      >
        {Children.map(children, (child, index) => {
          const item = items[index];
          if (item) {
            return (
              <Column key={get(item, "identifier")} gap={4}>
                <Item
                  isUpDisabled={index === 0}
                  isDownDisabled={index === items.length - 1}
                  item={item}
                  onClick={(child as any).props.onClick}
                  onUp={() => {
                    onChange(swap(items, index, index - 1));
                  }}
                  onDown={() => {
                    onChange(swap(items, index, index + 1));
                  }}
                >
                  {child}
                </Item>
                <Text color="text.secondary" fontWeight="medium">
                  And then...
                </Text>
              </Column>
            );
          }
          return null;
        })}
      </Framer.Group>
    </Column>
  );
}

const Item: FC<
  Readonly<{
    children: ReactNode;
    item: any;
    isUpDisabled: boolean;
    isDownDisabled: boolean;
    onUp: () => void;
    onDown: () => void;
    onClick: (() => void) | undefined;
  }>
> = ({
  children,
  item,
  isUpDisabled,
  isDownDisabled,
  onUp,
  onDown,
  onClick,
}) => {
  const [dragging, setDragging] = useState(false);
  const controls = useDragControls();

  return (
    <Framer.Item
      dragListener={false}
      dragControls={controls}
      value={item}
      style={{
        display: "flex",
        border: "1px solid var(--chakra-colors-base-divider)",
        borderRadius: "var(--chakra-radii-md)",
        boxShadow: `0px 0px 8px 0px ${boxShadowColor} inset`,
        overflow: "hidden",
        backgroundColor: "rgba(255,255,255,1)",
        userSelect: "none",
      }}
      // Remove animation transition to avoid height shift when adding rows inside of sets
      transition={{ duration: 0 }}
      whileDrag={{
        boxShadow:
          "0px 12px 16px rgba(16, 24, 40, 0.16), 0px 8px 16px rgba(16, 24, 40, 0.16), 0px 0px 12px rgba(16, 24, 40, 0.08)",
      }}
      onDragEnd={() => {
        setDragging(false);
      }}
      onDragStart={() => {
        setDragging(true);
      }}
    >
      <Column
        bg="white"
        borderRight="1px"
        borderColor="base.divider"
        boxShadow={`2px 0px 8px 0px ${boxShadowColor}`}
        color="text.secondary"
        fontSize="3xl"
        justify="start"
        px={1}
        py={2}
      >
        <Row
          as="button"
          disabled={isUpDisabled}
          color={isUpDisabled ? "gray.400" : "inherit"}
          cursor={isUpDisabled ? "default" : "pointer"}
          onClick={onUp}
        >
          <ChevronUpIcon />
        </Row>
        <Row
          className="handle"
          role="handle"
          color="text.secondary"
          cursor={dragging ? "grabbing" : "grab"}
          onPointerDown={(e) => controls.start(e)}
        >
          <DragIcon />
        </Row>
        <Row
          as="button"
          disabled={isDownDisabled}
          color={isDownDisabled ? "gray.400" : "inherit"}
          cursor={isDownDisabled ? "default" : "pointer"}
          onClick={onDown}
        >
          <ChevronDownIcon />
        </Row>
      </Column>
      <Row
        bg="base.lightBackground"
        cursor={onClick ? "pointer" : "default"}
        flex={1}
        overflowX="auto"
        p={4}
        onClick={onClick}
      >
        {children}
      </Row>
    </Framer.Item>
  );
};
