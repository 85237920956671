import { FC } from "react";

import {
  Box,
  Button,
  Column,
  MeetingIcon,
  Row,
  ToggleButton,
  ToggleButtonGroup,
} from "@hightouchio/ui";
import { noop } from "lodash";

import { GraphSeries } from "src/components/analytics/cross-audience-graph/types";

import { DateRangePicker } from "src/components/analytics/date-range-picker";
import { BreakdownTable } from "./breakdown-table";
import { LookbackOptions } from "./state/constants";
import { GroupByColumn, TimeOptions } from "./types";
import { formatDatePickerLabel } from "./utils";

type AnalyticsBreakdownProps = {
  data: GraphSeries[];
  groupByColumns?: GroupByColumn[];
  isLoading?: boolean;
  lookbackWindow: TimeOptions;
  selectedDates: Date[];
  onSelectDateRange: (dates: Date[]) => void;
  onUpdateLookbackWindow: (value: TimeOptions) => void;
};

export const AnalyticsBreakdown: FC<AnalyticsBreakdownProps> = ({
  data,
  groupByColumns = [],
  isLoading = false,
  lookbackWindow,
  selectedDates,
  onSelectDateRange,
  onUpdateLookbackWindow,
}) => {
  return (
    <Column flex={1} minHeight={0} p={6} pt={4} pb={0} gap={4}>
      <Row align="center" gap={2}>
        <ToggleButtonGroup
          size="sm"
          value={lookbackWindow}
          onChange={(value) => onUpdateLookbackWindow(value as TimeOptions)}
        >
          {LookbackOptions.map((option) => (
            <ToggleButton key={option.value} {...option} />
          ))}
        </ToggleButtonGroup>
        <DateRangePicker
          maxDate={new Date()}
          selectedDates={selectedDates}
          onChange={onSelectDateRange}
        >
          <Box
            as={Button}
            background={
              lookbackWindow === TimeOptions.Custom ? "gray.200" : "unset"
            }
            fontWeight={
              lookbackWindow === TimeOptions.Custom ? "semibold" : "normal"
            }
            icon={MeetingIcon}
            size="sm"
            onClick={noop}
          >
            {formatDatePickerLabel(selectedDates, lookbackWindow)}
          </Box>
        </DateRangePicker>
      </Row>

      <BreakdownTable
        data={data}
        groupByColumns={groupByColumns}
        isLoading={isLoading}
      />
    </Column>
  );
};
