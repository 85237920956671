import { Box, Column, Row, AudienceIcon } from "@hightouchio/ui";
import { Link } from "src/router";
import { PieChart, Pie, Cell, Legend } from "recharts";
import { MetadataLabel } from "src/components/metadata-bar";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import type { Flow } from "src/pages/decision-engines/flows";

export const Audience = ({ flow }: { flow: Flow }) => {
  return (
    <Column>
      <MetadataLabel>Target audience</MetadataLabel>
      <Column>
        <Row>
          <Link href={`/audiences/${flow.audience?.id}`}>
            <Row gap={2} align="center">
              <Box as={AudienceIcon} boxSize={5} />
              <TextWithTooltip color="inherit">
                {flow.audience?.name}
              </TextWithTooltip>
            </Row>
          </Link>
        </Row>
        <PieChart height={100} width={200} margin={{ left: -12 }}>
          <Pie
            isAnimationActive={false}
            data={[
              {
                value: 100,
                name: `${Math.round(
                  100 - flow.config.holdout * 100,
                )}% Treatment`,
              },
              {
                value: flow.config.holdout * 100,
                name: `${flow.config.holdout * 100}% Holdout`,
              },
            ]}
            dataKey="value"
            nameKey="name"
            innerRadius={15}
            outerRadius={30}
          >
            <Cell fill="var(--chakra-colors-teal-600)" />
            <Cell fill="var(--chakra-colors-teal-400)" />
          </Pie>
          <Legend
            layout="vertical"
            align="right"
            height={36}
            verticalAlign="middle"
          />
        </PieChart>
      </Column>
    </Column>
  );
};
