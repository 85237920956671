import { FC } from "react";

import { Routes, Route } from "src/router";

import { ContractLoader, ContractDetails } from "./contract";
import { CreateEventSchema } from "./event-schema/create-event-schema";
import { EventSchema } from "./event-schema/event-schema";

export const ContractRouter: FC = () => {
  return (
    <Routes>
      <Route element={<ContractLoader />}>
        <Route path="/" element={<ContractDetails />} />
        <Route path="new_event" element={<CreateEventSchema />} />
        <Route
          path="/:event_type_and_name/:event_version"
          element={<EventSchema />}
        />
      </Route>
    </Routes>
  );
};
