import {
  MonitorConditionTypes,
  MonitoredResourceType,
  SupportedConditionsForResourceType,
  SupportedConditionsForResourceTypes,
} from "@hightouch/lib/resource-monitoring/types";
import { isEqual, pick } from "lodash";
import { isArrayElement } from "src/types/utils";

export type MinimalCondition = {
  enabled: boolean;
  error_value?: any;
  warning_value?: any;
};

export function getMinimalCondition<T extends MinimalCondition>(condition: T) {
  return pick(condition, ["enabled", "error_value", "warning_value"]);
}

export const isOverride = (
  syncCondition: MinimalCondition,
  defaultCondition?: MinimalCondition,
) => {
  const syncConditionEnabled = syncCondition.enabled;
  const defaultConditionEnabled = defaultCondition?.enabled || false;

  if (
    (!syncConditionEnabled && !defaultConditionEnabled) || // If they're both disabled, it's not an override regardless of the values
    !defaultCondition // If there's no default at all, it's not an override
  ) {
    return false;
  }

  return !isEqual(syncCondition, defaultCondition);
};

// By default Yup errors look something like:
//   "SyncDuration.destinations.52495.error_value must be less than or equal to 1440"
// This doesn't really work for our purposes, so strip the prefix:
//   "Value must be less than or equal to 1440"
// Note: there are a lot of different patterns depending on the field, so regex has to be pretty generic
export const cleanFormError = (msg: string, valueName?: string) =>
  msg.replace(/\S*\s/, `${valueName ?? "Value"} `);

export const MonitoredResourceTypeToLabel: Record<
  MonitoredResourceType,
  string
> = {
  [MonitoredResourceType.Sync]: "sync",
  [MonitoredResourceType.EventSource]: "event source",
  [MonitoredResourceType.EventSync]: "event sync",
};

export const isMandatoryCondition = ({
  conditionType,
  resourceType,
}: {
  conditionType: MonitorConditionTypes;
  resourceType: MonitoredResourceType;
}) => {
  return (
    resourceType === MonitoredResourceType.Sync &&
    (conditionType === MonitorConditionTypes.SyncSequentialFailures ||
      conditionType === MonitorConditionTypes.SyncRejectedRows)
  );
};

export function isSupportedConditionForResourceType<
  T extends MonitoredResourceType,
>(
  resourceType: T,
  condition: string,
): condition is SupportedConditionsForResourceType<T> {
  return isArrayElement(SupportedConditionsForResourceTypes[resourceType])(
    condition,
  );
}
