import { FC } from "react";

import { Column, Skeleton } from "@hightouchio/ui";

interface Props {
  children: React.ReactNode;
  isLoading: boolean;
}

export const DashboardMainItem: FC<Props> = ({ children, isLoading }) => {
  return (
    <Column>
      <Column
        overflow="hidden"
        sx={{
          "& > div": {
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <Skeleton isLoading={isLoading}>{children}</Skeleton>
      </Column>
    </Column>
  );
};
