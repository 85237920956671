import { FC, ReactNode } from "react";

import { Box, Column, Heading, LogoutIcon } from "@hightouchio/ui";

import { LinkButton } from "src/router";

import grain from "src/assets/backgrounds/grain.png";
import { useUser } from "src/contexts/user-context";
import { Logo } from "src/ui/brand";

interface Props {
  children: ReactNode;
  logo?: boolean;
  heading?: string;
  pt?: number;
}

export const AuthLayout: FC<Readonly<Props>> = ({
  children,
  logo = true,
  heading,
  pt = 32,
}) => {
  const { user, isCompanyRequired } = useUser();

  return (
    <Column
      align="center"
      background={`url(${grain}) repeat, linear-gradient(107.85deg, #002124 0%, #02373B 17.58%, #033E40 31.02%, #085954 64.76%)`}
      height="100vh"
      pt={pt}
      px={8}
      width="100vw"
      color="white"
    >
      {user && !isCompanyRequired && (
        <Box pos="absolute" right={4} top={4}>
          <LinkButton
            href={`${import.meta.env.VITE_API_BASE_URL}/auth/logout`}
            icon={LogoutIcon}
            variant="primary"
          >
            Logout
          </LinkButton>
        </Box>
      )}
      {logo && (
        <Box left={8} pos="absolute" top={8}>
          <Logo size="126px" variant="full" theme="light" />
        </Box>
      )}
      {heading && (
        <Box mb={8} sx={{ h2: { color: "white" } }}>
          <Heading size="2xl">{heading}</Heading>
        </Box>
      )}
      {children}
    </Column>
  );
};
