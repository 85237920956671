import pluralize from "pluralize";

import { Maybe } from "src/graphql";

export enum ScheduleType {
  MANUAL = "manual",
  INTERVAL = "interval",
  CRON = "cron",
  CUSTOM = "visual_cron",
  DBT_CLOUD = "dbt",
  FIVETRAN = "fivetran",
  STREAMING = "streaming",
  MATCH_BOOSTER = "match_booster",
  JOURNEY_TRIGGERED = "journey_triggered",
}

export const scheduleToLabel = (schedule: Schedule) => {
  switch (schedule?.type) {
    case ScheduleType.MANUAL:
      return "Manual";
    case ScheduleType.INTERVAL:
      return `Every ${schedule.schedule?.interval.quantity} ${pluralize(
        schedule.schedule?.interval.unit ?? "",
        schedule.schedule?.interval.quantity ?? 0,
      )}`;
    case ScheduleType.CRON:
      return "Cron expression";
    case ScheduleType.CUSTOM:
      return "Custom";
    case ScheduleType.MATCH_BOOSTER:
      return "Match Booster enrichment";
    case ScheduleType.DBT_CLOUD:
    case ScheduleType.FIVETRAN:
    case ScheduleType.JOURNEY_TRIGGERED:
      return "Journey-triggered";
    default:
      return null;
  }
};

export function scheduleTypeToLabel(scheduleType: ScheduleType | null) {
  switch (scheduleType) {
    case ScheduleType.MANUAL || null:
      return "Manual";
    case ScheduleType.INTERVAL:
      return "Interval";
    case ScheduleType.CRON:
      return "Cron";
    case ScheduleType.CUSTOM:
      return "Custom";
    case ScheduleType.DBT_CLOUD:
      return "dbt Cloud";
    case ScheduleType.FIVETRAN:
      return "Fivetran";
    default:
      return "Unknown";
  }
}

interface BaseSchedule {
  type: ScheduleType;
  startDate?: string | null;
  endDate?: string | null;
}

export interface ManualSchedule extends BaseSchedule {
  type: ScheduleType.MANUAL;
}

export interface JourneyTriggeredSchedule extends BaseSchedule {
  type: ScheduleType.JOURNEY_TRIGGERED;
}

export interface StreamingSchedule extends BaseSchedule {
  type: ScheduleType.STREAMING;
}

export interface IntervalSchedule extends BaseSchedule {
  type: ScheduleType.INTERVAL;
  schedule?: {
    interval: ScheduleInterval;
  };
}
export enum ScheduleIntervalUnit {
  MINUTE = "minute",
  HOUR = "hour",
  DAY = "day",
  WEEK = "week",
}

export interface CronSchedule extends BaseSchedule {
  type: ScheduleType.CRON;
  schedule?: {
    expression?: string;
  };
}

export interface VisualCronSchedule extends BaseSchedule {
  type: ScheduleType.CUSTOM;
  schedule?: {
    expressions?: ScheduleExpression[];
  };
}

export interface DbtCloudSchedule extends BaseSchedule {
  type: ScheduleType.DBT_CLOUD;
  schedule?: {
    account?: {
      id: string;
      name: string;
    };
    job?: {
      id: string;
      name: string;
    } | null;
  };
}

export type FivetranScheduleTrigger = {
  id: string;
  type: string;
  name: string;
  fivetran_id: Maybe<string>;
};

export interface FivetranSchedule extends BaseSchedule {
  type: ScheduleType.FIVETRAN;
  schedule?: {
    groupId: string;
    groupName: string;
    triggers: FivetranScheduleTrigger[];
  };
}

export interface MatchBoosterSchedule extends BaseSchedule {
  type: ScheduleType.MATCH_BOOSTER;
}

export interface JourneyTriggeredSchedule extends BaseSchedule {
  type: ScheduleType.JOURNEY_TRIGGERED;
}

export type Schedule =
  | ManualSchedule
  | IntervalSchedule
  | CronSchedule
  | VisualCronSchedule
  | DbtCloudSchedule
  | FivetranSchedule
  | StreamingSchedule
  | MatchBoosterSchedule
  | JourneyTriggeredSchedule;

export interface ScheduleInterval {
  quantity?: number | null;
  unit?: ScheduleIntervalUnit;
}

export interface ScheduleExpression {
  time: string;
  days?: Record<string, boolean | undefined>;
}
