import { MonitorStatus } from "@hightouch/lib/resource-monitoring/types";
import { Box, Column, NumberInput, Row, Text } from "@hightouchio/ui";
import { Controller } from "react-hook-form";
import { FieldError } from "src/components/field-error";
import { ResourceStatus } from "src/components/resource-alert-triggers/resource-status";
import { Term } from "src/components/term";
import { isPresent } from "ts-extras";
import { cleanFormError } from "src/components/resource-alert-triggers/utils";

export const SyncSequentialFailuresField = ({ name }) => {
  return (
    <Controller
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Column>
          <Row flexWrap="wrap" gap={1}>
            <Box
              isInvalid={Boolean(error)}
              as={NumberInput}
              size="sm"
              display="inline-flex"
              width="50px"
              value={value}
              onChange={onChange}
            />{" "}
            <Text>or more consecutive sync runs with</Text>
            <Term message="A fatal error occurs when a sync experiences a problem that prevents it from running. (e.g., expired source or destination credentials, invalid SQL syntax, network issues, etc.)">
              fatal errors
            </Term>
          </Row>
          {error?.message ? (
            <FieldError error={cleanFormError(error?.message)} />
          ) : null}
        </Column>
      )}
    />
  );
};

interface Props {
  condition: {
    error_value: number;
    warning_value: number;
  };
}

export const SyncSequentialFailuresCondition = ({ condition }: Props) => {
  return [
    condition.error_value ? (
      <Text>
        <Text fontWeight="semibold">{condition.error_value}</Text> or more
        consecutive sync runs with fatal errors sets sync status to{" "}
        <ResourceStatus status={MonitorStatus.Unhealthy} />{" "}
      </Text>
    ) : null,
    condition.warning_value ? (
      <Text>
        <Text fontWeight="semibold">{condition.error_value}</Text> or more
        consecutive sync runs with fatal errors sets sync status to{" "}
        <ResourceStatus status={MonitorStatus.Warning} />{" "}
      </Text>
    ) : null,
  ].filter(isPresent);
};
