export const Icon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20px"
    height="20px"
    viewBox="0 0 24 24"
    fill={color}
  >
    <path
      fillRule="evenodd"
      d="M12.673 6.56a3.836 3.836 0 0 0-1.995.175A3.452 3.452 0 0 0 9.105 7.89 2.978 2.978 0 0 0 8.5 9.678a.5.5 0 0 0 1 0c0-.417.138-.83.406-1.188a2.453 2.453 0 0 1 1.12-.817 2.837 2.837 0 0 1 1.472-.13c.495.089.944.305 1.29.614.346.31.575.696.666 1.107.092.41.045.837-.136 1.228a2.27 2.27 0 0 1-.907.983c-.06.035-.12.068-.183.099-.787.387-1.728 1.153-1.728 2.282V15a.5.5 0 0 0 1 0v-1.144c0-.53.47-1.04 1.17-1.385a3.64 3.64 0 0 0 .253-.137 3.269 3.269 0 0 0 1.302-1.421 2.912 2.912 0 0 0 .205-1.866 3.103 3.103 0 0 0-.976-1.636 3.627 3.627 0 0 0-1.781-.852Z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M11.907 21.186a9.093 9.093 0 1 0-9.093-9.093c0 2.423.947 4.623 2.492 6.253.33.349.55.818.57 1.34l.11 3.161 2.546-1.591a2.058 2.058 0 0 1 1.511-.261c.6.125 1.224.19 1.864.19ZM22 12.093c0 5.574-4.519 10.093-10.093 10.093-.709 0-1.4-.073-2.068-.212a1.057 1.057 0 0 0-.777.13l-2.546 1.591a1 1 0 0 1-1.53-.813l-.11-3.161a1.056 1.056 0 0 0-.296-.687 10.058 10.058 0 0 1-2.766-6.941C1.814 6.519 6.333 2 11.907 2S22 6.519 22 12.093Z"
      clipRule="evenodd"
    />
    <path d="M12.5 17a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z" />
  </svg>
);
