export const CountryCodes = [
  {
    code: "USD",
    sign: "$",
  },
  {
    code: "EUR",
    sign: "€",
  },
  {
    code: "GBP",
    sign: "£",
  },
  {
    code: "JPY",
    sign: "¥",
  },
  {
    code: "CAD",
    sign: "CA$",
  },
  {
    code: "AUD",
    sign: "A$",
  },

  {
    code: "AED",
    sign: "AED",
  },
  {
    code: "BRL",
    sign: "R$",
  },
  {
    code: "CHF",
    sign: "CHF",
  },
  {
    code: "CNY",
    sign: "CN¥",
  },
  {
    code: "HKD",
    sign: "HK$",
  },
  {
    code: "INR",
    sign: "₹",
  },
  {
    code: "MYR",
    sign: "MYR",
  },
  {
    code: "MXN",
    sign: "MX$",
  },
  {
    code: "PHP",
    sign: "₱",
  },
  {
    code: "SAR",
    sign: "SAR",
  },
  {
    code: "SEK",
    sign: "SEK",
  },
  {
    code: "SGD",
    sign: "SGD",
  },
  {
    code: "THB",
    sign: "THB",
  },
  {
    code: "ZAR",
    sign: "ZAR",
  },
];
