import { useState, useEffect, useCallback } from "react";

type SelectedRows = Array<string | number>;

type RowSelectOptions = {
  defaultSelectedRows?: SelectedRows;
};

export const useRowSelect = ({
  defaultSelectedRows = [],
}: RowSelectOptions = {}) => {
  const [selectedRows, setSelectedRows] =
    useState<SelectedRows>(defaultSelectedRows);

  const onRowSelect = useCallback(
    (value: string | number | Array<string | number>) => {
      if (Array.isArray(value)) {
        setSelectedRows(value);
      } else {
        setSelectedRows((selectedRows) =>
          selectedRows.includes(value)
            ? selectedRows.filter((k) => k !== value)
            : [...selectedRows, value],
        );
      }
    },
    [setSelectedRows],
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Esc" || event.key === "Escape") {
        setSelectedRows([]);
      }
    };

    window.addEventListener("keydown", handleKeyDown, false);
    return () => {
      window.removeEventListener("keydown", handleKeyDown, false);
    };
  }, []);

  return {
    selectedRows,
    onRowSelect,
  };
};
