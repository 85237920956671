import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const EventsIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        d="M12.219 13.818c-.402-1.004.595-2.001 1.599-1.6l2.506-.788c.896-.283.943-1.534.07-1.883L7.162 5.863c-.815-.326-1.625.484-1.299 1.3l3.684 9.231c.349.873 1.6.827 1.883-.07l.789-2.506Z"
        opacity=".3"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeOpacity=".7"
        strokeWidth="2"
        d="m14 14 3.5 3.5"
      />
    </Icon>
  );
};
