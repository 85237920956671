import { Column, Row, SectionHeading } from "@hightouchio/ui";

import { useParams } from "src/router";
import { ActionBar } from "src/components/action-bar";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { ResourceGrantSelect } from "src/components/grant-select";
import { Grants } from "src/components/grant-select/types";
import { convertGrants } from "src/components/grant-select/util";
import { SidebarForm } from "src/components/page";
import { useUpdateSourceV2Mutation } from "src/graphql";

export const SourceGrants = () => {
  const { id } = useParams<{ id: string }>();

  const { mutateAsync: updateSource } = useUpdateSourceV2Mutation();

  const form = useHightouchForm({
    onSubmit: async (data) => {
      await updateSource({
        id: String(id),
        grants: convertGrants(data.grants),
      });
    },

    defaultValues: {
      grants: {} as Grants<"source">,
    },
  });

  return (
    <Row justifyContent="space-between">
      <Form form={form}>
        <Column flex={1}>
          <SectionHeading mb={4}>Grants</SectionHeading>
          <ResourceGrantSelect id={id!} type="source" />
        </Column>
        <ActionBar>
          <FormActions
            permission={{
              v2: { resource: "source", grant: "can_update", id: id! },
            }}
          />
        </ActionBar>
      </Form>
      <SidebarForm name="user groups" docsUrl="" />
    </Row>
  );
};
