import React, { FC, useEffect, useState } from "react";

import {
  Box,
  Button,
  Column,
  ErrorIcon,
  Heading,
  Row,
  Spinner,
  Text,
  ExternalLinkIcon,
} from "@hightouchio/ui";
import { Link } from "src/router";
import { useFlags } from "launchdarkly-react-client-sdk";

import {
  useDbtSyncModelsQuery,
  useGetSigmaExtensionQuery,
  useLookerCredentialsQuery,
} from "src/graphql";
import { QueryType } from "src/types/models";

import { Selectable } from "src/ui/selectable";

import { QUERY_TYPE_OPTIONS } from "./query-type-menu";

type Props = {
  source:
    | {
        id: string;
        definition: { supportedQueries: string[] };
        plan_in_warehouse: boolean;
      }
    | null
    | undefined;
  selected: QueryType | undefined;
  isEmbeddedFlow?: boolean;
  onChange: (value: QueryType | null) => void;
};

const helperContent = {
  dbt_model: {
    text: "To use this modeling method, you first need to connect Hightouch to the Git repo containing your dbt models.",
    error:
      "No dbt models found. Please check dbt model sync is running successfully for this source.",
    docs: `${import.meta.env.VITE_DOCS_URL}/extensions/dbt-models`,
    link: `/extensions/dbt-models`,
    name: "dbt",
  },
  looker_look: {
    text: "To use this modeling method, you first need to connect Hightouch to your Looker instance.",
    error:
      "Error connecting to Looker. Please check your extension configuration and try again.",
    docs: `${import.meta.env.VITE_DOCS_URL}/extensions/looker-models`,
    link: `/extensions/looker`,
    name: "Looker",
  },
  sigma: {
    text: "To use this modeling method, you first need to connect Hightouch to your Sigma account.",
    error:
      "Error connecting to your Sigma account. Please check your extension configuration and try again.",
    docs: `${import.meta.env.VITE_DOCS_URL}/extensions/sigma`,
    link: `/extensions/sigma`,
    name: "Sigma",
  },
};

export const QueryTypeSelect: FC<Readonly<Props>> = ({
  source,
  selected,
  isEmbeddedFlow,
  onChange,
}) => {
  const { sigmaExtension } = useFlags();

  const supportedQueries = isEmbeddedFlow
    ? [QueryType.RawSql, QueryType.Table]
    : (source?.definition?.supportedQueries.filter((type) => {
        if (type == QueryType.Sigma) {
          return sigmaExtension;
        }
        return QUERY_TYPE_OPTIONS[type];
      }) as QueryType[]);

  const { data: lookerEnabled = false, isLoading: lookerLoading } =
    useLookerCredentialsQuery(undefined, {
      select(data) {
        return data.looker_credentials?.length > 0;
      },
    });
  const { data: dbtData, isFetching: dbtLoading } = useDbtSyncModelsQuery(
    {
      sourceId: String(source?.id),
    },
    { enabled: Boolean(source?.id) },
  );
  const noDBTModels = !dbtData?.dbt_sync_models?.length;
  const {
    data: sigmaCredentials,
    isFetching: sigmaLoading,
    error: sigmaError,
  } = useGetSigmaExtensionQuery(undefined, {
    select: (data) => data.sigma_credentials,
  });
  const noSigma = !sigmaLoading && !sigmaCredentials?.length;

  useEffect(() => {
    if (!selected && supportedQueries?.length === 1) {
      onChange(supportedQueries[0] ?? null);
    }
  }, [selected]);

  const enrichedQueryTypes: {
    queryType: QueryType;
    loading?: boolean;
    error?: Error | null;
    disabled?: boolean;
  }[] = supportedQueries.map((queryType) => {
    switch (queryType) {
      case "dbt_model":
        return {
          queryType,
          loading: dbtLoading,
          disabled: noDBTModels,
          error:
            !!dbtData?.dbt_sync_config?.length && noDBTModels
              ? new Error("No dbt models found")
              : undefined,
        };
      case "looker_look":
        return {
          queryType,
          loading: lookerLoading,
          disabled: !lookerEnabled,
          error: null,
        };
      case "sigma":
        return {
          queryType,
          loading: sigmaLoading,
          disabled: noSigma || !!sigmaError,
          error: sigmaError,
        };
      default:
        return { queryType };
    }
  });

  return (
    <Row sx={{ flexWrap: "wrap", gap: 6 }}>
      {enrichedQueryTypes.map(
        ({ loading, error, queryType: type, disabled }) => {
          return (
            <SelectQueryType
              key={type}
              disabled={!!disabled}
              type={type}
              loading={loading}
              error={error}
              selected={selected === type}
              onChange={onChange}
            />
          );
        },
      )}
    </Row>
  );
};

function errorContent(error?: Error | Record<string, unknown>): string | null {
  if (!error) return null;
  return error["message"] ? String(error["message"]) : "An error occurred";
}

const SelectQueryType: FC<{
  type: QueryType;
  disabled: boolean;
  error?: any | null;
  loading?: boolean;
  selected?: boolean;
  onChange: (type: QueryType | null) => void;
}> = ({ type, disabled, loading, error, selected, onChange }) => {
  const { label, description, image } = QUERY_TYPE_OPTIONS[type];
  const [showHelper, setShowHelper] = useState(false);

  const errorText =
    helperContent[type]?.errorText ??
    `${helperContent[type]?.name} has an error`;
  const unconfiguredText =
    helperContent[type]?.unconfiguredText ??
    `${helperContent[type]?.name} extension not configured`;
  const buttonText =
    helperContent[type]?.buttonText ?? `Configure ${helperContent[type]?.name}`;
  return (
    <Row
      sx={{
        opacity: disabled && !showHelper && !selected ? "50%" : "100%",
        ".preview-image": {
          opacity: selected ? 1 : 0.8,
        },
        ":hover:not(disabled)": {
          ".preview-image": {
            opacity: 1,
          },
        },
      }}
      onMouseEnter={() => setShowHelper(true)}
      onMouseLeave={() => setShowHelper(false)}
    >
      <Selectable
        width="300px"
        height="270px"
        bg="white"
        disabled={disabled}
        selected={selected}
        onSelect={() => onChange(type)}
      >
        <Column textAlign="left" gap={4} pos="relative" p={6} w="100%">
          <Row justify="space-between" alignItems="center">
            <Heading>{label}</Heading>
            {loading && <Spinner size="sm" />}
            {!loading && error && (
              <Box fontSize="18px">
                <ErrorIcon color="red" />{" "}
              </Box>
            )}
          </Row>

          {!loading && error && errorContent(error) && (
            <Text color="red">{errorContent(error)}</Text>
          )}

          {/* TODO remove the 'image &&' once we have an image for Sigma UI */}
          {image && React.createElement(image)}

          <Text>{description}</Text>

          {disabled && showHelper && (
            <Column
              pos="absolute"
              gap={4}
              top={0}
              left={0}
              bg="white"
              p={6}
              height="100%"
              width="100%"
              borderRadius="2xl"
            >
              <Heading>{error ? errorText : unconfiguredText}</Heading>
              <Text>
                {error ? helperContent[type].error : helperContent[type].text}
              </Text>
              <Row align="center" gap={4}>
                <Link href={helperContent[type].link}>
                  <Button size="sm" variant="primary">
                    {buttonText}
                  </Button>
                </Link>
                <Link href={helperContent[type].docs}>
                  <Row sx={{ alignItems: "center" }}>
                    <Row mr={1}>Learn more</Row>{" "}
                    <Box as={ExternalLinkIcon} fontSize="18px" />
                  </Row>
                </Link>
              </Row>
            </Column>
          )}
        </Column>
      </Selectable>
    </Row>
  );
};
