import { useState, FC } from "react";

import {
  Avatar,
  Box,
  Button,
  ChakraModal,
  ChakraModalBody,
  ChakraModalContent,
  ChakraModalFooter,
  ChakraModalHeader,
  ChakraModalOverlay,
  CloseIcon,
  Column,
  IconButton,
  Row,
  Text,
} from "@hightouchio/ui";
import { Link } from "src/router";

import { Private } from "src/components/private";
import { SuccessfulQueryResponse } from "src/graphql";
import { newPylonMessage } from "src/lib/pylon";
import ErrorSrc from "src/ui/table/error.svg";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { getColumnName } from "src/utils/models";

import { ErrorModal } from "src/components/modals/error-modal";

export interface Props {
  rows: SuccessfulQueryResponse["rows"] | undefined;
  parentModel: any;
  splitTestGroupName?: string;
  error: string | undefined;
}

export const AudienceResults: FC<Readonly<Props>> = ({
  parentModel,
  rows,
  splitTestGroupName,
  error,
}) => {
  const [selection, setSelection] = useState<number | undefined>();

  const primaryLabelKey = parentModel.visual_query_primary_label
    ? getColumnName(parentModel.visual_query_primary_label, parentModel.columns)
    : parentModel.primary_key;
  const secondaryLabelKey = getColumnName(
    parentModel.visual_query_secondary_label,
    parentModel.columns,
  );

  const selectedRow =
    typeof selection !== "undefined" ? rows?.[selection] : undefined;

  if (error) {
    return (
      <Column
        sx={{ alignItems: "center", pt: 24, px: 4, flex: 1, overflowY: "auto" }}
      >
        <Box as="img" src={ErrorSrc} sx={{ mb: 4, maxWidth: "200px" }} />
        <Box fontWeight="semibold" fontSize="sm" textAlign="center" mb={2}>
          Error
        </Box>
        <ErrorModal error={error} />
      </Column>
    );
  }

  if (!rows || !rows?.length) {
    return (
      <Column
        sx={{ alignItems: "center", pt: 24, px: 4, flex: 1, overflowY: "auto" }}
      >
        <Box as="img" src={ErrorSrc} sx={{ mb: 4 }} />
        <Box sx={{ fontWeight: "bold", fontSize: 2, textAlign: "center" }}>
          No results
        </Box>
        <Box sx={{ mt: 2, maxWidth: "500px", textAlign: "center" }}>
          Modify the query to return at least one row, or{" "}
          <Box
            display="inline-block"
            onClick={() => {
              newPylonMessage(
                "Could you help me create a model for a query with no results?",
              );
            }}
          >
            <Link href="">send us a message</Link>
          </Box>{" "}
          if your query cannot return results.
        </Box>
      </Column>
    );
  }

  return (
    <>
      <Column sx={{ flex: 1, overflow: "hidden" }}>
        {Array.isArray(rows) && rows.length > 0 ? (
          <Column sx={{ overflowY: "auto" }}>
            {rows.map((row, index) => {
              return (
                <Row
                  key={row[parentModel.primary_key]}
                  sx={{
                    p: 2,
                    alignItems: "center",
                    cursor: "pointer",
                    borderRadius: "md",
                    ":hover": { bg: "gray.100" },
                  }}
                  onClick={() => setSelection(index)}
                >
                  <Header
                    primaryLabelKey={primaryLabelKey}
                    row={row}
                    secondaryLabelKey={secondaryLabelKey}
                  />
                </Row>
              );
            })}
          </Column>
        ) : (
          <Column
            sx={{ alignItems: "center", pt: 24, px: 4, overflowY: "auto" }}
          >
            <Text size="lg" fontWeight="semibold" mb={1}>
              No rows found
            </Text>
            <Box color="text.secondary">
              There are no rows with a primary label matching your search.
            </Box>
          </Column>
        )}
      </Column>

      <ChakraModal
        isCentered
        isOpen={Boolean(selectedRow)}
        size="xl"
        onClose={() => setSelection(undefined)}
      >
        <ChakraModalOverlay />
        <ChakraModalContent p={0} maxHeight="85%" overflowY="auto" my="auto">
          <ChakraModalHeader
            p={4}
            borderBottom="1px solid"
            borderColor="base.border"
          >
            <Row justify="space-between" width="100%">
              <Header
                primaryLabelKey={primaryLabelKey}
                row={selectedRow}
                secondaryLabelKey={secondaryLabelKey}
              />
              <IconButton
                aria-label="Close"
                icon={CloseIcon}
                onClick={() => setSelection(undefined)}
              />
            </Row>
          </ChakraModalHeader>
          <ChakraModalBody overflowY="auto" p={4}>
            <Details
              row={selectedRow}
              splitTestGroupName={splitTestGroupName}
            />
          </ChakraModalBody>
          <ChakraModalFooter
            p={4}
            mt={0}
            borderTop="1px solid"
            borderColor="base.border"
            boxShadow="sm"
          >
            {typeof selection !== "undefined" && (
              <>
                <Button
                  isDisabled={selection === 0}
                  onClick={() => setSelection(selection - 1)}
                >
                  Previous
                </Button>
                <Text size="sm" color="text.secondary" mx={2}>
                  {selection + 1} / {rows?.length}
                </Text>
                <Button
                  isDisabled={selection === rows?.length - 1}
                  onClick={() => setSelection(selection + 1)}
                >
                  Next
                </Button>
              </>
            )}
          </ChakraModalFooter>
        </ChakraModalContent>
      </ChakraModal>
    </>
  );
};

const Details = ({ row, splitTestGroupName }) => {
  // We want to filter out the split test group assignment since this may change come sync time.
  const columns = Object.keys(row).filter(
    (column) => column !== splitTestGroupName,
  );
  const maxColumnChar = Math.min(
    columns.reduce((max, key) => Math.max(max, key.length), 0),
    32,
  );
  const maxColumnWidth = `${maxColumnChar * 12}px`;

  return (
    <>
      {columns.map((key) => {
        const value = row[key];
        const sanitized =
          typeof value === "object" ? JSON.stringify(value) : value?.toString();
        return (
          <Row
            key={key}
            sx={{
              p: 2,
              borderBottom: "small",
              overflow: "hidden",
              flexShrink: 0,
            }}
          >
            <Private>
              <Box
                sx={{
                  width: maxColumnWidth,
                  flexShrink: 0,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontWeight: "semibold",
                  color: "text.secondary",
                }}
              >
                {key}
              </Box>
              <Text>{sanitized}</Text>
            </Private>
          </Row>
        );
      })}
    </>
  );
};

const Header = ({ primaryLabelKey, secondaryLabelKey, row }) => {
  let primary = row[primaryLabelKey];
  let secondary = row[secondaryLabelKey];

  if (typeof primary !== "string") {
    primary = JSON.stringify(primary);
  }

  if (typeof secondary === "object") {
    secondary = JSON.stringify(primary);
  }

  return (
    <Row sx={{ alignItems: "center" }}>
      <Private>
        <Avatar name={primary} />
        <Column sx={{ ml: 4, gap: 1 }}>
          {primary && (
            <TextWithTooltip fontWeight="semibold" message={primary}>
              {primary}
            </TextWithTooltip>
          )}
          {secondary && (
            <TextWithTooltip color="gray.500" size="sm" message={secondary}>
              {secondary}
            </TextWithTooltip>
          )}
        </Column>
      </Private>
    </Row>
  );
};
