import { Suspense, useState } from "react";
import { Column, EmptyState, Row, Spinner } from "@hightouchio/ui";

import genericPlaceholder from "src/assets/placeholders/generic.svg";
import { Outlet, useSearchParams } from "src/router";
import { BulkDestinationAlertTriggerManagement } from "src/components/resource-alert-triggers/bulk-management";
import { Destinations } from "./components/destinations";

export const Alerts = () => {
  const [search] = useSearchParams();
  const [destinationIds, setDestinationIds] = useState<Array<string>>(
    search.get("destinations")?.split(",") || [],
  );

  return (
    <Column flex={1} minH={0}>
      <Row flex={1} minH={0}>
        <Destinations onChange={setDestinationIds} value={destinationIds} />
        {destinationIds.length ? (
          <Suspense fallback={<Spinner size="lg" m="auto" />}>
            <BulkDestinationAlertTriggerManagement
              destinationIds={destinationIds}
            />
          </Suspense>
        ) : (
          <Column p={6} flex={1}>
            <EmptyState
              imageUrl={genericPlaceholder}
              title="Select a destination to configure"
              message=""
            />
          </Column>
        )}
      </Row>
      <Outlet />
    </Column>
  );
};
