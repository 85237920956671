import { Edge, getOutgoers } from "reactflow";

import { JourneyNode } from "src/pages/journeys/types";
import { JourneyNodeType } from "src/types/journeys";
import { isSegmentBranchNode, isWaitUntilBranchNode } from "./type-guards";

/**
 * Get all branch nodes of a given node
 */
export const getBranchNodes = (
  nodeId: string,
  nodes: JourneyNode[],
  edges: Edge[],
) => {
  const parentNode = nodes.find((node) => node.id === nodeId);

  if (parentNode) {
    const outgoingNodes = getOutgoers(parentNode, nodes, edges);

    // Only segments and wait until event nodes have branch nodes
    if (parentNode.type === JourneyNodeType.Segments) {
      return outgoingNodes.filter(isSegmentBranchNode);
    }

    if (parentNode.type === JourneyNodeType.WaitUntilEvent) {
      return outgoingNodes.filter(isWaitUntilBranchNode);
    }
  }

  return [];
};
