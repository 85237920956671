import { FC } from "react";

import {
  ConfirmationDialog,
  Spinner,
  Paragraph,
  Text,
  Box,
  useToast,
} from "@hightouchio/ui";
import { Link } from "src/router";
import { useNavigate, useOutletContext, useParams } from "src/router";

import { Warning } from "src/components/warning";
import { useDeleteTunnelMutation, useTunnelSourceQuery } from "src/graphql";

import { TunnelsOutletContext } from ".";

export const DeleteTunnel: FC = () => {
  const { refetch, tunnels, reverseTunnels } =
    useOutletContext<TunnelsOutletContext>();
  const params = useParams<{ id: string }>();
  const { toast } = useToast();
  const navigate = useNavigate();

  const tunnel = [...tunnels, ...reverseTunnels].find(
    (tunnel) => tunnel.tunnel.id === params.id,
  )?.tunnel;

  const { data: tunnelSourceData, isLoading: dependencyLoading } =
    useTunnelSourceQuery({ tunnelID: tunnel?.id ?? "" });
  const { mutateAsync: deleteTunnel } = useDeleteTunnelMutation();

  const dependency = tunnelSourceData?.connections?.[0];

  const close = () => {
    navigate("/settings/tunnels");
  };

  if (!tunnel) {
    return <Warning title="Tunnel not found" />;
  }

  return (
    <ConfirmationDialog
      isOpen
      confirmButtonText="Delete tunnel"
      isConfirmButtonDisabled={dependencyLoading || Boolean(dependency)}
      title="Delete tunnel"
      variant="danger"
      onClose={close}
      onConfirm={async () => {
        try {
          await deleteTunnel({ id: tunnel?.id ?? "" });

          refetch();

          toast({
            id: "delete-tunnel",
            title: "Tunnel deleted",
            variant: "success",
          });
        } catch (error: unknown) {
          toast({
            id: "delete-tunnel",
            title: "Something went wrong",
            message: "Failed to delete the tunnel, please try again.",
            variant: "error",
          });
        }
      }}
    >
      {dependencyLoading ? (
        <Box display="flex" justifyContent="center">
          <Spinner />
        </Box>
      ) : dependency ? (
        <>
          <Paragraph>
            You cannot delete <Text fontWeight="semibold">{tunnel.name}</Text>{" "}
            because the{" "}
            <Link href={`/sources/${dependency.id}`}>{dependency.name}</Link>{" "}
            source depends on it.
          </Paragraph>
        </>
      ) : (
        <Paragraph>
          Are you sure you want to delete the{" "}
          <Text fontWeight="semibold">{tunnel.name}</Text> tunnel?
        </Paragraph>
      )}
    </ConfirmationDialog>
  );
};
