import { useCallback, useMemo } from "react";
import { uniqBy } from "lodash";

import { useAllResourceSubscriptionsQuery } from "src/graphql";
import {
  MonitoredResourceType,
  ParentResourceTypes,
} from "@hightouch/lib/resource-monitoring/types";

type BaseRecipient = {
  id: string;
  channel: {
    id: string;
    channel_type: string;
    config: any;
  };
};

type ResourceNotificationChannel = BaseRecipient & {
  __typename: "resource_notification_channels";
  resource_id: string;
  resource_type: MonitoredResourceType;
};

type NotificationChannelTemplate = BaseRecipient & {
  __typename: "notification_channel_templates";
  id: string;
  parent_resource_id: string;
  parent_resource_type: ParentResourceTypes;
};

export type Recipient =
  | ResourceNotificationChannel
  | NotificationChannelTemplate;

export const useRecipients = () => {
  const { data } = useAllResourceSubscriptionsQuery({}, { suspense: true });

  const overrides = data?.resource_notification_channels ?? [];
  const defaults = data?.notification_channel_templates ?? [];

  const getRecipientsForDestinations = useCallback(
    (destinationIds: string[]): Array<Recipient> => {
      const stringIds = destinationIds.map(String);
      return uniqBy(
        [
          ...overrides.filter((s) =>
            stringIds.includes(s.resource_id.toString()),
          ),
          ...defaults.filter((s) =>
            stringIds.includes(s.parent_resource_id.toString()),
          ),
        ],
        "channel.id",
      ) as Array<Recipient>;
    },
    [overrides, defaults],
  );

  const getRecipientsByDestination = useCallback(
    (destinationIds: string[]) => {
      const stringIds = destinationIds.map(String);
      return stringIds.reduce((acc, id) => {
        acc[id] = [
          ...overrides.filter((s) => s.resource_id.toString() === id),
          ...defaults.filter((s) => s.parent_resource_id.toString() === id),
        ];
        return acc;
      }, {});
    },
    [overrides, defaults],
  );

  return useMemo(
    () => ({ getRecipientsForDestinations, getRecipientsByDestination, data }),
    [getRecipientsForDestinations, getRecipientsByDestination, data],
  );
};
