export const Icon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20px"
    height="20px"
    viewBox="0 0 24 24"
    fill={color}
  >
    <path
      fillRule="evenodd"
      d="M13.008 6.999h-2.093v-1h2.093v1ZM19.833 14h-4.658c-.645 0-1.167.522-1.167 1.167v4.666c0 .645.522 1.167 1.167 1.167h4.658c.645 0 1.167-.522 1.167-1.167v-4.666c0-.645-.522-1.167-1.167-1.167Zm-4.658-1c-1.197 0-2.167.97-2.167 2.167v4.666c0 1.197.97 2.167 2.167 2.167h4.658C21.03 22 22 21.03 22 19.833v-4.666C22 13.97 21.03 13 19.833 13h-4.658Z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="m17.058 15.426 1.702 1.703a.5.5 0 0 1 0 .707l-1.702 1.703-.707-.708 1.349-1.348-1.35-1.35.708-.707ZM19.833 3h-4.658c-.645 0-1.167.522-1.167 1.167V8.82c0 .644.522 1.166 1.167 1.166h4.658c.645 0 1.167-.522 1.167-1.166V4.167C21 3.522 20.478 3 19.833 3Zm-4.658-1c-1.197 0-2.167.97-2.167 2.167V8.82c0 1.196.97 2.166 2.167 2.166h4.658c1.197 0 2.167-.97 2.167-2.166V4.167C22 2.97 21.03 2 19.833 2h-4.658ZM8.806 3h-4.64C3.523 3 3 3.522 3 4.167v4.639c0 .644.522 1.166 1.167 1.166h4.639c.644 0 1.166-.522 1.166-1.166v-4.64C9.972 3.523 9.45 3 8.806 3Zm-4.64-1C2.97 2 2 2.97 2 4.167v4.639c0 1.196.97 2.166 2.167 2.166h4.639c1.196 0 2.166-.97 2.166-2.166v-4.64C10.972 2.97 10.002 2 8.806 2h-4.64Z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="m6.062 4.433 1.7 1.7a.5.5 0 0 1 0 .707l-1.7 1.7-.707-.708 1.346-1.346-1.346-1.345.707-.708Zm2.746 9.551H4.167c-.645 0-1.167.523-1.167 1.167v4.691c0 .644.522 1.167 1.167 1.167h4.641c.645 0 1.167-.523 1.167-1.167v-4.691c0-.644-.522-1.167-1.167-1.167Zm-4.641-1c-1.197 0-2.167.97-2.167 2.167v4.691c0 1.197.97 2.167 2.167 2.167h4.641c1.197 0 2.167-.97 2.167-2.167v-4.691c0-1.197-.97-2.167-2.167-2.167H4.167Zm8.841 5.023h-2.016v-1h2.016v1Z"
      clipRule="evenodd"
    />
  </svg>
);
