import { FC, useEffect, useRef } from "react";

import { BoxProps, Column, Row, Text } from "@hightouchio/ui";

import { IconBox } from "src/components/icon-box";

type FilterPopoverTabProps = {
  bg: string;
  description: string;
  icon: JSX.Element;
  isFocused?: boolean;
  isSelected?: boolean;
  label: string;
  onClick?: () => void;
};

export const FilterPopoverTab: FC<
  Readonly<FilterPopoverTabProps & BoxProps>
> = ({
  bg,
  description,
  icon,
  isFocused = false,
  isSelected = false,
  label,
  ...props
}) => {
  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (isFocused) {
      ref.current?.focus();
    }
  }, [isFocused]);

  return (
    <Row
      ref={ref}
      as="button"
      align="center"
      role="tab"
      _active={{ borderColor: "primary.base", borderRadius: "md" }}
      _hover={{ bg: isSelected ? "forest.200" : "base.background" }}
      _focus={{ boxShadow: "inset 0 0 0 4px #E0F3F5" }}
      bg={isSelected ? "primary.background" : "transparent"}
      borderRight={isSelected ? "2px" : 0}
      borderColor="transparent"
      borderRightColor={isSelected ? "primary.base" : "transparent"}
      borderTopLeftRadius="md"
      borderBottomLeftRadius="md"
      height={16}
      gap={4}
      px={4}
      py={3}
      outline="none !important"
      transition="border-color 80ms ease-in"
      {...props}
    >
      <IconBox bg={bg} icon={icon} boxSize={10} iconSize={6} />
      <Column
        justify="start"
        overflow="hidden"
        sx={{ span: { textAlign: "left" } }}
      >
        <Text
          color={isSelected ? "primary.base" : "text.secondary"}
          fontWeight="medium"
        >
          {label}
        </Text>
        <Text color="text.tertiary" size="sm">
          {description}
        </Text>
      </Column>
    </Row>
  );
};
