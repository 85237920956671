import {
  Box,
  Checkbox,
  Column,
  InformationIcon,
  Row,
  Text,
  Tooltip,
} from "@hightouchio/ui";
import { repeat } from "lodash";
import { Controller, useFormContext } from "react-hook-form";
import { getCheckboxProps } from "src/components/grant-select/util";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { Cell, HeaderCell } from "src/ui/table/cells";
import { AdditionalConfigurationProps, ResourceDefinition } from "./types";
import { DEFAULT_GRANT_PLACEHOLDER, names } from "./grant-form";

export function GrantTableForm({
  grantOptions,
  resourceType,
  resources,
  isWorkspaceAdmin,
}: {
  grantOptions: Array<{ label: string; value: string; description?: string }>;
  resourceType: string;
  resources: Array<ResourceDefinition>;
  isWorkspaceAdmin: boolean;
}) {
  return (
    <Box
      as="table"
      display="grid"
      gridTemplateColumns={`1fr ${repeat(" 0.5fr", grantOptions.length)}`}
    >
      <Box as="thead" display="contents">
        <HeaderCell alignFirstHeader={true}>Name</HeaderCell>
        {grantOptions.map(({ label, value, description }) => {
          return (
            <HeaderCell key={value}>
              <Row gap={1}>
                {label}
                {description ? (
                  <Tooltip message={description}>
                    <InformationIcon />
                  </Tooltip>
                ) : null}
              </Row>
            </HeaderCell>
          );
        })}
      </Box>
      <GrantRow
        id={DEFAULT_GRANT_PLACEHOLDER}
        isWorkspaceAdmin={isWorkspaceAdmin}
        grantOptions={grantOptions}
        isDefaultGrant
      >
        <Column py={4}>
          <TextWithTooltip fontWeight="medium">
            {`All ${names[resourceType]} in this workspace`}
          </TextWithTooltip>
          <Text color="text.secondary">
            This includes any new {names[resourceType]} created in the future.
          </Text>
        </Column>
      </GrantRow>
      <Box as="tbody" display="contents">
        {resources.map((resource) => {
          return (
            <GrantGroup
              key={resource.id}
              isWorkspaceAdmin={isWorkspaceAdmin}
              grantOptions={grantOptions}
              resource={resource}
            />
          );
        })}
      </Box>
    </Box>
  );
}

type GrantGroupProps = {
  resource: ResourceDefinition;
  isWorkspaceAdmin: boolean;
  grantOptions: Array<{ value: string }>;
};

function GrantGroup({
  resource,
  isWorkspaceAdmin,
  grantOptions,
}: GrantGroupProps) {
  return (
    <GrantRow
      id={resource.id}
      isWorkspaceAdmin={isWorkspaceAdmin}
      grantOptions={grantOptions}
      additionalConfiguration={resource.additionalConfiguration}
    >
      <Row align="center" gap={2}>
        <IntegrationIcon
          src={resource.definition.icon}
          name={resource.definition.name}
        />
        <TextWithTooltip fontWeight="medium">{resource.name}</TextWithTooltip>
      </Row>
    </GrantRow>
  );
}

type GrantRowProps = {
  id: string;
  children: React.ReactNode;
  isWorkspaceAdmin: boolean;
  grantOptions: Array<{ value: string }>;
  isDefaultGrant?: boolean;
  additionalConfiguration?: React.FC<AdditionalConfigurationProps>;
};

function GrantRow({
  id,
  isWorkspaceAdmin,
  grantOptions,
  children,
  isDefaultGrant = false,
  additionalConfiguration,
}: GrantRowProps) {
  const form = useFormContext();

  const defaultGrant = !isDefaultGrant
    ? form.watch(`grants.${DEFAULT_GRANT_PLACEHOLDER}`)
    : undefined;

  const cellStyle = isDefaultGrant
    ? {
        borderBottom: "2px",
        borderColor: "base.border",
        backgroundColor: "base.lightBackground",
      }
    : { height: "60px" };

  const AdditionalConfigurationComponent =
    additionalConfiguration ?? (() => null);

  return (
    <Box display="contents">
      <Box as="tr" display="contents">
        <Cell {...cellStyle}>
          <Row align="center" gap={2}>
            {children}
          </Row>
        </Cell>
        {grantOptions.map(({ value }) => {
          return (
            <Cell {...cellStyle} key={`${id}-${value}`}>
              <Controller
                name={`grants.${id}.${value}`}
                render={({ field }) => {
                  const { checkboxProps, tooltipProps } = getCheckboxProps({
                    value: field.value,
                    defaultValue: defaultGrant?.[value],
                    isWorkspaceAdmin,
                    isBuiltIn: false, // Built-in roles cannot access this form
                  });

                  return (
                    <Tooltip {...tooltipProps}>
                      <Checkbox {...checkboxProps} onChange={field.onChange} />
                    </Tooltip>
                  );
                }}
              />
            </Cell>
          );
        })}
      </Box>
      {Boolean(additionalConfiguration) && (
        <Controller
          name={`additional_data.${id}`}
          render={({ field }) => {
            return (
              <AdditionalConfigurationComponent
                value={field.value}
                onChange={field.onChange}
              />
            );
          }}
        />
      )}
    </Box>
  );
}
