import { ChartType } from "src/graphql";

import { GraphType } from "../analytics/types";

export const chartTypeToGraphType: Record<
  ChartType,
  Omit<GraphType, GraphType.Funnel>
> = {
  // TODO(samuel): Uncomment when funnels are supported
  // FUNNEL: GraphType.Funnel,
  BREAKDOWN: GraphType.Breakdown,
  PERFORMANCE: GraphType.Performance,
};
