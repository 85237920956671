import { isPlainObject, set } from "lodash";
import * as Yup from "yup";
import {
  ComponentType,
  type FormkitForm,
  type FormkitNode,
  ModifierType,
  NodeType,
  type RadioGroupComponent,
} from "../..";
import {
  type Context,
  type ConditionalSchema,
  exhaustiveCheck,
  getConditionForFormkitBoolean,
  or,
  withPrerequisite,
} from "./common";

/**
 * WHAT WE'RE TRYING TO ACHIEVE WITH THIS IMPLEMENTATION:
 *
 * The idea is that each property in the schema is building a ConditionalSchema as its value. We can
 * think of the ConditionalSchema as a "function onion". At the core of the function onion is a function
 * that returns a component's individual validation rule. Each time we reach any sort of condition,
 * we wrap the current state of the "function onion" in another "function layer", which is a function
 * that takes in a config and ctx and returns a boolean based on the condition and state of the config.
 * (These are functions of the Condition type.)
 *
 * In the end, we want to return a function that takes in a config and ctx, passing the config
 * and ctx down through each function-layer of the "function onion", ultimately resulting in
 * either the appropriate validation rule for the component or undefined if a condition is not
 * met at any layer.
 */

/**
 * Retrieves Yup schema for form and wraps schema in a function to be used
 * in a Yup.lazy() call, which will evaluate the schema lazily.
 **/
export function getYupSchemaForForm(
  form: FormkitForm,
  ctx: Context,
): {
  validate: (config: any, opts: { abortEarly: boolean }) => any;
  isValid: (config: any) => Promise<boolean>;
} {
  const schema = getYupSchema(form.children);

  const lazySchemaFunction = (config) => {
    const evaluatedSchema = {};

    for (const [property, value] of Object.entries(schema)) {
      evaluatedSchema[property] = value(config, ctx);
    }

    const nestedSchema: NestedSchema = {};
    for (const property in evaluatedSchema) {
      set(nestedSchema, property, evaluatedSchema[property]);
    }

    return nestedValidation(nestedSchema, form.noSortEdges);
  };

  return Yup.lazy(lazySchemaFunction);
}

type NestedSchema = {
  [k: string]: Yup.AnySchema | NestedSchema;
};

const isRecord = (o: unknown): o is Record<string, unknown> => isPlainObject(o);

function nestedValidation(
  nestedSchema: NestedSchema,
  noSortEdges?: [string, string][],
): Yup.AnySchema {
  const acc: Record<string, Yup.AnySchema> = {};

  for (const property in nestedSchema) {
    const value = nestedSchema[property];
    if (isRecord(value)) {
      acc[property] = nestedValidation(value);
    } else if (value !== undefined) {
      acc[property] = value;
    }
  }

  // We need to add  default `{}` so we don't get weird error message below.
  // `"Cannot use 'in' operator to search for 'default' in undefined"`
  return Yup.object()
    .shape(acc, noSortEdges || [])
    .default({});
}

export type ProcessedYupError = Record<string, unknown>;

export function processYupError(error): ProcessedYupError {
  if (error.inner) {
    if (error.inner.length === 0) {
      return {
        [error.path]: error.message,
      };
    }
    return error.inner.reduce(
      (errors, { path, message }) => ({ ...errors, [path]: message }),
      {},
    );
  }

  return {};
}

/**
 * Gets a Yup schema for the given form that can be used to validate any
 * config. It should be reinstantiated everytime the context changes.
 **/
export function getYupSchema(nodes: FormkitNode[]): {
  [key: string]: ConditionalSchema;
} {
  const schema: Record<string, ConditionalSchema> = {};

  for (const node of nodes) {
    let childrenSchema: Record<string, ConditionalSchema> = {};

    if (node.type === NodeType.Component) {
      if (node.children) {
        childrenSchema = getYupSchema(node.children);
      }

      //Allow for null values on radio group when there's undefined in options
      //In frontend, undefined is automatically converted to null
      if (node.component === ComponentType.RadioGroup) {
        const radioNode = node as RadioGroupComponent;
        if (
          Array.isArray(radioNode.props.options) &&
          radioNode.props.options.some((o) => o.value === undefined) &&
          Yup.isSchema(node.props.validation)
        ) {
          node.props.validation = (
            node.props.validation as Yup.AnySchema
          ).nullable();
        }
      }

      childrenSchema[node.key] = () => node.props.validation;
    } else if (
      node.type === NodeType.Modifier &&
      node.modifier === ModifierType.Show
    ) {
      childrenSchema = getYupSchema(node.children);
      const condition = getConditionForFormkitBoolean(node.condition ?? false);

      for (const [property, value] of Object.entries(childrenSchema)) {
        childrenSchema[property] = withPrerequisite(condition, value);
      }
    } else if (
      node.type === NodeType.Layout ||
      node.type === NodeType.Modifier
    ) {
      childrenSchema = getYupSchema(node.children);
    } else {
      exhaustiveCheck(node);
    }

    for (const [key, value] of Object.entries(childrenSchema)) {
      // Not sure why TS thinks that `schema[key]` can be `undefined` here, revisit this later and remove `!`
      schema[key] = schema[key] ? or(schema[key]!, value) : value;
    }
  }
  return schema;
}
