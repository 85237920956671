export const LowerGlowPurple = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="51"
      height="58"
      viewBox="0 0 51 58"
      fill="none"
    >
      <g opacity="0.3" filter="url(#filter0_f_759_56761)">
        <circle cx="2.5" cy="48.5" r="18.5" fill="#BA8BF9" />
      </g>
      <defs>
        <filter
          id="filter0_f_759_56761"
          x="-46"
          y="0"
          width="97"
          height="97"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="15"
            result="effect1_foregroundBlur_759_56761"
          />
        </filter>
      </defs>
    </svg>
  );
};

export const LowerGlowBlue = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="113"
      height="38"
      viewBox="0 0 113 38"
      fill="none"
    >
      <g opacity="0.3" filter="url(#filter0_f_759_56760)">
        <ellipse cx="50.5" cy="48.5" rx="32.5" ry="18.5" fill="#91D4EB" />
      </g>
      <defs>
        <filter
          id="filter0_f_759_56760"
          x="-12"
          y="0"
          width="125"
          height="97"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="15"
            result="effect1_foregroundBlur_759_56760"
          />
        </filter>
      </defs>
    </svg>
  );
};
