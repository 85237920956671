import { useEffect, useMemo, useState } from "react";

import {
  Alert,
  AudienceIcon,
  Avatar,
  Badge,
  Box,
  Column,
  DeleteIcon,
  Heading,
  IconButton,
  Row,
  Spinner,
  Text,
  useToast,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useFieldArray } from "react-hook-form";
import { Link, useNavigate, useParams } from "src/router";
import { isPresent } from "ts-extras";

import { ActionBar } from "src/components/action-bar";
import { DetailBar } from "src/components/detail-bar";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { DetailPage } from "src/components/layout";
import { DeleteConfirmationModal } from "src/components/modals/delete-confirmation-modal";
import {
  PermissionedButton,
  PermissionedEditableHeading,
} from "src/components/permission";
import { PermissionProvider } from "src/components/permission/permission-context";
import {
  AudiencesForPriorityListsQuery,
  SegmentsBoolExp,
  SegmentsOrderBy,
  useAudiencesForPriorityListsQuery,
  useDeletePriorityListMutation,
  useObjectQuery,
  usePriorityListQuery,
  useUpdatePriorityListMutation,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { useTableConfig } from "src/ui/table";
import { formatDate } from "src/utils/time";

import { AudienceSelector } from "./audience-selector";
import { Reorder } from "src/components/reorder";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { SyncsCell } from "src/pages/syncs/sync/components/syncs-cell";

type Audience = AudiencesForPriorityListsQuery["segments"][number];
type FormData = { audiences: Audience[] };

export const PriorityList = () => {
  const { priority_list_id } = useParams();
  const { schemaV2 } = useFlags();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [showAddAudiences, setShowAddAudiences] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const updatePriorityListMutation = useUpdatePriorityListMutation({
    onSuccess: () => {
      // Don't update cache to avoid re-rendering
    },
  });
  const deletePriorityListMutation = useDeletePriorityListMutation();

  const priorityListQuery = usePriorityListQuery(
    { id: priority_list_id ?? "" },
    { enabled: Boolean(priority_list_id) },
  );

  const priorityList = priorityListQuery.data?.priority_lists_by_pk;
  const parentModelId = priorityList?.parent_model.id;
  const priorityListAudienceIds = priorityList?.priority_list_memberships?.map(
    ({ segment }) => segment.id.toString(),
  );

  const { limit, offset, orderBy } = useTableConfig<SegmentsOrderBy>({
    defaultSortKey: "updated_at",
    limit: 100_000,
  });

  const filters: SegmentsBoolExp = useMemo(() => {
    const hasuraFilters: SegmentsBoolExp = {
      id: { _in: priorityListAudienceIds },
      query_type: { _eq: "visual" },
      visual_query_parent_id: { _eq: parentModelId },
    };

    return hasuraFilters;
  }, [parentModelId, priorityListAudienceIds]);

  // Fetch audiences data to display in priority list
  const audiencesForPriorityListsQuery = useAudiencesForPriorityListsQuery(
    {
      filters,
      limit,
      offset,
      orderBy,
    },
    {
      enabled: isPresent(parentModelId),
    },
  );

  // Get parent model information
  const { data: parentModel, isLoading: parentModelLoading } = useObjectQuery(
    {
      id: String(parentModelId),
    },
    {
      enabled: Boolean(parentModelId),
      select: (data) => data.segments_by_pk,
    },
  );

  const parentModelSource = parentModel?.connection;
  const initialAudiences = useMemo(() => {
    const result: AudiencesForPriorityListsQuery["segments"] = [];

    // preserve rank order sent from server
    priorityList?.priority_list_memberships?.forEach(({ segment: { id } }) => {
      const audience = audiencesForPriorityListsQuery.data?.segments.find(
        ({ id: audienceId }) => id === audienceId,
      );
      if (audience) {
        result.push(audience);
      }
    });

    return result;
  }, [
    priorityList?.priority_list_memberships,
    audiencesForPriorityListsQuery.data?.segments,
  ]);

  const form = useHightouchForm<FormData>({
    onSubmit: async (data: FormData) => {
      await updatePriorityListMutation.mutateAsync({
        id: priority_list_id ?? "",
        audienceIds: data.audiences.map(({ id }) => id.toString()),
      });

      trackPriorityListUpdates();
    },
    success: "Priority list updated",
    error: "Failed to update this priority list",
    defaultValues: {
      audiences: initialAudiences,
    },
  });

  useEffect(() => {
    // Only reset if length changes. This prevents jarring re-renders when the name is updated.
    form.reset({
      audiences: initialAudiences,
    });
  }, [initialAudiences?.length]);

  useEffect(() => {
    if (form.formState.isSubmitSuccessful) {
      form.reset(form.getValues());
    }
  }, [form.formState.isSubmitSuccessful]);

  const audiences = form.watch("audiences");

  const { fields, remove, replace } = useFieldArray({
    control: form.control,
    name: "audiences",
    keyName: "generatedKey",
  });

  const trackPriorityListUpdates = () => {
    analytics.track("Priority List Updated", {
      priority_list_id: priority_list_id,
      parent_model_id: parentModelId,
    });
  };

  const saveName = async (name: string) => {
    if (name === priorityList?.name) {
      // Don't write out if we don't change anything
      return;
    }

    try {
      await updatePriorityListMutation.mutateAsync({
        id: priority_list_id ?? "",
        name,
      });

      trackPriorityListUpdates();

      toast({
        id: "update-priority-list-toast",
        title: "Priority list name updated",
        variant: "success",
      });
    } catch (error) {
      toast({
        id: "update-priority-list-toast",
        title: "Failed to update this priority list",
        variant: "error",
      });

      Sentry.captureException(error);
    }
  };

  const deletePriorityList = async () => {
    try {
      await deletePriorityListMutation.mutateAsync({
        id: priority_list_id ?? "",
      });

      analytics.track("Priority List Deleted", {
        priority_list_id,
        parent_model_id: priorityList?.parent_model?.id,
        priority_list_name: priorityList?.name,
      });

      // success toast shown in delete confirmation modal

      navigate("/priority-lists");
    } catch (error) {
      toast({
        id: "delete-model-error",
        title: "Failed to delete this priority list",
        variant: "error",
      });
      Sentry.captureException(error);
    }
  };

  const priorityListLoading =
    priorityListQuery.isLoading || audiencesForPriorityListsQuery.isLoading;

  const updatedByUsername =
    priorityList?.updated_by_user?.name || priorityList?.created_by_user?.name;

  return (
    <PermissionProvider
      permission={{
        v1: { resource: "audience", grant: "read" },
      }}
    >
      <DetailPage
        crumbs={[{ label: "All priority lists", link: "/priority-lists" }]}
        bg="base.lightBackground"
        header={
          <Column gap={4} mb={4}>
            <Row align="flex-end" justify="space-between">
              <PermissionedEditableHeading
                // TODO[Permissions]: Add V2 permissions where you have to be able to update all audiences
                permission={{
                  v1: {
                    resource: "audience",
                    grant: "update",
                  },
                }}
                size="lg"
                value={priorityList?.name ?? ""}
                onChange={saveName}
              />

              <PermissionedButton
                // TODO[Permissions]: Add V2 permissions where you have to be able to update all audiences
                permission={{
                  v1: {
                    resource: "audience",
                    grant: "update",
                  },
                }}
                variant="warning"
                isLoading={deletePriorityListMutation.isLoading}
                onClick={() => setShowDeleteModal(true)}
              >
                Delete
              </PermissionedButton>
            </Row>
            <DetailBar>
              <Row align="center" gap={2} flexShrink={0}>
                <IntegrationIcon
                  src={parentModelSource?.definition?.icon}
                  name={parentModelSource?.definition?.name}
                />
                <Link
                  href={
                    schemaV2
                      ? `/schema-v2/view?source=${parentModelSource?.id}&id=${parentModel?.id}`
                      : `/schema/parent-models/${parentModel?.id}`
                  }
                >
                  <Text isTruncated color="inherit">
                    {parentModel?.name ?? "Private model"}
                  </Text>
                </Link>
              </Row>
              <Row align="center" gap={2} flexShrink={0}>
                <Text>Last updated:</Text>
                <Row gap={1} align="center">
                  {formatDate(
                    (priorityList?.updated_at || priorityList?.created_at)!,
                  )}
                  {updatedByUsername && (
                    <>
                      <Text>by</Text>
                      <Avatar size="xs" name={updatedByUsername} />
                    </>
                  )}
                </Row>
              </Row>
            </DetailBar>
          </Column>
        }
        title={`${
          priorityList?.name ?? "Unnamed priority list"
        } - Priority lists - Audiences`}
      >
        {priorityListLoading || parentModelLoading ? (
          <Column flex={1} minHeight={0} align="center" justify="center">
            <Spinner size="lg" />
          </Column>
        ) : (
          <Form form={form}>
            <Row flex={1} justify="space-between" minHeight={0} gap={8}>
              <Column flex={1} minHeight={0}>
                {priorityListQuery.error ||
                audiencesForPriorityListsQuery.error ? (
                  <Column flex={1} minHeight={0}>
                    <Alert
                      variant="inline"
                      message="There was a problem fetching this priority list"
                      title="There was a problem"
                      type="error"
                    />
                  </Column>
                ) : (
                  <>
                    <Row align="center" justify="space-between" mb={6}>
                      <Heading>Set priority</Heading>
                      <PermissionedButton
                        // TODO[Permissions]: Add V2 permissions where you have to be able to update all audiences
                        permission={{
                          v1: {
                            resource: "audience",
                            grant: "update",
                          },
                        }}
                        onClick={() => setShowAddAudiences(true)}
                      >
                        Add audiences
                      </PermissionedButton>
                    </Row>

                    <Reorder nodeKey="id" onChange={replace} items={fields}>
                      {fields.map((field, index) => (
                        <Row key={field.id} align="center" gap={2} width="100%">
                          <Column gap={2} width="100%">
                            <Box
                              display="grid"
                              gap={1}
                              gridTemplateColumns="3fr repeat(3, 1fr) 50px"
                            >
                              <Text
                                fontWeight="semibold"
                                color="text.secondary"
                                letterSpacing="wide"
                                size="sm"
                                textTransform="uppercase"
                              >
                                Audience
                              </Text>
                              <Text
                                fontWeight="semibold"
                                color="text.secondary"
                                letterSpacing="wide"
                                size="sm"
                                textTransform="uppercase"
                              >
                                Size
                              </Text>
                              <Text
                                fontWeight="semibold"
                                color="text.secondary"
                                letterSpacing="wide"
                                size="sm"
                                textTransform="uppercase"
                              >
                                Syncs
                              </Text>
                              <Text
                                fontWeight="semibold"
                                color="text.secondary"
                                letterSpacing="wide"
                                size="sm"
                                textTransform="uppercase"
                              >
                                Last Updated
                              </Text>
                            </Box>
                            <Row
                              sx={{
                                alignItems: "center",
                                bg: "white",
                                borderRadius: "8px",
                                display: "grid",
                                gap: 1,
                                gridTemplateColumns: "3fr repeat(3, 1fr) 50px",
                                width: "100%",
                                transition: "box-shadow ease-in 0.2s",
                              }}
                            >
                              <Row maxWidth="100%" overflow="hidden">
                                <TextWithTooltip message={field.name}>
                                  <Link
                                    href={`/audiences/${field.id}`}
                                    target="_blank"
                                  >
                                    {field.name}
                                  </Link>
                                </TextWithTooltip>
                              </Row>
                              <Row>
                                <Badge icon={AudienceIcon}>
                                  {field?.query_runs?.[0]?.size ?? "--"}
                                </Badge>
                              </Row>
                              <Row>
                                <SyncsCell syncs={field.syncs} />
                              </Row>
                              <Row align="center">
                                <Text fontWeight="medium">
                                  {formatDate(
                                    (field?.updated_at || field?.created_at)!,
                                  )}
                                </Text>
                                <Box mr={1}>
                                  <Text color="gray.600">&nbsp;by</Text>
                                </Box>
                                <Avatar
                                  name={
                                    field?.updated_by_user?.name ||
                                    field?.created_by_user?.name
                                  }
                                />
                              </Row>
                            </Row>
                          </Column>

                          <Row>
                            <IconButton
                              aria-label="Remove list option"
                              icon={DeleteIcon}
                              variant="danger"
                              onClick={() => remove(index)}
                            />
                          </Row>
                        </Row>
                      ))}
                    </Reorder>
                  </>
                )}
              </Column>
            </Row>
            <ActionBar>
              <FormActions
                permission={{ v1: { resource: "audience", grant: "update" } }}
              />
            </ActionBar>
            <AudienceSelector
              isOpen={showAddAudiences}
              parentModelId={parentModelId}
              selectedData={audiences}
              onClose={() => setShowAddAudiences(false)}
              onSubmit={replace}
            />

            <DeleteConfirmationModal
              isOpen={showDeleteModal}
              label="priority list"
              onClose={() => {
                setShowDeleteModal(false);
              }}
              onDelete={deletePriorityList}
            />
          </Form>
        )}
      </DetailPage>
    </PermissionProvider>
  );
};
