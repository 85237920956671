import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const SlackIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        d="M6.21639 14.6333C6.21639 15.7948 5.27764 16.7335 4.11615 16.7335C2.95465 16.7335 2.01591 15.7948 2.01591 14.6333C2.01591 13.4718 2.95465 12.533 4.11615 12.533H6.21639V14.6333ZM7.26651 14.6333C7.26651 13.4718 8.20525 12.533 9.36675 12.533C10.5282 12.533 11.467 13.4718 11.467 14.6333V19.8839C11.467 21.0453 10.5282 21.9841 9.36675 21.9841C8.20525 21.9841 7.26651 21.0453 7.26651 19.8839V14.6333Z"
        fill="#E01E5A"
      />
      <path
        d="M9.36675 6.20048C8.20525 6.20048 7.26651 5.26173 7.26651 4.10024C7.26651 2.93874 8.20525 2 9.36675 2C10.5282 2 11.467 2.93874 11.467 4.10024V6.20048H9.36675ZM9.36675 7.26651C10.5282 7.26651 11.467 8.20525 11.467 9.36675C11.467 10.5282 10.5282 11.467 9.36675 11.467H4.10024C2.93874 11.467 2 10.5282 2 9.36675C2 8.20525 2.93874 7.26651 4.10024 7.26651H9.36675Z"
        fill="#36C5F0"
      />
      <path
        d="M17.7836 9.36675C17.7836 8.20525 18.7224 7.26651 19.8839 7.26651C21.0453 7.26651 21.9841 8.20525 21.9841 9.36675C21.9841 10.5282 21.0453 11.467 19.8839 11.467H17.7836V9.36675ZM16.7335 9.36675C16.7335 10.5282 15.7947 11.467 14.6333 11.467C13.4718 11.467 12.533 10.5282 12.533 9.36675V4.10024C12.533 2.93874 13.4718 2 14.6333 2C15.7947 2 16.7335 2.93874 16.7335 4.10024V9.36675Z"
        fill="#2EB67D"
      />
      <path
        d="M14.6333 17.7836C15.7947 17.7836 16.7335 18.7224 16.7335 19.8839C16.7335 21.0453 15.7947 21.9841 14.6333 21.9841C13.4718 21.9841 12.533 21.0453 12.533 19.8839V17.7836H14.6333ZM14.6333 16.7335C13.4718 16.7335 12.533 15.7948 12.533 14.6333C12.533 13.4718 13.4718 12.533 14.6333 12.533H19.8998C21.0613 12.533 22 13.4718 22 14.6333C22 15.7948 21.0613 16.7335 19.8998 16.7335H14.6333Z"
        fill="#ECB22E"
      />
    </Icon>
  );
};
