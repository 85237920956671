import { useEffect, useMemo, useState } from "react";
import { ProcessFormNode } from "../../../../../formkit/formkit";
import {
  useHightouchForm,
  Form,
  SaveButton,
  DiscardButton,
} from "../../../../../components/form";
import {
  DestinationDefinitionFragment,
  useFormkitSharedConfigValidationQuery,
} from "../../../../../graphql";
import { cleanConfig } from "../../../../../components/destinations/utils";
import { useQueryClient } from "react-query";
import {
  FormkitDestination,
  FormkitProvider,
} from "../../../../../formkit/components/formkit-context";
import { SidebarForm } from "../../../../../components/page";
import { Column, Row } from "@hightouchio/ui";
import { DestinationFormProvider } from "../../../../../contexts/destination-form-context";
import { eventSourceDefinitions } from "../../../../types";

export default function ConfigurationForm<Config>({
  formkitDefinition,
  destination,
  config,
  submit,
  submitTrigger,
  source,
  validation,
}: {
  formkitDefinition: any;
  destination: {
    type: string;
    name: string | null;
    id: any;
    definition: { name: string; icon: string };
  };
  source: {
    type: string;
    name: string;
  };
  config: Config;
  submit: (config: Config) => Promise<any>;
  // Allow parent to handle form submission
  submitTrigger?: React.MutableRefObject<
    (() => Promise<Config | undefined>) | undefined
  >;
  validation?: typeof useFormkitSharedConfigValidationQuery;
}) {
  const [isInitialized, setIsInitialized] = useState(false);
  const [errors, setErrors] = useState<string[]>();

  const validate = useValidate(
    destination?.type,
    validation ?? useFormkitSharedConfigValidationQuery,
  );
  const onSubmit = async (data) => {
    methods.clearErrors();
    const cleanedConfig = cleanConfig(data);
    const errors = await validate(cleanedConfig);
    if (typeof errors === "object" && Object.keys(errors).length) {
      Object.entries(errors).forEach(([key, message]) => {
        methods.setError(key, { message: String(message) });
      });

      setErrors(errors);
      throw "Couldn't save the sync configuration";
    } else {
      setErrors(undefined);

      return await submit(data);
    }
  };
  const methods = useHightouchForm({ onSubmit });
  if (submitTrigger) {
    submitTrigger.current = () => onSubmit(methods.getValues());
  }
  const formkit = useMemo(() => {
    if (!formkitDefinition) {
      return null;
    }
    return <ProcessFormNode node={formkitDefinition} />;
  }, [formkitDefinition]);

  // XXX: Existing state should be set via reset without setting the default values
  // This is because default values should be set by the components only
  useEffect(() => {
    if (config && Object.keys(config).length) {
      methods.reset(config, { keepDefaultValues: true });
    }
    setIsInitialized(true);
  }, [config]);

  if (!isInitialized) {
    return null;
  }

  return (
    <>
      <FormkitProvider
        validate={validate}
        destination={destination as FormkitDestination}
        destinationDefinition={
          destination.definition as DestinationDefinitionFragment
        }
        sourceDefinition={{
          ...eventSourceDefinitions[source.type],
          ...source,
        }}
      >
        <DestinationFormProvider
          errors={errors}
          setErrors={setErrors}
          config={{}}
          /* eslint-disable-next-line @typescript-eslint/no-empty-function */
          setConfig={() => {}}
          setCustomValidation={() => {}}
        >
          <Form form={methods}>
            <Row py={6}>
              <Column gap={8}>{formkit}</Column>
              <SidebarForm
                docsUrl=""
                name={destination.name ?? destination.type}
                buttons={
                  submitTrigger
                    ? []
                    : [
                        <SaveButton
                          permission={{
                            v1: { resource: "workspace", grant: "update" },
                            v2: {
                              resource: "workspace",
                              grant: "can_update",
                            },
                          }}
                          key={0}
                        />,
                        <DiscardButton key={1} />,
                      ]
                }
              />
            </Row>
          </Form>
        </DestinationFormProvider>
      </FormkitProvider>
    </>
  );
}

function useValidate(
  type: string | undefined,
  validationQuery: typeof useFormkitSharedConfigValidationQuery,
) {
  const client = useQueryClient();
  const formkitValidate = async (config) => {
    const response = await client.fetchQuery({
      queryFn: validationQuery.fetcher({
        type: type ?? "",
        config,
      }),
      queryKey: validationQuery.getKey(config),
    });

    return response.validation;
  };

  return async (config) => {
    const cleanedConfig = cleanConfig(config);
    const errors = await formkitValidate(cleanedConfig);
    if (typeof errors === "object" && Object.keys(errors).length) {
      return errors;
    }
  };
}
