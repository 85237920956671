import {
  DecisionEngineChannelConfigType,
  DecisionEngineChannelType,
} from "@hightouch/lib/customer-data/decision-engine/types";
import { Controller, useFormContext } from "react-hook-form";
import { Combobox, FormField, Radio, RadioGroup } from "@hightouchio/ui";

import { DestinationsQuery } from "src/graphql";
import { IdentifierField } from "./identifier-field";

type ChannelForm = (props: {
  destinations: DestinationsQuery["destinations"];
  prefix?: string;
}) => JSX.Element;

export const StandardForm: ChannelForm = ({ destinations, prefix = "" }) => {
  const { watch } = useFormContext();
  const destinationId = watch(`${prefix}destination_id`);
  const destination = destinations?.find((d) => d.id === destinationId);
  return (
    <>
      <Controller
        name={`${prefix}destination_id`}
        render={({ field }) => (
          <FormField label="Destination">
            <Combobox
              {...field}
              width="2xs"
              placeholder="Select destination..."
              optionAccessory={(option) => ({
                type: "image",
                url: option.definition.icon,
              })}
              optionValue={(option) => option.id}
              optionLabel={(option) => option.name ?? option.definition.name}
              options={destinations}
            />
          </FormField>
        )}
      />
      {/* TODO: Think about moving data extension use cases to a custom channel */}
      {destination?.type === "sfmc" && (
        <Controller
          name={`${prefix}config.type`}
          render={({ field }) => {
            const trigger: DecisionEngineChannelConfigType = "trigger";
            const dataExtensions: DecisionEngineChannelConfigType =
              "dataExtensions";
            return (
              <FormField label="Sync type">
                <RadioGroup {...field}>
                  <Radio
                    label="Triggered send"
                    value={trigger}
                    description="Hightouch will send messages directly using triggered sends"
                  />
                  <Radio
                    label="Data extension"
                    value={dataExtensions}
                    description="Hightouch will sync the messages to be sent to a data extension"
                  />
                </RadioGroup>
              </FormField>
            );
          }}
        />
      )}
      {destination?.type === "braze" && (
        <Controller
          defaultValue="trigger"
          name={`${prefix}config.type`}
          render={({ field }) => {
            const trigger: DecisionEngineChannelConfigType = "trigger";
            const canvas: DecisionEngineChannelConfigType = "canvas";
            return (
              <FormField label="Sync type">
                <RadioGroup {...field}>
                  <Radio label="Campaign trigger" value={trigger} />
                  <Radio label="Canvas trigger" value={canvas} />
                </RadioGroup>
              </FormField>
            );
          }}
        />
      )}
      <IdentifierField prefix={prefix} destination={destination} />
    </>
  );
};

export const RawForm: ChannelForm = ({ destinations, prefix = "" }) => {
  const { watch } = useFormContext();
  const destinationId = watch(`${prefix}destination_id`);
  const destination = destinations?.find((d) => d.id === destinationId);

  return (
    <>
      <Controller
        name={`${prefix}destination_id`}
        render={({ field }) => (
          <FormField label="Destination">
            <Combobox
              {...field}
              width="2xs"
              placeholder="Select destination..."
              optionAccessory={(option) => ({
                type: "image",
                url: option.definition.icon,
              })}
              optionValue={(option) => option.id}
              optionLabel={(option) => option.name ?? option.definition.name}
              options={destinations}
            />
          </FormField>
        )}
      />
      <IdentifierField prefix={prefix} destination={destination} />
    </>
  );
};

export const ChannelForms: Record<DecisionEngineChannelType, ChannelForm> = {
  email: StandardForm,
  sms: StandardForm,
  push: StandardForm,
  raw: RawForm,
};
