export const ConnectionNode = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 8 8"
    fill="none"
    height="8"
    width="8"
  >
    <g>
      <circle cx="4" cy="4" r="4" fill="white" />
      <circle cx="4" cy="4" r="3" stroke="#6AC1E7" strokeWidth="2" />
    </g>
  </svg>
);
