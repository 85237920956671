import {
  Box,
  Column,
  InformationIcon,
  Row,
  SparkleIcon,
  Tooltip,
} from "@hightouchio/ui";
import { FC } from "react";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { DestinationDefinition } from "src/graphql";
import { MetadataBadgeGroup } from "./metadata-badge-group";
import { TextWithTooltip } from "src/components/text-with-tooltip";

type Props = {
  destinationName: string;
  isSyncBoosted: boolean;
  metadata?: Record<string, unknown>;
  definition?: DestinationDefinition;
  syncDescription?: string;
};

export const DestinationCell: FC<Readonly<Props>> = ({
  metadata,
  destinationName,
  syncDescription,
  definition,
  isSyncBoosted,
}) => (
  <Row gap={3} width="100%" align="center">
    <IntegrationIcon
      name={definition?.name ?? "Private destination"}
      src={definition?.icon}
    />
    <Column gap={1} minWidth={0}>
      <Row width="100%">
        <TextWithTooltip fontWeight="medium">
          {destinationName || definition?.name || "Private destination"}
        </TextWithTooltip>
      </Row>

      <Row alignItems="center" gap={1}>
        {syncDescription && (
          <Tooltip message={syncDescription}>
            <InformationIcon />
          </Tooltip>
        )}
        {isSyncBoosted && (
          <Box width="20px" mt="-5px">
            <SparkleIcon color="warning.400" />
          </Box>
        )}
        {metadata && Object.keys(metadata).length > 0 && (
          <Box
            overflow="hidden"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <MetadataBadgeGroup metadata={metadata} />
          </Box>
        )}
      </Row>
    </Column>
  </Row>
);

DestinationCell.displayName = "DestinationCell";
