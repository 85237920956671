import { FC } from "react";

import { Routes, Route, Navigate, useOutletContext } from "src/router";

import { CreateEventWarehouseSync } from "./create";
import { EventSync, OutletContext } from "./common";
import { EventWarehouseSyncs } from "./syncs";
import { Schedule } from "./common/schedule";
import { Archive } from "./common/archive";
import { Errors } from "./common/errors";
import { Runs } from "./common/runs";
import { Configuration } from "./common/configuration";
import { ForwardingSyncFailuresArchived } from "./forwarding-sync/errors/archived";
import { ForwardingSyncFailuresRetrying } from "./forwarding-sync/errors/retrying";
import { Overview } from "./common/overview";
import { WarehouseSyncRun } from "./warehouse-sync/run";
import { EventSyncAlerting } from "./common/alerting";

export const EventWarehouseSyncsRouter: FC = () => {
  // TODO: warehouse and forwarding sync routes have some intermediate routing by types (see: syncs/common/)
  // but I think we can actually remove most of this logic,
  // and for most cases just let the unique URL segments tell is which type of sync we are dealing with
  return (
    <Routes>
      <Route path="/" element={<EventWarehouseSyncs />} />
      <Route path="new" element={<CreateEventWarehouseSync />} />
      <Route path=":id/*" element={<EventSync />}>
        <Route index element={<SyncIndex />} />
        <Route path="alerting" element={<EventSyncAlerting />} />

        {/* forwarding sync paths */}
        <Route path="overview" element={<Overview />} />
        <Route path="configuration" element={<Configuration />} />
        <Route path="configuration/event/:event?" element={<Configuration />} />

        <Route path="errors/*" element={<Errors />}>
          <Route index element={<Navigate to="retrying" replace />} />
          <Route path="archived" element={<ForwardingSyncFailuresArchived />} />
          <Route path="retrying" element={<ForwardingSyncFailuresRetrying />} />
        </Route>

        {/* warehouse sync paths */}
        <Route path="runs" element={<Runs />} />
        <Route path="archive" element={<Archive />} />
        <Route path="schedule" element={<Schedule />} />
      </Route>
      {/* warehouse sync only */}
      <Route path=":id/runs/:runId" element={<WarehouseSyncRun />} />
    </Routes>
  );
};

const SyncIndex = () => {
  const context = useOutletContext<OutletContext>();

  if (context.type === "forwarding") {
    return (
      <Navigate to={`/events/syncs/${context.sync.id}/overview`} replace />
    );
  }

  return <Navigate to={`/events/syncs/${context.sync.id}/runs`} replace />;
};
