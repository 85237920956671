import { createContext, useContext, ReactNode } from "react";

import { ResourceToPermission as V1ResourceToPermission } from "./types";
import { V2ResourceToPermission } from "./types-v2";
import {
  ResourcePermissionInput,
  useResourcePermission,
} from "./use-resource-permission";

type Props<
  V2Resource extends V2ResourceToPermission,
  V1Resource extends V1ResourceToPermission,
> = {
  children: ReactNode;
  fallback?: ReactNode;
  permission: ResourcePermissionInput<V2Resource, V1Resource>;
};

export type PermissionContextType = {
  unauthorized: boolean;
};

const defaultContextValue = {
  unauthorized: false,
};

export const PermissionContext =
  createContext<PermissionContextType>(defaultContextValue);

export const usePermissionContext = () => useContext(PermissionContext);

export function PermissionProvider<
  V2Resource extends V2ResourceToPermission,
  V1Resource extends V1ResourceToPermission,
>({ children, permission }: Readonly<Props<V2Resource, V1Resource>>) {
  const { isLoading, isPermitted } = useResourcePermission(permission);
  const unauthorized = isLoading ? true : !isPermitted;

  return (
    <PermissionContext.Provider value={{ unauthorized }}>
      {children}
    </PermissionContext.Provider>
  );
}
