import { Column, Pill, Row, SearchInput, Text } from "@hightouchio/ui";
import sortBy from "lodash/sortBy";
import pluralize from "pluralize";
import { FC, useEffect, useMemo, useState } from "react";
import { useUser } from "src/contexts/user-context";
import { useUserGroupGrantsForResourceQuery } from "src/graphql";
import { Table, TableColumn } from "src/ui/table";

type UserGroupAccessGrantsProps = {
  parentModelId: string | null;
  selectedRows: Array<string | number>;
  onRowSelect: (value: string | number | Array<string | number>) => void;
};

export const UserGroupGrants: FC<Readonly<UserGroupAccessGrantsProps>> = ({
  parentModelId,
  selectedRows,
  onRowSelect,
}) => {
  const { workspace } = useUser();

  const { data: userGroups, isLoading } = useUserGroupGrantsForResourceQuery(
    {
      type: "parent_model",
      workspaceId: workspace?.id,
      id: parentModelId ?? "",
    },
    {
      select: (data) =>
        data.user_group_grants.map(({ user_group, user_group_workspace }) => ({
          ...user_group,
          is_workspace_admin: user_group_workspace?.is_workspace_admin,
        })),
      enabled: Boolean(workspace?.id && parentModelId),
    },
  );

  // Fill up the selected rows with the user groups that always have access to the subset
  useEffect(() => {
    if (selectedRows.length === 0)
      onRowSelect(
        userGroups
          ?.filter(
            (userGroup) =>
              userGroup.is_organization_admin || userGroup.is_workspace_admin,
          )
          ?.map((userGroup) => userGroup.id) ?? [],
      );
  }, [userGroups]);

  const [search, setSearch] = useState<string>("");

  const filteredUsers = useMemo(() => {
    const sortedUserGroups = sortBy(userGroups, (userGroup) =>
      userGroup?.name?.toLowerCase(),
    );

    if (!search) return sortedUserGroups;

    const lowerCaseSearch = search.toLowerCase();
    return sortedUserGroups?.filter((userGroup) =>
      userGroup?.name.toLowerCase().includes(lowerCaseSearch),
    );
  }, [search, userGroups]);

  const columns: TableColumn[] = [
    {
      name: "Name",
      cell: ({ name }) => name,
    },
    {
      name: "Users",
      cell: ({ members_aggregate }) => members_aggregate.aggregate.count,
    },
  ];

  return (
    <Column gap={4} paddingBottom={2} zIndex={0}>
      <Column>
        <Text fontWeight="medium">Grant access to user groups</Text>
        <Text color="text.secondary">
          Select user groups who should be allowed to build audiences for this
          subset. The user group must have access to the parent model to be
          visible in this list.
        </Text>
      </Column>
      <Row justifyContent="space-between">
        <SearchInput
          width="xs"
          size="sm"
          placeholder="Search users..."
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
        <Row align="center" gap={2}>
          <Pill>{selectedRows.length}</Pill>
          {pluralize("user group", selectedRows.length)} selected
        </Row>
      </Row>
      <Table
        primaryKey="id"
        columns={columns}
        data={filteredUsers}
        disabled={(o) =>
          Boolean(
            o.is_organization_admin ||
              o.is_workspace_admin ||
              o.built_in_role_type,
          )
        }
        loading={isLoading}
        selectedRows={selectedRows}
        onSelect={onRowSelect}
      />
    </Column>
  );
};
