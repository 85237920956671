import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const AnalyticsIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.14709 16.5426V19H8.14709V15.4299C6.83885 15.8749 5.50352 16.2467 4.14709 16.5426ZM9.14709 15.0736V19H13.1471V13.3049C11.8505 13.9732 10.5148 14.5638 9.14709 15.0736ZM14.1471 12.7698V19H18.1471V10.1973C16.8679 11.1423 15.5316 12.0013 14.1471 12.7698Z"
        fillOpacity="0.3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6471 8C18.4755 8 19.1471 7.32843 19.1471 6.5C19.1471 5.67157 18.4755 5 17.6471 5C16.8187 5 16.1471 5.67157 16.1471 6.5C16.1471 6.57854 16.1531 6.65567 16.1648 6.73095C12.7908 9.33034 8.89023 11.1658 4.73351 12.1081C4.32955 12.1997 4.06585 12.5939 4.14709 13C4.22832 13.4062 4.62372 13.6705 5.0278 13.5795C9.42134 12.5892 13.5436 10.6498 17.1059 7.8994C17.2738 7.96437 17.4563 8 17.6471 8Z"
        fillOpacity="0.7"
      />
    </Icon>
  );
};
