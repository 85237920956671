import { FC, Fragment } from "react";

import { Avatar, Column, Row, Text } from "@hightouchio/ui";

import { LoadingDots } from "src/components/loading";
import { useUser } from "src/contexts/user-context";
import {
  ChartDefinition,
  CopilotChatMessage,
  EvaluateTimeSeriesMetricQueryVariables,
} from "src/graphql";
import { useScrollPageToBottom } from "src/hooks/use-scroll-page-to-bottom";
import { Markdown } from "src/ui/markdown";

import { CopilotChartSection } from "./copilot-chart";
import { MessageAnimation } from "./message-animation";

type CopilotMessageAreaProps = {
  data: CopilotChatMessage[];
  error?: Error | null;
  isLoading?: boolean;
};

export const CopilotMessageArea: FC<CopilotMessageAreaProps> = ({
  data,
  error,
  isLoading = false,
}) => {
  const { user } = useUser();

  useScrollPageToBottom([data.length, isLoading]);

  if (!user) return null;

  return (
    <Column gap={4} py={6}>
      {data.map(({ role, timestamp, content, chartDefinitions }, index) => {
        const id = `${timestamp}-${index}`;
        const Wrapper = index === data.length - 1 ? MessageAnimation : Fragment;
        const isLastMessage = index === data.length - 1;

        return (
          <Wrapper key={id}>
            {role === "USER" ? (
              <InputMessage name={user.name} content={content ?? ""} />
            ) : (
              <OutputMessage
                id={id}
                content={content}
                chartDefinitions={chartDefinitions}
              />
            )}
            {isLastMessage && error && (
              <Column ml={12} gap={4}>
                <Text color="danger.base">{error.message}</Text>
                <Text>
                  Please ask Copilot to regenerate your query or try a new one.
                </Text>
              </Column>
            )}
          </Wrapper>
        );
      })}

      {isLoading && (
        <Row ml={12}>
          <LoadingDots />
        </Row>
      )}
    </Column>
  );
};

type InputMessageProps = {
  name: string;
  content: string;
};

const InputMessage: FC<InputMessageProps> = ({ name, content }) => {
  return (
    <Row align="start" gap={4} flex={1} minWidth={0}>
      <Avatar name={name} mt={3} />
      <Column gap={2} flex={1} minWidth={0}>
        <Row
          align="center"
          borderRadius="md"
          bg="base.lightBackground"
          px={2}
          py={4}
          minWidth="0"
          flex={1}
        >
          <Text fontWeight="medium">{content}</Text>
        </Row>
      </Column>
    </Row>
  );
};

type OutputMessageProps = {
  id: string;
  content: string | null;
  chartDefinitions:
    | (ChartDefinition & {
        chartVariables: EvaluateTimeSeriesMetricQueryVariables;
      })[]
    | null;
};

const OutputMessage: FC<OutputMessageProps> = ({
  id,
  content,
  chartDefinitions,
}) => {
  return (
    <Column gap={2} flex={1} minWidth={0} ml={12}>
      {content && (
        <Column align="start" borderRadius="md" py={4} minWidth="0" flex={1}>
          <Markdown>{content}</Markdown>
        </Column>
      )}
      {chartDefinitions && chartDefinitions.length > 0 && (
        <CopilotChartSection id={id} chartDefinitions={chartDefinitions} />
      )}
    </Column>
  );
};
