import * as yup from "yup";

yup.addMethod(yup.array, "defined", function () {
  return this.test(
    "defined",
    "{path} must be defined",
    (value) => value !== undefined,
  );
});

export const audienceAndOrConditionSchema = yup.object({
  type: yup.string().oneOf(["and", "or"]).required(),
  // XXX: note that this is a full audience filter condition, and will be
  // complicated to validate. We'll solve this eventually but for now just
  // validate that it's present.
  conditions: yup.array().defined(),
});
