import { useFlags } from "launchdarkly-react-client-sdk";
import { NavSection } from "src/components/layout/nav/nav-section";

import { Icon } from "src/components/layout/nav/icons/intelligence";
import { ActiveSectionProps } from "src/components/layout/nav/utils";
import { UseSectionHook } from "./types";
import { useEntitlements } from "src/hooks/use-entitlement";

export const useIntelligenceSection: UseSectionHook = () => {
  const { data: entitlementsData } = useEntitlements(false);
  const { enableCopilot } = useFlags();

  return {
    title: "Intelligence",
    isEnabled: entitlementsData.entitlements.analytics,
    links: [
      {
        href: "/analytics",
        label: "Charts",
        permission: { v1: { resource: "audience", grant: "read" } },
      },
      {
        href: "/metrics",
        label: "Metrics",
        permission: { v1: { resource: "audience_schema", grant: "create" } },
      },
      enableCopilot && {
        href: "/copilot",
        label: "Copilot",
        permission: { v1: { resource: "audience", grant: "preview" } },
      },
      entitlementsData.entitlements.campaigns && {
        href: "/campaigns",
        label: "Campaigns",
        permission: { v1: { resource: "audience", grant: "read" } },
      },
    ],
  };
};

export const Intelligence = (props: ActiveSectionProps) => {
  const section = useIntelligenceSection();
  return <NavSection icon={Icon} {...section} {...props} />;
};
