import { FC } from "react";

import { Alert } from "@hightouchio/ui";
import { lowerFirst } from "lodash";
import moment from "moment";
import { ElementOf } from "ts-essentials";

import { SyncQuery } from "../../../graphql";

export const ExpiredSyncWarning: FC<{
  sync: ElementOf<SyncQuery["syncs"]>;
}> = ({ sync }) => {
  const endDate = sync?.schedule?.endDate
    ? new Date(sync.schedule.endDate)
    : undefined;

  let expiry =
    sync?.schedule_paused && endDate && endDate.getTime() < Date.now()
      ? moment(endDate).calendar()
      : undefined;
  if (expiry && /^(Last|Yesterday|Today) /.test(expiry)) {
    expiry = lowerFirst(expiry);
  }

  return (
    (expiry && (
      <Alert
        variant="banner"
        type="warning"
        justify="center"
        title="Expired sync"
        message={`This sync was scheduled to end ${expiry}.`}
      />
    )) ||
    null
  );
};
