import {
  Column,
  DeleteIcon,
  EditableDescription,
  EditableHeading,
  Menu,
  MenuActionsButton,
  MenuItem,
  MenuList,
  Row,
  Text,
  useToast,
} from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Sentry from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useMemo, useState } from "react";
import { Controller } from "react-hook-form";
import { ActionBar } from "src/components/action-bar";
import { DetailBar } from "src/components/detail-bar";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { DetailPage } from "src/components/layout";
import { PageSpinner } from "src/components/loading";
import { DeleteConfirmationModal } from "src/components/modals/delete-confirmation-modal";
import { useResourcePermission } from "src/components/permission/use-resource-permission";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { Warning } from "src/components/warning";
import {
  useFetchAttributionMethodQuery,
  useUpdateAttributionMethodInteractionsMutation,
  useUpdateAttributionMethodMutation,
} from "src/graphql";
import {
  FetchAttributionMethodQuery,
  useDeleteAttributionMethodMutation,
} from "src/graphql/types";
import * as analytics from "src/lib/analytics";
import { Link, useNavigate, useParams } from "src/router";
import { AttributionMethodType, InteractionType } from "src/types/visual";
import { formatDate } from "src/utils/time";
import { AttributionMethodForm } from "./attribution-method-form";
import {
  AttributionMethodFormData,
  attributionMethodValidationSchema,
} from "./use-attribution-method-form";

export const ViewAttributionMethod = () => {
  const { attribution_method_id: id } = useParams<{
    attribution_method_id?: string;
  }>();
  const { toast } = useToast();
  const updateAttributionMethodMutation = useUpdateAttributionMethodMutation();
  const updateInteractionsMutation =
    useUpdateAttributionMethodInteractionsMutation();

  const { isLoading, data: attributionMethod } = useFetchAttributionMethodQuery(
    {
      id: id ?? "",
    },
    { enabled: Boolean(id), select: (data) => data?.attribution_methods_by_pk },
  );

  const trackUpdate = () => {
    analytics.track("Attribution Method Updated", {
      attribution_method_id: id,
      name: attributionMethod?.name,
      parent_model_id: attributionMethod?.parent_model.id,
    });
  };

  if (!id || isLoading) {
    return <PageSpinner />;
  }

  if (!attributionMethod) {
    return (
      <Warning
        subtitle="It may have been deleted"
        title="Attribution method not found"
      />
    );
  }

  const updateAttributionMethod = async ({
    name,
    interactions,
    weighting,
    description,
  }: AttributionMethodFormData) => {
    try {
      await updateAttributionMethodMutation.mutateAsync({
        id: id ?? "",
        input: {
          name,
          type: weighting,
          description,
        },
      });
      // TODO: add conditional case here based on dirtyFields to only update interactions if changed
      await updateInteractionsMutation.mutateAsync({
        id: id,
        interactions: interactions.map((interaction) => ({
          attribution_method_id: id,
          interaction_model_id: interaction.interactionModelId,
          lookback_window: interaction.lookbackWindow,
        })),
      });

      trackUpdate();
    } catch (error) {
      toast({
        id: "update-attribution-method",
        title: "Failed to update this attribution method",
        variant: "error",
      });

      Sentry.captureException(error);
    }
  };

  return (
    <ViewAttributionInner
      attributionMethod={attributionMethod}
      isLoading={
        updateAttributionMethodMutation.isLoading ||
        updateInteractionsMutation.isLoading
      }
      updateAttributionMethod={updateAttributionMethod}
    />
  );
};

const ViewAttributionInner: React.FC<{
  attributionMethod: NonNullable<
    FetchAttributionMethodQuery["attribution_methods_by_pk"]
  >;
  isLoading: boolean;
  updateAttributionMethod: (formData: AttributionMethodFormData) => void;
}> = ({ attributionMethod, updateAttributionMethod }) => {
  const navigate = useNavigate();
  const { schemaV2 } = useFlags();
  const { toast } = useToast();

  const { isPermitted: userCanUpdate } = useResourcePermission({
    v1: { resource: "audience_schema", grant: "update" },
  });
  const deleteAttributionMethodMutation = useDeleteAttributionMethodMutation();
  const { isPermitted: hasDeletePermissions } = useResourcePermission({
    v1: { resource: "audience_schema", grant: "delete" },
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const deleteAttributionMethod = async () => {
    if (!hasDeletePermissions) {
      toast({
        id: "delete-attribution-method",
        title: "You don't have permissions to delete attribution methods",
        variant: "error",
      });

      return;
    }

    try {
      await deleteAttributionMethodMutation.mutateAsync({
        id: attributionMethod.id,
      });

      analytics.track("Attribution Method Deleted", {
        attribution_method_id: attributionMethod.id,
        attribution_method_name: attributionMethod.name,
        parent_model_id: attributionMethod.parent_model.id,
      });

      navigate("/metrics/attributions");
    } catch (error) {
      toast({
        id: "delete-attribution-method",
        title: "Failed to delete this attribution method",
        variant: "error",
      });

      Sentry.captureException(error);
    }
  };

  const formData = useMemo(
    () => ({
      name: attributionMethod.name,
      description: attributionMethod.description || "",
      interactions: attributionMethod.attribution_methods_interactions.map(
        (interaction) => ({
          type: attributionMethod.type as InteractionType,
          lookbackWindow: interaction.lookback_window,
          interactionModelId: interaction.interaction_model_id,
        }),
      ),
      weighting: attributionMethod.type as AttributionMethodType,
    }),
    [attributionMethod],
  );

  const form = useHightouchForm({
    onSubmit: async (data) => {
      updateAttributionMethod(data);
    },
    error: "Something went wrong",
    resolver: yupResolver(attributionMethodValidationSchema),
    values: formData,
  });

  const parentModel = attributionMethod.parent_model;

  const updatedAt =
    attributionMethod.updated_at || attributionMethod.created_at
      ? formatDate(attributionMethod.updated_at || attributionMethod.created_at)
      : "--";
  const updatedBy =
    attributionMethod.updated_by?.name || attributionMethod.created_by?.name;

  return (
    <>
      <Form form={form}>
        <DetailPage
          crumbs={[
            {
              label: "All attribution methods",
              link: "/metrics/attributions",
            },
          ]}
          header={
            <Column flex={1} minWidth={0}>
              <Row flex={1} justify="space-between" minWidth={0} pt={1}>
                <Controller
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <EditableHeading
                      isDisabled={!userCanUpdate}
                      size="lg"
                      {...field}
                    />
                  )}
                />
                <Row align="center">
                  <Menu>
                    <MenuActionsButton variant="secondary" />

                    <MenuList>
                      <MenuItem
                        isDisabled={
                          hasDeletePermissions ||
                          deleteAttributionMethodMutation.isLoading
                        }
                        icon={DeleteIcon}
                        variant="danger"
                        onClick={() => {
                          setShowDeleteModal(true);
                        }}
                      >
                        Delete
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Row>
              </Row>
              <Row>
                <Controller
                  control={form.control}
                  name="description"
                  render={({ field }) => (
                    <EditableDescription
                      isDisabled={!userCanUpdate}
                      {...field}
                    />
                  )}
                />
              </Row>
              <DetailBar>
                <Row align="center" gap={2} flexShrink={0}>
                  <IntegrationIcon
                    src={parentModel?.connection?.definition?.icon}
                    name={parentModel?.name ?? ""}
                  />
                  <Link
                    href={
                      schemaV2
                        ? `/schema-v2/view?source=${parentModel?.connection?.id}&id=${parentModel?.id}`
                        : `/schema/parent-models/${parentModel?.id}`
                    }
                  >
                    <TextWithTooltip fontWeight="medium" color="inherit">
                      {parentModel.name}
                    </TextWithTooltip>
                  </Link>
                </Row>
                <Row align="center" flexShrink={0}>
                  <Text>
                    Updated:
                    <Text fontWeight="semibold" ml={1}>
                      {updatedAt}
                    </Text>{" "}
                    {updatedBy && `by ${updatedBy}`}
                  </Text>
                </Row>
              </DetailBar>
            </Column>
          }
          title={`${attributionMethod.name}`}
        >
          <AttributionMethodForm parentModelId={parentModel?.id} />

          <ActionBar>
            <FormActions />
          </ActionBar>
        </DetailPage>
      </Form>

      <DeleteConfirmationModal
        isOpen={showDeleteModal}
        label="attribution method"
        onClose={() => {
          setShowDeleteModal(false);
        }}
        onDelete={deleteAttributionMethod}
      />
    </>
  );
};
