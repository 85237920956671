import {
  FormulaTraitConfig,
  isAggregationTraitConfig,
  isColumnTypeValue,
  isCountDedupedTraitConfig,
  isIntervalType,
  isOrderDedupedTraitConfig,
  isTimeRangeType,
  isTransformedColumn,
  NumberOperator,
  NumberRangeValue,
  RelativeDirection,
  SyntheticColumn,
  TimeRangeValue,
  TimeType,
  TraitConfig,
  TransformedColumn,
} from "@hightouch/lib/query/visual/types";
import { Box, Row, Text } from "@hightouchio/ui";
import { isEqual } from "lodash";
import pluralize from "pluralize";
import { isPresent } from "ts-extras";

import {
  ColumnReference,
  ColumnType,
  FilterableColumn,
  getInitialTraitColumn,
  IntervalValue,
  isRelatedColumn,
  isSyntheticColumn,
  isTraitColumn,
  OperatorsWithoutValue,
  PropertyCondition,
  RawSqlTraitConfig,
  RelatedColumn,
  TraitDefinition,
  TraitType,
  Operator,
  OperatorOptions,
  RawColumn,
  shouldResetTimeType,
  shouldResetValue,
  shouldResetPercentile,
} from "src/types/visual";
import { TraitIcon } from "src/ui/icons";
import { accurateCommaNumber, ordinalSuffix } from "src/utils/numbers";
import { formatDateOrDatetime } from "src/utils/time";
import { TextWithTooltip } from "src/components/text-with-tooltip";

export interface ColumnOption {
  value: any;
  label: string;
  description?: string;
  type: ColumnType;
  case_sensitive: boolean | null;
}

interface TraitOption {
  value: RelatedColumn | TransformedColumn;
  label: string;
  description?: string;
  operator: "exists";
  type: ColumnType | undefined;
  render: () => JSX.Element;
}

export const getColumnFromValue = (
  options: (ColumnOption | TraitOption)[],
  column: any,
) => {
  return options.find(({ value }) => column === value);
};

export const getColumnOptions = (
  columns: FilterableColumn[],
  isDeprecatedPropertyCondition?: boolean,
): ColumnOption[] => {
  const result = columns.map(
    ({
      alias,
      name,
      type,
      custom_type,
      column_reference,
      case_sensitive,
      description,
    }) => ({
      value: isDeprecatedPropertyCondition
        ? getPropertyNameFromColumn(column_reference)
        : column_reference,
      label: alias || name,
      description: description || undefined,
      type: (custom_type || type) as ColumnType,
      case_sensitive,
    }),
  );

  result.sort((option1, option2) => option1.label.localeCompare(option2.label));

  return result;
};

export const getRawColumn = (
  column: RawColumn | RelatedColumn | SyntheticColumn,
): RawColumn | null => {
  if (!column || isSyntheticColumn(column)) {
    return null;
  }

  if (column.type === "raw") {
    return column;
  }

  if (column.type === "related") {
    return column.column.type === "raw" ? column.column : null;
  }

  return null;
};

export const getModelIdFromColumn = (
  column: ColumnReference | null | undefined,
): string | null => {
  if (!column) {
    return null;
  }

  if (column.type === "raw") {
    return column.modelId?.toString();
  } else if (column.type === "related") {
    return getModelIdFromColumn(column.column);
  }

  return null;
};

export const getModelIdFromTrait = (
  trait: TraitDefinition | null | undefined,
): string | null => {
  if (!trait) {
    return null;
  }

  return trait.relationship?.to_model.id;
};

export const getPropertyNameFromProperty = (
  property: PropertyCondition["property"],
): string | null => {
  if (!property) {
    return null;
  }

  if (typeof property === "string") {
    return property;
  }

  return getPropertyNameFromColumn(property);
};

export const getPropertyNameFromColumn = (
  column: ColumnReference | null | undefined | string,
): null | string => {
  if (!column) {
    return null;
  }
  if (typeof column === "string") {
    return column;
  }
  if (column.type === "raw" || isSyntheticColumn(column)) {
    return column.name;
  } else if (column.type === "related" || column.type === "transformed") {
    return getPropertyNameFromColumn(column.column);
  } else if (column.type === "trait") {
    return null;
  }

  return null;
};

export const getPropertyNameFromTrait = (
  trait: TraitDefinition | null | undefined,
): string | null => {
  if (!trait) {
    return null;
  }

  const type = trait.type as TraitType;
  const config = trait.config as TraitConfig;

  if (
    isOrderDedupedTraitConfig(type, config) ||
    isCountDedupedTraitConfig(type, config)
  ) {
    return getPropertyNameFromColumn(config.toSelect);
  }

  if (isAggregationTraitConfig(type, config)) {
    return getPropertyNameFromColumn(config.column);
  }

  // RawSQL traits and formula traits don't have a simple property name we can extract
  return null;
};

export const getTraitDefinitionIdFromColumn = (
  column: ColumnReference,
): string | null => {
  if (column.type === "trait") {
    return column.traitDefinitionId;
  }

  if (column.type === "related") {
    if (column.column.type === "trait") {
      return column.column.traitDefinitionId;
    }
  }

  return null;
};

type FormatValueOptions = {
  showParameterizedLabel?: boolean;
};

export const formatValue = (
  condition: PropertyCondition,
  value: any,
  formatOptions: FormatValueOptions = { showParameterizedLabel: false },
) => {
  const doesNotRequireValue = OperatorsWithoutValue.includes(
    condition.operator,
  );
  if (condition.propertyOptions?.parameterize && value == null) {
    // We want to differentiate between setting a condition to be parameterized (i.e., showParameterizedLabel === true)
    // vs. setting the operator and value for a condition that is parameterized (i.e., showParameterizaedLabel === false)
    if (formatOptions.showParameterizedLabel) {
      return <Text fontWeight="medium">parameterized</Text>;
    }

    return doesNotRequireValue ? null : <Text fontWeight="medium">___</Text>;
  }
  if (doesNotRequireValue) {
    return null;
  }
  if (value === null || value === undefined) {
    return <Text fontWeight="medium">___</Text>;
  }
  if (Array.isArray(value)) {
    if (value.length === 0) {
      return <Text fontWeight="medium">___</Text>;
    }

    return (
      <Text isTruncated fontWeight="medium">
        {value.map(String).join(", ")}
      </Text>
    );
  }
  if (
    condition.propertyType === ColumnType.Date ||
    condition.propertyType === ColumnType.Timestamp
  ) {
    if (isColumnTypeValue(condition.value)) {
      return (
        <Text isTruncated>
          {String(getPropertyNameFromProperty(value?.property) ?? "___")}
        </Text>
      );
    }

    if (typeof value === "object") {
      return condition.propertyType === ColumnType.Date
        ? getDateValue(value, condition.timeType)
        : getTimeValue(value, condition.timeType);
    }

    return (
      <AbsoluteTimeText
        hideTime={condition.propertyType === ColumnType.Date}
        value={value}
      />
    );
  }
  if (
    condition.propertyType === ColumnType.Number &&
    condition.propertyOptions?.percentile &&
    value
  ) {
    return (
      <Text isTruncated>
        {String(value)}
        {ordinalSuffix(value)} percentile
      </Text>
    );
  }

  if (
    condition.propertyType === ColumnType.Number &&
    condition.operator === NumberOperator.Between
  ) {
    return <Text>{getTimeRangeValue(condition.value)}</Text>;
  }

  if (condition.propertyType === ColumnType.Number) {
    return <TextWithTooltip>{accurateCommaNumber(value)}</TextWithTooltip>;
  }

  return <TextWithTooltip isTruncated>{String(value)}</TextWithTooltip>;
};

type MinimalTraitDefinition = Pick<TraitDefinition, "type" | "config"> & {
  relationship: {
    to_model: {
      filterable_audience_columns: FilterableColumn[];
    };
  } | null;
};

export const getTraitPropertyType = (
  trait: MinimalTraitDefinition,
): ColumnType => {
  switch (trait.type) {
    case TraitType.Average:
    case TraitType.Count:
    case TraitType.Sum:
      return ColumnType.Number;

    case TraitType.RawSql:
    case TraitType.Formula:
      return (trait.config as RawSqlTraitConfig | FormulaTraitConfig)
        .resultingType;

    case TraitType.First:
    case TraitType.Last:
    case TraitType.MostFrequent:
    case TraitType.LeastFrequent: {
      const columnReference = trait.config.toSelect;
      const column =
        trait.relationship?.to_model.filterable_audience_columns.find(
          ({ column_reference }) => isEqual(column_reference, columnReference),
        );
      return column?.type ? (column.type as ColumnType) : ColumnType.Unknown;
    }

    default:
      return ColumnType.Unknown;
  }
};

export const getCustomTraitPropertyType = ({
  type,
  selectedColumn,
  filterableColumns = [],
}: {
  type: TraitType;
  selectedColumn: ColumnReference | undefined;
  filterableColumns: FilterableColumn[];
}): ColumnType => {
  const propertyType = getTraitPropertyType({
    type: type,
    relationship: {
      to_model: {
        filterable_audience_columns: filterableColumns,
      },
    },
    config: {
      toSelect: selectedColumn,
    },
  });

  return propertyType;
};

export const getTraitOptions = (
  property: PropertyCondition["property"],
  traits: TraitDefinition[] | undefined,
): TraitOption[] => {
  if (traits) {
    return traits
      .filter((trait) => Boolean(trait.type))
      .map((trait) => ({
        label: trait.name,
        description: trait.description || undefined,
        value:
          (isRelatedColumn(property) || isTransformedColumn(property)) &&
          isTraitColumn(property.column) &&
          property.column.traitDefinitionId === trait.id
            ? property
            : getInitialTraitColumn(trait),
        operator: "exists",
        type: getTraitPropertyType(trait),
        render: () => (
          <>
            <TraitIcon color="base.5" size={16} sx={{ mr: 2 }} />
            <Text whiteSpace="nowrap">{trait.name}</Text>
          </>
        ),
      }));
  }
  return [];
};

export const isIntervalNow = (intervalValue: IntervalValue) => {
  return intervalValue.quantity === 0;
};

export const isCompletedInterval = (intervalValue: IntervalValue) => {
  return intervalValue.interval && intervalValue.quantity != null;
};

export const isCompletedTimeRange = (timeRangeValue: TimeRangeValue) => {
  const { before, after } = timeRangeValue;
  return [before, after].every(
    (v) =>
      typeof v === "string" || (isIntervalType(v) && isCompletedInterval(v)),
  );
};

export const AbsoluteTimeText = ({
  value,
  hideTime,
}: {
  value: any;
  hideTime?: boolean;
}) => {
  return (
    <Text isTruncated fontWeight="medium">
      {formatDateOrDatetime(value, !hideTime)}
    </Text>
  );
};

export const RelativeTimeText = ({
  value,
  isFunnelCondition,
}: {
  value: any;
  isFunnelCondition?: boolean;
}) => {
  if (isIntervalNow(value)) {
    return <Text fontWeight="medium">now</Text>;
  }

  return (
    <Box display="flex">
      <Text fontWeight="medium">
        {isPresent(value.quantity)
          ? pluralize(value.interval, value.quantity, true)
          : "___"}
      </Text>
      {!isFunnelCondition && (
        <Text fontWeight="medium" ml={1}>
          {getRelativeDirectionLabel(value)}
        </Text>
      )}
    </Box>
  );
};

const getDateValue = (
  value: any,
  timeType: TimeType | undefined,
  funnelCondition?: boolean,
) => {
  return getTimeValue(value, timeType, funnelCondition, true);
};

const TimeText = ({
  value,
  timeType,
  hideTime,
  isFunnelCondition,
}: {
  value: any;
  timeType: TimeType | undefined;
  hideTime?: boolean;
  isFunnelCondition?: boolean;
}) => {
  if (timeType === TimeType.Absolute) {
    return <AbsoluteTimeText hideTime={hideTime} value={value} />;
  } else {
    return (
      <RelativeTimeText isFunnelCondition={isFunnelCondition} value={value} />
    );
  }
};

export const getRelativeDirectionLabel = (value: IntervalValue) => {
  if (value.direction === RelativeDirection.Forward) {
    return " from now";
  } else {
    return " ago";
  }
};

export const getTimeValue = (
  value: any,
  timeType: TimeType | undefined,
  isFunnelCondition?: boolean,
  hideTime?: boolean,
) => {
  if (isTimeRangeType(value)) {
    return (
      <Row>
        <TimeText
          hideTime={hideTime}
          isFunnelCondition={isFunnelCondition}
          timeType={timeType}
          value={value.before}
        />
        <Text fontWeight="medium" mx={1}>
          and
        </Text>
        <TimeText
          hideTime={hideTime}
          isFunnelCondition={isFunnelCondition}
          timeType={timeType}
          value={value.after}
        />
      </Row>
    );
  }

  if (
    (isIntervalType(value) && isCompletedInterval(value)) ||
    timeType === TimeType.Absolute
  ) {
    return (
      <TimeText
        hideTime={hideTime}
        isFunnelCondition={isFunnelCondition}
        timeType={timeType}
        value={value}
      />
    );
  }

  return "___";
};

const getTimeRangeValue = (value: NumberRangeValue) => {
  return `${value.min ?? "___"} and ${value.max ?? "___"}`;
};

export const getOperatorLabel = (
  operator: Operator,
  propertyType: ColumnType | null,
  parameterized = false,
) => {
  if (parameterized) return "is";
  return OperatorOptions[propertyType || ColumnType.Unknown]?.find(
    (option) => option.value === operator,
  )?.label;
};

export const updateConditionOperator = (
  condition: PropertyCondition,
  value: Operator,
) => {
  const diff: Partial<PropertyCondition> = {
    operator: value,
  };

  if (shouldResetTimeType(condition.operator, value)) {
    diff.timeType = TimeType.Relative;
    diff.value = null;
  } else if (
    shouldResetValue(condition.propertyType, condition.operator, value)
  ) {
    diff.value = null;
  }

  if (shouldResetPercentile(value)) {
    diff.propertyOptions = {
      ...condition.propertyOptions,
      percentile: false,
    };
  }

  return diff;
};
