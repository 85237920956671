import { useCallback, useEffect } from "react";

import { useSearchParams } from "src/router";

/**
 * This hook creates a `string` state, where value is saved to the URL in the
 * query parameter named via `parameter` option. On page load, initial value
 * is restored from the URL as well.
 *
 * It's useful for things like search input, where we want to store the search
 * query in the `?search=abc` part of the URL, so that last state of the page
 * is rendered when user goes back to that page.
 */
const useQueryState = <T extends string | null>(
  parameter: string,
  defaultValue?: T,
): [string | T, (value: unknown) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setParam = useCallback(
    (value: unknown) => {
      if (!value) {
        setSearchParams((params) => {
          params.delete(parameter);
          return params;
        });
        return;
      }
      setSearchParams((params) => {
        params.set(parameter, value?.toString());
        return params;
      });
    },
    [searchParams, parameter],
  );

  const paramValue = searchParams.get(parameter) as string | T;

  useEffect(() => {
    if (defaultValue && !paramValue) {
      setParam(defaultValue);
    }
  }, [paramValue, defaultValue, setParam]);

  return [paramValue ?? (defaultValue as T), setParam];
};

export default useQueryState;
