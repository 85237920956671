import { FC, useMemo } from "react";

import {
  Box,
  Column,
  Row,
  SectionHeading,
  SkeletonBox,
  Text,
} from "@hightouchio/ui";
import { Cron } from "croner";
import { format, parseISO } from "date-fns";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";

import { DashboardSyncMetricGranularity } from "src/graphql";
import * as analytics from "src/lib/analytics";
import { commaNumber } from "src/utils/numbers";

import { useSyncHealthChartsContext } from "./sync-health-charts";
import { useSyncHealthContext } from "./sync-health-context";
import { quantityTickFormatter } from "../charts/chart-utils";

const ASPECT_RATIO = (1 / 5) * 100;

const TOOLTIP_CONSTANTS = {
  succeeded: { color: "success.base", label: "successful", order: 0 },
  warning: { color: "warning.base", label: "warning", order: 1 },
  failed: { color: "danger.base", label: "failed", order: 2 },
  successful_operations: {
    color: "success.base",
    label: "successful",
    order: 0,
  },
  failed_operations: { color: "danger.base", label: "rejected", order: 1 },
};

const SyncHealthTooltip: FC<TooltipProps<number, string>> = ({ payload }) => {
  const data = payload?.[0]?.payload;

  if (!data) return null;

  const rawTime = parseISO(data.time_bucket);
  const time =
    typeof rawTime === "string" ? rawTime : format(rawTime, "EEE HH:mm");
  const entries = Object.entries(data)
    .filter(([key]) => key !== "time_bucket")
    .sort(
      (a, b) =>
        TOOLTIP_CONSTANTS[a[0]]["order"] - TOOLTIP_CONSTANTS[b[0]]["order"],
    );

  return (
    <Column bg="gray.900" padding={2} borderRadius="md" gap={2}>
      <Text color="text.tertiary" fontWeight="medium">
        {time}
      </Text>
      {entries.map(([key, value], idx) => {
        const type = key.endsWith("operations") ? "rows" : "runs";
        return (
          <Row key={idx} gap={2} alignItems="stretch">
            <Column
              bg={TOOLTIP_CONSTANTS[key]["color"]}
              width={2}
              borderRadius="sm"
            />
            <Column>
              <Text fontWeight="medium" color="white" size="sm">
                {commaNumber(Number(value))} {TOOLTIP_CONSTANTS[key]["label"]}{" "}
                {type}
              </Text>
            </Column>
          </Row>
        );
      })}

      <Text color="text.tertiary" fontWeight="medium">
        Click to view runs {"->"}
      </Text>
    </Column>
  );
};

function timeTickFormatter(
  tick: string,
  granularity: DashboardSyncMetricGranularity,
): string {
  try {
    const parsed = parseISO(tick);

    if (parsed.getHours() === 0 && parsed.getMinutes() === 0) {
      return format(parsed, "EEE MMM d");
    }
    switch (granularity) {
      case DashboardSyncMetricGranularity.FourHour:
        return format(parsed, "HH:mm");
      case DashboardSyncMetricGranularity.OneDay:
        return format(parsed, "HH:mm");
      case DashboardSyncMetricGranularity.TwoDay:
        return format(parsed, "HH:mm");
      case DashboardSyncMetricGranularity.OneWeek:
        return format(parsed, "EEE HH:mm");
    }
  } catch {
    return tick;
  }
}

const X_AXIS_PROPS = (granularity: DashboardSyncMetricGranularity) => ({
  axisLine: { stroke: "var(--chakra-colors-base-border)" },
  dataKey: "time_bucket",
  tickMargin: 4,
  minTickGap: 0,
  fontSize: 12,
  tickFormatter: (t) => timeTickFormatter(t, granularity),
  height: 50,
  tickLine: { stroke: "var(--chakra-colors-base-border)" },
});

const Y_AXIS_PROPS = () => ({
  tickCount: 4,
  axisLine: false,
  tickLine: false,
  tickFormatter: quantityTickFormatter,
  allowDecimals: false,
  fontSize: 12,
});

const ChartWrapper: FC<{
  title: string;
  description: string;
  emptyText: string;
  isEmpty: boolean;
  children: React.ReactNode;
}> = ({ title, description, children, emptyText, isEmpty }) => {
  return (
    <Column>
      <SectionHeading>{title}</SectionHeading>
      <Text color="text.secondary">{description}</Text>
      <SkeletonBox
        position="relative"
        width="100%"
        pb={`${ASPECT_RATIO}%`}
        borderRadius="md"
        flex={1}
      >
        <Box position="absolute" left={0} right={0} top={0} bottom={0}>
          {children}
        </Box>
        {isEmpty && (
          <Box
            position="absolute"
            left="50%"
            top="50%"
            transform="translate(-50%, -50%)"
          >
            <Text color="text.secondary" fontWeight="medium">
              {emptyText}
            </Text>
          </Box>
        )}
      </SkeletonBox>
    </Column>
  );
};

const BAR_CHART_PROPS = () => ({
  margin: { top: 20, bottom: -30, right: 10, left: -20 },
});

const TOOLTIP_PROPS = () => ({
  cursor: false,
  style: { outline: "none" },
  offset: 20,
  position: { y: -20 },
  animationDuration: 150,
  wrapperStyle: { outline: "none" },
});

interface Props {
  data: any[];
  title: string;
  description: string;
  emptyText: string;
  isEmpty: boolean;
  onClick: (activeIndex: number) => void;
  bars: {
    dataKey: string;
    stackId: string;
    fill: string;
    highlightedFill: string;
  }[];
}

export const SyncHealthChart: FC<Props> = ({
  data,
  bars,
  title,
  description,
  emptyText,
  isEmpty,
  onClick,
}) => {
  const { activeIndex, setActiveIndex, handleMouseMove } =
    useSyncHealthChartsContext();
  const { granularity } = useSyncHealthContext();

  const tickProps = useMemo(() => {
    const startTime = data[0]?.time_bucket;

    if (!startTime) {
      return { interval: 8 };
    }

    if (granularity === DashboardSyncMetricGranularity.OneWeek) {
      return { interval: 12 };
    }

    let expression = "0 * * * *";
    let count = 4;

    switch (granularity) {
      case DashboardSyncMetricGranularity.FourHour:
        expression = "0 * * * *";
        count = 4;
        break;
      case DashboardSyncMetricGranularity.OneDay:
        expression = "0 0,4,8,12,16,20 * * *";
        count = 6;
        break;
      case DashboardSyncMetricGranularity.TwoDay:
        expression = "0 0,12 * * *";
        count = 4;
        break;
    }

    const ticks = Cron(expression).nextRuns(count, new Date(startTime));

    return { ticks: ticks.map((tick) => tick.toISOString()) };
  }, [granularity, data]);

  return (
    <ChartWrapper
      title={title}
      emptyText={emptyText}
      isEmpty={isEmpty}
      description={description}
    >
      <ResponsiveContainer maxHeight={200} minWidth="100%">
        <BarChart
          onMouseLeave={() => setActiveIndex(-1)}
          onMouseMove={handleMouseMove}
          {...BAR_CHART_PROPS()}
          data={data}
        >
          <CartesianGrid vertical={false} stroke="#E5E9ED" />
          <XAxis {...X_AXIS_PROPS(granularity)} {...tickProps} />
          <Tooltip content={SyncHealthTooltip} {...TOOLTIP_PROPS()} />

          <ReferenceLine
            x={data?.[activeIndex]?.time_bucket}
            strokeWidth="2%"
            stroke="var(--chakra-colors-base-divider)"
            onClick={() => {
              onClick(activeIndex);
              analytics.track("[App Dashboard] Chart Clicked", {
                granularity,
                chart: title,
              });
            }}
            style={{ cursor: "pointer" }}
          />
          <YAxis {...Y_AXIS_PROPS()} />
          {bars.map((bar, index) => {
            return (
              <Bar
                key={`bar-${index}`}
                dataKey={bar.dataKey}
                stackId={bar.stackId}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  onClick(activeIndex);
                  analytics.track("[App Dashboard] Chart Clicked", {
                    granularity,
                    chart: title,
                  });
                }}
              >
                {data.map((_entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={
                      activeIndex === -1 || index === activeIndex
                        ? bar.fill
                        : bar.highlightedFill
                    }
                  />
                ))}
              </Bar>
            );
          })}
        </BarChart>
      </ResponsiveContainer>
    </ChartWrapper>
  );
};
