import { PureComponent, ReactNode } from "react";

import { Column, Button, Text, ButtonGroup, Heading } from "@hightouchio/ui";
import * as Sentry from "@sentry/react";

import * as analytics from "src/lib/analytics";
import { newPylonMessage } from "src/lib/pylon";

interface Props {
  children: ReactNode;
}

interface State {
  isCrashed: boolean;
}

export class ErrorBoundary extends PureComponent<Props, State> {
  contactSupport = () => {
    analytics.track("Contact support clicked", {});

    newPylonMessage("");
  };

  override state = {
    isCrashed: false,
  };

  override render() {
    if (!this.state.isCrashed) {
      return this.props.children;
    }

    return (
      <Column width="100%" p={20} align="center" gap={4}>
        <svg
          fill="none"
          height="48"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
          width="48"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" />
          <path d="M9 9H9.01" />
          <path d="M15 9H15.01" />
          <path d="M16 14C16 14 12 14 12 14C12 14 8 14 8 14">
            <animate
              attributeName="d"
              begin="400ms"
              calcMode="spline"
              dur="1s"
              fill="freeze"
              keySplines="0.22 1 0.36 1"
              values="M16 14C16 14 12 14 12 14C12 14 8 14 8 14; M16 16C16 16 14.5 14 12 14C9.5 14 8 16 8 16"
            />
          </path>
        </svg>

        <Heading size="lg">Something went wrong...</Heading>
        <Text>
          An error has occurred and we certainly didn&apos;t expect it.
          <br />
          We&apos;ve been notified and will fix it as soon as possible.
        </Text>

        <ButtonGroup size="lg">
          <Button variant="secondary" onClick={this.contactSupport}>
            Contact support
          </Button>

          <Button onClick={this.reload}>Reload page</Button>
        </ButtonGroup>
      </Column>
    );
  }

  override componentDidCatch(error: Error) {
    this.setState({
      isCrashed: true,
    });

    Sentry.captureException(error);
  }

  reload() {
    window.location.reload();
  }
}
