import { Navigate, Route, Routes } from "src/router";
import { useFlags } from "launchdarkly-react-client-sdk";

import { NotFound } from "src/pages/not-found";
import { Layout } from "./layout";
import { DestinationRules } from "./destination-rules/destination-rules";
import { DestinationRule } from "./destination-rules/destination-rule";
import { PermissionedRoute } from "src/components/router/permissioned-route";
import { SubsetCategories } from "./subsets/subset-categories";
import { CreateCategory } from "./subsets/category/create-category";
import { ViewCategory } from "./subsets/category/view-category";
import { FormErrorProvider } from "src/contexts/form-error-context";
import { Subset } from "./subsets/subset";

const redirectDestinationRules = "/governance/destination-rules";
const redirectSubsetCategories = "/governance/subset-categories";

export const GovernanceRoutes = () => {
  const { appDestinationRulesEnabled, appSubsetsEnabled } = useFlags();

  if (!appDestinationRulesEnabled) {
    return <NotFound />;
  }

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route
          index
          element={
            <Navigate
              to={{ pathname: "destination-rules", search: location.search }}
              replace
            />
          }
        />
        <Route path="destination-rules" element={<DestinationRules />}>
          <Route
            path="new"
            element={
              <PermissionedRoute
                permission={{
                  v1: { resource: "audience_schema", grant: "update" },
                }}
                redirect={redirectDestinationRules}
              >
                <DestinationRule />
              </PermissionedRoute>
            }
          />
          <Route
            path=":destination_rule_id"
            element={
              <PermissionedRoute
                permission={{
                  v1: { resource: "audience_schema", grant: "read" },
                }}
                redirect={redirectDestinationRules}
              >
                <DestinationRule />
              </PermissionedRoute>
            }
          />
        </Route>

        {appSubsetsEnabled && (
          <Route path="subset-categories" element={<SubsetCategories />}>
            <Route
              path="new"
              element={
                <PermissionedRoute
                  permission={{
                    v1: { resource: "audience_schema", grant: "update" },
                  }}
                  redirect={redirectSubsetCategories}
                >
                  <CreateCategory />
                </PermissionedRoute>
              }
            />
            <Route
              path=":subset_category_id"
              element={
                <PermissionedRoute
                  permission={{
                    v1: { resource: "audience_schema", grant: "read" },
                  }}
                  redirect={redirectSubsetCategories}
                >
                  <ViewCategory />
                </PermissionedRoute>
              }
            />
            <Route
              path=":subset_category_id/subsets/new"
              element={
                <PermissionedRoute
                  permission={{
                    v1: { resource: "audience_schema", grant: "update" },
                  }}
                  redirect={redirectSubsetCategories}
                >
                  <FormErrorProvider>
                    <Subset />
                  </FormErrorProvider>
                </PermissionedRoute>
              }
            />
            <Route
              path=":subset_category_id/subsets/:subset_id"
              element={
                <PermissionedRoute
                  permission={{
                    v1: { resource: "audience_schema", grant: "read" },
                  }}
                  redirect={redirectSubsetCategories}
                >
                  <FormErrorProvider>
                    <Subset />
                  </FormErrorProvider>
                </PermissionedRoute>
              }
            />
          </Route>
        )}
      </Route>
    </Routes>
  );
};
