import type { RawColumn, RelatedColumn } from "./column";

export enum AggregationType {
  Count = "count",
  Sum = "sum",
}

export type Aggregation = CountAggregation | SumAggregation;

export type CountAggregation = {
  type: AggregationType.Count;
  column?: RawColumn | RelatedColumn;
};

export type SumAggregation = {
  type: AggregationType.Sum;
  column: RawColumn | RelatedColumn;
};

// User (and LLM) visible aggregation options. Each one of these options corresponds to an
// audience/per-user aggregation pair.
export enum AggregationOption {
  Count = "count",
  CountDistinctProperty = "countDistinctProperty",
  UniqueUsers = "uniqueUsers",
  PercentOfAudience = "percentOfAudience",
  SumOfProperty = "sum",
  SumOfPropertyPerUser = "sumPerUser",
  AverageOfProperty = "average",
  AverageOfPropertyPerUser = "averageOfPropertyPerUser",
}
