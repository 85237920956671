import { query } from "src/utils/query";

import { basicApiEndpoint, isFailedResponse, responseToError } from "./fetcher";
import { getHightouchRegionConfig } from "./regions";

export const switchWorkspace = async (
  id: string | number,
  to?: string,
  upgradeOptions?: {
    requestWritePermission: boolean;
    upgradeReason?: string;
    requestUserGroups?: string[];
  },
): Promise<boolean> => {
  try {
    await fetch(`${import.meta.env.VITE_API_BASE_URL}/auth/switch-workspace`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        workspaceId: Number(id),
        ...(upgradeOptions || {}),
      }),
    });
    window.location.href = to || "/";
    return true;
  } catch (error) {
    console.error(error);
  }
  return false;
};

export const adminSwitchWorkspace = async (slug: string, path?: string) => {
  const data = await query<{ workspaces?: { id: string }[] }>(
    `query WorkspaceBySlug($slug: String!) { workspaces(where: { slug: { _ilike: $slug } }) { id } }`,
    {
      slug,
    },
    {
      isAdmin: true,
    },
  );

  const id = data.workspaces?.[0]?.id;

  if (id) {
    return switchWorkspace(id, path);
  }
  return false;
};

export const verifyWorkspace = async (): Promise<void> => {
  const headers = {
    "Content-Type": "application/json",
  };

  const apiRegion = window.Hightouch?.apiRegion;
  const apiEndpoint = apiRegion
    ? getHightouchRegionConfig(apiRegion).apiEndpoint
    : basicApiEndpoint;
  const workspaceId = window.Hightouch?.workspaceId;

  if (workspaceId) {
    headers["X-Hightouch-Requested-Workspace-Id"] = String(workspaceId);
  }

  const res = await fetch(`${apiEndpoint}/verify-workspace`, {
    method: "GET",
    headers,
    credentials: "include",
  });

  if (isFailedResponse(res)) {
    throw await responseToError(res);
  }
};
