import { FC } from "react";

import { Row, Box } from "@hightouchio/ui";
import { Highlight } from "react-instantsearch-hooks-web";

import { DestinationDefinitionFragment as DestinationDefinition } from "src/graphql";
import * as analytics from "src/lib/analytics";
import { DestinationIcon } from "src/ui/icons";
import { Selectable } from "src/ui/selectable";

import { IntegrationBadge } from "../../integrations/integration-badge";

type DestinationHitProps = {
  category: string;
  definition: DestinationDefinition | undefined;
  selected: boolean;
  hit: any; // Algolia doesn't expose this type to us, so we are leaving it untyped.
  onSendEvent: (eventType: string, hit: any, eventName?: string) => void;
  onSelect: (definition: DestinationDefinition, hit: any) => void;
};

export const DestinationHit: FC<Readonly<DestinationHitProps>> = ({
  category,
  definition,
  selected,
  hit,
  onSelect,
  onSendEvent,
}) => {
  if (!definition) {
    return null;
  }

  const selectDefinition = (definition: DestinationDefinition, hit: any) => {
    // send analytics regardless of status
    onSendEvent("click", hit, "Destination Catalog Destination Clicked");
    analytics.track("Destination Catalog Destination Clicked", {
      destination_name: definition.name,
      destination_slug: definition.type,
      destination_status: definition.status,
      current_category: category,
    });
    onSelect(definition, hit);
  };

  return (
    <Row sx={{ "& > button": { borderColor: "transparent" } }}>
      <Selectable
        selected={selected}
        px={5}
        height="50px"
        onSelect={() => selectDefinition(definition, hit)}
        width="100%"
      >
        <Row align="center" justify="space-between" gap={4}>
          <Row align="center" gap={4}>
            {definition?.icon ? (
              <Box
                as="img"
                src={`${definition?.icon}?h=40`}
                height="20px"
                width="20px"
                objectFit="contain"
                flexShrink={0}
              />
            ) : (
              <DestinationIcon size={20} />
            )}
            <Box
              as="p"
              fontSize="md"
              fontWeight="medium"
              textAlign="left"
              sx={{
                wordBreak: "break-word",
                color: selected ? "inherit" : "gray.900",
                transition: "all 100ms ease-in-out",
                "& strong": {
                  color: "grass",
                  fontWeight: 600,
                },
              }}
            >
              <Highlight
                attribute="name"
                highlightedTagName="strong"
                hit={hit}
              />
            </Box>
          </Row>
          <IntegrationBadge
            definition={definition}
            integrationType="destination"
            isSample={false}
          />
        </Row>
      </Selectable>
    </Row>
  );
};
