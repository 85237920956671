import { Badge, BadgeGroup } from "@hightouchio/ui";
import { FC } from "react";

export const MetadataBadgeGroup: FC<
  Readonly<{ metadata: Record<string, unknown> }>
> = ({ metadata: labels }) => (
  <BadgeGroup size="sm">
    {Object.keys(labels)
      // XXX: Work around a bug where the backend returns an
      // object for the event name label when the event
      // column is a merge column reference.
      .filter((key) => typeof labels[key] != "object")
      .map((key) => (
        <Badge key={key} size="sm">
          {String(labels[key])}
        </Badge>
      ))}
  </BadgeGroup>
);

MetadataBadgeGroup.displayName = "MetadataBadgeGroup";
