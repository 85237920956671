import { FC, useEffect, useState } from "react";

import {
  Column,
  FormField,
  Heading,
  Row,
  Spinner,
  Text,
  TextInput,
  useToast,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { Controller, useForm } from "react-hook-form";

import { Outlet, useOutletContext } from "src/router";
import { ActionBar } from "src/components/action-bar";
import lookerImage from "src/components/extensions/assets/looker.png";
import { Overview } from "src/components/extensions/overview";
import { DetailPage } from "src/components/layout";
import { PermissionedButton } from "src/components/permission";
import { PermissionProvider } from "src/components/permission/permission-context";
import { TunnelSelect } from "src/components/tunnel-select";
import {
  LookerCredentialsQuery,
  useCreateLookerCredentialMutation,
  useDeleteLookerExtensionMutation,
  useLookerCredentialsQuery,
  useTestLookerExtensionQuery,
  useUpdateLookerCredentialMutation,
} from "src/graphql";
import { LookerIcon } from "src/ui/icons";
import { ConnectedExtension } from "./common";
import { DocsLink } from "src/components/docs-link";
import { Card } from "src/components/card";

export const LookerOverview: FC = () => {
  return (
    <Overview
      description="When you create a Hightouch model backed by a Look, Hightouch converts that Look to SQL and runs it against your data source."
      icon={LookerIcon}
      image={lookerImage}
      subtitle="Import models from Looker"
      title="Looker"
    />
  );
};

export const Looker: FC = () => {
  const { data: credentials, isLoading: loading } = useLookerCredentialsQuery(
    undefined,
    {
      select: (data) => data.looker_credentials?.[0],
    },
  );

  return (
    <DetailPage
      bg="base.lightBackground"
      crumbs={[{ label: "Extensions", link: "/extensions" }]}
      title="Looker - Extensions"
      header={<Heading size="xl">Looker</Heading>}
      tabs={[
        {
          path: "",
          title: "Overview",
        },
        {
          path: "configuration",
          title: "Configuration",
        },
      ]}
    >
      <Outlet context={{ credentials, loading }} />
    </DetailPage>
  );
};

type LookerCredentials = LookerCredentialsQuery["looker_credentials"][0];

interface OutletContext {
  loading: boolean;
  credentials: LookerCredentials;
}
type LookerTestStatus = "failed" | "loading" | "success" | "error";

export const LookerConfiguration: FC = () => {
  const { loading, credentials } = useOutletContext<OutletContext>();
  const { toast } = useToast();
  const {
    reset,
    handleSubmit,
    control,
    watch,
    formState: { isDirty, isSubmitting },
  } = useForm();

  const { mutateAsync: deleteExtension, isLoading: extensionDeleting } =
    useDeleteLookerExtensionMutation();
  const [testStatus, setTestStatus] = useState<LookerTestStatus>("loading");

  const isTunnel = Boolean(watch("tunnel_id"));
  const {
    data: testResult,
    isLoading: isTesting,
    refetch: testExtension,
    isRefetching: isReTesting,
  } = useTestLookerExtensionQuery(
    {},
    { select: (data) => data.testLookerExtension, enabled: !!credentials },
  );

  const { mutateAsync: create } = useCreateLookerCredentialMutation();
  const { mutateAsync: update } = useUpdateLookerCredentialMutation();

  const submit = async (data) => {
    if (!data.tunnel_id) {
      delete data.tunnel_id;
    }

    try {
      if (credentials?.id) {
        await update({
          credentials: { ...data, id: credentials.id },
        });
      } else {
        await create({
          credentials: data,
        });
      }

      toast({
        id: "save-looker",
        title: "Configuration was saved",
        variant: "success",
      });
    } catch (e) {
      toast({
        id: "save-looker",
        title: "There was an error saving your configuration",
        variant: "error",
      });
      Sentry.captureException(e);
    }
  };

  useEffect(() => {
    if (credentials) {
      if (isTesting || isReTesting) {
        setTestStatus("loading");
        return;
      }
      if (!testResult) {
        setTestStatus("failed");
        toast({
          id: "looker-extension",
          variant: "error",
          title: "Looker credentials test failed.",
          message: "Please check your Looker API credentials.",
        });
      } else {
        setTestStatus("success");
      }
    }
  }, [credentials, isTesting, isReTesting]);

  useEffect(() => {
    reset({
      tunnel_id: credentials?.tunnel_id ?? "",
      url: credentials?.url ?? "",
      client_id: credentials?.client_id ?? "",
      client_secret: "",
    });
  }, [credentials]);

  if (loading) {
    return <Spinner size="lg" />;
  }

  return (
    <PermissionProvider
      permission={{
        v2: {
          resource: "workspace",
          grant: "can_update",
        },
      }}
    >
      <Column gap={6} maxW="2xl">
        <Row justifyContent="space-between">
          <Heading>Looker configuration</Heading>
          <DocsLink name="Looker extension" href="models/looker-models" />
        </Row>
        {credentials?.id ? (
          <ConnectedExtension credentials={credentials} testStatus={testStatus}>
            <Text fontWeight="medium">
              <Text fontWeight="semibold">Client ID: </Text>
              {credentials?.client_id || "Not configured"}
            </Text>
          </ConnectedExtension>
        ) : (
          <Card gap={6}>
            <Controller
              control={control}
              name="tunnel_id"
              render={({ field }) => (
                <TunnelSelect
                  value={field.value ? { id: field.value } : undefined}
                  onChange={(tunnel) => {
                    field.onChange(tunnel?.id ?? null);
                  }}
                />
              )}
            />

            <Controller
              control={control}
              name="url"
              render={({ field }) => (
                <FormField label="URL">
                  <TextInput isDisabled={isTunnel} {...field} />
                </FormField>
              )}
            />

            <Controller
              control={control}
              name="client_id"
              render={({ field }) => (
                <FormField label="Client ID">
                  <TextInput isDisabled={loading} {...field} />
                </FormField>
              )}
            />

            <Controller
              control={control}
              name="client_secret"
              render={({ field }) => (
                <FormField label="Client secret">
                  <TextInput type="password" isDisabled={loading} {...field} />
                </FormField>
              )}
            />
          </Card>
        )}
      </Column>
      <ActionBar>
        {credentials?.id ? (
          <>
            <PermissionedButton
              permission={{
                v2: { resource: "workspace", grant: "can_update" },
              }}
              size="lg"
              isDisabled={isTesting || isReTesting || extensionDeleting}
              onClick={() => {
                setTestStatus("loading");
                testExtension({});
              }}
            >
              {isTesting || isReTesting ? "Testing..." : "Test connection"}
            </PermissionedButton>
            <PermissionedButton
              permission={{
                v2: { resource: "workspace", grant: "can_update" },
              }}
              size="lg"
              isDisabled={extensionDeleting || isTesting}
              variant="danger"
              onClick={async () => {
                try {
                  await deleteExtension({
                    workspaceId: credentials.workspace_id,
                  });
                  toast({
                    id: "looker-extension",
                    title: "Looker disconnected",
                    variant: "success",
                  });
                } catch (e) {
                  toast({
                    id: "looker-extension",
                    title: "Could not disconnect Looker",
                    variant: "success",
                  });
                }
              }}
            >
              Disconnect
            </PermissionedButton>
          </>
        ) : (
          <PermissionedButton
            permission={{
              v2: { resource: "workspace", grant: "can_update" },
            }}
            size="lg"
            variant="primary"
            isDisabled={!isDirty}
            isLoading={isSubmitting}
            onClick={handleSubmit(submit)}
          >
            Connect
          </PermissionedButton>
        )}
      </ActionBar>
    </PermissionProvider>
  );
};
