import { FC } from "react";

import {
  Column,
  Combobox,
  FormField,
  Row,
  SectionHeading,
  Text,
} from "@hightouchio/ui";
import { Controller, useFormContext } from "react-hook-form";
import { Link } from "src/router";

import { ErrorMessage } from "src/components/explore/visual/error-message";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import {
  ColumnType,
  ModelDetails,
  ModelState,
} from "src/pages/identity-resolution/types";

import { MapperField } from "./mapper-field";

type ModelConfigv2Props = {
  sourceId: string;
  identifierOptions: string[];
  model: ModelDetails;
};

export const ModelConfigv2: FC<ModelConfigv2Props> = ({
  identifierOptions,
  model,
  sourceId,
}) => {
  const modelId = model.id;

  const form = useFormContext<ModelState>();

  const timestampColumns = model.columns.filter((column) =>
    [ColumnType.Date, ColumnType.Timestamp].includes(column.type as ColumnType),
  );
  return (
    <Column gap={8}>
      <Row align="center" gap={3}>
        <IntegrationIcon
          src={model.connection?.definition.icon}
          name={model.connection?.definition.name}
        />
        <SectionHeading>{model.name}</SectionHeading>
      </Row>
      <FormField
        label="Select a timestamp column"
        description={
          <Column gap={2}>
            <Text>
              A timestamp column is required to process this data. If your model
              doesn’t have a timestamp column and should, you may{" "}
              <Link
                isExternal
                href={`/schema-v2/view/query?source=${sourceId}&id=${modelId}`}
              >
                update its configuration
              </Link>{" "}
              and then come back to add it.
            </Text>
            <Text>
              For event data, the timestamp should represent the time of the
              event. For non-event data, the timestamp should represent the time
              the row was last updated.
            </Text>
          </Column>
        }
      >
        <Controller
          control={form.control}
          name="order_by"
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <Column>
              <Combobox
                isClearable
                isInvalid={Boolean(error)}
                placeholder="Select a column..."
                emptyOptionsMessage="No date or timestamp columns found"
                options={timestampColumns}
                // TODO(samuel): add description to the SyncableColumn type
                optionLabel={(o) => o.alias ?? o.name}
                optionValue={(o) => o.name}
                {...field}
              />
              {error && <ErrorMessage>{error.message}</ErrorMessage>}
            </Column>
          )}
        />
      </FormField>

      <MapperField
        columns={model.columns}
        identifierOptions={identifierOptions}
      />
    </Column>
  );
};
