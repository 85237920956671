import { QueryType } from "src/types/models";
import { DuplicateKeyWarning } from "src/pages/syncs/sync/components/duplicate-key-warnings";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Alert } from "@hightouchio/ui";
import { LinkButton, useNavigate } from "src/router";
import { isMonitorStatus } from "src/pages/syncs/sync/components/utils";
import { SyncHealthAlertsForSync } from "src/pages/syncs/sync/components/sync-health-alerts";
import { useUser } from "src/contexts/user-context";
import { Sync } from "src/pages/syncs/sync/utils/types";
import { MonitoredResourceType } from "@hightouch/lib/resource-monitoring/types";

type Props = {
  sync: Sync;
};

export const AlertsAndWarnings = ({ sync }: Props) => {
  const flags = useFlags();
  const { workspace } = useUser();
  const navigate = useNavigate();

  const model = sync.segment;
  const plannerType = sync.last_run_planner_type;
  const duplicatePrimaryKeysCount = model?.last_run_duplicate_primary_keys;

  const showDuplicateKeyWarning =
    !!duplicatePrimaryKeysCount &&
    plannerType !== "all" &&
    plannerType !== null;

  const hasPrimaryKeyIssue =
    model?.query_type === QueryType.Visual
      ? Boolean(
          model?.parent?.columns?.length &&
            !model?.parent?.columns.some(
              (c) => c.name === model?.parent?.primary_key,
            ),
        )
      : Boolean(
          model?.columns?.length &&
            !model?.columns.some((c) => c.name === model?.primary_key),
        );

  return (
    <>
      {showDuplicateKeyWarning && (
        <DuplicateKeyWarning
          count={duplicatePrimaryKeysCount}
          primaryKey={model?.primary_key || ""}
          parentModelUrl={
            model?.query_type === QueryType.Visual
              ? flags.schemaV2
                ? `/schema-v2/view?source=${model?.parent?.connection?.id}&id=${model?.parent?.id}`
                : `/schema/parent-models/${model?.parent?.id}`
              : `/models/${model?.id}`
          }
        />
      )}
      {hasPrimaryKeyIssue && (
        <Alert
          type="warning"
          variant="inline"
          title="Undefined model primary key"
          message="Without a model primary key, your syncs may fail, or undefined behavior may occur. Please check to make sure that your primary key is set to a valid column."
          actions={
            <LinkButton
              href={
                model?.query_type === QueryType.Visual
                  ? flags.schemaV2
                    ? `/schema-v2/view?source=${model?.parent?.connection?.id}&id=${model?.parent?.id}`
                    : `/schema/parent-models/${model?.parent?.id}`
                  : `/models/${model?.id}`
              }
            >
              Go to your model
            </LinkButton>
          }
        />
      )}
      {sync &&
        workspace?.alerting_v2_enabled &&
        isMonitorStatus(sync.health) && (
          <SyncHealthAlertsForSync
            resourceType={MonitoredResourceType.Sync}
            syncId={sync.id.toString()}
            onClick={() => navigate(`/syncs/${sync.id}/alerting`)}
          />
        )}
    </>
  );
};
