import { useEffect, useState } from "react";

/**
 * Manually appends a div element to the dom. This
 * can be used as a replacement for a portal.
 *
 * @returns { current: HTMLDivElement }
 */
export const useManualPortal = () => {
  const [container, setContainer] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    const container = document.createElement("div");
    document.body.appendChild(container);
    setContainer(container);

    return () => {
      document.body.removeChild(container);
      setContainer(null);
    };
  }, []);

  return { current: container };
};
