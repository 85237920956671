import { FC, useEffect, useMemo } from "react";

import { Column, Row, Select, Text } from "@hightouchio/ui";
import { capitalize } from "lodash";
import { v4 as uuidv4 } from "uuid";

import { useFormErrorContext } from "src/contexts/form-error-context";
import {
  ConditionType,
  EventCondition,
  FunnelCondition,
  TimestampOperator,
  Window,
  windowOperatorOptions,
  funnelWindowOperatorOptions,
  shouldResetValue,
  shouldResetTimeType,
  TimeType,
} from "src/types/visual";

import { XButton } from "./condition-buttons";
import { validateWindowCondition } from "./condition-validation";
import { Filter } from "./filter";
import { TimestampInput } from "./timestamp-input";
import { getTimeValue } from "./utils";

type Props = { eventName?: string } & (
  | {
      condition: EventCondition;
      onChange: (updates: Partial<EventCondition>) => void;
    }
  | {
      condition: FunnelCondition;
      onChange: (updates: Partial<FunnelCondition>) => void;
    }
);

export const WindowFilter: FC<Readonly<Props>> = ({
  condition,
  onChange,
  eventName,
}) => {
  const filterId = useMemo<string>(uuidv4, []);
  const { window } = condition;

  const { getErrors, setFieldError, removeErrors } = useFormErrorContext();
  const filterErrors = getErrors(filterId);

  const quantityError = filterErrors?.quantity;
  const valueError = filterErrors?.value;

  useEffect(() => {
    setFieldError(filterId, validateWindowCondition(window));

    return () => {
      removeErrors([filterId]);
    };
  }, [condition.eventModelId, filterId, window]);

  const isFunnelWindow = condition.type === ConditionType.Funnel;
  const operatorLabel = capitalize(
    windowOperatorOptions.find((option) => option.value === window?.operator)
      ?.label,
  );
  const formattedValue = getTimeValue(
    window?.value,
    window?.timeType,
    isFunnelWindow,
  );

  const update = (updates: Partial<Window>) => {
    onChange({ window: { ...window!, ...updates } });
  };

  return (
    <Row pl={8}>
      <Column>
        <Filter
          content={
            <Row gap={2}>
              <Select
                removePortal
                options={
                  isFunnelWindow
                    ? funnelWindowOperatorOptions
                    : windowOperatorOptions
                }
                placeholder="Filter on"
                value={window?.operator}
                width="auto"
                onChange={(operator) => {
                  if (shouldResetTimeType(window?.operator, operator)) {
                    update({
                      operator,
                      timeType: TimeType.Relative,
                      value: undefined,
                    });
                  } else if (
                    shouldResetValue(null, operator, window?.operator)
                  ) {
                    update({ operator, value: undefined });
                  } else {
                    update({ operator });
                  }
                }}
              />
              {window && (
                <TimestampInput
                  condition={window}
                  error={quantityError || valueError}
                  hideTime={false}
                  showRelativeDirections={!isFunnelWindow}
                  onChange={update}
                />
              )}
            </Row>
          }
          error={quantityError || valueError}
          mr={2}
        >
          <Row>
            <Text color="text.secondary" mr={1}>
              {operatorLabel}
            </Text>

            {formattedValue && (
              <>
                {formattedValue}
                {eventName && (
                  <>
                    <Text fontWeight="medium" ml={1}>
                      {window?.operator === TimestampOperator.Between
                        ? "after"
                        : "of"}
                    </Text>
                    <Text
                      isTruncated
                      ml={1}
                      color="text.secondary"
                      fontWeight="normal"
                    >
                      {eventName}
                    </Text>
                  </>
                )}
              </>
            )}
          </Row>
        </Filter>
      </Column>
      <XButton onRemove={() => onChange({ window: null })} />
    </Row>
  );
};
