import { useMemo } from "react";
import {
  MonitorConditionTypes,
  MonitoredResourceType,
  ParentResourceTypes,
} from "@hightouch/lib/resource-monitoring/types";
import { assertedConditionType } from "@hightouch/lib/resource-monitoring/assertions";

import { BulkEditableMonitorConditionTypes } from "./monitor-condition-forms/monitor-condition-form";
import {
  getMinimalCondition,
  isOverride,
  isSupportedConditionForResourceType,
} from "./utils";
import {
  useResourceTypeMonitorConditionsQuery,
  useSubscribeableResourcesQuery,
} from "src/graphql";

/*
    Returns an object with the following shape:
    {
        [conditionType]: {
            destinations: {
                [destinationId]: [syncId, ...]
            }
        }
    }
    Where syncIds are the ids of the syncs that override the condition for the given destination
*/
export const useOverridesState = (destinationIds: string[]) => {
  const { data: syncsAndDestinations } = useSubscribeableResourcesQuery(
    undefined,
    { suspense: true },
  );

  const { data } = useResourceTypeMonitorConditionsQuery(
    {
      resourceType: MonitoredResourceType.Sync,
      parentResourceType: ParentResourceTypes.Destination,
      includeTemplates: true,
    },
    { suspense: true },
  );

  return useMemo(() => {
    const overrides: Record<
      BulkEditableMonitorConditionTypes,
      { destinations: Record<string, string[]> }
    > = {
      [MonitorConditionTypes.SyncSequentialFailures]: {
        destinations: {},
      },
      [MonitorConditionTypes.SyncRejectedRows]: {
        destinations: {},
      },
      [MonitorConditionTypes.SyncDuration]: {
        destinations: {},
      },
      [MonitorConditionTypes.SyncMissedSchedules]: {
        destinations: {},
      },
      [MonitorConditionTypes.SyncThroughput]: {
        destinations: {},
      },
      [MonitorConditionTypes.ModelSize]: {
        destinations: {},
      },
    };

    const destinationToSyncCount: Record<string, number> = {};

    for (const destination of syncsAndDestinations?.destinations || []) {
      destinationToSyncCount[destination.id.toString()] =
        destination.syncs_aggregate.aggregate?.count ?? 0;

      const destinationId = destination.id.toString();

      const existingDestinationSyncConditions =
        data!.monitor_condition_templates?.filter(
          (condition) =>
            condition.parent_resource_id === destination.id.toString() &&
            isSupportedConditionForResourceType(
              MonitoredResourceType.Sync,
              condition.type,
            ),
        ) || [];

      const destinationSyncIds =
        syncsAndDestinations?.syncs
          ?.filter((sync) => sync?.destination?.id.toString() === destinationId)
          ?.map((sync) => sync.id.toString()) || [];

      if (!existingDestinationSyncConditions.length) continue;

      for (const condition of existingDestinationSyncConditions) {
        const syncsOverridingCondition =
          data!.resource_monitor_conditions.filter(
            (rmc) =>
              destinationSyncIds.includes(rmc.resource_id) &&
              rmc.resource_type === MonitoredResourceType.Sync &&
              rmc.type === condition.type &&
              isOverride(
                getMinimalCondition(rmc),
                getMinimalCondition(condition),
              ),
          );
        overrides[assertedConditionType(condition.type)]["destinations"][
          destinationId
        ] = syncsOverridingCondition.map((rmc) => rmc.resource_id);
      }
    }

    const getSyncCount = (destinationIds: string[]) =>
      destinationIds.reduce((acc, destinationId) => {
        return acc + (destinationToSyncCount[destinationId] ?? 0);
      }, 0);

    const getOverrideCount = (
      conditionType: BulkEditableMonitorConditionTypes,
      destinationIds: string[],
    ) =>
      destinationIds.reduce((acc, destinationId) => {
        return (
          acc +
          (overrides[conditionType]["destinations"][destinationId]?.length ?? 0)
        );
      }, 0);

    return { overrides, getSyncCount, getOverrideCount };
  }, [destinationIds]);
};
