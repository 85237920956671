import { useOutletContext } from "src/router";
import { OutletContext } from "src/events/destinations/destination/types";
import { ForwardingConfiguration } from "./forwarding-configuration";
import { WarehouseConfiguration } from "./warehouse-configuration";

export const Configuration = () => {
  const { type, destination } = useOutletContext<OutletContext>();

  if (type === "forwarding") {
    return <ForwardingConfiguration destination={destination} />;
  }

  return <WarehouseConfiguration destination={destination} />;
};
