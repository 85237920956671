import { createContext, FC, useContext, useMemo, useState } from "react";

import { Box, Column, ToggleButton, ToggleButtonGroup } from "@hightouchio/ui";

import {
  DashboardSyncMetricGranularity,
  SyncHealthQuery,
  SyncHealthQueryVariables,
  useSyncHealthQuery,
} from "src/graphql";
import { getQueryWithOpts } from "src/utils/query-with-opts";

import { DashboardMainItem } from "./dashboard-main-item";
import { SyncHealthChart } from "./sync-health-chart";
import { useSyncHealthContext } from "./sync-health-context";
import { SyncRequestModal } from "./sync-request-modal";
import {
  EnrichedSyncMetric,
  transformSyncAttempts,
  transformSyncRequests,
} from "./transformations";

const SUCCESS_BAR_COLOR = "var(--chakra-colors-grass-500)";
const SUCCESS_BAR_COLOR_HIGHLIGHT = "var(--chakra-colors-grass-400)";
const WARNING_BAR_COLOR = "var(--chakra-colors-warning-500)";
const WARNING_BAR_COLOR_HIGHLIGHT = "var(--chakra-colors-warning-400)";
const FAILED_BAR_COLOR = "var(--chakra-colors-danger-500)";
const FAILED_BAR_COLOR_HIGHLIGHT = "var(--chakra-colors-danger-400)";

type SyncHealthChartsContextType = {
  activeIndex: number;
  setActiveIndex: (index: number) => void;
  handleMouseMove: (e: any) => void;
};

const SyncHealthChartsContext = createContext<SyncHealthChartsContextType>({
  activeIndex: -1,
  setActiveIndex: () => {},
  handleMouseMove: () => {},
});

export const useSyncHealthChartsContext = () =>
  useContext(SyncHealthChartsContext);

export const SyncHealthChartsContextProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [activeIndex, setActiveIndex] = useState<number>(-1);

  const handleMouseMove = (e) => {
    if (e && e.activeTooltipIndex !== undefined) {
      setActiveIndex(e.activeTooltipIndex);
    }
  };

  return (
    <SyncHealthChartsContext.Provider
      value={{
        activeIndex,
        setActiveIndex,
        handleMouseMove,
      }}
    >
      {children}
    </SyncHealthChartsContext.Provider>
  );
};

export interface SyncHealthChartData {
  dashboardSyncRequests: EnrichedSyncMetric<
    NonNullable<SyncHealthQuery["dashboardSyncRequests"]>[0]
  >[];
  dashboardSyncAttempts: EnrichedSyncMetric<
    NonNullable<SyncHealthQuery["dashboardSyncAttempts"]>[0]
  >[];
}

export interface TargetSyncs {
  destinationInstanceIds: string[];
  startPeriod: string;
  endPeriod: string | null;
}

export const useFastSyncHealthQuery = getQueryWithOpts<
  SyncHealthQuery,
  SyncHealthQueryVariables
>(useSyncHealthQuery, {
  useFastEndpoint: true,
});

export const SyncHealthCharts: FC = () => {
  const { filter, granularity, setGranularity } = useSyncHealthContext();

  const [targetSyncs, setTargetSyncs] = useState<TargetSyncs>({
    destinationInstanceIds: [],
    startPeriod: "",
    endPeriod: null,
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [defaultFilters, setDefaultFilters] =
    useState<Record<string, string[]>>();

  const { isLoading: isChartLoading, data: chartData } = useFastSyncHealthQuery(
    { granularity, sourceIds: [], destinationIds: [] },
    {
      select: (data) => {
        // We add source and destination types and names to the sync requests
        const sourceLookup: Record<
          string,
          { friendlyType: string; type: string; name: string; icon?: string }
        > = {};
        const destinationLookup: Record<
          string,
          { friendlyType: string; type: string; name: string; icon?: string }
        > = {};
        data.connections.forEach((c) => {
          const sourceDefinition = data.getSourceDefinitions.find(
            (sd) => sd.type === c.type,
          );
          sourceLookup[c.id.toString()] = {
            type: c.type,
            name: c.name || sourceDefinition?.name || c.type,
            friendlyType: sourceDefinition?.name || c.type,
            icon: sourceDefinition?.icon,
          };
        });
        data.destinations.forEach((d) => {
          const destinationDefinition = data.getDestinationDefinitions.find(
            (dd) => dd.type === d.type,
          );
          destinationLookup[d.id.toString()] = {
            type: d.type,
            name: d.name || destinationDefinition?.name || d.type,
            friendlyType: destinationDefinition?.name || d.type,
            icon: destinationDefinition?.icon,
          };
        });
        const enrichedSyncRequests = data?.dashboardSyncRequests?.map((sr) => {
          if (!sr.destination_id || !sr.source_id) {
            return {
              ...sr,
              source_type: undefined,
              sourceName: undefined,
              destinationType: undefined,
              destinationName: undefined,
              destinationIcon: undefined,
              sourceIcon: undefined,
              destinationFriendlyType: undefined,
              sourceFriendlyType: undefined,
            };
          }
          const source = sourceLookup[sr.source_id.toString()];
          const destination = destinationLookup[sr.destination_id.toString()];
          return {
            ...sr,
            source_type: source?.type,
            sourceName: source?.name || source?.type,
            destinationType: destination?.type,
            destinationName: destination?.name || destination?.type,
            destinationIcon: destination?.icon,
            sourceIcon: source?.icon,
            destinationFriendlyType: destination?.friendlyType,
            sourceFriendlyType: source?.friendlyType,
          };
        });

        const enrichedSyncAttempts = data?.dashboardSyncAttempts?.map((sa) => {
          if (!sa.destination_id || !sa.source_id) {
            return {
              ...sa,
              source_type: undefined,
              sourceName: undefined,
              destinationType: undefined,
              destinationName: undefined,
              destinationIcon: undefined,
              sourceIcon: undefined,
              sourceFriendlyType: undefined,
              destinationFriendlyType: undefined,
            };
          }
          const source = sourceLookup[sa.source_id.toString()];
          const destination = destinationLookup[sa.destination_id.toString()];
          return {
            ...sa,
            source_type: source?.type,
            sourceName: source?.name || source?.type,
            destinationType: destination?.type,
            destinationName: destination?.name || destination?.type,
            destinationIcon: destination?.icon,
            sourceIcon: source?.icon,
            sourceFriendlyType: source?.friendlyType,
            destinationFriendlyType: destination?.friendlyType,
          };
        });

        return {
          dashboardSyncRequests: enrichedSyncRequests || [],
          dashboardSyncAttempts: enrichedSyncAttempts || [],
        };
      },
    },
  );

  // We allow filters here so we only show the syncs that are in the time window and don't bother querying syncs with
  // an irrelevant status
  function setTargetSyncsFromChart(activeIndex: number) {
    const timeBucketTargetStart =
      transformedOperations?.[activeIndex]?.time_bucket;
    const timeBucketTargetEnd =
      transformedOperations?.[activeIndex + 1]?.time_bucket ?? null;

    const matchingRequests = chartData?.dashboardSyncRequests.filter(
      (cd) => cd.time_bucket === timeBucketTargetStart,
    );

    if (!timeBucketTargetStart || !matchingRequests?.length) {
      return;
    }
    const rawSyncsInTimeWindow: string[] = [];
    matchingRequests.forEach((r) => {
      rawSyncsInTimeWindow.push(...r.counts.failed);
      rawSyncsInTimeWindow.push(...r.counts.succeeded);
      rawSyncsInTimeWindow.push(...r.counts.warning);
    });
    const syncsInTimeWindow = new Set<string>(rawSyncsInTimeWindow);

    setTargetSyncs({
      destinationInstanceIds: Array.from(syncsInTimeWindow),
      startPeriod: timeBucketTargetStart,
      endPeriod: timeBucketTargetEnd,
    });
    setModalOpen(true);
  }

  const transformedSyncRequests = useMemo(() => {
    if (!chartData) return [];
    return transformSyncRequests(chartData.dashboardSyncRequests, filter);
  }, [chartData?.dashboardSyncRequests, granularity, filter]);

  const transformedOperations = useMemo(() => {
    if (!chartData) return [];
    return transformSyncAttempts(chartData.dashboardSyncAttempts, filter);
  }, [chartData?.dashboardSyncRequests, granularity, filter]);

  const noSyncRequests = !transformedSyncRequests.filter(
    (r) => r.succeeded || r.warning || r.failed,
  ).length;
  const noFailedSyncRequests = !transformedSyncRequests.filter(
    (r) => r.warning || r.failed,
  ).length;
  const noOperations = !transformedOperations.filter(
    (r) => r.successful_operations || r.failed_operations,
  ).length;
  const noFailedOperations = !transformedOperations.filter(
    (r) => r.failed_operations,
  ).length;

  return (
    <SyncHealthChartsContextProvider>
      <DashboardMainItem isLoading={isChartLoading}>
        <Column py={4} px={6} gap={4}>
          <ToggleButtonGroup
            size="sm"
            value={granularity}
            onChange={(v) =>
              setGranularity(v as DashboardSyncMetricGranularity)
            }
          >
            <ToggleButton
              label="4hr"
              value={DashboardSyncMetricGranularity.FourHour}
            />
            <ToggleButton
              label="24hr"
              value={DashboardSyncMetricGranularity.OneDay}
            />
            <ToggleButton
              label="48hr"
              value={DashboardSyncMetricGranularity.TwoDay}
            />
            <ToggleButton
              label="7d"
              value={DashboardSyncMetricGranularity.OneWeek}
            />
          </ToggleButtonGroup>

          <Box
            height="fit-content"
            minHeight="300px"
            display="grid"
            gridTemplateColumns="1fr 1fr"
            rowGap={4}
            columnGap={8}
          >
            <SyncHealthChart
              description="Number of sync runs triggered manually or via recurring schedule"
              onClick={(index) => {
                setDefaultFilters({});
                setTargetSyncsFromChart(index);
              }}
              bars={[
                {
                  stackId: "status",
                  dataKey: "failed",
                  fill: FAILED_BAR_COLOR,
                  highlightedFill: FAILED_BAR_COLOR_HIGHLIGHT,
                },
                {
                  stackId: "status",
                  dataKey: "warning",
                  fill: WARNING_BAR_COLOR,
                  highlightedFill: WARNING_BAR_COLOR_HIGHLIGHT,
                },
                {
                  stackId: "status",
                  dataKey: "succeeded",
                  fill: SUCCESS_BAR_COLOR,
                  highlightedFill: SUCCESS_BAR_COLOR_HIGHLIGHT,
                },
              ]}
              data={transformedSyncRequests}
              title="Sync runs"
              emptyText="No sync runs"
              isEmpty={!!noSyncRequests}
            />

            <SyncHealthChart
              description="Number of rows added, changed, or removed during sync runs"
              bars={[
                {
                  stackId: "status",
                  dataKey: "failed_operations",
                  fill: FAILED_BAR_COLOR,
                  highlightedFill: FAILED_BAR_COLOR_HIGHLIGHT,
                },
                {
                  stackId: "status",
                  dataKey: "successful_operations",
                  fill: SUCCESS_BAR_COLOR,
                  highlightedFill: SUCCESS_BAR_COLOR_HIGHLIGHT,
                },
              ]}
              onClick={(index) => {
                setDefaultFilters({ status: ["success", "warning"] });
                setTargetSyncsFromChart(index);
              }}
              data={transformedOperations}
              title="Rows processed"
              emptyText="No processed rows"
              isEmpty={noOperations}
            />

            <SyncHealthChart
              description="Number of sync runs that encountered fatal or row-level errors"
              onClick={(index) => {
                setDefaultFilters({ status: ["warning", "failed"] });
                setTargetSyncsFromChart(index);
              }}
              bars={[
                {
                  stackId: "status",
                  dataKey: "failed",
                  fill: FAILED_BAR_COLOR,
                  highlightedFill: FAILED_BAR_COLOR_HIGHLIGHT,
                },
                {
                  stackId: "status",
                  dataKey: "warning",
                  fill: WARNING_BAR_COLOR,
                  highlightedFill: WARNING_BAR_COLOR_HIGHLIGHT,
                },
              ]}
              data={transformedSyncRequests.map(
                ({ succeeded, ...rest }) => rest,
              )}
              title="Sync runs with errors"
              emptyText="No sync runs with errors"
              isEmpty={noFailedSyncRequests}
            />

            {/* Rejected operations */}
            <SyncHealthChart
              description="Number of rows that could not be synced due to row-level errors"
              onClick={(index) => {
                setDefaultFilters({ status: ["warning"] });
                setTargetSyncsFromChart(index);
              }}
              bars={[
                {
                  stackId: "status",
                  dataKey: "failed_operations",
                  fill: FAILED_BAR_COLOR,
                  highlightedFill: FAILED_BAR_COLOR_HIGHLIGHT,
                },
              ]}
              data={transformedOperations.map(
                ({ successful_operations, ...rest }) => rest,
              )}
              title="Rows failed"
              emptyText="No failed rows"
              isEmpty={noFailedOperations}
            />
          </Box>
        </Column>
      </DashboardMainItem>
      <SyncRequestModal
        targetSyncs={targetSyncs}
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        defaultFilters={defaultFilters}
      />
    </SyncHealthChartsContextProvider>
  );
};
