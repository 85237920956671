import { FC } from "react";

import { TraitConfig, TraitType } from "@hightouch/lib/query/visual/types";
import {
  ArrowLeftIcon,
  Button,
  CloseIcon,
  Column,
  Combobox,
  DrawerBody,
  DrawerFooter,
  FormField,
  Heading,
  IconButton,
  Row,
  Text,
  TextInput,
  Textarea,
} from "@hightouchio/ui";
import { captureException } from "@sentry/react";
import { Controller, FormProvider } from "react-hook-form";
import { useNavigate, useSearchParams } from "src/router";

import { Drawer } from "src/components/drawer";
import { Form, SaveButton, useHightouchForm } from "src/components/form";
import {
  TraitDefinitionsConstraint,
  useCreateTraitMutation,
  useTraitQuery,
  useTraitsQuery,
} from "src/graphql";
import { NotFound } from "src/pages/not-found";

import { CalculationSummary } from "./calculation-summary";
import { DUPLICATE_TRAIT_NAME_ERROR_MESSAGE } from "./constants";
import { PreviewTrait } from "./preview-trait";
import { TraitConditions } from "./trait-conditions";
import { validateConfig } from "./utils";

export const CreateTemplatedTrait: FC = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const templateId = searchParams.get("template_id");

  const onCloseDrawer = () => navigate("/traits/active");

  const createTrait = useCreateTraitMutation();

  const { data, isLoading } = useTraitQuery({ id: templateId ?? "" });
  const trait = data?.trait_definitions_by_pk;

  const traitTemplatesQuery = useTraitsQuery({
    filter: {
      is_template: { _eq: true },
      archived_at: { _eq: null },
    },
  });

  const templateOptions = (
    traitTemplatesQuery.data?.trait_definitions ?? []
  ).map((template) => ({
    label: template.name,
    value: template.id,
    description: template.description,
  }));

  const form = useHightouchForm({
    // TODO(samuel): Set up trait validation with yup
    values: trait
      ? {
          name: "",
          description: "",
          type: trait.type as TraitType,
          parent_model_id: trait.parent_model.id,
          relationship_id: trait.relationship?.id,
          config: trait.config as TraitConfig,
          trait_template_id: trait.id,
        }
      : {
          name: "",
          description: "",
          type: TraitType.Count,
          parent_model_id: "",
          relationship_id: undefined,
          config: {},
          trait_template_id: undefined,
        },
    onSubmit: async (formData) => {
      if (!formData.name || !validateConfig(type, config)) {
        throw new Error("Trait form has validation errors");
      }

      try {
        const newTrait = await createTrait.mutateAsync({
          input: {
            name: formData.name,
            description: formData.description,
            parent_model_id: formData.parent_model_id,
            relationship_id: formData.relationship_id,
            type: formData.type,
            config: formData.config,
            is_template: false,
            trait_template_id: formData.trait_template_id,
          },
        });

        const newTraitId = newTrait.insert_trait_definitions_one?.id;
        navigate(`/traits/active/${newTraitId}`);
      } catch (error) {
        // Add a more helpful error message
        throw error.message.includes(
          TraitDefinitionsConstraint.TraitDefinitionsNameParentModelIdKey,
        )
          ? new Error(DUPLICATE_TRAIT_NAME_ERROR_MESSAGE)
          : error;
      }
    },
    onError: (error) => {
      if (error.message !== DUPLICATE_TRAIT_NAME_ERROR_MESSAGE) {
        captureException(error);
      }
    },
  });

  const name = form.watch("name");
  const type = form.watch("type");
  const config = form.watch("config");

  return (
    <Drawer isLoading={isLoading} isOpen size="xl" onClose={onCloseDrawer}>
      {!trait ? (
        <NotFound />
      ) : (
        <Form form={form}>
          <Row
            align="center"
            justifyContent="space-between"
            p={6}
            borderBottom="1px solid"
            borderColor="base.border"
            width="100%"
          >
            <Heading>New trait</Heading>
            <IconButton
              aria-label="Close"
              icon={CloseIcon}
              onClick={onCloseDrawer}
            />
          </Row>

          <DrawerBody>
            <Column flex={1} overflowY="auto" gap={6}>
              <Column gap={2}>
                <Text fontWeight="medium">
                  Create a new trait from a template
                </Text>
                <Text>
                  A trait lets you perform a calculation on a column of a
                  related/event model. It acts as a new column on a parent model
                  and can be utilized in associated Audiences.
                </Text>
              </Column>

              <FormField
                label="Choose a template"
                tip="This trait will be available in the audiences of this parent model."
              >
                <Combobox
                  options={templateOptions}
                  onChange={(templateId) => {
                    if (templateId) {
                      setSearchParams({ template_id: templateId });
                    }
                  }}
                  value={templateId}
                  width="100%"
                />
              </FormField>

              <Column
                borderRadius="6px"
                border="1px solid"
                borderColor="base.border"
                p={4}
                gap={4}
              >
                <CalculationSummary
                  parentModel={trait.parent_model}
                  relatedModel={trait.relationship?.to_model}
                  type={type}
                  config={config}
                  hideConditions
                />
                <FormProvider {...form}>
                  <TraitConditions
                    parentModel={trait.parent_model}
                    relationship={trait.relationship}
                  />
                </FormProvider>
              </Column>

              <FormField
                label="Name"
                tip="Shows up in the audience query builder when selecting from a list of traits."
              >
                <Column gap={6}>
                  <Controller
                    control={form.control}
                    name="name"
                    render={({ field }) => (
                      <TextInput
                        placeholder="Enter a name"
                        width="100%"
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    control={form.control}
                    name="description"
                    render={({ field }) => (
                      <Textarea
                        placeholder="Enter a description"
                        width="100%"
                        {...field}
                      />
                    )}
                  />
                </Column>
              </FormField>

              <PreviewTrait
                parentModel={trait.parent_model}
                trait={{
                  name,
                  type: trait.type as TraitType,
                  config: trait.config,
                  relationshipId: trait.relationship?.id,
                }}
              />
            </Column>
          </DrawerBody>
          <DrawerFooter>
            <Button
              icon={ArrowLeftIcon}
              size="lg"
              onClick={() => navigate("/traits/active/new_from_template")}
            >
              Back
            </Button>
            <SaveButton
              permission={{
                v1: { resource: "audience_schema", grant: "create" },
                v2: {
                  resource: "model",
                  grant: "can_update",
                  id: trait.parent_model.id,
                },
              }}
            >
              Save
            </SaveButton>
          </DrawerFooter>
        </Form>
      )}
    </Drawer>
  );
};
