import { PlayIcon } from "@hightouchio/ui";

import { TileNode } from "./tile-node";
import { TileProps } from "./types";

export const StartNode = ({ data }: TileProps) => {
  return (
    <TileNode
      isSource
      isCloneable={false}
      isDeleteable={false}
      colorScheme="gray"
      icon={<PlayIcon />}
      data={data}
    />
  );
};
