import { FC } from "react";

import { Box, Text, Tooltip, Badge, CheckIcon } from "@hightouchio/ui";

import { Link } from "src/router";

import AudenicesIcon from "src/components/icons/audiences.svg";
import CustomAlertingIcon from "src/components/icons/customalerting.svg";
import CustomStorageIcon from "src/components/icons/customstorage.svg";
import DataDogIcon from "src/components/icons/datadogmonitoring.svg";
import DBTCloudIcon from "src/components/icons/dbtcloud.svg";
import DBTGitRepoIcon from "src/components/icons/dbtgitrepo.svg";
import GitSyncIcon from "src/components/icons/gitsync.svg";
import MultiRegionIcon from "src/components/icons/multiregion.svg";
import NotifyIcon from "src/components/icons/notify.svg";
import PermissionsIcon from "src/components/icons/permissions.svg";
import SSHIcon from "src/components/icons/ssh.svg";
import SsoIcon from "src/components/icons/sso.svg";
import { useUser } from "src/contexts/user-context";
import { useEntitlements } from "src/hooks/use-entitlement";

type FeatureSection = {
  label: string;
  features: Feature[];
};

type Feature = {
  label: string;
  icon: string;
  businessTier?: boolean;
  disabled?: boolean;
  href?: string;
};

const getFeatureCatalog: (
  audiencesEnabled: boolean,
  externalStorageEnabled: boolean,
  businessTier: boolean,
) => FeatureSection[] = (
  audiencesEnabled,
  externalStorageEnabled,
  businessTier,
) => [
  {
    label: "Data activation apps",
    features: [
      {
        label: "Hightouch Audiences",
        businessTier: true,
        disabled: !audiencesEnabled,
        icon: AudenicesIcon,
        href: "/audiences",
      },
      {
        label: "Hightouch Notify",
        icon: NotifyIcon,
        href: "/destinations",
      },
    ],
  },
  {
    label: "Enterprise management",
    features: [
      {
        label: "SSO",
        businessTier: true,
        disabled: !businessTier,
        icon: SsoIcon,
      },
      {
        label: "Permissions",
        icon: PermissionsIcon,
        businessTier: true,
        disabled: !businessTier,
      },
      {
        label: "Multi-region",
        icon: MultiRegionIcon,
        href: "/settings/workspace",
      },
      {
        label: "Custom storage",
        businessTier: true,
        icon: CustomStorageIcon,
        disabled: !externalStorageEnabled,
        href: "/settings/storage",
      },
      {
        label: "SSH tunneling",
        icon: SSHIcon,
        href: "/settings/tunnels",
      },
    ],
  },
  {
    label: "Extensions",
    features: [
      {
        label: "Version control with Git",
        icon: GitSyncIcon,
        href: "/extensions/git-sync",
      },
      {
        label: "dbt sync triggers",
        icon: DBTCloudIcon,
        href: "/extensions/dbt-cloud",
      },
      {
        label: "dbt model import",
        icon: DBTGitRepoIcon,
        href: "/extensions/dbt-models",
      },
      {
        label: "Monitoring with Datadog",
        icon: DataDogIcon,
        href: "/extensions/monitoring",
      },
      {
        label: "Custom alerting",
        icon: CustomAlertingIcon,
        href: "/extensions/alerting",
        businessTier: true,
        disabled: !businessTier,
      },
    ],
  },
];

export const FeatureCatalog: FC = () => {
  const { data: entitlementsData } = useEntitlements(false, true);
  const { workspace } = useUser();
  const featureCatalog = getFeatureCatalog(
    entitlementsData.entitlements.audiences,
    entitlementsData.entitlements.externalStorage,
    workspace?.organization?.plan?.sku === "business_tier",
  );

  return (
    <Box display="flex" flexDirection="column" gap={12}>
      {featureCatalog.map((featureSection) => (
        <Box key={featureSection.label}>
          <Text>{featureSection.label}</Text>

          <Box mt={1}>
            {featureSection.features.map((feature) => (
              <Box
                key={feature.label}
                borderBottom="1px"
                borderColor="gray.200"
                display="flex"
                py={3}
              >
                <Box
                  alignItems="center"
                  display="flex"
                  flex="none"
                  gap={2}
                  width="sm"
                >
                  <Box as="img" src={feature.icon} width={7} />

                  {feature.href ? (
                    <Link href={feature.href}>{feature.label}</Link>
                  ) : (
                    <Text>{feature.label}</Text>
                  )}

                  {feature.businessTier && (
                    <Tooltip message="This feature is available for business tier customers">
                      <Badge variant="upsell">Business Tier</Badge>
                    </Tooltip>
                  )}
                </Box>

                {feature.disabled ? (
                  <Box display="flex" alignItems="center">
                    <Text>Not included</Text>
                  </Box>
                ) : (
                  <Box
                    alignItems="center"
                    bg="grass.200"
                    borderRadius="full"
                    boxSize={7}
                    display="flex"
                    justifyContent="center"
                  >
                    <Box as={CheckIcon} fontSize="16px" color="grass.800" />
                  </Box>
                )}
              </Box>
            ))}
          </Box>
        </Box>
      ))}
    </Box>
  );
};
