import { useState, FC } from "react";

import * as Sentry from "@sentry/react";
import {
  Box,
  Button,
  FormField,
  TextInput,
  Paragraph,
  Text,
  CheckIcon,
  Dialog,
  ButtonGroup,
} from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Controller } from "react-hook-form";
import { useLocation } from "src/router";
import * as Yup from "yup";

import { useUser } from "src/contexts/user-context";
import { useSendInviteMutation } from "src/graphql";
import * as analytics from "src/lib/analytics";
import { RoleSelect } from "src/components/role-select";
import { Form, SaveButton, useHightouchForm } from "src/components/form";

interface InviteFormProps {
  name: string;
  close: () => void;
  open: boolean;
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  roleId: Yup.number().required("Role is required"),
});

export const InviteFormModal: FC<Readonly<InviteFormProps>> = ({
  name,
  close,
  open,
}) => {
  const { workspace } = useUser();
  const location = useLocation();
  const [invited, setInvited] = useState<boolean>(false);
  const { mutateAsync: sendInvite } = useSendInviteMutation();
  const { authDisableNonSso } = useFlags();

  const defaultValues = {
    email: "",
    roleId: workspace?.default_role_id,
  };

  const form = useHightouchForm({
    onSubmit: async (data) => {
      if (!data.email || !data.roleId) {
        return;
      }
      await sendInvite({
        recipientEmail: data.email,
        recipientRoleID: data.roleId,
      });
      setInvited(true);
      analytics.track("Teammate Invited", {
        workspace_id: workspace?.id,
        workspace_slug: workspace?.slug,
        origin_page: location.pathname,
      });
    },
    error: "Something went wrong",
    errorMessage: "Please ensure your invite details are correct",
    onError: (error) => {
      if (!error?.email?.message.includes("Invalid email address")) {
        Sentry.captureException(error);
      }
    },
    resolver: yupResolver(validationSchema),
    defaultValues,
  });
  const { reset, control, watch } = form;

  const email = watch("email");

  const handleClose = () => {
    close();
    setInvited(false);
    reset(defaultValues);
  };

  if (authDisableNonSso) {
    return (
      <Dialog
        isOpen={open}
        variant="form"
        title={`Invite a teammate to ${name}`}
        actions={<Button onClick={handleClose}>Close</Button>}
        onClose={handleClose}
      >
        <Paragraph>
          Inviting teammates by email is disabled in this workspace. Please use
          the SSO identity provider connected to your organization.
        </Paragraph>
      </Dialog>
    );
  }

  return (
    <Form form={form}>
      <Dialog
        isOpen={open}
        variant="form"
        title={`Invite a teammate to ${name}`}
        actions={
          invited ? (
            <ButtonGroup>
              <Button
                onClick={() => {
                  setInvited(false);
                  reset(defaultValues);
                }}
              >
                Invite more people
              </Button>
              <Button variant="primary" onClick={handleClose}>
                Done
              </Button>
            </ButtonGroup>
          ) : (
            <ButtonGroup>
              <Button onClick={handleClose}>Cancel</Button>
              <SaveButton size="md">Send invitation</SaveButton>
            </ButtonGroup>
          )
        }
        onClose={handleClose}
      >
        {invited ? (
          <Box>
            <Box
              alignItems="center"
              bg="grass.200"
              borderRadius="full"
              boxSize={14}
              display="flex"
              justifyContent="center"
              mx="auto"
              fontSize="32px"
            >
              <CheckIcon color="grass.700" />
            </Box>

            <Box maxWidth="sm" mt={2} mx="auto">
              <Paragraph textAlign="center">
                You invited <Text fontWeight="semibold">{email}</Text> to{" "}
                <Text fontWeight="semibold">{name}</Text>.
              </Paragraph>
            </Box>
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" gap={6}>
            <Controller
              control={control}
              name="email"
              render={({ field, fieldState: { error } }) => (
                <FormField error={error?.message} label="Email">
                  <TextInput
                    {...field}
                    isInvalid={Boolean(error?.message)}
                    placeholder="jane@acme.com"
                    type="email"
                    onChange={(event) =>
                      field.onChange(event.target.value.trim())
                    }
                  />
                </FormField>
              )}
            />

            <Controller
              control={control}
              name="roleId"
              render={({ field, fieldState: { error } }) => (
                <FormField
                  description="Depending on your role selection, new users may be able to create workspaces and invite other team members."
                  error={error?.message}
                  label="Role"
                >
                  <RoleSelect {...field} />
                </FormField>
              )}
            />
          </Box>
        )}
      </Dialog>
    </Form>
  );
};
