import {
  Alert,
  Box,
  Column,
  MailIcon,
  Row,
  SectionHeading,
  Spinner,
  Text,
  ToggleButton,
  ToggleButtonGroup,
} from "@hightouchio/ui";
import { FC, useEffect } from "react";

import { DecisionEngineFlowQuery } from "src/graphql";
import useQueryState from "src/hooks/use-query-state";
import { useOutletContext } from "src/router";
import { Pagination } from "src/ui/table";
import { abbreviateNumber } from "src/utils/numbers";
import { OutletContext } from "../..";
import { Interaction, InteractionHeader, useInteractions } from "./interaction";
import { MessageRow } from "./message-row";

export type InteractionRow = {
  interaction_id: string;
  action_features: {
    message: string;
    channel: string;
    frequency_arm: string;
    time_of_day: string;
  };
  primary_key: string; // user primary key
  sync_metadata: {
    variables: Record<string, string>;
    [key: string]: any;
  };
  send_at: string;
  user_primary_label: string;
};

export type FlowMessages = NonNullable<
  DecisionEngineFlowQuery["decision_engine_flows_by_pk"]
>["messages"];

type Props = {
  flowMessages: FlowMessages;
  usersByPrimaryKey: Record<string, any>;
};

export const Messages: FC<Readonly<Props>> = ({
  flowMessages,
  usersByPrimaryKey,
}) => {
  const [direction, setDirection] = useQueryState("direction");
  const [interactionId, setInteractionId] = useQueryState("message");
  const [flowId] = useQueryState("flow");
  const [users] = useQueryState("users");
  const selectedUsers = users ? users.split(",") : [];
  const context = useOutletContext<OutletContext>();
  const demoDefinition = context?.demoDefinition;

  const {
    interactions,
    error: interactionsError,
    isLoading,
    count,
    setPage,
    page,
  } = useInteractions({
    selectedUsers,
    flowId,
    direction: direction as "upcoming" | "past" | null,
    demoDefinition,
    flowMessages,
  });

  useEffect(() => {
    if (interactions.length > 0 && !interactionId) {
      setInteractionId(interactions?.[0]?.interaction_id);
    }
  });

  const interactionRow = interactions.find(
    (row) => row.interaction_id === interactionId,
  );

  const selectedMessage = interactionRow
    ? flowMessages.find(
        (flowMessage) =>
          flowMessage.message.id === interactionRow.action_features.message,
      )
    : undefined;

  return (
    <>
      <Column
        borderRight="1px"
        borderColor="base.border"
        flex={1}
        height="100%"
        overflow="hidden"
        bg="white"
      >
        <Column
          borderBottom="1px"
          borderColor="base.border"
          p={6}
          pb={4}
          gap={2}
        >
          <Row gap={4} align="center" justify="space-between" h="36px">
            <Row gap={2} align="center">
              <Box as={MailIcon} boxSize={5} color="text.secondary" />
              <SectionHeading>Actions</SectionHeading>
            </Row>
          </Row>
          <Row gap={4}>
            <ToggleButtonGroup
              value={direction || "upcoming"}
              onChange={setDirection}
            >
              <ToggleButton label="Upcoming" value="upcoming" />
              <ToggleButton label="Past" value="past" />
            </ToggleButtonGroup>
          </Row>
        </Column>

        {!isLoading && count ? (
          <Row borderBottom="1px" borderColor="base.border" px={6} py={2}>
            <Text size="sm" color="text.secondary">
              {abbreviateNumber(count)} actions
            </Text>
          </Row>
        ) : null}

        <Column overflow="auto" flex={1}>
          {isLoading ? (
            <Spinner mt={6} mx="auto" />
          ) : !interactions?.length ? (
            <Text color="text.tertiary" mx="auto" mt={6}>
              No actions
            </Text>
          ) : (
            interactions?.map((row: InteractionRow) => {
              return (
                <MessageRow
                  key={row.interaction_id}
                  onClick={() => {
                    setInteractionId(row.interaction_id);
                  }}
                  flowMessages={flowMessages}
                  interaction={row}
                  isSelected={row.interaction_id === interactionId}
                />
              );
            })
          )}
        </Column>
        {count > 100 && (
          <Row
            p={4}
            borderTop="1px"
            borderColor="base.border"
            justify="flex-end"
          >
            <Pagination
              compact
              text=""
              count={count}
              page={page}
              rowsPerPage={100}
              setPage={setPage}
            />
          </Row>
        )}
      </Column>
      <Column flex={2} overflow="hidden">
        {interactionRow ? (
          <>
            <Row p={6} pb={1}>
              <InteractionHeader
                interaction={interactionRow}
                message={selectedMessage!}
              />
            </Row>

            <Interaction
              message={selectedMessage!}
              usersByPrimaryKey={usersByPrimaryKey}
              interaction={interactionRow}
              error={interactionsError}
            />
          </>
        ) : (
          <Alert
            m={6}
            variant="inline"
            type="subtle"
            title="No action selected"
            message="Select an action to view details"
          />
        )}
      </Column>
    </>
  );
};
