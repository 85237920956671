import { Form, useHightouchForm } from "src/components/form";
import {
  useUpdateAudiencesByParentIdMutation,
  useUpdateObjectMutation,
} from "src/graphql";
import { SchemaFieldForm, SchemaField } from "src/pages/schema/types";
import { ColumnField } from "./column-field";
import { PrimaryKeyDialog } from "src/components/models/primary-key-dialog";
import { Controller } from "react-hook-form";

export const PrimaryLabelForm: SchemaFieldForm = ({
  object,
  hasUpdatePermission,
}) => {
  const { mutateAsync: updateObject } = useUpdateObjectMutation();
  const form = useHightouchForm({
    submitOnChange: true,
    onSubmit: async ({ visual_query_primary_label }) => {
      await updateObject({
        id: object.id,
        input: {
          visual_query_primary_label,
        },
      });
    },
    values: {
      visual_query_primary_label: object.visual_query_primary_label || "",
    },
  });

  return (
    <Form form={form}>
      <PrimaryLabelField
        object={object}
        isDisabled={!hasUpdatePermission}
        isLoading={form.formState.isSubmitting}
      />
    </Form>
  );
};

export const PrimaryLabelField: SchemaField = (props) => {
  return (
    <ColumnField
      name="visual_query_primary_label"
      label="Primary label"
      tip="Used when previewing results from an audience. E.g. “full_name”"
      {...props}
    />
  );
};

export const SecondaryLabelForm: SchemaFieldForm = ({
  object,
  hasUpdatePermission,
}) => {
  const { mutateAsync: updateObject } = useUpdateObjectMutation();

  const form = useHightouchForm({
    submitOnChange: true,
    onSubmit: async ({ visual_query_secondary_label }) => {
      await updateObject({
        id: object.id,
        input: {
          visual_query_secondary_label,
        },
      });
    },
    values: {
      visual_query_secondary_label: object.visual_query_secondary_label || "",
    },
  });

  return (
    <Form form={form}>
      <SecondaryLabelField
        object={object}
        isDisabled={!hasUpdatePermission}
        isLoading={form.formState.isSubmitting}
      />
    </Form>
  );
};

export const SecondaryLabelField: SchemaField = (props) => {
  return (
    <ColumnField
      name="visual_query_secondary_label"
      label="Secondary label"
      tip="Used when previewing results from an audience. E.g. “email”"
      {...props}
    />
  );
};

export const PrimaryKeyForm: SchemaFieldForm = ({
  object,
  hasUpdatePermission,
}) => {
  const { mutateAsync: updateObject } = useUpdateObjectMutation();
  const { mutateAsync: updateAudiencesByParentId } =
    useUpdateAudiencesByParentIdMutation();

  const form = useHightouchForm({
    onSubmit: async ({ primary_key }) => {
      await updateObject({
        id: object.id,
        input: {
          primary_key,
        },
      });
      await updateAudiencesByParentId({
        parent_id: object.id,
        input: {
          primary_key,
        },
      });
    },
    values: {
      primary_key: object.primary_key || "",
    },
  });

  return (
    <Form form={form}>
      <PrimaryKeyField
        object={object}
        isDisabled={!hasUpdatePermission}
        isLoading={form.formState.isSubmitting}
      />
      <Controller
        name="primary_key"
        render={({ field }) => (
          <PrimaryKeyDialog
            oldPrimaryKey={object.primary_key ?? undefined}
            newPrimaryKey={form.formState.isDirty ? field.value : undefined}
            onClose={() => {
              form.reset({ primary_key: object.primary_key || "" });
            }}
            onConfirm={form.submit}
          />
        )}
      />
    </Form>
  );
};

export const PrimaryKeyField: SchemaField = (props) => {
  return (
    <ColumnField
      name="primary_key"
      label="Primary key"
      tip="Key that is unique and consistent across queries. E.g. “id” or “email”"
      {...props}
    />
  );
};

export const ParentForm: SchemaFieldForm = (props) => {
  return (
    <>
      <PrimaryKeyForm {...props} />
      <PrimaryLabelForm {...props} />
      <SecondaryLabelForm {...props} />
    </>
  );
};

export const ParentFields: SchemaField = (props) => {
  return (
    <>
      <PrimaryKeyField {...props} />
      <PrimaryLabelField {...props} />
      <SecondaryLabelField {...props} />
    </>
  );
};
