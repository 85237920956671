import { FC } from "react";

import { CodeSnippet, Heading, Text } from "@hightouchio/ui";

import { Link } from "src/router";

import { CodeParams, SetupProps } from "./types";

function getReactNativeCode(params: CodeParams): string {
  return `
  import { createClient } from "@ht-sdks/events-sdk-react-native";

  // See the docs for additional information on building the native modules.
  const htClient = createClient({
    writeKey: "${params.writeKey}",
    proxy: "https://${params.apiHost}/v1/batch"
  });

  htClient.track("Order Completed");
  `;
}

const reactNativeInstall = `npm install \\
  @ht-sdks/events-sdk-react-native \\
  @ht-sdks/sovran-react-native \\
  react-native-get-random-values \\
  @react-native-async-storage/async-storage`;

export const SetupReactNative: FC<SetupProps> = (props: SetupProps) => {
  return (
    <>
      <Heading>Installing the SDK</Heading>
      <Text>
        This SDK is available via{" "}
        <Link href="https://www.npmjs.com/package/@ht-sdks/events-sdk-react-native">
          NPM
        </Link>
        .
      </Text>
      <CodeSnippet code={reactNativeInstall} />
      <Heading>Using the SDK</Heading>
      <CodeSnippet label="index.js" code={getReactNativeCode(props)} />
    </>
  );
};
