import { FC, useEffect } from "react";

import { Button, ButtonGroup, useToast } from "@hightouchio/ui";
import { captureException } from "@sentry/react";
import { Controller, useForm } from "react-hook-form";
import { useOutletContext } from "src/router";

import { ActionBar } from "src/components/action-bar";
import { ScheduleManager } from "src/components/schedule";
import { ScheduleType } from "src/components/schedule/types";
import { PermissionedButton } from "src/components/permission";
import { useUpdateEventWarehouseSyncMutation } from "src/graphql";

import { OutletContext } from ".";

export const WarehouseSyncSchedule: FC = () => {
  const { toast } = useToast();
  const { sync } = useOutletContext<OutletContext>();

  const form = useForm({ defaultValues: { schedule: sync.schedule } });

  const updateMutation = useUpdateEventWarehouseSyncMutation();

  const submit = async (data) => {
    try {
      await updateMutation.mutateAsync({
        id: sync.id,
        input: {
          schedule: data.schedule,
        },
      });
      toast({
        id: "save-schedule",
        title: "Your schedule has been saved.",
        variant: "success",
      });
    } catch (error) {
      captureException(error);
      toast({
        id: "save-schedule",
        title: "There was a problem saving your schedule.",
        variant: "error",
      });
    }
  };

  useEffect(() => {
    form.reset({ schedule: sync.schedule });
  }, [sync.schedule]);

  return (
    <>
      <Controller
        control={form.control}
        name="schedule"
        render={({ field }) => (
          <ScheduleManager
            resource="event_sync"
            schedule={field.value}
            setSchedule={field.onChange}
            types={[
              ScheduleType.MANUAL,
              ScheduleType.INTERVAL,
              ScheduleType.CRON,
            ]}
            includeStartAndEnd={false}
          />
        )}
      />
      <ActionBar>
        <ButtonGroup>
          <PermissionedButton
            size="lg"
            variant="primary"
            onClick={form.handleSubmit(submit)}
            isLoading={updateMutation.isLoading}
            isDisabled={!form.formState.isDirty}
            permission={{
              v1: { resource: "workspace", grant: "update" },
              v2: {
                resource: "workspace",
                grant: "can_update",
              },
            }}
          >
            Save changes
          </PermissionedButton>
          <Button
            size="lg"
            onClick={() => {
              form.reset();
            }}
            isLoading={updateMutation.isLoading}
            isDisabled={!form.formState.isDirty}
          >
            Discard changes
          </Button>
        </ButtonGroup>
      </ActionBar>
    </>
  );
};
