import { FC } from "react";

import {
  Badge,
  Column,
  EmptyState,
  ExternalLinkIcon,
  FrameStackIcon,
  Row,
  Text,
  TimeIcon,
} from "@hightouchio/ui";
import { Link } from "src/router";
import { useNavigate } from "src/router";

import placeholderIcon from "src/assets/placeholders/generic.svg";
import { IconBox } from "src/components/icon-box";
import {
  OrderBy,
  TraitDefinitionsOrderBy,
  useTraitsForParentModelQuery,
} from "src/graphql";
import {
  Pagination,
  SortOption,
  Table,
  TableColumn,
  useTableConfig,
  useTableSort,
} from "src/ui/table";
import { useResourcePermission } from "src/components/permission/use-resource-permission";
import { TRAIT_TYPE_LABELS } from "./utils";

type Props = {
  parentModelId: string;
};

const initialSort: SortOption<keyof TraitDefinitionsOrderBy> = {
  key: "updated_at",
  direction: OrderBy.Desc,
  label: "Recently updated",
};

const sortOptions: SortOption<keyof TraitDefinitionsOrderBy>[] = [
  { key: "name", direction: OrderBy.Asc, label: "Name A -> Z" },
  { key: "name", direction: OrderBy.Desc, label: "Name Z -> A" },
  { key: "created_at", direction: OrderBy.Desc, label: "Newest" },
  { key: "created_at", direction: OrderBy.Asc, label: "Oldest" },
  { key: "updated_at", direction: OrderBy.Desc, label: "Recently updated" },
];

export const SchemaTraitsTable: FC<Readonly<Props>> = ({ parentModelId }) => {
  const navigate = useNavigate();
  const { limit, offset, page, setPage } = useTableConfig();
  const orderBy = useTableSort<TraitDefinitionsOrderBy>(
    initialSort,
    sortOptions,
  );

  const {
    data: traits,
    isLoading,
    isError,
  } = useTraitsForParentModelQuery(
    {
      parentModelId: parentModelId,
      limit,
      offset,
      orderBy,
    },
    {
      select: (data) => data.trait_definitions,
    },
  );

  const columns: TableColumn[] = [
    {
      name: "Name",
      cell: ({ name, is_template }) => (
        <Row alignItems="center" gap={1} width="100%">
          <Text isTruncated>{name}</Text>
          {is_template && <Badge>Template</Badge>}
        </Row>
      ),
      cellSx: {
        fontWeight: "medium",
        pl: "4 !important",
      },
      headerSx: {
        "&:first-of-type": {
          pl: 4,
        },
      },
    },
    {
      name: "Related model",
      cell: ({ relationship }) => {
        const isEventModel = relationship.to_model?.event?.timestamp_column;

        return (
          <Row gap={2} width="100%">
            <IconBox
              icon={isEventModel ? <TimeIcon /> : <FrameStackIcon />}
              bg={isEventModel ? "cyan.400" : "grass.400"}
              boxSize={5}
              iconSize={3}
            />
            <Text isTruncated textTransform="capitalize">
              {relationship.to_model.name}
            </Text>
          </Row>
        );
      },
      min: "min-content",
      max: "min-content",
    },
    {
      name: "Type",
      cell: ({ type }) => (
        <Text isTruncated textTransform="capitalize">
          {TRAIT_TYPE_LABELS[type]}
        </Text>
      ),
      min: "min-content",
      max: "min-content",
    },
  ];

  return (
    <>
      <Column gap={5}>
        <Text fontWeight="medium" size="lg">
          Traits
        </Text>
        <Table
          columns={columns}
          data={traits}
          error={isError}
          loading={isLoading}
          placeholder={{
            custom: <EmptyPlaceholder />,
          }}
          primaryKey="id"
          sortOptions={sortOptions}
          onRowClick={({ id, is_template }) =>
            navigate(`/traits/${is_template ? "templates" : "active"}/${id}`)
          }
        />
        <Pagination
          count={traits?.length}
          page={page}
          rowsPerPage={limit}
          setPage={setPage}
        />
      </Column>
    </>
  );
};

const EmptyPlaceholder = () => {
  const { isPermitted: hasCreatePermission } = useResourcePermission({
    v1: { resource: "audience_schema", grant: "create" },
  });

  return (
    <EmptyState
      imageUrl={placeholderIcon}
      title={"You haven't created any traits"}
      message={
        <Row mt={2}>
          <Text color="text.secondary">
            Traits allow you to define and sync specific data from this model.
          </Text>
        </Row>
      }
      actions={
        hasCreatePermission && (
          <Link href="/traits/active">
            <Row gap={1}>
              <Text color="inherit">Create a new trait</Text>{" "}
              <ExternalLinkIcon />
            </Row>
          </Link>
        )
      }
    />
  );
};
