import { Text, Row, Column, Spinner, Box, ButtonGroup } from "@hightouchio/ui";
import { FC, ReactNode } from "react";
import { Card } from "src/components/card";

type Props = {
  placeholder: ReactNode;
  isLoading: boolean;
  subject: string | null | undefined;
  body: string | null | undefined;
  error: string | null | undefined;
  actions?: ReactNode;
};

export const EmailPreview: FC<Readonly<Props>> = ({
  actions,
  subject,
  ...content
}) => {
  return (
    <Card w="100%" h="100%" p={0} overflow="hidden">
      {(subject || actions) && (
        <Row
          align="center"
          gap={4}
          justify="space-between"
          px={6}
          py={4}
          borderBottom="1px"
          borderColor="base.border"
        >
          {subject && (
            <Box fontSize="lg" fontWeight="medium">
              {subject}
            </Box>
          )}
          <ButtonGroup>{actions}</ButtonGroup>
        </Row>
      )}
      <Column flex={1} align="center" justify="center">
        <Content {...content} />
      </Column>
    </Card>
  );
};

const Content = ({ isLoading, error, body, placeholder }) => {
  if (isLoading) {
    return <Spinner size="lg" />;
  }
  if (error) {
    return (
      <Column gap={2} align="center">
        <Text fontWeight="medium" color="danger.base">
          Error
        </Text>
        <Box>{error}</Box>
      </Column>
    );
  }
  if (body) {
    return <Box as="iframe" srcDoc={body} w="100%" h="100%" />;
  }
  return <>{placeholder}</>;
};
