import { Box, Text, Row, BoxProps } from "@hightouchio/ui";
import { motion } from "framer-motion";
import { FC } from "react";

const AnimatedBox = motion(Box);

type Props = {
  color: BoxProps["bg"];
  value: number;
  label?: string;
};

export const PercentageBar: FC<Readonly<Props>> = ({ value, label, color }) => {
  const percentage = `${Math.round(value * 100)}%`;
  return (
    <Row align="center" gap={2} w="100%">
      <AnimatedBox
        initial
        bg={color}
        height="16px"
        animate={{
          width: percentage,
          transition: { duration: 0.5 },
        }}
        borderRadius="sm"
      />
      <Text size="sm">{label || percentage}</Text>
    </Row>
  );
};
