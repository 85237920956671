import { MonitoredResourceType } from "@hightouch/lib/resource-monitoring/types";
import {
  Alert,
  ArrowRightIcon,
  Box,
  Column,
  ConfirmationDialog,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  RefreshIcon,
  Row,
  SectionHeading,
  Text,
  useToast,
} from "@hightouchio/ui";
import { FC, useMemo, useState } from "react";
import {
  AllResourceSubscriptionsQuery,
  useAllResourceSubscriptionsQuery,
  useClearManualNotificationOverridesMutation,
  useClearManualResourceAlertsMutation,
  useCreateResourceNotificationChannelMutation,
  useDeleteResourceChannelMutation,
  useMuteDefaultAlertMutation,
  useWorkspaceNotificationChannelsQuery,
} from "src/graphql";
import { ResourceQuickSubscribeMenu } from "src/components/notification-channels/resource-quick-subscribe-menu";
import { SubscribedResourceItem } from "src/components/notification-channels/subscribed-resource-item";
import { useNavigate } from "src/router";
import { MonitoredResourceTypeToLabel } from "./utils";
import { isBulkEditableResourceType } from "./monitor-condition-forms/monitor-condition-form";

export const SyncAlertingConfiguration: FC<{
  parentResourceType?: string;
  parentResourceId?: string;
  resourceType: MonitoredResourceType.Sync | MonitoredResourceType.EventSync;
  resourceId: string;
}> = ({ parentResourceType, parentResourceId, resourceType, resourceId }) => {
  const { toast } = useToast();

  const { data } = useAllResourceSubscriptionsQuery(
    {
      resourceId: resourceId?.toString(),
      resourceType,
      parentResourceId: parentResourceId?.toString(),
      parentResourceType,
    },
    { suspense: true },
  );

  const { data: workspaceChannels } = useWorkspaceNotificationChannelsQuery(
    undefined,
    {
      select: (data) => data.workspace_notification_channels,
      suspense: true,
    },
  );

  const { mutateAsync: subscribeResource } =
    useCreateResourceNotificationChannelMutation();

  const manualSubscriptions: AllResourceSubscriptionsQuery["resource_notification_channels"] =
    data?.resource_notification_channels ?? [];

  const enabledManualSubscriptions = manualSubscriptions.filter(
    (s) => !s.disabled,
  );

  const isBulkEditable = isBulkEditableResourceType(resourceType);

  const [defaultSubscriptions, overriddenDefaultSubscriptions] = useMemo(() => {
    if (!isBulkEditable) return [[], []];

    const defaultSubs: AllResourceSubscriptionsQuery["notification_channel_templates"] =
      [];

    const overriddenDefaultSubs: AllResourceSubscriptionsQuery["resource_notification_channels"] =
      [];

    for (const sub of data?.notification_channel_templates ?? []) {
      // Check that there is not a manual override for the default recipient
      const existingManualSubscription = manualSubscriptions.find(
        (s) => s.channel.id === sub.channel.id,
      );
      if (existingManualSubscription && existingManualSubscription.disabled) {
        overriddenDefaultSubs.push(existingManualSubscription);
        continue;
      }
      defaultSubs.push(sub);
    }
    return [defaultSubs, overriddenDefaultSubs];
  }, [data?.notification_channel_templates, manualSubscriptions]);

  const { mutateAsync: clearManualAlerts } =
    useClearManualResourceAlertsMutation();
  const { mutateAsync: unsubscribeResource } =
    useDeleteResourceChannelMutation();
  const { mutateAsync: muteDefaultAlert } = useMuteDefaultAlertMutation();

  const availableDestinationChannels = useMemo(
    () =>
      workspaceChannels?.filter(
        (wc) =>
          !manualSubscriptions
            ?.map((merged) => merged.channel.id)
            .includes(wc.id) &&
          !defaultSubscriptions
            ?.map((merged) => merged.channel.id)
            .includes(wc.id),
      ),
    [workspaceChannels, manualSubscriptions, defaultSubscriptions],
  );

  const navigate = useNavigate();

  const [overrideConfirmationOpen, setOverrideConfirmationOpen] =
    useState(false);

  const { mutateAsync: clearManualOverrides } =
    useClearManualNotificationOverridesMutation();

  const header = (
    <Row justifyContent="space-between">
      <Column gap={2}>
        <SectionHeading>Alert recipients</SectionHeading>
      </Column>
      {isBulkEditable && (
        <Menu>
          <MenuButton>Options</MenuButton>
          <MenuList>
            <MenuItem
              onClick={() => {
                navigate("/alerting/recipients");
              }}
            >
              Manage default recipients for this destination
              <ArrowRightIcon ml={2} />
            </MenuItem>
            {enabledManualSubscriptions.length > 0 && (
              <MenuItem
                onClick={() => {
                  setOverrideConfirmationOpen(true);
                }}
              >
                Clear all recipient overrides for this{" "}
                {MonitoredResourceTypeToLabel[resourceType]}
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      )}
    </Row>
  );

  return (
    <Column gap={4}>
      {header}
      {isBulkEditable && (
        <Column gap={2}>
          <Text color="text.secondary">
            By default, the following recipients will be alerted when this{" "}
            {MonitoredResourceTypeToLabel[resourceType]} becomes unhealthy:
          </Text>
          <Row gap={2}>
            {defaultSubscriptions.length > 0 ? (
              defaultSubscriptions.map((sub) => (
                <SubscribedResourceItem
                  key={sub.id}
                  channel={sub.channel}
                  onRemove={async () => {
                    await muteDefaultAlert({
                      resourceId,
                      resourceType,
                      channelId: sub.channel.id,
                    });
                    toast({
                      id: "resource-notifications",
                      title: "Recipient unassigned",
                      variant: "success",
                    });
                  }}
                />
              ))
            ) : (
              <Text color="text.secondary">
                No default recipients configured.
              </Text>
            )}
          </Row>
        </Column>
      )}
      <Column gap={2}>
        <Text size="md" color="text.secondary">
          {isBulkEditable
            ? `Additional recipients may be assigned for this ${MonitoredResourceTypeToLabel[resourceType]} specifically.`
            : `The following recipients will be alerted when this ${MonitoredResourceTypeToLabel[resourceType]} becomes unhealthy:`}
        </Text>
        {!isBulkEditable && !enabledManualSubscriptions.length && (
          <Text color="text.secondary">No recipients configured.</Text>
        )}
        <Row gap={2} flexWrap="wrap">
          {enabledManualSubscriptions.map((sub) => (
            <SubscribedResourceItem
              key={sub.id}
              channel={sub.channel}
              onRemove={async () => {
                await unsubscribeResource({
                  resourceChannelId: sub.id,
                });
                toast({
                  id: "resource-notifications",
                  title: "Recipient unassigned",
                  variant: "success",
                });
              }}
            />
          ))}
          <ResourceQuickSubscribeMenu
            resourceType={MonitoredResourceType.Sync}
            fullSize={!enabledManualSubscriptions?.length}
            resourceId={resourceId}
            onSelectChannel={async ({ channelId }) => {
              await subscribeResource({
                channelId,
                resourceId,
                resourceType,
              });
              toast({
                id: "resource-notifications",
                title: "Successfully subscribed",
                variant: "success",
              });
            }}
            availableDestinationChannels={availableDestinationChannels ?? []}
          />
        </Row>
      </Column>
      {overriddenDefaultSubscriptions.length > 0 && (
        <Alert
          type="warning"
          variant="inline"
          title={`Override: some default alert recipients have been unassigned for this ${MonitoredResourceTypeToLabel[resourceType]}`}
          message={
            <Column gap={2}>
              <Text>
                The following default{" "}
                {defaultSubscriptions?.[0]?.parent_resource_type} recipients
                will not receive alerts for this{" "}
                {MonitoredResourceTypeToLabel[resourceType]}:
              </Text>
              <Row>
                {overriddenDefaultSubscriptions.map((sub) => (
                  <SubscribedResourceItem
                    onRemove={async () => {
                      await unsubscribeResource({
                        resourceChannelId: sub.id,
                      });
                    }}
                    key={sub.id}
                    channel={sub.channel}
                  />
                ))}
              </Row>
              <Box
                cursor="pointer"
                onClick={async () => {
                  await clearManualAlerts({
                    resourceType,
                    resourceId,
                  });
                  toast({
                    id: "resource-notifications",
                    title: `Recipients reset for this ${MonitoredResourceTypeToLabel[resourceType]}`,
                    variant: "success",
                  });
                }}
                sx={{
                  _hover: {
                    color: "link.hover",
                  },
                }}
                color="link.default"
              >
                <Row gap={1}>
                  <RefreshIcon /> Restore default recipients
                </Row>
              </Box>
            </Column>
          }
        />
      )}
      <ConfirmationDialog
        isOpen={!!overrideConfirmationOpen}
        title="Clear overrides"
        confirmButtonText="Proceed"
        variant="warning"
        onClose={() => setOverrideConfirmationOpen(false)}
        onConfirm={async () => {
          await clearManualOverrides({
            resourceIds: [resourceId],
            resourceType: resourceType,
          });
          setOverrideConfirmationOpen(false);
        }}
      >
        Are you sure you want to clear all alert recipient overrides for this{" "}
        {MonitoredResourceTypeToLabel[resourceType]}?
      </ConfirmationDialog>
    </Column>
  );
};
