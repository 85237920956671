import { FC } from "react";

import { CodeSnippet, Heading, Text } from "@hightouchio/ui";

import { Link } from "src/router";

import { CodeParams, SetupProps } from "./types";

function getRubyCode(params: CodeParams): string {
  return `
  require 'hightouch'

  # only initialize analytics ONCE throughout your application
  analytics = Hightouch::Analytics.new(
    write_key: '${params.writeKey}',
    host: '${params.apiHost}'
  )
  
  # example calls
  # analytics.track(user_id: 42, event: 'Created Account')
  # analytics.track(user_id: 42, event: 'Order Completed')
  `;
}

const gemInstall = "gem install events-sdk-ruby";

export const SetupRuby: FC<SetupProps> = (props: SetupProps) => {
  return (
    <>
      <Heading>Installing the SDK</Heading>
      <Text>
        This SDK is available via{" "}
        <Link href="https://rubygems.org/gems/events-sdk-ruby/">
          RubyGems.org
        </Link>
        .
      </Text>
      <CodeSnippet code={gemInstall} />
      <Heading>Using the SDK</Heading>
      <CodeSnippet label="app.rb" code={getRubyCode(props)} />
    </>
  );
};
