export function randomNumber(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function randomItems<T>(
  array: T[],
  min: number = array.length,
  max: number = array.length,
): T[] {
  const length = array.length;

  // Clamp min and max within the valid range
  const clampedMin = Math.max(0, Math.min(min, length));
  const clampedMax = Math.max(clampedMin, Math.min(max, length));

  // Random number of items to pick between clampedMin and clampedMax
  const count =
    Math.floor(Math.random() * (clampedMax - clampedMin + 1)) + clampedMin;

  // Shuffle and pick first `count` items
  const shuffledArray = [...array].sort(() => Math.random() - 0.5);
  return shuffledArray.slice(0, count);
}

export function randomItem<T>(array: T[]): T {
  return randomItems(array)[0]!;
}
