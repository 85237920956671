import { Alert, Box, Column } from "@hightouchio/ui";
import { Controller, useFormContext } from "react-hook-form";

import { Editor } from "src/components/editor";
import { EventSchemaFormState } from "src/events/contracts/types";
import { ReactElement } from "react";

export const CustomJsonEditor = ({ header }: { header: ReactElement }) => {
  const { control } = useFormContext<EventSchemaFormState>();

  return (
    <Column
      border="1px"
      borderColor="base.border"
      borderRadius="md"
      overflow="hidden"
      flex={1}
      width="100%"
    >
      <Box
        px={4}
        py={2}
        borderBottom="1px"
        borderColor="base.border"
        backgroundColor="base.lightBackground"
      >
        {header}
      </Box>
      <Controller
        control={control}
        name="editorState.json"
        render={({ field, fieldState }) => (
          <>
            <Editor
              language="json"
              value={field.value}
              onChange={field.onChange}
            />
            {fieldState?.error?.message && (
              <Alert
                variant="inline"
                type="error"
                title="Errors"
                message={fieldState.error.message}
              />
            )}
          </>
        )}
      />
    </Column>
  );
};
