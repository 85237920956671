import { FC, useState } from "react";

import {
  Heading,
  Checkbox,
  Button,
  Text,
  Row,
  Box,
  Column,
  CheckboxGroup,
  Paragraph,
  ArrowRightIcon,
  BidirectionalArrowIcon,
  SuccessIcon,
} from "@hightouchio/ui";
import { Link } from "src/router";
import Helmet from "react-helmet";
import { Navigate, useParams, useNavigate } from "src/router";

import { AuthLayout } from "src/components/auth/auth-layout";
import {
  useAccessibleOrganizationsQuery,
  useJoinWorkspaceWithAutoJoinMutation,
  usePartnerConnectGetConnectionQuery,
} from "src/graphql";
import { Logo } from "src/ui/brand";
import { Card } from "src/components/card";
import { PageSpinner } from "src/components/loading";
import { switchWorkspace } from "src/utils/workspaces";

const PartnerConnect: FC = () => {
  const navigate = useNavigate();
  const params = useParams<{ uuid: string }>();
  const uuid = params.uuid ?? "";

  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const [hasAcceptedSharing, setHasAcceptedSharing] = useState(false);

  const {
    data: partnerConnection,
    isLoading: isPartnerConnectionLoading,
    error: partnerConnectionError,
  } = usePartnerConnectGetConnectionQuery(
    { uuid },
    {
      select: (data) => data.partnerConnectGetConnection,
      enabled: Boolean(uuid),
      cacheTime: 0,
    },
  );

  const { data: organizations, isLoading: isOrganizationsLoading } =
    useAccessibleOrganizationsQuery(
      {},
      { select: (data) => data.getAccessibleOrganizations.organizations },
    );

  const { mutateAsync: joinWithAutojoin, isLoading: joiningWorkspace } =
    useJoinWorkspaceWithAutoJoinMutation();

  if (isPartnerConnectionLoading || isOrganizationsLoading) {
    return <PageSpinner />;
  }

  const workspaces = organizations?.flatMap((org) => org.workspaces) ?? [];

  const linkedWorkspace = workspaces?.find(
    (w) => String(w?.id) === String(partnerConnection?.workspaceId),
  );

  if (partnerConnectionError) {
    return (
      <Navigate
        state={{ error: partnerConnectionError.message }}
        to="/unauthorized"
      />
    );
  }

  if (partnerConnection?.workspaceId) {
    if (!linkedWorkspace) {
      return (
        <Navigate
          state={{
            partnerConnection,
            error: `You are not authorized to access the workspace ${partnerConnection.partnerName} is connected to.`,
          }}
          to="/unauthorized"
        />
      );
    }

    return (
      <Card mx="auto" mt={24} maxWidth="540px">
        <Column align="center" gap={12}>
          <PartnerConnectLogo
            logo={partnerConnection.partnerLogo}
            name={partnerConnection.partnerName}
          />
          <Heading size="2xl">
            {partnerConnection.partnerName} connected!
          </Heading>

          <Paragraph textAlign="center">
            Your Hightouch workspace <strong>"{linkedWorkspace.name}"</strong>{" "}
            is connected to {partnerConnection.partnerName}.
          </Paragraph>

          <Button
            isLoading={joiningWorkspace}
            size="lg"
            variant="primary"
            onClick={async () => {
              if (linkedWorkspace.is_joinable) {
                await joinWithAutojoin({
                  workspaceId: String(linkedWorkspace.id),
                });
              }
              if (partnerConnection.workspaceId && linkedWorkspace.is_member) {
                await switchWorkspace(
                  partnerConnection.workspaceId,
                  `/${linkedWorkspace.slug}`,
                );
              }
            }}
          >
            Go to workspace
          </Button>
        </Column>
      </Card>
    );
  }

  if (!partnerConnection) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>Connect Hightouch to {partnerConnection?.partnerName}</title>
      </Helmet>

      <AuthLayout
        heading={`Connect Hightouch to ${partnerConnection?.partnerName}`}
      >
        <Card>
          <Column align="center" gap={12}>
            <PartnerConnectLogo
              logo={partnerConnection?.partnerLogo}
              name={partnerConnection?.partnerName}
            />

            <CheckboxGroup>
              <Checkbox
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                description={
                  <>
                    I agree to Hightouch's{" "}
                    <Link
                      href={`${
                        import.meta.env.VITE_WEBSITE_URL
                      }/terms-of-service/`}
                      rel="noreferrer"
                      target="_blank"
                    >
                      terms of service
                    </Link>{" "}
                    related to its usage in conjunction with{" "}
                    {partnerConnection?.partnerName}.
                  </>
                }
                isChecked={hasAcceptedTerms}
                label="Terms of service"
                onChange={(event) => setHasAcceptedTerms(event.target.checked)}
              />
              <Checkbox
                description={`I acknowledge that Hightouch will share data on its usage with ${partnerConnection.partnerName}`}
                isChecked={hasAcceptedSharing}
                label="Usage sharing"
                onChange={(event) =>
                  setHasAcceptedSharing(event.target.checked)
                }
              />
            </CheckboxGroup>

            <Button
              directionIcon={ArrowRightIcon}
              isDisabled={!(hasAcceptedSharing && hasAcceptedTerms)}
              size="lg"
              variant="primary"
              onClick={() => {
                const workspaceCount = workspaces.length ?? 0;
                navigate(
                  workspaceCount > 0 ? "/workspaces" : "/workspaces/new",
                  {
                    state: { partnerConnection: partnerConnection },
                  },
                );
              }}
            >
              Continue
            </Button>
          </Column>
        </Card>
      </AuthLayout>
    </>
  );
};

export default PartnerConnect;

export const PartnerConnectLogo: FC<
  Readonly<{ name: string; logo: string }>
> = ({ name, logo }) => (
  <Row align="center" gap={4} mx="auto">
    <Logo size="48px" theme="dark" />
    <Box fontSize="36px">
      <BidirectionalArrowIcon color="gray.500" />
    </Box>
    <Box
      as="img"
      alt={name}
      src={logo}
      sx={{ width: "48px", objectFit: "contain" }}
    />
  </Row>
);

export const PartnerConnectHeader: FC<
  Readonly<{ partnerInformation: any }>
> = ({ partnerInformation }) => {
  return (
    <Column align="center" color="gray.700" gap={8} mb={8}>
      <PartnerConnectLogo
        logo={partnerInformation.partnerLogo}
        name={partnerInformation.partnerName}
      />

      <Column gap={2}>
        {partnerInformation.resources.map((resource) => (
          <Row key={resource} align="center" gap={2}>
            <Box fontSize="24px">
              <SuccessIcon color="forest.600" />
            </Box>
            <Text fontWeight="medium">
              Connect your {resource.split(" ").slice(1).join(" ")}
            </Text>
          </Row>
        ))}
      </Column>
    </Column>
  );
};
