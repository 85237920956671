import { FC } from "react";

import {
  Column,
  Drawer as HightouchUIDrawer,
  DrawerBody,
  DrawerProps,
  Spinner,
} from "@hightouchio/ui";

import { usePylonDrawerOffset } from "./use-pylon-drawer-offset";

export const Drawer: FC<Readonly<DrawerProps & { isLoading?: boolean }>> = ({
  isOpen = false,
  size = "sm",
  isLoading,
  children,
  ...props
}) => {
  usePylonDrawerOffset({ isOpen, size });

  return (
    <HightouchUIDrawer isOpen={isOpen} size={size} {...props}>
      {isLoading ? (
        <DrawerBody>
          <Column
            id="column"
            height="100%"
            width="100%"
            alignItems="center"
            justifyContent="center"
          >
            <Spinner size="lg" />
          </Column>
        </DrawerBody>
      ) : (
        children
      )}
    </HightouchUIDrawer>
  );
};
