import { PauseIcon, TimeIcon } from "@hightouchio/ui";

import { JourneyNodeType } from "src/types/journeys";

import { TileNode } from "./tile-node";
import { TileProps } from "./types";

export const DelayNode = (props: TileProps) => (
  <TileNode
    isTarget
    isSource
    isSourceHandleDisabled={props.type === JourneyNodeType.WaitUntilEvent}
    colorScheme="warning"
    icon={
      props.type === JourneyNodeType.TimeDelay ? <TimeIcon /> : <PauseIcon />
    }
    data={props.data}
  />
);
