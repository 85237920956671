import { useUser } from "src/contexts/user-context";
import { MembersV2 } from "./members-v2";
import { MembersV1 } from "./members-v1";

export const Members = () => {
  const { user } = useUser();
  if (user?.permissions_v2_enabled) {
    return <MembersV2 />;
  }
  return <MembersV1 />;
};
