import { FC, useEffect, useState } from "react";

import {
  Alert,
  Button,
  ButtonGroup,
  ConfirmationDialog,
  DeleteIcon,
  Dialog,
  EditIcon,
  FolderIcon,
  Menu,
  MenuActionsButton,
  MenuDivider,
  MenuItem,
  MenuList,
  ModelIcon,
  Paragraph,
  PlayIcon,
  Radio,
  RadioGroup,
  RefreshIcon,
  Row,
  Spinner,
  Text,
  useToast,
} from "@hightouchio/ui";
import { Outlet } from "src/router";
import * as Sentry from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Navigate, useNavigate, useParams } from "src/router";

import { DeployButton } from "src/components/deployments/button";
import { DraftCircle } from "src/components/drafts/draft-circle";
import { EditingDraftWarning } from "src/components/drafts/draft-warning";
import { MoveFolder } from "src/components/folders/move-to-folder";
import { useFolder } from "src/components/folders/use-folder";
import { EditLabelModal } from "src/components/labels/edit-label-modal";
import { ResourceType } from "src/components/labels/use-labels";
import { DetailPage } from "src/components/layout";
import { Crumb } from "src/components/layout/header/breadcrumbs";
import { PageSpinner } from "src/components/loading";
import { DeleteConfirmationModal } from "src/components/modals/delete-confirmation-modal";
import {
  PermissionedButton,
  PermissionedMenuItem,
  PermissionedSwitch,
} from "src/components/permission";
import { PermissionProvider } from "src/components/permission/permission-context";
import { ScheduleType } from "src/components/schedule/types";
import { Warning } from "src/components/warning";
import { DraftProvider, useDraft } from "src/contexts/draft-context";
import { useUser } from "src/contexts/user-context";
import {
  ResourceToPermission,
  SyncDraft,
  useDeleteSyncMutation,
  useLatestJourneyVersionFromDestinationInstanceIdQuery,
  useSupportsClearingAudienceQuery,
  useSyncQuery,
  useUpdateSyncMutation,
} from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import * as analytics from "src/lib/analytics";
import { QueryType } from "src/types/models";
import { SyncName } from "./components/sync-name";
import { useCurrentRun } from "./utils/use-current-run";
import { useSearchParam } from "src/components/routeable-tabs/utils";
import type { Sync as SyncQuery } from "./utils/types";
import { ResyncWarning } from "src/pages/syncs/components/full-resync-warning";
import { useTabState } from "src/components/route-tabs";

const getDeleteSyncErrorMessage = (error: Error): string => {
  return error.message.startsWith("Foreign key violation") &&
    error.message.includes("sync_sequence")
    ? "This sync cannot be deleted because it is used in one or more sequences"
    : error.message;
};

const resetOptionsInfo = {
  runJourney: {
    label: "Resync full query",
    description: `This action will reprocess your entire model as if the sync is running for the first time. All rows (including those already processed in previous syncs) will be sent to the destination again, in accordance with your sync configuration.`,
    alert: {
      title: `Duplicate messages may be sent`,
      message: `For “trigger”-type syncs (e.g., if this sync triggers an email
          send), forcing a manual run may cause messages to be resent to previously synced
          users and triggered messages may be immediately sent for members who failed to sync in previous sync runs.`,
    },
  },
  resyncFullQuery: {
    label: "Resync full query",
    description: `This action will reprocess your entire model as if the sync is running for the first time. All rows (including those already processed in previous syncs) will be sent to the destination again, in accordance with your sync configuration.`,
    alert: {
      title: `Note: this action will not delete existing records in the destination before resyncing.`,
      message: `Full resyncs are generally safe for configurations that update or upsert data, but for insert-only syncs, be cautious, as a full resync may create duplicate records in your destination.`,
    },
  },
  resetCDC: {
    label: "Reset CDC",
    description: `This action resets change data capture (CDC) for your model without sending any data to the destination. Hightouch will behave as if all current rows in your model have already been processed, meaning no backfill will occur. However, any future changes to rows will be synced to the destination.`,
    alert: {
      title: `Note: resetting CDC may introduce drift between your model and the destination.`,
      message: `This action triggers a special sync run that captures a current snapshot of your model. No data will be sent to the destination during this process.`,
    },
  },
  clearAndFill: {
    label: "Clear list and trigger full resync",
    description: `This action will rebuild the list in your destination. It starts by deleting existing records in the list (if it already exists). Then, it repopulates the list with your entire model, as if the sync is running for the first time.`,
    alert: {
      title: `Note: this action will clear the entire list, not just the records added by Hightouch.`,
      message: `It may undo changes made by other tools that have previously managed records in this list. The clear operation of this sync may take several hours to complete.`,
    },
  },
};

export type Context = {
  sync: NonNullable<SyncQuery>;
  onRefetch: () => void;
};

export const SyncWrapper: FC = () => {
  const { sync_id: id } = useParams<{ sync_id: string }>();

  const {
    data: sync,
    refetch,
    remove,
    error,
    isLoading,
  } = useSyncQuery(
    {
      id: id ?? "",
    },
    {
      enabled: Boolean(id),
      select: (data) => data.syncs[0],
    },
  );

  if (!id) {
    return <Navigate to="/syncs" />;
  }

  if (error) {
    return (
      <Warning title="Something went wrong" subtitle="Please try again later" />
    );
  }

  if (isLoading) {
    return <PageSpinner />;
  }

  if (!sync) {
    return <Warning title="Sync not found" />;
  }

  const onRefetch = () => {
    remove();
    refetch();
  };

  return (
    <DraftProvider
      initialResourceIsDraft={sync?.draft || false}
      resourceId={id}
      resourceType={ResourceToPermission.Sync}
    >
      <SyncLayout sync={sync} onRefetch={onRefetch} />
    </DraftProvider>
  );
};

const SyncLayout = ({ sync, onRefetch }: Context) => {
  const id = String(sync.id);
  const { workspace, user } = useUser();
  const navigate = useNavigate();
  const { toast } = useToast();

  const [deleting, setDeleting] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const [isEditLabelModalOpen, setIsEditLabelModalOpen] = useState(false);
  const flags = useFlags();
  const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);
  const [isResyncOptionModalOpen, setIsResyncOptionModalOpen] = useState(false);
  const [isFullResyncModalOpen, setIsFullResyncModalOpen] = useState(false);
  const [resyncType, setResyncType] = useState<string | null>(null);
  const [showConfirmRunDialog, setShowConfirmRunDialog] = useState(false);

  const updateSyncMutation = useUpdateSyncMutation();
  const { mutateAsync: deleteSyncById } = useDeleteSyncMutation();

  const { isCancelling, isRunning, startRun, cancelRun, isRunDisabled } =
    useCurrentRun(id);

  const model = sync?.segment;
  const destination = sync?.destination;
  const source = model?.connection;
  const usesSyncTemplate = sync?.sync_template_id;
  const currentLabels = sync?.tags ?? {};
  const isAudienceModel = model?.query_type === QueryType.Visual;
  const isJourneyTriggered =
    sync?.schedule?.type === ScheduleType.JOURNEY_TRIGGERED;

  const journeyQuery = useLatestJourneyVersionFromDestinationInstanceIdQuery(
    { destination_instance_id: id ?? "" },
    {
      enabled: Boolean(isJourneyTriggered && id),
      select: (data) => data.journeys,
    },
  );
  // TODO(samuel): Can this be pulled off of the sync instead? >.<
  const journey = journeyQuery.data?.[0];
  const journeyId = journey?.id;

  const deleteSync = async () => {
    if (!id) {
      return;
    }

    try {
      await deleteSyncById({
        id,
        stringId: id.toString(),
      });

      analytics.track("Sync Deleted", {
        sync_id: id,
        destination_type: destination?.definition?.name,
        schedule_type: sync.schedule?.type,
        source_type: model?.connection?.type,
      });

      navigate("/syncs");
    } catch (error) {
      Sentry.captureException(error);

      toast({
        id: "delete-sync",
        title: "Couldn't delete this sync",
        message: getDeleteSyncErrorMessage(error),
        variant: "error",
      });
    }
  };

  const {
    draft,
    editingDraft,
    editingDraftChanges,
    setEditingDraft,
    onViewDraft,
  } = useDraft();

  const newResource = draft?.new_resource as SyncDraft;
  const draftSchedule = newResource?._set?.schedule;

  const { data: entitlementsData } = useEntitlements(true);
  const overageLockout: boolean = entitlementsData.overage?.overageLockout;
  const overageText = entitlementsData.overage?.destinationOverageText; // @TODO: hookup once there are more than one overage.

  const toggleSyncPause = async (enabled: boolean) => {
    if (!id) {
      return;
    }

    try {
      await updateSyncMutation.mutateAsync({
        id,
        object: {
          schedule_paused: !enabled,
        },
      });

      toast({
        id: "toggle-sync-pause",
        title: `Sync was ${enabled ? "enabled" : "disabled"}`,
        variant: "success",
      });
    } catch (error) {
      toast({
        id: "toggle-sync-pause",
        title: `Sync could not be ${enabled ? "enabled" : "disabled"}`,
        message: error.message,
        variant: "error",
      });
    }
  };

  const showStatusCircleConfiguration =
    newResource?._set?.config && editingDraft;
  const showStatusCircleSchedule = draftSchedule !== undefined && editingDraft;

  const showSplitsTab = isAudienceModel;
  const showMonitoringTab = workspace?.alerting_v2_enabled;
  // Hiding the old alerting config tab if the new alerting v2 is enabled
  const showAlertsTab =
    !usesSyncTemplate && !showMonitoringTab && !editingDraftChanges && id;
  const showSyncLogs =
    source?.definition?.supportsInWarehouseDiffing && !editingDraftChanges;

  const tabs = [
    {
      path: "overview",
      title: "Overview",
    },
    {
      path: "configuration",
      title: (
        <Row align="center" gap={2}>
          Configuration
          {showStatusCircleConfiguration && <DraftCircle sx={{ ml: 2 }} />}
        </Row>
      ),
    },
    {
      path: "schedule",
      title: (
        <Row align="center" gap={2}>
          Schedule
          {showStatusCircleSchedule && <DraftCircle sx={{ ml: 2 }} />}
        </Row>
      ),
    },
    {
      path: "runs",
      title: "Runs",
    },
    { isHidden: !showAlertsTab, path: "alerts", title: "Alerts" },
    {
      isHidden: !showMonitoringTab,
      path: "alerting",
      title: "Alerting",
    },
    {
      isHidden: !showSyncLogs,
      path: "logs",
      title: "Sync logs",
    },
    {
      isHidden: !showSplitsTab,
      path: "splits",
      title: "Splits",
    },
    {
      path: "activity",
      title: "Activity",
    },
  ];

  const { activeTab } = useTabState(tabs, 4);

  const [autorun, __] = useSearchParam("autorun");

  useEffect(() => {
    let autoRunTimeout: number | undefined | null;
    if (sync && autorun) {
      autoRunTimeout = window.setTimeout(() => {
        startRun();
      }, 400);
    }
    return () => {
      if (autoRunTimeout) {
        clearTimeout(autoRunTimeout);
        autoRunTimeout = null;
      }
    };
  }, [autorun, sync?.id]);

  useEffect(() => {
    setEnabled(!sync?.schedule_paused);
  }, [sync?.schedule_paused]);

  const folder = useFolder({
    folderId: sync?.segment?.folder?.id || null,
    folderType: sync?.segment?.query_type === "visual" ? "audiences" : "models",
    viewType: "syncs",
  });

  const crumbs: Crumb[] = [{ label: "All syncs", link: "/syncs" }];

  const hasManagedModel = Boolean(journey?.id || model?.flow_message);

  if (folder?.path) {
    folder.path.split("/").forEach((path) => {
      crumbs.push({
        label: path,
        link: "/syncs?folder=" + folder.id,
      });
    });
  }

  const { data: supportsClearingAudienceData } =
    useSupportsClearingAudienceQuery({
      syncConfig: sync?.config,
      destinationType: destination?.definition?.type || "",
    });
  const resetOptions = [
    isJourneyTriggered
      ? "runJourney"
      : supportsClearingAudienceData?.supportsClearingAudience &&
          flags?.clearAndFillEnabled
        ? "clearAndFill"
        : "resyncFullQuery",
  ];
  if (flags.emptySyncs) {
    resetOptions.push("resetCDC");
  }

  const context: Context = { sync, onRefetch };

  return (
    <>
      <PermissionProvider
        permission={{
          v2: {
            resource: "sync",
            grant: "can_update",
            id: sync?.id ?? "",
          },
        }}
      >
        <DetailPage
          hasBottomPadding
          crumbs={crumbs}
          bg={
            activeTab?.path === "configuration" ||
            activeTab?.path === "overview" ||
            activeTab?.path === "schedule" ||
            activeTab?.path === "logs"
              ? "base.lightBackground"
              : "undefined"
          }
          tabs={tabs}
          outsideTopbar={
            draft && (
              <EditingDraftWarning
                draft={draft}
                editingDraft={editingDraft}
                resourceType={ResourceToPermission.Sync}
                setEditingDraft={setEditingDraft}
                onViewDraft={onViewDraft}
              />
            )
          }
          header={
            <Row align="center" justify="space-between" width="100%" gap={4}>
              <SyncName
                // Make sure journey is loaded
                isDisabled={journeyQuery.isLoading}
                destination={destination}
                model={model}
                source={source}
                journeyId={journeyId}
                sync={sync}
              />
              <ButtonGroup size="lg">
                {!isJourneyTriggered && (
                  <Row align="center" gap={4}>
                    <Text
                      textTransform="uppercase"
                      size="sm"
                      fontWeight="semibold"
                      color="text.tertiary"
                    >
                      {enabled ? "Enabled" : "Disabled"}
                    </Text>
                    <PermissionedSwitch
                      permission={{
                        v2: {
                          resource: "sync",
                          grant: "can_run",
                          id: sync?.id,
                        },
                      }}
                      isChecked={enabled}
                      onChange={(value) => {
                        setEnabled(value);
                        toggleSyncPause(value);
                      }}
                    />
                  </Row>
                )}

                <Menu>
                  <MenuActionsButton variant="secondary" />
                  <MenuList>
                    {hasManagedModel && user?.can_impersonate && (
                      <MenuItem
                        icon={ModelIcon}
                        onClick={() => {
                          navigate(`/models/${model?.id}`);
                        }}
                      >
                        View underlying model
                      </MenuItem>
                    )}
                    <PermissionedMenuItem
                      permission={{
                        v2: {
                          resource: "sync",
                          grant: "can_update",
                          id: sync?.id,
                        },
                      }}
                      icon={FolderIcon}
                      onClick={() => {
                        setIsFolderModalOpen(true);
                      }}
                    >
                      Move to folder
                    </PermissionedMenuItem>
                    <PermissionedMenuItem
                      permission={{
                        v2: {
                          resource: "sync",
                          grant: "can_update",
                          id: sync?.id,
                        },
                      }}
                      icon={EditIcon}
                      onClick={() => {
                        setIsEditLabelModalOpen(true);
                      }}
                    >
                      Edit labels
                    </PermissionedMenuItem>
                    {resetOptions.length === 1 ? (
                      isJourneyTriggered ? (
                        <PermissionedMenuItem
                          permission={{
                            v1: {
                              // journeys permission runs on sync create for v1
                              resource: "sync",
                              grant: "create",
                            },
                            v2: {
                              resource: "sync",
                              grant: "can_run",
                              id: sync?.id,
                            },
                          }}
                          isDisabled={
                            isRunDisabled || overageLockout || !!sync?.draft
                          }
                          icon={PlayIcon}
                          tooltip={overageLockout && overageText}
                          onClick={() => setShowConfirmRunDialog(true)}
                        >
                          Run
                        </PermissionedMenuItem>
                      ) : (
                        <PermissionedMenuItem
                          permission={{
                            v2: {
                              resource: "sync",
                              grant: "can_run",
                              id: sync?.id,
                            },
                          }}
                          icon={RefreshIcon}
                          isDisabled={isRunning}
                          onClick={() => {
                            setIsFullResyncModalOpen(true);
                          }}
                        >
                          Resync full query
                        </PermissionedMenuItem>
                      )
                    ) : (
                      <PermissionedMenuItem
                        permission={{
                          v2: {
                            resource: "sync",
                            grant: "can_run",
                            id: sync?.id,
                          },
                        }}
                        icon={RefreshIcon}
                        isDisabled={isRunning}
                        onClick={() => {
                          setIsResyncOptionModalOpen(true);
                          setResyncType(null);
                        }}
                      >
                        Resync or reset...
                      </PermissionedMenuItem>
                    )}
                    {!isJourneyTriggered && (
                      <>
                        <MenuDivider />
                        <PermissionedMenuItem
                          permission={{
                            v2: {
                              resource: "sync",
                              grant: "can_delete",
                              id: sync?.id,
                            },
                          }}
                          icon={DeleteIcon}
                          isDisabled={isRunning}
                          variant="danger"
                          onClick={() => {
                            setDeleting(true);
                          }}
                        >
                          Delete
                        </PermissionedMenuItem>
                      </>
                    )}
                  </MenuList>
                </Menu>

                {!isJourneyTriggered &&
                  sync &&
                  sync?.segment?.query_type !== "visual" && (
                    <DeployButton
                      permission={{
                        v2: {
                          resource: "model",
                          grant: "can_create",
                          creationOptions: {
                            type: "model",
                            sourceId:
                              sync?.segment?.connection?.id?.toString() ?? "",
                          },
                        },
                      }}
                      isDisabled={Boolean(!sync.slug || sync.draft || draft)}
                      deployment={{
                        resourceName: "Sync",
                        sourceResourceId: sync.id,
                        resourceType: "destination_instances",
                        metadata: {
                          destinationId: sync.destination?.id,
                          modelId: sync.segment?.id,
                        },
                        isDraft: Boolean(sync.draft || draft),
                      }}
                    />
                  )}

                {isRunning || isCancelling ? (
                  <PermissionedButton
                    isDisabled={isCancelling}
                    onClick={cancelRun}
                    permission={{
                      v2: {
                        resource: "sync",
                        grant: "can_run",
                        id: sync?.id,
                      },
                    }}
                  >
                    <Spinner size="sm" mr={2} />
                    {isCancelling ? "Canceling..." : "Cancel run"}
                  </PermissionedButton>
                ) : !isJourneyTriggered ? (
                  <PermissionedButton
                    permission={{
                      v2: {
                        resource: "sync",
                        grant: "can_run",
                        id: sync?.id,
                      },
                    }}
                    isDisabled={
                      isRunDisabled || overageLockout || !!sync?.draft
                    }
                    icon={PlayIcon}
                    tooltip={overageLockout && overageText}
                    onClick={() => {
                      startRun();
                    }}
                  >
                    Run sync
                  </PermissionedButton>
                ) : null}
              </ButtonGroup>
            </Row>
          }
          sync={sync}
          title={`${model?.name ?? "Private model"} to ${
            destination?.name ??
            destination?.definition?.name ??
            "private destination"
          } - Syncs`}
        >
          <Outlet context={context} />
        </DetailPage>
      </PermissionProvider>

      <EditLabelModal
        isOpen={isEditLabelModalOpen}
        labels={currentLabels}
        resourceType={ResourceType.Sync}
        onClose={() => setIsEditLabelModalOpen(false)}
        onSubmit={(labels) =>
          updateSyncMutation.mutateAsync({
            id: id ?? "",
            object: {
              tags: labels,
            },
          })
        }
      />

      {sync?.segment && isFolderModalOpen && (
        <MoveFolder
          folder={sync.segment.folder}
          folderType={
            sync.segment.query_type === "visual" ? "audiences" : "models"
          }
          modelIds={[sync.segment.id]}
          viewType="syncs"
          onClose={() => setIsFolderModalOpen(false)}
        />
      )}

      <DeleteConfirmationModal
        isOpen={deleting}
        label="sync"
        onClose={() => {
          setDeleting(false);
        }}
        onDelete={deleteSync}
      />

      <Dialog
        isOpen={isResyncOptionModalOpen}
        title="Resync and reset options"
        variant="info"
        width="lg"
        onClose={() => setIsResyncOptionModalOpen(false)}
        actions={
          <ButtonGroup>
            <Button
              variant="secondary"
              onClick={() => setIsResyncOptionModalOpen(false)}
            >
              Cancel
            </Button>
            {resyncType && (
              <Button
                variant="primary"
                onClick={() => {
                  const type = resyncType;
                  setIsResyncOptionModalOpen(false);
                  switch (type) {
                    case "resyncFullQuery":
                      startRun({ resync: true });
                      break;
                    case "runJourney":
                      startRun();
                      break;
                    case "resetCDC":
                      startRun({ reset_cdc: true });
                      break;
                    case "clearAndFill":
                      startRun({ clear_and_fill: true });
                      break;
                  }
                }}
              >
                Trigger now
              </Button>
            )}
          </ButtonGroup>
        }
      >
        <Row align="center" justify="space-between" width="100%" mb={4} gap={4}>
          <RadioGroup
            mt={3}
            value={resyncType}
            onChange={(value) => setResyncType(value)}
            orientation="vertical"
          >
            {resetOptions.map((resetOption) => {
              return (
                <Radio
                  key={resetOption}
                  label={resetOptionsInfo[resetOption].label}
                  value={resetOption}
                  description={resetOptionsInfo[resetOption].description}
                />
              );
            })}
          </RadioGroup>
        </Row>
        <Row>
          {resyncType && resetOptionsInfo[resyncType]?.alert && (
            <Alert
              title={resetOptionsInfo[resyncType].alert.title}
              message={resetOptionsInfo[resyncType].alert.message}
              type="warning"
              variant="inline"
            />
          )}
        </Row>
      </Dialog>
      <ConfirmationDialog
        isOpen={isFullResyncModalOpen}
        title="Are you sure?"
        confirmButtonText="Trigger full resync"
        variant="danger"
        onClose={() => setIsFullResyncModalOpen(false)}
        onConfirm={async () => {
          startRun({ resync: true });
        }}
      >
        <ResyncWarning />
      </ConfirmationDialog>
      <ConfirmationDialog
        isOpen={showConfirmRunDialog}
        title="Are you sure?"
        confirmButtonText="Start manual run"
        variant="danger"
        onClose={() => setShowConfirmRunDialog(false)}
        onConfirm={async () => startRun()}
      >
        <Paragraph>
          By forcing a manual sync run, you are instructing Hightouch to run
          this sync outside of the normal journey execution schedule. This can
          be helpful if certain rows failed to sync in previous sync runs.
        </Paragraph>
        <Paragraph mt={6}>
          Beware: for “trigger”-type syncs (e.g., if this sync triggers an email
          send), forcing a manual run may cause messages to be triggered
          immediately for members who failed to sync in previous sync runs.
        </Paragraph>
      </ConfirmationDialog>
    </>
  );
};
