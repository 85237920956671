import { FC, useState } from "react";

import {
  RelatedColumn,
  TraitCondition,
  TraitConfig,
  TransformedColumn,
  VisualQueryFilter,
  isEventTraitColumn,
  isTraitColumn,
} from "@hightouch/lib/query/visual/types";
import {
  Column,
  FrameStackIcon,
  Heading,
  Row,
  Text,
  TimeIcon,
} from "@hightouchio/ui";
import { Outlet, useNavigate } from "src/router";
import { isPresent } from "ts-extras";

import { IconBox } from "src/components/icon-box";
import { TRAIT_TYPE_LABELS } from "src/components/traits/utils";
import { Audience } from "src/types/visual";
import { Table, TableColumn, Pagination } from "src/ui/table";

import { getTraitDefinitionIdFromColumn } from "src/components/explore/visual/utils";
import { toSingleCondition } from "src/components/audiences/utils";
import { EditEventTrait } from "./edit-event-trait";
import { EditTraitEnrichment } from "./edit-trait-enrichment";
import { PermissionedButton } from "src/components/permission";
import { ParentModel } from "src/pages/audiences/types";

type Props = {
  audience: Audience;
  parentModel: ParentModel;
  isLoading: boolean;
};

type TraitEnrichment = {
  audienceTrait: {
    alias: string;
    column: RelatedColumn | TransformedColumn;
  };
  parentTrait?: {
    id: string;
    name: string;
    relationship: {
      id: string;
      to_model: {
        name: string;
      };
    } | null;
    type: string;
    config: TraitConfig;
  };
  parentModel: ParentModel;
  isEventTrait: boolean;
  index: number;
};

const ROWS_PER_PAGE = 50;

const columns: TableColumn<TraitEnrichment>[] = [
  {
    name: "Name",
    cell: ({ audienceTrait }) => audienceTrait.alias,
  },
  {
    name: "Type",
    cell: ({ audienceTrait, parentTrait, isEventTrait }) => {
      const traitType =
        isEventTrait && isEventTraitColumn(audienceTrait.column.column)
          ? audienceTrait.column.column.traitType
          : parentTrait?.type;

      return (
        <Text
          textTransform="capitalize"
          color={traitType ? undefined : "text.secondary"}
        >
          {traitType ? TRAIT_TYPE_LABELS[traitType] : "--"}
        </Text>
      );
    },
  },
  {
    name: "Related model",
    cell: ({ audienceTrait, parentTrait, isEventTrait, parentModel }) => {
      let relationship;

      if (isEventTrait) {
        const relationshipId = isEventTraitColumn(audienceTrait.column.column)
          ? audienceTrait.column.column?.filteredEvent?.relationshipId
          : undefined;

        relationship = parentModel.relationships.find(
          ({ id }) => id === relationshipId,
        );
      } else {
        relationship = parentTrait?.relationship;
      }

      if (!relationship?.to_model) {
        return <Text color="text.secondary">--</Text>;
      }

      const isEventModel = relationship?.to_model?.event?.timestamp_column;

      return (
        <Row gap={2} width="100%">
          <IconBox
            icon={isEventModel ? <TimeIcon /> : <FrameStackIcon />}
            bg={isEventModel ? "cyan.400" : "grass.400"}
            boxSize={5}
            iconSize={3}
          />
          <Text textTransform="capitalize">{relationship.to_model.name}</Text>
        </Row>
      );
    },
  },
];

export const TraitEnrichments: FC<Props> = ({
  audience,
  parentModel,
  isLoading,
}) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedTraitEnrichment, setSelectedTraitEnrichment] =
    useState<TraitEnrichment>();

  const parentModelTraits = parentModel?.traits || [];
  const visualQueryFilter = audience?.visual_query_filter as VisualQueryFilter;
  const additionalColumns = visualQueryFilter.additionalColumns ?? [];

  // Associate each audience-level trait with its parent-level trait
  // to make it easier fetch values in the table and have relevant information together
  const traitEnrichments = additionalColumns
    .map((additionalColumn, index) => {
      const traitId = getTraitDefinitionIdFromColumn(additionalColumn.column);
      // If the trait is deleted, then the trait will not be listed on the parent model
      const parentTrait = parentModelTraits.find(
        (trait) => trait.id === traitId,
      );
      const isEventTrait =
        additionalColumn.column.column.type === "event_trait";

      return {
        audienceTrait: additionalColumn,
        parentTrait,
        isEventTrait,
        parentModel,
        index,
      };
    })
    // The enrichments are just stored in the visual_query_filter jsonb column
    // So if you delete the parent trait, Postgres won't automatically delete the enrichment.
    // TODO(XXX): when deleting a trait it'd be smart to clean up the trait enrichments on the audiences
    .filter(({ parentTrait }) => isPresent(parentTrait));

  return (
    <>
      <Column gap={4}>
        <Row justifyContent="space-between" alignItems="center">
          <Column>
            <Heading>Trait enrichments</Heading>
            <Text>These traits are scoped to sync for this audience only.</Text>
          </Column>
          <PermissionedButton
            permission={{
              v1: { resource: "audience_schema", grant: "update" },
              v2: {
                resource: "model",
                grant: "can_update",
                id: parentModel?.id,
              },
            }}
            variant="primary"
            onClick={() => navigate("new")}
          >
            Create trait
          </PermissionedButton>
        </Row>

        <Table
          columns={columns}
          data={traitEnrichments.slice(
            0 + currentPage * ROWS_PER_PAGE,
            currentPage * ROWS_PER_PAGE + ROWS_PER_PAGE,
          )}
          loading={isLoading}
          placeholder={{
            body: (
              <Column gap={2} mt={4}>
                <Text fontWeight="medium" size="lg">
                  You have not created any trait enrichments
                </Text>
                <Text color="text.secondary">
                  Trait enrichments allow you to sync specific data from this
                  model
                </Text>
              </Column>
            ),
          }}
          onRowClick={(row) => {
            if (!row) return;
            setSelectedTraitEnrichment(row);
          }}
        />
        <Pagination
          count={traitEnrichments.length}
          page={currentPage}
          rowsPerPage={ROWS_PER_PAGE}
          setPage={setCurrentPage}
        />
      </Column>

      {audience &&
        selectedTraitEnrichment &&
        selectedTraitEnrichment.audienceTrait.column.column.type ===
          "event_trait" && (
          <EditEventTrait
            audience={audience}
            parentModel={parentModel}
            eventTrait={{
              alias: selectedTraitEnrichment.audienceTrait.alias,
              traitType:
                selectedTraitEnrichment.audienceTrait.column.column.traitType,
              traitConfig:
                selectedTraitEnrichment.audienceTrait.column.column.traitConfig,
              filteredEvent:
                selectedTraitEnrichment.audienceTrait.column.column
                  .filteredEvent,
            }}
            index={selectedTraitEnrichment.index}
            onClose={() => setSelectedTraitEnrichment(undefined)}
          />
        )}

      {audience &&
        selectedTraitEnrichment &&
        selectedTraitEnrichment.parentTrait?.id &&
        isTraitColumn(selectedTraitEnrichment.audienceTrait.column.column) && (
          <EditTraitEnrichment
            audience={audience}
            parentModel={parentModel}
            traitEnrichment={{
              name: selectedTraitEnrichment.audienceTrait.alias,
              conditions: (toSingleCondition(
                selectedTraitEnrichment.audienceTrait.column.column.conditions,
              ) ?? []) as TraitCondition[],
              templateId: selectedTraitEnrichment.parentTrait.id,
              relationshipId:
                selectedTraitEnrichment.parentTrait.relationship?.id,
              index: selectedTraitEnrichment.index,
            }}
            onClose={() => setSelectedTraitEnrichment(undefined)}
          />
        )}

      <Outlet context={{ audience, parentModel }} />
    </>
  );
};
