import { Route, Routes } from "src/router";

import { PermissionedRoute } from "src/components/router/permissioned-route";
import { FormErrorProvider } from "src/contexts/form-error-context";

import { JOURNEY_UPDATE_PERMISSION, JourneyFilterType } from "./constants";
import { NodeDetailForm, SettingsForm } from "./forms";
import Journey from "./journey";
import { JourneysLayout } from "./journeys";
import { JourneysTable } from "./journeys-table";
import { CreateJourneySyncWizard } from "./wizards";
import { Navigate } from "src/router";

export const JourneysRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<JourneysLayout />}>
        <Route index element={<Navigate to="all" />} />
        <Route
          path="all"
          element={<JourneysTable type={JourneyFilterType.ALL} />}
        />
        <Route
          path="drafts"
          element={<JourneysTable type={JourneyFilterType.DRAFTS} />}
        />
        <Route
          path="live"
          element={<JourneysTable type={JourneyFilterType.LIVE} />}
        />
        <Route
          path="off"
          element={<JourneysTable type={JourneyFilterType.OFF} />}
        />
      </Route>
      <Route path="/:id" element={<Journey />}>
        <Route
          path="settings"
          element={
            <FormErrorProvider>
              <SettingsForm />
            </FormErrorProvider>
          }
        />
        <Route
          path=":node_id/new-sync"
          element={
            <PermissionedRoute
              permission={JOURNEY_UPDATE_PERMISSION}
              redirect="/journeys/:id/:node_id"
            >
              <CreateJourneySyncWizard />
            </PermissionedRoute>
          }
        />
        <Route path=":node_id/*" element={<NodeDetailForm />} />
      </Route>
    </Routes>
  );
};
