import {
  ChannelType,
  MonitorStatus,
  ParentResourceTypes,
} from "@hightouch/lib/resource-monitoring/types";
import {
  Button,
  ButtonGroup,
  CloseIcon,
  Column,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  Heading,
  IconButton,
  Row,
} from "@hightouchio/ui";

import { Form, SaveButton, useHightouchForm } from "src/components/form";
import { ChannelDefinition } from "src/components/notification-channels/channel-definitions";
import { useNavigate, useSearchParams } from "src/router";
import { TestConnection } from "./components/test-connection";
import { Resources } from "./components/resources";
import { ChannelFormData } from "./components/configuration";
import { useCreateWorkspaceNotificationChannelMutation } from "src/graphql";
import { ChannelTypeSelect } from "./components/channel-type-select";

export const CreateRecipient = () => {
  const [params] = useSearchParams();
  const hasChannelType = Boolean(params.get("type"));
  const destinationIds = params.get("destinations")?.split(",") ?? [];
  const navigate = useNavigate();

  const { mutateAsync: createWorkspaceNotificationChannel } =
    useCreateWorkspaceNotificationChannelMutation();

  const form = useHightouchForm<ChannelFormData>({
    success: "Recipient created",
    onSubmit: async ({ channelType, config, resourceIdsToSubscribe }) => {
      const templates = Object.entries(resourceIdsToSubscribe.destinations)
        ?.filter(([, d]) => d.subscribed)
        .map(([destinationId]) => ({
          status: MonitorStatus.Unhealthy,
          parent_resource_id: destinationId.toString(),
          parent_resource_type: ParentResourceTypes.Destination,
        }));

      const response = await createWorkspaceNotificationChannel({
        object: {
          channel_type: channelType,
          config,
          // We are only supporting sync defaults for now
          notification_channel_templates: {
            data: templates,
          },
        },
      });

      navigate(
        `/alerting/recipients?channel=${response.insert_workspace_notification_channels_one?.id}`,
      );
    },
    values: {
      channelType: params.get("type") || ChannelType.Slack,
      config: {},
      resourceIdsToSubscribe: {
        destinations: destinationIds.reduce(
          (acc, id) => ({ ...acc, [id]: { subscribed: true } }),
          {},
        ),
      },
    },
  });

  const { watch } = form;
  const channelType = watch("channelType");

  const onClose = () => {
    navigate("/alerting/recipients");
  };

  const definition = ChannelDefinition[channelType!];

  return (
    <Form form={form}>
      <Drawer isOpen onClose={onClose}>
        <DrawerHeader>
          <Row align="center" justify="space-between" w="100%">
            <Heading>Create recipient</Heading>
            <IconButton
              aria-label="Close drawer"
              icon={CloseIcon}
              onClick={onClose}
            />
          </Row>
        </DrawerHeader>
        <DrawerBody>
          <Column gap={6}>
            {!hasChannelType && <ChannelTypeSelect />}
            <definition.component />
            <TestConnection />
            <Resources />
          </Column>
        </DrawerBody>
        <DrawerFooter>
          <ButtonGroup>
            <SaveButton>Create recipient</SaveButton>
            <Button onClick={onClose} size="lg">
              Cancel
            </Button>
          </ButtonGroup>
        </DrawerFooter>
      </Drawer>
    </Form>
  );
};
