/**
 * Predicate to filter arrays that are typed but may contain null/undefined/false values in order to render conditional items
 * e.g. const numbersOnly: number[] = [1, 2, null, undefined, false].filter(notEmpty)
 */
export function isPresent<TValue>(
  value: TValue | null | undefined | boolean,
): value is TValue {
  return value != null && value !== false;
}

export type Only<T, U> = {
  [P in keyof T]: T[P];
} & {
  [P in keyof U]?: never;
};

export type Either<T, U> = Only<T, U> | Only<U, T>;

export type NullableProperties<T> = {
  [P in keyof T]: T[P] | null;
};

export const isEnum =
  <T extends { [s: string]: unknown }>(e: T) =>
  (token?: unknown): token is T[keyof T] =>
    Object.values(e).includes(token as T[keyof T]);

export const isArrayElement =
  <T extends string>(e: Readonly<T[]>) =>
  (token?: unknown): token is T =>
    e.includes(token as T);

/**
 * Returns a function that takes a value and returns an enum value if it
 * is a valid enum member, otherwise returns the fallback value.
 *
 * Handy for quickly narrowing a loose type to a specific enum type without
 * rewriting fallback logic repeatedly.
 */
export function enumOrFallback<
  FallbackType,
  EnumObject extends { [s: string]: string | number },
  TCoerceEmpty extends boolean = false,
>(enumObject: EnumObject, fallback: FallbackType, coerceEmpty: TCoerceEmpty) {
  return (
    candidate: typeof coerceEmpty extends true
      ? string | number | null | undefined
      : string | number,
  ): (typeof enumObject)[keyof EnumObject] | FallbackType =>
    isEnum(enumObject)(candidate) ? candidate : fallback;
}
