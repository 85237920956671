import { FC } from "react";

import { useUser } from "src/contexts/user-context";
import {
  useCanDeploySyncsQuery,
  useDeploySyncMutation,
  useLinkableWorkspaceResourcesQuery,
} from "src/graphql";
import { ResourceActivityDiffer } from "src/hooks/use-resource-activity";
import { syncActivityMappers } from "src/pages/syncs/sync/activity";

import { Deployment } from "./common";
import { DeploymentWizard } from "./deployment-wizard";
import { getDeploymentDiff } from "./diff";

const syncDeploymentDiffer = ResourceActivityDiffer(
  ["workspace_id", "model_id", "segment_id", "destination_id"],
  ["name"],
);

const getSyncDeploymentDiff = getDeploymentDiff(
  syncDeploymentDiffer,
  syncActivityMappers,
);

export const SyncDeploymentWizard: FC<
  Readonly<{
    isOpen: boolean;
    deployment: Deployment<{ modelId: string; destinationId: string }>;
    onClose: () => void;
  }>
> = ({ deployment, isOpen, onClose }) => {
  const { isLoading: userLoading, user } = useUser();
  const { mutateAsync: deploy, isLoading: deploying } = useDeploySyncMutation();

  const { data: deploymentTests, isLoading: isTestingDeployments } =
    useCanDeploySyncsQuery(
      {
        sourceResourceId: deployment.sourceResourceId,
      },
      {
        enabled: !userLoading,
        refetchOnMount: "always",
        select: (data) => data.canDeployDestinationInstances,
      },
    );
  const targetWorkspaces =
    deploymentTests
      ?.map((t) => t.targetWorkspace)
      .filter(
        (workspace) =>
          String(workspace.id) !== String(user?.current_workspace_id),
      ) || [];

  const { data: linkableResources, isLoading: linkableResourcesLoading } =
    useLinkableWorkspaceResourcesQuery(
      {
        sourceResourceId: deployment.metadata?.destinationId || "",
        resourceType: "destinations",
      },
      { select: (data) => data.getLinkableResources },
    );

  return (
    <DeploymentWizard
      isOpen={isOpen}
      onClose={onClose}
      deploymentTestsLoading={isTestingDeployments}
      isDeploying={deploying}
      getDeploymentDiff={getSyncDeploymentDiff}
      linkableResources={linkableResources}
      linkableResourcesLoading={linkableResourcesLoading}
      deployResource={async (
        resourceId,
        targetWorkspaceId,
        draft,
        { deploySchedule },
      ) => {
        const { deploySync } = await deploy({
          syncId: resourceId,
          targetWorkspaceId,
          draft,
          deploySchedule: Boolean(deploySchedule),
        });
        const success =
          deploySync.__typename === "SuccessfulSyncDeploymentResult";
        return {
          success,
          resourceId: success ? deploySync.deployedResourceId : undefined,
        };
      }}
      deployment={deployment}
      deploymentTests={deploymentTests}
      targetWorkspaces={targetWorkspaces}
    />
  );
};
