import { FC, useEffect, useMemo, useRef, useState } from "react";

import {
  CloseIcon,
  Column,
  ConfirmationDialog,
  DeleteIcon,
  Drawer,
  DrawerBody,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Paragraph,
  RefreshIcon,
  Row,
  SearchInput,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tooltip,
  useToast,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import capitalize from "lodash/capitalize";
import pluralize from "pluralize";

import { TextWithTooltip } from "src/components/text-with-tooltip";
import {
  OrderBy,
  SavedAnalyticsChartsBoolExp,
  SavedAnalyticsChartsOrderBy,
  useBulkArchiveSavedAnalyticsChartMutation,
  useBulkDeleteSavedAnalyticsChartsMutation,
  useBulkRestoreSavedAnalyticsChartMutation,
  useSavedAnalyticsChartsQuery,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { useNavigate, useParams } from "src/router";
import {
  Pagination,
  SortOption,
  Table,
  TableColumn,
  useTableConfig,
  useTableSort,
} from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { PaginationProps } from "src/ui/table/pagination";
import { useRowSelect } from "src/ui/table/use-row-select";

enum SortKeys {
  Name = "name",
  ChartType = "chart_type",
  UpdatedAt = "updated_at",
}

enum DrawerTabs {
  Saved = 0,
  Archived = 1,
}

const initialSort: SortOption<keyof SavedAnalyticsChartsOrderBy> = {
  key: "updated_at",
  direction: OrderBy.Desc,
  label: "Recently updated",
};
const sortOptions: SortOption<keyof SavedAnalyticsChartsOrderBy>[] = [
  { key: "name", direction: OrderBy.Asc, label: "Name A -> Z" },
  { key: "name", direction: OrderBy.Desc, label: "Name Z -> A" },

  { key: "chart_type", direction: OrderBy.Asc, label: "Chart type A -> Z" },
  { key: "chart_type", direction: OrderBy.Desc, label: "Chart type Z -> A" },
  initialSort,
  {
    key: "updated_at",
    direction: OrderBy.Asc,
    label: "Least recently updated",
  },
];

type SavedChartsDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const SavedChartsDrawer: FC<SavedChartsDrawerProps> = ({
  isOpen,
  onClose,
}) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { toast } = useToast();

  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const [search, setSearch] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [archiveChartModalOpen, setArchiveChartModalOpen] = useState(false);
  const [deleteChartModalOpen, setDeleteChartModalOpen] = useState(false);

  const bulkDeleteSavedAnalyticsChartsMutation =
    useBulkDeleteSavedAnalyticsChartsMutation();
  const bulkArchiveSavedAnalyticsChartsMutation =
    useBulkArchiveSavedAnalyticsChartMutation();
  const bulkRestoreSavedAnalyticsChartsMutation =
    useBulkRestoreSavedAnalyticsChartMutation();

  const { limit, offset, page, setPage } =
    useTableConfig<SavedAnalyticsChartsOrderBy>({
      defaultSortKey: "updated_at",
      sortOptions: Object.values(SortKeys),
    });
  const orderBy = useTableSort<SavedAnalyticsChartsOrderBy>(
    initialSort,
    sortOptions,
  );
  const { selectedRows, onRowSelect } = useRowSelect();

  const hasuraFilters = useMemo(() => {
    const filters: SavedAnalyticsChartsBoolExp = {
      archived_at: { _is_null: true },
    };

    if (activeTab === DrawerTabs.Archived) {
      filters.archived_at = { _is_null: false };
    }

    if (search) {
      return { ...filters, name: { _ilike: `%${search}%` } };
    }

    return filters;
  }, [activeTab, search]);

  const savedChartsQuery = useSavedAnalyticsChartsQuery(
    { limit, filters: hasuraFilters, orderBy, offset },
    {
      notifyOnChangeProps: "tracked",
      keepPreviousData: true,
    },
  );

  const savedCharts = savedChartsQuery.data?.saved_analytics_charts ?? [];
  const savedChartsCount =
    savedChartsQuery.data?.saved_analytics_charts_aggregate.aggregate?.count ??
    0;

  useEffect(() => {
    if (!isOpen) {
      setSearch("");
    }
  }, [isOpen]);

  const changeTab = (index: number) => {
    onRowSelect([]);
    setActiveTab(index);
  };

  const archiveCharts = async () => {
    try {
      await bulkArchiveSavedAnalyticsChartsMutation.mutateAsync({
        ids: selectedRows as string[],
      });

      toast({
        id: "archive-chart-error",
        title: `Archived ${pluralize("chart", selectedRows.length, true)}`,
        variant: "success",
      });

      onRowSelect([]);
    } catch (error) {
      Sentry.captureException(error);
      toast({
        id: "archive-chart-error",
        title: `Failed to archive ${pluralize(
          "chart",
          selectedRows.length,
          true,
        )}`,
        message: error.message,
        variant: "error",
      });
    }
  };

  const restoreCharts = async () => {
    try {
      await bulkRestoreSavedAnalyticsChartsMutation.mutateAsync({
        ids: selectedRows as string[],
      });

      toast({
        id: "restore-chart-error",
        title: `Restored ${pluralize("chart", selectedRows.length, true)}`,
        variant: "success",
      });

      onRowSelect([]);
    } catch (error) {
      Sentry.captureException(error);
      toast({
        id: "restore-chart-error",
        title: `Failed to restore ${pluralize(
          "chart",
          selectedRows.length,
          true,
        )}`,
        message: error.message,
        variant: "error",
      });
    }
  };

  const deleteCharts = async () => {
    try {
      await bulkDeleteSavedAnalyticsChartsMutation.mutateAsync({
        ids: selectedRows as string[],
      });

      analytics.track("Bulk Deleted Saved Analytics Charts");

      toast({
        id: "delete-chart-error",
        title: `Deleted ${pluralize("chart", selectedRows.length, true)}`,
        variant: "success",
      });

      onRowSelect([]);

      if (id && selectedRows.includes(id)) {
        navigate("/analytics");
      }
    } catch (error) {
      Sentry.captureException(error);
      toast({
        id: "delete-chart-error",
        title: `Failed to delete ${pluralize(
          "chart",
          selectedRows.length,
          true,
        )}`,
        message: error.message,
        variant: "error",
      });
    }
  };

  const columns: TableColumn[] = [
    {
      name: "Name",
      cell: ({ name }) => (
        <TextWithTooltip message={name}>{name}</TextWithTooltip>
      ),
    },
    {
      name: "Chart type",
      max: "140px",
      cell: ({ chart_type }) => (
        <TextWithTooltip message={chart_type}>
          {capitalize(chart_type)}
        </TextWithTooltip>
      ),
    },
    LastUpdatedColumn,
  ];

  const pagination: PaginationProps = {
    page,
    setPage,
    count: savedChartsCount,
    rowsPerPage: limit,
  };

  const clickRow = ({ id }) => {
    analytics.track("Open Saved Analytics Chart", {
      chart_type: savedCharts.find((chart) => chart.id === id)?.chart_type,
    });

    navigate(`/analytics/${id}`);

    onClose();
  };

  return (
    <>
      <Drawer
        isOpen={isOpen}
        initialFocusRef={closeButtonRef}
        size="lg"
        showOverlay={false}
        onClose={onClose}
      >
        <Column gap={4} maxHeight="100vh" overflowY="auto">
          <Row
            pt={4}
            px={4}
            align="center"
            justify="space-between"
            position="sticky"
            top={0}
            zIndex={1}
            bg="white"
          >
            <Heading>Saved charts</Heading>
            <Tooltip message="Close drawer">
              <IconButton
                aria-label="Close drawer."
                icon={CloseIcon}
                onClick={onClose}
              />
            </Tooltip>
          </Row>
          <Column gap={4} px={6}>
            <Tabs index={activeTab} onChange={changeTab}>
              <TabList>
                <Tab>Saved</Tab>
                <Tab>Archived</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <DrawerBody p={0}>
                    <Column gap={6} width="100%">
                      <Row justify="space-between">
                        <SearchInput
                          placeholder="Search charts..."
                          value={search}
                          onChange={(event) => setSearch(event.target.value)}
                        />

                        <Menu>
                          <MenuButton isDisabled={selectedRows.length === 0}>
                            Actions
                          </MenuButton>
                          <MenuList>
                            <MenuItem
                              icon={DeleteIcon}
                              variant="danger"
                              onClick={() => setArchiveChartModalOpen(true)}
                            >
                              Archive chart
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </Row>
                      <Table
                        columns={columns}
                        data={savedCharts}
                        selectedRows={selectedRows}
                        loading={
                          savedChartsQuery.isLoading ||
                          savedChartsQuery.isRefetching ||
                          bulkRestoreSavedAnalyticsChartsMutation.isLoading
                        }
                        placeholder={{
                          title: "No saved charts",
                          error: "Saved charts failed to load.",
                        }}
                        onSelect={onRowSelect}
                        onRowClick={clickRow}
                      />
                      <Row justify="flex-end" width="100%" mt={4} px={4}>
                        <Pagination {...pagination} />
                      </Row>
                    </Column>
                  </DrawerBody>
                </TabPanel>
                <TabPanel>
                  <DrawerBody p={0}>
                    <Column gap={6} width="100%">
                      <Row justify="space-between">
                        <SearchInput
                          placeholder="Search charts..."
                          value={search}
                          onChange={(event) => setSearch(event.target.value)}
                        />
                        <Menu>
                          <MenuButton isDisabled={selectedRows.length === 0}>
                            Actions
                          </MenuButton>
                          <MenuList>
                            <MenuItem
                              icon={RefreshIcon}
                              onClick={restoreCharts}
                            >
                              Restore {pluralize("chart", selectedRows.length)}
                            </MenuItem>
                            <MenuItem
                              icon={DeleteIcon}
                              variant="danger"
                              onClick={() => setDeleteChartModalOpen(true)}
                            >
                              Delete {pluralize("chart", selectedRows.length)}
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </Row>
                      <Table
                        columns={columns}
                        data={savedCharts}
                        selectedRows={selectedRows}
                        loading={
                          savedChartsQuery.isLoading ||
                          savedChartsQuery.isRefetching ||
                          bulkRestoreSavedAnalyticsChartsMutation.isLoading
                        }
                        placeholder={{
                          title: "No saved charts found",
                          error: "Archived charts failed to load.",
                        }}
                        onSelect={onRowSelect}
                        onRowClick={clickRow}
                      />
                      <Row justify="flex-end" width="100%" mt={4} px={4}>
                        <Pagination {...pagination} />
                      </Row>
                    </Column>
                  </DrawerBody>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Column>
        </Column>
      </Drawer>

      <ConfirmationDialog
        confirmButtonText="Archive"
        isOpen={archiveChartModalOpen}
        title={`Archive ${pluralize("chart", selectedRows.length)}`}
        variant="danger"
        onClose={() => setArchiveChartModalOpen(false)}
        onConfirm={archiveCharts}
      >
        <Column gap={2}>
          <Paragraph>
            Are you sure you want to archive{" "}
            {pluralize("this", selectedRows.length)}{" "}
            {pluralize("chart", selectedRows.length, selectedRows.length > 1)}?
          </Paragraph>
          <Paragraph>You may restore it from the archived tab.</Paragraph>
        </Column>
      </ConfirmationDialog>

      <ConfirmationDialog
        confirmButtonText="Delete"
        isOpen={deleteChartModalOpen}
        title={`Delete ${pluralize("chart", selectedRows.length)}`}
        variant="danger"
        onClose={() => setDeleteChartModalOpen(false)}
        onConfirm={deleteCharts}
      >
        <Column gap={4}>
          <Paragraph>
            Are you sure you want to delete{" "}
            {pluralize("this", selectedRows.length)}{" "}
            {pluralize("chart", selectedRows.length, selectedRows.length > 1)}?
          </Paragraph>
          <Paragraph>
            Deletion of a chart is not reversible, you will not be able to
            restore {selectedRows.length > 1 ? "these" : "it"} afterwards.
          </Paragraph>
        </Column>
      </ConfirmationDialog>
    </>
  );
};
