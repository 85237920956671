import { FC } from "react";

import { Box, Text } from "@hightouchio/ui";

import { FormProps } from "../types";

export const ObjectInput: FC<Readonly<FormProps>> = () => {
  return (
    <Box mt={3} mx={3}>
      <Text color="text.secondary" fontWeight="normal" size="md">
        This creates a custom object that you can send to the destination
      </Text>
    </Box>
  );
};
