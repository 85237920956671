import {
  Box,
  Checkbox,
  CheckboxGroup,
  Column,
  FormField,
} from "@hightouchio/ui";
import { useMemo } from "react";
import {
  OrganizationParentModelsQuery,
  useOrganizationParentModelsQuery,
  useUpdateUserGroupGrantsMutation,
  useUpdateUserGroupSubsetGrantsMutation,
  useUserGroupGrantsByTypeQuery,
  useUserGroupSubsetGrantsQuery,
} from "src/graphql";
import { useParams } from "src/router";
import { WorkspaceResourceGrantsForm } from "./components/grant-form";
import { AdditionalConfigurationProps } from "./components/types";

export const ParentModelGrants = () => {
  const { workspaceId, roleId } = useParams();

  const { data: definitions } = useOrganizationParentModelsQuery(
    { workspaceId: workspaceId ?? "" },
    {
      enabled: Boolean(workspaceId),
      select: (data) => data.getOrganizationParentModels.parentModels,
      suspense: true,
    },
  );

  const { data: parentModelGrants } = useUserGroupGrantsByTypeQuery(
    {
      workspaceId: workspaceId ?? "",
      userGroupId: roleId ?? "",
      resourceType: "parent_model",
    },
    {
      enabled: Boolean(workspaceId && roleId),
      select: (data) => data.user_group_grants,
      suspense: true,
    },
  );

  const { data: subsetGrants } = useUserGroupSubsetGrantsQuery(
    {
      workspaceId: workspaceId ?? "",
      userGroupId: roleId ?? "",
    },
    {
      enabled: Boolean(workspaceId && roleId),
      select: (data) => data.user_group_subset_grants,
      suspense: true,
    },
  );

  const { mutateAsync: updateGrants } = useUpdateUserGroupGrantsMutation({
    onSuccess: () => {},
  });
  const { mutateAsync: updateSubsetGrants } =
    useUpdateUserGroupSubsetGrantsMutation();

  const grants = useMemo(() => {
    if (!parentModelGrants || !subsetGrants) {
      return null;
    }

    // Create a map of subsets to their parent model
    const subsetsToParentModel = new Map<string, string>();
    for (const definition of definitions ?? []) {
      for (const subset of definition.subsets) {
        subsetsToParentModel.set(subset.id, definition.id);
      }
    }

    return parentModelGrants.map((grant) => {
      const relatedSubsetGrants = subsetGrants.filter(
        (g) =>
          subsetsToParentModel.get(g.resource_id) ===
            String(grant.resource_id) && g.can_sync,
      );

      return {
        ...grant,
        additional_data: {
          subsets: relatedSubsetGrants.map((g) => g.resource_id),
        },
      };
    });
  }, [parentModelGrants, subsetGrants, definitions]);

  return (
    <WorkspaceResourceGrantsForm
      formType="card"
      grants={grants?.map((g) => ({ ...g })) ?? []}
      resourceType="parent_model"
      resources={
        definitions?.map((d) => {
          return {
            id: d.id,
            name: d.name,
            definition: d.source.definition,
            additionalConfiguration: d.subsets.length
              ? ({ value, onChange }: AdditionalConfigurationProps) => {
                  return (
                    <SubsetAdditionalConfiguration
                      definition={d}
                      value={value}
                      onChange={onChange}
                    />
                  );
                }
              : undefined,
          };
        }) ?? []
      }
      onSubmit={async ({ objects, additionalData }) => {
        if (workspaceId === undefined || roleId === undefined) {
          return;
        }

        const subsetObjects: {
          subset_id: string;
          workspace_id: string;
          user_group_id: string;
          can_sync: boolean;
          can_draft: boolean;
        }[] = [];

        for (const definition of definitions ?? []) {
          const selectedSubsets = additionalData[definition.id]?.subsets ?? [];
          for (const subset of definition.subsets) {
            const hasAccess = selectedSubsets?.includes(subset.id);
            subsetObjects.push({
              subset_id: subset.id,
              workspace_id: workspaceId,
              user_group_id: roleId,
              can_sync: hasAccess,
              can_draft: hasAccess,
            });
          }
        }

        await updateGrants({
          objects: objects.map((o) => ({ ...o, can_draft: o.can_sync })),
        });
        if (subsetObjects.length) {
          await updateSubsetGrants({
            objects: subsetObjects,
          });
        }
      }}
    />
  );
};

export const SubsetAdditionalConfiguration = ({
  definition,
  value,
  onChange,
}: {
  definition: OrganizationParentModelsQuery["getOrganizationParentModels"]["parentModels"][0];
} & AdditionalConfigurationProps) => {
  const groupedOptions = useMemo(() => {
    const groupedOptionsMap: {
      [key: string]: { label: string; value: string }[];
    } = {};

    for (const subset of definition.subsets) {
      const groupName = subset.group.name;
      if (!groupedOptionsMap[groupName]) {
        groupedOptionsMap[groupName] = [];
      }
      groupedOptionsMap[groupName]?.push({
        label: subset.name,
        value: subset.id,
      });
    }

    return Object.keys(groupedOptionsMap).map((groupName) => ({
      label: groupName,
      options: groupedOptionsMap[groupName] ?? [],
    }));
  }, [definition]);

  return (
    <Column background="base.background" p={4} mt={4} borderRadius="md">
      <FormField
        label="Subsets"
        description="These permissions will apply only to the selected subsets. Users will be blocked from interacting with audiences or syncs that depend on subsets not explicitly granted below."
      >
        <Box display="grid" gridTemplateColumns="repeat(4, 1fr)">
          {groupedOptions.map((group) => {
            const values =
              value?.subsets?.filter((v: string) => {
                return group.options.some((option) => option.value === v);
              }) ?? [];

            const otherValues =
              value?.subsets?.filter((v: string) => !values?.includes(v)) ?? [];

            const onSelectChange = (values: string[]) => {
              onChange({
                ...value,
                subsets: [...otherValues, ...values],
              });
            };

            return (
              <FormField label={group.label} key={group.label}>
                <Box
                  sx={{
                    ".chakra-stack > div": { mt: 1 },
                    ".chakra-checkbox__label": { fontWeight: "normal" },
                  }}
                  marginTop={-2}
                >
                  <CheckboxGroup>
                    {group.options.map((option) => (
                      <Checkbox
                        key={option.value}
                        value={option.value}
                        label={option.label}
                        isChecked={values.includes(option.value)}
                        onChange={(e) =>
                          onSelectChange(
                            e.target.checked
                              ? [...values, option.value]
                              : values.filter((v) => v !== option.value),
                          )
                        }
                      >
                        {option.label}
                      </Checkbox>
                    ))}
                  </CheckboxGroup>
                </Box>
              </FormField>
            );
          })}
        </Box>
      </FormField>
    </Column>
  );
};
