import { Syncs } from "src/components/models/syncs";
import { OutletContext } from ".";

export const ModelSyncs = ({
  context: { model },
}: {
  context: OutletContext;
}) => {
  return (
    <Syncs
      modelMatchboostingEnabled={model.matchboosting_enabled}
      syncs={model.syncs}
    />
  );
};
