import { FC } from "react";

import { CodeSnippet, Heading, Text } from "@hightouchio/ui";

import { Link } from "src/router";

import { CodeParams, SetupProps } from "./types";

function getCode(params: CodeParams): string {
  return `
  import 'package:hightouch_events/client.dart';

  final htevents = createClient(Configuration("${params.writeKey}", apiHost: "https://${params.apiHost}"));

  htevents.track("Order Completed", properties: {"productId": 123});
  `;
}

const install = `flutter pub add hightouch_events`;

export const SetupFlutter: FC<SetupProps> = (props: SetupProps) => {
  return (
    <>
      <Heading>Installing the SDK</Heading>
      <Text>
        This SDK is available via{" "}
        <Link href="https://pub.dev/packages/hightouch_events">pub.dev</Link>.
      </Text>
      <CodeSnippet code={install} />
      <Heading>Using the SDK</Heading>
      <CodeSnippet label="main.dart" code={getCode(props)} />
    </>
  );
};
