import {
  MonitoredResourceType,
  ParentResourceTypes,
} from "@hightouch/lib/resource-monitoring/types";
import { Suspense } from "react";
import { Navigate, useParams } from "src/router";
import { PageSpinner } from "src/components/loading";
import { ResourceMonitoring } from "src/components/resource-alert-triggers";
import { useUser } from "src/contexts/user-context";
import { useEventSyncQuery } from "src/graphql";

export const EventSyncAlerting = () => {
  const { workspace } = useUser();
  const { id } = useParams<{ id: string }>();
  if (!id) {
    return <Navigate to="../.." />;
  }

  const { data } = useEventSyncQuery(
    { id: String(id), workspaceId: workspace?.id },
    {
      enabled: Boolean(id),
      suspense: true,
    },
  );

  const destinationId = data
    ? data.event_forwarding_syncs_by_pk
      ? String(data.event_forwarding_syncs_by_pk.destination.id)
      : data.event_warehouse_syncs_by_pk?.event_warehouse_destination.id
    : undefined;

  return (
    <Suspense fallback={<PageSpinner />}>
      <ResourceMonitoring
        resourceId={id}
        resourceType={MonitoredResourceType.EventSync}
        parentResourceType={ParentResourceTypes.Destination}
        parentResourceId={destinationId ?? ""}
      />
    </Suspense>
  );
};
