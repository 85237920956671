import { useFlags } from "launchdarkly-react-client-sdk";
import {
  useDiscoverSchemaBackgroundQuery,
  useDiscoverSchemaBackgroundResultQuery,
  useDiscoverSourceMutation,
} from "src/graphql";
import { useQueryClient } from "react-query";
import { usePersistedState } from "src/hooks/use-persisted-state";
import { useEffect } from "react";

type UseSchemaDiscovery = {
  discovering: boolean;
  runSourceDiscovery: () => Promise<void>;
  discoverError: Error | null;
};

const SCHEMA_RESULT_INTERVAL_MS = 1000;

export const useSchemaDiscovery = (opts: {
  connectionId: string;
}): UseSchemaDiscovery => {
  const connectionId = opts.connectionId;
  const { value: jobId, set: setJobId } = usePersistedState<string | null>(
    `source-schema-discovery-${connectionId}`,
    null,
  );

  const { backgroundSchemaDiscovery: backgroundDiscoveryEnabled } = useFlags();

  const foregroundQuery = useDiscoverSourceMutation();

  const queryClient = useQueryClient();

  const backgroundStartQuery = useDiscoverSchemaBackgroundQuery(
    {
      connectionId,
    },
    {
      enabled: false,
      onSuccess(data) {
        setJobId(data.startBackgroundDiscovery.jobId);
      },
    },
  );

  const backgroundResultQuery = useDiscoverSchemaBackgroundResultQuery(
    {
      jobId: jobId ?? "",
    },
    {
      enabled: Boolean(jobId),
      refetchInterval: SCHEMA_RESULT_INTERVAL_MS,
      select: (data) => data.backgroundDiscoveryResult,
    },
  );

  useEffect(() => {
    const { data, error } = backgroundResultQuery;

    if (data?.__typename === "DiscoverBackgroundSuccess") {
      setJobId(null);
      queryClient.invalidateQueries();
    } else if (data?.__typename === "DiscoverBackgroundFailure") {
      setJobId(null);
    } else if (error) {
      setJobId(null);
    }
  }, [backgroundResultQuery]);

  if (!backgroundDiscoveryEnabled) {
    return {
      discovering: foregroundQuery.isLoading,
      runSourceDiscovery: async () => {
        await foregroundQuery.mutateAsync({ connectionId });
        return;
      },
      discoverError: foregroundQuery.error,
    };
  }

  return {
    discovering: !!jobId,
    runSourceDiscovery: async () => {
      await backgroundStartQuery.refetch();
      return;
    },
    discoverError:
      backgroundStartQuery.error ||
      backgroundResultQuery.error ||
      backgroundResultQuery.data?.["error"],
  };
};
