import { FC, useState, useMemo } from "react";

import {
  Alert,
  Button,
  CodeSnippet,
  Column,
  DrawerBody,
  Heading,
  Paragraph,
  Row,
  SectionHeading,
  Text,
  useToast,
} from "@hightouchio/ui";
import { captureException } from "@sentry/react";
import { useOutletContext } from "src/router";

import { Drawer } from "src/components/drawer";
import { Warning } from "src/components/warning";
import {
  useArchivedFailuresQuery,
  useRetryArchivedFailuresMutation,
} from "src/graphql";
import { PageSpinner } from "src/components/loading";
import { Table, TableColumn } from "src/ui/table";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { formatDatetime } from "src/utils/time";

import { OutletContext } from ".";

type Failure = {
  eventType: string;
  table: string;
  receivedAt: string;
  error: string;
  payload: Record<string, unknown>;
};

const FOURTEEN_DAYS_MS = 14 * 24 * 60 * 60 * 1000;

export const WarehouseSyncArchive: FC = () => {
  const { sync } = useOutletContext<OutletContext>();
  const { toast } = useToast();
  const [selectedFailure, setSelectedFailure] = useState<Failure>();

  // We memoize the call to Date.now so that we don't continuously refresh
  // from the current date continuously changing.
  const fourteenDaysAgo = useMemo(
    () => new Date(Date.now() - FOURTEEN_DAYS_MS).toISOString(),
    [],
  );
  const {
    data: getArchivedFailuresData,
    isLoading,
    error,
  } = useArchivedFailuresQuery(
    { input: { syncId: sync.id, fromDate: fourteenDaysAgo } },
    { select: (data) => data.getArchivedFailures.events },
  );

  const retryMutation = useRetryArchivedFailuresMutation();
  const retry = async () => {
    try {
      const {
        retryArchivedFailures: { numRetried },
      } = await retryMutation.mutateAsync({
        input: { syncId: sync.id, fromDate: fourteenDaysAgo },
      });
      toast({
        id: "retry",
        title: `${numRetried} events will be retried on the next run`,
        variant: "success",
      });
    } catch (error) {
      captureException(error);
      toast({
        id: "retry",
        title: "There was an error retrying the failures.",
        variant: "error",
      });
    }
  };

  if (isLoading) {
    return <PageSpinner />;
  }
  if (error) {
    return (
      <Warning
        title="Failed to load archived errors"
        subtitle="Please try again"
      />
    );
  }

  // Explode the events to have an instance per error.
  const failures = getArchivedFailuresData?.flatMap((event) =>
    event.errors.map((err) => ({
      eventType: event.eventType,
      receivedAt: event.receivedAt,
      payload: event.payload,
      table: err.table,
      error: err.message,
    })),
  );

  const columns: TableColumn[] = [
    {
      name: "Event type",
      cell: ({ eventType }) => (
        <TextWithTooltip fontWeight="medium">{eventType}</TextWithTooltip>
      ),
    },
    {
      name: "Table",
      cell: ({ table }) => (
        <TextWithTooltip fontWeight="medium">{table}</TextWithTooltip>
      ),
    },
    {
      name: "Received at",
      cell: ({ receivedAt }) => (
        <Text fontWeight="medium">{formatDatetime(receivedAt)}</Text>
      ),
    },
  ];

  return (
    <Column gap={4}>
      <Row align="center" justify="space-between" gap={4}>
        <Column>
          <SectionHeading>Archived failures</SectionHeading>
          <Paragraph color="text.secondary">
            Events are archived if they fail for more than 48 hours.
          </Paragraph>
        </Column>
        <Button isLoading={retryMutation.isLoading} onClick={retry}>
          Retry failures
        </Button>
      </Row>
      <Table
        data={failures}
        columns={columns}
        onRowClick={(row) => {
          setSelectedFailure(row);
        }}
        placeholder={{
          title: "No archived failures",
          body: "Events are archived if they fail for more than 48 hours.",
        }}
      />
      <FailureDrawer
        failure={selectedFailure}
        onClose={() => {
          setSelectedFailure(undefined);
        }}
      />
    </Column>
  );
};

const FailureDrawer: FC<
  Readonly<{ failure: Failure | undefined; onClose: () => unknown }>
> = ({ failure, onClose }) => {
  return (
    <Drawer isOpen={Boolean(failure)} onClose={onClose} size="lg">
      <Row
        p={6}
        borderBottom="1px solid"
        borderColor="base.border"
        width="100%"
      >
        <Heading>Failure</Heading>
      </Row>
      <DrawerBody>
        <Column gap={4}>
          {failure && (
            <Column>
              <SectionHeading>{failure.eventType}</SectionHeading>
              <Text color="text.secondary">
                Received at: {formatDatetime(failure.receivedAt)}
              </Text>
            </Column>
          )}
          <Alert
            variant="inline"
            type="error"
            title="Error"
            message={failure?.error}
          />
          <CodeSnippet
            label="Event payload"
            code={failure ? JSON.stringify(failure.payload, null, 4) : ""}
          />
        </Column>
      </DrawerBody>
    </Drawer>
  );
};
