import { Row } from "@hightouchio/ui";

import { PermissionedLinkButton } from "src/components/permission";
import { SyncsOrderBy, useDestinationSyncsQuery } from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import { Pagination, Table, useTableConfig } from "src/ui/table";
import placeholderImage from "src/assets/placeholders/sync.svg";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { useNavigate, useParams } from "src/router";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";

enum SortKeys {
  ModelName = "segment.name",
  SourceName = "segment.connection.name",
  UpdatedAt = "updated_at",
}

export const Element = () => {
  const { destination_id: id } = useParams<{ destination_id: string }>();
  const navigate = useNavigate();
  const { data: entitlementsData } = useEntitlements(true);

  const { limit, offset, page, orderBy, setPage, onSort } =
    useTableConfig<SyncsOrderBy>({
      defaultSortKey: "updated_at",
      sortOptions: Object.values(SortKeys),
    });

  const { overageLockout, destinationOverageText } = entitlementsData.overage;
  const overageText =
    destinationOverageText + " To create a sync, upgrade your plan.";

  const destinationSyncsQuery = useDestinationSyncsQuery(
    { destinationId: id!, limit, offset, orderBy },
    {
      suspense: true,
      select: (data) => data.syncs,
    },
  );

  const syncs = destinationSyncsQuery.data ?? [];

  return (
    <>
      <Table
        data={syncs}
        columns={[
          {
            name: "Model",
            sortDirection: orderBy?.segment?.name,
            onClick: () => onSort(SortKeys.ModelName),
            cell: ({ segment }) => {
              const name = segment?.name;
              const definition = segment?.connection?.definition;
              return (
                <TextWithTooltip fontWeight="medium">
                  {name || definition?.name}
                </TextWithTooltip>
              );
            },
          },
          {
            name: "Source",
            sortDirection: orderBy?.segment?.connection?.name,
            onClick: () => onSort(SortKeys.SourceName),
            cell: ({ segment }) => {
              const definition = segment?.connection?.definition;
              return (
                <Row gap={2} align="center" overflow="hidden">
                  <IntegrationIcon
                    name={definition?.name}
                    src={definition?.icon}
                  />
                  <TextWithTooltip fontWeight="medium" isTruncated>
                    {definition?.name}
                  </TextWithTooltip>
                </Row>
              );
            },
          },
          {
            ...LastUpdatedColumn,
            max: "max-content",
            sortDirection: orderBy?.updated_at,
            onClick: () => onSort(SortKeys.UpdatedAt),
          },
        ]}
        placeholder={{
          image: placeholderImage,
          title: "No models are synced to this destination",
          body: "Still working on this? Add a sync when you’re ready",
          button: (
            <PermissionedLinkButton
              permission={{
                v1: { resource: "sync", grant: "create" },
              }}
              variant="primary"
              isDisabled={overageLockout}
              href="/syncs/new"
              tooltip={overageLockout && overageText}
            >
              Add a sync
            </PermissionedLinkButton>
          ),
        }}
        onRowClick={({ id }) => navigate(`/syncs/${id}`)}
      />
      <Row sx={{ justifyContent: "flex-end", width: "100%", mt: 4 }}>
        <Pagination
          count={syncs.length}
          label="syncs"
          page={page}
          rowsPerPage={limit}
          setPage={setPage}
        />
      </Row>
    </>
  );
};
