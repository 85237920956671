import { Code } from "@tiptap/extension-code";

export const SlackCode = Code.extend({
  addOptions() {
    return { HTMLAttributes: { class: "rte-slack-code-class" } };
  },
  addKeyboardShortcuts() {
    return {
      "Mod-Shift-c": () => this.editor.commands.toggleCode(),
      "Mod-Shift-C": () => this.editor.commands.toggleCode(),
    };
  },
});
