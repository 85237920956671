import { FC, useState } from "react";

import { Box, PlusIcon, Row, Text } from "@hightouchio/ui";
import isEqual from "lodash/isEqual";
import merge from "lodash/merge";
import { Control, useFieldArray, useFormContext } from "react-hook-form";

import { Schedule } from "./schedule";
import { SamplingConfig } from "./types";
import {
  ScheduleInterval,
  ScheduleIntervalUnit,
} from "src/components/schedule/types";

type Props = {
  modelId: string;
};

export const OverrideFrequency: FC<Props> = ({ modelId }) => {
  const { control, watch } = useFormContext<SamplingConfig>();
  const { fields, update } = useFieldArray<
    SamplingConfig,
    "sampledRelatedModels",
    "arrayId"
  >({
    control: control as unknown as Control<SamplingConfig>,
    keyName: "arrayId",
    name: "sampledRelatedModels",
  });

  const fieldIndex = fields.findIndex(
    (field) => String(field.modelId) === String(modelId),
  );
  const field = watch(`sampledRelatedModels.${fieldIndex}`);
  const schedule = field.schedule;
  const parentSchedule = watch("schedule");

  const [showOverride, setShowOverride] = useState(
    !isEqual(parentSchedule, schedule),
  );

  const handleScheduleChange = (
    partialSchedule: Partial<ScheduleInterval> | undefined,
  ) => {
    if (!partialSchedule) {
      return;
    }

    update(
      fieldIndex,
      merge({}, field, {
        schedule: {
          schedule: { interval: partialSchedule },
        },
      }),
    );
  };

  const handleResetOverride = () => {
    update(fieldIndex, {
      ...field,
      schedule: parentSchedule,
    });
    setShowOverride(false);
  };

  if (!field) {
    return null;
  }

  if (showOverride) {
    return (
      <Schedule
        quantity={schedule?.schedule?.interval.quantity ?? 1}
        unit={schedule?.schedule?.interval.unit ?? ScheduleIntervalUnit.DAY}
        onChange={handleScheduleChange}
        onReset={handleResetOverride}
      />
    );
  }

  return (
    <Row cursor="pointer" gap={2} onClick={() => setShowOverride(true)}>
      <Box as={PlusIcon} color="link.default" fontSize={20} />
      <Text color="link.default" fontWeight="medium">
        Override
      </Text>
    </Row>
  );
};
