import { FC, ReactNode } from "react";

import { Container } from "src/ui/box";
import { BoxProps } from "@hightouchio/ui";

export type PageContainerProps = {
  children: ReactNode;
  fullWidth?: boolean;
  center?: boolean;
} & BoxProps;

export enum DefaultPageContainerPadding {
  Top = 4,
  Bottom = 24,
  X = 6,
}

export const PageContainer: FC<Readonly<PageContainerProps>> = ({
  children,
  fullWidth,
  ...props
}) => {
  return (
    <Container
      size={fullWidth ? "full" : "page"}
      minWidth="400px"
      pt={DefaultPageContainerPadding.Top}
      pb={DefaultPageContainerPadding.Bottom}
      px={DefaultPageContainerPadding.X}
      {...props}
    >
      {children}
    </Container>
  );
};
