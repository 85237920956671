import { FC } from "react";

import { MonitorStatus } from "@hightouch/lib/resource-monitoring/types";
import { Box, Text } from "@hightouchio/ui";

export const ResourceStatus: FC<{ status: MonitorStatus }> = ({ status }) => {
  switch (status) {
    case MonitorStatus.Unhealthy:
      return (
        <Box display="contents">
          <Text color="red">Unhealthy</Text> and sends alerts to configured
          recipients
        </Box>
      );
    case MonitorStatus.Warning:
      return <Text color="orange">Warning</Text>;
    case MonitorStatus.Healthy:
      return <Text color="green">Healthy</Text>;
  }
  return null;
};

ResourceStatus.displayName = ResourceStatus.name;
