import { ToggleButton, ToggleButtonGroup } from "@hightouchio/ui";

export const RangeToggle = ({ value, onChange }) => {
  return (
    <ToggleButtonGroup value={value} onChange={onChange}>
      <ToggleButton value="all" label="All time" />
      <ToggleButton value="30d" label="30d" />
      <ToggleButton value="7d" label="7d" />
    </ToggleButtonGroup>
  );
};
