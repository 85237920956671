import { FC, useEffect, useState } from "react";

import { Badge, Button, ResetIcon, Row } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import isEqual from "lodash/isEqual";
import get from "lodash/get";
import { useFormContext } from "react-hook-form";

import { useFormkitContext } from "src/formkit/components/formkit-context";

type SyncOverrideProps = {
  childNodeKeys: string[];
};

export const SyncOverride: FC<SyncOverrideProps> = ({ childNodeKeys }) => {
  const { enableSyncTemplateOverrides } = useFlags();

  const { sync, hasUnlockedFields } = useFormkitContext();
  const { getValues, setValue } = useFormContext();

  const [initialValues, setInitialValues] = useState({});

  // RULE: If the field's children are all unlocked, then this field's value may be overriden.
  const isUnlocked = childNodeKeys.some(
    (nodeKey) =>
      get(sync, ["sync_template", "override_config", nodeKey]) !== undefined,
  );

  // Store initial value on mount
  useEffect(() => {
    if (enableSyncTemplateOverrides) {
      const initialConfigValues = {};

      childNodeKeys.forEach((nodeKey) => {
        initialConfigValues[nodeKey] = get(sync?.config, [nodeKey]);
      });

      setInitialValues(initialConfigValues);
    }
  }, [enableSyncTemplateOverrides, childNodeKeys, sync]);

  const hasOverrides = childNodeKeys.some(
    (nodeKey) => !isEqual(get(initialValues, nodeKey), getValues(nodeKey)),
  );

  const reset = () => {
    childNodeKeys.forEach((nodeKey) => {
      setValue(nodeKey, initialValues[nodeKey]);
    });
  };

  if (!enableSyncTemplateOverrides || !hasUnlockedFields || !isUnlocked) {
    return null;
  }

  return (
    <Row justify="end" gap={2}>
      {hasOverrides && <Badge variant="primary">Custom value</Badge>}
      <Button
        icon={ResetIcon}
        isDisabled={!hasOverrides}
        variant="tertiary"
        size="sm"
        onClick={reset}
      >
        Restore default
      </Button>
    </Row>
  );
};
