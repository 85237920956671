import {
  AggregationOption,
  AudienceAggregationType,
  ColumnType,
  DecisionEngineAnalyticsCampaignMetricType,
  DecisionEngineAnalyticsMetricDefinition,
  DecisionEngineInteractionColumnNames,
  isDecisionEngineAnalyticsAttributionMetricsDefinition,
  PerUserAggregationType,
  RawColumn,
  RelatedColumn,
} from "@hightouch/lib/query/visual/types";
import { Relationship } from "src/types/visual";
import { AggregationOptions } from "../metrics/constants";
import { MetricResultMaybeFromCache } from "./hooks/use-metric-series";
import {
  MeasuringMode,
  MeasuringSelection,
  MeasuringSelectionColumn,
  MetricGroupOption,
  MetricSelection,
} from "./types";

export type DecisionEngineOutcome = {
  outcome: {
    id: string;
    segment_id: number;
    name: string;
  };
};

export type DecisionEngineFlow = {
  id: string;
  name: string;
};

export const formatDecisionEngineAnalyticsCampaignMetric = ({
  parentModelId,
  aggregationConfiguration,
  eventModelId,
  relationshipId,
  metricSelection,
  measuringSelection,
  measuringMode,
  column,
}: {
  parentModelId: string;
  aggregationConfiguration: {
    aggregation: PerUserAggregationType;
    audienceAggregation: AudienceAggregationType;
  };
  eventModelId: string;
  relationshipId: string;
  metricSelection: MetricSelection;
  measuringSelection: MeasuringSelection;
  measuringMode: MeasuringMode | undefined;
  column: RawColumn | RelatedColumn | undefined;
}): DecisionEngineAnalyticsMetricDefinition => {
  const metricType = getFlowMetricType(measuringMode);

  return {
    type: "decision_engine_flow",
    flowId: measuringSelection.id,
    parentModelId,
    ...aggregationConfiguration,
    metricType,
    config: {
      eventModelId,
      relationshipId,
      column: column ?? metricSelection.column?.column_reference,
      filter: {
        subconditions: metricSelection.conditions,
      },
    },
  };
};

function getFlowMetricType(measuringMode: MeasuringMode | undefined) {
  if (measuringMode === MeasuringMode.Incrementality) {
    return DecisionEngineAnalyticsCampaignMetricType.Incrementality;
  }

  return DecisionEngineAnalyticsCampaignMetricType.AttributedEvents;
}

export const decisionEngineFlowMetricOptions = (
  events: Relationship[] | undefined,
  outcomes: DecisionEngineOutcome[] | undefined,
): MetricGroupOption[] => {
  return [
    {
      label: "Outcomes",
      options: (events ?? [])
        .map((event) => ({
          id: event.id,
          eventModelId: event.to_model?.id,
          name: event.to_model?.name ?? event.name,
          description: event?.to_model?.description,
        }))
        .filter((event) =>
          outcomes?.some(
            ({ outcome }) => outcome.segment_id == event.eventModelId,
          ),
        ),
    },
    // TODO @jenn-chan: Uncomment when we support interactions query
    // Make sure to not include this if in  incrementality mode
    /*
    {
      alias: "Interactions",
      options: [
        {
          id: `de_interactions_${measuringSelection?.id}`,
          eventModelId: null,
          name: "AI Decisioning Interactions",
          description: null,
        },
      ],
    },
    */
  ];
};

const supportedAggregations = [
  AggregationOption.Count,
  AggregationOption.SumOfProperty,
  AggregationOption.UniqueUsers,
  AggregationOption.AverageOfProperty,
];

export const DecisionEngineAggregationOptions = AggregationOptions.filter(
  (opt) => supportedAggregations.includes(opt.value),
);

// XXX: Temporary solution to remove holdout group from metric result from the
// client side by default
export const removeDecisionEngineHoldoutGroupFromMetricResult = (
  result: MetricResultMaybeFromCache[],
) => {
  return result.map((series) => {
    const isDecisionEngineAttribution =
      isDecisionEngineAnalyticsAttributionMetricsDefinition(
        series.ids.metricDefinition,
      );

    if (isDecisionEngineAttribution && "data" in series.result) {
      return {
        ...series,
        result: {
          ...series.result,
          data: series.result.data
            .filter(({ splitId }) => splitId !== "holdout")
            .map((seriesData) => ({ ...seriesData, splitId: null })),
        },
      };
    }

    return series;
  });
};

const DEFAULT_INTERACTION_COLUMNS: MeasuringSelectionColumn[] = [
  {
    alias: "User ID",
    columnReference: {
      type: "decision_engine_interaction",
      name: DecisionEngineInteractionColumnNames.UserId,
    },
    columnType: ColumnType.String,
  },
];

const DEFAULT_ACTION_FEATURES_COLUMNS: MeasuringSelectionColumn[] = [
  {
    alias: "Channel",
    columnReference: {
      type: "decision_engine_interaction_action_features",
      name: "channel",
    },
    columnType: ColumnType.String,
  },
  {
    alias: "Day of week",
    columnReference: {
      type: "decision_engine_interaction_action_features",
      name: "day_of_week",
    },
    columnType: ColumnType.String,
  },
  {
    alias: "Time of day",
    columnReference: {
      type: "decision_engine_interaction_action_features",
      name: "time_of_day",
    },
    columnType: ColumnType.String,
  },
  {
    alias: "Frequency",
    columnReference: {
      type: "decision_engine_interaction_action_features",
      name: "frequency_arm",
    },
    columnType: ColumnType.String,
  },
  {
    alias: "Message",
    columnReference: {
      type: "decision_engine_interaction_action_features",
      name: "message",
    },
    columnType: ColumnType.String,
  },
  {
    alias: "Subject",
    columnReference: {
      type: "decision_engine_interaction_action_features",
      name: "subject",
    },
    columnType: ColumnType.String,
  },
];

type DecisionEngineInteractionFeatureColumnsQueryResult =
  | {
      config: any;
      flows: Array<{
        messages: Array<{
          message: {
            variables: any | null;
          };
        }>;
      }>;
    }
  | undefined;

/**
 * Extract and format the columns from the decision engine columns query. These
 * column schemas come from different resources but fetched in one query.
 *
 * Within a decision engine flow, we have these different types of interaction columns:
 * - Interactions
 * - User features
 * - Action features
 *
 * User features have different types but we only want to expose categorical (ie. text)
 *  and booleans for now.
 * Action features are always *string* type
 */
export function decisionEngineFlowSyntheticColumns(
  measurmentMode: MeasuringMode | undefined,
  result: DecisionEngineInteractionFeatureColumnsQueryResult,
): {
  columns: MeasuringSelectionColumn[];
  additionalUserColumns: MeasuringSelectionColumn[];
} {
  const config = result?.config;
  const customUserFeaturesColumns = (config?.user_feature_schema ?? [])
    .filter((u) => u.type === "categorical" || u.type === "boolean")
    .map((u) => ({
      alias: u.name,
      columnReference: {
        type: "decision_engine_interaction_user_features",
        name: `${u.name.toLowerCase()}`,
      },
      columnType: getUserFeatureColumnType(u.type),
    }));

  if (measurmentMode === MeasuringMode.Incrementality) {
    return {
      columns: [],
      additionalUserColumns: customUserFeaturesColumns,
    };
  }

  const messages = result?.flows[0]?.messages ?? [];
  const customActionFeatures = new Set();

  for (const m of messages) {
    const variables = m.message.variables ?? [];
    for (const v of variables) {
      // Only want to include if there are values specified for the feature
      if (v?.name && v?.values.length) customActionFeatures.add(v.name);
    }
  }

  const customActionFeaturesColumns = Array.from(customActionFeatures).map(
    (action) => ({
      alias: action?.toString() ?? "",
      columnReference: {
        type: "decision_engine_interaction_action_features" as const,
        name: action?.toString() ?? "",
      },
      columnType: ColumnType.String,
    }),
  );

  const interactionColumns = [
    ...DEFAULT_INTERACTION_COLUMNS,
    ...DEFAULT_ACTION_FEATURES_COLUMNS,
    ...customActionFeaturesColumns,
  ];

  return {
    columns: interactionColumns,
    additionalUserColumns: customUserFeaturesColumns,
  };
}

function getUserFeatureColumnType(type: "categorical" | "boolean") {
  switch (type) {
    case "categorical":
      return ColumnType.String;
    case "boolean":
    default:
      return ColumnType.String;
  }
}
