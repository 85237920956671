import { FC, ReactNode } from "react";

import { Box } from "@hightouchio/ui";

// Wrap any PII to avoid sending to LogRocket
export const Private: FC<{ children: ReactNode; isDisabled?: boolean }> = ({
  children,
  isDisabled = false,
}) =>
  isDisabled ? (
    <>{children}</>
  ) : (
    <Box display="contents" data-private>
      {children}
    </Box>
  );
