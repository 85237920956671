import { FilterState } from "./filters";

export const isFilterActive = (
  filter: FilterState | undefined,
): filter is FilterState =>
  Boolean(filter && !filter.isAllSelected && filter.selected.length);

export const labelFilter = (state: FilterState | undefined) => {
  if (isFilterActive(state)) {
    return {
      _or: state.selected.map((filter) => {
        if (filter.id === "-1") {
          return {
            tags: { _eq: {} },
          };
        }
        const key = filter.id.split(":")[0];
        const value = filter.id.split(":")[1];
        const obj = {};
        obj[key!] = value;
        return {
          tags: { _contains: obj },
        };
      }),
    };
  }
  return {};
};

export const createdFilter = (state: FilterState | undefined) => {
  if (isFilterActive(state)) {
    return {
      created_by: { _in: state.selected.map((f) => f.id) },
    };
  }
  return {};
};
