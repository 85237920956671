import differenceWith from "lodash/differenceWith";
import isEqual from "lodash/isEqual";
import partition from "lodash/partition";
import pick from "lodash/pick";

import { SamplingForParentModelQuery } from "src/graphql";

import { SampledModel } from "./types";

export const isSampledParentModel = (
  sampledModel: Pick<
    SamplingForParentModelQuery["sampled_segments"][0],
    "segment" | "parent_model" | "relationship_id"
  >,
  parentModelId: string,
): boolean =>
  String(sampledModel.segment.id) === String(parentModelId) &&
  sampledModel.parent_model?.id == undefined &&
  sampledModel.relationship_id == undefined;

export const diffSampledRelatedModels = (
  oldModels: (SampledModel & { id: string })[],
  newModels: SampledModel[],
) => {
  // 1. Find additions
  const [savedModels, addedModels] = partition(newModels, "id");
  const addedAndEnabledModels = addedModels.filter((m) => m.enabled);

  // 2. Find updates
  const diffableKeysForUpdate = ["enabled", "sampleRate", "schedule"];
  const updatedModels = differenceWith(savedModels, oldModels, (a, b) =>
    isEqual(pick(a, diffableKeysForUpdate), pick(b, diffableKeysForUpdate)),
  );

  return {
    add: addedAndEnabledModels,
    update: updatedModels,
  };
};
