import { FC } from "react";

import { Box, Button, RefreshIcon, Row, Text, Tooltip } from "@hightouchio/ui";

import { usePermissionContext } from "src/components/permission";

import { useFormkitContext } from "../formkit-context";

type Props = {
  reload?: () => void;
  loading?: boolean;
  usingCustomFromOptions?: boolean;
  /**
  @default Name of model
  */
  fromLabel?: string;
  /**
  @default Icon of source
  */
  fromIcon?: string;
  fromReloadOptions?: () => void;
  fromLoadingOptions?: boolean;
};

export const MappingsHeader: FC<Readonly<Props>> = ({
  reload,
  usingCustomFromOptions,
  loading,
  fromReloadOptions,
  fromLoadingOptions,
  fromLabel,
  fromIcon,
}) => {
  const {
    model,
    loadingModel,
    reloadModel,
    sourceDefinition,
    destinationDefinition,
  } = useFormkitContext();

  const permission = usePermissionContext();

  return (
    <>
      <Row gap={2} justifyContent="space-between">
        <Row align="center" gap={2} isTruncated>
          {(fromIcon || sourceDefinition) && (
            <Box
              alt={`${sourceDefinition?.name} icon`}
              as="img"
              flex="none"
              src={fromIcon || sourceDefinition?.icon}
              width={5}
            />
          )}
          <Text fontWeight="semibold" isTruncated>
            {fromLabel || model?.name || "Source"}
          </Text>
        </Row>

        {(!usingCustomFromOptions || fromReloadOptions) && model && (
          <Box flexShrink={0}>
            <Tooltip
              isDisabled={permission.unauthorized}
              message={
                fromReloadOptions
                  ? "Refresh options"
                  : "Refresh columns available in the source"
              }
            >
              <Button
                icon={RefreshIcon}
                isDisabled={permission.unauthorized}
                isLoading={fromLoadingOptions || loadingModel}
                onClick={fromReloadOptions || reloadModel}
                size="sm"
                variant="secondary"
              >
                Refresh
              </Button>
            </Tooltip>
          </Box>
        )}
      </Row>

      <Box />

      <Row gap={2} justifyContent="space-between">
        <Row align="center" gap={2} isTruncated>
          <Box
            alt={`${destinationDefinition?.name} icon`}
            as="img"
            flex="none"
            src={destinationDefinition?.icon}
            width={5}
          />
          <Text fontWeight="semibold" isTruncated>
            Destination field
          </Text>
        </Row>

        {reload && (
          <Tooltip message="Refresh columns available in the destination">
            <Button
              icon={RefreshIcon}
              isLoading={loading}
              onClick={reload}
              size="sm"
              variant="secondary"
            >
              Refresh
            </Button>
          </Tooltip>
        )}
      </Row>
    </>
  );
};
