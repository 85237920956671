import { useCallback, useState } from "react";

export default () => {
  const [highlightedNodeIds, setHighlightedNodeIds] = useState<string[]>([]);

  const highlightNodes = useCallback((nodeIds: string[]) => {
    setHighlightedNodeIds(nodeIds);
  }, []);

  const resetHighlightedNodes = useCallback(() => {
    setHighlightedNodeIds([]);
  }, []);

  const isNodeHighlighted = useCallback(
    (nodeId: string) => {
      return !highlightedNodeIds.length || highlightedNodeIds.includes(nodeId);
    },
    [highlightedNodeIds],
  );

  return {
    highlightNodes,
    resetHighlightedNodes,
    isNodeHighlighted,
  };
};
