import { Column, Row, Text } from "@hightouchio/ui";
import { commaNumber } from "src/utils/numbers";

type Props = {
  title: string;
  data: {
    value: number;
    color: string;
    label: string;
  }[];
  description?: string;
};

export const ChartTooltip = ({ title, data, description }: Props) => {
  return (
    <Column bg="gray.900" padding={2} borderRadius="md" gap={2}>
      <Text fontWeight="medium" color="text.placeholder">
        {title}
      </Text>
      {data.map((item, i) => (
        <Row key={i} gap={2}>
          <Column bg={item.color} width={2} borderRadius="sm" />
          <Column>
            <Text fontWeight="medium" color="white">
              {`${commaNumber(item.value)} ${item.label}`}
            </Text>
          </Column>
        </Row>
      ))}
      {description && (
        <Text fontWeight="medium" color="text.placeholder">
          {description}
        </Text>
      )}
    </Column>
  );
};
