import { FC } from "react";

import { Box, Row, Tooltip, TooltipProps, BoxProps } from "@hightouchio/ui";

import { Link } from "src/router";

import { useContentVisibility } from "src/hooks/use-content-visibility";

type LinkWithTooltipProps = BoxProps &
  Omit<TooltipProps, "message"> & {
    message?: string;
  } & {
    href: string;
    isExternal?: boolean;
  };

export const LinkWithTooltip: FC<Readonly<LinkWithTooltipProps>> = ({
  children,
  isDisabled = false,
  keyboardShortcut,
  message,
  placement,
  href,
  ...props
}) => {
  const { isVisible, ref } = useContentVisibility<HTMLAnchorElement>();

  return (
    <>
      {/* Remove the `width: max-content` in the span to make ellipsis show */}
      <Row
        display="flex"
        alignItems="center"
        overflow="hidden"
        sx={{
          span: { width: "100%", whiteSpace: "nowrap" },
          a: {
            width: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        }}
      >
        <Tooltip
          message={message ?? String(children)}
          isDisabled={isVisible || isDisabled}
          keyboardShortcut={keyboardShortcut}
          placement={placement}
        >
          <Box as={Link} href={href} ref={ref} {...props}>
            {children}
          </Box>
        </Tooltip>
      </Row>
    </>
  );
};
