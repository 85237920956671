import { Column, DrawerHeader } from "@hightouchio/ui";
import { RouteTab, RouteTabs } from "src/components/route-tabs";
import { ReactNode } from "react";

type Props = {
  children: ReactNode;
  tabs: Array<RouteTab>;
  depth?: number;
};

export const TabDrawerHeader = ({ children, tabs, depth }: Props) => {
  return (
    <DrawerHeader
      border="none"
      p={0}
      flexDir="column"
      alignItems="stretch"
      w="100%"
    >
      <Column px={6} py={4}>
        {children}
      </Column>
      <RouteTabs px={6} tabs={tabs} depth={depth} />
    </DrawerHeader>
  );
};
