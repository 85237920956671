import { createContext, FC, useContext, useState } from "react";

import { uniq } from "lodash";
import { useDebounce } from "use-debounce";

import { DashboardSyncMetricGranularity } from "src/graphql";

import { SyncHealthFilter } from "./transformations";

interface SyncHealthContextType {
  filter: SyncHealthFilter;
  resetFilters: () => void;
  filterIds: string[];
  setFilterIds: (fn: (curr) => string[], manual: boolean) => void;
  filterLocked: boolean;
  granularity: DashboardSyncMetricGranularity;
  setGranularity: (granularity: DashboardSyncMetricGranularity) => void;
  targetResourceType: "source" | "destination";
  setTargetResourceType: (targetResourceType: "source" | "destination") => void;
}
const defaultFilters: SyncHealthFilter = {
  sourceIds: [],
  destinationIds: [],
  sourceTypes: [],
  destinationTypes: [],
};

const SyncHealthContext = createContext<SyncHealthContextType>({
  filter: { ...defaultFilters },
  resetFilters: () => {},
  filterLocked: false,
  filterIds: [],
  setFilterIds: () => {},
  granularity: DashboardSyncMetricGranularity.TwoDay,
  setGranularity: () => {},
  targetResourceType: "source",
  setTargetResourceType: () => {},
});

export const useSyncHealthContext = () => useContext(SyncHealthContext);

export const SyncHealthContextProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [targetResourceType, setTargetResourceType] = useState<
    "source" | "destination"
  >("destination");
  const [_filter, setFilter] = useState<SyncHealthFilter>({
    ...defaultFilters,
  });
  // We want to avoid instantly changing the filter when the user hovers over rows so the graphs seem more stable
  // and we aren't running transformations needlessly
  const [filter] = useDebounce(_filter, 300);

  const filterIds =
    targetResourceType === "destination"
      ? _filter.destinationIds
      : _filter.sourceIds;
  const setFilterIds = (fn: (curr) => string[], manual: boolean) => {
    if (filterLocked && !manual) {
      return;
    }
    setFilter((current) => {
      if (targetResourceType === "destination") {
        const updated = uniq(fn(current.destinationIds));
        if (manual) {
          if (!updated.length) {
            setFilterLocked(false);
            return current;
          } else {
            setFilterLocked(true);
          }
        }
        return { ...current, destinationIds: updated };
      } else {
        const updated = uniq(fn(current.sourceIds));
        if (manual) {
          if (!updated.length) {
            setFilterLocked(false);
            return current;
          } else {
            setFilterLocked(true);
          }
        }
        return { ...current, sourceIds: updated };
      }
    });
  };

  const [filterLocked, setFilterLocked] = useState(false);
  const [granularity, setGranularity] =
    useState<DashboardSyncMetricGranularity>(
      DashboardSyncMetricGranularity.TwoDay,
    );

  return (
    <SyncHealthContext.Provider
      value={{
        filter,
        resetFilters: () => setFilter({ ...defaultFilters }),
        filterIds,
        setFilterIds,
        filterLocked,
        granularity,
        setGranularity,
        targetResourceType,
        setTargetResourceType,
      }}
    >
      {children}
    </SyncHealthContext.Provider>
  );
};
