import { FC } from "react";

import { FormField, Radio, RadioGroup, Spinner, Text } from "@hightouchio/ui";
import { get } from "lodash";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { Section } from "src/ui/section";
import {
  MODE_LABEL,
  DEFAULT_MODE_LABEL,
  MODE_DESCRIPTIONS,
  DEFAULT_MODE_DESCRIPTIONS,
  MODE_DESCRIPTION,
  DEFAULT_MODE_DESCRIPTION,
} from "src/utils/destinations";
import { ReloadButton } from "src/components/reload-button";

type ModeFieldProps = {
  error?: string;
  options: { label: string; value: string; description?: string | null }[];
  value?: string;
  reload?: () => void;
  loading?: boolean;
  onChange?: (value: string) => void;
  path?: string[];
};

export const ModeField: FC<Readonly<ModeFieldProps>> = ({
  value: propValue,
  error: propError,
  options,
  onChange,
  reload,
  loading,
  path,
}) => {
  const { slug, errors, config, setConfig } = useDestinationForm();

  const value = propValue || config?.mode;
  const error = propError || errors?.mode;

  return (
    <Section>
      <FormField
        description={MODE_DESCRIPTION[slug ?? ""] || DEFAULT_MODE_DESCRIPTION}
        error={error}
        label={
          <>
            <Text>{MODE_LABEL[slug ?? ""] || DEFAULT_MODE_LABEL}</Text>
            {reload && <ReloadButton ml={2} onClick={reload} />}
          </>
        }
      >
        {loading ? (
          <Spinner />
        ) : (
          <RadioGroup
            value={value}
            onChange={(mode) => {
              if (onChange) {
                onChange(mode as string);
              } else {
                setConfig({ mode });
              }
            }}
          >
            {options.map((option) => (
              <Radio
                key={option.value}
                {...option}
                description={
                  option?.description ||
                  get(
                    MODE_DESCRIPTIONS,
                    path
                      ? [slug ?? "", ...path, option.value]
                      : [slug ?? "", option.value],
                  ) ||
                  DEFAULT_MODE_DESCRIPTIONS?.[option.value]
                }
              />
            ))}
          </RadioGroup>
        )}
      </FormField>
    </Section>
  );
};
