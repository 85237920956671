import { FC } from "react";

import {
  CodeSnippet,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@hightouchio/ui";

import { Link } from "src/router";

import { CodeParams, SetupProps } from "./types";

function getJavaCode(params: CodeParams): string {
  return `
  import com.hightouch.analytics.Analytics;

  public class Main {
    public static void main(String[] args) throws Exception {
      final Analytics analytics =
          Analytics.builder("${params.writeKey}")
              .endpoint("https://${params.apiHost}")
              .build();

      // e.g. send events
      // analytics.enqueue(
      //   TrackMessage.builder("Item Purchased")
      //   .userId("f4ca124298")
      // );
    }
  }
  `;
}

const pomXML = `
<repositories>
  <repository>
    <id>jitpack.io</id>
    <url>https://jitpack.io</url>
  </repository>
</repositories>

<dependency>
  <groupId>com.github.ht-sdks.events-sdk-java</groupId>
  <artifactId>analytics</artifactId>
  <version>LATEST</version>
</dependency>
`;

const buildGradle = `
allprojects {
  repositories {
    ...
    maven { url 'https://jitpack.io' }
  }
}

dependencies {
  ...
  compile 'com.github.ht-sdks.events-sdk-java:analytics:+'
}
`;

export const SetupJava: FC<SetupProps> = (props: SetupProps) => {
  return (
    <>
      <Heading>Installing the SDK</Heading>
      <Text>
        This SDK is available via{" "}
        <Link href="https://jitpack.io/#ht-sdks/events-sdk-java/">JitPack</Link>
        .
      </Text>
      <Tabs>
        <TabList>
          <Tab>Maven</Tab>
          <Tab>Gradle</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <CodeSnippet label="pom.xml" code={pomXML} />
          </TabPanel>
          <TabPanel>
            <CodeSnippet label="build.gradle" code={buildGradle} />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Heading>Using the SDK</Heading>
      <CodeSnippet label="Main.java" code={getJavaCode(props)} />
    </>
  );
};
