import { FC, useState, useEffect } from "react";

import { Controller } from "react-hook-form";
import { Combobox, FormField, TextInput } from "@hightouchio/ui";

import { GitCredentials, useGithubRepositoriesQuery } from "src/graphql";
import { isGitHub } from "./utils";

interface Props {
  credentials: GitCredentials | undefined;
}

export const GitRepositorySelector: FC<Readonly<Props>> = ({ credentials }) => {
  const [error, setError] = useState<string>("");

  const { data: githubRepos, isLoading: githubRepoLoading } =
    useGithubRepositoriesQuery(
      {
        credentialId: String(credentials?.id),
      },
      {
        select: (data) => data.getGithubRepositories,
        enabled: isGitHub(credentials),
        onError: (error) => setError(error?.message),
      },
    );

  const githubRepoOptions = githubRepos?.map((repo) => ({
    label: repo.fullName,
    value: repo.htmlUrl,
  }));

  useEffect(() => {
    setError("");
  }, [credentials?.type]);

  const isRepoSelect =
    isGitHub(credentials) && (githubRepoOptions || githubRepoLoading);

  return (
    <FormField
      description={
        isRepoSelect
          ? undefined
          : "Please use the full link of the Git repository, including https."
      }
      error={error}
      label="Repository"
    >
      <Controller
        name="repository"
        render={({ field }) =>
          isRepoSelect ? (
            <Combobox
              {...field}
              isLoading={githubRepoLoading}
              options={githubRepoOptions ?? []}
              placeholder="Select a repository..."
            />
          ) : (
            <TextInput {...field} />
          )
        }
      />
    </FormField>
  );
};
