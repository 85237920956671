import { useState } from "react";

import {
  FormField,
  Button,
  TextInput,
  IconButton,
  Row,
  Column,
  CloseIcon,
} from "@hightouchio/ui";
import validator from "validator";

import { EmailAlert, EmailAlertConfig } from "./types";

export const EmailForm = ({
  config,
  setConfig,
}: {
  config: EmailAlertConfig;
  setConfig: (config: EmailAlertConfig) => void;
}) => {
  const [newEmail, setNewEmail] = useState<string | undefined | null>();

  const recipients = config?.recipients || [];

  const setRecipients = (recipients: string[]) => {
    setConfig({ ...config, recipients });
  };

  return (
    <FormField label="Recipients">
      {!!recipients?.length && (
        <Column gap={2} mb={2}>
          {recipients?.map((r, idx) => (
            <Row key={idx} alignItems="center" gap={2}>
              <TextInput
                isReadOnly
                placeholder="Enter an email..."
                value={r || ""}
              />
              <IconButton
                aria-label="Remove recipient"
                icon={CloseIcon}
                onClick={() => {
                  const a = [...recipients];
                  a.splice(idx, 1);
                  setRecipients(a);
                }}
              />
            </Row>
          ))}
        </Column>
      )}
      <Row alignItems="center" gap={2}>
        <TextInput
          placeholder="Enter an email..."
          value={newEmail || ""}
          onChange={(event) => {
            setNewEmail(event.currentTarget.value);
          }}
        />
        <Button
          isDisabled={!newEmail || !validator.isEmail(newEmail)}
          onClick={() => {
            if (newEmail) {
              setRecipients([...recipients, newEmail]);
            }
            setNewEmail(null);
          }}
        >
          Add
        </Button>
      </Row>
    </FormField>
  );
};

export const emailValidator = (alert: EmailAlert): boolean => {
  const config = alert?.config;
  return Boolean(config?.recipients?.length);
};
