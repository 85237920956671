export const Icon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20px"
    height="20px"
    viewBox="0 0 24 24"
    fill={color}
  >
    <path
      fillRule="evenodd"
      d="M12 10.091a1.727 1.727 0 1 0 0-3.455 1.727 1.727 0 0 0 0 3.455Zm0 1a2.727 2.727 0 1 0 0-5.455 2.727 2.727 0 0 0 0 5.455Zm0 1.907a4.456 4.456 0 0 0-4.342 3.455h8.684A4.456 4.456 0 0 0 12 12.998Zm5.363 3.455a5.456 5.456 0 0 0-10.818 1h10.91c0-.342-.032-.676-.092-1ZM20.917 3h-2.38a.083.083 0 0 0-.082.083v2.38c0 .045.037.082.083.082h2.379A.083.083 0 0 0 21 5.462V3.083A.083.083 0 0 0 20.917 3Zm-2.38-1c-.597 0-1.082.485-1.082 1.083v2.38c0 .597.485 1.082 1.083 1.082h2.379c.598 0 1.083-.485 1.083-1.083V3.083C22 2.485 21.515 2 20.917 2h-2.38Zm2.38 16.454h-2.38a.083.083 0 0 0-.082.084v2.378c0 .046.037.084.083.084h2.379a.083.083 0 0 0 .083-.084v-2.378a.083.083 0 0 0-.083-.084Zm-2.38-1c-.597 0-1.082.485-1.082 1.084v2.378c0 .599.485 1.084 1.083 1.084h2.379c.598 0 1.083-.485 1.083-1.084v-2.378c0-.599-.485-1.084-1.083-1.084h-2.38ZM5.462 18.455H3.083A.083.083 0 0 0 3 18.54v2.378c0 .046.037.084.083.084h2.38a.083.083 0 0 0 .082-.084V18.54a.083.083 0 0 0-.083-.084Zm-2.379-1C2.485 17.455 2 17.94 2 18.54v2.378c0 .599.485 1.084 1.083 1.084h2.38c.597 0 1.082-.485 1.082-1.084V18.54c0-.599-.485-1.084-1.083-1.084H3.083ZM5.462 3H3.083A.083.083 0 0 0 3 3.083v2.38c0 .045.037.082.083.082h2.38a.083.083 0 0 0 .082-.083V3.083A.083.083 0 0 0 5.462 3ZM3.083 2C2.485 2 2 2.485 2 3.083v2.38c0 .597.485 1.082 1.083 1.082h2.38c.597 0 1.082-.485 1.082-1.083V3.083C6.545 2.485 6.06 2 5.462 2H3.083Z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M3.818 17.454V6.544h1v10.91h-1Zm15.455.077V6.545h1V17.53h-1ZM17.454 4.726H6.545v-1h10.91v1Zm0 15.456H6.545v-1h10.91v1Z"
      clipRule="evenodd"
    />
  </svg>
);
