import { FC } from "react";

import {
  Box,
  InformationIcon,
  Row,
  StatusIndicator,
  StatusIndicatorProps,
  Tooltip,
} from "@hightouchio/ui";

import { IdrRuns, IdrRunStatus } from "src/graphql";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { diff, formatFriendlyDistanceToNow } from "src/utils/time";

type GraphStatusIndicatorProps = {
  lastRun:
    | Pick<IdrRuns, "status" | "error" | "started_at" | "finished_at">
    | undefined;
  showDuration?: boolean;
  showTimeOnlyOnExtraLargeScreens?: boolean;
};

export const GraphStatusIndicator: FC<GraphStatusIndicatorProps> = ({
  lastRun,
  showTimeOnlyOnExtraLargeScreens,
  showDuration,
}) => {
  const { status, variant } = getStatusIndicator(
    lastRun?.status,
    Boolean(lastRun?.error),
  );

  const duration = lastRun
    ? getDuration(lastRun.started_at, lastRun.finished_at)
    : "--";
  const distanceFromNow = lastRun?.finished_at
    ? formatFriendlyDistanceToNow(lastRun.finished_at)
    : "--";

  return (
    <StatusIndicator variant={variant}>
      <Row align="center" gap={1} overflow="hidden">
        <TextWithTooltip fontWeight="medium" size="sm">
          {status}
        </TextWithTooltip>
        {lastRun?.finished_at && (
          <>
            <Box
              overflow="hidden"
              display={
                showTimeOnlyOnExtraLargeScreens
                  ? ["none", "none", "none", "none", "auto"]
                  : "auto"
              }
            >
              <TextWithTooltip size="sm" color="text.secondary">{`${
                showDuration ? `in ${duration}` : distanceFromNow
              }`}</TextWithTooltip>
            </Box>
            <Row
              align="center"
              overflow="hidden"
              display={
                showTimeOnlyOnExtraLargeScreens
                  ? ["flex", "flex", "flex", "flex", "none"]
                  : "none"
              }
            >
              <Tooltip
                message={formatFriendlyDistanceToNow(lastRun.finished_at)}
              >
                <InformationIcon color="text.secondary" />
              </Tooltip>
            </Row>
          </>
        )}
      </Row>
    </StatusIndicator>
  );
};

function getDuration(
  startedAt: string | null | undefined,
  finishedAt: string | null | undefined,
) {
  if (!startedAt) return "--";

  return diff(startedAt, finishedAt ?? new Date().toUTCString());
}

function getStatusIndicator(
  status: string | undefined,
  hasError: boolean,
): {
  variant: StatusIndicatorProps["variant"];
  status: string;
} {
  switch (status) {
    case IdrRunStatus.Cancelled:
      return { variant: "inactive", status: "Cancelled" };
    case IdrRunStatus.Cancelling:
      return { variant: "warning", status: "Cancelling" };
    case IdrRunStatus.Failure:
      return { variant: "error", status: "Failed" };
    case IdrRunStatus.Processing:
      return { variant: "processing", status: "Processing" };
    case IdrRunStatus.Queued:
      return { variant: "inactive", status: "Queued" };
    case IdrRunStatus.Success:
      return hasError
        ? { variant: "warning", status: "Completed w/ error" }
        : { variant: "success", status: "Completed" };
    default:
      return { variant: "inactive", status: "Never run" };
  }
}
