import { useState } from "react";

import { Row, Select, Button, RefreshIcon, PlusIcon } from "@hightouchio/ui";

import { useCloudCredentialsV2Query } from "src/graphql";

import { CreateCredential } from "./create";

export const SelectCredential = ({
  isInvalid = false,
  provider,
  value,
  onChange,
  isClearable = false,
}) => {
  const { data, isLoading, refetch, isRefetching } = useCloudCredentialsV2Query(
    { provider },
  );
  const credentials = data?.getCloudCredentials ?? [];

  const [isCreating, setCreating] = useState<boolean>();

  return (
    <>
      {isCreating && (
        <CreateCredential
          defaultProvider={provider}
          onClose={() => setCreating(false)}
          onCreate={({ id }) => {
            onChange(Number(id));
          }}
        />
      )}

      <Row gap={3}>
        <Select
          isInvalid={isInvalid}
          isLoading={isLoading || isRefetching}
          optionLabel={(credential) => credential.name}
          optionValue={(credential) => credential.id}
          options={credentials}
          placeholder="Select credentials..."
          value={value}
          onChange={onChange}
          isClearable={isClearable}
        />

        <Button icon={RefreshIcon} onClick={() => refetch()}>
          Refresh
        </Button>

        <Button icon={PlusIcon} onClick={() => setCreating(true)}>
          New credentials
        </Button>
      </Row>
    </>
  );
};
