import {
  MonitorConditionProperties,
  MonitorConditionTypes,
} from "@hightouch/lib/resource-monitoring/types";
import { Column, Row, Select, Text } from "@hightouchio/ui";
import { Controller, useFormContext } from "react-hook-form";
import { FieldError } from "src/components/field-error";

import { cleanFormError } from "src/components/resource-alert-triggers/utils";
import { ThresholdType } from "./monitor-condition-form";
import { NumberInputField } from "./fields/number-input-field";

type IntervalType = ThresholdType<
  MonitorConditionProperties[MonitorConditionTypes.EventSourceVolume]["Threshold"]
>[number]["interval"];

export const EventSourceVolumeField = ({ name, description }) => {
  const { watch, control, getFieldState } = useFormContext();

  const countError = getFieldState(`${name}.count`).error;
  const count = watch(`${name}.count`);

  const intervalOptions: {
    label: string;
    value: IntervalType;
  }[] = [
    { label: "15 minutes", value: "15 minutes" },
    { label: "4 hours", value: "4 hours" },
    { label: "1 day", value: "1 day" },
    { label: "1 hour", value: "1 hour" },
    { label: "2 days", value: "2 days" },
    { label: "7 days", value: "7 days" },
  ] as const;

  return (
    <Column>
      <Row gap={1} alignItems="center" flexWrap="wrap">
        {description}
        <NumberInputField name={`${name}.count`} />

        <Text mx={2}>{count === 1 ? "event" : "events"} received in</Text>

        <Controller
          name={`${name}.interval`}
          control={control}
          render={({ field: { value, onChange } }) => (
            <Select
              value={value}
              size="sm"
              width="auto"
              options={intervalOptions}
              onChange={onChange}
            />
          )}
        />
      </Row>
      {countError?.message ? (
        <FieldError error={cleanFormError(countError?.message as string)} />
      ) : null}
    </Column>
  );
};
