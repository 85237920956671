import { FC } from "react";

import {
  Column,
  InformationIcon,
  NumberInput,
  Row,
  SectionHeading,
  Select,
  Text,
  Tooltip,
} from "@hightouchio/ui";
import { Controller, useFormContext } from "react-hook-form";
import { IntervalUnit } from "src/types/visual";

import { TIME_OPTIONS } from "src/pages/metrics/constants";

import { ChartFormState } from "src/pages/analytics/types";
import { InputGroup } from "src/pages/analytics/input-group";

export const ConversionCriteria: FC = () => {
  const form = useFormContext<ChartFormState>();

  const quantity = form.watch("funnelConversionWindow.quantity");
  const unit = TIME_OPTIONS.find(
    ({ value }) => value === form.watch("funnelConversionWindow.unit"),
  )?.label;

  return (
    <Column gap={2}>
      <SectionHeading>Conversion criteria</SectionHeading>
      <Column
        flex={1}
        minWidth={0}
        p={2}
        gap={2}
        bg="white"
        border="1px solid"
        borderColor="base.border"
        borderRadius="md"
        boxShadow="sm"
      >
        <Row align="center" justify="space-between" gap={2}>
          <Row
            as={Text}
            align="center"
            gap={1}
            color="text.secondary"
            size="sm"
          >
            Conversion window
            <Tooltip message="The window of time a user has to complete all steps once they enter the funnel">
              <InformationIcon />
            </Tooltip>
          </Row>
          <InputGroup
            content={
              <Row flex={1} minWidth={0} gap={2}>
                <Controller
                  control={form.control}
                  name="funnelConversionWindow.quantity"
                  render={({ field }) => (
                    <NumberInput
                      {...field}
                      width="auto"
                      onChange={(value) => field.onChange(value ?? 0)}
                    />
                  )}
                />
                <Controller
                  control={form.control}
                  name="funnelConversionWindow.unit"
                  render={({ field: { ref, ...field } }) => (
                    <Select
                      {...field}
                      options={TIME_OPTIONS}
                      width="auto"
                      onChange={(value) =>
                        field.onChange(value ?? IntervalUnit.Day)
                      }
                    />
                  )}
                />
              </Row>
            }
          >
            <Text color="primary.base" size="sm">
              {quantity} {unit}
            </Text>
          </InputGroup>
        </Row>
      </Column>
    </Column>
  );
};
