import { FC, useState } from "react";

import {
  ConfirmationDialog,
  Paragraph,
  WizardDrawer as HightouchUIWizardDrawer,
  WizardDrawerProps,
} from "@hightouchio/ui";

import { usePylonDrawerOffset } from "./use-pylon-drawer-offset";

type Props = Readonly<WizardDrawerProps> & {
  isDirty?: boolean;
};

export const WizardDrawer: FC<Props> = ({
  isOpen = false,
  size = "md",
  isDirty = false,
  onClose,
  ...props
}) => {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const closeDrawer = () => {
    if (isDirty && !showConfirmationDialog) {
      setShowConfirmationDialog(true);
    } else {
      onClose();
    }
  };

  usePylonDrawerOffset({ isOpen, size });

  return (
    <>
      <HightouchUIWizardDrawer
        isOpen={isOpen}
        size={size}
        onClose={closeDrawer}
        {...props}
      />
      <ConfirmationDialog
        isOpen={showConfirmationDialog}
        title="Are you sure?"
        confirmButtonText="Yes, close"
        variant="warning"
        onConfirm={closeDrawer}
        onClose={() => setShowConfirmationDialog(false)}
      >
        <Paragraph>You will lose any unsaved changes.</Paragraph>
      </ConfirmationDialog>{" "}
    </>
  );
};
