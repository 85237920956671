import { MonitorStatus } from "@hightouch/lib/resource-monitoring/types";
import { Box, Column, NumberInput, Row, Text } from "@hightouchio/ui";
import { Controller } from "react-hook-form";
import { FieldError } from "src/components/field-error";
import { ResourceStatus } from "src/components/resource-alert-triggers/resource-status";
import { isPresent } from "src/types/utils";
import { cleanFormError } from "src/components/resource-alert-triggers/utils";

export const UnscheduledSyncsField = ({ name }) => {
  return (
    <Controller
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Column>
          <Row flexWrap="wrap" gap={1}>
            <Text>More than</Text>
            <Box
              isInvalid={Boolean(error)}
              as={NumberInput}
              display="inline-flex"
              width="50px"
              size="sm"
              value={value}
              onChange={onChange}
            />
            <Text>minutes elapsed without a sync run</Text>
          </Row>
          {error?.message ? (
            <FieldError error={cleanFormError(error?.message)} />
          ) : null}
        </Column>
      )}
    />
  );
};

interface Props {
  condition: {
    error_value: number;
    warning_value: number;
  };
}

export const UnscheduledSyncCondition = ({ condition }: Props) => {
  return [
    condition.error_value ? (
      <Text>
        If a sync has not run for over{" "}
        <Text fontWeight="semibold">{condition.error_value}</Text> minutes set
        sync status to <ResourceStatus status={MonitorStatus.Unhealthy} />
      </Text>
    ) : null,
    condition.warning_value ? (
      <Text>
        If a sync has not run for over{" "}
        <Text fontWeight="semibold">{condition.warning_value}</Text> minutes set
        sync status to <ResourceStatus status={MonitorStatus.Warning} />
      </Text>
    ) : null,
  ].filter(isPresent);
};
