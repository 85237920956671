import { Global, css } from "@emotion/react";

export const GlobalStyles = () => (
  <>
    <Global styles={base} />
    <Global styles={reset} />
    <Global styles={ace} />
    <Global styles={algolia} />
    <Global styles={fonts} />
    <Global styles={googlePicker} />
  </>
);

const base = () => css`
  html {
    font-weight: 400;
    font-size: 13px;
  }

  #react {
    height: 100%;
    display: flex;
  }
  /**
   * Remove outline from
   * - all anchor tags
   * - span elements (tooltips)
   *
   */
  a:active,
  a:focus,
  span {
    outline: none;
  }
  strong {
    font-weight: 500;
  }
`;

const reset = () => css`
  /**
 * Manually forked from SUIT CSS Base: https://github.com/suitcss/base
 * A thin layer on top of normalize.css that provides a starting point more
 * suitable for web applications.
 */
  /**
 * Removes the default spacing and border for appropriate elements.
 */
  button {
    background-color: transparent;
    background-image: none;
  }
  /**
  * Work around a Firefox/IE bug where the transparent button background
  * results in a loss of the default button focus styles.
  */
  button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }
  fieldset {
    margin: 0;
    padding: 0;
  }
  ol,
  ul {
    margin: 0;
    padding-left: 14px;
  }
  textarea {
    resize: vertical;
  }
  input::placeholder,
  textarea::placeholder {
    opacity: 1;
  }
  button,
  [role="button"] {
    cursor: pointer;
  }
  table {
    border-collapse: collapse;
  }
  button,
  input,
  optgroup,
  select,
  textarea {
    padding: 0;
    line-height: inherit;
    color: inherit;
  }
  /**
  * Make replaced elements display: block by default as that's
  * the behavior you want almost all of the time. Inspired by
  * CSS Remedy, with svg added as well.
  *
  * https://github.com/mozdevs/cssremedy/issues/14
  */
  img,
  svg,
  video,
  canvas,
  audio,
  iframe,
  embed,
  object {
    display: block;
    vertical-align: middle;
  }
  /**
  * Constrain images and videos to the parent width and preserve
  * their instrinsic aspect ratio.
  *
  * https://github.com/mozdevs/cssremedy/issues/14
  */
  img,
  video {
    max-width: 100%;
    height: auto;
  }
`;

const ace = css`
  .ace_editor {
    border-radius: 3px;
    border: 1px solid var(--chakra-colors-base-border) !important;
  }
  .ace_gutter {
    background-color: var(--chakra-colors-gray-100) !important;
  }
  .ace_gutter-active-line {
    background-color: var(--chakra-colors-forest-500) !important;
    color: white !important;
  }
`;

const algolia = css`
  .ais-SearchBox-form {
    display: flex;
    width: 100%;
    align-items: center;
    position: relative;
  }
  .ais-SearchBox-input {
    width: 100%;
    padding: 20px;
    padding-left: 52px;
    font-size: 15px;
    border: 2px solid rgb(233, 236, 245);
    border-radius: 4px;
    min-height: 32px;
    background-color: white;
    flex: 1;
    transition: border-color 150ms;
    outline: none !important;
    display: flex;
    align-items: center;
    &:hover,
    &:focus {
      border-color: var(--chakra-colors-gray-600);
    }
    &::-webkit-search-cancel-button {
      display: none;
    }
  }
  .ais-SearchBox-submit {
    position: absolute;
    left: 16px;
  }
  .ais-SearchBox-reset {
    position: absolute;
    right: 16px;
    border: none;
    &:focus:not(:focus-visible) {
      outline: none !important;
    }
  }
  .ais-SearchBox-input::placeholder {
    color: var(--chakra-colors-gray-600);
    font-size: 15px;
  }
  .ais-SearchBox-resetIcon {
    width: 16px;
    height: 16px;
    fill: var(--chakra-colors-gray-600);
  }
  .ais-SearchBox-loadingIndicator,
  .ais-SearchBox-submit {
    display: none;
  }
`;

const fonts = css`
  @font-face {
    font-family: "Sharp Sans Display No 1";
    font-style: normal;
    font-weight: 700;
    font-display: block;
    src: url(/fonts/sharp-sans-disp-no-1-bold.woff2) format("woff2");
  }
  @font-face {
    font-family: "Sharp Sans Display No 1";
    font-style: normal;
    font-weight: 600;
    font-display: block;
    src: url(/fonts/sharp-sans-disp-no-1-semibold.woff2) format("woff2");
  }
`;

const googlePicker = css`
  .picker-dialog-bg {
    z-index: 20000 !important;
  }
  .picker-dialog {
    z-index: 20001 !important;
  }
`;
