import { FC, useEffect, useState } from "react";

import {
  BidirectionalArrowIcon,
  Box,
  Button,
  ButtonGroup,
  CloseIcon,
  Column,
  Combobox,
  ConfirmationDialog,
  EditableText,
  IconButton,
  Row,
  SectionHeading,
  Select,
  Spinner,
  Switch,
  Text,
  TextInput,
  useToast,
} from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { captureException } from "@sentry/react";
import { merge, set } from "lodash";
import {
  Control,
  Controller,
  FieldErrors,
  SubmitHandler,
  useFieldArray,
  useForm,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { RouterLink, useNavigate, useParams } from "src/router";
import * as y from "yup";

import { PermissionedButton } from "src/components/permission";
import {
  Relationship,
  useCreateRelationshipMutation,
  useDeleteRelationship2Mutation,
  useRelationshipModelsQuery,
  useRelationshipsQuery,
  useUpdateRelationshipMutation,
} from "src/graphql";
import {
  canColumnsBeMerged,
  cardinalityOptions,
} from "src/pages/schema/relationships/utils";
import { FieldError } from "src/components/field-error";
import { SchemaModelType } from "src/types/schema";

type FormValues = {
  relationship: Relationship;
};

export const RelationshipForm: FC<
  Readonly<{
    models: { id: string; name: string; columns: Column[] }[];
    sourceId: string;
    modelId: string;
    requiredModelType?: SchemaModelType;
  }>
> = ({ models, modelId, requiredModelType: modelType, sourceId }) => {
  const navigate = useNavigate();
  const params = useParams<{ id?: string }>();
  const { toast } = useToast();
  const [isDeleting, setIsDeleting] = useState(false);

  const updateRelationshipMutation = useUpdateRelationshipMutation();
  const createRelationshipMutation = useCreateRelationshipMutation();
  const deleteRelationshipMutation = useDeleteRelationship2Mutation();

  const [edgeId, reverseEdgeId] = params.id?.split("-") || [];

  const { data: relationships } = useRelationshipsQuery(
    { id: String(modelId) },
    {
      enabled: Boolean(modelId),
      select: (data) => data.listRelationships.relationships,
    },
  );

  const fromModel = models?.find((m) => String(m.id) === String(modelId));
  const relationship = relationships?.find(
    (r) =>
      String(r.edgeId) === String(edgeId) &&
      String(r.reverseEdgeId) === String(reverseEdgeId),
  );
  const toModel = models?.find(
    (m) => String(m.id) === String(relationship?.to),
  );

  const {
    control,
    watch,
    setValue,
    reset,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm<FormValues>({
    resolver: validationResolver(relationshipFormSchema),
    context: { models },
    defaultValues: {
      relationship: getDefaultRelationship(modelId) as Relationship,
    },
  });

  const submit: SubmitHandler<FormValues> = async (data) => {
    if (relationship) {
      try {
        await updateRelationshipMutation.mutateAsync({
          edgeId: relationship.edgeId,
          reverseEdgeId: relationship.reverseEdgeId,
          relationship: data.relationship,
        });
        toast({
          id: "update-relationship",
          title: "Relationship updated",
          variant: "success",
        });
        // the reverse edge may be added while updating the relationship, we have to navigate so that we don't use
        // the stale params
        navigate({
          pathname: "/schema-v2/view/relationships",
          search: window.location.search,
        });
      } catch (e) {
        toast({
          id: "update-relationship",
          title: "Relationship failed to update",
          variant: "error",
        });
        captureException(e);
      }
    } else {
      try {
        await createRelationshipMutation.mutateAsync({
          relationship: data.relationship,
        });
        toast({
          id: "create-relationship",
          title: "Relationship created",
          variant: "success",
        });
        navigate({
          pathname: "/schema-v2/view/relationships",
          search: window.location.search,
        });
      } catch (e) {
        toast({
          id: "create-relationship",
          title: "Failed to create relationship",
          message: e.message,
          variant: "error",
        });
        captureException(e);
      }
    }
  };

  const handleDelete = async () => {
    try {
      await deleteRelationshipMutation.mutateAsync({
        edgeId: relationship?.edgeId,
        reverseEdgeId: relationship?.reverseEdgeId,
      });
      toast({
        id: "delete-relationships",
        title: "Relationship deleted",
        variant: "success",
      });
      navigate({
        pathname: "/schema-v2/view/relationships",
        search: window.location.search,
      });
    } catch (e) {
      toast({
        id: "delete-relationships",
        title: "Relationship failed to delete",
        variant: "error",
      });
      captureException(e);
    }
  };

  useEffect(() => {
    if (relationship) {
      reset({
        relationship: {
          ...relationship,
          edgeId: undefined,
          reverseEdgeId: undefined,
          toName: relationship.toName ?? toModel?.name,
        },
      });
    }
  }, [relationship]);

  return (
    <Column height="100%">
      <Column flex={1} p={6} overflow="auto">
        <Row align="center" mb={4} gap={2}>
          <RouterLink
            to={{
              pathname: "/schema-v2/view/relationships",
              search: window.location.search,
            }}
          >
            <Box
              as={Text}
              color="text.secondary"
              _hover={{ color: "text.primary" }}
            >
              Relationships
            </Box>
          </RouterLink>
          <Text fontWeight="medium" color="text.secondary">
            /
          </Text>
          <Text color="text.secondary" fontWeight="medium">
            {relationship
              ? relationship.toName || toModel?.name
              : "New relationship"}
          </Text>
        </Row>
        {fromModel ? (
          <RelationshipFields
            relationship={relationship}
            sourceId={sourceId}
            fromModel={fromModel}
            control={control}
            watch={watch}
            setValue={setValue}
            errors={errors}
            modelType={modelType}
          />
        ) : (
          <Spinner size="lg" m="auto" />
        )}
      </Column>
      <Row
        bg="white"
        borderTop="1px"
        borderColor="base.border"
        p={4}
        justify="space-between"
      >
        <ButtonGroup>
          <PermissionedButton
            permission={{
              v1: {
                resource: "audience_schema",
                grant: "update",
              },
              v2: {
                resource: "model",
                grant: "can_update",
                id: modelId,
              },
            }}
            isDisabled={!isDirty}
            isLoading={
              updateRelationshipMutation.isLoading ||
              createRelationshipMutation.isLoading
            }
            size="lg"
            variant="primary"
            onClick={handleSubmit(submit)}
          >
            Save changes
          </PermissionedButton>
          <Button
            isDisabled={!isDirty}
            size="lg"
            onClick={() => {
              if (relationship) {
                reset();
              } else {
                navigate({
                  pathname: "/schema-v2/view/relationships",
                  search: window.location.search,
                });
              }
            }}
          >
            Cancel
          </Button>
        </ButtonGroup>
        {relationship && (
          <PermissionedButton
            permission={{
              v1: {
                resource: "audience_schema",
                grant: "update",
              },
              v2: {
                resource: "model",
                grant: "can_update",
                id: modelId,
              },
            }}
            size="lg"
            variant="warning"
            onClick={() => {
              setIsDeleting(true);
            }}
          >
            Delete
          </PermissionedButton>
        )}
      </Row>
      <ConfirmationDialog
        confirmButtonText="Delete"
        isOpen={isDeleting}
        title="Delete relationship"
        variant="danger"
        onClose={() => setIsDeleting(false)}
        onConfirm={handleDelete}
      >
        <Text>Are you sure you want to delete this relationship?</Text>
      </ConfirmationDialog>
    </Column>
  );
};

type Column = {
  name: string;
  type: string;
  alias?: string | null;
  custom_type?: string | null;
};

export const RelationshipFields: FC<
  Readonly<{
    relationship?: Relationship;
    errors: FieldErrors<FormValues>;
    sourceId: string;
    control: Control<FormValues>;
    setValue: UseFormSetValue<FormValues>;
    watch: UseFormWatch<FormValues>;
    fromModel: {
      id: string;
      name: string;
      columns: Column[];
    };
    // toModel is used for creation flows where there is only a single relationship being created
    toModel?: { name: string; columns: Column[] };
    modelType?: SchemaModelType;
  }>
> = ({
  relationship,
  fromModel,
  sourceId,
  modelType,
  control,
  watch,
  errors,
  ...props
}) => {
  const to = watch("relationship.to");
  const cardinality = watch("relationship.cardinality");

  const { data: models } = useRelationshipModelsQuery(
    { sourceId },
    { select: (data) => data.segments },
  );

  const isMergable = canColumnsBeMerged(cardinality);

  const toModels = models?.filter((m) => {
    if (String(m.id) === String(fromModel?.id)) {
      return false;
    }

    if (modelType && m.type !== modelType) {
      return false;
    }

    return true;
  });
  const toModel =
    props.toModel ?? models?.find((m) => String(m.id) === String(to));

  useEffect(() => {
    // Only set this if no relationship exists.
    if (!relationship) {
      props.setValue("relationship.toName", toModel?.name ?? "");
    }
  }, [toModel?.name, relationship, props.setValue]);

  return (
    <Column gap={4} flex={1} pb={8}>
      <Row justify="space-between" align="center" width="100%">
        <Controller
          control={control}
          name="relationship.toName"
          render={({ field }) =>
            toModel ? (
              <EditableText
                value={field.value || toModel.name}
                onChange={field.onChange}
                fontWeight="medium"
              />
            ) : (
              <Text fontWeight="medium" color="text.secondary">
                New relationship
              </Text>
            )
          }
        />
      </Row>

      <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" gap={2}>
        <TextInput width="100%" isReadOnly value={fromModel.name} />
        <Controller
          control={control}
          name="relationship.cardinality"
          render={({ field, fieldState: { error } }) => (
            <Select
              width="100%"
              isInvalid={Boolean(error)}
              options={cardinalityOptions}
              value={field.value}
              onChange={(value) => {
                if (!value) return;

                if (!canColumnsBeMerged(value)) {
                  props.setValue("relationship.isMergingIntoFrom", false);
                }
                field.onChange(value);
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="relationship.to"
          render={({ field, fieldState: { error } }) => (
            <Column width="100%">
              {props.toModel ? (
                <TextInput
                  width="100%"
                  isReadOnly
                  value={toModel?.name ?? ""}
                  placeholder="Name your model..."
                />
              ) : (
                <Combobox
                  width="100%"
                  isInvalid={Boolean(error)}
                  placeholder="Select a model..."
                  options={toModels ?? []}
                  value={field.value}
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                  optionLabel={(option) => option.name}
                  optionValue={(option) => String(option.id)}
                />
              )}
              <FieldError error={error?.message} />
            </Column>
          )}
        />
      </Box>

      <Mapper
        control={control}
        watch={watch}
        errors={errors}
        toModel={toModel}
        fromModel={fromModel}
      />
      <Controller
        name="relationship.isMergingIntoFrom"
        control={control}
        render={({ field }) => {
          if (isMergable) {
            return (
              <Column gap={4}>
                <SectionHeading>Merge columns</SectionHeading>
                <Row gap={2} align="center">
                  <Switch isChecked={field.value} onChange={field.onChange} />
                  <Text fontWeight="medium">
                    Merge {toModel?.name} columns into {fromModel.name}
                  </Text>
                </Row>
              </Column>
            );
          }
          return <></>;
        }}
      />
    </Column>
  );
};

const Mapper: FC<
  Readonly<{
    errors: FieldErrors<FormValues>;
    control: Control<FormValues>;
    watch: UseFormWatch<FormValues>;
    toModel: { name: string; columns: Column[] } | undefined;
    fromModel: { name: string; columns: Column[] };
  }>
> = ({ control, watch, errors, toModel, fromModel }) => {
  const [multipleJoinKeys, setMultipleJoinKeys] = useState(false);
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "relationship.joinMappings",
  });

  const currentJoinMappings = watch("relationship.joinMappings");

  return (
    <Column gap={4}>
      {fields.map((field, index) => {
        const isMissingMapping = Boolean(
          errors?.relationship?.joinMappings?.message,
        );
        const mappingError = errors?.relationship?.joinMappings?.[index];
        const isRemovable =
          multipleJoinKeys && fields.length > 2 && index !== fields.length - 1;
        const otherJoinMappings = currentJoinMappings.filter(
          (_, i) => i !== index,
        );
        const fromOptions = fromModel.columns.filter(
          (c) => !otherJoinMappings.some((j) => j.from === c.name),
        );
        const toOptions =
          toModel?.columns.filter(
            (c) => !otherJoinMappings.some((j) => j.to === c.name),
          ) ?? [];
        return (
          <Column key={field.id}>
            <Row align="flex-start" gap={2} flex={1}>
              <Controller
                control={control}
                name={`relationship.joinMappings.${index}.from`}
                render={({ field, fieldState: { error } }) => (
                  <Column flex={1}>
                    <Combobox
                      width="100%"
                      isInvalid={Boolean(
                        error || mappingError || isMissingMapping,
                      )}
                      placeholder={`Select a column from ${fromModel.name}...`}
                      value={field.value}
                      onChange={(value) => {
                        if (multipleJoinKeys && index === fields.length - 1) {
                          append({ from: "", to: "" });
                        }
                        field.onChange(value);
                      }}
                      options={fromOptions}
                      optionLabel={(column) => column.alias || column.name}
                      optionValue={(column) => column.name}
                    />
                    <FieldError error={error?.message} />
                  </Column>
                )}
              />
              <Row
                height="32px"
                align="center"
                color="text.secondary"
                fontSize="24px"
              >
                <BidirectionalArrowIcon />
              </Row>
              <Controller
                control={control}
                name={`relationship.joinMappings.${index}.to`}
                render={({ field, fieldState: { error } }) => (
                  <Column flex={1}>
                    <Combobox
                      width="100%"
                      isInvalid={Boolean(
                        error || mappingError || isMissingMapping,
                      )}
                      placeholder={
                        toModel?.name
                          ? `Select a column from ${toModel.name}...`
                          : "Select a column..."
                      }
                      isDisabled={!toModel?.name}
                      value={field.value}
                      onChange={(value) => {
                        if (multipleJoinKeys && index === fields.length - 1) {
                          append({ from: "", to: "" });
                        }
                        field.onChange(value);
                      }}
                      options={toOptions}
                      optionLabel={(column) => column.alias || column.name}
                      optionValue={(column) => column.name}
                    />
                    <FieldError error={error?.message} />
                  </Column>
                )}
              />
              <Row
                pointerEvents={isRemovable ? "auto" : "none"}
                visibility={isRemovable ? "visible" : "hidden"}
                display={
                  multipleJoinKeys && currentJoinMappings.length > 2
                    ? "flex"
                    : "none"
                }
              >
                <IconButton
                  aria-label="Remove join key"
                  icon={CloseIcon}
                  onClick={() => remove(index)}
                />
              </Row>
            </Row>
            <FieldError error={mappingError?.message} />
          </Column>
        );
      })}
      <FieldError error={errors?.relationship?.joinMappings?.message} />

      <Row align="center" gap={2}>
        <Switch
          isChecked={multipleJoinKeys}
          onChange={(value) => {
            if (value) {
              setMultipleJoinKeys(true);
              append({ from: "", to: "" });
            } else {
              setMultipleJoinKeys(false);
              const firstMapping = currentJoinMappings[0];
              if (firstMapping) {
                replace(firstMapping);
              }
            }
          }}
        />
        <Text fontWeight="medium">Multiple join keys</Text>
      </Row>
    </Column>
  );
};

export const relationshipFormSchema = y.object().shape({
  relationship: y.object().shape({
    from: y.string().required("Select a model"),
    to: y.string().required("Select a model"),
    cardinality: y.string().required("Select a cardinality"),
    joinMappings: y
      .array()
      .of(
        y.object().shape({
          to: y.string().required("Select a column"),
          from: y.string().required("Select a column"),
        }),
      )
      .required("Add a mapping between your models"),
    isMergingIntoFrom: y.boolean(),
    isMergingIntoTo: y.boolean(),
  }),
});

export const getDefaultRelationship = (
  id: string,
): Omit<Relationship, "slug" | "edgeId" | "reverseEdgeId"> => ({
  from: id,
  to: "",
  cardinality: "one-to-many",
  joinMappings: [{ from: "", to: "" }],
  isMergingIntoFrom: false,
  isMergingIntoTo: false,
  fromName: null,
  toName: null,
});

export const validationResolver =
  (schema: y.ObjectSchema) => async (data, context, options) => {
    const filteredData = data.relationship
      ? {
          ...data,
          relationship: {
            ...data.relationship,
            joinMappings: data.relationship.joinMappings.filter(
              (j) => j.from && j.to,
            ),
          },
        }
      : data;
    const joinMappingErrors = {};
    if (data.relationship) {
      const toModel =
        context.toModel ??
        context.models?.find(
          (m) => String(m.id) === String(data.relationship.to),
        );
      const fromModel = context.models?.find(
        (m) => String(m.id) === String(data.relationship.from),
      );
      for (const [
        nestedIndex,
        mapping,
      ] of filteredData.relationship.joinMappings.entries()) {
        const fromColumn = fromModel?.columns.find(
          (c) => c.name === mapping.from,
        );
        const toColumn = toModel?.columns.find((c) => c.name === mapping.to);
        if (fromColumn && toColumn) {
          const fromType = fromColumn?.custom_type ?? fromColumn?.type;
          const toType = toColumn?.custom_type ?? toColumn?.type;
          if (fromType !== toType) {
            set(joinMappingErrors, `relationship.joinMappings.${nestedIndex}`, {
              message: `Types must match between columns. Column ${fromColumn.name} is a ${fromType} whereas ${toColumn.name} is a ${toType}.`,
            });
          }
        }
      }
    }
    const { values, errors } = await yupResolver(schema)(
      filteredData,
      context,
      options,
    );

    return {
      values: Object.keys(joinMappingErrors).length > 0 ? {} : values,
      errors: merge(errors, joinMappingErrors),
    };
  };
