import { useNavigate, useParams } from "src/router";

import { useHightouchFormContext } from "src/components/form";
import { DeprecatedWizard } from "src/components/wizard";
import { useCreateSyncWizard } from "src/pages/syncs/create/use-create-sync-wizard";
import { useGraphContext } from "src/pages/journeys/graph";

export const CreateJourneySyncWizard = () => {
  const { node_id } = useParams<{
    node_id: string;
  }>();
  const navigate = useNavigate();

  const { onAddSyncConfigToNode } = useGraphContext();
  const { submit } = useHightouchFormContext();

  const { createSync, step, setStep, steps } = useCreateSyncWizard({
    onSubmit: async ({ id, journeySyncConfig }) => {
      if (node_id && id) {
        onAddSyncConfigToNode(node_id, {
          destination_instance_id: Number(id),
          mode: journeySyncConfig.mode,
          exit_config: journeySyncConfig.exit_config,
        });

        // Save the journey immediately.
        await submit();
      }
    },
  });

  return (
    <DeprecatedWizard
      showOverlay={false}
      fullscreen={false}
      setStep={setStep}
      step={step}
      steps={steps}
      title="New sync"
      onCancel={() => {
        navigate(`../${node_id}`);
      }}
      onSubmit={async () => {
        await createSync();
      }}
    />
  );
};
