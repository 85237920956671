import { ReactElement, useEffect } from "react";

import {
  FormField,
  IconButton,
  Select,
  TextInput,
  Row,
  RefreshIcon,
  Combobox,
  NumberInput,
} from "@hightouchio/ui";
import * as Yup from "yup";

import {
  useTableauListViewsQuery,
  useTableauListWorkbooksQuery,
} from "src/graphql";

import {
  CustomQuery,
  CustomQueryFormProps,
  CustomQueryViewProps,
} from "./custom-query";

function QueryForm({
  source,
  query,
  onChange,
}: Readonly<CustomQueryFormProps>): ReactElement<any, any> {
  if (query?.type && query?.type !== "view") {
    return <>{`Query type ${query?.type} not valid for Tableau source`}</>;
  }

  useEffect(() => {
    if (!query?.type) {
      onChange({ ...query, type: "view" });
    }
  }, [query]);

  const {
    data: workbooksData,
    isLoading: workbooksLoading,
    refetch: refetchWorkbooks,
    error: workbooksError,
  } = useTableauListWorkbooksQuery({
    connectionId: String(source.id),
  });
  const workbooks = workbooksData?.tableauListWorkbooks || [];

  const {
    data: viewsData,
    isLoading: viewsLoading,
    refetch: refetchViews,
    error: viewsError,
  } = useTableauListViewsQuery(
    {
      connectionId: String(source.id),
      workbookId: String(query?.workbookId),
    },
    { enabled: Boolean(query?.workbookId) },
  );
  const views = viewsData?.tableauListViews || [];

  return (
    <>
      <FormField error={workbooksError?.message} label="Workbook">
        <Row gap={2}>
          <Combobox
            isLoading={workbooksLoading}
            options={workbooks ?? []}
            placeholder="Select a workbook..."
            value={query?.workbookId}
            onChange={(workbookId) =>
              onChange({
                ...query,
                workbookId,
              })
            }
          />
          <IconButton
            aria-label="Refresh workbooks"
            icon={RefreshIcon}
            isDisabled={workbooksLoading}
            variant="secondary"
            onClick={() => refetchWorkbooks()}
          />
        </Row>
      </FormField>
      {query?.workbookId && (
        <FormField error={viewsError?.message} label="View">
          <Row gap={2}>
            <Combobox
              isLoading={viewsLoading}
              options={views}
              placeholder="Select a view..."
              value={query?.viewId}
              onChange={(viewId) =>
                onChange({
                  ...query,
                  viewId,
                })
              }
            />
            <IconButton
              aria-label="Refresh views"
              icon={RefreshIcon}
              isDisabled={viewsLoading}
              variant="secondary"
              onClick={() => refetchViews()}
            />
          </Row>
        </FormField>
      )}
      {query?.workbookId && query?.viewId && (
        <FormField
          isOptional
          description={`The maximum number of minutes view data will be cached
          before being refreshed. By default, Hightouch uses the minimum value
          of 1 minute to ensure the freshest data possible.`}
          label="Max Data Age in minutes"
        >
          <NumberInput
            placeholder="1"
            min={1}
            value={(query?.maxAgeMinutes as number) ?? null}
            onChange={(maxAgeMinutes) =>
              onChange({
                ...query,
                maxAgeMinutes,
              })
            }
          />
        </FormField>
      )}
    </>
  );
}

function QueryView(
  props: Readonly<CustomQueryViewProps>,
): ReactElement<any, any> {
  const {
    data: workbooksData,
    isLoading: workbooksLoading,
    error: workbooksError,
  } = useTableauListWorkbooksQuery({
    connectionId: String(props.source.id),
  });
  const workbooks = workbooksData?.tableauListWorkbooks || [];

  const {
    data: viewsData,
    isLoading: viewsLoading,
    error: viewsError,
  } = useTableauListViewsQuery(
    {
      connectionId: String(props.source.id),
      workbookId: String(props.query.workbookId),
    },
    { enabled: Boolean(props.query.workbookId) },
  );
  const views = viewsData?.tableauListViews || [];

  return (
    <>
      <FormField error={workbooksError?.message} label="Workbook">
        <Select
          isDisabled
          isLoading={workbooksLoading}
          options={workbooks}
          value={props.query.workbookId ?? ""}
          onChange={() => null}
        />
      </FormField>
      <FormField label="View" error={viewsError?.message}>
        <Select
          isDisabled
          isLoading={viewsLoading}
          options={views}
          value={props.query.viewId ?? ""}
          onChange={() => null}
        />
      </FormField>
      <FormField label="Max Data Age in minutes">
        <TextInput
          isReadOnly
          placeholder="1"
          type="number"
          value={String(props.query.maxAgeMinutes)}
        />
      </FormField>
    </>
  );
}

const querySchema = Yup.lazy<CustomQuery | undefined>((_) => {
  return Yup.object().shape({
    type: Yup.string().required(),
    workbookId: Yup.string().required(),
    viewId: Yup.string().required(),
  });
});

export default { QueryForm, QueryView, querySchema };
