export function swap<T>(arr: T[], index1: number, index2: number): T[] {
  const newArr = [...arr];
  const temp = newArr[index1]!;
  newArr[index1] = newArr[index2]!;
  newArr[index2] = temp;
  return newArr;
}

export function getUniqueObjectsByKey<Collection>(
  collection: Collection[],
  key: string = "id",
) {
  const seen = new Set();

  return collection.filter((item) => {
    const duplicate = seen.has(item[key]);
    seen.add(item[key]);
    return !duplicate;
  });
}
