import { Link } from "@tiptap/extension-link";

export const SlackLink = Link.extend({
  addOptions() {
    return {
      autolink: true,
      linkOnPaste: true,
      HTMLAttributes: {
        rel: "noopener noreferrer",
        // Open a separate tab.
        target: null,
        class: "rte-link-class",
      },
      protocols: [],
      openOnClick: false,
    };
  },

  // TODO add commands
});
