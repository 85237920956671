import { EmptyState, CheckboxGroup, Checkbox } from "@hightouchio/ui";
import { Controller } from "react-hook-form";
import { useOutletContext } from "src/router";
import { OutletContext } from "src/pages/decision-engines";

export const CollectionSelect = () => {
  const { engine } = useOutletContext<OutletContext>();

  if (!engine.collections?.length) {
    return (
      <EmptyState
        title="No collections"
        message="Add a catalog collection to start using item recommendations in the decision engine."
      />
    );
  }

  return (
    <CheckboxGroup>
      {engine.collections.map((collection) => (
        <Controller
          key={collection.id}
          name={`collections.${collection.id}.enabled`}
          render={({ field }) => (
            <Checkbox
              label={collection.collection.name}
              isChecked={field.value}
              onChange={field.onChange}
            />
          )}
        />
      ))}
    </CheckboxGroup>
  );
};
