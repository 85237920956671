import { FC } from "react";

import {
  Column,
  FormField,
  Radio,
  RadioGroup,
  Row,
  SectionHeading,
} from "@hightouchio/ui";
import { Controller, useFormContext } from "react-hook-form";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { MapperField, OrderByField } from "src/pages/identity-resolution/forms";
import { ModelDetails, ModelState } from "src/pages/identity-resolution/types";

type ModelConfigv1Props = {
  identifierOptions: string[];
  model: ModelDetails;
};

export const ModelConfigv1: FC<ModelConfigv1Props> = ({
  identifierOptions,
  model,
}) => {
  const { watch } = useFormContext<ModelState>();
  const type = watch("type");

  return (
    <Column gap={8} maxW="576px" width="100%">
      <Column gap={4}>
        <Row borderRadius="md" gap={3}>
          <IntegrationIcon
            src={model.connection?.definition.icon}
            name={model.connection?.definition.name}
          />
          <SectionHeading>{model.name}</SectionHeading>
        </Row>
      </Column>
      <FormField label="What do records in this model represent?">
        <Controller
          name="type"
          render={({ field }) => (
            <RadioGroup orientation="vertical" {...field}>
              <Radio
                label="Profiles"
                value="profile"
                description="e.g. users, contacts, accounts"
              />
              <Radio
                label="Events"
                value="event"
                description="e.g. transactions, clicks"
              />
            </RadioGroup>
          )}
        />
      </FormField>
      {type === "event" ? <OrderByField columns={model.columns} /> : null}
      <MapperField
        columns={model.columns}
        identifierOptions={identifierOptions}
      />
    </Column>
  );
};
