import { FC } from "react";

import { ConfirmationDialog, Paragraph } from "@hightouchio/ui";
import pluralize from "pluralize";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  count: number;
  label: string;
  onDelete: () => Promise<void>;
  disabled?: boolean;
  content?: React.ReactNode;
};

export const BulkDeleteConfirmationModal: FC<Readonly<Props>> = ({
  isOpen,
  onClose,
  count,
  label,
  onDelete,
  disabled,
  content,
}) => {
  const pluralizedLabel = pluralize(label, count);

  const handleBulkDelete = async () => {
    await onDelete();
    onClose();
  };

  return (
    <ConfirmationDialog
      confirmButtonText="Delete"
      isConfirmButtonDisabled={disabled}
      isOpen={isOpen}
      title={
        disabled
          ? `Unable to delete ${count} ${pluralizedLabel}`
          : `Delete ${count} ${pluralizedLabel}`
      }
      variant="danger"
      onClose={onClose}
      onConfirm={handleBulkDelete}
    >
      {content ? (
        content
      ) : (
        <Paragraph>
          Are you sure you want to delete {count} {pluralizedLabel}? You will
          not be able to undo this.
        </Paragraph>
      )}
    </ConfirmationDialog>
  );
};
