import { FC } from "react";

import {
  Box,
  Column,
  Heading,
  Paragraph,
  Row,
  SectionHeading,
  Text,
} from "@hightouchio/ui";
import { Link } from "src/router";
import { EventDefinition } from "../../types";
import { useParams } from "src/router";

type Props = {
  name: string;
  events: Array<Omit<EventDefinition, "fields" | "description">>;
  customEvent: string | undefined;
};

export const AllEvents: FC<Readonly<Props>> = ({
  name,
  events,
  customEvent,
}) => {
  const { id } = useParams<{ id: string }>();

  return (
    <Column p={6}>
      <Column mb={6} gap={4}>
        <Heading>Select an event type to forward</Heading>
        <Row gap={4}>
          <Column gap={4} flex={1}>
            <Column>
              <SectionHeading>Standard events</SectionHeading>
              <Text color="text.secondary">
                These are supported standard events for {name}.
              </Text>
              <Text color="text.secondary">
                Select and configure applicable matching events
              </Text>
            </Column>

            <Box
              as="ul"
              listStyleType="none"
              pl={0}
              display="flex"
              flexDirection="column"
              gap={4}
            >
              {events.map(({ title, name }) => (
                <Box as="li" key={title}>
                  <Link
                    href={`/events/syncs/${id}/configuration/event/${name}`}
                  >
                    {title}
                  </Link>
                </Box>
              ))}
            </Box>
          </Column>

          {customEvent && (
            <Column gap={4} flex={1}>
              <Column>
                <SectionHeading>Custom events</SectionHeading>
                <Paragraph color="text.secondary">
                  Any remaining events not mapped to standard events can be
                  processed through custom events.
                </Paragraph>
              </Column>
              <Box
                as="ul"
                listStyleType="none"
                pl={0}
                display="flex"
                flexDirection="column"
                gap={4}
              >
                <Box as="li">
                  <Link
                    href={`/events/syncs/${id}/configuration/event/${customEvent}`}
                  >
                    Configure custom events
                  </Link>
                </Box>
              </Box>
            </Column>
          )}
        </Row>
      </Column>
    </Column>
  );
};
