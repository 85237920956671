import { FC, useState } from "react";

import { Box, UpsellButton } from "@hightouchio/ui";

import { LinkButton } from "src/router";

import bottomRightGradient from "src/assets/backgrounds/bottom-right-green-gradient.svg";
import topLeftGradient from "src/assets/backgrounds/top-left-green-gradient.svg";
import * as analytics from "src/lib/analytics";
import { AudienceIcon } from "src/ui/icons";

import { UpsellModal } from "../feature-gates/modal";
import { SplashPage } from "../splash-page";

export const AudiencesDemo: FC = () => {
  const [showUpsellModal, setShowUpsellModal] = useState(false);

  const trackLearnMoreClick = () => {
    analytics.track("Customer Studio Learn More Clicked");
  };

  return (
    <>
      <SplashPage
        icon={<AudienceIcon />}
        eyebrow="Customer Studio"
        heading="Give any marketer audience building super powers"
        description="Explore and activate customer data in minutes with Customer Studio, a suite of powerful no-code features built on top of your cloud data warehouse."
        actions={
          <>
            <UpsellButton onClick={() => setShowUpsellModal(true)}>
              Unlock Customer Studio
            </UpsellButton>

            <LinkButton
              href="https://hightouch.com/platform/customer-studio"
              onClick={trackLearnMoreClick}
            >
              Learn more
            </LinkButton>
          </>
        }
        visual={
          <Box
            as="video"
            autoPlay
            muted
            playsInline
            src="https://cdn.sanity.io/files/pwmfmi47/production/fcffe6d5459959f713e1374524a7c7908c5a53f0.mp4"
            maxHeight="100%"
            width="100%"
          />
        }
        backgroundGradient={
          <>
            <Box
              as="img"
              position="absolute"
              top={0}
              left={0}
              src={topLeftGradient}
            />
            <Box
              as="img"
              position="absolute"
              bottom={0}
              right={0}
              src={bottomRightGradient}
            />
          </>
        }
        logoHeader="Customer Studio is used by"
        customerLogos={[
          {
            name: "GameStop",
            logo: "https://cdn.sanity.io/images/pwmfmi47/production/ae0dee359e218bad5b70b1a91eff75208c7fe25b-3840x816.png",
          },
          {
            name: "Ramp",
            logo: "https://cdn.sanity.io/images/pwmfmi47/production/acaf90882a1ded8a3796cc3972219355a42d009c-270x72.svg",
          },
          {
            name: "Blizzard",
            logo: "https://cdn.sanity.io/images/pwmfmi47/production/47fed26595e04c1d861df53aaf1ef9d6a4ec95f8-1200x625.png",
          },
          {
            name: "Grammarly",
            logo: "https://cdn.sanity.io/images/pwmfmi47/production/debeecfa1c62c364743cb39c9243c77f54cb7931-566x143.png",
          },
          {
            name: "Weight Watchers",
            logo: "https://cdn.sanity.io/images/pwmfmi47/production/1c993a34a3ad1221ddd64f839ff62135fab76089-469x125.png",
          },
          {
            name: "AXS",
            logo: "https://cdn.sanity.io/images/pwmfmi47/production/81e5b8930a5329c96f573e0bdc0f5b596ce7746e-359x155.svg",
          },
          {
            name: "Tripadvisor",
            logo: "https://cdn.sanity.io/images/pwmfmi47/production/edd01e0e1e00136d6d50d8fa46ec6d5fd4a77165-3354x713.svg",
          },
        ]}
      />
      <UpsellModal
        featureName="Customer Studio"
        isOpen={showUpsellModal}
        onClose={() => {
          setShowUpsellModal(false);
        }}
      />
    </>
  );
};
