import { yupResolver } from "@hookform/resolvers/yup";
import Ajv, { ErrorObject } from "ajv";
import { SomeJSONSchema } from "ajv/dist/types/json-schema";
import JSON5 from "json5";
import filter from "lodash/filter";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import * as Yup from "yup";

import { ContractProperty, EventSchemaFormState } from "./types";
import { EventSchemaEventType } from "src/graphql";

export const createEventPath = ({
  eventName,
  eventType,
  eventVersion,
}: {
  eventName?: string | null;
  eventType: string;
  eventVersion: string;
}) => {
  return (
    (eventName ? `${eventType}-${eventName}` : eventType) + `/${eventVersion}`
  );
};

export const deconstructEventPath = (eventPath: string | undefined) => {
  if (!eventPath) return { eventType: null, eventName: null };

  const [eventType, eventName] = eventPath.split(/-(.*)/s);

  return { eventType: eventType, eventName };
};

export const ajvValidate = (data: SomeJSONSchema) => {
  const ajv = new Ajv();
  const valid = ajv.validateSchema(data);

  if (!valid) {
    throw ajv.errors;
  }
};

export const defaultSchema: SomeJSONSchema = {
  type: "object",
  required: [],
  properties: {
    "": {
      type: "string",
    },
  },
};

export const getDefaultProperty = (): ContractProperty => ({
  name: "",
  type: "string",
  required: false,
  properties: [],
});

declare module "yup" {
  interface NotRequiredArraySchema<T> {
    uniqueProperty(propertyPath: string, errorMessage: string): ArraySchema<T>;
  }
}

Yup.addMethod(
  Yup.array,
  "uniqueProperty",
  function (this: any, propertyPath: string, errorMessage: string) {
    return this.test(
      "unique",
      "",
      function (this: any, list: Record<string, unknown>[]) {
        const errors: any = [];

        list.forEach((item: Record<string, unknown>, index: number) => {
          const propertyValue = get(item, propertyPath);

          if (
            propertyValue &&
            filter(list, [propertyPath, propertyValue]).length > 1
          ) {
            errors.push(
              this.createError({
                path: `${this.path}[${index}].${propertyPath}`,
                message: errorMessage,
              }),
            );
          }
        });

        if (!isEmpty(errors)) {
          throw new Yup.ValidationError(errors, list, this.path, this.type);
        }

        return true;
      },
    );
  },
);

const propertySchema = Yup.array()
  .of(
    Yup.lazy((value: any) => {
      if (value.type === "array") {
        // The child element of Arrays do not have a name.
        return Yup.object().shape({
          name: Yup.string().nullable().required("Name is required"),
          properties: Yup.object()
            .shape({
              name: Yup.string().oneOf([""], "Name must be an empty string"),
              properties: propertySchema.nullable(),
            })
            .nullable(),
        });
      }

      return Yup.object().shape({
        name: Yup.string().nullable().required("Name is required"),
        properties: propertySchema.nullable(),
      });
    }),
  )
  .uniqueProperty("name", "Property name must be unique");

const nameTypeValidator = Yup.string().when("eventType", {
  is: EventSchemaEventType.Track,
  then: Yup.string().nullable().required("Track events must have a name"),
  otherwise: Yup.string().nullable(),
});

const simpleContractFormSchema = Yup.object({
  name: nameTypeValidator,
  editorState: Yup.object().shape({
    properties: propertySchema.min(1, "At least one property is required"),
  }),
});

const jsonEditorSchema = Yup.object({
  name: nameTypeValidator,
  editorState: Yup.object().shape({
    json: Yup.string()
      .required("JSON cannot be empty")
      .test({
        test: (value) => {
          try {
            const parsed = JSON5.parse(value);
            ajvValidate(parsed);

            return true;
          } catch (error) {
            let message = error.message || "";

            if (Array.isArray(error)) {
              const ajvErrors = error as ErrorObject[];
              const firstError = ajvErrors?.[0] as ErrorObject;

              message = `Invalid JSON schema: "${firstError.dataPath}" ${firstError.message}`;
            }

            return new Yup.ValidationError(message, value, "editorState.json");
          }
        },
      }),
  }),
});

export const validationResolver = async (
  data: EventSchemaFormState,
  context,
  options,
) => {
  if (!data.editorState.isJson) {
    const result = await yupResolver(simpleContractFormSchema)(
      data,
      context,
      options,
    );
    const values = {
      ...result.values,
      editorState: {
        ...data.editorState,
        // Strangely the yup resolver is returning `null` for `properties`
        properties: data.editorState.properties,
      },
    } as EventSchemaFormState;

    return {
      values,
      errors: result.errors,
    };
  } else {
    return await yupResolver(jsonEditorSchema)(data, context, options);
  }
};
