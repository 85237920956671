import { FC, Fragment, useEffect, useState } from "react";

import {
  Box,
  Button,
  Column,
  DeleteIcon,
  DescriptionAddIcon,
  IconButton,
  InformationIcon,
  Menu,
  MenuActionsButton,
  MenuItem,
  MenuList,
  PlusIcon,
  Row,
  SectionHeading,
  Select,
  Switch,
  Text,
  Tooltip,
} from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import isEqual from "lodash/isEqual";
import noop from "lodash/noop";
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { v4 as uuidv4 } from "uuid";

import { colors } from "src/components/explore/visual/colors";
import { AndOrToggleButton } from "src/components/explore/visual/condition-buttons";
import { ErrorMessage } from "src/components/explore/visual/error-message";
import { GroupIndicatorBar } from "src/components/explore/visual/group-indicator-bar";
import { MergeRulesReorder } from "src/components/reorder";
import {
  operatorOptions,
  RulesFormStatev1,
  SupportedSource,
} from "src/pages/identity-resolution/types";
import {
  defaultOuterType,
  getDefaultMergeRule,
  getDefaultMergeRuleSet,
} from "src/pages/identity-resolution/utils";
import { BucketingConfig, FUZZY_RULE_OPERATORS } from "src/types/idr";
import { ordinalSuffix } from "src/utils/numbers";

import { useRulesFormContext } from "./rules-form-context";
import { TransformationOptions } from "./transformation-options";

export const MergeRules: FC = () => {
  const { enableFuzzySearchingIdr } = useFlags();
  const { watch, setValue } = useFormContext<RulesFormStatev1>();
  const { append, remove } = useFieldArray<RulesFormStatev1>({
    name: "merge_rules",
  });
  const mergeRuleSets = watch("merge_rules");

  const hasAnyFuzzyRules =
    enableFuzzySearchingIdr &&
    mergeRuleSets.some((set) =>
      set.conditions.some((c) =>
        c.rules.some((r) => FUZZY_RULE_OPERATORS.includes(r.operator)),
      ),
    );

  const hasAnyFastFuzzyRules =
    enableFuzzySearchingIdr &&
    mergeRuleSets.some((set) =>
      set.conditions.some((c) => c.rules.some((r) => Boolean(r.bucketing))),
    );

  const [isFastFuzzyMatchingEnabled, setIsFastFuzzyMatchingEnabled] =
    useState<boolean>(
      enableFuzzySearchingIdr && (!hasAnyFuzzyRules || hasAnyFastFuzzyRules),
    );

  useEffect(() => {
    if (enableFuzzySearchingIdr && !hasAnyFuzzyRules) {
      // Reset the default if the user removes all fuzzy rules (this is what
      // will happen on page refresh anyway, so match that).
      setIsFastFuzzyMatchingEnabled(true);
    }
  }, [enableFuzzySearchingIdr, hasAnyFuzzyRules]);

  // Make sure the form state's fuzzy match settings are what they should be.
  // Only makes state changes if the form's state is not as it should be, so
  // should at most trigger one additional re-render.
  // Note that 'newValue' is not an optional argument and should never be
  // omitted - only use undefined as a value here intentionally.
  const ensureFuzzyMatchSettings = (newValue: BucketingConfig | undefined) => {
    mergeRuleSets.map((set, set_index) => {
      set.conditions.map((condition, condition_index) => {
        condition.rules.map((rule, rule_index) => {
          if (
            enableFuzzySearchingIdr &&
            FUZZY_RULE_OPERATORS.includes(rule.operator)
          ) {
            if (!isEqual(rule.bucketing, newValue)) {
              setValue(
                `merge_rules.${set_index}.conditions.${condition_index}.rules.${rule_index}.bucketing`,
                newValue,
                {
                  shouldDirty: true,
                },
              );
            }
          } else {
            if (rule.bucketing != undefined) {
              setValue(
                `merge_rules.${set_index}.conditions.${condition_index}.rules.${rule_index}.bucketing`,
                undefined,
                {
                  shouldDirty: true,
                },
              );
            }
          }
        });
      });
    });
  };

  // Do this on every re-render (theoretically tied to changes in the form
  // state) to intercept changes to operators within the editor, and make sure
  // their fuzzy match settings match the graph's current ones. Note that this
  // is also how the toggle button takes effect (see toggleFastFuzzyMatching).
  const currentFuzzySettings: BucketingConfig | undefined =
    enableFuzzySearchingIdr && isFastFuzzyMatchingEnabled
      ? {
          scheme: "first-n-chars",
          config: {
            numChars: 3,
          },
        }
      : undefined;
  ensureFuzzyMatchSettings(currentFuzzySettings);

  // This will trigger the above code to make the form state consistent with the
  // new value for the switch.
  const toggleFastFuzzyMatching = () => {
    setIsFastFuzzyMatchingEnabled((oldValue) => !oldValue);
  };

  // Ensure that all rule sets have an identifier before rendering the reorder group.
  // This backfills any existing merge rules that were created before the waterfalls feature.
  useEffect(() => {
    mergeRuleSets.forEach((ruleSet, index) => {
      if (!ruleSet.identifier) {
        const newIdentifier = uuidv4();
        ruleSet.identifier = newIdentifier;
        setValue(`merge_rules.${index}.identifier`, newIdentifier);
      }
    });
  }, [mergeRuleSets]);

  return (
    <Column align="flex-start" gap={4}>
      <Column>
        <SectionHeading>Merge records when:</SectionHeading>
        <Text>
          Define what identifiers to use to merge profiles and associate events
          with each profile.
        </Text>
      </Column>

      <Column w="100%" gap={4}>
        <MergeRulesReorder
          items={mergeRuleSets}
          onChange={(newRules) => {
            setValue("merge_rules", newRules, { shouldDirty: true });
          }}
        >
          {mergeRuleSets.map((set, index) => (
            <MergeRuleSet
              key={set.identifier}
              mergeRuleIndex={index}
              removeSet={() => {
                remove(index);
              }}
            />
          ))}
        </MergeRulesReorder>
      </Column>

      <Button icon={PlusIcon} onClick={() => append(getDefaultMergeRuleSet())}>
        Rule set
      </Button>

      {hasAnyFuzzyRules && (
        <Row gap={3} align="center">
          <Switch
            isChecked={enableFuzzySearchingIdr && isFastFuzzyMatchingEnabled}
            onChange={enableFuzzySearchingIdr ? toggleFastFuzzyMatching : noop}
          />
          <Text>
            Optimize non-exact matching
            <Tooltip message="Significantly improve runtime by grouping records before applying non-exact matching rules. May reduce match rate.">
              <Text ml={1} size="lg" color="text.secondary">
                <InformationIcon />
              </Text>
            </Tooltip>
          </Text>
        </Row>
      )}
    </Column>
  );
};

const MergeRuleSet: FC<
  Readonly<{
    mergeRuleIndex: number;
    removeSet: () => void;
  }>
> = ({ mergeRuleIndex, removeSet }) => {
  const { watch } = useFormContext<RulesFormStatev1>();
  const { fields, append, remove } = useFieldArray<RulesFormStatev1>({
    name: `merge_rules.${mergeRuleIndex}.conditions`,
  });
  const outerType = watch(`merge_rules.${mergeRuleIndex}.type`);
  const innerType = outerType === "and" ? "or" : "and";

  const mergeRulesLength = watch("merge_rules").length;
  const conditions = watch(`merge_rules.${mergeRuleIndex}.conditions`);

  return (
    <Column gap={4} w="100%">
      <Column gap={4}>
        <Text fontWeight="medium">
          {mergeRuleIndex + 1}
          {ordinalSuffix(mergeRuleIndex + 1)} set
        </Text>

        {fields.map((field, index) => {
          return (
            <MergeRuleGroup
              key={field.id}
              index={index}
              outerType={outerType}
              mergeRuleIndex={mergeRuleIndex}
              removeGroup={() => {
                remove(index);
                if (conditions.length === 1 && mergeRulesLength > 1) {
                  removeSet();
                }
              }}
            />
          );
        })}

        <AddIdentifierButton
          type={fields.length ? outerType : defaultOuterType}
          onClick={() =>
            append({ type: innerType, rules: [getDefaultMergeRule()] })
          }
        />
      </Column>
    </Column>
  );
};

type IdentifierButtonProps = {
  /**
   * The type of the button, which will determine the color of the button
   * @default "and"
   */
  type: "and" | "or";
  onClick: () => void;
};

const AddIdentifierButton: FC<Readonly<IdentifierButtonProps>> = ({
  type,
  onClick,
}) => {
  return (
    <Box
      sx={
        type
          ? {
              button: {
                bg: colors.base[type],
                border: "none",
                _hover: {
                  bg: colors.hover[type],
                },
                _active: {
                  bg: colors.hover[type],
                },
                svg: { color: "text.primary" },
              },
            }
          : {}
      }
    >
      <Button icon={PlusIcon} onClick={onClick}>
        Identifier
      </Button>
    </Box>
  );
};

const MergeRuleGroup: FC<{
  index: number;
  outerType: "and" | "or";
  mergeRuleIndex: number;
  removeGroup: () => void;
}> = ({ index, outerType, mergeRuleIndex, removeGroup }) => {
  const { watch, setValue } = useFormContext<RulesFormStatev1>();
  const conditions = watch(`merge_rules.${mergeRuleIndex}.conditions`);
  const rules = watch(
    `merge_rules.${mergeRuleIndex}.conditions.${index}.rules`,
  );
  const innerType = outerType === "and" ? "or" : "and";
  const { fields, remove, append } = useFieldArray<RulesFormStatev1>({
    name: `merge_rules.${mergeRuleIndex}.conditions.${index}.rules`,
  });
  const nested = fields.length > 1;

  const ungroup = () => {
    const newConditions = conditions.filter((_, i) => i !== index);
    rules.forEach((rule) => {
      newConditions.push({
        type: innerType,
        rules: [rule],
      });
    });
    setValue(`merge_rules.${mergeRuleIndex}.conditions`, newConditions, {
      shouldDirty: true,
    });
  };

  const toggleTypes = () => {
    setValue(`merge_rules.${mergeRuleIndex}.type`, innerType, {
      shouldDirty: true,
    });
    conditions.map((_, i) => {
      setValue(
        `merge_rules.${mergeRuleIndex}.conditions.${i}.type`,
        outerType,
        { shouldDirty: true },
      );
    });
  };

  const ruleElements = fields.map((field, nestedIndex) =>
    nested ? (
      <Fragment key={field.id}>
        <Row>
          <GroupIndicatorBar conditionType={innerType as any} />
          <MergeRule
            name={`merge_rules.${mergeRuleIndex}.conditions.${index}.rules.${nestedIndex}`}
            type={innerType}
            remove={() => {
              if (fields.length === 1) {
                removeGroup();
              } else {
                remove(nestedIndex);
              }
            }}
          />
        </Row>
        <Row>
          <AndOrToggleButton
            conditionType={innerType as any}
            onClick={toggleTypes}
          />
          <Box sx={{ button: { color: "text.secondary" } }}>
            <Button variant="tertiary" onClick={ungroup}>
              Ungroup
            </Button>
          </Box>
        </Row>
        {nestedIndex === fields.length - 1 && (
          <AddIdentifierButton
            type={innerType}
            onClick={() => {
              append(getDefaultMergeRule());
            }}
          />
        )}
      </Fragment>
    ) : (
      <MergeRule
        key={field.id}
        name={`merge_rules.${mergeRuleIndex}.conditions.${index}.rules.${nestedIndex}`}
        type={innerType}
        append={() => append(getDefaultMergeRule())}
        remove={() => {
          if (fields.length === 1) {
            removeGroup();
          } else {
            remove(nestedIndex);
          }
        }}
      />
    ),
  );

  return (
    <Column gap={4} width="100%">
      {fields.length === 1 ? (
        <Row width="100%">
          <GroupIndicatorBar conditionType={outerType as any} />
          {ruleElements}
        </Row>
      ) : (
        <Row gap={4} width="100%">
          <GroupIndicatorBar conditionType={outerType as any} />
          <Column gap={4} width="100%">
            {ruleElements}
          </Column>
        </Row>
      )}

      <AndOrToggleButton
        conditionType={outerType as any}
        onClick={toggleTypes}
      />
    </Column>
  );
};

const MergeRule: FC<
  Readonly<{
    append?: () => void;
    remove: () => void;
    type: string;
    name: string;
  }>
> = ({ append, remove, type, name }) => {
  const { enableFuzzySearchingIdr } = useFlags();
  const identifier = useWatch({ name: `${name}.identifier` });
  const form = useFormContext();

  const operatorName = `${name}.operator`;

  const { identifiers, sourceType } = useRulesFormContext();
  const filteredOperatorOptionsBySource = operatorOptions.filter((operator) => {
    if (!operator.sources) {
      return true;
    }
    return operator.sources.includes(sourceType as unknown as SupportedSource);
  });

  useEffect(() => {
    if (!enableFuzzySearchingIdr) {
      // Auto set the operator
      form.setValue(`${name}.operator`, "eq");
    }
  }, [enableFuzzySearchingIdr]);

  return (
    <Row
      flex={1}
      px={4}
      py={2}
      align="center"
      gap={4}
      bg="white"
      border="1px"
      borderLeft="none"
      borderColor="base.border"
      borderTopRightRadius="md"
      borderBottomRightRadius="md"
    >
      <Row align="center" justify="space-between" gap={4} flex={1}>
        <Row align="baseline" gap={enableFuzzySearchingIdr ? 4 : 2} wrap="wrap">
          <Row align="baseline" gap={enableFuzzySearchingIdr ? 4 : 2}>
            <Controller
              name={`${name}.identifier`}
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <Column>
                  <Select
                    {...field}
                    isInvalid={Boolean(error)}
                    placeholder="Identifier..."
                    width="auto"
                    variant="heavy"
                    options={identifiers}
                    optionValue={(o) => o}
                    optionLabel={(o) => o}
                  />
                  {error?.message && (
                    <ErrorMessage>{error.message}</ErrorMessage>
                  )}
                </Column>
              )}
            />

            <Text color="text.secondary">
              {enableFuzzySearchingIdr ? "is matching" : "matches exactly"}
            </Text>
          </Row>

          {enableFuzzySearchingIdr && (
            <Controller
              name={operatorName}
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <Select
                  {...field}
                  isInvalid={Boolean(error)}
                  // Decreasing width to 4xs or setting width to auto
                  // will cause a react virtual bug and crash the page when clicked
                  // https://carryinternal.slack.com/archives/C05LP5ND10U/p1710345621776769
                  width="3xs"
                  variant="heavy"
                  options={filteredOperatorOptionsBySource}
                />
              )}
            />
          )}

          {identifier && <TransformationOptions path={name} />}
        </Row>

        <Row align="center">
          {append && (
            <Menu>
              <MenuActionsButton />
              <MenuList>
                <Box
                  as={MenuItem}
                  color="text.secondary"
                  icon={DescriptionAddIcon}
                  sx={{
                    svg: {
                      height: "24px",
                      width: "24px",
                    },
                  }}
                  onClick={append}
                >
                  <Column>
                    <Text fontWeight="medium">Add group</Text>
                    <Text color="text.secondary">
                      Create a nested {type} group from this filter
                    </Text>
                  </Column>
                </Box>
              </MenuList>
            </Menu>
          )}
          <Box sx={{ svg: { color: "danger.base" } }}>
            <IconButton
              aria-label="Remove"
              icon={DeleteIcon}
              variant="tertiary"
              onClick={remove}
            />
          </Box>
        </Row>
      </Row>
    </Row>
  );
};
