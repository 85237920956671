export const Icon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20px"
    height="20px"
    viewBox="0 0 24 24"
    fill={color}
  >
    <path
      fillRule="evenodd"
      d="M17.412 4.81a9 9 0 0 0-4.124-1.717l.143-.99a10 10 0 0 1 6.938 15.37l-.837-.548a9 9 0 0 0-2.12-12.116ZM5.41 18.13a9 9 0 0 1-2.156-4.006l-.972.236a10 10 0 0 0 16.25 5.211l-.653-.757a9 9 0 0 1-12.468-.683ZM5.345 5.941a9 9 0 0 1 5.302-2.839l-.15-.988a10 10 0 0 0-8.48 9.314l.997.057a9 9 0 0 1 2.33-5.544Z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M2 13a1 1 0 1 0 2 0 1 1 0 0 0-2 0Zm-1 0a2 2 0 1 0 4 0 2 2 0 0 0-4 0Zm7.066-1.284a3.859 3.859 0 1 0 7.718 0 3.859 3.859 0 0 0-7.718 0Zm-1 0a4.859 4.859 0 1 0 9.718 0 4.859 4.859 0 0 0-9.718 0ZM11 3a1 1 0 1 0 2 0 1 1 0 0 0-2 0Zm-1 0a2 2 0 1 0 4 0 2 2 0 0 0-4 0Zm8 15a1 1 0 1 0 2 0 1 1 0 0 0-2 0Zm-1 0a2 2 0 1 0 4 0 2 2 0 0 0-4 0Z"
      clipRule="evenodd"
    />
  </svg>
);
