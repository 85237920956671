// Based on: https://github.com/aboveyunhai/chakra-dayzed-datepicker/
import React, { useState } from "react";

import { chakra } from "@hightouchio/ui";
import { DateObj, RenderProps } from "dayzed";

import { DatepickerProps } from "../utils/common-types";

interface DayOfMonthProps extends DatepickerProps {
  renderProps: RenderProps;
  isInRange?: boolean | null;
  isFirstSelectedDate?: boolean;
  isLastSelectedDate?: boolean;
  disabledDates?: Set<number>;
  dateObj: DateObj;
  onMouseEnter?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

const selectedStyles = {
  bg: "primary.base",
  color: "white",
};

const startButtonStyles = {
  borderTopLeftRadius: "md",
  borderBottomLeftRadius: "md",
};

const endButtonStyles = {
  borderTopRightRadius: "md",
  borderBottomRightRadius: "md",
};

export const DayOfMonth: React.FC<DayOfMonthProps> = ({
  dateObj,
  isInRange,
  isFirstSelectedDate,
  isLastSelectedDate,
  disabledDates,
  renderProps,
  onMouseEnter,
}) => {
  const [isClicked, setIsClicked] = useState(false);

  const { date, selected, selectable } = dateObj;
  const { getDateProps } = renderProps;
  const disabled = !selectable || disabledDates?.has(date.getTime());

  return (
    <chakra.button
      {...getDateProps({
        dateObj,
        disabled: disabled,
        onMouseEnter: onMouseEnter,
      })}
      disabled={disabled}
      height="32px"
      width="32px"
      _focusWithin={{
        boxShadow: isClicked ? undefined : "outline",
      }}
      _hover={{
        bg: selected ? "forest.700" : "forest.200",
        transitionProperty: "var(--chakra-transition-property-common)",
        transitionDuration: 500,
        color: selected ? "white" : "text.primary",
      }}
      _active={{ bg: selected ? "forest.800" : "forest.300" }}
      _disabled={{
        color: "text.placeholder",
        bg: "transparent",
        cursor: "not-allowed",
      }}
      bg={isInRange ? "forest.200" : "transparent"}
      fontSize="sm"
      fontWeight="medium"
      outline="none !important"
      onMouseDown={() => setIsClicked(true)}
      onBlur={() => setIsClicked(false)}
      {...(selected && selectedStyles)}
      {...(isFirstSelectedDate && startButtonStyles)}
      {...(isLastSelectedDate && endButtonStyles)}
    >
      {date.getDate()}
    </chakra.button>
  );
};
