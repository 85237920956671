import { useState, FC } from "react";

import {
  Row,
  Column,
  Text,
  IconButton,
  CloseIcon,
  FormField,
} from "@hightouchio/ui";
import moment from "moment";

import { TimeSelect } from "src/ui/time-select";
import { WEEK_DAYS } from "src/utils/constants";

import { Schedule, ScheduleExpression, VisualCronSchedule } from "./types";

export const LocalDayToUTCDay = (localTime: string, dayIndex: number) => {
  return Number(
    moment(`${dayIndex} ${localTime}`, "e HH:mm").utc().format("e"),
  );
};

export const UTCDayToLocalDay = (utcTime: string, dayIndex: number) => {
  return Number(
    moment.utc(`${dayIndex} ${utcTime}`, `e HH:mm`).local().format("e"),
  );
};

interface Props {
  index: number;
  isDisabled?: boolean;
  schedule: VisualCronSchedule;
  setSchedule: (schedule: Schedule) => void;
}

export const VisualCronExpression: FC<Props> = ({
  index = 0,
  isDisabled = false,
  setSchedule,
  schedule,
}) => {
  const [cronTime, setCronTime] = useState(
    moment
      .utc(schedule.schedule?.expressions?.[index]?.time, "HH:mm")
      .local()
      .toDate() || new Date(),
  );

  return (
    <Row
      align="start"
      justify="space-between"
      borderTop={index > 0 ? "1px solid" : undefined}
      borderColor="base.border"
      pt={index > 0 ? 4 : 0}
      gap={12}
    >
      <Column gap={8}>
        <FormField label="Repeat at">
          <TimeSelect
            isDisabled={isDisabled}
            value={cronTime}
            onChange={(value) => {
              setSchedule({
                ...schedule,
                schedule: {
                  ...schedule?.schedule,
                  expressions: schedule.schedule?.expressions?.map(
                    (exp, idx) => {
                      if (idx === index) {
                        const newTime = moment(value).utc().format("HH:mm");
                        const localTime = moment(value).format("HH:mm");

                        const days = Object.entries(exp.days ?? {})
                          .filter(([_, value]) => Boolean(value))
                          .map(([key]) =>
                            WEEK_DAYS[
                              LocalDayToUTCDay(
                                localTime,
                                UTCDayToLocalDay(
                                  exp.time,
                                  Number(moment(key, "dddd").format("e")),
                                ),
                              )
                            ]!.toLowerCase(),
                          )
                          .reduce((state, index) => {
                            state[index] = true;
                            return state;
                          }, {});

                        return {
                          days,
                          time: newTime,
                        };
                      }
                      return exp;
                    },
                  ),
                },
              });
              setCronTime(value as Date);
            }}
          />
        </FormField>

        <FormField label="Repeat on">
          <Row gap={2} bg="white">
            {WEEK_DAYS.map((localDay, dayIndex) => {
              const day =
                WEEK_DAYS[
                  LocalDayToUTCDay(moment(cronTime).format("HH:mm"), dayIndex)
                ]!;
              const selected = schedule?.schedule?.expressions?.[index]?.days
                ? Boolean(
                    schedule.schedule?.expressions?.[index]?.days?.[
                      day.toLowerCase()
                    ],
                  )
                : new Date().getDay() === dayIndex;

              return (
                <Row
                  key={localDay}
                  cursor={isDisabled ? "not-allowed" : "pointer"}
                  onClick={() => {
                    if (isDisabled) return;

                    setSchedule({
                      ...schedule,
                      schedule: {
                        ...schedule?.schedule,
                        expressions: schedule.schedule?.expressions?.map(
                          (exp, idx) => {
                            if (idx === index) {
                              const newDays = {
                                ...schedule?.schedule?.expressions?.[index]
                                  ?.days,
                                [day.toLowerCase()]: selected
                                  ? undefined
                                  : true,
                              };

                              return {
                                ...exp,
                                days: newDays,
                              };
                            }
                            return exp;
                          },
                        ),
                      },
                    });
                  }}
                >
                  <Row
                    align="center"
                    justify="center"
                    borderRadius="full"
                    boxSize={6}
                    bg={selected ? "forest.base" : "gray.500"}
                    _hover={{
                      bg: selected || isDisabled ? undefined : " gray.600",
                    }}
                  >
                    <Text size="md" color="white">
                      {localDay.charAt(0)}
                    </Text>
                  </Row>
                </Row>
              );
            })}
          </Row>
        </FormField>
      </Column>
      {index > 0 && (
        <IconButton
          aria-label="Remove recurrence"
          isDisabled={isDisabled}
          icon={CloseIcon}
          onClick={() =>
            setSchedule({
              ...schedule,
              schedule: {
                ...schedule?.schedule,
                expressions: schedule.schedule?.expressions?.reduce<
                  ScheduleExpression[]
                >((arr, expression, idx) => {
                  if (idx === index) {
                    return arr;
                  }

                  return [...arr, expression];
                }, []),
              },
            })
          }
        />
      )}
    </Row>
  );
};
