import { TagIcon } from "@hightouchio/ui";

import { ResourceActivityMapper } from "src/components/resource-activity/timeline";
import { modelActivityMappers } from "src/pages/models/model-activity";

export const parentModelActivityMappers: ResourceActivityMapper[] = [
  ...modelActivityMappers,
  {
    accessor: "visual_query_primary_label",
    parser: (_, { parsedDiff }) => {
      if (parsedDiff.type !== "value") {
        return null;
      }

      return {
        message: `updated the parent model primary label to \`${parsedDiff.value}\``,
        icon: <TagIcon />,
      };
    },
  },
  {
    accessor: "visual_query_secondary_label",
    parser: (_, { parsedDiff }) => {
      if (parsedDiff.type !== "value") {
        return null;
      }

      return {
        message: `updated the parent model primary label to \`${parsedDiff.value}\``,
        icon: <TagIcon />,
      };
    },
  },
];
