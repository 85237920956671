import type { BaseType, Props, ValidatorSync } from "./base";
import * as yup from "yup";
import * as d3 from "d3-time-format";

export interface DateTimeType extends BaseType {
  type: "DATETIME";
  /**
   * https://strftime.org/
   */
  format?: string;
  /**
   * Inclusive earliest datetime.
   * https://en.wikipedia.org/wiki/ISO_8601 format
   */
  min?: string;
  /**
   * Inclusive latest datetime.
   * https://en.wikipedia.org/wiki/ISO_8601 format
   */
  max?: string;
}

export function datetime(opts?: Props<DateTimeType>): DateTimeType {
  return {
    ...opts,
    type: "DATETIME",
  };
}

export interface DateType extends Omit<DateTimeType, "type"> {
  type: "DATE";
}

export function date(opts?: Props<DateType>): DateType {
  return {
    ...opts,
    type: "DATE",
  };
}

/**
 * Use this to collect common date formats.
 */
export enum DATE_FORMATS {
  /**
   * https://en.wikipedia.org/wiki/ISO_8601
   */
  ISO_DATE_TIME = "%Y-%m-%dT%H:%M:%S.%LZ",
  ISO_DATE = "%Y-%m-%d",
}

export const validator: ValidatorSync<DateType | DateTimeType> = (type) => {
  let schema = yup.date();

  if (type.min) schema = schema.min(new Date(type.min));
  if (type.max) schema = schema.max(new Date(type.max));

  const timeParser = type.format && d3.timeParse(type.format);

  return (value) => {
    let date: Date;
    if (timeParser) {
      let parsed: Date | null;
      if (value instanceof Date) parsed = timeParser(value.toISOString());
      else parsed = timeParser(String(value));

      if (!parsed)
        throw new Error(
          `${value} does not match the expected format: ${type.format}`,
        );
      else date = parsed;
    } else {
      date = new Date(value as any);
    }

    if (isNaN(date.getTime())) throw new Error(`invalid date: ${value}`);

    schema.validateSync(date, { strict: true });
  };
};
