import { Box, Column, ErrorIcon, Row, Text } from "@hightouchio/ui";
import { FC, ReactNode } from "react";
import { NodeProps } from "reactflow";
import { getTypeName } from "src/components/audiences/utils";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { schemaIcons } from "src/pages/schema/graph/utils";
import { SchemaModelType } from "src/types/schema";
import { NodeData } from "../types";
import { GroupNodeBody } from "./group-node";

const NodeWrapper: FC<
  Readonly<{
    isEphemeral?: boolean;
    children?: ReactNode;
    isActive?: boolean;
    isSelected?: boolean;
    isDimmed?: boolean;
  }>
> = ({ children, isEphemeral, isActive, isSelected, isDimmed }) => {
  const highlight = isSelected || isEphemeral;

  return (
    <Column
      height="100%"
      width="100%"
      borderRadius="md"
      bg="white"
      opacity={isDimmed ? 0.25 : 1}
      boxShadow={isActive || isSelected ? "lg" : "md"}
      border="1px"
      borderColor={highlight ? "primary.base" : "base.border"}
      outline="2px solid"
      outlineColor={highlight ? "teal.200" : "transparent"}
      transition="outline-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out, opacity 0.2s ease-in-out"
      position="relative"
      justifyContent="stretch"
    >
      {children}
    </Column>
  );
};

export const Node: FC<
  Readonly<{
    node: NodeProps;
    type: SchemaModelType;
    onClick: () => void;
    children?: ReactNode;
    isActive?: boolean;
    isDimmed?: boolean;
  }>
> = ({ node, type, children, onClick, isActive, isDimmed }) => {
  return (
    <NodeWrapper
      isEphemeral={node.data.isEphemeral}
      isSelected={node.data.isSelected}
      isActive={isActive}
      isDimmed={isDimmed}
    >
      {type === SchemaModelType.Group ? (
        <GroupNodeBody node={node} />
      ) : (
        <Row
          onClick={onClick}
          height="100%"
          align="center"
          gap={2}
          ml={4}
          mr={6}
        >
          <Box as="img" src={schemaIcons[type]} alt={type} />
          <Column overflow="hidden">
            <Text
              size="sm"
              fontWeight="semibold"
              textTransform="uppercase"
              color="text.secondary"
            >
              {getTypeName(type)}
            </Text>
            <NodeBody data={node.data} />
          </Column>
        </Row>
      )}

      {children}
    </NodeWrapper>
  );
};

const NodeBody: FC<{ data: NodeData }> = ({ data }) => {
  if (data.isMissingAsset) {
    return (
      <Row gap={1} fontSize="20px">
        <TextWithTooltip
          color="danger.base"
          fontWeight="medium"
          isTruncated
          message={data.label}
        >
          {data.label}
        </TextWithTooltip>

        <ErrorIcon color="danger.base" />
      </Row>
    );
  }

  if (data.label) {
    return (
      <TextWithTooltip fontWeight="medium" isTruncated message={data.label}>
        {data.label}
      </TextWithTooltip>
    );
  }

  return (
    <Text color="text.tertiary" fontWeight="medium">
      Pending...
    </Text>
  );
};
