import { FC, useMemo, useState } from "react";

import {
  Column,
  CubeIcon,
  FrameStackIcon,
  Row,
  SearchInput,
  Text,
  TimeIcon,
} from "@hightouchio/ui";
import groupBy from "lodash/groupBy";
import pluralize from "pluralize";
import { Control, useFieldArray, useFormContext } from "react-hook-form";

import { IconBox } from "src/components/icon-box";
import { ModelRelationshipsOrderBy } from "src/graphql";
import { Pagination, Table, TableColumn, useTableConfig } from "src/ui/table";
import { TextWithTooltip } from "src/components/text-with-tooltip";

import { OverrideFrequency } from "./override-frequency";
import { SampledModel, SamplingConfig } from "./types";

type Props = {
  relationships: {
    id: number;
    to_model: {
      id: number;
      name: string;
      event: { timestamp_column: string } | null;
      primary_key: string | null;
    };
  }[];
};

export const SampledRelatedModelsTable: FC<Props> = ({ relationships }) => {
  const { control, watch } = useFormContext();
  const parentSchedule = watch("schedule");
  const parentSampleRate = watch("sampleRate");
  const { fields, append, replace, update } = useFieldArray<
    SamplingConfig,
    "sampledRelatedModels",
    "arrayId"
  >({
    control: control as unknown as Control<SamplingConfig>,
    keyName: "arrayId",
    name: "sampledRelatedModels",
  });

  const relatedModels = useMemo(() => {
    const relationshipsByToModelId = groupBy(
      relationships,
      ({ to_model }) => to_model.id,
    );
    const modelIds = Object.keys(relationshipsByToModelId);

    const models = modelIds.map((modelId) => {
      const relationships = relationshipsByToModelId[modelId];

      const relationship = relationships?.[0];
      return {
        modelId,
        modelName: relationships?.[0]?.to_model.name ?? "",
        isEventModel: relationship?.to_model.event != null,
        isParentModel: relationship?.to_model.primary_key != null,
        hasMultipleFromRelationships: relationships && relationships.length > 1,
      };
    });

    return models;
  }, [relationships]);

  const relatedModelsById = useMemo(
    () =>
      Object.fromEntries(relatedModels.map((model) => [model.modelId, model])),
    [relatedModels],
  );

  const onSelectRow = (modelId: string) => {
    const fieldIndex = fields.findIndex(
      (field) => String(field.modelId) === String(modelId),
    );
    const field = fields[fieldIndex];

    if (field) {
      // Already tracking the field, just toggle whether its enabled or not
      update(fieldIndex, {
        ...field,
        enabled: !field.enabled,
      });
    } else {
      const relatedModel = relatedModelsById[modelId];
      if (!relatedModel) {
        return;
      }

      // New model to sample
      append({
        modelId: relatedModel.modelId,
        enabled: true,
        sampleRate: parentSampleRate,
        schedule: parentSchedule,
      });
    }
  };

  // Takes in a modelId or array of modelIds
  const onSelect = (value: string | string[]) => {
    if (Array.isArray(value)) {
      const models: SampledModel[] = value.map((modelId) => ({
        modelId,
        enabled: true,
        schedule: parentSchedule,
        sampleRate: parentSampleRate,
      }));
      replace(models);
    } else {
      onSelectRow(value);
    }
  };

  const [search, setSearch] = useState("");

  const filteredModels = useMemo(() => {
    if (!search) return relatedModels;

    const lowerCaseSearch = search.toLowerCase();

    return relatedModels.filter((model) =>
      model.modelName.toLowerCase().includes(lowerCaseSearch),
    );
  }, [search, relatedModels]);

  const { limit, offset, page, setPage } =
    useTableConfig<ModelRelationshipsOrderBy>({
      defaultSortKey: "updated_at",
      limit: 10,
    });

  const columns: TableColumn[] = [
    {
      header: () => <Text color="text.secondary">Model name</Text>,
      cell: ({ modelName, isEventModel, isParentModel }) => {
        return (
          <Row gap={2} overflow="hidden">
            <IconBox
              bg={
                isEventModel
                  ? "cyan.400"
                  : isParentModel
                    ? "electric.400"
                    : "grass.400"
              }
              boxSize={5}
              icon={
                isEventModel ? (
                  <TimeIcon />
                ) : isParentModel ? (
                  <CubeIcon />
                ) : (
                  <FrameStackIcon />
                )
              }
              iconSize={3}
            />

            <TextWithTooltip>{modelName}</TextWithTooltip>
          </Row>
        );
      },
    },
    {
      header: () => <Text color="text.secondary">Override frequency</Text>,
      cell: ({ modelId }, _index, selected) =>
        selected ? <OverrideFrequency modelId={modelId} /> : null,
    },
  ];

  const selectedRows = fields
    .filter(({ enabled }) => enabled)
    .map(({ modelId }) => modelId);

  return (
    <Column gap={2} zIndex={0}>
      <Row alignItems="center" justifyContent="space-between">
        <SearchInput
          placeholder="Search related models..."
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
        <Text color="text.secondary">
          {fields.length} of {pluralize("model", relatedModels.length, true)}{" "}
          selected
        </Text>
      </Row>
      <Table
        columns={columns}
        data={filteredModels.slice(offset, offset + limit)}
        disabled={(model) => Boolean(model.hasMultipleFromRelationships)}
        placeholder={{
          title: "No models",
        }}
        primaryKey="modelId"
        selectedRows={selectedRows}
        onSelect={onSelect}
      />
      <Pagination
        count={filteredModels.length}
        rowsPerPage={limit}
        page={page}
        setPage={setPage}
      />
    </Column>
  );
};
