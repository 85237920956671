import { array, number, object, string } from "yup";

import {
  AttributionMethodType,
  InteractionLookbackWindow,
  IntervalUnit,
} from "src/types/visual";

export type LookbackWindow = {
  quantity: number;
  unit: IntervalUnit.Day | IntervalUnit.Week | IntervalUnit.Month;
};

export type AttributionMethodFormData = {
  name: string;
  description: string;
  interactions: InteractionFormData[];
  weighting: AttributionMethodType;
};

export type InteractionFormData = {
  lookbackWindow: InteractionLookbackWindow;
  interactionModelId?: string;
};

export const attributionMethodValidationSchema = object().shape({
  name: string().required("Name is required"),
  description: string().nullable(),
  interactions: array().of(
    object().shape({
      lookbackWindow: object()
        .required()
        .shape({
          quantity: number().min(1).required("Quantity is required"),
          unit: string()
            .oneOf(Object.values(IntervalUnit))
            .required("Unit is required"),
        }),
      interactionModelId: string().required("Interaction model is required"),
    }),
  ),
  weighting: string().required("Weighting is required"),
});

export const attributionMethodFormDefaultValues: AttributionMethodFormData = {
  name: "",
  description: "",
  interactions: [
    {
      lookbackWindow: { quantity: 14, unit: IntervalUnit.Day },
      interactionModelId: undefined,
    },
  ],
  weighting: AttributionMethodType.LastTouch,
};
