import { ReactNode } from "react";

import { keyframes } from "@emotion/react";
import { Box } from "@hightouchio/ui";

const slideFadeIn = keyframes`
  from {
    transform: translateY(4px);
    opacity: 0.6;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const MessageAnimation = ({ children }: { children: ReactNode }) => {
  return <Box animation={`${slideFadeIn} 200ms ease-in`}>{children}</Box>;
};
