import { createContext, useContext } from "react";
import { NodeProps } from "reactflow";

export const GraphContext = createContext<{
  highlight: NodeProps | null;
  setHighlight: (node: NodeProps | null) => void;
  highlightedNodes: string[];
  onToggleExpandedGroup: ({
    groupNodeId,
    expanded,
  }: {
    groupNodeId: string;
    expanded: boolean;
  }) => void;
}>({
  highlight: null,
  setHighlight: () => {},
  highlightedNodes: [],
  onToggleExpandedGroup: () => {},
});

export const useGraphContext = () => useContext(GraphContext);
