import { FC, useMemo } from "react";

import {
  Box,
  CleanUpIcon,
  Column,
  Drawer,
  FrameIcon,
  IconButton,
  PlusIcon,
  SubtractIcon,
  Tooltip,
} from "@hightouchio/ui";
import noop from "lodash/noop";
import { useFormContext } from "react-hook-form";
import { Outlet, useLocation, useNavigate, useParams } from "src/router";
import ReactFlow, {
  Background,
  BackgroundVariant,
  EdgeTypes,
  SelectionMode,
} from "reactflow";

import { useHeaderHeight } from "src/contexts/header-height-context";
import {
  OverviewCard,
  PerformanceCard,
  SettingsCard,
} from "src/pages/journeys/cards";
import { MAX_CARD_WIDTH } from "src/pages/journeys/constants";
import {
  ConnectionLine,
  EdgeWithArrow,
  VisualEdge,
} from "src/pages/journeys/edges";
import { NodeLibrary } from "src/pages/journeys/node-library";
import {
  BranchNode,
  DelayNode,
  FlowNode,
  StartNode,
  SyncNode,
} from "src/pages/journeys/nodes";
import { JourneyGraph } from "src/pages/journeys/types";
import { JourneyNodeType } from "src/types/journeys";

import { useGraphContext } from "./use-graph-context";

const CARD_SPACING = 6;

const nodeTypes = {
  [JourneyNodeType.EntryCohort]: StartNode,
  [JourneyNodeType.Segments]: FlowNode,
  [JourneyNodeType.Sync]: SyncNode,
  [JourneyNodeType.TimeDelay]: DelayNode,
  [JourneyNodeType.WaitUntilEvent]: DelayNode,
  [JourneyNodeType.SegmentBranch]: BranchNode,
  [JourneyNodeType.WaitUntilEventBranch]: BranchNode,
};

const edgeTypes: EdgeTypes = {
  [VisualEdge.Arrow]: EdgeWithArrow,
};

type GraphProps = {
  numberOfUsersInJourney: number;
};

export const Graph: FC<GraphProps> = ({ numberOfUsersInJourney }) => {
  const { node_id } = useParams<{ node_id?: string }>();
  const { headerHeight } = useHeaderHeight();

  const location = useLocation();
  const isSettings = location.pathname.endsWith("/settings");

  const navigate = useNavigate();
  const form = useFormContext<JourneyGraph>();
  const {
    isDraft,
    isDrawerOpen,
    isEditMode,
    isJourneyRunning,
    unauthorized,
    isValidConnection,
    sidebarRef,
    onCleanUp,
    onCloseDrawer,
    onConnect,
    onConnectEnd,
    onConnectStart,
    onDragNode,
    onEdgesChange,
    onNodesChange,
    onRefitView,
    onZoomIn,
    onZoomOut,
  } = useGraphContext();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - Circular reference problem with Column types
  const nodes = form.watch("nodes");
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - Circular reference problem with Column types
  const formEdges = form.watch("edges");

  const edges = useMemo(() => {
    if (!isJourneyRunning) return formEdges;

    return formEdges.map((edge) => ({ ...edge, animated: true }));
  }, [formEdges, isJourneyRunning]);

  const closeDrawer = () => {
    navigate(".");
    onCloseDrawer();
  };

  return (
    <>
      <ReactFlow
        fitView
        panOnScroll
        proOptions={{ hideAttribution: true }}
        connectionLineComponent={ConnectionLine}
        edges={edges}
        nodes={nodes}
        edgeTypes={edgeTypes}
        nodeTypes={nodeTypes}
        selectNodesOnDrag={false}
        selectionMode={SelectionMode.Partial}
        deleteKeyCode={null}
        nodesConnectable={!unauthorized && isEditMode}
        nodesDraggable={!unauthorized && isEditMode}
        onConnect={onConnect}
        onConnectStart={onConnectStart}
        onConnectEnd={onConnectEnd}
        onNodeDrag={onDragNode}
        isValidConnection={isValidConnection}
        onEdgesChange={onEdgesChange}
        onNodesChange={onNodesChange}
      >
        <Column
          gap={4}
          pos="absolute"
          top={6}
          left={6}
          zIndex={5}
          sx={{ button: { borderColor: "base.border" } }}
        >
          <Column
            bg="white"
            border="1px"
            borderColor="base.border"
            borderRadius="md"
            overflow="hidden"
            sx={{
              button: {
                width: "40px",
                height: "40px",
                borderRadius: 0,
                ":nth-child(2)": {
                  border: "1px",
                  borderLeft: "none",
                  borderRight: "none",
                  borderColor: "base.border",
                },
              },
            }}
          >
            <Tooltip message="Zoom in" placement="right" openSpeed="slow">
              <IconButton
                aria-label="Zoom in"
                icon={PlusIcon}
                onClick={() => {
                  onZoomIn();
                }}
              />
            </Tooltip>
            <Tooltip message="Zoom out" placement="right" openSpeed="slow">
              <IconButton
                aria-label="Zoom out"
                icon={SubtractIcon}
                onClick={() => {
                  onZoomOut();
                }}
              />
            </Tooltip>
            <Tooltip message="Fit view" placement="right" openSpeed="slow">
              <IconButton
                aria-label="Fit view"
                icon={FrameIcon}
                onClick={onRefitView}
              />
            </Tooltip>
          </Column>
          {isEditMode && (
            <Column
              bg="white"
              border="1px"
              borderColor="base.border"
              borderRadius="md"
              overflow="hidden"
              sx={{
                button: {
                  width: "40px",
                  height: "40px",
                  borderRadius: 0,
                  ":nth-child(2)": {
                    border: "1px",
                    borderLeft: "none",
                    borderRight: "none",
                    borderColor: "base.border",
                  },
                },
              }}
            >
              <Tooltip message="Clean up" placement="right" openSpeed="slow">
                <IconButton
                  aria-label="Clean up"
                  icon={CleanUpIcon}
                  onClick={() => {
                    onCleanUp();
                  }}
                />
              </Tooltip>
            </Column>
          )}
        </Column>
        <Box pos="absolute" width="100%" height="100%" bg="base.background" />
        <Background
          id="journey-graph-background-dots"
          gap={60}
          size={4}
          color="var(--chakra-colors-gray-400)"
          variant={BackgroundVariant.Dots}
        />
      </ReactFlow>

      <Column
        ref={sidebarRef}
        position="absolute"
        top={CARD_SPACING}
        right={CARD_SPACING}
        gap={4}
        w={`${MAX_CARD_WIDTH}px`}
        maxHeight={`calc(100% - var(--chakra-space-${CARD_SPACING}) - var(--chakra-space-${CARD_SPACING}))`}
        overflowY={isEditMode ? "visible" : "hidden"}
      >
        {isEditMode && (
          <>
            <NodeLibrary />
            <SettingsCard />
          </>
        )}
        {!isDraft && !isEditMode && (
          <>
            <OverviewCard />
            <PerformanceCard numberOfUsersInJourney={numberOfUsersInJourney} />
          </>
        )}
      </Column>

      <Drawer
        isOpen={(node_id && isDrawerOpen) || isSettings}
        showOverlay={false}
        blockBackgroundInteraction={false}
        closeOnEsc={false}
        contentWrapperStyles={{
          mt: `${64 + headerHeight}px`,
        }}
        size="lg"
        trapFocus={false}
        onClose={noop}
      >
        <Column
          flex={1}
          minHeight={0}
          borderTop="1px solid"
          borderLeft="1px solid"
          borderColor="base.border"
        >
          <Outlet context={{ nodeId: node_id, onClose: closeDrawer }} />
        </Column>
      </Drawer>
    </>
  );
};
