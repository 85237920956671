import { FC, useCallback, ReactNode, useMemo, useEffect } from "react";

import { Text, Row, Heading, SearchInput } from "@hightouchio/ui";
import { LinkButton } from "src/router";
import { useNavigate } from "src/router";

import sequencePlaceholder from "src/assets/placeholders/sequence.svg";
import { FeaturePreview } from "src/components/feature-gates";
import { Page } from "src/components/layout";
import { PageAlert } from "src/components/page-alert";
import { PermissionedLinkButton } from "src/components/permission";
import { PermissionProvider } from "src/components/permission/permission-context";
import { useUser } from "src/contexts/user-context";
import {
  OrderBy,
  SyncSequencesBoolExp,
  SyncSequencesOrderBy,
  useSequencesQuery,
} from "src/graphql";
import useQueryState from "src/hooks/use-query-state";
import { track, TrackView } from "src/lib/analytics";
import {
  Pagination,
  Table,
  useTableConfig,
  useTableSort,
  SortOption,
} from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { Placeholder } from "src/ui/table/placeholder";
import { formatDatetime } from "src/utils/time";
import { openUrl } from "src/utils/urls";

import { SequenceStatusBadge } from "./sequence-status-badge";

const initialSort: SortOption<keyof SyncSequencesOrderBy> = {
  key: "updated_at",
  direction: OrderBy.Desc,
  label: "Recently updated",
};
const sortOptions: SortOption<keyof SyncSequencesOrderBy>[] = [
  { key: "name", direction: OrderBy.Asc, label: "Name A -> Z" },
  { key: "name", direction: OrderBy.Desc, label: "Name Z -> A" },
  {
    key: "last_run_at",
    direction: OrderBy.Asc,
    label: "Last run",
  },
  initialSort,
  { key: "created_at", direction: OrderBy.Asc, label: "Newest" },
  { key: "created_at", direction: OrderBy.Desc, label: "Oldest" },
];

export const Sequences: FC = () => {
  const { workspace, resources } = useUser();
  const navigate = useNavigate();
  const [search, setSearch] = useQueryState("search");

  const { limit, offset, page, setPage } =
    useTableConfig<SyncSequencesOrderBy>();
  const orderBy = useTableSort<SyncSequencesOrderBy>(initialSort, sortOptions);

  const filters: SyncSequencesBoolExp | undefined = useMemo(() => {
    const value = search?.trim();
    if (value) {
      return {
        name: { _ilike: `%${value}%` },
      };
    }
    return undefined;
  }, [search]);

  const {
    data,
    isLoading: loading,
    error: sequencesError,
  } = useSequencesQuery({ orderBy, limit, offset, filters });

  const sequences = data?.sync_sequences;
  const sequencesCount = data?.sync_sequences_aggregate?.aggregate?.count ?? 0;

  const onRowClick = useCallback(
    ({ id }, event) => openUrl(`/sequences/${id}`, navigate, event),
    [navigate],
  );

  useEffect(() => {
    setPage(0);
  }, [filters]);

  if (!sequences?.length && !loading) {
    return (
      <SequencesPage
        pageAlert={
          resources?.canSequence ? null : (
            <PageAlert
              button={
                <LinkButton href="/syncs" variant="primary">
                  Go to syncs
                </LinkButton>
              }
              message="Sequences consist of multiple related syncs that are triggered in a specific order. Your workspace must have 2+ syncs before you can use this feature."
              title="First, you need to configure at least two syncs"
            />
          )
        }
      >
        <Heading mb={8} size="xl">
          Sequences
        </Heading>
        <Placeholder
          content={{
            image: sequencePlaceholder,
            title: "No sequences in this workspace",
            body: "Sequences help you trigger a series of related syncs in a predefined order. This feature offers an easier way to orchestrate syncs without using a tool like Airflow.",
            button: resources?.canSequence ? (
              <PermissionedLinkButton
                permission={{ v1: { resource: "sync", grant: "create" } }}
                href="/sequences/new"
                variant="primary"
                onClick={() => {
                  track("Add Sequence Clicked");
                }}
              >
                Add sequence
              </PermissionedLinkButton>
            ) : null,
          }}
        />
      </SequencesPage>
    );
  }

  return (
    <SequencesPage>
      <Row align="center" justify="space-between" mb={8} gap={4}>
        <Heading size="xl">Sequences</Heading>
        <Row align="center" gap={3}>
          <SearchInput
            placeholder="Search sequences by name..."
            value={search ?? ""}
            onChange={(event) => setSearch(event.target.value)}
          />
          <PermissionedLinkButton
            permission={{
              v1: {
                resource: "sync",
                grant: "create",
              },
            }}
            href="/sequences/new"
            variant="primary"
            onClick={() => {
              track("Add Sequence Clicked");
            }}
          >
            Add sequence
          </PermissionedLinkButton>
        </Row>
      </Row>
      <FeaturePreview
        enabled={workspace?.organization?.plan?.sku === "business_tier"}
        featureDetails={{
          pitch:
            "Flexible orchestration tool for managing complex sequences of syncs",
          description:
            "Sync sequences let you define the order in which multiple syncs should run for a given use case. When you need to send data involving multiple objects, Hightouch can ensure that those syncs are executed correctly.",
          bullets: [
            "Specify the order in which Hightouch should run a series of syncs",
            "Hydrate your data for use cases involving dependent objects, like accounts and contacts",
            "Avoid brittle point-to-point APIs or manual cron jobs",
          ],
          image: {
            src: "https://cdn.sanity.io/images/pwmfmi47/production/5753e43bf95780d5c3dd96ed644ba956393b6797-865x507.png",
          },
        }}
        featureName="sync sequences"
        mb={8}
        variant="full"
      />
      <Table
        columns={[
          {
            name: "Status",
            min: "160px",
            max: "160px",
            cell: ({ runs }) => (
              <SequenceStatusBadge status={runs?.[0]?.status} />
            ),
          },
          {
            name: "Name",
            cell: ({ name }) => (
              <Text isTruncated fontWeight="medium">
                {name}
              </Text>
            ),
          },
          {
            name: "Last run",
            cell: ({ last_run_at }) =>
              last_run_at ? formatDatetime(last_run_at) : "--",
            breakpoint: "sm",
          },
          {
            ...LastUpdatedColumn,
            breakpoint: "sm",
          },
        ]}
        data={sequences}
        loading={loading}
        error={Boolean(sequencesError)}
        placeholder={{
          error: "Sequences failed to load, please try again.",
        }}
        onRowClick={onRowClick}
        sortOptions={sortOptions}
      />
      <Row sx={{ justifyContent: "flex-end", width: "100%", mt: 4 }}>
        <Pagination
          count={sequencesCount}
          label="sequences"
          page={page}
          rowsPerPage={limit}
          setPage={setPage}
        />
      </Row>
    </SequencesPage>
  );
};

const SequencesPage: FC<
  Readonly<{ children: ReactNode; pageAlert?: ReactNode }>
> = ({ children, pageAlert }) => (
  <PermissionProvider
    permission={{
      v1: {
        resource: "sync",
        grant: "create",
      },
    }}
  >
    <TrackView name="Sequences Page" />
    <Page outsideTopbar={pageAlert} title="Sequences">
      {children}
    </Page>
  </PermissionProvider>
);
