import { groupBy } from "lodash";
import { FC, useMemo, useRef } from "react";

import {
  Alert,
  Button,
  ChakraListItem,
  ChakraUnorderedList,
  CloseIcon,
  Column,
  FormField,
  GroupedCombobox,
  IconButton,
  Paragraph,
  PlusIcon,
  Row,
  Text,
  TextInput,
} from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useFieldArray } from "react-hook-form";
import { Link } from "src/router";

import { AccordionSection } from "src/components/accordion-section";
import { ActionBar } from "src/components/action-bar";
import { AddSyncButton } from "src/components/audiences/add-sync/add-sync-button";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import {
  PermissionedButton,
  PermissionedSwitch,
} from "src/components/permission";
import { useUpdateAudienceSplitsV2Mutation } from "src/graphql";
import { ParentModel } from "src/pages/audiences/types";
import {
  Audience,
  AudienceSplit,
  Column as ColumnReference,
  SplitSamplingType,
} from "src/types/visual";
import { disambiguateSyncs } from "src/utils/syncs";

import { ConnectionLine } from "./connection-line";
import {
  createTreatmentGroup,
  getDefaultFormValues,
  getValidationSchema,
} from "./form-helpers";
import { CONNECTIONS_CONTAINER_ID, Grid, GridTemplateColumn } from "./grid";
import { PercentageSlider } from "./percentage-slider";
import { SplitGroupNode } from "./split-group-node";
import { SyncNode } from "./sync-node";
import useHighlightNodes from "./use-highlight-nodes.ts";
import useNodePositions from "./use-node-positions";
import {
  HOLDOUT_GROUP_SYNC_ID,
  constructUpdateSplitsMutationPayload,
  getSplitGroupNodeId,
  getSyncIdsForSplit,
  getSyncNodeId,
  isSplitGroupEnabled,
} from "./utils";

type Props = {
  audience: NonNullable<Audience>;
  parentModel: NonNullable<ParentModel>;
};

export const Splits: FC<Readonly<Props>> = ({ audience, parentModel }) => {
  const audienceId = audience.id;

  const advancedConfigRef = useRef<HTMLDivElement>(null);

  const updateAudienceSplits = useUpdateAudienceSplitsV2Mutation();

  const syncs = useMemo(
    () => disambiguateSyncs(audience.syncs) ?? [],
    [audience.syncs],
  );
  const syncOptions = useMemo(() => {
    return syncs.map((sync) => ({
      label: sync.name,
      logo: sync.destination?.definition.icon ?? "",
      status: sync.status,
      value: Number(sync.id),
    }));
  }, [audience.syncs]);

  const groupedStratificationVarOptions = useMemo(() => {
    const filterableColumns = parentModel?.filterable_audience_columns ?? [];
    const columnsGroupedByModel = groupBy(
      filterableColumns,
      ({ model_name }) => model_name,
    );
    const modelNames = Object.keys(columnsGroupedByModel);

    return modelNames.map((model) => ({
      label: model === parentModel?.name ? "Properties" : model,
      options:
        columnsGroupedByModel[model]?.map((col) => ({
          label: col.alias || col.name,
          value: col.column_reference,
        })) ?? [],
    }));
  }, [parentModel?.filterable_audience_columns, parentModel?.name]);

  const form = useHightouchForm({
    values: getDefaultFormValues(audience),
    mode: "onSubmit",
    resolver: yupResolver(
      // Note that BigQuery is especially sensitive to special characters in column
      // names, so we disallow them here. The backend should also be robust to
      // this, but it's better if we don't let the user enter them in the first place.
      getValidationSchema({
        preventSpecialCharacters:
          parentModel?.connection?.definition.type === "bigquery",
      }),
    ),
    onSubmit: async (data) => {
      const splitConfig = {
        enabled: data.isEnabled,
        audienceId: String(audience.id),
        groupColumnName: data.groupColumnName,
        samplingType: data.samplingType,
        stratificationVariables: data.stratificationVariables,
      };

      if (!data.isEnabled) {
        // Splits have been disabled -- delete any existing splits
        await updateAudienceSplits.mutateAsync({
          removeSplitsIds: audience.splits.map((split) => split.id),
          ...splitConfig,
        });
      } else {
        // Splits are enabled -- update/create/remove splits by diffing the form values against the persisted values.
        const oldSplits = audience.splits.map((split) => ({
          ...split,
          destination_instance_ids: split.destination_instance_splits.map(
            (dis) => Number(dis.destination_instance_id),
          ),
        }));

        const newSplits = data.splits;

        const payload = constructUpdateSplitsMutationPayload({
          audienceId,
          oldSplits,
          newSplits,
        });

        await updateAudienceSplits.mutateAsync({
          addSplits: payload.addSplitsPayload,
          removeSplitsIds: payload.removeSplitsPayload,
          updateSplits: payload.updateSplitsPayload,
          updatedSplitsIds: payload.updatedSplitsIds,
          addDestinationInstanceSplits:
            payload.addDestinationInstanceSplitsPayload,
          ...splitConfig,
        });
      }
    },
  });

  const {
    control,
    formState: { errors, isDirty, isSubmitted, isSubmitting },
    setValue,
    watch,
  } = form;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - circular type with Column
  const isEnabled = watch("isEnabled");
  const samplingType = watch("samplingType");
  const splits = watch("splits");

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - circular type with Column
  const { fields, append, remove, update } = useFieldArray({
    control,
    keyName: "fieldId",
    name: "splits",
  });
  const stratificationVariables =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - no circular types until react-hook-form v8
    useFieldArray({
      control,
      name: "stratificationVariables",
    });

  // Associate sync ID to array of splits
  const splitsBySyncId: Map<number, AudienceSplit[]> = useMemo(() => {
    const map = new Map();
    fields.forEach((split) => {
      split.destination_instance_ids.forEach((syncId) => {
        const currentValue: AudienceSplit[] = map.get(syncId) ?? [];
        map.set(syncId, currentValue.concat(split));
      });

      // Holdout groups are a special case.
      // They can only be "assigned" the audience warehouse sync.
      // But there is no actual assignment and there is no real sync, so we fake it.
      if (split.is_holdout_group && split.percentage > 0) {
        map.set(HOLDOUT_GROUP_SYNC_ID, [split]);
      }
    });

    return map;
  }, [fields]);

  const { nodePositions, updateNodePosition } = useNodePositions(splits);

  const { highlightNodes, resetHighlightedNodes, isNodeHighlighted } =
    useHighlightNodes();

  const holdoutGroup = watch("splits.0");
  const firstTreatmentGroup = watch("splits.1");
  const showPercentageSlider =
    fields.length === 2 && holdoutGroup.percentage > 0;

  const handleRemoveSplitGroup = (splitGroupIndex: number) => {
    remove(splitGroupIndex);

    // After deleting this node, we need to unhighlight all of its related nodes. Effectively, we just clear them out.
    resetHighlightedNodes();
  };

  const activeSplits: Array<AudienceSplit & { splitIndex: number }> = splits
    .map((split, index) => ({ ...split, splitIndex: index }))
    .filter(isSplitGroupEnabled);

  const handleUpdateSplitGroup = (
    splitGroupIndex: number,
    splitGroup: AudienceSplit,
  ) => {
    update(splitGroupIndex, splitGroup);

    // Check if we need to rebalance the percentage
    if (activeSplits.length <= 2) {
      const remainingPercentage = 100 - splitGroup.percentage;
      activeSplits.forEach((split) => {
        // Skip the split we just updated and any disabled splits
        if (
          splitGroupIndex !== split.splitIndex &&
          isSplitGroupEnabled(split)
        ) {
          setValue(
            `splits.${split.splitIndex}.percentage`,
            remainingPercentage,
          );
        }
      });
    }
  };

  const handleToggleHoldoutGroup = (
    enable: boolean,
    holdoutGroup: AudienceSplit,
  ) => {
    let holdoutGroupPercentage = enable ? 20 : 0;

    // 1 split -> 2 splits
    if (activeSplits.length === 1 && enable) {
      const activeSplitPercentage = activeSplits[0]?.percentage || 0;
      if (activeSplitPercentage >= 100) {
        // Active split percentage is maxed out (rare case, so just set it back to the initial state of 80)
        activeSplits.forEach((split) => {
          setValue(`splits.${split.splitIndex}.percentage`, 80);
        });
      } else {
        // Active split percentage is not maxed out, so holdout group gets the remaining value
        holdoutGroupPercentage = 100 - activeSplitPercentage;
      }
    }

    update(0, { ...holdoutGroup, percentage: holdoutGroupPercentage });
  };

  const handleAddSplitGroup = () => {
    const shouldRebalance = activeSplits.length === 1;
    const percentage = shouldRebalance
      ? 100 - (activeSplits[0]?.percentage || 0)
      : 1;

    append(
      createTreatmentGroup({
        friendlyName: `Treatment ${fields.length}`,
        percentage,
        destinationInstanceIds: [],
      }),
    );
  };

  // Scroll the page down to hint that there is more content
  // We use an artificial timeout to give the advanced configuration section to render (it's hidden by an accordion).
  // When it fully renders, there will be scroll height. If we try to scroll before there is content to be scrolled then nothing will happen.
  const scrollAdvancedConfigSectionIntoView = () => {
    setTimeout(() => {
      advancedConfigRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }, 200);
  };

  const sharedNodeProps = {
    onMouseEnter: highlightNodes,
    onMouseLeave: resetHighlightedNodes,
    onNodeResize: updateNodePosition,
  };

  const sharedSyncNodeProps = {
    source: {
      id: parentModel?.connection?.id,
      planInWarehouse: parentModel?.connection?.plan_in_warehouse,
      audienceSnapshottingEnabled:
        parentModel?.connection?.audience_snapshotting_enabled,
    },
    assignableSplits: activeSplits.filter((split) => !split.is_holdout_group), // holdout groups can't be assigned a sync
    onUpdateSplit: handleUpdateSplitGroup,
    ...sharedNodeProps,
  };

  const isAddSyncDisabled = isDirty || isSubmitting;

  const errorKeys = Object.keys(errors);

  return (
    <Column gap={6} mb={20}>
      <Form form={form}>
        <Column gap={2}>
          <Row gap={2}>
            <Text fontWeight="medium" size="lg">
              Enable split groups
            </Text>
            <Controller
              control={control}
              name="isEnabled"
              render={({ field }) => (
                <PermissionedSwitch
                  permission={{
                    v1: {
                      resource: "audience",
                      grant: "update",
                      id: audience.id,
                    },
                    v2: {
                      resource: "model",
                      grant: "can_update",
                      id: audience.id,
                    },
                  }}
                  isChecked={field.value}
                  {...field}
                />
              )}
            />
          </Row>
          <Column width="576px">
            <Paragraph color="text.secondary">
              Split this audience into multiple groups to run A/B tests or
              measure incremental lift against a holdout group. Check out the{" "}
              <Link
                href={`${import.meta.env.VITE_DOCS_URL}/customer-studio/splits`}
              >
                docs
              </Link>{" "}
              for step-by-step instructions.
            </Paragraph>
          </Column>
        </Column>

        {isEnabled && (
          <>
            {isSubmitted && Boolean(errorKeys.length) && (
              <Alert
                variant="inline"
                type="error"
                title="Splits couldn't be saved. Please check the following errors:"
                message={
                  <ChakraUnorderedList spacing={2}>
                    {errorKeys.map((errorKey) => {
                      const error = errors[errorKey];
                      if (Array.isArray(error)) {
                        return error.map((e, index) =>
                          Object?.keys(e).map((key) => (
                            <ChakraListItem key={index}>
                              {e[key]?.message}
                            </ChakraListItem>
                          )),
                        );
                      }
                      return (
                        <ChakraListItem key={errorKey}>
                          {error.message}
                        </ChakraListItem>
                      );
                    })}
                  </ChakraUnorderedList>
                }
              />
            )}

            {/* Slider */}
            {showPercentageSlider && (
              <Column width="576px" py={4}>
                <PercentageSlider
                  splitGroup1={holdoutGroup}
                  splitGroup2={firstTreatmentGroup}
                  onChange={handleUpdateSplitGroup}
                />
              </Column>
            )}

            <Grid>
              {/* Split groups */}
              <Column
                gridColumnStart={GridTemplateColumn.SplitGroupsStart}
                gridColumnEnd={GridTemplateColumn.SplitGroupsEnd}
                gap={4}
              >
                <Row alignItems="center" justifyContent="space-between">
                  <Text fontWeight="medium">Split groups</Text>
                  <PermissionedButton
                    permission={{
                      v1: {
                        resource: "audience",
                        grant: "update",
                        id: audience.id,
                      },
                      v2: {
                        resource: "model",
                        grant: "can_update",
                        id: audience.id,
                      },
                    }}
                    icon={PlusIcon}
                    onClick={handleAddSplitGroup}
                  >
                    Add split group
                  </PermissionedButton>
                </Row>

                {fields?.map(({ fieldId, ...split }, index) => (
                  <SplitGroupNode
                    {...sharedNodeProps}
                    key={fieldId}
                    isHighlighted={isNodeHighlighted(
                      getSplitGroupNodeId(split),
                    )}
                    split={split}
                    syncOptions={syncOptions}
                    // There needs to be a minimum of two split groups
                    onDelete={
                      fields.length > 2
                        ? () => handleRemoveSplitGroup(index)
                        : undefined
                    }
                    onToggleHoldoutGroup={handleToggleHoldoutGroup}
                    onUpdate={(updates: Partial<AudienceSplit>) =>
                      handleUpdateSplitGroup(index, { ...split, ...updates })
                    }
                  />
                ))}
              </Column>

              {/* Connection lines */}
              <Column
                id={CONNECTIONS_CONTAINER_ID}
                gridColumnStart={GridTemplateColumn.ConnectionsStart}
                gridColumnEnd={GridTemplateColumn.ConnectionsEnd}
              >
                <svg style={{ height: "100%" }}>
                  {fields.map((split) =>
                    getSyncIdsForSplit(split).map((syncId) => {
                      const sourceId = getSplitGroupNodeId(split);
                      const source = nodePositions[sourceId];

                      const targetId = getSyncNodeId(syncId);
                      const target = nodePositions[targetId];

                      return source && target ? (
                        <ConnectionLine
                          isHighlighted={
                            isNodeHighlighted(sourceId) &&
                            isNodeHighlighted(targetId)
                          }
                          key={`${sourceId}-${targetId}`}
                          source={source}
                          target={target}
                        />
                      ) : null;
                    }),
                  )}
                </svg>
              </Column>

              {/* Syncs groups */}
              <Column
                gridColumnStart={GridTemplateColumn.SyncsStart}
                gridColumnEnd={GridTemplateColumn.SyncsEnd}
                gap={4}
              >
                <Row alignItems="center" justifyContent="space-between">
                  <Text fontWeight="medium">Syncs</Text>
                  <AddSyncButton
                    tooltip={
                      isAddSyncDisabled
                        ? "Please save your changes before adding new syncs."
                        : undefined
                    }
                    audience={audience}
                    parentModel={parentModel}
                    icon={PlusIcon}
                    isDisabled={isAddSyncDisabled}
                  />
                </Row>

                {/* "Fake" sync for holdout group. Only shown if the holdout group is enabled. */}
                {isSplitGroupEnabled(holdoutGroup) && (
                  <SyncNode
                    {...sharedSyncNodeProps}
                    isHighlighted={isNodeHighlighted(
                      getSyncNodeId(HOLDOUT_GROUP_SYNC_ID),
                    )}
                    assignedSplits={
                      splitsBySyncId.get(HOLDOUT_GROUP_SYNC_ID) ?? []
                    }
                    isAudienceWarehouseSync
                    sync={{
                      id: HOLDOUT_GROUP_SYNC_ID,
                      description: null,
                      destination: {
                        definition: {
                          icon: audience?.connection?.definition.icon,
                          name: audience?.connection?.definition.name,
                        },
                        name: audience?.connection?.name,
                      },
                      name: "Holdout group logs",
                      status: null,
                    }}
                  />
                )}

                {/* Real syncs */}
                {syncs?.map((sync, index) => (
                  <SyncNode
                    {...sharedSyncNodeProps}
                    key={index}
                    assignedSplits={splitsBySyncId.get(sync.id) ?? []}
                    isHighlighted={isNodeHighlighted(getSyncNodeId(sync.id))}
                    sync={sync}
                  />
                ))}
              </Column>
            </Grid>

            <Column ref={advancedConfigRef}>
              <AccordionSection
                label="Advanced configuration"
                labelFontSize="lg"
                onChange={scrollAdvancedConfigSectionIntoView}
              >
                <Column id="advanced_config" gap={6} maxW="576px" mt={2}>
                  <Controller
                    name="groupColumnName"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <FormField
                        description="This column will be created to include the specific split group a user is included in."
                        error={error?.message}
                        label="Column name"
                      >
                        <TextInput
                          width="sm"
                          isInvalid={Boolean(error)}
                          placeholder="Enter a name for your group column (e.g. test_group)..."
                          {...field}
                        />
                      </FormField>
                    )}
                  />

                  <Column>
                    <Row alignItems="center" gap={4}>
                      <Text fontWeight="medium">Stratified Sampling</Text>

                      <PermissionedSwitch
                        permission={{
                          v1: {
                            resource: "audience",
                            grant: "update",
                            id: audience.id,
                          },
                          v2: {
                            resource: "model",
                            grant: "can_update",
                            id: audience.id,
                          },
                        }}
                        isChecked={
                          samplingType === SplitSamplingType.Stratified
                        }
                        onChange={(enabled) => {
                          setValue(
                            "samplingType",
                            enabled
                              ? SplitSamplingType.Stratified
                              : SplitSamplingType.NonStratified,
                          );
                          setValue("stratificationVariables", []);
                          if (enabled) {
                            stratificationVariables.append(
                              {} as ColumnReference,
                            );
                          }

                          scrollAdvancedConfigSectionIntoView();
                        }}
                      />
                    </Row>
                    <Paragraph color="text.secondary">
                      Stratified sampling allows users to stratify their groups
                      such that each group will contain an equal allocation of
                      values from specified stratification variables.{" "}
                      <i>
                        Please note that stratified sampling can only be used
                        with one-time syncs.
                      </i>
                    </Paragraph>
                  </Column>

                  {samplingType === SplitSamplingType.Stratified && (
                    <>
                      <Column>
                        <Text fontWeight="medium">
                          Stratification variables
                        </Text>
                        <Text color="text.secondary">
                          The below columns are used to stratify each group. For
                          example, you may want to make sure that your groups
                          are stratified along the age factor.
                        </Text>
                      </Column>
                      <Column gap={2} align="flex-start">
                        {Boolean(stratificationVariables.fields.length) && (
                          <>
                            {stratificationVariables.fields.map(
                              ({ id }, index) => (
                                <Controller
                                  key={id}
                                  control={control}
                                  name={
                                    `stratificationVariables.${index}` as const
                                  }
                                  render={({
                                    field,
                                    fieldState: { error },
                                  }) => (
                                    <Column gap={2}>
                                      <Row align="center" gap={2}>
                                        <GroupedCombobox
                                          isInvalid={Boolean(error)}
                                          optionGroups={
                                            groupedStratificationVarOptions
                                          }
                                          placeholder="Select a column..."
                                          {...field}
                                        />
                                        {stratificationVariables.fields.length >
                                          1 && (
                                          <IconButton
                                            aria-label="Remove stratification variable"
                                            icon={CloseIcon}
                                            onClick={() =>
                                              stratificationVariables.remove(
                                                index,
                                              )
                                            }
                                          />
                                        )}
                                      </Row>
                                    </Column>
                                  )}
                                />
                              ),
                            )}
                          </>
                        )}
                        <Button
                          onClick={() => {
                            stratificationVariables.append(
                              {} as ColumnReference,
                            );
                          }}
                        >
                          Add variable
                        </Button>
                      </Column>
                    </>
                  )}
                </Column>
              </AccordionSection>
            </Column>
          </>
        )}

        <ActionBar>
          <FormActions
            permission={{
              v2: { resource: "model", grant: "can_update", id: audience.id },
              v1: { resource: "audience", grant: "update", id: audience.id },
            }}
          />
        </ActionBar>
      </Form>
    </Column>
  );
};
