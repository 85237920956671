import { useCallback } from "react";

import { Box, Column, Row, SectionHeading, Text } from "@hightouchio/ui";
import { Pagination, Table, useTableConfig } from "src/ui/table";
import { useDestinationRulesForParentModelQuery } from "src/graphql";
import { openUrl } from "src/utils/urls";
import { Outlet, useNavigate, useOutletContext } from "src/router";
import { DESTINATION_RULES_BASE_PATH } from "./utils";
import { PermissionedButton } from "src/components/permission";
import { OutletContext } from "../layout";

export const DestinationRules = () => {
  const navigate = useNavigate();
  const { parentModel } = useOutletContext<OutletContext>();
  const destinationRulesQuery = useDestinationRulesForParentModelQuery(
    { parentModelId: parentModel.id },
    {
      enabled: Boolean(parentModel.id),
    },
  );
  const destinationRules = destinationRulesQuery.data?.destination_rules ?? [];
  const destinationRulesCount =
    destinationRulesQuery.data?.destination_rules_aggregate.aggregate?.count;

  const { limit, page, setPage } = useTableConfig({ limit: 20 });

  const onRowClick = useCallback(
    ({ id }, event) =>
      openUrl(`${DESTINATION_RULES_BASE_PATH}/${id}`, navigate, event),
    [navigate],
  );

  return (
    <>
      <Column py={6} gap={6}>
        <Row alignItems="center" justifyContent="space-between">
          <Column>
            <SectionHeading>Destination rules</SectionHeading>
            <Text>
              Records which don’t meet a destination’s rule will be excluded at
              sync time
            </Text>
          </Column>
          <PermissionedButton
            permission={{
              v1: { resource: "audience_schema", grant: "update" },
              v2: {
                resource: "model",
                grant: "can_update",
                id: parentModel.id ?? "",
              },
            }}
            variant="primary"
            onClick={() => navigate("new")}
          >
            Add rule
          </PermissionedButton>
        </Row>
        <Table
          columns={[
            {
              name: "Destination",
              cell: (data) => (
                <Row alignItems="center" gap={2}>
                  <Box
                    as="img"
                    h={6}
                    w={6}
                    src={data.destination.definition.icon}
                  />
                  <Text fontWeight="medium">
                    {data.destination.name ?? data.destination.definition.name}
                  </Text>
                </Row>
              ),
            },
            {
              name: "Parent model",
              cell: (data) => (
                <Row alignItems="center" gap={2}>
                  <Box
                    as="img"
                    h={6}
                    w={6}
                    src={data.parent_model?.connection?.definition.icon}
                  />
                  <Text fontWeight="medium">{data.parent_model.name}</Text>
                </Row>
              ),
            },
          ]}
          data={destinationRules}
          loading={
            destinationRulesQuery.isLoading ||
            destinationRulesQuery.isRefetching
          }
          onRowClick={onRowClick}
          placeholder={{
            title: `You haven't created any destination rules for parent model ${parentModel.name}`,
          }}
        />
        <Row justifyContent="right" mt={4}>
          <Pagination
            count={destinationRulesCount}
            page={page}
            rowsPerPage={limit}
            setPage={setPage}
          />
        </Row>
      </Column>
      <Outlet context={{ parentModel }} />
    </>
  );
};
