import { useEffect, useState, useMemo, ReactNode } from "react";

import {
  ChakraModal,
  ChakraModalBody,
  ChakraModalContent,
  ChakraModalFooter,
  ChakraModalHeader,
  ChakraModalOverlay,
  Row,
  Column,
  Menu,
  MenuItem,
  MenuList,
  useToast,
  SectionHeading,
  MenuActionsButton,
  Badge,
  Select,
  Combobox,
  FormErrorMessage,
  EmptyState,
  Button,
  IconButton,
  EditIcon,
  CloseIcon,
  CheckIcon,
  Tooltip,
  RefreshIcon,
  InformationIcon,
  TextInput,
  Box,
  Text,
} from "@hightouchio/ui";
import { uniqueId } from "lodash";
import { useFormContext } from "react-hook-form";
import { useQueryClient } from "react-query";

import { Editor } from "src/components/editor";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { useDestinationForm } from "src/contexts/destination-form-context";
import {
  SyncOp,
  TestSyncConfigurationResult,
  useTestSyncConfigurationQuery,
  useTestSyncConfigurationSupportedQuery,
} from "src/graphql";
import { Arrow } from "src/ui/arrow";
import { SimplePagination } from "src/ui/table";
import { processRequestInfo, RequestInfo } from "src/utils/syncs";
import { cleanConfig } from "./utils";
import { ResourcePermissionInput } from "src/components/permission/use-resource-permission";
import { PermissionedButton } from "src/components/permission";
import { getRawModelRow } from "src/utils/models";
import { isPresent } from "src/types/utils";

export const TestSync = ({
  formkit,
  permission,
}: {
  formkit: ReactNode | null;
  permission:
    | ResourcePermissionInput<"model", "sync_template">
    | ResourcePermissionInput<"sync", "sync">;
}) => {
  const [testSyncOpen, setTestSyncOpen] = useState(false);
  const [supported, setSupported] = useState(false);
  const {
    config: _config,
    destination,
    model,
    setErrors,
    validate,
  } = useDestinationForm();
  const { getValues, handleSubmit, setError } = useFormContext();

  const { toast } = useToast();

  const config = formkit ? getValues() : _config;

  const { data } = useTestSyncConfigurationSupportedQuery(
    {
      // These types are wrong in the GQL (they're numbers instead of strings)
      segmentId: Number(model?.id),
      destinationId: Number(destination?.id),
      syncConfig: config,
    },
    { enabled: Boolean(model && destination && config) },
  );

  useEffect(() => {
    if (data?.testSyncConfigurationSupported === true) {
      setSupported(true);
    } else if (data?.testSyncConfigurationSupported === false) {
      setSupported(false);
    }
  }, [data]);

  if (!supported) return null;

  return (
    <>
      <PermissionedButton
        permission={permission}
        onClick={handleSubmit(async () => {
          const errors = await validate(config);
          if (
            errors &&
            typeof errors === "object" &&
            Object.entries(errors).length
          ) {
            Object.entries(errors).forEach(([key, message]) => {
              setError(key, { message: String(message) });
            });

            toast({
              id: "test-sync",
              title: "Complete the sync configuration before testing",
              variant: "error",
            });

            setErrors(errors);
          } else {
            setTestSyncOpen(true);
            setErrors(null);
          }
        })}
      >
        Test
      </PermissionedButton>
      {testSyncOpen && (
        <TestSyncModal
          formkit={formkit}
          onClose={() => {
            setTestSyncOpen(false);
          }}
        />
      )}
    </>
  );
};

const TestSyncModal = ({ formkit, onClose }) => {
  const client = useQueryClient();
  const [loading, setLoading] = useState<boolean>(false);
  const [result, setResult] = useState<
    TestSyncConfigurationResult | undefined
  >();

  const {
    reloadRows,
    loadingRows,
    queryRowsError,
    model,
    destination,
    destinationDefinition,
    sourceDefinition,
    rows: allRows,
    config: _config,
  } = useDestinationForm();

  const { getValues } = useFormContext();

  const rawConfig = formkit ? getValues() : _config;
  // New placeholder mappings were added to help users create their initial mappings.
  // These placeholders leave an empty mapping in the config.
  // We don't save the empty mapping to the db unless the user populates it.
  // Before testing, we need to clean the config of these empty mappings.
  const config = cleanConfig(rawConfig);

  // Only rows with a primary key value can be synced.
  const primaryKey = model?.primary_key ?? "";
  const rows = allRows?.filter((row) => row[primaryKey] != null);

  useEffect(() => {
    if (!rows?.length) {
      reloadRows();
    }
  }, []);

  useEffect(() => {
    if (rows?.length && !row) {
      setRow(rows[0]);
    }
  }, [rows]);

  const [row, setRow] = useState<{ [key: string]: any }[]>();
  const [viewError, setViewError] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(0);

  const rowOptions =
    rows?.map((row) => ({
      label: `${primaryKey}: ${row[primaryKey]}`,
      value: String(row[primaryKey]),
    })) ?? [];

  const runOperation = async (operation: SyncOp) => {
    const segmentId = model?.id;
    const destinationId = destination?.id;

    if (!segmentId || !destinationId) {
      return;
    }

    setLoading(true);
    const variables = {
      segmentId: Number(segmentId),
      destinationId: Number(destinationId),
      syncConfig: config,
      rows: row ? [getRawModelRow(row, model.columns)] : [],
      operation,
    };
    const { testSyncConfiguration } = await client.fetchQuery(uniqueId(), {
      queryFn: useTestSyncConfigurationQuery.fetcher(variables),
    });
    setLoading(false);

    setResult(testSyncConfiguration);
  };

  const rejectedRow = result?.batches?.[0]?.rejectedRows?.[0];
  const error = result?.error;
  const requests = result?.batches?.[0]?.requestInfoSet;

  const requestInfoSet: RequestInfo[] = useMemo(() => {
    return (requests || [])
      .filter(isPresent)
      .map((requestInfo) =>
        processRequestInfo(requestInfo, destinationDefinition),
      )
      .filter((requestInfo) => requestInfo.method !== "Contact.bulkload");
  }, [requests, destinationDefinition]);

  const requestInfo = requestInfoSet?.[selectedRequest];

  return (
    <ChakraModal
      isCentered
      isOpen
      size="6xl"
      onClose={onClose}
      scrollBehavior="inside"
    >
      <ChakraModalOverlay />
      <ChakraModalContent
        p={0}
        overflowY="auto"
        maxHeight="80vh"
        height="100%"
        my="auto"
        mx={4}
      >
        <ChakraModalHeader
          p={4}
          borderBottom="1px solid"
          borderColor="base.border"
          mb={0}
        >
          <Row
            alignItems="center"
            gap={4}
            flex={1}
            justifyContent="space-between"
            w="100%"
          >
            <SectionHeading>Test a row</SectionHeading>
            <Row
              gap={2}
              alignItems="center"
              flexShrink={1}
              maxW={{ base: "50%", md: "60%" }}
            >
              <IntegrationIcon
                name={sourceDefinition?.name}
                src={sourceDefinition?.icon}
              />
              <TextWithTooltip>
                {model?.name ?? "Private model"}
              </TextWithTooltip>
              <Arrow />
              <IntegrationIcon
                name={destinationDefinition?.name}
                src={destinationDefinition?.icon}
              />
              <TextWithTooltip>
                {destination?.name ??
                  destinationDefinition?.name ??
                  "Private destination"}
              </TextWithTooltip>
            </Row>
            <Row gap={2} flexShrink={0}>
              <Button
                variant="primary"
                isDisabled={loading || !row}
                onClick={() => {
                  runOperation(SyncOp.Added);
                }}
              >
                Sync as added row
              </Button>
              <Menu>
                <MenuActionsButton variant="secondary" />
                <MenuList>
                  <MenuItem
                    isDisabled={loading || !row}
                    onClick={() => {
                      runOperation(SyncOp.Changed);
                    }}
                  >
                    Sync as changed row
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      runOperation(SyncOp.Removed);
                    }}
                    isDisabled={loading || !row}
                  >
                    Sync as removed row
                  </MenuItem>
                </MenuList>
              </Menu>
            </Row>
          </Row>
        </ChakraModalHeader>
        <ChakraModalBody p={0} mb={0}>
          <Box
            display="grid"
            gridTemplateColumns="repeat(2, minmax(0, 1fr))"
            height="100%"
          >
            <Column
              height="100%"
              minHeight={0}
              borderRight="1px solid"
              borderColor="base.border"
            >
              <Column borderBottom="1px solid" borderColor="base.border" p={3}>
                <Row gap={2} align="center" width="100%">
                  <Combobox
                    width="100%"
                    isInvalid={Boolean(!loadingRows && queryRowsError)}
                    isLoading={loadingRows}
                    options={rowOptions}
                    placeholder="Select a row..."
                    value={row?.[primaryKey]?.toString()}
                    onChange={(value) =>
                      setRow(
                        rows?.find(
                          (row) => value === String(row?.[primaryKey]),
                        ),
                      )
                    }
                  />
                  <IconButton
                    aria-label="Reload rows"
                    icon={RefreshIcon}
                    variant="secondary"
                    onClick={reloadRows}
                  />
                </Row>
                <FormErrorMessage>{queryRowsError}</FormErrorMessage>
              </Column>
              <Column overflowY="auto">
                {row ? (
                  <>
                    <RowValue
                      key={primaryKey}
                      readOnly
                      property={primaryKey}
                      tooltip="Hightouch casts the primary key to a string."
                      value={String(valueToString(row[primaryKey]))}
                    />
                    {Object?.entries(row)
                      ?.filter(([k, _]) => k !== primaryKey)
                      ?.map(([k, v]) => (
                        <RowValue
                          key={k}
                          property={k}
                          setValue={(value) => {
                            setRow({ ...row, [k]: value });
                          }}
                          value={v}
                        />
                      ))}
                  </>
                ) : (
                  <Column p={4} flex={1}>
                    <EmptyState
                      title="No rows selected"
                      message="Select a row to view details here"
                    />
                  </Column>
                )}
              </Column>
            </Column>
            <Box
              display="grid"
              gridTemplateRows={
                viewError ? "1fr auto" : "repeat(2, minmax(0, 1fr)) auto"
              }
              gridTemplateColumns="minmax(0, 1fr)"
              minHeight="0"
              height="100%"
            >
              {result ? (
                <>
                  {viewError ? (
                    <Box p={3}>
                      <Box
                        as="pre"
                        sx={{ wordBreak: "break-all", whiteSpace: "pre-wrap" }}
                      >
                        {rejectedRow?.reason || error}
                      </Box>
                    </Box>
                  ) : !requestInfo ? (
                    <EmptyState
                      title="No request sent for this row"
                      message="An error may have occurred, or Hightouch did not send a request due to the sync mode (i.e. a changed row in an insert only destination)"
                    />
                  ) : (
                    <>
                      <Column
                        borderBottom="1px solid"
                        borderColor="base.border"
                      >
                        <Column
                          gap={2}
                          p={3}
                          borderBottom="1px solid"
                          borderColor="base.border"
                        >
                          <Row
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Row gap={2}>
                              <Text fontWeight="semibold">Request</Text>
                              {!!requestInfo?.meta?.invokedTimestamp && (
                                <Text color="text.secondary">
                                  ({requestInfo?.meta?.invokedTimestamp})
                                </Text>
                              )}
                            </Row>
                            <SimplePagination
                              mt={0}
                              page={selectedRequest}
                              pages={requestInfoSet?.length}
                              onNext={() => {
                                setSelectedRequest(
                                  (selectedRequest) => selectedRequest + 1,
                                );
                              }}
                              onPrevious={() => {
                                setSelectedRequest(
                                  (selectedRequest) => selectedRequest - 1,
                                );
                              }}
                            />
                          </Row>
                          <Row alignItems="center" gap={2}>
                            <Row>
                              <Badge>{requestInfo?.method}</Badge>
                            </Row>

                            <Box
                              sx={{
                                fontFamily: "monospace",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {requestInfo?.destination}
                            </Box>
                          </Row>
                        </Column>
                        <Editor
                          minHeight="0"
                          language={
                            requestInfo?.requestIsJson
                              ? "json"
                              : requestInfo?.requestIsXml
                                ? "xml"
                                : undefined
                          }
                          value={requestInfo?.requestBody}
                        />
                      </Column>
                      <Column>
                        <Column
                          p={3}
                          gap={2}
                          borderBottom="1px solid"
                          borderColor="base.border"
                        >
                          <Row gap={2}>
                            <Text fontWeight="semibold">Response</Text>
                            {!!requestInfo?.meta?.finishedTimestamp && (
                              <Text color="text.secondary">
                                ({requestInfo?.meta?.finishedTimestamp})
                              </Text>
                            )}
                          </Row>
                          <Row>
                            {requestInfo && (
                              <Badge
                                mr={2}
                                variant={
                                  requestInfo?.status.match(/E[Rr][Rr]/)
                                    ? "danger"
                                    : "success"
                                }
                              >
                                {requestInfo?.status}
                              </Badge>
                            )}
                          </Row>
                        </Column>
                        {requestInfo?.responseBody ? (
                          <Editor
                            minHeight="0"
                            language={
                              requestInfo?.responseIsJson
                                ? "json"
                                : requestInfo?.responseIsXml
                                  ? "xml"
                                  : undefined
                            }
                            value={requestInfo?.responseBody}
                          />
                        ) : (
                          <Box p={3}>
                            <Text>
                              No response
                              {destinationDefinition?.name
                                ? ` from ${destinationDefinition.name}`
                                : ""}
                            </Text>
                          </Box>
                        )}
                      </Column>
                    </>
                  )}
                  <Column p={3} borderTop="1px solid" borderColor="base.border">
                    {!(rejectedRow || error) ? (
                      <Row alignItems="center" gap={2}>
                        <Badge variant="success">Success</Badge>
                        <Box
                          sx={{
                            flex: 1,
                            px: 4,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          The sync to {destinationDefinition?.name} was
                          successfully completed.
                        </Box>
                      </Row>
                    ) : (
                      <Row
                        alignItems="center"
                        justifyContent="space-between"
                        gap={2}
                      >
                        <Row alignItems="center" gap={2} minWidth={0}>
                          <Row>
                            <Badge variant="danger">Failed</Badge>
                          </Row>
                          <Box
                            sx={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {rejectedRow?.reason || error}
                          </Box>
                        </Row>
                        <Row>
                          {viewError ? (
                            <Button
                              onClick={() => {
                                setViewError(false);
                              }}
                            >
                              Close Full Error
                            </Button>
                          ) : (
                            <Button
                              onClick={() => {
                                setViewError(true);
                              }}
                            >
                              View Full Error
                            </Button>
                          )}
                        </Row>
                      </Row>
                    )}
                  </Column>
                </>
              ) : (
                <Box p={4}>
                  <EmptyState
                    title="Send a row to view the response"
                    message="We will sync one row to your destination"
                  />
                </Box>
              )}
            </Box>
          </Box>
        </ChakraModalBody>
        <ChakraModalFooter
          p={4}
          mt={0}
          borderTop="1px solid"
          borderColor="base.border"
          boxShadow="sm"
        >
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </ChakraModalFooter>
      </ChakraModalContent>
    </ChakraModal>
  );
};

const getType = (value) => {
  return value === null
    ? "null"
    : typeof value === "object"
      ? Array.isArray(value)
        ? "array"
        : "object"
      : typeof value;
};

const TYPE_OPTIONS = [
  { label: "String", value: "string" },
  { label: "Number", value: "number" },
  { label: "Object", value: "object" },
  { label: "Array", value: "array" },
  { label: "Null", value: "null" },
  { label: "Boolean", value: "boolean" },
];

const cast = {
  string: (v) => v || "",
  number: (v) => {
    if (isNaN(v)) {
      throw new Error("Value is NaN.");
    }
    return Number(v);
  },
  array: (v) => {
    const value = JSON.parse(v);
    if (!Array.isArray(value)) {
      throw new Error("Value is not a valid array.");
    }
    return value;
  },
  object: (v) => {
    const value = JSON.parse(v);
    if (typeof value !== "object") {
      throw new Error("Value is not a valid object.");
    }
    return value;
  },
  boolean: (v) => {
    const value = JSON.parse(v);
    if (typeof value !== "boolean") {
      throw new Error("Value is not a valid boolean.");
    }
    return value;
  },
  null: () => null,
};

const valueToString = (value) => {
  return typeof value === "object" || typeof value === "boolean"
    ? JSON.stringify(value)
    : value;
};

const RowValue = ({
  property,
  value,
  setValue,
  readOnly = false,
  tooltip,
}: {
  property: string;
  value: any;
  setValue?: (value: any) => void;
  readOnly?: boolean;
  tooltip?: string;
}) => {
  const { toast } = useToast();

  const [hover, setHover] = useState(false);
  const [editing, _setEditing] = useState(false);
  const [editValue, setEditValue] = useState<string>();
  const [editType, setEditType] = useState<string>();

  const setEditing = (v: boolean) => {
    if (v) {
      setEditValue(valueToString(value));
      setEditType(getType(value));
    } else {
      setEditValue(undefined);
      setEditType(undefined);
    }
    _setEditing(v);
  };

  const save = () => {
    if (setValue) {
      try {
        const value = editType ? cast[editType](editValue) : undefined;

        if (value === undefined) {
          throw new Error(
            "Value cannot be undefined. To set a null value, use the object type with the input null.",
          );
        }

        setValue(value);
        setEditing(false);
      } catch (e) {
        toast({
          id: "save-row-value",
          title: e?.message ?? "Couldn't update value",
          variant: "error",
        });
      }
    } else {
      setEditing(false);
    }
  };

  return (
    <Row
      sx={{
        flex: 0,
        py: 2,
        px: 4,
        width: "100%",
        borderBottom: "1px",
        borderColor: "base.border",
      }}
      onPointerEnter={() => {
        setHover(true);
      }}
      onPointerLeave={() => {
        setHover(false);
      }}
    >
      <Box
        display="grid"
        gridTemplateColumns="1fr 100px 1fr"
        width="100%"
        height="32px"
        gap={2}
        alignItems="center"
      >
        <TextWithTooltip>{property}</TextWithTooltip>
        {!editing ? (
          <>
            <Row gap={2}>
              <Badge>{getType(value)}</Badge>
              {tooltip && (
                <Tooltip message={tooltip}>
                  <InformationIcon />
                </Tooltip>
              )}
            </Row>
            <Row overflow="hidden" justifyContent="space-between">
              <TextWithTooltip>{valueToString(value)}</TextWithTooltip>

              {!readOnly && hover && (
                <IconButton
                  size="sm"
                  aria-label="Edit value"
                  icon={EditIcon}
                  onClick={() => setEditing(true)}
                />
              )}
            </Row>
          </>
        ) : (
          <>
            <Select
              options={TYPE_OPTIONS}
              size="sm"
              width="auto"
              value={editType}
              onChange={(value) => {
                if (editType !== value) {
                  setEditType(value);
                  setEditValue(undefined);
                }
              }}
            />
            <Row justifyContent="space-between" gap={1}>
              {editType !== "null" ? (
                <TextInput
                  placeholder="Enter a value..."
                  size="sm"
                  width="100%"
                  type={editType === "number" ? "number" : undefined}
                  value={editValue || ""}
                  onChange={(event) => {
                    setEditValue(event.target.value);
                  }}
                />
              ) : (
                <Text>null</Text>
              )}
              <Row>
                <IconButton
                  size="sm"
                  aria-label="Save"
                  icon={CheckIcon}
                  onClick={() => {
                    save();
                  }}
                />
                <IconButton
                  size="sm"
                  aria-label="Cancel"
                  icon={CloseIcon}
                  onClick={() => {
                    setEditing(false);
                  }}
                />
              </Row>
            </Row>
          </>
        )}
      </Box>
    </Row>
  );
};
