import { FC } from "react";

import { Alert, Button } from "@hightouchio/ui";

import { useUser } from "src/contexts/user-context";

export const WorkspaceValidation: FC = () => {
  const { workspaceError } = useUser();

  if (!workspaceError) return null;

  function reload() {
    window.location.reload();
  }

  return (
    <Alert
      variant="banner"
      type="error"
      justify="center"
      title={workspaceError.message}
      message="Reload to continue working in this workspace. You will lose any unsaved changes."
      actions={<Button onClick={reload}>Reload workspace</Button>}
    />
  );
};
