/**
 * Q: Why are we using JSON.stringify instead of string template and a delimiter?
 * A: event_name is user input and might contain our delimiter. Yes, there are ways around that,
 * but JSON.stringify is far simpler. Any performance impact will be dwarfed by the DB IO.
 */

/**
 * Q: Why are we returning a string of the error instead of logging?
 * A: These utilities may be used on the front end; dev should log after parsing.
 */

/**
 * Accepts a JSON.stringified tuple of [eventSourceId, eventType?, eventName?]
 * Returns a parsed object.
 * May include an error field in case the parsing fails.
 */
export function parseEventSourceResourceId(resourceId: string): {
  resourceId: string;
  eventSourceId: string | null;
  eventType: string | null;
  eventName: string | null;
  error?: string;
} {
  const base = {
    resourceId: resourceId,
    eventSourceId: null,
    eventType: null,
    eventName: null,
  };

  try {
    const parsed = JSON.parse(resourceId);
    if (Array.isArray(parsed) && parsed.length == 3) {
      return {
        ...base,
        eventSourceId: parsed[0],
        eventType: parsed[1],
        eventName: parsed[2],
      };
    } else {
      return { ...base, error: "Invalid JSON tuple" };
    }
  } catch (err) {
    return { ...base, error: err.toString() };
  }
}

/**
 * Accepts parsed object representing the event source monitor
 * Returns JSON.stringified tuple of [eventSourceId, eventType, eventName]
 */
export function serializeEventSourceResourceId({
  eventSourceId,
  eventType,
  eventName,
}: {
  eventSourceId: string;
  eventType?: string | null;
  eventName?: string | null;
}): string {
  return JSON.stringify([eventSourceId, eventType ?? null, eventName ?? null]);
}

export const sourceIdLike = (sourceId: string) => `["${sourceId}%`;
