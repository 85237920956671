import {
  forwardRef,
  Fragment,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  Box,
  Column,
  Row,
  SearchInput,
  SparkleIcon,
  SplitIcon,
  TableIcon,
  Text,
  ToggleButton,
  ToggleButtonGroup,
  TraitIcon,
} from "@hightouchio/ui";
import { isEqual } from "lodash";

import placeholder from "src/assets/placeholders/generic.svg";
import { convertType, NEW_ICON_MAP } from "src/utils/destinations";
import { getSearchRegExp } from "src/utils/string";

import {
  ColumnOption,
  ToggleOption,
  useFormkitContext,
} from "../formkit-context";
import { FormProps } from "../types";

interface OnChangeValueProp {
  from: string | Record<string, unknown>;
  type: "standard";
}

const StandardInput = forwardRef<
  HTMLInputElement,
  Readonly<
    FormProps & {
      onChange: (onClose: () => void, value: OnChangeValueProp) => void;
      onClose: () => void;
    }
  >
>(({ onClose, onChange, value }, ref) => {
  const [search, setSearch] = useState<string>("");
  const [selectedSection, setSelectedSection] = useState<
    ToggleOption | undefined
  >(undefined);
  const { columns: allColumns } = useFormkitContext();
  const selectedColumnRef = useRef<HTMLDivElement | null>(null);
  // Used to scroll down to the specific section when a user clicks on the toggle buttons
  const columnSectionRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const splitSectionRef = useRef<HTMLDivElement | null>(null);
  const traitSectionRef = useRef<HTMLDivElement | null>(null);

  const handleToggleChange = (value: ToggleOption): void => {
    setSelectedSection(value);
    // Scroll the selected section into view
    if (containerRef.current) {
      const container = containerRef.current;
      let elementToScroll;

      if (columnSectionRef.current && value === ToggleOption.Column) {
        elementToScroll = columnSectionRef.current;
      } else if (splitSectionRef.current && value === ToggleOption.Split) {
        elementToScroll = splitSectionRef.current;
      } else if (traitSectionRef.current && value === ToggleOption.Trait) {
        elementToScroll = traitSectionRef.current;
      }

      if (!elementToScroll) {
        return;
      }

      const containerRect = container.getBoundingClientRect();
      const elementRect = elementToScroll.getBoundingClientRect();
      const scrollTop =
        container.scrollTop + elementRect.top - containerRect.top;

      // scrollIntoView does not work well with the ChakraPopover as it causes the entire page to scroll
      container.scrollTo({
        behavior: "smooth",
        top: scrollTop,
      });
    }
  };

  const options: ColumnOption[] | undefined = useMemo(() => {
    const nonMbColumns = allColumns?.filter((column) => {
      return column.label !== "boosted";
    });

    if (search) {
      const regex = getSearchRegExp(search, "i");
      const filteredColumns: ColumnOption[] = [];

      for (const column of nonMbColumns ?? []) {
        if (column.options) {
          filteredColumns.push({
            ...column,
            options: column.options.filter(
              (option) =>
                regex.test(option.modelName || "") || regex.test(option.label),
            ),
          });
        } else if (
          regex.test(column.modelName || "") ||
          regex.test(column.label)
        ) {
          filteredColumns.push(column);
        }
      }
      return filteredColumns;
    }

    return nonMbColumns;
  }, [allColumns, search, value]);

  useEffect(() => {
    if (selectedColumnRef && selectedColumnRef.current) {
      selectedColumnRef.current.scrollIntoView();
    }
  }, []);

  const hasMultipleGroups = options.length > 1;

  return (
    <Column flex={1} pl={3} pr={3} pt={1}>
      <Row gap={2}>
        <SearchInput
          autoFocus
          placeholder="Search columns..."
          ref={ref}
          value={search}
          width="100%"
          onChange={(event) => setSearch(event.target.value)}
        />
        {hasMultipleGroups && (
          <ToggleButtonGroup
            value={selectedSection}
            onChange={(value) => handleToggleChange(value as ToggleOption)}
          >
            {options.find((option) => option.label === ToggleOption.Column) && (
              <ToggleButton
                aria-label="Columns"
                icon={TableIcon}
                value={ToggleOption.Column}
              />
            )}
            {options.find((option) => option.label === ToggleOption.Trait) && (
              <ToggleButton
                aria-label="Trait columns"
                icon={TraitIcon}
                value={ToggleOption.Trait}
              />
            )}
            {options.find((option) => option.label === ToggleOption.Split) && (
              <ToggleButton
                aria-label="Split columns"
                icon={SplitIcon}
                value={ToggleOption.Split}
              />
            )}
          </ToggleButtonGroup>
        )}
      </Row>
      <Column height="227px" mt={2} overflow="auto" ref={containerRef}>
        {options.map((option, index) => {
          if (option.options && option.options.length) {
            return (
              <Fragment key={index}>
                {hasMultipleGroups && (
                  <Row>
                    <Row
                      alignItems="center"
                      bg="base.background"
                      borderY="1px"
                      color="base.border"
                      flex="1"
                      minHeight="32px"
                      px={2}
                      ref={
                        option.label === ToggleOption.Column
                          ? columnSectionRef
                          : option.label === ToggleOption.Split
                            ? splitSectionRef
                            : traitSectionRef
                      }
                    >
                      <Box
                        as={option.icon}
                        boxSize={5}
                        color="text.placeholder"
                        mr={3}
                      />
                      <Text
                        color="text.secondary"
                        fontWeight="semibold"
                        isTruncated
                        letterSpacing="wide"
                        size="sm"
                        textTransform="uppercase"
                      >
                        {option.label}
                      </Text>
                    </Row>
                  </Row>
                )}

                {option.options.map((groupedOption) => {
                  const selected = isEqual(groupedOption.value, value.from);
                  return (
                    <Option
                      key={JSON.stringify(groupedOption.value)}
                      ref={selected ? selectedColumnRef : undefined}
                      group={option.label}
                      option={groupedOption}
                      selected={selected}
                      onClick={() =>
                        onChange(onClose, {
                          type: value.type ?? "standard",
                          from: groupedOption.value,
                        })
                      }
                    />
                  );
                })}
              </Fragment>
            );
          }
          if (option.options && !option.options.length) {
            return null;
          }
          const selected = isEqual(option.value, value);
          return (
            <Option
              key={JSON.stringify(option.value)}
              ref={selected ? selectedColumnRef : undefined}
              option={option}
              selected={selected}
              onClick={() =>
                onChange(onClose, {
                  type: value.type ?? "standard",
                  from: option.label,
                })
              }
            />
          );
        })}
        {options.every((option) => !option?.options?.length) && (
          <Column
            alignItems="center"
            border="1px solid"
            borderColor="gray.300"
            borderRadius="md"
            flex={1}
            gap={2}
            justifyContent="center"
            mb={3}
            py={2}
          >
            <Box as="img" height="92px" src={placeholder} />
            <Box
              as={Text}
              color="text.secondary"
              fontFamily="'Sharp Sans Display No 1'"
              fontSize="2xl"
            >
              No results found
            </Box>
            <Text color="text.secondary" size="md" fontWeight="normal">
              Try your search again
            </Text>
          </Column>
        )}
      </Column>
    </Column>
  );
});

StandardInput.displayName = "StandardInput";
export { StandardInput };

const Option = forwardRef<
  HTMLDivElement,
  {
    group?: string;
    option: ColumnOption;
    selected: boolean;
    onClick: () => void;
  }
>(({ group, onClick, option, selected }, ref) => {
  const {
    customType,
    description,
    label,
    modelName,
    modelIcon,
    type = "",
    value,
  } = option;
  const isBoosted = typeof value == "object" && value.type === "boosted";
  const isSplitOrTrait =
    group === ToggleOption.Split || group === ToggleOption.Trait;
  const Icon = isSplitOrTrait
    ? null
    : isBoosted
      ? SparkleIcon
      : NEW_ICON_MAP[convertType(customType || type || "")];

  return (
    <Row>
      <Box
        _hover={{
          bg: "gray.100",
        }}
        alignItems="center"
        bg={selected ? "forest.100" : undefined}
        borderBottom="1px"
        borderColor="gray.300"
        cursor="pointer"
        display="flex"
        flex="1"
        minHeight="32px"
        pointerEvents={selected ? "none" : undefined}
        px={2}
        ref={ref}
        onClick={onClick}
      >
        {Icon ? (
          <Box mr={3}>
            <Box
              as={Icon}
              boxSize={5}
              color={isBoosted ? "warning.400" : "text.placeholder"}
              fontSize="20px"
            />
          </Box>
        ) : (
          <Box boxSize={5} mr={3} />
        )}
        <Box
          alignItems="center"
          display="flex"
          maxWidth="665px"
          minWidth={0}
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          width={description ? "256px" : "auto"}
        >
          <Text
            color={selected ? "forest.600" : "gray.900"}
            fontWeight="medium"
            isTruncated
            mr={2}
            size="sm"
          >
            {label}
          </Text>

          {modelIcon && modelName && (
            <Box display="flex" gap="5px">
              <Box as={modelIcon} boxSize={4} color="text.placeholder" />
              <Text color="text.secondary" fontWeight="normal" size="sm">
                {modelName}
              </Text>
            </Box>
          )}
        </Box>
        {description && (
          <Box
            color="text.secondary"
            fontSize="sm"
            fontWeight="normal"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {description}
          </Box>
        )}
      </Box>
    </Row>
  );
});

Option.displayName = "Option";
