import { ReactNode, Suspense } from "react";

import { Column } from "@hightouchio/ui";
import { Navigate, Outlet, useLocation } from "src/router";

import { Nav } from "src/components/layout/nav/nav";
import { useUser } from "src/contexts/user-context";
import { PageSpinner } from "src/components/loading";
import { ErrorBoundary } from "src/components/error-boundary";

export const Layout = ({ children = null }: { children?: ReactNode }) => {
  const { user, isCompanyRequired } = useUser();
  const location = useLocation();

  if (!user) {
    return (
      <Navigate
        state={location?.state}
        to={`/login?returnTo=${encodeURI(location?.pathname)}`}
      />
    );
  }

  if (isCompanyRequired) {
    return (
      <Navigate
        state={location?.state}
        to={`/restricted?returnTo=${encodeURI(location?.pathname)}`}
      />
    );
  }

  return (
    <>
      {user && <Nav />}
      <Column flex={1} minWidth={0}>
        <ErrorBoundary>
          <Suspense fallback={<PageSpinner />}>
            <Outlet />
            {children}
          </Suspense>
        </ErrorBoundary>
      </Column>
    </>
  );
};
