// Based on: https://github.com/aboveyunhai/chakra-dayzed-datepicker/
import { Fragment, ReactNode, useEffect, useState } from "react";

import {
  Box,
  ChakraPopover,
  ChakraPopoverBody,
  ChakraPopoverContent,
  ChakraPopoverTrigger,
  Portal,
  useDisclosure,
} from "@hightouchio/ui";
import FocusLock from "react-focus-lock";
import { isPresent } from "ts-extras";

import { RangeCalendarPanel } from "./components/range-calendar-panel";
import { Month_Names_Short, Weekday_Names_Short } from "./utils/calendar-utils";
import {
  DatepickerConfigs,
  DatepickerProps,
  OnDateSelected,
} from "./utils/common-types";

export interface DateRangePickerProps extends DatepickerProps {
  selectedDates: Date[];
  children: ReactNode;
  configs?: DatepickerConfigs;
  defaultIsOpen?: boolean;
  onChange: (dates: Date[]) => void;
  usePortal?: boolean;
}

const DefaultConfigs: Required<DatepickerConfigs> = {
  monthNames: Month_Names_Short,
  dayNames: Weekday_Names_Short,
  firstDayOfWeek: 0,
  monthsToDisplay: 2,
};

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  configs,
  usePortal,
  defaultIsOpen = false,
  children,
  selectedDates: initialSelectedDates,
  minDate,
  maxDate,
  onChange,
}) => {
  const [startDate, setStartDate] = useState<Date | null>(
    initialSelectedDates?.[0] ?? null,
  );
  const [endDate, setEndDate] = useState<Date | null>(
    initialSelectedDates?.[1] ?? null,
  );

  // Reset inputs when dates are no longer selected
  useEffect(() => {
    if (initialSelectedDates.length === 0) {
      setStartDate(null);
      setEndDate(null);
    }
  }, [initialSelectedDates.length]);

  const submit = () => {
    if (startDate && endDate) {
      onChange([startDate, endDate]);
      onClose();
    }
  };

  // chakra popover utils
  const [dateInView, setDateInView] = useState(
    initialSelectedDates[0] || new Date(),
  );
  const [offset, setOffset] = useState(-1);
  const { onOpen, onClose, isOpen } = useDisclosure({ defaultIsOpen });

  const datepickerConfigs = {
    ...DefaultConfigs,
    ...configs,
  };

  const onPopoverClose = () => {
    onClose();
    setDateInView(initialSelectedDates[0] || new Date());
    setStartDate(initialSelectedDates?.[0] ?? null);
    setEndDate(initialSelectedDates?.[1] ?? null);
    setOffset(-1);
  };

  const handleOnDateSelected: OnDateSelected = ({ selectable, date }) => {
    if (!selectable) {
      return;
    }

    if (!startDate) {
      setStartDate(date);
    } else if (!endDate) {
      if (startDate < date) {
        setEndDate(date);
      } else {
        // flip dates
        setStartDate(date);
        setEndDate(startDate);
      }
    } else {
      setStartDate(date);
      setEndDate(null);
    }
  };

  const PopoverContentWrapper = usePortal ? Portal : Fragment;

  return (
    <Box sx={{ div: { zIndex: "var(--chakra-zIndices-popover)" } }}>
      <ChakraPopover
        isLazy
        placement="bottom"
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onPopoverClose}
      >
        <ChakraPopoverTrigger>{children}</ChakraPopoverTrigger>

        <PopoverContentWrapper>
          <ChakraPopoverContent width="100%">
            <ChakraPopoverBody p={0}>
              <FocusLock>
                <RangeCalendarPanel
                  dayzedHookProps={{
                    onDateSelected: handleOnDateSelected,
                    selected: [startDate, endDate].filter(isPresent),
                    monthsToDisplay: datepickerConfigs.monthsToDisplay,
                    date: dateInView,
                    minDate: minDate,
                    maxDate: maxDate,
                    offset: offset,
                    onOffsetChanged: setOffset,
                    firstDayOfWeek: datepickerConfigs.firstDayOfWeek,
                  }}
                  configs={datepickerConfigs}
                  startDate={startDate}
                  endDate={endDate}
                  onUpdateStartDate={setStartDate}
                  onUpdateEndDate={setEndDate}
                  onSubmit={submit}
                  onCancel={onPopoverClose}
                />
              </FocusLock>
            </ChakraPopoverBody>
          </ChakraPopoverContent>
        </PopoverContentWrapper>
      </ChakraPopover>
    </Box>
  );
};
