import { FC, useMemo } from "react";

import { Column } from "@hightouchio/ui";

import { useOrganizationMembersQuery } from "src/graphql";
import { useOrganizationPermissions } from "src/components/permission/use-organization-permissions";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { Table } from "src/ui/table";
import { useDebounce } from "use-debounce";

export type Member = {
  user_id: number;
  is_member?: boolean | null;
  is_member_via_sso?: boolean | null;
  user: {
    name: string;
    email: string;
    auth0_auth_id: string | null;
    google_auth_id: string | null;
    azure_ad_auth_id: string | null;
  } | null;
};

export type Props = {
  organizationId: string;
  members?: Array<Member>;
  isMemberDisabled?: (member: Member) => boolean;
  exclude?: Array<string>;
  search?: string;
  onSelect?: (id: number) => void;
  selectedRows?: Array<any>;
};

export const Members: FC<Readonly<Props>> = (props) => {
  const [search] = useDebounce(props.search, 500);
  const { isOrganizationAdmin } = useOrganizationPermissions();

  const { data, isFetching } = useOrganizationMembersQuery(
    {
      search: search ? `%${search}%` : undefined,
      organizationId: props.organizationId,
    },
    {
      enabled: !props.members,
      keepPreviousData: true,
    },
  );

  const members: Array<Member> = useMemo(() => {
    if (props.members) {
      return props.members.filter((member) => {
        if (search) {
          const s = search.toLowerCase();
          return (
            member.user?.name?.toLowerCase().includes(s) ||
            member.user?.email?.toLowerCase().includes(s)
          );
        }
        return true;
      });
    }
    return (
      data?.organization_members_distinct?.filter((member) => {
        return !props.exclude?.includes(member.user_id);
      }) ?? []
    );
  }, [
    search,
    props.members,
    data?.organization_members_distinct,
    props.exclude,
  ]);

  return (
    <Table
      disabled={(m) =>
        !isOrganizationAdmin || Boolean(props.isMemberDisabled?.(m))
      }
      loading={isFetching}
      primaryKey="user_id"
      onSelect={props.onSelect}
      selectedRows={props.selectedRows}
      data={members}
      placeholder={{
        title: "No users found",
        body: props.search
          ? "Try changing your search query"
          : props.exclude
            ? "All users are already assigned"
            : undefined,
      }}
      columns={[
        {
          name: "Name",
          cell: ({ user, is_member, is_member_via_sso }) => (
            <Column>
              <TextWithTooltip fontWeight="medium">
                <>
                  {user?.name}{" "}
                  {is_member_via_sso && !is_member
                    ? "(assigned via SSO)"
                    : null}
                </>
              </TextWithTooltip>
              <TextWithTooltip color="text.secondary">
                {user?.email}
              </TextWithTooltip>
            </Column>
          ),
        },
        {
          name: "Auth method",
          cell: ({ user }) => {
            let authMethod = "Email (magic link)";

            if (user?.google_auth_id) {
              authMethod = "Google";
            } else if (user?.azure_ad_auth_id) {
              authMethod = "Microsoft";
            } else if (user?.auth0_auth_id) {
              authMethod = "Single sign-on";
            }

            return <TextWithTooltip>{authMethod}</TextWithTooltip>;
          },
        },
      ]}
    />
  );
};
