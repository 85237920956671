import type { BaseType, Props, ValidatorSync } from "./base";
import * as yup from "yup";

export interface StringType extends BaseType {
  type: "STRING";
  pattern?: string;
  maxLength?: number;
  minLength?: number;
}

export function string(props?: Props<StringType>): StringType {
  return {
    ...props,
    type: "STRING",
  };
}

export const validator: ValidatorSync<StringType> = (type) => {
  let schema = yup.string();

  if (type.pattern) schema = schema.matches(new RegExp(type.pattern));

  if (typeof type.maxLength === "number") schema = schema.max(type.maxLength);
  if (typeof type.minLength === "number") schema = schema.min(type.minLength);

  return (value) => {
    schema.validateSync(value, { strict: true });
  };
};
