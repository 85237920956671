import { FC } from "react";

import { HighlightStyle, syntaxHighlighting } from "@codemirror/language";
import { Compartment } from "@codemirror/state";
import { Column } from "@hightouchio/ui";
import { tags } from "@lezer/highlight";
import * as diff from "diff";

import { Editor } from "src/components/editor";

import { highlightChanges } from "./editor/highlight-changes";

interface Props {
  diffs: diff.Change[];
}

const highlightChangesCompartment = new Compartment();

const theme = syntaxHighlighting(
  HighlightStyle.define([
    {
      tag: tags.string,
      color: "var(--chakra-colors-base-danger)",
    },
    {
      tag: tags.bracket,
      color: "var(--chakra-colors-gray-800)",
    },
    { tag: tags.number, color: "var(--chakra-colors-gray-800)" },
  ]),
);

export const Diff: FC<Readonly<Props>> = ({ diffs }) => {
  const output = diffs.map((d) => d.value).join("");

  return (
    <Column
      border="1px"
      borderColor="base.border"
      borderRadius="md"
      overflow="hidden"
    >
      <Editor
        readOnly
        extensions={[
          theme,
          highlightChangesCompartment.of(highlightChanges(diffs)),
        ]}
        language="json"
        value={output}
      />
    </Column>
  );
};
