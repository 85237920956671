import { FC, ReactNode } from "react";

import { Box, Text } from "@hightouchio/ui";

export type FieldErrorProps = {
  error?: string | ReactNode | Error | null;
  prefix?: string;
};

export const FieldError: FC<Readonly<FieldErrorProps>> = ({
  prefix,
  error,
}) => {
  if (error) {
    return (
      <Box
        mt={2}
        _firstLetter={{
          textTransform: "capitalize",
        }}
      >
        <Text color="text.danger" size="sm">
          {prefix} {getErrorMessage(error)}
        </Text>
      </Box>
    );
  }

  return null;
};

function getErrorMessage(
  error: string | ReactNode | Error | null,
): string | ReactNode {
  if (!error) {
    return "";
  }

  if (error instanceof Error) {
    return error.message;
  }

  return error;
}
