export const BIGQUERY_REASON = ({ definitionName }) =>
  `The code snippets below create a dedicated ${definitionName} user for Hightouch. It also creates two datasets required for the Lightning engine to efficiently track changes to your data. These caches are used by the Lightning engine to improve sync speed.`;

export const BIGQUERY_USER_ROLE = {
  code: ({ configuration, credential }) =>
    `gcloud projects add-iam-policy-binding ${
      configuration?.project || "<YOUR_PROJECT_NAME>"
    } \\\n--member serviceAccount:${
      credential?.stripped_config.client_email || "<YOUR_SERVICE_ACCOUNT>"
    } \\\n--role roles/bigquery.user`,
  title: () => "Grant permission to read metadata and list tables",
};

export const BIGQUERY_DATAVIEWER_ROLE = {
  code: ({ configuration, credential }) =>
    `gcloud projects add-iam-policy-binding ${
      configuration?.project || "<YOUR_PROJECT_NAME>"
    } \\\n--member serviceAccount:${
      credential?.stripped_config.client_email || "<YOUR_SERVICE_ACCOUNT>"
    } \\\n--role roles/bigquery.dataViewer`,
  title: () => "Grant permission to read data from tables and views",
};
