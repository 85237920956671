import { useCallback, useMemo, useRef } from "react";

import { Buffer } from "buffer";
import { DeepPartial } from "react-hook-form";

import * as Sentry from "@sentry/react";
import { NavigateOptions, useSearchParams } from "src/router";

/**
 * Encode an object to a string using Base64 encryption.
 */
const encode = <T>(value: T) => {
  return Buffer.from(JSON.stringify(value)).toString("base64");
};

/**
 * Decode a string from Base64 format to an object.
 */
const decode = <T>(encodedValue: string): T => {
  return JSON.parse(Buffer.from(encodedValue, "base64").toString("utf8"));
};

export const useEncodedSearchParams = <T extends Record<string, unknown>>(
  key = "data",
): [
  T | null,
  (value: DeepPartial<T>, navigateOptions?: NavigateOptions) => void,
] => {
  const [searchParams, setSearchParams] = useSearchParams();
  const urlValue = searchParams.get(key);
  const previousValueRef = useRef<T | null>(null);

  const get = useCallback((): T | null => {
    try {
      return urlValue !== null ? decode<T>(urlValue) : urlValue;
    } catch (error) {
      Sentry.captureException(error, { extra: { key } });
      return null;
    }
  }, [urlValue]);

  const set = useCallback(
    (value: DeepPartial<T>, options?: NavigateOptions) => {
      const newValue = { ...previousValueRef.current, ...value };

      if (
        JSON.stringify(newValue) !== JSON.stringify(previousValueRef.current)
      ) {
        const newParams = new URLSearchParams(searchParams.toString());

        newParams.set(key, encode(newValue));
        setSearchParams(newParams, { replace: true, ...options });

        previousValueRef.current = newValue as T;
      }
    },
    [searchParams, setSearchParams, key],
  );

  const data = useMemo(() => {
    const newData = get();
    previousValueRef.current = newData;
    return newData;
  }, [get, location.search]);

  return useMemo(() => [data, set], [data, set]);
};
