import {
  Box,
  BoxProps,
  ChakraStyledOptions,
  Tooltip,
  TooltipProps,
} from "@hightouchio/ui";
import { FC } from "react";

type Props = {
  message: TooltipProps["message"];
  children: BoxProps["children"];
};

const sx: ChakraStyledOptions = {
  textDecorationSkipInk: "none",

  // Space (in pixels) between the text and the decoration
  textUnderlineOffset: 3,
};

export const Term: FC<Readonly<Props>> = ({ children, message }) => {
  return (
    <Tooltip message={message}>
      <Box
        textDecoration="underline"
        textDecorationStyle="dashed"
        sx={sx}
        display="inline-block"
      >
        {children}
      </Box>
    </Tooltip>
  );
};
