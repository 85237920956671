import {
  Column,
  EmptyState,
  Paragraph,
  Row,
  SearchInput,
  SectionHeading,
} from "@hightouchio/ui";
import { capitalize, omit } from "lodash";
import pluralize from "pluralize";
import { useMemo, useState } from "react";
import { ActionBar } from "src/components/action-bar";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import {
  GrantObject,
  GrantableResource,
  Grants,
  RESOURCE_GRANTS,
  RESOURCE_GRANT_OPTIONS,
} from "src/components/grant-select/types";
import { UserGroupGrantsInsertInput } from "src/graphql";
import { useOutletContext, useParams } from "src/router";
import { OutletContext } from "..";
import { GrantTableForm } from "./table-form";
import { GrantCardForm } from "./card-form";

export type AdditionalConfigurationProps = {
  value: Record<string, any>;
  onChange: (value: Record<string, any>) => void;
};

export const DEFAULT_GRANT_PLACEHOLDER = "-1";

type ResourceDefinition = {
  id: string;
  name: string;
  definition: {
    name: string;
    icon: string;
  };
  additionalConfiguration?: React.FC<AdditionalConfigurationProps>;
};

type GrantInfo<R extends GrantableResource> = {
  id: string;
  resource_id: string;
  workspace_id: string;
  additional_data?: Record<string, any>;
} & GrantObject<R>;

type Props<R extends GrantableResource> = {
  resourceType: R;
  resources: Array<ResourceDefinition>;
  onSubmit: (values: {
    objects: Array<UserGroupGrantsInsertInput>;
    additionalData: Record<string, Record<string, any>>;
  }) => Promise<void>;
  grants: GrantInfo<R>[];
  formType?: "card" | "table";
};

export const names = {
  source: "sources",
  destination: "destinations",
  parent_model: "parent models",
};

export function WorkspaceResourceGrantsForm<R extends GrantableResource>({
  resourceType,
  resources: unfilteredResources,
  grants,
  onSubmit,
  formType = "table",
}: Props<R>) {
  const { roleId, workspaceId } = useParams();
  const [search, setSearch] = useState("");

  const { isWorkspaceAdmin, isApprovalsRequired } =
    useOutletContext<OutletContext>();

  const resources = useMemo(() => {
    return unfilteredResources.filter((resource) =>
      resource.name.toLowerCase().includes(search.toLowerCase()),
    );
  }, [search, unfilteredResources]);

  const grantOptions =
    RESOURCE_GRANT_OPTIONS(isApprovalsRequired)[resourceType];
  const grantValues = RESOURCE_GRANTS[resourceType];

  const formValues: {
    values: Grants<R>;
    additionalValues: Record<string, Record<string, any>>;
  } = useMemo(() => {
    if (!resources?.length) return { values: {}, additionalValues: {} };

    const values = {};
    const additionalValues = {};
    for (const resource of resources) {
      const grant = grants.find(
        (grant) => String(grant.resource_id) === String(resource.id),
      );
      values[resource.id] = grantValues.reduce((acc, curr) => {
        return {
          ...acc,
          [curr]: grant?.[curr] ?? false,
        };
      }, {});
      additionalValues[resource.id] = grant?.additional_data ?? {};
    }

    const defaultGrant = grants.find((grant) => grant.resource_id === null);

    // Handle the default grant for a resource (e.g. all sources in a workspace)
    values[DEFAULT_GRANT_PLACEHOLDER] = grantValues.reduce((acc, curr) => {
      return {
        ...acc,
        [curr]: defaultGrant?.[curr] ?? false,
      };
    }, {});

    return { values, additionalValues };
  }, [grants, resources]);

  const form = useHightouchForm({
    onSubmit: async ({ grants, additional_data }) => {
      const objects: Array<UserGroupGrantsInsertInput> = Object.entries(
        grants,
      ).map(([resource_id, grants]) => ({
        resource_id:
          resource_id === DEFAULT_GRANT_PLACEHOLDER
            ? null
            : String(resource_id),
        resource_type: resourceType,
        workspace_id: String(workspaceId),
        user_group_id: String(roleId),
        ...omit(grants, ["user_group_id"]),
      }));

      await onSubmit({ objects, additionalData: additional_data });
    },
    values: {
      // @ts-expect-error - React Hook Form doesn't support type generics properly
      grants: formValues.values as Grants<R>,
      additional_data: formValues.additionalValues,
    },
  });

  if (!resources?.length) {
    return (
      <Column p={4}>
        <EmptyState message={`No ${names[resourceType]} in this workspace`} />
      </Column>
    );
  }

  return (
    <Form form={form}>
      <Column flex={1} overflow="auto" p={4}>
        <Row align="center" justify="space-between" mb={4} gap={4}>
          <Column>
            <SectionHeading>{capitalize(names[resourceType])}</SectionHeading>
            <Paragraph color="text.secondary">
              Use the checkboxes below to configure which actions users with
              this custom role can perform for each{" "}
              {pluralize(names[resourceType], 1)} and its related resources.
            </Paragraph>
          </Column>
          <SearchInput
            value={search}
            onChange={(event) => setSearch(event.currentTarget.value)}
            placeholder={`Search by ${pluralize(
              names[resourceType],
              1,
            )} name...`}
          />
        </Row>
        {formType === "table" ? (
          <GrantTableForm
            grantOptions={grantOptions}
            resources={resources}
            resourceType={resourceType}
            isWorkspaceAdmin={isWorkspaceAdmin}
          />
        ) : (
          <GrantCardForm
            grantOptions={grantOptions}
            resources={resources}
            resourceType={resourceType}
            isWorkspaceAdmin={isWorkspaceAdmin}
          />
        )}
      </Column>
      <ActionBar fit>
        <FormActions />
      </ActionBar>
    </Form>
  );
}
