import { MouseEvent } from "react";

import { NavigateFunction } from "src/router";

export const openUrl = (
  url: string,
  navigate: NavigateFunction,
  event: MouseEvent,
) => {
  if (event.metaKey || event.ctrlKey) {
    openUrlInNewTab(url);
  } else {
    navigate(url);
  }
};

export const openUrlInNewTab = (url: string) => {
  const slug = window.location.pathname.split("/")[1];
  const urlWithSlug = url.startsWith(`/${slug}`) ? url : `/${slug}${url}`;
  const win = window.open(urlWithSlug, "_blank");
  win?.focus();
};
