import { forwardRef } from "react";

import {
  Box,
  BoxProps,
  IconButton,
  IconButtonProps,
  Row,
} from "@hightouchio/ui";

const copilotSubmitButtonBase = "copilot-submit-button-base";
const copilotSubmitButtonHover = "copilot-submit-button-hover";
const copilotSubmitButtonActive = "copilot-submit-button-active";
const copilotSubmitButtonDisabled = "copilot-submit-button-disabled";

const backgroundCssProps: BoxProps = {
  top: 0,
  left: 0,
  opacity: 0,
  width: "100%",
  height: "100%",
  borderRadius: "md",
  position: "absolute",
  bg: "transparent",
  pointerEvents: "none",
  transition: "all 100ms ease-in",
};

export const CopilotSubmitButton = forwardRef<HTMLDivElement, IconButtonProps>(
  (props, ref) => {
    const Icon = props.icon;

    return (
      <Box
        ref={ref}
        position="relative"
        sx={{
          ...getOpacityValues("base"),
          _hover: {
            ...getOpacityValues("hover"),
          },
          _active: {
            ...getOpacityValues("active"),
          },
          ...(props.isDisabled && {
            ...getOpacityValues("disabled"),
            _hover: {
              ...getOpacityValues("disabled"),
            },
            active: {
              ...getOpacityValues("disabled"),
            },
          }),

          button: {
            bg: "transparent",
            border: "none",
            boxShadow: "drop-shadow(0 1px 2px rgba(37, 45, 54, 0.04))",
            transition: "100ms ease-in-out",
            svg: {
              fill: "transparent",
            },
            _focusWithin: {
              boxShadow:
                "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 0px 0px 3px rgba(242, 231, 254, 0.80)",
            },
          },
        }}
      >
        <IconButton {...props} />
        <Box
          id="copilot-submit-button-base"
          {...backgroundCssProps}
          bg="linear-gradient(180deg, #8743D2 0%, #B061FF 100%)"
        />
        <Box
          id="copilot-submit-button-hover"
          {...backgroundCssProps}
          bg="electric.700"
        />
        <Box
          id="copilot-submit-button-active"
          {...backgroundCssProps}
          bg="electric.800"
        />
        <Box
          id="copilot-submit-button-disabled"
          {...backgroundCssProps}
          bg="electric.300"
        />
        <Row
          id="copilot-submit-button-icon"
          color="white"
          {...backgroundCssProps}
          opacity={1}
          justify="center"
        >
          <Icon />
        </Row>
      </Box>
    );
  },
);

CopilotSubmitButton.displayName = "CopilotSubmitButton";

const getOpacityValues = (state: "base" | "active" | "hover" | "disabled") => {
  switch (state) {
    case "hover":
      return {
        [`#${copilotSubmitButtonBase}`]: {
          opacity: `0`,
        },
        [`#${copilotSubmitButtonHover}`]: {
          opacity: `1`,
        },
        [`#${copilotSubmitButtonActive}`]: {
          opacity: `0`,
        },
        [`#${copilotSubmitButtonDisabled}`]: {
          opacity: `0`,
        },
      };
    case "active":
      return {
        [`#${copilotSubmitButtonBase}`]: {
          opacity: `0`,
        },
        [`#${copilotSubmitButtonHover}`]: {
          opacity: `0`,
        },
        [`#${copilotSubmitButtonActive}`]: {
          opacity: `1`,
        },
        [`#${copilotSubmitButtonDisabled}`]: {
          opacity: `0`,
        },
      };
    case "disabled":
      return {
        [`#${copilotSubmitButtonBase}`]: {
          opacity: `0`,
        },
        [`#${copilotSubmitButtonHover}`]: {
          opacity: `0`,
        },
        [`#${copilotSubmitButtonActive}`]: {
          opacity: `0`,
        },
        [`#${copilotSubmitButtonDisabled}`]: {
          opacity: `1`,
        },
      };
    case "base":
    default:
      return {
        [`#${copilotSubmitButtonBase}`]: {
          opacity: `1`,
        },
        [`#${copilotSubmitButtonHover}`]: {
          opacity: `0`,
        },
        [`#${copilotSubmitButtonActive}`]: {
          opacity: `0`,
        },
        [`#${copilotSubmitButtonDisabled}`]: {
          opacity: `0`,
        },
      };
  }
};
