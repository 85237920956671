export const Icon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20px"
    height="20px"
    viewBox="0 0 24 24"
    fill={color}
  >
    <path
      fillRule="evenodd"
      d="M9.178 3.365c-2.258-.664-4.099-.418-5.165.648-1.066 1.066-1.312 2.907-.648 5.165.658 2.238 2.184 4.762 4.44 7.018 1.342 1.342 2.78 2.426 4.195 3.222 1.416-.796 2.853-1.88 4.196-3.222.311-.311.609-.628.892-.949a.5.5 0 0 1 .75.663c-.298.336-.61.667-.935.993a20.926 20.926 0 0 1-3.85 3.057c.602.28 1.195.506 1.769.675 2.259.664 4.099.418 5.165-.648 1.066-1.066 1.312-2.906.648-5.165a13.43 13.43 0 0 0-1.058-2.533.505.505 0 0 1-.053-.098c-.805-1.477-1.925-2.984-3.328-4.387-1.343-1.342-2.78-2.426-4.196-3.222-1.416.796-2.853 1.88-4.196 3.222-.325.326-.636.657-.93.992a.5.5 0 0 1-.752-.66c.31-.352.635-.698.975-1.039a20.932 20.932 0 0 1 3.85-3.057 12.677 12.677 0 0 0-1.769-.675Zm2.822.08a14.256 14.256 0 0 0-2.54-1.04c-2.406-.707-4.708-.545-6.154.9-1.446 1.447-1.608 3.749-.9 6.155.243.827.592 1.68 1.039 2.54-.857 1.648-1.36 3.288-1.437 4.76-.08 1.544.308 2.944 1.298 3.934 1.446 1.447 3.748 1.608 6.154.9A14.25 14.25 0 0 0 12 20.555c.86.448 1.713.796 2.54 1.04 2.406.707 4.708.546 6.154-.9 1.447-1.447 1.608-3.749.9-6.155A14.265 14.265 0 0 0 20.555 12c.857-1.646 1.36-3.285 1.437-4.757.081-1.545-.307-2.946-1.298-3.937-1.446-1.446-3.748-1.608-6.154-.9A14.25 14.25 0 0 0 12 3.445Zm1.053.595a20.928 20.928 0 0 1 3.85 3.057 20.93 20.93 0 0 1 3.057 3.85c.625-1.342.974-2.627 1.033-3.756.072-1.356-.273-2.445-1.006-3.178-1.066-1.066-2.906-1.312-5.165-.648a12.68 12.68 0 0 0-1.769.675Zm-2.106 15.92a20.93 20.93 0 0 1-3.85-3.057 20.928 20.928 0 0 1-3.057-3.85c-.625 1.343-.974 2.63-1.033 3.76-.071 1.354.274 2.442 1.006 3.174 1.066 1.066 2.907 1.312 5.165.648a12.684 12.684 0 0 0 1.77-.675Z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M12 14.005a2.004 2.004 0 1 0 0-4.008 2.004 2.004 0 0 0 0 4.008Zm0 1a3.004 3.004 0 1 0 0-6.008 3.004 3.004 0 0 0 0 6.008Z"
      clipRule="evenodd"
    />
  </svg>
);
