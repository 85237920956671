import type { BaseType, Props, ValidatorSync } from "./base";
import * as yup from "yup";

export interface PhoneType extends BaseType {
  type: "PHONE";
  format?: string;
}

export function phone(opts?: Props<PhoneType>): PhoneType {
  return {
    ...opts,
    type: "PHONE",
  };
}

export const validator: ValidatorSync<PhoneType> = (type) => {
  let schema = yup.string();

  if (type.format) schema = schema.matches(new RegExp(type.format));

  return (value) => {
    schema.validateSync(value, { strict: true });
  };
};
