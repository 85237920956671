import * as Yup from "yup";
import type {
  FormkitNode,
  FormkitSection,
  RadioGroupOption,
  RichTextEditorProps,
} from "../../api";
import { Field, RadioGroup, RowsPerBatchSection, switchOnStateKey } from "..";
import { Section } from "../layouts";
import { Editor } from "../components";
import {
  EditorSection,
  ModeSection,
  RichTextEditorSection,
  SendEmptySection,
} from "../sections";

const FORMATS = {
  message: {
    label: "Message",
    value: "message",
    description:
      "Sends individual messages for each record that was added or removed since the last sync. There will be no messages for the initial run.",
  },
  batchMessage: {
    label: "Batch Message",
    value: "batchMessage",
    description:
      "Sends a formatted message or multiple messages with plaintext of all the records in the query results.",
  },
  table: {
    label: "Table",
    value: "table",
    description:
      "Sends a formatted table of all records in the query results for each sync as a single message.",
  },
  csv: {
    label: "CSV",
    value: "csv",
    description:
      "Sends a CSV file of all records in the query results for each sync attached to a single message.",
  },
};

const FORMAT_BODIES = {
  message: [
    {
      key: "addedBody",
      action: "added",
    },
    {
      key: "changedBody",
      action: "changed",
    },
    {
      key: "removedBody",
      action: "removed",
    },
  ],
};

type EditorOptions = {
  language?: "liquid" | "json";
  isBlockKit?: boolean;
  beautifyJson?: boolean;

  /**
   * The placeholder may contain {{action}} which will be replaced with body.action (added/updated/removed)
   */
  placeholder?: string;
};

type StateCase<Props, Opts = Record<string, unknown>> = {
  value: string | number | boolean;
  opts?: Props &
    Opts & {
      additional?: FormkitNode[];
    };
};

type StateCaseRequiredProps<Props, Opts = Record<string, unknown>> = {
  value: string | number | boolean;
  opts: Props &
    Opts & {
      additional?: FormkitNode[];
    };
};

type MessageCase =
  | (StateCase<EditorOptions> & { type?: "editor" })
  | (StateCaseRequiredProps<RichTextEditorProps> & { type: "richTextEditor" });

type MessageStateCase = {
  key: string;
  messageCases?: MessageCase[];
};

type BatchProps = {
  append?: boolean;
  isSection?: boolean;
  additional?: FormkitNode[];
  headerPlaceholder?: string;
  bodyPlaceholder?: string;
  footerPlaceholder?: string;
};

type BatchCase =
  | (StateCase<EditorOptions, BatchProps> & { type?: "editor" })
  | (StateCase<RichTextEditorProps, BatchProps> & { type: "richTextEditor" });

type BatchStateCase = {
  key: string;
  batchCases?: BatchCase[];
};

export function messageFormHelper(props: {
  modes: ("message" | "batchMessage" | "table" | "csv")[];
  recipientSection: FormkitNode[];
  batchRecipientSection: FormkitNode[];
  tableRecipientSection?: FormkitNode[];
  csvRecipientSection?: FormkitNode[];
  message: MessageStateCase;
  batchMessage: BatchStateCase;
  table?: {
    bodies: FormkitNode[];
  };
  csv?: {
    bodies: FormkitNode[];
  };
  /**
   * Props overrides to rowsPerBatch section.
   */
  rowsPerBatchProps?: Partial<Parameters<typeof RowsPerBatchSection>[0]>;
}): FormkitNode[] {
  const {
    modes,
    recipientSection,
    batchRecipientSection,
    tableRecipientSection,
    csvRecipientSection,
    message,
    batchMessage,
    table,
    csv,
  } = props;
  const modeSection = messageModeSection(modes);
  const modeCases: {
    value: string | number | boolean;
    children: FormkitNode[];
  }[] = [];
  let messageSection;
  // Essentially used as a built-in `switchOnStateKey` for this helper
  // Ex: Slack form needs to display Beautify JSON and Test Block Kit buttons based on some other form value
  if (message.messageCases && message.messageCases.length > 1) {
    const switchCases: {
      value: string | number | boolean;
      children: FormkitNode[];
    }[] = message.messageCases.map((messageCase) => {
      const additional = messageCase.opts?.additional || [];
      return {
        value: messageCase.value,
        children: [...messageBodySections(messageCase), ...additional],
      };
    });
    messageSection = [switchOnStateKey(message.key, switchCases)];
  } else {
    const additional = message?.messageCases?.[0]?.opts?.additional || [];
    messageSection = [
      ...messageBodySections(message?.messageCases?.[0]),
      ...additional,
    ];
  }

  modeCases.push({
    value: "message",
    children: [skipFirstRunSection, ...recipientSection, ...messageSection],
  });

  const rowsSection = RowsPerBatchSection({
    heading: "How many rows would you like to send per message?",
    placeholder: "10",
    max: 1000,
    default: 10,
    ...props.rowsPerBatchProps,
  });

  let batchSection;
  if (batchMessage.batchCases && batchMessage.batchCases?.length > 1) {
    const switchCases: {
      value: string | number | boolean;
      children: FormkitNode[];
    }[] = batchMessage.batchCases.map((batchCase) => {
      return {
        value: batchCase.value,
        children: [...batchBodySections(batchCase.opts)],
      };
    });
    batchSection = [switchOnStateKey(batchMessage.key, switchCases)];
  } else {
    batchSection = batchBodySections(batchMessage.batchCases?.[0]?.opts);
  }

  modeCases.push({
    value: "batchMessage",
    children: [
      ...batchRecipientSection,
      ...batchSection,
      rowsSection,
      SendEmptySection(),
    ],
  });

  if (modes.includes("table")) {
    const bodies = table?.bodies || [];
    modeCases.push({
      value: "table",
      children: [
        ...(tableRecipientSection || batchRecipientSection),
        ...bodies,
        SendEmptySection(),
      ],
    });
  }

  if (modes.includes("csv")) {
    const bodies = csv?.bodies || [];
    modeCases.push({
      value: "csv",
      children: [
        ...(csvRecipientSection || batchRecipientSection),
        ...bodies,
        SendEmptySection(),
      ],
    });
  }
  return [modeSection, switchOnStateKey("mode", modeCases)];
}

export function messageModeSection(keys: string[]): FormkitSection {
  const formats: RadioGroupOption[] = [];
  for (const key of keys) {
    formats.push(FORMATS[key]);
  }

  return ModeSection({
    options: formats,
  });
}

export const skipFirstRunSection = Section({
  heading:
    "Do you want to send messages for all records during the initial sync run?",
  subheading:
    "Warning: changing this setting after the initial sync run may cause undesired behavior for rows subsequently added to your query result.",
  children: [
    RadioGroup("skipFirstRun", {
      options: [
        {
          value: true,
          label: "No, skip existing rows (most common)",
          description: "The initial sync run will not send any messages.",
        },
        {
          value: false,
          label: "Yes, backfill all rows",
          description: `The initial sync run will trigger a "row added" message for each row.`,
        },
      ],
      validation: Yup.boolean().required(),
      disableDefault: true,
    }),
  ],
});

export function messageBodySections(
  messageCase: MessageCase | undefined,
): FormkitSection[] {
  if (messageCase?.type === "richTextEditor") {
    const props = messageCase.opts;
    return FORMAT_BODIES.message.map((body) => {
      return RichTextEditorSection(body.key, {
        placeholder: props?.placeholder
          ? props.placeholder.replace("{{action}}", body.action)
          : `User with name {{ row["name"] }} and email {{ row["email"] }} has been ${body.action}`,
        heading: `What content would you like to send when a record is ${body.action}?`,
        subheading: `Enter a message template for when a record is ${body.action}`,
        optional: body.key !== "addedBody",
        profile: props.profile,
        handler: props.handler,
      });
    });
  }

  const props = messageCase?.opts;
  const mode = props?.language || "liquid";
  return FORMAT_BODIES.message.map((body) => {
    return EditorSection(body.key, {
      placeholder: props?.placeholder
        ? props.placeholder.replace("{{action}}", body.action)
        : `User with name {{ row["name"] }} and email {{ row["email"] }} has been ${body.action}`,
      heading: `What content would you like to send when a record is ${body.action}?`,
      subheading: `Enter a message template for when a record is ${body.action}`,
      optional: body.key !== "addedBody",
      language: mode,
      beautifyJson: props?.beautifyJson,
    });
  });
}

export function batchBodySections(
  props?: EditorOptions & {
    append?: boolean;
    additional?: FormkitNode[];
    isSection?: boolean;
    headerPlaceholder?: string;
    bodyPlaceholder?: string;
    footerPlaceholder?: string;
  },
): FormkitNode[] {
  const mode = props?.language || "liquid";
  const sections: FormkitNode[] = [];
  const editors: FormkitNode[] = [];
  if (!props?.append && props?.additional && !props?.isSection) {
    editors.push(...props.additional);
  } else if (!props?.append && props?.additional && props?.isSection) {
    sections.push(...props.additional);
  }
  editors.push(
    ...[
      Field({
        heading: "Message header",
        optional: true,
        component: Editor("header", {
          placeholder:
            props?.headerPlaceholder ||
            "The following users have signed up in the last week:",
          language: mode,
          beautifyJson: props?.beautifyJson,
        }),
      }),
      Field({
        heading: "Message body",
        optional: false,
        component: Editor("body", {
          placeholder: props?.bodyPlaceholder || "- {{ name }}, {{ email }}",
          language: mode,
          beautifyJson: props?.beautifyJson,
        }),
      }),
      Field({
        heading: "Message footer",
        optional: true,
        component: Editor("footer", {
          placeholder: props?.footerPlaceholder || "Please make them happy!",
          language: mode,
          beautifyJson: props?.beautifyJson,
        }),
      }),
    ],
  );
  if (props?.append && props?.additional && !props?.isSection) {
    editors.push(...props.additional);
  }
  sections.push(
    Section({
      heading: "Would you like to customize the content?",
      children: editors,
    }),
  );
  if (props?.append && props?.additional && props?.isSection) {
    sections.push(...props.additional);
  }

  return sections;
}
