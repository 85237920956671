import FuzzySet from "fuzzyset";

import { ColumnOption } from "src/formkit/components/formkit-context";
import { ColumnReference, isColumnReference } from "src/types/visual";
import { flattenOptions, Option } from "src/ui/select";
import { StandardFieldType } from "src/utils/destinations";

type BaseField = {
  label: string;
  value: string | ColumnReference;
  object?: { label: string; value: string };
  required?: boolean;
};

type StandardField = BaseField & {
  type?: Exclude<StandardFieldType, StandardFieldType.REFERENCE>;
  extendedType?: { type: string; semanticColumnType?: string };
};
type ReferenceField = BaseField & {
  type: StandardFieldType.REFERENCE;
  referenceObjects: { label: string; value: string }[];
};

type Field = StandardField | ReferenceField;

/**
 * Matches similar columns and fields to create array of mappings.
 * Iterates through fields comparing to all columns.
 * @param columns
 * @param fields Fields that you want matched (generally unmatched options)
 * @returns Array of Mappings
 */
export const automap = (
  columns: ColumnOption[],
  fields: Field[],
): Mapping[] => {
  // Filter out boosted columns
  const filteredCols = columns.filter((col) => col.label !== "boosted");

  const flatOptions = flattenOptions(filteredCols);

  const matcher = FuzzySet(
    flatOptions.map((f) => (isColumnReference(f.value) ? f.label : f.value)),
  );
  const matched: Mapping[] = [];

  for (const field of fields) {
    if (isColumnReference(field.value)) {
      //Unable to use matcher here; we are unable to retrieve value as an object as it was mapped.
      matched.push(suggest(field, flatOptions));
    } else {
      const [result] = matcher.get(field.value, [], 0.74);

      if (!result) {
        continue;
      }
      const column = result[1];
      matched.push(getMappingFromField(column, field));
    }
  }

  return matched;
};

/**
 * Matches a single column to a list of fields
 * Used for when column contains a reference
 * @param column
 * @param fields
 * @returns Mapping
 */
export const suggest = (
  column: { label: string; value: ColumnReference | string },
  fields: (Field | Option)[] = [],
): Mapping => {
  const matcher = FuzzySet(
    fields.map((f) => (isColumnReference(f.value) ? f.label : f.value)),
  );
  const [result] = matcher.get(
    isColumnReference(column.value) ? column.label : column.value,
    [],
    0.74,
  );
  if (!result) {
    return {
      from: column.value,
      to: undefined,
      object: undefined,
      type: "standard",
    };
  }

  const field = result[1];

  return getMappingFromField(
    column.value,
    fields.find(
      (f) => field === (isColumnReference(f.value) ? f.label : f.value),
    ),
  );
};

interface Mapping {
  lookup?: {
    by: null;
    byType: null;
    from: ColumnReference | string;
    object: unknown;
  };
  from?: ColumnReference | string;
  to: string | undefined;
  object: string | undefined;
  type: "reference" | "standard";
}

export const getMappingFromField = (
  columnValue,
  field: Field | Option | undefined,
): Mapping => {
  if (field?.type === StandardFieldType.REFERENCE) {
    return {
      lookup: {
        by: null,
        byType: null,
        from: columnValue,
        object:
          ("referenceObjects" in field &&
            field?.referenceObjects?.[0]?.value) ??
          field.value,
      },
      to: isColumnReference(field.value) ? field.label : field.value,
      object: field?.object?.value,
      type: "reference",
    };
  } else {
    const toValue = !field
      ? undefined
      : isColumnReference(field.value)
        ? field.label
        : field.value;
    return {
      from: columnValue,
      to: toValue,
      object: field?.object?.value,
      type: "standard",
    };
  }
};
