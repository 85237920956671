import { FC } from "react";

import { ChannelType } from "@hightouch/lib/resource-monitoring/types";
import {
  Menu,
  MenuActionsButton,
  MenuList,
  MenuDivider,
} from "@hightouchio/ui";
import { capitalize } from "lodash";

import { useNavigate } from "src/router";
import {
  useDisconnectSlackMutation,
  useDisconnectPagerdutyMutation,
} from "src/graphql";
import { PermissionedMenuItem } from "src/components/permission";

const MonitoringCredentialsPermission = {
  v1: { resource: "workspace", grant: "update" },
  v2: { resource: "workspace", grant: "can_update" },
} as const;

export const ChannelMenu: FC<{ channelType: ChannelType }> = ({
  channelType,
}) => {
  const navigate = useNavigate();

  const { mutateAsync: disconnectSlackCredentials } =
    useDisconnectSlackMutation();
  const { mutateAsync: disconnectPagerdutyCredentials } =
    useDisconnectPagerdutyMutation();

  if (
    channelType !== ChannelType.Slack &&
    channelType !== ChannelType.PagerDuty
  ) {
    return null;
  }

  let disconnectCredentials;

  if (channelType === ChannelType.Slack) {
    disconnectCredentials = disconnectSlackCredentials;
  }

  if (channelType === ChannelType.PagerDuty) {
    disconnectCredentials = disconnectPagerdutyCredentials;
  }

  return (
    <Menu>
      <MenuActionsButton size="sm" />
      <MenuList>
        {channelType === ChannelType.Slack && (
          <PermissionedMenuItem
            permission={MonitoringCredentialsPermission}
            onClick={() => {
              window.open(
                `${import.meta.env.VITE_API_BASE_URL}/slack-alerts/oauth`,
                "_blank",
              );
            }}
          >
            Re-authorize Slack
          </PermissionedMenuItem>
        )}
        {channelType === ChannelType.PagerDuty && (
          <PermissionedMenuItem
            permission={MonitoringCredentialsPermission}
            onClick={() => {
              navigate(`${channelType}/manage`);
            }}
          >
            Manage PagerDuty credentials
          </PermissionedMenuItem>
        )}
        <MenuDivider />
        <PermissionedMenuItem
          permission={MonitoringCredentialsPermission}
          variant="danger"
          onClick={() => disconnectCredentials()}
        >
          Disconnect {capitalize(channelType)}
        </PermissionedMenuItem>
      </MenuList>
    </Menu>
  );
};
