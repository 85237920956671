import { CheckboxProps, TooltipProps } from "@hightouchio/ui";
import {
  GrantObject,
  GrantableResource,
  Grants,
  RESOURCE_GRANTS,
  ResourceGrantsFor,
} from "./types";
import { BuiltInPermissionSet } from "src/pages/organizations/roles/types";

export const BUILT_IN_GRANTS: {
  [r in GrantableResource]: {
    [k in (typeof RESOURCE_GRANTS)[r][number]]: BuiltInPermissionSet[];
  };
} = {
  destination: {
    can_draft: [
      "workspace_draft_editor",
      "workspace_editor",
      "workspace_admin",
    ],
    can_sync: ["workspace_editor", "workspace_admin"],
    can_edit: ["workspace_admin"],
    can_run: ["workspace_draft_editor", "workspace_editor", "workspace_admin"],
  },
  parent_model: {
    can_access_data: [
      "workspace_draft_editor",
      "workspace_editor",
      "workspace_admin",
    ],
    can_sync: ["workspace_draft_editor", "workspace_editor", "workspace_admin"],
  },
  source: {
    can_draft: [
      "workspace_draft_editor",
      "workspace_editor",
      "workspace_admin",
    ],
    can_sync: ["workspace_editor", "workspace_admin"],
    can_edit: ["workspace_admin"],
    can_access_data: [
      "workspace_draft_editor",
      "workspace_editor",
      "workspace_admin",
    ],
    can_manage_schema: ["workspace_admin"],
  },
};

export function getBuiltInGrantValue(
  resource: GrantableResource,
  grant: ResourceGrantsFor<GrantableResource>,
  builtInPermissionSet: BuiltInPermissionSet,
): boolean {
  return BUILT_IN_GRANTS[resource][grant].includes(builtInPermissionSet);
}

export function getCheckboxProps({
  value,
  defaultValue,
  isWorkspaceAdmin,
  isBuiltIn,
}: {
  value: boolean | undefined;
  defaultValue: boolean | undefined;
  isWorkspaceAdmin: boolean;
  isBuiltIn: boolean;
}): {
  checkboxProps: Pick<CheckboxProps, "isChecked" | "isDisabled">;
  tooltipProps: Pick<TooltipProps, "message" | "isDisabled">;
} {
  // The default value applies to standalone resources (e.g. sources and destinations)
  const baseValue = Boolean(value || defaultValue);

  // Workspace admins always have full access
  if (isWorkspaceAdmin) {
    return {
      checkboxProps: { isChecked: baseValue, isDisabled: true },
      tooltipProps: {
        message: "This role is a workspace admin and has full access.",
        isDisabled: false,
      },
    };
  }

  // Built-in roles cannot be changed
  // Some built-in roles may have access (e.g. admin), while others may not (e.g. viewer)
  if (isBuiltIn) {
    return {
      checkboxProps: {
        isChecked: baseValue,
        isDisabled: true,
      },
      tooltipProps: {
        message: "This role is a built-in role and cannot be changed.",
        isDisabled: false,
      },
    };
  }

  // If a default value has been set, it cannot be changed at the resource level
  if (defaultValue) {
    return {
      checkboxProps: { isChecked: baseValue, isDisabled: true },
      tooltipProps: {
        message: "This access is set by default and cannot be overridden.",
        isDisabled: false,
      },
    };
  }

  // Otherwise, this is a standalone resource
  return {
    checkboxProps: { isChecked: baseValue },
    tooltipProps: {
      isDisabled: true,
      message: "",
    },
  };
}

export function convertGrants<R extends GrantableResource>(
  grants: Grants<R>,
): Array<GrantObject<R> & { user_group_id: string }> {
  return Object.entries(grants)
    .filter(([_ug, g]) => Boolean(g))
    .map(([ug, g]) => ({ user_group_id: ug, ...g }));
}
