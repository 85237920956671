import { FC, useEffect, useState } from "react";

import {
  Alert,
  Box,
  ChakraUnorderedList,
  Column,
  Combobox,
  MergeIcon,
  Row,
  Text,
} from "@hightouchio/ui";

import { OverlaySpinner } from "src/components/loading";
import { AudienceBreakdownResult } from "src/graphql";
import { FilterableColumn, isRelatedColumn } from "src/types/visual";

import { BreakdownGraph } from "./breakdowns/breakdown-graph";
import { HexColors } from "./constants";

export type BreakdownColumn = {
  alias: string | null;
  name: string;
  type: string;
  modelId: string;
};
export type ChartType = "pie" | "table";
type Props = {
  breakdownData: AudienceBreakdownResult["columns"];
  columns: FilterableColumn[];
  graphWidth?: number;
  loading?: boolean;
  isFastQueryEnabled: boolean;
  onRemoveBreakdown(columnName: string): void;
  onSubmit(column: BreakdownColumn): void;
};

export const AudienceBreakdowns: FC<Props> = ({
  breakdownData,
  columns,
  graphWidth = 400,
  loading,
  isFastQueryEnabled,
  onRemoveBreakdown,
  onSubmit,
}) => {
  const [selectedColumn, setSelectedColumn] = useState<
    FilterableColumn | undefined
  >();
  const [activeGraph, setActiveGraph] = useState<null | number>(null);

  // To fix typing inferences in combobox, have the column be a property in the object
  const filterableColumns = columns.map((column) => ({ column }));

  const [showFastQueryWarning, setShowFastQueryWarning] =
    useState(isFastQueryEnabled);

  useEffect(() => {
    // If fast query was turned off, we should hide the warning as well
    if (showFastQueryWarning && !isFastQueryEnabled) {
      setShowFastQueryWarning(isFastQueryEnabled);
    }
  }, [showFastQueryWarning, isFastQueryEnabled, setShowFastQueryWarning]);

  const resetForm = () => {
    setSelectedColumn(undefined);
  };

  const selectColumn = (column: FilterableColumn) => {
    const selection = {
      ...column.column_reference,
      alias: column.alias,
    };

    setSelectedColumn(column);
    onSubmit(selection);
  };

  useEffect(() => {
    resetForm();
  }, [breakdownData.length]);

  const hasBreakdowns = breakdownData.length > 0;

  return (
    <Column flex={1} minHeight={0} overflowY="auto">
      {!breakdownData.length && (
        <Column
          flex={1}
          minHeight={0}
          justifyContent="center"
          alignItems="center"
          mx={4}
          overflowY="auto"
          pb={2}
        >
          {showFastQueryWarning && (
            <Alert
              mb={4}
              variant="inline"
              type="subtle"
              title="Breakdowns are using fast query mode"
              message="Fast query mode may introduce minor error. For exact breakdown calculations, disable fast query mode."
              onDismiss={() => setShowFastQueryWarning(false)}
            />
          )}
          <Box
            sx={{
              alignItems: "flex-end",
              flexShrink: 0,
              width: "141px",
              height: "150px",
              mb: 4,
            }}
          >
            <Box
              borderRadius="2px 2px 0 0"
              sx={{
                display: "inline-block",
                width: "25%",
                height: "100%",
                bg: HexColors[0]?.base,
              }}
            />
            <Box
              borderRadius="0 2px 0 0"
              sx={{
                display: "inline-block",
                width: "25%",
                height: "77%",
                bg: HexColors[1]?.base,
              }}
            />
            <Box
              borderRadius="0 2px 0 0"
              sx={{
                display: "inline-block",
                width: "25%",
                height: "50%",
                bg: HexColors[2]?.base,
              }}
            />
            <Box
              borderRadius="0 2px 0 0"
              sx={{
                display: "inline-block",
                width: "25%",
                height: "25%",
                bg: HexColors[3]?.base,
              }}
            />
          </Box>

          <Row align="center" px={10}>
            <Box as={Text} color="text.secondary" textAlign="center">
              Visualize how this audience breaks down by a column property
            </Box>
          </Row>
        </Column>
      )}

      <Box position="relative">
        {breakdownData.length > 0 && (
          <>
            {loading && (
              <OverlaySpinner sx={{ zIndex: "modal", height: "100%" }} />
            )}
            <ChakraUnorderedList width="100%" flexWrap="wrap" mx={0} p={0}>
              {breakdownData.map(({ name, values }, index) => (
                <BreakdownGraph
                  key={`name-${index}`}
                  data={values}
                  graphName={name}
                  graphWidth={graphWidth}
                  isActive={index === activeGraph}
                  secondaryAxisLabel="Count"
                  onMouseEnterCell={() => {
                    setActiveGraph(index);
                  }}
                  onMouseLeaveCell={() => setActiveGraph(null)}
                  onRemoveGraph={onRemoveBreakdown}
                />
              ))}
            </ChakraUnorderedList>
          </>
        )}
      </Box>

      <Row pb={6}>
        <Combobox
          isLoading={loading}
          placeholder={`Select ${
            hasBreakdowns ? "another" : "a"
          } column to breakdown`}
          options={filterableColumns}
          optionValue={(column) => column.column}
          optionLabel={({ column }) => column.alias || column.name}
          valueLabel={({ column }) => column.alias || column.name}
          optionDescription={({ column }) => column.description ?? ""}
          _internalRenderLabelContent={({ column }) => (
            <>
              {column.alias || column.name}
              {isRelatedColumn(column.column_reference) && (
                <Row
                  as={Text}
                  color="text.secondary"
                  align="center"
                  display="contents"
                >
                  <Box as={MergeIcon} flexShrink={0} ml={1} mb={0.5} />{" "}
                  {column.model_name}
                </Row>
              )}
            </>
          )}
          value={selectedColumn}
          width="100%"
          onChange={(value) => {
            if (!value) {
              return;
            }

            selectColumn(value);
          }}
        />
      </Row>
    </Column>
  );
};
