import { FC } from "react";

import { Column, Text } from "@hightouchio/ui";

import { MAX_CARD_WIDTH } from "src/pages/journeys/constants";

import { NODE_LIBRARY_OPTIONS } from "./constants";
import { NodeOption } from "./node-option";

export const NodeLibrary: FC = () => {
  return (
    <Column
      width={`${MAX_CARD_WIDTH}px`}
      p="16px 0px 8px 0px"
      gap={2}
      justify="space-between"
      bg="white"
      border="1px solid"
      borderColor="base.border"
      borderRadius="md"
    >
      <Text size="lg" fontWeight="medium" mx="4">
        Tile library
      </Text>
      <Column as="ul" p={0}>
        {NODE_LIBRARY_OPTIONS.map((option) => (
          <NodeOption key={option.label} {...option} />
        ))}
      </Column>
    </Column>
  );
};
