import { ReactElement } from "react";

import {
  ClickIcon,
  IdentityIcon,
  MonitorIcon,
  MobilePhoneIcon,
  AudienceIcon,
  BidirectionalArrowIcon,
  ArrayIcon,
  NumberIcon,
  ObjectIcon,
  TextStringIcon,
  BooleanIcon,
} from "@hightouchio/ui";

import { SchemaPropertyType } from "src/events/contracts/types";
import { EventSchemaEventType } from "src/graphql";

const eventTypeIconMap: Record<EventSchemaEventType, ReactElement> = {
  [EventSchemaEventType.Track]: <ClickIcon />,
  [EventSchemaEventType.Page]: <MonitorIcon />,
  [EventSchemaEventType.Screen]: <MobilePhoneIcon />,
  [EventSchemaEventType.Identify]: <IdentityIcon />,
  [EventSchemaEventType.Group]: <AudienceIcon />,
  [EventSchemaEventType.Alias]: <BidirectionalArrowIcon />,
};

export const EventTypeIcon = ({
  eventType,
}: {
  eventType: EventSchemaEventType;
}) => {
  return eventTypeIconMap[eventType];
};

const typeIconMap: Record<SchemaPropertyType, ReactElement> = {
  [SchemaPropertyType.String]: <TextStringIcon />,
  [SchemaPropertyType.Number]: <NumberIcon />,
  [SchemaPropertyType.Boolean]: <BooleanIcon />,
  [SchemaPropertyType.Object]: <ObjectIcon />,
  [SchemaPropertyType.Array]: <ArrayIcon />,
};

export const SchemaPropertyTypeIcon = ({
  type,
}: {
  type: SchemaPropertyType;
}) => {
  return typeIconMap[type];
};
