import { ComponentType, FC, SVGAttributes } from "react";

import { useDisclosure } from "@hightouchio/ui";
import { useNavigate } from "src/router";

import { PermissionedButton } from "src/components/permission";
import { useSyncTemplatesForParentModelQuery } from "src/graphql";
import { Audience } from "src/types/visual";

import { AddSyncTemplatesModal } from "./add-sync-templates-modal";
import { useSubsetPermissions } from "src/components/permission/use-subset-permissions";
import { useSyncCreationPermissions } from "src/components/permission/creation/sync";
import { useIsPermissionsV2Enabled } from "src/components/permission/utils";
import { ParentModel } from "src/pages/audiences/types";

type Props = {
  audience: NonNullable<Audience>;
  parentModel?: NonNullable<ParentModel>;
  icon?: ComponentType<SVGAttributes<SVGElement>>;
  isDisabled?: boolean;
  tooltip?: string;
  variant?: "primary" | "tertiary";
};

export const AddSyncButton: FC<Readonly<Props>> = ({
  audience,
  parentModel,
  icon,
  isDisabled = false,
  tooltip,
  variant,
}) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const isPermissionsV2Enabled = useIsPermissionsV2Enabled();
  const navigate = useNavigate();
  const newSyncUrl = `/syncs/new?model=${audience.id}`;

  const { data: syncTemplates, isLoading } =
    useSyncTemplatesForParentModelQuery(
      { parentModelId: parentModel?.id },
      { enabled: Boolean(parentModel), select: (data) => data.sync_templates },
    );

  const { permission: subsetPermission } = useSubsetPermissions(
    "audience",
    audience?.id,
  );

  const { destinationIds } = useSyncCreationPermissions(audience.id);

  const onClick = () => {
    if (syncTemplates?.length) {
      onToggle();
    } else {
      navigate(newSyncUrl);
    }
  };

  return (
    <>
      <PermissionedButton
        icon={icon}
        isLoading={isLoading}
        tooltip={tooltip}
        isDisabled={
          isDisabled ||
          !subsetPermission ||
          (isPermissionsV2Enabled && !destinationIds.length)
        }
        permission={{ v1: { resource: "sync", grant: "create" } }}
        variant={variant}
        onClick={onClick}
      >
        Add sync
      </PermissionedButton>

      {isOpen && (
        <AddSyncTemplatesModal
          audience={audience}
          syncTemplates={syncTemplates ?? []}
          onClose={onClose}
        />
      )}
    </>
  );
};
