import { useState } from "react";

import {
  Box,
  FormField,
  Text,
  TextInput,
  RadioGroup,
  Radio,
  Paragraph,
  ClipboardButton,
} from "@hightouchio/ui";
import { sha256 } from "js-sha256";
import { v4 as uuidv4 } from "uuid";

// TODO: Move to env at some point
const hightouchAccountId = "324528010823";

export const awsConfigValidator = (config): boolean => {
  return config?.roleArn || (config?.accessKeyId && config?.secretAccessKey);
};

export const AWSForm = ({ config, setConfig }) => {
  const [accessType, setAccessType] = useState<string>("crossAccountRole");

  const getAccessTypeForm = () => {
    if (accessType === "crossAccountRole") {
      return <CrossAccountRoleSetup config={config} setConfig={setConfig} />;
    } else if (accessType === "accessKey") {
      return <AccessKeySetup config={config} setConfig={setConfig} />;
    } else {
      return null;
    }
  };

  return (
    <>
      <FormField label="Access method" mt={6}>
        <RadioGroup
          orientation="vertical"
          value={accessType}
          onChange={(type) => {
            setAccessType(type as string);
            // Clear out the other config when we switch.
            setConfig({
              ...config,
              roleArn: undefined,
              accessKeyId: undefined,
              secretAccessKey: undefined,
              externalId: undefined,
            });
          }}
        >
          <Radio
            description="Grant Hightouch's AWS account permission to access resources in your AWS account."
            label="Cross-account role (recommended)"
            value="crossAccountRole"
          />
          <Radio
            description="Use a regular IAM user that has permission to access resources."
            label="Access key"
            value="accessKey"
          />
        </RadioGroup>
      </FormField>

      {getAccessTypeForm()}
    </>
  );
};

const CrossAccountRoleSetup = ({ config, setConfig }) => {
  // Autogenerate externalId and keep it in the state
  const [externalId] = useState<string>(generateExternalId());

  return (
    <>
      <Paragraph mb={8} mt={8}>
        To create a cross-account role, visit the{" "}
        <Text fontWeight="semibold">AWS Console</Text>, navigate to{" "}
        <Text fontWeight="semibold">IAM</Text> and then{" "}
        <Text fontWeight="semibold">Roles</Text>. Click{" "}
        <Text fontWeight="semibold">Create Role</Text>. Under{" "}
        <Text fontWeight="semibold">Trusted entity type</Text>, choose{" "}
        <Text fontWeight="semibold">AWS account</Text>. Select{" "}
        <Text fontWeight="semibold">Another AWS account</Text> and click{" "}
        <Text fontWeight="semibold">Require external ID</Text>. When asked,
        provide the IDs below. Then, on the{" "}
        <Text fontWeight="semibold">Add permissions</Text> screen, attach
        permissions to the role. After creating the cross-account role, paste
        its ARN below.
      </Paragraph>

      <FormField label="AWS account ID">
        <Box display="flex" gap={3}>
          <TextInput isReadOnly value={hightouchAccountId} />
          <ClipboardButton text={hightouchAccountId} />
        </Box>
      </FormField>

      <FormField label="AWS external ID" mt={6}>
        <Box display="flex" gap={3}>
          <TextInput isReadOnly value={externalId} />
          <ClipboardButton text={externalId} />
        </Box>
      </FormField>

      <FormField label="AWS role ARN" mt={6}>
        <TextInput
          placeholder="arn:aws:iam::xxxxxx:role/MyHightouchRole"
          value={config.roleArn}
          onChange={(event) =>
            setConfig({
              ...config,
              roleArn: event.target.value,
              externalId,
            })
          }
        />
      </FormField>
    </>
  );
};

const AccessKeySetup = ({ config, setConfig }) => {
  return (
    <>
      <FormField label="Access key ID" mt={6}>
        <TextInput
          value={config.accessKeyId}
          onChange={(event) =>
            setConfig({ ...config, accessKeyId: event.target.value })
          }
        />
      </FormField>

      <FormField label="Secret access key" mt={6}>
        <TextInput
          value={config.secretAccessKey}
          onChange={(event) =>
            setConfig({ ...config, secretAccessKey: event.target.value })
          }
        />
      </FormField>
    </>
  );
};

function generateExternalId(): string {
  return sha256.create().update(uuidv4()).hex();
}
