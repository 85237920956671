import { FC } from "react";

import { Alert } from "@hightouchio/ui";
import { LinkButton } from "src/router";
import { useFlags } from "launchdarkly-react-client-sdk";

import { useUser } from "src/contexts/user-context";
import { useEntitlements } from "src/hooks/use-entitlement";
import * as analytics from "src/lib/analytics";

export const OverageBanner: FC = () => {
  const { workspace } = useUser();
  const isBillingPage = location.pathname.endsWith("/settings/billing");
  const { data: entitlementsData, isLoading: loadingEntitlements } =
    useEntitlements(!isBillingPage);
  const { appShowOverageBanner } = useFlags();

  if (
    !appShowOverageBanner ||
    isBillingPage ||
    !workspace ||
    loadingEntitlements ||
    !entitlementsData.overage.overage
  ) {
    return null;
  }

  analytics.track("Overage Banner Shown", {
    workspace_id: workspace.id,
    ...entitlementsData.overage,
  });

  const onContactClick = () => {
    analytics.track("Overage Banner CTA Clicked", {
      workspace_id: workspace.id,
      ...entitlementsData.overage,
    });
  };

  return (
    <Alert
      variant="banner"
      type="upsell"
      justify="center"
      title="Workspace in overage"
      message="This workspace has exceeded the number of destinations allowed on its current plan."
      actions={
        <LinkButton
          variant="secondary"
          href="/settings/billing"
          onClick={onContactClick}
        >
          Upgrade workspace
        </LinkButton>
      }
    />
  );
};
