import { FC, Fragment, useState } from "react";

import {
  Box,
  Button,
  ButtonGroup,
  Column,
  Dialog,
  ExternalLinkIcon,
  Row,
  Text,
  useToast,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import pluralize from "pluralize";
import {
  Link,
  Navigate,
  Outlet,
  Route,
  Routes,
  useNavigate,
  useOutletContext,
  useParams,
} from "src/router";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { DetailPage } from "src/components/layout";
import { PageSpinner } from "src/components/loading";
import { MetadataBar, MetadataLabel } from "src/components/metadata-bar";
import {
  PermissionedButton,
  PermissionedEditableHeading,
} from "src/components/permission";
import { Schedule, scheduleToLabel } from "src/components/schedule/types";
import { Warning } from "src/components/warning";
import {
  useDeleteSyncTemplateMutation,
  useSyncsByTemplateIdQuery,
  useSyncTemplateQuery,
  useUpdateSyncTemplateMutation,
} from "src/graphql";
import { SyncTemplateAlerts } from "./sync-template-alerts";
import { SyncTemplateConfiguration } from "./sync-template-configuration";
import { SyncTemplateSchedule } from "./sync-template-schedule";
import {
  SyncTemplateConfig,
  SyncTemplateLayoutOutletContext,
  SyncTemplateOutletContext,
} from "./types";
import { DependenciesModal } from "src/components/modals/dependencies-modal";
import { DeleteConfirmationModal } from "src/components/modals/delete-confirmation-modal";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { MinimalSyncName } from "src/pages/syncs/sync/components/sync-name";
import { useTabState } from "src/components/route-tabs";

export const SyncTemplateRouter: FC = () => {
  return (
    <Routes>
      <Route element={<Loader />}>
        <Route element={<Layout />}>
          <Route
            path="/configuration"
            element={<SyncTemplateConfiguration />}
          />
          <Route path="/schedule" element={<SyncTemplateSchedule />} />
          <Route path="/alerts" element={<SyncTemplateAlerts />} />
          <Route
            index
            element={
              <Navigate
                to={{ pathname: "configuration", search: location.search }}
                replace
              />
            }
          />
        </Route>
      </Route>
    </Routes>
  );
};

export const Loader: FC = () => {
  const { sync_template_id } = useParams<{ sync_template_id?: string }>();
  const id = Number(sync_template_id);

  const syncTemplateQuery = useSyncTemplateQuery(
    {
      id,
    },
    {
      enabled: Boolean(sync_template_id),
      select: (data) => data.sync_templates_by_pk,
    },
  );

  const syncsByTemplatesQuery = useSyncsByTemplateIdQuery(
    { templateId: id },
    { select: (data) => data.syncs, enabled: Boolean(sync_template_id) },
  );

  const syncTemplate = syncTemplateQuery.data;
  const dependentSyncs = syncsByTemplatesQuery.data ?? [];

  if (syncTemplateQuery.isLoading || syncsByTemplatesQuery.isLoading) {
    return <PageSpinner />;
  }

  if (!syncTemplate) {
    return (
      <Warning
        subtitle="It may have been deleted"
        title="Sync template not found"
      />
    );
  }

  const context: SyncTemplateLayoutOutletContext = {
    syncTemplate,
    dependentSyncs,
  };

  return <Outlet context={context} />;
};

const Tabs = [
  { title: "Configuration", path: "configuration" },
  { title: "Schedule", path: "schedule" },
  { title: "Alerts", path: "alerts" },
];

export const Layout: FC = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { schemaV2 } = useFlags();
  const { activeTab } = useTabState(Tabs, 6);
  const { dependentSyncs, syncTemplate } =
    useOutletContext<SyncTemplateLayoutOutletContext>();

  const [confirmingDelete, setConfirmingDelete] = useState<boolean>(false);
  const [updatedSchedule, setUpdatedSchedule] = useState<
    Schedule | undefined
  >();
  const [updatedConfig, setUpdatedConfig] = useState<
    SyncTemplateConfig | undefined
  >();

  const destination = syncTemplate?.destination;
  const source = syncTemplate?.segment?.connection;

  const scheduleLabel = scheduleToLabel(syncTemplate?.schedule);

  const { mutateAsync: updateSyncTemplate, isLoading: updating } =
    useUpdateSyncTemplateMutation();
  const { mutateAsync: deleteSyncTemplate, isLoading: deletingSyncTemplate } =
    useDeleteSyncTemplateMutation();

  const onUpdate = () => {
    toast({
      id: "update-sync-template",
      title: `${syncTemplate?.name} was updated`,
      variant: "success",
    });
  };

  const updateName = async (name: string) => {
    await updateSyncTemplate({
      id: syncTemplate.id,
      input: {
        name,
      },
    });

    onUpdate();
  };

  const updateConfig = async () => {
    const { overrideConfig, ...syncConfig } = updatedConfig;

    await updateSyncTemplate({
      id: syncTemplate.id,
      input: {
        config: {
          ...syncConfig,
          configVersion: syncTemplate?.config?.configVersion,
        },
        override_config: overrideConfig,
      },
    });

    setUpdatedConfig(undefined);

    onUpdate();
  };

  const updateSchedule = async () => {
    await updateSyncTemplate({
      id: syncTemplate.id,
      input: {
        schedule: updatedSchedule?.type === "manual" ? null : updatedSchedule,
      },
    });

    onUpdate();
  };

  const resetUpdates = () => {
    setUpdatedConfig(undefined);
    setUpdatedSchedule(undefined);
  };

  const onDelete = async () => {
    try {
      await deleteSyncTemplate({
        id: syncTemplate.id,
      });

      toast({
        id: "single-delete-toast",
        title:
          dependentSyncs && dependentSyncs.length > 0
            ? `Sync template and ${pluralize(
                "sync",
                dependentSyncs.length,
                true,
              )} deleted`
            : "Sync template deleted",
        variant: "success",
      });

      navigate(
        schemaV2
          ? `/schema-v2/settings/sync-templates`
          : "/schema/sync-templates",
      );
    } catch (error) {
      toast({
        id: "single-delete-toast",
        title: "Couldn't delete this sync template",
        variant: "error",
      });

      Sentry.captureException(error);
      throw error;
    }
  };

  const context: SyncTemplateOutletContext = {
    syncTemplate,
    onUpdateConfig: setUpdatedConfig,
    onUpdateSchedule: setUpdatedSchedule,
  };

  return (
    <>
      <DetailPage
        tabDepth={6}
        crumbs={[
          {
            label: "All sync templates",
            link: schemaV2
              ? `/schema-v2/settings/sync-templates`
              : "/schema/sync-templates",
          },
        ]}
        tabs={Tabs}
        bg={
          activeTab?.path === "configuration"
            ? "base.lightBackground"
            : undefined
        }
        title={`${syncTemplate.name} - Sync templates - Audiences`}
        header={
          <>
            <Row
              justifyContent="space-between"
              alignItems="center"
              mb={5}
              gap={8}
              width="100%"
            >
              <PermissionedEditableHeading
                permission={{
                  v1: { resource: "sync_template", grant: "update" },
                  v2: {
                    resource: "model",
                    grant: "can_update",
                    id: syncTemplate.segment.id,
                  },
                }}
                size="lg"
                value={syncTemplate?.name ?? ""}
                isDisabled={updating}
                onChange={updateName}
              />

              <PermissionedButton
                variant="warning"
                permission={{
                  v1: { resource: "sync_template", grant: "delete" },
                  v2: {
                    resource: "model",
                    grant: "can_delete",
                    id: syncTemplate.segment.id,
                  },
                }}
                onClick={() => {
                  setConfirmingDelete(true);
                }}
              >
                Delete
              </PermissionedButton>
            </Row>

            <MetadataBar>
              <Column>
                <MetadataLabel>Parent model</MetadataLabel>
                <Row align="center" gap={2}>
                  <IntegrationIcon
                    src={source?.definition?.icon}
                    name={source?.definition?.name}
                  />
                  <Link
                    href={`/schema-v2/view?source=${source?.id}&id=${syncTemplate.segment.id}`}
                  >
                    <Text fontWeight="medium" color="inherit">
                      {syncTemplate.segment.name}
                    </Text>
                  </Link>
                </Row>
              </Column>
              <Column>
                <MetadataLabel>Destination</MetadataLabel>
                <Row align="center" gap={2}>
                  <IntegrationIcon
                    src={destination.definition?.icon}
                    name={destination.definition?.name ?? ""}
                  />
                  <Link href={`/destinations/${destination.id}`}>
                    <Text fontWeight="medium" color="inherit">
                      {syncTemplate.destination.definition?.name}
                    </Text>
                  </Link>
                </Row>
              </Column>
              {scheduleLabel && (
                <Column>
                  <MetadataLabel>Schedule</MetadataLabel>
                  <Text>{scheduleLabel}</Text>
                </Column>
              )}
              <Column>
                <MetadataLabel>Syncs</MetadataLabel>
                <Text>{dependentSyncs.length ?? "--"}</Text>
              </Column>
            </MetadataBar>
          </>
        }
      >
        <Outlet context={context} />
      </DetailPage>

      <DependenciesModal
        isOpen={
          confirmingDelete && !!dependentSyncs && dependentSyncs.length > 0
        }
        isDeleting={deletingSyncTemplate}
        resourceType="Sync template"
        dependencies={[
          {
            name: "Syncs",
            resources: (dependentSyncs ?? []).map((sync) => ({
              id: sync.id,
              name: <MinimalSyncName sync={sync} />,
              url: `/syncs/${sync.id}`,
              created_at: null,
              created_by_user: null,
              updated_at: null,
              updated_by_user: null,
            })),
          },
        ]}
        onClose={() => setConfirmingDelete(false)}
        onDelete={onDelete}
      />

      <DeleteConfirmationModal
        isOpen={
          confirmingDelete && (!dependentSyncs || dependentSyncs.length === 0)
        }
        label="sync template"
        onClose={() => {
          setConfirmingDelete(false);
        }}
        onDelete={onDelete}
      />

      <Dialog
        isOpen={Boolean(updatedConfig || updatedSchedule)}
        variant="form"
        width="xl"
        title="Confirm changes"
        actions={
          <ButtonGroup>
            <Button isDisabled={updating} onClick={resetUpdates}>
              Cancel
            </Button>
            <Button
              isLoading={updating}
              variant="primary"
              onClick={async () => {
                if (updatedSchedule) {
                  await updateSchedule();
                } else if (updatedConfig) {
                  await updateConfig();
                }

                resetUpdates();
              }}
            >
              Continue
            </Button>
          </ButtonGroup>
        }
        onClose={resetUpdates}
      >
        {dependentSyncs && dependentSyncs.length > 0 ? (
          <>
            <Row mb={6}>
              <Text fontWeight="medium">
                The following audience syncs will be affected:
              </Text>
            </Row>
            <Box display="grid" gridTemplateColumns="1fr 1fr" gap={0}>
              <Row p={2} borderBottom="small">
                <Text
                  fontWeight="semibold"
                  size="sm"
                  color="text.secondary"
                  textTransform="uppercase"
                >
                  Audience
                </Text>
              </Row>
              <Row p={2} borderBottom="small">
                <Text
                  fontWeight="semibold"
                  size="sm"
                  color="text.secondary"
                  textTransform="uppercase"
                >
                  Destination
                </Text>
              </Row>
              {dependentSyncs.map(({ id, segment, destination }) => {
                return (
                  <Fragment key={id}>
                    <Box p={2} borderBottom="small">
                      <TextWithTooltip>{segment?.name}</TextWithTooltip>
                    </Box>
                    <Row alignItems="center" borderBottom="small" p={2} gap={2}>
                      <IntegrationIcon
                        name={destination?.definition?.name}
                        src={destination?.definition?.icon}
                      />
                      <TextWithTooltip>
                        {destination?.name ??
                          destination?.definition?.name ??
                          "Private destination"}
                      </TextWithTooltip>
                      <Link href={`/syncs/${id}`}>
                        <Box
                          as={ExternalLinkIcon}
                          color="text.tertiary"
                          fontSize="16px"
                        />
                      </Link>
                    </Row>
                  </Fragment>
                );
              })}
            </Box>
          </>
        ) : (
          <Text>Are you sure you want to make these changes?</Text>
        )}
      </Dialog>
    </>
  );
};
