import { FC } from "react";

import { Alert, Button } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";

export const PartnerBanner: FC = () => {
  const { partnerUser } = useFlags();

  if (!partnerUser) return null;

  return (
    <Alert
      variant="banner"
      type="subtle"
      justify="center"
      title="You are viewing a customer's workspace."
      message="All activity will be logged."
      actions={
        <Button
          onClick={() => {
            window.location.href = "/partner";
          }}
        >
          Back to dashboard
        </Button>
      }
    />
  );
};
