import * as Yup from "yup";

import {
  JourneyNodeSyncMode,
  JourneyNodeType,
  entryCohortConfigSchema,
  segmentBranchConfigSchema,
  timeDelayConfigSchema,
  waitUntilEventConfigSchema,
} from "src/types/journeys";
import { IntervalUnit, exhaustiveCheck } from "src/types/visual";

/**
 * The idea here is to use the backend config schemas. We only
 * specify other fields when they're relevant to the node.
 */

export const StartFormSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  segment_id: Yup.string().nullable().required("Audience is required"),
  // TODO(samuel): Add types to schemas. These need to be cast due to mismatch between yup versions.
  config: entryCohortConfigSchema as any,
});

export const TimeDelayFormSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  // TODO(samuel): Add types to schemas. These need to be cast due to mismatch between yup versions.
  config: timeDelayConfigSchema as any,
});

export const WaitUntilEventFormSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  segment_id: Yup.string().nullable().required("Filter is required"),
  event_relationship_id: Yup.string().nullable().required("Filter is required"),
  // TODO(samuel): Add types to schemas. These need to be cast due to mismatch between yup versions.
  config: waitUntilEventConfigSchema as any,
});

export const SegmentBranchFormSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  // TODO(samuel): Add types to schemas. These need to be cast due to mismatch between yup versions.
  config: segmentBranchConfigSchema as any,
});

export const SyncFormSchema = Yup.object().shape({
  destination_instance_id: Yup.number().required(),
  mode: Yup.string().oneOf(Object.values(JourneyNodeSyncMode)),
  // On the frontend the properties of `exit_config` are nullable.
  // This diverges from the schema on the backend.
  // Using `undefined` as a 'false-y' value does not work with the Radio components.
  exit_config: Yup.object().shape({
    remove_after: Yup.object()
      .nullable()
      .shape({
        unit: Yup.string().oneOf(Object.values(IntervalUnit)),
        quantity: Yup.number().positive().integer(),
      }),
    remove_on_journey_exit: Yup.boolean().nullable(),
  }),
});

const SyncNodeSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  sync_configs: Yup.array().of(SyncFormSchema).nullable(),
});

const NoOpSchema = Yup.object();

export const JourneyNodeSchema = Yup.array().of(
  Yup.object().shape({
    data: Yup.lazy((value: any) => {
      const type = value.config.type as JourneyNodeType;
      switch (type) {
        case JourneyNodeType.EntryCohort:
          return StartFormSchema;
        case JourneyNodeType.TimeDelay:
          return TimeDelayFormSchema;
        case JourneyNodeType.WaitUntilEvent:
          return WaitUntilEventFormSchema;
        case JourneyNodeType.Segments:
          // No fields to validate, all config is on the segment branches.
          return Yup.object();
        case JourneyNodeType.SegmentBranch:
          return SegmentBranchFormSchema;
        case JourneyNodeType.Sync:
          return SyncNodeSchema;
        case JourneyNodeType.WaitUntilEventBranch:
          // Not updatable by the user
          return NoOpSchema;
        case JourneyNodeType.NoOp:
          // The user cannot create NoOp nodes.
          throw new Error(
            `JourneyNodeType ${type} does not have a valid schema defined`,
          );
        default:
          // Make the type checker enforce that we cover all of the possible
          // types here.
          exhaustiveCheck(type);
      }
    }),
  }),
);

export const JourneySchema = Yup.object().shape({
  journey: Yup.object().shape({
    id: Yup.string().required("id is required"),
  }),
  nodes: JourneyNodeSchema,
});

export const CreateJourneySchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  parentModelId: Yup.number().nullable().required("Parent model is required"), // nullable so it can begin as `null`
  description: Yup.string().nullable(),
});
