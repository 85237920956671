import { FC } from "react";

import { Box, Column, Heading, Row } from "@hightouchio/ui";
import { Helmet } from "react-helmet";

import { ActivityFeedItem } from "src/components/app-home/activity-feed";
import { ExtensionHealthItem } from "src/components/app-home/extension-health";
import { PendingDraftsItem } from "src/components/app-home/pending-drafts";
import { SyncHealthCharts } from "src/components/app-home/sync-health-charts";
import { SyncHealthContextProvider } from "src/components/app-home/sync-health-context";
import { SyncHealthTable } from "src/components/app-home/sync-health-table";
import { PageHeader } from "src/components/layout";

export const Home: FC = () => {
  return (
    <Column height="100vh" background="base.lightBackground">
      <PageHeader>
        <Row
          align="center"
          px={6}
          height="64px"
          flexShrink={0}
          borderBottom="1px"
          borderColor="base.border"
        >
          <Heading size="xl">Workspace overview</Heading>
        </Row>
      </PageHeader>
      <Helmet>
        <title>Workspace overview</title>
      </Helmet>
      <Box
        flex={1}
        overflow="hidden"
        display="grid"
        maxWidth="2400px"
        gridTemplateColumns="1fr max(320px, 20%)"
        gridTemplateRows="min-content minmax(100px, 1fr)"
        gridAutoFlow="column"
        background="white"
        rowGap={6}
      >
        <SyncHealthContextProvider>
          <SyncHealthCharts />
          <SyncHealthTable />
        </SyncHealthContextProvider>

        <Column
          overflow="auto"
          px={6}
          py={4}
          gridRow="span 2"
          alignContent="center"
          borderLeft="1px"
          borderColor="base.border"
          background="base.lightBackground"
          gap={8}
        >
          <ExtensionHealthItem />
          <PendingDraftsItem />
          <ActivityFeedItem />
        </Column>
      </Box>
    </Column>
  );
};
