import { FC, useCallback } from "react";

import { Heading, Row, StatusBadge, Text, Column } from "@hightouchio/ui";
import { Link } from "src/router";
import { useNavigate } from "src/router";

import genericPlaceholder from "src/assets/placeholders/generic.svg";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { eventSourceDefinitions } from "src/events/types";
import { useEventSyncsQuery, type EventSyncsQuery } from "src/graphql";
import { PageTable } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { openUrl } from "src/utils/urls";

import {
  getStatusBadgeLabel,
  getStatusBadgeVariant,
} from "./warehouse-sync/runs";
import { Page } from "src/components/layout";
import { formatFriendlyDistanceToNow } from "src/utils/time";
import {
  destinationMetadataFromForwardingSync,
  destinationMetadataFromWarehouseSync,
} from "src/events/utils";
import { keyBy, mapValues } from "lodash";
import { PermissionedLinkButton } from "src/components/permission";

type ForwardingSync = EventSyncsQuery["event_forwarding_syncs"][0];
type WarehouseSync = EventSyncsQuery["event_warehouse_syncs"][0];

const isWarehouseSync = (
  sync: ForwardingSync | WarehouseSync,
): sync is WarehouseSync => "event_warehouse_destination" in sync;

export const EventWarehouseSyncs: FC = () => {
  const navigate = useNavigate();
  const { data, isFetching, error } = useEventSyncsQuery(
    {},
    {
      select: (data) => {
        const functionsByEventSyncId = mapValues(
          keyBy(data.function_resources, "resource_id"),
          // Safe because a function_resource will always have a function_version (see graphql query)
          ({ function_versions }) => function_versions[0]!,
        );

        const syncs = [
          ...data.event_warehouse_syncs,
          ...data.event_forwarding_syncs,
        ];

        return { syncs, functionsByEventSyncId };
      },
    },
  );

  const onRowClick = useCallback(
    ({ id }, event) => openUrl(`/events/syncs/${id}`, navigate, event),
    [navigate],
  );

  return (
    <Page title="Event syncs" sx={{ p: 0 }}>
      <PageTable
        header={
          <>
            <Heading isTruncated size="xl">
              Event syncs
            </Heading>
            <Row>
              <PermissionedLinkButton
                permission={{
                  v1: { resource: "workspace", grant: "update" },
                  v2: {
                    resource: "workspace",
                    grant: "can_update",
                  },
                }}
                href="/events/syncs/new"
                variant="primary"
              >
                Create sync
              </PermissionedLinkButton>
            </Row>
          </>
        }
        columns={[
          {
            name: "Last Sync",
            cell: (sync: WarehouseSync | ForwardingSync) => {
              if (isWarehouseSync(sync)) {
                const lastRun = sync.runs.event_warehouse_sync_runs?.[0];

                const variant = getStatusBadgeVariant(lastRun);
                if (!lastRun) {
                  return <StatusBadge variant="inactive">Pending</StatusBadge>;
                }

                const finishedAt = lastRun.finished_at;

                return (
                  <Column gap={1}>
                    <StatusBadge variant={variant}>
                      {getStatusBadgeLabel(variant)}
                    </StatusBadge>
                    {finishedAt && (
                      <Text size="sm" color="text.secondary">
                        {formatFriendlyDistanceToNow(finishedAt)}
                      </Text>
                    )}
                  </Column>
                );
              }

              return (
                <TextWithTooltip size="sm" color="text.secondary">
                  Continuously streaming...
                </TextWithTooltip>
              );
            },
          },
          {
            name: "Source",
            cell: ({ event_source: source }) => {
              const definition = eventSourceDefinitions[source.type];
              if (definition) {
                return (
                  <Row overflow="hidden" gap={2} align="center">
                    <IntegrationIcon
                      name={definition.name}
                      src={definition.icon}
                    />
                    <TextWithTooltip
                      message={source.name}
                      isTruncated
                      fontWeight="medium"
                    >
                      {source.name}
                    </TextWithTooltip>
                  </Row>
                );
              }
              return null;
            },
          },
          {
            name: "Destination",
            cell: (sync) => {
              const destinationMetadata =
                sync.__typename === "event_warehouse_syncs"
                  ? destinationMetadataFromWarehouseSync(sync)
                  : destinationMetadataFromForwardingSync(sync);

              return (
                <Row overflow="hidden" gap={2} align="center">
                  <IntegrationIcon
                    name={destinationMetadata.name}
                    src={destinationMetadata.icon}
                  />
                  <TextWithTooltip isTruncated fontWeight="medium">
                    {destinationMetadata.name}
                  </TextWithTooltip>
                </Row>
              );
            },
          },

          {
            name: "Function",
            cell: ({ id }) => {
              const fn = data?.functionsByEventSyncId[id];

              if (!fn) {
                return <Text>--</Text>;
              }

              return (
                // Note: stop propagation so we don't hit the row click handler
                <Row overflow="hidden" onClick={(e) => e.stopPropagation()}>
                  <TextWithTooltip message={fn.name}>
                    <Link href={`/events/functions/${fn.id}`}>{fn.name}</Link>
                  </TextWithTooltip>
                </Row>
              );
            },
          },
          LastUpdatedColumn,
        ]}
        data={data?.syncs}
        error={Boolean(error)}
        loading={isFetching}
        placeholder={placeholder}
        onRowClick={onRowClick}
      />
    </Page>
  );
};

const placeholder = {
  image: genericPlaceholder,
  title: "No event syncs",
  body: "Create a new event sync",
  error: "Event syncs failed to load, please try again.",
};
