import { FC } from "react";

import { Box, Row, RowProps, Text } from "@hightouchio/ui";

import { Logo } from "src/ui/brand";

import { usePartner } from "./context";

export const Watermark: FC<Readonly<RowProps>> = (props) => {
  const { cobranded } = usePartner();

  if (!cobranded) {
    return null;
  }

  return (
    <Row py={2} {...props}>
      <Box
        as="a"
        display="flex"
        alignItems="center"
        flexShrink={0}
        gap={2}
        href="https://hightouch.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Text fontWeight="semibold" color="forest.700">
          Powered by
        </Text>
        <Logo variant="full" size="92px" theme="dark" />
      </Box>
    </Row>
  );
};
