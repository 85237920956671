import { FC } from "react";

import {
  CloseIcon,
  Column,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  FormField,
  IconButton,
  NumberInput,
  Row,
  Select,
} from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller } from "react-hook-form";

import { isAndOrCondition } from "src/components/explore/utils/type-guards";
import { ErrorMessage } from "src/components/explore/visual/error-message";
import { EventFilter } from "src/components/explore/visual/event-filter";
import { Form, SaveButton, useHightouchForm } from "src/components/form";
import { PermissionedEditableHeading } from "src/components/permission";
import { TIME_OPTIONS } from "src/constants";
import { useFormErrorContext } from "src/contexts/form-error-context";
import { JOURNEY_UPDATE_PERMISSION } from "src/pages/journeys/constants";
import { useGraphContext } from "src/pages/journeys/graph";
import {
  JourneyNodeDetails,
  NodeDetailFormProps,
} from "src/pages/journeys/types";
import { WaitUntilEventConfig } from "src/types/journeys";
import {
  Audience,
  ConditionType,
  EventCondition,
  initialEventCondition,
} from "src/types/visual";

import { getFormattedSubconditions } from "./utils";
import { WaitUntilEventFormSchema } from "./validation-schemas";

export const HoldUntilForm: FC<NodeDetailFormProps<WaitUntilEventConfig>> = ({
  id, // id techincally lives in data too, redundant to have both :/
  data,
  onClose,
}) => {
  const { parentModel, onUpdateNode } = useGraphContext();
  const { hasValidationErrors } = useFormErrorContext();

  const form = useHightouchForm<JourneyNodeDetails<WaitUntilEventConfig>>({
    onSubmit: (newTileData) => {
      if (hasValidationErrors()) {
        throw new Error("Form has validation errors");
      }

      onUpdateNode(id, newTileData);
      onClose();

      return Promise.resolve();
    },
    onError: () => {
      // Defined `onError` to ignore sentry tracking in `useHightouchForm`
    },
    success: "Tile was saved",
    values: {
      ...data,
      config: {
        ...data.config,
        // Make sure event_conditions is a top level AND condition
        event_conditions: getFormattedSubconditions(
          data.config.event_conditions,
        ),
      },
    },
    resolver: yupResolver(WaitUntilEventFormSchema),
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - Circular reference problem with Column types
  const segmentId = form.watch("segment_id");
  const eventRelationshipId = form.watch("event_relationship_id");

  return (
    <Form form={form}>
      <DrawerHeader>
        <Row align="center" justify="space-between" flex={1} minWidth={0}>
          <Controller
            name="name"
            control={form.control}
            render={({ field, fieldState }) => (
              <Column>
                <PermissionedEditableHeading
                  permission={JOURNEY_UPDATE_PERMISSION}
                  value={field.value}
                  onChange={field.onChange}
                />
                {fieldState.error?.message && (
                  <ErrorMessage fontWeight="normal">
                    {fieldState.error.message}
                  </ErrorMessage>
                )}
              </Column>
            )}
          />
          <IconButton
            aria-label="Close drawer."
            icon={CloseIcon}
            onClick={onClose}
          />
        </Row>
      </DrawerHeader>

      <DrawerBody>
        <Column minHeight={0} flex={1} gap={6} pb={4}>
          <FormField
            label="Hold criteria"
            description="Hold user at this tile until they perform the following"
          >
            <Column
              p={4}
              border="1px solid"
              borderColor="base.border"
              borderRadius="md"
            >
              <Column gap={4}>
                <Controller
                  control={form.control}
                  name="config.event_conditions"
                  render={({ field }) => (
                    <EventFilter
                      hideFunnelCondition
                      hideOperatorFilter
                      hideWindowCondition
                      showOnlyEventOptions
                      audience={{} as Audience}
                      condition={
                        segmentId && eventRelationshipId
                          ? ({
                              type: ConditionType.Event,
                              eventModelId: Number(segmentId),
                              relationshipId: Number(eventRelationshipId),
                              // Should always be true, but narrowing for ts sake
                              subconditions: isAndOrCondition(field.value)
                                ? field.value.conditions
                                : [],
                            } as unknown as EventCondition)
                          : initialEventCondition
                      }
                      parent={parentModel}
                      onChange={({
                        eventModelId,
                        relationshipId,
                        subconditions,
                      }) => {
                        form.setValue(
                          "event_relationship_id",
                          // Note: these _are_ numbers. The type says string but it is incorrect.
                          relationshipId === null
                            ? null
                            : (Number(relationshipId) as unknown as string),
                        );
                        form.setValue(
                          "segment_id",
                          // Note: these _are_ numbers. The type says string but it is incorrect.
                          eventModelId === null
                            ? null
                            : (Number(eventModelId) as unknown as string),
                        );

                        // This is an array, but will be a single element array
                        field.onChange(
                          subconditions?.[0] ?? {
                            type: ConditionType.And,
                            conditions: [],
                          },
                        );
                      }}
                    />
                  )}
                />
              </Column>
            </Column>
          </FormField>
          <FormField
            label="Maximum hold duration"
            description="Users who don’t meet the criteria after this duration go down the “rule not met” branch"
          >
            <Row gap={4}>
              <Controller
                control={form.control}
                name="config.timeout_duration.quantity"
                render={({ field, fieldState }) => (
                  <Column gap={1}>
                    <NumberInput
                      isInvalid={Boolean(fieldState.error?.message)}
                      placeholder="Enter the quantity..."
                      min={0}
                      value={field.value}
                      width="100%"
                      onChange={field.onChange}
                    />
                    {fieldState.error?.message && (
                      <ErrorMessage>Quantity is required</ErrorMessage>
                    )}
                  </Column>
                )}
              />
              <Controller
                control={form.control}
                name="config.timeout_duration.unit"
                render={({ field, fieldState }) => (
                  <Column gap={1}>
                    <Select
                      onChange={field.onChange}
                      options={TIME_OPTIONS}
                      placeholder="Select a unit..."
                      value={field.value}
                    />
                    {fieldState.error?.message && (
                      <ErrorMessage>Unit is required</ErrorMessage>
                    )}
                  </Column>
                )}
              />
            </Row>
          </FormField>
        </Column>
      </DrawerBody>

      <DrawerFooter>
        <SaveButton
          permission={JOURNEY_UPDATE_PERMISSION}
          onClick={() => hasValidationErrors()}
        >
          Update
        </SaveButton>
      </DrawerFooter>
    </Form>
  );
};
