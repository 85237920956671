import { FC } from "react";

import { Alert, Column, Paragraph, Box, Code } from "@hightouchio/ui";

import { LinkButton } from "src/router";
import { Link } from "src/router";

import { newPylonMessage } from "src/lib/pylon";

export const ChatLink: FC = () => (
  <Box
    display="inline-block"
    onClick={() => {
      newPylonMessage(
        `Hi, can you help me with the duplicate primary key error I'm seeing in my sync?`,
      );
    }}
  >
    <Link href="">Chat with us</Link>
  </Box>
);

export type Props = {
  count: number;
  primaryKey: string;
  parentModelUrl: string;
};
export const DuplicateKeyWarning: FC<Readonly<Props>> = ({
  count,
  primaryKey,
  parentModelUrl,
}) => {
  return (
    <Alert
      type="warning"
      variant="inline"
      title={`Hightouch detected ${count} duplicate row${
        count > 1 ? "s" : ""
      } during the most recent sync run.`}
      message={
        <Column gap={2}>
          <Paragraph>
            Please edit your model to ensure that its primary key column (
            <Code>{primaryKey}</Code>) does not contain any repeated values. You
            need to filter out duplicates or select a different column that
            serves as a unique identifier for each row. Hightouch will reject
            rows with repeated primary keys as a safeguard to prevent duplicate
            records from reaching the destination. <ChatLink /> if you need help
            troubleshooting this issue.
          </Paragraph>
        </Column>
      }
      actions={<LinkButton href={parentModelUrl}>Edit model</LinkButton>}
    />
  );
};
