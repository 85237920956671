import { DestinationInstancesInsertInput } from "@hightouch/core/server/graphql/types";
import {
  Button,
  ButtonGroup,
  Column,
  Dialog,
  Row,
  SectionHeading,
  Text,
  useToast,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import pluralize from "pluralize";
import { Fragment, useState } from "react";
import { isPresent } from "ts-extras";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { Syncs } from "src/components/models/syncs";
import { useUser } from "src/contexts/user-context";
import {
  DraftOperation,
  useCreateSyncsMutation,
  useSubmitDraftSyncMutation,
  useSyncTemplatesForParentModelQuery,
} from "src/graphql";
import { AudiencePageOutletState } from "src/pages/audiences/types";
import { Link, useNavigate, useOutletContext } from "src/router";
import { Table } from "src/ui/table";
import { useRowSelect } from "src/ui/table/use-row-select";
import { generateSlug } from "src/utils/slug";
import { openUrl } from "src/utils/urls";

export const AudienceSyncs = () => {
  const { audience, parentModel, id } =
    useOutletContext<AudiencePageOutletState>();
  const navigate = useNavigate();
  const [addSyncModal, setAddSyncModal] = useState(false);
  const { workspace } = useUser();
  const {
    selectedRows: syncTemplateIdsToAdd,
    onRowSelect: onSyncTemplateSelected,
  } = useRowSelect();
  const { mutateAsync: addSyncs, isLoading: addingSyncs } =
    useCreateSyncsMutation();
  const { mutateAsync: submitDraftSync, isLoading: submittingDraftSync } =
    useSubmitDraftSyncMutation();

  const syncs = audience?.syncs;

  const { schemaV2 } = useFlags();

  const { toast } = useToast();

  const { data: syncTemplatesData } = useSyncTemplatesForParentModelQuery(
    { parentModelId: parentModel?.id },
    { enabled: Boolean(parentModel) },
  );
  const syncTemplates = syncTemplatesData?.sync_templates;
  const usedSyncTemplateIds = syncs?.map(
    ({ sync_template_id }) => sync_template_id,
  );
  const availableSyncTemplates = syncTemplates?.filter(
    ({ id }) => !usedSyncTemplateIds?.includes(id),
  );
  const usedSyncTemplates = syncTemplates?.filter(({ id }) =>
    usedSyncTemplateIds?.includes(id),
  );

  const onAddSyncTemplates = async () => {
    const approvalsRequired = workspace?.approvals_required;

    const syncObjects: DestinationInstancesInsertInput[] = syncTemplateIdsToAdd
      .map((id) => syncTemplates?.find((template) => template.id === id))
      .filter(isPresent)
      .map((template) => ({
        segment_id: id,
        sync_template_id: template.id,
        destination_id: template.destination.id,
        slug: generateSlug(template.name),
        draft: approvalsRequired,
      }));

    try {
      const syncs = await addSyncs({
        objects: syncObjects,
      });

      if (approvalsRequired) {
        const syncIds: string[] = (
          syncs.insert_destination_instances?.returning ?? []
        ).map(({ id }) => id.toString());

        // Save newly created syncs as new drafts without approvers.
        // Approvers will be requested later from the individual sync pages.
        await Promise.all(
          syncIds.map(async (syncId: string) => {
            return submitDraftSync(
              {
                resourceId: syncId,
                approverIds: [],
                operation: DraftOperation.Create,
                draft: {
                  _set: {
                    draft: false,
                  },
                },
              },
              {
                onError: (error) => {
                  Sentry.captureException(error);
                },
              },
            );
          }),
        );
      }

      toast({
        id: "add-sync-to-audience",
        title: `${pluralize("sync", syncTemplateIdsToAdd.length, true)} added`,
        variant: "success",
      });

      setAddSyncModal(false);
    } catch (error) {
      toast({
        id: "add-sync-to-audience",
        title: `${pluralize(
          "sync",
          syncTemplateIdsToAdd.length,
          true,
        )} could not be added. Please try again.`,
        variant: "error",
      });

      Sentry.captureException(error);
    }
  };

  const syncTemplateColumns = [
    {
      name: "Name",
      key: "name",
      cell: (name) => (
        <Text isTruncated fontWeight="medium">
          {name}
        </Text>
      ),
    },
    {
      name: "Destination",
      cell: ({ destination }) => {
        return (
          <Row align="center" gap={2} overflow="hidden">
            <IntegrationIcon
              name={destination?.definition?.name}
              src={destination?.definition?.icon}
            />
            <Link
              href={
                schemaV2
                  ? `/schema-v2/settings/sync-templates/${id}`
                  : `/schema/sync-templates/${destination.id}`
              }
            >
              <Text isTruncated fontWeight="medium">
                {destination?.name ??
                  destination?.definition?.name ??
                  "Private destination"}
              </Text>
            </Link>
          </Row>
        );
      },
    },
  ];

  return (
    <Fragment>
      <Syncs
        model={audience}
        parentModel={parentModel}
        syncs={syncs}
        modelMatchboostingEnabled={Boolean(parentModel?.matchboosting_enabled)}
      />

      <Dialog
        isOpen={addSyncModal}
        variant="form"
        width="lg"
        title="Select sync templates"
        actions={
          <ButtonGroup>
            <Button
              onClick={() => {
                setAddSyncModal(false);
              }}
            >
              Cancel
            </Button>

            {!workspace?.sync_templates_only && (
              <Button
                isDisabled={syncTemplateIdsToAdd.length > 0}
                onClick={() => navigate(`/syncs/new?model=${id}`)}
              >
                Create new sync
              </Button>
            )}
            <Button
              isDisabled={syncTemplateIdsToAdd?.length === 0}
              isLoading={addingSyncs || submittingDraftSync}
              onClick={onAddSyncTemplates}
            >
              Add syncs
            </Button>
          </ButtonGroup>
        }
        onClose={() => {
          setAddSyncModal(false);
        }}
      >
        <Column gap={6}>
          <Column>
            {availableSyncTemplates?.length === 0 ? (
              <Text fontWeight="medium">
                You have no available sync templates.{" "}
                <Link
                  href={
                    schemaV2
                      ? "/schema-v2/settings/sync-templates/new"
                      : "/schema/sync-templates/new"
                  }
                >
                  Create a new sync template
                </Link>{" "}
                to easily sync audiences to other destinations.
              </Text>
            ) : (
              <>
                <Text fontWeight="medium">
                  Select one or more sync templates below. Templates are
                  pre-configured to work with this Audience's data source.
                </Text>
                <Text fontWeight="medium">
                  If your destination isn't listed below,{" "}
                  <Link href={`/syncs/new?model=${id}`}>create a new sync</Link>
                  .
                </Text>
              </>
            )}
          </Column>
          {Array.isArray(availableSyncTemplates) &&
            availableSyncTemplates.length > 0 && (
              <Column gap={3}>
                <SectionHeading>Available sync templates</SectionHeading>
                <Table
                  data={availableSyncTemplates}
                  columns={syncTemplateColumns}
                  onSelect={onSyncTemplateSelected}
                  selectedRows={syncTemplateIdsToAdd}
                />
              </Column>
            )}
          {Array.isArray(usedSyncTemplates) && usedSyncTemplates.length > 0 && (
            <Column gap={3}>
              <SectionHeading>Active sync templates</SectionHeading>
              <Table
                data={usedSyncTemplates}
                columns={syncTemplateColumns}
                onRowClick={({ id }, event) =>
                  openUrl(
                    schemaV2
                      ? `/schema-v2/settings/sync-templates/${id}`
                      : `/schema/sync-templates/${id}`,
                    navigate,
                    event,
                  )
                }
              />
            </Column>
          )}
        </Column>
      </Dialog>
    </Fragment>
  );
};
