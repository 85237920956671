import { Text, TextProps } from "@hightouchio/ui";
import { ReactNode } from "react";

export const ErrorMessage = ({
  children,
  ...props
}: { children: ReactNode } & TextProps) => {
  // TODO: migrate query builder to use form fields
  // for accessibility.
  return (
    <Text color="text.danger" mt={2} size="sm" {...props}>
      {children}
    </Text>
  );
};
