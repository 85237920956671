import { FC, useMemo, useState } from "react";

import {
  ArrowRightIcon,
  Box,
  BoxProps,
  ChakraAccordion,
  ChakraAccordionButton,
  ChakraAccordionItem,
  ChakraAccordionPanel,
  Checkbox,
  ChevronRightIcon,
  Column,
  EmptyState,
  Pill,
  Row,
  SkeletonBox,
  StatsItemTitle,
  Text,
  ToggleButton,
  ToggleButtonGroup,
} from "@hightouchio/ui";
import { times } from "lodash";
import { RouterLink } from "src/router";

import syncPlaceholder from "src/assets/placeholders/sync.svg";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { useLatestSyncStatusQuery } from "src/graphql";
import * as analytics from "src/lib/analytics";
import { commaNumber } from "src/utils/numbers";

import { DashboardMainItem } from "./dashboard-main-item";
import { useSyncHealthContext } from "./sync-health-context";
import {
  enrichSyncs,
  ResourceOperationsItem,
  transformSyncs,
} from "./transformations";
import { useUser } from "../../contexts/user-context";
import { MonitorStatus } from "@hightouch/lib/resource-monitoring/types";

type AggregatedResources = {
  succeeded: number;
  warning: number;
  failed: number;
  [MonitorStatus.Healthy]: number;
  health_warning: number;
  [MonitorStatus.Unhealthy]: number;
};

type Variant = "warning" | "success" | "failed";

function variantToHealth(v: Variant): MonitorStatus {
  switch (v) {
    case "failed":
      return MonitorStatus.Unhealthy;
    case "success":
      return MonitorStatus.Healthy;
    case "warning":
      return MonitorStatus.Warning;
  }
}

const SyncsCountCell: FC<{
  syncs?: AggregatedResources;
  variant: Variant;
  border?: boolean;
  hover?: boolean;
  url: string;
  resource?: ResourceOperationsItem;
  resourceId?: string;
}> = ({
  syncs,
  variant,
  url,
  hover: rowHover = false,
  resource,
  resourceId,
}) => {
  let formattedSyncs;
  let hoverBgColor;
  let bgColor;
  let textColor;

  const { workspace } = useUser();

  const alertingV2Enabled = workspace?.alerting_v2_enabled;

  let metric: number = 0;

  const metricBase =
    syncs ||
    resource ||
    ({
      failed: 0,
      succeeded: 0,
      warning: 0,
      health_warning: 0,
      healthy: 0,
      unhealthy: 0,
    } satisfies AggregatedResources);

  switch (variant) {
    case "success":
      bgColor = "success.background";
      hoverBgColor = "grass.200";
      textColor = "grass.800";
      metric = alertingV2Enabled
        ? metricBase[MonitorStatus.Healthy]
        : metricBase.succeeded;
      break;
    case "warning":
      bgColor = "warning.background";
      hoverBgColor = "warning.200";
      textColor = "warning.800";
      metric = alertingV2Enabled
        ? metricBase.health_warning
        : metricBase.warning;
      break;
    case "failed":
      bgColor = "danger.background";
      hoverBgColor = "danger.200";
      textColor = "danger.dark";
      metric = alertingV2Enabled
        ? metricBase[MonitorStatus.Unhealthy]
        : metricBase.failed;
      break;
  }

  const noSyncs = metric === 0;

  const variantUrl = noSyncs
    ? "#"
    : url +
      (alertingV2Enabled
        ? "&health=" + variantToHealth(variant)
        : "&status=" + variant);

  if (noSyncs) {
    formattedSyncs = "0";
    textColor = "text.tertiary";
    bgColor = "base.lightBackground";
    hoverBgColor = "base.background";
  } else {
    formattedSyncs = commaNumber(metric);
  }

  const [hover, setHover] = useState(false);

  return (
    <RouterLink
      onClick={() => {
        analytics.track("[App Dashboard] Sync Table Cell Clicked", {
          statusFilter: variant,
          resourceType: resource?.type,
          resourceTypeName: resource?.friendlyType,
          resourceId: resourceId,
        });
      }}
      to={variantUrl}
    >
      <Cell
        height="100%"
        bg={rowHover ? hoverBgColor : bgColor}
        justifyContent="center"
        alignItems="center"
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
      >
        <Text fontWeight="medium" color={textColor}>
          {hover && !noSyncs ? (
            <>
              View syncs <ArrowRightIcon />
            </>
          ) : (
            formattedSyncs
          )}
        </Text>
      </Cell>
    </RouterLink>
  );
};

const GRID_TEMPLATE_COLUMNS = "1fr 150px 150px 150px";

const SyncHealthTableRow: FC<{
  resource: ResourceOperationsItem;
  resourceId: string;
  nested?: boolean;
}> = ({ resource, resourceId, nested = false }) => {
  const { targetResourceType, filterLocked, filterIds, setFilterIds } =
    useSyncHealthContext();

  const syncUrl = `/syncs?${targetResourceType}=${resourceId}`;
  const isFiltered = filterIds?.includes(resourceId) && filterLocked;

  const [hover, setHover] = useState(false);

  return (
    <Box
      position="relative"
      display="grid"
      gridTemplateColumns={GRID_TEMPLATE_COLUMNS}
      gridTemplateRows="42px"
      onMouseEnter={() => {
        setFilterIds(() => [resourceId], false);
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      <Cell
        bg={hover ? "base.lightBackground" : "white"}
        cursor="pointer"
        pl={nested ? 12 : undefined}
        onClick={() => {
          if (!isFiltered) {
            setFilterIds((current) => [...current, resourceId], true);
          } else {
            setFilterIds(
              (current) => current.filter((id) => id !== resourceId),
              true,
            );
          }
        }}
        gap={2}
        alignItems="center"
      >
        <Checkbox
          isChecked={isFiltered}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={() => {
            if (!isFiltered) {
              setFilterIds((current) => [...current, resourceId], true);
            } else {
              setFilterIds(
                (current) => current.filter((id) => id !== resourceId),
                true,
              );
            }
          }}
        />
        <IntegrationIcon src={resource.icon} name={resource.name} />
        <Text fontWeight="medium" isTruncated>
          {resource.name}
        </Text>
      </Cell>
      <SyncsCountCell
        resource={resource}
        resourceId={resourceId}
        variant="success"
        url={syncUrl}
        hover={hover}
      />
      <SyncsCountCell
        resource={resource}
        resourceId={resourceId}
        variant="warning"
        url={syncUrl}
        hover={hover}
      />
      <SyncsCountCell
        resource={resource}
        resourceId={resourceId}
        variant="failed"
        url={syncUrl}
        hover={hover}
      />
    </Box>
  );
};

const SyncHealthTableItem: FC<{
  resourceItems: Record<string, ResourceOperationsItem>;
}> = ({ resourceItems }) => {
  const { targetResourceType, filterLocked, filterIds, setFilterIds } =
    useSyncHealthContext();
  const [hover, setHover] = useState(false);
  const [expanded, setExpanded] = useState<boolean>(false);

  const entries = Object.entries(resourceItems);

  const aggregatedResources = useMemo<AggregatedResources>(() => {
    const statuses: AggregatedResources = {
      succeeded: 0,
      warning: 0,
      failed: 0,
      [MonitorStatus.Healthy]: 0,
      [MonitorStatus.Unhealthy]: 0,
      health_warning: 0,
    };
    for (const [_id, resource] of entries) {
      statuses.succeeded += resource.succeeded;
      statuses.warning += resource.warning;
      statuses.failed += resource.failed;

      statuses[MonitorStatus.Healthy] += resource.healthy;
      statuses[MonitorStatus.Unhealthy] += resource.unhealthy;
      statuses.health_warning += resource.health_warning;
    }

    return statuses;
  }, [entries]);

  if (entries.length === 1) {
    const [resourceId, resource] = entries[0] ? entries[0] : [null, null];
    if (!resource) return null;
    return <SyncHealthTableRow resource={resource} resourceId={resourceId} />;
  }

  const resourceIds = Object.keys(resourceItems);
  const syncUrl = `/syncs?${targetResourceType}=${resourceIds.join(",")}`;
  const isAllFiltered =
    resourceIds.every((id) => filterIds.includes(id)) && filterLocked;
  const isSomeFiltered =
    resourceIds.some((id) => filterIds.includes(id)) && filterLocked;

  return (
    <ChakraAccordion
      allowToggle
      index={expanded ? [0] : []}
      onChange={(e) => setExpanded(e === 0)}
    >
      <ChakraAccordionItem border="none">
        <Box
          display="grid"
          gridTemplateColumns={GRID_TEMPLATE_COLUMNS}
          onMouseEnter={() => {
            setHover(true);
            setFilterIds(() => resourceIds, false);
          }}
          onMouseLeave={() => {
            setHover(false);
          }}
        >
          <Cell
            cursor="pointer"
            alignItems="center"
            bg={hover ? "base.lightBackground" : "white"}
            gap={2}
            onClick={() => {
              if (expanded) {
                setFilterIds(
                  (current) =>
                    current.filter((id) => !resourceIds.includes(id)),
                  true,
                );
              } else {
                setFilterIds((current) => [...resourceIds, ...current], true);
              }
              setExpanded(!expanded);
            }}
          >
            <Box>
              <Checkbox
                isChecked={isAllFiltered}
                isIndeterminate={!isAllFiltered && isSomeFiltered}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onChange={() => {
                  if (isSomeFiltered) {
                    setFilterIds(
                      (current) =>
                        current.filter((id) => !resourceIds.includes(id)),
                      true,
                    );
                  } else {
                    setFilterIds(
                      (current) => [...resourceIds, ...current],
                      true,
                    );
                  }
                }}
              />
            </Box>
            <IntegrationIcon
              name={entries[0]?.[1].name}
              src={entries[0]?.[1].icon}
            />
            <Text fontWeight="medium" isTruncated>
              {entries[0]?.[1].friendlyType}
            </Text>
            <Pill>{entries.length}</Pill>

            <ChakraAccordionButton
              outline="none !important"
              p={1}
              borderRadius="sm"
              width="fit-content"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Box
                as={ChevronRightIcon}
                opacity={hover || expanded ? 1 : 0}
                fontSize="18px"
                transform={expanded ? "rotate(90deg)" : ""}
                transition="all 150ms ease"
              />
            </ChakraAccordionButton>
          </Cell>

          <SyncsCountCell
            syncs={aggregatedResources}
            variant="success"
            url={syncUrl}
            hover={hover}
          />
          <SyncsCountCell
            syncs={aggregatedResources}
            variant="warning"
            url={syncUrl}
            hover={hover}
          />
          <SyncsCountCell
            syncs={aggregatedResources}
            variant="failed"
            url={syncUrl}
            hover={hover}
          />
        </Box>

        <ChakraAccordionPanel p={0}>
          {entries.map(([id, resource]) => (
            <SyncHealthTableRow
              nested
              key={id}
              resource={resource}
              resourceId={id}
            />
          ))}
        </ChakraAccordionPanel>
      </ChakraAccordionItem>
    </ChakraAccordion>
  );
};

const SyncHealthSkeleton: FC = () => (
  <Box display="grid" gridTemplateColumns={GRID_TEMPLATE_COLUMNS}>
    <Cell>
      <Row alignItems="center" gap={2}>
        <SkeletonBox borderRadius="full" width="24px" height="24px" />
        <SkeletonBox width="200px" height="16px" />
      </Row>
    </Cell>
    <Cell justifyContent="center" alignItems="center">
      <SkeletonBox width="50px" height="16px" />
    </Cell>
    <Cell justifyContent="center" alignItems="center">
      <SkeletonBox width="50px" height="16px" />
    </Cell>
    <Cell justifyContent="center" alignItems="center">
      <SkeletonBox width="50px" height="16px" />
    </Cell>
  </Box>
);

export const SyncHealthTable: FC = () => {
  const { workspace } = useUser();

  const alertingV2Enabled = workspace?.alerting_v2_enabled;

  const { data: tableData, isLoading: isTableLoading } =
    useLatestSyncStatusQuery(
      {},
      {
        select: (data) => {
          const enrichedSyncs = enrichSyncs(
            data.syncs,
            data.getSourceDefinitions,
            data.getDestinationDefinitions,
          );
          return transformSyncs(enrichedSyncs);
        },
      },
    );

  const {
    targetResourceType,
    setTargetResourceType,
    resetFilters,
    filterLocked,
  } = useSyncHealthContext();
  const resources = tableData?.[targetResourceType] ?? {};
  return (
    <DashboardMainItem isLoading={isTableLoading}>
      <Column
        onMouseLeave={() => {
          if (!filterLocked) {
            resetFilters();
          }
        }}
        overflow="hidden"
      >
        <Box
          display="grid"
          gridTemplateColumns={GRID_TEMPLATE_COLUMNS}
          gridTemplateRows="42px"
          borderBottom="1px"
          borderColor="base.border"
        >
          <Cell>
            <ToggleButtonGroup
              size="sm"
              value={targetResourceType}
              onChange={(v) => {
                resetFilters();
                if (v === "destination" || v === "source")
                  setTargetResourceType(v);
              }}
            >
              <ToggleButton label="By destination" value="destination" />
              <ToggleButton label="By source" value="source" />
            </ToggleButtonGroup>
          </Cell>
          <Cell justifyContent="center">
            <StatsItemTitle>Healthy</StatsItemTitle>
          </Cell>
          <Cell justifyContent="center">
            <StatsItemTitle>Warning</StatsItemTitle>
          </Cell>
          <Cell justifyContent="center">
            <StatsItemTitle>
              {alertingV2Enabled ? MonitorStatus.Unhealthy : "Failing"}
            </StatsItemTitle>
          </Cell>
        </Box>
        <Column overflow="auto">
          {Object.keys(resources).length ? (
            Object.entries(resources).map(([_typeName, resource], idx) => (
              <SyncHealthTableItem key={idx} resourceItems={resource} />
            ))
          ) : isTableLoading ? (
            times(10, (idx) => <SyncHealthSkeleton key={idx} />)
          ) : (
            <Column sx={{ "& > div": { p: 0, pt: 4, border: "none" } }}>
              <EmptyState
                title="No active syncs"
                message="A sync defines how and when your data will be sent to a destination. This table only shows syncs that are enabled."
                imageUrl={syncPlaceholder}
                m={4}
              />
            </Column>
          )}
        </Column>
      </Column>
    </DashboardMainItem>
  );
};

export const Cell: FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Row px={6} py={2} {...props}>
      {children}
    </Row>
  );
};
