import { StatusBadge } from "@hightouchio/ui";
import { SyncRunStatus, SyncStatusToText } from "src/utils/syncs";

/**
 * TODO: Soon, run status and sync health will be completely decoupled. When that happens,
 * we can convert this component to only take monitor statuses and cut a separate component
 * for showing run statuses with an indicator.
 * @deprecated Use SyncRunStatusIndicator instead.
 */
export const SyncRunStatusBadge = ({
  status: statusProp,
  request,
}: {
  status?: any;
  request?: any;
}) => {
  const status = statusProp || request?.status_computed;

  if (!status) {
    return null;
  }

  const percent = request?.completion_ratio
    ? Math.round(request.completion_ratio * 100)
    : undefined;

  switch (status) {
    case SyncRunStatus.WARNING:
      return (
        <StatusBadge variant="warning">{SyncStatusToText[status]}</StatusBadge>
      );
    case SyncRunStatus.FAILED:
      return (
        <StatusBadge variant="error">{SyncStatusToText[status]}</StatusBadge>
      );
    case SyncRunStatus.ACTIVE:
      return (
        <StatusBadge variant="processing" progress={percent}>
          {SyncStatusToText[SyncRunStatus.ACTIVE]}
        </StatusBadge>
      );
    case SyncRunStatus.PROCESSING:
      return (
        <StatusBadge variant="processing" progress={percent}>
          {SyncStatusToText[SyncRunStatus.PROCESSING]}
        </StatusBadge>
      );
    case SyncRunStatus.SUCCESS:
      return (
        <StatusBadge variant="success">{SyncStatusToText[status]}</StatusBadge>
      );
    case SyncRunStatus.QUERYING:
      return (
        <StatusBadge variant="processing">
          {SyncStatusToText[status]}
        </StatusBadge>
      );
    case SyncRunStatus.REPORTING:
      if (request?.phase_to_status?.report?.error?.message) {
        return (
          <StatusBadge variant="warning">
            {SyncStatusToText[status]}
          </StatusBadge>
        );
      }
      return (
        <StatusBadge variant="processing">
          {SyncStatusToText[status]}
        </StatusBadge>
      );
    case SyncRunStatus.PENDING:
    case SyncRunStatus.INTERRUPTED:
    case SyncRunStatus.ENQUEUED:
    case SyncRunStatus.CANCELLED:
    case SyncRunStatus.DISABLED:
      return (
        <StatusBadge variant="inactive">{SyncStatusToText[status]}</StatusBadge>
      );

    default:
      return <StatusBadge variant="inactive">Unknown</StatusBadge>;
  }
};
