export async function query<Data>(
  query: string,
  variables?: any,
  options?: { isAdmin: boolean },
): Promise<Data> {
  const headers = {
    "Content-Type": "application/json",
  };

  if (options?.isAdmin) {
    headers["X-Hasura-Role"] = "cs_admin";
  }

  const res = await fetch(`${import.meta.env.VITE_API_BASE_URL}/v1/graphql`, {
    method: "POST",
    headers,
    body: JSON.stringify({
      query,
      variables,
    }),
    credentials: "include",
  });

  const json = await res.json();

  if (json.errors) {
    const { message } = json.errors[0] || "Error";
    throw new Error(message);
  }

  return json.data;
}
