import { FC } from "react";

import {
  Text,
  Drawer,
  Row,
  DrawerBody,
  Column,
  SectionHeading,
  CodeSnippet,
  Heading,
  Alert,
} from "@hightouchio/ui";

import { FailedEvent } from "src/graphql";
import { formatUTC } from "src/utils/time";

export const EventDrawer: FC<
  Readonly<{
    title: string;
    event: FailedEvent | undefined;
    onClose: () => unknown;
  }>
> = ({ title, event, onClose }) => {
  return (
    <Drawer isOpen={Boolean(event)} onClose={onClose} size="lg">
      <Row
        p={6}
        borderBottom="1px solid"
        borderColor="base.border"
        width="100%"
      >
        <Heading>{title}</Heading>
      </Row>
      <DrawerBody>
        <Column gap={4} pb={6}>
          {event && (
            <Column>
              <SectionHeading>{event.payload.type}</SectionHeading>
              <Text color="text.secondary">
                Received at: {formatUTC(event.firstAttemptAt)}
              </Text>
            </Column>
          )}
          <Alert
            variant="inline"
            type="error"
            title="Error"
            message={event?.errorMsg}
          />
          <CodeSnippet
            label="Event payload"
            code={event ? JSON.stringify(event.payload, null, 4) : ""}
          />
        </Column>
      </DrawerBody>
    </Drawer>
  );
};
