import { FC } from "react";

import { Column, Text } from "@hightouchio/ui";

import { QueryBuilder } from "src/components/explore";
import { ParentModel } from "src/pages/audiences/types";
import { AndCondition, AndOrCondition, OrCondition } from "src/types/visual";

type Props = {
  parentModel: ParentModel | null | undefined;
  conditions: AndOrCondition<AndCondition | OrCondition> | undefined;
  setConditions: (filter: AndCondition | OrCondition | null) => void;
};

export const SubsetFilter: FC<Readonly<Props>> = ({
  parentModel,
  conditions,
  setConditions,
}) => {
  return (
    <>
      <Column>
        <Text fontWeight="medium">Definition</Text>
        <Text color="text.secondary">
          Add filters to specify who should be included in this subset
        </Text>
      </Column>

      <QueryBuilder
        parent={parentModel}
        filter={conditions}
        setConditions={(c) => {
          setConditions(c);
        }}
      />
    </>
  );
};
