import { FC } from "react";

import { Column, Text } from "@hightouchio/ui";
import orderBy from "lodash/orderBy";
import pluralize from "pluralize";

import { useSamplingForRelatedModelQuery } from "src/graphql";

import { ParentModelSamplingConfigCard } from "./parent-model-sampling-config-card";
import { PageSpinner } from "src/components/loading";

type Props = {
  modelId: string;
  sourceId: string;
};

export const RelatedModelSampling: FC<Props> = ({ modelId, sourceId }) => {
  const { data: parentModelRelationships, isLoading } =
    useSamplingForRelatedModelQuery(
      {
        relatedModelId: modelId,
      },
      {
        select: (data) =>
          // Order by enabled samples first
          orderBy(
            data.parent_model_relationships,
            "from_model.sampled_segments.0.enabled",
            "asc",
          ),
      },
    );

  if (isLoading) {
    return <PageSpinner />;
  }

  return (
    <Column gap={6} p={6}>
      <Column>
        <Text fontWeight="medium" size="lg">
          Related model sampling
        </Text>
        <Text color="text.secondary">
          Sampling is configured at the parent model level.{" "}
          {parentModelRelationships?.length
            ? `This model is related to ${pluralize(
                "parent models",
                parentModelRelationships.length,
                true,
              )}, listed below.`
            : "This model is not related to any parent models."}
        </Text>
      </Column>

      <Column gap={6}>
        {parentModelRelationships?.map(({ id, from_model }) => {
          const sampledSegment = from_model.sampled_segments?.[0];
          if (!sampledSegment) {
            return null;
          }

          return (
            <ParentModelSamplingConfigCard
              key={id}
              enabled={sampledSegment.enabled}
              lastRunAt={sampledSegment.last_run_at}
              parentModelId={from_model.id}
              parentModelName={from_model.name}
              sampleRate={sampledSegment.sample_rate}
              schedule={sampledSegment.schedule}
              sourceId={sourceId}
            />
          );
        })}
      </Column>
    </Column>
  );
};
