import { createContext, useContext } from "react";

import { noop } from "ts-essentials";

import { RequiredParentModelFieldsForQueryBuilder } from "src/components/audiences/types";
import {
  Condition,
  EventCondition,
  FilterableColumn,
  InlineTraitCondition,
  PropertyCondition,
  Relationship,
  TraitDefinition,
} from "src/types/visual";

export type SelectedCondition = {
  condition: PropertyCondition | InlineTraitCondition | EventCondition;
  update: (condition: Condition) => void;
} | null;

type QueryBuilderContextType = {
  columns: FilterableColumn[];
  events: Relationship[];
  relatedAudiences: { label: string; value: string }[];
  relationships: Relationship[];
  traits: (TraitDefinition & {
    parentModel?: RequiredParentModelFieldsForQueryBuilder | null;
  })[];
  selectedCondition: SelectedCondition;
  selectCondition: (input: SelectedCondition) => void;
};

const defaultContextValue: QueryBuilderContextType = {
  columns: [],
  events: [],
  relatedAudiences: [],
  relationships: [],
  traits: [],
  selectedCondition: null,
  selectCondition: noop,
};

export const QueryBuilderContext =
  createContext<QueryBuilderContextType>(defaultContextValue);

export const useQueryBuilderContext = () => useContext(QueryBuilderContext);
