import { FC } from "react";

import { CodeSnippet, Heading, Text } from "@hightouchio/ui";

import { Link } from "src/router";

import { CodeParams, SetupProps } from "./types";

function getPHPCode(params: CodeParams): string {
  return `
  <?php

  use Hightouch\\Hightouch;

  Hightouch::init('${params.writeKey}', [
    'host' => 'https://${params.apiHost}',
  ]);

  Hightouch::track([
    'event' => 'Created Account',
    'userId' => '123',
    'properties' => [
      'application' => 'Desktop',
      'version' => '1.2.3',
    ],
  ]);
  `;
}

const phpInstall = "composer require ht-sdks/events-sdk-php";

export const SetupPHP: FC<SetupProps> = (props: SetupProps) => {
  return (
    <>
      <Heading>Installing the SDK</Heading>
      <Text>
        This SDK is available via{" "}
        <Link href="https://packagist.org/packages/ht-sdks/events-sdk-php">
          Composer
        </Link>
        .
      </Text>
      <CodeSnippet code={phpInstall} />
      <Heading>Using the SDK</Heading>
      <CodeSnippet label="index.php" code={getPHPCode(props)} />
    </>
  );
};
