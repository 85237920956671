import { FC } from "react";

import { Navigate } from "src/router";
import { Route, Routes } from "src/router";
import { AssetType } from "src/types/visual";

import Campaigns from "./campaigns";
import { CampaignsTable } from "./campaigns-table";

export const CampaignsRoutes: FC = () => (
  <Routes>
    <Route element={<Campaigns />}>
      <Route path=":assetType" element={<CampaignsTable />}>
        <Route path=":savedViewId" />
      </Route>
    </Route>
    <Route index element={<Navigate to={`./${AssetType.Email}`} replace />} />
  </Routes>
);
