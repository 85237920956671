import { FC, useState } from "react";

import {
  Box,
  Button,
  Column,
  EditIcon,
  Row,
  Text,
  WarningIcon,
} from "@hightouchio/ui";
import { format, parseISO } from "date-fns";

import { ResourceActivity } from "src/hooks/use-resource-activity";
import { Markdown } from "src/ui/markdown";

import { ResourceActivityExpandedView } from "./expanded-view";
import { DisplayedResourceActivity } from "./timeline";

export const ActivityChanges: FC<{ changes?: string[] }> = ({ changes }) => {
  if (!changes) {
    return null;
  }
  return (
    <Box as="ul" ml={4}>
      {changes.map((change, index) => {
        return (
          <li key={index}>
            <Markdown>{change}</Markdown>
          </li>
        );
      })}
    </Box>
  );
};

export const ActivityItem: FC<{
  activity: ResourceActivity;
  formattedChanges: DisplayedResourceActivity[];
  attributionLoading: boolean;
  icon?: JSX.Element;
}> = ({ activity, formattedChanges, icon = <EditIcon /> }) => {
  const [rawViewOpen, setRawViewOpen] = useState(false);
  return (
    <>
      <Row>
        {icon && (
          <Column
            mx={3}
            boxSize="32px"
            align="center"
            justify="center"
            borderRadius="full"
            bg="base.background"
          >
            <Row color="text.secondary" width="16px" fontSize="16px">
              {icon}
            </Row>
          </Column>
        )}
        <Column gap={1} mt={1}>
          {activity.metadata.is_guest_admin && (
            <Row
              as="span"
              align="center"
              gap={1}
              fontWeight="semibold"
              color="danger.600"
            >
              <Box as={WarningIcon} fontSize="16px" /> Change made by Hightouch
              employee
            </Row>
          )}
          <Markdown>
            {activity.metadata.user_name + " " + formattedChanges[0]?.message}
          </Markdown>
          <Column>
            {formattedChanges.map((display, idx) => (
              <ActivityChanges key={idx} changes={display.changes} />
            ))}
          </Column>
          {activity.approvedDraft &&
            activity.approvedDraft.applied_by_user?.id !==
              Number(activity.metadata.action_by) && (
              <Text size="sm" fontWeight="medium" color="text.secondary">
                Approved by {activity.approvedDraft.applied_by_user?.name} on{" "}
                {activity.approvedDraft.applied_at
                  ? format(parseISO(activity.approvedDraft.applied_at), "PPpp")
                  : "unknown"}
              </Text>
            )}
          {activity.deployment && (
            <Text size="sm" fontWeight="medium" color="text.secondary">
              Deployed by {activity.deployment.created_by_name} from{" "}
              {activity.deployment.from_workspace_name} on{" "}
              {activity.deployment.created_at
                ? format(parseISO(activity.deployment.created_at), "PPpp")
                : "unknown"}
            </Text>
          )}
        </Column>
      </Row>
      <Column mt={1} pt="2px">
        <Text size="sm" fontWeight="medium" color="text.secondary">
          {format(parseISO(activity.metadata.created_at), "PPpp")}
        </Text>
      </Column>
      <Column alignItems="flex-end" mt={1}>
        <Button size="sm" onClick={() => setRawViewOpen(true)}>
          Details
        </Button>
      </Column>

      <ResourceActivityExpandedView
        activity={activity}
        open={rawViewOpen}
        onClose={() => setRawViewOpen(false)}
      />
    </>
  );
};
