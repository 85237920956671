export const DEFAULT_PLANNER_REASON = ({ definitionName }) =>
  `The \`hightouch_planner\` schema is used to store logs of previously-synced data. Before enabling the Lightning sync engine, you must first create this schema and grant Hightouch permission to read/write data. Try running this SQL snippet in your ${definitionName} console.`;

export const DEFAULT_AUDIT_REASON = ({ definitionName }) =>
  `The \`hightouch_audit\` schema is used to store logs of previously-synced data. Before enabling the Lightning sync engine, you must first create this schema and grant Hightouch permission to read/write data. Try running this SQL snippet in your ${definitionName} console.`;

export const DEFAULT_PLANNER_CODE_TITLE = () =>
  "Create hightouch_planner schema and grant access";

export const DEFAULT_AUDIT_CODE_TITLE = () =>
  "Create hightouch_planner schema and grant access";
