import { Bold } from "@tiptap/extension-bold";
import { markInputRule, markPasteRule } from "@tiptap/react";

// Example: *foo*
const starInputRegex = /(?:^|\s)((?:\*)((?:[^*]+))(?:\*))$/;
const starPasteRegex = /(?:^|\s)((?:\*)((?:[^*]+))(?:\*))/g;

export const SlackBold = Bold.extend({
  addOptions() {
    return {
      HTMLAttributes: { class: "rte-slack-bold-class" },
    };
  },
  addInputRules() {
    return [markInputRule({ type: this.type, find: starInputRegex })];
  },
  addPasteRules() {
    return [markPasteRule({ type: this.type, find: starPasteRegex })];
  },
});
