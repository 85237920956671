import { FC, useEffect, useState } from "react";

import {
  Column,
  Row,
  FormField,
  Combobox,
  TextInput,
  Box,
  Button,
  Text,
  PlusIcon,
  Tooltip,
  Spinner,
  RefreshIcon,
  Alert,
} from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";

import { DestinationForm } from "src/components/destinations/sync-form";
import { Explore } from "src/components/explore";
import { QueryTypeSelect } from "src/components/explore/query-type-select";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { ScheduleManager } from "src/components/schedule";
import { ScheduleType } from "src/components/schedule/types";
import { SourceForm } from "src/components/sources/setup";
import { Source } from "src/components/sources/source-catalog";
import { Testing } from "src/components/sources/testing";
import { useSourceTesting } from "src/components/sources/testing/hooks";
import {
  useCreateSyncMutation,
  useCreateModelMutation,
  useModelQuery,
  SourceDefinition,
  useCreateSourceV2Mutation,
  useSourceQuery,
  useDestinationQuery,
  useSourceDefinitionsQuery,
  useUpdateSourceV2Mutation,
  useUpdateModelMutation,
  useSourcesQuery,
} from "src/graphql";
import { usePartner } from "src/partner/context";
import { Welcome } from "src/partner/welcome";
import {
  PartnerWizard,
  PartnerWizardStep,
  PartnerWizardStickyHeader,
} from "src/partner/wizard";
import { QueryType } from "src/types/models";
import { Selectable } from "src/ui/selectable";
import {
  getModelInputFromState,
  useModelRun,
  useUpdateQuery,
} from "src/utils/models";
import { SlugResourceType, useResourceSlug } from "src/utils/slug";
import { useWizardStepper } from "src/utils/use-wizard-stepper";

import { sendPostMessage } from "./send-post-message";
import { WIZARD_CONTAINER_STYLES } from "./utils";
import { useScheduleState } from "src/components/schedule/schedule-manager";
import {
  DEFAULT_LIGHTNING_SCHEMA_CONFIG,
  WarehouseSchemaConfig,
} from "src/components/sources/setup/constants";
import { useModelState } from "src/hooks/use-model-state";
import { CreateSyncConfig } from "./types";

const Loading: FC = () => (
  <Row my={8} justify="center">
    <Spinner size="lg" />
  </Row>
);

const LIGHTNING_ONLY_TEST_IDS = [
  "validatePlannerSchemaPermissions",
  "validateAuditSchemaPermissions",
];

export const CreateSync = () => {
  const {
    config: partnerConfig,
    brand,
    cobranded,
  } = usePartner<CreateSyncConfig>();
  const [modelId, setModelId] = useState<string>();
  const [modelName, setModelName] = useState("");
  const { validateSchedule } = useScheduleState("sync");
  const [schedule, setSchedule] = useState<any>({ type: ScheduleType.MANUAL });
  const [syncConfig, setSyncConfig] = useState<any>();
  const { getSlug: getSyncSlug } = useResourceSlug(SlugResourceType.Syncs);
  const { getSlug: getModelSlug } = useResourceSlug(SlugResourceType.Segments);
  const { getSlug: getSourceSlug } = useResourceSlug(
    SlugResourceType.Connections,
  );
  const [_sourceDefinition, setSourceDefinition] = useState<SourceDefinition>();
  const [tunnelId, setTunnelId] = useState<string | null>();
  const [credentialId, setCredentialId] = useState<string>();
  const [sourceConfig, setSourceConfig] = useState<any>({});
  const [sourceId, setSourceId] = useState(partnerConfig.sourceId ?? "");
  const [isWelcome, setIsWelcome] = useState(cobranded);
  const [step, setStep] = useWizardStepper(0);
  const flags = useFlags();
  const { appBypassSourceTests } = flags;
  const {
    results: testResults,
    steps: testSteps,
    getTestSteps,
    runTest,
    timeElapsed,
  } = useSourceTesting();

  const [plannerDatabase, setPlannerDatabase] = useState<string | undefined>();
  const [schema, setSchema] = useState<WarehouseSchemaConfig>(
    DEFAULT_LIGHTNING_SCHEMA_CONFIG,
  );

  const [lightningEnabled, setLightningEnabled] = useState<boolean | undefined>(
    false,
  );
  const [lightningSchemaMode, setLightningSchemaMode] = useState<
    "shared" | "separate"
  >("separate");

  const sourceDefinitionsQuery = useSourceDefinitionsQuery(undefined, {
    select: (data) => data.getSourceDefinitions,
    suspense: true,
  });
  const sourcesQuery = useSourcesQuery(undefined, {
    select: (data) => data.connections,
    suspense: true,
  });

  const [isCreatingSource, setIsCreatingSource] = useState(
    sourcesQuery?.data?.length === 0,
  );

  const { data: model } = useModelQuery(
    {
      id: String(modelId),
    },
    {
      enabled: Boolean(modelId),
      select: (data) => data.segments_by_pk,
    },
  );
  const { data: source } = useSourceQuery(
    { id: String(sourceId) },
    {
      enabled: Boolean(sourceId),
      select: (data) => data.connections_by_pk,
    },
  );
  const { data: destination } = useDestinationQuery(
    { id: String(partnerConfig.destinationId) },
    {
      select: (data) => data.destinations_by_pk,
    },
  );

  const createSourceMutation = useCreateSourceV2Mutation({
    onSuccess: () => {
      // skip query invalidation
    },
  });
  const updateSourceMutation = useUpdateSourceV2Mutation({
    onSuccess: () => {
      // skip query invalidation
    },
  });
  const { mutateAsync: createSync } = useCreateSyncMutation();
  const createModelMutation = useCreateModelMutation({
    onSuccess: () => {
      // skip query invalidation
    },
  });
  const updateModelMutation = useUpdateModelMutation({
    onSuccess: () => {
      // skip query invalidation
    },
  });
  const updateQuery = useUpdateQuery();

  const sourceDefinition = _sourceDefinition ?? source?.definition;

  const allNonLightningTestsPassed = testResults?.stepResults
    ?.filter((r) => !LIGHTNING_ONLY_TEST_IDS.includes(r.id))
    .every((r) => r.success);
  const canProgressWithFailedTest =
    allNonLightningTestsPassed && sourceDefinition?.supportsInWarehouseDiffing;

  const modelState = useModelState({
    connection: source,
    query_type: sourceDefinition?.supportedQueries.includes("custom")
      ? QueryType.Custom
      : undefined,
  });

  const {
    runQuery,
    cancelQuery,
    rows,
    numRowsWithoutLimit,
    isResultTruncated,
    columns,
    rawColumns,
    loading: queryLoading,
    error: queryError,
    errorAtLine: queryErrorAtLine,
    resetRunState,
  } = useModelRun(modelState.state);

  const createModel = async () => {
    if (modelId) {
      await updateModelMutation.mutateAsync({
        id: modelId,
        input: {
          name: modelName,
          primary_key: modelState.state.primary_key,
        },
      });
      await updateQuery({
        model: modelState.state,
        columns: columns ?? rawColumns,
        overwriteMetadata: true,
      });
      return;
    }

    const slug = await getModelSlug(`partner-${modelName}`);

    const data = await createModelMutation.mutateAsync({
      input: {
        name: modelName,
        ...getModelInputFromState(modelState.state),
        slug,
        columns: { data: columns },
        draft: false,
      },
    });
    const id = data.insert_segments_one?.id;
    setModelId(id);
  };

  const createSource = async () => {
    if (sourceId) {
      await updateSourceMutation.mutateAsync({
        id: sourceId,
        source: {
          name: sourceDefinition?.name ?? source?.name,
          config: sourceConfig,
          type: sourceDefinition?.type,
          credential_id: credentialId ? String(credentialId) : undefined,
        },
      });
      return;
    }

    const slug = await getSourceSlug(`partner-${sourceDefinition?.type}`);
    const { createSourceWithSecrets } = await createSourceMutation.mutateAsync({
      source: {
        slug,
        name: sourceDefinition?.name,
        config: sourceConfig,
        type: sourceDefinition?.type,
        setup_complete: true,
        tunnel_id: tunnelId,
        credential_id: credentialId ? String(credentialId) : undefined,
        sample_data_source_id: null,
        plan_in_warehouse: lightningEnabled,
        plan_in_warehouse_config: lightningEnabled
          ? { plannerDatabase, schema }
          : null,
      },
    });
    const id = createSourceWithSecrets?.id.toString();
    setSourceId(id);
  };

  const submit = async () => {
    const slug = await getSyncSlug(`${model?.name} to ${destination?.name}`);
    await createSync({
      object: {
        slug,
        destination_id: destination?.id,
        segment_id: model?.id,
        config: {
          ...syncConfig,
          configVersion: destination?.definition?.configVersion,
        },
        schedule: schedule?.type === "manual" ? null : schedule,
      },
    });
    sendPostMessage("create_sync_finished");
  };

  const testVariables = {
    sourceId: undefined,
    sourceType: sourceDefinition?.type,
    configuration: sourceConfig,
    credentialId: credentialId ? Number(credentialId) : undefined,
    tunnelId: tunnelId ? String(tunnelId) : undefined,
    warehousePlanConfig: lightningEnabled
      ? { plannerDatabase, schema }
      : undefined,
  };

  const steps: Array<PartnerWizardStep | boolean | undefined> = [
    !partnerConfig.sourceId &&
      sourcesQuery.data &&
      sourcesQuery.data.length > 0 && {
        label: "Select a data source",
        description:
          "Choose an existing data source or set up a new connection.",
        next: "Select a data source to continue",
        render: () => {
          sendPostMessage("create_sync_started");

          return (
            <>
              <PartnerWizardStickyHeader>
                Select a data source
              </PartnerWizardStickyHeader>
              <Box
                flex={1}
                display="grid"
                gridTemplateColumns="repeat(auto-fit, minmax(200px, 1fr))"
                gridAutoRows="64px"
                gap={4}
              >
                {sourcesQuery.data
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((source) => {
                    return (
                      <Selectable
                        bg="white"
                        key={source.id}
                        selected={false}
                        p={4}
                        overflow="visible"
                        height="64px"
                        onSelect={() => {
                          setIsCreatingSource(false);
                          setSourceId(source.id);
                          setStep((step) => step + 1);
                        }}
                      >
                        <Row
                          align="center"
                          gap={4}
                          textAlign="left"
                          overflow="hidden"
                        >
                          <IntegrationIcon
                            src={source.definition.icon}
                            name={source.definition.name}
                          />
                          <Text fontWeight="medium" wordBreak="break-all">
                            {source.name}
                          </Text>
                        </Row>
                      </Selectable>
                    );
                  })}

                <Selectable
                  selected={false}
                  bg="forest.100"
                  p={4}
                  overflow="visible"
                  height="64px"
                  onSelect={() => {
                    setIsCreatingSource(true);
                    setStep((step) => step + 1);
                  }}
                >
                  <Row align="center" gap={4} fontSize="3xl" textAlign="left">
                    <PlusIcon />
                    <Text fontWeight="semibold">Add a new source</Text>
                  </Row>
                </Selectable>
              </Box>
            </>
          );
        },
      },
    isCreatingSource && {
      label: "Select a new data source type",
      description:
        "Connect to your data warehouse, transactional database, object storage, etc.",
      next: "Select a data source to continue",
      render: () => {
        const sourceDefinitions: SourceDefinition[] = [];
        partnerConfig.allowedSourceTypes.forEach((type) => {
          const definition = sourceDefinitionsQuery.data?.find(
            (d) => d.type === type,
          );
          if (definition) {
            sourceDefinitions.push(definition);
          }
        });

        return (
          <>
            <PartnerWizardStickyHeader>
              Select a new data source type
            </PartnerWizardStickyHeader>
            <Box
              display="grid"
              gridTemplateColumns="repeat(auto-fit, minmax(200px, 1fr))"
              gap={4}
            >
              {sourceDefinitions
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((definition) => {
                  return (
                    <Source
                      key={definition.type}
                      definition={definition}
                      onSelect={(definition) => {
                        setSourceId("");
                        setSourceDefinition(definition);
                        setStep((step) => step + 1);
                      }}
                      selected={false}
                    />
                  );
                })}
            </Box>
          </>
        );
      },
    },
    isCreatingSource && {
      label: "Configure your data source",
      description:
        "Provide credentials and connection details for your chosen data source.",
      onNext: async () => {
        await getTestSteps(testVariables);
        runTest(testVariables);
        setStep((step) => step + 1);
      },
      render: () => (
        <>
          <PartnerWizardStickyHeader>
            Configure your data source
          </PartnerWizardStickyHeader>
          <Column {...WIZARD_CONTAINER_STYLES}>
            <SourceForm
              config={sourceConfig}
              credentialId={credentialId}
              definition={sourceDefinition!}
              disableAuthMethod={false}
              isSetup={true}
              lightningEnabled={lightningEnabled}
              setLightningEnabled={setLightningEnabled}
              setConfig={setSourceConfig}
              setCredentialId={setCredentialId}
              setTunnelId={setTunnelId}
              sourceId={undefined}
              tunnelId={tunnelId}
              plannerDatabase={plannerDatabase}
              setPlannerDatabase={setPlannerDatabase}
              schema={schema}
              setSchema={setSchema}
              lightningSchemaMode={lightningSchemaMode}
              setLightningSchemaMode={setLightningSchemaMode}
              hasSetupLightning={false}
              onConnectClick={() => {}}
            />
          </Column>
        </>
      ),
    },
    isCreatingSource && {
      label: "Test your source connection",
      description:
        "Verify that you can query your data source with the provided credentials.",
      isDisabled:
        !testResults?.success &&
        !canProgressWithFailedTest &&
        !appBypassSourceTests,
      tooltip:
        !testResults?.success &&
        !canProgressWithFailedTest &&
        !appBypassSourceTests
          ? "Cannot continue until tests pass"
          : undefined,
      onNext: async () => {
        await createSource();
        setStep((step) => step + 1);
      },
      render: () => {
        if (!sourceDefinition) {
          return null;
        }

        return (
          <>
            <PartnerWizardStickyHeader>
              Test your source connection
            </PartnerWizardStickyHeader>
            <Column {...WIZARD_CONTAINER_STYLES}>
              <Testing
                config={sourceConfig}
                credentialId={credentialId}
                isSetup={true}
                plannerDatabase={undefined}
                results={testResults}
                sourceDefinition={sourceDefinition}
                steps={testSteps}
                timeElapsed={timeElapsed}
              />

              {testResults?.success === false && (
                <Row>
                  <Button
                    icon={RefreshIcon}
                    mt={10}
                    onClick={() => runTest(testVariables)}
                  >
                    Test again
                  </Button>
                </Row>
              )}
            </Column>
          </>
        );
      },
    },
    !sourceDefinition?.supportedQueries?.includes("custom") && {
      label: "Select a modeling method",
      description: "Choose how you want to build a data model for this sync.",
      isDisabled: !modelState.state.query_type,
      tooltip: !modelState.state.query_type
        ? "Select a modeling method to continue"
        : undefined,
      onNext: () => Promise.resolve(setStep((step) => step + 1)), // onNext expects a Promise
      render: () => (
        <>
          <PartnerWizardStickyHeader>
            Select a modeling method
          </PartnerWizardStickyHeader>
          {source ? (
            <QueryTypeSelect
              isEmbeddedFlow
              selected={modelState.state.query_type as QueryType}
              source={source}
              onChange={(query_type) => {
                modelState.onChange({ query_type });
              }}
            />
          ) : (
            <Loading />
          )}
        </>
      ),
    },
    {
      label: "Define your data model",
      description:
        "Query your source using custom SQL or by reading from an existing table.",
      isDisabled:
        !modelState.isPreviewFresh ||
        Boolean(queryError) ||
        !modelName ||
        !modelState.state.primary_key,
      tooltip: queryError
        ? "Fix the error and preview again to continue"
        : !modelState.isPreviewFresh
          ? "Preview results to continue"
          : !modelName
            ? "Name your model to continue"
            : !modelState.state.primary_key
              ? "Select a primary key to continue"
              : undefined,
      onNext: async () => {
        await createModel();
        setStep((step) => step + 1);
      },
      render: () => {
        const columnOptions: { value: string; label: string }[] =
          columns?.map(({ name }) => ({ value: name, label: name })) ?? [];

        const header = (
          <PartnerWizardStickyHeader>
            Define your data model
          </PartnerWizardStickyHeader>
        );

        if (!source) {
          return (
            <>
              {header}
              <Column gap={4} {...WIZARD_CONTAINER_STYLES}>
                <Loading />
              </Column>
            </>
          );
        }

        return (
          <>
            {header}
            <Column gap={4} {...WIZARD_CONTAINER_STYLES}>
              <Row
                gap={4}
                flexDir={{ base: "column", md: "row" }}
                pb={6}
                borderBottom="1px"
                borderColor="base.border"
              >
                <FormField
                  label="Model name"
                  description="Give your data model a unique name."
                >
                  <TextInput
                    width="100%"
                    value={modelName}
                    onChange={(event) => setModelName(event.target.value)}
                    placeholder="Enter a name..."
                  />
                </FormField>
                <FormField
                  label="Primary key"
                  description="The column that uniquely identifies each row."
                >
                  <Box sx={{ "& > span": { width: "100%" } }}>
                    <Tooltip
                      message="Preview results before selecting a primary key column"
                      isDisabled={Boolean(columnOptions?.length)}
                    >
                      <Combobox
                        width="100%"
                        isDisabled={!columnOptions?.length}
                        options={columnOptions}
                        placeholder="Select a column..."
                        value={
                          columnOptions?.find(
                            (co) => co.value === modelState?.state?.primary_key,
                          )?.value
                        }
                        onChange={(column) => {
                          if (column) {
                            modelState.onChange({
                              primary_key: column,
                            });
                          }
                        }}
                      />
                    </Tooltip>
                  </Box>
                </FormField>
              </Row>
              <Explore
                limit
                isEmbeddedFlow
                modelState={modelState}
                isPlaceholder={false}
                cancelQuery={cancelQuery}
                columns={columns}
                isResultTruncated={Boolean(isResultTruncated)}
                numRowsWithoutLimit={numRowsWithoutLimit}
                rows={rows}
                runQuery={runQuery}
                source={source}
                error={queryError}
                errorAtLine={queryErrorAtLine}
                loading={queryLoading}
                rowsPerPage={15}
              />
            </Column>
          </>
        );
      },
    },
    {
      next: (
        <Button
          variant="primary"
          size="lg"
          form="destination-form"
          type="submit"
        >
          Continue
        </Button>
      ),
      label: `Configure sync to ${brand.name}`,
      description: `Define how your data model should be ingested by ${brand.name}.`,
      isDisabled: !destination,
      render: () => {
        const header = (
          <PartnerWizardStickyHeader>
            Configure sync to {brand.name}
          </PartnerWizardStickyHeader>
        );

        if (!destination) {
          return (
            <>
              {header}
              <Column {...WIZARD_CONTAINER_STYLES}>
                <Alert
                  variant="inline"
                  type="error"
                  title="Destination ID error"
                  message={
                    <Text>
                      The <Text isMonospace>destinationId</Text> you provided is
                      undefined.{" "}
                    </Text>
                  }
                />
              </Column>
            </>
          );
        } else if (!model) {
          return (
            <>
              {header}
              <Column {...WIZARD_CONTAINER_STYLES}>
                <Loading />
              </Column>
            </>
          );
        } else if (model) {
          return (
            <>
              {header}
              <DestinationForm
                permission={{
                  v2: {
                    resource: "sync",
                    grant: "can_create",
                    creationOptions: {
                      modelId: model.id.toString(),
                      destinationId: destination.id.toString(),
                    },
                  },
                  v1: {
                    resource: "sync",
                    grant: "create",
                  },
                }}
                testPermission={{
                  v2: {
                    resource: "sync",
                    grant: "can_test",
                    creationOptions: {
                      modelId: model.id.toString(),
                      destinationId: destination.id.toString(),
                    },
                  },
                  v1: {
                    resource: "sync",
                    grant: "testrow",
                  },
                }}
                hideSave
                hideSidebar
                destination={destination}
                destinationDefinition={destination?.definition}
                model={model}
                slug={destination.type}
                sourceDefinition={sourceDefinition}
                onSubmit={(config) => {
                  setSyncConfig(config);
                  setStep((step) => step + 1);
                  return Promise.resolve();
                }}
              />
            </>
          );
        }
        return null;
      },
    },
    {
      isDisabled: !validateSchedule(schedule),
      label: "Schedule sync",
      description: "Set a recurring interval for your sync.",
      render: () => (
        <>
          <PartnerWizardStickyHeader>Schedule sync</PartnerWizardStickyHeader>
          <Column gap={6} {...WIZARD_CONTAINER_STYLES}>
            <ScheduleManager
              schedule={schedule}
              setSchedule={setSchedule}
              types={[
                ScheduleType.CRON,
                ScheduleType.CUSTOM,
                ScheduleType.INTERVAL,
                ScheduleType.MANUAL,
              ]}
            />
          </Column>
        </>
      ),
    },
  ].filter(Boolean);

  useEffect(() => {
    if (columns?.length && !queryError) {
      modelState.setIsPreviewFresh(true);
    }
  }, [rows, columns]);

  useEffect(() => {
    setSourceConfig(undefined);
    setTunnelId(undefined);
    setCredentialId("");
    resetRunState();
    modelState.reset({
      query_type: sourceDefinition?.supportedQueries.includes("custom")
        ? QueryType.Custom
        : null,
    });
  }, [sourceDefinition]);

  if (isWelcome) {
    return (
      <Welcome
        onContinue={() => {
          setIsWelcome(false);
        }}
      />
    );
  }

  return (
    <PartnerWizard
      step={step}
      steps={steps as any}
      onStepChange={setStep}
      onSubmit={submit}
    />
  );
};
