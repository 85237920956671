import { convertArrayToCSV } from "convert-array-to-csv";
import JSONBig from "json-bigint";

export const downloadJson = (data: any, filename: string) => {
  const blob = new Blob([JSONBig.stringify(data)], {
    type: "application/json",
  });
  const url = URL.createObjectURL(blob);

  const a = document.createElement("a");

  a.href = url;
  a.download = filename;

  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
    }, 150);
  };

  a.addEventListener("click", clickHandler, false);

  a.click();
};

export const downloadCsv = (data: any, filename: string) => {
  const csvString = convertArrayToCSV(data);
  const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);

  const a = document.createElement("a");

  a.href = url;
  a.download = filename;

  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
    }, 150);
  };

  a.addEventListener("click", clickHandler, false);

  a.click();
};

export const downloadText = (data: string, filename: string) => {
  const blob = new Blob([data], { type: "text/plain;charset=utf-8;" });
  const url = URL.createObjectURL(blob);

  const a = document.createElement("a");

  a.href = url;
  a.download = filename;

  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
    }, 150);
  };

  a.addEventListener("click", clickHandler, false);

  a.click();
};
