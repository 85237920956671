import {
  FC,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  useState,
  Ref,
} from "react";

import {
  Box,
  Column,
  Row as BoxRow,
  SearchInput,
  Skeleton,
  SkeletonBox,
  Text,
} from "@hightouchio/ui";
import { isEqual } from "lodash";
import { matchSorter } from "match-sorter";

import { useDestinationForm } from "src/contexts/destination-form-context";

import { ColumnOption, useFormkitContext } from "../formkit-context";
import { FormProps, JsonColumnProps } from "../types";
import {
  extractEligibleInlineMapperColumns,
  isValidJsonColumnMetadata,
} from "./utils";

interface CustomColumnOption {
  label: ColumnOption["label"];
  type?: ColumnOption["type"];
  value: string;
  options?: ColumnOption["options"];
}

const rowSx = {
  alignItems: "center",
  borderBottom: "small",
  fontSize: 0,
  gap: 2,
  minHeight: "32px",
  paddingTop: 1,
  px: 2,
};

interface OnChangeValueProp {
  custom?: boolean;
  from: string | Record<string, unknown>;
  type: "standard";
}

type ArrayPropertiesInputProps = FormProps & {
  columnOptions: CustomColumnOption[];
  jsonColumnProperties: JsonColumnProps;
  onChange: (close: () => void, value: OnChangeValueProp) => void;
  onClose: () => void;
  ref: Ref<HTMLInputElement>;
};

const ArrayPropertiesInput: FC<Readonly<ArrayPropertiesInputProps>> =
  forwardRef(
    (
      {
        onClose,
        columnOptions,
        jsonColumnProperties,
        onChange,
        onReloadEligibleInlineMapperColumns,
        value,
      },
      ref,
    ) => {
      const [search, setSearch] = useState("");
      const selectedColumnRef = useRef<HTMLDivElement>(null);
      const { loadingRows, rows, reloadRows } = useDestinationForm();
      const { columns } = useFormkitContext();

      const options = useMemo(() => {
        if (search) {
          const filteredColumns = matchSorter(columnOptions ?? [], search, {
            keys: ["label"],
          });
          return filteredColumns;
        }

        return columnOptions;
      }, [columnOptions, search]);

      const searchInputExists = useMemo(() => {
        if (search === value.from) return true;

        for (const option of options ?? []) {
          if (option.label === search) return true;
        }
        return false;
      }, [search, value.from]);

      const handleReloadRows = async () => {
        await onReloadEligibleInlineMapperColumns(value.from);
      };

      /**
       * These two useEffects work together to handle two functionalities:
       * 1. On load, we want to check if the json column metadata is valid. We will reloadRows to validate the metadata if it is not.
       * 2. handleReloadRows, which calls onReloadEligibleInlineMapperColumns will load the metadata to state only if it is valid. Therefore, it needs to have 'rows' as a dependency
       *    because if rows is updated, it can mean that either 1. we loaded the new valid metadata after we found that the previous one is invalid. 2. The user clicked on the 'Reload button' to re-validate the metadata and we need to extract that new data and set it to state.
       * This approach is needed to avoid multiple re-renders. The ideal approach would've been to make `reloadRows` a promise that resolves with the row data and we preform the extraction afterwards. However, that was not how reloadRows worked at this time, so this approach was chosen for the sake of time.
       * If this should be revisited, reloadRows should be changed into a promise so it can handle this scenario without multiple useEffects.
       */

      useEffect(() => {
        const jsonColumns = extractEligibleInlineMapperColumns(columns);
        if (!isValidJsonColumnMetadata(jsonColumns)) {
          reloadRows();
        }
      }, []);

      useEffect(() => {
        handleReloadRows();
      }, [
        rows,
        jsonColumnProperties.selectedColumnProps,
        jsonColumnProperties.allColumnsProps,
      ]);

      useEffect(() => {
        selectedColumnRef.current?.scrollIntoView();
      }, []);

      const handleEnterPress = (event) => {
        if (
          !loadingRows &&
          event.key === "Enter" &&
          search &&
          !searchInputExists
        ) {
          onChange(onClose, {
            ...value,
            from: search,
            custom: true,
          });
        }
      };
      return (
        <Column flex={1} pt={1} px={3}>
          <SearchInput
            autoFocus
            placeholder="Search columns..."
            ref={ref}
            value={search}
            width="100%"
            onChange={(event) => setSearch(event.target.value)}
            onKeyDown={handleEnterPress}
          />
          {loadingRows ? (
            <ShimmerLoadingState />
          ) : (
            <>
              <Column height="227px" mt={2} overflow="auto">
                {(options || []).map((option) => {
                  const selected = isEqual(option.value, value.from);
                  return (
                    <Row
                      key={option.value}
                      selected={selected}
                      selectedColumnRef={selectedColumnRef}
                      value={option.value}
                      onClickHandler={() => {
                        onChange(onClose, {
                          ...value,
                          from: option.value,
                          custom: undefined,
                        });
                      }}
                    />
                  );
                })}
                {value.custom && (
                  <Row
                    selected
                    selectedColumnRef={selectedColumnRef}
                    value={`Select "${value.from}" as an expected column value...`}
                  />
                )}
                {search && !searchInputExists && (
                  <Row
                    selected={isEqual(search, value.from)}
                    selectedColumnRef={selectedColumnRef}
                    value={`Select "${search}" as an expected column value...`}
                    onClickHandler={() => {
                      onChange(onClose, {
                        ...value,
                        from: search,
                        custom: true,
                      });
                    }}
                  />
                )}
              </Column>
            </>
          )}
        </Column>
      );
    },
  );

ArrayPropertiesInput.displayName = "ArrayPropertiesInput";
export { ArrayPropertiesInput };

const Row: FC<{
  onClickHandler?: () => void;
  selected: boolean;
  selectedColumnRef: React.MutableRefObject<HTMLDivElement | null>;
  value: string;
}> = ({ selected, value, onClickHandler, selectedColumnRef }) => {
  return (
    <Box
      _hover={{
        bg: "gray.100",
      }}
      alignItems="center"
      bg={selected ? "forest.100" : undefined}
      borderBottom="1px"
      borderColor="gray.300"
      cursor="pointer"
      display="flex"
      flex="0"
      minHeight="32px"
      onClick={onClickHandler}
      pointerEvents={selected ? "none" : undefined}
      px={2}
      ref={selected ? selectedColumnRef : undefined}
    >
      <Box isTruncated maxWidth="fit-content">
        <Text
          color={selected ? "forest.600" : "gray.900"}
          fontWeight="medium"
          mr={2}
          size="sm"
        >
          {value}
        </Text>
      </Box>
    </Box>
  );
};

const containersWidth = ["25%", "20%", "35%", "35%", "25%", "20%", "15%"];

const ShimmerLoadingState = () => (
  <Skeleton isLoading={true}>
    {containersWidth.map((width, index) => (
      <BoxRow key={index} sx={rowSx}>
        <Box margin={0} width={width}>
          <SkeletonBox borderRadius="sm" height="18px" width="100%" />
        </Box>
      </BoxRow>
    ))}
  </Skeleton>
);
