import { FC, ReactNode } from "react";

import { Alert } from "@hightouchio/ui";

export interface PageAlertProps {
  title: string;
  message: string;
  button?: ReactNode;
  link?: ReactNode;
}

export const PageAlert: FC<Readonly<PageAlertProps>> = ({
  title,
  message,
  button,
  link,
}) => {
  return (
    <Alert
      type="info"
      variant="banner"
      justify="left"
      title={title}
      message={message}
      actions={
        <>
          {button}
          {link}
        </>
      }
    />
  );
};
