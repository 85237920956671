import { MappingType } from "src/formkit/components/types";
import { ColumnReference } from "@hightouch/lib/query/visual/types";

export interface Mapping {
  from?:
    | ColumnReference
    | string
    | { type: MappingType.BOOSTED; semanticType: string };
  to: string | undefined;
  type: "reference" | "standard";
  [x: string]: unknown;
}

interface Segment {
  matchboosting_enabled?: boolean;
  parent?: {
    matchboosting_enabled?: boolean;
  } | null;
}

const isMapping = (m: unknown) => {
  return (
    typeof m === "object" &&
    m !== null &&
    m !== undefined &&
    typeof m["to"] === "string" &&
    m["type"] !== "destinationOnlyMapping"
  );
};

export function isModelMatchboosted(segment: Segment) {
  return Boolean(
    segment?.matchboosting_enabled || segment?.parent?.matchboosting_enabled,
  );
}

export function isSyncMatchBoosted(
  config: Record<string, unknown> | undefined,
): boolean {
  if (!config) {
    return false;
  }

  const allMappings = getAllMappings(config);
  return allMappings.some(
    (m) => typeof m?.from === "object" && m.from?.type === MappingType.BOOSTED,
  );
}

function getAllMappings(configuration: Record<string, any>) {
  const mappings: Mapping[] = [];

  for (const field of Object.keys(configuration)) {
    const value = configuration[field];
    const arrayValue = Array.isArray(value) ? value : [value];

    for (const v of arrayValue) {
      if (isMapping(v)) {
        mappings.push(v);
      }
    }
  }

  return mappings;
}
