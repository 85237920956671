import { FC } from "react";
import {
  BidirectionalArrowIcon,
  SettingsIcon,
  MeetingIcon,
  PauseIcon,
  PlayIcon,
} from "@hightouchio/ui";
import { capitalize } from "lodash";
import { singular } from "pluralize";

import { commonActivityMappings } from "src/components/resource-activity/common-mappings";
import {
  normalizeName,
  ResourceActivityMapper,
  ResourceActivityTimeline,
} from "src/components/resource-activity/timeline";
import { scheduleTypeToLabel } from "src/components/schedule/types";
import { ObjectPropertyDiff } from "src/hooks/use-resource-activity";
import { useOutletContext } from "src/router";

import {
  formatConfigValue,
  isMapping,
  isMappingArray,
  Mapping,
} from "./components/sync-activity-utils";
import { Context } from ".";

export const Activity: FC = () => {
  const { sync } = useOutletContext<Context>();
  return (
    <ResourceActivityTimeline
      primaryResource={{
        mappers: syncActivityMappers,
        resource: "Sync",
        resourceId: String(sync.id),
      }}
    />
  );
};

function displayMappedValueChange(
  value: Mapping,
  operation: ObjectPropertyDiff["operation"],
  mappingName: string,
): string | null {
  // get the 'from' value from the mapping
  let fromValue: string | undefined;

  switch (value.type) {
    case "static":
      fromValue = String(value.value);
      break;
    case "reference":
      fromValue = value.lookup.from;
      break;
    case "template":
      fromValue = value.template;
      break;
    case "variable":
      fromValue = value.variable;
      break;
    default:
      if ("lookup" in value) {
        fromValue = value.lookup.from;
        break;
      }
      if ("from" in value) {
        if (typeof value.from === "string") {
          fromValue = value.from;
          break;
        }
        if ("type" in value.from && (value as any).from?.type === "related") {
          fromValue = `Audience related model column ${
            (value as any).from?.column?.name
          }`;
          break;
        }
      }
      fromValue = undefined;
  }

  return `${capitalize(operation)} ${value.type ?? ""} ${singular(
    normalizeName(mappingName),
  )} from \`${fromValue}\` to \`${String(value.to)}\``;
}

export const syncActivityMappers: ResourceActivityMapper[] = [
  ...commonActivityMappings,
  {
    accessor: "schedule_paused",
    parser: (_, { parsedDiff }) => {
      if (parsedDiff.type !== "value") {
        return null;
      }

      return {
        message: `${parsedDiff.value ? "paused" : "resumed"} sync scheduling`,
        icon: parsedDiff.value ? <PauseIcon /> : <PlayIcon />,
      };
    },
  },
  // Handles top-level config keys and config mappings
  {
    accessor: "config",
    parser: (_, { parsedDiff, newValue, oldValue }) => {
      if (parsedDiff.type !== "nested") {
        return null;
      }
      const nestedKeys = Object.keys(parsedDiff.nested);
      let mappingKeyCount = 0;

      const changes: (string | null)[] = [];
      for (const key of nestedKeys) {
        const parsed = parsedDiff.nested[key];
        const newKeyValue = newValue[key];
        const oldKeyValue = oldValue[key];
        if (!parsed) {
          continue;
        }

        // We need to check both new and old config key values to see if they are mappings
        // as mappings might have been removed and [] should return false for isMappingArray
        if (isMappingArray(oldKeyValue) || isMappingArray(newKeyValue)) {
          mappingKeyCount++;
          if (parsed.type !== "array") {
            continue;
          }
          changes.push(
            ...parsed.array
              .map((item) =>
                item.value
                  ? displayMappedValueChange(item.value, item.operation, key)
                  : null,
              )
              .filter(Boolean),
          );
          continue;
        }

        if (isMapping(oldKeyValue) || isMapping(newKeyValue)) {
          mappingKeyCount++;
          if (parsed.type === "value") {
            changes.push(
              displayMappedValueChange(
                parsed.operation === "added" ? newKeyValue : oldKeyValue,
                parsed.operation,
                key,
              ),
            );
            continue;
          }
          changes.push(displayMappedValueChange(newKeyValue, "updated", key));
          continue;
        }

        if (parsed.type === "value") {
          // capitalize operation name
          switch (parsed.operation) {
            case "added":
              changes.push(
                `Set \`${normalizeName(key)}\` to \`${formatConfigValue(
                  parsed.value,
                )}\``,
              );
              break;
            case "removed":
              changes.push(`Removed \`${normalizeName(key)}\``);
              break;
            case "updated":
              changes.push(
                `Updated \`${normalizeName(key)}\` from \`${formatConfigValue(
                  oldKeyValue,
                )}\` to \`${String(newKeyValue)}\``,
              );
              break;
          }
        }
      }

      const isMappingChange = mappingKeyCount === nestedKeys.length;
      return {
        message: `updated ${
          isMappingChange ? "the sync mappings" : "the configuration"
        }`,
        icon: isMappingChange ? <BidirectionalArrowIcon /> : <SettingsIcon />,
        changes: changes.filter(Boolean),
      };
    },
  },
  // Handle setting sync schedule to manual which makes the whole schedule object null
  {
    accessor: "schedule",
    parser: (_, { parsedDiff }) => {
      if (parsedDiff.type === "value" && parsedDiff.value === null) {
        return {
          message: `disabled automatic scheduling`,
          icon: <MeetingIcon />,
        };
      }
      return null;
    },
  },
  {
    accessor: "schedule.type",
    overrideDiffAccessor: {
      or: [{ var: "schedule.type" }, { var: "schedule.1.type" }],
    },
    parser: (_, { parsedDiff }) => {
      if (parsedDiff.type !== "value") {
        return null;
      }
      return {
        message: `updated the schedule type to \`${scheduleTypeToLabel(
          parsedDiff.value,
        )}\``,
        icon: <MeetingIcon />,
      };
    },
  },
  {
    accessor: "schedule.schedule.expression",
    overrideDiffAccessor: {
      or: [
        { var: "schedule.schedule.expression" },
        { var: "schedule.1.schedule.expression" },
      ],
    },
    parser: (_, { parsedDiff }) => {
      if (parsedDiff.type !== "value" || parsedDiff.operation === "removed") {
        return null;
      }
      return {
        message: `updated the cron expression`,
        icon: <MeetingIcon />,
        changes: [`Set cron expression to \`${parsedDiff.value}\``],
      };
    },
  },
  {
    accessor: "schedule.schedule.interval",
    overrideDiffAccessor: {
      or: [
        { var: "schedule.schedule.interval" },
        { var: "schedule.1.schedule.interval" },
      ],
    },
    parser: (_, { parsedDiff, newValue }) => {
      if (
        parsedDiff.type === "array" ||
        (parsedDiff.type == "value" && parsedDiff.operation === "removed")
      ) {
        return null;
      }
      let change;
      if (parsedDiff.type === "value") {
        change = `Set interval to every \`${parsedDiff.value?.quantity} ${parsedDiff.value?.unit}(s)\``;
      } else {
        change = `Updated interval to every \`${newValue.quantity} ${newValue.unit}(s)\``;
      }

      return {
        message: `updated the sync interval`,
        icon: <MeetingIcon />,
        changes: [change],
      };
    },
  },
  {
    accessor: "schedule.schedule.expressions",
    parser: (_, { parsedDiff }) => {
      if (parsedDiff.type !== "array") {
        return null;
      }
      return {
        message: "updated the custom recurrence",
        icon: <MeetingIcon />,
        // Changes are a bit harder to grok here, we can come back to this if it's really bad
        changes: [`Updated recurrence days and or times`],
      };
    },
  },
];
