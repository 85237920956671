import { useOutletContext } from "src/router";
import { AudiencePageOutletState } from "../types";
import { ResourceActivityTimeline } from "src/components/resource-activity/timeline";
import { audienceActivityMappers } from "../setup/audience-activity";

export const AudienceActivity = () => {
  const { id } = useOutletContext<AudiencePageOutletState>();

  return (
    <ResourceActivityTimeline
      primaryResource={{
        mappers: audienceActivityMappers,
        resource: "Audience",
        resourceId: String(id),
      }}
    />
  );
};
