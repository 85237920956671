import {
  Avatar,
  Button,
  ButtonGroup,
  CloseIcon,
  Column,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  EditableDescription,
  EditableHeading,
  FormField,
  IconButton,
  Menu,
  MenuActionsButton,
  Pill,
  Row,
  Select,
  Text,
} from "@hightouchio/ui";
import noop from "lodash/noop";
import { useOutletContext } from "src/router";

import { DiscardButton, SaveButton } from "src/components/form";
import { TextWithTooltip } from "src/components/text-with-tooltip";

import { GoldenRecordOutletContext } from "../types";

export const Identifier = () => {
  const { onClose } = useOutletContext<GoldenRecordOutletContext>();

  return (
    <>
      <DrawerHeader>
        <Column gap={4} flex={1} minWidth={0}>
          <Column>
            <Row justify="space-between">
              <EditableHeading value="email" onChange={noop} />
              <Menu>
                <MenuActionsButton />
              </Menu>
              <IconButton
                aria-label="Close drawer"
                icon={CloseIcon}
                onClick={onClose}
              />
            </Row>
            <EditableDescription value="" onChange={noop} />
          </Column>
          <Row
            gap={4}
            flexShrink={0}
            sx={{
              "& > div": {
                ":not(:last-of-type)": {
                  borderRight: "1px",
                  pr: 4,
                  borderColor: "base.border",
                },
                justifyContent: "space-between",
                whiteSpace: "nowrap",
              },
            }}
          >
            <Row gap={1} align="center">
              <Text color="text.secondary" fontWeight="normal">
                Identifier:
              </Text>
              <TextWithTooltip fontWeight="normal">Email</TextWithTooltip>
            </Row>
            <Row gap={1} align="center">
              <Text fontWeight="normal">Last updated: 07/19/21 by </Text>
              <Avatar name="Cheese Sampson" size="xs" />
            </Row>
            <Row align="center">
              <Pill>Primary label</Pill>
            </Row>
          </Row>
        </Column>
      </DrawerHeader>

      <DrawerBody>
        <Column gap={4}>
          <FormField
            label="Source priority"
            description="Choose the survivorship rule to determine which value will be stored in the golden record."
          >
            <Select
              placeholder="Select a rule..."
              options={[]}
              value={undefined}
              onChange={noop}
              width="100%"
            />
          </FormField>

          {/* TODO: drag and drop tables... */}
        </Column>
      </DrawerBody>

      <DrawerFooter>
        <ButtonGroup>
          <SaveButton onClick={noop}>Save changes</SaveButton>
          <DiscardButton />
        </ButtonGroup>

        <Button size="lg" variant="warning" onClick={noop}>
          Delete
        </Button>
      </DrawerFooter>
    </>
  );
};
