import { FC } from "react";

import {
  Box,
  Column,
  IconButton,
  Row,
  Spinner,
  Text,
  Avatar,
  CloseIcon,
} from "@hightouchio/ui";
import { Link } from "src/router";
import { Reorder } from "framer-motion";
import { noop } from "lodash";

import { SyncsCell } from "src/pages/syncs/sync/components/syncs-cell";
import { AudiencesForPriorityListsQuery } from "src/graphql";
import { SixDots } from "src/ui/icons";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { formatDate } from "src/utils/time";

type DragAndDropListProps = {
  data: AudiencesForPriorityListsQuery["segments"][0][]; // TODO: generalize this component to use anything in the future
  isLoading?: boolean;
  readOnly?: boolean;
  onUpdate(data: AudiencesForPriorityListsQuery["segments"][0][]): void;
  onRemove(index: number): void;
};

export const DragAndDropEditor: FC<Readonly<DragAndDropListProps>> = ({
  data,
  isLoading,
  readOnly,
  onUpdate,
  onRemove,
}) => {
  const gridTemplateColumns = `50px 3fr repeat(3, 1fr) 50px`;

  if (isLoading) {
    return (
      <Column
        alignItems="center"
        flex={1}
        justifyContent="center"
        minHeight={0}
      >
        <Spinner />
      </Column>
    );
  }

  return (
    <Column borderRadius={3} flex={1} mb={4} minWidth={0} py={3}>
      <Box
        display="grid"
        gap={1}
        gridTemplateColumns={gridTemplateColumns}
        p={3}
      >
        <Box />
        <Text
          fontWeight="medium"
          letterSpacing="wide"
          size="sm"
          textTransform="uppercase"
        >
          Audience
        </Text>
        <Text
          fontWeight="medium"
          letterSpacing="wide"
          size="sm"
          textTransform="uppercase"
        >
          Size
        </Text>
        <Text
          fontWeight="medium"
          letterSpacing="wide"
          size="sm"
          textTransform="uppercase"
        >
          Syncs
        </Text>
        <Text
          fontWeight="medium"
          letterSpacing="wide"
          size="sm"
          textTransform="uppercase"
        >
          Last Updated
        </Text>
      </Box>
      {/* 
      Note: animation looks weird when React.StrictMode is enabled. This will look strange in development, but production works fine.
      https://github.com/framer/motion/issues/1518#issuecomment-1141696276
      */}
      <Reorder.Group
        layoutScroll
        style={{
          listStyle: "none",
          paddingLeft: 0,
          position: "relative",
        }}
        values={data}
        onReorder={onUpdate}
      >
        {data.map((listItem, index) => (
          <Reorder.Item
            key={listItem.id}
            style={{
              borderRadius: "8px",
            }}
            value={listItem}
            whileDrag={{
              cursor: "grabbing",
              boxShadow:
                "0px 12px 16px rgba(16, 24, 40, 0.16), 0px 8px 16px rgba(16, 24, 40, 0.16), 0px 0px 12px rgba(16, 24, 40, 0.08)",
            }}
          >
            <Row
              sx={{
                alignItems: "center",
                bg: "white",
                border: "1px solid",
                borderColor: "base.border",
                borderRadius: "8px",
                display: "grid",
                gap: 1,
                gridTemplateColumns,
                mb: 3,
                p: 2,
                width: "100%",
                transition: "box-shadow ease-in 0.2s",
                _hover: {
                  cursor: "grab",
                },
              }}
            >
              <Row color="gray.400" role="handle">
                <IconButton
                  aria-label="Click and drag to sort"
                  icon={() => <SixDots size={16} />}
                  onClick={noop}
                />
              </Row>
              <Row maxWidth="100%" overflow="hidden">
                <TextWithTooltip message={listItem.name}>
                  <Link href={`/audiences/${listItem.id}`} target="_blank">
                    {listItem.name}
                  </Link>
                </TextWithTooltip>
              </Row>
              <Row>{listItem?.query_runs?.[0]?.size ?? "--"}</Row>
              <Row>
                <SyncsCell syncs={listItem.syncs} />
              </Row>
              <Row align="center">
                <Text fontWeight="medium">
                  {formatDate((listItem?.updated_at || listItem?.created_at)!)}
                </Text>
                <Box mr={1}>
                  <Text color="gray.600">&nbsp;by</Text>
                </Box>
                <Avatar
                  name={
                    listItem?.updated_by_user?.name ||
                    listItem?.created_by_user?.name
                  }
                />
              </Row>
              <Row>
                <IconButton
                  aria-label="Remove list option"
                  icon={CloseIcon}
                  isDisabled={readOnly}
                  onClick={() => onRemove(index)}
                />
              </Row>
            </Row>
          </Reorder.Item>
        ))}
      </Reorder.Group>
    </Column>
  );
};
