import { useState, FC } from "react";

import { Box, Button, Dialog } from "@hightouchio/ui";
import { capitalize } from "lodash";

type Props = {
  variant?: "error" | "warning";
  error: string;
};

export const ErrorModal: FC<Readonly<Props>> = ({
  error,
  variant = "error",
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <Button size="sm" variant="warning" onClick={() => setIsOpen(true)}>
        View {variant}
      </Button>
      <Dialog
        isOpen={isOpen}
        variant="info"
        width="lg"
        title={capitalize(variant)}
        actions={<Button onClick={() => setIsOpen(false)}>Close</Button>}
        onClose={() => setIsOpen(false)}
      >
        <Box whiteSpace="pre-line" minWidth="300px">
          {error}
        </Box>
      </Dialog>
    </>
  );
};
