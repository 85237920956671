import { FC } from "react";

import {
  Column,
  PlusIcon,
  RefreshIcon,
  Row,
  SubtractIcon,
  Text,
} from "@hightouchio/ui";
import { DiffPatcher } from "jsondiffpatch-rc";
import { upperFirst } from "lodash";

import {
  ObjectDiffOperation,
  ParsedDiff,
  parseDiff,
} from "src/hooks/use-resource-activity";
import { Markdown } from "src/ui/markdown";
import { ActivityChanges } from "src/components/resource-activity/item";
import {
  defaultResourceMapper,
  DisplayedResourceActivity,
  ResourceActivityMapper,
} from "src/components/resource-activity/timeline";
import { Deployment } from "./common";

export interface DeploymentDiff {
  diffObject: ParsedDiff | null;
  key: string;
  displayedDiff: DisplayedResourceActivity | null;
}

const diffOperationIcon = (operation: ObjectDiffOperation) => {
  switch (operation) {
    case "added":
      return <PlusIcon color="greens.1" />;
    case "updated":
      return <RefreshIcon color="greys.1" />;
    case "removed":
      return <SubtractIcon color="reds.1" />;
  }
};

export const DeploymentComparison: FC<{ diffs?: DeploymentDiff[] }> = ({
  diffs,
}) => {
  if (!diffs) {
    return <Text>No changes to deploy</Text>;
  }
  return (
    <>
      {diffs.map((diff, idx) => {
        const { diffObject, displayedDiff } = diff;
        if (!diffObject || !displayedDiff) {
          return null;
        }

        const { parsedDiff } = diffObject;
        return (
          <Column gap={2} key={idx}>
            <Row gap={2}>
              {parsedDiff["operation"] ? (
                diffOperationIcon(parsedDiff["operation"])
              ) : (
                <RefreshIcon color="greys.1" />
              )}
              <Markdown>{upperFirst(displayedDiff.message)}</Markdown>
            </Row>
            <ActivityChanges changes={displayedDiff.changes} />
          </Column>
        );
      })}
    </>
  );
};

export const getDeploymentDiff =
  (differ: DiffPatcher, mappers: ResourceActivityMapper[]) =>
  (
    deployment: Deployment,
    sourceObj: any,
    targetObj: any,
  ): {
    diffObject: ParsedDiff | null;
    key: string;
    displayedDiff: DisplayedResourceActivity | null;
  }[] => {
    const diff = differ.diff(targetObj, sourceObj);
    const diffObjects = mappers
      .map((mapper) => {
        const metadata = {
          old: targetObj,
          new: sourceObj,
          resource_name: deployment.resourceName,
        };
        let displayedDiff: DisplayedResourceActivity | null = null;
        const diffObject = parseDiff(
          { metadata, diff },
          mapper.accessor,
          mapper.overrideDiffAccessor,
        );
        if (diffObject) {
          displayedDiff = mapper.parser(metadata, diffObject);
          // We fall back to the default parser
          if (!displayedDiff) {
            displayedDiff = defaultResourceMapper({
              resource: deployment.resourceName,
              metadata,
            });
          }
        }

        return { diffObject, key: mapper.accessor, displayedDiff };
      })
      .filter((o) => Boolean(o.diffObject));

    return diffObjects;
  };
