import { MatchBoostingSettingsWrapper } from "src/pages/schema/match-booster";
import { OutletContext } from ".";

export const ModelMatchbooster = ({
  context: { model, columns },
}: {
  context: OutletContext;
}) => {
  return (
    <MatchBoostingSettingsWrapper
      sidebar={false}
      columns={columns}
      model={model}
      segmentType="model"
    />
  );
};
