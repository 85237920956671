import type { EventSourceDefinition } from "src/events/types";
import type { EventSourceFormProps } from "./types";
import WebhookForm from "./webhook";
import EventSourceFormkitForm from "./formkit";

export type { EventSourceFormProps };

export function getEventSourceForm(
  definition: EventSourceDefinition,
): React.FC<EventSourceFormProps> | undefined {
  if (definition.form) {
    return EventSourceFormkitForm;
  }

  // TODO: convert webhook to formkit and eliminate this check
  if (definition.type === "webhook") {
    return WebhookForm;
  }

  return;
}
