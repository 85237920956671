import { useState, useEffect, useCallback } from "react";

import { useLocation } from "src/router";
import store from "store2";

export interface UnsavedValue<TValue> {
  value: TValue | undefined;
  set: (value: TValue) => void;
  restore: () => void;
  clear: () => void;
}

export const useUnsavedValue = <TValue>(key: string): UnsavedValue<TValue> => {
  const location = useLocation();
  const storageKey = `unsaved-${key}-${location.pathname}`;
  const [value, setValue] = useState<TValue | undefined>(() => {
    return store.get(storageKey);
  });

  useEffect(() => {
    setValue(store.get(storageKey));
  }, [storageKey]);

  const set = useCallback(
    (value: TValue) => {
      store.set(storageKey, value);
    },
    [storageKey],
  );

  const restore = useCallback(() => {
    setValue(undefined);
  }, []);

  const clear = useCallback(() => {
    setValue(undefined);
    store.remove(storageKey);
  }, [storageKey]);

  return { value, set, restore, clear };
};
