import { Column, Heading } from "@hightouchio/ui";
import { motion } from "framer-motion";

import { Logo } from "src/ui/brand";

export const CopilotPlaceholder = () => {
  return (
    <motion.div
      initial={{
        opacity: 0.2,
        y: -48,
      }}
      animate={{
        opacity: 1,
        y: 0,
      }}
      transition={{
        type: "spring",
        stiffness: 32,
        damping: 7,
        mass: 1,
      }}
    >
      <Column align="center" justify="center" gap={6}>
        <Column
          align="center"
          justify="center"
          borderRadius="50%"
          background="white"
          width="146px"
          height="146px"
          border="1px solid"
          borderColor="base.divider"
          filter="drop-shadow(0px 2px 4px rgba(16, 24, 40, 0.06)) drop-shadow(0px 4px 8px rgba(16, 24, 40, 0.10))"
        >
          <Logo size="64px" theme="dark" />
        </Column>
        <Heading>How can I help you?</Heading>
      </Column>
    </motion.div>
  );
};
