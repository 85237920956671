import { FormField, TextInput } from "@hightouchio/ui";
import { FC } from "react";
import { Controller } from "react-hook-form";
import validator from "validator";

export const WebhookRecipientForm: FC = () => {
  return (
    <>
      <Controller
        name="config.name"
        rules={{
          required: "Webhook notifications should be named",
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormField label="Webhook name" error={error?.message}>
            <TextInput
              width="100%"
              value={value}
              onChange={(e) => onChange(e.target.value)}
              autoComplete="false"
              type="text"
            />
          </FormField>
        )}
      />
      <Controller
        name="config.url"
        rules={{
          required: "A valid webhook url is required",
          validate: (value) =>
            validator.isURL(value, { require_valid_protocol: true })
              ? true
              : "Must be a valid URL that is not used for another webhook alert",
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormField
            description="POST requests will be made to this URL."
            label="Webhook URL"
            error={error?.message}
          >
            <TextInput
              width="100%"
              value={value}
              onChange={(e) => onChange(e.target.value)}
              autoComplete="false"
              type="url"
            />
          </FormField>
        )}
      />
    </>
  );
};
