import {
  SectionHeading,
  Row,
  Column,
  DrawerBody,
  DrawerFooter,
  Text,
} from "@hightouchio/ui";

import { Form, FormActions, useHightouchForm } from "src/components/form";
import { useUpdateDecisionEngineFlowMessageMutation } from "src/graphql";
import { ConditionGroup } from "src/pages/decision-engines/collections/collection/components/filter";
import { useOutletContext } from "src/router";
import { FlowMessageContext } from ".";
import { ConditionType } from "src/types/visual";
import {
  Condition,
  DecisionEngineFlowMessageConfig,
} from "@hightouch/lib/customer-data/decision-engine/types";

export const Eligibility = () => {
  const { flowMessage, engine, flow } = useOutletContext<FlowMessageContext>();

  const updateMutation = useUpdateDecisionEngineFlowMessageMutation();

  const form = useHightouchForm({
    onSubmit: async (data) => {
      const config: DecisionEngineFlowMessageConfig = {
        ...flowMessage.config,
        userFilter: data.filter,
      };
      await updateMutation.mutateAsync({
        flowId: flow.id,
        messageId: flowMessage.message.id,
        input: {
          config,
        },
      });
    },
    defaultValues: {
      filter:
        flowMessage.config?.userFilter ??
        ({
          type: ConditionType.And,
          conditions: [],
        } as Condition),
    },
  });

  return (
    <Form form={form}>
      <DrawerBody bg="base.lightBackground">
        <Column gap={6}>
          <Row>
            <Column>
              <SectionHeading>Eligibility</SectionHeading>
              <Text>
                This message will only be sent to users who meet the following
                criteria
              </Text>
            </Column>
          </Row>
          <ConditionGroup
            leftModelId={engine.config.feature_model_id}
            leftTable="users"
          />
        </Column>
      </DrawerBody>
      <DrawerFooter>
        <FormActions />
      </DrawerFooter>
    </Form>
  );
};
