export const ConnectionLine = ({
  fromX,
  fromY,
  toX,
  toY,
}: {
  fromX: string | number;
  fromY: string | number;
  toX: string | number;
  toY: string | number;
}) => {
  return (
    <g>
      <path
        fill="none"
        stroke="var(--chakra-colors-text-tertiary)"
        strokeWidth={2}
        className="animated"
        d={`M${fromX},${fromY} C ${fromX} ${toY} ${fromX} ${toY} ${toX},${toY}`}
      />
    </g>
  );
};
