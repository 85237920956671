import { FC, useState } from "react";

import {
  Box,
  BoxProps,
  ChevronDownIcon,
  ConfirmationDialog,
  FolderIcon,
  Menu,
  MenuActionsButton,
  MenuItem,
  MenuList,
  Paragraph,
  Portal,
  Row,
  Text,
  useToast,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import pluralize from "pluralize";

import {
  FolderType,
  useDeleteFolderMutation,
  useDeleteModelsMutation,
  useUpdateFoldersMutation,
  useUpdateModelsWhereMutation,
} from "src/graphql";

import { TextWithTooltip } from "src/components/text-with-tooltip";

import { useResourcePermission } from "../permission/use-resource-permission";
import { AddFolder } from "./add-folder";
import { EditFolder } from "./edit-folder";
import { Folder } from "./types";

interface FolderProps {
  isSelected: boolean;
  isOpen?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
  count: number | undefined;
  name: string;
  depth: number;
  onClick: () => void;
  icon?: JSX.Element;
  bg?: BoxProps["bg"];
  folder?: Folder;
  parentFolder?: Folder;
  setSelectedFolder: (folder: string | null) => void;
}

export const IndividualFolder: FC<FolderProps> = ({
  count,
  name,
  depth,
  icon,
  onClick,
  isSelected,
  bg,
  folder,
  parentFolder,
  setSelectedFolder,
  isOpen,
  setIsOpen,
}) => {
  const [deleteFolderConfirmOpen, setDeleteFolderConfirmOpen] = useState(false);
  const [
    deleteFolderAndResourcesConfirmOpen,
    setDeleteFolderAndResourcesConfirmOpen,
  ] = useState(false);
  const [updatingFolderOpen, setUpdatingFolderOpen] = useState(false);
  const [addingChild, setAddingChild] = useState(false);

  const { mutateAsync: updateSegment } = useUpdateModelsWhereMutation();
  const { mutateAsync: deleteSegments } = useDeleteModelsMutation();
  const { mutateAsync: updateFolders } = useUpdateFoldersMutation();
  const { mutateAsync: deleteFolder } = useDeleteFolderMutation();
  const { toast } = useToast();

  const { isPermitted: hasUpdatePermission } = useResourcePermission({
    v2: { resource: "workspace", grant: "can_update" },
  });

  const hasMenu =
    folder?.parentId !== undefined && !!folder && hasUpdatePermission;

  const parentFolderName = parentFolder?.name || `All ${folder?.type}`;

  const onConfirmDelete = async (deleteResources: boolean) => {
    // typeguard.
    if (!folder?.id) {
      return;
    }

    try {
      if (deleteResources) {
        await deleteSegments({
          where: {
            folder_id: { _eq: folder.id },
          },
        });
      } else {
        // move all the segments to the parent folder.
        await updateSegment({
          where: {
            folder_id: { _eq: folder.id },
          },
          input: {
            folder_id: folder?.parentId,
          },
        });
      }

      // move the child folders to the parent folder.
      await updateFolders({
        ids: folder?.children.map((child) => child.id) || [],
        object: {
          parent_id: folder?.parentId,
        },
      });

      // delete the folder.
      await deleteFolder({
        id: folder?.id,
      });

      toast({
        id: "delete-folder",
        title: "Folder was deleted",
        variant: "success",
      });
    } catch (err) {
      toast({
        id: "delete-folder",
        title: "There was an error deleting the folder",
        variant: "error",
      });

      Sentry.captureException(err);
    }

    setSelectedFolder(parentFolder?.id || null);
  };

  const iconNode = icon || <FolderIcon />;

  return (
    <>
      <Row
        align="center"
        borderRadius="md"
        border="1px"
        borderColor={isSelected ? "primary.border" : "transparent"}
        bg={bg || isSelected ? "forest.background" : undefined}
        cursor="pointer"
        gap={1.5}
        pl={`${8 + (depth + 1) * 22}px`}
        pr={3}
        height="30px"
        transition="border-color 0.1s, background-color 0.1s, box-shadow 0.1s"
        width="100%"
        onClick={onClick}
      >
        {setIsOpen ? (
          !folder || folder.children.length ? (
            <Box
              _hover={{ bg: "gray.300" }}
              borderRadius="sm"
              color={isSelected ? "primary.pressed" : "gray.500"}
              transform={isOpen ? undefined : "rotate(-90deg)"}
              transition="transform 0.1s"
              onClick={(e) => {
                e.stopPropagation();
                setIsOpen(!isOpen);
              }}
            >
              <Box as={ChevronDownIcon} height="20px" width="20px" />
            </Box>
          ) : (
            <Box flexShrink={0} width={5} />
          )
        ) : null}
        <Row
          color={isSelected ? "primary.pressed" : "gray.500"}
          fontSize="16px"
        >
          {iconNode}
        </Row>
        <TextWithTooltip
          message={name}
          placement="top"
          fontWeight={folder ? "normal" : "medium"}
          color={isSelected ? "primary.pressed" : "text.primary"}
          size="sm"
        >
          {name}
        </TextWithTooltip>
        <Row
          minW={5}
          justify="flex-end"
          ml="auto"
          flexShrink={0}
          align="center"
          gap={1}
        >
          <Box
            as={Text}
            color={isSelected ? "primary.pressed" : "text.secondary"}
            className="count"
            flexShrink={0}
            size="sm"
          >
            {count}
          </Box>
          {hasMenu && (
            <Box display="contents" onClick={(e) => e.stopPropagation()}>
              <Menu>
                <MenuActionsButton size="sm" />
                <Portal>
                  <MenuList>
                    <MenuItem onClick={() => setUpdatingFolderOpen(true)}>
                      Edit folder
                    </MenuItem>
                    <MenuItem onClick={() => setAddingChild(true)}>
                      Add subfolder
                    </MenuItem>
                    <MenuItem
                      variant="danger"
                      onClick={() => setDeleteFolderConfirmOpen(true)}
                    >
                      Remove folder
                    </MenuItem>
                    <MenuItem
                      variant="danger"
                      onClick={() =>
                        setDeleteFolderAndResourcesConfirmOpen(true)
                      }
                    >
                      Remove folder and {folder.count}{" "}
                      {pluralize(folder.type, folder.count)}
                    </MenuItem>
                  </MenuList>
                </Portal>
              </Menu>
            </Box>
          )}
        </Row>

        {folder?.type && updatingFolderOpen && (
          <EditFolder
            folder={folder}
            onClose={() => setUpdatingFolderOpen(false)}
          />
        )}

        {folder && addingChild && (
          <AddFolder
            toggleDisabled
            defaultParentFolder={folder.id}
            folderType={folder.type as FolderType}
            viewType="models"
            onSave={(folder) => {
              setSelectedFolder(folder);
            }}
            onClose={() => {
              setAddingChild(false);
            }}
          />
        )}
      </Row>
      <ConfirmationDialog
        confirmButtonText="Remove"
        isOpen={deleteFolderConfirmOpen}
        title="Remove folder"
        variant="danger"
        onClose={() => setDeleteFolderConfirmOpen(false)}
        onConfirm={async () => await onConfirmDelete(false)}
      >
        <Paragraph>
          {folder && (
            <DeleteFolder folder={folder} parentFolderName={parentFolderName} />
          )}
        </Paragraph>
      </ConfirmationDialog>
      <ConfirmationDialog
        confirmButtonText="Remove"
        isOpen={deleteFolderAndResourcesConfirmOpen}
        title={`Remove folder and ${folder?.count} ${pluralize(
          folder?.type || "",
          folder?.count || 0,
        )}`}
        variant="danger"
        onClose={() => setDeleteFolderAndResourcesConfirmOpen(false)}
        onConfirm={async () => await onConfirmDelete(true)}
      >
        <Paragraph>
          {folder && <DeleteFolderAndResources folder={folder} />}
        </Paragraph>
      </ConfirmationDialog>
    </>
  );
};

const DeleteFolderAndResources = ({ folder }: { folder: Folder }) => {
  if (folder.count) {
    return (
      <Text>
        Removing the folder {folder.name} will cause{" "}
        <Text fontWeight="semibold">
          {folder.count} {pluralize(folder.type, folder.count)}
        </Text>
        {"  "}
        to be deleted
      </Text>
    );
  }

  return <Text>There are no resource or child folders in this folder.</Text>;
};

const DeleteFolder = ({
  folder,
  parentFolderName,
}: {
  folder: Folder;
  parentFolderName: string;
}) => {
  if (folder.count) {
    return (
      <Text>
        Removing the folder {folder.name} will cause{" "}
        <Text fontWeight="semibold">
          {folder.count} {pluralize(folder.type, folder.count)}
        </Text>{" "}
        to be moved to the parent folder{" "}
        <Text fontWeight="semibold">{parentFolderName}</Text>
      </Text>
    );
  }

  if (folder.children.length) {
    return (
      <Row>
        Removing the folder {folder.name} will cause{" "}
        <Text fontWeight="semibold">
          {folder.children.length} {pluralize("folder", folder.children.length)}
        </Text>{" "}
        to be moved to the parent folder{" "}
        <Text fontWeight="semibold">{parentFolderName}</Text>
      </Row>
    );
  }

  return <Text>There are no resource or child folders in this folder</Text>;
};
