import { NavSection } from "src/components/layout/nav/nav-section";

import { Icon } from "src/components/layout/nav/icons/integrations";
import { ActiveSectionProps } from "src/components/layout/nav/utils";
import { UseSectionHook } from "./types";
import { isPresent } from "ts-extras";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useUser } from "src/contexts/user-context";

export const useIntegrationsSection: UseSectionHook = () => {
  const { appAlertingV2Eligible } = useFlags();
  const { workspace } = useUser();

  return {
    title: "Integrations",
    isEnabled: true,
    links: [
      {
        href: "/destinations",
        label: "Destinations",
        permission: { v1: { resource: "destination", grant: "read" } },
      },
      {
        href: "/sources",
        label: "Sources",
        permission: { v1: { resource: "source", grant: "read" } },
      },
      {
        href: "/extensions",
        label: "Extensions",
      },
      appAlertingV2Eligible || workspace?.alerting_v2_enabled
        ? {
            href: "/alerting",
            label: "Alerting",
          }
        : null,
    ].filter(isPresent),
  };
};

export const Integrations = (props: ActiveSectionProps) => {
  const section = useIntegrationsSection();
  return <NavSection icon={Icon} {...section} {...props} />;
};
