import { FC } from "react";

import { Box, Column, Row, SkeletonBox, Text } from "@hightouchio/ui";
import { formatDistance, parseISO, startOfHour } from "date-fns";
import { groupBy, times } from "lodash";
import { RouterLink } from "src/router";

import { HomeActivityFeedQuery, useHomeActivityFeedQuery } from "src/graphql";
import * as analytics from "src/lib/analytics";
import { DashboardSidebarItem } from "./dashboard-sidebar-item";
import { Card } from "src/components/card";

type EnrichedActivityItem = HomeActivityFeedQuery["auditLog"]["items"][0] & {
  source?: {
    icon: string;
    name: string;
  };
  destination?: {
    icon: string;
    name: string;
  };
};

const ACTIVITY_DAYS = 7;

const ACTIVITY_ITEMS = 10;

function activityOperation(
  n: Record<string, unknown> | null,
  o: Record<string, unknown> | null,
): "updated" | "created" | "deleted" | null {
  if (n && !o) return "created";
  if (n && o) return "updated";
  if (!n && o) return "deleted";
  return null;
}

function activityItemLink(
  resource: string,
  resourceId: string,
  meta?: any,
): string {
  switch (resource) {
    case "Sync":
      return `/syncs/${resourceId}`;
    case "Model":
      return `/models/${resourceId}`;
    case "Audience":
      return `/audiences/${resourceId}`;
    case "Source":
      return `/sources/${resourceId}`;
    case "Destination":
      return `/destinations/${resourceId}`;
    case "Audience Schema":
      return `/schema-v2/view/query?source=${meta?.new?.connection_id}&id=${resourceId}`;
    default:
      return "";
  }
}

const ActivityItem: FC<{ item: EnrichedActivityItem }> = ({ item }) => {
  if (!item || !item.resource || !item.id || !item.created_at) return null;
  return (
    <Column
      _notLast={{ borderBottom: "1px", borderColor: "base.border" }}
      _hover={{ bg: "base.lightBackground" }}
    >
      <RouterLink
        onClick={() =>
          analytics.track("[App Dashboard] Sidebar Activity Item Clicked", {
            resourceId: item.resource_id,
            resourceType: item.resource,
          })
        }
        to={activityItemLink(item.resource, item.resource_id, item)}
      >
        <Column gap={2} py={3} px={4}>
          <Column gap={1}>
            <Text fontWeight="medium">
              {item.resource} {activityOperation(item.new, item.old)} by{" "}
              {item.user_name}
            </Text>
            <Text size="sm" color="text.secondary">
              {formatDistance(parseISO(item.created_at), new Date(), {
                addSuffix: true,
              })}
            </Text>
          </Column>
          <Column gap={1}>
            {item.source && (
              <Row alignItems="center">
                {item.source.icon && (
                  <Column pr={2} flexShrink={0}>
                    <Box
                      as="img"
                      width="16px"
                      height="16px"
                      src={item.source.icon}
                    />
                  </Column>
                )}
                <Text isTruncated>{item.source.name}</Text>
              </Row>
            )}
            {item.destination && (
              <Row alignItems="center">
                {item.destination.icon && (
                  <Column pr={2} flexShrink={0}>
                    <Box
                      as="img"
                      width="16px"
                      height="16px"
                      src={item.destination.icon}
                    />
                  </Column>
                )}
                <Text isTruncated>{item.destination.name}</Text>
              </Row>
            )}
          </Column>
        </Column>
      </RouterLink>
    </Column>
  );
};

const ActivitySkeleton: FC = () => {
  return (
    <Column gap={1} p={2} alignItems="flex-start">
      <SkeletonBox height="20px" width="200px" />
      <SkeletonBox height="8px" width="100px" />
      <SkeletonBox height="20px" width="200px" />
    </Column>
  );
};

const since = new Date(
  new Date().getTime() - 1000 * 60 * 60 * 24 * ACTIVITY_DAYS,
).toISOString();

export const ActivityFeedItem = () => {
  const { data, isLoading } = useHomeActivityFeedQuery(
    {
      since,
    },
    {
      refetchOnMount: "always",
      select(data): EnrichedActivityItem[] {
        if (!data) {
          return [];
        }

        // Group items by resource and time window
        const groupedItems = groupBy(data.auditLog.items, (item) => {
          // Bucket to the hour
          const timeWindowStart = startOfHour(parseISO(item.created_at));
          return `${item.resource_id}-${
            item.resource
          }-${timeWindowStart.toISOString()}`;
        });

        const syncLookup = new Set(
          data.destination_instances.map((item) => item.id),
        );

        const segmentLookup: Record<string, { name: string; icon: string }> =
          data.segments.reduce(
            (acc, item) => {
              if (!item.connection) return acc;
              const sourceDefinition = data.getSourceDefinitions.find(
                (def) => def.type === item.connection?.type,
              );
              acc[item.id] = {
                name: item.name || sourceDefinition?.name || "Unknown",
                icon: sourceDefinition?.icon || "",
              };

              return acc;
            },
            {} as Record<string, { name: string; icon: string }>,
          );

        const sourceLookup: Record<string, { name: string; icon: string }> =
          data.connections.reduce(
            (acc, item) => {
              const sourceDefinition = data.getSourceDefinitions.find(
                (def) => def.type === item.type,
              );
              acc[item.id] = {
                name: item.name || sourceDefinition?.name || "Unknown",
                icon: sourceDefinition?.icon || "",
              };
              return acc;
            },
            {} as Record<string, { name: string; icon: string }>,
          );

        const destinationLookup: Record<
          string,
          { name: string; icon: string }
        > = data.destinations.reduce(
          (acc, item) => {
            const destinationDefinition = data.getDestinationDefinitions.find(
              (def) => def.type === item.type,
            );
            acc[item.id] = {
              name: item.name || destinationDefinition?.name || "Unknown",
              icon: destinationDefinition?.icon || "",
            };
            return acc;
          },
          {} as Record<string, { name: string; icon: string }>,
        );

        // Now return a list of the last item in each group
        const filteredItems = Object.values(groupedItems).map(
          (items) => items[items.length - 1],
        );

        // Now we enrich the filtered items
        const enrichedItems: EnrichedActivityItem[] = [];

        for (const item of filteredItems) {
          // We skip showing deleted items
          if (!item || !item.new) {
            continue;
          }
          let destination: undefined | { name: string; icon: string };
          let source: undefined | { name: string; icon: string };

          if (item.resource === "Sync") {
            // Permissions check
            if (!syncLookup.has(item.resource_id)) {
              continue;
            }
            destination = destinationLookup[item.new?.destination_id];
            source = segmentLookup[item.new?.segment_id];
          }

          if (
            item.resource === "Model" ||
            item.resource === "Audience" ||
            item.resource === "Audience Schema"
          ) {
            if (!segmentLookup[item.resource_id]) continue;
            source =
              segmentLookup[item.resource_id] ||
              sourceLookup[item.new?.connection_id];
          }

          if (item.resource === "Source") {
            if (!sourceLookup[item.resource_id]) continue;
            source = sourceLookup[item.resource_id];
          }
          if (item.resource === "Destination") {
            if (!destinationLookup[item.resource_id]) continue;
            destination = destinationLookup[item.resource_id];
          }

          enrichedItems.push({
            ...item,
            source,
            destination,
          });
        }

        return enrichedItems.slice(0, ACTIVITY_ITEMS);
      },
    },
  );

  return (
    <DashboardSidebarItem title="Activity feed" isLoading={isLoading}>
      {isLoading ? (
        times(4, (idx) => <ActivitySkeleton key={idx} />)
      ) : data?.length ? (
        <Card p={0}>
          {/* Filter out deletions here, it isn't useful for users to see deleted resources on the activity feed */}
          {data.map((activityItem, idx) => (
            <ActivityItem key={idx} item={activityItem} />
          ))}
        </Card>
      ) : (
        <Text color="text.secondary">No recent activity</Text>
      )}
    </DashboardSidebarItem>
  );
};
