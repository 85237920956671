import { FC } from "react";

import {
  Alert,
  Button,
  Dialog,
  FormField,
  Tooltip,
  useDisclosure,
  Column,
  Row,
  Text,
  CheckIcon,
  CloseIcon,
  Badge,
} from "@hightouchio/ui";
import { useQueryClient } from "react-query";

import { PermissionedButton } from "src/components/permission";
import { useUser } from "src/contexts/user-context";
import { ImpersonationPermissionsModal } from "./impersonation-permissions-modal";
import { useResourcePermission } from "src/components/permission/use-resource-permission";
import { useUpdateOrganizationMutation } from "src/graphql";

export const WorkspaceWarning: FC = () => {
  const { user, workspace } = useUser();
  const {
    isOpen: permissionModalOpen,
    onOpen: openPermissionModal,
    onClose: closePermissionModal,
  } = useDisclosure();
  const {
    isOpen: workspaceInfoOpen,
    onOpen: openWorkspaceInfo,
    onClose: closeWorkspaceInfo,
  } = useDisclosure();

  const queryClient = useQueryClient();

  const canUpgrade = user?.is_admin && !user?.is_admin_readonly;

  const { isPermitted: hasUpdatePermission } = useResourcePermission({
    v2: { resource: "workspace", grant: "can_update" },
  });

  const updateOrganization = useUpdateOrganizationMutation({
    // Note: must invalidate all queries because org permission v2 status is pulled from the "Initial" query
    onSuccess: () => queryClient.invalidateQueries(),
  });

  const setPermissionsV2Enabled = async (enabled: boolean) => {
    if (!user?.organization) return;

    await updateOrganization.mutate({
      id: String(user.organization.id),
      input: {
        user_group_permissions: enabled,
      },
    });
  };

  if (!user) {
    return null;
  }

  if (!user.not_member_of_current_workspace) {
    return null;
  }

  return (
    <>
      <Alert
        variant="banner"
        type="error"
        justify="center"
        title={`You are not a member of this workspace (${user.current_workspace_id}).`}
        message="Be careful when assisting customers."
        actions={
          <>
            {hasUpdatePermission && (
              <Row alignItems="center" gap={1}>
                <Badge variant="warning">Workspace admin</Badge>
              </Row>
            )}
            <Tooltip
              isDisabled={canUpgrade}
              message="You have limited permissions in this workspace."
            >
              <Button
                variant="secondary"
                isDisabled={!canUpgrade}
                onClick={openPermissionModal}
              >
                Change permissions
              </Button>
            </Tooltip>
            <Button onClick={openWorkspaceInfo}>More info</Button>
          </>
        }
      />
      <ImpersonationPermissionsModal
        isOpen={permissionModalOpen}
        onClose={closePermissionModal}
      />
      <Dialog
        variant="info"
        isOpen={workspaceInfoOpen}
        title="Workspace information"
        actions={
          <Button variant="primary" onClick={closeWorkspaceInfo}>
            Close
          </Button>
        }
        onClose={closeWorkspaceInfo}
      >
        <Column gap={4}>
          <FormField label="Organization">
            {user?.organization?.name} ({user?.organization?.id})
          </FormField>
          <FormField label="Migrations">
            <Column gap={2}>
              <MigrationInfo
                label="Permissions V2"
                enabled={user?.permissions_v2_enabled}
                action={
                  <PermissionedButton
                    permission={{
                      v2: { resource: "workspace", grant: "can_update" },
                    }}
                    tooltip={`${
                      user?.permissions_v2_enabled ? "Disable" : "Enable"
                    } permissions V2 for this organization. Only toggles the V2 flag, user groups and other permission entities will not be modified.`}
                    isLoading={updateOrganization.isLoading}
                    placement="top"
                    size="sm"
                    variant="danger"
                    onClick={() =>
                      setPermissionsV2Enabled(!user?.permissions_v2_enabled)
                    }
                  >
                    {user?.permissions_v2_enabled ? "Disable" : "Enable"} V2
                  </PermissionedButton>
                }
              />
              <MigrationInfo
                label="Alerting V2"
                enabled={Boolean(workspace?.alerting_v2_enabled)}
              />
            </Column>
          </FormField>
        </Column>
      </Dialog>
    </>
  );
};

type MigrationInfoProps = {
  label: string;
  enabled?: boolean;
  action?: React.ReactNode;
};

const MigrationInfo = ({ label, enabled, action }: MigrationInfoProps) => (
  <Row gap={2} alignItems="center">
    {enabled ? (
      <CheckIcon color="success.base" />
    ) : (
      <CloseIcon color="danger.base" />
    )}
    <Text>{label}</Text>
    {action ? action : null}
  </Row>
);
