import { FC } from "react";

import {
  Box,
  CloseIcon,
  NumberInput,
  Row,
  Select,
  Text,
} from "@hightouchio/ui";
import {
  ScheduleInterval,
  ScheduleIntervalUnit,
} from "src/components/schedule/types";

type Props = {
  quantity: number;
  unit: ScheduleIntervalUnit;
  onChange: (partialSchedule: Partial<ScheduleInterval> | undefined) => void;
  onReset?: () => void;
};

export const Schedule: FC<Props> = ({ quantity, unit, onChange, onReset }) => {
  return (
    <Row
      align="center"
      display="grid"
      gridTemplateColumns={`min-content 56px 104px ${onReset ? "20px" : ""}`}
      gap={2}
    >
      <Text>Every</Text>
      <NumberInput
        value={quantity}
        onChange={(quantity) => onChange({ quantity })}
      />
      <Select
        options={[
          { label: "Hour(s)", value: ScheduleIntervalUnit.HOUR },
          { label: "Day(s)", value: ScheduleIntervalUnit.DAY },
          { label: "Week(s)", value: ScheduleIntervalUnit.WEEK },
        ]}
        value={unit}
        width="4xs"
        onChange={(unit) => onChange({ unit: unit as ScheduleIntervalUnit })}
      />
      {onReset && (
        <Box
          as={CloseIcon}
          color="text.secondary"
          fontSize={20}
          onClick={onReset}
        />
      )}
    </Row>
  );
};
