import { ConfirmationDialog, Paragraph, useToast } from "@hightouchio/ui";
import { captureException } from "@sentry/react";
import { FC, useState } from "react";
import { useNavigate } from "src/router";
import { useDeleteDestinationRuleMutation } from "src/graphql";
import { DESTINATION_RULES_BASE_PATH } from "./utils";
import { PermissionedButton } from "src/components/permission";

type Props = {
  id: string;
  parentModelId: string;
};

export const DeleteDestinationRule: FC<Readonly<Props>> = ({
  id,
  parentModelId,
}) => {
  const navigate = useNavigate();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { toast } = useToast();
  const deleteDestinationRule = useDeleteDestinationRuleMutation();

  const handleDelete = async () => {
    try {
      await deleteDestinationRule.mutateAsync({ id });

      toast({
        id: "delete-destination-rule",
        title: "Destination rule was deleted",
        variant: "success",
      });

      navigate(DESTINATION_RULES_BASE_PATH);
    } catch (error) {
      toast({
        id: "delete-destination-rule",
        title: "Could not delete destination rule",
        variant: "error",
      });
      captureException(error);
    }
  };

  return (
    <>
      <PermissionedButton
        permission={{
          v1: { resource: "audience_schema", grant: "update" },
          v2: {
            resource: "model",
            grant: "can_update",
            id: parentModelId,
          },
        }}
        onClick={() => setShowConfirmation(true)}
        isLoading={deleteDestinationRule.isLoading}
        size="lg"
        variant="warning"
      >
        Delete
      </PermissionedButton>
      <ConfirmationDialog
        isOpen={showConfirmation}
        confirmButtonText="Delete"
        title="Delete destination rule"
        variant="danger"
        onCancel={() => setShowConfirmation(false)}
        onClose={() => setShowConfirmation(false)}
        onConfirm={handleDelete}
      >
        <Paragraph>
          Deletion of a destination rule is not reversible, you will not be able
          to restore it afterwards.
        </Paragraph>
      </ConfirmationDialog>
    </>
  );
};
