import { useCallback, useEffect, useState } from "react";

import { AudienceSplit } from "src/types/visual";

import { CONNECTIONS_CONTAINER_ID } from "./grid";
import {
  getSplitGroupNodeId,
  getSyncIdsForSplit,
  getSyncNodeId,
} from "./utils";

export default (splits: AudienceSplit[]) => {
  const [positions, setPositions] = useState<Record<string, Position>>({});

  useEffect(() => {
    const coordinates: Record<string, Position> = {};

    splits?.forEach((split) => {
      // Calculate position of split
      const splitNodeId = getSplitGroupNodeId(split);
      const splitNodePosition = getNodePosition({
        nodeId: splitNodeId,
        containerId: CONNECTIONS_CONTAINER_ID,
        align: "left",
      });
      if (splitNodePosition) {
        coordinates[splitNodeId] = splitNodePosition;
      }

      // Calculate position of each sync ID
      getSyncIdsForSplit(split).forEach((syncId) => {
        const syncNodeId = getSyncNodeId(syncId);
        const syncNodePosition = getNodePosition({
          nodeId: syncNodeId,
          containerId: CONNECTIONS_CONTAINER_ID,
          align: "right",
        });
        if (syncNodePosition) {
          coordinates[syncNodeId] = syncNodePosition;
        }
      });
    });

    setPositions(coordinates);
  }, [splits]);

  const updateNodePosition = useCallback(
    ({ nodeId, align }: { nodeId: string; align: "left" | "right" }) => {
      const newPosition = getNodePosition({
        nodeId,
        containerId: CONNECTIONS_CONTAINER_ID,
        align,
      });

      if (newPosition) {
        setPositions((state) => ({ ...state, [nodeId]: newPosition }));
      }
    },
    [],
  );

  return {
    nodePositions: positions,
    updateNodePosition,
  };
};

export type Position = {
  x: number;
  y: number;
};

// Get the position (x, y) of the `node` relative to the SVG `container`.
// Returns null if the node does not exist on the DOM.
export const getNodePosition = ({
  nodeId,
  containerId,
  align,
}: {
  nodeId: string;
  containerId: string;
  align: "left" | "right"; // alignment relative to connection
}): Position | null => {
  const node = document.getElementById(nodeId);
  const container = document.getElementById(containerId);

  if (!node || !container) return null;

  const nodeBox = node.getBoundingClientRect();
  const containerBox = container.getBoundingClientRect();

  return {
    x: align === "left" ? 0 : containerBox.width,
    y: nodeBox.top - containerBox.top + nodeBox.height / 2, // add an offset to start at the center of node
  };
};
