import { SchemaModelType } from "src/types/schema";
import { AssetType, InteractionType } from "src/types/visual";
import { InteractionTypeToHumanName } from "src/pages/campaigns/constants";

export const SCHEMA_DRAWER_WIDTH = 672;

// magic value, not an actual interaction but used to determine if a given event
// is a predefined metric type
export const GENERIC_INTERACTION = "GENERIC";

export const GENERIC_INTERACTION_OPTIONS = [
  {
    label: "Generic",
    options: [
      {
        label: "Generic",
        value: GENERIC_INTERACTION,
      },
    ],
  },
];

function getOption(interactionType: InteractionType) {
  return {
    label: InteractionTypeToHumanName[interactionType],
    value: interactionType,
  };
}

export const SMS_INTERACTION_OPTIONS = [
  {
    label: "SMS",
    options: [
      getOption(InteractionType.SmsSent),
      getOption(InteractionType.SmsDelivered),
      getOption(InteractionType.SmsClicked),
      getOption(InteractionType.SmsReplied),
      getOption(InteractionType.SmsOptedOut),
    ],
  },
];

export const INTERACTION_TYPE_OPTIONS = [
  {
    label: "Ads",
    options: [getOption(InteractionType.Sessions)],
  },
  {
    label: "Email",
    options: [
      getOption(InteractionType.EmailDelivered),
      getOption(InteractionType.EmailOpened),
      getOption(InteractionType.EmailClicked),
      getOption(InteractionType.Unsubscribe),
    ],
  },
];

export const SMS_ASSET_OPTIONS = [
  {
    label: "SMS",
    value: AssetType.Sms,
  },
];
export const ASSET_TYPE_OPTIONS = [
  { label: "Email", value: AssetType.Email },
  { label: "Ad", value: AssetType.Ad },
];

export const RelationshipModelTypes = [
  SchemaModelType.Event,
  SchemaModelType.Related,
  SchemaModelType.Interaction,
  SchemaModelType.Asset,
  SchemaModelType.AdStats,
];
