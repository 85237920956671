import { FC, useState } from "react";

import {
  Button,
  ConfirmationDialog,
  Paragraph,
  PlayIcon,
  useToast,
} from "@hightouchio/ui";
import { captureException } from "@sentry/react";
import { useRunIdentityResolutionMutation } from "src/graphql";
import { useOutletContext } from "src/router";

import { OutletContext } from "src/pages/identity-resolution/graph";

export const RunGraphButton: FC<{
  isDisabled?: boolean;
}> = ({ isDisabled }) => {
  const { toast } = useToast();
  const { graph } = useOutletContext<OutletContext>();

  const runMutation = useRunIdentityResolutionMutation();

  const [isFullReRunConfirmationOpen, setIsFullReRunConfirmationOpen] =
    useState(false);

  const startRun = async ({ fullReRun }: { fullReRun: boolean }) => {
    try {
      await runMutation.mutateAsync({
        id: graph.id,
        fullReRun: fullReRun,
      });

      toast({
        id: "run-graph",
        title: "Identity graph run has been initiated.",
        variant: "success",
      });
    } catch (error) {
      captureException(error);
      toast({
        id: "run-graph",
        title: "There was a problem running your identity graph.",
        message: error.message,
        variant: "error",
      });
    }
  };

  const runGraph = () => {
    if (graph.full_rerun) {
      setIsFullReRunConfirmationOpen(true);
    } else {
      startRun({ fullReRun: false });
    }
  };

  return (
    <>
      <Button
        isLoading={runMutation.isLoading}
        isDisabled={isDisabled}
        variant="primary"
        icon={PlayIcon}
        onClick={runGraph}
      >
        Run
      </Button>

      <ConfirmationDialog
        title="Full re-run required"
        isOpen={isFullReRunConfirmationOpen}
        confirmButtonText="Confirm"
        variant="warning"
        onClose={() => setIsFullReRunConfirmationOpen(false)}
        onConfirm={() => startRun({ fullReRun: true })}
      >
        <Paragraph>
          This graph must be fully re-run. Would you like to proceed?
        </Paragraph>
      </ConfirmationDialog>
    </>
  );
};
