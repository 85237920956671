import { FC } from "react";

import { BoxProps, Row, SystemStyleObject } from "@hightouchio/ui";

type IconBoxProps = {
  bg?: string;
  hasGradient?: boolean;
  icon: JSX.Element;
  boxSize?: number | string;
  iconSize?: number | string;
  iconSx?: SystemStyleObject;
};

export const IconBox: FC<Readonly<IconBoxProps & BoxProps>> = ({
  hasGradient = true,
  bg,
  icon,
  boxSize = "24px",
  iconSize = "16px",
  iconSx = {},
  ...props
}) => {
  const fontSize = typeof iconSize === "number" ? iconSize * 4 : iconSize;

  return (
    <Row
      align="center"
      justify="center"
      flexShrink={0}
      height={boxSize}
      width={boxSize}
      borderRadius="md"
      color="white"
      bg={bg}
      backgroundImage={
        hasGradient
          ? "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%)"
          : undefined
      }
      fontSize={fontSize}
      sx={{ svg: { color: "white !important", ...iconSx } }}
      {...props}
    >
      {icon}
    </Row>
  );
};
