import { Code, Context } from "src/components/sources/setup/constants";
import {
  DEFAULT_AUDIT_CODE_TITLE,
  DEFAULT_AUDIT_REASON,
  DEFAULT_PLANNER_CODE_TITLE,
  DEFAULT_PLANNER_REASON,
} from "./default";

type Suggestion = {
  reason: (ctx: Context) => string;
  code: Code[];
};

export const DEFAULT_SUGGESTIONS: Record<string, Suggestion> = {
  validatePlannerSchemaPermissions: {
    reason: DEFAULT_PLANNER_REASON,
    code: [
      {
        title: DEFAULT_PLANNER_CODE_TITLE,
        code: ({ configuration }) =>
          `CREATE SCHEMA IF NOT EXISTS hightouch_planner;\nGRANT CREATE, USAGE ON SCHEMA hightouch_planner TO ${
            configuration?.user || "hightouch_user"
          };`,
      },
    ],
  },
  validateAuditSchemaPermissions: {
    reason: DEFAULT_AUDIT_REASON,
    code: [
      {
        title: DEFAULT_AUDIT_CODE_TITLE,
        code: ({ configuration }) =>
          `CREATE SCHEMA IF NOT EXISTS hightouch_audit;\nGRANT CREATE, USAGE ON SCHEMA hightouch_audit TO ${
            configuration?.user || "hightouch_user"
          };`,
      },
    ],
  },
};

export const SUGGESTIONS: Record<string, Record<string, Suggestion>> = {
  databricks: {
    validatePlannerSchemaPermissions: {
      reason: DEFAULT_PLANNER_REASON,
      code: [
        {
          title: DEFAULT_PLANNER_CODE_TITLE,
          code: () =>
            "CREATE SCHEMA IF NOT EXISTS hightouch_planner;\nGRANT ALL PRIVILEGES ON SCHEMA hightouch_planner TO <YOUR_USER>;",
        },
      ],
    },
    validateAuditSchemaPermissions: {
      reason: DEFAULT_AUDIT_REASON,
      code: [
        {
          title: DEFAULT_AUDIT_CODE_TITLE,
          code: () =>
            "CREATE SCHEMA IF NOT EXISTS hightouch_audit;\nGRANT ALL PRIVILEGES ON SCHEMA hightouch_audit TO <YOUR_USER>;",
        },
      ],
    },
  },
  snowflake: {
    validatePlannerSchemaPermissions: {
      reason: DEFAULT_PLANNER_REASON,
      code: [
        {
          title: DEFAULT_PLANNER_CODE_TITLE,
          code: ({ configuration }) =>
            `CREATE ROLE IF NOT EXISTS HIGHTOUCH_ROLE;\nGRANT ROLE HIGHTOUCH_ROLE TO USER ${
              configuration?.user || "hightouch_user"
            };\nCREATE SCHEMA IF NOT EXISTS HIGHTOUCH_PLANNER;\nGRANT OWNERSHIP ON SCHEMA HIGHTOUCH_PLANNER TO HIGHTOUCH_ROLE;`,
        },
      ],
    },
    validateAuditSchemaPermissions: {
      reason: DEFAULT_AUDIT_REASON,
      code: [
        {
          title: DEFAULT_AUDIT_CODE_TITLE,
          code: ({ configuration }) =>
            `CREATE ROLE IF NOT EXISTS HIGHTOUCH_ROLE;\nGRANT ROLE HIGHTOUCH_ROLE TO USER ${
              configuration?.user || "hightouch_user"
            };\nCREATE SCHEMA IF NOT EXISTS HIGHTOUCH_AUDIT;\nGRANT OWNERSHIP ON SCHEMA HIGHTOUCH_AUDIT TO HIGHTOUCH_ROLE;`,
        },
      ],
    },
  },
  bigquery: {
    validatePlannerSchemaPermissions: {
      reason: DEFAULT_PLANNER_REASON,

      code: [
        {
          title: () => "Create hightouch_planner schema and grant access",
          code: ({ credential, plannerDatabase, configuration }) => {
            const database = plannerDatabase || configuration?.project || "";

            return `CREATE SCHEMA IF NOT EXISTS \`${database}.hightouch_planner\`;\nGRANT \`roles/bigquery.dataViewer\`, \`roles/bigquery.dataEditor\` ON SCHEMA \`${database}.hightouch_planner\` TO "serviceAccount:${
              credential?.stripped_config.client_email ||
              "<YOUR_SERVICE_ACCOUNT>"
            }";`;
          },
        },
      ],
    },
    validateAuditSchemaPermissions: {
      reason: DEFAULT_AUDIT_REASON,
      // reason: ({ definitionName }) =>
      //   `Hightouch needs to create and write to this dataset in order to write audit log data to your database. Try running this snippet in your ${definitionName} SQL console to create the dataset and grant access.`,
      code: [
        {
          title: () => "Create hightouch_audit schema and grant access",
          code: ({ credential, plannerDatabase, configuration }) => {
            const database = plannerDatabase || configuration?.project || "";

            return `CREATE SCHEMA IF NOT EXISTS \`${database}.hightouch_audit\`;\nGRANT \`roles/bigquery.dataViewer\`, \`roles/bigquery.dataEditor\` ON SCHEMA \`${database}.hightouch_audit\` TO "serviceAccount:${
              credential?.stripped_config.client_email ||
              "<YOUR_SERVICE_ACCOUNT>"
            }";`;
          },
        },
      ],
    },
  },
  // It would be nice to switch on the configuration.connector here and provide
  // connector-specific setup instructions.
  trino: {
    validatePlannerSchemaPermissions: {
      reason: () =>
        [
          `A schema (default: \`hightouch_planner\`) is used to store logs of previously-synced data.`,
          `Before enabling the Lightning sync engine, you must first create this schema and grant Hightouch permission to read/write data.`,
          `This snippet applies to a Hive connector and needs to be run in Trino for a specific catalog.`,
          `Modify as needed for your connector.`,
        ].join(" "),
      code: [
        {
          title: DEFAULT_PLANNER_CODE_TITLE,
          code: ({ configuration }) =>
            `CREATE SCHEMA IF NOT EXISTS ${
              configuration?.catalog ?? "<catalog>"
            }.hightouch_planner;
            `,
        },
      ],
    },
    validateAuditSchemaPermissions: {
      reason: DEFAULT_AUDIT_REASON,
      code: [
        {
          title: () =>
            [
              `A schema (default: \`hightouch_audit\`) is used to store logs of previously-synced data.`,
              `Before enabling the Lightning sync engine, you must first create this schema and grant Hightouch permission to read/write data.`,
              `This snippet applies to a Hive connector and needs to be run in Trino for a specific catalog.`,
              `Modify as needed for your connector.`,
            ].join(" "),
          code: ({ configuration }) =>
            `CREATE SCHEMA IF NOT EXISTS ${
              configuration?.catalog ?? "<catalog>"
            }.hightouch_audit;
            `,
        },
      ],
    },
  },
};

export const DEFAULT_TIME_ELAPSED_WARNING_TITLE = ({
  definitionName,
}: Context): string =>
  `Our connection test for ${definitionName} is taking longer than expected.`;

export const TIME_ELAPSED_WARNING_TITLE: Record<
  string,
  (ctx: Context) => string
> = {
  databricks: (): string =>
    `Our connection test for Databricks is taking longer than expected.`,
};

export const DEFAULT_TIME_ELAPSED_WARNING_MESSAGE = ({
  definitionName,
}: Context): string =>
  `This could be due to a slow connection or ${definitionName} taking a while to start up. If you have been seeing this message for a long time, please check that your ${definitionName} instance is up and available.`;

export const TIME_ELAPSED_WARNING_MESSAGE: Record<
  string,
  (ctx: Context) => string
> = {
  databricks: (): string =>
    `This could be due to your Databricks cluster taking a while to start up. If you have been seeing this message for a long time, please check that your Databricks cluster is online and available.`,
};
