import { FC, ReactNode } from "react";

import { Row, Column, BoxProps } from "@hightouchio/ui";
import Helmet from "react-helmet";

import { useHeaderHeight } from "src/contexts/header-height-context";
import { Props as HeaderProps, PageHeader } from "./header/page-header";
import {
  DefaultPageContainerPadding,
  PageContainer,
  PageContainerProps,
} from "./page-container";

export type Props = {
  children: ReactNode;
  header?: ReactNode;
  sidebar?: ReactNode;
  title: string;
  contain?: boolean; // Use if container should fit screen size and not scroll at the body level
  sx?: BoxProps;
} & HeaderProps &
  Omit<PageContainerProps, keyof BoxProps>;

export const Page: FC<Readonly<Props>> = ({
  children,
  crumbs,
  sync,
  header,
  outsideTopbar,
  sx,
  sidebar,
  title,
  contain,
  ...containerProps
}) => {
  const { headerHeight } = useHeaderHeight();

  const headerNode = (
    <PageHeader crumbs={crumbs} outsideTopbar={outsideTopbar} sync={sync}>
      {header}
    </PageHeader>
  );

  if (sidebar) {
    return (
      <>
        <Helmet>
          <title>{title}</title>
        </Helmet>

        {headerNode}
        <Row flex={1} pos="relative" flexDir="row-reverse">
          <Column flex={1} pb={24}>
            {children}
          </Column>
          {sidebar}
        </Row>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      {headerNode}
      <PageContainer
        {...containerProps}
        {...sx}
        pt={crumbs ? 0 : DefaultPageContainerPadding.Top}
        maxHeight={contain ? `calc(100vh - ${headerHeight}px)` : undefined}
        overflow={contain ? "hidden" : undefined}
      >
        {children}
      </PageContainer>
    </>
  );
};
