import {
  ExtendedType,
  type ExtendedTypes,
  isExtendedTypes,
} from "./extended-types";

export const toStandardFieldTypes = (
  type: undefined | string | ExtendedTypes,
): string | undefined => {
  if (isExtendedTypes(type)) {
    if (["URL", "PHONE"].includes(type.type)) return "STRING";

    if (type.type === "UNION") {
      return toStandardFieldTypes(type?.types?.[0]);
    }

    return type.type;
  }

  return type;
};

export const toExtendedFieldTypes = (
  type: undefined | string | ExtendedTypes,
): ExtendedTypes | undefined => {
  if (!type) return undefined;

  if (isExtendedTypes(type)) return type;

  if (typeof type === "string") {
    return ExtendedType[type.toLowerCase()]?.() ?? ExtendedType.unknown();
  }

  return ExtendedType.unknown();
};
