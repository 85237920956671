import {
  Box,
  Column,
  Combobox,
  FormField,
  NumberInput,
  Row,
  SectionHeading,
  Text,
} from "@hightouchio/ui";
import {
  useEligibleTargetAudiencesQuery,
  useUpdateDecisionEngineFlowMutation,
} from "src/graphql";
import { Controller } from "react-hook-form";
import { Card } from "src/components/card";
import { useOutletContext } from "src/router";
import { OutletContext } from "src/pages/decision-engines";
import { Form, SaveButton, useHightouchForm } from "src/components/form";
import { FC } from "react";
import { Flow } from "..";

export const Targeting: FC<Readonly<{ flow: Flow }>> = ({ flow }) => {
  const { engine } = useOutletContext<OutletContext>();

  const modelsQuery = useEligibleTargetAudiencesQuery(
    {
      parentModelId: engine.segment.id,
    },
    {
      select: (data) => data.segments,
    },
  );

  const update = useUpdateDecisionEngineFlowMutation();

  const form = useHightouchForm({
    onSubmit: async (data) => {
      await update.mutateAsync({
        id: flow?.id,
        input: {
          audience_id: data.audience_id,
          config: data.config,
        },
      });
    },
    values: {
      audience_id: flow.audience?.id,
      config: flow.config,
    },
  });

  return (
    <Form form={form}>
      <Card gap={4} p={6}>
        <Column>
          <SectionHeading>Targeting</SectionHeading>
          <Text>
            The target audience for this flow. Optionally hold out a percentage
            to measure incrementality.
          </Text>
        </Column>
        <Row gap={4} align="flex-end">
          <Controller
            name="audience_id"
            render={({ field }) => (
              <Box as={FormField} label="Target audience" width="2xs">
                <Combobox
                  {...field}
                  width="100%"
                  isLoading={modelsQuery.isLoading}
                  optionValue={(option) => option.id}
                  optionLabel={(option) => option.name}
                  options={modelsQuery.data ?? []}
                />
              </Box>
            )}
          />
          <Controller
            name="config.holdout"
            render={({ field }) => (
              <Box as={FormField} label="Holdout" width="100px">
                <NumberInput
                  width="100%"
                  {...field}
                  placeholder="Set a holdout..."
                  formatOptions={{ style: "percent" }}
                  min={0}
                  max={1}
                />
              </Box>
            )}
          />
          <SaveButton size="md">Save</SaveButton>
        </Row>
      </Card>
    </Form>
  );
};
