import { Column, Row, SearchInput, Text } from "@hightouchio/ui";
import { FC, useState } from "react";
import { Controller } from "react-hook-form";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { useSubscribedResources } from "src/components/notification-channels/use-subscribed-resources";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { Table } from "src/ui/table";
import { getSearchRegExp } from "src/utils/string";

export const Resources: FC = () => {
  const [search, setSearch] = useState("");
  const { availableResources } = useSubscribedResources({});

  const allDestinations = availableResources?.destinations ?? [];

  const filteredDestinations = allDestinations.filter((destination) => {
    if (!destination) {
      return false;
    }
    if (search) {
      const regex = getSearchRegExp(search, "i");
      return (
        regex.test(destination.name?.toLowerCase() ?? "") ||
        regex.test(destination.definition.name?.toLowerCase() ?? "")
      );
    }
    return true;
  });

  return (
    <Column gap={6} minH={0}>
      <Column gap={2}>
        <Row justify="space-between">
          <Column>
            <Text fontWeight="medium">Destinations</Text>
            <Text color="text.secondary">
              Alerts from the following destinations will be sent to this
              recipient. Routing logic can still be overridden per sync.
            </Text>
          </Column>
        </Row>
        <Row gap={2} align="center">
          <SearchInput
            placeholder="Search destinations..."
            value={search ?? ""}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </Row>
      </Column>
      <Controller
        name="resourceIdsToSubscribe.destinations"
        render={({ field }) => {
          const subscribedDestinations = Object.entries(field.value ?? {})
            .filter(([_, value]) => (value as any).subscribed)
            .map(([id]) => Number(id));

          return (
            <Table
              onSelect={(selection) => {
                if (Array.isArray(selection)) {
                  const newValue = { ...field.value };
                  if (selection.length === 0) {
                    for (const id of Object.keys(field.value)) {
                      newValue[id] = {
                        ...field.value[id],
                        subscribed: false,
                      };
                    }
                  } else {
                    for (const id of selection) {
                      const existing = field.value[id];
                      newValue[id] = {
                        ...(existing ?? {}),
                        subscribed: true,
                      };
                    }
                  }
                  field.onChange(newValue);
                } else {
                  const currentValue = field.value[selection];
                  field.onChange({
                    ...field.value,
                    [selection]: {
                      ...currentValue,
                      subscribed: !currentValue?.subscribed,
                    },
                  });
                }
              }}
              selectedRows={subscribedDestinations}
              columns={[
                {
                  cell: (destination) => {
                    return (
                      <Row gap={2} align="center" overflow="hidden">
                        <IntegrationIcon
                          src={destination.definition.icon}
                          name={destination.name || destination.definition.name}
                        />
                        <Column>
                          <TextWithTooltip fontWeight="medium">
                            {destination.name || destination.definition.name}
                          </TextWithTooltip>
                        </Column>
                      </Row>
                    );
                  },
                  name: "Destination",
                },
              ]}
              data={filteredDestinations}
            />
          );
        }}
      />
    </Column>
  );
};
