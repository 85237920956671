import { FC } from "react";

import { Handle, HandleProps, Position } from "reactflow";

import { HANDLE_SIZE, NODE_WIDTH } from "src/pages/journeys/constants";
import { useGraphContext } from "src/pages/journeys/graph";
import { NodeColor } from "src/pages/journeys/types";

type SharedHandleProps = HandleProps & { enabled: boolean };

export const TileNodeSourceHandle: FC<
  Omit<SharedHandleProps, "type" | "position"> & {
    colorScheme: NodeColor;
    opacity?: number;
  }
> = ({ colorScheme, enabled = false, opacity = 1, ...props }) => {
  return (
    <Handle
      {...props}
      type="source"
      position={Position.Bottom}
      isConnectableStart={enabled}
      style={{
        pointerEvents: enabled ? "all" : "none",
        height: `${HANDLE_SIZE}px`,
        width: `${HANDLE_SIZE}px`,
        borderColor: `var(--chakra-colors-gray-500)`,
        background: "white",
        borderWidth: "2px",
        opacity,
        bottom: "-8px",
        cursor: enabled ? "crosshair" : "unset",
      }}
    />
  );
};

export const TileNodeTargetHandle: FC<
  Omit<SharedHandleProps, "type" | "position">
> = ({ enabled = false, ...props }) => {
  const { connectionSourceNodeId } = useGraphContext();

  return (
    <Handle
      {...props}
      position={Position.Top}
      type="target"
      isConnectableEnd={enabled}
      style={{
        borderRadius: 0,
        pointerEvents: enabled ? "all" : "none",
        height: connectionSourceNodeId ? "100%" : 0,
        width: connectionSourceNodeId ? `${NODE_WIDTH}px` : 0,
        opacity: 0,
        cursor: enabled ? "crosshair" : "unset",
      }}
    />
  );
};

export const MiniNodeHandle: FC<SharedHandleProps> = ({
  enabled,
  ...props
}) => {
  return (
    <Handle
      {...props}
      isConnectableStart={enabled}
      style={{
        height: `${HANDLE_SIZE}px`,
        width: `${HANDLE_SIZE}px`,
        borderColor: `var(--chakra-colors-gray-500)`,
        borderWidth: "2px",
        background: "white",
        bottom: "-8px",
        cursor: enabled ? "crosshair" : "unset",
      }}
    />
  );
};

export const HiddenHandle: FC<SharedHandleProps> = ({ enabled, ...props }) => {
  return (
    <Handle
      {...props}
      isConnectableEnd={false}
      style={{
        opacity: 0,
        cursor: enabled ? "crosshair" : "unset",
      }}
    />
  );
};
