import { CampaignAnalyticsDashboardData } from "src/graphql";
import {
  AssetType,
  ColumnReference,
  ColumnType,
  exhaustiveCheck,
  FilterableColumn,
  PredefinedMetric as BackendPredefinedMetric,
} from "src/types/visual";

import {
  CampaignTableRow,
  ConversionMetric,
  PredefinedMetric,
  CampaignTableRowWithKey,
} from "./types";
import { capitalize } from "lodash";

export const transformTableData = (
  data: CampaignAnalyticsDashboardData["assets"] | undefined,
  columns: FilterableColumn[],
  predefinedMetrics: PredefinedMetric[],
  conversionMetrics: ConversionMetric[],
): CampaignTableRowWithKey[] => {
  if (!data) return [];

  return data.map(({ assetColumns, metricAttribution }, index) => {
    // Asset columns
    const assetRowData: CampaignTableRow = assetColumns.reduce(
      (all, column) => {
        all[column.name] = {
          label: column.name,
          type: columns.find((c) => c.name === column.name)?.type || "string",
          value: column.value,
        };

        return all;
      },
      {},
    );

    // Metric attribution
    const metricAttributionRowData: CampaignTableRow = metricAttribution.reduce(
      (all, metricAttribution) => {
        const foundPredefinedMetric = predefinedMetrics.find(
          (metric) => metric.id === metricAttribution.goalId,
        );
        const foundConversionMetric = conversionMetrics.find(
          (metric) =>
            metric.id === metricAttribution.goalId &&
            metric.attributionMethod.id ===
              metricAttribution.attributionMethodId,
        );

        const metric = foundPredefinedMetric ?? foundConversionMetric;

        if (!metric) return all;

        const attributionMethod = foundConversionMetric
          ? foundConversionMetric.attributionMethod
          : undefined;

        let id = metricAttribution.goalId;

        if (metricAttribution.attributionMethodId) {
          id += `-${metricAttribution.attributionMethodId}`;
        }

        all[id] = {
          label: metric?.name,
          description: attributionMethod?.name,
          type: ColumnType.Number,
          value: metricAttribution.attributionValue,
        };

        return all;
      },
      {},
    );

    return {
      ...assetRowData,
      ...metricAttributionRowData,
      __reactKey: `row-${index}`,
    } as CampaignTableRowWithKey;
  });
};

export const convertColumnReferenceModelIdToString = (
  columnReference: ColumnReference,
) => {
  if (columnReference.type === "raw") {
    return { ...columnReference, modelId: columnReference.modelId.toString() };
  }

  if (columnReference.type === "related") {
    return {
      ...columnReference,
      column: convertColumnReferenceModelIdToString(columnReference.column),
    };
  }

  // Unsupported types
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - This is explicitly not of type 'never'
  return exhaustiveCheck(columnReference.type);
};

export function getAssetNoun(
  assetType: AssetType,
  options: { capitalize?: boolean; shorthand?: boolean } = {},
): string {
  // Ad == 'ad', Email == 'email', Sms == 'SMS message'
  switch (assetType) {
    case AssetType.Sms:
      if (options.shorthand) {
        return "SMS";
      }

      return "SMS message";
    default:
      if (options.capitalize) {
        return capitalize(assetType);
      }

      return assetType;
  }
}

const HiddenPredefinedMetrics = [
  BackendPredefinedMetric.UniqueEmailsOpened,
  BackendPredefinedMetric.EmailClickthroughRate,
  BackendPredefinedMetric.SmsClickRate,
  BackendPredefinedMetric.SmsUniqueClicked,
];

export function shouldShowPredefinedMetric(metric: PredefinedMetric) {
  return !HiddenPredefinedMetrics.includes(metric.config.predefinedMetric);
}
