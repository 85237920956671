import { FC, useState } from "react";

import {
  Alert,
  Button,
  ChakraModal,
  ChakraModalBody,
  ChakraModalContent,
  ChakraModalFooter,
  ChakraModalHeader,
  ChakraModalOverlay,
  Column,
  Text,
  Paragraph,
  useToast,
} from "@hightouchio/ui";
import { Link } from "src/router";
import * as Sentry from "@sentry/react";
import capitalize from "lodash/capitalize";
import { useNavigate } from "src/router";

import { TraitQuery, useArchiveTraitMutation } from "src/graphql";
import { PermissionedButton } from "src/components/permission";

import { TraitReferences, isTraitReferenced } from "./trait-references";

type Props = {
  trait: NonNullable<TraitQuery["trait_definitions_by_pk"]>;
};

export const ArchiveTrait: FC<Readonly<Props>> = ({ trait }) => {
  const navigate = useNavigate();
  const { toast } = useToast();

  const [showArchiveConfirmation, setShowArchiveConfirmation] =
    useState<boolean>(false);
  const archiveTrait = useArchiveTraitMutation();

  const isTraitBeingUsed = isTraitReferenced(trait);

  const traitType = trait.is_template ? "template" : "trait";

  const handleArchiveTrait = async () => {
    try {
      await archiveTrait.mutateAsync({ id: trait.id });
      navigate(`/traits/archived/${trait.id}`);

      toast({
        id: "archive-trait",
        title: `${capitalize(traitType)} was archived successfully`,
        variant: "success",
      });
      setShowArchiveConfirmation(false);
    } catch (error) {
      toast({
        id: "archive-trait",
        title: `${capitalize(traitType)} failed to be archived`,
        variant: "error",
      });
      Sentry.captureException(error);
    }
  };

  return (
    <>
      <PermissionedButton
        permission={{
          v1: { resource: "audience_schema", grant: "update" },
          v2: {
            resource: "model",
            grant: "can_update",
            id: trait.parent_model.id,
          },
        }}
        variant="warning"
        size="lg"
        onClick={() => setShowArchiveConfirmation(true)}
      >
        Archive
      </PermissionedButton>

      <ChakraModal
        isOpen={showArchiveConfirmation}
        onClose={() => setShowArchiveConfirmation(false)}
        closeOnEsc
        closeOnOverlayClick
        isCentered
        scrollBehavior="inside"
      >
        <ChakraModalOverlay>
          <ChakraModalContent p={0} maxWidth="500px" my="auto">
            <ChakraModalHeader
              p={6}
              borderBottom="1px solid"
              borderColor="base.border"
            >
              Archive {traitType}: {trait.name}
            </ChakraModalHeader>
            <ChakraModalBody pb={6} m={0}>
              {isTraitBeingUsed ? (
                <Column>
                  <Alert
                    variant="inline"
                    type="error"
                    title={`${capitalize(traitType)} in use`}
                    message={`Cannot archive a ${traitType} that is used in an ${
                      trait.is_template
                        ? "active trait, audience, or sync"
                        : "audience or sync"
                    }.`}
                  />
                  <TraitReferences trait={trait} />
                </Column>
              ) : (
                <Paragraph textAlign="left">
                  Are you sure you want to archive the {traitType} "
                  <Text fontWeight="semibold">{trait.name}</Text>"?
                  <br />
                  <br />
                  This {traitType} will no longer be available to use in
                  audiences or syncs but you can restore it from the{" "}
                  <Link href="/traits/archived" isExternal>
                    archived table
                  </Link>
                  .
                </Paragraph>
              )}
            </ChakraModalBody>
            <ChakraModalFooter
              p={4}
              m={0}
              borderTop="1px solid"
              borderColor="base.border"
            >
              <Button onClick={() => setShowArchiveConfirmation(false)}>
                Cancel
              </Button>
              <Button
                isDisabled={isTraitBeingUsed || archiveTrait.isLoading}
                isLoading={archiveTrait.isLoading}
                variant="danger"
                onClick={handleArchiveTrait}
              >
                Archive
              </Button>
            </ChakraModalFooter>
          </ChakraModalContent>
        </ChakraModalOverlay>
      </ChakraModal>
    </>
  );
};
