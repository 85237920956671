import { ReactNode, Suspense } from "react";
import { QueryClientProvider } from "react-query";
import { ThemeProvider } from "@hightouchio/ui";

import { FeatureFlagProvider } from "src/contexts/feature-flags";
import { UserProvider } from "src/contexts/user-context";
import { CommandBar } from "src/components/commandbar";
import { HeaderHeightProvider } from "src/contexts/header-height-context";
import { GlobalStyles } from "src/components/global-styles";
import { PageSpinner } from "src/components/loading";
import { ErrorBoundary } from "src/components/error-boundary";
import { queryClient } from "src/lib/react-query";

export const RootProviders = ({ children }: { children: ReactNode }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        {children}
        <GlobalStyles />
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export const AppProviders = ({ children }: { children: ReactNode }) => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<PageSpinner />}>
        <UserProvider>
          <FeatureFlagProvider>
            <HeaderHeightProvider>
              <CommandBar>{children}</CommandBar>
            </HeaderHeightProvider>
          </FeatureFlagProvider>
        </UserProvider>
      </Suspense>
    </ErrorBoundary>
  );
};
