import { FC } from "react";

import {
  Box,
  Row,
  SystemStyleObject,
  Text,
  TextProps,
  Tooltip,
  TooltipProps,
} from "@hightouchio/ui";

import { useContentVisibility } from "src/hooks/use-content-visibility";

export type TextWithTooltipProps = TextProps &
  Omit<TooltipProps, "message"> & {
    message?: string;
    sx?: SystemStyleObject;
  };

export const TextWithTooltip: FC<Readonly<TextWithTooltipProps>> = ({
  children,
  isDisabled = false,
  keyboardShortcut,
  message,
  placement,
  sx,
  ...props
}) => {
  const { isVisible, ref } = useContentVisibility<HTMLDivElement>();

  return (
    <>
      {/* Remove the `width: max-content` in the span to make ellipsis show */}
      <Row
        display="flex"
        alignItems="center"
        overflow="hidden"
        sx={{ ">span": { width: "100%", whiteSpace: "nowrap", ...sx } }}
      >
        <Tooltip
          message={message ?? String(children)}
          isDisabled={isVisible || isDisabled}
          keyboardShortcut={keyboardShortcut}
          placement={placement}
        >
          {/* Set display to block to make ellipsis show */}
          <Box ref={ref} as={Text} isTruncated display="block" {...props}>
            {children}
          </Box>
        </Tooltip>
      </Row>
    </>
  );
};
