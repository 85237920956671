import { FC } from "react";

import { OutletContext } from ".";
import { useOutletContext } from "src/router";
import { ForwardingSyncForm } from "./components/form";

export const ForwardingSyncConfiguration: FC = () => {
  const { sync } = useOutletContext<OutletContext>();

  return <ForwardingSyncForm sync={sync} />;
};
