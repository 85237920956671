import { FC, Fragment, MouseEvent, useMemo, useState } from "react";

import {
  Box,
  Button,
  Column,
  MeetingIcon,
  Paragraph,
  Pill,
  Row,
  SearchInput,
  SectionHeading,
  Spinner,
  Text,
  ToggleButton,
  ToggleButtonGroup,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import isEqual from "lodash/isEqual";
import noop from "lodash/noop";
import omit from "lodash/omit";
import round from "lodash/round";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend as RechartsLegend,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "src/router";
import { isPresent } from "ts-extras";

import analyticsPlaceholder from "src/assets/placeholders/analytics.svg";
import { DateRangePicker } from "src/components/analytics/date-range-picker";
import CustomLegend from "src/components/analytics/shared/legend";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { FunnelMetricDataForCohort } from "src/graphql";
import * as analytics from "src/lib/analytics";
import {
  graphColors,
  placeholderContentWidthPx,
} from "src/pages/analytics/constants";
import { LookbackOptions } from "src/pages/analytics/state/constants";
import {
  ChartFormState,
  FunnelStepGraphData,
  HoveredSection,
  FunnelMeasurementType,
  TimeOptions,
} from "src/pages/analytics/types";
import {
  createEventConditionFromFunnelSteps,
  formatDatePickerLabel,
  getTableDataFromFunnelData,
  transformFunnelDataForGraph,
} from "src/pages/analytics/utils";
import { useAnalyticsContext } from "src/pages/analytics/state";
import { CreateAudienceState } from "src/pages/audiences/types";
import {
  ColumnType,
  EventCondition,
  PropertyCondition,
  initialPropertyCondition,
} from "src/types/visual";
import { Table, TableColumn } from "src/ui/table";

import { FunnelGraphDropdown } from "./funnel-graph-dropdown";
import { Label } from "./label";
import CustomTooltip from "./tooltip";
import { GraphWrapper } from "../shared/graph-wrapper";

const axisStyles = {
  stroke: "#9AA6B2",
  fontFamily: "Inter",
  fontWeight: 600,
  letterSpacing: "0.03em",
};
const tickStyles = {
  fontSize: "12px",
  color: "var(--chakra-colors-text-secondary)",
  fontWeight: 400,
};

type Props = {
  data: FunnelMetricDataForCohort[];
  hasErrors?: boolean;
  isLoading?: boolean;
};

export const FunnelGraph: FC<Props> = ({
  data,
  hasErrors = false,
  isLoading = false,
}) => {
  const navigate = useNavigate();
  const { events, parent, setLookbackWindow, setSelectedDates } =
    useAnalyticsContext();

  const form = useFormContext<ChartFormState>();

  const parentModelId = form.watch("parentModelId");
  const groupByColumns = form.watch("groupByColumns");
  const selectedAudiences = form.watch("selectedAudiences");
  const selectedDateStrings = form.watch("selectedDates");
  const timeValue = form.watch("timeValue");

  const funnelsGraph = useMemo(
    () =>
      transformFunnelDataForGraph({
        audiences: selectedAudiences ?? [],
        data,
        events,
      }),
    [events, data, selectedAudiences],
  );

  const [selectedConditions, setSelectedConditions] = useState<
    (EventCondition | PropertyCondition)[]
  >([]);
  const [popoverPosition, setPopoverPosition] = useState({ x: 0, y: 0 });
  const [search, setSearch] = useState("");
  const [hoveredSection, setHoveredSection] = useState<HoveredSection>({
    dataKey: null,
    seriesName: null,
    section: "conversion",
  });

  const selectedDates = useMemo(
    () => selectedDateStrings.map((dateStr) => new Date(dateStr)),
    [selectedDateStrings?.[0], selectedDateStrings?.[1]],
  );

  const tableData = getTableDataFromFunnelData(funnelsGraph);
  const filteredTableData = tableData.filter(({ seriesName }) => {
    return seriesName.toLowerCase().includes(search.toLowerCase());
  });

  const openCreateAudienceDropdown = (
    stage: FunnelStepGraphData,
    groupByValue?: string | number,
    didPerform = true,
  ) => {
    // need a way to figure out first and second step
    if (!stage.eventModelId || !stage.relationshipId) {
      Sentry.captureException(new Error("[Funnels] Stage data not found"));
      return;
    }

    const conditions: (EventCondition | PropertyCondition)[] = [];

    const propertyConditions: PropertyCondition[] = groupByColumns
      .filter(isPresent)
      .map((columnReference) => {
        const column = parent?.filterable_audience_columns.find(
          ({ column_reference }) =>
            // parentModelId is used to determine merge columns on the frontend
            // but it's not in column_reference
            isEqual(column_reference, omit(columnReference, "parentModelId")),
        );

        return {
          ...initialPropertyCondition,
          propertyType: (column?.type ?? null) as ColumnType | null,
          property: column?.column_reference,
          operator: "=",
          value: [groupByValue],
        };
      })
      .filter(isPresent);

    if (stage.index > 0) {
      const previousStep = funnelsGraph[stage.index - 1];
      if (!previousStep) {
        Sentry.captureException(
          new Error("[Funnels] Previous stage's data not found"),
        );
        return;
      }

      conditions.push(
        createEventConditionFromFunnelSteps({
          stage: {
            eventModelId: previousStep.eventModelId,
            relationshipId: previousStep.relationshipId,
            subconditions: previousStep.subconditions,
          },
          secondStage: {
            eventModelId: stage.eventModelId,
            relationshipId: stage.relationshipId,
            subconditions: stage.subconditions,
          },
          didPerform,
        }),
      );
    } else {
      conditions.push(
        createEventConditionFromFunnelSteps({
          stage: {
            eventModelId: stage.eventModelId,
            relationshipId: stage.relationshipId,
            subconditions: stage.subconditions,
          },
          didPerform,
        }),
      );
    }

    conditions.push(...propertyConditions);

    setSelectedConditions(conditions);
  };

  const closeAudienceDropdown = () => {
    setSelectedConditions([]);
  };

  const createAudience = () => {
    if (selectedConditions.length === 0 || !parentModelId) {
      return;
    }

    analytics.track("Creating Audience from Funnel Chart", {
      parent_model_id: parentModelId,
      parent_model_name: parent?.name,
      parent_model_source_name: parent?.connection?.name,
      parent_model_source_type: parent?.connection?.definition.name,
      number_of_conditions: selectedConditions.length,
    });

    const state: CreateAudienceState = {
      referrer: {
        pathname: "analytics",
        search: location.search,
      },
      // bigint -> Type says string but it's actually a number
      parentModelId: parentModelId as unknown as string,
      conditions: selectedConditions,
    };

    navigate("/audiences/new", {
      state,
    });
  };

  const clickBar = ({
    payload,
    event,
    seriesName,
    didPerform = true,
  }: {
    payload: FunnelStepGraphData;
    event: MouseEvent;
    seriesName: string;
    didPerform?: boolean;
  }) => {
    setPopoverPosition({
      x: event.clientX,
      y: event.clientY,
    });

    openCreateAudienceDropdown(
      payload,
      payload.data[seriesName]?.groupByValue,
      didPerform,
    );

    analytics.track("Clicked on Funnel Bar");
  };

  const columns: TableColumn[] = [
    {
      name: "Audience",
      cell: ({ seriesName }) => (
        <TextWithTooltip color="text.secondary" size="sm">
          {seriesName}
        </TextWithTooltip>
      ),
    },
    {
      name: "Conversion",
      cell: ({ conversion }) => (
        <Text color="text.secondary" size="sm">
          {round(conversion * 100, 2)}%
        </Text>
      ),
    },
    ...(funnelsGraph ?? []).map(({ stageName }, index) => ({
      header: () => {
        const headerName = `${index + 1}. ${stageName}`;

        return (
          <TextWithTooltip
            color="text.secondary"
            fontWeight="semibold"
            size="sm"
          >
            {headerName}
          </TextWithTooltip>
        );
      },
      cell: ({ steps }) => {
        const step = steps[index];

        return (
          <Text color="text.secondary" size="sm">
            {step.numberOfUsers} ({round(step.conversion * 100, 2)}%)
          </Text>
        );
      },
    })),
  ];

  const showPlaceholder = !funnelsGraph || funnelsGraph.length === 0;

  if (!isLoading && hasErrors) {
    return (
      <Column justify="center" align="center" height="100%">
        <Column textAlign="center" width={placeholderContentWidthPx}>
          <Box
            as="img"
            boxSize={4}
            alignSelf="center"
            width="380px"
            height="200px"
            src={analyticsPlaceholder}
          />
        </Column>
        <SectionHeading color="danger.base" mb={2}>
          Error running query
        </SectionHeading>
        <Paragraph color="text.secondary">
          There was an error running this query. Please check the errors shown
          in the sidebar and try again.
        </Paragraph>
      </Column>
    );
  }

  return (
    <Column p={6} height="100%">
      <Row gap={2}>
        <ToggleButtonGroup
          size="sm"
          value={timeValue}
          onChange={(value) => setLookbackWindow(value as TimeOptions)}
        >
          {LookbackOptions.map((option) => (
            <ToggleButton key={option.value} {...option}></ToggleButton>
          ))}
        </ToggleButtonGroup>

        <DateRangePicker
          maxDate={new Date()}
          selectedDates={selectedDates}
          onChange={(dates) => {
            setSelectedDates(dates);
          }}
        >
          <Box
            as={Button}
            background={timeValue === TimeOptions.Custom ? "gray.200" : "unset"}
            fontWeight={
              timeValue === TimeOptions.Custom ? "semibold" : "normal"
            }
            icon={MeetingIcon}
            size="sm"
            onClick={noop}
          >
            {formatDatePickerLabel(selectedDates, timeValue)}
          </Box>
        </DateRangePicker>
      </Row>
      {isLoading ? (
        <Column align="center" justifyContent="center" flex={1} minHeight={0}>
          <Spinner size="lg" />
        </Column>
      ) : showPlaceholder ? (
        <Column justifyContent="center" alignItems="center" height="100%">
          <Column textAlign="center" width={placeholderContentWidthPx}>
            <Box
              as="img"
              boxSize={4}
              alignSelf="center"
              width="380px"
              height="200px"
              src={analyticsPlaceholder}
            />
          </Column>
          <SectionHeading>No data</SectionHeading>
          <Paragraph>
            No data was returned. Please adjust the query to try again.
          </Paragraph>
        </Column>
      ) : (
        <>
          {/*
        Using the classic aspect ratio trick to get responsivness working.
        Without these wrapper divs, the chart has a hard time resizing itself to use the appropriate amount of space.
      */}
          <GraphWrapper>
            <ResponsiveContainer>
              <BarChart
                data={funnelsGraph}
                margin={{
                  top: 30,
                  right: 60,
                  left: 0,
                }}
              >
                <CartesianGrid vertical={false} stroke="#E5E9ED" />
                <XAxis
                  axisLine={{ stroke: "#E5E9ED" }}
                  dataKey="index"
                  tickFormatter={(index) => {
                    return `${index + 1}. ${funnelsGraph[index]?.stageName}`;
                  }}
                  interval={0}
                  minTickGap={50}
                  tick={{ ...tickStyles, fontWeight: 500 }}
                  tickLine={false}
                  {...axisStyles}
                  stroke="var(--chakra-colors-text-primary)"
                />
                <YAxis
                  axisLine={false}
                  tickFormatter={(value) => `${(value * 100).toFixed(0)}%`}
                  tick={tickStyles}
                  ticks={[0, 0.25, 0.5, 0.75, 1]}
                  tickLine={false}
                  {...axisStyles}
                />
                <RechartsTooltip
                  animationDuration={200}
                  animationEasing="ease"
                  content={
                    <CustomTooltip
                      hoveredSection={hoveredSection}
                      // TODO(samuel): integrate once backend is ready
                      funnelMeasuringType={
                        FunnelMeasurementType.TotalConversion
                      }
                    />
                  }
                  cursor={{ fill: "transparent" }}
                  wrapperStyle={{ outline: "none" }}
                />
                {funnelsGraph.length > 0 &&
                  Object.keys(funnelsGraph[0]!.data).map(
                    (seriesName, index) => (
                      <Fragment key={seriesName}>
                        <Bar
                          dataKey={`data.${seriesName}.conversionBarSize`}
                          stackId={seriesName}
                          fill={graphColors[index % graphColors.length]!.color}
                          onClick={(data, _, event) => {
                            clickBar({
                              payload: data.payload,
                              event,
                              seriesName,
                            });
                          }}
                          onMouseEnter={() => {
                            if (
                              hoveredSection.seriesName !== seriesName ||
                              hoveredSection.section !== "conversion"
                            ) {
                              setHoveredSection({
                                dataKey: `data.${seriesName}.conversionBarSize`,
                                seriesName,
                                section: "conversion",
                              });
                            }
                          }}
                        >
                          <LabelList
                            dataKey={`data.${seriesName}.conversion`}
                            formatter={(value: number) => `${value}%`}
                            position="insideTop"
                            content={<Label />}
                          />
                        </Bar>
                        <Bar
                          dataKey={`data.${seriesName}.dropOffBarSize`}
                          stackId={seriesName}
                          fill={graphColors[index % graphColors.length]!.color}
                          opacity={0.2}
                          onClick={(data, _, event) =>
                            clickBar({
                              payload: data.payload,
                              event,
                              seriesName,
                              didPerform: false,
                            })
                          }
                          onMouseEnter={() => {
                            if (
                              hoveredSection.seriesName !== seriesName ||
                              hoveredSection.section !== "dropOff"
                            ) {
                              setHoveredSection({
                                dataKey: `data.${seriesName}.dropOffBarSize`,
                                seriesName,
                                section: "dropOff",
                              });
                            }
                          }}
                        />
                      </Fragment>
                    ),
                  )}
                <RechartsLegend
                  verticalAlign="bottom"
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore -- overload type for payload so we can pass more information to Legend
                  payload={undefined}
                  content={() => (
                    <CustomLegend
                      onHover={noop}
                      payload={Object.keys(funnelsGraph?.[0]?.data ?? []).map(
                        (seriesName, index) => ({
                          key: seriesName,
                          name: seriesName,
                          legendName: seriesName,
                          color: graphColors[index % graphColors.length]!.color,
                        }),
                      )}
                    />
                  )}
                />
              </BarChart>
            </ResponsiveContainer>
          </GraphWrapper>
          <Box mb={4} borderBottom="1px solid" borderColor="base.border" />
          {funnelsGraph.length > 0 && (
            <Column>
              <Row align="center" justify="space-between" px={6} pb={4}>
                <Row align="center" gap={2}>
                  <Text>All series</Text>
                  <Box>
                    <Pill>{tableData.length}</Pill>
                  </Box>
                </Row>
                <SearchInput
                  placeholder="Search subsegments..."
                  width="auto"
                  value={search}
                  onChange={(event) => setSearch(event.target.value)}
                />
              </Row>
              <Table columns={columns} data={filteredTableData} />
            </Column>
          )}
        </>
      )}

      <FunnelGraphDropdown
        isOpen={selectedConditions.length > 0}
        label="Create audience"
        position={popoverPosition}
        onClick={createAudience}
        onClose={closeAudienceDropdown}
      />
    </Column>
  );
};
