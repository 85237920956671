import {
  Column,
  FormField,
  Radio,
  RadioGroup,
  SectionHeading,
} from "@hightouchio/ui";
import { Controller, useFormContext } from "react-hook-form";

import { EventSchemaFormState } from "src/events/contracts/types";

export const EnforcementSettings = () => {
  const { control } = useFormContext<EventSchemaFormState>();

  return (
    <Column gap={4}>
      <SectionHeading>Enforcement settings</SectionHeading>
      <Controller
        name="onUndeclaredFields"
        control={control}
        render={({ field }) => (
          <FormField
            label="Undeclared fields"
            description="When this event has properties that are not part of the above schema"
          >
            <RadioGroup
              orientation="vertical"
              value={field.value}
              onChange={field.onChange}
            >
              <Radio
                label="Allow"
                description="Sync the event to its destination with a warning"
                value="ALLOW_EVENT"
              />
              <Radio
                label="Filter"
                description="Sync the event but block the undeclared fields"
                value="OMIT_FIELDS"
              />
              <Radio
                label="Block"
                description="Event will not sync to destination"
                value="BLOCK_EVENT"
              />
            </RadioGroup>
          </FormField>
        )}
      />
      <Controller
        name="onSchemaViolation"
        control={control}
        render={({ field }) => (
          <FormField
            label="Invalid fields"
            description="When this event is missing required fields or field types are incorrect"
          >
            <RadioGroup
              orientation="vertical"
              value={field.value}
              onChange={field.onChange}
            >
              <Radio
                label="Allow"
                description="Sync the event to its destination with a warning"
                value="ALLOW_EVENT"
              />

              <Radio
                label="Block"
                description="Event will not sync to destination"
                value="BLOCK_EVENT"
              />
            </RadioGroup>
          </FormField>
        )}
      />
    </Column>
  );
};
