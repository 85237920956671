import { format } from "numerable";

export const abbreviateNumber = (
  value: number,
  maximumFractionDigits = 1,
): string =>
  Intl.NumberFormat("en-US", {
    notation: "compact",
    maximumFractionDigits,
  }).format(value);

export const roundToDecimalPlace = (
  num: number,
  decimalPlaces: number,
): number => {
  return Math.round(num * 10 ** decimalPlaces) / 10 ** decimalPlaces;
};

export const commaNumber = (num: number): string =>
  num < 100000000 ? format(num, "0,0") : abbreviateNumber(num);

export const accurateCommaNumber = (num: number | string): string =>
  format(num, "0,0.X", { rounding: "truncate" });

export const truncateNumber = (num: number): string =>
  Number.isInteger(num) ? String(num) : format(num, "0.00");

export const ordinalSuffix = (num: number): string => {
  if (num > 3 && num < 21) return "th";

  switch (num % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};
