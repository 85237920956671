import {
  DestinationMetadataQuery,
  SyncRunsQuery,
  useDestinationMetadataQuery,
  useIsDestinationMetadataQuery,
  useSyncRunsQuery,
} from "src/graphql";
import { ElementOf } from "ts-essentials";

export const useDestinationMetadata = (
  syncId: string | null,
): {
  isSupported: boolean;
  isLoading: boolean;
  metadata: DestinationMetadataQuery["getDestinationMetadata"] | undefined;
  firstRun: ElementOf<SyncRunsQuery["sync_requests"]> | undefined;
} => {
  const { data: isSupported, isLoading: isSupportedIsLoading } =
    useIsDestinationMetadataQuery(
      { id: syncId ?? "" },
      {
        enabled: Boolean(syncId),
        select: (data) => data.supportsDestinationMetadata,
      },
    );

  const { data: metadata, isLoading: metadataIsLoading } =
    useDestinationMetadataQuery(
      {
        id: syncId ?? "",
      },
      {
        enabled: Boolean(syncId),
        select: (data) => data.getDestinationMetadata,
      },
    );

  const { data: firstRun, isLoading: firstRunIsLoading } = useSyncRunsQuery(
    {
      filter: { destination_instance_id: { _eq: syncId } },
      orderBy: { created_at: "asc" },
      limit: 1,
    },
    {
      enabled: Boolean(syncId),
      select: (data) => data.sync_requests?.[0],
      notifyOnChangeProps: "tracked",
      keepPreviousData: true,
    },
  );

  return {
    isSupported: Boolean(isSupported),
    isLoading: isSupportedIsLoading || metadataIsLoading || firstRunIsLoading,
    metadata,
    firstRun,
  };
};
