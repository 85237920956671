import { FC, useMemo, useState } from "react";

import {
  Button,
  Column,
  Dialog,
  ExternalLinkIcon,
  InformationIcon,
  Row,
  SearchInput,
  Tooltip,
} from "@hightouchio/ui";
import isEmpty from "lodash/isEmpty";
import sortBy from "lodash/sortBy";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { Labels } from "src/components/labels/labels";
import { Query } from "src/components/models/query";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import {
  SegmentsBoolExp,
  SegmentsOrderBy,
  useIdrModelOptionsWithQueryFieldsQuery,
} from "src/graphql";
import {
  ColumnType,
  ModelOptionWithQueryFields,
} from "src/pages/identity-resolution/types";
import { GraphVersion } from "src/pages/identity-resolution/utils";
import { QueryType } from "src/types/models";
import { Table, useTableConfig } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { openUrlInNewTab } from "src/utils/urls";
import { useNavigate } from "src/router";

enum SortKeys {
  Name = "name",
  UpdatedAt = "updated_at",
}

type ModelSelectProps = {
  sourceId: string | undefined;
  graphVersion: GraphVersion;
  modelsToOmit?: string[];
} & (
  | {
      isMultiSelect: true;
      selectedModels: string[];
      onSelectModel: (
        model: ModelOptionWithQueryFields | ModelOptionWithQueryFields[],
      ) => void;
    }
  | {
      isMultiSelect: false;
      selectedModels?: never;
      onSelectModel: (model: ModelOptionWithQueryFields) => void;
    }
);

export const ModelSelect: FC<ModelSelectProps> = ({
  isMultiSelect,
  modelsToOmit = [],
  graphVersion,
  sourceId,
  selectedModels,
  onSelectModel,
}) => {
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [previewModel, setPreviewModel] = useState<
    ModelOptionWithQueryFields | undefined
  >();

  const { onSort, orderBy } = useTableConfig<SegmentsOrderBy>({
    defaultSortKey: "updated_at",
    sortOptions: Object.values(SortKeys),
  });

  const hasuraFilters: SegmentsBoolExp = useMemo(() => {
    const filter: SegmentsBoolExp = {
      connection_id: { _eq: sourceId },
      is_schema: { _eq: false },
      visual_query_parent_id: { _is_null: true },
    };

    if (modelsToOmit.length > 0) {
      filter["id"] = { _nin: modelsToOmit };
    }

    if (search) {
      return {
        ...filter,
        name: {
          _ilike: `%${search}%`,
        },
      };
    }
    return filter;
  }, [search, sourceId]);

  const modelOptionsQuery = useIdrModelOptionsWithQueryFieldsQuery(
    { filters: hasuraFilters, orderBy },
    {
      enabled: Boolean(sourceId),
      select: (data) => {
        if (graphVersion !== GraphVersion.V2) {
          return (
            data.segments.map((segment) => ({ ...segment, disabled: false })) ??
            []
          );
        }

        // Version 2 graphs must have a date or timestamp column
        // Disable models that don't have one
        const formattedData = (data.segments ?? []).map((segment) => ({
          ...segment,
          disabled: segment.syncable_columns.every(
            (column) =>
              ![ColumnType.Date, ColumnType.Timestamp].includes(
                column.type as ColumnType,
              ),
          ),
        }));

        return sortBy(formattedData, "disabled");
      },
    },
  );

  const selectModels = (value: string | string[]) => {
    if (isMultiSelect) {
      if (Array.isArray(value)) {
        const modelsById = modelOptionsQuery.data?.reduce(
          (acc, model) => {
            acc[model.id] = model;
            return acc;
          },
          {} as Record<string, ModelOptionWithQueryFields>,
        );

        onSelectModel(
          value
            .map((id) => modelsById?.[id])
            .filter((model): model is ModelOptionWithQueryFields => !!model),
        );
        return;
      }

      const model = modelOptionsQuery.data?.find(({ id }) => id === value);
      if (model) {
        onSelectModel(model);
      }
    }
  };

  return (
    <Column gap={4}>
      <SearchInput
        width="100%"
        autoFocus
        placeholder="Search by name..."
        value={search}
        size="lg"
        onChange={(event) => setSearch(event.target.value)}
      />

      <Table
        data={modelOptionsQuery.data}
        loading={modelOptionsQuery.isLoading}
        disabled={(row) => row.disabled}
        placeholder={{
          title: "No models",
          body:
            search.length > 0
              ? "No models found, please try again."
              : "This source has no models that may be used for identity resolution. Please add one and try again.",
          button: (
            <Button variant="primary" onClick={() => navigate("/models/new")}>
              Add model
            </Button>
          ),
          error: "Models failed to load, please try again.",
        }}
        selectedRows={isMultiSelect ? selectedModels : undefined}
        onRowClick={onSelectModel}
        onSelect={isMultiSelect ? selectModels : undefined}
        columns={[
          {
            name: "Name",
            sortDirection: orderBy?.name,
            onClick: () => onSort(SortKeys.Name),
            cell: (model) => (
              <Tooltip
                message="This model has no date or timestamp columns"
                isDisabled={!model.disabled}
              >
                <Row align="center" gap={1}>
                  <Row align="center" gap={2} overflow="hidden">
                    <IntegrationIcon
                      name={model.connection?.definition?.name}
                      src={model.connection?.definition?.icon}
                    />
                    <TextWithTooltip fontWeight="medium">
                      {model.name ?? "Private source"}
                    </TextWithTooltip>
                  </Row>

                  {model.disabled && <InformationIcon mt={0.5} />}
                </Row>
              </Tooltip>
            ),
          },
          {
            name: "Labels",
            max: ".5fr",
            cell: ({ tags }) => {
              if (isEmpty(tags)) {
                return "--";
              }
              return <Labels labels={tags} zIndex={undefined} />;
            },
            breakpoint: "md",
          },
          {
            ...LastUpdatedColumn,
            max: "max-content",
            breakpoint: "md",
            sortDirection: orderBy?.updated_at,
            onClick: () => onSort(SortKeys.UpdatedAt),
          },
          {
            name: "",
            max: "max-content",
            cellSx: {
              opacity: "1 !important",
            },
            cell: (model) =>
              model.disabled ? (
                <Button
                  directionIcon={ExternalLinkIcon}
                  size="sm"
                  onClick={() => openUrlInNewTab(`/models/${model.id}`)}
                >
                  View model
                </Button>
              ) : model.query_type !== QueryType.Visual ? (
                <Button
                  size="sm"
                  onClick={(event) => {
                    event.stopPropagation();
                    setPreviewModel(model);
                  }}
                >
                  View query
                </Button>
              ) : null,
          },
        ]}
      />
      <Dialog
        isOpen={Boolean(previewModel)}
        variant="info"
        width="xl"
        title={previewModel?.name ?? "Query"}
        actions={
          <Button onClick={() => setPreviewModel(undefined)}>Close</Button>
        }
        onClose={() => setPreviewModel(undefined)}
      >
        <Query
          model={previewModel ?? null}
          source={previewModel?.connection ?? null}
        />
      </Dialog>
    </Column>
  );
};
