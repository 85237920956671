import { forwardRef, ReactNode } from "react";

import { SectionHeading, BoxProps, Column, Row } from "@hightouchio/ui";

export type Props = {
  title?: string;
  header?: ReactNode;
  footer?: ReactNode;
  sx?: BoxProps["sx"];
  bodySx?: BoxProps["sx"];
  children?: ReactNode;
  divider?: boolean;
};

export const Section = forwardRef<HTMLDivElement, Props>(
  ({ title, header, footer, children, sx = {}, bodySx, divider }, ref) => (
    <Column
      ref={ref}
      sx={{
        pb: divider ? 10 : 0,
        borderBottom: divider ? "1px" : undefined,
        borderColor: "base.border",
        ...sx,
      }}
    >
      {(header || title) && (
        <Row alignItems="center" mb={6}>
          {header ? header : <SectionHeading>{title}</SectionHeading>}
        </Row>
      )}
      <Column
        sx={{
          flexGrow: 1,
          ...bodySx,
        }}
      >
        {children}
      </Column>
      {footer && (
        <Row gap={3} mt={6}>
          {footer}
        </Row>
      )}
    </Column>
  ),
);

Section.displayName = "Section";
