import {
  Box,
  Menu,
  MenuButton,
  MenuDivider,
  MenuIconButton,
  MenuItem,
  MenuList,
  PlusIcon,
  Spinner,
  Tooltip,
} from "@hightouchio/ui";
import { FC, useState } from "react";
import { createSearchParams, useNavigate } from "src/router";
import { ChannelDefinition, channelName } from "./channel-definitions";

export interface ResourceQuickSubscribeMenuProps {
  availableDestinationChannels: {
    channel_type: string;
    config: any;
    id: string;
  }[];
  onSelectChannel: (params: { channelId: string }) => Promise<unknown>;
  resourceType: string;
  resourceId: string;
  fullSize?: boolean;
}

export const ResourceQuickSubscribeMenu: FC<
  ResourceQuickSubscribeMenuProps
> = ({
  availableDestinationChannels,
  onSelectChannel,
  resourceId,
  resourceType,
  fullSize,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  return (
    <Tooltip message="Assign recipient">
      {isLoading ? (
        <Spinner size="sm" />
      ) : (
        <Menu>
          {fullSize ? (
            <MenuButton>Assign recipient</MenuButton>
          ) : (
            <MenuIconButton
              aria-label="add-resource-alert-recipient"
              icon={PlusIcon}
              variant="secondary"
            />
          )}
          <Box as={MenuList} sx={{ svg: { width: "18px", height: "18px" } }}>
            {availableDestinationChannels?.map((channel) => {
              const definition = ChannelDefinition[channel.channel_type];
              if (!definition) {
                return null;
              }
              return (
                <MenuItem
                  key={channel.id}
                  icon={definition.icon}
                  onClick={async () => {
                    setIsLoading(true);
                    await onSelectChannel({ channelId: channel.id });
                    setIsLoading(false);
                  }}
                >
                  {channelName(channel)}
                </MenuItem>
              );
            })}
            {availableDestinationChannels?.length ? <MenuDivider /> : null}
            <MenuItem
              icon={PlusIcon}
              onClick={() => {
                navigate({
                  pathname: "/alerting/recipients/new",
                  search: createSearchParams({
                    resourceType,
                    resourceId,
                  }).toString(),
                });
              }}
            >
              Create a new recipient
            </MenuItem>
          </Box>
        </Menu>
      )}
    </Tooltip>
  );
};
