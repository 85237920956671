import {
  Text,
  Column,
  DrawerBody,
  Row,
  SectionHeading,
  DrawerFooter,
} from "@hightouchio/ui";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useCollectionQuery, useUpdateCollectionMutation } from "src/graphql";
import {
  ConditionGroup,
  filterSchema,
} from "src/pages/decision-engines/collections/collection/components/filter";
import { useOutletContext, useParams } from "src/router";
import { OutletContext } from "src/pages/decision-engines";
import { Form, FormActions, useHightouchForm } from "src/components/form";

const schema = yup.object().shape({
  filter: filterSchema,
});

export const CollectionEligibility = () => {
  const { engine } = useOutletContext<OutletContext>();
  const { collectionId } = useParams<{ collectionId: string }>();
  const { data: collection } = useCollectionQuery(
    {
      id: collectionId ?? "",
    },
    {
      select: (data) => data.decision_engine_collections_by_pk,
      suspense: true,
    },
  );

  const updateCollectionMutation = useUpdateCollectionMutation();

  const form = useHightouchForm({
    resolver: yupResolver(schema),
    onSubmit: async (data) => {
      await updateCollectionMutation.mutateAsync({
        catalogCollectionId: collection!.collection.id,
        config: collection!.config,
        filter: data.filter,
      });
    },
    values: {
      filter: collection!.collection.filter,
    },
  });

  return (
    <Form form={form}>
      <DrawerBody>
        <Column gap={6}>
          <Row>
            <Column>
              <SectionHeading>Eligibility</SectionHeading>
              <Text>
                Determine which items in this collection are eligible for which
                users
              </Text>
            </Column>
          </Row>
          <ConditionGroup
            leftModelId={collection!.collection.catalog.model.id}
            leftTable="items"
            rightModelId={engine.segment.id}
            rightTable="users"
          />
        </Column>
      </DrawerBody>
      <DrawerFooter>
        <FormActions />
      </DrawerFooter>
    </Form>
  );
};
