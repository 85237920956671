import {
  type FormkitBoolean,
  type FormkitModifier,
  type FormkitNode,
  ModifierType,
  NodeType,
  BinaryBooleanOperator,
  UnaryBooleanOperator,
  type FormkitGraphQLReference,
  type GraphQLVariables,
  ReferenceType,
} from "../api";

import { Boolean } from "./booleans";
import { ContextReference, StateReference } from "./references";

export function ShowModifier(props: {
  condition: FormkitBoolean;
  children: FormkitNode[];
}): FormkitModifier {
  return {
    type: NodeType.Modifier,
    modifier: ModifierType.Show,
    ...props,
  };
}

// If using AsyncReference for an OAuth destination, be sure to add state to the handlers
// i.e. getAuthToken in anaplan/oauth.ts or getEloquaAccessToken in eloqua/oauth.ts
export function AsyncReference(props: {
  children: FormkitNode[];
  handler: FormkitGraphQLReference<GraphQLVariables>;
}): FormkitModifier {
  return {
    type: NodeType.Modifier,
    modifier: ModifierType.AsyncReference,
    ...props,
  };
}

// Reset children components when keys in state change
export function ResetModifier(props: {
  keys: string[];
  children: FormkitNode[];
}): FormkitModifier {
  return {
    type: NodeType.Modifier,
    modifier: ModifierType.Reset,
    ...props,
  };
}

/** When `key` changes in state, switch to that case and reset children */
export function switchOnStateKey(
  key: string,
  cases: { value: string | number | boolean; children: FormkitNode[] }[],
): FormkitModifier {
  // check cases for duplicate values
  const values = new Set<string | number | boolean>();
  for (const { value } of cases) {
    if (values.has(value)) {
      throw new Error(`Duplicate cases found with the same value: ${value}`);
    }
    values.add(value);
  }

  return ResetModifier({
    keys: [key],
    children: cases.map((caseItem) => {
      return showIfKeyEqualsValue({
        key,
        ...caseItem,
      });
    }),
  });
}

export function showIfKeyIsOneOf(props: {
  key: string;
  values: string[] | number[];
  children: FormkitNode[];
}): FormkitNode {
  const { key, values, children } = props;
  const unique = new Set<string | number | boolean>();
  for (const value of values) {
    if (unique.has(value)) {
      throw new Error(`Duplicate cases found with the same value: ${value}`);
    }
    unique.add(value);
  }
  return ResetModifier({
    keys: [key],
    children: values.map((value) => {
      return showIfKeyEqualsValue({
        key,
        value,
        children,
      });
    }),
  });
}

export function showIfKeyIsNotOneOf(props: {
  key: string;
  values: string[] | number[];
  children: FormkitNode[];
}): FormkitNode {
  const { key, values, children } = props;
  return ResetModifier({
    keys: [key],
    children: [
      ShowModifier({
        condition: Boolean({
          function: "arrayNotIncludes",
          variables: {
            value: StateReference({ key }),
            array: values,
          },
        }),
        children,
      }),
    ],
  });
}

export function showIfKeyEqualsValue(props: {
  key: string;
  value: string | number | boolean;
  children: FormkitNode[];
}) {
  const { key, value, children } = props;
  return ResetModifier({
    keys: [key],
    children: [
      ShowModifier({
        condition: Boolean({
          leftOperand: StateReference({ key }),
          operator: BinaryBooleanOperator.Equals,
          rightOperand: value,
        }),
        children: children,
      }),
    ],
  });
}

export function showIfKeyNotEqualsValue(props: {
  key: string;
  value: string | number | boolean;
  children: FormkitNode[];
}) {
  const { key, value, children } = props;
  return ResetModifier({
    keys: [key],
    children: [
      ShowModifier({
        condition: Boolean({
          leftOperand: StateReference({ key }),
          operator: BinaryBooleanOperator.NotEquals,
          rightOperand: value,
        }),
        children: children,
      }),
    ],
  });
}

type ShowIfProps = {
  key: string;
  children: FormkitNode[];
  /**
   * @default `ReferenceType.State`
   */
  referenceType?: ReferenceType.State | ReferenceType.Context;
};

export function showIfKeyIsTruthy(props: ShowIfProps): FormkitModifier {
  const { key, children, referenceType } = props;
  return ResetModifier({
    keys: [key],
    children: [
      ShowModifier({
        condition: Boolean({
          operand:
            referenceType === ReferenceType.Context
              ? ContextReference({ key })
              : StateReference({ key }),
        }),
        children,
      }),
    ],
  });
}

export function showIfKeyIsFalsey(props: ShowIfProps): FormkitNode {
  const { key, children, referenceType } = props;
  return ResetModifier({
    keys: [key],
    children: [
      ShowModifier({
        condition: Boolean({
          operand:
            referenceType === ReferenceType.Context
              ? ContextReference({ key })
              : StateReference({ key }),
          operator: UnaryBooleanOperator.Not,
        }),
        children,
      }),
    ],
  });
}
