import { FC } from "react";
import { Alert } from "@hightouchio/ui";
import { LinkButton } from "src/router";
import { useEntitlements } from "src/hooks/use-entitlement";

export const OverageContentAlert: FC = () => {
  const { data: entitlementsData } = useEntitlements(true);
  const overageText = entitlementsData.overage?.destinationOverageText; // @TODO once more overages are added, determine the text based on the active overage.

  return (
    <Alert
      variant="inline"
      type="upsell"
      title="Workspace in overage"
      message={overageText}
      actions={
        <LinkButton href="/settings/billing">Upgrade workspace</LinkButton>
      }
    />
  );
};
