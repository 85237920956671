import { useMemo } from "react";

import {
  Column,
  FormField,
  GroupedCombobox,
  IconButton,
  RefreshIcon,
  Row,
  TextInput,
} from "@hightouchio/ui";
import * as Yup from "yup";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { Section } from "src/ui/section";
import { COMMON_SCHEMAS } from "src/utils/destinations";

import { FromIdField } from "src/components/destinations/from-id-field";
import { MappingsField } from "src/components/destinations/mappings-field";
import { ModeField } from "src/components/destinations/mode-field";
import { ObjectField } from "src/components/destinations/object-field";

export const veroValidationSchema = Yup.object().shape({
  type: Yup.string().required().default("objects"),
  object: Yup.string().required(),
  fromId: Yup.mixed().required(),
  fromEmail: Yup.mixed().when("object", {
    is: "contact",
    then: Yup.mixed().required(),
    otherwise: Yup.mixed().notRequired(),
  }),
  eventName: Yup.string().when("object", {
    is: "event",
    then: Yup.string().required(),
    otherwise: Yup.string().notRequired(),
  }),
  mappings: COMMON_SCHEMAS.mappings,
});

const OBJECTS = [
  { label: "User", value: "user" },
  { label: "Event", value: "event" },
];

const FIELDS = {
  user: [
    { label: "Email", value: "email" },
    { label: "Channels", value: "channels" },
  ],
};

export const VeroForm = () => {
  const {
    config,
    setConfig,
    hightouchColumns,
    reloadModel,
    loadingModel,
    errors,
  } = useDestinationForm();

  const modes =
    config?.object === "event"
      ? [{ label: "Insert", value: "insert" }]
      : [{ label: "Upsert", value: "upsert" }];

  const hightouchColumnGroups = useMemo(() => {
    return hightouchColumns.map((group) => ({
      ...group,
      options: group.options ?? [],
    }));
  }, [hightouchColumns]);

  return (
    <>
      <ObjectField
        options={OBJECTS}
        onChange={(object) => setConfig({ type: config?.type, object })}
      />

      {config?.object && (
        <>
          <ModeField options={modes} value={modes[0]?.value} />

          <Section>
            <Column gap={8}>
              <FromIdField fieldName="Vero User ID" />

              {config.object === "event" && (
                <FormField
                  error={errors?.fromEmail}
                  label="Which column contains the Email that the user belongs to?"
                >
                  <Row align="center" gap={2}>
                    <GroupedCombobox
                      isInvalid={Boolean(errors?.fromEmail)}
                      isLoading={loadingModel}
                      optionGroups={hightouchColumnGroups}
                      placeholder="Select a column..."
                      value={config?.fromEmail}
                      onChange={(val) => {
                        setConfig({ ...config, fromEmail: val });
                      }}
                    />
                    <IconButton
                      icon={RefreshIcon}
                      aria-label="Refresh columns"
                      isLoading={loadingModel}
                      onClick={reloadModel}
                    />
                  </Row>
                </FormField>
              )}

              {config.object === "event" && (
                <FormField
                  error={errors?.eventName}
                  label="What is the Vero event name?"
                >
                  <TextInput
                    value={config?.eventName}
                    placeholder="Enter event name..."
                    onChange={(e) => {
                      setConfig({
                        ...config,
                        eventName: e.target.value,
                      });
                    }}
                  />
                </FormField>
              )}
            </Column>
          </Section>

          {config.object === "user" && (
            <Section>
              <MappingsField options={FIELDS[config?.object]} />
            </Section>
          )}

          <Section>
            <MappingsField isCustom />
          </Section>
        </>
      )}
    </>
  );
};

export default { form: VeroForm, validation: veroValidationSchema };
