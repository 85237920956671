import { useFlags } from "launchdarkly-react-client-sdk";
import { useUser } from "src/contexts/user-context";
import { NavSection } from "src/components/layout/nav/nav-section";

import { Icon } from "src/components/layout/nav/icons/activation";
import { ActiveSectionProps } from "src/components/layout/nav/utils";
import { UseSectionHook } from "./types";

export const useActivateSection: UseSectionHook = () => {
  const { onboarding } = useUser();
  const { syncSequencesWorkspace } = useFlags();

  return {
    title: "Activation",
    isEnabled: true,
    links: [
      !onboarding && {
        href: "/home",
        label: "Overview",
      },
      {
        href: "/syncs",
        label: "Syncs",
        permission: { v1: { resource: "sync", grant: "read" } },
      },
      {
        href: "/models",
        label: "Models",
        permission: { v1: { resource: "model", grant: "read" } },
      },
      syncSequencesWorkspace && {
        href: "/sequences",
        label: "Sequences",
        permission: { v1: { resource: "sync", grant: "read" } },
      },
    ],
  };
};

export const Activate = (props: ActiveSectionProps) => {
  const section = useActivateSection();
  return <NavSection icon={Icon} {...section} {...props} />;
};
