import {
  type BinaryBooleanOperator,
  type BooleanFunction,
  type BooleanOperand,
  BooleanType,
  type FormkitBoolean,
  type UnaryBoolean,
  UnaryBooleanOperator,
} from "../api";

export function Boolean(props: {
  operand?: BooleanOperand | FormkitBoolean;
  operator?: UnaryBooleanOperator | BinaryBooleanOperator;
  leftOperand?: any;
  rightOperand?: any;

  // TODO improve typings
  function?: keyof BooleanFunction;
  variables?: Record<string, unknown>;
}): FormkitBoolean {
  // Function
  if (props.function) {
    return {
      type: BooleanType.Function,
      variables: props.variables || null,
      function: props.function,
    };
  }

  // Unary
  if (
    props.operand &&
    (typeof props.operand === "boolean" ||
      typeof props.operand === "string" ||
      (typeof props.operand === "object" &&
        props.operand.type !== BooleanType.Function))
  ) {
    const result: UnaryBoolean = {
      type: BooleanType.Unary,
      operand: props.operand,
    };
    if (props.operator) {
      result.operator = props.operator as UnaryBooleanOperator;
    }
    return result;
  }

  // Binary
  if (
    (props.leftOperand || props.leftOperand === false) &&
    (props.rightOperand || props.rightOperand === false)
  ) {
    return {
      type: BooleanType.Binary,
      leftOperand: props.leftOperand,
      rightOperand: props.rightOperand,
      operator: props.operator as BinaryBooleanOperator,
    };
  }

  throw new Error(`Invalid boolean props: ${props}`);
}

export const getUnaryBooleanValue = (
  unaryBoolean: boolean | UnaryBoolean,
): boolean => {
  let val: boolean;
  if (isUnaryBoolean(unaryBoolean)) {
    val = !!unaryBoolean.operand;
    if (unaryBoolean.operator === UnaryBooleanOperator.Not) {
      val = !val;
    }
  } else {
    val = !!unaryBoolean;
  }
  return val;
};

export const isUnaryBoolean = (o: unknown): o is UnaryBoolean => {
  return typeof o === "object" && o != null && o["type"] === BooleanType.Unary;
};
