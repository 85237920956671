import { Box } from "@hightouchio/ui";
import { FC } from "react";

export const Divider: FC = () => (
  <Box
    my={2}
    sx={{
      borderTop: "1px",
      borderColor: "base.border",
    }}
  />
);
