import { FC } from "react";

import {
  Box,
  Row,
  Column,
  Spinner,
  Alert,
  CodeSnippet,
  Text,
  ErrorIcon,
  CloseIcon,
  SuccessIcon,
} from "@hightouchio/ui";

import { Link } from "src/router";

import {
  ListSourceTestStepsQuery,
  SourceDefinition,
  TestSourceQuery,
  useCloudCredentialsV2Query,
} from "src/graphql";
import { Markdown } from "src/ui/markdown";

import { Context } from "../setup/constants";
import {
  DEFAULT_SUGGESTIONS,
  DEFAULT_TIME_ELAPSED_WARNING_TITLE,
  DEFAULT_TIME_ELAPSED_WARNING_MESSAGE,
  SUGGESTIONS,
  TIME_ELAPSED_WARNING_TITLE,
  TIME_ELAPSED_WARNING_MESSAGE,
} from "./constants";

const TestingState = () => (
  <>
    <Spinner size="sm" />
    <Text
      color="gray.400"
      fontWeight="semibold"
      textTransform="uppercase"
      size="sm"
      ml={2}
    >
      Testing...
    </Text>
  </>
);

const SuccessState = () => (
  <>
    <Box as={SuccessIcon} color="success.base" fontSize="18px" />
    <Text
      color="success.base"
      fontWeight="semibold"
      textTransform="uppercase"
      size="sm"
      ml={2}
    >
      Success
    </Text>
  </>
);

const ErrorState = () => (
  <>
    <Box as={ErrorIcon} color="danger.base" fontSize="18px" />
    <Text
      color="danger.base"
      fontWeight="semibold"
      textTransform="uppercase"
      size="sm"
      ml={2}
    >
      Failed
    </Text>
  </>
);

const SkippedState = () => (
  <>
    <Box as={CloseIcon} color="text.tertiary" fontSize="18px" />
    <Text
      color="text.tertiary"
      fontWeight="semibold"
      textTransform="uppercase"
      size="sm"
      ml={2}
    >
      Skipped
    </Text>
  </>
);

export type TestingProps = {
  plannerDatabase: string | undefined;
  sourceDefinition: SourceDefinition;
  config: Record<string, unknown> | undefined;
  credentialId: string | undefined;
  steps: ListSourceTestStepsQuery["listSourceTestSteps"] | undefined;
  results: Partial<TestSourceQuery["testSource"]> | undefined;
  timeElapsed: number | undefined;
  isSetup: boolean;
  onContinue?: () => void;
};

export const Testing: FC<Readonly<TestingProps>> = ({
  plannerDatabase,
  sourceDefinition,
  steps,
  results,
  config,
  credentialId,
  timeElapsed,
  isSetup,
  onContinue,
}) => {
  if (!sourceDefinition) {
    return null;
  }

  const { data } = useCloudCredentialsV2Query(
    { id: String(credentialId) },
    { enabled: Boolean(credentialId) },
  );

  const ctx: Context = {
    plannerDatabase,
    definitionName: sourceDefinition.name,
    configuration: config,
    credential: data?.getCloudCredentials?.[0],
  };

  const timeElapsedWarningTitle =
    TIME_ELAPSED_WARNING_TITLE[sourceDefinition.type] ||
    DEFAULT_TIME_ELAPSED_WARNING_TITLE;
  const timeElapsedWarningMessage =
    TIME_ELAPSED_WARNING_MESSAGE[sourceDefinition.type] ||
    DEFAULT_TIME_ELAPSED_WARNING_MESSAGE;

  return (
    <Column gap={4}>
      {Boolean(timeElapsed && timeElapsed > 60) && (
        <Alert
          mb={4}
          variant="inline"
          type="warning"
          title={timeElapsedWarningTitle(ctx)}
          message={
            <>
              {timeElapsedWarningMessage(ctx)}
              {onContinue && (
                <Text mt={2}>
                  If you're sure that your configuration settings are correct,
                  you can{" "}
                  <Box
                    display="inline-block"
                    onClick={() => {
                      onContinue();
                    }}
                  >
                    <Link href="">continue to the next step.</Link>
                  </Box>
                </Text>
              )}
            </>
          }
        />
      )}
      {steps?.map((s, idx) => {
        const result = results?.stepResults?.find((r) => s.id === r.id);
        const testRunning = result || results?.success !== undefined;
        const suggestion =
          SUGGESTIONS[sourceDefinition.type]?.[s.id] ||
          DEFAULT_SUGGESTIONS[s.id];
        return (
          <Row
            key={idx}
            sx={{
              alignItems: "start",
              ":not(:last-child)": {
                pb: 4,
                borderBottom: "1px solid",
                borderColor: "base.border",
              },
            }}
          >
            <Row sx={{ alignItems: "center", pr: 2, width: "150px" }}>
              {testRunning ? (
                result !== undefined ? (
                  result.success ? (
                    <SuccessState />
                  ) : (
                    <ErrorState />
                  )
                ) : (
                  <SkippedState />
                )
              ) : (
                <TestingState />
              )}
            </Row>
            <Column flex={1}>
              <Text>
                <Markdown>{s.name}</Markdown>
              </Text>
              {result?.success === false && (
                <>
                  <Alert
                    mt={4}
                    variant="inline"
                    type="error"
                    title={result?.error?.message ?? "Error"}
                    message={
                      result?.error?.cause ??
                      "An unexpected error has occurred."
                    }
                  />
                  {suggestion && (
                    <Column
                      borderTop="1px solid"
                      borderColor="base.border"
                      pt={4}
                      mt={4}
                      fontSize="sm"
                    >
                      <Text
                        textTransform="uppercase"
                        color="text.secondary"
                        mb={2}
                        size="sm"
                        fontWeight="semibold"
                      >
                        Recommended action
                      </Text>
                      <Text>
                        <Markdown>{suggestion.reason(ctx)}</Markdown>
                      </Text>
                      {Boolean(suggestion.code.length) &&
                        suggestion.code.map((block, idx) => (
                          <CodeSnippet
                            key={idx}
                            label={
                              suggestion.code.length > 1
                                ? block.title(ctx)
                                : undefined
                            }
                            code={block.code(ctx)}
                          />
                        ))}
                    </Column>
                  )}
                </>
              )}
            </Column>
          </Row>
        );
      })}
      {results?.success && (
        <Alert
          mt={4}
          variant="inline"
          type="success"
          title="Connected successfully!"
          message={`All tests passed.${
            isSetup
              ? ` Continue to finish setting up your ${sourceDefinition.name}
            source.`
              : ""
          }`}
        />
      )}
    </Column>
  );
};
