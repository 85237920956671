import { useNavigate, useParams } from "src/router";

import { SyncDraftPage } from "src/components/drafts/sync-draft";
import { Page } from "src/components/layout";
import { useDraftsQuery, useSyncQuery, DraftOperation } from "src/graphql";
import { PageSpinner } from "src/components/loading";

export const SyncDraft = () => {
  const { sync_id: id } = useParams<{ sync_id: string }>();
  const navigate = useNavigate();

  const { data: syncData, isLoading: syncLoading } = useSyncQuery(
    {
      id: id ?? "",
    },
    {
      enabled: Boolean(id),
    },
  );
  const sync = syncData?.syncs?.[0];
  const model = sync?.segment;
  const source = model?.connection;

  const { data: drafts } = useDraftsQuery(
    {
      resourceId: id?.toString() || "",
      resourceType: "sync",
      status: "pending",
    },
    {
      enabled: Boolean(id),
    },
  );
  const draft = drafts?.drafts?.[0];

  if (syncLoading) {
    return <PageSpinner />;
  }

  if (!draft) {
    navigate(`/syncs/${id}`);
    return null;
  }

  if (!sync || !source) {
    navigate("/syncs");
    return null;
  }

  const link = `/syncs/${sync.id}`;

  return (
    <Page
      crumbs={[
        { label: "All syncs", link: "/syncs" },
        {
          label: "Sync",
          link:
            draft.operation === DraftOperation.Create
              ? link
              : `${link}?editing=true`,
        },
      ]}
      sync={sync}
      title="Draft request - Syncs"
    >
      <SyncDraftPage
        draft={draft}
        scheduleType={sync?.schedule ? sync?.schedule : { type: "manual" }}
        sync={sync}
      />
    </Page>
  );
};
