import {
  EditableHeading,
  Menu,
  MenuActionsButton,
  MenuList,
  Row,
} from "@hightouchio/ui";
import { FC, useMemo } from "react";
import { Outlet, useNavigate, useOutletContext, useParams } from "src/router";

import { DetailPage } from "src/components/layout";
import { Warning } from "src/components/warning";
import {
  DecisionEngineFlowQuery,
  useDecisionEngineFlowQuery,
  useDeleteDecisionEngineFlowMutation,
  useUpdateDecisionEngineFlowMutation,
} from "src/graphql";

import { DeleteButton } from "src/components/delete-button";
import { FlowConfiguration } from "./configuration";
import { OutletContext } from "src/pages/decision-engines";

export const Loader = () => {
  const { flowId } = useParams<{ flowId: string }>();

  const { data } = useDecisionEngineFlowQuery(
    {
      id: flowId ?? "",
    },
    { select: (data) => data.decision_engine_flows_by_pk, suspense: true },
  );

  if (!data) {
    return <Warning title="Flow not found" />;
  }

  return <FlowContent flow={data} />;
};

export type Flow = NonNullable<
  DecisionEngineFlowQuery["decision_engine_flows_by_pk"]
>;

export type FlowContext = OutletContext & {
  flow: Flow;
};

export const FlowContent: FC<
  Readonly<{
    flow: Flow;
  }>
> = ({ flow }) => {
  const context = useOutletContext<OutletContext>();
  const navigate = useNavigate();
  const deleteMutation = useDeleteDecisionEngineFlowMutation();
  const update = useUpdateDecisionEngineFlowMutation();

  const flowContext = useMemo(() => ({ flow, ...context }), [flow, context]);

  return (
    <DetailPage
      hasBottomPadding
      bg="base.lightBackground"
      title={flow?.name ?? "Flow"}
      crumbs={[
        {
          label: "All flows",
          link: "/ai/flows",
        },
      ]}
      header={
        <Row justify="space-between" gap={4}>
          <EditableHeading
            value={flow?.name}
            onChange={(name) => {
              update.mutate({
                id: flow?.id,
                input: { name },
              });
            }}
          />
          <Menu>
            <MenuActionsButton />
            <MenuList>
              <DeleteButton
                variant="menu"
                label="flow"
                onDelete={() =>
                  deleteMutation.mutateAsync({
                    id: flow.id,
                  })
                }
                onSuccess={() => {
                  navigate("/ai/flows");
                }}
              />
            </MenuList>
          </Menu>
        </Row>
      }
    >
      <FlowConfiguration flow={flow} />
      <Outlet context={flowContext} />
    </DetailPage>
  );
};
