import { EmptyState, Combobox } from "@hightouchio/ui";
import { Controller } from "react-hook-form";

import { useOutletContext } from "src/router";
import { useEligbleOutcomeModelsQuery } from "src/graphql";
import { OutletContext } from "src/pages/decision-engines";

export const EventModelSelect = () => {
  const { engine } = useOutletContext<OutletContext>();

  const modelsQuery = useEligbleOutcomeModelsQuery(
    {
      parentModelId: engine.segment.id,
    },
    {
      select: (data) =>
        data.model_relationships.flatMap(({ to_model }) => to_model),
      suspense: true,
    },
  );

  if (!modelsQuery.data?.length) {
    return (
      <EmptyState
        title="No event models"
        message="Add an event model to build a decision engine outcome from."
      />
    );
  }

  return (
    <Controller
      name="model"
      render={({ field }) => (
        <Combobox
          value={field.value?.id}
          onChange={(value) => {
            field.onChange(
              modelsQuery.data?.find((model) => model.id === value),
            );
          }}
          optionValue={(option) => option.id}
          optionLabel={(option) => option.name}
          options={modelsQuery.data ?? []}
        />
      )}
    />
  );
};
