import { SplitIcon } from "@hightouchio/ui";

import { TileNode } from "./tile-node";
import { TileProps } from "./types";

export const FlowNode = (props: TileProps) => (
  <TileNode
    isTarget
    isSource
    colorScheme="electric"
    icon={<SplitIcon />}
    data={props.data}
  />
);
