// Note that this has some functional overlap with goals.ts, and the two should
// probably be merged eventually.

import {
  isColumnReference,
  type RawColumn,
  type RelatedColumn,
  type Column,
} from "./column";
import type { VisualQueryFilter } from "./filter";
import {
  AttributionBasis,
  AudienceAggregationType,
  type EventFilter,
  type Goal,
  PerUserAggregationType,
  PredefinedMetric,
  type UserDefinedMetricConfig,
  type SyntheticUserDefinedMetricConfig,
  SyntheticMetricType,
} from "./goals";
import * as yup from "yup";

function standardMetricDefinitionValidator() {
  return yup.object({
    parentModelId: yup.string().required(),
    aggregation: yup
      .string()
      .oneOf(Object.values(PerUserAggregationType))
      .required(),
    audienceAggregation: yup
      .string()
      .oneOf(Object.values(AudienceAggregationType))
      .required(),
    config: yup
      .object({
        eventModelId: yup.string().required(),
        relationshipId: yup.string().required(),
        filter: yup
          .object({
            // XXX: this is gonna be complicated to validate properly, since it
            // could be nested conditions. For now just check that it's an array
            // of objects.
            subconditions: yup.array(yup.object()),
          })
          .required(),
      })
      .required(),
    attributionWindow: yup
      .object({
        quantity: yup.number().integer().min(0).required(),
        unit: yup.string().oneOf(["day", "week", "month"]).required(),
        // Optional because there are existing metrics without a basis (defaults to "exit")
        basis: yup.string().oneOf(Object.values(AttributionBasis)),
      })
      .required(),
  });
}

function decisionEngineMetricValidator() {
  return yup.object({
    parentModelId: yup.string().required(),
    aggregation: yup
      .string()
      .oneOf(Object.values(PerUserAggregationType))
      .required(),
    audienceAggregation: yup
      .string()
      .oneOf(Object.values(AudienceAggregationType))
      .required(),
    flowId: yup.string().required(),
    metricType: yup
      .string()
      .oneOf(Object.values(DecisionEngineAnalyticsCampaignMetricType))
      .required(),
    config: yup
      .object()
      .when("metricType", {
        is: DecisionEngineAnalyticsCampaignMetricType.Interactions,
        then: yup.object({
          source: yup
            .string()
            .oneOf(Object.values(SyntheticMetricType))
            .required(),
          resourceId: yup.string().required(),
          filter: yup
            .object({
              subconditions: yup.array(yup.object()),
            })
            .required(),
        }),
        otherwise: yup.object({
          eventModelId: yup.string().required(),
          relationshipId: yup.string().required(),
          filter: yup
            .object({
              subconditions: yup.array(yup.object()),
            })
            .required(),
          interactionFilter: yup.object().when("metricType", {
            is: DecisionEngineAnalyticsCampaignMetricType.AttributedEvents,
            then: yup
              .object({
                subconditions: yup.array(yup.object()),
              })
              .required(),
            otherwise: yup.object().notRequired(),
          }),
        }),
      })
      .required(),
  });
}

function predefinedMetricDefinitionValidator() {
  return yup.object({
    parentModelId: yup.string().required(),
    predefinedMetric: yup
      .string()
      .oneOf([PredefinedMetric.AudienceSize])
      .required(),
  });
}

export function getAnalyticsMetricDefinitionValidator() {
  return yup.lazy((metricDefinition: Record<string, any>) => {
    return metricDefinition["predefinedMetric"]
      ? predefinedMetricDefinitionValidator()
      : metricDefinition["type"] &&
          metricDefinition["type"] === "decision_engine_flow"
        ? decisionEngineMetricValidator()
        : standardMetricDefinitionValidator();
  });
}

export function getAnalyticsCohortDefinitionValidator() {
  return yup.object({
    filter: yup
      .object({
        // XXX: note that this is a full audience filter condition, and will be
        // complicated to validate. We'll solve this eventually but for now just
        // validate that it's present.
        conditions: yup.array().required(),
        splitTestDefinition: yup
          .object({
            groupColumnName: yup.string().optional(),
            // XXX: didn't need the other fields for now so didn't include them,
            // but noting that here in case someone else comes along to use
            // this.
          })
          .optional(),
      })
      .required(),
    parentModelId: yup.string().required(),
  });
}

export function getAnalyticsFunnelStageValidator() {
  return yup.object({
    relationshipId: yup.string().required(),
    eventModelId: yup.string().required(),
    filter: yup
      .object({
        // XXX: note that this is an event filter condition, and will be
        // complicated to validate. We'll solve this eventually but for now just
        // validate that it's present.
        subconditions: yup.array().optional(),
      })
      .optional(),
  });
}

export enum AnalyticsFrequency {
  Daily = "daily",
  Weekly = "weekly",
  Monthly = "monthly",
  All = "all",
}

export type ColumnWithAlias = {
  column: Column | SyntheticColumn;
  alias: string;
};

export type AnalyticsCohortDefinition = {
  parentModelId: string;
  filter: Pick<VisualQueryFilter, "conditions" | "splitTestDefinition">;
  name?: string;
};

export type UserDefinedAnalyticsMetricDefinition = Pick<
  Goal,
  | "parentModelId"
  | "aggregation"
  | "audienceAggregation"
  | "attributionWindow"
  | "config"
  | "attributionMethods"
> & { config: UserDefinedMetricConfig };

export type PredefinedAnalyticsMetricDefinition = {
  parentModelId: string;
  predefinedMetric: string;
};

export type AnalyticsMetricDefinition =
  | UserDefinedAnalyticsMetricDefinition
  | PredefinedAnalyticsMetricDefinition
  | DecisionEngineAnalyticsMetricDefinition;

export enum DecisionEngineAnalyticsCampaignMetricType {
  Interactions = "interactions",
  AttributedEvents = "attributed_events",
  Incrementality = "incrementality",
}

export enum NormalizationType {
  None = "none",
  RatePerUser = "rate_per_user",
  RatePerInteraction = "rate_per_interaction",

  // The ratio should be 1 if this is the treatment group, since it's already normalized.
  // Otherwise, it's the experiment size / treatment size
  // However, if we don't have any users in the experiment,
  // we should use 0 to prevent divide by 0 errors.
  TreatmentSize = "treatment_size",

  // This normalizes each group to 100% by dividing the value by the total count of users in the group.
  // For example, if there are 100 users total with 1 conversion each and 5 users in the treatment group,
  // the normalized value would be 100 / 5 * 5 = 100.
  // This tells us "if the treatment group was 100%, how many conversions would we have gotten".
  HundredPercent = "hundred_percent",

  // Incremental purchases that wouldn’t have happened without marketing,
  // which we can calculate by subtracting the treatment groups' values from the control groups' values.
  // So, for example, if the treatment is 100 purchases, and the holdout is 80 purchases,
  // the absolute lift is 20. We don't have a line for the treatment group, so we filter it out.
  AbsoluteLift = "absolute_lift",

  // Marketing drives up purchases by what %. Same as above, except we divide
  // the absolute lift by the size of the group (note, not the treatment group).
  LiftPercent = "lift_percent",
}

// Attribution metrics are metrics that have been attributed
// to a decision engine interaction through the AID attribution table, as
// determined through the flow's specific attribution logic (e.g. last touch within a 14 day window).
export type DecisionEngineAttributionMetricsDefinition = {
  type: "decision_engine_flow";
  parentModelId: string;
  aggregation: PerUserAggregationType;
  audienceAggregation: AudienceAggregationType;
  flowId: string;
  metricType: DecisionEngineAnalyticsCampaignMetricType.AttributedEvents;
  config: {
    eventModelId: string;
    relationshipId: string;
    filter: EventFilter;
    normalization?: NormalizationType;
    column?: RawColumn | RelatedColumn;
  };
};

// Interactions metrics are metrics that measure the base metric of the interactions
// for a given decision engine flow.
// These require their own metric type because the metric query cannot be generated from
// a model ID, as the interactions table is _not_ in the schema & is not a model.
// We provide the flow ID so we can generate the query from that flow's interactions table.
export type DecisionEngineInteractionsMetricDefinition = {
  type: "decision_engine_flow";
  parentModelId: string;
  aggregation: PerUserAggregationType;
  audienceAggregation: AudienceAggregationType;
  flowId: string;
  metricType: DecisionEngineAnalyticsCampaignMetricType.Interactions;
  config: SyntheticUserDefinedMetricConfig;
};

// Incrementality metrics measure conversions between
// two different experiment groups, so the events don't have to have been attributed
// to an interaction, it's just measuring which group the user belongs to.
export type DecisionEngineIncrementalityMetricsDefinition = {
  type: "decision_engine_flow";
  parentModelId: string;
  aggregation: PerUserAggregationType;
  audienceAggregation: AudienceAggregationType;
  flowId: string;
  metricType: DecisionEngineAnalyticsCampaignMetricType.Incrementality;
  config: {
    eventModelId: string;
    relationshipId: string;
    filter: EventFilter;
    normalization?: NormalizationType;
    column?: RawColumn | RelatedColumn;
  };
};

export type DecisionEngineAnalyticsMetricDefinition =
  | DecisionEngineAttributionMetricsDefinition
  | DecisionEngineInteractionsMetricDefinition
  | DecisionEngineIncrementalityMetricsDefinition;

export function isDecisionEngineAnalyticsMetricsDefinition(
  metric: AnalyticsMetricDefinition,
): metric is DecisionEngineAnalyticsMetricDefinition {
  return metric && "type" in metric && metric.type === "decision_engine_flow";
}

export function isDecisionEngineInteractionsMetricDefinition(
  metric: AnalyticsMetricDefinition,
): metric is DecisionEngineInteractionsMetricDefinition {
  return (
    isDecisionEngineAnalyticsMetricsDefinition(metric) &&
    metric.metricType === DecisionEngineAnalyticsCampaignMetricType.Interactions
  );
}

export function isDecisionEngineAnalyticsAttributionMetricsDefinition(
  metric: AnalyticsMetricDefinition,
): metric is DecisionEngineAttributionMetricsDefinition {
  return (
    isDecisionEngineAnalyticsMetricsDefinition(metric) &&
    metric.metricType ===
      DecisionEngineAnalyticsCampaignMetricType.AttributedEvents
  );
}

export function isDecisionEngineIncrementalityMetricDefinition(
  metric: AnalyticsMetricDefinition,
): metric is DecisionEngineIncrementalityMetricsDefinition {
  return (
    isDecisionEngineAnalyticsMetricsDefinition(metric) &&
    metric.metricType ===
      DecisionEngineAnalyticsCampaignMetricType.Incrementality
  );
}

export enum DecisionEngineInteractionColumnNames {
  UserId = "primary_key",
  ReadyToSend = "ready_to_send",
  Experiment = "experiment",
}

export const SYNTHETIC_COLUMN_TYPES = [
  "decision_engine_interaction",
  "decision_engine_interaction_user_features",
  "decision_engine_interaction_action_features",
] as const;

export type DecisionEngineInteractionColumn = {
  type: (typeof SYNTHETIC_COLUMN_TYPES)[0];
  name: DecisionEngineInteractionColumnNames;
};
export type DecisionEngineInteractionUserFeaturesColumn = {
  type: (typeof SYNTHETIC_COLUMN_TYPES)[1];
  // User features are different for each decision engine flow,
  // so we can't enforce types here.
  name: string;
};

export type DecisionEngineInteractionActionFeaturesColumns = {
  type: (typeof SYNTHETIC_COLUMN_TYPES)[2];
  // Action features have some common names, but some are different for each
  // decision engine flow based on what AID is recommending.
  name:
    | "channel"
    | "day_of_week"
    | "time_of_day"
    | "frequency_arm"
    | "message"
    | "subject"
    | string;
};

export type SyntheticColumn =
  | DecisionEngineInteractionColumn
  | DecisionEngineInteractionUserFeaturesColumn
  | DecisionEngineInteractionActionFeaturesColumns;

export function isSyntheticColumn(column: any): column is SyntheticColumn {
  return (
    isColumnReference(column) &&
    "type" in column &&
    [
      "decision_engine_interaction",
      "decision_engine_interaction_user_features",
      "decision_engine_interaction_action_features",
    ].includes(column.type)
  );
}

export function isUserDefinedAnalyticsMetricDefinition(
  metric: AnalyticsMetricDefinition,
): metric is UserDefinedAnalyticsMetricDefinition {
  return (
    metric &&
    "config" in metric &&
    typeof metric.config === "object" &&
    "attributionWindow" in metric
  );
}

export function isPredefinedAnalyticsMetricDefinition(
  metric: AnalyticsMetricDefinition,
): metric is PredefinedAnalyticsMetricDefinition {
  if (!metric) return false;
  return (
    "predefinedMetric" in metric && typeof metric.predefinedMetric === "string"
  );
}

export type AnalyticsFunnelStageDefinition = {
  relationshipId: string;
  eventModelId: string;
  filter: EventFilter;
};

export type DateOrString = Date | string;

export type AnalyticsMetricDatapoint<TTime extends DateOrString = Date> = {
  timestamp: TTime;
  value: number;
};

export type AnalyticsGroupByKey = {
  column: Column | SyntheticColumn;
  value: string;
};

// XXX: Redis and SQS both serialize Dates as strings - this is a generic so we
// can change the date types depending on whether we're creating the types
// ourselves or reading from SQS/Redis, and make typescript tell us if we misuse
// the results.
export type AnalyticsMetricSeries<TTime extends DateOrString = Date> = {
  splitId?: string;
  groupBy: AnalyticsGroupByKey[];
  data: AnalyticsMetricDatapoint<TTime>[];
};

export type AnalyticsAudienceSizeBySplit = Record<
  string,
  {
    splitName: string | null;
    size: number;
  }
>;

export type AnalyticsAggregatedMetricValueBySplit = Record<
  string,
  {
    splitName: string | null;
    value: number;
  }
>;

export type AnalyticsMetricSummary = {
  timeWindow: {
    start: string; // ISO datetime string
    end: string; // ISO datetime string
  };
  data: AnalyticsSummaryStats[];
};

export type AnalyticsSummaryStats = {
  splitName: string | null;
  rawValue: number;
  value: number;
  size: number;
  isBaseline: boolean;
  isNormalized: boolean;
};

export type AnalyticsMetricFunnel = {
  // XXX: GraphQL (or at least Apollo) requires explicit nulls for optional
  // types, so just require it here to avoid the hastle of converting it later.
  splitId: string | null;
  groupBy: AnalyticsGroupByKey[];
  stages: {
    stage: AnalyticsFunnelStageDefinition;
    count: number;
  }[];
};
