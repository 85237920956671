import { FC, ReactNode, useEffect, useState } from "react";

import {
  Box,
  Button,
  ChakraModal,
  ChakraModalBody,
  ChakraModalContent,
  ChakraModalHeader,
  ChakraModalOverlay,
  CloseIcon,
  ConfirmationDialog,
  EditableText,
  IconButton,
  Paragraph,
  Row,
  Text,
  Tooltip,
} from "@hightouchio/ui";
import { Link } from "src/router";
import * as Sentry from "@sentry/react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "src/router";

import { useCreateSavedAnalyticsChartMutation } from "src/graphql";
import { ChartFormState, GraphType, Metric } from "src/pages/analytics/types";
import { getSavedChartData } from "src/pages/analytics/utils";

type CopilotModalProps = {
  chartName: string;
  children: ReactNode;
  isOpen: boolean;
  metrics: Metric[];
  shouldConfirmClose?: boolean;
  onClose: () => void;
};

export const CopilotModal: FC<CopilotModalProps> = ({
  chartName: initialChartName,
  children,
  metrics,
  isOpen,
  shouldConfirmClose = false,
  onClose,
}) => {
  const navigate = useNavigate();
  const form = useFormContext<ChartFormState>();

  const parentModelId = form.watch("parentModelId");

  const createSavedAnalyticsChartMutation =
    useCreateSavedAnalyticsChartMutation();

  const [chartName, setChartName] = useState(initialChartName);
  const [confirmingClose, setConfirmingClose] = useState(false);

  const saveChart = async () => {
    const values = form.getValues();

    try {
      const formattedData = getSavedChartData({
        cohorts: values.selectedAudiences ?? [],
        customDateRange: values.selectedDates.map((date) => new Date(date)),
        metrics,
        metricSelection: values.metricSelection,
        funnelSteps: [],
        parentModelId,
        timeValue: values.timeValue,
      });

      const result = await createSavedAnalyticsChartMutation.mutateAsync({
        input: {
          name: chartName,
          description: null,
          frequency: values.rollupFrequency,
          chart_type: values.graphType,
          lookback_window: formattedData.lookbackWindow,
          group_by: values.groupByColumns,
          // Graphql type labels this as a string, but it must be a number
          parent_model_id: parentModelId as unknown as string,
          goals: {
            data:
              values.graphType !== GraphType.Funnel
                ? formattedData.metrics
                : [],
          },
          cohorts: {
            data: formattedData.cohorts,
          },
        },
      });

      navigate(`/analytics/${result.insert_saved_analytics_charts_one?.id}`);
    } catch (error) {
      Sentry.captureException(error);

      throw error;
    }
  };

  const confirmCloseChart = () => {
    if (shouldConfirmClose) {
      setConfirmingClose(true);
    } else {
      onClose();
    }
  };

  const closeChart = () => {
    setConfirmingClose(false);
    onClose();
  };

  useEffect(() => {
    setChartName(initialChartName);
  }, [initialChartName]);

  return (
    <ChakraModal isCentered isOpen={isOpen} size="full" onClose={onClose}>
      <ChakraModalOverlay />

      <ChakraModalContent
        minHeight="calc(100dvh - 80px)"
        maxHeight="calc(100dvh - 80px)"
        maxWidth="calc(100vw - 80px)"
        borderRadius="md"
        p={0}
        my="auto"
      >
        <ChakraModalHeader mb={0} px={0}>
          <Row
            py={4}
            px={6}
            borderBottom="1px solid"
            borderColor="base.border"
            justify="space-between"
            w="100%"
          >
            <Row align="center" gap={2}>
              <Link href="/analytics">
                <Text color="text.secondary" size="lg">
                  New conversation
                </Text>
              </Link>
              <Text color="text.secondary" size="lg">
                /
              </Text>
              <Text fontWeight="medium">
                <EditableText
                  size="lg"
                  value={chartName}
                  onChange={setChartName}
                />
              </Text>
            </Row>
            <Row gap={4}>
              <Button
                isLoading={createSavedAnalyticsChartMutation.isLoading}
                variant="primary"
                onClick={saveChart}
              >
                Save chart
              </Button>
              <Box
                height="100%"
                borderLeft="1px solid"
                borderColor="base.border"
              />
              <Tooltip message="Close modal">
                <IconButton
                  aria-label="Close modal."
                  icon={CloseIcon}
                  onClick={confirmCloseChart}
                />
              </Tooltip>
            </Row>
          </Row>
        </ChakraModalHeader>
        <ChakraModalBody borderBottomRadius="md" my={0} p={0} overflowY="auto">
          {children}
        </ChakraModalBody>
      </ChakraModalContent>

      <ConfirmationDialog
        isOpen={confirmingClose}
        title="Are you sure?"
        confirmButtonText="Exit"
        cancelButtonText="Never mind"
        variant="danger"
        onClose={() => setConfirmingClose(false)}
        onConfirm={closeChart}
      >
        <Paragraph>Your unsaved changes will be lost.</Paragraph>
      </ConfirmationDialog>
    </ChakraModal>
  );
};
