import type {
  RawColumn,
  RelatedColumn,
  ColumnType,
  TransformedColumn,
} from "./column";
import type { ConditionType, PropertyCondition } from "./condition";

export type TraitDefinition = {
  name: string;
  type: TraitType;
  config: TraitConfig;
  relationshipId: string | null;
  modelId: string;
};

export enum TraitType {
  Count = "count",
  Sum = "sum",
  Average = "average",
  RawSql = "raw_sql",
  LeastFrequent = "least_frequent",
  MostFrequent = "most_frequent",
  First = "first",
  Last = "last",
  Formula = "formula",
}

export type TraitConfig =
  | CountTraitConfig
  | SumTraitConfig
  | AverageTraitConfig
  | CountDedupedTraitConfig
  | OrderDedupedTraitConfig
  | RawSqlTraitConfig
  | FormulaTraitConfig;

export type TraitCondition = {
  type: ConditionType.And | ConditionType.Or;
  conditions: PropertyCondition[];
};

export type CountTraitConfig = {
  column?: RawColumn | RelatedColumn;
  conditions?: TraitCondition[];
};

export type SumTraitConfig = {
  column: RawColumn | RelatedColumn;
  conditions?: TraitCondition[];
};

export type AverageTraitConfig = {
  column: RawColumn | RelatedColumn;
  conditions?: TraitCondition[];
};

// Used by MostFrquent and LeastFrequest trait types
export type CountDedupedTraitConfig = {
  toSelect: RawColumn | RelatedColumn;
  conditions?: TraitCondition[];
};

// Used by First and Last trait types
export type OrderDedupedTraitConfig = {
  orderBy: RawColumn | RelatedColumn;
  toSelect: RawColumn | RelatedColumn;
  conditions?: TraitCondition[];
};

export type RawSqlTraitConfig = {
  // The raw SQL for the aggregation. Columns must be referred to with `{{column "name"}}`.
  // For example:  `COUNT(DISTINCT {{column "product_id"}})`
  aggregation: string;

  // The type of the result of the trait. This is used to determine what
  // property operators are compatible with the trait.
  resultingType: ColumnType;

  // The raw SQL for the trait's default value (i.e. if the trait evaluate to NULL).
  // An empty string is treated as NULL.
  // Note that string literals must be quoted appropriately.
  defaultValue?: string;

  conditions?: TraitCondition[];
};

export type FormulaTraitConfig = {
  transformation: string;

  resultingType: ColumnType;

  // Empty string or undefined is treated as NULL
  defaultValue?: string;

  referencedColumns: {
    alias: string;
    column: RawColumn | RelatedColumn | TransformedColumn;
  }[];

  // Include this to make the typechecking consistent everywhere.
  // But set it to undefined because we cant't add filters to a formula trait.
  conditions?: undefined;
};

export type AggregationTraitConfig =
  | CountTraitConfig
  | SumTraitConfig
  | AverageTraitConfig;

export const isAggregationTraitConfig = (
  traitType: TraitType,
  _config: TraitConfig,
): _config is AggregationTraitConfig => {
  return (
    traitType === TraitType.Sum ||
    traitType === TraitType.Count ||
    traitType === TraitType.Average
  );
};

export const isCountDedupedTraitConfig = (
  traitType: TraitType,
  _config: TraitConfig,
): _config is CountDedupedTraitConfig => {
  return (
    traitType === TraitType.LeastFrequent ||
    traitType === TraitType.MostFrequent
  );
};

export const isOrderDedupedTraitConfig = (
  traitType: TraitType,
  _config: TraitConfig,
): _config is OrderDedupedTraitConfig => {
  return traitType === TraitType.First || traitType === TraitType.Last;
};

export const isFormulaTraitConfig = (
  traitType: TraitType,
  _config: TraitConfig,
): _config is FormulaTraitConfig => {
  return traitType === TraitType.Formula;
};
