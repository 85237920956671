import { useCallback } from "react";
import { useUser } from "src/contexts/user-context";
import {
  useModelCreationAuthorizationQuery,
  useAudienceCreationAuthorizationQuery,
} from "src/graphql";

export function useModelCreationPermission(): {
  sourceIds: string[];
  sourceFilter: (id: string | number) => boolean;
} {
  const { user } = useUser();
  const permissionsV2Enabled = user?.permissions_v2_enabled ?? false;
  const { data: authorizationRows, isLoading } =
    useModelCreationAuthorizationQuery(
      {},
      {
        select: (data) => data.user_group_model_creation_authorization ?? [],
        enabled: permissionsV2Enabled ?? false,
      },
    );

  const sourceFilter = useCallback(
    (id: string | number) => {
      if (!permissionsV2Enabled || isLoading || !authorizationRows) {
        return true;
      }

      return authorizationRows.some(
        (row) => String(row.source_id) === String(id),
      );
    },
    [authorizationRows, isLoading, permissionsV2Enabled],
  );

  return {
    sourceIds: authorizationRows?.map((row) => row.source_id) ?? [],
    sourceFilter,
  };
}

export function useAudienceCreationPermission(): {
  parentModelIds: string[];
  parentModelFilter: (id: string | number) => boolean;
} {
  const { user } = useUser();
  const permissionsV2Enabled = user?.permissions_v2_enabled ?? false;
  const { data: authorizationRows, isLoading } =
    useAudienceCreationAuthorizationQuery(
      {},
      {
        select: (data) => data.user_group_audience_creation_authorization ?? [],
        enabled: permissionsV2Enabled ?? false,
      },
    );

  const parentModelFilter = useCallback(
    (id: string | number) => {
      if (!permissionsV2Enabled || isLoading || !authorizationRows) {
        return true;
      }

      return authorizationRows.some(
        (row) => String(row.parent_model_id) === String(id),
      );
    },
    [authorizationRows, isLoading, permissionsV2Enabled],
  );

  return {
    parentModelIds: authorizationRows?.map((row) => row.parent_model_id) ?? [],
    parentModelFilter,
  };
}
