import { Code, Text, AudienceIcon, FunnelIcon } from "@hightouchio/ui";

import { ResourceActivityMapper } from "src/components/resource-activity/timeline";

import { parentModelActivityMappers } from "./parent-model-activity";

export const audienceActivityMappers: ResourceActivityMapper[] = [
  ...parentModelActivityMappers,
  {
    accessor: "visual_query_filter.splitTestDefinition",
    parser: (_, { parsedDiff }) => {
      if (parsedDiff.type !== "value") {
        return null;
      }
      const operation = {
        added: "enabled",
        removed: "disabled",
        updated: "updated",
      }[parsedDiff.operation];

      return {
        message: `${operation} audience splits`,
        icon: <FunnelIcon />,
      };
    },
  },
  {
    accessor: "visual_query_filter.conditions",
    parser: (_, { parsedDiff }) => {
      if (parsedDiff.type !== "array") {
        return null;
      }

      return {
        message: `updated audience conditions`,
        icon: <AudienceIcon />,
      };
    },
  },
  {
    accessor: "visual_query_filter.splitTestDefinition.splits",
    parser: (_, { parsedDiff }) => {
      if (parsedDiff.type !== "array") {
        return null;
      }

      return {
        message: `updated split definitions`,
        icon: <AudienceIcon />,
      };
    },
  },
  {
    accessor: "visual_query_filter.additionalColumns",
    parser: (_, { parsedDiff }) => {
      if (parsedDiff.type !== "array") {
        return null;
      }
      return {
        message: "updated trait enrichments",
        icon: <AudienceIcon />,
        changes: parsedDiff.array.map((change, idx) => {
          const { operation, value } = change;
          const operationMap = {
            added: "added",
            removed: "removed",
            updated: "updated",
          };

          return (
            <Text key={idx}>
              {operationMap[operation]} trait
              {"  "}
              {value?.alias ? <Code>{value?.alias}</Code> : "trait"}
            </Text>
          );
        }),
      };
    },
  },
];
