import { MonitorStatus } from "@hightouch/lib/resource-monitoring/types";
import { Box, Column, Row, Select, Text } from "@hightouchio/ui";
import { Controller, useFormContext } from "react-hook-form";
import { FieldError } from "src/components/field-error";

import { ResourceStatus } from "src/components/resource-alert-triggers/resource-status";

import { Term } from "src/components/term";
import { isPresent } from "src/types/utils";
import { cleanFormError } from "src/components/resource-alert-triggers/utils";
import { isNil } from "lodash";
import pluralize from "pluralize";
import { NumberInputField } from "./fields/number-input-field";

export const ModelSizeField = ({ name }) => {
  const { setValue, control, getFieldState } = useFormContext();

  const countError = getFieldState(`${name}.[0].count`).error;

  return (
    <Column>
      <Row gap={1} alignItems="center" flexWrap="wrap">
        <Controller
          name={`${name}.[0].sign`}
          control={control}
          render={({ field: { value } }) => (
            <Box width="120px">
              <Select
                value={value}
                size="sm"
                options={[
                  { label: "More than", value: "gt" },
                  { label: "Fewer than", value: "lt" },
                ]}
                onChange={(option) => {
                  setValue(
                    `${name}.[0]`,
                    {
                      sign: option,
                      count: option === "gt" ? 1e6 : 1,
                    },
                    { shouldDirty: true },
                  );
                }}
              />
            </Box>
          )}
        />
        <NumberInputField name={`${name}.[0].count`} />
        <Text>
          <Term message="Model size is the total number of queried rows when your sync runs. Model size is different from the number of sync operations.">
            queried rows
          </Term>{" "}
          during a sync run
        </Text>
      </Row>
      {countError?.message ? (
        <FieldError error={cleanFormError(countError?.message as string)} />
      ) : null}
    </Column>
  );
};

interface Props {
  condition: {
    error_value: { sign: "gt" | "lt"; count: number }[];
    warning_value: { sign: "gt" | "lt"; count: number }[];
  };
}

const withSign = (opts: { sign: "gt" | "lt"; count: number }): string => {
  const prefix = opts.sign === "gt" ? "More than" : "Fewer than";
  return `${prefix} ${Intl.NumberFormat("en-US").format(
    opts.count,
  )} queried ${pluralize("row", opts.count)}`;
};

export const ModelSizeMonitorCondition = ({ condition }: Props) => {
  return [
    !isNil(condition.error_value?.[0]) &&
    Array.isArray(condition.error_value) &&
    condition.error_value.length > 0 ? (
      <Text>
        <Text fontWeight="semibold">
          {withSign(condition.error_value?.[0])}
        </Text>{" "}
        in a given run sets sync status to{" "}
        <ResourceStatus status={MonitorStatus.Unhealthy} />{" "}
      </Text>
    ) : null,
    !isNil(condition.warning_value?.[0]) &&
    Array.isArray(condition.warning_value) &&
    condition.warning_value.length > 0 ? (
      <Text>
        <Text fontWeight="semibold">
          {withSign(condition.warning_value?.[0])}
        </Text>{" "}
        in a given run sets sync status to{" "}
        <ResourceStatus status={MonitorStatus.Unhealthy} />{" "}
      </Text>
    ) : null,
  ].filter(isPresent);
};
