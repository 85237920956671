import { createRoot } from "react-dom/client";
import { wrapCreateBrowserRouter } from "@sentry/react";
// eslint-disable-next-line no-restricted-imports
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "src/lib/sentry";
import "inter-ui/inter.css";
import { filterConsoleErrors } from "src/utils/logs";
import { routes } from "./components/router";
import { PageSpinner } from "./components/loading";
import { RootProviders } from "./components/app/providers";

const root = createRoot(document.getElementById("react")!);

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(routes);

filterConsoleErrors();

root.render(
  <RootProviders>
    <RouterProvider router={router} fallbackElement={<PageSpinner />} />
  </RootProviders>,
);
