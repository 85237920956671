import {
  Text,
  Column,
  Combobox,
  FormField,
  Row,
  IconButton,
  CloseIcon,
  Button,
  PlusIcon,
} from "@hightouchio/ui";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useOutletContext } from "src/router";
import { FlowMessageContext } from "..";
import uniq from "lodash/uniq";

export const Tags = () => {
  const { tagValuesForKeys } = useOutletContext<FlowMessageContext>();
  const { watch } = useFormContext();
  const tags = watch("tags");
  const { fields, append, remove } = useFieldArray({ name: "tags" });

  const selectedTagKeys = tags.map((tag) => tag.key);
  const keyOptions = Object.keys(tagValuesForKeys).filter(
    (key) => !selectedTagKeys.includes(key),
  );

  return (
    <Column gap={4} align="flex-start">
      <FormField
        isOptional
        label="Tags"
        description="Message tags provide the model with more context about a message. This lets the model transfer learnings across messages. For example, if certain messages are tagged with “Tone: Casual” or “Tone: Serious”, the model can determine which message tone each user prefers."
      >
        <Column gap={2} flex={1}>
          {fields.map(({ id }, index) => {
            const key = tags[index]?.key;
            const valueOptions = tagValuesForKeys[key] ?? [];
            return (
              <Row key={id} gap={2} flex={1} align="center">
                <Controller
                  name={`tags.${index}.key` as const}
                  render={({ field }) => (
                    <Combobox
                      supportsCreatableOptions
                      value={field.value}
                      options={
                        field.value
                          ? uniq([field.value, ...keyOptions])
                          : keyOptions
                      }
                      placeholder="Enter a key"
                      emptyOptionsMessage="Type to create a key"
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      onCreateOption={(value) => {
                        field.onChange(value);
                      }}
                      optionLabel={(o) => o}
                      optionValue={(o) => o}
                    />
                  )}
                />
                <Row>
                  <Text color="text.secondary">=</Text>
                </Row>
                <Controller
                  name={`tags.${index}.value` as const}
                  render={({ field }) => (
                    <Combobox
                      supportsCreatableOptions
                      emptyOptionsMessage="Type to create a value"
                      value={field.value}
                      options={
                        field.value
                          ? uniq([field.value, ...valueOptions])
                          : valueOptions
                      }
                      placeholder="Enter a value"
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      onCreateOption={(value) => {
                        field.onChange(value);
                      }}
                      optionLabel={(o) => o}
                      optionValue={(o) => o}
                    />
                  )}
                />
                <IconButton
                  aria-label="Delete tag"
                  icon={CloseIcon}
                  onClick={() => remove(index)}
                />
              </Row>
            );
          })}
        </Column>
      </FormField>
      <Button icon={PlusIcon} onClick={() => append({ key: "", value: "" })}>
        Add tag
      </Button>
    </Column>
  );
};
