import { FC } from "react";
import { useNavigate, useParams } from "src/router";

import {
  Box,
  CloseIcon,
  Column,
  Heading,
  IconButton,
  Row,
  Text,
} from "@hightouchio/ui";
import { Link } from "src/router";
import { useDestinationRuleQuery } from "src/graphql";

import { Drawer } from "src/components/drawer";

import { DestinationRuleForm } from "./form";
import { DESTINATION_RULES_BASE_PATH } from "./utils";
import { FormErrorProvider } from "src/contexts/form-error-context";

export const DestinationRule: FC = () => {
  const { destination_rule_id } = useParams();

  const { data, isLoading } = useDestinationRuleQuery(
    {
      id: destination_rule_id ?? "",
    },
    {
      enabled: Boolean(destination_rule_id),
      select: (data) => data.destination_rules_by_pk,
    },
  );

  const navigate = useNavigate();
  const onClose = () => navigate(DESTINATION_RULES_BASE_PATH);

  const header = data?.id ? (
    <Column gap={4}>
      <Row alignContent="center" gap={2}>
        <Box as="img" h={6} w={6} src={data.destination.definition.icon} />
        <Heading>
          {data.destination.name ?? data.destination.definition.name}
        </Heading>
      </Row>
      <Row alignContent="center" gap={2}>
        <Box
          as="img"
          h={5}
          w={5}
          src={data.parent_model.connection?.definition.icon}
        />
        <Text
          as={Link}
          href={`/schema-v2/view/query?source=${data.parent_model.connection?.id}&id=${data.parent_model.id}`}
        >
          {data.parent_model.name}
        </Text>
      </Row>
    </Column>
  ) : (
    <Heading size="md">New destination rule</Heading>
  );

  return (
    <Drawer closeOnEsc isLoading={isLoading} isOpen size="lg" onClose={onClose}>
      <Row
        alignItems="center"
        borderBottom="1px solid"
        borderColor="base.border"
        justifyContent="space-between"
        p={6}
      >
        {header}
        <IconButton aria-label="Close" icon={CloseIcon} onClick={onClose} />
      </Row>
      <FormErrorProvider>
        <DestinationRuleForm data={data} />
      </FormErrorProvider>
    </Drawer>
  );
};
