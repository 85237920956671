import { Text, Box } from "@hightouchio/ui";

import { Link } from "src/router";

export const Terms = ({ variant }: { variant: "dark" | "light" }) => (
  <Box
    sx={{
      a: {
        fontSize: "inherit",
        color: variant === "dark" ? "brand.accent" : "text.primary",
        ":hover": { color: variant === "dark" ? "#FFF" : "gray.700" },
        ":active": { color: variant === "dark" ? "#FFF" : "gray.800" },
      },
    }}
  >
    <Text size="sm" color={variant === "dark" ? "white" : "text.secondary"}>
      By using Hightouch, you agree to our{" "}
      <Link href={`${import.meta.env.VITE_WEBSITE_URL}/privacy-policy`}>
        privacy policy
      </Link>{" "}
      and{" "}
      <Link href={`${import.meta.env.VITE_WEBSITE_URL}/terms-of-service`}>
        terms of service
      </Link>
      .
    </Text>
  </Box>
);
