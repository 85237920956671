import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import merge from "lodash/merge";

import {
  IDRGraphWizardFormState,
  ModelState,
} from "src/pages/identity-resolution/types";

const ModelValidationSchema = yup.object<ModelState>().shape({
  type: yup.string().required(),
  mappings: yup
    .array()
    .of(
      yup.object().shape({
        column: yup.string().required("This is a required field"),
        identifier: yup.string().required("This is a required field"),
      }),
    )
    .min(1, "At least one identifier is required"),
}) as yup.Schema<ModelState>;

const ModelWithTimeStampValidationSchema = yup.object<ModelState>().shape({
  type: yup.string().required(),
  order_by: yup.string().required("A column is required"),
  mappings: yup
    .array()
    .of(
      yup.object().shape({
        column: yup.string().required("This is a required field"),
        identifier: yup.string().required("This is a required field"),
      }),
    )
    .min(1, "At least one identifier is required"),
}) as yup.Schema<ModelState>;

export const IDRv1ModelValidationSchema = yup.lazy(
  (data: ModelState): yup.Schema<ModelState> => {
    return data.type === "event"
      ? ModelWithTimeStampValidationSchema
      : ModelValidationSchema;
  },
);

export const IDRv1ModelStateSchema = yup.array().of(IDRv1ModelValidationSchema);
export const IDRv2ModelStateSchema = yup
  .array()
  .of(ModelWithTimeStampValidationSchema);

export const IDRv1MergeRuleSchema = yup.object({
  identifier: yup.string().required("This is a required field"),
  type: yup.string().oneOf(["and", "or"]),
  conditions: yup.array().of(
    yup.object({
      type: yup.string().oneOf(["and", "or"]),
      rules: yup.array().of(
        yup.object({
          identifier: yup.string().required("This is a required field"),
          operator: yup.string().required("This is a required field"),
          transformations: yup.array().of(yup.string()),
          // Bucketing not validated on frontend
        }),
      ),
    }),
  ),
});
export const IDRv2MergeRuleSchema = yup.object({
  identifier: yup.string().required("This is a required field"),
  transformations: yup.array().of(yup.string()),
});

const BlockRuleSchema = yup.object().shape({
  identifier: yup.string().required("This is a required field"),
  limit: yup.number().required("This is a required field"),
});

// TODO(samuel): add schema for resolution rules
const ResolutionRuleSchema = yup.mixed();

const blockAndResolutionRuleProperties = {
  block_rules: yup.array().of(BlockRuleSchema),
  resolution_rules: yup.array().of(ResolutionRuleSchema),
};

export const IDRv1IdentifieRulesSchema = yup.object({
  merge_rules: yup.array().of(IDRv1MergeRuleSchema),
  ...blockAndResolutionRuleProperties,
});
export const IDRv2IdentifieRulesSchema = yup.object({
  merge_rules: yup.array().of(IDRv2MergeRuleSchema),
  ...blockAndResolutionRuleProperties,
});

const configurationProperties = {
  output_table: yup
    .string()
    .matches(
      /^\w+$/,
      "Only alphanumeric characters and underscores are allowed",
    )
    .required("This is a required field"),
  schedule: yup.object({
    type: yup.string().required("Schedule type is required"),
  }),
};

export const IDRConfigurationFormSchema = yup.object(configurationProperties);

export const IdentityGraphSchema = yup.lazy(
  (data: IDRGraphWizardFormState): yup.Schema<IDRGraphWizardFormState> =>
    yup.object({
      version: yup.number().required("Version is required"),
      name: yup.string().required("This is a required field"),
      description: yup.string(),
      models:
        data.version === 2 ? IDRv2ModelStateSchema : IDRv1ModelStateSchema,
      merge_rules:
        data.version === 2
          ? yup.array().of(IDRv2MergeRuleSchema)
          : yup.array().of(IDRv1MergeRuleSchema),
      ...blockAndResolutionRuleProperties,
      ...configurationProperties,
    }) as yup.Schema<IDRGraphWizardFormState>,
);

const formatModelData = (data: ModelState): ModelState => ({
  ...data,
  mappings: data.mappings.filter((j) => j.column && j.identifier),
});

export const modelConfigValidationResolver = async (
  data: ModelState,
  isIDRv2: boolean,
  context,
  options,
) => {
  const filteredData = formatModelData(data);

  const mappingErrors: any = {};

  // Timestamp is always required in IDRv2
  const { values, errors } = await yupResolver(
    isIDRv2 ? ModelWithTimeStampValidationSchema : IDRv1ModelValidationSchema,
  )(filteredData, context, options);

  return {
    values: Object.keys(mappingErrors).length > 0 ? {} : values,
    errors: merge(errors, mappingErrors),
  };
};
