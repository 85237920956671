import { FC, ReactNode } from "react";

import { Row } from "@hightouchio/ui";

type Props = {
  children: ReactNode;
};

// This assumes that the Selects used in the Condition fields
// have a height of 32px
const FIELD_HEIGHT = "32px";

export const ConditionTextWrapper: FC<Readonly<Props>> = ({ children }) => (
  <Row height={FIELD_HEIGHT} alignItems="center" gap={1}>
    {children}
  </Row>
);
