import { Combobox, FormField } from "@hightouchio/ui";
import { Controller } from "react-hook-form";

import { Card } from "src/components/card";
import { useMinimalParentModelsQuery } from "src/graphql";

export const Targeting = () => {
  const modelsQuery = useMinimalParentModelsQuery(undefined, {
    select: (data) => data.segments,
  });

  return (
    <Card>
      <Controller
        name="segment_id"
        render={({ field }) => (
          <FormField
            label="Parent model"
            description="Define a parent model to base your flows off of"
          >
            <Combobox
              {...field}
              isLoading={modelsQuery.isLoading}
              optionValue={(option) => option.id}
              optionLabel={(option) => option.name}
              optionAccessory={(option) => ({
                type: "image",
                url: option.connection!.definition.icon,
              })}
              options={modelsQuery.data ?? []}
            />
          </FormField>
        )}
      />
    </Card>
  );
};
