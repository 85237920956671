import { Row } from "@hightouchio/ui";

import { useParams } from "src/router";
import { FullResourceLinkForm } from "src/components/deployments/linking";
import { SidebarForm } from "src/components/page";
import { useSourceQuery } from "src/graphql";

export const SourceLinking = () => {
  const { id } = useParams<{ id: string }>();

  const { data: source } = useSourceQuery(
    { id: String(id) },
    { select: (data) => data.connections_by_pk, suspense: true },
  );

  return (
    <Row justifyContent="space-between">
      <FullResourceLinkForm
        sourceResourceId={source!.id}
        resourceType="connections"
      />
      <SidebarForm name="source linking" docsUrl="" />
    </Row>
  );
};
