import { FC, PropsWithChildren, ReactNode, useState } from "react";

import { Box, Button, Column, Heading, Row, Tooltip } from "@hightouchio/ui";

import {
  VerticalWizard,
  VerticalWizardStep,
} from "src/components/wizard/vertical-wizard";
import { Watermark } from "src/partner/watermark";

import { FOOTER_HEIGHT } from "./utils";

const CONTAINER_MAX_WIDTH = "1200px";
const SIDEBAR_WIDTH = "280px";

export const PartnerWizardStickyHeader: FC<PropsWithChildren> = ({
  children,
}) => {
  return (
    <Row
      width="100%"
      maxW={CONTAINER_MAX_WIDTH}
      mx="auto"
      pos="sticky"
      top={0}
      align="center"
      bg="base.lightBackground"
      flexShrink={0}
      py={8}
      zIndex={1}
    >
      <Heading size="xl">{children}</Heading>
    </Row>
  );
};

export type PartnerWizardStep = {
  next?: ReactNode;
  tooltip?: string;
  isDisabled?: boolean;
  onNext?: () => Promise<unknown>;
} & VerticalWizardStep;

type Props = {
  step: number;
  steps: PartnerWizardStep[];
  onStepChange: (step: number) => void;
  onSubmit: () => Promise<unknown>;
};

export const PartnerWizard: FC<Readonly<Props>> = ({
  steps,
  step,
  onSubmit,
  onStepChange,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { next, tooltip, onNext, isDisabled } = steps[step]!;

  const isFirstStep = step === 0;
  const isLastStep = step === steps.length - 1;

  return (
    <Column width="100vw" height="100vh">
      <Column
        overflowY="auto"
        flex={1}
        maxWidth="100%"
        bg="base.lightBackground"
        px={4}
        sx={{ scrollbarGutter: "stable" }}
      >
        <VerticalWizard
          steps={steps}
          step={step}
          maxW={CONTAINER_MAX_WIDTH}
          mx="auto"
          gap={{ base: 8, md: 16, lg: 24, xl: 32 }}
          pb={12}
          contentStyles={{
            // Add zIndex for web screens so it sits on top of the heading
            zIndex: { base: undefined, lg: 1 },
          }}
        />
      </Column>

      <Row
        align="center"
        borderTop="1px"
        borderColor="base.border"
        height={FOOTER_HEIGHT}
        py={3}
        px={4}
      >
        <Row
          gap={{ base: 6, lg: 24, xl: 32 }}
          flexWrap={{ base: "wrap-reverse", md: "nowrap" }}
          justify="space-between"
          width="100%"
          maxW={CONTAINER_MAX_WIDTH}
          mx="auto"
        >
          <Watermark
            display="flex"
            alignItems="center"
            justifyContent={{ base: "center", md: "start" }}
            minW={{ base: "auto", lg: SIDEBAR_WIDTH }}
            w={{ base: "auto", lg: SIDEBAR_WIDTH }}
            py={0}
          />
          <Row
            gap={3}
            align="center"
            justify={{ base: "end", lg: "space-between" }}
            w={{ base: "auto", lg: "100%" }}
            ml="auto"
            sx={{ scrollbarGutter: "stable" }}
          >
            {!isFirstStep && (
              <Button
                size="lg"
                onClick={() => {
                  onStepChange(step - 1);
                }}
              >
                Back
              </Button>
            )}
            <Box ml="auto">
              {next ?? (
                <Tooltip isDisabled={!tooltip} message={tooltip ?? ""}>
                  <Button
                    size="lg"
                    variant="primary"
                    isDisabled={isDisabled}
                    isLoading={isLoading}
                    onClick={async () => {
                      setIsLoading(true);
                      if (isLastStep) {
                        await onSubmit();
                      } else if (onNext) {
                        await onNext();
                      }
                      setIsLoading(false);
                    }}
                  >
                    {isLastStep ? "Finish" : "Continue"}
                  </Button>
                </Tooltip>
              )}
            </Box>
          </Row>
        </Row>
      </Row>
    </Column>
  );
};
