import { FC } from "react";

import { Column, Combobox, FormField } from "@hightouchio/ui";
import { Controller } from "react-hook-form";

import { ColumnType } from "src/pages/identity-resolution/types";

export const OrderByField: FC<
  Readonly<{
    columns: {
      name: string;
      alias: string | null;
      description?: string | null;
      type: string;
    }[];
  }>
> = ({ columns }) => {
  const timestampColumns = columns.filter((column) =>
    [ColumnType.Date, ColumnType.Timestamp].includes(column.type as ColumnType),
  );

  return (
    <Column gap={4} width="100%">
      <Controller
        name="order_by"
        render={({ field: { ref, ...field }, fieldState: { error } }) => (
          <FormField
            label="Which column represents the timestamp when the event occurred?"
            error={error?.message}
          >
            <Combobox
              {...field}
              isInvalid={Boolean(error)}
              width="100%"
              placeholder="Select a column..."
              options={timestampColumns}
              optionLabel={(o) => o.alias ?? o.name}
              optionValue={(o) => o.name}
            />
          </FormField>
        )}
      />
    </Column>
  );
};
