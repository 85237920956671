import { FC, useMemo, useState } from "react";

import {
  Box,
  Button,
  ClipboardButton,
  Column,
  Dialog,
  Pill,
  Row,
  SectionHeading,
} from "@hightouchio/ui";
import { Link } from "src/router";
import { useOutletContext } from "src/router";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { SyncRunStatusBadge } from "src/components/syncs/sync-run-status-badge";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import {
  SequenceRunsQuery,
  SyncSequenceRunsOrderBy,
  useSequenceRunsQuery,
} from "src/graphql";
import { SequenceStatusBadge } from "src/pages/sequences/sequence-status-badge";
import { Pagination, Table, useTableConfig } from "src/ui/table";
import { formatDatetime } from "src/utils/time";

import { SequenceContext } from ".";

type SyncSequence = NonNullable<SequenceRunsQuery["sync_sequence_runs"]["0"]>;

export const SequenceRuns: FC = () => {
  const {
    sequence: { id },
  } = useOutletContext<SequenceContext>();

  const [selectedRun, setSelectedRun] = useState<SyncSequence | undefined>();

  const PAGE_SIZE = 10;

  const { limit, offset, page, setPage } =
    useTableConfig<SyncSequenceRunsOrderBy>({
      limit: PAGE_SIZE,
    });

  const { data, isLoading: loading } = useSequenceRunsQuery(
    { id, limit: limit + 1, offset },
    {
      select: (data) => data,
      refetchInterval: 5000,
      notifyOnChangeProps: "tracked",
      keepPreviousData: true,
    },
  );
  const count = data?.sync_sequence_runs_aggregate?.aggregate?.count ?? 0;
  const runs = useMemo(() => {
    if (data?.sync_sequence_runs?.length === PAGE_SIZE + 1) {
      return data.sync_sequence_runs?.slice(0, PAGE_SIZE);
    }
    return data?.sync_sequence_runs;
  }, [data?.sync_sequence_runs]);

  return (
    <>
      <SectionHeading>Sequence runs</SectionHeading>
      <Column>
        <Table
          columns={[
            {
              name: "Status",
              cell: ({ status }) => <SequenceStatusBadge status={status} />,
            },
            {
              name: "Started",
              cell: ({ created_at }) => formatDatetime(created_at),
            },
            {
              name: "Sync runs",
              cell: ({ requests }) => {
                if (requests?.length) {
                  const completed = requests.reduce(
                    (prev, curr) => prev + (curr.status === "done" ? 1 : 0),
                    0,
                  );
                  const failed = requests.reduce(
                    (prev, curr) => prev + (curr.status === "failed" ? 1 : 0),
                    0,
                  );
                  const pending = requests.reduce(
                    (prev, curr) => prev + (curr.status === "pending" ? 1 : 0),
                    0,
                  );
                  const running = requests.reduce(
                    (prev, curr) => prev + (curr.status === "running" ? 1 : 0),
                    0,
                  );
                  const cancelled = requests.reduce(
                    (prev, curr) =>
                      prev + (curr.status === "cancelled" ? 1 : 0),
                    0,
                  );
                  const entries: string[] = [];
                  if (completed) entries.push(`${completed} completed`);
                  if (failed) entries.push(`${failed} failed`);
                  if (running) entries.push(`${running} running`);
                  if (pending) entries.push(`${pending} pending`);
                  if (cancelled) entries.push(`${cancelled} cancelled`);
                  return entries.join(", ");
                }
                return "Pending...";
              },
            },
            {
              name: "Sequence Run Id",
              cell: ({ id }) => (
                <>
                  <TextWithTooltip>{id}</TextWithTooltip>
                  <Box onClick={(e) => e.stopPropagation()}>
                    <ClipboardButton text={id} />
                  </Box>
                </>
              ),
            },
          ]}
          data={runs}
          loading={loading}
          placeholder={{
            title: "No runs",
            body: "Set a schedule or manually run the sequence.",
            error: "Runs failed to load, please try again.",
          }}
          onRowClick={setSelectedRun}
        />

        <Row sx={{ justifyContent: "flex-end", width: "100%", mt: 4 }}>
          <Pagination
            count={count}
            label="runs"
            page={page}
            rowsPerPage={limit}
            setPage={setPage}
          />
        </Row>
      </Column>

      <Dialog
        isOpen={Boolean(selectedRun)}
        variant="form"
        width="xl"
        title="Sequence run"
        actions={
          <Button onClick={() => setSelectedRun(undefined)}>Close</Button>
        }
        onClose={() => setSelectedRun(undefined)}
      >
        {selectedRun?.requests?.map(({ sync, sync_request, status }, index) => {
          return (
            <>
              {index !== 0 && (
                <Box
                  sx={{ ml: 7, width: "2px", height: "32px", bg: "base.2" }}
                />
              )}
              <Box
                display="grid"
                key={id}
                gap={6}
                sx={{
                  p: 4,
                  bg: "white",
                  borderRadius: "md",
                  alignItems: "center",
                  gridTemplateColumns: "max-content 100px 1fr 1fr 1fr",
                }}
              >
                <Pill>{index + 1}</Pill>
                {sync_request ? (
                  <SyncRunStatusBadge request={sync_request} />
                ) : (
                  <SequenceStatusBadge status={status} />
                )}
                <TextWithTooltip>{sync?.segment?.name ?? "--"}</TextWithTooltip>
                <Row align="center" gap={2} overflow="hidden">
                  <IntegrationIcon
                    name={sync?.destination?.definition?.name}
                    src={sync?.destination?.definition?.icon}
                  />
                  <TextWithTooltip>
                    {sync?.destination?.name ?? "unknown"}
                  </TextWithTooltip>
                </Row>
                {sync_request && sync_request.finished_at && sync ? (
                  <>
                    <Link href={`/syncs/${sync.id}/runs/${sync_request.id}`}>
                      {formatDatetime(sync_request.finished_at)}
                    </Link>
                  </>
                ) : (
                  <Box />
                )}
              </Box>
            </>
          );
        })}
      </Dialog>
    </>
  );
};
