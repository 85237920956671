export const NAV_EXPANDED_WIDTH = 218;
export const NAV_COLLAPSED_WIDTH = 56;

export const NAV_WIDTHS_PER_BREAKPOINT = [
  `${NAV_COLLAPSED_WIDTH}px`,
  `${NAV_COLLAPSED_WIDTH}px`,
  `${NAV_COLLAPSED_WIDTH}px`,
  `${NAV_EXPANDED_WIDTH}px`,
];
export const NAV_HEIGHT_BREAKPOINT = "920px";

export const NAV_ITEM_SMALL_HEIGHT = 32;
export const NAV_ITEM_LARGE_HEIGHT = 40;

export const STICKY_FOOTER_HEIGHT = "72px";
