import { createContext, useContext } from "react";

type RulesFormContextType = {
  identifiers: string[];
  sourceType: string;
};

export const RulesFormContext = createContext<RulesFormContextType>({
  identifiers: [],
  sourceType: "",
});

export const useRulesFormContext = () => {
  return useContext(RulesFormContext);
};
