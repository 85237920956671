import { Suspense, useEffect, useState } from "react";

import { Column, Spinner } from "@hightouchio/ui";

import { Outlet, useSearchParams } from "src/router";
import { PartnerProvider } from "src/partner/context";
import { Config } from ".";
import { hidePylon } from "src/lib/pylon";

export const Layout = () => {
  const [config, setConfig] = useState<Config>();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const queryConfig = searchParams.get("config");
    if (typeof queryConfig === "string") {
      const json = JSON.parse(atob(queryConfig));
      setConfig(json);
    }
  }, [searchParams]);

  useEffect(() => {
    hidePylon();
  }, []);

  if (!config) {
    return null;
  }

  return (
    <PartnerProvider config={config}>
      <Column width="100%" minHeight="100vh">
        <Column flex={1} width="100%" minHeight={0}>
          <Suspense fallback={<Spinner size="lg" m="auto" />}>
            <Outlet />
          </Suspense>
        </Column>
      </Column>
    </PartnerProvider>
  );
};
