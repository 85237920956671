import { useMemo } from "react";

import { FolderViewType } from "src/components/folders/types";
import { flattenFolders, nestFolders } from "src/components/folders/utils";
import {
  FolderType,
  ListFoldersQuery,
  ListFoldersQueryVariables,
  useListFoldersQuery,
} from "src/graphql";
import { getQueryWithOpts } from "src/utils/query-with-opts";

const useFastFoldersQuery = getQueryWithOpts<
  ListFoldersQuery,
  ListFoldersQueryVariables
>(useListFoldersQuery, {
  useFastEndpoint: true,
});

export const useFolders = ({
  folderType,
  viewType,
}: {
  folderType: FolderType | undefined;
  viewType: FolderViewType;
}) => {
  const { data, refetch, isLoading } = useFastFoldersQuery({
    resourceType: viewType,
    folderType: folderType,
  });

  const state = useMemo(() => {
    const {
      folders,
      audienceCount,
      totalCount,
      modelCount,
      journeyCount,
      decisioningCount,
    } = data?.listFolders ?? {};
    const nestedFolders = nestFolders(folders ?? []);
    const flattenedFolders = flattenFolders(nestedFolders ?? []);

    return {
      flattenedFolders,
      nestedFolders,
      audienceCount,
      modelCount,
      totalCount,
      journeyCount,
      decisioningCount,
    };
  }, [data?.listFolders]);

  return {
    state,
    refetchFolders: refetch,
    loadingFolders: isLoading,
  };
};
