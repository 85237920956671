import { FC } from "react";

import { Column, FormField, TextInput } from "@hightouchio/ui";
import * as Yup from "yup";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { Section } from "src/ui/section";
import { COMMON_SCHEMAS } from "src/utils/destinations";

import { MappingsField } from "src/components/destinations/mappings-field";

export const validation = Yup.object().shape({
  path: Yup.string().required(),
  autoSyncColumns: Yup.boolean().notRequired().default(true),
  mappings: COMMON_SCHEMAS.mappings,
});

export const SfmcFileDropForm: FC = () => {
  const { config, setConfig, errors } = useDestinationForm();

  return (
    <>
      <Section>
        <FormField
          description={
            "'Import/' will be added to the beginning of this path. The parent \
            directory for the file must exist. You can use these timestamp \
            variables in the file name: YYYY, MM, DD, HH, mm (UTC). Ex: \
            '{YYYY}-{MM}-{DD}-result.csv'"
          }
          error={errors?.path}
          label="Which file in the Import directory would you like to sync data to?"
        >
          <TextInput
            placeholder="results.csv"
            value={config.path}
            width="100%"
            onChange={(e) => {
              setConfig({ ...config, path: e.target.value });
            }}
          />
        </FormField>
      </Section>
      <Section>
        <Column gap={8}>
          <MappingsField allEnabled />
        </Column>
      </Section>
    </>
  );
};

export default {
  form: SfmcFileDropForm,
  validation,
};
