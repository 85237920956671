import { FC } from "react";

import { Text, Box } from "@hightouchio/ui";
import { Link } from "src/router";

import * as analytics from "src/lib/analytics";
import { newPylonMessage } from "src/lib/pylon";

type Props = {
  category: string;
};

export const RequestDestination: FC<Readonly<Props>> = ({ category }) => {
  return (
    <Box pt={8}>
      <Text color="text.secondary" fontWeight="semibold">
        Don’t see what you’re looking for? Let us know{" "}
        <Box
          display="inline-block"
          onClick={() => {
            newPylonMessage(
              `Hi, I would like to request a new destination. I want to sync data to ____ in order to help the _____ team accomplish _____.`,
            );
            analytics.track("Destination Catalog Suggest Destination Clicked", {
              current_category: category,
            });
          }}
        >
          <Link href="">here.</Link>
        </Box>
      </Text>
    </Box>
  );
};
