import type { FormkitForm, FormkitReference, FormkitSection } from "..";
import {
  type ExtendedTypes,
  isExtendedTypes,
  toExtendedFieldTypes,
} from "../types";

export type OptionsOrReference = FormkitReference | Option[];

type ReferenceOption = {
  // Associated object type
  objectType?: string;
  // Associated object name/label (if different than `objectType`)
  objectLabel?: string;
};

export type Option = {
  label: string;
  value: boolean | string | number | object | undefined;
  description?: string;

  /** @deprecated use required prop in the `ExtendedType` instead */
  required?: boolean;

  type?: string | ExtendedTypes;

  // This does not support Yup validation due to serialization restrictions.
  // Instead just denote the validation requirements in a record.
  // Use a section element to change the dialog heading.
  // TODO: Yup support (requires new graphql handler)
  typeSpecs?: FormkitForm | FormkitSection;
} & ReferenceOption &
  NestedRadioGroupOption;

export type OptionField = {
  label: string;
  field: string;
  description?: string;
  type: ExtendedTypes;
};

export const isFormkitOption = (option: unknown): option is Option => {
  return option && typeof option === "object" && option["value"];
};

export const isFormkitOptions = (options: unknown): options is Option[] => {
  return Array.isArray(options) && options.every(isFormkitOption);
};

/**
 * Formkit `Option` that has been migrated to use formkit extended types.
 * This is the type that frontend code must expect when receiving `Option` from the backend.
 */
export type ExtendedOption = Omit<Option, "type" | "childrenOptions"> & {
  childrenOptions?: ExtendedOption[];

  extendedType?: ExtendedTypes;

  modelName?: string;

  // These are exclusive for Salesforce destination.
  // We probably wan't to remove this at some point but
  // need to be careful not to break anything ins salesforce destination.
  // See `packages/backend/destinations/salesforce/object/handlers.ts`
  referenceObjects?: { label: string; value: string }[];
  object?: { label: string; value: string };
};

export const isExtendedOption = (value: unknown): value is ExtendedOption => {
  if (!value || typeof value !== "object" || !value["value"]) return false;

  return (
    (value["extendedType"] === undefined ||
      isExtendedTypes(value["extendedType"])) &&
    (value["childrenOptions"] ?? []).every(isExtendedOption)
  );
};

const fromOptionToExtendedOption = ({
  type,
  childrenOptions,
  ...option
}: Option): ExtendedOption => {
  if (isFormkitOptions(childrenOptions)) {
    return {
      ...option,
      extendedType: option["extendedType"] || toExtendedFieldTypes(type),
      childrenOptions: childrenOptions.map(fromOptionToExtendedOption),
    };
  }

  return {
    ...option,
    extendedType: option["extendedType"] || toExtendedFieldTypes(type),
  };
};

const toExtendedOptionIfOption = <T>(
  maybeOption: T,
): T extends Option ? ExtendedOption : T => {
  if (isFormkitOption(maybeOption)) {
    return fromOptionToExtendedOption(maybeOption) as any;
  } else {
    return maybeOption as any;
  }
};

/**
 * - transform `Option` to `ExtendedOption`
 * - transform `Options[]` to `ExtendedOption[]`
 * Returns value T of non of the above is true.
 */
export const toExtendedOption = <T>(
  value: T,
): T extends Option[]
  ? ExtendedOption[]
  : T extends Option
    ? ExtendedOption
    : T => {
  if (Array.isArray(value)) return value.map(toExtendedOptionIfOption) as any;

  return toExtendedOptionIfOption(value) as any;
};

/**
 * Converts option back from `ExtendedOption` to `Option`.
 * @param option
 * @returns
 */
export const fromExtendedOptiontoOption = <
  T extends Option | ExtendedOption | undefined,
>(
  option: T,
): Exclude<T, ExtendedOption> => {
  // This is fine beacuse it should be infered back as `undefined`
  if (!option) return undefined as any;

  if (isExtendedOption(option)) {
    return {
      ...option,
      type: option.extendedType?.type,
      childrenOptions: option.childrenOptions?.map(fromExtendedOptiontoOption),
    } as unknown as Exclude<T, ExtendedOption>;
  }

  return option as Exclude<T, ExtendedOption>;
};

export type RadioGroupOption = {
  label: string;
  value: boolean | string | number;
  description: string | undefined;
};

type NestedRadioGroupOption = {
  childrenOptions?: Option[];
  disabled?: boolean;
};
