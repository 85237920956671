import { FC, ReactNode } from "react";

import {
  Column,
  Row,
  Box,
  ChakraPopover,
  ChakraPopoverBody,
  ChakraPopoverContent,
  ChakraPopoverTrigger,
  Portal,
  Text,
  LogoutIcon,
} from "@hightouchio/ui";

import { Link } from "src/router";

export const UserMenu: FC<
  Readonly<{
    children: ReactNode;
    user: { name: string; email: string } | null;
  }>
> = ({ children, user }) => {
  return (
    <ChakraPopover isLazy placement="bottom-end">
      <ChakraPopoverTrigger>{children}</ChakraPopoverTrigger>
      <Portal>
        <ChakraPopoverContent>
          <ChakraPopoverBody p={0}>
            <Column
              gap={2}
              width="100%"
              borderBottom="1px"
              borderColor="base.border"
              p={3}
            >
              <Row gap={3} justify="space-between" overflow="hidden">
                <Box overflow="hidden">
                  <Box
                    fontSize="lg"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                  >
                    {user?.name}
                  </Box>
                  <Box
                    color="gray.600"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                  >
                    {user?.email}
                  </Box>
                </Box>
              </Row>

              <Row align="center" gap={2}>
                <Link
                  href={`${import.meta.env.VITE_WEBSITE_URL}/terms-of-service`}
                >
                  Terms
                </Link>
                •
                <Link
                  href={`${import.meta.env.VITE_WEBSITE_URL}/privacy-policy`}
                >
                  Privacy
                </Link>
              </Row>
            </Column>
            <Row
              w="100%"
              p={3}
              gap={2}
              align="center"
              as="button"
              _hover={{ bg: "forest.100" }}
              onClick={() => {
                window.location.href = `${
                  import.meta.env.VITE_API_BASE_URL
                }/auth/logout`;
              }}
            >
              <Box as={LogoutIcon} fontSize="20px" />
              <Text fontWeight="medium">Log out of Hightouch</Text>
            </Row>
          </ChakraPopoverBody>
        </ChakraPopoverContent>
      </Portal>
    </ChakraPopover>
  );
};
