import {
  createContext,
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from "react";

import noop from "lodash/noop";
import partition from "lodash/partition";
import sortBy from "lodash/sortBy";

import { Form, useHightouchForm } from "src/components/form";
import { SaveNameAndDescriptionModal } from "src/components/modals/save-name-and-description-modal";
import {
  AssetModelColumn,
  CampaignAnalyticsDashboardData,
  useEvaluateCampaignAnalyticsDashboardQuery,
  useGetCampaignAnalyticsDashboardResultBackgroundQuery,
} from "src/graphql";
import { CampaignResultsLimit } from "src/pages/campaigns/constants";
import { SavedCampaignViewsDrawer } from "src/pages/campaigns/saved-views";
import {
  ConversionMetric,
  PredefinedMetric,
  RequiredAssetModelFields,
  SavedCampaignView,
} from "src/pages/campaigns/types";
import { convertColumnReferenceModelIdToString } from "src/pages/campaigns/utils";
import { useParams } from "src/router";
import {
  AttributionTimeWindow,
  ConditionType,
  FilterableColumn,
} from "src/types/visual";
import { useTableConfig } from "src/ui/table";

import { useSavedCampaignView } from "./saved-views";
import { getMetricsToMeasure } from "./utils";

type InputProps = {
  parentModelId?: string;
  assetModel: RequiredAssetModelFields;
  savedView: SavedCampaignView;
  isSavedViewLoading?: boolean;
  predefinedMetrics: PredefinedMetric[];
  conversionMetrics: ConversionMetric[];
};

type Result = {
  isArchived: boolean;
  isCampaignsPageLoading: boolean;
  isPolling: boolean;
  isSavedView: boolean;
  isSavedViewLoading: boolean;
  assetModelColumns: FilterableColumn[];
  assetModel: RequiredAssetModelFields;
  data: null | CampaignAnalyticsDashboardData;
  error: null | undefined | string;
  onPageChange: (page: number) => void;
  openCampaignViewModal: (() => Promise<void | undefined>) | (() => void);
  openSavedViewsDrawer: () => void;
  predefinedMetrics: PredefinedMetric[];
  conversionMetrics: ConversionMetric[];
};

export const CampaignsFilterContext = createContext<Result>({
  assetModelColumns: [],
  assetModel: {} as RequiredAssetModelFields,
  data: null,
  error: null,
  isArchived: false,
  isCampaignsPageLoading: false,
  isPolling: false,
  isSavedView: false,
  isSavedViewLoading: false,
  onPageChange: noop,
  openCampaignViewModal: noop,
  openSavedViewsDrawer: noop,
  predefinedMetrics: [],
  conversionMetrics: [],
});

export const CampaignFiltersContextProvider: FC<
  PropsWithChildren & InputProps
> = ({
  assetModel,
  children,
  savedView,
  isSavedViewLoading,
  conversionMetrics,
  parentModelId,
  predefinedMetrics,
}) => {
  const { savedViewId } = useParams<{
    savedViewId?: string;
  }>();

  const { page, setPage } = useTableConfig({ limit: CampaignResultsLimit });
  const [isPolling, setIsPolling] = useState(false);
  const [showSavedViews, setShowSavedViews] = useState(false);
  const [showSaveViewModal, setShowSaveViewModal] = useState(false);

  const [_primaryColumns, columns] = useMemo(() => {
    const standardColumns = assetModel?.model.filterable_audience_columns ?? [];

    return partition(
      standardColumns,
      (column) =>
        column.column_reference.name === assetModel?.primary_label_column ||
        column.column_reference.name === assetModel?.primary_key_column,
    );
  }, [assetModel]);

  const { initialValues, createCampaignView, updateCampaignView } =
    useSavedCampaignView({
      assetModel,
      columns,
      predefinedMetrics,
      conversionMetrics,
    });

  const form = useHightouchForm({
    onSubmit: savedViewId ? updateCampaignView : () => Promise.resolve(),
    values: initialValues,
  });

  const assetModelId = assetModel?.model_id;

  // Always fetch all columns + all metrics
  const formattedMetrics = useMemo(
    () => getMetricsToMeasure(predefinedMetrics, conversionMetrics),
    [conversionMetrics, predefinedMetrics],
  );
  const sortedColumns = sortBy(
    assetModel?.model.filterable_audience_columns ?? [],
    (column) => column.alias?.toLowerCase() ?? column.name.toLowerCase(),
  );
  const assetModelColumns: AssetModelColumn[] = useMemo(() => {
    return columns.map((column) =>
      convertColumnReferenceModelIdToString(column.column_reference),
    );
  }, [columns]);

  const queryEnabled =
    Boolean(parentModelId && assetModelId) && formattedMetrics.length > 0;

  const timeWindow = form.watch("timeWindow");
  const assetModelFilter = form.watch("assetModelFilters");
  const metricFilters = form.watch("metricFilters");
  const orderBy = form.watch("orderBy");

  const evaluateCampaignAnalyticsDashboardQuery =
    useEvaluateCampaignAnalyticsDashboardQuery(
      {
        parentModelId: parentModelId?.toString() ?? "",
        assetModelId: assetModelId?.toString() ?? "",
        timeWindow: timeWindow ?? AttributionTimeWindow.SevenDays,
        assetModelFilter:
          assetModelFilter.length > 0
            ? {
                type: ConditionType.And,
                conditions: assetModelFilter,
              }
            : undefined,
        metricAttributionFilter:
          metricFilters.length > 0
            ? {
                type: ConditionType.And,
                conditions: metricFilters,
              }
            : undefined,
        assetModelColumns,
        // always has to be at least one metric
        metrics: formattedMetrics,
        orderBy,
        // Starts at page 1
        paginationOptions: { page: page + 1, pageSize: CampaignResultsLimit },
      },
      {
        enabled: queryEnabled,
        select: (data) =>
          data.evaluateCampaignAnalyticsDashboardQuery?.backgroundJob?.jobId,
      },
    );

  const backgroundJobId = evaluateCampaignAnalyticsDashboardQuery.data;

  const getCampaignAnalyticsDashboardResultBackgroundQuery =
    useGetCampaignAnalyticsDashboardResultBackgroundQuery(
      { jobId: backgroundJobId ?? "" },
      {
        enabled: Boolean(isPolling && backgroundJobId),
        refetchInterval: 3000,
        select: (data) => data.getCampaignAnalyticsDashboardResultBackground,
      },
    );

  let result: CampaignAnalyticsDashboardData | null = null;
  let error: string | null = null;

  if (getCampaignAnalyticsDashboardResultBackgroundQuery.data?.result) {
    if (
      "assets" in getCampaignAnalyticsDashboardResultBackgroundQuery.data.result
    ) {
      result = getCampaignAnalyticsDashboardResultBackgroundQuery.data.result;
    } else if (
      "error" in getCampaignAnalyticsDashboardResultBackgroundQuery.data.result
    ) {
      error =
        getCampaignAnalyticsDashboardResultBackgroundQuery.data?.result.error;
    }
  }

  useEffect(() => {
    const shouldPoll = Boolean(backgroundJobId && !result && !error);

    setIsPolling(shouldPoll);
  }, [backgroundJobId, result, error]);

  return (
    <CampaignsFilterContext.Provider
      value={{
        assetModel,
        assetModelColumns: sortedColumns,
        isArchived: Boolean(savedView?.archived_at),
        isPolling:
          evaluateCampaignAnalyticsDashboardQuery.isLoading ||
          getCampaignAnalyticsDashboardResultBackgroundQuery.isLoading ||
          isPolling,
        isCampaignsPageLoading:
          evaluateCampaignAnalyticsDashboardQuery.isLoading,
        isSavedViewLoading: Boolean(isSavedViewLoading),
        data: result,
        error: error ?? evaluateCampaignAnalyticsDashboardQuery.error?.message,
        isSavedView: Boolean(savedViewId),
        openCampaignViewModal: () => {
          if (savedViewId) {
            return form.submit();
          }

          return setShowSaveViewModal(true);
        },
        openSavedViewsDrawer: () => setShowSavedViews(true),
        onPageChange: setPage,
        conversionMetrics,
        predefinedMetrics,
      }}
    >
      <Form form={form}>{children}</Form>

      <SaveNameAndDescriptionModal
        type="view"
        isOpen={showSaveViewModal}
        onClose={() => setShowSaveViewModal(false)}
        onSubmit={async (args) => {
          await createCampaignView({ ...args, form });
          setShowSaveViewModal(false);
        }}
      />

      <SavedCampaignViewsDrawer
        isOpen={showSavedViews}
        onClose={() => setShowSavedViews(false)}
      />
    </CampaignsFilterContext.Provider>
  );
};
