import { FC } from "react";

import { FormField } from "@hightouchio/ui";
import { upperCase } from "lodash";

import { SelectCredential } from "src/components/credentials";

type ProviderSectionProps = {
  provider: "gcp" | "aws" | "azure";
  credentialId?: any;
  setCredentialId?: any;
};

export const ProviderSection: FC<Readonly<ProviderSectionProps>> = ({
  provider,
  credentialId,
  setCredentialId,
}) => {
  return (
    <FormField label={`${upperCase(provider)} credentials`}>
      <SelectCredential
        provider={provider}
        value={credentialId}
        onChange={setCredentialId}
      />
    </FormField>
  );
};
