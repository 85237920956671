import {
  BigIntegerIcon,
  BooleanIcon,
  MeetingIcon,
  NullIcon,
  NumberIcon,
  QuestionIcon,
  TextStringIcon,
  TimeIcon,
} from "@hightouchio/ui";
import { AnalyticsColors } from "src/pages/analytics/constants";
import { ColumnType } from "src/types/visual";

export const NumericFontStyles = {
  fontFamily: "Inter",
  fontVariantNumeric: "lining-nums tabular-nums slashed-zero",
};

export const BreakdownChartViews = [
  { label: "Bar chart", value: "table" },
  { label: "Pie chart", value: "pie" },
];

export const HexColors = [
  {
    base: AnalyticsColors.Primary.primary,
    hover: AnalyticsColors.Primary.hover,
  },
  {
    base: AnalyticsColors.Secondary.primary,
    hover: AnalyticsColors.Secondary.hover,
  },
  {
    base: AnalyticsColors.Tertiary.primary,
    hover: AnalyticsColors.Tertiary.hover,
  },
  {
    base: AnalyticsColors.Fourth.primary,
    hover: AnalyticsColors.Fourth.hover,
  },
  {
    base: AnalyticsColors.Fifth.primary,
    hover: AnalyticsColors.Fifth.hover,
  },
  {
    base: AnalyticsColors.Sixth.primary,
    hover: AnalyticsColors.Sixth.hover,
  },
];

export const ALL_COLUMN_OPTIONS = [
  { label: "String", value: ColumnType.String, icon: TextStringIcon },
  { label: "Number", value: ColumnType.Number, icon: NumberIcon },
  { label: "Big Integer", value: ColumnType.BigInt, icon: BigIntegerIcon },
  { label: "Timestamp", value: ColumnType.Timestamp, icon: TimeIcon },
  { label: "Date", value: ColumnType.Date, icon: MeetingIcon },
  {
    label: "Boolean",
    value: ColumnType.Boolean,
    icon: BooleanIcon,
  },
  {
    label: "Unknown",
    value: ColumnType.Unknown,
    icon: QuestionIcon,
  },
  {
    label: "Null",
    value: ColumnType.Null,
    icon: NullIcon,
  },
];
