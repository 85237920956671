import { FC } from "react";
import { Navigate, useOutletContext } from "src/router";
import { Column, Row } from "@hightouchio/ui";
import { SidebarForm } from "src/components/page";
import { useUpdateEventSourceMutation } from "src/graphql";
import { getEventSourceForm, EventSourceFormProps } from "./form";
import { OutletContext } from ".";

export const Configuration: FC = () => {
  const { source, definition } = useOutletContext<OutletContext>();

  const SourceForm = getEventSourceForm(definition);

  const updateMutation = useUpdateEventSourceMutation();

  const onSubmit: EventSourceFormProps["onSubmit"] = async ({
    config,
    tunnelId,
    credentialId,
  }) => {
    await updateMutation.mutateAsync({
      id: source.id,
      input: {
        config,
        tunnel_id: tunnelId || null,
        credential_id: credentialId || null,
      },
    });
  };

  return SourceForm ? (
    <Row gap={8}>
      <Column width="100%">
        <SourceForm
          mode="edit"
          source={{
            name: source.name,
            config: source.config,
            tunnelId: source.tunnel_id,
            credentialId: source.credential_id,
          }}
          definition={definition}
          onSubmit={onSubmit}
        />
      </Column>
      <SidebarForm name={source.name} />
    </Row>
  ) : (
    <Navigate to="../setup" replace />
  );
};
