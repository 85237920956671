import { useEffect, useRef, useState } from "react";

import {
  Box,
  Column,
  FormField,
  Heading,
  Row,
  Spinner,
  Textarea,
  useToast,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { FormProvider } from "react-hook-form";

import { AccordionSection } from "src/components/accordion-section";
import {
  Destination,
  DestinationSelect,
} from "src/components/destinations/destination-select";
import {
  DestinationForm,
  FormRef,
} from "src/components/destinations/sync-form";
import { useHightouchForm } from "src/components/form";
import { LabelForm, getTagsFromLabels } from "src/components/labels/label-form";
import { ResourceType } from "src/components/labels/use-labels";
import { ModelSelect } from "src/components/models/model-select";
import { useSyncCreationPermissions } from "src/components/permission/creation/sync";
import {
  ScheduleManager,
  useScheduleState,
} from "src/components/schedule/schedule-manager";
import {
  Schedule,
  ScheduleIntervalUnit,
  ScheduleType,
} from "src/components/schedule/types";
import { SplitsSyncAssignmentForm } from "src/components/splits-v2/splits-sync-assignment-form";
import { WizardStep } from "src/components/wizard";
import { DraftProvider } from "src/contexts/draft-context";
import { useUser } from "src/contexts/user-context";
import {
  DestinationDefinitionFragment as DestinationDefinition,
  ModelQuery,
  ResourceToPermission,
  useCreateSyncMutation,
  useDestinationQuery,
  useModelQuery,
  useUpdateSplitSyncAssignmentsMutation,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { JourneySyncConfigForm } from "src/pages/syncs/create/journey-sync-config-form";
import { JourneyNodeSyncMode, JourneySyncExitConfig } from "src/types/journeys";
import { QueryType } from "src/types/models";
import { NullableProperties, isPresent } from "src/types/utils";
import { SlugResourceType, useResourceSlug } from "src/utils/slug";
import { useQueryString } from "src/utils/use-query-string";
import { useWizardStepper } from "src/utils/use-wizard-stepper";

type CreateSyncWizard = {
  initialStep?: number;
  model?: ModelQuery["segments_by_pk"];
  destination?: Destination;
  destinationDefinition?: DestinationDefinition;
  onSubmit?: ({
    id,
    journeySyncConfig,
  }: {
    id: string;
    journeySyncConfig: {
      mode: JourneyNodeSyncMode;
      exit_config: NullableProperties<JourneySyncExitConfig>;
    };
  }) => void | Promise<void>;
};

export const useCreateSyncWizard = ({
  initialStep = 0,
  model: onboardingModel,
  destination: onboardingDestination,
  destinationDefinition: onboardingDestinationDefinition,
  onSubmit,
}: CreateSyncWizard) => {
  const { data: queryParameters, loading: loadingParams } = useQueryString();
  const params = queryParameters as {
    model?: string;
    schedule?: string;
    destination?: string;
  };
  const { toast } = useToast();
  const { workspace } = useUser();
  const { validateSchedule } = useScheduleState("sync");
  const [destination, setDestination] = useState<Destination | undefined>(
    onboardingDestination,
  );
  const [destinationDefinition, setDestinationDefinition] = useState<
    DestinationDefinition | undefined
  >(onboardingDestinationDefinition);
  const [selectedModelId, setSelectedModelId] = useState(
    params?.model ?? onboardingModel?.id,
  );
  const [schedule, setSchedule] = useState<Schedule>({
    type:
      (params.schedule as ScheduleType) === ScheduleType.JOURNEY_TRIGGERED
        ? ScheduleType.JOURNEY_TRIGGERED
        : ScheduleType.MANUAL,
  });
  const [isStreaming, setIsStreaming] = useState(false);
  const [config, setConfig] = useState<any>();
  const [selectedSplitIds, setSelectedSplitIds] = useState<string[]>([]);
  const [name, setName] = useState("");
  const { getSlug } = useResourceSlug(SlugResourceType.Syncs);
  const [step, setStep] = useWizardStepper(initialStep);
  const [description, setDescription] = useState("");

  const { modelFilter, destinationFilter } =
    useSyncCreationPermissions(selectedModelId);

  const { data: model, isLoading: modelLoading } = useModelQuery(
    {
      id: selectedModelId,
      includeSourceColumnDescriptions: true,
    },
    {
      enabled: Boolean(selectedModelId),
      select: (data) => data.segments_by_pk,
    },
  );

  const destinationQuery = useDestinationQuery(
    {
      id: params.destination ?? "",
    },
    {
      enabled: Boolean(params.destination),
      select: (data) => data.destinations_by_pk,
    },
  );

  useEffect(() => {
    if (destinationQuery.data) {
      setDestination(destinationQuery.data);
      setDestinationDefinition(destinationQuery.data.definition);
    }
  }, [destinationQuery.data]);

  useEffect(() => {
    // Set the default schedule based on model
    if (!model) return;
    if (
      model.matchboosting_enabled ||
      (model.parent?.matchboosting_enabled &&
        schedule.type === ScheduleType.MANUAL)
    ) {
      setSchedule({ type: ScheduleType.MATCH_BOOSTER });
    }
    if (model.query_type === QueryType.DecisionEngine) {
      // AID syncs default to hourly
      setSchedule({
        type: ScheduleType.INTERVAL,
        schedule: {
          interval: {
            unit: ScheduleIntervalUnit.HOUR,
            quantity: 1,
          },
        },
      });
    }
  }, [model]);

  const { mutateAsync: createSync } = useCreateSyncMutation();
  const updateSplitSyncAssignments = useUpdateSplitSyncAssignmentsMutation();

  const isJourneyTriggered = schedule.type === ScheduleType.JOURNEY_TRIGGERED;

  const splitsCount = model?.splits_aggregate.aggregate?.count;
  const showSplitsAssignmentStep =
    model?.query_type === QueryType.Visual &&
    splitsCount !== undefined &&
    splitsCount > 0;

  const form = useHightouchForm({
    onSubmit: async ({ labels, mode, exit_config }) => {
      const slug = await getSlug(name);

      const data = await createSync({
        object: {
          slug,
          tags: getTagsFromLabels(labels),
          destination_id: destination!.id,
          description,
          segment_id: model?.id,
          config: {
            ...config,
            configVersion: destinationDefinition?.configVersion,
          },
          schedule: schedule?.type === "manual" ? null : schedule,
          draft: isJourneyTriggered || workspace?.approvals_required,
          is_streaming: isStreaming,
        },
      });

      const id = data?.insert_destination_instances_one?.id;

      // if splits are enabled, assign the selected splits to this new sync
      if (showSplitsAssignmentStep && selectedSplitIds.length) {
        try {
          await updateSplitSyncAssignments.mutateAsync({
            addObjects: selectedSplitIds.map((splitId) => ({
              split_id: splitId,
              destination_instance_id: id,
            })),
          });
        } catch (error) {
          Sentry.captureException(error);
          toast({
            id: "split-sync-assignment-error",
            title: "Sync was created, but split groups could not be assigned.",
            variant: "error",
          });
        }
      }

      analytics.track("Sync Created", {
        destination_id: destination?.id,
        destination_name: destination?.name,
        destination_type: destinationDefinition?.name,
        model_id: model?.id,
        model_name: model?.name,
        schedule_type: schedule?.type,
      });

      if (typeof onSubmit === "function" && id) {
        await onSubmit({ id, journeySyncConfig: { mode, exit_config } });
      }

      return { id, journeySyncConfig: { mode, exit_config } };
    },
    success: workspace?.approvals_required ? false : "Sync was created",
    defaultValues: {
      labels: [{ key: "", value: "" }],
      mode: JourneyNodeSyncMode.Cohort,
      exit_config: {
        remove_after: null,
        remove_on_journey_exit: true,
      },
    },
  });

  useEffect(() => {
    if (params.model) {
      setSelectedModelId(params?.model);
    }

    if (params.schedule) {
      setSchedule({
        type:
          (params.schedule as ScheduleType) === ScheduleType.JOURNEY_TRIGGERED
            ? ScheduleType.JOURNEY_TRIGGERED
            : ScheduleType.MANUAL,
      });
    }
  }, [params]);

  useEffect(() => {
    // if they select `isStreaming` we want to default the type to STREAMING
    if (isStreaming) {
      setSchedule({ type: ScheduleType.STREAMING });
    }
  }, [isStreaming]);

  useEffect(() => {
    if (step === 1) {
      analytics.track("Add Sync Model Selected", {
        model_name: model?.name,
        source_type: model?.connection?.type,
        query_type: model?.query_type,
      });
    }
    if (step === 2) {
      analytics.track("Add Sync Destination Selected", {
        model_name: model?.name,
        source_type: model?.connection?.type,
        query_type: model?.query_type,
        destination_type: destinationDefinition?.name,
      });
    }
  }, [step]);

  useEffect(() => {
    if (model?.name && destination?.name) {
      setName(`${model?.name} to ${destination?.name}`);
    }
  }, [model?.name, destination?.name]);

  const destinationFormRef = useRef<FormRef>(null);

  const steps: WizardStep[] = [
    params.model
      ? null
      : {
          title: "Select model",
          continue: "Click on a model to continue",
          header: <Heading>Select a model</Heading>,
          render: () => (
            <DraftProvider
              initialResourceIsDraft={model?.draft || false}
              resourceId={model?.id}
              resourceType={ResourceToPermission.Sync}
            >
              <ModelSelect
                isOptionAvailable={modelFilter}
                filter={[
                  {
                    query_type: { _neq: QueryType.JourneyNode },
                    is_schema: { _eq: false },
                  },
                ]}
                onSelect={(selection) => {
                  setSelectedModelId(selection.id);
                  setStep((step) => step + 1);
                }}
              />
            </DraftProvider>
          ),
        },
    params.destination
      ? null
      : {
          title: "Select destination",
          continue: "Click on a destination to continue",
          header: <Heading>Select a destination</Heading>,
          render: () => (
            <DestinationSelect
              allowedDestinations={
                model?.connection?.definition?.allowedDestinations
              }
              isOptionAvailable={destinationFilter}
              onSelect={(destination) => {
                setDestination(destination);
                setDestinationDefinition(destination.definition);
                setStep((step) => step + 1);
              }}
            />
          ),
        },
    {
      title: "Configure sync",
      continueProps: { form: "destination-form", type: "submit" as const },
      onContinue: async () => {
        const form = destinationFormRef.current;
        if (!form) return;

        await form.submit();
      },
      header: (
        <Row alignItems="center" gap={4}>
          <Box
            as="img"
            alt={destinationDefinition?.name}
            src={destinationDefinition?.icon}
            sx={{ width: "32px", objectFit: "contain" }}
          />
          <Heading>
            Configure sync to {destination?.name || destinationDefinition?.name}
          </Heading>
        </Row>
      ),
      bg: "base.lightBackground",
      render: () => {
        if (
          destination &&
          destinationDefinition &&
          model &&
          model.connection?.definition
        ) {
          return (
            <DestinationForm
              ref={destinationFormRef}
              hideSave
              disableRowTesting={
                schedule.type === ScheduleType.JOURNEY_TRIGGERED
              }
              permission={{
                v2: {
                  resource: "sync",
                  grant: "can_create",
                  creationOptions: {
                    modelId: model.id.toString(),
                    destinationId: destination.id.toString(),
                  },
                },
                v1: {
                  resource: "sync",
                  grant: "create",
                },
              }}
              testPermission={{
                v2: {
                  resource: "sync",
                  grant: "can_test",
                  creationOptions: {
                    modelId: model.id.toString(),
                    destinationId: destination.id.toString(),
                  },
                },
                v1: {
                  resource: "sync",
                  grant: "testrow",
                },
              }}
              destination={destination}
              destinationDefinition={destinationDefinition}
              model={model}
              slug={destination.type}
              sourceDefinition={model.connection?.definition}
              syncConfig={config}
              onSubmit={(config) => {
                setConfig(config);
                setStep((step) => step + 1);
                return Promise.resolve();
              }}
            />
          );
        }
        return <Spinner size="lg" m="auto" />;
      },
    },
    showSplitsAssignmentStep && {
      title: "Assign split groups",
      disabled: selectedSplitIds.length === 0,
      render: () => (
        <SplitsSyncAssignmentForm
          hideSave
          audienceId={selectedModelId}
          setSelectedSplitIds={setSelectedSplitIds}
          onSubmit={() => {
            setStep((step) => step + 1);
            return Promise.resolve();
          }}
        />
      ),
    },
    isJourneyTriggered
      ? {
          // TODO: in the future, decouple creating syncs from the ui returned in this hook in the wizard.
          title: "Journey settings",
          header: <Heading>Configure the settings for this sync</Heading>,
          render: () => (
            <FormProvider {...form}>
              <Column gap={6}>
                <JourneySyncConfigForm />
              </Column>
            </FormProvider>
          ),
        }
      : null,
    {
      title: "Finalize sync",
      disabled: !validateSchedule(schedule),
      header: <Heading>Finalize settings for this sync</Heading>,
      render: () => (
        <Column gap={6} maxWidth="2xl">
          <FormField label="Description" isOptional>
            <Textarea
              placeholder="Enter a description..."
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </FormField>
          <FormProvider {...form}>
            <AccordionSection label="Advanced configuration (optional)">
              <LabelForm
                heading="Add labels"
                hint="Example keys: team, project, region, env."
                resourceType={ResourceType.Sync}
              />
            </AccordionSection>
          </FormProvider>
          <ScheduleManager
            schedule={schedule}
            setSchedule={setSchedule}
            isStreamable={model?.is_streamable}
            isStreaming={isStreaming}
            setIsStreaming={setIsStreaming}
            matchboosterEnabledOnModel={
              model?.matchboosting_enabled ||
              model?.parent?.matchboosting_enabled
            }
            includeStartAndEnd={!isJourneyTriggered}
            types={
              isJourneyTriggered ? [ScheduleType.JOURNEY_TRIGGERED] : undefined
            }
            isAID={model?.query_type === QueryType.DecisionEngine}
          />
        </Column>
      ),
    },
  ].filter(isPresent);

  return {
    createSync: form.submit,
    setStep,
    step,
    steps,
    loading:
      loadingParams ||
      (params.model && modelLoading) ||
      (params.destination && destinationQuery.isLoading),
    selectedModelId,
    selectedDestinationId: destination?.id,
  };
};
