import { useCallback } from "react";
import { useUser } from "src/contexts/user-context";
import { useSchemaObjectCreationAuthorizationQuery } from "src/graphql";

// for objects like traits, metrics, and sync templates
export function useSchemaObjectCreationPermissions(): {
  parentModelIds: string[];
  parentModelFilter: (id: string | number) => boolean;
} {
  const { user } = useUser();
  const permissionsV2Enabled = user?.permissions_v2_enabled ?? false;
  const { data: authorizationRows, isLoading } =
    useSchemaObjectCreationAuthorizationQuery(
      {},
      {
        select: (data) => data.user_group_model_authorization ?? [],
        enabled: permissionsV2Enabled ?? false,
      },
    );

  const parentModelFilter = useCallback(
    (id: string | number) => {
      if (!permissionsV2Enabled || isLoading || !authorizationRows) {
        return true;
      }

      return authorizationRows.some(
        (row) => String(row.model_id) === String(id),
      );
    },
    [authorizationRows, permissionsV2Enabled, isLoading],
  );

  return {
    parentModelIds: authorizationRows?.map((row) => row.model_id) ?? [],
    parentModelFilter,
  };
}
