import { FC } from "react";

import { Column, Text, Heading, WarningIcon, Row } from "@hightouchio/ui";

import { LinkButton } from "src/router";

import { Card } from "src/components/card";

type Props = {
  title: string;
  subtitle?: string;
  linkText?: string;
  to?: string;
};

export const Warning: FC<Props> = ({ title, subtitle, linkText, to }) => {
  return (
    <Card p={10} sx={{ maxWidth: "540px", width: "100%", mx: "auto", mt: 24 }}>
      <Column
        align="center"
        gap={6}
        sx={{ color: "gray.900", textAlign: "center" }}
      >
        <Row fontSize="50px">
          <WarningIcon color="warning.base" />
        </Row>
        <Heading size="lg">{title}</Heading>
        <Text>{subtitle}</Text>
        {to && <LinkButton href={to}>{linkText || ""}</LinkButton>}
      </Column>
    </Card>
  );
};
