type EmbedPostMessage =
  | "create_sync_started"
  | "create_sync_finished"
  | "create_source_started"
  | "create_source_finished";

// Send a postMessage to it's parent window
export function sendPostMessage(message: EmbedPostMessage) {
  const data = {
    sender: "hightouch_partner",
    message,
  };

  // Because we're not sending anything secure in the message,
  // it's ok to use the "*" pattern for targetOrigin
  return window.parent.postMessage(data, "*");
}
