import { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";

import useQueryState from "./use-query-state";

// Special case of useQueryState where we grab the initial search query from the URL
// and propagate changes to the URL on search query change.
// Also provides a debounced search value for convenience when using in network requests.
export const useSearchQueryState = (
  debounceMs = 300,
): {
  search: string;
  setSearch: (str: string) => void;
  debouncedSearch: string;
  isSearchPending: () => boolean;
} => {
  const [initialSearch, setSearchQueryState] = useQueryState("search");

  const [search, setSearch] = useState<string>(initialSearch || "");

  const [debouncedSearch, { isPending: isSearchPending }] = useDebounce(
    search,
    debounceMs,
  );

  useEffect(() => {
    // Make sure to use the debounced value to avoid excessive URL updates, which can create some page lag
    setSearchQueryState(debouncedSearch);
  }, [debouncedSearch]);

  return { search, setSearch, debouncedSearch, isSearchPending };
};
