// eslint-disable-next-line no-restricted-imports
import { Outlet, Route, Routes, Navigate, RouteObject } from "react-router-dom";

import { App } from "src/components/app";
import { Passwordless } from "src/components/auth/passwordless";
import { Layout } from "src/components/layout";
import { PermissionedRoute } from "src/components/router/permissioned-route";
import { FormErrorProvider } from "src/contexts/form-error-context";
import { EventsRouter } from "src/events";
import { Analytics } from "src/pages/analytics";
import { Audience } from "src/pages/audiences/audience";
import Audiences from "src/pages/audiences/audiences";
import { CloneAudience } from "src/pages/audiences/clone-audience";
import { CreateAudience } from "src/pages/audiences/create-audience";
import { CreateAudienceObject } from "src/pages/audiences/setup/create-object";
import { CreateSyncTemplate } from "src/pages/audiences/setup/create-sync-template";
import { AudienceObject } from "src/pages/audiences/setup/object";
import { AudienceObjectQuery } from "src/pages/audiences/setup/object-query";
import { SetupAudiences } from "src/pages/audiences/setup/setup-audiences";
import { SyncTemplateRouter } from "src/pages/audiences/setup/sync-template";
import { CampaignsRoutes } from "src/pages/campaigns";
import { Copilot } from "src/pages/copilot";
import { DecisionEngineRoutes } from "src/pages/decision-engines";
import { routes as destinationRoutes } from "src/pages/destinations";
import { Expired } from "src/pages/expired";
import { ExtensionsRouter } from "src/pages/extensions";
import { GovernanceRoutes } from "src/pages/governance";
import { Home } from "src/pages/home";
import { IdentityResolutionRoutes } from "src/pages/identity-resolution";
import { Invalid } from "src/pages/invalid";
import { JourneysRoutes } from "src/pages/journeys";
import Login from "src/pages/login";
import { MetricsRoutes } from "src/pages/metrics/index";
import { MiscRoutes } from "src/pages/misc";
import { routes as monitoringRoutes } from "src/pages/alerting";
import { OrganizationRoutes } from "src/pages/organizations";
import PartnerConnect from "src/pages/partner-connect";
import { PriorityListsRouter } from "src/pages/priority-lists";
import RedirectPage from "src/pages/redirect";
import { Restricted } from "src/pages/restricted";
import { SchemaPage } from "src/pages/schema";
import { SequenceRoutes } from "src/pages/sequences";
import { SettingsRouter } from "src/pages/settings";
import { Setup } from "src/pages/setup";
import { Signup } from "src/pages/signup";
import { routes as sourceRoutes } from "src/pages/sources";
import SSO from "src/pages/sso";
import { Traits } from "src/pages/traits/traits";
import { Unauthorized } from "src/pages/unauthorized";
import { Welcome, WelcomeWizard } from "src/pages/welcome";
import { routes as partnerRoutes } from "src/partner";
import { AuthenticatedRoute } from "./authenticated-route";
import { RootIndex } from "./root-index";
import { SlugIndex } from "./slug-index";
import * as SlugLayout from "./slug-layout";
import { UnauthenticatedRoute } from "./unauthenticated-route";

// New style routes
import { routes as workspaceRoutes } from "src/pages/workspaces";
import { routes as modelRoutes } from "src/pages/models";
import { routes as syncRoutes } from "src/pages/syncs";
import { NotFound } from "src/pages/not-found";
import { RouteErrorBoundary } from "./error-boundary";

// TODO: Migrate to new router definition
const SlugRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path="onboarding/*" element={<Setup />} />
      <Route path="home" element={<Home />} />
      <Route path="sequences/*" element={<SequenceRoutes />} />
      <Route path="extensions/*" element={<ExtensionsRouter />} />
      <Route path="settings/*" element={<SettingsRouter />} />
      <Route
        path="schema/sync-templates/new"
        element={
          <PermissionedRoute
            permission={{
              v1: { resource: "sync_template", grant: "create" },
            }}
            redirect="/schema/sync-templates"
          >
            <CreateSyncTemplate />
          </PermissionedRoute>
        }
      />
      <Route
        path="metrics/*"
        element={
          <PermissionedRoute
            permission={{
              v1: { resource: "audience_schema", grant: "create" },
            }}
            redirect="/home"
          >
            <MetricsRoutes />
          </PermissionedRoute>
        }
      />
      <Route
        path="schema/sync-templates/:sync_template_id"
        element={<SyncTemplateRouter />}
      />
      <Route
        path="schema/:object/:id/query"
        element={<AudienceObjectQuery />}
      />
      <Route
        path="schema/:object/new"
        element={
          <PermissionedRoute
            permission={{
              v1: { resource: "audience_schema", grant: "create" },
            }}
            redirect="/schema/:object"
          >
            <CreateAudienceObject />
          </PermissionedRoute>
        }
      />
      <Route path="schema/:object/:id" element={<AudienceObject />} />
      <Route path="schema/*" element={<SetupAudiences />} />
      <Route
        path="schema/"
        element={<Navigate to="/schema/parent-models" replace />}
      />
      <Route path="schema-v2/*" element={<SchemaPage />} />
      <Route
        path="audiences/:id/clone"
        element={
          <PermissionedRoute
            permission={{
              v1: { resource: "audience", grant: "create" },
            }}
            redirect="/audiences/:id"
          >
            <FormErrorProvider>
              <CloneAudience />
            </FormErrorProvider>
          </PermissionedRoute>
        }
      />
      <Route
        path="audiences/new"
        element={
          <PermissionedRoute
            permission={{
              v1: { resource: "audience", grant: "create" },
            }}
            redirect="/audiences"
          >
            <FormErrorProvider>
              <CreateAudience />
            </FormErrorProvider>
          </PermissionedRoute>
        }
      />
      <Route path="audiences/:id/*" element={<Audience />} />
      <Route path="audiences" element={<Audiences />} />
      <Route path="priority-lists/*" element={<PriorityListsRouter />} />
      <Route path="analytics" element={<Analytics />} />
      <Route path="analytics/:id" element={<Analytics />} />
      <Route path="copilot" element={<Copilot />} />
      <Route path="campaigns/*" element={<CampaignsRoutes />} />
      <Route path="traits/*" element={<Traits />} />
      <Route path="idr/*" element={<IdentityResolutionRoutes />} />
      <Route path="events/*" element={<EventsRouter />} />
      <Route path="organization/*" element={<OrganizationRoutes />} />
      <Route path="journeys/*" element={<JourneysRoutes />} />
      <Route path="ai/*" element={<DecisionEngineRoutes />} />
      <Route path="governance/*" element={<GovernanceRoutes />} />
      <Route path="misc/*" element={<MiscRoutes />} />
    </Routes>
  );
};

const RootLayout = () => {
  return (
    <App>
      <Outlet />
    </App>
  );
};

export const routes: Array<RouteObject> = [
  {
    element: <RootLayout />,
    children: [
      { index: true, element: <RootIndex /> },
      { path: "*", element: <NotFound /> },
      {
        path: "/welcome",
        element: (
          <AuthenticatedRoute>
            <Welcome />
          </AuthenticatedRoute>
        ),
      },
      {
        path: "/welcome/:step",
        element: (
          <AuthenticatedRoute>
            <WelcomeWizard />
          </AuthenticatedRoute>
        ),
      },
      { path: "/unauthorized", element: <Unauthorized /> },
      { path: "/expired", element: <Expired /> },
      { path: "/invalid", element: <Invalid /> },
      { path: "/restricted", element: <Restricted /> },
      { path: "/redirect", element: <RedirectPage /> },
      {
        element: (
          <AuthenticatedRoute>
            <PartnerConnect />
          </AuthenticatedRoute>
        ),
        path: "/partner-connect/:uuid",
      },

      {
        path: "/login",
        element: (
          <UnauthenticatedRoute>
            <Login />
          </UnauthenticatedRoute>
        ),
      },
      {
        path: "/signup/email",
        element: (
          <UnauthenticatedRoute>
            <Passwordless />
          </UnauthenticatedRoute>
        ),
      },
      {
        path: "/signup",
        element: (
          <UnauthenticatedRoute>
            <Signup />
          </UnauthenticatedRoute>
        ),
      },
      {
        path: "/login/email",
        element: (
          <UnauthenticatedRoute>
            <Passwordless />
          </UnauthenticatedRoute>
        ),
      },
      {
        path: "/sso",
        element: (
          <UnauthenticatedRoute>
            <SSO />
          </UnauthenticatedRoute>
        ),
      },
      {
        path: "/sso/:org",
        element: (
          <UnauthenticatedRoute>
            <SSO />
          </UnauthenticatedRoute>
        ),
      },
      partnerRoutes,
      workspaceRoutes,
      {
        path: "/:root_workspace_slug/*",
        loader: SlugLayout.loader,
        element: (
          <AuthenticatedRoute>
            <SlugLayout.Element />
          </AuthenticatedRoute>
        ),
        children: [
          { index: true, element: <SlugIndex /> },
          {
            element: <Layout />,
            errorElement: (
              <Layout>
                <RouteErrorBoundary />
              </Layout>
            ),
            children: [
              {
                path: "*",
                element: <SlugRoutes />,
              },
              // Add new style routes here
              ...monitoringRoutes,
              ...modelRoutes,
              ...sourceRoutes,
              ...destinationRoutes,
              ...syncRoutes,
            ],
          },
        ],
      },
    ],
  },
];
