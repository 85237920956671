import { Alert, Column, SectionHeading, Text, useToast } from "@hightouchio/ui";
import { Link } from "src/router";
import { isEqual } from "lodash";
import pluralize from "pluralize";
import { useEffect, useState } from "react";

import { useNavigate, useParams } from "src/router";
import { ActionBar } from "src/components/action-bar";
import { OverageContentAlert } from "src/components/overage/overage-content-alert";
import { PermissionedButton } from "src/components/permission";
import {
  ScheduleManager,
  useScheduleState,
} from "src/components/schedule/schedule-manager";
import {
  Schedule as ScheduleObject,
  ScheduleType,
} from "src/components/schedule/types";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { useDraft } from "src/contexts/draft-context";
import { useUser } from "src/contexts/user-context";
import {
  SyncDraft,
  useSequencesForSyncQuery,
  useSyncQuery,
  useUpdateSyncMutation,
} from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import * as analytics from "src/lib/analytics";
import { Table } from "src/ui/table";
import { QueryType } from "src/types/models";

export const Schedule = () => {
  const { workspace } = useUser();
  const navigate = useNavigate();
  const { toast } = useToast();
  const { sync_id: id } = useParams<{ sync_id: string }>();
  const [isSaving, setIsSaving] = useState(false);
  const { validateSchedule } = useScheduleState("sync");
  const [schedule, setSchedule] = useState<ScheduleObject>();
  const [isStreaming, setIsStreaming] = useState(false);
  const { draft, editingDraft, updateResourceOrDraft } = useDraft();

  const newResource = draft?.new_resource as SyncDraft;
  const draftSchedule = newResource?._set?.schedule;

  const { data: entitlementsData } = useEntitlements(true);
  const overageLockout: boolean = entitlementsData.overage?.overageLockout;

  const { data: sync } = useSyncQuery(
    {
      id: id ?? "",
    },
    {
      enabled: Boolean(id),
      select: (data) => data.syncs[0],
      suspense: true,
    },
  );

  const model = sync!.segment;
  const destination = sync!.destination;

  const { data: sequences } = useSequencesForSyncQuery(
    { syncId: String(id) },
    {
      enabled: Boolean(id),
      select: (data) => data.sync_sequences,
      suspense: true,
    },
  );

  const { mutateAsync: updateSync } = useUpdateSyncMutation();

  // Since when we edit the draft, we set the schedule on the draft instead of the
  // actual resource, we need to check if there is a draft when we are setting the schedule
  // to the manual schedule type.
  const isSameSchedule = editingDraft
    ? isEqual(schedule, draftSchedule ?? { type: ScheduleType.MANUAL })
    : isEqual(schedule, sync?.schedule ?? { type: ScheduleType.MANUAL });
  const isIncompleteSchedule = !validateSchedule(schedule ?? null);
  const scheduleSaveDisabled = isSameSchedule || isIncompleteSchedule;
  const isJourneyTriggered =
    sync!.schedule?.type === ScheduleType.JOURNEY_TRIGGERED;

  const onUpdate = () => {
    analytics.track("Sync Edited", {
      sync_id: id,
      destination_type: destination?.definition?.name,
      schedule_type: sync!.schedule?.type,
      source_type: model!.connection?.type,
    });

    if (!workspace?.approvals_required) {
      toast({
        id: "update-sync",
        title: "Sync was updated",
        variant: "success",
      });
    }
  };

  const updateScheduleAndIsStreaming = async () => {
    if (!id || !sync) {
      return;
    }

    setIsSaving(true);

    const updatePayload = {
      schedule: schedule?.type === "manual" ? null : schedule,
      is_streaming: isStreaming,
      // we null the draft id, it gets added on the backend and we want to be consistent
      // if a workspace turns off approvals again =
      approved_draft_id: null,
    };

    if (updateResourceOrDraft) {
      await updateResourceOrDraft(
        { _set: updatePayload },
        onUpdate,
        () =>
          updateSync({
            id,
            object: updatePayload,
          }),
        sync.draft || false,
      );
    }

    setIsSaving(false);
  };

  useEffect(() => {
    // if they select `isStreaming` we want to default the type to STREAMING
    if (isStreaming) {
      setSchedule({ type: ScheduleType.STREAMING });
    }
  }, [isStreaming]);

  useEffect(() => {
    setSchedule(
      editingDraft && draftSchedule
        ? draftSchedule
        : sync?.schedule
          ? sync.schedule
          : sync?.is_streaming
            ? { type: ScheduleType.STREAMING }
            : { type: ScheduleType.MANUAL },
    );
    setIsStreaming(sync?.is_streaming ?? false);
  }, [sync, draftSchedule, editingDraft]);

  if (overageLockout) {
    return <OverageContentAlert />;
  }

  return (
    <>
      <Column width="100%">
        {sequences && sequences.length > 0 && (
          <Column>
            <SectionHeading>Sequences</SectionHeading>
            <Text mb={4} color="text.secondary">
              This sync is currently being run by {sequences.length}{" "}
              {pluralize("sequence", sequences.length)}
            </Text>
            <Table
              columns={[
                {
                  name: "Name",
                  cell: ({ name }) => <TextWithTooltip>{name}</TextWithTooltip>,
                },
                {
                  name: "Syncs",
                  cell: ({ members }) =>
                    `${members.length} ${pluralize("sync", members.length)}`,
                },
              ]}
              data={sequences}
              showHeaders={false}
              onRowClick={({ id }) => navigate(`/sequences/${id}`)}
            />
          </Column>
        )}
        <ScheduleManager
          types={
            schedule?.type === ScheduleType.JOURNEY_TRIGGERED
              ? [ScheduleType.JOURNEY_TRIGGERED]
              : undefined
          }
          schedule={schedule ?? null}
          setSchedule={setSchedule}
          isStreamable={model?.is_streamable}
          isStreaming={isStreaming}
          setIsStreaming={setIsStreaming}
          isAID={sync?.segment?.query_type === QueryType.DecisionEngine}
          matchboosterEnabledOnModel={
            model?.matchboosting_enabled || model?.parent?.matchboosting_enabled
          }
        />
        {!isJourneyTriggered && (
          <Alert
            mt={8}
            type="info"
            variant="inline"
            title="Sync triggers"
            message={
              <>
                You can also trigger this sync via Airflow, Dagster, or Prefect.
                For more information view our{" "}
                <Link
                  href={`${import.meta.env.VITE_DOCS_URL}/extensions/airflow`}
                >
                  Airflow Operator
                </Link>
                {", "}
                <Link
                  href={`${import.meta.env.VITE_DOCS_URL}/extensions/dagster`}
                >
                  Dagster
                </Link>
                {", or "}
                <Link
                  href={`${import.meta.env.VITE_DOCS_URL}/extensions/prefect`}
                >
                  Prefect docs
                </Link>
                . If you need an API key, you can create one in{" "}
                <Link href="/settings/api-keys">Settings.</Link>
              </>
            }
          />
        )}
      </Column>

      <ActionBar>
        <PermissionedButton
          permission={{
            v2: {
              resource: "sync",
              grant: "can_update",
              id: sync?.id,
            },
          }}
          size="lg"
          variant="primary"
          isDisabled={scheduleSaveDisabled}
          isLoading={isSaving}
          onClick={() => updateScheduleAndIsStreaming()}
        >
          {workspace?.approvals_required ? "Save draft" : "Save"}
        </PermissionedButton>
      </ActionBar>
    </>
  );
};
