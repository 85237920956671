import { FC } from "react";

import { InlineMapper } from "./inline-mapper";
import { ArrayMapping, InlineMapperProps } from "../types";

type ArrayInlineMapperProps = Omit<InlineMapperProps, "value" | "type"> & {
  value: ArrayMapping;
  onReloadEligibleInlineMapperColumns: (currentSelectedColumn?: string) => void;
};

export const ArrayInlineMapper: FC<Readonly<ArrayInlineMapperProps>> = (
  props,
) => {
  const {
    jsonColumnProperties,
    onChangeJsonColumnProperties,
    value,
    enabledNestedInlineMapper,
    onReloadEligibleInlineMapperColumns,
  } = props;

  const onReloadJsonColumnOptions = (currentSelectedColumn?: string) => {
    if (jsonColumnProperties.allColumnsProps) {
      onChangeJsonColumnProperties((currentState) => ({
        ...currentState,
        selectedColumnProps: value.from
          ? currentState.allColumnsProps?.[value.from]
          : undefined,
      }));
    } else {
      onReloadEligibleInlineMapperColumns(currentSelectedColumn);
    }
  };

  return (
    <InlineMapper
      {...props}
      enabledNestedInlineMapper={enabledNestedInlineMapper}
      overwriteColumnsWithArrayProps={true}
      type="array"
      onReloadEligibleInlineMapperColumns={onReloadJsonColumnOptions}
    />
  );
};
