import { useState } from "react";
import { useDebounce } from "use-debounce";
import {
  useColumnSuggestionsQuery,
  useColumnSuggestionsV2Query,
} from "src/graphql";
import { useFlags } from "launchdarkly-react-client-sdk";

export function useFetchColumnSuggestions(
  args: {
    modelId: string | undefined;
    columnName: string | undefined;
    query?: string | undefined;
  },
  options: { enabled?: boolean } = { enabled: true },
): {
  suggestions: Array<{
    value: string | number | boolean | null;
    count: number | undefined;
  }>;
  loadingSuggestions: boolean;
  onSearchUpdate: (search: string) => void;
} {
  const { enabled } = options;
  const isValid = !!args.modelId && !!args.columnName;
  const [search, setSearch] = useState<string>("");
  const { enableNewColumnSuggestionsReads } = useFlags();
  const [debouncedSearch, { isPending: isSearchPending }] = useDebounce(
    search,
    // Debounce time in milliseconds.
    500,
  );

  const { data: newColumnSuggestionsData, isLoading: newLoadingSuggestions } =
    useColumnSuggestionsV2Query(
      {
        modelId: String(args.modelId!),
        columnName: args.columnName!,
        // Empty string is a valid query grabbing the top values with no
        // filters applied.
        query: debouncedSearch ?? "",
      },
      {
        enabled: enableNewColumnSuggestionsReads && enabled && isValid,
      },
    );

  const { data: oldColumnSuggestionsData, isLoading: oldLoadingSuggestions } =
    useColumnSuggestionsQuery(
      {
        modelIds: [args.modelId!],
        columnNames: [args.columnName!],
      },
      {
        enabled: !enableNewColumnSuggestionsReads && enabled && isValid,
      },
    );

  const loadingSuggestions = enableNewColumnSuggestionsReads
    ? newLoadingSuggestions
    : oldLoadingSuggestions;

  const suggestions = enableNewColumnSuggestionsReads
    ? newColumnSuggestionsData?.topKForQuery?.values
    : oldColumnSuggestionsData?.getTopK?.columns?.find(
        (column) =>
          column.modelId === args.modelId && column.name === args.columnName,
      )?.values;

  return {
    suggestions: suggestions ?? [],
    loadingSuggestions: loadingSuggestions || isSearchPending(),
    onSearchUpdate: setSearch,
  };
}
