import { snakeCase } from "lodash";

export const snakecaseObject = (obj: Record<string, any>) => {
  if (!obj) {
    return {};
  }

  return Object.entries(obj).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [snakeCase(key)]: value,
    }),
    {},
  );
};

export const lowercase = (
  value: Record<string, any> | Array<any> | string | number | null,
  levels = 1,
) => {
  if (!value) {
    return value;
  }

  if (typeof value === "string") {
    return value.toLowerCase();
  }

  if (typeof value === "object") {
    if (Array.isArray(value)) {
      return value.map((v) => lowercase(v, levels));
    }

    return Object.entries(value).reduce(
      (acc, [key, v]) => ({
        ...acc,
        [key.toLowerCase()]: levels > 1 ? lowercase(v, levels - 1) : v,
      }),
      {},
    );
  }

  return value;
};

export const parseIfString = (obj: string | Record<string, unknown>) =>
  typeof obj === "string" ? JSON.parse(obj) : obj;
