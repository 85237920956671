import {
  ColumnType,
  ConditionType,
  IntervalOperators,
  IntervalUnit,
  MultiValueOperatorsByColumnType,
  OperatorsWithoutValue,
  PropertyCondition,
  RawColumn,
  RelatedColumn,
  RelativeDirection,
} from "src/types/visual";
import * as yup from "yup";

// @ts-expect-error This is a recursive type
type NestedValue = undefined | string | Record<string, NestedValue>;

export const getNestedValueError = (errorObject: NestedValue) => {
  if (!errorObject) return undefined;

  if (typeof errorObject === "string") {
    return errorObject;
  }

  return getNestedValueError(Object.values(errorObject)?.[0]) ?? undefined;
};

const TimeIntervalSchema = yup.object().shape({
  interval: yup.string().oneOf(Object.values(IntervalUnit)).required(),
  quantity: yup.number().nullable().required("Quantity is required"),
  direction: yup.string().oneOf(Object.values(RelativeDirection)).required(),
});

const RawColumnSchema = yup.object().shape({
  type: yup.string().oneOf(["raw"]).required(),
  // Could be a string or a number
  modelId: yup.string().required(),
  name: yup.string().required(),
});

const RelatedColumnSchema = yup.object().shape({
  type: yup.string().oneOf(["related"]).required(),
  path: yup.array().of(yup.string()),
  // TODO: Missing TraitColumn, EventTraitColumn, InlineTraitColumn schemas
  column: RawColumnSchema.required(),
}) as yup.MixedSchema<RelatedColumn>;

const getPropertySchema = (
  property: PropertyCondition["property"],
):
  | yup.MixedSchema<string>
  | yup.ObjectSchema<RawColumn>
  | yup.MixedSchema<RelatedColumn>
  | yup.MixedSchema<null> => {
  if (!property || typeof property === "string") {
    return yup.string().nullable().required("A column selection is required");
  }

  if (property.type === "raw") {
    return RawColumnSchema.required();
  }

  if (property.type === "related") {
    return RelatedColumnSchema.required();
  }

  return RawColumnSchema.required();
};

// TODO(samuel): keep building this out in future PRs with the goal of using yup for validation in the query builder
export const getPropertyConditionSchema = ({
  allowsMultiValue,
  allowPercentile,
}: {
  allowsMultiValue: boolean;
  allowPercentile: boolean;
}) =>
  yup.lazy((condition: PropertyCondition | undefined) => {
    const schema: yup.ObjectSchemaDefinition<PropertyCondition> = {
      property: getPropertySchema(condition?.property ?? null),
      type: yup.string().oneOf([ConditionType.Property]).required(),
      propertyType: yup.string().oneOf(Object.values(ColumnType)).required(),
      operator: yup.string().nullable().required("Operator is required"),
      value: yup.mixed().oneOf([null]),
    };

    if (!condition) {
      return yup.object().shape(schema);
    }

    // Validate by column type
    if (condition.propertyType === ColumnType.String) {
      if (OperatorsWithoutValue.includes(condition.operator)) {
        // Value must be null
      } else if (
        allowsMultiValue &&
        MultiValueOperatorsByColumnType[ColumnType.String].includes(
          condition.operator,
        )
      ) {
        // Multi value
        schema.value = yup.array().of(yup.string()).nullable().required();
      } else {
        // Single value
        schema.value = yup.string().nullable().required("A value is required");
      }
    }

    if (
      condition.propertyType === ColumnType.Number ||
      condition.propertyType === ColumnType.BigInt
    ) {
      if (OperatorsWithoutValue.includes(condition.operator)) {
        schema.value = yup.mixed().oneOf([null]);
      } else if (
        allowsMultiValue &&
        MultiValueOperatorsByColumnType[ColumnType.Number].includes(
          condition.operator,
        )
      ) {
        schema.value = yup.array().of(yup.number()).required();
      } else {
        schema.value = yup.number().nullable().required("A value is required");
      }

      if (allowPercentile) {
        // todo
      }
    }

    if (
      condition.propertyType === ColumnType.Timestamp ||
      condition.propertyType === ColumnType.Date
    ) {
      // Intervals
      if (IntervalOperators.includes(condition.operator)) {
        if (condition.timeType === "relative") {
          schema.value = TimeIntervalSchema;
        } else if (condition.timeType === "absolute") {
          schema.value = yup.object().shape({
            before: yup.string().required("Before date required"),
            after: yup.string().required("After date required"),
          });
        }
      }

      // Single dates
      else if (condition.timeType === "relative") {
        schema.value = TimeIntervalSchema;
      } else if (condition.timeType === "absolute") {
        schema.value = yup.string().nullable().required("Date is required");
      }
    }

    if (condition.propertyType === ColumnType.Boolean) {
      schema.value = yup.boolean().nullable().required("A value is required");
    }

    if (condition.propertyType === ColumnType.JsonArrayStrings) {
      // todo
    }
    if (condition.propertyType === ColumnType.JsonArrayNumbers) {
      // todo
    }

    // Not supported
    // if (condition.propertyType === ColumnType.Json) {}
    // if (condition.propertyType === ColumnType.Null) {}
    // if (condition.propertyType === ColumnType.Unknown) {}

    return yup.object().shape(schema);
  });
