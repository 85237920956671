export enum SchemaModelType {
  Parent = "parent",
  Event = "event",
  // Interaction is a subclass of Event, it appears the same but with additional features
  Interaction = "interaction",
  Related = "related",
  Catalog = "catalog",
  Group = "group-node",
  Asset = "asset",
  AdStats = "ad-stats",
}
