import { FC, ReactNode } from "react";

import { Column } from "@hightouchio/ui";

export const Form: FC<{
  children: ReactNode;
  compact?: boolean;
}> = ({ children, compact }) => {
  return (
    <Column flex={1} gap={compact ? 3 : 6}>
      {children}
    </Column>
  );
};
