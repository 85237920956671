import { Text, Column, Skeleton, SkeletonBox, Box } from "@hightouchio/ui";
import {
  padTimeseriesDataWithBuckets,
  ChartGranularity,
  ChartGranularityMetadata,
} from "src/components/charts/chart-utils";
import { Sparkline } from "src/components/charts/sparkline";
import { useEventMetricsQuery } from "src/graphql";
import { isPresent } from "ts-extras";
import { sum } from "lodash";
import { minutesToMilliseconds } from "date-fns";

export const EventVolumeDisplay = ({
  sourceId,
  eventName,
  eventType,
  startTime,
  endTime,
  granularity,
}: {
  sourceId: string;
  eventName?: string;
  eventType?: string;
  startTime: Date;
  endTime: Date;
  granularity: ChartGranularity;
}) => {
  const { isLoading, data } = useEventMetricsQuery(
    {
      input: {
        id: sourceId,
        dimensions: {
          eventName,
          eventType,
        },
        resourceType: "SOURCE",
        requests: [
          {
            aggregateRequests: {
              aggType: "SUM",
              endTime: endTime.valueOf(),
              startTime: startTime.valueOf(),
              metrics: ["RECEIVED"],
            },
            timeseriesRequests: {
              endTime: endTime.valueOf(),
              startTime: startTime.valueOf(),
              metrics: ["RECEIVED"],
              interval: {
                binInterval: ChartGranularityMetadata[granularity].binHours,
                binType: "HOURS",
              },
            },
          },
        ],
      },
    },
    {
      refetchOnMount: false,
      staleTime: minutesToMilliseconds(2),
      select: (data) => ({
        timeseriesData: padTimeseriesDataWithBuckets(
          data.getEventMetrics.results
            .find((result) => isPresent(result.timeseriesData))
            ?.timeseriesData?.at(0)
            ?.dataPoints.map((point) => ({
              timestamp: new Date(point.timestamp),
              data: { value: point.value },
            })) || [],
          { value: 0 },
          granularity,
          startTime,
        ).map((point) => point.data),
        volume: sum(
          data.getEventMetrics.results
            .find((result) => isPresent(result.aggregateData))
            ?.aggregateData?.map((data) => data?.value),
        ).toLocaleString(),
      }),
    },
  );

  const haveTimeseriesData =
    data && data.timeseriesData && data.timeseriesData.length;

  return (
    <Skeleton isLoading={isLoading}>
      {data && haveTimeseriesData ? (
        <Column alignItems="end">
          <Text size="sm">{data.volume}</Text>
          <SkeletonBox width={120} height={25}>
            <Sparkline data={data.timeseriesData.slice(0, -1)} />
          </SkeletonBox>
        </Column>
      ) : (
        <Column alignItems="end">
          <Text color="text.secondary" size="sm">
            No data
          </Text>
          <Box
            width={120}
            height={2}
            borderBottom="1px"
            borderBottomColor="text.tertiary"
          ></Box>
        </Column>
      )}
    </Skeleton>
  );
};
