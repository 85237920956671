import { FC, ReactNode } from "react";

import {
  Box,
  ChakraModal,
  ChakraModalBody,
  ChakraModalContent,
  ChakraModalFooter,
  ChakraModalHeader,
  ChakraModalOverlay,
  Heading,
  Paragraph,
  Button,
} from "@hightouchio/ui";

import { Link } from "src/router";

import { Logo } from "src/ui/brand";
import { CheckList, CheckListItem } from "src/ui/checklist";
import { Confetti } from "src/ui/confetti";

type Props = {
  plan: string;
  checkList: (string | ReactNode)[];
  isOpen?: boolean;
  onClose: () => void;
};

export const PlanWelcomeModal: FC<Readonly<Props>> = ({
  plan,
  checkList,
  isOpen,
  onClose,
}) => {
  return (
    <ChakraModal
      isCentered
      isOpen={Boolean(isOpen)}
      size="xl"
      onClose={onClose}
    >
      <ChakraModalOverlay />
      <ChakraModalContent p={0} maxHeight="85%" overflowY="auto" my="auto">
        <ChakraModalHeader p={4}>
          <Logo theme="dark" />
          <Heading mt={4} size="xl">
            Welcome to the {plan} plan!
          </Heading>
        </ChakraModalHeader>
        <ChakraModalBody px={4}>
          <Box>
            <Confetti showConfetti={true} />
            <Paragraph id="plan-welcome-modal--supports">
              Your Hightouch plan now supports...
            </Paragraph>

            <CheckList aria-labelledby="plan-welcome-modal--supports">
              {checkList?.map((listItem, index) => {
                return <CheckListItem key={index}>{listItem}</CheckListItem>;
              })}
            </CheckList>
          </Box>
        </ChakraModalBody>
        <ChakraModalFooter
          p={4}
          mt={0}
          borderTop="1px solid"
          borderColor="base.border"
          boxShadow="sm"
        >
          <Paragraph>
            If you have additional questions, check out our{" "}
            <Link
              href={
                plan === "Enterprise"
                  ? `${import.meta.env.VITE_DOCS_URL}/pricing/bt-pricing`
                  : `${import.meta.env.VITE_DOCS_URL}/pricing/ss-pricing`
              }
            >
              plans and pricing docs
            </Link>{" "}
            for guidance.
          </Paragraph>
          <Button
            isJustified
            mt={4}
            type="button"
            variant="primary"
            onClick={onClose}
          >
            Continue working in Hightouch
          </Button>
        </ChakraModalFooter>
      </ChakraModalContent>
    </ChakraModal>
  );
};
