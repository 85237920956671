import { Spinner } from "@hightouchio/ui";

import { useTransformedSyncRunConfigurationQuery } from "src/graphql";
import { useOutletContext } from "src/router";
import { Configuration } from "src/pages/syncs/sync/run/configuration";
import { Context } from ".";

export const SyncRunConfiguration = () => {
  const { sync, attempt } = useOutletContext<Context>();

  const syncId = sync.id;
  const runId = attempt?.sync_request?.id;
  const model = sync?.segment;
  const destination = sync?.destination;
  const syncRequest = attempt?.sync_request;

  const { data: prevConfig, isLoading } =
    useTransformedSyncRunConfigurationQuery(
      { id: syncId ?? "", runId: runId ?? "" },
      {
        enabled: Boolean(runId && syncId),
        select: (data) => data.getTransformedSyncRunConfiguration,
      },
    );

  if (isLoading) {
    return <Spinner size="lg" m="auto" />;
  }

  return (
    <Configuration
      destination={destination}
      model={model}
      prevConfig={prevConfig}
      prevConfigDate={syncRequest?.created_at}
      sync={sync}
    />
  );
};
