import { FC, useState, useEffect } from "react";

import {
  useToast,
  Button,
  LockIcon,
  UserIcon,
  Text,
  Box,
  Textarea,
} from "@hightouchio/ui";

import { DestinationDefinitionFragment as DestinationDefinition } from "src/graphql";
import * as analytics from "src/lib/analytics";
import { Card } from "src/components/card";

export type DestinationAccessCardProps = {
  category: string;
  definition: DestinationDefinition | undefined;
  onClear: () => void;
};

export const DestinationAccessCard: FC<
  Readonly<DestinationAccessCardProps>
> = ({ category, definition, onClear }) => {
  const [formVisible, setFormVisible] = useState(false);
  const { toast } = useToast();
  const [requestFormText, setRequestFormText] = useState("");

  useEffect(() => {
    if (definition?.name) {
      setFormVisible(false);
    }
  }, [definition?.name]);

  const setDestinationRequestIntent = () => {
    analytics.track("Destination Catalog Request Clicked", {
      destination_name: definition?.name,
      destination_slug: definition?.type,
      destination_status: definition?.status,
      current_category: category,
    });
    setFormVisible(true);
  };

  const submitDestinationRequest = (event) => {
    event.preventDefault();
    analytics.track("Destination Catalog Request Form Submitted", {
      destination_name: definition?.name,
      destination_slug: definition?.type,
      destination_status: definition?.status,
      current_category: category,
      request_form_text: requestFormText,
    });

    onClear();

    toast({
      id: "submit-destination-request",
      title: `Thank you for your interest in ${definition?.name}! We will follow up with you shortly.`,
      variant: "success",
    });
  };

  if (definition?.status === "coming_soon") {
    return (
      <Card>
        <Text mb={5}>
          This destination is <strong>coming soon</strong>.
        </Text>
        {!formVisible && (
          <Button onClick={setDestinationRequestIntent} icon={UserIcon}>
            Notify me when it's available
          </Button>
        )}
        {formVisible && (
          <Box as="form" onSubmit={(event) => submitDestinationRequest(event)}>
            <Textarea
              width="100%"
              aria-label="Tell us about your use case. (optional)"
              my={6}
              name="usageRequest"
              placeholder="Tell us about your use case. (optional)"
              rows={6}
              value={requestFormText}
              onChange={(e) => setRequestFormText(e.target.value)}
            />
            <Button variant="primary" type="submit">
              Join waitlist
            </Button>
          </Box>
        )}
      </Card>
    );
  }

  if (definition?.status === "alpha") {
    return (
      <Card>
        <Text>
          This destination is in <strong>private preview</strong>.
        </Text>
        <Text my={5}>
          We are working with a small group of users to verify functionality.
        </Text>
        {!formVisible && (
          <Button onClick={setDestinationRequestIntent} icon={LockIcon}>
            Request early access
          </Button>
        )}
        {formVisible && (
          <Box as="form" onSubmit={(event) => submitDestinationRequest(event)}>
            <Textarea
              width="100%"
              aria-label={`Tell us about your ${definition?.name} use case. Please be specific about what you hope to accomplish.`}
              my={6}
              placeholder={`Tell us about your ${definition?.name} use case. Please be specific about what you hope to accomplish.`}
              rows={6}
              value={requestFormText}
              onChange={(e) => setRequestFormText(e.target.value)}
            />
            <Button variant="primary" type="submit">
              Submit request
            </Button>
          </Box>
        )}
      </Card>
    );
  }

  if (
    definition?.status === "released" &&
    definition?.requiresInternalApproval
  ) {
    return (
      <Card>
        <Text>
          This destination requires additional configuration before it can be
          enabled for your use. Please reach out to get started!
        </Text>
      </Card>
    );
  }

  return null;
};
