import { FC } from "react";

import { Column, MultiSelect, Text } from "@hightouchio/ui";
import { Controller, useWatch } from "react-hook-form";

import {
  allTransformationOptions,
  transformationOptionsByIdentifier,
} from "src/pages/identity-resolution/types";
import { ErrorMessage } from "src/components/explore/visual/error-message";

export const TransformationOptions: FC<{ path: string }> = ({ path }) => {
  const identifier = useWatch({ name: `${path}.identifier` });

  return (
    <Controller
      name={`${path}.transformations`}
      render={({ field: { ref, ...field }, fieldState }) => {
        const identifierTransformationOptions =
          transformationOptionsByIdentifier[identifier];

        if (identifierTransformationOptions?.length === 0) {
          return <></>;
        }

        return (
          <>
            {field.value.length > 0 && (
              <Text color="text.secondary" ml={-0.5}>
                and apply
              </Text>
            )}
            <Column>
              <MultiSelect
                {...field}
                isDisabled={!identifier}
                placeholder="+ option"
                width="auto"
                variant="heavy"
                options={
                  identifierTransformationOptions ?? allTransformationOptions
                }
                value={field.value || []}
              />
              {fieldState.error?.message && (
                <ErrorMessage>{fieldState.error.message}</ErrorMessage>
              )}
            </Column>
          </>
        );
      }}
    />
  );
};
