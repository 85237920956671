import { FC } from "react";

import {
  Box,
  Column,
  InformationIcon,
  MetricIcon,
  Row,
  Text,
  TimeIcon,
  Tooltip,
} from "@hightouchio/ui";
import { format } from "date-fns";
import range from "lodash/range";
import { format as formatNumber } from "numerable";
import pluralize from "pluralize";

import { Graph } from "src/components/analytics/cross-audience-graph/types";
import { IconBox } from "src/components/icon-box";
import { commaNumber } from "src/utils/numbers";

type Props = {
  summaryStats: Graph["summary"];
};

const formatDate = (dateStr: string) => format(new Date(dateStr), "M/d/yy");

export const SummaryStats: FC<Readonly<Props>> = ({ summaryStats }) => {
  const hasSummaryStats = summaryStats.length > 0;
  const hasMultipleMetrics = summaryStats.length > 1;

  if (!hasSummaryStats) {
    return null;
  }

  const startDateStr = summaryStats[0]
    ? formatDate(summaryStats[0].timeWindow.start)
    : ""; // typeguard to satisfy tsc
  const endDateStr = summaryStats[0]
    ? formatDate(summaryStats[0].timeWindow.end)
    : ""; // typeguard to satisfy tsc

  // If there are multiple emtrics, the first column will represent the metric name
  const gridTemplateColumns = `${
    hasMultipleMetrics ? "1fr" : ""
  } repeat(${summaryStats[0]?.data.length}, 1fr)`;

  return (
    <>
      <Column
        background="base.lightBackground"
        border="1px solid"
        borderColor="base.border"
        borderRadius="md"
        gap={2}
        my={4}
        mx={6}
        py={4}
        px={6}
      >
        <Text color="text.secondary" size="sm">
          Performance summary for {startDateStr} → {endDateStr}
        </Text>
        <Column>
          {/* Table headers */}
          <Box
            alignItems="center"
            display="grid"
            gridTemplateColumns={gridTemplateColumns}
            sx={{
              ...(hasMultipleMetrics
                ? { borderBottom: "1px solid", borderColor: "base.border" }
                : {}),
            }}
          >
            {hasMultipleMetrics && <ColumnHeader name="Metric" />}
            {summaryStats?.[0]?.data.map(
              ({ audienceName, splitName, isNormalized, rawValue, size }) => (
                <ColumnHeader
                  key={splitName}
                  name={splitName ?? audienceName ?? ""}
                  isNormalized={isNormalized}
                  rawValue={rawValue}
                  size={size}
                />
              ),
            )}
          </Box>

          {/* Rows -- each row is a metric */}
          {range(summaryStats.length).map((index) => (
            <Row
              key={index}
              display="grid"
              gridTemplateColumns={gridTemplateColumns}
              gap={hasMultipleMetrics ? 0 : 2}
            >
              {hasMultipleMetrics && (
                <Row alignItems="center" gap={2} pl={2}>
                  <IconBox
                    bg={
                      summaryStats[index]?.isSavedMetric
                        ? "peridot.400"
                        : "cyan.400"
                    }
                    boxSize="20px"
                    icon={
                      summaryStats[index]?.isSavedMetric ? (
                        <MetricIcon />
                      ) : (
                        <TimeIcon />
                      )
                    }
                    iconSize="14px"
                  />
                  <CellValue
                    value={summaryStats[index]?.metricName ?? ""}
                    variant={hasMultipleMetrics ? "small" : "large"}
                  />
                </Row>
              )}
              {summaryStats[index]?.data.map(
                ({
                  splitName,
                  isBaseline,
                  isWinner,
                  value,
                  percentDifference,
                }) => (
                  <CellValue
                    key={splitName}
                    value={commaNumber(value)}
                    variant={hasMultipleMetrics ? "small" : "large"}
                    isBaseline={isBaseline}
                    isWinner={isWinner}
                    percentDifference={percentDifference}
                  />
                ),
              )}
            </Row>
          ))}
        </Column>
      </Column>
    </>
  );
};

const ColumnHeader = ({
  name,
  isNormalized,
  rawValue,
  size,
}: {
  name: string;
  isNormalized?: boolean;
  rawValue?: number;
  size?: number;
}) => (
  <Column p={2}>
    <Text fontWeight="medium">{name}</Text>
    {isNormalized && (
      <Row gap={1} alignItems="center">
        <Text color="text.secondary" size="sm">
          Normalized
        </Text>
        <Tooltip
          message={`${name} performance has been adjusted to match the size of the largest treatment group. The absolute value is ${rawValue} for ${pluralize(
            "total users",
            size,
            true,
          )}.`}
        >
          <InformationIcon color="text.secondary" />
        </Tooltip>
      </Row>
    )}
  </Column>
);

const CellValue = ({
  value,
  variant,
  isBaseline,
  isWinner,
  percentDifference,
}: {
  value: string;
  variant: "small" | "large";
  isBaseline?: boolean;
  isWinner?: boolean;
  percentDifference?: number;
}) => (
  <Box
    display="flex"
    background={isWinner ? "avocado.background" : undefined}
    sx={{
      ...(variant === "small"
        ? { alignItems: "center", pl: 2, py: 4, gap: 2, flexDirection: "row" }
        : { gap: 4, flexDirection: "column" }),
    }}
  >
    <Box
      as={Text}
      fontWeight="medium"
      sx={{
        ...(variant === "small"
          ? { fontSize: "md" }
          : { fontSize: "4xl", pt: 4, pl: 2 }),
      }}
      fontSize={variant === "small" ? "md" : "4xl"}
    >
      {value}
    </Box>
    <Box pl={variant === "large" ? 2 : 0} pb={variant === "large" ? 2 : 0}>
      {isBaseline ? (
        <Box
          borderRadius="md"
          border="1px solid"
          borderColor="base.border"
          px={2}
          height="fit-content"
          width="fit-content"
        >
          <Text color="text.secondary" size="sm">
            Baseline
          </Text>
        </Box>
      ) : (
        percentDifference != null && <Percentage value={percentDifference} />
      )}
    </Box>
  </Box>
);

const Percentage = ({ value }: { value: number }) => {
  if (value === 0) {
    // Don't show zero percentage
    return null;
  }

  return (
    <Text color={value > 0 ? "avocado.base" : "danger.base"}>
      {formatNumber(value, { defaultPattern: "+0%", rounding: "truncate" })}
    </Text>
  );
};
