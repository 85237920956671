import { useEffect, useState } from "react";
import debounce from "lodash/debounce";

/**
 * Returns the relative viewport size
 * @returns {Object} size - The relative viewport size
 */
export const useRelativeViewportSize = () => {
  const [size, setSize] = useState({
    relativeWidth: window.innerWidth,
    relativeHeight: window.innerHeight,
  });

  useEffect(() => {
    const determineSize = () => {
      const zoomLevel = window.outerHeight / window.innerHeight;
      const relativeHeight = window.innerHeight / zoomLevel;
      const relativeWidth = window.innerWidth / zoomLevel;
      setSize({ relativeWidth: relativeWidth, relativeHeight: relativeHeight });
    };

    const debouncedDetermineSize = debounce(determineSize, 200);

    // Initial call to set size
    determineSize();

    window.addEventListener("resize", debouncedDetermineSize);

    return () => {
      window.removeEventListener("resize", debouncedDetermineSize);
    };
  }, []);

  return size;
};
