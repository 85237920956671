import {
  IconButton,
  Button,
  Column,
  Row,
  PlusIcon,
  FormField,
  Box,
  DeleteIcon,
  TagInput,
  EditableText,
  Tooltip,
} from "@hightouchio/ui";
import { Controller, useFieldArray } from "react-hook-form";

export const Variables = () => {
  const { fields, append, remove } = useFieldArray({
    name: "variables",
  });

  return (
    <Column align="flex-start" gap={4} maxW="2xl" w="100%">
      <FormField
        label="Variables"
        description="Define other variables to experiment with different dynamic content blocks, images, copy, and more."
        isOptional
      >
        <Column gap={4} width="100%">
          {fields.map(({ id }, index) => (
            <Column key={id} bg="gray.100" p={4} gap={4} borderRadius="md">
              <Row align="center" justify="space-between">
                <Controller
                  name={`variables.${index}.name`}
                  render={({ field }) => {
                    return (
                      <EditableText
                        fontWeight="medium"
                        onChange={field.onChange}
                        value={field.value || "Variable name"}
                      />
                    );
                  }}
                />
                <Tooltip message="Delete variable">
                  <Box
                    as={IconButton}
                    icon={DeleteIcon}
                    variant="danger"
                    aria-label="Delete variable"
                    onClick={() => {
                      remove(index);
                    }}
                  />
                </Tooltip>
              </Row>
              <VariableValues index={index} />
            </Column>
          ))}
        </Column>
      </FormField>
      <Button
        icon={PlusIcon}
        variant="secondary"
        onClick={() => {
          append({ name: "", values: [] });
        }}
      >
        Add variable
      </Button>
    </Column>
  );
};

const VariableValues = ({ index }) => {
  return (
    <FormField label="Possible values">
      <Controller
        name={`variables.${index}.values`}
        render={({ field }) => {
          return (
            <TagInput
              supportsCreatableOptions
              width="100%"
              value={field.value}
              onChange={field.onChange}
              optionLabel={(value) => value}
              optionValue={(value) => value}
              onCreateOption={(createdValue) => {
                field.onChange([...field.value, createdValue]);
              }}
              options={(field.value ?? []) as Array<string>}
            />
          );
        }}
      />
    </FormField>
  );
};
