import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const PagerDutyIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        d="M15.6022 3.885C14.2382 3.123 13.2834 3 11.0445 3H6V13.9125H11.0249C13.015 13.9125 14.5074 13.7895 15.8206 12.885C17.2534 11.9078 18 10.272 18 8.37975C18 6.34275 17.0844 4.7085 15.6022 3.885ZM11.6118 11.6295H8.55744V5.33475L11.4428 5.3145C14.0692 5.2935 15.3823 6.24 15.3823 8.4105C15.3823 10.7453 13.7514 11.6295 11.6118 11.6295ZM6 16.2075H8.55744V21H6V16.2075Z"
        fill="#06AC38"
      />
    </Icon>
  );
};
