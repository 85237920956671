import { FC, useState } from "react";

import {
  Box,
  Column,
  ConfirmationDialog,
  Heading,
  Paragraph,
  Row,
  Text,
} from "@hightouchio/ui";

import { PermissionedButton } from "src/components/permission";
import { EnrichmentStatusQuery } from "src/graphql";
import { formatDurationDisplay } from "src/utils/match-boosting";

type Props = {
  status: EnrichmentStatusQuery["enrichmentStatus"] | undefined | null;
  sidebar: boolean;
  modelId: string | number;
  cancelInitializing: () => void;
};

export const MatchBoosterInitializing: FC<Readonly<Props>> = ({
  status,
  sidebar,
  modelId,
  cancelInitializing,
}) => {
  const [isConfirmCancelOpen, setConfirmCancelOpen] = useState(false);

  return (
    <>
      <InitializingBox
        initializingStatus={status}
        onCancel={() => setConfirmCancelOpen(true)}
        sidebar={sidebar}
        modelId={modelId}
      />

      <ConfirmationDialog
        confirmButtonText="Confirm"
        isOpen={isConfirmCancelOpen}
        title="Cancel Match Booster"
        variant="danger"
        onClose={() => setConfirmCancelOpen(false)}
        onConfirm={cancelInitializing}
        cancelButtonText="Never mind"
      >
        <Paragraph>
          Stopping the initialization process will require the process to start
          again from the beginning.
        </Paragraph>
      </ConfirmationDialog>
    </>
  );
};

const InitializingBox = ({
  initializingStatus,
  onCancel,
  sidebar,
  modelId,
}: {
  initializingStatus:
    | EnrichmentStatusQuery["enrichmentStatus"]
    | undefined
    | null;
  onCancel: () => void;
  sidebar: boolean;
  modelId: string | number;
}) => {
  const startTime = initializingStatus?.created_at;
  const duration =
    startTime &&
    formatDurationDisplay({ start: new Date(startTime), end: new Date() });

  return (
    <Box
      border="1px solid"
      borderColor="base.border"
      borderRadius="md"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      pb={10}
      mt={sidebar ? 6 : undefined}
      mx={sidebar ? 6 : undefined}
    >
      <Column gap={4} maxWidth="lg" mb={4} alignItems="center">
        <Row maxH="155px" pt={10}>
          <Box
            as="video"
            autoPlay
            muted
            loop
            playsInline
            src="https://cdn.sanity.io/files/pwmfmi47/production/ab149841e703a8de81d2e9669517612886d6d4af.mp4"
            maxHeight="100%"
            width="100%"
          />
        </Row>

        {startTime && (
          <Row>
            <Text fontWeight="semibold" color="text.secondary" mr={1}>
              Time elapsed:
            </Text>
            <Text color="text.secondary">{duration || "1 min"}</Text>
          </Row>
        )}
        <Heading size="lg">Initializing Match Booster...</Heading>

        <Row mb={2}>
          <Paragraph color="text.secondary" size="md" textAlign="center">
            Our system is currently analyzing your model to find the optimal
            configuration for Match Booster. This calibration process will
            happen once for your workspace and can take up to 72 hours.
          </Paragraph>
        </Row>
      </Column>

      <PermissionedButton
        permission={{
          v1: {
            resource: "audience_schema",
            grant: "update",
          },
          v2: {
            resource: "model",
            grant: "can_update",
            id: modelId,
          },
        }}
        size="md"
        variant="secondary"
        onClick={onCancel}
      >
        Cancel
      </PermissionedButton>
    </Box>
  );
};
