import { FC, useEffect } from "react";

import {
  Spinner,
  FormField,
  IconButton,
  Row,
  RefreshIcon,
  Combobox,
} from "@hightouchio/ui";
import * as Yup from "yup";

import {
  useAirtableListBasesQuery,
  useAirtableListTablesQuery,
} from "src/graphql";

import { CustomQueryViewProps, CustomQueryFormProps } from "../custom-query";

export const QueryForm: FC<Readonly<CustomQueryFormProps>> = ({
  source,
  query,
  onChange,
  setError,
}) => {
  const {
    data: bases,
    isLoading: basesLoading,
    error: basesError,
    refetch: listBases,
  } = useAirtableListBasesQuery({ connectionId: String(source.id) });

  const {
    data: tables,
    isLoading: tablesLoading,
    error: tablesError,
    refetch: listTables,
  } = useAirtableListTablesQuery(
    { connectionId: String(source.id), baseId: query?.baseId ?? "" },
    { enabled: Boolean(query?.baseId) },
  );

  useEffect(() => {
    if (basesError || tablesError) {
      setError(basesError || tablesError);
    }
  }, [basesError, tablesError]);

  return (
    <>
      <FormField label="Base" error={basesError?.message}>
        <Row gap={2}>
          <Combobox
            isLoading={basesLoading}
            options={
              bases?.airtableListBases?.map((base) => ({
                label: base.name,
                value: base.id,
              })) ?? []
            }
            placeholder="Select a base..."
            value={query?.baseId}
            onChange={(baseId) => onChange({ ...query, baseId })}
          />
          <IconButton
            aria-label="Refresh bases"
            icon={RefreshIcon}
            isDisabled={tablesLoading}
            variant="secondary"
            onClick={() => listBases()}
          />
        </Row>
      </FormField>
      <FormField label="Table" error={tablesError?.message}>
        <Row gap={2}>
          <Combobox
            isDisabled={!query?.baseId}
            isLoading={tablesLoading}
            options={
              tables?.airtableListTables?.map((table) => ({
                label: table.name,
                value: table.id,
              })) ?? []
            }
            placeholder="Select a table..."
            value={query?.tableId}
            onChange={(tableId) =>
              onChange({ ...query, tableId, viewId: undefined })
            }
          />
          <IconButton
            aria-label="Refresh tables"
            icon={RefreshIcon}
            isDisabled={tablesLoading}
            variant="secondary"
            onClick={() => listTables()}
          />
        </Row>
      </FormField>
      {query?.tableId && (
        <FormField isOptional label="View">
          <Combobox
            isLoading={tablesLoading}
            options={
              tables?.airtableListTables
                ?.find((table) => table.id === query?.tableId)
                ?.views?.map((view) => ({
                  label: view.name,
                  value: view.id,
                })) ?? []
            }
            placeholder="Select a view..."
            value={query?.viewId as any}
            onChange={(viewId) => onChange({ ...query, viewId })}
          />
        </FormField>
      )}
    </>
  );
};

export const QueryView: FC<Readonly<CustomQueryViewProps>> = ({
  source,
  query,
  setError,
}) => {
  const {
    data: bases,
    isLoading: basesLoading,
    error: basesError,
  } = useAirtableListBasesQuery({ connectionId: String(source.id) });

  const {
    data: tables,
    isLoading: tablesLoading,
    error: tablesError,
  } = useAirtableListTablesQuery(
    { connectionId: String(source.id), baseId: String(query.baseId) },
    { enabled: Boolean(query?.baseId) },
  );

  useEffect(() => {
    if (basesError || tablesError) {
      setError(basesError || tablesError);
    }
  }, [basesError, tablesError]);

  const base = bases?.airtableListBases?.find(
    (base) => base.id === query.baseId,
  );
  const baseName = base ? base.name : `Unknown base with id ${query.baseId}`;
  const table = tables?.airtableListTables?.find(
    (table) => table.id === query.tableId,
  );
  const tableName = table
    ? table.name
    : `Unknown table with id ${query.tableId}`;
  let view: Record<string, unknown> | null | undefined = null;
  let viewName: string | null = null;
  if (query.viewId) {
    view = table?.views?.find((view) => view.id === query.viewId);
    viewName = view
      ? String(view.name)
      : `Unknown view with id ${query.viewId}`;
  }

  return (
    <>
      <FormField label="Base">
        {basesLoading ? <Spinner /> : baseName}
      </FormField>
      <FormField label="Table">
        {tablesLoading ? <Spinner /> : tableName}
      </FormField>
      {viewName && <FormField label="View">{viewName}</FormField>}
    </>
  );
};

export const querySchema = Yup.object().shape({
  type: Yup.string().required().equals(["table"]),
  baseId: Yup.string().required(),
  tableId: Yup.string().required(),
  viewId: Yup.string().notRequired(),
});
