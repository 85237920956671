import { FC } from "react";

import {
  ChakraModal,
  ChakraModalOverlay,
  ChakraModalContent,
  ChakraModalFooter,
  ChakraModalBody,
  Row,
  Heading,
  Column,
  Button,
  SuccessIcon,
} from "@hightouchio/ui";
import { useNavigate } from "src/router";
import noop from "lodash/noop";

import * as analytics from "src/lib/analytics";
import { Confetti } from "src/ui/confetti";

type ConfettiModalProps = {
  syncId: string;
};

export const ConfettiModal: FC<ConfettiModalProps> = ({ syncId }) => {
  const navigate = useNavigate();

  return (
    <ChakraModal isCentered isOpen onClose={noop}>
      <ChakraModalOverlay />
      <ChakraModalContent p={0} maxHeight="85%" overflowY="auto" my="auto">
        <ChakraModalBody overflowY="auto" p={4} mt={0}>
          <Confetti showConfetti>
            <Column align="center">
              <Row fontSize="64px">
                <SuccessIcon color="success.base" />
              </Row>

              <Heading size="lg" my={8}>
                Your first sync has been added to this workspace!
              </Heading>
            </Column>
          </Confetti>
        </ChakraModalBody>

        <ChakraModalFooter p={4} mt={0}>
          <Row
            width="100%"
            justify="stretch"
            gap={4}
            sx={{ a: { width: "100%" }, button: { width: "100%" } }}
          >
            <Button
              size="lg"
              variant="secondary"
              onClick={() => {
                navigate(`/syncs/${syncId}`, {});
                analytics.track("Go To Syncs Clicked", {});
              }}
            >
              View sync
            </Button>
            <Button
              size="lg"
              variant="primary"
              onClick={() => {
                navigate(`/syncs/${syncId}?autorun=true`, {});
                analytics.track("Onboarding Run Sync Button Clicked", {
                  sync_id: syncId,
                });
              }}
            >
              Run sync
            </Button>
          </Row>
        </ChakraModalFooter>
      </ChakraModalContent>
    </ChakraModal>
  );
};
