import { FC, useState } from "react";

import {
  Box,
  Column,
  SectionHeading,
  Heading,
  Row,
  FormField,
  TextInput,
  Button,
  EmptyState,
  IconButton,
  DeleteIcon,
  ClipboardButton,
  useToast,
  Alert,
} from "@hightouchio/ui";
import { LinkButton } from "src/router";
import { Link } from "src/router";
import { captureException } from "@sentry/react";
import { sha256 } from "js-sha256";
import { v4 as uuidv4 } from "uuid";
import placeholder from "src/assets/placeholders/generic.svg";
import { DeleteConfirmationModal } from "src/components/modals/delete-confirmation-modal";
import {
  useAccessibleOrganizationsQuery,
  useApiKeysQuery,
  useCreateApiKeyV2Mutation,
  useDeleteApiKeyMutation,
} from "src/graphql";
import { Workspaces } from "src/pages/workspaces/workspaces";
import { Logo } from "src/ui/brand";
import { switchWorkspace } from "src/utils/workspaces";
import { formatFriendlyDistanceToNow } from "src/utils/time";

export const PartnerDashboard: FC = () => {
  const { toast } = useToast();
  const [token, setToken] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);

  const { data: apiKey } = useApiKeysQuery(
    { filter: { workspace_id: { _is_null: true } } },
    { select: (data) => data.api_keys?.[0], suspense: true },
  );

  const createApiKeyMutation = useCreateApiKeyV2Mutation();
  const deleteApiKeyMutation = useDeleteApiKeyMutation();

  const createApiKey = async () => {
    const token = uuidv4();
    const hash = sha256.create().update(token).hex();
    try {
      await createApiKeyMutation.mutateAsync({
        input: { name: "Partner API", hash },
      });
      setToken(token);
    } catch (error) {
      captureException(error);
      toast({
        id: "api-key",
        title: "Failed to create API key",
        message: "Please try again.",
        variant: "error",
      });
    }
  };

  const { data: organizationsData } = useAccessibleOrganizationsQuery(
    {},
    { select: (data) => data.getAccessibleOrganizations.organizations },
  );

  const organizations =
    organizationsData?.filter((o) => o.workspaces.some((w) => w.is_member)) ??
    [];

  return (
    <Column width="100%" height="100%">
      <Row
        bg="white"
        borderBottom="1px"
        borderColor="base.border"
        justify="space-between"
        p={6}
        mb={10}
      >
        <Logo variant="full" size="100px" theme="dark" />

        <Row align="center" gap={6}>
          <Link href="https://www.notion.so/hightouch/Hightouch-Partner-API-f8dc92400474429cbea36bc975266693">
            Documentation
          </Link>
          <Link href={`${import.meta.env.VITE_API_BASE_URL}/auth/logout`}>
            Logout
          </Link>
        </Row>
      </Row>
      <Column mx="auto" maxWidth="1000px" width="100%" px={10} pb={10} gap={10}>
        <Heading size="2xl">Partner dashboard</Heading>

        <Column gap={6}>
          <FormField
            label="API Key"
            tip={
              apiKey?.last_used
                ? formatFriendlyDistanceToNow(apiKey.last_used)
                : undefined
            }
          >
            {token ? (
              <Column gap={2}>
                <Alert
                  variant="inline"
                  type="warning"
                  title="Save your key"
                  message="This is your private api key and is not recoverable. Securely save this key before leaving this page."
                />
                <Row gap={2}>
                  <TextInput isReadOnly value={token} />
                  <ClipboardButton text={token} />
                </Row>
              </Column>
            ) : apiKey ? (
              <Row gap={2}>
                <TextInput
                  type="password"
                  value="f8dc92400474429cbea36bc975266693"
                />
                <IconButton
                  aria-label="Delete api key"
                  icon={DeleteIcon}
                  onClick={() => {
                    setIsDeleting(true);
                  }}
                />
              </Row>
            ) : (
              <Button onClick={createApiKey} variant="primary">
                Generate API Key
              </Button>
            )}
          </FormField>
        </Column>
        <Column gap={4}>
          <Heading size="lg">Workspaces</Heading>
          {organizations?.length > 0 ? (
            organizations.map((o) => (
              <Column key={o.slug} gap={4}>
                <Column>
                  <Box
                    color="gray.500"
                    fontSize="sm"
                    fontWeight="semibold"
                    textTransform="uppercase"
                  >
                    Organization
                  </Box>
                  <SectionHeading>{o.name ?? ""}</SectionHeading>
                </Column>
                <Workspaces
                  workspaces={o.workspaces.filter((w) => w.is_member)}
                  onSelect={(id, slug) => switchWorkspace(id, `/${slug}`)}
                />
              </Column>
            ))
          ) : (
            <EmptyState
              actions={
                <LinkButton href="https://www.notion.so/hightouch/Hightouch-Partner-API-f8dc92400474429cbea36bc975266693">
                  View documentation
                </LinkButton>
              }
              imageUrl={placeholder}
              message="Get started by creating your api key and reading the docs. Workspaces that your customers create will be listed here for you to manage."
              title="You have no customer workspaces yet"
            />
          )}
        </Column>
      </Column>
      <DeleteConfirmationModal
        isOpen={isDeleting}
        label="API key"
        onClose={() => {
          setIsDeleting(false);
        }}
        onDelete={async () =>
          deleteApiKeyMutation.mutateAsync({ id: apiKey?.id ?? "" })
        }
      />
    </Column>
  );
};
