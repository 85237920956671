import { FC } from "react";

import {
  Combobox,
  FormField,
  Select,
  TextInput,
  Textarea,
} from "@hightouchio/ui";
import { Controller } from "react-hook-form";

import { ColumnType, TraitType, TraitTypeOptions } from "src/types/visual";

import { SUPPORTED_JSON_ARRAY_SOURCES } from "./utils";

const RAW_SQL_COMMON_RES_TYPES = [
  {
    value: ColumnType.Boolean,
    label: "Boolean",
  },
  {
    value: ColumnType.Number,
    label: "Number",
  },
  {
    value: ColumnType.String,
    label: "String",
  },
  {
    value: ColumnType.Timestamp,
    label: "Timestamp",
  },
  {
    value: ColumnType.Date,
    label: "Date",
  },
];

const RAW_SQL_JSON_ARR_RES_TYPES = [
  {
    value: ColumnType.JsonArrayNumbers,
    label: "JSON Array (Numbers)",
  },
  {
    value: ColumnType.JsonArrayStrings,
    label: "JSON Array (Strings)",
  },
];

export const TraitAggregationFields: FC<
  Readonly<{
    control: any;
    disabled: boolean;
    propertyOptions: any;
    sourceType?: string;
    type: TraitType;
  }>
> = ({ type, control, propertyOptions, disabled, sourceType }) => {
  const rawSqlResultingType = [
    ...RAW_SQL_COMMON_RES_TYPES,
    ...(SUPPORTED_JSON_ARRAY_SOURCES.includes(sourceType ?? "")
      ? RAW_SQL_JSON_ARR_RES_TYPES
      : []),
  ];

  return (
    <>
      <Controller
        control={control}
        name="type"
        render={({ field, fieldState: { error } }) => (
          <FormField
            description="How should the rows be aggregated?"
            label="Aggregation"
            error={error?.message}
          >
            <Select
              width="100%"
              {...field}
              isInvalid={Boolean(error)}
              options={TraitTypeOptions}
            />
          </FormField>
        )}
      />
      {type === TraitType.RawSql && (
        <>
          <Controller
            control={control}
            name="config.aggregation"
            render={({ field, fieldState: { error } }) => (
              <FormField
                description="Rows will be aggregated according to a custom SQL aggregation."
                label="SQL"
                error={error?.message}
              >
                <Textarea
                  width="100%"
                  onChange={field.onChange}
                  value={field.value}
                  isInvalid={Boolean(error)}
                  placeholder={
                    "CASE WHEN SUM({{column \"price\"}}) > 100 THEN 'high' ELSE 'low' END`"
                  }
                />
              </FormField>
            )}
          />
          <Controller
            control={control}
            name="config.defaultValue"
            render={({ field, fieldState: { error } }) => (
              <FormField
                description="Defines the value when the above SQL returns no rows."
                label="Default value"
                error={error?.message}
              >
                <TextInput width="100%" isInvalid={Boolean(error)} {...field} />
              </FormField>
            )}
          />
          <Controller
            control={control}
            name="config.resultingType"
            render={({ field, fieldState: { error } }) => (
              <FormField
                description="Defines the type of the resulting value"
                label="Property type"
                error={error?.message}
              >
                <Select
                  width="100%"
                  {...field}
                  isInvalid={Boolean(error)}
                  options={rawSqlResultingType}
                />
              </FormField>
            )}
          />
        </>
      )}
      {type === TraitType.Count && (
        <Controller
          control={control}
          name="config.column"
          render={({ field, fieldState: { error } }) => (
            <FormField
              description="Rows will be counted according to the distinct values of this column. If not specified, all rows will be counted."
              label="Count by"
              error={error?.message}
            >
              <Combobox
                width="100%"
                {...field}
                value={field.value ?? []}
                isDisabled={disabled}
                isInvalid={Boolean(error)}
                options={propertyOptions ?? []}
              />
            </FormField>
          )}
        />
      )}
      {type === TraitType.Sum && (
        <Controller
          control={control}
          name="config.column"
          render={({ field, fieldState: { error } }) => (
            <FormField
              description="The column that will be summed for all rows"
              label="Sum by"
              error={error?.message}
            >
              <Combobox
                width="100%"
                {...field}
                value={field.value ?? []}
                isDisabled={disabled}
                isInvalid={Boolean(error)}
                options={propertyOptions ?? []}
              />
            </FormField>
          )}
        />
      )}
      {type === TraitType.Average && (
        <Controller
          control={control}
          name="config.column"
          render={({ field, fieldState: { error } }) => (
            <FormField
              description="The column that will be averaged for all rows"
              label="Average by"
              error={error?.message}
            >
              <Combobox
                width="100%"
                {...field}
                value={field.value ?? []}
                isDisabled={disabled}
                isInvalid={Boolean(error)}
                options={propertyOptions ?? []}
              />
            </FormField>
          )}
        />
      )}
      {(type === TraitType.MostFrequent ||
        type === TraitType.LeastFrequent) && (
        <>
          <Controller
            control={control}
            name="config.toSelect"
            render={({ field, fieldState: { error } }) => (
              <FormField
                description="Frequency will be based on this column"
                label="Frequency column"
                error={error?.message}
              >
                <Combobox
                  width="100%"
                  {...field}
                  isDisabled={disabled}
                  isInvalid={Boolean(error)}
                  options={propertyOptions}
                />
              </FormField>
            )}
          />
        </>
      )}
      {(type === TraitType.First || type === TraitType.Last) && (
        <>
          <Controller
            control={control}
            name="config.toSelect"
            render={({ field, fieldState: { error } }) => (
              <FormField
                description="The column that represents the value"
                label="Trait value"
                error={error?.message}
              >
                <Combobox
                  width="100%"
                  {...field}
                  isDisabled={disabled}
                  isInvalid={Boolean(error)}
                  options={propertyOptions}
                />
              </FormField>
            )}
          />
          <Controller
            control={control}
            name="config.orderBy"
            render={({ field, fieldState: { error } }) => (
              <FormField
                description="Rows will be ordered according to this column"
                label="Order by"
                error={error?.message}
              >
                <Combobox
                  width="100%"
                  {...field}
                  isDisabled={disabled}
                  isInvalid={Boolean(error)}
                  options={propertyOptions}
                />
              </FormField>
            )}
          />
        </>
      )}
    </>
  );
};
