import { useMemo } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { isPresent } from "ts-extras";

import {
  useAudiencesAndMetricsForParentModelQuery,
  useParentModelAndRelationshipsQuery,
  useParentModelDecisionEngineFlowsQuery,
  useParentModelsForAnalyticsQuery,
} from "src/graphql";
import {
  AudienceAggregationType,
  PerUserAggregationType,
} from "src/types/visual";

import { STATIC_ARRAY } from "./constants";
import { MeasurementScope, MeasurementGroupOption } from "../types";
import { DecisionEngineFlow } from "../decision-engine-utils";
import { DecisionEngineFlowIcon } from "../common";

const formatAvailableMeasurements = (
  parentModelId: number | undefined,
  decisionEngineFlows: DecisionEngineFlow[] | undefined,
  decisionInsightsEnabled: boolean,
): MeasurementGroupOption[] => {
  if (!parentModelId || !decisionInsightsEnabled) {
    return [];
  }

  // Supported resource types: AID Flows
  const groupOptions: MeasurementGroupOption[] = [];

  const flows =
    (decisionEngineFlows ?? []).map((flow) => ({
      id: flow.id,
      label: flow.name,
      scope: MeasurementScope.DecisionEngineFlow,
      icon: DecisionEngineFlowIcon,
    })) ?? [].filter(isPresent);

  if (flows.length > 0) {
    groupOptions.push({
      groupLabel: "AI Decisioning Flows",
      options: flows,
    });
  }

  return groupOptions;
};

type UseChartDependencies = {
  parentModelId: number | undefined;
};

/**
 * Hook for fetching the schema information for charts
 */
export const useChartDependencies = ({
  parentModelId,
}: UseChartDependencies) => {
  const { decisionInsightsEnabled } = useFlags();

  const parentModelsForAnalyticsQuery = useParentModelsForAnalyticsQuery(
    undefined,
    {
      refetchOnMount: "always",
      select: (data) => data.segments,
    },
  );

  const parentModelOptions = useMemo(
    () =>
      parentModelsForAnalyticsQuery.data?.map((option) => ({
        value: option.id,
        label: option.name,
        connection: option.connection,
      })) ?? [],
    [parentModelsForAnalyticsQuery.data],
  );

  const parentModelAndRelationships = useParentModelAndRelationshipsQuery(
    { id: parentModelId?.toString() ?? "" },
    { enabled: Boolean(parentModelId) },
  );

  const audiencesAndMetrics = useAudiencesAndMetricsForParentModelQuery(
    { id: parentModelId?.toString() ?? "" },
    {
      enabled: Boolean(parentModelId),
      select: (data) => {
        const { segments, goals } = data;

        return {
          ...data,
          goals: goals.map(({ audience_aggregation, ...goal }) => ({
            ...goal,
            aggregation: goal.aggregation as PerUserAggregationType,
            audienceAggregation:
              audience_aggregation as AudienceAggregationType,
          })),
          segments: segments.map((segment) => ({
            ...segment,
            splits: segment.splits.map(({ friendly_name, ...split }) => ({
              ...split,
              name: friendly_name,
              enabled: true,
            })),
          })),
        };
      },
      keepPreviousData: true,
    },
  );

  const parentModel = parentModelAndRelationships.data?.segments_by_pk ?? null;

  const events = useMemo(
    () =>
      parentModel?.relationships?.filter(({ to_model: { event } }) =>
        Boolean(event),
      ) ?? STATIC_ARRAY,
    [parentModel?.relationships],
  );

  const { data: decisionEngineFlows, isLoading: isMeasurementsLoading } =
    useParentModelDecisionEngineFlowsQuery(
      { parentModelId: parentModelId?.toString() ?? "" },
      {
        enabled: Boolean(parentModelId && decisionInsightsEnabled),
        select: (data) => data.decision_engine_flows,
      },
    );

  const measurementOptions = formatAvailableMeasurements(
    parentModelId,
    decisionEngineFlows,
    decisionInsightsEnabled,
  );

  const values = useMemo(
    () => ({
      events,
      parentModel,
      parentModelId,
      parentModelOptions,
      audienceOptions: audiencesAndMetrics.data?.segments ?? STATIC_ARRAY,
      metricOptions: audiencesAndMetrics.data?.goals ?? STATIC_ARRAY,
      parentModelLoading: parentModelAndRelationships.isLoading,
      parentModelOptionsLoading: parentModelsForAnalyticsQuery.isLoading,
      measurementOptions: measurementOptions ?? STATIC_ARRAY,
      dependenciesLoading: audiencesAndMetrics.isLoading,
      measurementsOptionsLoading: isMeasurementsLoading,
    }),
    [
      audiencesAndMetrics.data,
      parentModelAndRelationships.data,
      events,
      parentModel,
      parentModelId,
      parentModelOptions,
    ],
  );

  return values;
};
