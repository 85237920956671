import { Text, Row, Column } from "@hightouchio/ui";

import { useParams } from "src/router";
import { SidebarForm } from "src/components/page";
import { useSourceQuery } from "src/graphql";

export const DemoSourceConfiguration = () => {
  const { id } = useParams<{ id: string }>();

  const { data: source } = useSourceQuery(
    { id: String(id) },
    { select: (data) => data.connections_by_pk, suspense: true },
  );

  return (
    <Row align="flex-start" gap={8}>
      <Column gap={4}>
        <Text size="lg" fontWeight="semibold">
          {source!.definition?.name}
        </Text>
        <Text color="text.secondary">{source!.definition?.longPitch}</Text>
      </Column>
      <SidebarForm
        docsUrl={source!.definition?.docs ?? ""}
        name={source!.definition?.name ?? ""}
      />
    </Row>
  );
};
