export const Icon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20px"
    height="20px"
    viewBox="0 0 24 24"
    fill={color}
  >
    <path
      fillRule="evenodd"
      d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Zm0 1c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="m16.667 12-7-4.042v8.084l7-4.042Zm-2 0-4-2.31v4.62l4-2.31Z"
      clipRule="evenodd"
    />
  </svg>
);
