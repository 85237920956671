import { FC, ReactNode, useState } from "react";

import { Box, ExtendedUpsellCard, Paragraph } from "@hightouchio/ui";

import { UpsellModal } from "./modal";
import { FeatureProps } from "./types";

type FeatureFullProps = {
  enabled: boolean;
  featureName: string;
  featureDetails: FeatureProps;
  children: ReactNode;
};

export const FeatureFull: FC<Readonly<FeatureFullProps>> = ({
  enabled,
  featureName,
  children,
  featureDetails,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  // TODO: Add docs link
  const { pitch, bullets, description, video, image } = featureDetails;

  if (enabled) {
    return <>{children}</>;
  }

  return (
    <>
      <ExtendedUpsellCard
        actionText={`Unlock ${featureName}`}
        badgeText="Business Tier"
        imageUrl={image?.src}
        title={pitch}
        videoPoster={video?.poster}
        videoUrl={video?.src}
        onAction={() => {
          setModalOpen(true);
        }}
      >
        <Paragraph mb={2}>{description}</Paragraph>
        <Box as="ul">
          {bullets.map((bullet, idx) => (
            <Box key={idx} as="li" ml={4} sx={{ _notFirst: { mt: 2 } }}>
              <Paragraph>{bullet}</Paragraph>
            </Box>
          ))}
        </Box>
      </ExtendedUpsellCard>
      <UpsellModal
        featureName={featureName}
        isOpen={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      />
    </>
  );
};
