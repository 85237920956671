import { FC, useMemo } from "react";

import {
  EmptyState,
  FrameStackIcon,
  InformationIcon,
  Row,
  Text,
  TimeIcon,
  Tooltip,
} from "@hightouchio/ui";
import { Outlet, useNavigate, useParams, useSearchParams } from "src/router";

import placeholderIcon from "src/assets/placeholders/generic.svg";
import { IconBox } from "src/components/icon-box";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { TRAIT_TYPE_LABELS } from "src/components/traits/utils";
import {
  OrderBy,
  TraitDefinitionsOrderBy,
  TraitQuery,
  useTraitsQuery,
} from "src/graphql";
import {
  Pagination,
  SortOption,
  useTableConfig,
  useTableSort,
} from "src/ui/table";
import { Table, TableColumn } from "src/ui/table/table";

import { TraitType } from "./types";
import { getTraitsFilter } from "./utils";

const initialSort: SortOption<keyof TraitDefinitionsOrderBy> = {
  key: "updated_at",
  direction: OrderBy.Desc,
  label: "Recently updated",
};

const sortOptions: SortOption<keyof TraitDefinitionsOrderBy>[] = [
  { key: "name", direction: OrderBy.Asc, label: "Name A -> Z" },
  { key: "name", direction: OrderBy.Desc, label: "Name Z -> A" },
  { key: "created_at", direction: OrderBy.Desc, label: "Newest" },
  { key: "created_at", direction: OrderBy.Asc, label: "Oldest" },
  { key: "updated_at", direction: OrderBy.Desc, label: "Recently updated" },
];

type Props = {
  type: TraitType;
};

type Trait = NonNullable<TraitQuery["trait_definitions_by_pk"]>;

export const TraitsTable: FC<Readonly<Props>> = ({ type }) => {
  const navigate = useNavigate();
  const { traitId } = useParams();
  const [searchParams] = useSearchParams();

  const search = searchParams.get("search") ?? "";

  const columns: TableColumn[] = useMemo(() => {
    switch (type) {
      case TraitType.Active:
        return activeColumns;
      case TraitType.Templates:
        return templateColumns;
      case TraitType.Archived:
        return archivedColumns;
      default:
        return [];
    }
  }, [type]);

  const filter = useMemo(
    () => getTraitsFilter({ search, type }),
    [type, search],
  );

  const { limit, offset, page, setPage } = useTableConfig({ limit: 20 });
  const orderBy = useTableSort<TraitDefinitionsOrderBy>(
    initialSort,
    sortOptions,
  );

  const {
    data: traits,
    isLoading,
    isError,
  } = useTraitsQuery({
    filter,
    limit,
    offset,
    orderBy,
  });

  return (
    <>
      <Table
        columns={columns}
        data={traits?.trait_definitions ?? []}
        error={isError}
        highlight={traitId}
        loading={isLoading}
        placeholder={{
          custom: <EmptyPlaceholder search={search} type={type} />,
        }}
        primaryKey="id"
        sortOptions={sortOptions}
        onRowClick={(row) => {
          navigate(`${row.id}`);
        }}
      />
      <Row justifyContent="right" mt={4}>
        <Pagination
          count={traits?.trait_definitions_aggregate?.aggregate?.count}
          page={page}
          rowsPerPage={limit}
          setPage={setPage}
        />
      </Row>

      <Outlet />
    </>
  );
};

const sharedColumns: TableColumn<Trait>[] = [
  {
    name: "Name",
    cell: ({ name }) => <TextWithTooltip>{name}</TextWithTooltip>,
    cellSx: {
      fontWeight: "medium",
      pl: "4 !important",
    },
    headerSx: {
      "&:first-of-type": {
        pl: 4,
      },
    },
  },
  {
    name: "Parent model",
    cell: ({ parent_model }) => (
      <Row gap={2} width="100%">
        <IntegrationIcon
          src={parent_model?.connection?.definition?.icon}
          name={parent_model?.connection?.definition?.name}
          size={5}
        />
        <TextWithTooltip textTransform="capitalize">
          {parent_model.name}
        </TextWithTooltip>
      </Row>
    ),
  },
  {
    name: "Type",
    cell: ({ type }) => (
      <TextWithTooltip textTransform="capitalize">
        {TRAIT_TYPE_LABELS[type]}
      </TextWithTooltip>
    ),
    min: "min-content",
    max: "min-content",
  },
  {
    name: "Related model",
    cell: ({ relationship }) => {
      if (!relationship) {
        return (
          <Row as={Text} color="text.secondary" align="center">
            None
            <Tooltip message="Formula traits do not use related models">
              <Text color="text.secondary" ml={2}>
                <InformationIcon />
              </Text>
            </Tooltip>
          </Row>
        );
      }

      const isEventModel = relationship.to_model?.event?.timestamp_column;

      return (
        <Row gap={2} width="100%">
          <IconBox
            icon={isEventModel ? <TimeIcon /> : <FrameStackIcon />}
            bg={isEventModel ? "cyan.400" : "grass.400"}
            boxSize={5}
            iconSize={3}
          />
          <TextWithTooltip textTransform="capitalize">
            {relationship.to_model.name}
          </TextWithTooltip>
        </Row>
      );
    },
  },
];

const activeColumns: TableColumn[] = sharedColumns;

const templateColumns: TableColumn[] = [
  ...sharedColumns,
  {
    header: () => (
      <Row width="100%" justifyContent="end" textTransform="uppercase">
        Active traits
      </Row>
    ),
    cell: ({ active_traits }) => (
      <Row width="100%" justifyContent="end">
        {active_traits.length}
      </Row>
    ),
    min: "min-content",
    max: "min-content",
  },
];

const archivedColumns: TableColumn[] = [...sharedColumns];

const EmptyPlaceholder = ({
  search,
  type,
}: {
  search: string;
  type: TraitType;
}) => {
  let title;
  let message;
  if (type === TraitType.Active) {
    title = search
      ? "No active traits found"
      : "You haven't created any traits";
    message =
      "Traits allow you to define and sync specific data from this model.";
  } else if (type === TraitType.Templates) {
    title = search
      ? "No trait templates found"
      : "You haven't created any trait templates";
    message =
      "Templates allow you to define a specific calculation from this model.";
  } else if (type === TraitType.Archived) {
    title = search
      ? "No archived traits found"
      : "You haven't archived any traits";
    message = "Anyhing you archive can be restored from this list.";
  }

  return (
    <EmptyState imageUrl={placeholderIcon} title={title} message={message} />
  );
};
