import { FC } from "react";
import { Row, SuccessIcon } from "@hightouchio/ui";
import { TextWithTooltip } from "src/components/text-with-tooltip";

type MenuItem = {
  icon: React.ElementType;
  name: string;
  value?: string;
};

type Props = {
  menuItems?: Array<MenuItem>;
  savedEvents: Array<string>;
  pickedEvents: Array<string>;
  events: Array<{ name: string }>;
  selected: string | undefined;
  onSelect: (key: string | undefined) => void;
};

export const EventSelect: FC<Readonly<Props>> = ({
  menuItems,
  savedEvents,
  pickedEvents,
  events,
  selected,
  onSelect,
}) => {
  return (
    <>
      {menuItems?.map((item, i) => (
        <Item
          key={i}
          isSelected={selected === item.value}
          onClick={() => {
            onSelect(item.value);
          }}
        >
          <Row gap={2} fontSize="20px">
            <item.icon />
            <TextWithTooltip fontWeight="medium">{item.name}</TextWithTooltip>
          </Row>
        </Item>
      ))}
      {savedEvents.map((name) => {
        return (
          <EventItem
            key={name}
            onClick={() => {
              onSelect(name);
            }}
            isSelected={name === selected}
            isChecked
            name={name}
            events={events}
          />
        );
      })}
      {pickedEvents.map((name) => {
        return (
          <EventItem
            key={name}
            onClick={() => {
              onSelect(name);
            }}
            isSelected={name === selected}
            isChecked={false}
            name={name}
            events={events}
          />
        );
      })}
    </>
  );
};

const EventItem = ({ name, events, isSelected, isChecked, onClick }) => {
  const title =
    name === "custom"
      ? "Custom"
      : events.find((event) => event.name === name).title!;
  return (
    <Item isSelected={isSelected} onClick={onClick}>
      <Row align="center" gap={2} fontSize="20px">
        {isChecked ? <SuccessIcon /> : <CircleIcon />}
        <TextWithTooltip fontWeight="medium">{title}</TextWithTooltip>
      </Row>
    </Item>
  );
};

const Item = ({ children, isSelected, onClick }) => {
  return (
    <Row
      p={2}
      bg={isSelected ? "forest.100" : "transparent"}
      onClick={onClick}
      gap={2}
      align="center"
      borderRadius="md"
      cursor="pointer"
      justify="space-between"
      _hover={isSelected ? {} : { bg: "gray.100" }}
    >
      {children}
    </Row>
  );
};

const CircleIcon = () => {
  return (
    <Row
      w="17px"
      h="17px"
      align="center"
      justify="center"
      borderRadius="50%"
      border="2px"
      borderColor="gray.500"
      marginLeft="2px"
    />
  );
};
