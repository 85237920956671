import { MouseEvent, ReactNode, useRef } from "react";

import { Box, Checkbox, Column } from "@hightouchio/ui";

export type Props = {
  label: string;
  value: boolean;
  onChange: (value: boolean) => void;
  children?: ReactNode;
};

export const Collapsible = ({ label, value, onChange, children }: Props) => {
  const checkboxContainer = useRef<HTMLSpanElement>(null);

  const toggle = (event: MouseEvent) => {
    // Ignore clicks on checkbox
    if (checkboxContainer.current?.contains(event.target as HTMLElement)) {
      return;
    }

    onChange(!value);
  };

  return (
    <Box borderColor="gray.300" borderRadius="md" borderWidth="1px">
      <Box
        bg={value ? "grass.200" : undefined}
        borderTopRadius="md"
        cursor="pointer"
        display="flex"
        p={6}
        onClick={toggle}
      >
        <span ref={checkboxContainer}>
          <Checkbox
            isChecked={value}
            label={label}
            onChange={(event) => {
              onChange(event.target.checked);
            }}
          />
        </span>
      </Box>

      {value && (
        <Column borderColor="gray.300" borderTopWidth="1px" gap={6} p={6}>
          {children}
        </Column>
      )}
    </Box>
  );
};

Collapsible.displayName = "Collapsible";
