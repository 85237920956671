import { FC, useEffect, useState } from "react";

import {
  Column,
  FormField,
  Heading,
  Row,
  Text,
  TextInput,
  useToast,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { Outlet, useOutletContext } from "src/router";

import fivetranExtensionImage from "src/components/extensions/assets/fivetran-extension.png";
import { Overview } from "src/components/extensions/overview";
import { ActionBar } from "src/components/action-bar";
import { FeaturePreview } from "src/components/feature-gates/preview";
import { DetailPage } from "src/components/layout";
import { PermissionedButton } from "src/components/permission";
import { ScheduleType } from "src/components/schedule/types";
import { useUser } from "src/contexts/user-context";
import {
  FivetranExtensionError,
  GetFivetranExtensionQuery,
  useCreateFivetranCredentialsMutation,
  useDeleteFivetranCredentialsMutation,
  useGetFivetranExtensionQuery,
  useTestFivetranExtensionQuery,
} from "src/graphql";
import { PageSpinner } from "src/components/loading";
import {
  ConnectedExtension,
  DependentSyncsModal,
  ExtensionTestStatus,
} from "./common";
import { FivetranIcon } from "src/ui/icons";
import { DocsLink } from "src/components/docs-link";
import { Card } from "src/components/card";

export const Fivetran: FC = () => {
  const {
    data: credentials,
    isLoading: loading,
    refetch,
  } = useGetFivetranExtensionQuery(undefined, {
    select: (data) => data.fivetran_credentials?.[0],
  });

  return (
    <DetailPage
      bg="base.lightBackground"
      crumbs={[{ label: "Extensions", link: "/extensions" }]}
      title="Fivetran - Extensions"
      header={<Heading size="xl">Fivetran</Heading>}
      tabs={[
        { path: "", title: "Overview" },
        { path: "configuration", title: "Configuration" },
      ]}
    >
      <Outlet context={{ credentials, loading, reload: refetch }} />
    </DetailPage>
  );
};

interface OutletContext {
  credentials: GetFivetranExtensionQuery["fivetran_credentials"][0] | undefined;
  loading: boolean;
  reload: () => void;
}

export const FivetranOverview = () => {
  return (
    <Overview
      description="Schedule your Hightouch syncs to run when your Fivetran connectors and dbt transformations complete."
      icon={FivetranIcon}
      image={fivetranExtensionImage}
      subtitle="Trigger syncs upon completion of Fivetran jobs"
      title="Fivetran"
    />
  );
};

export const FivetranConfiguration: FC = () => {
  const { credentials, loading, reload } = useOutletContext<OutletContext>();
  const { workspace } = useUser();
  const [apiKey, setApiKey] = useState("");
  const [apiSecret, setApiSecret] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);

  const [error, setError] = useState<FivetranExtensionError | null>(null);

  const [testStatus, setTestStatus] = useState<ExtensionTestStatus>("loading");
  const { toast } = useToast();
  const { mutateAsync: create, isLoading: isCreating } =
    useCreateFivetranCredentialsMutation();
  const {
    data: testResult,
    isLoading: isTesting,
    refetch: testExtension,
    isRefetching: isReTesting,
  } = useTestFivetranExtensionQuery(
    {},
    { select: (data) => data.testFivetranWebhook, enabled: !!credentials },
  );
  const { mutateAsync: deleteExtension, isLoading: fivetranDeleting } =
    useDeleteFivetranCredentialsMutation();

  useEffect(() => {
    if (credentials) {
      if (isTesting || isReTesting) {
        setTestStatus("loading");
        return;
      }
      if (!testResult?.succeed) {
        setTestStatus("failed");
        toast({
          id: "failed-fivetran-test",
          variant: "error",
          title: "Fivetran webhook test failed.",
          message: testResult?.message ?? "Please check your API credentials.",
        });
      } else {
        setTestStatus("success");
      }
    }
  }, [credentials, isTesting, isReTesting]);

  const submit = async () => {
    try {
      if (credentials?.id) {
        // We don't allow an update
        return;
      } else {
        setError(null);
        const resp = await create({ apiKey, apiSecret });
        if (
          resp.createFivetranExtension.__typename === "FivetranExtensionError"
        ) {
          setError(resp.createFivetranExtension);
          throw Error(
            resp.createFivetranExtension.fivetranError ??
              resp.createFivetranExtension.message,
          );
        }
        toast({
          id: "fivetran-credentials",
          variant: "success",
          title: "Fivetran connected",
          message: "You can now use Fivetran to trigger your syncs.",
        });
        reload();
        setApiKey("");
        setApiSecret("");
      }
    } catch (e) {
      toast({
        id: "fivetran-credentials",
        variant: "error",
        title: "Fivetran connection failed",
        message:
          e.message ?? "There was an error saving your Fivetran configuration.",
      });
      // TODO: Handle Fivetran Errors here
      Sentry.captureException(e);
    }
  };

  if (loading) {
    return <PageSpinner />;
  }

  return (
    <>
      <Column flex={1} gap={6} maxW="2xl">
        <FeaturePreview
          enabled={workspace?.organization?.plan?.sku === "business_tier"}
          featureDetails={{
            pitch:
              "Trigger for a Hightouch sync to run after a Fivetran job completes",
            description:
              "The Fivetran extension creates an end-to-end flow for your data. This extension enables a Hightouch sync to begin running whenever a Fivetran sync or transformation completes.",
            bullets: [
              "Schedule Hightouch syncs based on when a Fivetran job finishes",
              "Support for both Fivetran syncs and Fivetran transformations",
              "Guarantee freshness when sending data into your business tools",
            ],
            video: {
              src: "https://cdn.sanity.io/files/pwmfmi47/production/05f48da576ab4ec1cd867525c68931a70932fa79.mp4",
            },
          }}
          featureName="Fivetran extension"
          variant="full"
        />
        <Row align="center" justify="space-between">
          <Heading>Fivetran configuration</Heading>
          <DocsLink href="extensions/fivetran" name="Fivetran extension" />
        </Row>
        {credentials?.id ? (
          <ConnectedExtension credentials={credentials} testStatus={testStatus}>
            <Text fontWeight="medium">
              <Text fontWeight="semibold">API key: </Text>
              {credentials?.api_key}
            </Text>
            <Text fontWeight="medium">
              <Text fontWeight="semibold">Fivetran webhook ID: </Text>
              {credentials?.fivetran_webhook_id}
            </Text>
          </ConnectedExtension>
        ) : (
          <Card gap={6}>
            <FormField
              error={error?.message ? String(error.message) : undefined}
              label="API key"
            >
              <TextInput
                isDisabled={isCreating}
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
              />
            </FormField>
            <FormField label="API secret">
              <TextInput
                isDisabled={isCreating}
                type="password"
                value={apiSecret}
                onChange={(e) => setApiSecret(e.target.value)}
              />
            </FormField>
          </Card>
        )}
        <ActionBar>
          {!credentials?.id ? (
            <PermissionedButton
              permission={{
                v2: { resource: "workspace", grant: "can_update" },
              }}
              size="lg"
              isDisabled={!apiKey || !apiSecret}
              isLoading={isCreating}
              variant="primary"
              onClick={() => submit()}
            >
              Connect
            </PermissionedButton>
          ) : (
            <>
              <PermissionedButton
                permission={{
                  v2: { resource: "workspace", grant: "can_update" },
                }}
                size="lg"
                isDisabled={isTesting || isReTesting || isDeleting}
                onClick={() => {
                  setTestStatus("loading");
                  testExtension({});
                }}
              >
                {isTesting || isReTesting ? "Testing..." : "Test connection"}
              </PermissionedButton>
              <PermissionedButton
                permission={{
                  v2: { resource: "workspace", grant: "can_update" },
                }}
                size="lg"
                isDisabled={isDeleting || isTesting}
                variant="danger"
                onClick={() => {
                  setIsDeleting(true);
                }}
              >
                Disconnect
              </PermissionedButton>
            </>
          )}
        </ActionBar>
      </Column>

      <DependentSyncsModal
        deleteExtension={() => deleteExtension({})}
        extensionName="Fivetran"
        isDeleting={fivetranDeleting}
        open={isDeleting}
        scheduleType={ScheduleType.FIVETRAN}
        onClose={() => setIsDeleting(false)}
      />
    </>
  );
};
