import { Column, Heading, Text } from "@hightouchio/ui";

import { LoadingCircles } from "src/components/loading";
import { useAssetType } from "./filters/use-campaign-page";
import { getAssetNoun } from "./utils";

export const CampaignsLoadingCircles = () => {
  const assetType = useAssetType();

  return (
    <Column
      align="center"
      justify="center"
      gap={8}
      py="40px"
      borderRadius="md"
      border="1px solid"
      borderColor="base.border"
      width="100%"
    >
      <LoadingCircles variant="green" />
      <Column align="center" maxW="563px" gap={2}>
        <Heading>Processing {getAssetNoun(assetType)} assets...</Heading>
        <Text color="text.secondary">
          Our system is currently analyzing your model. This may take a few
          seconds.
        </Text>
      </Column>
    </Column>
  );
};
