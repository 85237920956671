import { FC, useState } from "react";

import {
  Box,
  Button,
  Column,
  FormField,
  Row,
  Text,
  TextInput,
  SuccessIcon,
  ErrorIcon,
  Dialog,
  ButtonGroup,
} from "@hightouchio/ui";

import { useIsOrganizationSlugAvailableQuery } from "src/graphql";

type Props = {
  isOpen: boolean;
  isLoading: boolean;
  onSubmit: (organizationName: string) => void;
  onCancel: () => void;
};

export const AddOrganizationNameModal: FC<Readonly<Props>> = ({
  isOpen,
  isLoading,
  onSubmit,
  onCancel,
}) => {
  const [organizationName, setOrganizationName] = useState("");

  const upgradePlan = async () => {
    onSubmit(organizationName);
  };

  const { data, isLoading: loadingSlug } = useIsOrganizationSlugAvailableQuery(
    {
      name: organizationName,
    },
    { enabled: Boolean(organizationName) },
  );
  const available = data?.isOrganizationAvailable;

  return (
    <Dialog
      isOpen={isOpen}
      variant="form"
      title="Create your first billing organization"
      actions={
        <ButtonGroup>
          <Button onClick={() => onCancel()}>Cancel</Button>
          <Button
            isDisabled={organizationName === "" || loadingSlug || !available}
            isLoading={isLoading}
            variant="primary"
            onClick={upgradePlan}
          >
            Continue
          </Button>
        </ButtonGroup>
      }
      onClose={onCancel}
    >
      <Column align="center" gap={2}>
        <Text color="text.secondary">
          This organization can be used to manage billing across multiple
          workspaces.
        </Text>
        <Column position="relative" width="100%">
          <FormField label="Billing organization name">
            <TextInput
              placeholder="The ACME Company"
              width="100%"
              value={organizationName}
              onChange={(event) => {
                setOrganizationName(event.target.value);
                if (!isOpen) {
                  open();
                }
              }}
            />
          </FormField>
          <Row position="absolute" right={2} fontSize={0} align="center">
            {!organizationName ? null : loadingSlug ? (
              "Searching"
            ) : available ? (
              <>
                <Box as={SuccessIcon} color="success.base" fontSize="12px" />
                <Text ml={1}>Available!</Text>
              </>
            ) : (
              <>
                <Box as={ErrorIcon} color="text.danger" fontSize="12px" />
                <Text ml={1} color="text.danger">
                  Unavailable
                </Text>
              </>
            )}
          </Row>
        </Column>
      </Column>
    </Dialog>
  );
};
