import { FormField } from "@hightouchio/ui";
import { Controller } from "react-hook-form";
import { ColumnSelect } from "src/components/models/column-select";
import { useModelQuery } from "src/graphql";

export const OutcomeCampaignField = ({ modelId }) => {
  const modelQuery = useModelQuery(
    { id: modelId },
    {
      select: (data) => data.segments_by_pk,
    },
  );

  return (
    <Controller
      name="attribution.campaign_id_column"
      render={({ field }) => (
        <FormField
          isOptional
          label="Campaign ID"
          description="How each outcome is associated with a campaign"
          tip="Column that contains the campaign ID. E.g. “campaign_id”"
        >
          <ColumnSelect
            {...field}
            isClearable
            isLoading={modelQuery.isLoading}
            model={modelQuery.data}
          />
        </FormField>
      )}
    />
  );
};
