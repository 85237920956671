import { ReactElement, useEffect } from "react";

import * as Yup from "yup";

import {
  CustomQuery,
  CustomQueryFormProps,
  CustomQueryViewProps,
} from "../custom-query";
import * as sheets from "./sheets";

function QueryForm({
  source,
  query,
  onChange,
  error,
  setError,
}: Readonly<CustomQueryFormProps>): ReactElement<any, any> {
  const renderForm = (type: string) => {
    switch (type) {
      case "sheet":
        return (
          <sheets.QueryForm
            error={error}
            query={query}
            setError={setError}
            source={source}
            onChange={onChange}
          />
        );
      default:
        return `Type ${type} not valid for Google Sheets source`;
    }
  };

  useEffect(() => {
    if (!query?.type) {
      onChange({ type: "sheet" });
    }
  }, [query]);

  return <>{query?.type && renderForm(query.type)}</>;
}

function QueryView(
  props: Readonly<CustomQueryViewProps>,
): ReactElement<any, any> {
  const renderView = (type: string | undefined) => {
    switch (type) {
      case "sheet":
        return <sheets.QueryView {...props} />;
      default:
        props.setError(
          new Error(`Type ${type} not valid for Google Sheets source`),
        );
        return undefined;
    }
  };

  return <>{renderView(props.query.type)}</>;
}

export default {
  QueryForm,
  QueryView,
  querySchema: Yup.lazy<CustomQuery | undefined>((query) => {
    switch (query?.["type"]) {
      case "sheet":
        return sheets.querySchema;
      default:
        return Yup.object().shape({ type: Yup.string().required() });
    }
  }),
};
