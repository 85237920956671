import { EditIcon, TagIcon } from "@hightouchio/ui";

import { ResourceActivityMapper } from "./timeline";

export const commonActivityMappings: ResourceActivityMapper[] = [
  {
    accessor: "description",
    parser: (_, { parsedDiff, newValue, oldValue }) => {
      if (parsedDiff.type !== "value") {
        return null;
      }
      let text;
      switch (parsedDiff.operation) {
        case "added":
          text = "Added a description";
          break;
        case "removed":
          text = "Removed the description";
          break;

        // We run into some annoying issues with descriptions where they may have been '' or ' ' in the db
        case "updated":
          text = `Updated the description from \`${oldValue}\` to \`${newValue}\``;
          if (!oldValue) {
            text = `Added a description \`${newValue}\``;
          } else if (!newValue) {
            text = `Removed the description \`${oldValue}\``;
          }
          break;
      }
      return {
        message: text,
        icon: <EditIcon />,
      };
    },
  },
  {
    accessor: "tags",
    parser: (_, { parsedDiff }) => {
      if (parsedDiff.type !== "nested") {
        return null;
      }
      const changes: string[] = [];
      for (const [tag, change] of Object.entries(parsedDiff.nested)) {
        if (change.type !== "value") {
          continue;
        }
        switch (change.operation) {
          case "added":
            changes.push(`Added label \`${tag}\`:\`${change.value}\``);
            break;
          case "removed":
            changes.push(`Removed label \`${tag}\``);
            break;
          case "updated":
            changes.push(`Updated label \`${tag}\` to \`${change.value}\``);
            break;
        }
      }

      return {
        message: `updated labels`,
        icon: <TagIcon />,
        changes,
      };
    },
  },
];
