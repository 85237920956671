import { FC, ReactNode } from "react";

import { ButtonGroup, Row } from "@hightouchio/ui";

import { FOOTER_HEIGHT } from "./utils";
import { Watermark } from "./watermark";

export const BottomBar: FC<Readonly<{ children?: ReactNode }>> = ({
  children,
}) => {
  return (
    <Row
      borderTop="1px"
      borderColor="base.border"
      width="100%"
      height={FOOTER_HEIGHT}
      px={4}
      py={3}
      flexShrink={0}
      bg="white"
      pos="sticky"
      bottom={0}
      justify="space-between"
      zIndex={1}
    >
      {children && <ButtonGroup>{children}</ButtonGroup>}
      <Watermark ml="auto" />
    </Row>
  );
};
