import * as Sentry from "@sentry/react";
import LogRocket from "logrocket";
import { useEffect } from "react";
// eslint-disable-next-line no-restricted-imports
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

Sentry.init({
  beforeSend(event) {
    const logRocketSession = LogRocket.sessionURL;
    if (logRocketSession !== null && event.extra) {
      event.extra["LogRocket"] = logRocketSession;
      return event;
    } else {
      return event;
    }
  },
  enabled: import.meta.env.PROD && !import.meta.env.PREVIEW,
  dsn: "https://b61c707a79854bc2902b1392410fa6ae@o1224454.ingest.sentry.io/6505512",
  ignoreErrors: [/ResizeObserver loop/, /A network error occurred./],
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 0.5,
});
