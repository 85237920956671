import { Badge, StatusIndicator } from "@hightouchio/ui";

import { diff } from "../../utils/time";

export const ExtensionSyncStatusBadge = ({
  setup,
  lastAttemptedAt,
  error,
  statusTextOverride,
}: {
  setup: boolean;
  lastAttemptedAt: string | null | undefined;
  error: any;
  statusTextOverride?: string;
}) => {
  const statusText =
    statusTextOverride ||
    (lastAttemptedAt
      ? diff(new Date().toISOString(), lastAttemptedAt) + " ago"
      : setup
        ? "Waiting to be synced"
        : "Not synced");

  return (
    <Badge>
      {!setup || lastAttemptedAt ? (
        <StatusIndicator
          variant={
            lastAttemptedAt
              ? error
                ? error?.temp
                  ? "warning"
                  : "error"
                : "success"
              : "inactive"
          }
        >
          {statusText}
        </StatusIndicator>
      ) : (
        statusText
      )}
    </Badge>
  );
};
