import { FC } from "react";

import {
  Box,
  Column,
  Radio,
  RadioGroup,
  Row,
  Text,
  TextInput,
} from "@hightouchio/ui";

import { FormProps, STATIC_OPTIONS, StaticType } from "../types";

export const StaticInput: FC<Readonly<FormProps>> = ({ onChange, value }) => {
  const getInput = () => {
    switch (value.valueType) {
      case StaticType.NUMBER:
        return (
          <TextInput
            autoFocus
            placeholder="Enter number"
            type="number"
            value={value.value}
            width="sm"
            onChange={(event) => {
              onChange({
                ...value,
                value: event.target.value,
              });
            }}
            onKeyDown={(event) => {
              const invalidChars = ["+", "e"];
              if (invalidChars.includes(event.key)) {
                event.preventDefault();
              }
            }}
          />
        );

      case StaticType.BOOLEAN:
        return (
          <RadioGroup
            value={value.value === true ? "true" : "false"}
            onChange={(newValue) => {
              onChange({
                ...value,
                value: newValue === "true",
              });
            }}
          >
            <Radio label="True" value="true" />
            <Radio label="False" value="false" />
          </RadioGroup>
        );

      case StaticType.NULL:
        return (
          <Text color="text.secondary" fontWeight="normal" size="md">
            A null value will be synced to this destination field.
          </Text>
        );

      case StaticType.STRING:
      default:
        return (
          <Column>
            <Box maxWidth="384px">
              <TextInput
                autoFocus
                placeholder="Enter string"
                value={value.value ?? ""}
                width="100%"
                onChange={(event) => {
                  onChange({
                    valueType: StaticType.STRING,
                    ...value,
                    value: event.target.value,
                  });
                }}
              />
            </Box>
            <Text color="text.secondary" fontWeight="normal" mt={2} size="sm">
              Strings can include any combination of numbers, letters, and
              special characters.
            </Text>
          </Column>
        );
    }
  };

  return (
    <Row flex="1" minWidth={0} mt={1}>
      <Box
        borderColor="gray.300"
        borderRightWidth="1px"
        display="flex"
        flex="none"
        flexDirection="column"
        gap={1}
        px={3}
        width="180px"
      >
        <RadioGroup
          value={value.valueType || StaticType.STRING}
          onChange={(value) => {
            if (value === StaticType.NULL) {
              onChange({
                type: "static",
                value: null,
                valueType: value,
              });
            } else if (value === StaticType.BOOLEAN) {
              onChange({
                type: "static",
                value: false,
                valueType: value,
              });
            } else {
              onChange({
                type: "static",
                valueType: value,
              });
            }
          }}
        >
          {STATIC_OPTIONS.map(({ label, type }) => {
            return <Radio key={type} label={label} value={type} />;
          })}
        </RadioGroup>
      </Box>

      <Row flex="1" minWidth={0} px={3}>
        {getInput()}
      </Row>
    </Row>
  );
};
