import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect, useState } from "react";

import { QueryClient, useQueryClient } from "react-query";

import {
  ListSourceTestStepsQuery,
  ListSourceTestStepsQueryVariables,
  StartTestSourceBackgroundJobQuery,
  TestSourceInput,
  TestSourceQuery,
  TestSourceResult,
  useListSourceTestStepsQuery,
  useStartTestSourceBackgroundJobQuery,
  useTestSourceBackgroundJobResultQuery,
  useTestSourceQuery,
} from "src/graphql";
import { sleep } from "src/utils/promise";

const TEST_SOURCE_POLL_INTERVAL_MS = 1000;

export const useSourceTesting = (): {
  results: Partial<TestSourceResult> | undefined;
  steps: ListSourceTestStepsQuery["listSourceTestSteps"] | undefined;
  getTestSteps: (variables: ListSourceTestStepsQueryVariables) => Promise<void>;
  runTest: (input: TestSourceInput) => void;
  cancelRunTest: () => void;
  timeElapsed: number | undefined;
} => {
  const [testResult, setTestResult] = useState<
    TestSourceQuery["testSource"] | undefined
  >();
  const [delayedTestResult, setDelayedTestResult] = useState<
    Partial<TestSourceQuery["testSource"]> | undefined
  >();
  const [testSteps, setTestSteps] = useState<
    ListSourceTestStepsQuery["listSourceTestSteps"] | undefined
  >();
  const [counter, setCounter] = useState<number>();

  const [jobId, setJobId] = useState<string | undefined>();

  const client = useQueryClient();

  useEffect(() => {
    const set = async (testResult) => {
      for (const step of testResult.stepResults) {
        await sleep(Math.random() * (1000 - 500) + 500);
        setDelayedTestResult((result) => ({
          stepResults: [...(result?.stepResults || []), step],
        }));
      }
      setDelayedTestResult((result) => ({
        ...result,
        success: testResult.success,
      }));
    };

    if (testResult) {
      setCounter(undefined);
      set(testResult);
    } else {
      setDelayedTestResult(testResult);
    }
  }, [testResult]);

  useEffect(() => {
    let timer;
    if (counter !== undefined) {
      timer = setTimeout(() => setCounter(counter + 1), 1000);
    }
    return () => clearInterval(timer);
  }, [counter]);

  const getTestSteps = async (variables: ListSourceTestStepsQueryVariables) => {
    const queryKey = useListSourceTestStepsQuery.getKey(variables);
    const queryFn = useListSourceTestStepsQuery.fetcher(variables);

    const response = await client.fetchQuery<ListSourceTestStepsQuery>(
      queryKey,
      {
        queryFn,
        cacheTime: 0,
      },
    );

    setTestSteps(response?.listSourceTestSteps);
  };

  // This only runs if the flag `backgroundTestSourceEnabled` is enabled
  useTestSourceBackgroundJobResultQuery(
    {
      jobId: jobId!,
    },
    {
      enabled: Boolean(jobId),
      refetchInterval: TEST_SOURCE_POLL_INTERVAL_MS,
      onSuccess(data) {
        switch (data.testSourceBackgroundJobResult.__typename) {
          case "TestSourceBackgroundJobProcessing":
            break;
          // TODO: should return error somewhere, but currently no consumers handle errors
          case "TestSourceBackgroundJobFailure": {
            setJobId(undefined);
            break;
          }
          case "TestSourceBackgroundJobSuccess": {
            setJobId(undefined);
            setTestResult(data.testSourceBackgroundJobResult.result);
            break;
          }
        }
      },
      onError() {
        // TODO: should return error somewhere, but currently no consumers handle errors
        setJobId(undefined);
      },
    },
  );

  const { backgroundTestSourceEnabled } = useFlags();

  const runTest = async (input: TestSourceInput) => {
    setTestResult(undefined);
    setDelayedTestResult(undefined);
    setCounter(0);

    if (backgroundTestSourceEnabled) {
      const res = await startTestSourceBackgroundJob(input, client);

      // Setting job id starts query which polls for test results
      setJobId(res?.jobId);
    } else {
      // In the legacy case, results are returned immediately
      const result = await runTestSourceLegacy(input, client);
      setTestResult(result);
    }
  };

  // This only does anything when we are using background jobs
  // When background jobs are enabled, clear the job id so we stop polling for results
  // Useful for callers eg. when a test modal is closed
  const cancelRunTest = async () => {
    if (backgroundTestSourceEnabled) {
      setJobId(undefined);
    }
  };

  return {
    results: delayedTestResult,
    steps: testSteps,
    getTestSteps,
    runTest,
    cancelRunTest,
    timeElapsed: counter,
  };
};

const runTestSourceLegacy = async (
  input: TestSourceInput,
  client: QueryClient,
): Promise<TestSourceResult> => {
  const queryKey = useTestSourceQuery.getKey({ input });
  const queryFn = useTestSourceQuery.fetcher({ input });

  const response = await client.fetchQuery<TestSourceQuery>(queryKey, {
    queryFn,
    cacheTime: 0,
  });

  return response.testSource;
};

const startTestSourceBackgroundJob = async (
  input: TestSourceInput,
  client: QueryClient,
): Promise<{ jobId: string } | undefined> => {
  const queryKey = useStartTestSourceBackgroundJobQuery.getKey({ input });
  const queryFn = useStartTestSourceBackgroundJobQuery.fetcher({ input });

  const response = await client.fetchQuery<StartTestSourceBackgroundJobQuery>(
    queryKey,
    {
      queryFn,
      cacheTime: 0,
    },
  );

  return response?.startTestSourceBackgroundJob;
};
