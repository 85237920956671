import { Button, EmptyState, useToast } from "@hightouchio/ui";
import slugify from "@sindresorhus/slugify";
import { nanoid } from "nanoid";
import { Route, Routes, useNavigate } from "src/router";
import {
  CreateDestinationV2MutationVariables,
  useCreateDestinationV2Mutation,
} from "src/graphql";
import { useQueryString } from "src/utils/use-query-string";

export const MiscRoutes = () => (
  <Routes>
    <Route path="/create-destination" element={<CreateDestination />} />
  </Routes>
);

const CreateDestination = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const queryState = useQueryString();

  const input: {
    name: string;
    type: string;
    config: Record<string, any>;
  } = queryState.data.input ? JSON.parse(atob(queryState.data.input)) : {};

  const { mutateAsync: createDestination, isLoading } =
    useCreateDestinationV2Mutation({
      onSuccess: (data) => {
        toast({
          id: "destination-created",
          title:
            "Destination created successfully. You will be redirected shortly.",
          variant: "success",
        });
        navigate(`/destinations/${data.createDestinationWithSecrets.id}`);
      },
      onError: () => {
        toast({
          id: "destination-error",
          title: "Could not create destination. Please refresh.",
          variant: "error",
        });
      },
    });

  return (
    <EmptyState
      m={8}
      title="Create a demo destination"
      message="Create a destination to get started"
      actions={
        <Button
          onClick={async () => {
            const destination: CreateDestinationV2MutationVariables["destination"] =
              {
                ...input,
                slug: slugify(input.name) + "-" + nanoid(),
                setup_complete: true,
              };

            await createDestination({ destination });
          }}
          isLoading={isLoading}
        >
          Add a destination
        </Button>
      }
    />
  );
};
