import { PauseIcon, SplitIcon, SyncIcon, TimeIcon } from "@hightouchio/ui";

import { JourneyNodeType } from "src/types/journeys";

import { NodeOptionProps } from "./types";

export const NODE_LIBRARY_OPTIONS: NodeOptionProps[] = [
  {
    icon: <SyncIcon />,
    label: "Send to destination",
    color: "cyan",
    description: "Trigger campaigns or send to other tools",
    type: JourneyNodeType.Sync,
  },
  {
    icon: <TimeIcon />,
    label: "Time delay",
    color: "warning",
    description: "Wait for a specified duration",
    type: JourneyNodeType.TimeDelay,
  },
  {
    icon: <PauseIcon />,
    label: "Hold until",
    color: "warning",
    description: "Wait until a specific action or event",
    type: JourneyNodeType.WaitUntilEvent,
  },
  {
    icon: <SplitIcon />,
    label: "Segment",
    color: "electric",
    description: "Split into logic-based branches",
    type: JourneyNodeType.Segments,
  },
];
