import { FC } from "react";

import { StatusBadge } from "@hightouchio/ui";

export enum SyncSequenceRunStatus {
  Running = "running",
  Done = "done",
  Failed = "failed",
  Cancelled = "cancelled",
}

export const SequenceStatusBadge: FC<
  Readonly<{ status: string | undefined | null }>
> = ({ status }) => {
  switch (status) {
    case SyncSequenceRunStatus.Done:
      return <StatusBadge variant="success">Completed</StatusBadge>;
    case SyncSequenceRunStatus.Failed:
      return <StatusBadge variant="error">Failed</StatusBadge>;
    case SyncSequenceRunStatus.Cancelled:
      return <StatusBadge variant="inactive">Cancelled</StatusBadge>;
    case SyncSequenceRunStatus.Running:
      return <StatusBadge variant="processing">Running</StatusBadge>;
    default:
      return <StatusBadge variant="inactive">Pending</StatusBadge>;
  }
};
