import { useDebounce } from "use-debounce";

import { useOutputTablesQuery } from "src/graphql";

import { IdentityGraph } from "src/pages/identity-resolution/types";

export type SlugValidationState =
  | "valid"
  | "invalid"
  | "validating"
  | undefined;

export const useIsOutputTableSlugAvailable = ({
  id,
  slug,
  enabled,
}: {
  id?: string;
  slug: string;
  enabled: boolean;
}) => {
  const [debouncedSlug, { isPending }] = useDebounce(slug, 1000);

  const outputTableQuery = useOutputTablesQuery(
    { name: debouncedSlug ?? "" },
    {
      enabled: Boolean(debouncedSlug) && enabled,
      select: (data) => data.idr,
    },
  );

  if (!enabled) return { state: undefined };

  const tables = outputTableQuery.data?.filter((table) => table.id !== id);

  let state: SlugValidationState = undefined;

  if (!slug) {
    state = undefined;
  } else if (outputTableQuery.isLoading || isPending()) {
    state = "validating";
  } else if (tables === undefined) {
    state = undefined;
  } else {
    state = tables.length === 0 ? "valid" : "invalid";
  }

  return { state };
};

export const useOutputTable = ({
  outputTableName,
  graph,
}: {
  outputTableName: string;
  graph?: IdentityGraph;
}) => {
  const shouldValidateName = !graph || outputTableName !== graph?.output_table;

  const { state: validationState } = useIsOutputTableSlugAvailable({
    id: graph?.id,
    slug: outputTableName,
    enabled: shouldValidateName,
  });

  return { validationState };
};
