import { useEffect } from "react";

import { useToken } from "@hightouchio/ui";

import { hidePylon, showPylon, updatePylonPosition } from "src/lib/pylon";

export const usePylonDrawerOffset = ({
  isOpen,
  size,
}: {
  isOpen?: boolean;
  size: string;
}) => {
  // width is determined by the max width defined on the drawer
  const maxWidthSize =
    size === "xl"
      ? "4xl"
      : size === "lg"
        ? "2xl"
        : size === "md"
          ? "lg"
          : size === "sm"
            ? "md"
            : size;
  const [value] = useToken("sizes", [maxWidthSize]);

  const isHidden = value === "100%";

  useEffect(() => {
    const numericalValue = parseInt(value.replace("px", ""));

    if (isOpen) {
      if (isHidden) {
        hidePylon();
      } else {
        updatePylonPosition(isNaN(numericalValue) ? 0 : numericalValue);
      }
    } else {
      if (isHidden) {
        showPylon();
      } else {
        updatePylonPosition(0);
      }
    }

    return () => {
      updatePylonPosition(0);
    };
  }, [isOpen, value]);
};
