import { FC } from "react";

import { Radio, RadioGroup } from "@hightouchio/ui";
import get from "lodash/get";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { FormField } from "@hightouchio/ui";
import { Section } from "src/ui/section";
import {
  TYPE_LABEL,
  DEFAULT_TYPE_LABEL,
  TYPE_DESCRIPTIONS,
  DEFAULT_TYPE_DESCRIPTIONS,
  TYPE_DESCRIPTION,
  DEFAULT_TYPE_DESCRIPTION,
} from "src/utils/destinations";

type TypeFieldProps = {
  error?: string;
  options: { label: string; value: string }[];
  value?: string;
  onChange?: (value: string) => void;
  path?: string[];
};

export const TypeField: FC<Readonly<TypeFieldProps>> = ({
  path,
  error,
  options,
  value,
  onChange,
}) => {
  const { config, setConfig, errors, slug } = useDestinationForm();

  const val = value || config?.type;
  const err = error || errors?.type;

  return (
    <Section>
      <FormField
        description={TYPE_DESCRIPTION[slug ?? ""] || DEFAULT_TYPE_DESCRIPTION}
        error={err}
        label={TYPE_LABEL[slug ?? ""] || DEFAULT_TYPE_LABEL}
      >
        <RadioGroup
          value={val}
          onChange={(type) => {
            if (onChange) {
              onChange(type as string);
            } else {
              setConfig({
                type,
              });
            }
          }}
        >
          {options.map((option) => (
            <Radio
              key={option.value}
              {...option}
              description={
                get(
                  TYPE_DESCRIPTIONS,
                  path
                    ? [slug ?? "", ...path, option.value]
                    : [slug ?? "", option.value],
                ) || DEFAULT_TYPE_DESCRIPTIONS?.[option.value]
              }
            />
          ))}
        </RadioGroup>
      </FormField>
    </Section>
  );
};
