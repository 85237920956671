import { FC, createContext, useContext, useState } from "react";

type ChartsContextType = {
  activeIndex: number;
  handleMouseMove: (e: any) => void;
  handleMouseLeave: (e: any) => void;
};

const ChartsContext = createContext<ChartsContextType>({
  activeIndex: -1,
  handleMouseMove: () => {},
  handleMouseLeave: () => {},
});

export const useChartsContext = () => useContext(ChartsContext);

export const ChartsContextProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [activeIndex, setActiveIndex] = useState<number>(-1);

  const handleMouseMove = (e) => {
    if (e && e.activeTooltipIndex !== undefined) {
      setActiveIndex(e.activeTooltipIndex);
    }
  };

  const handleMouseLeave = () => setActiveIndex(-1);

  return (
    <ChartsContext.Provider
      value={{
        activeIndex,
        handleMouseMove,
        handleMouseLeave,
      }}
    >
      {children}
    </ChartsContext.Provider>
  );
};
