import { FC } from "react";

import { keyframes } from "@emotion/react";
import { Box, BoxProps, Column } from "@hightouchio/ui";

type AnimationProps = {
  duration?: number;
  circle?: number;
  variant?: AnimationVariant;
} & BoxProps;

type AnimationVariant = "green" | "blue";

export const LoadingCircles: FC<AnimationProps> = ({
  duration = 4,
  circle = 96,
  variant = "blue",
  ...props
}) => {
  const circleHeightPx = `${circle}px`;

  const { colorA, colorB, colorC, shadowA, shadowB, shadowC } =
    VariantColors[variant];

  const { loadingSpin, pedalA, pedalB, pedalC } = useLoadingAnimation({
    circle,
    shadowA,
    shadowB,
    shadowC,
  });

  const circleStyles: BoxProps = {
    width: circleHeightPx,
    height: circleHeightPx,
    position: "absolute",
    mixBlendMode: "multiply",
  };

  const afterStyles: BoxProps["_after"] = {
    content: '""',
    position: "absolute",
    width: circleHeightPx,
    height: circleHeightPx,
    borderRadius: circleHeightPx,
  };

  return (
    <Column align="center" justify="center" height="200px" {...props}>
      <Box width={circleHeightPx} height={circleHeightPx}>
        <Box
          animation={`${loadingSpin} ${duration * 2}s ease-in-out infinite`}
          width={circleHeightPx}
          height={circleHeightPx}
          borderRadius={circleHeightPx}
        >
          <Box
            animation={`${loadingSpin} ${duration * 2}s ease-in-out infinite`}
            width={circleHeightPx}
            height={circleHeightPx}
            borderRadius={circleHeightPx}
          >
            <Box
              {...circleStyles}
              id="a"
              _after={{
                ...afterStyles,
                backgroundColor: colorA,
                animation: `${pedalA} ${duration}s ease-in-out infinite`,
              }}
            />
            <Box
              {...circleStyles}
              transform="rotate(120deg)"
              _after={{
                ...afterStyles,
                backgroundColor: colorB,
                animation: `${pedalB} ${duration}s ease-in-out infinite`,
              }}
            />
            <Box
              {...circleStyles}
              transform="rotate(240deg)"
              _after={{
                ...afterStyles,
                backgroundColor: colorC,
                animation: `${pedalC} ${duration}s ease-in-out infinite`,
              }}
            />
            <Box
              {...circleStyles}
              backgroundColor="white"
              opacity={0.8}
              display="none"
              mixBlendMode="normal"
              _after={{}}
            />
          </Box>
        </Box>
      </Box>
    </Column>
  );
};

const VariantColors: Record<
  AnimationVariant,
  {
    colorA: string;
    colorB: string;
    colorC: string;
    shadowA: string;
    shadowB: string;
    shadowC: string;
  }
> = {
  green: {
    colorA: "#40E398",
    colorB: "#1D656A",
    colorC: "#CEF9E3",
    shadowC: "#79D5A6",
    shadowA: "#CEF9E3",
    shadowB: "#1D656A",
  },
  blue: {
    colorA: "#BC83FB",
    colorB: "#919EF2",
    colorC: "#8BE0F0",
    shadowA: "#CCA6F8",
    shadowB: "#767FF1",
    shadowC: "#B3EBF5",
  },
};

const useLoadingAnimation = ({
  circle,
  shadowA,
  shadowB,
  shadowC,
}: {
  circle: number;
  shadowA: string;
  shadowB: string;
  shadowC: string;
}) => {
  const push = circle / 3;

  const loadingSpin = keyframes`
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(719deg);
    }
`;

  const pedalA = keyframes`
    0% {
      top: ${push}px;
      filter: drop-shadow(0px 0px 0px #FFFFFF);
    }
    50% {
      top: 0;
      filter: drop-shadow(0px ${circle / 24}px ${circle / 12}px ${shadowA});
    }
    100% {
      top: 32px;
      filter: drop-shadow(0px 0px 0px #FFFFFF);
    }
`;

  const pedalB = keyframes`
    0% {
      top: 32px;
      filter: drop-shadow(0px 0px 0px #FFFFFF);
    }
    50% {
      top: 0;
      filter: drop-shadow(${(-1 * circle) / 12}px  ${(-1 * circle) / 24}px ${
        circle / 12
      }px ${shadowB});
    }
    100% {
      top: 32px;
      filter: drop-shadow(0px 0px 0px #FFFFFF);
    }
`;
  const pedalC = keyframes`
    0% {
      top: 32px;
      filter: drop-shadow(0px 0px 0px #FFFFFF);
    }
    50% {
      top: 0;
      filter: drop-shadow(${circle / 12}px ${(-1 * circle) / 24}px ${
        circle / 12
      }px ${shadowC});
    }
    100% {
      top: 32px;
      filter: drop-shadow(0px 0px 0px #FFFFFF);
    }
`;

  return { loadingSpin, pedalA, pedalB, pedalC };
};
