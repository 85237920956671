import { FC, ReactNode } from "react";

import {
  Box,
  ChakraPopover,
  ChakraPopoverTrigger,
  ChakraPopoverContent,
  ChakraPopoverBody,
  Button,
  useOutsideClick,
  useDisclosure,
  Portal,
} from "@hightouchio/ui";

import { useManualPortal } from "src/hooks/use-manual-portal";

interface InputGroupProps {
  children: ReactNode;
  content: ReactNode;
  isInvalid?: boolean;
  width?: "sm" | "lg";
}

export const InputGroup: FC<Readonly<InputGroupProps>> = ({
  children,
  content,
  isInvalid = false,
  width = "sm",
}) => {
  // Need to attach the content to the DOM manually
  // because chakra Portal's `containerRef` renders the
  // content inside the main tree.
  const containerRef = useManualPortal();
  const { isOpen, onToggle, onClose } = useDisclosure();

  useOutsideClick({
    enabled: isOpen,
    ref: containerRef,
    handler: onClose,
  });

  if (!containerRef.current) {
    return null;
  }

  return (
    <ChakraPopover
      closeOnBlur={false}
      isOpen={isOpen}
      onClose={onClose}
      placement="bottom-start"
    >
      <ChakraPopoverTrigger>
        <Box
          sx={{
            button: {
              maxWidth: width === "sm" ? "220px" : "100%",
              width: width === "sm" ? undefined : "100%",
              justifyContent: "flex-start",
              filter: "none",
              bg: "primary.background",
              borderColor: isInvalid
                ? "danger.base"
                : isOpen
                  ? "primary.base"
                  : "primary.background",
              color: "primary.base",
              fontWeight: "normal",
              _hover: {
                color: "primary.base",
                borderColor: isInvalid ? "danger.base" : "primary.base",
                bg: "primary.background",
              },
              _active: {
                color: "primary.base",
                borderColor: isInvalid ? "danger.base" : "primary.base",
                bg: "forest.200",
              },
            },
            span: {
              color: "primary.base",
            },
          }}
        >
          <Button size="sm" onClick={onToggle}>
            {children}
          </Button>
        </Box>
      </ChakraPopoverTrigger>

      <Portal containerRef={containerRef}>
        {/* `containerRef` overrides the containerRef from hightouch ui. This hack is needed until the z-index problems are addressed. */}
        <Box
          sx={{
            "& .chakra-popover__popper": {
              zIndex: "popover",
            },
          }}
        >
          <ChakraPopoverContent width="fit-content">
            <ChakraPopoverBody pointerEvents={isOpen ? "all" : "none"}>
              {content}
            </ChakraPopoverBody>
          </ChakraPopoverContent>
        </Box>
      </Portal>
    </ChakraPopover>
  );
};
