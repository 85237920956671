import { FC } from "react";

import { Navigate, useOutletContext } from "src/router";
import { OutletContext } from ".";

import { ForwardingSyncConfiguration } from "../forwarding-sync/configuration";

export const Configuration: FC = () => {
  const context = useOutletContext<OutletContext>();

  if (context.type === "warehouse") {
    return <Navigate to={`/events/syncs/${context.sync.id}`} replace />;
  }

  return <ForwardingSyncConfiguration />;
};
