// THIS IS COPIED FROM packages/backend/sdk/interfaces/destination/mapping.ts PENDING REFACTOR
// We should be able to move this to the lib package, making it importable by the frontend

import { isEmpty } from "lodash";

export enum StandardFieldType {
  STRING = "STRING",
  ENUM = "ENUM",
  NUMBER = "NUMBER",
  BOOLEAN = "BOOLEAN",
  DATE = "DATE",
  DATETIME = "DATETIME",
  EMAIL = "EMAIL",
  OBJECT = "OBJECT",
  ARRAY = "ARRAY",
  REFERENCE = "REFERENCE",
  UNKNOWN = "UNKNOWN",
}

/**
 * This is what most of the destinations would need to apply mappings to data.
 */

export type StandardMapping = {
  type?: "standard";

  // Field from the row.
  from: string;

  // Field on the resulting entity.
  to: string;

  ignoreNull?: boolean;
  disablePIIHashing?: boolean;
  fieldType?: string;
};

/**
 * Represents a mapping that contains a reference (ie foreign key) to another entity
 * or object.
 */

export type ReferenceMapping = {
  type?: "reference";

  // The lookup entity.
  lookup: {
    by: string; // Field to perform the lookup.
    byType: StandardFieldType; // Type of the field.
    from: string; // Column that contains the field.
    object: string; // Entity (ie. Company)
    nullIfNotFound?: boolean; // Set the value to null if the reference is not found
  };

  // Field on the resulting entity.
  to: string;
};

export type KeyValueMapping = Record<
  string,
  string | { value: string; isSecret: boolean }
>;

/**
 * Represents a static value that gets determined by the sync configuration.
 */

export type StaticMapping = {
  type?: "static";
  to: string;
  value: unknown;
  valueType:
    | StandardFieldType.BOOLEAN
    | StandardFieldType.DATETIME
    | StandardFieldType.NUMBER
    | StandardFieldType.STRING;
};

export type VariableMapping = {
  type?: "variable";
  to: string;
  variable:
    | "current-timestamp"
    | "model-name"
    | "model-id"
    | "sync-id"
    | "sync-run-id";
};

export function isVariableMapping(m: unknown): m is VariableMapping {
  return (
    isMapping(m) && (m.type === "variable" || typeof m["variable"] === "string")
  );
}

export type TemplateMapping = {
  type?: "template";
  to: string;
  template: string;
};

// WARNING!! If you add a new Mapping type that references columns, you
// *must* update `lib/query/visual/sync-transform` as well to handle
// converting visual column references into string column names.
export type Mapping =
  | StandardMapping
  | ReferenceMapping
  | StaticMapping
  | VariableMapping
  // XXX: TemplateMappings do not currently support visual column
  // references.
  | TemplateMapping;

export function isMapping(m: unknown): m is Mapping {
  return (
    typeof m === "object" &&
    m !== null &&
    m !== undefined &&
    typeof m["to"] === "string" &&
    !m["destinationOnlyMapping"]
  );
}

export type Column = { from: string };

export type ColumnOrConstant<C = string> = Column | C;

export function isMappingArray(m: unknown): m is Mapping[] {
  return Array.isArray(m) && m.every(isMapping);
}

export function formatConfigValue(value: any): string {
  if (
    typeof value === "object" &&
    value !== null &&
    !isEmpty(value) &&
    !Array.isArray(value)
  ) {
    let formatted = "";
    for (const entry of Object.entries(value)) {
      formatted += `${entry[0]}: ${formatConfigValue(entry[1])}, `;
    }
    return formatted.slice(0, -2);
  }

  return JSON.stringify(value);
}
