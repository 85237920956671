import { FC, useCallback } from "react";

import { Column, Row, Slider, Text } from "@hightouchio/ui";

import { AudienceSplit } from "src/types/visual";

type Props = {
  splitGroup1: AudienceSplit;
  splitGroup2: AudienceSplit;
  onChange: (value: number, splitGroup: AudienceSplit) => void;
};

export const PercentageSlider: FC<Readonly<Props>> = ({
  splitGroup1,
  splitGroup2,
  onChange,
}) => {
  const handlePercentageChange = useCallback(
    (value: number) => {
      onChange(0, { ...splitGroup1, percentage: value });
    },
    [splitGroup1, onChange],
  );

  return (
    <Column gap={1}>
      <Row justifyContent="space-between">
        {[splitGroup1, splitGroup2].map((splitGroup, index) => (
          <Row gap={2} key={index}>
            <Text fontWeight="medium">{splitGroup.friendly_name}</Text>
            <Text color="text.secondary">{splitGroup.percentage}%</Text>
          </Row>
        ))}
      </Row>
      <Slider
        aria-label="Update holdout group percentage vs treatment group percentage"
        value={Number(splitGroup1.percentage)}
        onChange={handlePercentageChange}
      />
    </Column>
  );
};
