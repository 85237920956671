import { FC, useState } from "react";

import { Box, BoxProps, Row, Select, Text } from "@hightouchio/ui";
import {
  eachDayOfInterval,
  eachWeekOfInterval,
  endOfMonth,
  endOfWeek,
  getDate,
  getMonth,
  getYear,
  isAfter,
  isBefore,
  isSameDay,
  set,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from "date-fns";

import { MONTH_OPTIONS, WEEKDAYS, YEAR_OPTIONS } from "./constants";

export type Props = {
  value: Date;
  onChange: (value: Date) => void;
  minDate?: Date;
  maxDate?: Date;
};

export const Calendar: FC<Readonly<Props>> = ({
  value,
  onChange,
  minDate,
  maxDate,
}) => {
  const [current, setCurrent] = useState<Date>(value || new Date());
  const monthNumber = getMonth(current);
  const yearNumber = getYear(current);

  const start = startOfMonth(current);
  const end = endOfMonth(current);

  const weekStarts = eachWeekOfInterval({ start, end });

  const days = weekStarts
    .map((weekStart) =>
      eachDayOfInterval({
        start: startOfWeek(weekStart),
        end: endOfWeek(weekStart),
      }),
    )
    .flat();

  return (
    <Box width="min-content">
      <Row align="center" mb={2} gap={1} justify="center">
        <Select
          removePortal
          options={MONTH_OPTIONS}
          value={monthNumber}
          width="auto"
          onChange={(value) => setCurrent(set(current, { month: value }))}
        />
        <Select
          removePortal
          options={YEAR_OPTIONS}
          value={yearNumber}
          width="auto"
          onChange={(value) => setCurrent(set(current, { year: value }))}
        />
      </Row>
      <Box
        display="grid"
        gridTemplateColumns="repeat(7, max-content)"
        gridGap={2}
        justifyItems="center"
      >
        {WEEKDAYS.map((day) => (
          <Text key={day} fontWeight="semibold">
            {day}
          </Text>
        ))}
        {days.map((date, i) => (
          <Cell
            key={i}
            minDate={minDate}
            maxDate={maxDate}
            current={current}
            date={date}
            value={value}
            onChange={onChange}
          />
        ))}
      </Box>
    </Box>
  );
};

export const Cell: FC<
  Readonly<
    {
      date: Date;
      current: Date;
    } & Props
  >
> = ({ current, date, onChange, value, minDate, maxDate }) => {
  const currentMonth = getMonth(date) === getMonth(current);
  const selected = isSameDay(value, date);

  const styles: BoxProps["sx"] = currentMonth
    ? {
        cursor: "pointer",
        color: "black",
        ":hover": { bg: "primary.background" },
      }
    : {
        color: "primary",
      };

  if (
    (minDate && isBefore(startOfDay(date), startOfDay(minDate))) ||
    (maxDate && isAfter(startOfDay(date), startOfDay(maxDate)))
  ) {
    styles.color = "gray.400";
    styles.cursor = "not-allowed";
    styles.pointerEvents = "none";
  }

  if (selected) {
    styles.bg = "primary.pressed";
    styles.color = "white";
    styles[":hover"] = {};
  }

  const props = currentMonth
    ? {
        onClick: () => onChange(set(current, { date: getDate(date) })),
      }
    : {};

  return (
    <Row
      {...props}
      userSelect="none"
      borderRadius="3px"
      flexShrink={0}
      width="24px"
      height="24px"
      alignItems="center"
      justifyContent="center"
      sx={styles}
    >
      {getDate(date)}
    </Row>
  );
};
