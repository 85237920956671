import { Edge } from "reactflow";
import sortBy from "lodash/sortBy";

import { JourneyNodeType } from "src/types/journeys";
import { JourneyNode } from "src/pages/journeys/types";

import { getBranchNodes } from "./get-branch-nodes";
import { isSegmentBranchNode } from "./type-guards";

export const updateSegmentPriority = (
  segmentNodeId: string,
  nodes: JourneyNode[],
  edges: Edge[],
) => {
  const nodesCopy = nodes.slice();

  const parentNode = nodesCopy.find((node) => node.id === segmentNodeId);

  if (!parentNode || parentNode.data.config.type !== JourneyNodeType.Segments) {
    return nodesCopy;
  }

  // Get outgoers
  const branchNodes = getBranchNodes(parentNode.id, nodesCopy, edges).filter(
    isSegmentBranchNode,
  );

  // Order outgoers
  const orderedBranchNodes = sortBy(branchNodes, [
    "data.config.segment_is_catch_all",
    "data.config.segment_priority_rank",
  ]);

  const priorityMap = orderedBranchNodes.reduce((all, node, index) => {
    all[node.id] = index;

    return all;
  }, {});

  // Apply new priority rank
  return nodesCopy.map((node) => {
    if (isSegmentBranchNode(node) && priorityMap[node.id] !== undefined) {
      return {
        ...node,
        data: {
          ...node.data,
          config: {
            ...node.data.config,
            segment_priority_rank: priorityMap[node.id],
          },
        },
      };
    }

    return node;
  });
};
