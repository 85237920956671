import { Italic } from "@tiptap/extension-italic";
import { markInputRule, markPasteRule } from "@tiptap/react";

const underscoreInputRegex = /(?:^|\s)((?:_)((?:[^_]+))(?:_))$/;
const underscorePasteRegex = /(?:^|\s)((?:_)((?:[^_]+))(?:_))/g;

export const SlackItalic = Italic.extend({
  addOptions() {
    return { HTMLAttributes: { class: "rte-slack-italic-class" } };
  },
  addInputRules() {
    return [markInputRule({ type: this.type, find: underscoreInputRegex })];
  },
  addPasteRules() {
    return [markPasteRule({ type: this.type, find: underscorePasteRegex })];
  },
});
