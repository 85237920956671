import { useCallback, useState } from "react";

import { useDropzone } from "react-dropzone";

import { Box, Column, Text, CheckIcon, FormField, Row } from "@hightouchio/ui";

interface Props {
  /** Description to show within uploader type  */
  descripion?: string;
  /**
   * List of accepted file types.
   * If `undefined`, will accept all file types
   */
  acceptedFileTypes?: string[];

  /**
   * Function to transform file bolb into value,
   * "base64" : Will transform your file into a base64 string
   * "JSONParse": Will parse your JSON file into an object
   *  Custom Function: Will give you the file to return any value format
   */
  transformation: "base64" | "JSONParse" | "string" | ((blob: File) => any);
  label?: string;
  value: string;
  onChange?: (value: any) => void;
}

export const FileUploader = ({ transformation, onChange, ...props }: Props) => {
  const [error, setError] = useState<string>("");
  const [uploadedFileName, setUploadedFileName] = useState<string | undefined>(
    undefined,
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: useCallback(async (acceptedFiles) => {
      setError("");
      if (acceptedFiles.length === 0) {
        return setUploadedFileName(undefined);
      }

      setUploadedFileName(acceptedFiles[0].name);
      if (transformation === "JSONParse") {
        const fileContents = await acceptedFiles[0].text();
        try {
          if (typeof onChange === "function") {
            onChange(JSON.parse(fileContents));
          }
        } catch (err) {
          setError("Could not parse file.");
        }
      } else if (transformation === "base64") {
        if (typeof onChange === "function") {
          onChange(await fileToBase64(acceptedFiles[0]));
        }
      } else if (transformation === "string") {
        if (typeof onChange === "function") {
          const res = await fileToString(acceptedFiles[0]);
          onChange(res);
        }
      } else {
        const transformedValue = transformation(acceptedFiles[0]);

        if (typeof onChange === "function") {
          onChange(transformedValue);
        }
      }
    }, []),
    accept:
      Array.isArray(props.acceptedFileTypes) &&
      props.acceptedFileTypes.length > 0
        ? props.acceptedFileTypes
        : undefined,
  });

  return (
    <FormField error={error} label={props.label ?? ""}>
      <Column
        alignItems="center"
        textAlign="center"
        padding="20px"
        borderWidth={1}
        borderRadius={2}
        borderColor={uploadedFileName ? "success.base" : "gray.300"}
        borderStyle="dashed"
        backgroundColor={uploadedFileName ? "ocean.600" : "gray.100"}
        color="gray.600"
        outline="none"
        transition="border .24s ease-in-out"
      >
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          {!uploadedFileName ? (
            <>
              {props.value && (
                <Text fontWeight="semibold" mb={2}>
                  Using last saved file
                </Text>
              )}

              <Text>
                Drag your file here, or click to select a file
                {props.value && " to overwrite current file"}.{" "}
              </Text>
              {Array.isArray(props.acceptedFileTypes) &&
                props.acceptedFileTypes.length > 0 && (
                  <Text>
                    Accepted file types: {props.acceptedFileTypes.join(", ")}
                  </Text>
                )}
            </>
          ) : (
            <Row alignItems="center">
              <Text fontWeight="semibold">
                Uploaded file: {uploadedFileName}
              </Text>
              <Box
                as={CheckIcon}
                color="success.base"
                fontSize="18px"
                sx={{
                  transition: "all 0.15s",
                }}
              />
            </Row>
          )}
        </div>
      </Column>
    </FormField>
  );
};

export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      resolve(reader.result?.toString().split(",")[1] || "");
    reader.onerror = (error) => reject(error);
  });
};

export const fileToString = (file: File): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
      resolve(reader.result?.toString() || "");
    };
    reader.onerror = (error) => reject(error);
  });
};
