import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const AzureIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props} viewBox="0 0 32 32">
      <svg
        data-slug-id="azure-blue"
        viewBox="0 0 256 256"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <linearGradient
            gradientTransform="matrix(1 0 0 -1 1075 318)"
            gradientUnits="userSpaceOnUse"
            id="a"
            x1="-960.606"
            x2="-1032.511"
            y1="283.397"
            y2="70.972"
          >
            <stop offset="0" stopColor="#114a8b" />
            <stop offset="1" stopColor="#0669bc" />
          </linearGradient>
          <linearGradient
            gradientTransform="matrix(1 0 0 -1 1075 318)"
            gradientUnits="userSpaceOnUse"
            id="b"
            x1="-938.144"
            x2="-954.778"
            y1="184.402"
            y2="178.778"
          >
            <stop offset="0" stopOpacity=".3" />
            <stop offset=".071" stopOpacity=".2" />
            <stop offset=".321" stopOpacity=".1" />
            <stop offset=".623" stopOpacity=".05" />
            <stop offset="1" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            gradientTransform="matrix(1 0 0 -1 1075 318)"
            gradientUnits="userSpaceOnUse"
            id="c"
            x1="-947.292"
            x2="-868.363"
            y1="289.594"
            y2="79.308"
          >
            <stop offset="0" stopColor="#3ccbf4" />
            <stop offset="1" stopColor="#2892df" />
          </linearGradient>
        </defs>
        <path
          d="M89.158 18.266h69.238L86.523 231.224a11.041 11.041 0 0 1-10.461 7.51H22.179a11.023 11.023 0 0 1-10.445-14.548l66.963-198.41a11.04 11.04 0 0 1 10.461-7.51z"
          fill="url(#a)"
        />
        <path
          d="M189.77 161.104H79.976a5.083 5.083 0 0 0-3.468 8.8l70.552 65.847a11.091 11.091 0 0 0 7.567 2.983h62.167z"
          fill="#0078d4"
        />
        <path
          d="M89.158 18.266a10.95 10.95 0 0 0-10.483 7.654L11.817 224.006a11.01 11.01 0 0 0 10.393 14.728h55.274a11.814 11.814 0 0 0 9.069-7.714l13.33-39.29 47.625 44.418a11.267 11.267 0 0 0 7.089 2.586h61.937l-27.166-77.63-79.19.018 48.47-142.856z"
          fill="url(#b)"
        />
        <path
          d="M177.592 25.764a11.023 11.023 0 0 0-10.444-7.498H89.984a11.024 11.024 0 0 1 10.445 7.498l66.967 198.421a11.024 11.024 0 0 1-10.445 14.549h77.164a11.024 11.024 0 0 0 10.444-14.549z"
          fill="url(#c)"
        />
      </svg>
    </Icon>
  );
};
