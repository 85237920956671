import { FC } from "react";

import {
  Column,
  Combobox,
  FormField,
  IconButton,
  RefreshIcon,
  Row,
  TextInput,
} from "@hightouchio/ui";
import * as Yup from "yup";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { useOrbitWorkspacesQuery } from "src/graphql";
import { Section } from "src/ui/section";
import { COMMON_SCHEMAS, StandardFieldType } from "src/utils/destinations";

import { ColumnOrConstantField } from "src/components/destinations/column-or-constant-field";
import { IdMappingField } from "src/components/destinations/id-mapping-field";
import { MappingsField } from "src/components/destinations/mappings-field";
import { ModeField } from "src/components/destinations/mode-field";
import { ObjectField } from "src/components/destinations/object-field";

export const validation = Yup.object().shape({
  workspacePath: Yup.string().required(),
  mappings: COMMON_SCHEMAS.mappings,
  title: Yup.mixed().when("object", {
    is: "activities",
    then: COMMON_SCHEMAS.columnOrConstant,
    otherwise: Yup.mixed().notRequired(),
  }),
  source: Yup.mixed().when("object", {
    is: "activities",
    then: COMMON_SCHEMAS.columnOrConstant,
    otherwise: Yup.mixed().notRequired(),
  }),
  mode: Yup.string().when("object", {
    is: "activities",
    then: Yup.string().required().default("insert"),
    otherwise: Yup.string().required().default("upsert"),
  }),
});

// All fields from:
// https://docs.orbit.love/reference/post_-workspace-id-activities
const CUSTOM_ACTIVITY: {
  label: string;
  value: string;
  type: StandardFieldType;
}[] = [
  // Custom activity
  {
    label: "Description",
    value: "activity.description",
    type: StandardFieldType.STRING,
  },
  { label: "Link", value: "activity.link", type: StandardFieldType.STRING },
  {
    label: "Link Text",
    value: "activity.linkText",
    type: StandardFieldType.STRING,
  },
  { label: "Title", value: "activity.title", type: StandardFieldType.STRING },
  { label: "Weight", value: "activity.weight", type: StandardFieldType.STRING },
  {
    label: "Activity Type",
    value: "activity.activity_type",
    type: StandardFieldType.STRING,
  },
  {
    label: "Activity Type Key",
    value: "activity.activity_type_key",
    type: StandardFieldType.STRING,
  },
  { label: "Key", value: "activity.key", type: StandardFieldType.STRING },
  {
    label: "Occurred At",
    value: "activity.occurred_at",
    type: StandardFieldType.STRING,
  },
  { label: "Tags", value: "activity.tags", type: StandardFieldType.ARRAY },
];
const MEMBER: { label: string; value: string; type: StandardFieldType }[] = [
  // Member
  { label: "Member Bio", value: "member.bio", type: StandardFieldType.STRING },
  {
    label: "Member Birthday",
    value: "member.birthday",
    type: StandardFieldType.STRING,
  },
  {
    label: "Member Company",
    value: "member.company",
    type: StandardFieldType.STRING,
  },
  {
    label: "Member Title",
    value: "member.title",
    type: StandardFieldType.STRING,
  },
  {
    label: "Member Location",
    value: "member.location",
    type: StandardFieldType.STRING,
  },
  {
    label: "Member Name",
    value: "member.name",
    type: StandardFieldType.STRING,
  },
  {
    label: "Member Pronouns",
    value: "member.pronouns",
    type: StandardFieldType.STRING,
  },
  {
    label: "Member Shipping Address",
    value: "member.shipping_address",
    type: StandardFieldType.STRING,
  },
  {
    label: "Member Slug",
    value: "member.slug",
    type: StandardFieldType.STRING,
  },
  {
    label: "Member Tags To Add",
    value: "member.tags_to_add",
    type: StandardFieldType.ARRAY,
  },
  { label: "Member Tags", value: "member.tags", type: StandardFieldType.ARRAY },
  {
    label: "Member T-shirt",
    value: "member.tshirt",
    type: StandardFieldType.STRING,
  },
  {
    label: "Member Teammate",
    value: "member.teammate",
    type: StandardFieldType.STRING,
  },
  { label: "Member URL", value: "member.url", type: StandardFieldType.STRING },
  {
    label: "Member GitHub",
    value: "member.github",
    type: StandardFieldType.STRING,
  },
  {
    label: "Member Twitter",
    value: "member.twitter",
    type: StandardFieldType.STRING,
  },
  {
    label: "Member Email",
    value: "member.email",
    type: StandardFieldType.STRING,
  },
  {
    label: "Member LinkedIn",
    value: "member.linkedin",
    type: StandardFieldType.STRING,
  },
  {
    label: "Member Dev.to",
    value: "member.devto",
    type: StandardFieldType.STRING,
  },
];

const IDENTITY: { label: string; value: string; type: StandardFieldType }[] = [
  // Identity
  {
    label: "Identity Name",
    value: "identity.name",
    type: StandardFieldType.STRING,
  },
  {
    label: "Identity Source",
    value: "identity.source",
    type: StandardFieldType.STRING,
  },
  {
    label: "Identity Source Host",
    value: "identity.source_host",
    type: StandardFieldType.STRING,
  },
  {
    label: "Identity Username",
    value: "identity.username",
    type: StandardFieldType.STRING,
  },
  {
    label: "Identity UID",
    value: "identity.uid",
    type: StandardFieldType.STRING,
  },
  {
    label: "Identity Email",
    value: "identity.email",
    type: StandardFieldType.STRING,
  },
  {
    label: "Identity URL",
    value: "identity.url",
    type: StandardFieldType.STRING,
  },
];

const MEMBER_MODES = [{ label: "Upsert", value: "upsert" }];

const ACTIVITY_MODES = [{ label: "Insert", value: "insert" }];

const OBJECTS = [
  { label: "Activities", value: "activities" },
  { label: "Members", value: "members" },
];

const MAP_MEMBERS = MEMBER.map((o) => ({ ...o, value: `activity.${o.value}` }));
const ACTIVITY_FIELDS = [...CUSTOM_ACTIVITY, ...MAP_MEMBERS, ...IDENTITY];
const MEMBER_FIELDS = [...MEMBER, ...IDENTITY];

export const OrbitForm: FC = () => {
  const { destination, config, setConfig, errors } = useDestinationForm();

  const {
    data: workspaceData,
    error: workspaceError,
    isFetching: loadingWorkspaces,
    refetch: getWorkspaces,
  } = useOrbitWorkspacesQuery({
    destinationId: String(destination?.id),
  });

  const workspaces = workspaceData?.orbitListWorkspaces?.workspaces;
  const workspacePath: string | undefined = config?.workspacePath;

  const workspaceOpts =
    workspaces?.map((a) => ({ label: a.name, value: a.path })) || [];

  return (
    <>
      <Section>
        <FormField
          error={errors?.workspace || workspaceError?.message}
          label="Which workspace would you like to sync to?"
        >
          <Row gap={2} align="center">
            <Combobox
              isInvalid={Boolean(errors?.workspacePath)}
              isLoading={loadingWorkspaces}
              options={workspaceOpts}
              placeholder="Select an Orbit workspace..."
              value={workspacePath}
              onChange={(value) => setConfig({ workspacePath: value })}
            />
            <IconButton
              aria-label="Refresh workspaces"
              isLoading={loadingWorkspaces}
              icon={RefreshIcon}
              onClick={() => getWorkspaces()}
            />
          </Row>
        </FormField>
      </Section>

      {config?.workspacePath && (
        <ObjectField
          options={OBJECTS}
          onChange={(object) =>
            setConfig({ workspacePath: config?.workspacePath, object })
          }
        />
      )}

      {config?.object === "activities" && (
        <ModeField
          options={ACTIVITY_MODES}
          onChange={(mode) =>
            setConfig({
              workspacePath: config?.workspacePath,
              object: config?.object,
              mode,
            })
          }
        />
      )}

      {config?.object === "members" && (
        <ModeField
          options={MEMBER_MODES}
          onChange={(mode) =>
            setConfig({
              workspacePath: config?.workspacePath,
              object: config?.object,
              mode,
            })
          }
        />
      )}

      {config?.workspacePath && config?.object === "activities" && (
        <Section>
          <Column gap={8}>
            <ColumnOrConstantField
              columnLabel="Which column contains the activity title?"
              constantInput={
                <TextInput
                  value={config?.title}
                  placeholder="Activity title..."
                  onChange={(e) =>
                    setConfig({ ...config, title: e.target.value })
                  }
                />
              }
              constantLabel="What is the Activity title?"
              property="title"
            />

            <ColumnOrConstantField
              columnLabel="Which column contains the identity source?"
              constantInput={
                <TextInput
                  value={config?.source}
                  placeholder="Identity source..."
                  onChange={(e) =>
                    setConfig({ ...config, source: e.target.value })
                  }
                />
              }
              constantLabel="What is the Identity source?"
              property="source"
            />
          </Column>
        </Section>
      )}

      {config?.workspacePath && config?.object === "activities" && (
        <Section>
          <MappingsField
            required
            options={ACTIVITY_FIELDS.filter(
              (mapping) =>
                !["activity.title", "identity.source"].includes(mapping.value),
            )}
          />
        </Section>
      )}

      {config?.workspacePath && config?.object === "members" && (
        <>
          <Section>
            <IdMappingField
              options={[{ label: "Email", value: "member.email" }]}
            />
          </Section>

          <Section>
            <MappingsField options={MEMBER_FIELDS} />
          </Section>
        </>
      )}
    </>
  );
};

export default {
  form: OrbitForm,
  validation,
};
