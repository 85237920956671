import {
  ArrowRightIcon,
  Box,
  Column,
  CubeIcon,
  Row,
  StatusBadge,
  Text,
} from "@hightouchio/ui";
import { Link } from "src/router";
import pluralize from "pluralize";
import { FC } from "react";
import { IconBox } from "src/components/icon-box";
import { IntervalSchedule } from "src/components/schedule/types";
import { formatDatetime } from "src/utils/time";

type Props = {
  enabled: boolean;
  lastRunAt: string | null;
  parentModelId: string;
  parentModelName: string;
  sampleRate: number;
  schedule: IntervalSchedule;
  sourceId: string;
};

export const ParentModelSamplingConfigCard: FC<Props> = ({
  enabled,
  lastRunAt,
  parentModelId,
  parentModelName,
  sampleRate,
  schedule,
  sourceId,
}) => {
  return (
    <Row
      border="1px solid"
      borderColor="base.border"
      background="base.lightBackground"
      gap={4}
      justifyContent="space-between"
      px={6}
      py={4}
    >
      <Column gap={4}>
        <Row alignItems="center" gap={2}>
          <IconBox
            bg="electric.400"
            boxSize={6}
            icon={<CubeIcon />}
            iconSize={4}
          />
          <Text fontWeight="medium" size="md">
            Sampling for {parentModelName}
          </Text>
        </Row>
        {enabled ? (
          <Column gap={2}>
            <Row gap={1}>
              <Text color="text.secondary">Size:</Text>
              <Text fontWeight="medium">{sampleRate * 100}%</Text>
            </Row>
            <Row gap={1}>
              <Text color="text.secondary">Frequency:</Text>
              <Text fontWeight="medium">{formatSchedule(schedule)}</Text>
            </Row>
            <Row gap={1}>
              <Text color="text.secondary">Last run:</Text>
              <Text fontWeight="medium">
                {lastRunAt ? formatDatetime(lastRunAt) : "--"}
              </Text>
            </Row>
          </Column>
        ) : null}
        <Row>
          <Link
            href={`/schema-v2/view/sampling?source=${sourceId}&id=${parentModelId}`}
          >
            Go to model to edit
            <Box as={ArrowRightIcon} fontSize={20} ml={2} />
          </Link>
        </Row>
      </Column>
      <StatusBadge variant={enabled ? "success" : "inactive"}>
        {enabled ? "Active" : "Inactive"}
      </StatusBadge>
    </Row>
  );
};

const formatSchedule = (schedule: IntervalSchedule) =>
  `Every ${pluralize(
    schedule.schedule?.interval.unit ?? "",
    schedule.schedule?.interval.quantity ?? 0,
    true,
  )}`;
