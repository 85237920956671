import { FC } from "react";

import { Button, Column } from "@hightouchio/ui";

import { Warning } from "src/components/warning";
import { useUser } from "src/contexts/user-context";

export const NotFound: FC = () => {
  const { slug } = useUser();

  return (
    <Column align="center" gap={8} mt={24} mx="auto">
      <Warning
        subtitle="Sorry, we could not find what you were looking for!"
        title="Not found"
      />
      {slug && (
        <Button
          onClick={() =>
            (window.location.href = `${window.location.origin}/${slug}`)
          }
        >
          Back to workspace
        </Button>
      )}
      {!slug && (
        <Button onClick={() => (window.location.href = "/")}>
          Back to home
        </Button>
      )}
    </Column>
  );
};
