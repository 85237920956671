import { useContext, useEffect, useMemo, useRef } from "react";

import { useToast } from "@hightouchio/ui";
import { captureException } from "@sentry/react";
import { debounce, isEqual } from "lodash";
import {
  DeepPartial,
  FieldValues,
  useForm,
  UseFormProps,
} from "react-hook-form";

import { useEncodedSearchParams } from "src/hooks/use-encoded-search-params";

import { HightouchFormContext, UseHightouchFormReturn } from "./form";

const DebounceTime = 300;

export type HightouchSubmitHandler<
  T extends FieldValues,
  C extends Record<string, unknown> = Record<string, unknown>,
  R = void,
> = (data: T, ctx?: C) => Promise<R>;

export type UseHightouchFormProps<
  T extends FieldValues,
  C extends Record<string, unknown>,
  R,
> = {
  success?: string | boolean | ((data: T) => string);
  error?: string;
  errorMessage?: string;
  onSubmit: HightouchSubmitHandler<T, C, R>;
  onError?: (error: any) => any;
  values?: DeepPartial<T>;
  submitOnChange?: boolean;
  /**
   * If provided, the form state will be read (on mount) and written to a URL query string for sharability.
   */
  queryKey?: string;
} & UseFormProps<T>;

export const useHightouchFormContext = () => useContext(HightouchFormContext);

export function useHightouchForm<
  T extends FieldValues,
  C extends Record<string, unknown> = Record<string, unknown>,
  R = any,
>({
  onSubmit,
  onError,
  values,
  success = "Changes saved",
  error = "There was a problem saving your changes",
  errorMessage,
  queryKey,
  submitOnChange,
  ...props
}: UseHightouchFormProps<T, C, R>): UseHightouchFormReturn<T, C, R> {
  const [urlState, setUrlState] = useEncodedSearchParams<T>(queryKey);

  const savedValues = useRef(values);
  const isInitialized = useRef(false);

  const { toast } = useToast();

  const urlDefaultValues = useMemo(() => {
    if (queryKey) {
      return (urlState as DeepPartial<T> | null) ?? undefined;
    }

    return undefined;
  }, []);

  const form = useForm<T>({
    ...props,
    defaultValues: urlDefaultValues ?? values ?? props.defaultValues,
  });

  const { isDirty } = form.formState;

  const submit = async (_e?: MouseEvent, ctx?: C) => {
    form.clearErrors();
    let result: R | undefined;

    await form.handleSubmit(
      // Callback to handle successful form submission when form is valid
      async (data) => {
        // Promise can be rejected if onError rethrows it
        try {
          result = await onSubmit(data, ctx);
          if (typeof success === "string" || typeof success === "function") {
            toast({
              id: "save",
              title: typeof success === "function" ? success(data) : success,
              variant: "success",
            });
          }
        } catch (e) {
          // When onError is defined, assume the callsite will handle any error is receives.
          // If no onError handler, send to sentry for tracking.
          if (!onError) {
            captureException(e);
          }

          toast({
            id: "save-error",
            title: error,
            message: errorMessage || e?.message,
            variant: "error",
          });
          // Promise can be rejected if onError rethrows it
          try {
            onError?.(e);
          } catch (e1) {
            result = undefined;
            throw e1;
          }
        }
      },
      // Callback to handle validation errors
      (errors) => {
        if (typeof error === "string" || typeof error === "function") {
          toast({
            id: "save-error",
            title: error,
            message: errorMessage,
            variant: "error",
          });
        }
        onError?.(errors);
        result = undefined;
      },
    )();

    return result;
  };

  // Subscribe to form changes and update the URL
  useEffect(() => {
    if (queryKey) {
      const debouncedSetUrlState = debounce((formValues: DeepPartial<T>) => {
        setUrlState(formValues, { replace: true });
      }, DebounceTime);

      const subscription = form.watch(debouncedSetUrlState);

      return () => subscription.unsubscribe();
    }

    return;
  }, [queryKey, form.watch]);

  // If no url state is preset, reset the form to the default values
  useEffect(() => {
    if (queryKey && !urlState) {
      form.reset(values);
    }
  }, [queryKey, urlState, form.reset]);

  // On mount: set the url form state to be the initial state
  useEffect(() => {
    if (
      !isInitialized.current &&
      queryKey &&
      urlState &&
      !isEqual(urlState, savedValues.current)
    ) {
      form.reset(urlState as DeepPartial<T>, {
        keepIsSubmitted: true,
        keepTouched: true,
      });
      isInitialized.current = true;
    }
  }, [queryKey, urlState]);

  // Reset form when external values change
  useEffect(() => {
    if (!queryKey && !isEqual(savedValues.current, values)) {
      form.reset(values, { keepIsSubmitted: true, keepTouched: true });
      savedValues.current = values;
    }
  }, [queryKey, values]);

  useEffect(() => {
    if (submitOnChange && isDirty) {
      submit();
    }
  }, [submitOnChange, isDirty]);

  return { ...form, submit };
}
