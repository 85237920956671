import { FC } from "react";

import { Navigate, useOutletContext } from "src/router";
import { OutletContext } from ".";
import { ForwardingSyncErrors } from "../forwarding-sync/errors";

export const Errors: FC = () => {
  const context = useOutletContext<OutletContext>();

  if (context.type === "forwarding") {
    // TOOD: implement Errors tab
    return <ForwardingSyncErrors />;
  }

  return <Navigate to={`/events/syncs/${context.sync.id}`} replace />;
};
