import { SelectProps, Select, Box } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { forwardRef } from "react";
import { useUser } from "src/contexts/user-context";
import { useWorkspaceRolesQuery } from "src/graphql";

type Option = { id: string; name: string; permissions: any };

type Props = Omit<
  SelectProps<Option, string>,
  "options" | "optionLabel" | "optionValue" | "isOptionDisabled" | "placeholder"
>;

export const RoleSelect = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { workspace } = useUser();
  const { appAllowRoles } = useFlags();

  const { data: roles, isLoading: loadingRoles } = useWorkspaceRolesQuery(
    { workspaceId: workspace?.id ?? "" },
    {
      select: (data) =>
        data.workspaces_by_pk?.roles?.sort((a, b) =>
          Number(a.id) > Number(b.id) ? 1 : -1,
        ),
    },
  );

  return (
    <Box ref={ref}>
      <Select
        isLoading={loadingRoles || props.isLoading}
        isOptionDisabled={(role) =>
          roleDisabled(appAllowRoles, workspace?.organization?.plan?.sku, role)
        }
        optionLabel={(role) => role.name}
        optionValue={(role) => role.id}
        options={roles ?? []}
        placeholder="Select role for this user..."
        width="3xs"
        {...props}
      />
    </Box>
  );
});

RoleSelect.displayName = "RoleSelect";

export const roleDisabled = (
  allowRolesFlag: boolean,
  sku: string | undefined,
  role: { name: string },
) => {
  const workspaceIsBusiness = allowRolesFlag || sku === "business_tier";
  return !workspaceIsBusiness && role.name !== "Admin";
};
