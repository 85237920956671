import { load, save } from "src/utils/storage";

export interface HandlerCache {
  get(key: string): Promise<any>;
  set(key: string, result: any): Promise<void>;
}

/**
 * Generates a unique string key based on destinationId and modelId to be used in handlerCacheKey
 * @param destinationId
 * @param modelId
 * @returns String
 */
export function generateKey(
  destinationId: number,
  modelId: number,
  handler: string,
  handlerVariables?: Record<string, unknown>,
): string {
  const key: (number | string)[] = [];
  if (destinationId) key.push(destinationId);
  if (modelId) key.push(modelId);
  if (handler) key.push(handler);
  if (handlerVariables) key.push(Object.values(handlerVariables).join(":"));
  return key.join(":");
}

/**
 * Creates a new HandlerCache object for storing data in localStorage for a default of 5 days. The HandlerCache object has two methods: GET and SET
 * GET - Retrieves data from localStorage based on handlerCacheKey
 * SET - Saves data to localStorage with handlerCacheKey
 * @param opts
 * @returns HandlerCache
 */
export const newLocalStorageHandlerCache = (
  opts: { prefix: string; ttl: number } = { prefix: "formkitHandlers", ttl: 5 }, // Default expiration is set to 5 days
): HandlerCache => {
  const handlerCacheKey = (key: string) => `${opts.prefix}:${key}`;

  return {
    get(key: string) {
      const cacheValue = load(handlerCacheKey(key));
      if (cacheValue) {
        const { timestamp, data } = JSON.parse(cacheValue);
        const now = Date.now();
        if (now - timestamp <= opts.ttl * 24 * 60 * 60 * 1000) {
          return data;
        }
      }
    },

    async set(key: string, result: any): Promise<void> {
      const dataWithTimestamp = {
        data: result,
        timestamp: Date.now(),
      };
      save(handlerCacheKey(key), JSON.stringify(dataWithTimestamp));
    },
  };
};
