import { FC } from "react";

import { ChannelType } from "@hightouch/lib/resource-monitoring/types";
import { Box } from "@hightouchio/ui";

import { ChannelDefinition } from "src/components/notification-channels/channel-definitions";

export const ChannelIcon: FC<{ channelType: ChannelType; size?: number }> = ({
  channelType,
  size = 5,
}) => {
  const Icon = ChannelDefinition[channelType].icon;
  return (
    <Box display="contents" sx={{ svg: { width: size, height: size } }}>
      <Icon />
    </Box>
  );
};
