import { useFlags } from "launchdarkly-react-client-sdk";

import { Navigate, Outlet } from "src/router";
import { PartnerDashboard } from "src/partner/dashboard";
import { CreateSource } from "./create-source";
import { CreateSync } from "./create-sync";
import { SyncLayout } from "./sync";
import { Brand, Config as PartnerConfig, ConfigType } from "./types";
import { NotFound } from "src/pages/not-found";
import { AuthenticatedRoute } from "src/components/router/authenticated-route";
import { Layout } from "./layout";
import Source from "./source";
import { Schedule } from "./sync/schedule";
import { Model } from "./sync/model";
import { Configuration } from "./sync/configuration";
import { Runs } from "./sync/runs";
import { SyncRunWrapper } from "./sync/run";
import { SyncRunConfiguration } from "./sync/run/configuration";
import { SyncRunPendingRows } from "./sync/run/pending-rows";
import { SyncRunRejectedRows, SyncRunSuccessfulRows } from "./sync/run/rows";
import { RunDebug as SyncRunDebug } from "src/pages/syncs/sync/run/debug";

export type Config = {
  brand: Brand;
  config: PartnerConfig;
};

// Use this for initial state for easy testing
export const exampleCreateSyncConfig: Config = {
  brand: {
    logo: "https://raw.githubusercontent.com/Iterable/iterable-android-sdk/master/images/Iterable-Logo.png",
    name: "Iterable",
  },
  config: {
    type: ConfigType.CreateSync,
    destinationId: "11111",
    sourceId: "12345",
    allowedSourceTypes: ["snowflake"],
    allowedModelingMethods: [],
  },
};

export const exampleCreateSourceConfig: Config = {
  brand: {
    logo: "https://raw.githubusercontent.com/Iterable/iterable-android-sdk/master/images/Iterable-Logo.png",
    name: "Iterable",
  },
  config: {
    type: ConfigType.CreateSource,
    allowedSourceTypes: ["snowflake"],
  },
};

export const exampleViewSourceConfig: Config = {
  brand: {
    logo: "https://raw.githubusercontent.com/Iterable/iterable-android-sdk/master/images/Iterable-Logo.png",
    name: "Iterable",
  },
  config: {
    type: ConfigType.ViewSource,
    sourceId: "28730",
  },
};

export const exampleViewSyncConfig: Config = {
  brand: {
    logo: "https://raw.githubusercontent.com/Iterable/iterable-android-sdk/master/images/Iterable-Logo.png",
    name: "Iterable",
  },
  config: {
    type: ConfigType.ViewSyncConfiguration,
    syncId: "2283749",
  },
};

const PartnerIndex = () => {
  const { partnerUser } = useFlags();
  return partnerUser ? <PartnerDashboard /> : <NotFound />;
};

export const routes = {
  path: "/partner/*",
  element: (
    <AuthenticatedRoute>
      <Outlet />
    </AuthenticatedRoute>
  ),
  children: [
    {
      index: true,
      element: <PartnerIndex />,
    },
    {
      element: <Layout />,
      children: [
        {
          path: "sources/new",
          element: <CreateSource />,
        },
        {
          path: "sources/:id",
          element: <Source />,
        },
        {
          path: "syncs/new",
          element: <CreateSync />,
        },
        {
          path: "syncs/:id/*",
          element: <SyncLayout />,
          children: [
            {
              index: true,
              element: (
                <Navigate
                  to={{ pathname: "runs", search: location.search }}
                  replace
                />
              ),
            },
            { path: "runs", element: <Runs /> },
            { path: "configuration", element: <Configuration /> },
            { path: "schedule", element: <Schedule /> },
            { path: "model", element: <Model /> },
          ],
        },
        {
          path: "syncs/:sync_id/runs/:run_id/*",
          element: <SyncRunWrapper />,
          children: [
            {
              index: true,
              element: (
                <Navigate
                  to={{ pathname: "successful", search: location?.search }}
                  replace
                />
              ),
            },
            {
              path: "successful",
              element: <SyncRunSuccessfulRows />,
              children: [{ path: "debug/:row_id", element: <SyncRunDebug /> }],
            },
            {
              path: "rejected",
              element: <SyncRunRejectedRows />,
              children: [{ path: "debug/:row_id", element: <SyncRunDebug /> }],
            },
            {
              path: "pending",
              element: <SyncRunPendingRows />,
            },
            {
              path: "configuration",
              element: <SyncRunConfiguration />,
            },
          ],
        },
      ],
    },
  ],
};
