import { CloseIcon, Column, RefreshIcon } from "@hightouchio/ui";

import { useGraphContext } from "src/pages/journeys/graph";
import { JourneyNodeDetails } from "src/pages/journeys/types";
import { EntryCohortConfig } from "src/types/journeys";

import { DescriptionRow } from "./description-row";
import { EmptyStateDescription } from "./empty-state-description";

export const EntryNodeDescription = ({
  segment_id,
  config,
}: JourneyNodeDetails<EntryCohortConfig>) => {
  const { audienceOptions } = useGraphContext();

  const selectedAudience = audienceOptions.find(
    (audience) => audience.id.toString() === segment_id?.toString(),
  );

  return selectedAudience ? (
    <Column gap={4}>
      <DescriptionRow label="On audience entry">
        {selectedAudience.name}
      </DescriptionRow>
      <DescriptionRow
        label="Re-entry"
        icon={config.max_num_entries === 1 ? CloseIcon : RefreshIcon}
      >
        {config.max_num_entries === 1 ? "Only one entry" : "Unlimited"}
      </DescriptionRow>
    </Column>
  ) : (
    <EmptyStateDescription />
  );
};
