import { range } from "lodash";

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const WEEKDAYS = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

const MIN_YEAR = 1900;
const MAX_YEAR = new Date().getFullYear() + 3;

export const YEAR_OPTIONS = range(MAX_YEAR, MIN_YEAR - 1).map((year) => ({
  label: String(year),
  value: year,
}));

export const MONTH_OPTIONS = MONTHS.map((label, index) => ({
  label,
  value: index,
}));
