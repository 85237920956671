import { ColumnOption } from "../formkit-context";

export const isValidJsonColumnMetadata = (
  columns: Record<string, string[] | null>,
): columns is Record<string, string[]> => {
  const keys = Object.keys(columns);

  for (const key of keys) {
    if (columns[key] === null) {
      return false;
    }
  }
  return true;
};

export const extractEligibleInlineMapperColumns = (
  columns: ColumnOption[],
): { [column: string]: string[] | null } => {
  const allColumnProps = {};

  columns.forEach((column) => {
    if (column.metadata?.properties !== undefined) {
      allColumnProps[column.label] = column.metadata.properties;
    }
    if (column.options) {
      column.options.forEach((nestedColumn) => {
        if (nestedColumn.metadata?.properties !== undefined) {
          allColumnProps[nestedColumn.label] = nestedColumn.metadata.properties;
        }
      });
    }
  });

  return allColumnProps;
};
