import { Text, Row, Avatar } from "@hightouchio/ui";

import { formatDate } from "src/utils/time";

export const LastUpdatedColumn = {
  name: "Last updated",
  max: "max-content",
  cell: ({ updated_at, created_at, updated_by_user, created_by_user }) => {
    const timestamp = updated_at || created_at;
    const name = updated_by_user?.name || created_by_user?.name;

    if (!name && !timestamp) {
      return <Text fontWeight="medium">-</Text>;
    }

    if (!name) {
      return <Text fontWeight="medium">{formatDate(timestamp)}</Text>;
    }

    return (
      <Row align="center" gap={2}>
        <Avatar name={name} />
        <Text fontWeight="medium">{formatDate(timestamp)}</Text>
      </Row>
    );
  },
};
