import { typeGuard, type ExtendedTypesSync } from ".";
import type { BaseType, Props, ValidatorSync } from "./base";

/**
 * Union of two or more types.
 */
export interface UnionType extends BaseType {
  type: "UNION";
  types?: [ExtendedTypesSync, ExtendedTypesSync, ...ExtendedTypesSync[]];
}

export function union(props?: Props<UnionType>): UnionType {
  return {
    ...props,
    type: "UNION",
  };
}

export const validator: ValidatorSync<UnionType> = (type) => {
  const tests = type.types?.map(typeGuard) ?? [];

  return (value) => {
    if (!tests.some((test) => test(value))) {
      throw new Error(
        `value ${JSON.stringify(
          value,
        )} does not meet any of the following types: ${
          type?.types?.map((t) => t.type) ?? "unknown"
        }`,
      );
    }
  };
};
