import { SchemaPropertyType } from "src/events/contracts/types";
import { EventSchemaEventType } from "src/graphql";

export const typeOptions = [
  { label: "String", value: SchemaPropertyType.String },
  { label: "Number", value: SchemaPropertyType.Number },
  { label: "Boolean", value: SchemaPropertyType.Boolean },
  { label: "Object", value: SchemaPropertyType.Object },
  { label: "Array", value: SchemaPropertyType.Array },
];

export const validationOptions = [
  {
    label: "Required",
    value: "required",
  },
  {
    label: "Optional",
    value: "optional",
  },
];

export const eventTypeOptions = [
  { label: "Track", value: EventSchemaEventType.Track },
  { label: "Page", value: EventSchemaEventType.Page },
  { label: "Screen", value: EventSchemaEventType.Screen },
  { label: "Identify", value: EventSchemaEventType.Identify },
  { label: "Group", value: EventSchemaEventType.Group },
  { label: "Alias", value: EventSchemaEventType.Alias },
];
