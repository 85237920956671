import emailProviders from "email-providers/all.json";

export const getInitials = (
  name: string | undefined | null,
): string | undefined => {
  if (!name || typeof name !== "string") {
    return "";
  }

  const initials = name.split(" ");

  if (initials.length === 2) {
    const first = initials[0]?.[0] || "";
    const second = initials[1]?.[0] || "";
    return `${first}${second}`.toUpperCase();
  }

  return initials[0]?.[0]?.toUpperCase();
};

export const isPersonalEmail = (email: string) => {
  const domain = email.split("@").pop();
  return emailProviders.includes(domain || "");
};
