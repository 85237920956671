import { FC } from "react";

import {
  CloseIcon,
  Column,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  FormField,
  IconButton,
  NumberInput,
  Row,
  Select,
} from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller } from "react-hook-form";

import { ErrorMessage } from "src/components/explore/visual/error-message";
import { Form, SaveButton, useHightouchForm } from "src/components/form";
import { PermissionedEditableHeading } from "src/components/permission";
import { TIME_OPTIONS } from "src/constants";
import { JOURNEY_UPDATE_PERMISSION } from "src/pages/journeys/constants";
import { useGraphContext } from "src/pages/journeys/graph";
import {
  JourneyNodeDetails,
  NodeDetailFormProps,
} from "src/pages/journeys/types";
import { TimeDelayConfig } from "src/types/journeys";

import { TimeDelayFormSchema } from "./validation-schemas";

export const TimeDelayForm: FC<NodeDetailFormProps<TimeDelayConfig>> = ({
  id, // id techincally lives in data too, redundant to have both :/
  data,
  onClose,
}) => {
  const { isEditMode, onUpdateNode } = useGraphContext();

  const form = useHightouchForm<JourneyNodeDetails<TimeDelayConfig>>({
    onSubmit: (newTileData) => {
      onUpdateNode(id, newTileData);
      onClose();

      return Promise.resolve();
    },
    success: "Tile was saved",
    values: data,
    resolver: yupResolver(TimeDelayFormSchema),
  });

  return (
    <Form form={form}>
      <DrawerHeader>
        <Row align="center" justify="space-between" flex={1} minWidth={0}>
          <Controller
            name="name"
            control={form.control}
            render={({ field, fieldState }) => (
              <Column>
                <PermissionedEditableHeading
                  isDisabled={!isEditMode}
                  permission={JOURNEY_UPDATE_PERMISSION}
                  value={field.value}
                  onChange={field.onChange}
                />
                {fieldState.error?.message && (
                  <ErrorMessage fontWeight="normal">
                    {fieldState.error.message}
                  </ErrorMessage>
                )}
              </Column>
            )}
          />
          <IconButton
            aria-label="Close drawer."
            icon={CloseIcon}
            onClick={onClose}
          />
        </Row>
      </DrawerHeader>

      <DrawerBody>
        <Column minHeight={0} flex={1} gap={6} pb={4}>
          <FormField label="Time duration">
            <Row gap={6}>
              <Controller
                control={form.control}
                name="config.delay.quantity"
                render={({ field, fieldState }) => (
                  <Column justify="center" gap={1}>
                    <NumberInput
                      isDisabled={!isEditMode}
                      isInvalid={Boolean(fieldState.error?.message)}
                      placeholder="Enter the quantity..."
                      min={0}
                      value={field.value}
                      width="100%"
                      onChange={field.onChange}
                    />
                    {fieldState.error?.message && (
                      <ErrorMessage>Quantity is required</ErrorMessage>
                    )}
                  </Column>
                )}
              />
              <Controller
                control={form.control}
                name="config.delay.unit"
                render={({ field, fieldState }) => (
                  <Column gap={1}>
                    <Select
                      isDisabled={!isEditMode}
                      isInvalid={Boolean(fieldState.error?.message)}
                      onChange={field.onChange}
                      options={TIME_OPTIONS}
                      placeholder="Select a unit..."
                      value={field.value}
                    />
                    {fieldState.error?.message && (
                      <ErrorMessage>Unit is required</ErrorMessage>
                    )}
                  </Column>
                )}
              />
            </Row>
          </FormField>
        </Column>
      </DrawerBody>

      {isEditMode && (
        <DrawerFooter>
          <SaveButton permission={JOURNEY_UPDATE_PERMISSION}>Update</SaveButton>
        </DrawerFooter>
      )}
    </Form>
  );
};
