import { Column, Row, Text, Tooltip } from "@hightouchio/ui";
import { NodeToolbar } from "@reactflow/node-toolbar";
import { FC, useEffect, useRef } from "react";
import { Position } from "reactflow";
import { SchemaModelType } from "src/types/schema";
import { useMenuOptions, MenuOption } from "./utils";

const DefaultOffset = 24;

export const Menu: FC<{
  type: SchemaModelType;
  isVisible: boolean;
  isDisabled: boolean;
  offsetY?: number;
  onClose: () => void;
  onChange: (option: MenuOption) => void;
}> = ({ type, isVisible, offsetY, onClose, onChange, isDisabled }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const menuOptions = useMenuOptions(type);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (!ref?.current?.contains(e.target)) {
        onClose();
      }
    };

    if (isVisible) {
      document.addEventListener("click", handleOutsideClick, false);
    } else {
      document.removeEventListener("click", handleOutsideClick, false);
    }
    return () => {
      document.removeEventListener("click", handleOutsideClick, false);
    };
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }

  return (
    <NodeToolbar isVisible position={Position.Right} offset={-16}>
      <Column
        ref={ref}
        width="360px"
        border="1px"
        borderColor="base.border"
        borderRadius="md"
        bg="white"
        py={2}
        pos="absolute"
        top={`${offsetY || DefaultOffset}px`}
        sx={{ "& > span": { width: "100%" } }}
      >
        {menuOptions?.map((option) => {
          const { icon: Icon } = option;

          return (
            <Tooltip
              key={option.type}
              message="You do not have permission to perform this action."
              isDisabled={!isDisabled}
            >
              <Row
                width="100%"
                gap={2}
                align="flex-start"
                py={2}
                px={2}
                onClick={async () => {
                  onClose();
                  onChange(option);
                }}
                cursor="pointer"
                _hover={{
                  bg: "gray.100",
                }}
                pointerEvents={!isDisabled ? "auto" : "none"}
                opacity={!isDisabled ? 1 : 0.5}
              >
                <Column fontSize="20px">
                  <Icon color="text.secondary" />
                </Column>
                <Column>
                  <Text size="md" fontWeight="medium">
                    {option.label}
                  </Text>
                  <Text size="sm" color="text.secondary">
                    {option.description}
                  </Text>
                </Column>
              </Row>
            </Tooltip>
          );
        })}
      </Column>
    </NodeToolbar>
  );
};
