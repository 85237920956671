import { Alert, Box, Column, Spinner } from "@hightouchio/ui";

import { Card } from "src/components/card";
import { Docs } from "src/components/page/sidebar-form";

import { Summary } from "./components/summary";
import { Latency } from "./components/latency";
import { Graph } from "./components/graph";
import { useEventMetricsQuery } from "src/graphql";
import { useOutletContext } from "src/router";
import { OutletContext } from "..";
import { useMemo } from "react";

export const ForwardingSyncOverview = () => {
  const { sync } = useOutletContext<OutletContext>();

  const input: any = useMemo(() => {
    return {
      id: sync.id,
      resourceType: "SYNC",
      requests: [
        {
          aggregateRequests: {
            aggType: "SUM",
            startTime: Date.now() - 1000 * 60 * 60 * 24,
            endTime: Date.now(),
            metrics: ["ATTEMPTED", "DELIVERED", "FAILED"],
          },
        },
        {
          aggregateRequests: {
            aggType: "AVG",
            startTime: Date.now() - 1000 * 60 * 60 * 24,
            endTime: Date.now(),
            metrics: ["LAG"],
          },
        },
        {
          currentDataRequests: {
            metric: "RETRY",
          },
        },
      ],
    };
  }, [sync.id]);

  const { data, isLoading } = useEventMetricsQuery(
    {
      input,
    },
    {
      select: (data) => {
        let attempted, delivered, failed, lag, retrying;

        for (const result of data.getEventMetrics.results) {
          for (const metric of result.aggregateData ?? []) {
            switch (metric?.metric) {
              case "ATTEMPTED":
                attempted = metric.value;
                break;
              case "DELIVERED":
                delivered = metric.value;
                break;
              case "FAILED":
                failed = metric.value;
                break;
              case "LAG":
                lag = metric.value;
                break;
            }
          }
          if (result?.currentData) {
            retrying = result.currentData.value;
          }
        }

        return { attempted, delivered, failed, lag, retrying };
      },
    },
  );

  if (isLoading) return <Spinner size="lg" m="auto" />;

  return (
    <Column>
      {!data?.attempted ? (
        <Alert
          variant="inline"
          type="info"
          title="No metrics available yet"
          message="It may take up to 5 mins for new events to be reflected in the metrics."
          mt={6}
        />
      ) : null}
      <Box
        display="grid"
        py={6}
        gridGap={6}
        gridTemplateColumns={[
          "minmax(0,1fr)",
          "minmax(0,1fr)",
          "minmax(0,1fr)",
          "minmax(0,1fr) minmax(0,1fr)",
          "minmax(0,2.5fr) minmax(0,1fr)",
        ]}
        alignItems="flex-start"
      >
        <Summary
          delivered={data?.delivered}
          retrying={data?.retrying}
          failed={data?.failed}
        />
        <Latency value={data?.lag} />
        <Graph syncId={sync.id} />
        <Card>
          <Docs name="event streaming" docsUrl="" />
        </Card>
      </Box>
    </Column>
  );
};
