import { FC } from "react";

import { Routes, Route } from "src/router";

import { CreateEventWarehouseDestination } from "./create";
import { EventDestinationRouter } from "./destination";
import { EventDestinations } from "./destinations";

export const EventDestinationsRouter: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<EventDestinations />} />
      <Route path="new" element={<CreateEventWarehouseDestination />} />
      <Route path=":id/*" element={<EventDestinationRouter />} />
    </Routes>
  );
};
