import { ColumnSettings } from "src/components/models/column-settings";
import { OutletContext } from ".";

export const ModelColumns = ({
  context: { model, columns },
}: {
  context: OutletContext;
}) => {
  const sourceColumnDescriptions = model.source_column_descriptions;

  return (
    <ColumnSettings
      columns={columns}
      isDraft={model.draft || false}
      loading={false}
      modelId={model.id}
      dbtColumns={model.query_dbt_model?.data?.columns || []}
      sourceColumnDescriptions={sourceColumnDescriptions}
      sourceType={model.connection?.type || ""}
    />
  );
};
