import * as Yup from "yup";

export const FN_TEMPLATE = `/*
* Get started by reading the docs:
* https://hightouch.com/docs/events/functions/getting-started
*/

async function transformEvents(events) {
  return events;
}
`;

export const codeValidator = Yup.string()
  .required("Function code is required")
  .max(100_000, "Function code must be less than 100,000 characters");

export const testInputValidator = Yup.array(Yup.object()).max(
  100,
  "Array must have less than 100 items",
);
