export const Icon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20px"
    height="20px"
    viewBox="0 0 24 24"
    fill={color}
  >
    <path
      fillRule="evenodd"
      d="M19.044 16.393a1.504 1.504 0 0 1-.3-1.66 1.503 1.503 0 0 1 1.376-.91h.082a1.823 1.823 0 1 0 0-3.646h-.155a1.504 1.504 0 0 1-1.376-.911v-.073a1.504 1.504 0 0 1 .3-1.659l.055-.055A1.824 1.824 0 1 0 16.447 4.9l-.055.055a1.504 1.504 0 0 1-1.658.3 1.504 1.504 0 0 1-.912-1.376v-.082a1.823 1.823 0 0 0-3.645 0v.155a1.504 1.504 0 0 1-.912 1.376h-.073a1.504 1.504 0 0 1-1.658-.3l-.055-.055a1.823 1.823 0 1 0-2.58 2.58l.056.054a1.504 1.504 0 0 1 .3 1.659 1.504 1.504 0 0 1-1.376.984h-.082a1.823 1.823 0 0 0 0 3.645h.155a1.504 1.504 0 0 1 1.376.912 1.504 1.504 0 0 1-.3 1.658l-.055.055a1.823 1.823 0 1 0 2.579 2.58l.055-.055a1.505 1.505 0 0 1 1.658-.301 1.504 1.504 0 0 1 .985 1.376v.082a1.823 1.823 0 1 0 3.645 0v-.155a1.502 1.502 0 0 1 .912-1.376 1.504 1.504 0 0 1 1.658.3l.055.055a1.824 1.824 0 1 0 2.58-2.579l-.056-.054Zm-4.636 1.361a2.504 2.504 0 0 1 2.757.503l.007.008.055.054a.826.826 0 0 0 1.165 0 .823.823 0 0 0 0-1.164l-.062-.063a2.504 2.504 0 0 1-.503-2.757 2.504 2.504 0 0 1 2.289-1.513h.086a.823.823 0 0 0 0-1.645h-.159a2.504 2.504 0 0 1-2.291-1.517.997.997 0 0 1-.072-.255 2.505 2.505 0 0 1 .577-2.57l.007-.008.055-.055a.826.826 0 0 0 0-1.164v-.001a.822.822 0 0 0-1.165 0l-.062.063a2.504 2.504 0 0 1-2.757.503 2.504 2.504 0 0 1-1.513-2.29v-.086a.823.823 0 0 0-1.645 0v.16a2.504 2.504 0 0 1-1.518 2.29 1 1 0 0 1-.255.072 2.504 2.504 0 0 1-2.57-.576l-.007-.008-.055-.055a.825.825 0 0 0-1.165 0 .823.823 0 0 0 0 1.165l.062.063a2.504 2.504 0 0 1 .514 2.732 2.503 2.503 0 0 1-2.28 1.61h-.106a.823.823 0 0 0 0 1.645h.159a2.504 2.504 0 0 1 2.29 1.513 2.504 2.504 0 0 1-.504 2.757l-.007.008-.055.054a.825.825 0 0 0 0 1.165.823.823 0 0 0 1.164 0l.063-.062a2.504 2.504 0 0 1 2.733-.513 2.504 2.504 0 0 1 1.61 2.28v.105a.823.823 0 0 0 1.645 0v-.159a2.503 2.503 0 0 1 1.513-2.289Z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M12 13.995a1.995 1.995 0 1 0 0-3.99 1.995 1.995 0 0 0 0 3.99Zm0 1a2.995 2.995 0 1 0 0-5.99 2.995 2.995 0 0 0 0 5.99Z"
      clipRule="evenodd"
    />
  </svg>
);
