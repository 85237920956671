import { FC, useEffect } from "react";

import {
  Box,
  Button,
  ButtonGroup,
  chakra,
  ChakraModal,
  ChakraModalBody,
  ChakraModalContent,
  ChakraModalFooter,
  ChakraModalHeader,
  ChakraModalOverlay,
  Column,
  FormField,
  NumberInput,
  Row,
  Select,
  Text,
  Textarea,
  TextInput,
} from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { number, object, string } from "yup";

import { EventColumn } from "src/components/explore/filter-popover/constants";
import { IconBox } from "src/components/icon-box";
import {
  formatValue,
  getOperatorLabel,
  getPropertyNameFromProperty,
} from "src/components/explore/visual/utils";
import { MetricSelection } from "src/pages/analytics/types";
import {
  AttributionBasis,
  IntervalUnit,
  PropertyCondition,
  exhaustiveCheck,
} from "src/types/visual";

import {
  AggregationOption,
  ATTRIBUTION_BASIS_OPTIONS,
  TIME_OPTIONS,
} from "./constants";
import { metricFormDefaultValues } from "./use-metric-form";

type MetricModalProps = {
  isLoading?: boolean;
  isOpen: boolean;
  metricDefinition: MetricSelection | null;
  onClose: () => void;
  onSave: (dataToSave: SimpleMetricFormData) => Promise<void>;
};

export type SimpleMetricFormData = {
  name: string;
  description?: string;
  quantity: number;
  unit: IntervalUnit.Day | IntervalUnit.Week | IntervalUnit.Month;
  basis?: AttributionBasis;
};

const simpleMetricModalSchema = object().shape({
  name: string().required("Name is required"),
  description: string(),
  quantity: number()
    .min(0, "Quantity must be 0 or greater")
    .required("Quantity is required"),
  unit: string()
    .oneOf([IntervalUnit.Day, IntervalUnit.Week, IntervalUnit.Month])
    .required(),
  basis: string().oneOf(Object.values(AttributionBasis)),
});

export const SimpleMetricModal: FC<MetricModalProps> = ({
  isLoading,
  isOpen,
  metricDefinition,
  onClose,
  onSave,
}) => {
  const { control, handleSubmit, reset, setValue } = useForm({
    resolver: yupResolver(simpleMetricModalSchema),
    defaultValues: {
      name: "",
      description: "",
      ...(metricDefinition?.attributionWindow ??
        metricFormDefaultValues.attributionWindow),
    },
  });

  const resetForm = () => {
    reset({
      name: "",
      description: "",
      ...metricFormDefaultValues.attributionWindow,
    });
  };

  const saveMetric = async (formData: SimpleMetricFormData) => {
    await onSave(formData);
    resetForm();
  };

  const closeModal = () => {
    onClose();
    resetForm();
  };

  useEffect(() => {
    setValue(
      "quantity",
      metricDefinition?.attributionWindow?.quantity ??
        metricFormDefaultValues.attributionWindow.quantity,
    );
    setValue(
      "unit",
      metricDefinition?.attributionWindow?.unit ??
        metricFormDefaultValues.attributionWindow.unit,
    );
    setValue(
      "basis",
      metricDefinition?.attributionWindow?.basis ??
        metricFormDefaultValues.attributionWindow.basis,
    );
  }, [metricDefinition?.attributionWindow]);

  const getMetricSummary = () => {
    if (!metricDefinition?.aggregationMethod) {
      return null;
    }

    const eventIcon = (
      <IconBox
        bg={EventColumn.color}
        display="inline-flex"
        icon={EventColumn.icon}
        boxSize={6}
        iconSize={4}
      />
    );
    const metricName = (
      <Text color="text.primary" fontWeight="medium">
        {metricDefinition.name}
      </Text>
    );

    switch (metricDefinition.aggregationMethod) {
      case AggregationOption.AverageOfProperty:
      case AggregationOption.AverageOfPropertyPerUser:
        return (
          <>
            <Text color="text.primary" fontWeight="medium">
              Average
            </Text>
            of
            <Text color="text.primary" fontWeight="medium">
              {metricDefinition?.column?.alias ||
                metricDefinition?.column?.name}
            </Text>
            events{" "}
            {metricDefinition.aggregationMethod ===
            AggregationOption.AverageOfPropertyPerUser
              ? "per user"
              : ""}
            in
            {eventIcon}
            {metricName}
          </>
        );
      case AggregationOption.Count:
        return (
          <>
            <Text color="text.primary" fontWeight="medium">
              Total number
            </Text>
            of
            {eventIcon}
            {metricName}
            events performed
          </>
        );
      case AggregationOption.CountDistinctProperty:
        return (
          <>
            <Text color="text.primary" fontWeight="medium">
              Distinct count
            </Text>
            of
            <Text color="text.primary" fontWeight="medium">
              {metricDefinition?.column?.alias ||
                metricDefinition?.column?.name}
            </Text>
            across all events in
            {eventIcon}
            {metricName}
          </>
        );
      case AggregationOption.SumOfProperty:
      case AggregationOption.SumOfPropertyPerUser:
        return (
          <>
            <Text color="text.primary" fontWeight="medium">
              Sum
            </Text>
            of
            <Text color="text.primary" fontWeight="medium">
              {metricDefinition?.column?.alias ||
                metricDefinition?.column?.name}
            </Text>
            events{" "}
            {metricDefinition.aggregationMethod ===
            AggregationOption.SumOfPropertyPerUser
              ? "per user"
              : ""}{" "}
            in
            {eventIcon}
            {metricName}
          </>
        );
      case AggregationOption.UniqueUsers:
      case AggregationOption.PercentOfAudience:
        return (
          <>
            <Text color="text.primary" fontWeight="medium">
              {metricDefinition?.aggregationMethod ===
              AggregationOption.UniqueUsers
                ? "Total number of users"
                : "% of users"}
            </Text>
            who performed
            {eventIcon}
            {metricName}
          </>
        );
      default:
        exhaustiveCheck(metricDefinition.aggregationMethod);
    }
  };

  return (
    <ChakraModal closeOnOverlayClick isOpen={isOpen} onClose={closeModal}>
      <ChakraModalOverlay>
        <ChakraModalContent
          py={6}
          px={0}
          w="xl"
          maxW="xl"
          my="auto"
          motionProps={{
            initial: {
              scale: 0.9,
              opacity: 0,
            },
            variants: {
              enter: {
                scale: 1,
                opacity: 1,
                transition: {
                  type: "spring",
                  damping: 30,
                  stiffness: 1000,
                },
              },
              exit: {
                scale: 0.9,
                opacity: 0,
                transition: {
                  type: "spring",
                  duration: 0.15,
                },
              },
            },
          }}
        >
          <ChakraModalHeader mb={5} px={5}>
            Create a metric
          </ChakraModalHeader>
          <ChakraModalBody
            mt={0}
            p={5}
            borderBottom="1px solid"
            borderTop="1px solid"
            borderColor="base.border"
          >
            <chakra.form display="flex" flexDirection="column" gap={4}>
              <Controller
                control={control}
                name="name"
                render={({ field, fieldState: { error } }) => (
                  <FormField label="Name" error={error?.message}>
                    <TextInput
                      {...field}
                      placeholder="Enter a name"
                      width="100%"
                      value={field.value.toString()}
                    />
                  </FormField>
                )}
              />
              <Controller
                control={control}
                name="description"
                render={({
                  field: { ref, ...field },
                  fieldState: { error },
                }) => (
                  <FormField
                    isOptional
                    error={error?.message}
                    label="Description"
                    tip="Helps teammates understand what this metric means."
                  >
                    <Textarea
                      {...field}
                      placeholder="Enter a description..."
                      width="100%"
                      value={field.value?.toString() ?? ""}
                    />
                  </FormField>
                )}
              />

              <Text fontWeight="medium">Metric summary</Text>
              <Column
                p={4}
                gap={4}
                border="1px solid"
                borderColor="base.border"
                borderRadius="md"
              >
                <Row as={Text} align="center" gap={1.5} color="text.secondary">
                  {getMetricSummary()}
                </Row>
                {metricDefinition?.conditions &&
                  metricDefinition.conditions.length > 0 && (
                    <Column pl={4} gap={4}>
                      {(
                        metricDefinition?.conditions as PropertyCondition[]
                      ).map((subcondition, index) => (
                        <Row
                          key={index}
                          as={Text}
                          align="center"
                          gap={1.5}
                          color="text.secondary"
                        >
                          {index === 0 ? (
                            "Where"
                          ) : (
                            <Box
                              as={Text}
                              px={1}
                              py={0.5}
                              bg="teal.300"
                              borderRadius="md"
                              fontWeight="medium"
                              textTransform="uppercase"
                            >
                              And
                            </Box>
                          )}

                          <Text color="text.primary" fontWeight="medium">
                            {getPropertyNameFromProperty(subcondition.property)}
                          </Text>
                          <Text color="text.secondary" fontWeight="semibold">
                            {getOperatorLabel(
                              subcondition.operator,
                              subcondition.propertyType,
                            )}
                          </Text>
                          <Text color="text.primary" fontWeight="medium">
                            {formatValue(subcondition, subcondition.value)}
                          </Text>
                        </Row>
                      ))}
                    </Column>
                  )}
              </Column>

              <Controller
                control={control}
                name="quantity"
                render={({ field, fieldState: { error } }) => (
                  <FormField
                    error={error?.message}
                    label="Attribution window"
                    tip="During what period should customer actions be attributed back to an audience? Default is 0 days after leaving an audience"
                  >
                    <Row gap={2}>
                      <NumberInput
                        {...field}
                        width="auto"
                        value={field.value}
                        onChange={(value) =>
                          field.onChange(value === undefined ? 0 : value)
                        }
                      />
                      <Controller
                        control={control}
                        name="unit"
                        render={({ field: { ref, ...field } }) => (
                          <Select
                            {...field}
                            options={TIME_OPTIONS}
                            width="auto"
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name="basis"
                        render={({ field: { ref, ...field } }) => (
                          <Select
                            {...field}
                            options={ATTRIBUTION_BASIS_OPTIONS}
                            width="auto"
                          />
                        )}
                      />
                    </Row>
                  </FormField>
                )}
              />
            </chakra.form>
          </ChakraModalBody>
          <ChakraModalFooter
            px={5}
            mt={4}
            alignItems="center"
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            gap={4}
          >
            <ButtonGroup>
              <Button isDisabled={isLoading} onClick={closeModal}>
                Cancel
              </Button>
              <Button
                isLoading={isLoading}
                variant="primary"
                onClick={handleSubmit(saveMetric)}
              >
                Save metric
              </Button>
            </ButtonGroup>
          </ChakraModalFooter>
        </ChakraModalContent>
      </ChakraModalOverlay>
    </ChakraModal>
  );
};
