import { FC } from "react";

import {
  Box,
  UpsellButton,
  ChakraUnorderedList,
  ChakraListItem,
} from "@hightouchio/ui";
import { LinkButton } from "src/router";
import { Navigate, Route, Routes } from "src/router";

import bottomRightGradient from "src/assets/backgrounds/bottom-right-blue-gradient.svg";
import topLeftGradient from "src/assets/backgrounds/top-left-green-gradient.svg";
import { useEntitlements } from "src/hooks/use-entitlement";
import * as analytics from "src/lib/analytics";
import { newPylonMessage } from "src/lib/pylon";
import { EventsIcon } from "src/ui/icons/events";
import { useUser } from "src/contexts/user-context";
import { HightouchRegions, getHightouchRegionConfig } from "src/utils/regions";

import { SplashPage } from "src/components/splash-page";
import { ContractsRouter } from "./contracts";
import { EventDestinationsRouter } from "./destinations";
import { EventSourcesRouter } from "./sources";
import { EventWarehouseSyncsRouter } from "./syncs";
import { FunctionsRouter } from "./functions";

export const EventsRouter: FC = () => {
  const { data: entitlementsData } = useEntitlements(true, true);
  const showEvents = entitlementsData.entitlements.events;

  const { region } = useUser();
  const regionConfig = getHightouchRegionConfig(region);

  const unlockEventCollection = () => {
    newPylonMessage("Hi, I'd like to unlock Event Collection!");
  };

  const trackLearnMoreClick = () => {
    analytics.track("Identity Resolution Learn More Clicked");
  };

  if (!showEvents) {
    return (
      <SplashPage
        icon={<EventsIcon />}
        eyebrow="Event Collection"
        heading="Collect and load events directly into your data warehouse"
        description="Hightouch Events is an easy-to-use event collection product. It’s fully customizable and purpose-built to centralize your data in the warehouse."
        actions={
          <>
            <UpsellButton onClick={unlockEventCollection}>
              Unlock Event Collection
            </UpsellButton>
            <LinkButton
              href="https://hightouch.com/platform/events"
              onClick={trackLearnMoreClick}
            >
              Learn more
            </LinkButton>
          </>
        }
        visual={
          <Box
            as="video"
            autoPlay
            muted
            playsInline
            src="https://cdn.sanity.io/files/pwmfmi47/production/721f7c8c41f06ef7df94444d3beae52c5d99814a.mp4"
            maxHeight="100%"
            width="100%"
          />
        }
        backgroundGradient={
          <>
            <Box
              as="img"
              position="absolute"
              top={0}
              left={0}
              src={topLeftGradient}
            />
            <Box
              as="img"
              position="absolute"
              bottom={0}
              right={0}
              src={bottomRightGradient}
            />
          </>
        }
      />
    );
  }

  if (!regionConfig.events.enabled) {
    return (
      <SplashPage
        icon={<EventsIcon />}
        hideLogos
        eyebrow="Event Collection"
        heading="Event Collection is not available in this region"
        description={
          <>
            <span>Hightouch Events is available in the following regions:</span>
            <ChakraUnorderedList mt={2} spacing={1}>
              {Object.entries(HightouchRegions)
                .filter(([_region, config]) => config.events.enabled)
                .map(([region, config]) => (
                  <ChakraListItem key={region}>
                    {config.friendlyName}
                  </ChakraListItem>
                ))}
            </ChakraUnorderedList>
          </>
        }
        actions={
          <>
            <LinkButton href="/workspaces">View workspaces</LinkButton>
          </>
        }
        visual={
          <Box
            as="video"
            autoPlay
            muted
            playsInline
            src="https://cdn.sanity.io/files/pwmfmi47/production/721f7c8c41f06ef7df94444d3beae52c5d99814a.mp4"
            maxHeight="100%"
            width="100%"
          />
        }
        backgroundGradient={
          <>
            <Box
              as="img"
              position="absolute"
              top={0}
              left={0}
              src={topLeftGradient}
            />
            <Box
              as="img"
              position="absolute"
              bottom={0}
              right={0}
              src={bottomRightGradient}
            />
          </>
        }
      />
    );
  }

  return (
    <>
      <Routes>
        <Route path="/sources/*" element={<EventSourcesRouter />} />
        <Route path="/destinations/*" element={<EventDestinationsRouter />} />
        <Route path="/syncs/*" element={<EventWarehouseSyncsRouter />} />
        <Route path="/contracts/*" element={<ContractsRouter />} />
        <Route path="/functions/*" element={<FunctionsRouter />} />
        <Route
          index
          element={
            <Navigate
              to={{ pathname: "syncs", search: location.search }}
              replace
            />
          }
        />
      </Routes>
    </>
  );
};
