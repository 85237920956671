import { FC } from "react";

import {
  Text,
  Row,
  Column,
  SectionHeading,
  Box,
  Tooltip,
  InformationIcon,
} from "@hightouchio/ui";

import { commaNumber } from "src/utils/numbers";
import { Card } from "src/components/card";

type Props = {
  delivered: number | undefined | null;
  retrying: number | undefined | null;
  failed: number | undefined | null;
};

export const Summary: FC<Readonly<Props>> = ({
  delivered,
  retrying,
  failed,
}) => {
  return (
    <Card>
      <SectionHeading mb={4}>
        Total events over the past 24 hours
      </SectionHeading>
      <Box
        display="grid"
        gridGap={16}
        gridTemplateColumns={[
          "minmax(0,1fr)",
          "minmax(0,1fr)",
          "repeat(2, minmax(0,1fr))",
          "repeat(2, minmax(0,1fr))",
          "repeat(3, minmax(0,1fr))",
        ]}
      >
        <Metric
          value={delivered}
          title="Delivered"
          color="success.base"
          tooltip="Events successfully delivered to the configured destination"
        />
        <Metric
          value={retrying}
          title="Retrying"
          color="warning.300"
          tooltip="Failed events are automatically retried for 8 hours"
        />
        <Metric
          value={failed}
          title="Failed"
          color="danger.base"
          tooltip="Failed events"
        />
      </Box>
    </Card>
  );
};

const Metric = ({ value, title, color, tooltip }) => {
  return (
    <Column gap={1}>
      <Row gap={1} align="center">
        <Box bg={color} w="14px" h="14px" borderRadius="sm" />
        <Text
          fontWeight="semibold"
          color="text.secondary"
          textTransform="uppercase"
        >
          {title}
        </Text>
        <Tooltip message={tooltip}>
          <InformationIcon />
        </Tooltip>
      </Row>
      <Box fontWeight="semibold" fontSize="4xl">
        {value ? commaNumber(value) : 0}
      </Box>
    </Column>
  );
};
