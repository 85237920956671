import { FC } from "react";

import { FormField, useToast, Text } from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import * as diff from "diff";
import { useNavigate } from "src/router";

import { Diff } from "src/components/diff";
import { DraftPage } from "src/components/drafts/draft-page";
import {
  DraftOperation,
  DraftsQuery,
  ModelQuery,
  ResourceToPermission,
  UpdateModelMutationVariables,
  useDbtSyncModelQuery,
  useGetLookerLookQuery,
  useSubmitDraftModelMutation,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { QueryType } from "src/types/models";
import { Strike } from "src/utils/strike";

import { DbtQuery } from "src/components/explore/preview/dbt-query";
import { LookerQuery } from "src/components/explore/preview/looker-query";
import { SigmaQuery } from "src/components/explore/preview/sigma-query";
import { TableQuery } from "src/components/explore/preview/table-query";
import { useFolder } from "src/components/folders/use-folder";
import { Card } from "src/components/card";

interface Props {
  model: ModelQuery["segments_by_pk"] | undefined;
  draft: DraftsQuery["drafts"][0];
}

export const isQueryDraft = (
  _set: Partial<UpdateModelMutationVariables["input"]>,
) => {
  return (
    _set.query_raw_sql ||
    _set.query_table_name ||
    _set.query_dbt_model_id ||
    _set.query_looker_look_id ||
    _set.custom_query ||
    _set.query_integrations
  );
};

export const ModelDraftPage: FC<Readonly<Props>> = ({ model, draft }) => {
  const { mutateAsync: submitModel, isLoading: withdrawing } =
    useSubmitDraftModelMutation();
  const navigate = useNavigate();
  const { toast } = useToast();

  const onWithdraw = async (deny: boolean) => {
    try {
      await submitModel({
        resourceId: model?.id.toString(),
        approverIds: [],
      });

      toast({
        id: `${deny ? "deny" : "withdraw"}-draft`,
        title: `Draft was ${deny ? "denied" : "withdrawn"}`,
        variant: "success",
      });

      navigate(`/models/${model?.id}?editing=true`);
    } catch (err) {
      toast({
        id: `${deny ? "deny" : "withdraw"}-draft`,
        title: `Couldn't ${deny ? "deny" : "withdraw"} this draft`,
        variant: "error",
      });

      Sentry.captureException(err);
    }
  };

  const newResource =
    draft.operation === DraftOperation.Create && model
      ? model
      : (draft.new_resource._set as Partial<
          UpdateModelMutationVariables["input"]
        >);

  const oldResource =
    draft.operation === DraftOperation.Create
      ? ({} as ModelQuery["segments_by_pk"])
      : model;

  const folderType =
    newResource.query_type === "visual" ? "audiences" : "models";
  const newFolder = useFolder({
    folderId: newResource.folder_id ?? null,
    folderType,
    viewType: "models",
  });

  const oldFolder = useFolder({
    folderId: oldResource?.folder_id ?? null,
    folderType,
    viewType: "models",
  });

  const oldFolderName =
    draft.operation === DraftOperation.Create
      ? undefined
      : oldFolder
        ? `All ${folderType} / ${oldFolder?.path.replace("/", " / ")}`
        : `All ${folderType}`;

  const { data: oldDbtSyncModelQuery } = useDbtSyncModelQuery(
    {
      id: String(oldResource?.query_dbt_model_id),
    },
    { enabled: Boolean(oldResource?.query_dbt_model_id) },
  );

  const { data: newDbtSyncModelQuery } = useDbtSyncModelQuery(
    {
      id: String(newResource.query_dbt_model_id),
    },
    { enabled: Boolean(newResource.query_dbt_model_id) },
  );

  const { data: oldLookerLookQuery } = useGetLookerLookQuery(
    {
      id: oldResource?.query_looker_look_id ?? "",
      withSql: true,
    },
    { enabled: Boolean(oldResource?.query_looker_look_id) },
  );

  const { data: newLookerLookQuery } = useGetLookerLookQuery(
    {
      id: newResource.query_looker_look_id ?? "",
      withSql: true,
    },
    { enabled: Boolean(newResource.query_looker_look_id) },
  );

  const oldSigmaQuery =
    model?.query_integrations?.query?.queryType === QueryType.Sigma
      ? model.query_integrations
      : undefined;
  const sigmaQuery =
    newResource?.query_integrations?.query?.queryType === QueryType.Sigma
      ? newResource?.query_integrations
      : undefined;

  return (
    <DraftPage
      draft={draft}
      link={`/models/${model?.id}`}
      resourceId={model?.id}
      resourceType={ResourceToPermission.Model}
      title={`Draft of ${model?.name} model`}
      withdrawing={withdrawing}
      onApprove={() => {
        analytics.track("Model Edited", {
          model_id: model?.id,
          through_draft: true,
        });
        navigate(`/models/${model?.id}`);
      }}
      onWithdraw={onWithdraw}
    >
      <FormField label="Query">
        {newResource.query_raw_sql && (
          <Diff
            diffs={diff.diffLines(
              draft.operation === DraftOperation.Create
                ? newResource.query_raw_sql
                : oldResource?.query_raw_sql || "",
              newResource.query_raw_sql,
            )}
          />
        )}

        {newResource.query_table_name && (
          <Card>
            <TableQuery
              oldTable={oldResource?.query_table_name || ""}
              table={newResource?.query_table_name}
            />
          </Card>
        )}

        {newResource.query_dbt_model_id &&
          newDbtSyncModelQuery?.dbt_sync_models_by_pk && (
            <DbtQuery
              model={newDbtSyncModelQuery?.dbt_sync_models_by_pk}
              oldModel={oldDbtSyncModelQuery?.dbt_sync_models_by_pk}
            />
          )}

        {newResource.query_looker_look_id &&
          newLookerLookQuery?.getLookerLook?.look && (
            <LookerQuery
              look={newLookerLookQuery?.getLookerLook?.look}
              oldLook={oldLookerLookQuery?.getLookerLook?.look}
            />
          )}

        {sigmaQuery && model?.connection && (
          <SigmaQuery
            oldSigma={oldSigmaQuery}
            sigma={sigmaQuery}
            source={model.connection}
          />
        )}

        {newResource.custom_query && (
          <Diff
            diffs={diff.diffJson(
              draft.operation === DraftOperation.Create
                ? newResource.custom_query
                : oldResource?.custom_query || {},
              newResource.custom_query,
            )}
          />
        )}

        {!isQueryDraft(newResource) && <Text>No changes</Text>}
      </FormField>

      <FormField label="Primary key">
        <Text>
          {newResource.primary_key ? (
            <Strike
              _new={newResource.primary_key}
              old={oldResource?.primary_key || ""}
            />
          ) : (
            "No changes"
          )}
        </Text>
      </FormField>

      <FormField label="Folder">
        <Text>
          {newFolder ? (
            <Strike
              _new={`All ${folderType} / ${newFolder.path.replace("/", " / ")}`}
              old={oldFolderName}
            />
          ) : (
            "No changes"
          )}
        </Text>
      </FormField>
      <FormField label="Model columns">
        {draft?.new_resource.modelColumns ? (
          <Diff
            diffs={diff.diffJson(
              draft.operation === DraftOperation.Create
                ? draft?.new_resource.modelColumns
                : oldResource?.columns || {},
              draft.new_resource.modelColumns,
            )}
          />
        ) : (
          <Text>No changes</Text>
        )}
      </FormField>
    </DraftPage>
  );
};
