import { useState } from "react";
import {
  Box,
  Text,
  SectionHeading,
  Column,
  Skeleton,
  Row,
  ToggleButton,
  ToggleButtonGroup,
  SkeletonBox,
} from "@hightouchio/ui";
import { Card } from "src/components/card";
import {
  BinIntervalTypeEnum,
  EventsMetricEnum,
  useEventMetricsQuery,
  useHistoricStatusChangesForEventSourceQuery,
} from "src/graphql";
import { subHours } from "date-fns";
import { roundToNearestHours } from "date-fns";
import { groupBy, sortBy, sum } from "lodash";
import { isEnum, isPresent } from "src/types/utils";
import { ChartsContextProvider } from "src/hooks/use-charts-context";
import { EventMetricChart, MetricTooltip } from "./event-metric-chart";
import { sourceIdLike } from "@hightouch/lib/resource-monitoring/composite-ids";
import { MonitorStatus } from "@hightouch/lib/resource-monitoring/types";
import { isMonitorStatus } from "src/pages/syncs/sync/components/utils";
import {
  ChartGranularity,
  ChartGranularityMetadata,
  ChartColors,
  roundTimestampToBin,
} from "src/components/charts/chart-utils";
import { useFlags } from "launchdarkly-react-client-sdk";

export const SourceOverviewCharts = ({
  source,
}: {
  source: { id: string };
}) => {
  const [granularity, setGranularity] = useState(ChartGranularity.OneDay);
  const granularityMetadata = ChartGranularityMetadata[granularity];
  const { appEventMonitoringEnabled } = useFlags();

  const endTime = roundToNearestHours(new Date(), {
    roundingMethod: "ceil",
  });

  const startTime = subHours(endTime, granularityMetadata.days * 24);

  const { data: volumeData, isLoading } = useEventMetricsQuery(
    {
      input: {
        id: source.id,
        resourceType: "SOURCE",
        requests: [
          {
            timeseriesRequests: {
              endTime: endTime.valueOf(),
              startTime: startTime.valueOf(),
              interval: {
                binInterval: ChartGranularityMetadata[granularity].binHours,
                binType: BinIntervalTypeEnum.Hours,
              },
              metrics: [EventsMetricEnum.Received],
            },
          },
        ],
      },
    },
    {
      select: (data) =>
        data.getEventMetrics.results
          .flatMap((result) =>
            result.timeseriesData?.flatMap((datum) =>
              sortBy(datum?.dataPoints, (point) => point.timestamp).map(
                (point) => ({
                  data: { volume: point.value },
                  timestamp: new Date(point.timestamp),
                }),
              ),
            ),
          )
          .filter(isPresent),
    },
  );

  const { data: alertData } = useHistoricStatusChangesForEventSourceQuery(
    {
      sourceIdLike: sourceIdLike(source.id),
      startPeriod: startTime.toISOString(),
      endPeriod: endTime.toISOString(),
    },
    {
      select: (data) =>
        Object.entries(
          groupBy(
            data.resource_monitor_statuses.filter(
              (status) =>
                isMonitorStatus(status.new_status) &&
                [MonitorStatus.Unhealthy, MonitorStatus.Warning].includes(
                  status.new_status,
                ),
            ),
            (status) =>
              roundTimestampToBin(
                new Date(status.transitioned_at),
                startTime,
                granularityMetadata.binHours,
              ),
          ),
        ).map(([timestamp, allAlerts]) => ({
          timestamp: new Date(timestamp),
          data: {
            alerts: sum(
              allAlerts.map((alert) => alert.condition_changes.length),
            ),
          },
        })),
    },
  );

  return (
    <Card w="100%">
      <Column gap={4}>
        <Row justifyContent="space-between">
          <SectionHeading>Overview</SectionHeading>
          <ToggleButtonGroup
            value={granularity}
            onChange={(value) => {
              if (isEnum(ChartGranularity)(value)) setGranularity(value);
            }}
          >
            {Object.values(ChartGranularity).map((g) => (
              <ToggleButton
                key={g}
                label={ChartGranularityMetadata[g].label}
                value={g}
              />
            ))}
          </ToggleButtonGroup>
        </Row>
        <Column gap={2}>
          <Text fontWeight="semibold">Total Volume</Text>
          <ChartsContextProvider>
            <Skeleton isLoading={isLoading}>
              <SkeletonBox borderRadius={6}>
                <Box w="100%" h="300px">
                  <EventMetricChart
                    colors={{ volume: ChartColors.green }}
                    data={volumeData}
                    padWith={{ volume: 0 }}
                    startTime={startTime}
                    tooltip={MetricTooltip(
                      {
                        volume: {
                          descriptor: {
                            singular: "event received",
                            plural: "events received",
                          },
                          color: ChartColors.green.hover,
                        },
                      } as const,
                      granularity,
                    )}
                    granularity={granularity}
                    emptyMessage="No events received"
                  />
                </Box>
              </SkeletonBox>
              {appEventMonitoringEnabled && (
                <>
                  <Text fontWeight="semibold">Alerts</Text>
                  <SkeletonBox borderRadius={6}>
                    <Box w="100%" h="300px">
                      <EventMetricChart
                        colors={{ alerts: ChartColors.red }}
                        data={alertData}
                        padWith={{ alerts: 0 }}
                        startTime={startTime}
                        tooltip={MetricTooltip(
                          {
                            alerts: {
                              descriptor: {
                                singular: "alert",
                                plural: "alerts",
                              },
                              color: ChartColors.red.hover,
                            },
                          } as const,
                          granularity,
                        )}
                        granularity={granularity}
                        emptyMessage="No alerts"
                      />
                    </Box>
                  </SkeletonBox>
                </>
              )}
            </Skeleton>
          </ChartsContextProvider>
        </Column>
      </Column>
    </Card>
  );
};
