import { FC, Fragment, useEffect, useState } from "react";

import {
  ArrowRightIcon,
  Box,
  CloseIcon,
  Column,
  Combobox,
  ComboboxProps,
  FormField,
  IconButton,
  Row,
  Text,
} from "@hightouchio/ui";
import { useFieldArray, useFormContext } from "react-hook-form";

import { FieldError } from "src/components/field-error";
import { ModelState } from "src/pages/identity-resolution/types";

export const MapperField: FC<
  Readonly<{
    columns: { name: string; alias: string | null }[];
    identifierOptions: string[];
  }>
> = ({ columns, identifierOptions }) => {
  const {
    watch,
    clearErrors,
    control,
    formState: { errors, submitCount },
  } = useFormContext<ModelState>();
  const isSubmitted = submitCount > 0;
  const modelId = watch("model.id");
  const { fields, remove, append, update } = useFieldArray({
    control,
    name: "mappings",
  });

  const [createdIdentifiers, setCreatedIdentifiers] = useState<string[]>([]);
  const identifiers = [...identifierOptions, ...createdIdentifiers];

  const noValidMappings =
    fields.filter((mapping) => mapping.identifier && mapping.column).length ===
    0;

  // Make sure mapper resets when the model is changed
  useEffect(() => {
    clearErrors();
  }, [modelId]);

  return (
    <Column gap={4} width="100%">
      <FormField
        label="Which identifiers does this model contain?"
        description="Assigning identifiers will allow you to create rules to resolve the identity for a given row. Create a new identifier by typing in the desired name."
      >
        <Box
          display="grid"
          gridTemplateColumns="1fr max-content 1fr max-content"
          gridGap={2}
          width="100%"
          alignItems="start"
          sx={{ "> :not(:first-child)": { mb: 2 } }}
        >
          <Box
            gridColumn="1 / -1"
            display="grid"
            gridTemplateColumns="1fr max-content 1fr max-content"
          >
            <Text
              color="text.secondary"
              textTransform="uppercase"
              fontWeight="semibold"
            >
              Column name
            </Text>
            <Box />
            <Text
              color="text.secondary"
              textTransform="uppercase"
              fontWeight="semibold"
            >
              Identifier
            </Text>
            <Box />
          </Box>
          {fields.map((field, index) => {
            const shouldAppend = index === fields.length - 1;
            const otherMappings = fields.filter((_, i) => i !== index);
            const availableColumns = columns.filter(
              (column) =>
                !otherMappings.find(
                  (mapping) => mapping.column === column.name,
                ),
            );
            const availableIdentifiers = identifiers.filter(
              (identifier) =>
                !otherMappings.find(
                  (mapping) => mapping.identifier === identifier,
                ),
            );

            return (
              <Fragment key={`${field.id}-${identifiers.length}`}>
                <Column>
                  <Combobox
                    value={field.column}
                    onChange={(value) => {
                      if (!value) return;

                      update(index, { ...field, column: value });

                      if (shouldAppend) {
                        append({ column: "", identifier: "" });
                      }
                    }}
                    width="100%"
                    isInvalid={Boolean(
                      isSubmitted &&
                        (errors.mappings?.[index]?.column ||
                          errors?.mappings?.message?.toString()),
                    )}
                    placeholder="Select a column..."
                    options={availableColumns}
                    emptyOptionsMessage="No columns"
                    optionLabel={(o) => o.alias ?? o.name}
                    optionValue={(o) => o.name}
                  />
                  <FieldError
                    error={errors.mappings?.[index]?.column?.message}
                  />
                </Column>
                <Row px={0} color="text.placeholder" fontSize="3xl" mt={1}>
                  <ArrowRightIcon />
                </Row>
                <Column>
                  <IdentifierInput
                    allIdentifierOptions={identifiers}
                    value={field.identifier}
                    onChange={(value) => {
                      if (!value) return;

                      update(index, { ...field, identifier: value });

                      if (shouldAppend) {
                        append({ column: "", identifier: "" });
                      }
                    }}
                    width="100%"
                    isInvalid={Boolean(
                      isSubmitted &&
                        (errors.mappings?.[index]?.identifier ||
                          errors?.mappings?.message?.toString()),
                    )}
                    onCreateOption={(value) => {
                      if (value === "Forbidden") {
                        return;
                      }

                      // Prevent user from creating duplicate identifiers
                      if (createdIdentifiers.includes(value)) {
                        return;
                      }

                      if (!identifierOptions.includes(value)) {
                        setCreatedIdentifiers((currentIdentifiers) => [
                          ...currentIdentifiers,
                          value,
                        ]);
                      }

                      update(index, { ...field, identifier: value });

                      if (shouldAppend) {
                        append({ column: "", identifier: "" });
                      }
                    }}
                    placeholder="Select or create an identifier..."
                    options={availableIdentifiers}
                    optionLabel={(o) => o}
                    optionValue={(o) => o}
                  />
                  <FieldError
                    error={
                      isSubmitted &&
                      errors.mappings?.[index]?.identifier?.message
                    }
                  />
                </Column>
                <Box
                  visibility={
                    fields.length === 1 || index === fields.length - 1
                      ? "hidden"
                      : "visible"
                  }
                >
                  <IconButton
                    icon={CloseIcon}
                    aria-label="Remove mapping"
                    onClick={() => {
                      if (createdIdentifiers.includes(field.identifier)) {
                        setCreatedIdentifiers((currentIdentifiers) =>
                          currentIdentifiers.filter(
                            (identifier) => identifier !== field.identifier,
                          ),
                        );
                      }

                      remove(index);
                    }}
                  />
                </Box>
              </Fragment>
            );
          })}
          <Row mt={-2}>
            <FieldError
              error={
                isSubmitted &&
                noValidMappings &&
                errors?.mappings?.message?.toString()
              }
            />
          </Row>
        </Box>
      </FormField>
    </Column>
  );
};

type IdentifierInputProps<Option, OptionValue extends Option[keyof Option]> = {
  allIdentifierOptions: string[];
} & ComboboxProps<Option, OptionValue>;

const IdentifierInput = <Option, OptionValue extends Option[keyof Option]>({
  allIdentifierOptions,
  ...props
}: IdentifierInputProps<Option, OptionValue>) => {
  // Track changes to input so that creating options may be disabled
  const [inputValue, setInputValue] = useState("");
  const isValueAlreadyCreated = allIdentifierOptions.includes(inputValue);

  return (
    <Combobox
      supportsCreatableOptions={!isValueAlreadyCreated}
      emptyOptionsMessage={
        isValueAlreadyCreated
          ? `Identifier '${inputValue}' already exists`
          : undefined
      }
      onSearchUpdate={setInputValue}
      {...props}
    />
  );
};
