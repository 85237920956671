import { FormkitComponent } from "@hightouch/formkit";
import {
  Row,
  InformationIcon,
  Switch,
  Text,
  Tooltip,
  Column,
  ChakraUnorderedList,
  ChakraListItem,
} from "@hightouchio/ui";
import { FC, PropsWithChildren } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { isPresent } from "ts-extras";

import { useFormkitContext } from "src/formkit/components/formkit-context";

import { useSyncTemplateLockingRules } from "./use-sync-template-locking-rules";
import { OVERRIDE_CONFIG_FORM_KEY } from "./constants";

type SyncTemplateLockProps = {
  node: FormkitComponent;
};

export const SyncTemplateLock: FC<PropsWithChildren<SyncTemplateLockProps>> = ({
  node,
}) => {
  const { allowOverrides } = useFormkitContext();
  const { control } = useFormContext();

  const { disabledMessage, onUnlockChildNodes } =
    useSyncTemplateLockingRules(node);

  if (!allowOverrides) {
    return null;
  }

  return (
    <Row
      bg="base.background"
      px={4}
      py={2}
      height="min-content"
      borderRadius="md"
      sx={{
        label: {
          display: "flex",
          gap: 4,
          alignItems: "center",
          whiteSpace: "nowrap",
        },
      }}
    >
      <Text as="label" fontWeight="medium">
        <Row gap={1} align="center">
          Lock this field
          <Tooltip message="Prevent collaborators from editing this field when using this sync template">
            <Row as={Text} color="text.secondary">
              <InformationIcon />
            </Row>
          </Tooltip>
        </Row>
        <Controller
          name={`${OVERRIDE_CONFIG_FORM_KEY}.${node.key}.overridable`}
          control={control}
          render={({ field }) => (
            <Tooltip
              isDisabled={!disabledMessage}
              message={
                typeof disabledMessage === "string" ? (
                  disabledMessage
                ) : isPresent(disabledMessage) ? (
                  <Column
                    gap={2}
                    sx={{ "> span": { textAlign: "left", pl: 2 } }}
                  >
                    <Text color="white">{disabledMessage.label}</Text>
                    {disabledMessage && typeof disabledMessage === "object" && (
                      <ChakraUnorderedList>
                        {disabledMessage?.children.map((child) => (
                          <ChakraListItem
                            key={child}
                            sx={{ textAlign: "left" }}
                          >
                            <Text color="white">&quot;{child}&quot;</Text>
                          </ChakraListItem>
                        ))}
                      </ChakraUnorderedList>
                    )}
                  </Column>
                ) : (
                  ""
                )
              }
            >
              <Switch
                size="sm"
                isDisabled={Boolean(disabledMessage)}
                isChecked={!field.value}
                onChange={(value) => {
                  field.onChange(!value);
                  // If the field is being unlocked, unlock all child nodes
                  if (!value) {
                    onUnlockChildNodes(node.key);
                  }
                }}
              />
            </Tooltip>
          )}
        />
      </Text>
    </Row>
  );
};
