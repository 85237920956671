import { FC } from "react";

import { Badge, Tooltip } from "@hightouchio/ui";

import {
  SourceDefinitionFragment as SourceDefinition,
  DestinationDefinitionFragment as DestinationDefinition,
} from "src/graphql";

export type IntegrationBadgeProps =
  | {
      definition: SourceDefinition;
      isSample?: boolean;
      integrationType: "source";
    }
  | {
      definition: DestinationDefinition;
      isSample?: boolean;
      integrationType: "destination";
    };

export const IntegrationBadge: FC<Readonly<IntegrationBadgeProps>> = ({
  definition,
  integrationType,
  isSample,
}) => {
  const lastMonth: Date = new Date();
  lastMonth.setMonth(lastMonth.getMonth() - 1);

  if (definition.status === "released") {
    if (isSample && integrationType === "source") {
      return (
        <Tooltip message="This source is a preconfigured Postgres database containing sample data.">
          <Badge variant="warning">Sample</Badge>
        </Tooltip>
      );
    }
  }

  // "New" badge is shown in the destination catalog only.
  if (
    integrationType === "destination" &&
    "released" &&
    typeof definition.releasedAt === "string" &&
    definition.releasedAt.length > 0
  ) {
    const releasedAt = new Date(definition.releasedAt);

    if (releasedAt > lastMonth) {
      return <Badge variant="success">New</Badge>;
    }
  }

  if (definition.status === "alpha") {
    return <Badge>Private preview</Badge>;
  }

  if (definition.status === "coming_soon") {
    return <Badge>Coming soon</Badge>;
  }

  return null;
};
