import { FC } from "react";

import * as Yup from "yup";
import { Controller } from "react-hook-form";
import { useNavigate } from "src/router";

import { RootCondition } from "@hightouch/lib/query/visual/types";
import {
  Button,
  CloseIcon,
  Column,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  EditableText,
  IconButton,
  Paragraph,
  Row,
  Tooltip,
} from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";

import { QueryBuilder } from "src/components/explore";
import { ErrorMessage } from "src/components/explore/visual/error-message";
import { Form, SaveButton, useHightouchForm } from "src/components/form";
import {
  FormErrorProvider,
  useFormErrorContext,
} from "src/contexts/form-error-context";
import {
  CreateAudienceState,
  ParentModel as FullParentModel,
} from "src/pages/audiences/types";

import { DEFAULT_FILTER } from "./constants";
import {
  AdHocAudience,
  ParentModel,
  SelectedAudience,
  isAdHocAudience,
} from "./types";

type ParentModelFilterProps = {
  parent: ParentModel;
  isOpen: boolean;
  onClose: () => void;
  selectedAudience: SelectedAudience;
  addFilter: (audience: AdHocAudience) => void;
};

const filteredAudienceSchema = Yup.object().shape({
  id: Yup.number().required(),
  name: Yup.string().required("Name is required"),
  filter: Yup.object()
    .shape({
      type: Yup.string().required(),
      conditions: Yup.array()
        .min(1, "Filter must have at least one condition")
        .required("Conditions are required"),
    })
    .required(),
  splits: Yup.array(),
});

const ParentModelFilter: FC<ParentModelFilterProps> = ({
  isOpen,
  onClose,
  parent,
  selectedAudience,
  addFilter,
}) => {
  const navigate = useNavigate();

  const { hasValidationErrors } = useFormErrorContext();

  const form = useHightouchForm({
    onSubmit: (audience: AdHocAudience) => {
      if (hasValidationErrors()) {
        throw new Error("Filter has validation errors");
      }

      addFilter(audience);
      onClose();

      return Promise.resolve();
    },
    values: isAdHocAudience(selectedAudience)
      ? selectedAudience
      : { ...selectedAudience, filter: DEFAULT_FILTER },
    success: "Audience filter applied",
    error: "Failed to apply audience filter",
    resolver: yupResolver(filteredAudienceSchema),
    onError: () => {
      // define onError so that sentry does not log validation errors
    },
  });

  const createAudience = () => {
    const audience = form.getValues();

    const audienceFilter =
      audience.filter == null
        ? []
        : Array.isArray(audience.filter)
          ? audience.filter
          : [audience.filter];

    const state: CreateAudienceState = {
      referrer: {
        pathname: "analytics",
        search: location.search,
      },
      // bigint -> Type says string but it's actually a number
      parentModelId: parent.id as unknown as string,
      conditions: audienceFilter as RootCondition[],
      name: audience.name,
    };

    navigate("/audiences/new", {
      state,
    });
  };

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="lg">
      <Form form={form}>
        <DrawerHeader>
          <Row align="center" justify="space-between" flex={1} minWidth={0}>
            <Controller
              name="name"
              control={form.control}
              render={({ field }) => (
                <Column>
                  <EditableText
                    size="lg"
                    fontWeight="medium"
                    onChange={field.onChange}
                    value={field.value ?? "Audience X"}
                  />
                </Column>
              )}
            />
            <Tooltip message="Close drawer">
              <IconButton
                aria-label="Close drawer."
                icon={CloseIcon}
                onClick={onClose}
              />
            </Tooltip>
          </Row>
        </DrawerHeader>

        <DrawerBody>
          <Column minHeight={0} flex={1} gap={6}>
            <Paragraph>
              Filter down customers using anything in your underlying data.
            </Paragraph>

            <Controller
              control={form.control}
              name="filter"
              render={({ field, formState }) => (
                <Column gap={2}>
                  <QueryBuilder
                    parent={parent as FullParentModel}
                    filter={field.value ?? DEFAULT_FILTER}
                    setConditions={field.onChange}
                  />
                  {formState.errors.filter?.conditions && (
                    <ErrorMessage fontWeight="normal">
                      {formState.errors.filter.conditions.message}
                    </ErrorMessage>
                  )}
                </Column>
              )}
            />
          </Column>
        </DrawerBody>

        <DrawerFooter>
          <SaveButton size="lg">Apply filter</SaveButton>
          <Tooltip message="Create new audience from filter">
            <Button
              size="lg"
              isDisabled={form.watch("filter.conditions").length === 0}
              onClick={createAudience}
            >
              Save as audience
            </Button>
          </Tooltip>
        </DrawerFooter>
      </Form>
    </Drawer>
  );
};

const ParentModelFilterWithErrorProvider: FC<ParentModelFilterProps> = (
  props: ParentModelFilterProps,
) => (
  <FormErrorProvider>
    <ParentModelFilter {...props} />
  </FormErrorProvider>
);

export { ParentModelFilterWithErrorProvider as ParentModelFilter };
