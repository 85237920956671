import { FC, useCallback } from "react";
import {
  Row,
  Button,
  ChevronLeftIcon,
  ChevronRightIcon,
  RowProps,
} from "@hightouchio/ui";

export const BasicPagination: FC<
  Readonly<
    {
      setPage: (page: number) => void;
      page: number;
      disableNextPage: boolean;
    } & RowProps
  >
> = ({ setPage, page, disableNextPage, ...props }) => {
  const next = useCallback(() => {
    setPage(page + 1);
  }, [setPage, page]);

  const prev = useCallback(() => {
    setPage(page - 1);
  }, [setPage, page]);

  if (disableNextPage && page === 0) {
    return null;
  }

  return (
    <Row alignSelf="flex-end" mt={4} {...props}>
      <Button
        isDisabled={page === 0}
        icon={ChevronLeftIcon}
        size="sm"
        variant="secondary"
        onClick={() => prev()}
      >
        Previous
      </Button>
      <Button
        isDisabled={disableNextPage}
        directionIcon={ChevronRightIcon}
        size="sm"
        variant="secondary"
        ml={2}
        onClick={() => next()}
      >
        Next
      </Button>
    </Row>
  );
};
