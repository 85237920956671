import type { FormkitReference } from "..";
import { type ExtendedOption, type Option, toExtendedOption } from "./option";

export type AssociationOptionsOrReference =
  | FormkitReference
  | AssociationOption;

/**
 * Each associated object will point to a list of options that users can
 * select to lookup by
 * Ex: 
 {
    prospect: [{"label": "prospect_id", "value": "prospect_id","type": "STRING"}],
    account: [{"label": "acc_id", "value": "acc_id","type": "STRING"}],
 }
 * Note: Can't pass a Map object to the frontend
 */
export type AssociationOption = Record<string, Array<Option>>;

/**
 * This is `AssociationOption` but its using `ExtendedOption` instead of `Option`
 */
export type ExtendedAssociationOption = Record<string, ExtendedOption[]>;

/**
 * Transforms `AssociationOption` to `ExtendedAssociationOption`
 * if associationOptions is not `AssociationOption` returns that value.
 * @param associationOptions
 * @returns
 */
export const toExtendedAssociationOption = <T>(
  associationOptions: T,
): T extends AssociationOption ? ExtendedAssociationOption : T => {
  if (associationOptions && typeof associationOptions === "object") {
    const transformed = {};

    for (const [key, value] of Object.entries(associationOptions)) {
      if (Array.isArray(value)) {
        transformed[key] = value.map(toExtendedOption);
      } else {
        transformed[key] = value;
      }
    }

    return transformed as any;
  }

  return associationOptions as any;
};
