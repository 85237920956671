import { SystemStyleObject } from "@hightouchio/ui";

export enum CellZIndex {
  GroupByHeader = 4,
  MetricHeader = 3,
  GroupByCell = 2,
  MetricValueCell = 1,
}

export enum GridArea {
  GroupBys = "groupBys",
  Metrics = "metrics",
}

const sharedContainerStyles = ({
  gridArea,
  numColumns,
  zIndex,
}: {
  gridArea: GridArea;
  numColumns: number;
  zIndex: CellZIndex;
}): SystemStyleObject => ({
  display: "grid",
  gridArea,
  gridTemplateColumns: `repeat(${numColumns}, 1fr)`,
  zIndex,
});

export const sharedHeaderContainerStyles = ({
  gridArea,
  numColumns,
  zIndex,
}: {
  gridArea: GridArea;
  numColumns: number;
  zIndex: CellZIndex;
}): SystemStyleObject => ({
  ...sharedContainerStyles({ gridArea, numColumns, zIndex }),
  position: "sticky",
  left: 0,
  top: 0,
});

export const sharedHeaderCellStyles: SystemStyleObject = {
  background: "base.lightBackground",
  border: "1px solid",
  borderBottom: "2px solid",
  borderLeft: "none",
  borderTop: "none",
  borderColor: "base.border",
  fontWeight: "medium",
};

export const sharedSubHeaderCellStyles: SystemStyleObject = {
  borderTop: "1px solid",
  borderColor: "base.border",
  paddingX: 4,
  paddingY: 2,
  align: "center",

  justifyContent: "right",

  textAlign: "right",
  maxHeight: "72px",
};

export const sharedRowContainerStyles = ({
  gridArea,
  numColumns,
  rowIndex,
  zIndex,
}: {
  gridArea: GridArea;
  numColumns: number;
  rowIndex: number;
  zIndex: CellZIndex;
}): SystemStyleObject => ({
  ...sharedContainerStyles({ gridArea, numColumns, zIndex }),
  gridRowStart: rowIndex + 2,
  gridRowEnd: rowIndex + 3,
});

export const sharedRowCellStyles: SystemStyleObject = {
  border: "1px solid",
  borderLeft: "none",
  borderTop: "none",
  borderColor: "base.border",
};

export const gridTemplateColumns = (columnCount: number): string =>
  `repeat(${columnCount}, minmax(150px, 1fr))`;
