import { AggregationOption } from "src/pages/metrics/constants";

export const audienceSplitName = (
  audienceName: string,
  splitName: string | undefined,
) => {
  if (splitName) {
    return `${audienceName} / ${splitName}`;
  }

  return audienceName;
};

export const isPercentAgggregation = (aggregation: AggregationOption) =>
  aggregation === AggregationOption.PercentOfAudience;
