import {
  ArrowRightIcon,
  Box,
  ClickIcon,
  Row,
  SourceIcon,
  Tooltip,
} from "@hightouchio/ui";
import { FC } from "react";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { LinkWithTooltip } from "src/components/link-with-tooltip";

type Props = {
  isWarehouseSync: boolean;
  sourceDefinition: {
    name: string;
    icon: string;
  };
  source: {
    id: string;
    name: string;
  };
  destination: {
    id: string;
    name: string;
  };
  destinationDefinition: {
    name: string;
    icon: string;
  };
};

export const SyncName: FC<Readonly<Props>> = ({
  isWarehouseSync,
  source,
  destination,
  sourceDefinition,
  destinationDefinition,
}) => {
  return (
    <Row
      flex={1}
      alignItems="center"
      gap={4}
      overflow="hidden"
      sx={{
        a: {
          color: "text.primary",
        },
        "a:hover": {
          color: "link.default",
        },
      }}
    >
      <Tooltip message={isWarehouseSync ? "Storage sync" : "Forwarding sync"}>
        <Row
          align="center"
          justify="center"
          bg="gray.100"
          w={8}
          h={8}
          borderRadius="sm"
          flexShrink={0}
        >
          <Box
            as={isWarehouseSync ? SourceIcon : ClickIcon}
            boxSize={5}
            color="text.secondary"
          />
        </Row>
      </Tooltip>
      <IntegrationIcon
        name={sourceDefinition.name}
        src={sourceDefinition.icon}
      />
      <LinkWithTooltip
        href={`/events/sources/${source.id}`}
        fontWeight="semibold"
        fontSize="lg"
        color="inherit"
      >
        {source.name}
      </LinkWithTooltip>
      <Box as={ArrowRightIcon} color="text.secondary" boxSize={6} />
      <IntegrationIcon
        name={destinationDefinition.name}
        src={destinationDefinition.icon}
      />
      <LinkWithTooltip
        href={`/events/destinations/${destination.id}`}
        fontWeight="semibold"
        fontSize="lg"
        color="inherit"
      >
        {destination.name}
      </LinkWithTooltip>
    </Row>
  );
};
