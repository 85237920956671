import { FC } from "react";

import { Box, BoxProps } from "@hightouchio/ui";

import { usePartner } from "./context";

export const PartnerLogo: FC<Readonly<{ size?: BoxProps["height"] }>> = ({
  size = "24px",
}) => {
  const { brand } = usePartner();

  if (brand) {
    return (
      <Box
        as="img"
        src={brand.logo}
        alt={brand.name}
        width="auto"
        height={size}
        objectFit="contain"
      />
    );
  }

  return null;
};
