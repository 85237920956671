import { createContext, FC, ReactNode, useContext } from "react";

import { Brand } from "@hightouch/core/server/routes/partner/types";
import { Config } from ".";

export type PartnerContextValue<C> = {
  isPartner: boolean;
  brand: Brand;
  cobranded: boolean;
  config: C;
};

export const PartnerContext = createContext<PartnerContextValue<any>>({
  isPartner: false,
  brand: {} as Brand,
  cobranded: true,
  config: {},
});

export function usePartner<C>() {
  return useContext(PartnerContext) as PartnerContextValue<C>;
}

const cobrandExclusions = ["iterable", "hooli"];

export const PartnerProvider: FC<
  Readonly<{ config: Config; children: ReactNode }>
> = ({ config, children }) => {
  return (
    <PartnerContext.Provider
      value={{
        isPartner: true,
        brand: config.brand,
        cobranded: cobrandExclusions.includes(config.brand.name.toLowerCase())
          ? false
          : true,
        config: config.config,
      }}
    >
      {children}
    </PartnerContext.Provider>
  );
};
