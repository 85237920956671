import { Dispatch, FC, SetStateAction, useEffect } from "react";

import { Spinner, useToast } from "@hightouchio/ui";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";

import { FormkitProvider } from "src/formkit/components/formkit-context";
import {
  SourceDefinition,
  useFormkitSourceDefinitionQuery,
  useFormkitSourceValidationQuery,
} from "src/graphql";

import { IPWhitelistMessage } from "src/components/sources/ip-whitelist-message";
import { SyncEngineProps } from "./form-method";
import { SelectedMethodForm } from "src/components/selected-method-form";

/**
 * Props shared between source and event destination forms.
 */
export type SharedFormProps = {
  definition: SourceDefinition;
  tunnelId: string | null | undefined;
  setTunnelId: (tunnelId: string | null | undefined) => void;
  config: Record<string, unknown> | undefined;
  setConfig: Dispatch<SetStateAction<Record<string, unknown> | undefined>>;
  credentialId: string | undefined;
  setCredentialId: (credential: string) => void;
  hideSecret?: boolean;
  onSubmit?: () => Promise<void>;
  /** Whether to disable authentication method radio selection. */
  disableAuthMethod?: boolean;
  /** Function called whenever OAuth 'connect' button is clicked. */
  onConnectClick?(definition: SourceDefinition): void;
  isSetup: boolean;
};

type SourceFormProps = {
  sourceId: string | undefined;
};

type FormProps = SharedFormProps & SourceFormProps & SyncEngineProps;

export const SourceForm: FC<Readonly<FormProps>> = ({
  definition,
  setConfig,
  config,
  sourceId,
  isSetup,
  tunnelId,
  setTunnelId,
  credentialId,
  setCredentialId,
  onSubmit,
  disableAuthMethod,
  onConnectClick,
  lightningEnabled,
  setLightningEnabled,
  plannerDatabase,
  setPlannerDatabase,
  hasSetupLightning,
  lightningSchemaMode,
  setLightningSchemaMode,
  schema,
  setSchema,
}) => {
  const client = useQueryClient();
  const { toast } = useToast();
  const { data, isLoading } = useFormkitSourceDefinitionQuery({
    type: definition?.type,
  });

  const setupMethods = data?.formkitSourceDefinition;
  const context = {
    sourceDefinition: definition,
    sourceId,
    isSetup,
    tunnel: tunnelId,
    credentialId,
  };

  const validate = async (config, context) => {
    const response = await client.fetchQuery({
      queryFn: useFormkitSourceValidationQuery.fetcher({
        type: context.sourceDefinition?.type,
        config,
        context,
      }),
      queryKey: useFormkitSourceValidationQuery.getKey({
        ...config,
        ...context,
      }),
    });

    return response.formkitSourceValidation;
  };

  const methods = useForm();

  const { methodKey, ...configWithoutMethodKey } = config ?? {};

  const setMethodKey = (methodKey: string) => {
    setConfig({ methodKey });
  };

  const setConfigWithoutMethodKey = (value: Record<string, unknown>) => {
    setConfig((prev) => ({ ...value, methodKey: prev?.methodKey }));
  };

  useEffect(() => {
    // XXX: Make sure that the config is not already set before we reset it.
    if (configWithoutMethodKey && Object.keys(configWithoutMethodKey).length) {
      methods.reset(configWithoutMethodKey, { keepDefaultValues: true });
    }
  }, [Boolean(config)]);

  useEffect(() => {
    // Always setup the setup method to the first one.
    const initialSetupMethod = setupMethods?.[0];

    if (initialSetupMethod && !config?.methodKey) {
      setConfig((prev) => ({ ...prev, methodKey: initialSetupMethod.key }));
    }
  }, [config, definition.type, setupMethods?.length]);

  useEffect(() => {
    // XXX: This useEffect watches fields and sets config state in the parent.
    // We use this pattern because (unlike the sync form) the parent component
    // needs to know the config to render specific UI elements (like the continue
    // button) and for repeat testing in the testing step.

    const fieldWatcher = methods.watch((value) => {
      setConfigWithoutMethodKey(value);
    });
    return () => fieldWatcher.unsubscribe();
  }, [methods.watch]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (setupMethods?.length === 0) {
      if (typeof onSubmit === "function") {
        return await onSubmit();
      }

      return;
    }

    const errors = await validate(config, context);
    if (typeof errors === "object" && Object.keys(errors).length) {
      methods.clearErrors();
      Object.entries(errors).forEach(([key, message]) => {
        methods.setError(key, { message: String(message) });
      });

      toast({
        id: "save-source-config",
        title: "There is an error in your source configuration",
        variant: "error",
      });
    } else {
      if (typeof onSubmit === "function") {
        await onSubmit();
      }
    }
  };

  if (!setupMethods || !Array.isArray(setupMethods) || isLoading)
    return <Spinner size="lg" m="auto" />;

  return (
    <FormkitProvider {...context} validate={validate}>
      <FormProvider {...methods}>
        <SelectedMethodForm
          config={config}
          credentialId={credentialId}
          definition={definition}
          disableAuthMethod={disableAuthMethod}
          hasSetupLightning={hasSetupLightning}
          isSetup={isSetup}
          lightningEnabled={lightningEnabled}
          methodKey={String(methodKey)}
          plannerDatabase={plannerDatabase}
          setCredentialId={setCredentialId}
          setLightningEnabled={setLightningEnabled}
          setMethodKey={setMethodKey}
          setPlannerDatabase={setPlannerDatabase}
          setTunnelId={setTunnelId}
          setupMethods={setupMethods}
          sourceId={sourceId}
          tunnelId={tunnelId}
          onConnectClick={onConnectClick}
          schema={schema}
          setSchema={setSchema}
          lightningSchemaMode={lightningSchemaMode}
          setLightningSchemaMode={setLightningSchemaMode}
        />
        {definition.supportsIpFiltering && <IPWhitelistMessage />}
        <form hidden id="source-form" onSubmit={handleSubmit} />
      </FormProvider>
    </FormkitProvider>
  );
};
