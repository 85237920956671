import { FC, useState } from "react";

import {
  Button,
  ButtonGroup,
  Column,
  Dialog,
  FormField,
  TextInput,
} from "@hightouchio/ui";
import { Controller, NestedValue, useForm } from "react-hook-form";

import { ConditionField } from "src/components/explore/visual/condition";
import {
  AdditionalColumn,
  EventCondition,
  EventTraitColumn,
  RelatedColumn,
  TraitType,
} from "src/types/visual";

import { TraitAggregationFields } from "./trait-aggregation-fields";
import { RequiredParentModelFieldsForQueryBuilder } from "./types";

export const AudienceEventTraitForm: FC<
  Readonly<{
    title: string;
    alias?: string;
    condition: EventCondition;
    parent: RequiredParentModelFieldsForQueryBuilder | undefined | null;
    type?: TraitType;
    config?: any;
    onClose: () => void;
    onSubmit: (value: AdditionalColumn) => Promise<void>;
  }>
> = (props) => {
  const { title, onSubmit, parent, onClose } = props;
  const relationships = parent?.relationships;
  const events = relationships?.filter(({ to_model: { event } }) =>
    Boolean(event),
  );

  const [submitting, setSubmitting] = useState(false);

  const { handleSubmit, control, watch } = useForm<{
    alias: string | undefined;
    condition: NestedValue<EventCondition>;
    type: TraitType | undefined;
    config: any;
  }>({
    defaultValues: {
      alias: props.alias,
      condition: props.condition as NestedValue<EventCondition>,
      type: props.type,
      config: props.config,
    },
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - no circular types until react-hook-form v8
  const type = watch("type");

  const condition = watch("condition");

  const propertyOptions = events
    ?.find(({ id }) => id === condition.relationshipId)
    ?.to_model?.filterable_audience_columns?.map(
      ({ column_reference, name, alias }) => ({
        value: column_reference,
        label: alias || name,
      }),
    );

  const submit = async ({ alias, type, config, condition }) => {
    setSubmitting(true);

    const eventTraitColumn: EventTraitColumn = {
      type: "event_trait",
      filteredEvent: condition,
      traitType: type,
      traitConfig: config,
    };

    const relatedColumn: RelatedColumn = {
      type: "related",
      path: [String(condition.relationshipId)],
      column: eventTraitColumn,
    };

    await onSubmit({ alias, column: relatedColumn });

    setSubmitting(false);

    onClose();
  };

  return (
    <Dialog
      isOpen
      title={title}
      variant="form"
      actions={
        <ButtonGroup>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            variant="primary"
            isLoading={submitting}
            onClick={handleSubmit(submit)}
          >
            {title ? "Save" : "Add"}
          </Button>
        </ButtonGroup>
      }
      onClose={onClose}
    >
      <Column gap={6}>
        <Controller
          control={control}
          name="alias"
          render={({ field, fieldState: { error } }) => (
            <FormField label="Name" error={error?.message}>
              <TextInput
                ref={field.ref}
                value={field.value ?? ""}
                onChange={field.onChange}
                onBlur={field.onBlur}
                isInvalid={Boolean(error)}
              />
            </FormField>
          )}
        />
        <FormField label="Event">
          <Column
            gap={2}
            sx={{
              p: 4,
              fontWeight: "bold",
              fontSize: 0,
              bg: "gray.100",
              borderRadius: "6px",
              "& > :not(:last-child)": { mb: 4 },
            }}
          >
            <Controller
              control={control}
              name="condition"
              render={({ field }) => (
                <ConditionField
                  isEventTrait
                  audience={undefined}
                  columns={[]}
                  condition={field.value}
                  events={events}
                  parent={parent}
                  relationships={[]}
                  traits={undefined}
                  onChange={(value) => {
                    field.onChange({ ...condition, ...value });
                  }}
                  onRemove={() => {}}
                />
              )}
            />
          </Column>
        </FormField>
        <TraitAggregationFields
          control={control}
          disabled={!condition.relationshipId}
          propertyOptions={propertyOptions}
          type={type as TraitType}
        />
      </Column>
    </Dialog>
  );
};
