import { FC, PropsWithChildren } from "react";
import { Box } from "@hightouchio/ui";

const ASPECT_RATIO = (9 / 16) * 100;

export const GraphWrapper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box position="relative" width="100%" pb={`${ASPECT_RATIO}%`}>
      <Box position="absolute" left={0} right={0} top={0} bottom={0}>
        {children}
      </Box>
    </Box>
  );
};
