import { FC } from "react";

import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  CodeSnippet,
  Heading,
  Text,
  Row,
  TextInput,
  ClipboardButton,
  FormField,
} from "@hightouchio/ui";
import { Link } from "src/router";
import { useQuery } from "react-query";

import { CodeParams, SetupProps } from "./types";

function getKotlinCode(params: CodeParams): string {
  return `
  import android.app.Application
  import com.hightouch.analytics.Analytics

  class MyApp : Application() {
    override fun onCreate() {
      val analytics = Analytics
          .Builder(
              this, // e.g. context
             "${params.writeKey}"
          )
          .defaultApiHost("${params.apiHost}/v1")
          .trackApplicationLifecycleEvents()
          .build()
  
      Analytics.setSingletonInstance(analytics)
  
      // Safely call Analytics.with(context) from anywhere within your app!
      Analytics.with(this).track("Application Started")
    }
  }
  `;
}

function getJavaCode(params: CodeParams): string {
  return `
  import android.app.Application;
  import com.hightouch.analytics.Analytics

  public class MyApp extends Application {
    @Override public void onCreate() {
  
      Analytics analytics = new Analytics.Builder(
        this, // e.g. context
        "${params.writeKey}"
      )
      .defaultApiHost("${params.apiHost}/v1")
      .trackApplicationLifecycleEvents()
      .build();
  
      Analytics.setSingletonInstance(analytics);
  
      // Safely call Analytics.with(context) from anywhere within your app!
      Analytics.with(this).track("Application Started");
    }
  }
  `;
}

const gradleSettings = `
allprojects {
  repositories {
    maven { url 'https://jitpack.io' }
  }
}
`;

function getGradleBuild(libraryIdentifier: string): string {
  return `
dependencies {
  implementation '${libraryIdentifier}'
}`;
}

/**
 * https://docs.jitpack.io/api/
 * 
  "com.github.ht-sdks" : {
    "events-sdk-android" : {
      "-a2cc0f614f-1" : "Error",
      "0.0.1" : "Error",
      "0.0.2" : "Error",
      "0.0.3" : "ok",
      "0.0.4" : "ok",
      "0.0.5" : "ok",
      "0.0.6" : "ok"
    }
  }
}
 */
const jitPackAPI =
  "https://jitpack.io/api/builds/com.github.ht-sdks.events-sdk-android/";

function parseLatestVersion(result: {
  "com.github.ht-sdks": {
    "events-sdk-android": {
      [key: string]: "ok" | "Error";
    };
  };
}): string {
  const builds = result?.["com.github.ht-sdks"]?.["events-sdk-android"] ?? {};
  const versionKeys = Object.keys(builds);

  // return the latest--normal--version with a successful build
  const version = versionKeys
    .filter((version) => {
      // Only numbers. Dont return release candidates.
      return /^\d+\.\d+\.\d+$/.test(version) && builds[version] === "ok";
    })
    .sort()
    .reverse()
    .find(() => true);
  return version ?? "*";
}

export const SetupAndroid: FC<SetupProps> = (props: SetupProps) => {
  const fetchVersion = async () =>
    await parseLatestVersion(await (await fetch(jitPackAPI)).json());

  const { data, status } = useQuery("androidJitVersion", fetchVersion);

  let semVersion;
  if (status != "success") {
    semVersion = "*";
  } else {
    semVersion = data;
  }

  const libraryIdentifier = `com.github.ht-sdks.events-sdk-android:analytics:${semVersion}`;
  return (
    <>
      <Heading>Installing the SDK</Heading>
      <FormField label="Jit Package">
        <Row align="center" gap={2}>
          <TextInput width="lg" isReadOnly value={libraryIdentifier} />
          <ClipboardButton text={libraryIdentifier} />
        </Row>
      </FormField>
      <Text>
        This SDK is available via{" "}
        <Link href="https://jitpack.io/#ht-sdks/events-sdk-android/">
          JitPack
        </Link>
        .
      </Text>
      <CodeSnippet label="settings.gradle" code={gradleSettings} />
      <CodeSnippet
        label="build.gradle"
        code={getGradleBuild(libraryIdentifier)}
      />
      <Heading>Using the SDK</Heading>
      <Tabs>
        <TabList>
          <Tab>Kotlin</Tab>
          <Tab>Java</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <CodeSnippet label="SampleApp.kt" code={getKotlinCode(props)} />
          </TabPanel>
          <TabPanel>
            <CodeSnippet label="SampleApp.java" code={getJavaCode(props)} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};
