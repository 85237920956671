import {
  Column,
  FormField,
  NumberInput,
  Radio,
  RadioGroup,
  Row,
} from "@hightouchio/ui";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

export const SendLimit = () => {
  const { watch, setValue } = useFormContext();
  const maxSendsPerUser = watch("flowMessageConfig.maxSendsPerUser");
  const minOtherMessagesInBetween = watch(
    "flowMessageConfig.minOtherMessagesInBetween",
  );
  const maxSendsPerUserWindowDays = watch(
    "flowMessageConfig.maxSendsPerUserWindowDays",
  );

  const [limitType, setLimitType] = useState<
    "none" | "static" | "window" | "min-other-messages"
  >("none");

  useEffect(() => {
    if (maxSendsPerUserWindowDays) {
      setLimitType("window");
    } else if (maxSendsPerUser) {
      setLimitType("static");
    } else if (minOtherMessagesInBetween) {
      setLimitType("min-other-messages");
    }
  }, []);

  return (
    <Column gap={4}>
      <FormField
        isOptional
        label="Send limit"
        description="The maximum number of times this message can be sent to the same user."
      >
        <RadioGroup
          value={limitType}
          onChange={(value) => {
            setValue("flowMessageConfig.maxSendsPerUser", null);
            setValue("flowMessageConfig.maxSendsPerUserWindowDays", null);
            setValue("flowMessageConfig.minOtherMessagesInBetween", null);
            setLimitType(value);
          }}
        >
          <Radio label="No limit" value="none" />
          <Radio label="Static" value="static" />
          <Radio label="Time window" value="window" />
          <Radio
            label="Minimum other messages in between"
            value="min-other-messages"
          />
        </RadioGroup>
      </FormField>
      {limitType === "static" ? (
        <StaticLimit />
      ) : limitType === "window" ? (
        <WindowLimit />
      ) : limitType === "min-other-messages" ? (
        <MinTimeLimit />
      ) : null}
    </Column>
  );
};

export const StaticLimit = () => {
  return (
    <Controller
      name="flowMessageConfig.maxSendsPerUser"
      render={({ field }) => (
        <FormField
          label="Number of total sends"
          description="The maximum number of times this message can be sent to the same user."
        >
          <NumberInput
            {...field}
            width="xs"
            placeholder="Enter a maximum number..."
          />
        </FormField>
      )}
    />
  );
};

export const MinTimeLimit = () => {
  return (
    <FormField
      label="Minimum number of other messages in between"
      description="The minimum number of other messages that must be sent before this message can be sent again to the same user."
    >
      <Row alignItems="center" gap={2}>
        <Controller
          name="flowMessageConfig.minOtherMessagesInBetween"
          render={({ field }) => (
            <NumberInput
              {...field}
              width="xs"
              placeholder="Number of messages..."
            />
          )}
        />
      </Row>
    </FormField>
  );
};

export const WindowLimit = () => {
  return (
    <FormField
      label="Number of sends within a period of time"
      description="The maximum number of times this message can be sent to the same user within a period of time (e.g. 3 times every 14 days)."
    >
      <Row alignItems="center" gap={2}>
        <Controller
          name="flowMessageConfig.maxSendsPerUser"
          render={({ field }) => (
            <Row width="120px">
              <NumberInput {...field} width="100%" placeholder="Number of..." />
            </Row>
          )}
        />
        messages every
        <Controller
          name="flowMessageConfig.maxSendsPerUserWindowDays"
          render={({ field }) => (
            <Row width="120px">
              <NumberInput {...field} width="100%" placeholder="Days..." />
            </Row>
          )}
        />
        days
      </Row>
    </FormField>
  );
};
