import { FC } from "react";

import { ArrowRightIcon, Row } from "@hightouchio/ui";

import { Card } from "src/components/card";
import { Form, SubmitButton, useHightouchForm } from "src/components/form";
import { VerticalWizard } from "src/components/wizard";

import { ModelConfigv1, ModelConfigv2 } from "./forms";
import { ModelDetails, ModelState } from "./types";
import { modelConfigValidationResolver } from "./utils";

type ModelConfigurationWizardProps = {
  activeStep: number;
  isIDRv2: boolean;
  model: ModelState;
  identifierOptions: string[];
  steps: ModelDetails[];
  source:
    | { id: string; definition: { name: string; icon: string } }
    | undefined;
  onSubmit: (modelConfig: ModelState, index: number) => void;
};

export const ModelConfigurationWizard: FC<ModelConfigurationWizardProps> = ({
  activeStep,
  isIDRv2,
  model,
  identifierOptions,
  source,
  steps,
  onSubmit,
}) => {
  const form = useHightouchForm({
    onSubmit: (data) => {
      onSubmit(data, activeStep);
      return Promise.resolve();
    },
    success: false,
    error: "There was an error in your model configuration",
    values: model,
    resolver: (data, context, options) =>
      modelConfigValidationResolver(data, isIDRv2, context, options),
  });

  return (
    <VerticalWizard
      step={activeStep}
      steps={steps.map((modelDetails) => ({
        label: modelDetails.name,
        integrationIcon: source
          ? {
              name: source?.definition.name,
              src: source?.definition.icon,
            }
          : undefined,
        render: () => (
          <Form form={form} key={model.model.id}>
            <Card gap={6} p={4}>
              {isIDRv2 ? (
                <ModelConfigv2
                  model={modelDetails}
                  sourceId={source?.id ?? ""}
                  identifierOptions={identifierOptions}
                />
              ) : (
                <ModelConfigv1
                  identifierOptions={identifierOptions}
                  model={modelDetails}
                />
              )}
              <Row>
                <SubmitButton
                  enablePristineSubmit
                  directionIcon={ArrowRightIcon}
                >
                  {activeStep < steps.length - 1
                    ? "Configure next model"
                    : "Continue"}
                </SubmitButton>
              </Row>
            </Card>
          </Form>
        ),
      }))}
    />
  );
};
