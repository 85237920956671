// Based on: https://github.com/aboveyunhai/chakra-dayzed-datepicker/
export const Month_Names_Full = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const Month_Names_Short = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const Weekday_Names_Short = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
