import { FC } from "react";

import { Box, Row, TextInput, Tooltip } from "@hightouchio/ui";

type FormkitInputProps = {
  defaultValue?: any;
  value: any;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;

  placeholder?: string;

  prefix?: string;

  min?: number | string;
  max?: number | string;

  readonly?: boolean;
  disabled?: boolean;
  invalid?: boolean;

  type?: "text" | "number" | "url";

  style?: "editor";
};

const maxLength = 50;
/**
 * @deprecated this is because we cant use ellipse on input text.
 */
const manualEllipse = (text: string) => {
  if (text.length < maxLength) return text;

  return text.slice(0, maxLength - 3) + "...";
};

const getWidth = (text: string) => {
  return `${7 * text.length}px`; // is there a better way to do this with css?
};

export const FormkitInput: FC<FormkitInputProps> = ({
  defaultValue,
  value,
  readonly,
  disabled,
  onChange,
  onBlur,
  placeholder,
  prefix,
  style,
  type = "text",
  invalid,
  min,
  max,
}) => {
  if (typeof prefix === "string") {
    return (
      <Row align="center">
        <Row
          sx={{
            input: {
              borderRightRadius: 0,
              borderRight: "none",
              pointerEvents: "none",
              color: "gray.700",
              width: getWidth(manualEllipse(prefix)),
            },
          }}
        >
          <Tooltip message={prefix}>
            <TextInput isDisabled value={manualEllipse(prefix)} />
          </Tooltip>
        </Row>

        <Row
          align="center"
          flex={1}
          position="relative"
          sx={{
            input: { borderLeftRadius: 0, width: "100%", pr: 8 },
            "& > div": { flex: 1 },
          }}
        >
          <TextInput
            isDisabled={disabled}
            isInvalid={invalid}
            isReadOnly={readonly}
            placeholder={placeholder}
            type={type}
            value={value ?? defaultValue}
            min={min}
            max={max}
            width={style === "editor" ? "100%" : undefined}
            onBlur={onBlur}
            onChange={onChange}
          />
        </Row>
      </Row>
    );
  }

  const formattedValue = type === "number" && value === 0 ? "" : value;
  return (
    <Box
      sx={
        style === "editor" ? { input: { fontFamily: "monospace" } } : undefined
      }
    >
      <TextInput
        isDisabled={disabled}
        isInvalid={invalid}
        isReadOnly={readonly}
        placeholder={placeholder}
        type={type}
        value={formattedValue ?? defaultValue}
        width={style === "editor" ? "100%" : undefined}
        onBlur={onBlur}
        onChange={onChange}
      />
    </Box>
  );
};
