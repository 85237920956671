import { FC } from "react";

import { Navigate, useOutletContext } from "src/router";
import { OutletContext } from ".";

import { WarehouseSyncRuns } from "../warehouse-sync/runs";

export const Runs: FC = () => {
  const context = useOutletContext<OutletContext>();

  if (context.type === "forwarding") {
    return <Navigate to={`/events/syncs/${context.sync.id}`} replace />;
  }

  return <WarehouseSyncRuns />;
};
