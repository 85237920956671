import { FC, useEffect, useMemo, useRef, useState } from "react";

import {
  Box,
  Button,
  Column,
  EmptyState,
  IconButton,
  RefreshIcon,
  Row,
  SearchInput,
  Skeleton,
  SkeletonBox,
  Text,
  Tooltip,
} from "@hightouchio/ui";
import { Link } from "src/router";
import { isEqual } from "lodash";
import { matchSorter } from "match-sorter";

import { useDestinationForm } from "src/contexts/destination-form-context";

import { useFormkitContext } from "../formkit-context";
import { FormProps, MappingType, JsonColumnProps } from "../types";
import {
  extractEligibleInlineMapperColumns,
  isValidJsonColumnMetadata,
} from "./utils";

const rowSx = {
  minHeight: "32px",
  alignItems: "center",
  fontSize: "10px",
  gap: 2,
  paddingTop: 1,
  px: 2,
};

interface OnChangeValueProp {
  from: string;
  type: "array";
}

type ArrayInputProps = {
  onClose: () => void;
  jsonColumnProperties: JsonColumnProps;
  onChange: (onClose: () => void, value: OnChangeValueProp) => void;
  onChangeJsonColumnProperties: React.Dispatch<
    React.SetStateAction<JsonColumnProps>
  >;
};

export const ArrayInput: FC<Readonly<FormProps & ArrayInputProps>> = ({
  onClose,
  jsonColumnProperties,
  onChange,
  onChangeJsonColumnProperties,
  onReloadEligibleInlineMapperColumns,
  value,
}) => {
  const [search, setSearch] = useState("");
  const selectedColumnRef = useRef<HTMLDivElement>(null);
  const { reloadRows, loadingRows, rows } = useDestinationForm();
  const { columns: allColumns } = useFormkitContext();

  const columns = useMemo(() => {
    const arrayColumns = Object.keys(
      jsonColumnProperties?.allColumnsProps || {},
    );
    if (search) {
      const filteredColumns = matchSorter(arrayColumns, search);
      return filteredColumns;
    }

    return arrayColumns;
  }, [jsonColumnProperties.allColumnsProps, search]);

  useEffect(() => {
    selectedColumnRef.current?.scrollIntoView();
  }, []);

  const handleReloadRows = () => {
    onReloadEligibleInlineMapperColumns(value.from);
  };

  /**
   * These two useEffects work together to handle two functionalities:
   * 1. On load, we want to check if the json column metadata is valid. We will reloadRows to validate the metadata if it is not.
   * 2. handleReloadRows, which calls onReloadEligibleInlineMapperColumns will load the metadata to state only if it is valid. Therefore, it needs to have 'rows' as a dependency
   *    because if rows is updated, it can mean that either 1. we loaded the new valid metadata after we found that the previous one is invalid. 2. The user clicked on the 'Reload button' to re-validate the metadata and we need to extract that new data and set it to state.
   * This approach is needed to avoid multiple re-renders. The ideal approach would've been to make `reloadRows` a promise that resolves with the row data and we preform the extraction afterwards. However, that was not how reloadRows worked at this time, so this approach was chosen for the sake of time.
   * If this should be revisited, reloadRows should be changed into a promise so it can handle this scenario without multiple useEffects.
   */

  useEffect(() => {
    const jsonColumns = extractEligibleInlineMapperColumns(allColumns);
    if (!isValidJsonColumnMetadata(jsonColumns)) {
      reloadRows();
    }
  }, []);

  useEffect(() => {
    handleReloadRows();
  }, [rows]);

  return (
    <Column flex={1} p={3}>
      <Box pb={2}>
        <Text>Select a column that contains an array of objects:</Text>
      </Box>
      <Row alignItems="center" justifyContent="space-between" pb={2}>
        <>
          <SearchInput
            autoFocus
            placeholder="Search..."
            value={search}
            width="100%"
            onChange={(event) => setSearch(event.target.value)}
          />
          <Box ml={2}>
            <Tooltip message="Refresh properties">
              <IconButton
                aria-label="Refresh array properties"
                icon={RefreshIcon}
                isLoading={loadingRows}
                size="md"
                variant="secondary"
                onClick={reloadRows}
              />
            </Tooltip>
          </Box>
        </>
      </Row>
      {loadingRows ? (
        <ShimmerLoadingState />
      ) : !columns?.length ? (
        <Row
          flex={1}
          height="100%"
          sx={{
            div: {
              justifyContent: "center",
              p: 0,
              width: "100%",
            },
          }}
          width="100%"
        >
          <EmptyState
            actions={
              <Button variant="primary" onClick={reloadRows}>
                Reload columns
              </Button>
            }
            message={
              <>
                Reference our{" "}
                <Link href="https://hightouch.com/docs/syncs/mapping-data#required-data-format">
                  documentation
                </Link>{" "}
                to find out about the required data format to use the array
                in-line mapper.
              </>
            }
            title="No eligible columns found"
          />
        </Row>
      ) : (
        <Box height="192px" overflowY="auto">
          {(columns || []).map((column) => {
            const selected = isEqual(column, value.from);
            return (
              <Row
                key={column}
                ref={selected ? selectedColumnRef : undefined}
                _hover={{
                  bg: "base.background",
                }}
                alignItems="center"
                bg={selected ? "forest.100" : undefined}
                borderBottom="1px"
                borderColor="base.border"
                cursor="pointer"
                flex="0"
                gap={2}
                minHeight={8}
                pointerEvents={selected ? "none" : undefined}
                px={2}
                onClick={() => {
                  onChangeJsonColumnProperties((currentValue) => ({
                    ...currentValue,
                    selectedColumnProps: currentValue.allColumnsProps?.[column],
                  }));
                  onChange(onClose, { type: MappingType.ARRAY, from: column });
                }}
              >
                <Box
                  maxWidth="fit-content"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                >
                  <Text color={selected ? "forest.600" : undefined}>
                    {column}
                  </Text>
                </Box>
                <Box
                  maxWidth="fit-content"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                >
                  <Text color="text.placeholder">
                    {Array.from(
                      jsonColumnProperties.allColumnsProps?.[column] || [],
                    ).join(", ")}
                  </Text>
                </Box>
              </Row>
            );
          })}
        </Box>
      )}
    </Column>
  );
};

const containers = [
  ["25%", "75%"],
  ["20%", "80%"],
  ["35%", "85%"],
  ["35%", "65%"],
  ["25%", "95%"],
  ["20%", "35%"],
  ["15%", "75%"],
];

const ShimmerLoadingState = () => (
  <Skeleton isLoading={true}>
    {containers.map((container, index) => (
      <Row key={index} sx={rowSx}>
        <Box m={0} width={container[0]}>
          <SkeletonBox borderRadius="sm" height="18px" width="100%" />
        </Box>
        <Box m={0} width="100%">
          <SkeletonBox borderRadius="sm" height="18px" width={container[1]} />
        </Box>
      </Row>
    ))}
  </Skeleton>
);
