import { FC, Fragment } from "react";

import {
  Box,
  BoxProps,
  ChakraPopover,
  ChakraPopoverBody,
  ChakraPopoverContent,
  ChakraPopoverTrigger,
  Portal,
} from "@hightouchio/ui";
import { GraphTooltip } from "../analytics/graph-tooltip/index";
import { AnalyticsColors } from "src/pages/analytics/constants";
import { accurateCommaNumber } from "src/utils/numbers";

const defaultSize = 75;
const maxDiameter = 150;
const minDiameter = 15;
const minOverlapPercentageGreaterThan0 = 0.075;
const defaultPercentageOverlap = 0.15;

const baseStyles: BoxProps["sx"] = {
  borderRadius: "50%",
  flexShrink: 0,
};

type Props = {
  baseCircleSize?: number;
  disableHover?: boolean;
  currentAudienceSize?: number;
  comparedAudienceSize?: number;
  comparedAudienceName?: string;
  maxCircleDiameter?: number;
  minCircleDiameter?: number;
  sharedMembers?: number;
  sx?: BoxProps["sx"];
};

export const VennDiagram: FC<Readonly<Props>> = ({
  baseCircleSize = defaultSize,
  disableHover,
  currentAudienceSize: undefinedOrCurrentAudienceSize,
  comparedAudienceSize: undefinedOrComparedAudienceSize,
  comparedAudienceName,
  maxCircleDiameter = maxDiameter,
  minCircleDiameter = minDiameter,
  sharedMembers: undefinedOrSharedMembers,
  sx,
}) => {
  const currentAudienceSize = undefinedOrCurrentAudienceSize || baseCircleSize;
  const comparedAudienceSize =
    undefinedOrComparedAudienceSize || baseCircleSize;

  // if no shared members - show 15% of the current audience size
  const sharedMembers =
    undefinedOrSharedMembers ?? defaultPercentageOverlap * currentAudienceSize;

  // Right circle size stays static. Calculate left based off of the
  // ratio of log10 of each size
  const leftCircleSize = Math.max(
    minCircleDiameter,
    Math.min(
      maxCircleDiameter,
      baseCircleSize *
        (Math.log10(currentAudienceSize) / Math.log10(comparedAudienceSize)),
    ),
  );

  const smallestAudience = Math.min(currentAudienceSize, comparedAudienceSize);
  let percentageOfSmallestAudienceToOverlap = sharedMembers / smallestAudience;

  // If overlap is too small it can be hard to interact with the overlap
  if (
    percentageOfSmallestAudienceToOverlap > 0 &&
    percentageOfSmallestAudienceToOverlap < minOverlapPercentageGreaterThan0
  ) {
    percentageOfSmallestAudienceToOverlap = minOverlapPercentageGreaterThan0;
  }

  const displacement =
    Math.min(baseCircleSize, leftCircleSize) *
    percentageOfSmallestAudienceToOverlap;

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        flexShrink: 0,
        maxHeight: `${maxCircleDiameter}px`,
        ...sx,
      }}
    >
      <ChakraPopover trigger="hover">
        <ChakraPopoverTrigger>
          <Box
            id="left-circle"
            background={AnalyticsColors.Primary.primary}
            _hover={{
              background: disableHover ? "" : AnalyticsColors.Primary.hover,
            }}
            boxShadow="md"
            sx={{
              ...baseStyles,
              zIndex: 1,
              height: `${leftCircleSize}px`,
              width: `${leftCircleSize}px`,
            }}
          />
        </ChakraPopoverTrigger>
        <Tooltip
          text="Current audience"
          audienceSize={undefinedOrCurrentAudienceSize || 0}
          color={AnalyticsColors.Primary.hover}
          isDisabled={disableHover}
        />
      </ChakraPopover>

      <Box
        id="overlap"
        sx={{
          zIndex: 2,
          position: "absolute",
          overflow: "hidden",
          pointerEvents: "none",
        }}
      >
        <Box
          id="left-overlap"
          sx={{
            display: "flex",
            alignItems: "center",
            height: `${leftCircleSize}px`,
            width: `${leftCircleSize}px`,
            overflow: "hidden",
            borderRadius: "50%",
            pointerEvents: "none",
          }}
        >
          <ChakraPopover trigger="hover">
            <ChakraPopoverTrigger>
              <Box>
                <Box
                  id="right-overlap"
                  bg="ocean.500"
                  _hover={{
                    background: disableHover ? "" : "ocean.600",
                  }}
                  sx={{
                    zIndex: 1,
                    flexShrink: 0,
                    height: `${baseCircleSize}px`,
                    width: `${baseCircleSize}px`,
                    ml: `${leftCircleSize - displacement}px`,
                    borderRadius: "50%",
                    overflow: "hidden",
                    pointerEvents: "auto",
                  }}
                />
              </Box>
            </ChakraPopoverTrigger>

            <Tooltip
              text="Member overlap"
              audienceSize={sharedMembers ?? 0}
              color="ocean.600"
              usePortal
              isDisabled={disableHover}
            />
          </ChakraPopover>
        </Box>
      </Box>

      <ChakraPopover trigger="hover">
        <ChakraPopoverTrigger>
          <Box
            id="right-circle"
            background={AnalyticsColors.Secondary.primary}
            _hover={{
              background: disableHover ? "" : AnalyticsColors.Secondary.hover,
            }}
            sx={{
              ...baseStyles,
              zIndex: 1,
              height: `${baseCircleSize}px`,
              width: `${baseCircleSize}px`,
              ml: `${-displacement}px`,
            }}
          />
        </ChakraPopoverTrigger>
        <Tooltip
          text={comparedAudienceName}
          audienceSize={undefinedOrComparedAudienceSize || 0}
          color={AnalyticsColors.Secondary.hover}
          isDisabled={disableHover}
        />
      </ChakraPopover>
    </Box>
  );
};

const Tooltip = ({
  audienceSize,
  color,
  text,
  usePortal,
  isDisabled = false,
}: {
  audienceSize: number;
  color: string;
  text?: string;
  usePortal?: boolean;
  isDisabled?: boolean;
}) => {
  const Container = usePortal ? Portal : Fragment;

  if (isDisabled) {
    return null;
  }

  return (
    <Container>
      <ChakraPopoverContent width="fit-content">
        <ChakraPopoverBody
          boxShadow="sm"
          bg="text.primary"
          p={3}
          borderRadius="md"
        >
          <GraphTooltip
            color={color}
            title={text}
            value={[`${accurateCommaNumber(audienceSize)} users`]}
          />
        </ChakraPopoverBody>
      </ChakraPopoverContent>
    </Container>
  );
};
