import { useCallback } from "react";

import { ResourceToPermission, useDraftsQuery } from "src/graphql";

interface DraftMergerProps {
  resourceId: string;
  resourceType: ResourceToPermission;
}

export const useDraftMerger = ({
  resourceId,
  resourceType,
}: DraftMergerProps) => {
  const { data, isFetching } = useDraftsQuery(
    {
      resourceType,
      resourceId: resourceId.toString(),
      status: "pending",
    },
    { enabled: Boolean(resourceId) },
  );

  const draft = data?.drafts?.[0];

  const mergeResourceWithDraft = useCallback(
    (resource: Readonly<Record<string, unknown>>) => {
      if (!draft) {
        return resource;
      }

      const newResource = draft?.new_resource;
      const toAssign = newResource?._set || {};
      const copy = { ...resource };
      Object.assign(copy, toAssign);

      return copy;
    },
    [draft],
  );

  return { draft: isFetching ? undefined : draft, mergeResourceWithDraft };
};
