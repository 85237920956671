import * as Yup from "yup";

import {
  IntervalSchedule,
  ScheduleIntervalUnit,
  ScheduleType,
} from "src/components/schedule/types";

export type SamplingConfig = SampledModel & {
  sampledRelatedModels: SampledModel[];
};

export type SampledModel = {
  // Only persisted sampled models will have an ID
  id?: string;
  modelId: string;
  enabled: boolean;
  // In the range of [0.00001, 1] (inclusive). 1 means 100%.
  sampleRate: number;
  schedule: IntervalSchedule;
};

// Exported for tests
export const intervalScheduleSchema: Yup.Schema<IntervalSchedule> = Yup.object({
  type: Yup.string().equals([ScheduleType.INTERVAL]).required(),
  schedule: Yup.object({
    type: Yup.string().equals([ScheduleType.INTERVAL]),
    schedule: Yup.object({
      interval: Yup.object({
        quantity: Yup.number().min(1).label("Quantity"),
        unit: Yup.string()
          .oneOf(Object.values(ScheduleIntervalUnit))
          .label("Unit of time"),
      }),
    }),
  }),
}).required() as Yup.Schema<IntervalSchedule>;

// Exported for tests
export const sampledModelSchema: Yup.Schema<SampledModel> = Yup.object({
  enabled: Yup.boolean().required(),
  modelId: Yup.string().required(),
  sampleRate: Yup.number()
    .min(0.0001, "Must be at least .01%")
    .max(1, "Must be at most 100%")
    .label("Sample size")
    .required(),
  schedule: intervalScheduleSchema,
}).required();

export const samplingSchema: Yup.Schema<SamplingConfig> = Yup.object({
  enabled: Yup.boolean().required(),
  modelId: Yup.string().required(),
  sampleRate: Yup.number()
    .min(0.0001, "Must be at least .01%")
    .max(1, "Must be at most 100%")
    .label("Sample size")
    .required(),
  schedule: intervalScheduleSchema,
  sampledRelatedModels: Yup.array().of(sampledModelSchema).defined(), // Use defined() instead of required() to allow empty array
}).required();

export const DEFAULT_SAMPLE_RATE = 0.1;
export const DEFAULT_SCHEDULE: IntervalSchedule = {
  type: ScheduleType.INTERVAL,
  schedule: {
    interval: {
      quantity: 1,
      unit: ScheduleIntervalUnit.DAY,
    },
  },
};
