import { FC } from "react";

import { FormField, TagInput } from "@hightouchio/ui";
import { Controller } from "react-hook-form";

export const CampaignAttribution: FC = () => {
  return (
    <Controller
      name="flowMessageConfig.attributionCampaignIds"
      render={({ field }) => {
        const value = (field.value ?? []) as Array<string>;
        return (
          <FormField
            isOptional
            label="Campaign attribution"
            description="Additional campaign IDs that are sending this message, for outcome attribution purposes."
          >
            <TagInput
              {...field}
              value={value}
              supportsCreatableOptions
              options={value}
              optionLabel={(option) => option}
              optionValue={(option) => option}
              placeholder="Additional campaign IDs..."
              onCreateOption={(option) => {
                field.onChange([...value, option]);
              }}
            />
          </FormField>
        );
      }}
    />
  );
};
