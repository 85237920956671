import { FC, useState } from "react";

import {
  Button,
  Box,
  Column,
  TextInput,
  Heading,
  Text,
  Paragraph,
} from "@hightouchio/ui";
import { Link } from "src/router";
import * as Sentry from "@sentry/react";
import { useNavigate } from "src/router";

import { AuthLayout } from "src/components/auth/auth-layout";
import { Terms } from "src/components/auth/terms";
import { useAuthState } from "src/components/auth/use-auth-state";
import { PartnerConnectHeader } from "src/pages/partner-connect";
import { Card } from "src/components/card";

export const Passwordless: FC = () => {
  const { returnTo, partnerInformation, isSignup } = useAuthState();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const submit = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${import.meta.env.VITE_API_BASE_URL}/auth/email`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
            returnTo,
          }),
        },
      );
      if (response.ok) {
        setSuccess(true);
      } else {
        setError(true);
      }
    } catch (e) {
      Sentry.captureException(e);
    } finally {
      setLoading(false);
    }
  };

  const route = isSignup ? "/signup" : "/login";
  const action = isSignup ? "sign up" : "log in";

  return (
    <AuthLayout heading={`Receive a link to ${action}`}>
      <Column>
        <Card>
          {partnerInformation && (
            <PartnerConnectHeader partnerInformation={partnerInformation} />
          )}
          <Column
            as="form"
            gap={3}
            mb={8}
            sx={{
              input: { width: "100%", color: "inherit" },
              color: "gray.900",
              textAlign: "center",
            }}
            onSubmit={(event) => {
              event.preventDefault();
              submit();
            }}
          >
            {success && (
              <>
                <Heading mb={3} size="xl">
                  Email sent
                </Heading>
                <Text>
                  Click the link sent to <strong>{email}</strong> to continue.
                </Text>
              </>
            )}

            {error && (
              <>
                <Heading mb={3} size="xl">
                  Enterprise email
                </Heading>
                <Box>
                  <Paragraph>
                    This email is tied to an enterprise organization.
                  </Paragraph>
                  <Paragraph>
                    Go to <Link href="/sso">enterprise log in</Link> to
                    continue.
                  </Paragraph>
                </Box>
              </>
            )}

            {!success && !error && (
              <>
                <TextInput
                  autoFocus
                  name="email"
                  placeholder="name@company.com"
                  type="email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />

                <Button
                  isLoading={loading}
                  size="lg"
                  type="submit"
                  variant="primary"
                >
                  Send link
                </Button>
              </>
            )}
          </Column>

          <Terms variant="light" />
        </Card>
        <Box
          as="button"
          mt={8}
          sx={{
            alignSelf: "flex-start",
            color: "white",
            bg: "none",
            fontWeight: "medium",
            ":hover": { opacity: 0.7, color: "white", bg: "none" },
          }}
          onClick={() => {
            navigate(`${route}${returnTo ? `?returnTo=${returnTo}` : ""}`);
          }}
        >{`<- Other options`}</Box>
      </Column>
    </AuthLayout>
  );
};
