import { useMemo } from "react";

import partition from "lodash/partition";

import {
  PredefinedMetric,
  PredefinedMetricConfig,
} from "@hightouch/lib/query/visual/types";
import { useCampaignAnalyticsPageQuery } from "src/graphql";
import {
  AllowedAggregationTypePairs,
  InteractionTypeToAssetType,
} from "src/pages/campaigns/constants";
import { AttributionMethod } from "src/pages/campaigns/types";
import { useParams } from "src/router";
import {
  AssetType,
  AudienceAggregationType,
  PerUserAggregationType,
} from "src/types/visual";

import {
  convertMetricsFromBackendToCorrectType,
  sortPredefinedMetricsByColumnName,
} from "./utils";

type UseCampaignPage = {
  parentModelId?: string;
  interactionModelIds?: string[];
};

function hasValidInteraction(
  attributionMethod: AttributionMethod,
  assetType: AssetType,
) {
  const interactions =
    attributionMethod.attribution_methods_interactions.flatMap(
      ({ interactions }) => interactions,
    );

  return (
    interactions.find(({ type }) => {
      return InteractionTypeToAssetType[type] === assetType;
    }) !== undefined
  );
}

export function useAssetType(): AssetType {
  const { assetType } = useParams<{ assetType: AssetType }>();

  return assetType ?? AssetType.Email;
}

export const useCampaignPage = ({
  parentModelId,
  interactionModelIds = [],
}: UseCampaignPage) => {
  const assetType = useAssetType();

  const campaignAnalyticsPageQuery = useCampaignAnalyticsPageQuery(
    {
      parentModelId: parentModelId ?? "",
      interactionModelIds,
    },
    {
      enabled: Boolean(parentModelId),
    },
  );

  const assetModel = useMemo(() => {
    return campaignAnalyticsPageQuery.data?.asset_models.find(
      ({ type }) => type === assetType,
    );
  }, [assetType, campaignAnalyticsPageQuery.data?.asset_models]);

  const [predefinedMetrics, conversionMetrics] = useMemo(() => {
    const [nonUserDefinedMetrics, userDefinedMetrics] = partition(
      campaignAnalyticsPageQuery.data?.goals,
      (goal) => Boolean(goal.config["predefinedMetric"]),
    );

    // We don't want to show any predefined metrics that aren't related to the asset type.
    // e.g., if we're looking at Email campaigns, we don't care about "Ad Click" metrics.
    const relevantNonUserDefinedMetrics = nonUserDefinedMetrics.filter(
      (metric: { config: PredefinedMetricConfig }) =>
        assetTypeByPredefinedMetric[metric.config.predefinedMetric] ===
        assetType,
    );

    const metricsWithValidAttribution = userDefinedMetrics.map((goal) => ({
      ...goal,
      goals_attribution_methods: goal.goals_attribution_methods.filter(
        ({ attribution_method }) =>
          hasValidInteraction(attribution_method, assetType),
      ),
    }));

    // Only conversion metrics with at least one attribution method are shown
    const allowedConversionMetrics = metricsWithValidAttribution
      .filter(
        (goal) =>
          goal.goals_attribution_methods.length > 0 &&
          AllowedAggregationTypePairs.get(
            goal.aggregation as PerUserAggregationType,
          )?.includes(goal.audience_aggregation as AudienceAggregationType),
      )
      .flatMap(({ goals_attribution_methods, ...goal }) =>
        goals_attribution_methods.map(({ attribution_method }) => ({
          ...goal,
          attributionMethod: attribution_method,
        })),
      );

    return [relevantNonUserDefinedMetrics, allowedConversionMetrics];
  }, [campaignAnalyticsPageQuery.data?.goals, assetType]);

  const formattedPredefinedMetrics =
    convertMetricsFromBackendToCorrectType(predefinedMetrics);

  return {
    isLoading:
      campaignAnalyticsPageQuery.isLoading || campaignAnalyticsPageQuery.isIdle,
    assetModel: assetModel ?? null,
    predefinedMetrics: sortPredefinedMetricsByColumnName(
      assetType,
      formattedPredefinedMetrics,
    ),
    conversionMetrics:
      convertMetricsFromBackendToCorrectType(conversionMetrics),
  };
};

export const assetTypeByPredefinedMetric: {
  // Audience size metric is only used in Charts, not in Campaigns. Exclude it from here.
  [key in Exclude<PredefinedMetric, "audience-size">]: AssetType;
} = {
  // Ad
  [PredefinedMetric.AdClicks]: AssetType.Ad,
  [PredefinedMetric.AdImpressions]: AssetType.Ad,
  [PredefinedMetric.AdSpend]: AssetType.Ad,

  // Email
  [PredefinedMetric.EmailsDelivered]: AssetType.Email,
  [PredefinedMetric.EmailsClicked]: AssetType.Email,
  [PredefinedMetric.EmailsOpened]: AssetType.Email,
  [PredefinedMetric.UniqueEmailsClicked]: AssetType.Email,
  [PredefinedMetric.UniqueEmailsOpened]: AssetType.Email,
  [PredefinedMetric.EmailClickRate]: AssetType.Email,
  [PredefinedMetric.EmailOpenRate]: AssetType.Email,
  [PredefinedMetric.EmailClickthroughRate]: AssetType.Email,

  [PredefinedMetric.SmsSent]: AssetType.Sms,
  [PredefinedMetric.SmsDelivered]: AssetType.Sms,
  [PredefinedMetric.SmsClicked]: AssetType.Sms,
  [PredefinedMetric.SmsUniqueClicked]: AssetType.Sms,
  [PredefinedMetric.SmsClickRate]: AssetType.Sms,
  [PredefinedMetric.SmsReplied]: AssetType.Sms,
  [PredefinedMetric.SmsOptedOut]: AssetType.Sms,
};
