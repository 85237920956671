import {
  Box,
  ChakraPopover,
  ChakraPopoverBody,
  ChakraPopoverContent,
  ChakraPopoverTrigger,
  CheckIcon,
  CloseIcon,
  Column,
  Portal,
  Row,
  Text,
} from "@hightouchio/ui";
import { slice } from "lodash";
import { FC, Fragment, useState } from "react";
import {
  RESOURCE_GRANTS,
  RESOURCE_GRANT_OPTIONS,
} from "src/components/grant-select/types";
import { getBuiltInGrantValue } from "src/components/grant-select/util";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import {
  UserGroupGrantsByTypeQuery,
  useOrganizationDestinationsQuery,
  useUserGroupGrantsByTypeQuery,
} from "src/graphql";
import { isBuiltInPermissionSet } from "src/pages/organizations/roles/utils";
import { Workspace, getWorkspaceRole } from "./workspaces";

type Props = {
  userGroup: { id: string };
  workspace: Workspace;
  workspaceRole: ReturnType<typeof getWorkspaceRole>;
};

const BOX_PROPS = {
  bg: "white",
  border: "1px solid",
  w: "30px",
  h: "30px",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "full",
  borderColor: "base.border",
};

export const DestinationsCell: FC<Readonly<Props>> = ({
  userGroup,
  workspace,
  workspaceRole,
}) => {
  const workspaceId = workspace.id;
  const { data: destinations } = useOrganizationDestinationsQuery(
    { workspaceId: String(workspaceId) ?? "" },
    {
      enabled: Boolean(workspaceId),
      select: (data) =>
        data.getOrganizationDestinations.destinations.map((d) => ({
          id: d.id,
          name: d.name,
          icon: d.definition.icon,
        })) ?? [],
      suspense: true,
      useErrorBoundary: false,
    },
  );

  const { data: grants } = useUserGroupGrantsByTypeQuery(
    {
      workspaceId: workspaceId ?? "",
      userGroupId: userGroup?.id ?? "",
      resourceType: "destination",
    },
    {
      enabled: Boolean(workspaceId && userGroup?.id),
      select: (data) => data.user_group_grants,
      suspense: true,
    },
  );

  const OVERFLOW_LIMIT = 5;

  if (!destinations) return null;

  const grantOptions = RESOURCE_GRANT_OPTIONS(workspace.isApprovalsRequired)[
    "destination"
  ];

  const grantedDestinations = destinations.filter((destination) => {
    const specificGrant = grants?.find(
      (grant) => grant.resource_id === destination.id,
    );
    const defaultGrant = grants?.find((grant) => grant.resource_id === null);
    return (
      grantOptions.some((o) => specificGrant?.[o.value]) ||
      grantOptions.some((o) => defaultGrant?.[o.value]) ||
      !["custom", "none"].includes(workspaceRole)
    );
  });

  if (grantedDestinations.length === 0)
    return <Text color="text.secondary">--</Text>;

  return (
    <Row>
      {slice(grantedDestinations, 0, OVERFLOW_LIMIT).map((destination) => {
        return (
          <DestinationCell
            key={destination.id}
            destination={destination}
            grants={grants ?? []}
            workspaceRole={workspaceRole}
            grantOptions={grantOptions}
          />
        );
      })}
      {grantedDestinations.length > OVERFLOW_LIMIT && (
        <Row {...BOX_PROPS}>
          <Text>+{grantedDestinations.length - OVERFLOW_LIMIT}</Text>
        </Row>
      )}
    </Row>
  );
};

const DestinationCell: FC<
  Readonly<{
    destination: {
      name: string;
      icon: string;
      id: string;
    };
    grants: UserGroupGrantsByTypeQuery["user_group_grants"];
    grantOptions: Array<{ label: string; description: string; value: string }>;
    workspaceRole: ReturnType<typeof getWorkspaceRole>;
  }>
> = ({ destination, grants, workspaceRole, grantOptions }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Row sx={{ _notLast: { mr: -2 } }} {...BOX_PROPS}>
      <ChakraPopover
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <ChakraPopoverTrigger>
          <Box
            onMouseEnter={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}
            cursor="pointer"
          >
            <IntegrationIcon name={destination.name} src={destination.icon} />
          </Box>
        </ChakraPopoverTrigger>
        <Portal>
          <ChakraPopoverContent w="fit-content">
            <ChakraPopoverBody p={3}>
              <Column gap={2}>
                <Row alignItems="center" gap={1}>
                  <IntegrationIcon
                    name={destination.name}
                    src={destination.icon}
                  />
                  <Text fontWeight="medium">{destination.name}</Text>
                </Row>
                <Column background="base.background" borderRadius="sm" p={2}>
                  <Box
                    display="grid"
                    gridTemplateColumns="min-content 1fr"
                    gap={2}
                  >
                    {grantOptions?.map((grant, index) => {
                      const isExplicitlyGranted = grants?.find(
                        (g) => g.resource_id === destination.id,
                      )?.[grant.value];

                      const isDefaultGranted = grants?.find(
                        (g) => g.resource_id === null,
                      )?.[grant.value];

                      const isGrantedViaRole = isBuiltInPermissionSet(
                        workspaceRole,
                      )
                        ? getBuiltInGrantValue(
                            "destination",
                            grant.value as (typeof RESOURCE_GRANTS)["destination"][number],
                            workspaceRole,
                          )
                        : false;

                      const isGranted =
                        isExplicitlyGranted ||
                        isDefaultGranted ||
                        isGrantedViaRole;

                      return (
                        <Fragment key={index}>
                          {isGranted ? (
                            <CheckIcon color="success.base" />
                          ) : (
                            <CloseIcon color="danger.base" />
                          )}

                          <Text>{grant.label}</Text>
                        </Fragment>
                      );
                    })}
                  </Box>
                </Column>
              </Column>
            </ChakraPopoverBody>
          </ChakraPopoverContent>
        </Portal>
      </ChakraPopover>
    </Row>
  );
};
