import { useCallback } from "react";
import { useUser } from "src/contexts/user-context";
import { useSyncCreationAuthorizationQuery } from "src/graphql";

export function useSyncCreationPermissions(modelId?: string | number): {
  modelIds: string[];
  destinationIds: string[];
  syncTemplateIds: string[];
  modelFilter: (id: string | number) => boolean;
  destinationFilter: (id: string | number) => boolean;
  syncTemplateFilter: (id: string | number) => boolean;
} {
  const { user } = useUser();
  const permissionsV2Enabled = user?.permissions_v2_enabled ?? false;
  const { data: authorizationRows, isLoading } =
    useSyncCreationAuthorizationQuery(
      {
        modelId: modelId?.toString(),
      },
      {
        select: (data) => data.user_group_sync_creation_authorization ?? [],
        enabled: permissionsV2Enabled ?? false,
      },
    );

  const modelFilter = useCallback(
    (id: string | number) => {
      if (!permissionsV2Enabled || isLoading || !authorizationRows) {
        return true;
      }

      return authorizationRows.some(
        (row) => String(row.model_id) === String(id),
      );
    },
    [authorizationRows, permissionsV2Enabled, isLoading],
  );

  const destinationFilter = useCallback(
    (id: string | number) => {
      if (!permissionsV2Enabled || isLoading || !authorizationRows) {
        return true;
      }

      return authorizationRows.some(
        (row) => String(row.destination_id) === String(id),
      );
    },
    [authorizationRows, permissionsV2Enabled, isLoading],
  );

  const syncTemplateFilter = useCallback(
    (id: string | number) => {
      if (!permissionsV2Enabled || isLoading || !authorizationRows) {
        return true;
      }

      return authorizationRows.some(
        (row) => String(row.sync_template_id) === String(id),
      );
    },
    [authorizationRows, permissionsV2Enabled, isLoading],
  );

  return {
    modelIds: authorizationRows?.map((row) => row.model_id) ?? [],
    destinationIds: authorizationRows?.map((row) => row.destination_id) ?? [],
    syncTemplateIds:
      authorizationRows
        ?.filter((row) => row.sync_template_id)
        ?.map((row) => row.sync_template_id?.toString() as string) ?? [],
    modelFilter,
    destinationFilter,
    syncTemplateFilter,
  };
}
