import { FC, useState } from "react";

import { Heading, useToast } from "@hightouchio/ui";
import { Helmet } from "react-helmet";

import { Form, useHightouchForm } from "src/components/form";
import { ParentModelSelect } from "src/components/models/parent-model-select";
import { WizardStep, DeprecatedWizard } from "src/components/wizard";
import { useCreateAttributionMethodMutation } from "src/graphql";
import { useNavigate } from "src/router";

import { AttributionMethodForm } from "./attribution-method-form";
import {
  AttributionMethodFormData,
  attributionMethodFormDefaultValues,
} from "./use-attribution-method-form";

export const CreateAttributionMethod: FC = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [step, setStep] = useState(0);
  const [parentModelId, setParentModelId] = useState<string | undefined>();
  const { mutateAsync: createAttributionMethod } =
    useCreateAttributionMethodMutation();

  const form = useHightouchForm({
    success: "Attribution method created",
    onSubmit: async (data: AttributionMethodFormData) => {
      if (!parentModelId) {
        toast({
          id: "attribution-form-toast",
          title: "Attribution method creation error",
          message: "No parent model selected.",
          variant: "error",
        });
        return;
      }

      await createAttributionMethod({
        input: {
          name: data.name,
          type: data.weighting,
          parent_model_id: parentModelId,
          attribution_methods_interactions: {
            data: data.interactions.map((interaction) => ({
              interaction_model_id: interaction.interactionModelId,
              lookback_window: interaction.lookbackWindow,
            })),
          },
        },
      });

      navigate("/metrics/attributions");
    },
    defaultValues: attributionMethodFormDefaultValues,
  });

  const name = form.watch("name");
  const weighting = form.watch("weighting");
  const interactions = form.watch("interactions");

  const steps: WizardStep[] = [
    {
      title: "Select parent model",
      continue: "Click on a parent model to continue",
      header: <Heading>Select a parent model</Heading>,
      render: () => (
        <ParentModelSelect
          disableSourcesWithoutAudienceSnapshotting
          onSelect={(model) => {
            if (model?.connection?.audience_snapshotting_enabled) {
              setParentModelId(model.id);
              setStep(1);
            }
          }}
        />
      ),
    },
    {
      title: "Configure attribution method",
      header: (
        <Heading>Define how this attribution method will be calculated</Heading>
      ),
      render: () =>
        parentModelId ? (
          <Form form={form}>
            <AttributionMethodForm parentModelId={parentModelId} />
          </Form>
        ) : null,
      disabled:
        !name ||
        !weighting ||
        !interactions ||
        interactions.length === 0 ||
        interactions[0]?.interactionModelId === undefined,
    },
  ];

  return (
    <>
      <Helmet>
        <title>New attribution method</title>
      </Helmet>
      <DeprecatedWizard
        setStep={setStep}
        step={step}
        steps={steps}
        title="New attribution method"
        onCancel={() => navigate("/metrics/attributions")}
        onSubmit={form.submit}
      />
    </>
  );
};
