import { FC } from "react";

import {
  Column,
  FormField,
  NumberInput,
  Radio,
  RadioGroup,
  Row,
  Select,
} from "@hightouchio/ui";
import { Controller, useFormContext } from "react-hook-form";

import { TIME_OPTIONS } from "src/constants";
import { JourneyNodeSyncMode, JourneySyncExitConfig } from "src/types/journeys";
import { NullableProperties } from "src/types/utils";
import { IntervalUnit } from "src/types/visual";

type JourneySyncConfig = {
  mode: JourneyNodeSyncMode;
  exit_config: NullableProperties<JourneySyncExitConfig>;
};

export const JourneySyncConfigForm: FC<{ isDisabled?: boolean }> = ({
  isDisabled = false,
}) => {
  const form = useFormContext<JourneySyncConfig>();

  const isCohortSync = form.watch("mode") === JourneyNodeSyncMode.Cohort;

  return (
    <Column gap={6}>
      <Controller
        control={form.control}
        name="mode"
        render={({ field }) => (
          <FormField label="What type of sync is this?">
            <RadioGroup
              isDisabled={isDisabled}
              value={field.value}
              onChange={(value) => {
                field.onChange(value);

                form.setValue(
                  "exit_config",
                  {
                    remove_after: null,
                    remove_on_journey_exit: null,
                  },
                  { shouldDirty: true },
                );
              }}
            >
              <Radio
                value={JourneyNodeSyncMode.Trigger}
                label="Trigger"
                description="For triggering campaigns or sending user events. The same user can be synced multiple times and a removal is not necessary."
              />
              <Radio
                value={JourneyNodeSyncMode.Cohort}
                label="Cohort"
                description="For syncing users into a cohort or list. Users will never appear in the destination cohort more than one time and you can specify when the user should be removed."
              />
            </RadioGroup>
          </FormField>
        )}
      />

      {isCohortSync && (
        <>
          <Controller
            control={form.control}
            name="exit_config.remove_after"
            render={({ field }) => (
              <FormField label="Should users be removed from this sync after a specified time period?">
                <RadioGroup
                  isDisabled={isDisabled}
                  orientation="vertical"
                  value={field.value !== null}
                  onChange={(value) => {
                    field.onChange(
                      value ? { quantity: 1, unit: IntervalUnit.Day } : null,
                    );
                  }}
                >
                  <Row align="center" gap={2}>
                    <Radio value={true} label="Yes, remove after" />
                    {field.value && (
                      <>
                        <NumberInput
                          isDisabled={isDisabled}
                          placeholder="Select quantity..."
                          value={field.value?.quantity}
                          width="auto"
                          onChange={(value) =>
                            field.onChange({
                              ...field.value,
                              quantity: value,
                            })
                          }
                        />
                        <Select
                          isDisabled={isDisabled}
                          placeholder="Select delay unit..."
                          options={TIME_OPTIONS}
                          value={field.value?.unit}
                          width="auto"
                          onChange={(value) =>
                            field.onChange({ ...field.value, unit: value })
                          }
                        />
                      </>
                    )}
                  </Row>
                  <Radio value={false} label="No, don't remove" />
                </RadioGroup>
              </FormField>
            )}
          />

          <Controller
            control={form.control}
            name="exit_config.remove_on_journey_exit"
            render={({ field }) => (
              <FormField label="Should users be removed from this sync when they exit the journey?">
                <RadioGroup
                  isDisabled={isDisabled}
                  orientation="vertical"
                  value={field.value !== null}
                  onChange={(value) => field.onChange(value || null)}
                >
                  <Radio value={true} label="Yes, remove upon exit" />
                  <Radio value={false} label="No, don't remove" />
                </RadioGroup>
              </FormField>
            )}
          />
        </>
      )}
    </Column>
  );
};
