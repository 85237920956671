import { ReactNode } from "react";

import { Row } from "@hightouchio/ui";

import { useHeaderHeight } from "src/contexts/header-height-context";
import { Pagination, PaginationProps } from "src/ui/table/pagination";
import { Table, TableProps } from "src/ui/table/table";

type Props<RowData extends Record<string, unknown>> = TableProps<RowData> & {
  header: ReactNode;
  pagination?: Omit<PaginationProps, "rowsPerPage">;
};

export function PageTable<RowData extends Record<string, unknown>>({
  header,
  pagination,
  ...props
}: Props<RowData>) {
  const { headerHeight } = useHeaderHeight();

  return (
    <>
      <Row
        align="center"
        justify="space-between"
        py={4}
        gap={4}
        bg="white"
        zIndex={1}
        px={8}
        width="100%"
        pos="sticky"
        top={`${headerHeight ?? 0}px`}
      >
        {header}
      </Row>
      <Table
        top={`${headerHeight + 64}px`}
        {...props}
        placeholder={{ ...(props.placeholder || {}), p: 8 }}
      />
      {pagination && (
        <Row justify="flex-end" width="100%" mt={4} px={4}>
          <Pagination {...pagination} rowsPerPage={50} />
        </Row>
      )}
    </>
  );
}
