import { V2ResourceToPermission } from "src/components/permission/types-v2";
import { ResourcePermissionInput } from "src/components/permission/use-resource-permission";
import {
  IntervalSchedule,
  ScheduleIntervalUnit,
  ScheduleType,
} from "src/components/schedule/types";
import { ResourceToPermission } from "src/graphql";
import { JourneyNodeType } from "src/types/journeys";
import { ConditionType } from "src/types/visual";

import { ExitCriteria, JourneyState } from "./types";

export const DRAWER_WIDTH = 672;
export const NODE_WIDTH = 284;
export const BASE_NODE_HEIGHT = 100;
export const MAX_NODE_HEIGHT = 200;
export const NODE_SPACING = 100;
export const MINI_NODE_MIN_WIDTH = 100;
export const MINI_NODE_MIN_HEIGHT = 50;
export const MINI_NODE_INITIAL_SPACING = 150;
export const HANDLE_SIZE = 16;
export const MAX_CARD_WIDTH = 348;
export const VISIBLE_SYNC_COUNT = 3;

export const LIVE_STATES: JourneyState[] = ["live", "draining"];

export enum JourneyFilterType {
  // Draining and paused are purposely left out
  ALL = "All",
  DRAFTS = "Draft",
  LIVE = "Live",
  OFF = "Off",
}

export const JOURNEY_NODES_WITH_BRANCH_NODES = [
  JourneyNodeType.Segments,
  JourneyNodeType.WaitUntilEvent,
];

export const JOURNEY_BRANCH_NODES = [
  JourneyNodeType.SegmentBranch,
  JourneyNodeType.WaitUntilEventBranch,
];

export const PARENT_NODE_TO_BRANCH_NODE = {
  [JourneyNodeType.Segments]: JourneyNodeType.SegmentBranch,
  [JourneyNodeType.WaitUntilEvent]: JourneyNodeType.WaitUntilEventBranch,
};

// Actions that should not dirty the form.
export const NOOP_REACTFLOW_CHANGES = ["dimensions", "select", "position"];

export const DEFAULT_INTERVAL_SCHEDULE: IntervalSchedule = {
  type: ScheduleType.INTERVAL,
  schedule: {
    interval: {
      quantity: 1,
      unit: ScheduleIntervalUnit.HOUR,
    },
  },
};

export const JOURNEY_UPDATE_PERMISSION: ResourcePermissionInput<
  V2ResourceToPermission,
  ResourceToPermission
> = {
  v1: { resource: "sync", grant: "create" },
};

export const JOURNEY_DELETE_PERMISSION: ResourcePermissionInput<
  V2ResourceToPermission,
  ResourceToPermission
> = {
  v1: { resource: "sync", grant: "delete" },
};

export const NULL_EXIT_CRITERIA: ExitCriteria = {
  type: ConditionType.And,
  conditions: [],
};
