// Based on: https://github.com/aboveyunhai/chakra-dayzed-datepicker/
import { useEffect, useState } from "react";

import { Box, Button, ButtonGroup, Column, Row, Text } from "@hightouchio/ui";
import { differenceInDays, isValid } from "date-fns";
import { Props as DayzedHookProps } from "dayzed";
import { isPresent } from "ts-extras";

import { CalendarConfigs, OnDateSelected } from "../utils/common-types";
import { CalendarPanel } from "./calendar-panel";
import { DayInput } from "./day-input";

interface RangeCalendarPanelProps {
  dayzedHookProps: DayzedHookProps & { onDateSelected: OnDateSelected };
  configs: CalendarConfigs;
  startDate: Date | null;
  endDate: Date | null;
  onUpdateStartDate: (date: Date) => void;
  onUpdateEndDate: (date: Date) => void;
  onCancel: () => void;
  onSubmit: () => void;
}

export const RangeCalendarPanel: React.FC<RangeCalendarPanelProps> = ({
  dayzedHookProps,
  configs,
  startDate,
  endDate,
  onUpdateStartDate,
  onUpdateEndDate,
  onCancel,
  onSubmit,
}) => {
  const [hoveredDate, setHoveredDate] = useState<Date | null>(null);
  const [isStartDateValid, setIsStartDateValid] = useState(true);
  const [isEndDateValid, setIsEndDateValid] = useState(true);

  // Set valid state for date range
  useEffect(() => {
    setIsStartDateValid(isValid(startDate));
    setIsEndDateValid(isValid(endDate));
  }, [startDate, endDate]);

  // Calendar level
  const onMouseLeave = () => {
    setHoveredDate(null);
  };

  // Date level
  const onMouseEnterHighlight = (date: Date) => {
    setHoveredDate(date);
  };

  const selectTodayAsEndDate = () => {
    onUpdateEndDate(new Date(new Date().setHours(0, 0, 0, 0)));
  };

  const isInRange = (date: Date) => {
    if (!startDate) {
      return false;
    }

    if (endDate) {
      return startDate < date && endDate > date;
    } else {
      return (
        hoveredDate &&
        ((startDate < date && hoveredDate >= date) ||
          (date < startDate && date >= hoveredDate))
      );
    }
  };

  const getRange = (selectedDates: Date | Date[] | undefined) => {
    if (!Array.isArray(selectedDates) || !selectedDates?.length) {
      return null;
    }

    const firstSelected = selectedDates[0];

    if (!firstSelected) {
      return null;
    }

    const secondSelected = selectedDates[1]!;

    if (secondSelected || hoveredDate) {
      return Math.abs(
        differenceInDays(secondSelected ?? hoveredDate, firstSelected),
      );
    }

    return null;
  };

  const range = getRange([startDate, endDate].filter(isPresent));

  const setStartDate = (date: Date) => {
    if (endDate) {
      if (endDate > date) {
        onUpdateStartDate(date);
      } else {
        onUpdateEndDate(date);
        onUpdateStartDate(endDate);
      }
    } else {
      onUpdateStartDate(date);
    }
  };

  const setEndDate = (date: Date) => {
    if (startDate) {
      if (startDate < date) {
        onUpdateEndDate(date);
      } else {
        onUpdateStartDate(date);
        onUpdateEndDate(startDate);
      }
    } else {
      onUpdateEndDate(date);
    }
  };

  const isValidRange = isStartDateValid && isEndDateValid;
  const isRangePresent = startDate != null && endDate != null;

  return (
    <Box
      display="flex"
      flexDirection="column"
      rowGap={4}
      onMouseLeave={onMouseLeave}
    >
      <Box
        display="grid"
        alignItems="center"
        gridTemplateColumns="min-content min-content 1fr"
        gap={2}
        p={4}
        borderTopRadius="md"
        borderBottom="1px solid"
        borderColor="base.border"
        bg="base.lightBackground"
      >
        <DayInput
          isValid={isStartDateValid}
          placeholder="mm/dd/yyyy"
          size="sm"
          width="auto"
          value={startDate}
          onChange={setStartDate}
          setIsValid={setIsStartDateValid}
        />
        <DayInput
          isValid={isEndDateValid}
          placeholder="mm/dd/yyyy"
          size="sm"
          width="auto"
          value={endDate}
          onChange={setEndDate}
          setIsValid={setIsEndDateValid}
        />
        {range !== null && (
          <Box as={Text} color="text.secondary" textAlign="end">
            Range: <Text fontWeight="semibold">{range} days</Text>
          </Box>
        )}
      </Box>

      <CalendarPanel
        dayzedHookProps={dayzedHookProps}
        selected={[startDate, endDate]}
        configs={configs}
        isInRange={isInRange}
        onMouseEnterHighlight={onMouseEnterHighlight}
      />

      <Row px={4} pb={4} justify="space-between">
        <ButtonGroup>
          <Button
            isDisabled={!isValidRange || !isRangePresent}
            variant="primary"
            onClick={onSubmit}
          >
            Apply
          </Button>
          <Button onClick={onCancel}>Cancel</Button>
        </ButtonGroup>

        {/* TODO: compare end date to today */}
        {!endDate && (
          <Box
            sx={{
              button: {
                color: "link.default",
                _hover: {
                  color: "link.default",
                },
              },
            }}
          >
            <Button onClick={selectTodayAsEndDate} variant="tertiary">
              Select today as end date
            </Button>
          </Box>
        )}
        {isRangePresent && !isValidRange && (
          <Column justifyContent="center">
            <Text color="text.danger">Invalid date range</Text>
          </Column>
        )}
      </Row>
    </Box>
  );
};
