import { JSONContent } from "@tiptap/react";
import {
  TokenKind,
  Tokenizer,
  defaultOperators,
  TopLevelToken,
} from "liquidjs";

type ExtendedContent = {
  json: JSONContent;
  text: string;
  position: {
    start: number;
    end: number;
  };
};

type HTMLDeserialize = (html: string) => JSONContent;

const processTokens = (
  tokens: TopLevelToken[],
  opts: Readonly<{ deserialize: HTMLDeserialize }>,
): ExtendedContent[] => {
  const accumulations: ExtendedContent[] = [];

  const { deserialize } = opts;

  for (const token of tokens) {
    if (token.kind === TokenKind.HTML) {
      const text = token.getText();
      accumulations.push({
        text,
        json: deserialize(text),
        position: { start: token.begin, end: token.end },
      });
    } else if (token.kind === TokenKind.Output) {
      accumulations.push({
        text: token.getText(),
        json: {
          type: "liquid",
          attrs: {
            id: token
              .getText()
              .replace(/^(\{\{)/g, "")
              .replace(/(\}\})$/, "")
              .trim(),
          },
        },
        position: { start: token.begin, end: token.end },
      });
    } else {
      // Liquid tags
      // TODO this can be it's own node.
      accumulations.push({
        text: token.getText(),
        json: { type: "text", text: token.getText() },
        position: { start: token.begin, end: token.end },
      });
    }
  }

  return accumulations;
};

export const liquidDeserialize = (
  content: string,
  opts: {
    /**
     * Deserialize non-liquid property.
     */
    deserialize: HTMLDeserialize;
    /**
     * This handles the merging of contents from liquid and non-liquid template to build the final JSON content.
     */
    merge: (contents: ExtendedContent[]) => JSONContent;
  },
): JSONContent => {
  const { deserialize, merge } = opts;

  const tokenizer = new Tokenizer(content, defaultOperators);
  const tokens = tokenizer.readTopLevelTokens();

  return merge(processTokens(tokens, { deserialize }));
};
