import { AttributionMethodType } from "src/types/visual";

export const WeightingOptions = [
  {
    label: "Participation",
    description: "Attribute every campaign a user interacts with",
    value: AttributionMethodType.Participation,
  },
  {
    label: "Last touch",
    description: "Attribute the last campaign a user interacts with",
    value: AttributionMethodType.LastTouch,
  },
  {
    label: "First touch",
    description: "Attribute the first campaign a user interacts with",
    value: AttributionMethodType.FirstTouch,
  },
];

export const WeightingOptionMap = WeightingOptions.reduce(
  (all, option) => {
    all[option.value] = option.label;

    return all;
  },
  {} as Record<AttributionMethodType, string>,
);
