import { useLocation } from "src/router";

import { usePartnerConnectGetInformationQuery } from "src/graphql";
import { useQueryString } from "src/utils/use-query-string";

interface LocationState {
  from?: {
    pathname: string;
  };
}

export const useAuthState = () => {
  const location = useLocation();
  const locationState = location.state as LocationState | undefined;
  const {
    data: { returnTo: queryLocation, error },
  } = useQueryString();
  const returnTo = queryLocation || locationState?.["from"]?.["pathname"];
  const match = returnTo?.match(
    /^\/?partner-connect\/([0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12})$/i,
  );
  const { data: partnerInformation } = usePartnerConnectGetInformationQuery(
    { uuid: match?.[1] ?? "" },
    {
      enabled: Boolean(match?.length),
      select: (data) => data.partnerConnectGetInformation,
      suspense: true,
    },
  );

  const googleAuthUrl = new URL(
    `${import.meta.env.VITE_API_BASE_URL}/google/oauth`,
  );
  const microsoftAuthUrl = new URL(
    `${import.meta.env.VITE_API_BASE_URL}/azure/oauth`,
  );

  if (returnTo) {
    googleAuthUrl.searchParams.append("returnTo", returnTo);
    microsoftAuthUrl.searchParams.append("returnTo", returnTo);
  }

  const isSignup = location.pathname.includes("signup");

  return {
    error,
    isSignup,
    returnTo,
    googleAuthUrl: googleAuthUrl.toString(),
    microsoftAuthUrl: microsoftAuthUrl.toString(),
    partnerInformation,
  };
};
