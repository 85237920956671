import { FC, ReactNode } from "react";

import { Box, BoxProps, CheckIcon, Column, Row, Text } from "@hightouchio/ui";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { TextWithTooltip } from "src/components/text-with-tooltip";

export type VerticalWizardStep = {
  label: string;
  description?: string;
  integrationIcon?: { name: string; src: string };
  render: () => ReactNode;
};

const SIDEBAR_WIDTH = "280px";

type VerticalWizardContentProps = {
  steps: VerticalWizardStep[];
  step: number;
  contentStyles?: BoxProps;
} & BoxProps;

export const VerticalWizard: FC<VerticalWizardContentProps> = ({
  steps,
  step,
  contentStyles,
  ...boxProps
}) => {
  const currentStep = steps[step];

  return (
    <Row position="relative" height="100%" width="100%" gap={6} {...boxProps}>
      {steps.length > 1 && (
        <Column
          pos="sticky"
          pt={8}
          top={0}
          gap={10}
          width={{ base: "auto", lg: SIDEBAR_WIDTH }}
          maxW={SIDEBAR_WIDTH}
          height="max-content"
          flexShrink={0}
        >
          <Column as="ol" p={0} gap={1}>
            {steps.map((s, index) => (
              <Row gap={4} as="li" key={s.label}>
                <Column>
                  {step > index ? (
                    <Row
                      flexShrink={0}
                      fontSize="lg"
                      bg="base.background"
                      borderRadius="50%"
                      w="21px"
                      h="21px"
                      align="center"
                      justify="center"
                    >
                      <CheckIcon color="primary.base" />
                    </Row>
                  ) : index > step ? (
                    <Row
                      flexShrink={0}
                      borderRadius="50%"
                      w="21px"
                      h="21px"
                      border="1px"
                      borderColor="base.border"
                    />
                  ) : (
                    <Row
                      flexShrink={0}
                      w="21px"
                      h="21px"
                      align="center"
                      justify="center"
                      border="2px"
                      borderColor="primary.base"
                      borderRadius="50%"
                    >
                      <Row
                        flexShrink={0}
                        w="9px"
                        h="9px"
                        borderRadius="50%"
                        bg="primary.base"
                      />
                    </Row>
                  )}
                  {index < steps.length - 1 && (
                    <Box
                      mt="4px"
                      w="1px"
                      height="48px"
                      ml="10px"
                      bg="base.divider"
                    />
                  )}
                </Column>
                <Column
                  gap={1}
                  display={{ base: "none", md: "flex", lg: "flex" }}
                  overflowX="hidden"
                >
                  <Row gap={2}>
                    {s.integrationIcon && (
                      <IntegrationIcon {...s.integrationIcon} size={6} />
                    )}
                    <TextWithTooltip
                      fontWeight="medium"
                      message={s.label}
                      whiteSpace="normal"
                    >
                      {s.label}
                    </TextWithTooltip>
                  </Row>
                  <Text size="sm" color="text.secondary">
                    {s.description}
                  </Text>
                </Column>
              </Row>
            ))}
          </Column>
        </Column>
      )}

      <Column flex={1} minW={0} {...contentStyles}>
        {currentStep?.render()}
      </Column>
    </Row>
  );
};
