import { FC, useState } from "react";

import {
  AudienceIcon,
  Box,
  ButtonGroup,
  Checkbox,
  CloseIcon,
  Column,
  EditIcon,
  ExternalLinkIcon,
  FilterIcon,
  GroupedCombobox,
  IconButton,
  PlusIcon,
  Row,
  SectionHeading,
  Text,
  Tooltip,
  WarningIcon,
} from "@hightouchio/ui";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { Link } from "src/router";
import { noop } from "ts-essentials";
import { isPresent } from "ts-extras";

import { AudienceColumn } from "src/components/explore/filter-popover/constants";
import { IconBox } from "src/components/icon-box";
import * as analytics from "src/lib/analytics";

import { useAnalyticsContext } from "src/pages/analytics/state";
import { PLACEHOLDER_AUDIENCE } from "src/pages/analytics/state/constants";
import {
  AdHocAudience,
  ChartFormState,
  GraphType,
  isAdHocAudience,
  MeasurementScope,
} from "src/pages/analytics/types";
import { MAX_SELECTED_AUDIENCES_ALLOWED } from "src/pages/analytics/constants";
import { ParentModelFilter } from "src/pages/analytics/parent-model-filter";

const BlueAudienceIcon: FC = () => (
  <IconBox
    bg={AudienceColumn.color}
    boxSize="20px"
    icon={AudienceColumn.icon}
    iconSize="14px"
  />
);

const InvertedAudienceIcon: FC = () => (
  <IconBox
    boxSize="20px"
    icon={<AudienceIcon />}
    iconSize="14px"
    iconSx={{ color: "space.600" }}
  />
);

type SegmentByProps = {
  funnelsErrors: Record<string, string>;
  metricSeriesErrors: Record<string, string>;
};

export const SegmentBy: FC<SegmentByProps> = ({
  funnelsErrors,
  metricSeriesErrors,
}) => {
  const { audiences, audiencesAndMetricsLoading, parent, parentModelLoading } =
    useAnalyticsContext();

  const form = useFormContext<ChartFormState>();
  const { fields, update, remove, append } = useFieldArray({
    control: form.control,
    name: "selectedAudiences",
  });

  const [showParentModelFilter, setShowParentModelFilter] = useState(false);
  const [selectedAudienceToFilter, setSelectedAudienceToFilter] = useState<
    null | number
  >(null);

  const graphType = form.watch("graphType");
  const selectedAudiences = form.watch("selectedAudiences");
  const measuringSelection = form.watch("measuringSelection");

  const parentModelNotSelected =
    parent &&
    !selectedAudiences?.some((a) => a.id === parent.id && !isAdHocAudience(a));

  const selectedNonParentAudienceIds = new Set(
    selectedAudiences
      ?.filter(({ id }) => parent?.id !== id)
      ?.map(({ id }) => id) ?? [],
  );

  return (
    <>
      <Column gap={2}>
        <Row align="center" justifyContent="space-between">
          <SectionHeading>Segment by</SectionHeading>
          {(selectedAudiences?.length ?? 0) <=
            MAX_SELECTED_AUDIENCES_ALLOWED && (
            <ButtonGroup size="lg">
              {parent && (
                <Tooltip message={`Filter ${parent?.name ?? "Parent model"}`}>
                  <IconButton
                    icon={FilterIcon}
                    aria-label="Filter parent model"
                    onClick={() => {
                      setSelectedAudienceToFilter(
                        selectedAudiences?.length ?? 0,
                      );
                      setShowParentModelFilter(true);
                    }}
                  />
                </Tooltip>
              )}
              <Tooltip message="Add audience">
                <IconButton
                  icon={PlusIcon}
                  aria-label="Add audience input"
                  onClick={() => append(PLACEHOLDER_AUDIENCE)}
                />
              </Tooltip>
            </ButtonGroup>
          )}
        </Row>

        <Column gap={2}>
          {fields?.map((_, index) => {
            const selectedAudience = selectedAudiences?.[index];
            if (!selectedAudience) {
              return null;
            }

            const { splits = [], ...audience } = selectedAudience;

            // Display a static box for ad hoc audiences bc we don't want
            // to combine them with the persisted audience dropdown so it's
            // clearer that these audiences are temporary
            if (isAdHocAudience(selectedAudience)) {
              return (
                <Column
                  key={`${audience?.id}-${audience?.name}-${index}`}
                  bg="white"
                  gap={2}
                  p={2}
                  border="1px solid"
                  borderRadius="md"
                  borderColor="base.border"
                >
                  <Box
                    display="grid"
                    gridTemplateColumns="1fr max-content"
                    gap={2}
                    sx={{
                      input: {
                        width: "100%",
                        boxSizing: "border-box",
                      },
                    }}
                  >
                    <Tooltip message="Filtered parent model">
                      <Box
                        display="inline-flex"
                        alignItems="center"
                        gap={1}
                        paddingInline={2}
                        backgroundColor="base.lightBackground"
                        boxShadow="sm"
                        borderWidth="1px"
                        borderColor="base.border"
                        borderRadius="md"
                      >
                        <Box as={InvertedAudienceIcon} />
                        <Text>{audience.name}</Text>
                      </Box>
                    </Tooltip>

                    {(audience.name || fields.length > 1) && (
                      <Row alignItems="center" width="fit-content">
                        {audience.name && (
                          <Tooltip message="Edit filter">
                            <IconButton
                              icon={EditIcon}
                              aria-label="Edit audience filter"
                              variant="tertiary"
                              onClick={() => {
                                analytics.track("Editing Audience Filter", {
                                  chart_type: graphType,
                                  audience_id: audience.id,
                                  audience_name: audience.name,
                                });

                                setSelectedAudienceToFilter(index);
                                setShowParentModelFilter(true);
                              }}
                            />
                          </Tooltip>
                        )}
                        {fields.length > 1 && (
                          <Tooltip message="Remove">
                            <IconButton
                              icon={CloseIcon}
                              aria-label="Remove audience selection"
                              variant="tertiary"
                              onClick={() => remove(index)}
                            />
                          </Tooltip>
                        )}
                      </Row>
                    )}
                  </Box>
                </Column>
              );
            }

            const isParentModel = audience.id === parent?.id;

            return (
              <Column
                key={`${audience?.id}-${audience?.name}-${index}`}
                bg="white"
                gap={2}
                p={2}
                border="1px solid #dbe1e8"
                borderRadius="md"
              >
                <Box
                  display="grid"
                  gridTemplateColumns="1fr max-content"
                  gap={2}
                  sx={{
                    input: {
                      width: "100%",
                      boxSizing: "border-box",
                    },
                  }}
                >
                  <Controller
                    control={form.control}
                    name={`selectedAudiences.${index}`}
                    render={({ field }) => (
                      <GroupedCombobox
                        isLoading={
                          parentModelLoading || audiencesAndMetricsLoading
                        }
                        optionGroups={[
                          parentModelNotSelected || audience.id === parent?.id
                            ? {
                                label: "Parent model",
                                options: [
                                  {
                                    id: parent.id,
                                    name: parent.name,
                                    splits: [],
                                  },
                                ],
                              }
                            : null,
                          {
                            label: "Audiences",
                            options:
                              audiences?.filter(
                                ({ id }) =>
                                  !selectedNonParentAudienceIds.has(id) ||
                                  audience.id === id,
                              ) ?? [],
                          },
                        ]
                          .filter(isPresent) // Throws a type error unless null is filtered out separately
                          .filter((group) => group.options.length > 0)}
                        optionAccessory={({ id }) => {
                          if (id === parent?.id) {
                            return {
                              type: "image",
                              url: parent.connection?.definition.icon ?? "",
                            };
                          }
                          return {
                            type: "icon",
                            icon: BlueAudienceIcon,
                          };
                        }}
                        optionLabel={(option) => option.name}
                        optionValue={(option) => option.id}
                        placeholder="Select an audience..."
                        value={
                          field.value.id !== PLACEHOLDER_AUDIENCE.id
                            ? field.value.id
                            : undefined
                        }
                        variant="heavy"
                        popoverWidth="sm"
                        width="100%"
                        onChange={(newValue) => {
                          if (!newValue) {
                            return;
                          }

                          if (newValue === parent?.id) {
                            analytics.track(
                              "Selecting Parent Model in Analytics Chart",
                              {
                                chart_type: graphType,
                                audience_id: newValue,
                                parent_model_name: parent?.name,
                                parent_model_source_name:
                                  parent?.connection?.name,
                                parent_model_source_type:
                                  parent?.connection?.definition.name,
                              },
                            );

                            // TODO: validate that setting the id here doesn't mess with things
                            update(index, {
                              id: parent.id,
                              name: parent.name,
                              splits: [],
                            });
                          } else {
                            const audienceToSelect = audiences?.find(
                              ({ id }) => id === newValue,
                            );

                            analytics.track(
                              "Selecting Audience in Analytics Chart",
                              {
                                chart_type: graphType,
                                audience_id: newValue,
                                audience_name: audienceToSelect?.name,
                                has_splits: Boolean(
                                  audienceToSelect?.splits.length,
                                ),
                              },
                            );

                            if (audienceToSelect) {
                              update(index, audienceToSelect);
                            }
                          }
                        }}
                      />
                    )}
                  />

                  {(audience.name || selectedAudiences.length > 1) && (
                    <Row alignItems="center" width="fit-content">
                      {audience.name && (
                        <Link
                          href={
                            isParentModel
                              ? parent?.connection
                                ? `/schema-v2/view/query?source=${parent.connection.id}&id=${audience.id}`
                                : "/schema-v2/view/query"
                              : `/audiences/${audience.id}`
                          }
                          isExternal
                        >
                          <Tooltip message="View audience">
                            <IconButton
                              icon={ExternalLinkIcon}
                              aria-label="Link to selected audience"
                              variant="tertiary"
                              onClick={noop}
                            />
                          </Tooltip>
                        </Link>
                      )}
                      {selectedAudiences.length > 1 && (
                        <Tooltip message="Remove">
                          <IconButton
                            icon={CloseIcon}
                            aria-label="Remove audience selection"
                            variant="tertiary"
                            onClick={() => remove(index)}
                          />
                        </Tooltip>
                      )}
                    </Row>
                  )}
                </Box>

                {/* TODO(samuel): when splits are enabled for funnels, add this back in */}
                {graphType !== GraphType.Funnel &&
                  measuringSelection?.scope !==
                    MeasurementScope.DecisionEngineFlow &&
                  splits.length > 0 && (
                    <Column pl={2} gap={2}>
                      {splits.map(({ id, name, enabled }, splitIndex) => (
                        <Checkbox
                          key={id}
                          label={name}
                          isChecked={enabled}
                          onChange={(event) => {
                            const newSplits = splits.slice();
                            newSplits[splitIndex] = {
                              id,
                              name,
                              enabled: event.target.checked,
                            };

                            update(index, {
                              ...audience,
                              splits: newSplits,
                            });
                          }}
                        />
                      ))}
                    </Column>
                  )}

                {graphType !== GraphType.Funnel &&
                  audience.name &&
                  metricSeriesErrors[audience.id] && (
                    <Box
                      alignItems="center"
                      display="grid"
                      gridTemplateColumns="28px 1fr 32px"
                      color="danger.base"
                      fontSize="20px"
                      gap={2}
                    >
                      <WarningIcon ml={2} />
                      <Text color="danger.base" size="sm">
                        {metricSeriesErrors[audience.id]}
                      </Text>
                    </Box>
                  )}

                {graphType === GraphType.Funnel &&
                  audience.name &&
                  funnelsErrors[audience.id] && (
                    <Box
                      alignItems="center"
                      display="grid"
                      gridTemplateColumns="28px 1fr 32px"
                      color="danger.base"
                      fontSize="20px"
                      gap={2}
                    >
                      <WarningIcon ml={2} />
                      <Text color="danger.base" size="sm">
                        {funnelsErrors[audience.id]}
                      </Text>
                    </Box>
                  )}
              </Column>
            );
          })}
        </Column>
      </Column>

      {parent && selectedAudienceToFilter != null && (
        <ParentModelFilter
          parent={parent}
          selectedAudience={
            selectedAudiences?.[selectedAudienceToFilter] ?? {
              id: parent.id,
              name: "Audience X",
              splits: [],
            }
          }
          isOpen={showParentModelFilter}
          onClose={() => {
            setShowParentModelFilter(false);
            setSelectedAudienceToFilter(null);
          }}
          addFilter={(audience: AdHocAudience) =>
            selectedAudienceToFilter < (selectedAudiences?.length ?? 0)
              ? update(selectedAudienceToFilter, audience)
              : append(audience)
          }
        />
      )}
    </>
  );
};
