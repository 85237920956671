import { SchemaField, SchemaFieldForm } from "src/pages/schema/types";

import { SectionHeading } from "@hightouchio/ui";
import { useMemo } from "react";
import { Form, useHightouchForm } from "src/components/form";
import { useUpdateAdStatsModelMutation } from "src/graphql";
import { ColumnField } from "./column-field";

export const AssetIdField: SchemaField = (props) => {
  return (
    <ColumnField name="adStats.asset_id_column" label="Asset ID" {...props} />
  );
};

export const StandardColumnsFields: SchemaField = (props) => {
  return (
    <>
      <SectionHeading>Standard Columns</SectionHeading>
      <ColumnField name="adStats.spend_column" label="Spend" {...props} />
      <ColumnField name="adStats.clicks_column" label="Clicks" {...props} />
      <ColumnField
        name="adStats.impressions_column"
        label="Impressions"
        {...props}
      />
    </>
  );
};

export const AdStatsTimestamp: SchemaField = (props) => {
  return (
    <ColumnField
      name="adStats.timestamp_column"
      label="Timestamp"
      tip="Choose a column that represents when the stat was calculated."
      {...props}
    />
  );
};

export const AdStatsForm: SchemaFieldForm = ({
  object,
  hasUpdatePermission,
}) => {
  const { mutateAsync: updateAdStatsModel } = useUpdateAdStatsModelMutation();

  const initialAdStats = useMemo(() => {
    if (!object.ad_stats) {
      return null;
    }
    const { __typename, ...rest } = object.ad_stats || {};
    return rest;
  }, [object]);

  const form = useHightouchForm({
    submitOnChange: true,
    onSubmit: async (data) => {
      await updateAdStatsModel({
        id: object.id,
        set: data.adStats,
      });
    },
    values: {
      adStats: {
        ...initialAdStats,
      },
    },
  });

  return (
    <Form form={form}>
      <AdStatsTimestamp
        object={object}
        isDisabled={!hasUpdatePermission}
        isLoading={form.formState.isSubmitting}
      />

      <AssetIdField
        object={object}
        isDisabled={!hasUpdatePermission}
        isLoading={form.formState.isSubmitting}
      />

      <StandardColumnsFields
        object={object}
        isDisabled={!hasUpdatePermission}
        isLoading={form.formState.isSubmitting}
      />
    </Form>
  );
};
