import {
  type FormkitStateReference,
  type FormkitGraphQLReference,
  type FormkitContextReference,
  ReferenceType,
  type GraphQLVariables,
  type HandlerGraphQLVariables,
} from "../api";

export function StateReference(props: { key: string }): FormkitStateReference {
  return {
    type: ReferenceType.State,
    key: props.key,
  };
}

export function GraphQLReference<
  TGraphQLVariables extends GraphQLVariables = GraphQLVariables,
>(props: {
  document: string;
  variables?: TGraphQLVariables;
}): FormkitGraphQLReference<TGraphQLVariables> {
  return {
    type: ReferenceType.GraphQL,
    ...props,
  };
}

export function ContextReference(props: {
  key: string;
}): FormkitContextReference {
  return {
    type: ReferenceType.Context,
    ...props,
  };
}

export function HandlerReference({
  handler,
  variables,
}: {
  handler: string;
  variables?: GraphQLVariables;
}): FormkitGraphQLReference<HandlerGraphQLVariables> {
  return GraphQLReference({
    document: `
      query FormkitSyncHandler($input: FormkitSyncHandlerInput!) {
        formkitSyncHandler(input: $input)
      }
    `,
    variables: {
      input: {
        handler,
        variables,
        context: {
          destinationId: {
            type: ReferenceType.Context,
            key: "destination.id",
          },
          modelId: {
            type: ReferenceType.Context,
            key: "model.id",
          },
          syncId: {
            type: ReferenceType.Context,
            key: "sync.id",
          },
        },
      },
    },
  });
}

export function SourceHandlerReference({
  handler,
  variables,
}: {
  handler: string;
  variables?: GraphQLVariables;
}): FormkitGraphQLReference<HandlerGraphQLVariables> {
  return GraphQLReference({
    document: `
      query FormkitSourceHandler($input: FormkitSourceHandlerInput!) {
        formkitSourceHandler(input: $input)
      }
    `,
    variables: {
      input: {
        handler,
        variables,
        context: {
          type: {
            type: ReferenceType.Context,
            key: "sourceDefinition.type",
          },
          workspaceId: {
            type: ReferenceType.Context,
            key: "workspaceId",
          },
        },
      },
    },
  });
}

export function DestinationHandlerReference({
  handler,
  variables,
}: {
  handler: string;
  variables?: GraphQLVariables;
}): FormkitGraphQLReference<HandlerGraphQLVariables> {
  return GraphQLReference({
    document: `
      query FormkitDestinationHandler($input: FormkitSourceHandlerInput!) {
        formkitDestinationHandler(input: $input)
      }
    `,
    variables: {
      input: {
        handler,
        variables,
        context: {
          type: {
            type: ReferenceType.Context,
            key: "destinationDefinition.type",
          },
          workspaceId: {
            type: ReferenceType.Context,
            key: "workspaceId",
          },
        },
      },
    },
  });
}
