import { MonitorStatus } from "@hightouch/lib/resource-monitoring/types";
import {
  Box,
  Column,
  NumberInput,
  Text,
  ToggleButton,
  ToggleButtonGroup,
} from "@hightouchio/ui";
import { Controller, useFormContext } from "react-hook-form";
import { isEmpty, isNumber } from "lodash";

import { ResourceStatus } from "src/components/resource-alert-triggers/resource-status";
import { Term } from "src/components/term";
import { isPresent } from "src/types/utils";
import { FieldError } from "src/components/field-error";
import { cleanFormError } from "src/components/resource-alert-triggers/utils";

const copy = (isPercent: boolean) =>
  isPercent
    ? "The rejected row rate for a sync run is defined as [number of sync operations that are rejected during the run] divided by [number of sync operations that are attempted during the run]."
    : `A row is considered "rejected" when it cannot be synced to the destination during a sync run. Rejections can happen for many reasons, including destination API errors, invalid/corrupt data, or failed lookups.`;

export const SyncRejectedRowsField = ({ name }) => {
  const { setValue, getValues } = useFormContext();

  const rejectedRowCountPath = `${name}.rejectedRowCount`;
  const pctRejectedRowsPath = `${name}.pctRejectedRows`;

  const countValue = getValues(rejectedRowCountPath);
  const pctValue = getValues(pctRejectedRowsPath);

  const isPercent = Boolean(isPresent(pctValue) && isNumber(pctValue));
  const fieldName = isPercent ? pctRejectedRowsPath : rejectedRowCountPath;

  return (
    <Controller
      name={fieldName}
      render={({ field: { onChange }, fieldState: { error } }) => {
        return (
          <Column>
            <Box
              display="inline-block"
              sx={{
                "div[role=radiogroup]": {
                  label: { width: "16px", height: "24px", px: 3 },
                },
              }}
            >
              {isPercent ? "Higher than " : "More than "}
              <Box
                isInvalid={Boolean(error)}
                as={NumberInput}
                display="inline-block"
                size="sm"
                width="50px"
                value={isPercent ? pctValue : countValue}
                onChange={onChange}
              />{" "}
              <Box
                as={ToggleButtonGroup}
                display="inline-block"
                value={isPercent ? "percent" : "number"}
                onChange={(value: any) => {
                  if (value === "percent") {
                    setValue(
                      name,
                      { pctRejectedRows: 1 },
                      { shouldDirty: true },
                    );
                  } else {
                    setValue(
                      name,
                      { rejectedRowCount: 1 },
                      { shouldDirty: true },
                    );
                  }
                }}
              >
                <ToggleButton label="#" value="number" />
                <ToggleButton label="%" value="percent" />
              </Box>{" "}
              <Term message={copy(isPercent)}>
                {isPercent ? "rejected row rate" : "rejected rows"}
              </Term>{" "}
              during a sync run
            </Box>
            {error?.message ? (
              <FieldError error={cleanFormError(error?.message)} />
            ) : null}
          </Column>
        );
      }}
    />
  );
};

interface Props {
  condition: {
    error_value: { pctRejectedRows: number; rejectedRowCount: number };
    warning_value: { pctRejectedRows: number; rejectedRowCount: number };
  };
}

export const SyncRejectedRowsCondition = ({ condition }: Props) => {
  return [
    !isEmpty(condition.error_value) && (
      <Text>
        More than{" "}
        <Text fontWeight="semibold">
          {condition.error_value.rejectedRowCount}
        </Text>{" "}
        rejected rows or a rejected row rate higher than{" "}
        <Text fontWeight="semibold">
          {condition.error_value.pctRejectedRows}%{" "}
        </Text>{" "}
        during a sync run sets sync status to{" "}
        <ResourceStatus status={MonitorStatus.Unhealthy} />{" "}
      </Text>
    ),
    !isEmpty(condition.warning_value) && (
      <Text>
        More than{" "}
        <Text fontWeight="semibold">
          {condition.warning_value.rejectedRowCount}
        </Text>{" "}
        rejected rows or a rejected row rate higher than{" "}
        <Text fontWeight="semibold">
          {condition.warning_value.pctRejectedRows}%{" "}
        </Text>{" "}
        during a sync run sets sync status to{" "}
        <ResourceStatus status={MonitorStatus.Warning} />{" "}
      </Text>
    ),
  ].filter(isPresent);
};
