import { Column, Row, Skeleton, Text } from "@hightouchio/ui";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { useJourneySyncsQuery } from "src/graphql";
import { VISIBLE_SYNC_COUNT } from "src/pages/journeys/constants";
import { JourneyNodeDetails } from "src/pages/journeys/types";
import { SyncConfig } from "src/types/journeys";
import { SyncRunStatus, getSyncStatusColor } from "src/utils/syncs";

import { DescriptionRow } from "./description-row";
import { EmptyStateDescription } from "./empty-state-description";

export const SyncNodeDescription = ({
  sync_configs,
}: JourneyNodeDetails<SyncConfig>) => {
  const journeySyncsQuery = useJourneySyncsQuery(
    {
      ids:
        sync_configs?.map(({ destination_instance_id }) =>
          destination_instance_id.toString(),
        ) ?? [],
    },
    {
      enabled: Boolean(sync_configs && sync_configs.length > 0),
      select: (data) =>
        data.syncs.filter(
          (sync) => sync.status !== null && sync.destination !== null,
        ),
    },
  );

  if (
    !journeySyncsQuery.isLoading &&
    (!sync_configs || sync_configs.length === 0)
  ) {
    return <EmptyStateDescription />;
  } else if (!journeySyncsQuery.data) {
    return (
      <Skeleton isLoading>
        <Column gap={2}>
          {(sync_configs ?? [])
            .slice(0, VISIBLE_SYNC_COUNT)
            .map(({ destination_instance_id }) => (
              <Row
                key={destination_instance_id}
                as="span"
                height="30px"
                align="center"
                px={2}
                py={1.5}
                bg="base.background"
                borderRadius="md"
                overflowX="hidden"
              />
            ))}
        </Column>
      </Skeleton>
    );
  }

  const visibleSyncs = journeySyncsQuery.data.slice(0, VISIBLE_SYNC_COUNT);
  const invisibleSyncNumber =
    journeySyncsQuery.data.length - visibleSyncs.length;

  return (
    <Column gap={2}>
      {visibleSyncs.map(({ status, destination }, index) => {
        if (status === null || destination === null) return null;

        const statusColor = getSyncStatusColor(status as SyncRunStatus);

        return (
          <DescriptionRow key={`${destination?.id}-${index}`}>
            <Row
              align="center"
              justify="center"
              w="24px"
              h="24px"
              borderRadius="50%"
              flexShrink={0}
              bg="white"
              border="1px"
              borderColor={statusColor}
            >
              <IntegrationIcon
                name={destination.definition.name}
                src={destination.definition.icon}
                size={4}
              />
            </Row>

            <TextWithTooltip message={destination?.definition.name}>
              {destination?.definition.name}
            </TextWithTooltip>
          </DescriptionRow>
        );
      })}

      {invisibleSyncNumber > 0 && <Text>+ {invisibleSyncNumber} more</Text>}
    </Column>
  );
};
