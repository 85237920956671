import { FC, useEffect } from "react";

import {
  useClearRefinements,
  useCurrentRefinements,
  useInstantSearch,
  useMenu,
} from "react-instantsearch-hooks-web";
import { Row, Column, Box } from "@hightouchio/ui";

import { DestinationDefinitionFragment as DestinationDefinition } from "src/graphql";
import * as analytics from "src/lib/analytics";
import { PageSpinner } from "src/components/loading";
import { Placeholder } from "src/ui/table/placeholder";
import { AlgoliaSearchInput } from "src/components/search/algolia-search-input";

import { CategoryMenu } from "./category-menu";
import { DestinationDetails } from "./destination-details";
import { DestinationHits } from "./destination-hits";

type DestinationsCatalogProps = {
  definitions: DestinationDefinition[];
  error: Error | boolean | null;
  selection: DestinationDefinition | undefined;
  onSelect: (definition: DestinationDefinition, hit: any) => void;
  onClear: () => void;
};

export const DestinationsCatalog: FC<Readonly<DestinationsCatalogProps>> = ({
  definitions,
  error,
  selection,
  onSelect,
  onClear,
}) => {
  const {
    results,
    currentCategory,
    menuApi,
    isSearchReady,
    currentRefinements,
    clearRefinements,
  } = useDestinationCatalogSearch();

  useEffect(() => {
    analytics.track("Destination Catalog Page Viewed");
  }, []);

  // scroll results to top when a category filter occurs
  useEffect(() => {
    if (selection) {
      onClear();
    }
    if (results?.query.length < 2 && window.scrollY > 0) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [currentRefinements?.[0]?.refinements?.[0], results?.query]);

  if (error || definitions?.length === 0) {
    return (
      <Placeholder
        content={{
          title: "No destinations",
          error: "Destinations failed to load, please try again.",
        }}
        error={Boolean(error)}
      />
    );
  }

  if (!isSearchReady) {
    return <PageSpinner />;
  }

  return (
    <Row gap={8} flex={1}>
      <Box
        position="sticky"
        top="calc(144px + 32px)"
        height="calc(100vh - 144px - 124px)"
        overflow="auto"
        width="180px"
      >
        <CategoryMenu
          key={results?.queryID ?? "key"}
          clear={clearRefinements}
          currentCategory={currentCategory}
          items={menuApi.items}
          query={results?.query ?? ""}
          refine={menuApi.refine}
        />
      </Box>

      <Column
        flex={1}
        minWidth={selection ? "436px" : "inherit"}
        gap={4}
        mt="-40px"
        justify="flex-start"
      >
        <Row
          position="sticky"
          top="144px"
          zIndex={2}
          bg="white"
          align="flex-end"
          height="72px"
        >
          <AlgoliaSearchInput placeholder="Search destinations..." />
        </Row>
        <DestinationHits
          category={currentCategory}
          destinations={definitions}
          selection={selection}
          onSelect={onSelect}
        />
      </Column>

      {selection && (
        <Column
          display={["none", "none", "none", "flex"]}
          maxWidth="300px"
          position="sticky"
          top="calc(144px + 32px)"
          height="300px"
        >
          <DestinationDetails
            category={currentCategory}
            definition={selection}
            onClear={onClear}
          />
        </Column>
      )}
    </Row>
  );
};

export const useDestinationCatalogSearch = () => {
  const { results, indexUiState } = useInstantSearch();
  const { items: currentRefinements } = useCurrentRefinements();
  const menuApi = useMenu({
    attribute: "categories.name",
    limit: 30,
    sortBy: ["name:asc"],
  });
  const { refine: clearRefinements } = useClearRefinements();
  const currentCategory =
    currentRefinements?.[0]?.refinements?.[0]?.label ?? "All";

  useEffect(() => {
    if (results?.query.length === 1) {
      analytics.track("Destination Catalog Search Performed", {
        current_category: currentCategory,
      });

      // reset category to "all" when beginning a search
      clearRefinements();
    }
  }, [results?.query.length]);

  return {
    results,
    menuApi,
    clearRefinements,
    currentRefinements,
    currentCategory,
    isSearchReady: Boolean(indexUiState?.configure),
  };
};
