import { FC, useEffect, useMemo, useState } from "react";

import {
  Button,
  ButtonGroup,
  Column,
  Dialog,
  FormField,
  Heading,
  Row,
  Select,
  TextInput,
  Textarea,
} from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFlags } from "launchdarkly-react-client-sdk";
import lodashOrderBy from "lodash/orderBy";
import { Controller } from "react-hook-form";
import { Outlet, useNavigate } from "src/router";

import { Form, SaveButton, useHightouchForm } from "src/components/form";
import { Page } from "src/components/layout";
import { PermissionedButton } from "src/components/permission";
import {
  useCreateJourneyMutation,
  useParentModelsWithIconsQuery,
} from "src/graphql";
import { JourneyStatus } from "src/types/journeys";

import {
  DEFAULT_INTERVAL_SCHEDULE,
  JOURNEY_UPDATE_PERMISSION,
  JourneyFilterType,
} from "./constants";
import { CreateJourneySchema } from "./forms";
import { CreateJourneyForm } from "./types";
import { RouteTabs } from "src/components/route-tabs";

const TABS = [
  {
    title: JourneyFilterType.ALL,
    path: "all",
  },
  {
    title: JourneyFilterType.DRAFTS,
    path: "drafts",
  },
  {
    title: JourneyFilterType.LIVE,
    path: "live",
  },
  {
    title: JourneyFilterType.OFF,
    path: "off",
  },
];

export const JourneysLayout: FC = () => {
  const { appEnableJourneys } = useFlags();
  const navigate = useNavigate();
  const [isCreating, setIsCreating] = useState(false);

  const createJourneyMutation = useCreateJourneyMutation();

  const parentModelsQuery = useParentModelsWithIconsQuery(undefined, {
    select: (data) => data.segments,
  });

  const parentModelOptions = useMemo(() => {
    return lodashOrderBy(
      parentModelsQuery.data,
      ["connection.plan_in_warehouse", "name"],
      ["desc", "asc"],
    );
  }, [parentModelsQuery.data]);

  const createJourney = async (data: CreateJourneyForm) => {
    const result = await createJourneyMutation.mutateAsync({
      input: {
        name: data.name,
        description: data.description ? data.description : null,
        parent_model_id: data.parentModelId?.toString(),
        status: JourneyStatus.Disabled,
        is_latest: true,
        schedule: DEFAULT_INTERVAL_SCHEDULE,
      },
    });

    if (result.insert_journeys_one) {
      navigate(`${result.insert_journeys_one.id}`);
    }
  };

  const form = useHightouchForm<CreateJourneyForm>({
    onSubmit: createJourney,
    resolver: yupResolver(CreateJourneySchema),
    values: {
      name: "",
      description: null,
      parentModelId: null,
    },
  });

  const closeModal = () => {
    setIsCreating(false);
    form.reset();
  };

  // If there's only one parent model option, pre-select it
  useEffect(() => {
    if (parentModelOptions.length === 1) {
      form.reset({
        name: "",
        description: null,
        parentModelId: parentModelOptions[0]!.id,
      });
    }
  }, [parentModelOptions]);

  useEffect(() => {
    if (!appEnableJourneys) {
      navigate("/home");
    }
  }, [appEnableJourneys]);

  return (
    <Page title="Journeys">
      <Form form={form}>
        <Column mb={6}>
          <Row justify="space-between">
            <Heading size="xl">Journeys</Heading>
            <PermissionedButton
              variant="primary"
              permission={JOURNEY_UPDATE_PERMISSION}
              onClick={() => setIsCreating(true)}
            >
              Add journey
            </PermissionedButton>
          </Row>
        </Column>

        <RouteTabs tabs={TABS} />

        <Column pt={6} height="100%">
          <Outlet />
        </Column>

        <Dialog
          isOpen={isCreating}
          title="New journey"
          variant="form"
          onClose={closeModal}
          actions={
            <ButtonGroup>
              <Button onClick={closeModal}>Cancel</Button>
              <SaveButton size="md">Create journey</SaveButton>
            </ButtonGroup>
          }
        >
          <Column gap={4}>
            <Controller
              control={form.control}
              name="parentModelId"
              render={({ field, fieldState }) => (
                <FormField
                  label="Select a parent model"
                  error={fieldState.error?.message}
                >
                  <Select
                    isInvalid={Boolean(fieldState.error?.message)}
                    isOptionDisabled={(option) =>
                      !option.connection?.plan_in_warehouse
                    }
                    isLoading={parentModelsQuery.isLoading}
                    placeholder="Select a parent model"
                    optionLabel={(option) => option.name}
                    optionValue={(option) => option.id}
                    optionDescription={(option) =>
                      option.connection?.plan_in_warehouse
                        ? ""
                        : "Lightning sync engine must be enabled to use this parent model in journeys"
                    }
                    optionAccessory={(option) => ({
                      type: "image",
                      url: option.connection?.definition.icon ?? "",
                    })}
                    options={parentModelOptions}
                    width="100%"
                    value={field.value}
                    onChange={field.onChange}
                  />
                </FormField>
              )}
            />
            <Controller
              control={form.control}
              name="name"
              render={({ field, fieldState }) => (
                <FormField
                  label="Journey name"
                  error={fieldState.error?.message}
                >
                  <TextInput
                    placeholder="Enter a name..."
                    width="100%"
                    value={field.value}
                    onChange={field.onChange}
                  />
                </FormField>
              )}
            />
            <Controller
              control={form.control}
              name="description"
              render={({ field, fieldState }) => (
                <FormField
                  isOptional
                  label="Journey description"
                  error={fieldState.error?.message}
                >
                  <Textarea
                    placeholder="Enter a description..."
                    width="100%"
                    value={field.value ?? ""}
                    onChange={field.onChange}
                  />
                </FormField>
              )}
            />
          </Column>
        </Dialog>
      </Form>
    </Page>
  );
};
