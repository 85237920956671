import { FC } from "react";

import {
  Button,
  ButtonGroup,
  Dialog,
  FormField,
  TextInput,
  useToast,
} from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { Navigate, useNavigate, useOutletContext, useParams } from "src/router";
import { object, string } from "yup";

import { useUpdateTunnelMutation } from "src/graphql";

import { TunnelsOutletContext } from ".";

const validationSchema = object().shape({
  name: string().required("A name is required."),
});

export const EditTunnelForm: FC = () => {
  const { tunnels, reverseTunnels } = useOutletContext<TunnelsOutletContext>();
  const params = useParams<{ id: string }>();
  const { toast } = useToast();
  const { mutateAsync: updateTunnel } = useUpdateTunnelMutation();
  const navigate = useNavigate();

  const tunnel = [...tunnels, ...reverseTunnels].find(
    (tunnel) => tunnel.tunnel.id == params.id,
  )?.tunnel;

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: tunnel?.name ?? "",
    },
  });

  const close = () => {
    navigate("/settings/tunnels");
  };

  const submit = async ({ name }) => {
    try {
      await updateTunnel({
        id: tunnel?.id ?? "",
        input: { name, updated_at: "now()" },
      });
      close();

      toast({
        id: "edit-tunnel",
        title: "Tunnel updated",
        variant: "success",
      });
    } catch (error: unknown) {
      toast({
        id: "edit-tunnel",
        title: "Something went wrong",
        message: "Failed to update the tunnel, please try again.",
        variant: "error",
      });
    }
  };

  if (!tunnel) {
    return <Navigate to="/settings/tunnels" />;
  }

  return (
    <Dialog
      isOpen
      variant="form"
      title="Edit tunnel"
      actions={
        <ButtonGroup>
          <Button onClick={close}>Cancel</Button>
          <Button
            isLoading={isSubmitting}
            variant="primary"
            onClick={handleSubmit(submit)}
          >
            Save
          </Button>
        </ButtonGroup>
      }
      onClose={close}
    >
      <Controller
        control={control}
        name="name"
        render={({ field }) => (
          <FormField
            error={errors.name ? String(errors.name.message) : undefined}
            label="Name"
          >
            <TextInput
              isInvalid={Boolean(errors.name)}
              value={field.value}
              onChange={field.onChange}
            />
          </FormField>
        )}
      />
    </Dialog>
  );
};
