import {
  Button,
  ButtonGroup,
  CloseIcon,
  Column,
  FormField,
  Heading,
  IconButton,
  Row,
  TextInput,
  Textarea,
} from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { FC } from "react";
import { Controller } from "react-hook-form";
import { useNavigate, useOutletContext } from "src/router";
import { Form, useHightouchForm } from "src/components/form";
import { useCreateSubsetCategoryMutation } from "src/graphql";
import * as Yup from "yup";
import { OutletContext } from "../../layout";

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  description: Yup.string().nullable(),
});

type SubsetGroupData = {
  name: string;
  description: string;
};

export const CreateCategory: FC = () => {
  const navigate = useNavigate();

  const createSubsetCategory = useCreateSubsetCategoryMutation();

  const { parentModel } = useOutletContext<OutletContext>();

  const form = useHightouchForm<SubsetGroupData>({
    resolver: yupResolver(validationSchema),
    onSubmit: async (data) => {
      const ret = await createSubsetCategory.mutateAsync({
        input: {
          name: data.name,
          description: data.description,
          parent_model_id: parentModel.id,
        },
      });

      navigate({
        pathname: `../${ret.insert_audience_subset_groups_one?.id}`,
        search: window.location.search,
      });
    },
  });

  const {
    control,
    formState: { isSubmitting },
    submit,
  } = form;

  const onClose = () => {
    navigate({
      pathname: "../",
      search: window.location.search,
    });
  };

  return (
    <>
      <Column alignContent="space-between" height="100%">
        <Row
          align="center"
          px={6}
          py={4}
          borderBottom="1px solid"
          borderColor="base.border"
          justifyContent="space-between"
        >
          <Heading size="md">New subset category</Heading>
          <IconButton aria-label="Cancel" icon={CloseIcon} onClick={onClose} />
        </Row>

        <Column gap={4} height="100%" width="50%" p={6}>
          <Form form={form}>
            <Controller
              control={control}
              name="name"
              render={({ field, fieldState }) => (
                <FormField
                  label="Name"
                  description="Choose a name that explains the type of subsets contained in this category (e.g. “Region” or “Consent”)"
                  error={fieldState.error?.message}
                >
                  <TextInput placeholder="Enter a name..." {...field} />
                </FormField>
              )}
            />

            <Controller
              control={control}
              name="description"
              render={({ field, fieldState }) => (
                <FormField
                  label="Description"
                  isOptional
                  error={fieldState.error?.message}
                >
                  <Textarea placeholder="Enter a description..." {...field} />
                </FormField>
              )}
            />
          </Form>
        </Column>
      </Column>

      <Column
        borderTop="1px solid"
        borderColor="base.border"
        px={6}
        py={4}
        mt={4}
        position="sticky"
        bottom={0}
        background="white"
      >
        <ButtonGroup>
          <Button
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
            onClick={submit}
            size="lg"
            variant="primary"
          >
            Create subset category
          </Button>
          <Button isDisabled={isSubmitting} size="lg" onClick={onClose}>
            Cancel
          </Button>
        </ButtonGroup>
      </Column>
    </>
  );
};
