import { FC, useMemo } from "react";

import {
  ColumnType,
  TraitConfig,
  TraitType,
  isAggregationTraitConfig,
  isCountDedupedTraitConfig,
  isOrderDedupedTraitConfig,
} from "@hightouch/lib/query/visual/types";
import { Column, Combobox, FormField, Text, TextInput } from "@hightouchio/ui";
import { Controller, useFormContext } from "react-hook-form";
import * as Yup from "yup";

import { QueryBuilderProvider } from "src/components/explore/context/query-builder-context";
import { ConditionField } from "src/components/explore/visual/condition";
import { SqlEditor } from "src/components/sql-editor";
import {
  RAW_SQL_COMMON_RES_TYPES,
  RAW_SQL_JSON_ARR_RES_TYPES,
  TRAIT_TYPE_OPTIONS,
} from "src/components/traits/utils";
import { Audience } from "src/types/visual";

import { SUPPORTED_JSON_ARRAY_SOURCES } from "../utils";
import { FilteredEvent } from "./utils";
import { ParentModel } from "src/pages/audiences/types";

export type Props = {
  audience: NonNullable<Audience>;
  parentModel: NonNullable<ParentModel>;
};

export type EventTraitFormProps = {
  alias: string;
  traitType: TraitType;
  traitConfig: TraitConfig;
  filteredEvent: FilteredEvent;
};

export const eventTraitValidationSchema = Yup.object()
  .shape({
    alias: Yup.string().required("Name is required"),
    traitType: Yup.string()
      .oneOf(Object.values(TraitType))
      .required("Trait calculation method is required"),
    traitConfig: Yup.object().required(),
    filteredEvent: Yup.object().required(),
  })
  .required();

export const EventTraitForm: FC<Readonly<Props>> = ({
  audience,
  parentModel,
}) => {
  const { control, watch, getValues, reset } = useFormContext();

  const traitType = watch("traitType");
  const traitConfig = watch("traitConfig");
  const filteredEvent = watch("filteredEvent");

  const handleChangeTraitType = (traitType: TraitType | undefined) => {
    if (!traitType) return;

    reset({
      ...getValues(),
      traitType,
      traitConfig: {},
    });
  };

  const relationshipId = filteredEvent.relationshipId;
  const relationships = parentModel.relationships;

  const events = relationships?.filter(({ to_model: { event } }) =>
    Boolean(event),
  );

  const columns = useMemo(() => {
    return relationships?.find(({ id }) => id === relationshipId)?.to_model
      ?.filterable_audience_columns;
  }, [relationships, relationshipId]);

  const columnOptions = useMemo(() => {
    if (columns) {
      const validColumns =
        traitType === TraitType.Sum || traitType === TraitType.Average
          ? columns.filter(
              (column) =>
                column.type === ColumnType.Number ||
                column.custom_type === ColumnType.Number,
            )
          : columns;
      return validColumns.map(({ alias, name, column_reference }) => ({
        label: alias || name,
        value: column_reference,
      }));
    }
    return [];
  }, [columns, traitType]);

  const rawSqlResultingType = [
    ...RAW_SQL_COMMON_RES_TYPES,
    ...(SUPPORTED_JSON_ARRAY_SOURCES.includes(
      parentModel?.connection?.type ?? "",
    )
      ? RAW_SQL_JSON_ARR_RES_TYPES
      : []),
  ];

  return (
    <>
      <Column>
        <Text fontWeight="medium">Sync a trait value to a destination</Text>
        <Text>
          An audience trait enrichment allows you to craft a trait value and
          sync it scoped specifically to this audience only.
        </Text>
      </Column>

      <Column gap={6}>
        <Controller
          control={control}
          name="alias"
          render={({ field, fieldState }) => (
            <FormField label="Name" error={fieldState.error?.message}>
              <TextInput placeholder="Enter a name" width="100%" {...field} />
            </FormField>
          )}
        />

        <FormField label="Event">
          <Column
            sx={{
              p: 4,
              fontWeight: "bold",
              fontSize: 0,
              bg: "gray.100",
              borderRadius: "6px",
              "& > :not(:last-child)": { mb: 4 },
            }}
          >
            <Controller
              control={control}
              name="filteredEvent"
              render={({ field }) => (
                <QueryBuilderProvider
                  audience={audience}
                  parentModel={parentModel}
                >
                  <ConditionField
                    isEventTrait
                    audience={undefined}
                    columns={[]}
                    condition={field.value}
                    events={events}
                    parent={parentModel}
                    relationships={[]}
                    traits={undefined}
                    onChange={(value) => {
                      field.onChange({ ...filteredEvent, ...value });
                    }}
                    onRemove={() => {}}
                  />
                </QueryBuilderProvider>
              )}
            />
          </Column>
        </FormField>

        <Controller
          control={control}
          name="traitType"
          render={({ field, fieldState }) => (
            <FormField
              label="Calculation method"
              error={fieldState.error?.message}
            >
              <Combobox
                options={TRAIT_TYPE_OPTIONS}
                placeholder="Select a calculation method"
                width="100%"
                {...field}
                onChange={handleChangeTraitType}
              />
            </FormField>
          )}
        />

        {/* Average, Count, Sum */}
        {isAggregationTraitConfig(traitType, traitConfig) && (
          <Controller
            control={control}
            name="traitConfig.column"
            render={({ field, fieldState }) => (
              <FormField
                label={traitType === TraitType.Count ? "Count by" : "Sum by"}
                error={fieldState.error?.message}
              >
                <Combobox
                  isDisabled={!relationshipId}
                  options={columnOptions}
                  placeholder="Select a column"
                  width="100%"
                  {...field}
                />
              </FormField>
            )}
          />
        )}

        {/* Least Frequent, Most Frequent */}
        {isCountDedupedTraitConfig(traitType, traitConfig) && (
          <>
            <Controller
              control={control}
              name="traitConfig.toSelect"
              render={({ field, fieldState }) => (
                <FormField
                  label="Trait value"
                  tip="The column that represents the value"
                  error={fieldState.error?.message}
                >
                  <Combobox
                    isDisabled={!relationshipId}
                    options={columnOptions}
                    placeholder="Select a column"
                    width="100%"
                    {...field}
                  />
                </FormField>
              )}
            />

            <Controller
              control={control}
              name="traitConfig.orderBy"
              render={({ field, fieldState }) => (
                <FormField
                  label="Order by"
                  tip="Rows will be ordered according to this column"
                  error={fieldState.error?.message}
                >
                  <Combobox
                    isDisabled={!relationshipId}
                    options={columnOptions}
                    placeholder="Select a column"
                    width="100%"
                    {...field}
                  />
                </FormField>
              )}
            />
          </>
        )}

        {/* First, Last */}
        {isOrderDedupedTraitConfig(traitType, traitConfig) && (
          <>
            <Controller
              control={control}
              name="traitConfig.toSelect"
              render={({ field, fieldState }) => (
                <FormField
                  label="Trait value"
                  tip="The column that represents the value"
                  error={fieldState.error?.message}
                >
                  <Combobox
                    isDisabled={!relationshipId}
                    options={columnOptions}
                    placeholder="Select a column"
                    width="100%"
                    {...field}
                  />
                </FormField>
              )}
            />

            <Controller
              control={control}
              name="traitConfig.orderBy"
              render={({ field, fieldState }) => (
                <FormField
                  label="Order by"
                  tip="Rows will be ordered according to this column"
                  error={fieldState.error?.message}
                >
                  <Combobox
                    isDisabled={!relationshipId}
                    options={columnOptions}
                    placeholder="Select a column"
                    width="100%"
                    {...field}
                  />
                </FormField>
              )}
            />
          </>
        )}

        {traitType === TraitType.RawSql && (
          <>
            <Controller
              control={control}
              name="traitConfig.aggregation"
              render={({ field, fieldState }) => (
                <FormField
                  label="SQL"
                  description="Rows will be aggregated according to a custom SQL aggregation."
                  error={fieldState.error?.message}
                >
                  <SqlEditor
                    placeholder={
                      "CASE WHEN SUM({{column \"price\"}}) > 100 THEN 'high' ELSE 'low' END`"
                    }
                    source={parentModel?.connection}
                    {...field}
                    readOnly={!relationshipId}
                  />
                </FormField>
              )}
            />

            <Controller
              control={control}
              name="traitConfig.defaultValue"
              render={({ field, fieldState }) => (
                <FormField
                  label="Default value"
                  tip="Defines the value when the above SQL returns no rows. String values should be surrounded with quotes ('')."
                  error={fieldState.error?.message}
                >
                  <TextInput
                    isDisabled={!relationshipId}
                    placeholder="Enter the default value"
                    width="100%"
                    {...field}
                  />
                </FormField>
              )}
            />
            <Controller
              control={control}
              name="traitConfig.resultingType"
              render={({ field, fieldState }) => (
                <FormField
                  label="Property type"
                  tip="Defines the type of resulting value"
                  error={fieldState.error?.message}
                >
                  <Combobox
                    isDisabled={!relationshipId}
                    options={rawSqlResultingType}
                    placeholder="Select a column type"
                    width="100%"
                    {...field}
                  />
                </FormField>
              )}
            />
          </>
        )}
      </Column>
    </>
  );
};
