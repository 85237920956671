import { FC } from "react";

import { Box, Column, Text, Heading, WarningIcon } from "@hightouchio/ui";
import Helmet from "react-helmet";

import { AuthLayout } from "src/components/auth/auth-layout";
import { Card } from "src/components/card";

export const Invalid: FC = () => {
  return (
    <>
      <Helmet>
        <title>Invalid invitation</title>
      </Helmet>

      <AuthLayout>
        <Card maxWidth="400px" mt={24}>
          <Column align="center" gap={8} sx={{ textAlign: "center" }}>
            <Box fontSize="64px">
              <WarningIcon color="warning.base" />
            </Box>
            <Heading size="xl">Invalid invitation</Heading>
            <Text>
              Check that your account email is the same as the invite recipient
              or contact your workspace administrator.
            </Text>
          </Column>
        </Card>
      </AuthLayout>
    </>
  );
};
