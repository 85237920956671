import { ColumnType } from "src/types/visual";
import {
  BigIntegerIcon,
  MeetingIcon,
  NumberIcon,
  TextStringIcon,
  TimeIcon,
} from "@hightouchio/ui";

// Represents the supported casts for each source type that should be available in the UI.
// TODO: expand support for casting as backend support increases
const SUPPORTED_COLUMN_CASTS = {
  snowflake: {
    [ColumnType.String]: [
      { label: "String", value: ColumnType.String, icon: TextStringIcon },
      { label: "Number", value: ColumnType.Number, icon: NumberIcon },
      { label: "Timestamp", value: ColumnType.Timestamp, icon: TimeIcon },
    ],
    [ColumnType.Timestamp]: [
      { label: "Timestamp", value: ColumnType.Timestamp, icon: TimeIcon },
      { label: "Date", value: ColumnType.Date, icon: MeetingIcon },
    ],
  },
  databricks: {
    [ColumnType.String]: [
      { label: "String", value: ColumnType.String, icon: TextStringIcon },
      { label: "Number", value: ColumnType.Number, icon: NumberIcon },
      { label: "Big Integer", value: ColumnType.BigInt, icon: BigIntegerIcon },
    ],
    [ColumnType.Number]: [
      { label: "Number", value: ColumnType.Number, icon: NumberIcon },
      { label: "Big Integer", value: ColumnType.BigInt, icon: BigIntegerIcon },
      { label: "String", value: ColumnType.String, icon: TextStringIcon },
    ],
    [ColumnType.BigInt]: [
      { label: "Big Integer", value: ColumnType.BigInt, icon: BigIntegerIcon },
      { label: "Number", value: ColumnType.Number, icon: NumberIcon },
      { label: "String", value: ColumnType.String, icon: TextStringIcon },
    ],
  },
  redshift: {
    [ColumnType.String]: [
      { label: "String", value: ColumnType.String, icon: TextStringIcon },
      { label: "Number", value: ColumnType.Number, icon: NumberIcon },
    ],
    [ColumnType.Timestamp]: [
      { label: "Timestamp", value: ColumnType.Timestamp, icon: TimeIcon },
      { label: "Date", value: ColumnType.Date, icon: MeetingIcon },
    ],
  },
};

/**
 * @param inferredType  The type that our backend's type detection system inferred. Comes from the `type` field on `model_columns`.
 * @param sourceType  Type of the source.
 * @returns  An array of options to cast to, or undefined to use the defaults.
 */
export const getColumnTypeCastOptions = (
  inferredType: ColumnType,
  opts: {
    sourceType?: string;
    allowBigints?: boolean;
  },
):
  | {
      label: string;
      value: ColumnType;
      // This is used in HightouchUI Select, which is generic. It should be a
      // HightouchUI icon.
      icon: any;
    }[]
  | undefined => {
  if (opts.sourceType && opts.sourceType in SUPPORTED_COLUMN_CASTS) {
    const supportedCasts = SUPPORTED_COLUMN_CASTS[opts.sourceType];
    if (inferredType in supportedCasts) {
      let casts = supportedCasts[inferredType];
      // Don't show bigint cast options if that column type isn't feature-flagged.
      if (!opts.allowBigints) {
        casts = casts.filter((cast) => cast.value !== ColumnType.BigInt);
      }
      return casts;
    }
  }

  return undefined;
};
