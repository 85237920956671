import { FC } from "react";

import { Box, Badge, BoxProps } from "@hightouchio/ui";
import { Link } from "src/router";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import placeholderSource from "src/components/permission/source.svg";
import { SourceQuery, useSourceObjectDefinitionsQuery } from "src/graphql";
import { useSchemaDiscovery } from "./use-schema-discovery";

export type SourceConfig = Record<string, unknown>;

export type SourceTunnel = NonNullable<
  NonNullable<SourceQuery["connections_by_pk"]>["tunnel"]
>;

// The logic for this is that if the search term doesn't contain a period,
// we want to use it to search both the defintions and groups (matching either).
// If it does contain a period, we want to split it and search the group and definition, matching both.
const parseSearch = (
  search?: string,
): { searchBoth?: string; searchGroup?: string; searchDefinition?: string } => {
  if (!search) {
    return {};
  }

  if (search.includes(".")) {
    const [searchGroup, ...rest] = search.split(".");
    const searchDefinition = rest.join(".");

    return {
      searchGroup: searchGroup ? `%${searchGroup}` : undefined,
      searchDefinition: searchDefinition ? `${searchDefinition}%` : undefined,
    };
  }

  return { searchBoth: `%${search}%` };
};

export const useObjectDefinitions = (
  sourceId: string | undefined,
  enable = true,
  search?: string,
) => {
  const {
    data: objectDefinitionsData,
    isFetching: objectDefinitionsLoading,
    error: objectDefinitionsError,
    refetch: refetchObjectDefinitions,
  } = useSourceObjectDefinitionsQuery(
    { sourceId: sourceId ?? "", ...parseSearch(search) },
    { enabled: Boolean(sourceId) && enable },
  );

  const { discovering, runSourceDiscovery, discoverError } = useSchemaDiscovery(
    {
      connectionId: sourceId ?? "",
    },
  );

  const objectDefinitions = objectDefinitionsData?.object_definitions;

  const objectDefinitionOptions =
    objectDefinitions?.map(({ name, object_definition_group }) => ({
      label: `${object_definition_group?.name}.${name}`,
      value: `${object_definition_group?.name}.${name}`,
    })) || [];

  const refetch = async () => {
    try {
      sourceId && (await runSourceDiscovery());
      refetchObjectDefinitions();
    } catch (error) {
      console.log(error);
    }
  };

  return {
    refetch,
    objectDefinitions,
    objectDefinitionOptions,
    loading: discovering || objectDefinitionsLoading,
    error: (discoverError || objectDefinitionsError)?.message,
  };
};

type SourceTileProps = {
  source?: {
    name: string;
    id: string;
    definition: {
      name: string;
      icon: string;
      isSampleDataSource: boolean;
    };
  };
  placeholder?: boolean;
};

export const SourceTile: FC<Readonly<SourceTileProps>> = ({
  source,
  placeholder,
}) => {
  const name = !source && placeholder ? "Private source" : source?.name;

  return (
    <>
      <IntegrationIcon
        name={source?.definition?.name}
        src={source?.definition?.icon}
      />
      <Box
        sx={{
          fontWeight: "semi",
          fontSize: "lg",
          ml: 2,
          a: { color: "inherit", fontSize: "inherit", fontWeight: "inherit" },
        }}
      >
        {source?.id ? (
          <Link href={`/sources/${source?.id}`}>{name}</Link>
        ) : (
          name
        )}
      </Box>

      <SourceBadges
        isSampleDataSource={source?.definition.isSampleDataSource}
      />
    </>
  );
};

export function SourceIcon({
  placeholder,
  source,
  sx = {},
}: Readonly<{
  placeholder?: boolean;
  source:
    | {
        definition: {
          name: string;
          icon: string;
        };
      }
    | undefined
    | null;
  sx: BoxProps["sx"];
}>): JSX.Element | null {
  const alt =
    placeholder && !source ? "Private source" : source?.definition?.name;
  const src =
    placeholder && !source ? placeholderSource : source?.definition?.icon;
  return (
    <Box as="img" alt={alt} src={src} sx={{ ...sx, objectFit: "contain" }} />
  );
}

export function SourceBadges({
  isSampleDataSource,
}: Readonly<{
  isSampleDataSource: boolean | undefined;
  sx?: BoxProps["sx"];
}>): JSX.Element | null {
  return isSampleDataSource ? (
    <Badge ml={2} variant="warning">
      Sample
    </Badge>
  ) : null;
}
