import { groupBy } from "lodash";

import { Folder } from "src/components/folders/types";
import { ListFoldersOutput } from "src/graphql";

export const nestFolders = (folders: ListFoldersOutput["folders"]) => {
  const foldersByParent = groupBy(folders, "parent_id");
  const rootFolders = foldersByParent["null"] || [];

  const nest = (
    folder: ListFoldersOutput["folders"][0],
    depth: number,
    parentPath: string,
  ): Folder => {
    const children =
      foldersByParent[folder.id]?.sort((a, b) => (a.name > b.name ? 1 : -1)) ||
      [];
    const path = parentPath ? `${parentPath}/${folder.name}` : folder.name;

    return {
      id: folder.id,
      name: folder.name,
      count: folder.count,
      depth,
      path,
      type: folder.type,
      children: children.map((child) => nest(child, depth + 1, path)),
      parentId: folder.parent_id,
    };
  };

  const sumChildren = (folder: Folder) => {
    let count = folder.count || 0;
    for (const child of folder.children) {
      sumChildren(child);
      count += child.count || 0;
    }
    folder.count = count;
    return count;
  };

  const nested = rootFolders
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .map((folder) => nest(folder, 1, ""));
  nested.forEach((folder) => sumChildren(folder));
  return nested;
};

export const flattenFolders = (folders: Folder[]): Folder[] => {
  return folders
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .reduce((acc, folder) => {
      return [...acc, folder, ...flattenFolders(folder.children)];
    }, [] as Folder[]);
};

export function isChild(folder: Folder, maybeChild: Folder): boolean {
  return (
    maybeChild.id === folder.id ||
    maybeChild.parentId === folder.id ||
    folder.children.some((f) => isChild(f, maybeChild))
  );
}
