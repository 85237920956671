import { FC, useMemo, useState } from "react";

import { Row, Text } from "@hightouchio/ui";
import { isEmpty } from "lodash";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { Labels } from "src/components/labels/labels";
import { ResourceSelect } from "src/components/resource-select";
import {
  DestinationsOrderBy,
  DestinationsBoolExp,
  DestinationQuery,
  useDestinationsQuery,
} from "src/graphql";
import { TableColumn, useTableConfig } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";

enum SortKeys {
  Name = "name",
  Type = "type",
  UpdatedAt = "updated_at",
}

export type Destination = DestinationQuery["destinations_by_pk"];

interface Props {
  onSelect: (Destination) => void;
  allowedDestinations?: string[] | null;
  loading?: boolean;
  isOptionAvailable?: (id: string) => boolean;
}

export const DestinationSelect: FC<Readonly<Props>> = ({
  allowedDestinations,
  onSelect,
  loading,
  isOptionAvailable,
}) => {
  const [search, setSearch] = useState("");

  const { onSort, orderBy } = useTableConfig<DestinationsOrderBy>({
    defaultSortKey: "updated_at",
    sortOptions: Object.values(SortKeys),
  });

  const hasuraFilters: DestinationsBoolExp = useMemo(() => {
    if (search) {
      return {
        _or: [
          { name: { _ilike: `%${search}%` } },
          { type: { _ilike: `%${search}%` } },
        ],
      };
    }
    return {};
  }, [search]);

  const {
    data: destinations,
    isLoading: loadingDestinations,
    error,
    isRefetching,
  } = useDestinationsQuery(
    {
      filters: hasuraFilters,
      orderBy,
    },
    { select: (data) => data.destinations },
  );

  const filteredDestinations = useMemo(() => {
    if (loading) {
      return undefined;
    }
    if (allowedDestinations) {
      return destinations?.filter(({ type }) => {
        return allowedDestinations.includes(type);
      });
    }
    return destinations;
  }, [loading, destinations, allowedDestinations]);

  const columns = useMemo(
    (): TableColumn[] => [
      {
        name: "Name",
        sortDirection: orderBy?.name,
        onClick: () => onSort(SortKeys.Name),
        cell: ({ name, definition }) => {
          return (
            <Text isTruncated fontWeight="medium">
              {name ?? definition?.name ?? "Private destination"}
            </Text>
          );
        },
      },
      {
        name: "Type",
        sortDirection: orderBy?.type,
        onClick: () => onSort(SortKeys.Type),
        max: "max-content",
        cell: ({ definition }) => {
          return (
            <Row align="center" gap={2} overflow="hidden">
              <IntegrationIcon name={definition?.name} src={definition?.icon} />
              <Text isTruncated fontWeight="medium">
                {definition?.name ?? "Private destination"}
              </Text>
            </Row>
          );
        },
      },
      {
        name: "Labels",
        max: ".5fr",
        cell: ({ tags }) => {
          if (isEmpty(tags)) {
            return "--";
          }
          return <Labels labels={tags} zIndex={undefined} />;
        },
        breakpoint: "md",
      },
      {
        ...LastUpdatedColumn,
        max: "max-content",
        sortDirection: orderBy?.updated_at,
        onClick: () => onSort(SortKeys.UpdatedAt),
        breakpoint: "md",
      },
    ],
    [filteredDestinations],
  );

  return (
    <ResourceSelect
      disabled={(row) =>
        Boolean(isOptionAvailable && !isOptionAvailable(row.id))
      }
      columns={columns}
      data={filteredDestinations}
      error={Boolean(error)}
      label="destination"
      loading={loadingDestinations || loading || isRefetching}
      search={search}
      onSearch={setSearch}
      onSelect={onSelect}
    />
  );
};
