import { FC } from "react";

import { Box, Column, Pill, Row, SkeletonBox, Text } from "@hightouchio/ui";
import { formatDistance, parseISO } from "date-fns";
import { capitalize, times } from "lodash";
import { RouterLink } from "src/router";

import { useUser } from "src/contexts/user-context";
import { PendingDraftsQuery, usePendingDraftsQuery } from "src/graphql";
import * as analytics from "src/lib/analytics";
import { Card } from "src/components/card";

import { DashboardSidebarItem } from "./dashboard-sidebar-item";

const Draft: FC<{ draft: EnrichedDrafts[0] }> = ({ draft }) => {
  function draftLink(resourceType: string, resourceId: string): string {
    switch (resourceType) {
      case "sync":
        return `/syncs/${resourceId}/draft`;
      case "model":
        return `/models/${resourceId}/draft`;
      case "audience":
        return `/audiences/${resourceId}/draft`;
      default:
        return "";
    }
  }

  if (
    !draft ||
    !draft.resource_id ||
    !draft.resource_type ||
    !draft.created_by_user ||
    !draft.created_at
  )
    return null;
  return (
    <Column
      _notLast={{ borderBottom: "1px", borderColor: "base.border" }}
      _hover={{ bg: "base.lightBackground" }}
    >
      <RouterLink
        onClick={() =>
          analytics.track("[App Dashboard] Sidebar Draft Item Clicked", {
            resourceId: draft.resource_id,
            resourceType: draft.resource_type,
          })
        }
        to={draftLink(draft.resource_type, draft.resource_id)}
      >
        <Column gap={2} px={4} py={3}>
          <Column gap={1}>
            <Text fontWeight="medium">
              {capitalize(draft.resource_type)} drafted by{" "}
              {draft.created_by_user.name}
            </Text>
            <Text size="sm" color="text.secondary">
              {formatDistance(parseISO(draft.created_at), new Date(), {
                addSuffix: true,
              })}
            </Text>
          </Column>
          <Column gap={1}>
            {draft.segment && (
              <Row alignItems="center">
                {draft.segment.icon && (
                  <Column pr={2} flexShrink={0}>
                    <Box
                      as="img"
                      width="16px"
                      height="16px"
                      src={draft.segment.icon}
                    />
                  </Column>
                )}
                <Text isTruncated>{draft.segment?.name}</Text>
              </Row>
            )}
            {draft.destination && (
              <Row alignItems="center">
                {draft.destination.icon && (
                  <Column pr={2} flexShrink={0}>
                    <Box
                      as="img"
                      width="16px"
                      height="16px"
                      src={draft.destination.icon}
                    />
                  </Column>
                )}
                <Text isTruncated>{draft.destination.name}</Text>
              </Row>
            )}
          </Column>
        </Column>
      </RouterLink>
    </Column>
  );
};

const DraftSkeleton: FC = () => {
  return (
    <Column gap={1} p={2} alignItems="flex-start">
      <SkeletonBox height="20px" width="200px" />

      <Row alignItems="center" gap={1}>
        <SkeletonBox height="24px" width="24px" borderRadius="full" />
        <SkeletonBox height="20px" width="120px" />
      </Row>

      <SkeletonBox height="16px" width="100px" />
    </Column>
  );
};

type EnrichedDrafts = (PendingDraftsQuery["drafts"][0] & {
  destination?: { name: string; icon: string };
  segment?: { name: string; icon: string };
})[];

export const PendingDraftsItem = () => {
  const { workspace, isLoading: isWorkspaceLoading } = useUser();
  const { data, isLoading } = usePendingDraftsQuery(undefined, {
    refetchOnMount: "always",
    select: (data): EnrichedDrafts => {
      // Draft resource IDs are strings annoyingly
      const syncIds = new Set(data.syncs.map((item) => item.id.toString()));
      const modelIds = new Set(data.segments.map((item) => item.id.toString()));
      const rawDrafts = data?.drafts.filter(
        (item) =>
          (item.resource_type === "sync" && syncIds.has(item.resource_id)) ||
          (item.resource_type === "model" && modelIds.has(item.resource_id)),
      );

      const destinationInstanceLookup: Record<
        string,
        { name: string; icon: string; modelId: string }
      > = data.syncs.reduce(
        (acc, item) => {
          if (!item.destination) return acc;
          const definition = data.getDestinationDefinitions.find(
            (def) => def.type === item.destination?.type,
          );
          const targetSync = data.syncs.find(
            (sync) => sync.id.toString() === item.id.toString(),
          );
          acc[item.id] = {
            name: item.destination?.name || definition?.name || "Unknown",
            icon: definition?.icon || "",
            modelId: targetSync?.segment?.id || "",
          };

          return acc;
        },
        {} as Record<string, { name: string; icon: string; modelId: string }>,
      );

      const segmentLookup: Record<string, { name: string; icon: string }> =
        data.segments.reduce(
          (acc, item) => {
            if (!item.connection) return acc;
            const sourceDefinition = data.getSourceDefinitions.find(
              (def) => def.type === item.connection?.type,
            );
            acc[item.id] = {
              name: item.name || sourceDefinition?.name || "Unknown",
              icon: sourceDefinition?.icon || "",
            };

            return acc;
          },
          {} as Record<string, { name: string; icon: string }>,
        );

      const drafts = rawDrafts.map((draft) => {
        if (!draft.resource_id || !draft.resource_type) return draft;
        const destination =
          draft.resource_type === "sync"
            ? destinationInstanceLookup[draft.resource_id]
            : undefined;
        let segment =
          draft.resource_type === "model"
            ? segmentLookup[draft.resource_id]
            : undefined;
        if (draft.resource_type === "sync" && destination)
          segment = segmentLookup[destination.modelId];
        return {
          ...draft,
          destination,
          segment,
        };
      });
      return drafts;
    },
  });

  if (isWorkspaceLoading || workspace?.approvals_required === false) {
    return null;
  }

  return (
    <DashboardSidebarItem
      title="Draft approval requests"
      header={isLoading ? null : <Pill key={1}>{data?.length || 0}</Pill>}
      isLoading={isLoading}
    >
      {data?.length ? (
        <Card p={0}>
          {data.map((draft, idx) => (
            <Draft key={idx} draft={draft} />
          ))}
        </Card>
      ) : isLoading ? (
        times(3, (idx) => <DraftSkeleton key={idx} />)
      ) : (
        <Text color="text.secondary">No draft approvals pending</Text>
      )}
    </DashboardSidebarItem>
  );
};
