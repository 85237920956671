import { DecisionEngineStatus } from "@hightouch/lib/customer-data/decision-engine/types";
import {
  Box,
  Button,
  Column,
  Heading,
  SectionHeading,
  Text,
} from "@hightouchio/ui";
import { FC } from "react";

import { Card } from "src/components/card";
import { useUpdateDecisionEngineFlowMutation } from "src/graphql";
import { LoadingCircles } from "src/components/loading";
import { Flow } from "..";

export const Summary: FC<Readonly<{ flow: Flow }>> = ({ flow }) => {
  if (flow.status === DecisionEngineStatus.READY) {
    // TOOD: Summary card
    return null;
  }

  if (flow.status === DecisionEngineStatus.TRAINING) {
    return <Initializing />;
  }

  return <Setup flow={flow} />;
};

export const Initializing = () => {
  return (
    <Card
      p={6}
      gap={6}
      h="100%"
      mb={8}
      alignItems="center"
      justifyContent="center"
    >
      <Box>
        <LoadingCircles />
      </Box>

      <Column gap={4} align="center">
        <Column align="center" gap={2}>
          <Heading textAlign="center">Initializing your flow...</Heading>
          <Box maxW="xl" textAlign="center" color="text.secondary">
            A custom machine learning model is being trained based on your
            configuration. This process can take up to a few days and you’ll
            receive an email when it’s ready.
          </Box>
        </Column>
      </Column>
    </Card>
  );
};

export const Setup: FC<Readonly<{ flow: Flow }>> = ({ flow }) => {
  const updateMutation = useUpdateDecisionEngineFlowMutation();

  return (
    <Card alignItems="flex-start" gap={4}>
      <Column>
        <SectionHeading>Draft flow</SectionHeading>
        <Text>
          Once you add an action and an outcome you can initialize the flow to
          start delivering optimal content to your users.
        </Text>
      </Column>

      <Button
        isDisabled={flow.messages.length === 0 || flow.outcomes.length === 0}
        isLoading={updateMutation.isLoading}
        variant="primary"
        onClick={async () => {
          await updateMutation.mutateAsync({
            id: flow.id,
            input: {
              status: DecisionEngineStatus.TRAINING,
            },
          });
        }}
      >
        Initialize flow
      </Button>
    </Card>
  );
};
