import { FC, useState } from "react";

import { Column, Row, SearchInput, Text } from "@hightouchio/ui";
import { Outlet, useOutletContext } from "src/router";

import { useUpsertUserGroupWorkspacesMutation } from "src/graphql";

import { ActionBar } from "src/components/action-bar";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { isDefaultRole } from "src/pages/organizations/roles/utils";
import { OutletContext } from "..";
import {
  Workspace,
  Workspaces,
  useUserGroupWorkspaces,
} from "./components/workspaces";

export const RoleWorkspaces: FC = () => {
  const context = useOutletContext<OutletContext>();
  const [search, setSearch] = useState("");

  const updateWorkspacesMutation = useUpsertUserGroupWorkspacesMutation();

  const workspaces = useUserGroupWorkspaces({
    organizationId: context.organization.id,
    userGroupId: context.userGroup.id,
    search,
  });

  const form = useHightouchForm({
    onSubmit: async ({ workspaces }) => {
      const objects = Object.values(workspaces)
        .filter((w) => w.role !== "none")
        .map((workspace) => ({
          user_group_id: String(context.userGroup.id),
          workspace_id: String(workspace.id),
          is_workspace_admin: workspace.role === "workspace_admin",
          built_in_permission_set:
            workspace.role === "custom" ? null : workspace.role,
        }));

      await updateWorkspacesMutation.mutateAsync({
        objects,
        workspaceIds: objects.map((w) => w.workspace_id),
        userGroupId: String(context.userGroup.id),
      });
    },
    values: {
      workspaces: workspaces?.reduce(
        (acc, workspace) => ({
          ...acc,
          [workspace.id]: workspace,
        }),
        {},
      ) as Record<string, Workspace>,
    },
  });

  return (
    <>
      <Form form={form}>
        <Column py={4} px={6} gap={4} flex={1} overflow="auto">
          <Row align="center" gap={4} justify="space-between">
            <Text color="text.secondary">
              Members of this group have access to the workspaces listed below,
              with their level of access determined by the role assigned to them
              for each workspace.
            </Text>
            <SearchInput
              value={search}
              placeholder="Search by workspace name..."
              onChange={(event) => setSearch(event.currentTarget.value)}
            />
          </Row>
          <Column flex={1} overflow="auto" pb={4}>
            <Workspaces
              isEditable={!isDefaultRole(context.userGroup.built_in_role_type)}
              workspaces={workspaces}
              placeholder={{
                title: "No workspaces",
                error: "Workspaces failed to load, please try again.",
                body: search
                  ? "No workspaces match your search."
                  : "No workspaces have been assigned to this group",
              }}
            />
          </Column>
        </Column>
        {!isDefaultRole(context.userGroup.built_in_role_type) && (
          <ActionBar fit>
            <FormActions />
          </ActionBar>
        )}
      </Form>
      <Outlet context={context} />
    </>
  );
};
