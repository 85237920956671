import { FC } from "react";

import {
  Box,
  ChakraPopover,
  ChakraPopoverBody,
  ChakraPopoverContent,
  ChakraPopoverTrigger,
  Portal,
  IconButton,
  Radio,
  RadioGroup,
  SettingsIcon,
  useDisclosure,
} from "@hightouchio/ui";
import noop from "lodash/noop";

import { Mapping } from "./types";

type Props = {
  isDisabled?: boolean;
  setValue?: (value) => void;
  value?: Mapping & { ignoreNull?: boolean };
};

type Option = {
  description: string;
  label: string;
  value: string;
};

const options: Option[] = [
  {
    description:
      "This will sync null values. Caution as this can erase production data. Does not apply to nested object or array properties.",
    label: "Always sync",
    value: "false",
  },
  {
    description:
      "This will avoid syncing any null values from your source to destination.",
    label: "Don’t sync null values",
    value: "true",
  },
];

export const SyncType: FC<Readonly<Props>> = ({
  isDisabled = false,
  setValue = noop,
  value = { ignoreNull: undefined },
}) => {
  const { isOpen, onClose, onToggle } = useDisclosure();
  return (
    <ChakraPopover
      isLazy
      isOpen={isOpen}
      onClose={onClose}
      placement="bottom-start"
    >
      <ChakraPopoverTrigger>
        <Box style={{ position: "relative" }}>
          <>
            {value?.ignoreNull && (
              <Box position="absolute" right={0} top={1} zIndex={1}>
                <Box
                  background="primary.base"
                  borderRadius="full"
                  height={2}
                  width={2}
                />
              </Box>
            )}
            <IconButton
              aria-label="Select sync type"
              icon={SettingsIcon}
              isDisabled={isDisabled}
              onClick={onToggle}
            />
          </>
        </Box>
      </ChakraPopoverTrigger>
      <Portal>
        <ChakraPopoverContent p={2} width="320px">
          <ChakraPopoverBody p={0}>
            <RadioGroup
              onChange={(ignoreNull) => {
                setValue({
                  ...value,
                  ignoreNull: ignoreNull === "true" ? true : undefined,
                });
                onClose();
              }}
              value={String(Boolean(value.ignoreNull))}
            >
              {options.map(({ description, label, value }, index) => (
                <Radio
                  key={index}
                  description={description}
                  label={label}
                  value={value}
                />
              ))}
            </RadioGroup>
          </ChakraPopoverBody>
        </ChakraPopoverContent>
      </Portal>
    </ChakraPopover>
  );
};
