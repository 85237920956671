import { Box } from "@hightouchio/ui";

export const Strike = ({ old, _new }: { old?: string; _new?: string }) => {
  return (
    <>
      {old && (
        <Box
          as="span"
          sx={{ mr: 1, display: "inline", textDecoration: "line-through" }}
        >
          {old}
        </Box>
      )}
      <Box as="span" sx={{ display: "inline" }}>
        {_new}
      </Box>
    </>
  );
};
