import { useCallback, useEffect, useMemo, useState } from "react";

import { Box, Text } from "@hightouchio/ui";
import { get } from "lodash";

import { TargetSyncRequestsQuery } from "src/graphql";
import { SyncStatusToText } from "src/utils/syncs";

import { FilterData, FilterOption, FilterState } from "../folders/filters";
import { EnrichedSyncMetric } from "./transformations";
import { SyncRunStatusBadge } from "../syncs/sync-run-status-badge";

export type FilterDefinition<A> = {
  getOptions: (data: A) => FilterOption[];
  title: string;
};

export function useFilteredData<
  D extends Record<string, unknown>,
  F extends { [key: string]: FilterDefinition<D[]> },
>({
  filters,
  data,
  applyFilters,
  defaultFilters,
}: {
  filters: F;
  defaultFilters?: { [key in keyof F]?: string[] };
  data: D[] | undefined;
  applyFilters: (row: D, state: { [key in keyof F]?: FilterState }) => boolean;
}): { filterProps: FilterData[]; filteredData: D[] } {
  const [filterState, setFilterState] = useState<{
    [key in keyof F]?: string[];
  }>({});

  useEffect(() => {
    if (defaultFilters) {
      setFilterState(defaultFilters);
    }
  }, [defaultFilters]);

  const getFilterQueryState = useCallback(
    (filterKey: string, filterOptions: FilterOption[]) => () => {
      const selectedOptions = filterState[filterKey];

      if (!selectedOptions || selectedOptions.length === 0) {
        return filterOptions;
      }

      return selectedOptions
        .map((id) => {
          const option = filterOptions.find(
            (o) => o?.id?.toString() === id?.toString(),
          );
          if (!option) {
            return null;
          }
          return option;
        })
        .filter((o: FilterOption | null): o is FilterOption => Boolean(o));
    },
    [filterState],
  );

  const setFilterQueryState = useCallback(
    (filterKey: string, filterOptions: FilterOption[]) =>
      (selectedOptions: FilterOption[]) => {
        if (selectedOptions.length === filterOptions.length) {
          setFilterState((state) => ({ ...state, [filterKey]: [] }));
          return;
        }

        setFilterState((state) => ({
          ...state,
          [filterKey]: selectedOptions.map((o) => o.id),
        }));
      },
    [],
  );

  return useMemo(() => {
    const state: Record<string, FilterState> = {};
    const filterPropsWithUnfilteredCount: Record<string, FilterData> = {};
    for (const [filterKey, { getOptions, title }] of Object.entries(filters)) {
      const options = getOptions(data ?? []);
      const value = getFilterQueryState(filterKey, options)();

      state[filterKey] = {
        isAllSelected: value.length === options.length,
        selected: value,
      };

      const props = {
        selectedOptions: value,
        setSelectedOptions: setFilterQueryState(filterKey, options),
        options,
        title,
      };
      filterPropsWithUnfilteredCount[filterKey] = props;
    }

    const filteredData = data?.filter((row) => applyFilters(row, state)) ?? [];

    const filterProps = Object.entries(filterPropsWithUnfilteredCount).map(
      ([filterKey, props]) => {
        const options = filters[filterKey]?.getOptions(filteredData) ?? [];
        const mergedOptions = props.options.map((option) => {
          return {
            ...option,
            filteredCount: options.find((o) => o.id === option.id)?.count ?? 0,
          };
        });
        return { ...props, options: mergedOptions };
      },
    );

    return { filteredData, filterProps };
  }, [filterState, data, applyFilters]);
}

export type EnrichedSyncRunData =
  TargetSyncRequestsQuery["sync_requests"][0] & {
    sync: EnrichedSyncMetric<TargetSyncRequestsQuery["syncs"][0]> | undefined;
  };

export const getStatusOptions = (
  runs: EnrichedSyncRunData[],
): FilterOption[] => {
  const statuses: Record<string, FilterOption> = {};

  for (const run of runs) {
    const status = run.status_computed;
    if (status) {
      statuses[status] = {
        id: status,
        count: (statuses?.[status]?.count ?? 0) + 1,
        label: SyncStatusToText[status],
        renderOption: () => <SyncRunStatusBadge status={status} />,
      };
    }
  }
  return Object.values(statuses);
};

export const getDestinationOptions = (
  runs: EnrichedSyncRunData[],
): FilterOption[] => {
  const destinations: Record<string, FilterOption> = {};

  for (const run of runs) {
    const destination = run.sync?.destination;
    if (destination?.id) {
      destinations[destination.id] = {
        id: destination.id,
        count: (destinations?.[destination.id]?.count ?? 0) + 1,
        label: `${destination.name} ${run.sync?.destinationFriendlyType}`, // label is only used for search
        renderOption: () => (
          <>
            <Box
              alt={run.sync?.destinationFriendlyType}
              as="img"
              flexShrink={0}
              maxHeight="100%"
              objectFit="contain"
              src={run.sync?.destinationIcon}
              width="18px"
              height="18px"
            />
            <Text size="sm">
              {destination.name || run.sync?.destinationFriendlyType}
            </Text>
          </>
        ),
      };
    }
  }

  return Object.values(destinations);
};

export const getSourceOptions = (
  runs: EnrichedSyncRunData[],
): FilterOption[] => {
  const sources: Record<string, FilterOption> = {};

  for (const run of runs) {
    const source = run.sync?.segment?.connection;
    if (source?.id) {
      sources[source.id] = {
        id: source.id,
        count: (sources?.[source.id]?.count ?? 0) + 1,
        label: `${source.name} ${run.sync?.sourceFriendlyType}`, // label is only used for search
        renderOption: () => (
          <>
            <Box
              alt={run.sync?.sourceFriendlyType}
              as="img"
              flexShrink={0}
              maxHeight="100%"
              objectFit="contain"
              src={run.sync?.sourceIcon}
              width="18px"
              height="18px"
            />
            <Text size="sm">{source.name || run.sync?.sourceFriendlyType}</Text>
          </>
        ),
      };
    }
  }

  return Object.values(sources);
};

export const doesRunSatisfy = (
  run: EnrichedSyncRunData,
  path: string,
  filter: FilterState | undefined,
): boolean => {
  if (!filter) {
    return true;
  }
  if (filter.isAllSelected) {
    return true;
  }
  return filter.selected.map((s) => s.id).includes(get(run, path));
};
