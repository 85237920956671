import {
  PerUserAggregationType,
  AttributionTimeWindow,
  ColumnType,
  AudienceAggregationType,
  AssetType,
  InteractionType,
  PredefinedMetric,
} from "src/types/visual";

export const headerStyles = {
  p: "0 !important",
  borderBottom: "1px solid",
};

export const cellStyles = {
  py: "0 !important",
  px: "0 !important",
};

export const TimeWindowLabels: Record<AttributionTimeWindow, string> = {
  [AttributionTimeWindow.SevenDays]: "7 days",
  [AttributionTimeWindow.ThirtyDays]: "30 days",
  [AttributionTimeWindow.SixtyDays]: "60 days",
  [AttributionTimeWindow.NinetyDays]: "90 days",
};

export const TimeWindowOptions = Object.entries(TimeWindowLabels).map(
  ([key, value]) => ({
    label: value,
    value: key,
  }),
);

export const CampaignResultsLimit = 30;

export const CampaignFiltersAllowMultiValue = false;
export const CampaignFiltersAllowPercentile = false;

export const NumberColumns = [ColumnType.Number, ColumnType.BigInt];

export const AssetTypeToPredefinedMetricOrder: Record<
  AssetType,
  Exclude<PredefinedMetric, "audience-size">[]
> = {
  [AssetType.Email]: [
    PredefinedMetric.EmailsDelivered,
    PredefinedMetric.EmailsOpened,
    PredefinedMetric.UniqueEmailsOpened,
    PredefinedMetric.EmailOpenRate,
    PredefinedMetric.EmailsClicked,
    PredefinedMetric.EmailClickRate,
    PredefinedMetric.UniqueEmailsClicked,
    PredefinedMetric.EmailClickthroughRate,
  ],
  [AssetType.Ad]: [
    PredefinedMetric.AdImpressions,
    PredefinedMetric.AdClicks,
    PredefinedMetric.AdSpend,
  ],
  [AssetType.Sms]: [
    PredefinedMetric.SmsDelivered,
    PredefinedMetric.SmsClicked,
    PredefinedMetric.SmsReplied,
    PredefinedMetric.SmsOptedOut,
  ],
};

export const AllowedAggregationTypePairs: Map<
  PerUserAggregationType,
  AudienceAggregationType[]
> = new Map([
  [PerUserAggregationType.Count, [AudienceAggregationType.Sum]], // total count
  [PerUserAggregationType.Sum, [AudienceAggregationType.Sum]], // sum of property
]);

export const Delimiter = "==";

export const InteractionTypeToAssetType: Record<InteractionType, AssetType> = {
  [InteractionType.EmailClicked]: AssetType.Email,
  [InteractionType.EmailDelivered]: AssetType.Email,
  [InteractionType.EmailOpened]: AssetType.Email,
  [InteractionType.Unsubscribe]: AssetType.Email,

  [InteractionType.Sessions]: AssetType.Ad,

  [InteractionType.SmsSent]: AssetType.Sms,
  [InteractionType.SmsDelivered]: AssetType.Sms,
  [InteractionType.SmsClicked]: AssetType.Sms,
  [InteractionType.SmsReplied]: AssetType.Sms,
  [InteractionType.SmsOptedOut]: AssetType.Sms,
};

export const InteractionTypeToHumanName: Record<InteractionType, string> = {
  [InteractionType.Sessions]: "Ad Sessions",

  [InteractionType.EmailDelivered]: "Email delivered",
  [InteractionType.EmailOpened]: "Email opened",
  [InteractionType.EmailClicked]: "Email clicked",
  [InteractionType.Unsubscribe]: "Unsubscribe",

  [InteractionType.SmsSent]: "SMS sent",
  [InteractionType.SmsDelivered]: "SMS delivered",
  [InteractionType.SmsClicked]: "SMS clicked",
  [InteractionType.SmsReplied]: "SMS replied",
  [InteractionType.SmsOptedOut]: "SMS opt-out",
};
