import { FC, useState } from "react";

import {
  Box,
  FormField,
  TextInput,
  Column,
  Button,
  Combobox,
  Select,
  ArrowRightIcon,
} from "@hightouchio/ui";
import countries from "countries-list/dist/countries.emoji.min.json";

import { UserPersonalization } from "src/graphql";
import { track } from "src/lib/analytics";

const sizeOptions = [
  { value: "1-50" },
  { value: "51-250" },
  { value: "251-500" },
  { value: "501-1,000" },
  { value: "1,001+" },
];

const countryOptions = Object.values(countries).sort((a, b) =>
  a.name.localeCompare(b.name),
);

type FormState = Omit<
  UserPersonalization,
  "role" | "custom_role" | "referrer" | "state"
>;

export const CompanyForm: FC<
  Readonly<{ onSubmit: (data: FormState) => Promise<void> }>
> = ({ onSubmit }) => {
  const [size, setSize] = useState("");
  const [country, setCountry] = useState("");
  const [loading, setLoading] = useState(false);

  const submit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const data: FormState = {
      company_name: String(new FormData(event.target).get("company")),
      company_size: size,
      company_country: country,
    };
    track("company signup info", data);
    await onSubmit(data);
    setLoading(false);
  };

  return (
    <Column
      as="form"
      gap={8}
      sx={{ label: { color: "gray.700" }, input: { color: "gray.900" } }}
      onSubmit={submit}
    >
      <FormField isRequired label="Company name">
        {/* Current Hightouch UI does not support uncontrolled TextInput */}
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <TextInput id="company" name="company" />
      </FormField>
      <FormField isRequired label="Number of employees">
        <Box color="gray.900">
          <Select
            optionLabel={({ value }) => value}
            optionValue={({ value }) => value}
            options={sizeOptions}
            value={size}
            onChange={(newSize) => setSize(newSize ?? "")}
          />
        </Box>
      </FormField>
      <FormField isRequired label="Country">
        <Box color="gray.900">
          <Combobox
            optionLabel={({ name, emoji }) => `${emoji} ${name}`}
            optionValue={({ name }) => name}
            options={countryOptions}
            value={country}
            onChange={(newCountry) => setCountry(newCountry ?? "")}
          />
        </Box>
      </FormField>

      <Button
        directionIcon={ArrowRightIcon}
        isLoading={loading}
        size="lg"
        type="submit"
        variant="primary"
      >
        Continue
      </Button>
    </Column>
  );
};
