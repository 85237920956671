export const BUILT_IN_PERMISSION_SETS = [
  "workspace_admin",
  "workspace_editor",
  "workspace_draft_editor",
  "workspace_viewer",
] as const;

export type BuiltInPermissionSet = (typeof BUILT_IN_PERMISSION_SETS)[number];

export const BUILT_IN_ROLE_TYPES = [
  "organization_admin",
  "organization_viewer",
  "all_users",
] as const;

export type BuiltInRoleType = (typeof BUILT_IN_ROLE_TYPES)[number];
