import { FC } from "react";

import { Navigate, useOutletContext } from "src/router";
import { OutletContext } from ".";

import { ForwardingSyncOverview } from "../forwarding-sync/overview";

export const Overview: FC = () => {
  const context = useOutletContext<OutletContext>();

  if (context.type === "forwarding") {
    return <ForwardingSyncOverview />;
  }

  return <Navigate to={`/events/syncs/${context.sync.id}`} replace />;
};
