import {
  FC,
  MouseEventHandler,
  MouseEvent as ReactMouseEvent,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  Box,
  ChakraListItem,
  CloseIcon,
  Column,
  IconButton,
  Portal,
  Row,
  Switch,
  Text,
  ToggleButton,
  ToggleButtonGroup,
} from "@hightouchio/ui";
import capitalize from "lodash/capitalize";
import {
  Cell,
  LabelList,
  Pie,
  PieChart,
  Tooltip,
  TooltipProps,
} from "recharts";

import { TextWithTooltip } from "src/components/text-with-tooltip";
import { abbreviateNumber } from "src/utils/numbers";
import { GraphTooltip } from "src/components/analytics/graph-tooltip";
import { audienceGraphColors } from "src/pages/analytics/constants";
import { ChartType } from "src/components/audiences/audience-breakdowns";
import { NumericFontStyles } from "src/components/audiences/constants";

import { AudiencePropertyBreakdownTable } from "./audience-property-breakdown-table";

const horizontalPadding = 4;

const getVisibleValue = (value: unknown) => {
  if (value === null) {
    return "null";
  }

  if (value === "") {
    return "<empty string>";
  }

  // Cast to a string in case the value is a boolean
  // so we can display it as a label on the graph
  return String(value);
};

const MaxCharacterLength = 9;

const valueFormatter = (
  value: number | string,
  formatKey: "percentage" | "count",
): string => {
  if (formatKey === "percentage" && typeof value === "number") {
    return `${(value * 100).toFixed(2)}%`;
  } else if (typeof value === "number") {
    return abbreviateNumber(value);
  } else if (typeof value === "boolean") {
    return String(value);
  }
  // Abbreviate values so they don't overflow on the page
  if (value.length > MaxCharacterLength) {
    return value.slice(0, MaxCharacterLength) + "...";
  }

  return value;
};

type Props = {
  data: { value: string; count: number; percentage?: number }[];
  graphName: string;
  graphWidth: number;
  hoverColor?: string;
  isActive?: boolean;
  isAnimationActive?: boolean;
  secondaryAxisLabel?: string;
  onMouseEnterCell?: MouseEventHandler<HTMLDivElement>;
  onMouseLeaveCell?: MouseEventHandler<HTMLDivElement>;
  onRemoveGraph: (graphName: string) => void;
};

export const BreakdownGraph: FC<Props> = ({
  data,
  graphName,
  graphWidth: _graphWidth,
  onMouseEnterCell,
  onMouseLeaveCell,
  onRemoveGraph,
}) => {
  const [animate, setAnimate] = useState(true);
  const [activeCell, setActiveCell] = useState<number | null>(null);
  const [showTop10, setShowTop10] = useState(true);
  const [chartType, setChartType] = useState<ChartType>("table");

  const formattedData = useMemo(
    () =>
      data.map(({ value, ...rest }) => ({
        ...rest,
        value: getVisibleValue(value),
      })),
    [data],
  );

  const filteredData = formattedData.filter(
    ({ value }) => !showTop10 || value !== "Other",
  );
  const hasOtherColumn = useMemo(
    () => formattedData.some(({ value }) => value === "Other"),
    [data],
  );

  const graphWidth = _graphWidth - horizontalPadding * 4 * 2; // Remove horizontal padding of <li> elements

  const enterCell = (
    event: ReactMouseEvent<HTMLDivElement>,
    cellIndex: number,
  ) => {
    setAnimate(false);
    setActiveCell(cellIndex);
    setAnimate(false);
    onMouseEnterCell?.(event);
  };

  const leaveCell = (event: ReactMouseEvent<HTMLDivElement>) => {
    setActiveCell(null);
    onMouseEnterCell?.(event);
  };

  const hasFilters = hasOtherColumn;

  return (
    <ChakraListItem mb={6}>
      <Column gap={4}>
        <Row justify="space-between" gap={2} align="center">
          <TextWithTooltip fontWeight="medium" message={graphName} size="lg">
            {capitalize(graphName)}
          </TextWithTooltip>

          <Row gap={4} alignItems="center">
            {hasFilters && hasOtherColumn && (
              <Text as="label" fontWeight="medium">
                <Row alignItems="center" gap={2} cursor="pointer">
                  <Switch
                    size="sm"
                    isChecked={showTop10}
                    onChange={(value) => {
                      setAnimate(true);
                      setShowTop10(value);
                    }}
                  />
                  Limit to top 10
                </Row>
              </Text>
            )}

            <ToggleButtonGroup
              value={chartType}
              onChange={(value) => setChartType(value as ChartType)}
              size="sm"
            >
              <ToggleButton label="Bar" value="table" />
              <ToggleButton label="Pie" value="pie" />
            </ToggleButtonGroup>
            <IconButton
              aria-label="Remove graph"
              icon={CloseIcon}
              onClick={() => onRemoveGraph(graphName)}
            />
          </Row>
        </Row>

        <Column
          width={chartType === "table" ? "100%" : graphWidth}
          sx={{
            ":not(:last-child)": { mb: 8 },
          }}
          onMouseLeave={onMouseLeaveCell}
        >
          <Column>
            {chartType === "pie" && (
              <PieChart
                key={`graphName-${showTop10.toString()}-pie`}
                height={230}
                width={graphWidth}
              >
                <Pie
                  animationBegin={0}
                  animationDuration={600}
                  data={filteredData}
                  dataKey="count"
                  isAnimationActive={animate}
                  nameKey="value"
                  cursor="pointer"
                  onMouseEnter={enterCell}
                  onMouseLeave={leaveCell}
                  innerRadius={50}
                >
                  {filteredData.map((_, cellIndex) => {
                    const colors =
                      audienceGraphColors[
                        cellIndex % audienceGraphColors.length
                      ]!;

                    const color =
                      activeCell !== null && activeCell !== cellIndex
                        ? colors.unfocussed
                        : colors.bg;

                    return <Cell key={`cell-${cellIndex}`} fill={color} />;
                  })}
                  <LabelList
                    dataKey="value"
                    fontFamily="inter"
                    fontSize="12px"
                    fill="#252D36"
                    style={NumericFontStyles}
                    formatter={(value) => valueFormatter(value, "count")}
                    position="outside"
                    stroke="transparent"
                  />
                </Pie>
                <Tooltip
                  content={
                    <TooltipContent
                      color={
                        activeCell
                          ? audienceGraphColors[
                              activeCell % audienceGraphColors.length
                            ]?.bg
                          : undefined
                      }
                    />
                  }
                  isAnimationActive={false}
                />
              </PieChart>
            )}
            {chartType === "table" && (
              <AudiencePropertyBreakdownTable data={filteredData} />
            )}
          </Column>
        </Column>
      </Column>
    </ChakraListItem>
  );
};

const TooltipContent = ({
  payload,
  color,
}: TooltipProps<number, string> & { color: string | undefined }) => {
  const [position, setPosition] = useState<{
    x: number | null;
    y: number | null;
  }>({ x: null, y: null });

  useEffect(() => {
    const handleWindowMouseMove = (event: MouseEvent) => {
      if (!payload?.[0]) {
        return null;
      }

      return setPosition({
        x: event.pageX,
        y: event.pageY,
      });
    };
    window.addEventListener("mousemove", handleWindowMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleWindowMouseMove);
    };
  }, [payload?.length]);

  if (!payload?.[0]) {
    return null;
  }

  const {
    payload: { count, percentage, value, fill },
  } = payload[0];

  return (
    <Portal>
      <Box
        position="absolute"
        top={`${position.y === null ? 0 : position.y + 20}px`}
        left={`${position.x === null ? 0 : position.x + 20}px`}
        mr={3}
        display={position.x === null ? "none" : "visible"}
        zIndex="popover"
        boxShadow="sm"
        bg="text.primary"
        p={3}
        borderRadius="md"
      >
        <GraphTooltip
          title={value}
          color={color || fill}
          value={[
            {
              value: `Count: ${count} or ${(percentage * 100).toFixed(2)}%`,
            },
          ]}
        />
      </Box>
    </Portal>
  );
};
