import { FC, useEffect, useState } from "react";

import {
  CloseIcon,
  Column,
  IconButton,
  Row,
  Text,
  Combobox,
} from "@hightouchio/ui";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";

import { ResourceType, useLabels } from "./use-labels";
import { uniq } from "lodash";
import { getKeyValueArray, getKeyValueObject } from "src/utils/key-value";

export interface LabelData {
  key: string;
  value: string;
}

interface Props {
  heading: string;
  hint?: string;
  loading?: boolean;
  keyOptions?: { label: string; value: string | null }[];
  resourceType: ResourceType;
}

export const LabelForm: FC<Readonly<Props>> = ({
  heading,
  hint,
  loading = false,
  resourceType,
}) => {
  const { watch, control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "labels",
  });
  const formLabels = watch("labels");
  const { labels: existingLabels } = useLabels(resourceType);
  const [keyOptions, setKeyOptions] = useState<Array<string>>([]);
  const [valueOptions, setValueOptions] = useState<
    Record<string, Array<string>>
  >({});

  useEffect(() => {
    const keys = uniq(
      [
        ...existingLabels.map(({ key }) => key),
        ...formLabels.map(({ key }) => key),
      ].filter(Boolean),
    );
    setKeyOptions(keys);
    setValueOptions((prev) =>
      keys.reduce(
        (acc, key) => ({
          ...acc,
          [key]: uniq([
            ...(existingLabels.find((l) => l.key === key)?.values ?? []),
            ...(prev[key] ?? []),
          ]),
        }),
        {} as Record<string, Array<string>>,
      ),
    );
  }, [formLabels, existingLabels]);

  return (
    <Column gap={1}>
      <Text fontWeight="medium">{heading}</Text>
      <Text color="gray.500" size="sm">
        {hint}
      </Text>
      <Column gap={4} mt={4}>
        {fields.map((field, index) => (
          <Row key={field.id} align="center" gap={2}>
            <Controller
              control={control}
              name={`labels.${index}` as const}
              render={({ field: labelField }) => (
                <>
                  <Controller
                    control={control}
                    name={`labels.${index}.key` as const}
                    render={({ field }) => (
                      <Combobox
                        supportsCreatableOptions
                        value={field.value}
                        isLoading={loading}
                        options={keyOptions}
                        placeholder="Enter a key"
                        onChange={(value) => {
                          field.onChange(value);
                          append(
                            { key: "", value: "" },
                            { shouldFocus: false },
                          );
                        }}
                        onCreateOption={(value) => {
                          field.onChange(value);
                          append(
                            { key: "", value: "" },
                            { shouldFocus: false },
                          );
                        }}
                        optionLabel={(o) => o}
                        optionValue={(o) => o}
                      />
                    )}
                  />
                  <Text color="gray.400">=</Text>
                  <Controller
                    control={control}
                    name={`labels.${index}.value` as const}
                    render={({ field }) => {
                      return (
                        <Combobox
                          supportsCreatableOptions
                          isDisabled={!labelField.value.key}
                          value={field.value}
                          isLoading={loading}
                          options={valueOptions[labelField.value.key] ?? []}
                          placeholder="Enter a value"
                          onChange={(value) => {
                            field.onChange(value);
                          }}
                          onCreateOption={(value) => {
                            setValueOptions((prev) => ({
                              ...prev,
                              [labelField.value.key]: [
                                ...(prev[labelField.value.key] ?? []),
                                value,
                              ],
                            }));
                            field.onChange(value);
                          }}
                          optionLabel={(o) => o}
                          optionValue={(o) => o}
                        />
                      );
                    }}
                  />
                </>
              )}
            />
            <IconButton
              aria-label="Remove label"
              icon={CloseIcon}
              onClick={() => remove(index)}
            />
          </Row>
        ))}
      </Column>
    </Column>
  );
};

export const getLabelsFromTags = getKeyValueArray;
export const getTagsFromLabels = getKeyValueObject;
