import { ReactNode, forwardRef } from "react";

import { Column } from "@hightouchio/ui";

interface HeaderProps {
  children: ReactNode;
  mb?: number;
  stickToTop?: boolean;
}

export const Header = forwardRef<HTMLDivElement, HeaderProps>(
  ({ children, mb, stickToTop = true }, ref) => {
    return (
      <Column
        ref={ref}
        position={stickToTop ? "sticky" : "relative"}
        width="100%"
        top={0}
        left={0}
        height="max-content"
        zIndex={1}
        bg="white"
        mb={mb}
      >
        {children}
      </Column>
    );
  },
);

Header.displayName = "Header";
