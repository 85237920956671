import { useEffect } from "react";

export const useScrollPageToBottom = <T>(
  dependencies: Array<T> | undefined = [],
) => {
  useEffect(() => {
    // if the window is lower than the previous bottom, scroll to the bottom
    const isAtBottom =
      window.scrollY + window.innerHeight >= document.body.offsetHeight;

    if (!isAtBottom) {
      window.scrollTo({
        left: 0,
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    }
  }, dependencies);
};
