import {
  Text,
  Avatar,
  Box,
  Column,
  ChakraPopover,
  ChakraPopoverContent,
  ChakraPopoverTrigger,
  Portal,
  DescriptionIcon,
  DescriptionAddIcon,
  ChatIcon,
  InformationIcon,
  SparkleIcon,
  UserIcon,
  useDisclosure,
} from "@hightouchio/ui";
import { UserMenu } from "src/components/layout/header/user-menu";
import { NavItem } from "src/components/layout/nav/nav-item";
import { useUser } from "src/contexts/user-context";
import { More } from "src/components/layout/nav/more";
import * as analytics from "src/lib/analytics";
import { newPylonMessage } from "src/lib/pylon";
import { useState } from "react";
import { InviteFormModal } from "src/components/modals/invite-modal";
import { useResourcePermission } from "src/components/permission/use-resource-permission";
import { Icon as HelpIcon } from "src/components/layout/nav/icons/help";
import { Icon as SettingsIcon } from "src/components/layout/nav/icons/settings";
import { RouterLink } from "src/router";
import { useFlags } from "launchdarkly-react-client-sdk";
import { ActiveSectionProps } from "src/components/layout/nav/utils";
import { Settings } from "./settings";
import { useOrganizationPermissions } from "src/components/permission/use-organization-permissions";
export const Footer = (props: ActiveSectionProps) => {
  const { user } = useUser();
  const { manageOrganizations } = useFlags();
  const { isOrganizationAdmin } = useOrganizationPermissions();

  const shouldViewOrganizations =
    (user?.permissions_v2_enabled || manageOrganizations) &&
    isOrganizationAdmin;

  return (
    <Column gap={1} py={4} px={2}>
      <More />

      {shouldViewOrganizations ? (
        <Settings {...props} />
      ) : (
        <NavItem href="/settings" icon={SettingsIcon} label="Settings" />
      )}

      <Support />
      <UserMenu user={user || { name: "unknown user", email: "<no email>" }}>
        <Box>
          <NavItem onClick={() => {}}>
            <Box
              as={Avatar}
              size="2xs"
              name={user?.name || "unknown user"}
              bg="grass.200"
            />
            <Box
              as={Text}
              color="gray.300"
              display={["none", "none", "none", "inline-block"]}
              fontFamily="'Sharp Sans Display No 1'"
              fontSize="13px"
              fontWeight="medium"
              overflow="hidden"
              textAlign="initial"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {user?.email === "guest"
                ? user?.name
                : (user?.email ?? "<no email>")}
            </Box>
          </NavItem>
        </Box>
      </UserMenu>
    </Column>
  );
};

export const Support = () => {
  const { workspace, user } = useUser();
  const [isInviting, setIsInviting] = useState(false);
  const { isOpen, onClose, onToggle } = useDisclosure();

  const { isPermitted: hasWorkspaceUpdate } = useResourcePermission({
    v1: { resource: "workspace", grant: "update" },
  });

  const canInviteUsers =
    workspace?.organization?.can_invite_users !== false &&
    hasWorkspaceUpdate &&
    !user?.permissions_v2_enabled;

  const contactSupport = () => {
    analytics.track("Contact support clicked", {
      workspace_id: workspace?.id,
    });

    newPylonMessage("");
  };

  const submitFeatureRequest = () => {
    analytics.track("Feedback Menu: Submit feature request clicked.", {
      workspace_id: workspace?.id,
    });

    newPylonMessage("Hi, I have a feature request.");
  };

  return (
    <>
      <ChakraPopover
        isLazy
        placement="top-start"
        isOpen={isOpen}
        onClose={onClose}
      >
        <ChakraPopoverTrigger>
          <Box>
            <NavItem
              icon={HelpIcon}
              label="Docs + Support"
              onClick={onToggle}
            />
          </Box>
        </ChakraPopoverTrigger>
        <Portal>
          <ChakraPopoverContent width="300px" overflow="hidden">
            <Column>
              <MenuItem
                href={import.meta.env.VITE_DOCS_URL}
                icon={DescriptionIcon}
                title="Documentation"
                description="View the Hightouch docs"
              />
              <MenuItem
                onClick={contactSupport}
                icon={ChatIcon}
                title="Contact support"
                description="Ask us anything. We're here to help!"
              />
              <MenuItem
                onClick={submitFeatureRequest}
                icon={DescriptionAddIcon}
                title="Submit a feature request"
                description="Share your feedback with our product team"
              />
              <MenuItem
                href="https://status.hightouch.io"
                icon={InformationIcon}
                title="Status updates"
                description="View current status of our systems"
              />
              <MenuItem
                href="https://changelog.hightouch.io"
                icon={SparkleIcon}
                title="What's new"
                description="Explore the latest Hightouch features"
              />
              <MenuItem
                onClick={() => setIsInviting(true)}
                isDisabled={!canInviteUsers}
                icon={UserIcon}
                title="Invite a teammate"
                description="Invite someone to join this workspace"
              />
            </Column>
          </ChakraPopoverContent>
        </Portal>
      </ChakraPopover>
      <InviteFormModal
        close={() => setIsInviting(false)}
        name="Hightouch"
        open={isInviting}
      />
    </>
  );
};

const MenuItem = ({
  title,
  description,
  icon,
  href = "",
  isDisabled = false,
  ...props
}) => {
  if (isDisabled) {
    return null;
  }

  return (
    <Box
      as={href ? RouterLink : "button"}
      // @ts-expect-error - `as` prop
      disabled={isDisabled}
      to={href}
      target="_blank"
      display="flex !important"
      width="100%"
      align="center"
      textAlign="left"
      gap={2}
      p={2}
      pointerEvents={isDisabled ? "none" : "auto"}
      bg="inherit"
      _hover={{
        bg: "forest.100",
      }}
      _focusVisible={{
        outline: "none",
        border: "none",
        bg: "forest.200",
      }}
      {...props}
    >
      <Box as={icon} fontSize="2xl" color="text.secondary" />
      <Column align="flex-start">
        <Text>{title}</Text>
        <Text color="text.secondary" size="sm">
          {description}
        </Text>
      </Column>
    </Box>
  );
};
