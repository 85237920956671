import { addHours, differenceInHours } from "date-fns";
import { defaults, keyBy, round, sortBy } from "lodash";

export function quantityTickFormatter(tick: number): string {
  if (tick >= 1000000000) {
    return `${(tick / 1000000000).toFixed(0)}B`;
  } else if (tick >= 1000000) {
    return `${(tick / 1000000).toFixed(0)}M`;
  } else if (tick >= 1000) {
    return `${round(tick / 1000, 1)}K`; // ex. "2.5K". Recharts will often choose increments of 500 at this scale and dropping the decimal can make it quite off.
  } else if (tick == 0) {
    return "";
  } else {
    return tick.toString();
  }
}

export const roundTimestampToBin = (
  timestamp: Date,
  startTime: Date,
  binHours: number,
) =>
  addHours(
    startTime,
    round(differenceInHours(timestamp, startTime) / binHours) * binHours,
  );

export function padTimeseriesDataWithBuckets<T>(
  timeseriesData: { timestamp: Date; data: T }[],
  padWith: T,
  granularity: ChartGranularity,
  startTime: Date,
) {
  const granularityMetadata = ChartGranularityMetadata[granularity];
  const buckets = Array.from(
    {
      length: (granularityMetadata.days * 24) / granularityMetadata.binHours,
    },
    (_, i) => ({
      timestamp: addHours(startTime, i * granularityMetadata.binHours),
      data: padWith,
    }),
  );
  const bucketsObject = keyBy(buckets, "timestamp");
  const volumeDataObject: typeof bucketsObject = keyBy(
    timeseriesData,
    "timestamp",
  );
  const merged = defaults(volumeDataObject, bucketsObject);
  const paddedData = sortBy(Object.values(merged), (point) => point.timestamp);
  return paddedData;
}

export enum ChartGranularity {
  OneDay = "24h",
  ThreeDays = "3d",
  SevenDays = "7d",
}

export const ChartGranularityMetadata = {
  [ChartGranularity.OneDay]: {
    label: "24h",
    days: 1,
    binHours: 1,
    tickInterval: 3,
  },
  [ChartGranularity.ThreeDays]: {
    label: "3d",
    days: 3,
    binHours: 3,
    tickInterval: 7,
  },
  [ChartGranularity.SevenDays]: {
    label: "7d",
    days: 7,
    binHours: 6,
    tickInterval: 3,
  },
};

export const ChartColors = {
  green: {
    base: "var(--chakra-colors-success-border)",
    hover: "var(--chakra-colors-success-base)",
  },
  red: {
    base: "var(--chakra-colors-danger-border)",
    hover: "var(--chakra-colors-danger-base)",
  },
  gray: {
    base: "var(--chakra-colors-gray-400)",
    hover: "var(--chakra-colors-gray-500)",
  },
};
