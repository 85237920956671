import { FC } from "react";

import { FormField, SlugInput } from "@hightouchio/ui";
import { Controller } from "react-hook-form";

import { Card } from "src/components/card";
import { IDRv1OutputTableSuffix, IDRv2OutputTableSuffix } from "src/types/idr";

import { SlugValidationState } from "./hooks";

function getOutputTableTip(fieldValue: string | undefined, isIDRv2: boolean) {
  const value = fieldValue || "<table_name>";

  return `The output will be two tables in the hightouch_planner schema called ${value}_resolved and ${value}${
    isIDRv2 ? IDRv2OutputTableSuffix : IDRv1OutputTableSuffix
  }.`;
}

export const OutputTable: FC<{
  isIDRv2: boolean;
  validationState: SlugValidationState;
}> = ({ isIDRv2, validationState }) => {
  return (
    <Card gap={6}>
      <Controller
        name="output_table"
        render={({ field, fieldState: { error } }) => (
          <FormField
            label="Where should Hightouch send the output of resolved identities?"
            description="Provide a prefix for this new table (don’t use an existing table name)"
            tip={getOutputTableTip(field.value, isIDRv2)}
            error={
              validationState === "invalid"
                ? "This name is not available"
                : error?.message
            }
          >
            <SlugInput
              {...field}
              placeholder="Enter a prefix..."
              validationState={validationState}
            />
          </FormField>
        )}
      />
    </Card>
  );
};
