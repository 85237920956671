import { FC, ReactNode } from "react";

import { Row } from "@hightouchio/ui";

type Props = {
  children: ReactNode;
};

export const DetailBar: FC<Readonly<Props>> = ({ children }) => {
  return (
    <Row
      align="center"
      gap={4}
      whiteSpace="nowrap"
      sx={{
        "& > div:not(:last-of-type)": {
          borderRight: "1px",
          pr: 4,
          borderColor: "base.border",
          height: "24px",
        },
      }}
    >
      {children}
    </Row>
  );
};
