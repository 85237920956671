import { FC } from "react";

import {
  Box,
  Column,
  Row,
  Select,
  Spinner,
  Text,
  TimeIcon,
  Tooltip,
} from "@hightouchio/ui";

import { noop } from "lodash";
import { useFormContext } from "react-hook-form";
import { useDecisionEngineQuery } from "src/graphql";
import { SubSection } from "../../subsection";
import {
  ChartFormState,
  MeasurementScope,
  MeasuringMode,
  MeasuringSelection,
} from "../../types";

type DecisionEngineAttributionWindowProps = {
  measuringSelection: MeasuringSelection | undefined;
};

type DecisionEngineAttributionWindow = {
  value: number;
  unit: string;
};

export const DecisionEngineAttributionWindow: FC<
  DecisionEngineAttributionWindowProps
> = ({ measuringSelection }) => {
  const form = useFormContext<ChartFormState>();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: Suppress circular reference error
  const measuringMode = form.watch("measuringMode");

  const isIncrementalityMode = measuringMode === MeasuringMode.Incrementality;

  // Attribution window is defined on the decision engine for a given parent model
  const { data: decisionEngineConfig, isLoading } = useDecisionEngineQuery(
    undefined,
    {
      select: (data) => data.decision_engines[0]?.config,
      enabled:
        measuringSelection?.scope === MeasurementScope.DecisionEngineFlow &&
        !isIncrementalityMode,
    },
  );

  const decisionEngineAttributionWindow:
    | DecisionEngineAttributionWindow
    | undefined = decisionEngineConfig?.attribution?.window;

  if (
    !measuringSelection ||
    measuringSelection.scope !== MeasurementScope.DecisionEngineFlow ||
    isIncrementalityMode
  ) {
    return null;
  }

  const options = decisionEngineAttributionWindow
    ? [
        {
          label: `${decisionEngineAttributionWindow.value} ${decisionEngineAttributionWindow.unit}s - last AID interaction sent`,
          value: decisionEngineAttributionWindow,
        },
      ]
    : [];

  return (
    <SubSection>
      <Text color="text.secondary" fontWeight="medium">
        <Row gap={1}>
          <TimeIcon /> Attribution method
        </Row>
      </Text>

      <Column>
        <Tooltip
          message={
            <Box width="163px">
              <Text color="white">
                Attribution method is set by the AI Decisioning flow
              </Text>
            </Box>
          }
        >
          {isLoading ? (
            <Spinner size="xs" />
          ) : (
            <Box width="fit-content">
              <Select
                size="sm"
                width="auto"
                isDisabled
                value={decisionEngineAttributionWindow}
                options={options}
                variant="alternative"
                onChange={noop}
              />
            </Box>
          )}
        </Tooltip>
      </Column>
    </SubSection>
  );
};
