import { FC } from "react";

import {
  Box,
  CloseIcon,
  Column,
  IconButton,
  Row,
  Spinner,
  useToast,
} from "@hightouchio/ui";
import { Link } from "src/router";
import {
  AudienceSubsetGroupsSetInput,
  useUpdateSubsetCategoryMutation,
} from "src/graphql";
import { captureException } from "@sentry/react";
import {
  PermissionedEditableDescription,
  PermissionedEditableHeading,
} from "src/components/permission";

type Props = {
  subsetCategoryId: string;
  name: string;
  description?: string;
  parentModel:
    | {
        id: any;
        name: string;
        connection: {
          id: any;
          definition: {
            icon: string;
          };
        } | null;
      }
    | null
    | undefined;
  onClose: () => void;
};

export const CategoryDrawerHeader: FC<Readonly<Props>> = ({
  subsetCategoryId,
  description,
  name,
  parentModel,
  onClose,
}) => {
  const { toast } = useToast();
  const updateSubsetCategory = useUpdateSubsetCategoryMutation();

  const onUpdate = async (
    property: string,
    payload: AudienceSubsetGroupsSetInput,
  ) => {
    try {
      await updateSubsetCategory.mutateAsync({
        id: subsetCategoryId,
        input: payload,
      });
      toast({
        id: `update-${property}`,
        title: `Successfully updated ${property}.`,
        variant: "success",
      });
    } catch (error) {
      toast({
        id: `update-${property}`,
        title: `Could not update ${property}. Please try again.`,
        variant: "error",
      });
      captureException(error);
    }
  };

  return (
    <Row
      justifyContent="space-between"
      px={6}
      py={4}
      borderBottom="1px solid"
      borderColor="base.border"
    >
      <Row alignItems="baseline">
        <Column gap={4}>
          <Column gap={1}>
            <PermissionedEditableHeading
              permission={{
                v1: { resource: "audience_schema", grant: "update" },
                v2: {
                  resource: "model",
                  grant: "can_update",
                  id: parentModel?.id ?? "",
                },
              }}
              size="lg"
              onChange={(name) => onUpdate("name", { name })}
              value={name}
            />
            <PermissionedEditableDescription
              permission={{
                v1: { resource: "audience_schema", grant: "update" },
                v2: {
                  resource: "model",
                  grant: "can_update",
                  id: parentModel?.id ?? "",
                },
              }}
              onChange={(description) =>
                onUpdate("description", { description })
              }
              value={description ?? ""}
            />
          </Column>
          <Row alignItems="center" gap={1}>
            <Box
              as="img"
              width="12px"
              height="12px"
              src={parentModel?.connection?.definition.icon}
            />
            <Link
              href={`/schema-v2/view?source=${parentModel?.connection?.id}&id=${parentModel?.id}`}
            >
              {parentModel?.name}
            </Link>
          </Row>
        </Column>
        {updateSubsetCategory.isLoading && (
          <Box as={Spinner} ml={1} size="xs" />
        )}
      </Row>
      <IconButton
        aria-label="Close drawer"
        icon={CloseIcon}
        onClick={onClose}
      />
    </Row>
  );
};
