import { Column } from "@hightouchio/ui";
import { FC, Suspense } from "react";
import { SyncAlertingConfiguration } from "./sync-alerting-configuration";
import { SyncAlertTriggerManagement } from "./sync-trigger-management";
import { MonitoredResourceType } from "@hightouch/lib/resource-monitoring/types";
import { PageSpinner } from "../loading";

interface Props {
  resourceType: MonitoredResourceType.Sync | MonitoredResourceType.EventSync;
  resourceId: string;
  parentResourceType?: string;
  parentResourceId?: string;
}

export const ResourceMonitoring: FC<Props> = ({
  resourceType,
  resourceId,
  parentResourceId,
  parentResourceType,
}) => {
  return (
    <Suspense fallback={<PageSpinner />}>
      <Column gap={6}>
        <SyncAlertingConfiguration
          parentResourceId={parentResourceId}
          parentResourceType={parentResourceType}
          resourceId={resourceId}
          resourceType={resourceType}
        />

        <SyncAlertTriggerManagement
          syncId={resourceId}
          destinationId={parentResourceId}
          resourceType={resourceType}
        />
      </Column>
    </Suspense>
  );
};
