import {
  ButtonGroup,
  Column,
  FormField,
  Row,
  Switch,
  Text,
  TextInput,
  useToast,
} from "@hightouchio/ui";
import { Controller, useForm } from "react-hook-form";
import { useOutletContext } from "src/router";

import { ActionBar } from "src/components/action-bar";
import { useUpdateEventWarehouseDestinationMutation } from "src/graphql";
import { OutletContext } from "./types";
import { PermissionedButton } from "src/components/permission";

export type FormProps = {
  schemaName: string;

  // These are false by default.
  writePagesAndScreensToTracks?: boolean;

  // These are enabled by default.
  enableTrackSubtables?: boolean;
  enableJsonExplosion?: boolean;
};

export const SchemaSettings = () => {
  const { type, destination } = useOutletContext<OutletContext>();
  const { toast } = useToast();

  if (type === "forwarding") {
    return null;
  }

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm<FormProps>({
    defaultValues: destination.schema_settings,
  });

  const { mutateAsync: update, isLoading: isUpdating } =
    useUpdateEventWarehouseDestinationMutation();

  const onSave = async (data: FormProps) => {
    const updatedSchemaSettings = {
      ...destination.schema_settings,
      ...data,
    };

    try {
      await update({
        id: destination.id,
        input: {
          schema_settings: updatedSchemaSettings,
        },
      });

      toast({
        id: "update-event-destination-schema-settings",
        title: "Schema settings were updated",
        variant: "success",
      });
    } catch (error) {
      toast({
        id: "update-event-destination-schema-settings",
        title:
          "There was a problem updating your event destination's schema settings",
        variant: "error",
      });
    }
  };

  return (
    <>
      <Column
        gap={12}
        sx={{
          "& > div:not(:last-child)": {
            borderBottom: "1px solid",
            borderColor: "base.border",
            pb: 12,
          },
        }}
      >
        <Controller
          control={control}
          name="schemaName"
          render={({ field }) => (
            <FormField
              description="Customize the schema Hightouch uses to write events into your warehouse. By default, the schema name is based on the Event Source name."
              label="Schema name"
            >
              <TextInput {...field} placeholder="Add schema name..." />
            </FormField>
          )}
        />

        <Controller
          control={control}
          name="enableTrackSubtables"
          render={({ field }) => (
            <Column maxWidth="60%">
              <Row gap={2} alignItems="center">
                <Text fontWeight="medium">Enable per-event tables</Text>
                <Switch
                  isChecked={field.value ?? true}
                  onChange={field.onChange}
                />
              </Row>
              <Row mt={1}>
                <Text color="text.secondary">
                  Create a separate event table for each Track event type (e.g.
                  "added_to_cart").
                  <br />
                  Disabling per-event tables will make it so Hightouch only
                  writes to the tracks table, and includes the full event
                  properties on the tracks table.
                </Text>
              </Row>
            </Column>
          )}
        />

        <Controller
          control={control}
          name="enableJsonExplosion"
          render={({ field }) => (
            <Column maxWidth="60%">
              <Row gap={2} alignItems="center">
                <Text fontWeight="medium">
                  Convert properties and context fields to columns
                </Text>
                <Switch
                  isChecked={field.value ?? true}
                  onChange={field.onChange}
                />
              </Row>
              <Row mt={1}>
                <Text color="text.secondary">
                  Automatically convert event properties and context into
                  individual columns.
                  <br />
                  When disabled, Hightouch writes the event properties and
                  context as JSON. This makes some SQL analysis simpler, but
                  makes it harder to tell what fields are defined for each
                  event.
                </Text>
              </Row>
            </Column>
          )}
        />

        <Controller
          control={control}
          name="writePagesAndScreensToTracks"
          render={({ field }) => (
            <Column maxWidth="60%">
              <Row gap={2} alignItems="center">
                <Text fontWeight="medium">
                  Write Page and Screen events to the Tracks table
                </Text>
                <Switch
                  isChecked={Boolean(field.value)}
                  onChange={field.onChange}
                />
              </Row>
              <Row mt={1}>
                <Text color="text.secondary">
                  This is helpful when analyzing all user interactions in a
                  single table.
                  <br />
                  By default, Page and Screen events are written to separate
                  tables.
                </Text>
              </Row>
            </Column>
          )}
        />
      </Column>

      <ActionBar>
        <ButtonGroup>
          <PermissionedButton
            permission={{
              v1: { resource: "workspace", grant: "update" },
              v2: {
                resource: "workspace",
                grant: "can_update",
              },
            }}
            isDisabled={!isDirty || isUpdating}
            isLoading={isUpdating}
            size="lg"
            variant="primary"
            onClick={handleSubmit(onSave)}
          >
            Save changes
          </PermissionedButton>
        </ButtonGroup>
      </ActionBar>
    </>
  );
};
