import { EventWarehouseDestinationType } from "@hightouch/core/server/resources/events/types";
import { MonitoredResourceType } from "@hightouch/lib/resource-monitoring/types";
import {
  Checkbox,
  Column,
  Pill,
  Row,
  SearchInput,
  SectionHeading,
  Spinner,
  Text,
} from "@hightouchio/ui";
import { useMemo, useState } from "react";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import {
  useEventDestinationsQuery,
  useSubscribeableResourcesQuery,
} from "src/graphql";
import { enumOrFallback, isPresent } from "src/types/utils";
import { getSearchRegExp } from "src/utils/string";
import { useRecipients } from "src/pages/alerting/utils";
import { ChannelBubbles } from "src/pages/alerting/recipients/components/channel-bubbles";

export const Destinations = ({
  onChange,
  value,
}: {
  onChange: (ids: Array<string>) => void;
  value: Array<string>;
}) => {
  const [searchInput, setSearchInput] = useState("");

  const resourceType = MonitoredResourceType.Sync as const; // Get this from search when we add new resource types: search.get("resourceType");

  const { getRecipientsForDestinations } = useRecipients();

  const subscribeableResourcesQuery = useSubscribeableResourcesQuery();

  const eventDestinationsQuery = useEventDestinationsQuery(
    { forwardingTypes: [] },
    {
      select: (data) =>
        data.event_warehouse_destinations
          .map((destination) => {
            const destinationType = enumOrFallback(
              EventWarehouseDestinationType,
              undefined,
              false,
            )(destination.type);

            return destinationType
              ? {
                  ...destination,
                  type: destinationType,
                }
              : undefined;
          })
          .filter(isPresent),
    },
  );

  const destinations = useMemo(() => {
    if (resourceType === MonitoredResourceType.Sync) {
      return (
        subscribeableResourcesQuery.data?.destinations.map((destination) => ({
          ...destination,
          channels: getRecipientsForDestinations([destination.id]).map(
            (r) => r.channel,
          ),
        })) || []
      );
    }
    return [];
  }, [
    getRecipientsForDestinations,
    resourceType,
    subscribeableResourcesQuery.data,
    eventDestinationsQuery.data,
  ]);

  const filteredDestinations = useMemo(() => {
    return destinations?.filter((destination) => {
      if (searchInput) {
        const regex = getSearchRegExp(searchInput, "i");
        return (
          regex.test(destination.name?.toLowerCase() ?? "") ||
          regex.test(destination.definition.name?.toLowerCase() ?? "")
        );
      }
      return true;
    });
  }, [destinations, searchInput]);

  const onSelect = (id: string) => {
    if (value.includes(id)) {
      const newIds = value.filter((selectedId) => selectedId !== id);
      if (newIds.length) {
        onChange(newIds);
      } else {
        clearSelection();
      }
    } else {
      onChange([...value, id]);
    }
  };

  const clearSelection = () => {
    onChange([]);
  };

  const onSelectAll = () => {
    onChange(destinations.map((d) => d.id.toString()));
  };

  const isLoading =
    subscribeableResourcesQuery.isLoading || eventDestinationsQuery.isLoading;

  return (
    <Column
      borderRight="1px"
      borderColor="base.border"
      height="100%"
      width="400px"
    >
      <Column
        gap={3}
        pt={6}
        px={6}
        pb={4}
        borderBottom="1px"
        borderColor="base.border"
      >
        <Row gap={2} align="center">
          <SectionHeading>Destinations</SectionHeading>
          {destinations.length > 0 && <Pill>{destinations.length}</Pill>}
        </Row>

        <SearchInput
          width="100%"
          onChange={(e) => {
            setSearchInput(e.target.value);
          }}
          value={searchInput}
          placeholder="Search destinations..."
        />

        {value.length > 0 && (
          <Row gap={2} align="center" justify="space-between">
            <Row gap={2} align="center">
              <Checkbox
                isIndeterminate={
                  value.length > 0 && value.length < destinations.length
                }
                isChecked={value.length === destinations.length}
                onChange={() => {
                  if (value.length === destinations.length) {
                    clearSelection();
                  } else {
                    onSelectAll();
                  }
                }}
              />
              <Text color="text.secondary">{value.length} selected</Text>
            </Row>
          </Row>
        )}
      </Column>

      <Column overflow="auto" pl={6} pr={2} flex={1}>
        {isLoading ? (
          <Spinner mx="auto" mt={6} />
        ) : filteredDestinations.length > 0 ? (
          filteredDestinations.map((destination) => (
            <Row
              key={destination.id}
              py={2}
              gap={3}
              align="center"
              justify="space-between"
            >
              <Row align="center" gap={3}>
                <Checkbox
                  isChecked={value.includes(destination.id.toString())}
                  onChange={() => {
                    onSelect(destination.id.toString());
                  }}
                />
                <IntegrationIcon
                  src={destination.definition.icon}
                  name={destination.definition.name}
                />
                <Column overflow="hidden">
                  <TextWithTooltip fontWeight="medium">
                    {destination.name ?? destination.definition.name}
                  </TextWithTooltip>
                  <Text color="text.secondary">
                    {destination.syncs_aggregate.aggregate?.count ?? 0} syncs
                  </Text>
                </Column>
              </Row>
              {destination.channels.length > 0 && (
                <ChannelBubbles channels={destination.channels} />
              )}
            </Row>
          ))
        ) : (
          <Text color="text.secondary" mx="auto" mt={6}>
            No destinations found
          </Text>
        )}
      </Column>
    </Column>
  );
};
