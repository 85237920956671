import { MonitorStatus } from "@hightouch/lib/resource-monitoring/types";
import { Box, Column, Row, Select, Text } from "@hightouchio/ui";
import { Controller, useFormContext } from "react-hook-form";
import { FieldError } from "src/components/field-error";

import { ResourceStatus } from "src/components/resource-alert-triggers/resource-status";

import { Term } from "src/components/term";
import { isPresent } from "src/types/utils";
import { cleanFormError } from "src/components/resource-alert-triggers/utils";
import { NumberInputField } from "./fields/number-input-field";

export const SyncThroughputField = ({ name }) => {
  const { setValue, control, getValues, getFieldState } = useFormContext();

  const countError = getFieldState(`${name}.count`).error;
  const interval = getValues(`${name}.interval`);

  return (
    <Column>
      <Row gap={1} alignItems="center" flexWrap="wrap">
        <Controller
          name={`${name}.sign`}
          control={control}
          render={({ field: { value } }) => (
            <Box width="120px">
              <Select
                value={value}
                size="sm"
                options={[
                  { label: "More than", value: "gt" },
                  { label: "Fewer than", value: "lt" },
                ]}
                onChange={(option) => {
                  setValue(
                    name,
                    {
                      sign: option,
                      interval: interval ?? "7 days",
                      count: option === "gt" ? 1e6 : 1,
                    },
                    { shouldDirty: true },
                  );
                }}
              />
            </Box>
          )}
        />
        <NumberInputField name={`${name}.count`} />
        <Text>
          <Term message="An operation is performed whenever a row is added, changed, or removed in a destination. This counts successful operations only; rejected operations are excluded.">
            sync operations
          </Term>{" "}
          in the last
        </Text>

        <Controller
          name={`${name}.interval`}
          control={control}
          render={({ field: { value, onChange } }) => (
            <Box width="90px">
              <Select
                value={value}
                size="sm"
                options={[
                  { label: "1 hour", value: "1 hour" },
                  { label: "4 hours", value: "4 hours" },
                  { label: "1 day", value: "1 day" },
                  { label: "2 days", value: "2 days" },
                  { label: "7 days", value: "7 days" },
                ]}
                onChange={onChange}
              />
            </Box>
          )}
        />
      </Row>
      {countError?.message ? (
        <FieldError error={cleanFormError(countError?.message as string)} />
      ) : null}
    </Column>
  );
};

interface Props {
  condition: {
    error_value: { sign: "gt" | "lt"; count: number };
    warning_value: { sign: "gt" | "lt"; count: number };
  };
}

const withSign = (opts: { sign: "gt" | "lt"; count: number }): string => {
  const prefix = opts.sign === "gt" ? "More than" : "Fewer than";
  return `${prefix} ${Intl.NumberFormat("en-US").format(
    opts.count,
  )} sync operations in the last seven days`;
};

export const SyncThroughputMonitorCondition = ({ condition }: Props) => {
  return [
    condition.error_value ? (
      <Text>
        <Text fontWeight="semibold">{withSign(condition.error_value)}</Text>{" "}
        minutes sets sync status to{" "}
        <ResourceStatus status={MonitorStatus.Unhealthy} />{" "}
      </Text>
    ) : null,
    condition.warning_value ? (
      <Text>
        <Text fontWeight="semibold">{withSign(condition.warning_value)}</Text>{" "}
        minutes sets sync status to{" "}
        <ResourceStatus status={MonitorStatus.Unhealthy} />{" "}
      </Text>
    ) : null,
  ].filter(isPresent);
};
