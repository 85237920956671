import { NavItem } from "src/components/layout/nav/nav-item";
import { Icon } from "src/components/layout/nav/icons/get-started";
import { useUser } from "src/contexts/user-context";
import { Tooltip, Box } from "@hightouchio/ui";
import { UseSectionHook } from "./types";

export const useGetStartedSection: UseSectionHook = () => {
  const { onboarding } = useUser();

  return {
    title: "Get started",
    isEnabled: Boolean(onboarding),
    links: [
      {
        href: "/onboarding",
        label: "Get started",
      },
    ],
  };
};

export const GetStarted = () => {
  const { isEnabled } = useGetStartedSection();

  if (!isEnabled) {
    return null;
  }
  return (
    <Box>
      <Box display={{ base: "none", lg: "flex" }}>
        <NavItem href="/onboarding" icon={Icon} label="Get started" />
      </Box>
      <Box display={{ base: "block", lg: "none" }}>
        <Tooltip message="Get started" placement="right">
          <Box>
            <NavItem href="/onboarding" icon={Icon} label="Get started" />
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );
};
