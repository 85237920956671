import { useOutletContext } from "src/router";
import { AudiencePageOutletState } from "../types";
import { TraitEnrichments } from "src/components/audiences/trait-enrichments/trait-enrichments";

export const AudienceTraits = () => {
  const { audience, audienceFetching, parentModel } =
    useOutletContext<AudiencePageOutletState>();

  return (
    <TraitEnrichments
      audience={audience}
      parentModel={parentModel}
      isLoading={audienceFetching}
    />
  );
};
