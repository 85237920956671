import { SomeJSONSchema } from "ajv/dist/types/json-schema";
import {
  EventPlanQuery,
  EventSchemaEventType,
  EventSchemaOnSchemaViolation,
  EventSchemaOnUndeclaredFields,
  EventSchemaVersionQuery,
} from "src/graphql";
import { isEnum } from "src/types/utils";

export enum SchemaPropertyType {
  String = "string",
  Number = "number",
  Boolean = "boolean",
  Object = "object",
  Array = "array",
}

export type Contract = NonNullable<EventPlanQuery["event_plans_by_pk"]>;

export type EventSchemaOutletContext = {
  contract: Contract;
};

export type EventSchemaDrawerOutletContext = {
  contract: Contract;
  onClose: () => void;
};

export type EventSchemaApiType = EventSchemaVersionQuery["event_schemas"][0];

export type EventSchema = EventSchemaApiType & {
  event_type: EventSchemaEventType;
  on_schema_violation: EventSchemaOnSchemaViolation;
  on_undeclared_fields: EventSchemaOnUndeclaredFields;
};

export enum SchemaViolations {
  ALLOW_EVENT = "ALLOW_EVENT",
  BLOCK_EVENT = "BLOCK_EVENT",
}

export type EventSchemaFormState = {
  name: string | null;
  description: string;
  eventType: EventSchemaEventType;
  schema: SomeJSONSchema;
  editorState: {
    isJson: boolean;
    json: string;
    properties: ContractProperty[];
  };
  onSchemaViolation: EventSchemaOnSchemaViolation;
  onUndeclaredFields: EventSchemaOnUndeclaredFields;
  isDirty: boolean;
};

export type SimpleContractFormState = {
  properties: ContractProperty[];
};

export type ContractProperty = {
  name: string;
  type: string;
  description?: string;
  required?: boolean;
  properties: ContractProperty[];
};

function assert(condition: any, msg?: string) {
  if (!condition) {
    throw new Error(msg);
  }
}

const isEventType = isEnum(EventSchemaEventType);
const isSchemaViolation = isEnum(EventSchemaOnSchemaViolation);
const isUndeclaredFields = isEnum(EventSchemaOnUndeclaredFields);

// A util for converting event schema fields into proper enums in a type-safe way
export const assertEventSchema = (schema: EventSchemaApiType): EventSchema => {
  const { event_type, on_schema_violation, on_undeclared_fields } = schema;

  assert(
    isEventType(event_type),
    `Unexpected value for event_type: ${event_type}`,
  );

  assert(
    isSchemaViolation(on_schema_violation),
    `Unexpected value for on_schema_violation: ${on_schema_violation}`,
  );

  assert(
    isUndeclaredFields(on_undeclared_fields),
    `Unexpected value for on_undeclared_fields: ${on_undeclared_fields}`,
  );

  return schema as EventSchema;
};
