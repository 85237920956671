import * as Yup from "yup";
import {
  AndCondition,
  AndOrCondition,
  ConditionType,
  OrCondition,
} from "src/types/visual";

export type DestinationRuleSchema = {
  destination_id: string;
  conditions: AndOrCondition<AndCondition | OrCondition>;
};

export const destinationRuleValidation: Yup.Schema<DestinationRuleSchema> =
  Yup.object({
    destination_id: Yup.string().required("Destination is required"),
    conditions: Yup.object(),
  }).required();

export const DEFAULT_CONDITIONS = {
  type: ConditionType.And,
  conditions: [],
};

export const DESTINATION_RULES_BASE_PATH = "/governance/destination-rules";
