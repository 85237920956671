import { FC } from "react";

import {
  Alert,
  CloseIcon,
  Column,
  LinkIcon,
  Radio,
  Row,
  SuccessIcon,
  Text,
  WarningIcon,
} from "@hightouchio/ui";

import { Link } from "src/router";

import {
  CanDeployModelsQuery,
  DeploymentOperation,
  LinkableWorkspaceResourcesQuery,
  ValidatorNames,
} from "src/graphql";
import { diff } from "src/utils/time";

import { Deployment, TargetWorkspace, ValidationResult } from "./common";
import { DeploymentDiff } from "./diff";
import { ResourceLinkForm } from "./linking";

type FailedDeploymentValidationResult = Extract<
  ValidationResult[0],
  { __typename: "FailedDeploymentValidationResult" }
>;

const getExtensionSetupLink = (
  stepName: ValidatorNames,
  workspaceSlug: string,
) => {
  let extensionTarget = "";
  switch (stepName) {
    case ValidatorNames.DbtModelExists:
      extensionTarget = "dbt";
      break;
    case ValidatorNames.LookerExtensionExists:
      extensionTarget = "looker";
      break;
    case ValidatorNames.SigmaExtensionExists:
      extensionTarget = "sigma";
      break;
  }
  return `${
    import.meta.env.VITE_APP_BASE_URL
  }/${workspaceSlug}/extensions/${extensionTarget}`;
};

const ReasonSupplement: FC<{
  deployment: Deployment;
  result: FailedDeploymentValidationResult;
  targetWorkspace: TargetWorkspace;
}> | null = ({ deployment, result, targetWorkspace }) => {
  switch (result.reason) {
    case "ExtensionSetup": {
      const link = getExtensionSetupLink(
        result.stepName,
        targetWorkspace?.slug || "",
      );
      switch (result.stepName) {
        case ValidatorNames.DbtModelExists:
          return (
            <Text>
              {result.message}. Please <Link href={link}>resolve</Link> this
              issue in this workspace.
            </Text>
          );
        // We are just soft-disabling the sync schedule deployment checkbox if this
        // check failed
        case ValidatorNames.ScheduleExtensions:
          return null;
        default: //
          return (
            <Text>
              {result.message}. Please <Link href={link}>set up</Link> this
              extension.
            </Text>
          );
      }
    }
    case "Draft":
      return (
        <Text>
          The {deployment.resourceName.toLowerCase()} has an existing draft
        </Text>
      );
    case "Unauthorized":
      return (
        <Text>
          {result.message}. Please contact one of your workspace admins.
        </Text>
      );
    case "SegmentDeploymentNeeded":
      if (result.fatal) {
        return (
          <Text>
            The model must be deployed before this sync can be deployed.{" "}
            <Link href={`/models/${deployment.metadata?.modelId}`}>
              Please deploy the model first.
            </Link>
          </Text>
        );
      } else {
        return (
          <Text>
            There are changes that are undeployed in the target workspace.{" "}
            <Link href={`/models/${deployment.metadata?.modelId}`}>
              Deploy the model first or continue anyway.
            </Link>
          </Text>
        );
      }
    default:
      return (
        <Text>
          Check {result.stepName} ({result.reason}): {result.message}
        </Text>
      );
  }
};

const CantDeploy: FC<{
  deployment: Deployment;
  results: ValidationResult;
  targetWorkspace: TargetWorkspace;
}> = ({ results, deployment, targetWorkspace }) => (
  <Column gap={1}>
    <Row alignItems="center" width="100%" gap={2}>
      <Row>
        <CloseIcon color="danger.base" />
      </Row>
      <Text>Can't deploy</Text>
    </Row>
    {results.map((result, idx) => {
      if (!result || result.__typename !== "FailedDeploymentValidationResult") {
        return null;
      }

      return (
        <ReasonSupplement
          key={idx}
          deployment={deployment}
          result={result}
          targetWorkspace={targetWorkspace}
        />
      );
    })}
  </Column>
);

const DeployWithCaution: FC<{
  deployment: Deployment;
  results: ValidationResult;
  targetWorkspace: TargetWorkspace;
}> = ({ deployment, results, targetWorkspace }) => {
  return (
    <Column>
      {results.map((result, index) => {
        if (
          !result ||
          result.__typename !== "FailedDeploymentValidationResult"
        ) {
          return null;
        }

        const reasonSupplement = ReasonSupplement({
          deployment,
          result,
          targetWorkspace,
        });
        if (!reasonSupplement) return null;
        return (
          <Row alignItems="center" width="100%" gap={2} key={index}>
            <Row>
              <WarningIcon color="warning.base" />
            </Row>
            <ReasonSupplement
              deployment={deployment}
              result={result}
              targetWorkspace={targetWorkspace}
            />
          </Row>
        );
      })}
    </Column>
  );
};

const CanDeploy: FC = () => (
  <Row alignItems="center" gap={2}>
    <SuccessIcon color="success.base" />
    <Text>Ready to deploy</Text>
  </Row>
);

const NeedsLinkingDeploy: FC<{ resourceName: string }> = ({ resourceName }) => (
  <Row alignItems="center" gap={2}>
    <LinkIcon color="text.secondary" />
    <Text>No linked {resourceName}</Text>
  </Row>
);

const NoopDeploy: FC = () => (
  <Row alignItems="center" gap={2}>
    <SuccessIcon color="success.base" />
    <Text>Nothing to deploy</Text>
  </Row>
);

export const DeploymentTargetRadio: FC<{
  deployment: Deployment;
  targetWorkspace: TargetWorkspace;
  deploymentTest?: CanDeployModelsQuery["canDeploySegments"][0];
  getDeploymentDiff: (
    deployment: Deployment,
    sourceObj: any,
    targetObj: any,
  ) => DeploymentDiff[];
  isSelected: boolean;
  linkableResources:
    | LinkableWorkspaceResourcesQuery["getLinkableResources"]
    | undefined;
  loading: boolean;
}> = ({
  deployment,
  targetWorkspace,
  deploymentTest,
  getDeploymentDiff,
  isSelected,
  linkableResources,
  loading,
}) => {
  const dependentResourceType =
    deployment.resourceType === "segments" ? "connections" : "destinations";
  const dependentResourceName =
    deployment.resourceType === "segments" ? "source" : "destination";

  const isFailedTest =
    deploymentTest?.__typename === "FailedDeploymentTestResult";

  const isNoop =
    !isFailedTest &&
    deploymentTest?.type === DeploymentOperation.Update &&
    getDeploymentDiff(
      deployment,
      deploymentTest.sourceObj,
      deploymentTest.targetObj,
    ).length === 0;

  const isCaution =
    !isFailedTest &&
    deploymentTest?.results.some(
      (result) => result?.__typename === "FailedDeploymentValidationResult",
    );

  const isLinkable =
    isFailedTest &&
    deploymentTest?.results.every(
      (result) =>
        result?.__typename === "FailedDeploymentValidationResult" &&
        result?.reason === "UnlinkedDependency",
    );

  const hasLinkableResources = linkableResources?.some(
    (r) =>
      String(r.workspace.id) === String(targetWorkspace?.id) &&
      !r.existing_link,
  );

  const hasLinked =
    !isFailedTest && deploymentTest?.type === DeploymentOperation.Update;
  const lastDeployed = hasLinked
    ? deploymentTest?.history?.slice(-1)?.[0]?.created_at
    : null;

  let description;

  if (isNoop) {
    description = <NoopDeploy />;
  } else if (isCaution) {
    description = (
      <DeployWithCaution
        deployment={deployment}
        results={deploymentTest?.results || []}
        targetWorkspace={targetWorkspace}
      />
    );
  } else if (!isFailedTest) {
    description = <CanDeploy />;
  } else if (isLinkable) {
    description = <NeedsLinkingDeploy resourceName={dependentResourceName} />;
  } else {
    description = (
      <CantDeploy
        deployment={deployment}
        results={deploymentTest?.results}
        targetWorkspace={targetWorkspace}
      />
    );
  }

  return (
    <Column>
      <Row justifyContent="space-between" alignItems="start">
        <Radio
          value={targetWorkspace.id}
          isDisabled={(isFailedTest && !isLinkable) || isNoop}
          label={targetWorkspace.name}
          description={description}
        />
        {!isFailedTest &&
          (hasLinked ? (
            lastDeployed ? (
              <Text color="text.secondary">
                Last deployed{" "}
                {diff(new Date().toISOString(), lastDeployed ?? "")} ago
              </Text>
            ) : (
              <Text color="text.secondary">Deployed from this workspace</Text>
            )
          ) : (
            <Text color="text.secondary">Never deployed</Text>
          ))}
      </Row>

      {isLinkable &&
        isSelected &&
        (hasLinkableResources ? (
          <Column
            border="1px"
            borderColor="base.border"
            borderRadius="md"
            p={4}
            ml={6}
            mt={2}
            mb={4}
          >
            <ResourceLinkForm
              targetResources={
                linkableResources?.filter((r) => !r.existing_link) ?? []
              }
              sourceResourceId={
                deployment.metadata?.connectionId ||
                deployment.metadata?.destinationId
              }
              loadingResources={loading}
              resourceName={dependentResourceName}
              resourceType={dependentResourceType}
              targetWorkspace={targetWorkspace}
            />
          </Column>
        ) : (
          <Alert
            variant="inline"
            type="warning"
            title={`No ${dependentResourceName} of the same type found in ${targetWorkspace.name}`}
            message={`In order to deploy this resource, you'll first need to create a ${dependentResourceName} of the same type in ${targetWorkspace.name}.`}
            ml={6}
            mt={2}
            mb={4}
          />
        ))}
    </Column>
  );
};
