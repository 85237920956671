import { FC } from "react";

import { Box, Radio, RadioGroup, Select, Text } from "@hightouchio/ui";

import dbtLogo from "./images/dbt-logo.svg";
import googleSheetsLogo from "./images/google-sheets-logo.svg";
import lookerLogo from "./images/looker-logo.svg";
import schemaIcon from "./images/schema-icon.svg";
import sigmaLogo from "./images/sigma-logo.svg";

const noop = () => {};

export const BrowserImage: FC = () => (
  <Box
    aria-hidden
    bg="white"
    border="1px"
    borderColor="base.border"
    borderRadius="md"
    className="preview-image"
    height="100px"
    overflow="hidden"
    p={4}
    pointerEvents="none"
  >
    <Box transform="scale(0.8)" transformOrigin="top left">
      <RadioGroup value="one" onChange={noop}>
        <Radio label="Source 1" value="one" />
        <Radio label="Source 2" value="two" />
      </RadioGroup>

      <Select
        mt={4}
        optionAccessory={() => ({
          type: "image",
          url: googleSheetsLogo,
        })}
        options={[
          {
            label: "Select an object",
            value: "default",
          },
        ]}
        value="default"
        onChange={noop}
      />
    </Box>
  </Box>
);

export const DbtImage: FC = () => (
  <Box
    aria-hidden
    bg="white"
    border="1px"
    borderColor="base.border"
    borderRadius="md"
    className="preview-image"
    display="grid"
    gridTemplateColumns="1fr"
    gridTemplateRows="repeat(3, 1fr)"
    height="100px"
    overflow="hidden"
    pointerEvents="none"
    sx={{
      "*": {
        fontSize: "sm",
      },
    }}
  >
    <Box
      alignItems="center"
      borderBottom="1px"
      borderColor="gray.300"
      display="flex"
      gap={2}
      px={2.5}
    >
      <Box as="img" boxSize={3} src={dbtLogo} />
      <Text fontWeight="medium">dbt.model.name.1</Text>
    </Box>

    <Box
      alignItems="center"
      borderBottom="1px"
      borderColor="gray.300"
      display="flex"
      gap={2}
      px={2.5}
    >
      <Box as="img" boxSize={3} src={dbtLogo} />
      <Text fontWeight="medium">dbt.model.name.2</Text>
    </Box>

    <Box alignItems="center" display="flex" gap={2} px={2.5}>
      <Box as="img" boxSize={3} src={dbtLogo} />
      <Text fontWeight="medium">dbt.model.name.3</Text>
    </Box>
  </Box>
);

export const LookerImage: FC = () => (
  <Box
    aria-hidden
    bg="white"
    border="1px"
    borderColor="base.border"
    borderRadius="md"
    className="preview-image"
    display="grid"
    gridTemplateColumns="1fr"
    gridTemplateRows="repeat(3, 1fr)"
    height="100px"
    overflow="hidden"
    pointerEvents="none"
    sx={{
      "*": {
        fontSize: "sm",
      },
    }}
  >
    <Box
      alignItems="center"
      borderBottom="1px"
      borderColor="gray.300"
      display="flex"
      gap={2}
      px={2.5}
    >
      <Box as="img" boxSize={3} src={lookerLogo} />
      <Text fontWeight="medium">Look 1</Text>
    </Box>

    <Box
      alignItems="center"
      borderBottom="1px"
      borderColor="gray.300"
      display="flex"
      gap={2}
      px={2.5}
    >
      <Box as="img" boxSize={3} src={lookerLogo} />
      <Text fontWeight="medium">Look 2</Text>
    </Box>

    <Box alignItems="center" display="flex" gap={2} px={2.5}>
      <Box as="img" boxSize={3} src={lookerLogo} />
      <Text fontWeight="medium">Look 3</Text>
    </Box>
  </Box>
);

export const SigmaImage: FC = () => (
  <Box
    aria-hidden
    bg="white"
    border="1px"
    borderColor="base.border"
    borderRadius="md"
    className="preview-image"
    display="grid"
    gridTemplateColumns="1fr"
    gridTemplateRows="repeat(3, 1fr)"
    height="100px"
    overflow="hidden"
    pointerEvents="none"
    sx={{
      "*": {
        fontSize: "sm",
      },
    }}
  >
    <Box
      alignItems="center"
      borderBottom="1px"
      borderColor="gray.300"
      display="flex"
      gap={2}
      px={2.5}
    >
      <Box as="img" boxSize={4} src={sigmaLogo} />
      <Text fontWeight="medium">Workbook 1</Text>
    </Box>

    <Box
      alignItems="center"
      borderBottom="1px"
      borderColor="gray.300"
      display="flex"
      gap={2}
      px={2.5}
    >
      <Box as="img" boxSize={4} src={sigmaLogo} />
      <Text fontWeight="medium">Workbook 2</Text>
    </Box>

    <Box alignItems="center" display="flex" gap={2} px={2.5}>
      <Box as="img" boxSize={4} src={sigmaLogo} />
      <Text fontWeight="medium">Workbook 3</Text>
    </Box>
  </Box>
);

export const SQLImage: FC = () => (
  <Box
    aria-hidden
    bg="white"
    border="1px"
    borderColor="base.border"
    borderRadius="md"
    className="preview-image"
    height="100px"
    overflow="hidden"
    pointerEvents="none"
    position="relative"
  >
    <Box bg="avocado.100" height="100%" width="34px" />

    <Box
      alignItems="center"
      display="flex"
      fontFamily="mono"
      fontSize="11px"
      gap={2}
      left={0}
      position="absolute"
      right={0}
      top={4}
    >
      <Box
        bg="forest.600"
        color="white"
        pr={2}
        py={0.5}
        textAlign="right"
        width="34px"
      >
        <Box as="span">1</Box>
      </Box>

      <Box as="span">select * from users;</Box>
      <Box bg="forest.600" h={4} w={0.5} />
    </Box>
  </Box>
);

export const TableImage: FC = () => (
  <Box
    aria-hidden
    bg="white"
    border="1px"
    borderColor="base.border"
    borderRadius="md"
    className="preview-image"
    display="grid"
    gridTemplateColumns="1fr"
    gridTemplateRows="repeat(3, 1fr)"
    height="100px"
    overflow="hidden"
    pointerEvents="none"
    sx={{
      "*": {
        fontSize: "sm",
      },
    }}
  >
    <Box
      alignItems="center"
      borderBottom="1px"
      borderColor="gray.300"
      display="flex"
      gap={2}
      px={2.5}
    >
      <Box as="img" boxSize={4} src={schemaIcon} />
      <Text fontWeight="medium">schema.table.name.1</Text>
    </Box>

    <Box
      alignItems="center"
      borderBottom="1px"
      borderColor="gray.300"
      display="flex"
      gap={2}
      px={2.5}
    >
      <Box as="img" boxSize={4} src={schemaIcon} />
      <Text fontWeight="medium">schema.table.name.2</Text>
    </Box>

    <Box alignItems="center" display="flex" gap={2} px={2.5}>
      <Box as="img" boxSize={4} src={schemaIcon} />
      <Text fontWeight="medium">schema.table.name.3</Text>
    </Box>
  </Box>
);
