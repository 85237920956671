import { TraitConfig, TraitType } from "@hightouch/lib/query/visual/types";
import {
  Button,
  ButtonGroup,
  CloseIcon,
  Column,
  DrawerBody,
  DrawerFooter,
  EditableDescription,
  EditableHeading,
  IconButton,
  Row,
  useToast,
} from "@hightouchio/ui";
import { captureException } from "@sentry/react";
import { Controller } from "react-hook-form";
import { useNavigate, useParams } from "src/router";

import { Drawer } from "src/components/drawer";
import { Form, useHightouchForm } from "src/components/form";
import { useFormErrorContext } from "src/contexts/form-error-context";
import {
  TraitDefinitionsConstraint,
  TraitDefinitionsInsertInput,
  useCreateTraitMutation,
  useTraitQuery,
} from "src/graphql";
import { NotFound } from "src/pages/not-found";

import { DUPLICATE_TRAIT_NAME_ERROR_MESSAGE } from "./constants";
import { Metadata } from "./metadata";
import { TraitCalculationForm } from "./trait-calculation-form";
import {
  CalculationMethod,
  formatTraitConfig,
  traitTypeToCalculationMethod,
  validateConfig,
  validateTraitConfig,
} from "./utils";

export const DuplicateTrait = () => {
  const { traitId } = useParams();

  const navigate = useNavigate();
  const { toast } = useToast();
  const createTrait = useCreateTraitMutation();

  const { hasValidationErrors } = useFormErrorContext();

  const { data, isLoading } = useTraitQuery(
    { id: traitId ?? "" },
    { enabled: traitId != null },
  );
  const trait = data?.trait_definitions_by_pk;

  const form = useHightouchForm({
    // TODO(samuel): Set up trait validation with yup
    values: trait
      ? {
          name: `${trait.name} (Duplicate)`,
          description: trait.description,
          parent_model_id: trait.parent_model.id,
          relationship_id: trait.relationship?.id,
          calculation_method: traitTypeToCalculationMethod[trait.type],
          type: trait.type as TraitType,
          config: trait.config as TraitConfig,
        }
      : {
          name: "",
          description: "",
          parent_model_id: undefined,
          relationship_id: undefined,
          calculation_method: CalculationMethod.Aggregation,
          type: TraitType.Count,
          config: {},
        },
    onSubmit: async (formData) => {
      if (
        hasValidationErrors() ||
        !validateConfig(formData.type, formData.config)
      ) {
        throw new Error("Trait form has validation errors");
      }

      try {
        const input: TraitDefinitionsInsertInput = {
          name: formData.name,
          description: formData.description,
          parent_model_id: formData.parent_model_id,
          relationship_id: formData.relationship_id,
          type: formData.type,
          config: formatTraitConfig(
            formData.type,
            formData.config,
            trait?.parent_model,
          ),
          is_template: false,
        };

        const newTrait = await createTrait.mutateAsync({
          input,
        });

        const newTraitId = newTrait.insert_trait_definitions_one?.id;
        navigate(`/traits/active/${newTraitId}`);
      } catch (error) {
        // Add a more helpful error message
        throw error.message.includes(
          TraitDefinitionsConstraint.TraitDefinitionsNameParentModelIdKey,
        )
          ? new Error(DUPLICATE_TRAIT_NAME_ERROR_MESSAGE)
          : error;
      }
    },
    onError: (error) => {
      if (error.message !== DUPLICATE_TRAIT_NAME_ERROR_MESSAGE) {
        captureException(error);
      }
    },
  });

  const type = form.watch("type");
  const config = form.watch("config");

  const onCancel = () => navigate(`/traits/active/${traitId}`);
  const onCloseDrawer = () => navigate("/traits/active");

  const isCreateDisabled =
    createTrait.isLoading || !validateConfig(type, config);

  return (
    <Drawer isLoading={isLoading} isOpen size="xl" onClose={onCloseDrawer}>
      {!trait ? (
        <DrawerBody>
          <NotFound />
        </DrawerBody>
      ) : (
        <Form form={form}>
          <Row
            justifyContent="space-between"
            p={6}
            borderBottom="1px solid"
            borderColor="base.border"
            width="100%"
          >
            <Column>
              <Controller
                control={form.control}
                name="name"
                render={({ field }) => (
                  <EditableHeading
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
              <Controller
                control={form.control}
                name="description"
                render={({ field }) => (
                  <EditableDescription
                    value={field.value ?? ""}
                    onChange={field.onChange}
                  />
                )}
              />
              <Metadata
                parentModel={trait.parent_model}
                isTemplate={trait.is_template}
                isArchived={Boolean(trait.archived_at)}
              />
            </Column>
            <IconButton
              icon={CloseIcon}
              aria-label="Close"
              onClick={onCloseDrawer}
            />
          </Row>

          <DrawerBody>
            <Column overflowY="auto" flex={1}>
              <TraitCalculationForm
                parentModel={trait.parent_model}
                onPreviewValidation={() =>
                  validateTraitConfig(hasValidationErrors, toast)
                }
              />
            </Column>
          </DrawerBody>

          <DrawerFooter>
            <ButtonGroup>
              <Button
                isDisabled={isCreateDisabled}
                size="lg"
                variant="primary"
                onClick={form.submit}
              >
                Save changes
              </Button>
              <Button size="lg" onClick={onCancel}>
                Cancel
              </Button>
            </ButtonGroup>
          </DrawerFooter>
        </Form>
      )}
    </Drawer>
  );
};
