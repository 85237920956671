import { FC } from "react";

import { Box, Code, Column, Row, Text } from "@hightouchio/ui";

import { FormProps, StaticType } from "../types";
import { NEW_STATIC_ICONS } from "../utils";

enum VariableType {
  CURRENT_TIMESTAMP = "current-timestamp",
  MODEL_ID = "model-id",
  MODEL_NAME = "model-name",
  SYNC_ID = "sync-id",
  SYNC_RUN_ID = "sync-run-id",
  OPERATION = "operation",
}

interface ValueProp {
  type: "variable";
  variable: string;
}

export const VARIABLE_OPTIONS = [
  {
    description: <>The ID of the current model as a string.</>,
    label: "Model ID",
    type: StaticType.STRING,
    variable: VariableType.MODEL_ID,
  },
  {
    description: <>The name of the current model as a string.</>,
    label: "Model name",
    type: StaticType.STRING,
    variable: VariableType.MODEL_NAME,
  },
  {
    description: <>The ID of the current sync as an integer.</>,
    label: "Sync ID",
    type: StaticType.NUMBER,
    variable: VariableType.SYNC_ID,
  },
  {
    description: <>The ID of the current sync run as an integer.</>,
    label: "Sync run ID",
    type: StaticType.NUMBER,
    variable: VariableType.SYNC_RUN_ID,
  },
  {
    description: (
      <>
        The current timestamp as an ISO 8601 string (
        <Code>"YYYY-MM-DDTHH:mm:ss.sssZ"</Code>).
      </>
    ),
    label: "Current timestamp",
    type: StaticType.DATETIME,
    variable: VariableType.CURRENT_TIMESTAMP,
  },
  {
    description: (
      <>
        The CDC operation <Code>add</Code>, <Code>change</Code>,
        <Code>remove</Code>, or <Code>all</Code> as a string.
      </>
    ),
    label: "Operation",
    type: StaticType.STRING,
    variable: VariableType.OPERATION,
  },
];

export const VariableInput: FC<
  Readonly<
    FormProps & {
      onClose: () => void;
      onChange: (onClose: () => void, value: ValueProp) => void;
    }
  >
> = ({ onClose, onChange, value }) => {
  return (
    <Column height="249px" mx={3} mt={1} overflow="auto">
      {VARIABLE_OPTIONS.map(({ description, label, type, variable }) => {
        const isActive = variable === value.variable;
        const Icon = NEW_STATIC_ICONS[type];
        return (
          <Row
            _hover={{
              bg: "gray.100",
            }}
            alignItems="center"
            bg={isActive ? "forest.100" : undefined}
            borderBottom="1px"
            borderColor="base.border"
            cursor="pointer"
            key={label}
            minHeight="32px"
            pointerEvents={isActive ? "none" : undefined}
            px={2}
            py="6px"
            sx={
              isActive
                ? {
                    span: {
                      color: "forest.600",
                    },
                  }
                : undefined
            }
            onClick={() => {
              onChange(onClose, { type: "variable", variable });
            }}
          >
            <Box mr={3}>
              <Box as={Icon} color="text.secondary" fontSize="20px" />
            </Box>
            <Box
              as={Text}
              color="text.primary"
              fontSize="sm"
              fontWeight="medium"
              mr={4}
              width="256px"
            >
              {label}
            </Box>
            <Box as={Text} color="text.secondary" fontSize="sm" width="442px">
              {description}
            </Box>
          </Row>
        );
      })}
    </Column>
  );
};
