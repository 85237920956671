import { FC } from "react";

import {
  CloseIcon,
  Column,
  DrawerHeader,
  EmptyState,
  IconButton,
  Row,
  SectionHeading,
} from "@hightouchio/ui";

import { LinkButton } from "src/router";

import genericPlaceholder from "src/assets/placeholders/generic.svg";

export const NotFoundForm: FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <>
      <DrawerHeader>
        <Row align="center" justify="space-between" flex={1} minWidth={0}>
          <SectionHeading>Tile not found</SectionHeading>{" "}
          <IconButton
            aria-label="Close drawer."
            icon={CloseIcon}
            onClick={onClose}
          />
        </Row>
      </DrawerHeader>
      <Column justify="center" px={6} mt={10}>
        <EmptyState
          imageUrl={genericPlaceholder}
          title="Tile not found"
          message="The tile may have been removed. Please close this window and click on the tile you wish to inspect."
          actions={
            <LinkButton href="../" variant="primary">
              Go back
            </LinkButton>
          }
        />
      </Column>
    </>
  );
};
