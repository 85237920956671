import { FC } from "react";

import { Box, Column, Row, SectionHeading, Skeleton } from "@hightouchio/ui";

interface Props {
  title: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
  header?: React.ReactNode;
  icon?: React.ReactNode;
  isLoading: boolean;
  empty?: boolean;
}

export const DashboardSidebarItem: FC<Props> = ({
  footer,
  title,
  children,
  header,
  isLoading,
  icon,
  empty = false,
}) => {
  return (
    <Column gap={2}>
      <Row alignItems="center" justifyContent="space-between" gap={2}>
        {!icon ? (
          <SectionHeading>{title}</SectionHeading>
        ) : (
          <SectionHeading>
            <Row
              gap={2}
              align="center"
              sx={{ svg: { width: "18px", height: "18px" } }}
            >
              {icon} {title}
            </Row>
          </SectionHeading>
        )}
        {header}
      </Row>
      {!empty && !isLoading ? (
        children
      ) : (
        <Skeleton isLoading={isLoading}>{children}</Skeleton>
      )}
      {footer && <Box py={2}>{footer}</Box>}
    </Column>
  );
};
