import { StatusBadge } from "@hightouchio/ui";
import {
  SyncHealthOrUnknown,
  SyncHealthStatus,
  UnknownSyncHealth,
} from "src/utils/syncs";

// This solution will be safer when we turn on noImplicitAny, but until then, there is strict checking on both sides of it.
export const SyncHealthBadge = ({
  health,
  syncEnabled,
}: {
  health: SyncHealthOrUnknown;
  syncEnabled: boolean;
}): JSX.Element => {
  const healthStatus = syncEnabled ? health : SyncHealthStatus.Disabled;

  const variants = {
    [SyncHealthStatus.Unhealthy]: "error",
    [SyncHealthStatus.Healthy]: "success",
    [SyncHealthStatus.Warning]: "warning",
    [SyncHealthStatus.Disabled]: "inactive",
    [SyncHealthStatus.Pending]: "draft",
    [UnknownSyncHealth]: "draft",
  } as const;

  const healthText = {
    [SyncHealthStatus.Disabled]: "Disabled",
    [SyncHealthStatus.Healthy]: "Healthy",
    [SyncHealthStatus.Unhealthy]: "Unhealthy",
    [SyncHealthStatus.Warning]: "Warning",
    [SyncHealthStatus.Pending]: "Pending",
    [UnknownSyncHealth]: "Unknown",
  };

  return (
    <StatusBadge variant={variants[healthStatus]}>
      {healthText[healthStatus]}
    </StatusBadge>
  );
};
