import {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  Box,
  BulbSuggestionIcon,
  Button,
  ChakraPopover,
  ChakraPopoverContent,
  ChakraPopoverTrigger,
  Column,
  EditIcon,
  FormField,
  IconButton,
  Menu,
  MenuIconButton,
  MenuList,
  NumberIcon,
  Pill,
  Portal,
  QuestionIcon,
  RefreshIcon,
  Row,
  SearchInput,
  SectionHeading,
  Select,
  SettingsIcon,
  Text,
  TextInput,
  TextStringIcon,
  Tooltip,
  WarningIcon,
  useDisclosure,
  useToast,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";

import {
  PermissionedButton,
  PermissionedEditableDescription,
  PermissionedIconButton,
  PermissionedMenuItem,
  PermissionedSwitch,
} from "src/components/permission";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import {
  ObjectColumnFragment,
  useUpdateModelColumnDescriptionMutation,
  useUpdateModelColumnMutation,
  useUpdateModelColumnsMutation,
  useUpdateWhitelistedModelColumnsMutation,
} from "src/graphql";
import { ColumnDateTypes, ColumnType } from "src/types/visual";
import { Pagination, Table } from "src/ui/table";
import { getColumnTypeCastOptions } from "src/utils/schema";
import { getSearchRegExp } from "src/utils/string";
import { formatDate } from "src/utils/time";

import { useResourcePermission } from "src/components/permission/use-resource-permission";
import { ALL_COLUMN_OPTIONS } from "./constants";
import { SUPPORTED_JSON_ARRAY_SOURCES, useBigintSupport } from "./utils";
import { accurateCommaNumber } from "src/utils/numbers";

type Props = {
  source?: any;
  modelId: string;
  columns: ObjectColumnFragment[] | undefined;
  primaryKey?: string;
};

export enum RefreshIntervals {
  Hourly = 3600,
  Daily = 86400,
  Weekly = 604800,
  BiWeekly = 1209600,
  Monthly = 2419200,
  ManualOnly = -1,
}

const REFRESH_OPTIONS = [
  {
    label: "Hourly",
    value: RefreshIntervals.Hourly,
  },
  {
    label: "Daily",
    value: RefreshIntervals.Daily,
  },
  {
    label: "Weekly",
    value: RefreshIntervals.Weekly,
  },
  {
    label: "Bi-weekly",
    value: RefreshIntervals.BiWeekly,
  },
  {
    label: "Monthly",
    value: RefreshIntervals.Monthly,
  },
  {
    label: "Manual Only",
    value: RefreshIntervals.ManualOnly,
  },
];

const SUPPORTED_SUGGESTION_SOURCES = [
  "postgres",
  "bigquery",
  "snowflake",
  "athena",
  "redshift",
  "databricks",
  "sample-data",
  "trino",
  "bigquery-sf",
];

const JSON_ARRAY_OPTIONS = [
  {
    label: "JSON Array (Strings)",
    description: "Compatible with the audience builder.",
    value: ColumnType.JsonArrayStrings,
    icon: TextStringIcon,
  },
  {
    label: "JSON Array (Numbers)",
    description: "Compatible with the audience builder.",
    value: ColumnType.JsonArrayNumbers,
    icon: NumberIcon,
  },
  {
    label: "Unknown JSON",
    description: "Not compatible with the audience builder.",
    value: ColumnType.Json,
    icon: QuestionIcon,
  },
];

export const getColumnTypeLabel = (type: string) => {
  return (
    ALL_COLUMN_OPTIONS.find((option) => option.value === type)?.label ??
    "Unknown"
  );
};

export const getColumnTypeIcon = (type: string) => {
  return (
    ALL_COLUMN_OPTIONS.find((option) => option.value === type)?.icon ??
    QuestionIcon
  );
};

const limitErrorMessage = (error: string) => {
  const count = parseInt(error.split("storing only ")[1] || "10000");
  return `Too many distinct values. Only the ${accurateCommaNumber(
    count,
  )} most frequent values will be shown as suggestions.`;
};

const ROW_LIMIT = 20;

export const ColumnSettings: FC<Readonly<Props>> = ({
  modelId,
  primaryKey,
  source,
  columns,
}) => {
  const { allowBigints } = useBigintSupport(source?.type);

  const { toast } = useToast();
  const { isPermitted: hasUpdatePermission } = useResourcePermission({
    v2: { resource: "model", grant: "can_update", id: modelId },
    v1: { resource: "audience_schema", grant: "update" },
  });

  const [search, setSearch] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(0);

  const [objectColumns, setObjectColumns] = useState(columns);

  const filteredColumns = useMemo(() => {
    const regex = getSearchRegExp(search, "i");
    return (objectColumns ?? []).filter(
      ({ name, alias }) => regex.test(name) || regex.test(alias ?? ""),
    );
  }, [search, objectColumns]);

  useEffect(() => {
    setObjectColumns(columns);
  }, [columns]);

  // If the search query reduces the number of pages, we need to make sure that
  // we aren't on a page that is no longer rendered. If we are, go back to the first page.
  useLayoutEffect(() => {
    const totalPages = Math.ceil(filteredColumns.length / ROW_LIMIT);
    if (currentPage >= totalPages) {
      // currentPage is zero-indexed, so we check for equality
      setCurrentPage(0);
    }
  }, [filteredColumns.length, currentPage]);

  const refreshInterval = objectColumns?.find(({ top_k_sync_interval }) =>
    Boolean(top_k_sync_interval),
  )?.top_k_sync_interval;
  const refreshing = Boolean(
    objectColumns?.find(
      ({ trigger_top_k_sync, top_k_enabled, disable }) =>
        top_k_enabled && trigger_top_k_sync && !disable,
    ),
  );
  const [currentRefreshInterval, setCurrentRefreshInterval] = useState<number>(
    refreshInterval !== undefined && refreshInterval !== null
      ? refreshInterval
      : RefreshIntervals.ManualOnly,
  );
  const suggestionsDisabled = !SUPPORTED_SUGGESTION_SOURCES.includes(
    source?.type,
  );
  const jsonArraysEnabled = SUPPORTED_JSON_ARRAY_SOURCES.includes(source?.type);
  const allColumnsDisabled = filteredColumns.every((column) => !column.disable);

  const { mutateAsync: updateColumn, isLoading: modelUpdating } =
    useUpdateModelColumnMutation();
  const { mutateAsync: updateColumns, isLoading: columnsUpdating } =
    useUpdateModelColumnsMutation();
  const {
    mutateAsync: updateWhitelistedColumns,
    isLoading: updatingWhitelistedColumns,
  } = useUpdateWhitelistedModelColumnsMutation();

  const updateModelColumnDescriptionMutation =
    useUpdateModelColumnDescriptionMutation();

  const isLoading =
    modelUpdating ||
    columnsUpdating ||
    updatingWhitelistedColumns ||
    updateModelColumnDescriptionMutation.isLoading;

  const toggleColumnDisabled = async ({
    name,
    value,
  }: {
    name: string;
    value: boolean;
  }) => {
    try {
      const result = await updateColumn({
        id: modelId,
        name,
        input: { disable: !value },
      });

      setObjectColumns(result?.update_model_columns_by_pk?.model?.columns);
      toast({
        id: "update-column",
        title: `Updated column settings for ${name}`,
        variant: "success",
      });
    } catch (error) {
      Sentry.captureException(error);
      toast({
        id: "update-column",
        title: `Couldn't update column settings for ${name}`,
        variant: "error",
      });
    }
  };

  const handleUpdateColumns = async (value: number | undefined) => {
    try {
      if (value) {
        setCurrentRefreshInterval(value);

        const result = await updateColumns({
          id: modelId,
          input: {
            top_k_sync_interval: value,
          },
        });

        setObjectColumns(
          result?.update_model_columns?.returning?.[0]?.model?.columns,
        );

        toast({
          id: "update-columns",
          title: "Updated column settings",
          variant: "success",
        });
      }
    } catch (error) {
      Sentry.captureException(error);

      toast({
        id: "update-columns",
        title: "Couldn't update column settings",
        variant: "error",
      });
    }
  };

  const toggleAllSuggestions = useCallback(
    async (enable: boolean) => {
      if (suggestionsDisabled) return;

      // Certain columns can't have their suggestions toggled, so we need to exclude them
      const toggleableColumns: string[] = filteredColumns
        .filter(
          (column) =>
            !ColumnDateTypes.includes(column.type as ColumnType) &&
            !(enable && (column.disable || column.disable_preview)),
        )
        .map((column) => column.name);

      try {
        const result = await updateWhitelistedColumns({
          model_id: modelId,
          input: {
            top_k_enabled: enable,
          },
          whitelist: toggleableColumns,
        });

        setObjectColumns(
          result?.update_model_columns?.returning?.[0]?.model?.columns,
        );
        toast({
          id: "update-columns",
          title: "Updated column settings",
          variant: "success",
        });
      } catch (error) {
        Sentry.captureException(error);
        toast({
          id: "update-columns",
          title: "Couldn't update column settings",
          variant: "error",
        });
      }
    },
    [suggestionsDisabled, filteredColumns, updateWhitelistedColumns, modelId],
  );

  const toggleAllColumnsEnabled = useCallback(
    async (enable: boolean) => {
      try {
        const result = await updateColumns({
          id: modelId,
          input: {
            disable: !enable,
          },
        });

        setObjectColumns(
          result?.update_model_columns?.returning?.[0]?.model?.columns,
        );

        toast({
          id: "update-columns",
          title: `Colums ${enable ? "enabled" : "disabled"}`,
          variant: "success",
        });
      } catch (error) {
        Sentry.captureException(error);
        toast({
          id: "update-columns",
          title: `Couldn't update column settings`,
          variant: "error",
        });
      }
    },
    [modelId],
  );

  const refreshAllSuggestions = useCallback(async () => {
    const refreshableColumns: string[] = filteredColumns
      .filter((column) => column.top_k_enabled)
      .map((column) => column.name);

    try {
      const result = await updateWhitelistedColumns({
        model_id: modelId,
        input: {
          trigger_top_k_sync: true,
        },
        whitelist: refreshableColumns,
      });
      setObjectColumns(
        result?.update_model_columns?.returning?.[0]?.model?.columns,
      );
      toast({
        id: "update-columns",
        title: "Updated column settings",
        variant: "success",
      });
    } catch (error) {
      Sentry.captureException(error);
      toast({
        id: "update-columns",
        title: "Couldn't update column settings",
        variant: "error",
      });
    }
  }, [filteredColumns, updateWhitelistedColumns, modelId]);

  const tableColumns = [
    {
      name: "Enabled",
      max: "max-content",
      cell: ({ disable, name }) => {
        const isPrimaryKey = name === primaryKey && !disable;

        return (
          <PermissionedSwitch
            permission={{
              v2: {
                resource: "model",
                grant: "can_update",
                id: modelId,
              },
              v1: { resource: "audience_schema", grant: "update" },
            }}
            tooltip={
              isPrimaryKey
                ? "Primary key column cannot be disabled."
                : undefined
            }
            size="sm"
            isChecked={!disable}
            isDisabled={!hasUpdatePermission || isLoading || isPrimaryKey}
            onChange={(value) => toggleColumnDisabled({ name, value })}
          />
        );
      },
    },
    {
      name: "Name",
      cell: ({ alias, name, description }) => {
        const ref = useRef<HTMLInputElement | null>(null);
        const { isOpen, onToggle, onClose } = useDisclosure();
        const [isSaving, setIsSaving] = useState(false);
        const [isDeleting, setIsDeleting] = useState(false);
        const [value, setValue] = useState(alias ?? name ?? "");

        return (
          <Column flex={1} minWidth={0} mt={2} mb={1}>
            <Row
              overflow="hidden"
              gap={2}
              align="center"
              sx={{ svg: { visibility: isOpen ? "visible" : "hidden" } }}
              _hover={{ svg: { visibility: "visible" } }}
            >
              {alias ? (
                <Column overflow="hidden">
                  <TextWithTooltip fontWeight="medium">{alias}</TextWithTooltip>
                  <TextWithTooltip color="text.secondary">
                    {name}
                  </TextWithTooltip>
                </Column>
              ) : (
                <TextWithTooltip fontWeight="medium">{name}</TextWithTooltip>
              )}
              <ChakraPopover
                returnFocusOnClose={false}
                isOpen={isOpen}
                onClose={onClose}
                placement="right"
                closeOnBlur={true}
                initialFocusRef={ref}
                isLazy
              >
                <ChakraPopoverTrigger>
                  <IconButton
                    icon={EditIcon}
                    aria-label="Edit name"
                    size="sm"
                    onClick={onToggle}
                  />
                </ChakraPopoverTrigger>
                <Portal>
                  <ChakraPopoverContent>
                    <Column
                      gap={3}
                      p={3}
                      as="form"
                      onSubmit={async (event) => {
                        event.preventDefault();
                        setIsSaving(true);
                        await updateColumn({
                          id: modelId,
                          name,
                          input: { alias: value },
                        });
                        setIsSaving(false);
                        onClose();
                      }}
                    >
                      <FormField
                        label="Set an alias"
                        tip="Replace the column name with an alias"
                      >
                        <TextInput
                          ref={ref}
                          value={value}
                          onChange={(event) => setValue(event.target.value)}
                          width="100%"
                          placeholder="Enter an alias..."
                        />
                      </FormField>
                      <Row justify="space-between" gap={4}>
                        <Row gap={2}>
                          <PermissionedButton
                            permission={{
                              v2: {
                                resource: "model",
                                grant: "can_update",
                                id: modelId,
                              },
                              v1: {
                                resource: "audience_schema",
                                grant: "update",
                              },
                            }}
                            type="submit"
                            isDisabled={!value || value === alias || isLoading}
                            variant="primary"
                            isLoading={isSaving}
                          >
                            Save
                          </PermissionedButton>
                          <Button
                            onClick={() => {
                              setValue(alias ?? "");
                              onClose();
                            }}
                          >
                            Cancel
                          </Button>
                        </Row>

                        {alias && (
                          <PermissionedButton
                            permission={{
                              v2: {
                                resource: "model",
                                grant: "can_update",
                                id: modelId,
                              },
                              v1: {
                                resource: "audience_schema",
                                grant: "update",
                              },
                            }}
                            isLoading={isDeleting || isLoading}
                            variant="warning"
                            onClick={async () => {
                              setIsDeleting(true);
                              await updateColumn({
                                id: modelId,
                                name,
                                input: { alias: "" },
                              });
                              setValue("");
                              setIsDeleting(false);
                            }}
                          >
                            Remove alias
                          </PermissionedButton>
                        )}
                      </Row>
                    </Column>
                  </ChakraPopoverContent>
                </Portal>
              </ChakraPopover>
            </Row>
            <PermissionedEditableDescription
              permission={{
                v2: {
                  resource: "model",
                  grant: "can_update",
                  id: modelId,
                },
                v1: {
                  resource: "audience_schema",
                  grant: "update",
                },
              }}
              value={description || ""}
              width="100%"
              isDisabled={isLoading}
              onChange={async (option) => {
                if (modelId) {
                  try {
                    await updateModelColumnDescriptionMutation.mutateAsync({
                      model_id: modelId,
                      name,
                      description: option,
                    });

                    toast({
                      id: "update-column-desc",
                      title: "Column description updated",
                      variant: "success",
                    });
                  } catch (err) {
                    Sentry.captureException(err);

                    toast({
                      id: "update-column-desc",
                      title: "Column description failed to update",
                      variant: "error",
                    });
                  }
                }
              }}
            />
          </Column>
        );
      },
    },
    {
      name: "Type",
      max: "max-content",
      cell: ({
        name,
        type: warehouseType,
        custom_type: customType,
        disable,
      }) => {
        const type = customType ?? warehouseType;
        const isJsonColumnType = [
          ColumnType.Json,
          ColumnType.JsonArrayStrings,
          ColumnType.JsonArrayNumbers,
        ].includes(type);
        const isDetectedJsonArrayColumnType = [
          ColumnType.JsonArrayStrings,
          ColumnType.JsonArrayNumbers,
        ].includes(warehouseType);

        const columnCastOptions = getColumnTypeCastOptions(warehouseType, {
          sourceType: source?.type,
          allowBigints,
        });

        const isJsonColumn = jsonArraysEnabled && isJsonColumnType;
        const notSupported = !columnCastOptions && !isJsonColumn;

        // If the column was detected by the backend as a JSON array,
        // don't allow changing the type to JSON.
        const jsonOptions = isDetectedJsonArrayColumnType
          ? JSON_ARRAY_OPTIONS.filter(
              (opt) => opt.value !== ColumnType.Json,
            ).map((opt) => ({ ...opt, description: undefined }))
          : JSON_ARRAY_OPTIONS;
        const castOptions =
          columnCastOptions ??
          (isJsonColumn ? jsonOptions : ALL_COLUMN_OPTIONS);

        return (
          <Tooltip
            isDisabled={hasUpdatePermission && !notSupported}
            message={
              <Column gap={2}>
                <Text color="white">{getColumnTypeLabel(type)}</Text>
                <Text color="white">
                  {!hasUpdatePermission
                    ? "You do not have permission to update this value"
                    : `Type conversion is not supported for ${warehouseType} columns`}
                </Text>
              </Column>
            }
          >
            <Box sx={{ span: { display: "none" } }}>
              <Select
                width="auto"
                optionAccessory={(option: any) => ({
                  type: "icon",
                  icon: option.icon,
                })}
                isLoading={isLoading}
                isDisabled={disable || !hasUpdatePermission || notSupported}
                options={castOptions}
                value={type}
                onChange={(value) => {
                  updateColumn({
                    id: modelId,
                    name,
                    input: { custom_type: value },
                  });
                }}
              />
            </Box>
          </Tooltip>
        );
      },
    },
    {
      name: "Aa",
      header: () => (
        <Row justify="center" flex={1}>
          <Tooltip message="Treat column as case-sensitive during string comparisons">
            <Box sx={{ textTransform: "initial !important" }}>Aa</Box>
          </Tooltip>
        </Row>
      ),
      max: "max-content",
      cell: ({ case_sensitive, name, type, disable }) => {
        // Don't show toggle for ColumnType.JsonArrayStrings because backend logic doesn't handle case insensitivity
        if (type === ColumnType.String) {
          return (
            <PermissionedSwitch
              permission={{
                v2: {
                  resource: "model",
                  grant: "can_update",
                  id: modelId,
                },
                v1: { resource: "audience_schema", grant: "update" },
              }}
              size="sm"
              isChecked={case_sensitive}
              isDisabled={disable || isLoading}
              onChange={(value) => {
                updateColumn({
                  id: modelId,
                  name,
                  input: { case_sensitive: value },
                });
              }}
            />
          );
        }
        return (
          <Row justify="center" flex={1}>
            <Tooltip message="Only supported for string columns">--</Tooltip>
          </Row>
        );
      },
    },

    {
      name: "***",
      header: () => (
        <Row justify="center" flex={1}>
          <Tooltip message="Redacted field">***</Tooltip>
        </Row>
      ),
      max: "max-content",
      cell: ({ disable_preview, disable, name }) => {
        return (
          <PermissionedSwitch
            permission={{
              v2: {
                resource: "model",
                grant: "can_update",
                id: modelId,
              },
              v1: { resource: "audience_schema", grant: "update" },
            }}
            size="sm"
            isChecked={disable_preview}
            isDisabled={disable || isLoading}
            onChange={(value) => {
              updateColumn({
                id: modelId,
                name,
                input: {
                  disable_preview: value,
                  top_k_enabled: value ? false : undefined,
                },
              });
            }}
          />
        );
      },
    },
    {
      name: "Suggestions",
      header: () => (
        <Row pl={1}>
          <Tooltip
            message="Suggestions will cache common column values for Audience builder."
            placement="top-end"
          >
            <Box as={BulbSuggestionIcon} fontSize="16px" />
          </Tooltip>
        </Row>
      ),
      max: "max-content",
      cell: ({
        top_k_enabled,
        name,
        last_top_k_sync_timestamp,
        top_k_error: error,
        disable,
        disable_preview,
        type,
      }) => {
        const isDateType = ColumnDateTypes.includes(type);
        const isLimitError = error?.startsWith(
          "number of distinct values exceeds limit",
        );

        if (isDateType) {
          return (
            <Row pl={1} flex={1}>
              <Tooltip message="Not supported for date type columns">
                <Text color="text.secondary" fontWeight="semibold">
                  --
                </Text>
              </Tooltip>
            </Row>
          );
        }

        if (suggestionsDisabled) {
          return (
            <Row pl={1} flex={1}>
              <Tooltip message="Not supported for this source">
                <Text color="text.secondary" fontWeight="semibold">
                  --
                </Text>
              </Tooltip>
            </Row>
          );
        }

        return (
          <Row align="center" justify="center" flex={1} gap={2}>
            <PermissionedSwitch
              permission={{
                v2: {
                  resource: "model",
                  grant: "can_update",
                  id: modelId,
                },
                v1: { resource: "audience_schema", grant: "update" },
              }}
              tooltip={
                disable
                  ? undefined
                  : disable_preview
                    ? "Suggestions are not available for redacted columns"
                    : last_top_k_sync_timestamp
                      ? `Last updated: ${formatDate(last_top_k_sync_timestamp)}`
                      : undefined
              }
              size="sm"
              isChecked={top_k_enabled}
              isDisabled={disable || disable_preview || isLoading}
              onChange={(value) => {
                updateColumn({
                  id: modelId,
                  name,
                  input: { top_k_enabled: value },
                });
              }}
            />
            <Row align="center" width="20px" height="20px">
              {error && !disable && top_k_enabled && (
                <Tooltip
                  message={isLimitError ? limitErrorMessage(error) : error}
                >
                  <Box fontSize="20px">
                    <WarningIcon
                      color={isLimitError ? "warning.border" : "danger.base"}
                    />
                  </Box>
                </Tooltip>
              )}
            </Row>
          </Row>
        );
      },
    },
  ];

  return (
    <>
      <Row mb={4} width="100%" gap={4}>
        <Row align="center" gap={4} flex={1}>
          <Row align="center" gap={2}>
            <SectionHeading>Columns</SectionHeading>
            <Pill>{objectColumns?.length}</Pill>
          </Row>
          <SearchInput
            width="100%"
            placeholder="Search columns..."
            value={search}
            onChange={(event) => setSearch(event.target.value)}
          />
        </Row>
        <Row
          align="center"
          justify="flex-end"
          flexShrink={0}
          gap={2}
          whiteSpace="nowrap"
        >
          <Text fontWeight="medium">Refresh interval</Text>
          <Tooltip
            isDisabled={hasUpdatePermission}
            message="You do not have permission to perform this action"
          >
            <Select
              width="auto"
              isDisabled={!hasUpdatePermission}
              options={REFRESH_OPTIONS}
              placeholder="Select an interval..."
              isLoading={isLoading}
              value={currentRefreshInterval}
              onChange={handleUpdateColumns}
            />
          </Tooltip>

          <PermissionedIconButton
            permission={{
              v2: {
                resource: "model",
                grant: "can_update",
                id: modelId,
              },
              v1: { resource: "audience_schema", grant: "update" },
            }}
            tooltip={
              suggestionsDisabled
                ? "Suggestions are not supported for this source"
                : "Refresh all suggestions"
            }
            aria-label="Refresh suggestions"
            variant="secondary"
            isDisabled={suggestionsDisabled}
            isLoading={refreshing || isLoading}
            onClick={refreshAllSuggestions}
            icon={RefreshIcon}
          />

          <Menu>
            <MenuIconButton
              aria-label="Bulk column actions"
              icon={SettingsIcon}
              variant="secondary"
            />
            <MenuList>
              <PermissionedMenuItem
                permission={{
                  v2: {
                    resource: "model",
                    grant: "can_update",
                    id: modelId,
                  },
                  v1: { resource: "audience_schema", grant: "update" },
                }}
                isDisabled={
                  columnsUpdating ||
                  !hasUpdatePermission ||
                  suggestionsDisabled ||
                  filteredColumns.every((column) => column.top_k_enabled)
                }
                onClick={() => toggleAllSuggestions(true)}
              >
                Turn all suggestions on
              </PermissionedMenuItem>
              <PermissionedMenuItem
                permission={{
                  v2: {
                    resource: "model",
                    grant: "can_update",
                    id: modelId,
                  },
                  v1: { resource: "audience_schema", grant: "update" },
                }}
                isDisabled={
                  columnsUpdating ||
                  !hasUpdatePermission ||
                  suggestionsDisabled ||
                  filteredColumns.every((column) => !column.top_k_enabled)
                }
                onClick={() => toggleAllSuggestions(false)}
              >
                Turn all suggestions off
              </PermissionedMenuItem>
              <PermissionedMenuItem
                permission={{
                  v2: {
                    resource: "model",
                    grant: "can_update",
                    id: modelId,
                  },
                  v1: { resource: "audience_schema", grant: "update" },
                }}
                isDisabled={columnsUpdating || !hasUpdatePermission}
                onClick={() => toggleAllColumnsEnabled(!allColumnsDisabled)}
              >
                {!allColumnsDisabled ? "Enable" : "Disable"} all columns
              </PermissionedMenuItem>
            </MenuList>
          </Menu>
        </Row>
      </Row>
      <Table
        columns={tableColumns}
        data={filteredColumns.slice(
          0 + currentPage * ROW_LIMIT,
          currentPage * ROW_LIMIT + ROW_LIMIT,
        )}
        primaryKey="name"
        rowHeight="auto"
      />
      <Row justify="flex-end" mt={4} px={4}>
        <Pagination
          count={filteredColumns.length}
          label="columns"
          page={currentPage}
          rowsPerPage={ROW_LIMIT}
          setPage={setCurrentPage}
        />
      </Row>
    </>
  );
};
