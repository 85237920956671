import {
  Box,
  Button,
  Column,
  Combobox,
  DollarIcon,
  NumberSignIcon,
  PercentIcon,
  Row,
  Text,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  FormField,
} from "@hightouchio/ui";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Card } from "src/components/card";
import { CountryCodes } from "src/constants/country-codes";
import { MetricFormData } from "../use-metric-form";
import {
  DefaultsPerType,
  formatNumericMetric,
  MAX_FRACTION_DIGITS,
  SupportedOptionsPerAggregationType,
} from "./format-metric";

export const MetricFormattingForm: FC = () => {
  const { watch, control } = useFormContext<MetricFormData>();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - Circular type problem with Condition[]
  const aggregationType = watch("aggregationType");

  return (
    <Controller
      control={control}
      name="config"
      render={({ field }) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - Circular type problem with Condition[]
        const displayFormatting = field.value?.displayFormatting;

        return (
          <FormField
            label="Formatting"
            description="Determines how this metric will be displayed"
          >
            <Column
              padding="16px"
              bg="base.background"
              maxWidth="320px"
              borderRadius="md"
              gap={2}
            >
              <Row justifyContent="space-between">
                <Box bg="white" borderRadius="md">
                  <ToggleButtonGroup
                    size="sm"
                    value={displayFormatting?.type || "number"}
                    onChange={(value) => {
                      field.onChange({
                        ...field.value,
                        displayFormatting: {
                          ...DefaultsPerType[value].config,
                          fractionDigits: displayFormatting?.fractionDigits,
                        },
                      });
                    }}
                  >
                    <Tooltip
                      placement="bottom"
                      openSpeed="slow"
                      message="Format as number"
                    >
                      <ToggleButton
                        icon={NumberSignIcon}
                        label=""
                        value="number"
                        isDisabled={
                          !SupportedOptionsPerAggregationType[
                            aggregationType
                          ].includes("number")
                        }
                      />
                    </Tooltip>
                    <Tooltip
                      placement="bottom"
                      openSpeed="slow"
                      message="Format as percent"
                    >
                      <ToggleButton
                        icon={PercentIcon}
                        label=""
                        value="percentage"
                        isDisabled={
                          !SupportedOptionsPerAggregationType[
                            aggregationType
                          ].includes("percentage")
                        }
                      />
                    </Tooltip>
                    <Tooltip placement="bottom" message="Format as currency">
                      <ToggleButton
                        icon={DollarIcon}
                        label=""
                        value="currency"
                        isDisabled={
                          !SupportedOptionsPerAggregationType[
                            aggregationType
                          ].includes("currency")
                        }
                      />
                    </Tooltip>
                  </ToggleButtonGroup>
                </Box>

                <Row gap={2}>
                  <Tooltip
                    placement="bottom"
                    openSpeed="slow"
                    message="Decrease decimal places"
                    isDisabled={displayFormatting?.fractionDigits === 0}
                  >
                    <Button
                      size="sm"
                      isDisabled={displayFormatting?.fractionDigits === 0}
                      onClick={() => {
                        if (!displayFormatting) {
                          return;
                        }

                        field.onChange({
                          ...field.value,
                          displayFormatting: {
                            ...displayFormatting,
                            fractionDigits:
                              displayFormatting?.fractionDigits - 1,
                          },
                        });
                      }}
                    >
                      {"<-.0"}
                    </Button>
                  </Tooltip>
                  <Tooltip
                    placement="bottom"
                    openSpeed="slow"
                    message="Increase decimal places"
                    isDisabled={
                      field.value?.displayFormatting?.fractionDigits ===
                        MAX_FRACTION_DIGITS ||
                      (displayFormatting?.type === "currency" &&
                        displayFormatting.fractionDigits >= 2)
                    }
                  >
                    <Button
                      size="sm"
                      isDisabled={
                        field.value?.displayFormatting?.fractionDigits ===
                        MAX_FRACTION_DIGITS
                      }
                      onClick={() => {
                        if (!displayFormatting) {
                          return;
                        }
                        field.onChange({
                          ...field.value,
                          displayFormatting: {
                            ...displayFormatting,
                            fractionDigits:
                              displayFormatting?.fractionDigits + 1,
                          },
                        });
                      }}
                    >
                      {".00->"}
                    </Button>
                  </Tooltip>
                </Row>
              </Row>
              <Card p={0}>
                <Row p={2} justifyContent="space-between" alignItems="center">
                  {displayFormatting?.type === "currency" ? (
                    <Box maxWidth="24">
                      <Combobox
                        variant="alternative"
                        size="sm"
                        width="100%"
                        options={CountryCodes}
                        optionLabel={(option) =>
                          `${option.code} (${option.sign})`
                        }
                        value={displayFormatting?.countryCode}
                        optionValue={(option) => option.code}
                        onChange={(value) => {
                          if (value) {
                            field.onChange({
                              ...field.value,
                              displayFormatting: {
                                ...displayFormatting,
                                countryCode: value,
                              },
                            });
                          }
                        }}
                      />
                    </Box>
                  ) : (
                    <Box py={0.5}>
                      <Text color="text.tertiary">Preview</Text>
                    </Box>
                  )}

                  <Text color="text.secondary">
                    {formatNumericMetric(undefined, displayFormatting)}
                  </Text>
                </Row>
              </Card>
            </Column>
          </FormField>
        );
      }}
    />
  );
};
