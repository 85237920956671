import { Text, Row, Column } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useMemo } from "react";
import { useEntitlements } from "src/hooks/use-entitlement";

import { Icon as EventCollectionIcon } from "./icons/event-collection";
import { Icon as CustomerStudioIcon } from "./icons/customer-studio";
import { Icon as CampaignIntelligenceIcon } from "./icons/intelligence";
import { NavItem } from "./nav-item";
import { useEventCollectionSection } from "./sections/event-collection";
import { useIntelligenceSection } from "./sections/intelligence";
import { useCustomerStudioSection } from "./sections/customer-studio";

const CampaignIntelligence = {
  label: "Campaign Intelligence",
  href: "/analytics",
  icon: CampaignIntelligenceIcon,
};

const EventCollection = {
  label: "Events",
  href: "/events",
  icon: EventCollectionIcon,
};

const CustomerStudio = {
  label: "Customer Studio",
  href: "/audiences",
  icon: CustomerStudioIcon,
};

export const More = () => {
  const { data: entitlementsData } = useEntitlements(false);
  const flags = useFlags();

  const eventCollection = useEventCollectionSection();
  const intelligence = useIntelligenceSection();
  const customerStudio = useCustomerStudioSection();

  const products = useMemo(() => {
    if (
      eventCollection.isEnabled &&
      customerStudio.isEnabled &&
      intelligence.isEnabled
    ) {
      return [];
    }
    if (eventCollection.isEnabled && customerStudio.isEnabled) {
      return [CampaignIntelligence];
    }
    if (customerStudio.isEnabled && intelligence.isEnabled) {
      return [EventCollection];
    }
    if (eventCollection.isEnabled) {
      return [CustomerStudio];
    }
    if (customerStudio.isEnabled) {
      return [CampaignIntelligence, EventCollection];
    }
    return [EventCollection, CustomerStudio];
  }, [
    flags,
    entitlementsData,
    eventCollection.isEnabled,
    customerStudio.isEnabled,
    intelligence.isEnabled,
  ]);

  if (products.length === 0) {
    return null;
  }

  return (
    <Column
      borderBottom="1px"
      borderColor="#638989"
      pb={4}
      mb={4}
      display={["none", "none", "none", "flex"]}
    >
      <Row
        mb={1}
        align="center"
        width="max-content"
        px={2}
        height="20px"
        borderRadius="6px"
        bg="#03393C"
        border="1px"
        borderColor="#04494C"
      >
        <Text color="#40E398" size="sm">
          More products
        </Text>
      </Row>
      {products.map((product) => (
        <NavItem key={product.href} {...product} />
      ))}
    </Column>
  );
};
