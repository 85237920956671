import { FC, useEffect, useState } from "react";

import {
  Box,
  Column,
  DynamicTableIcon,
  IconButton,
  RefreshIcon,
  Row,
  SearchInput,
  Spinner,
  TableIcon,
  Text,
  Tooltip,
} from "@hightouchio/ui";

import { IntegrationIcon } from "src/components/integrations/integration-icon";

import { SelectorRow } from "./selector-row";
import { useObjectDefinitions } from "src/utils/sources";
import { QueryType } from "src/types/models";
import { useDebounce } from "use-debounce";

type Props = {
  onChange: (table: string) => void;
  table: string | null | undefined;
  source: {
    id?: string | number;
    name: string;
    definition: {
      icon: string;
      name: string;
      supportedQueries: string[];
    };
  };
};

export const TableSelector: FC<Readonly<Props>> = ({
  table,
  onChange,
  source,
}) => {
  const [search, setSearch] = useState<string>("");
  const [initialReload, setInitialReload] = useState(false);

  const [debouncedSearch, { isPending: isSearchPending }] = useDebounce(
    search,
    1000,
  );

  const {
    loading: objectDefinitionsLoading,
    refetch,
    objectDefinitions,
  } = useObjectDefinitions(
    String(source?.id),
    source?.definition?.supportedQueries.includes(QueryType.Table),
    debouncedSearch,
  );

  const isLoading = objectDefinitionsLoading || isSearchPending();

  useEffect(() => {
    if (!isLoading && !objectDefinitions?.length && !search && !initialReload) {
      refetch();
      setInitialReload(true);
    }
  }, [isLoading, objectDefinitions, initialReload, search]);

  const isPlaceholder = isLoading || !objectDefinitions?.length;

  return (
    <Column
      width="100%"
      border="1px"
      borderColor="base.border"
      borderRadius="md"
      overflow="hidden"
      minWidth={0}
    >
      <Row
        align="center"
        px={4}
        py={4}
        borderBottom="1px"
        borderColor="base.border"
        gap={4}
        justify="space-between"
      >
        <Row align="center" gap={2}>
          <IntegrationIcon
            src={source?.definition.icon}
            name={source?.definition.name}
          />
          <Text fontWeight="medium" size="lg">
            {source?.name ?? "Private source"}
          </Text>
        </Row>
        <Row gap={2}>
          <SearchInput
            placeholder="Search tables..."
            value={search}
            onChange={(event) => setSearch(event.target.value)}
          />
          <Tooltip
            message="Source schema is refreshing"
            isDisabled={!isLoading}
          >
            <IconButton
              variant="secondary"
              aria-label="Refresh tables"
              icon={RefreshIcon}
              isLoading={isLoading}
              onClick={() => {
                refetch();
              }}
            />
          </Tooltip>
        </Row>
      </Row>
      <Column
        overflow="hidden"
        flex={1}
        minWidth={0}
        p={isPlaceholder ? 10 : undefined}
        align={isPlaceholder ? "center" : undefined}
        justify={isPlaceholder ? "center" : undefined}
      >
        {isLoading ? (
          <Spinner size="lg" />
        ) : !objectDefinitions?.length ? (
          <>
            <Text size="lg" fontWeight="semibold">
              {search ? "No tables match your search" : "No tables found"}
            </Text>
            <Text color="text.secondary">
              Try refreshing to populate the tables
            </Text>
          </>
        ) : (
          <Column overflow="auto" flex={1} minWidth={0}>
            {objectDefinitions?.map(
              ({ name, object_definition_group, meta }) => {
                const key = `${
                  object_definition_group
                    ? `${object_definition_group.name}.`
                    : ""
                }${name}`;
                const active = key === table;

                return (
                  <SelectorRow
                    key={key}
                    icon={
                      <Box
                        as={TableIcon}
                        fontSize="16px"
                        color="text.secondary"
                      />
                    }
                    selected={active}
                    end={
                      meta?.isStreamable ? (
                        <Box
                          as={DynamicTableIcon}
                          fontSize="16px"
                          color="text.secondary"
                        />
                      ) : undefined
                    }
                    onClick={() => onChange(key)}
                  >
                    {key}
                  </SelectorRow>
                );
              },
            )}
          </Column>
        )}
      </Column>
    </Column>
  );
};
