import { FC, useMemo, useState } from "react";

import {
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Line,
  ResponsiveContainer,
  ReferenceLine,
  TooltipProps,
  Tooltip,
} from "recharts";
import { format, fromUnixTime } from "date-fns";
import {
  Text,
  Column,
  Row,
  SectionHeading,
  ToggleButton,
  ToggleButtonGroup,
  Spinner,
} from "@hightouchio/ui";

import { Card } from "src/components/card";
import { abbreviateNumber, commaNumber } from "src/utils/numbers";
import { EventsMetricEnum, useEventMetricsQuery } from "src/graphql";

type Range = "24h" | "3d" | "7d";

export const Graph: FC<Readonly<{ syncId: string }>> = ({ syncId }) => {
  const [range, setRange] = useState<Range>("24h");

  const input: any = useMemo(() => {
    return {
      id: syncId,
      resourceType: "SYNC",
      requests: [
        {
          timeseriesRequests: {
            // Fetch all timeseries data on first page load.
            // This could be optimized to fetch only the data needed for the current range,
            // but as-is, it reducing imtermediate loading states for the users
            startTime: Date.now() - durationMap["7d"],
            endTime: Date.now(),
            metrics: ["ATTEMPTED", "DELIVERED", "FAILED"],
          },
        },
      ],
    };
  }, [syncId]);

  const { data, isLoading } = useEventMetricsQuery(
    {
      input,
    },
    {
      select: (data) => data.getEventMetrics.results[0]?.timeseriesData,
    },
  );

  const datapoints = (type: EventsMetricEnum) =>
    data
      ?.find((d) => d?.metric === type)
      ?.dataPoints.sort((a, b) => a.timestamp - b.timestamp);

  const attempted = datapoints("ATTEMPTED");
  const delivered = datapoints("DELIVERED");
  const failed = datapoints("FAILED");

  const [hoveredX, setHoveredX] = useState<number>(-1);

  return (
    <Card>
      <Row justify="space-between" gap={4} pb={4}>
        <SectionHeading>Events over time</SectionHeading>
        <ToggleButtonGroup
          onChange={(value) => setRange(value as Range)}
          value={range}
        >
          <ToggleButton label="24h" value="24h" />
          <ToggleButton label="3d" value="3d" />
          <ToggleButton label="7d" value="7d" />
        </ToggleButtonGroup>
      </Row>
      <Column gap={4}>
        <Chart
          title="Attempted"
          color="var(--chakra-colors-gray-500)"
          data={attempted}
          range={range}
          isLoading={isLoading}
          noDataMessage="No data"
          hoveredX={hoveredX}
          setHoveredX={setHoveredX}
        />
        <Chart
          title="Delivered"
          color="var(--chakra-colors-success-base)"
          data={delivered}
          range={range}
          isLoading={isLoading}
          noDataMessage="No data"
          hoveredX={hoveredX}
          setHoveredX={setHoveredX}
        />
        <Chart
          title="Failed"
          color="var(--chakra-colors-danger-base)"
          data={failed}
          range={range}
          isLoading={isLoading}
          noDataMessage={attempted ? "No failed rows" : "No data"}
          hoveredX={hoveredX}
          setHoveredX={setHoveredX}
        />
      </Column>
    </Card>
  );
};

// Note: a lot of this borrows from packages/app/src/components/app-home/sync-health-chart.tsx
// probably not enough to generalize into a shared component,
// but could be worth considering if more of these are added
const renderTooltip = (
  eventType: string,
  bulletColor: string,
  { payload }: TooltipProps<number, string>,
) => {
  const data = payload?.[0]?.payload as
    | { value: number; timestamp: number }
    | undefined;

  if (!data) return null;

  const rawTime = fromUnixTime(data.timestamp / 1000);
  const time = format(rawTime, "EEE HH:mm");

  return (
    <Column bg="gray.900" padding={2} borderRadius="md" gap={2}>
      <Text color="text.tertiary" fontWeight="medium">
        {time}
      </Text>
      <Row gap={2} alignItems="stretch">
        <Column bg={bulletColor} width={2} borderRadius="sm" />
        <Column>
          <Text fontWeight="medium" color="white" size="sm">
            {`${commaNumber(data.value)} ${eventType.toLowerCase()} events`}
          </Text>
        </Column>
      </Row>
    </Column>
  );
};

const Chart: FC<
  Readonly<{
    data: Array<{ value: number; timestamp: number }> | undefined | null;
    range: Range;
    color: string;
    title: string;
    isLoading: boolean;
    noDataMessage: string;
    hoveredX: number;
    setHoveredX: (x: number) => void;
  }>
> = ({
  title,
  data,
  range,
  color,
  isLoading,
  noDataMessage,
  hoveredX,
  setHoveredX,
}) => {
  // We know ticks will always be a non-empty array of numbers, so this is safe
  const firstTick = ticks(range)[0] as number;

  const filteredData = data?.filter((d) => d.timestamp >= firstTick);

  return (
    <Column width="100%" height="150px" gap={1} position="relative">
      {!data && (
        <Row
          w="100%"
          h="100%"
          align="center"
          justify="center"
          color="text.secondary"
          pos="absolute"
          textAlign="center"
        >
          {isLoading ? <Spinner /> : noDataMessage}
        </Row>
      )}
      <Text fontWeight="medium">{title}</Text>
      <ResponsiveContainer>
        <LineChart
          data={filteredData ?? []}
          margin={{ top: 10, left: 0, right: 0, bottom: 0 }}
          onMouseMove={(e) =>
            e?.activeLabel && setHoveredX(parseInt(e.activeLabel))
          }
          onMouseLeave={() => setHoveredX(-1)}
        >
          <Tooltip
            content={(props: TooltipProps<number, string>) =>
              renderTooltip(title, color, props)
            }
            cursor={false}
            offset={20}
            position={{ y: -20 }}
            animationDuration={0}
          />

          {data && (
            <ReferenceLine
              x={hoveredX}
              strokeWidth="5px"
              stroke="var(--chakra-colors-base-divider)"
              style={{ cursor: "pointer" }}
            />
          )}

          <XAxis
            scale="time"
            tickLine={false}
            axisLine={false}
            dataKey="timestamp"
            ticks={ticks(range)}
            tickFormatter={(timestamp) => tickFormatter(timestamp, range)}
            type="number"
            domain={["auto", "auto"]}
            tickSize={10}
            tickMargin={4}
            padding={{ left: 5, right: 5 }}
          />
          <YAxis
            width={40}
            axisLine={false}
            tickLine={false}
            dataKey="value"
            type="number"
            tickFormatter={(value) => abbreviateNumber(value)}
          />
          {data && (
            <CartesianGrid
              vertical={false}
              stroke="var(--chakra-colors-base-divider)"
            />
          )}
          <Line
            dot={false}
            type="monotone"
            dataKey="value"
            strokeWidth={3}
            stroke={color}
          />
        </LineChart>
      </ResponsiveContainer>
    </Column>
  );
};

const durationMap = {
  "24h": 3600 * 1000 * 24,
  "3d": 3600 * 1000 * 24 * 3,
  "7d": 3600 * 1000 * 24 * 7,
};

const segmentsMap = {
  "24h": 12,
  "3d": 3,
  "7d": 7,
};

// returns an array of unix timestamps for the given range
const ticks = (range: Range): Array<number> => {
  const now = new Date();
  const ticks: number[] = [];

  const duration = durationMap[range];
  const segments = segmentsMap[range];

  for (let i = 0; i < segments; i++) {
    const interval = duration / segments;
    // Server data uses milliseconds, so make sure and stay consistent here
    const timestamp = new Date(now.getTime() - i * interval).getTime();
    ticks.unshift(Math.floor(timestamp));
  }

  return ticks;
};

const tickFormatter = (timestamp: number, range: Range) => {
  const date = fromUnixTime(timestamp / 1000);

  switch (range) {
    case "24h":
      return format(date, "h aa");
    case "3d":
      return format(date, "EEE-d");
    case "7d":
      return format(date, "EEE-d");
  }
};
