import {
  IdentityGraph,
  IdrStats,
  IDRSummaryStats,
} from "src/pages/identity-resolution/types";
import { IDRv2Stats, ModelStats } from "src/types/idr";
import { isPresent } from "ts-extras";

export enum GraphVersion {
  V1 = 1,
  V2 = 2,
}

/**
 * Values for the idrGraphVersionEnabled flag.
 *
 * https://app.launchdarkly.com/projects/default/flags/idr-graph-version-enabled/targeting
 */
export enum GraphVersionEnabled {
  Bothv2AndLegacy = "both",
  v2 = "v2",
  Legacy = "legacy",
}

function isIDRv2Enabled(flag: string) {
  if (flag === GraphVersionEnabled.Bothv2AndLegacy) {
    return true;
  }

  return flag === GraphVersionEnabled.v2;
}

export function getGraphVersion(
  urlParamVersion: number | null,
  idrGraphVersionEnabled: GraphVersionEnabled,
): GraphVersion {
  // If both are enabled, the url param should determine the version
  if (idrGraphVersionEnabled === GraphVersionEnabled.Bothv2AndLegacy) {
    // If no param is provided, default to v2
    return urlParamVersion === GraphVersion.V2 || urlParamVersion === null
      ? GraphVersion.V2
      : GraphVersion.V1;
  }

  return isIDRv2Enabled(idrGraphVersionEnabled)
    ? GraphVersion.V2
    : GraphVersion.V1;
}

function findModelStats(
  modelStat: ModelStats,
  model: IdentityGraph["models"][number],
) {
  return Number(modelStat.id) === model.model.id;
}

export function getLegacySummaryValues(
  stats: IdrStats | undefined,
  models: IdentityGraph["models"] = [],
): IDRSummaryStats | null {
  if (!stats) return null;

  const modelStats: IDRSummaryStats["models"] = [];

  for (const model of models) {
    const thisModelStats =
      model.type === "event"
        ? stats.events?.models.find((eventModel) =>
            findModelStats(eventModel, model),
          )
        : stats.profiles?.models.find((profileModel) =>
            findModelStats(profileModel, model),
          );
    // This should always be present if we have any stats.
    if (thisModelStats) {
      modelStats.push({
        id: model.model.id,
        modelName: model.model.name,
        numHtIds: thisModelStats.num_ht_ids,
        sourceRows: thisModelStats.source_rows,
      });
    }
  }

  return {
    totalHtIds: !stats.isLegacy ? stats.num_ht_ids : null,
    totalSrcRows:
      isPresent(stats.events) && isPresent(stats.profiles)
        ? stats.events.source_rows + stats.profiles.source_rows
        : null,
    diffHtIds: null,
    models: modelStats,
    identifiers: [],
  };
}

export function getv2SummaryValues(
  stats: IDRv2Stats | undefined,
): IDRSummaryStats | null {
  if (!stats) return null;

  return {
    totalHtIds: stats.num_ht_ids,
    totalSrcRows: stats.source_rows,
    diffHtIds: stats.new_num_ht_ids,
    models: stats.models.map((modelStats) => ({
      id: modelStats.id,
      modelName: modelStats.name,
      numHtIds: modelStats.num_ht_ids,
      sourceRows: modelStats.source_rows,
    })),
    identifiers: stats.identifiers.map((identifierStats) => ({
      name: identifierStats.name,
      numHtIds: identifierStats.num_ht_ids,
      sourceRows: identifierStats.source_rows,
    })),
  };
}
