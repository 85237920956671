import { ResourceActivityTimeline } from "src/components/resource-activity/timeline";
import {
  modelActivityMappers,
  modelColumnIgnoreFields,
  modelColumnMappers,
} from "src/pages/models/model-activity";
import { OutletContext } from ".";

export const ModelActivity = ({
  context: { model },
}: {
  context: OutletContext;
}) => {
  return (
    <ResourceActivityTimeline
      primaryResource={{
        mappers: modelActivityMappers,
        resource: "Model",
        resourceId: String(model.id),
      }}
      relatedResources={[
        {
          mappers: modelColumnMappers,
          resource: "Model Column",
          resourceNameOverride: "Model column",
          resourceId: `model_id:${model.id}:%`,
          ignoreColumns: modelColumnIgnoreFields,
        },
      ]}
    />
  );
};
