import { FC, Fragment, ReactNode } from "react";

import {
  Box,
  Column,
  CopyIcon,
  DescriptionAddIcon,
  Menu,
  MenuActionsButton,
  MenuItem,
  MenuList,
  Row,
  SyncIcon,
  Text,
  TraitIcon,
} from "@hightouchio/ui";

import { ConditionType } from "src/types/visual";

import {
  AndOrToggleButton,
  DeleteButton,
  TextButton,
} from "./condition-buttons";
import { GroupIndicatorBar } from "./group-indicator-bar";

interface ConditionWrapperProps {
  children: ReactNode;
  conditionType: ConditionType.And | ConditionType.Or;
  "data-testid"?: string;
  disableGrouping?: boolean;
  onAddTrait?: () => void;
  onAddTraitEnrichment?: () => void;
  onChange: (conditionType: ConditionType.And | ConditionType.Or) => void;
  onDuplicate: () => void;
  onGroup: () => void;
  onUngroup: (() => void) | undefined;
  onRemove: () => void;
}

export const ConditionWrapper: FC<Readonly<ConditionWrapperProps>> = ({
  children,
  conditionType,
  "data-testid": dataTestId,
  disableGrouping = false,
  onAddTrait,
  onAddTraitEnrichment,
  onChange,
  onDuplicate,
  onGroup,
  onRemove,
  onUngroup,
}) => {
  const menuItems = [
    {
      label: `Add ${
        conditionType === ConditionType.And ? '"OR"' : '"AND"'
      } group`,
      description: `Create a nested ${
        conditionType === ConditionType.And ? '"OR"' : '"AND"'
      } group from this filter`,
      icon: DescriptionAddIcon,
      onClick: onGroup,
      isDisabled: disableGrouping,
    },
    {
      label: "Duplicate filter",
      description: "Create a copy of this filter",
      icon: CopyIcon,
      onClick: onDuplicate,
    },
    {
      label: "Sync this trait",
      description: "Create a new audience scoped trait to sync",
      icon: SyncIcon,
      onClick: onAddTraitEnrichment,
    },
    {
      label: "Save trait",
      description: "Create a new trait based on this filter",
      icon: TraitIcon,
      onClick: onAddTrait,
    },
  ];

  return (
    <Row width="100%" data-testid={dataTestId}>
      <Column flex={1} minWidth={0}>
        <Row flex={1} minWidth={0} mb={4}>
          <GroupIndicatorBar conditionType={conditionType} />

          <Row
            flex={1}
            minWidth="max-content"
            bg="white"
            borderBottomWidth="1px"
            borderRightWidth="1px"
            borderTopWidth="1px"
            borderBottomRightRadius="md"
            borderTopRightRadius="md"
            borderColor="base.border"
            boxShadow="sm"
            flexBasis="fit-content"
            gap={2}
            justify="space-between"
            p={4}
            position="relative"
          >
            <Column
              flex="1 0 auto"
              minWidth={0}
              sx={{
                "& > :not(:last-child)": { mb: 4 },
              }}
            >
              {children}
            </Column>

            <Row align="center" justify="center" gap={2} flexShrink={0}>
              <Menu>
                <MenuActionsButton />

                <MenuList>
                  {menuItems.map((item) => (
                    <Fragment key={item.label}>
                      {item.onClick && (
                        <Box
                          as={MenuItem}
                          color="text.secondary"
                          icon={item.icon}
                          isDisabled={item.isDisabled}
                          sx={{
                            svg: {
                              height: "24px",
                              width: "24px",
                            },
                          }}
                          onClick={item.onClick}
                        >
                          <Column>
                            <Text fontWeight="medium">{item.label}</Text>
                            <Text color="text.secondary">
                              {item.description}
                            </Text>
                          </Column>
                        </Box>
                      )}
                    </Fragment>
                  ))}
                </MenuList>
              </Menu>

              <DeleteButton onRemove={onRemove} />
            </Row>
          </Row>
        </Row>

        <Row align="center" gap={2} mb={4}>
          <AndOrToggleButton
            conditionType={conditionType}
            onClick={() =>
              onChange(
                conditionType === ConditionType.And
                  ? ConditionType.Or
                  : ConditionType.And,
              )
            }
          />

          {onUngroup && (
            <TextButton size="md" onClick={onUngroup}>
              Ungroup
            </TextButton>
          )}
        </Row>
      </Column>
    </Row>
  );
};
