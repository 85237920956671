import {
  EvaluationTrigger,
  MonitorConditionTypes,
  MonitoredResourceType,
  MonitorStatus,
} from "./types";

export function assertedResourceType(
  resourceType: string,
): MonitoredResourceType {
  if (
    !Object.values(MonitoredResourceType).includes(
      resourceType as MonitoredResourceType,
    )
  ) {
    throw new Error(`Invalid resource type: ${resourceType}`);
  }
  return resourceType as MonitoredResourceType;
}

export function assertedMonitorStatusType(
  status?: string,
): MonitorStatus | null {
  if (!status) {
    return null;
  }
  if (!Object.values(MonitorStatus).includes(status as MonitorStatus)) {
    throw new Error(`Invalid status: ${status}`);
  }
  return status as MonitorStatus;
}

export function assertedConditionType(
  conditionType: string,
): MonitorConditionTypes {
  if (
    !Object.values(MonitorConditionTypes).includes(
      conditionType as MonitorConditionTypes,
    )
  ) {
    throw new Error(`Invalid condition type: ${conditionType}`);
  }
  return conditionType as MonitorConditionTypes;
}

export function assertedEvaluationTrigger(
  trigger?: string,
): EvaluationTrigger | null {
  if (!trigger) {
    return null;
  }
  if (
    !Object.values(EvaluationTrigger).includes(trigger as EvaluationTrigger)
  ) {
    throw new Error(`Invalid evaluation trigger: ${trigger}`);
  }
  return trigger as EvaluationTrigger;
}
