import { Tooltip, Column, Text } from "@hightouchio/ui";

import { abbreviateNumber } from "src/utils/numbers";

export const NumberBadge = ({ value, color, tooltip }) => (
  <Tooltip message={tooltip}>
    <Column
      align="center"
      justify="center"
      minWidth="32px"
      height="16px"
      px={1}
      bg={`${color}.background`}
      borderRadius="md"
    >
      <Text size="sm" color={`${color}.base`} fontWeight="medium">
        {typeof value !== "undefined" ? abbreviateNumber(value) : "-"}
      </Text>
    </Column>
  </Tooltip>
);
