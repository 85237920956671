export const Icon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20px"
    height="20px"
    viewBox="0 0 24 24"
    fill={color}
  >
    <path
      fillRule="evenodd"
      d="M11.45 5.137a.5.5 0 0 1 .5.5v1.245a.5.5 0 0 1-1 0V5.637a.5.5 0 0 1 .5-.5ZM6.945 6.944a.5.5 0 0 1 .707 0l.881.88a.5.5 0 1 1-.707.707l-.88-.88a.5.5 0 0 1 0-.707Zm9.06 0a.5.5 0 0 1 0 .707l-.881.88a.5.5 0 0 1-.707-.707l.88-.88a.5.5 0 0 1 .708 0ZM5.139 11.448a.5.5 0 0 1 .5-.5h1.245a.5.5 0 0 1 0 1H5.639a.5.5 0 0 1-.5-.5Zm3.394 2.967a.5.5 0 0 1 0 .707l-.88.88a.5.5 0 1 1-.708-.706l.88-.88a.5.5 0 0 1 .708 0Zm2.94-2.942 2.448 7.947 1.688-3.135.006-.012a1.416 1.416 0 0 1 .658-.658l.012-.006 3.135-1.688-7.948-2.448Zm9.841 2.451c-.055.142-.274.26-.713.497l-3.842 2.068a.863.863 0 0 0-.126.075.412.412 0 0 0-.069.069.834.834 0 0 0-.075.126l-2.068 3.842c-.237.439-.355.658-.497.713a.415.415 0 0 1-.374-.036c-.129-.082-.202-.32-.349-.797l-2.871-9.32c-.12-.39-.18-.585-.132-.716a.416.416 0 0 1 .247-.247c.131-.048.326.012.715.132l9.321 2.871c.477.147.715.22.797.349.071.112.085.25.037.374Z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M4.167 3h15.666C20.478 3 21 3.522 21 4.167v6.83h1v-6.83C22 2.97 21.03 2 19.833 2H4.167C2.97 2 2 2.97 2 4.167v15.666C2 21.03 2.97 22 4.167 22h6.83v-1h-6.83A1.167 1.167 0 0 1 3 19.833V4.167C3 3.522 3.522 3 4.167 3Z"
      clipRule="evenodd"
    />
  </svg>
);
