import { FC, useState } from "react";

import {
  Box,
  Button,
  ButtonGroup,
  Column,
  FormField,
  NumberInput,
  Paragraph,
  PlayIcon,
  Row,
  Switch,
  Text,
  Tooltip,
  useToast,
} from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { captureException } from "@sentry/react";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import merge from "lodash/merge";
import partition from "lodash/partition";
import pluralize from "pluralize";
import {
  Controller,
  ControllerRenderProps,
  useFieldArray,
} from "react-hook-form";
import { useSearchParams } from "src/router";

import samplingPlaceholder from "src/assets/placeholders/sampling.svg";
import { ActionBar } from "src/components/action-bar";
import {
  Form,
  HightouchSubmitHandler,
  useHightouchForm,
} from "src/components/form";
import {
  ScheduleInterval,
  ScheduleIntervalUnit,
} from "src/components/schedule/types";
import {
  SampledSegmentsInsertInput,
  SampledSegmentsUpdates,
  useSamplingForParentModelQuery,
  useCreateAndUpdateSampledSegmentsMutation,
  useRunSampledSegmentsMutation,
} from "src/graphql";
import { PageSpinner } from "src/components/loading";

import { ParentModelSamplingConfigCard } from "./parent-model-sampling-config-card";
import { RunsTable } from "./runs-table";
import { SampledRelatedModelsTable } from "./sampled-related-models-table";
import { Schedule } from "./schedule";
import {
  DEFAULT_SAMPLE_RATE,
  DEFAULT_SCHEDULE,
  SampledModel,
  SamplingConfig,
  samplingSchema,
} from "./types";
import { diffSampledRelatedModels, isSampledParentModel } from "./utils";

type Props = {
  lightningEngineEnabled: boolean;
  modelName: string;
  sourceId: string;
};

export const ParentModelSampling: FC<Props> = ({
  lightningEngineEnabled,
  modelName,
  sourceId,
}) => {
  const [isRunButtonDisabled, setIsRunButtonDisabled] = useState(false);
  const { toast } = useToast();

  const [searchParams] = useSearchParams();
  const parentModelId = searchParams.get("id");

  const { data, isLoading: isLoadingSamplingSegment } =
    useSamplingForParentModelQuery(
      {
        parentModelId: String(parentModelId),
      },
      {
        enabled: parentModelId != null,
        select: (data) => {
          const [parentModels, relatedModels] = partition(
            data.sampled_segments,
            (sampledModel) =>
              isSampledParentModel(sampledModel, String(parentModelId)),
          );

          const sampledParentModel = parentModels?.[0];
          const [sampledRelatedParentModels, sampledRelatedModels] = partition(
            relatedModels,
            (m) => String(m.segment.id) === String(parentModelId),
          );

          return {
            sampledParentModel,
            sampledRelatedModels,
            sampledRelatedParentModels,
            relationships: data.relationships,
          };
        },
      },
    );

  const sampledParentModel = data?.sampledParentModel;
  const sampledRelatedModels = data?.sampledRelatedModels ?? [];
  const sampledRelatedParentModels = data?.sampledRelatedParentModels ?? [];
  const relationships = data?.relationships ?? [];

  const createAndUpdateSampledSegments =
    useCreateAndUpdateSampledSegmentsMutation();
  const runSampledSegments = useRunSampledSegmentsMutation();

  const onSubmit: HightouchSubmitHandler<SamplingConfig> = async (data) => {
    const insert: SampledSegmentsInsertInput[] = [];
    const updates: SampledSegmentsUpdates[] = [];

    if (shouldDisable) {
      // Disable the parent model and related models
      updates.push(
        {
          where: { id: { _eq: sampledParentModel?.id } },
          _set: {
            enabled: false,
          },
        },
        ...sampledRelatedModels.map((sampledModel) => ({
          where: { id: { _eq: sampledModel.id } },
          _set: {
            enabled: false,
          },
        })),
      );

      await createAndUpdateSampledSegments.mutateAsync({
        add: [],
        update: updates,
      });
    } else {
      // Find which sampled related models need to be created, updated, and disabled
      const diff = diffSampledRelatedModels(
        sampledRelatedModels.map((m) => ({
          id: m.id.toString(),
          modelId: m.segment.id.toString(),
          enabled: m.enabled,
          sampleRate: m.sample_rate,
          schedule: m.schedule,
        })),
        data.sampledRelatedModels,
      );

      // Insert newly selected related models.
      // We have to do this whether we're creating or updating a sampling parent model.
      insert.push(
        ...diff.add.map(
          (sampledModel): SampledSegmentsInsertInput => ({
            segment_id: sampledModel.modelId,
            parent_model_id: parentModelId,
            sample_rate: data.sampleRate,
            schedule: merge({}, data.schedule, sampledModel.schedule),
            // If we are creating the sampled parent model as well, trigger the initial run
            pending_manual_run: shouldCreate ? new Date().toISOString() : null,
          }),
        ),
      );

      if (shouldCreate) {
        // Create sampling for parent model
        insert.push({
          segment_id: parentModelId,
          sample_rate: data.sampleRate,
          schedule: data.schedule,
          // We want to kickoff the initial run once the parent model is created
          pending_manual_run: new Date().toISOString(),
        });

        await createAndUpdateSampledSegments.mutateAsync({ add: insert });
      } else if (shouldUpdate) {
        // Update the parent model
        updates.push({
          where: { id: { _eq: sampledParentModel?.id } },
          _set: {
            enabled: data.enabled,
            sample_rate: data.sampleRate,
            schedule: data.schedule,
          },
        });

        // Update related models that have had their values changed
        updates.push(
          ...diff.update.map(
            (sampledModel): SampledSegmentsUpdates => ({
              where: { id: { _eq: sampledModel.id } },
              _set: {
                enabled: sampledModel.enabled,
                sample_rate: sampledModel.sampleRate,
                schedule: merge({}, data.schedule, sampledModel.schedule),
              },
            }),
          ),
        );

        await createAndUpdateSampledSegments.mutateAsync({
          add: insert,
          update: updates,
        });
      } else {
        // Should not reach here
        throw new Error("Sampling submission encountered an error");
      }
    }

    setIsRunButtonDisabled(false);
  };

  const form = useHightouchForm<SamplingConfig>({
    values: {
      modelId: parentModelId?.toString(),
      enabled: sampledParentModel?.enabled ?? false,
      sampleRate: sampledParentModel?.sample_rate ?? DEFAULT_SAMPLE_RATE,
      schedule: sampledParentModel?.schedule ?? DEFAULT_SCHEDULE,
      sampledRelatedModels:
        // The initial state should set all related models to be sampled
        sampledParentModel == null
          ? relationships.map(
              (r): SampledModel => ({
                modelId: r.to_model.id.toString(),
                enabled: true,
                sampleRate: DEFAULT_SAMPLE_RATE,
                schedule: DEFAULT_SCHEDULE,
              }),
            )
          : sampledRelatedModels.map(
              (m): SampledModel => ({
                id: m.id.toString(),
                enabled: m.enabled,
                sampleRate: m.sample_rate,
                modelId: m.segment.id.toString(),
                schedule: m.schedule,
              }),
            ),
    },
    resolver: yupResolver(samplingSchema),
    onSubmit,
  });

  const {
    control,
    formState: { isDirty, isSubmitting },
    getValues,
    reset,
    submit,
    watch,
  } = form;
  const { update: updateSampledRelatedModelField } = useFieldArray({
    control,
    keyName: "arrayId",
    name: "sampledRelatedModels",
  });

  const enabled = watch("enabled");

  const shouldCreate = sampledParentModel == null;
  const shouldUpdate = sampledParentModel != null;
  const shouldDisable = shouldUpdate && !enabled;

  const handleRun = async () => {
    try {
      setIsRunButtonDisabled(true);

      const enabledSampledModelIds = [
        sampledParentModel,
        ...sampledRelatedModels,
      ]
        .filter((m) => m?.enabled)
        .map((m) => m?.id);

      await runSampledSegments.mutateAsync({
        sampledSegmentIds: enabledSampledModelIds,
      });

      toast({
        id: "run-sampling-success",
        title: "Manual run will begin shortly",
        message:
          "Parent and related models will be sampled. The amount of time it takes is dependent on the size of your models and the configured sample size.",
        variant: "success",
      });
    } catch (error) {
      setIsRunButtonDisabled(false);
      toast({
        id: "run-sampling-error",
        title: "Sampling could not be run",
        message:
          "There was an error enqueueing the models to be sampled. Please try again.",
        variant: "error",
      });
      captureException(error);
    }
  };

  const handleSampleRateChange = (
    field: ControllerRenderProps<SamplingConfig, "sampleRate">,
    sampleRate: number | undefined,
  ) => {
    if (sampleRate == undefined) {
      return;
    }

    // Update parent model's sample rate
    field.onChange(sampleRate);

    // Update each related model's sample rate
    getValues("sampledRelatedModels").forEach((relatedModelField, index) => {
      updateSampledRelatedModelField(index, {
        ...relatedModelField,
        sampleRate,
      });
    });
  };

  const handleScheduleChange = (
    field: ControllerRenderProps<SamplingConfig, "schedule">,
    partialSchedule: Partial<ScheduleInterval> | undefined,
  ) => {
    const oldSchedule = field.value;
    const newSchedule = merge({}, field.value, {
      schedule: { interval: partialSchedule },
    });

    // Update parent model's schedule
    field.onChange(newSchedule);

    // Update each related model's schedule if they don't have an override
    getValues("sampledRelatedModels").forEach((relatedModelField, index) => {
      const shouldOverride =
        relatedModelField.enabled &&
        isEqual(oldSchedule, relatedModelField.schedule);

      if (shouldOverride) {
        updateSampledRelatedModelField(
          index,
          merge({}, relatedModelField, {
            schedule: newSchedule,
          }),
        );
      }
    });
  };

  if (isLoadingSamplingSegment) {
    return <PageSpinner />;
  }

  return (
    <Column height="100%" justifyContent="space-between">
      <Form form={form}>
        <Column gap={6} p={6}>
          <Column gap={1}>
            <Row align="center" gap={4}>
              <Text fontWeight="medium" size="lg">
                Enable model sampling
              </Text>
              <Controller
                control={control}
                name="enabled"
                render={({ field }) => (
                  <Tooltip
                    isDisabled={lightningEngineEnabled}
                    message="Please enable the lightning engine for this source first"
                  >
                    <Switch
                      {...field}
                      isChecked={field.value}
                      isDisabled={!lightningEngineEnabled}
                    />
                  </Tooltip>
                )}
              />
            </Row>
            <Paragraph color="text.secondary">
              Materialize sampled subsets of models in the source warehouse to
              enable faster audience previews and analytics queries. This page
              controls sampling for the "{modelName}" parent model and
              associated related models.
            </Paragraph>
          </Column>
          {enabled ? (
            <>
              <Controller
                control={control}
                name="sampleRate"
                render={({ field, fieldState }) => (
                  <FormField
                    label="Sample size"
                    description="Percent of the parent model to be sampled"
                    error={fieldState.error?.message}
                  >
                    <NumberInput
                      {...field}
                      onChange={(sampleRate) =>
                        handleSampleRateChange(field, sampleRate)
                      }
                      step={0.0001}
                      formatOptions={{
                        style: "percent",
                        maximumFractionDigits: 4,
                      }}
                    />
                  </FormField>
                )}
              />

              <Controller
                control={control}
                name="schedule"
                render={({ field, fieldState }) => (
                  <FormField
                    label="Sample frequency"
                    description="How often the model(s) are resampled"
                  >
                    <Column gap={1}>
                      <Schedule
                        quantity={field.value.schedule?.interval.quantity ?? 1}
                        unit={
                          field.value.schedule?.interval.unit ??
                          ScheduleIntervalUnit.DAY
                        }
                        onChange={(partialSchedule) =>
                          handleScheduleChange(field, partialSchedule)
                        }
                      />
                      {/* <FormField /> doesn't handle nested object errors, so we render them ourselves */}
                      {/* We use get() because react-hook-form's FieldError type isn't smart enough to detect nested errors */}
                      {get(fieldState.error, "quantity.message") && (
                        <Text color="text.danger">
                          {get(fieldState.error, "quantity.message")}
                        </Text>
                      )}

                      {get(fieldState.error, "unit.message") && (
                        <Text color="text.danger">
                          {get(fieldState.error, "unit.message")}
                        </Text>
                      )}
                    </Column>
                  </FormField>
                )}
              />

              <Column gap={4}>
                <Column gap={1}>
                  <Text fontWeight="medium">Related models</Text>
                  <Paragraph color="text.secondary">
                    Select which related models to sample. We recommend sampling
                    all related models to achieve fastest queries, however you
                    have the option to exclude them as needed.
                  </Paragraph>
                </Column>
                <SampledRelatedModelsTable
                  relationships={relationships ?? []}
                />
              </Column>

              {sampledParentModel && (
                <Column gap={4}>
                  <Column gap={1}>
                    <Text fontWeight="medium">Last run</Text>
                    <Paragraph color="text.secondary">
                      Summary of the last sampling job ran for each model
                    </Paragraph>
                  </Column>
                  <RunsTable
                    parentModel={sampledParentModel.segment}
                    relationships={relationships}
                  />
                </Column>
              )}
            </>
          ) : sampledRelatedParentModels.length === 0 ? (
            <Box
              as="img"
              boxSize={4}
              alignSelf="center"
              width="380px"
              height="200px"
              src={samplingPlaceholder}
            />
          ) : null}
        </Column>
      </Form>

      {sampledRelatedParentModels.length ? (
        <Column gap={6} p={6}>
          <Column gap={2}>
            <Text fontWeight="medium" size="lg">
              Related model sampling
            </Text>
            <Text color="text.secondary">
              Sampling is configured at the parent model level. This model is
              related to{" "}
              {pluralize(
                "parent models",
                sampledRelatedParentModels.length,
                true,
              )}
              , listed below.
            </Text>
          </Column>
          {sampledRelatedParentModels.map((sampledSegment) => (
            <ParentModelSamplingConfigCard
              key={sampledSegment.id}
              enabled={sampledSegment.enabled}
              lastRunAt={sampledSegment.last_run_at}
              parentModelId={sampledSegment.parent_model?.id}
              parentModelName={sampledSegment.parent_model?.name ?? ""}
              sampleRate={sampledSegment.sample_rate}
              schedule={sampledSegment.schedule}
              sourceId={sourceId}
            />
          ))}
        </Column>
      ) : null}

      <ActionBar fit>
        <Row gap={3} justifyContent="space-between" width="100%">
          <ButtonGroup>
            {/* Save & run */}
            {shouldCreate && (
              <Button
                isDisabled={!isDirty || isSubmitting}
                isLoading={isSubmitting}
                variant="primary"
                onClick={submit}
              >
                Save & run sampling
              </Button>
            )}

            {/* Save only */}
            {shouldUpdate && (
              <Button
                isDisabled={!isDirty || isSubmitting}
                isLoading={isSubmitting}
                variant="primary"
                onClick={submit}
              >
                Save changes
              </Button>
            )}

            {/* Discard changes */}
            <Button
              isDisabled={!isDirty || isSubmitting}
              isLoading={isSubmitting}
              onClick={() => reset()}
            >
              Discard changes
            </Button>
          </ButtonGroup>

          {/* Run only */}
          {enabled && shouldUpdate && (
            <Button
              icon={PlayIcon}
              isDisabled={isDirty || isSubmitting || isRunButtonDisabled}
              isLoading={runSampledSegments.isLoading}
              onClick={handleRun}
            >
              Run
            </Button>
          )}
        </Row>
      </ActionBar>
    </Column>
  );
};
