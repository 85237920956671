import {
  Column,
  ListBulletedIcon,
  Row,
  SectionHeading,
  SettingsIcon,
  Spinner,
  Text,
} from "@hightouchio/ui";
import { FC, useEffect, useState } from "react";

import { EventSelect } from "./event-select";
import { EventForwardingSync } from "../..";
import { useLocation, useNavigate, useParams } from "src/router";

import {
  useEventForwardingDefinitionQuery,
  useFormkitEventConfigValidationQuery,
  useUpdateEventForwardingSyncMutation,
} from "../../../../../graphql";
import ConfigurationForm from "./configuration-form";
import { useUser } from "../../../../../contexts/user-context";
import EventConfigurationForm from "./event-configuration-form";
import { AllEvents } from "./all-events";
import { isEmpty } from "lodash";

type Props = {
  sync: EventForwardingSync;
};

const CONFIG_KEY = "__config__";

export const AdvancedEventForm: FC<Readonly<Props>> = ({ sync }) => {
  const { pathname } = useLocation();
  const { workspace } = useUser();
  const updateMutation = useUpdateEventForwardingSyncMutation();
  const { event, id } = useParams<{ event: string; id: string }>();
  const navigate = useNavigate();
  const [pickedEvents, setPickedEvents] = useState<Array<string>>([]);
  const {
    error: definitionError,
    data,
    isLoading: definitionLoading,
  } = useEventForwardingDefinitionQuery({
    type: sync.destination.type,
  });
  const sharedConfig = data?.eventForwardingSharedDefinition;
  const page =
    pathname.endsWith("/configuration") && sharedConfig ? "config" : "event";
  useEffect(() => {
    if (
      event &&
      !pickedEvents.includes(event) &&
      data?.eventForwardingEvents?.find((def) => def.name === event)
    ) {
      setPickedEvents((names) => [...names, event]);
    }
  }, [data?.eventForwardingEvents, event]);

  if (definitionLoading && !definitionError) {
    return <Spinner size="lg" m="auto" />;
  }

  const events = data?.eventForwardingEvents ?? [];
  const definition = event ? events.find(({ name }) => name === event) : null;
  const [subscription, subscriptionIndex] =
    (definition &&
      sync.config.subscriptions?.flatMap((sub, i) =>
        sub.trackedEvent === definition.name ? [[sub, i]] : [],
      )[0]) ??
    (definitionLoading || events.length > 0
      ? [null, sync.config.subscriptions?.length ?? 0]
      : [sync.config.subscriptions[0], 0]);
  const savedEvents = [
    ...events.map((definition) => {
      if (
        sync.config.subscriptions?.find(
          (sub) => sub.trackedEvent === definition.name,
        )
      ) {
        return definition.name;
      }
      return "";
    }),
    sync.config.catchAll ? "custom" : "",
  ].filter(Boolean);

  const eventConfig = (
    <EventConfigurationForm
      formkitDefinition={data?.eventForwardingEventDefinition}
      config={{
        trackedEvent: definition?.name ?? "",
        syncConfig: {
          ...(subscription?.syncConfig ?? definition?.default ?? {}),
          _eventName: definition?.name ?? "",
        },
        filter: subscription?.filter ?? definition?.subscription ?? {},
      }}
      destination={sync.destination}
      source={sync.event_source}
      submit={({ filter, syncConfig }) =>
        updateMutation.mutateAsync({
          id: sync.id,
          workspaceId: workspace?.id,
          input: {
            config: {
              ...sync.config,
              subscriptions: [
                ...sync.config.subscriptions.slice(0, subscriptionIndex),
                {
                  trackedEvent: definition?.name ?? "",
                  filter: !isEmpty(filter)
                    ? {
                        type: "evaluate",
                        operation: "equals",
                        field: "event",
                        ...filter,
                      }
                    : { type: "unmatched" },
                  syncConfig,
                },
                ...sync.config.subscriptions.slice(subscriptionIndex + 1),
              ],
            },
          },
        })
      }
      otherFilters={events.length > 0}
      validation={useFormkitEventConfigValidationQuery}
      saved={Boolean(subscription)}
      onDelete={
        definition
          ? async () => {
              setPickedEvents(
                pickedEvents.filter((name) => name !== definition?.name),
              );
              if (subscription) {
                await updateMutation.mutateAsync({
                  id: sync.id,
                  workspaceId: workspace?.id,
                  input: {
                    config: {
                      ...sync.config,
                      subscriptions: [
                        ...sync.config.subscriptions.slice(
                          0,
                          subscriptionIndex,
                        ),
                        ...sync.config.subscriptions.slice(
                          subscriptionIndex + 1,
                        ),
                      ],
                    },
                  },
                });
              }
              navigate(`/events/syncs/${id}/configuration`);
            }
          : undefined
      }
    />
  );

  if (events.length === 0)
    return (
      <Column gap={6} flex={1}>
        {eventConfig}
      </Column>
    );

  return (
    <Row flex={1}>
      <Column
        borderRight="1px"
        borderColor="base.border"
        p={6}
        pl={0}
        maxWidth="220px"
        flex={1}
        overflow="auto"
      >
        <EventSelect
          menuItems={[
            ...(sharedConfig
              ? [
                  {
                    icon: SettingsIcon,
                    name: "Settings",
                    value: CONFIG_KEY,
                  },
                ]
              : []),
            {
              icon: ListBulletedIcon,
              name: "Events",
              value: undefined,
            },
          ]}
          savedEvents={savedEvents}
          pickedEvents={pickedEvents.filter(
            (name) => !savedEvents.includes(name),
          )}
          events={events}
          selected={
            page === "config"
              ? CONFIG_KEY
              : event === "custom"
                ? "custom"
                : event
          }
          onSelect={(key) => {
            if (key === CONFIG_KEY) {
              navigate(`/events/syncs/${id}/configuration`, {
                replace: true,
              });
            } else if (key) {
              navigate(`/events/syncs/${id}/configuration/event/${key}`, {
                replace: true,
              });
            } else {
              navigate(`/events/syncs/${id}/configuration/event`, {
                replace: true,
              });
            }
          }}
        />
      </Column>
      <Column flex={1}>
        {page === "config" ? (
          <Row px={6}>
            <ConfigurationForm
              formkitDefinition={sharedConfig}
              config={sync.config.sharedConfig}
              destination={sync.destination}
              source={sync.event_source}
              submit={(config) =>
                updateMutation.mutateAsync({
                  id: sync.id,
                  workspaceId: workspace?.id,
                  input: {
                    config: {
                      ...sync.config,
                      sharedConfig: config,
                    },
                  },
                })
              }
            />
          </Row>
        ) : definition ? (
          <>
            <Column p={6} pb={0}>
              <SectionHeading>{definition.title}</SectionHeading>
              {definition.description && (
                <Text color="text.secondary">{definition.description}</Text>
              )}
            </Column>
            {eventConfig}
          </>
        ) : (
          <AllEvents
            name={sync.destination.name ?? sync.destination.definition.name}
            events={events.filter((e) => e.subscription.type !== "unmatched")}
            customEvent={
              events.find((e) => e.subscription.type === "unmatched")?.name
            }
          />
        )}
      </Column>
    </Row>
  );
};
