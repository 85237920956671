import { FC, useEffect, useState } from "react";

import { Box, Row } from "@hightouchio/ui";
import _ from "lodash";

import { ReloadButton } from "src/components/reload-button";
import { useDestinationForm } from "src/contexts/destination-form-context";

import { TextWithTooltip } from "src/components/text-with-tooltip";

type MappingsHeaderProps = {
  object?: string;
  lookup?: boolean;
  reload?: () => void;
  loading?: boolean;
  spacing?: number;
};

export const MappingsHeader: FC<Readonly<MappingsHeaderProps>> = ({
  object,
  reload,
  loading,
  spacing,
}) => {
  const {
    model: latestModel,
    loadingModel,
    reloadModel,
    destinationDefinition,
    sourceDefinition,
  } = useDestinationForm();
  const [oldModel, setOldModel] = useState(latestModel);

  const model = latestModel || oldModel;

  useEffect(() => {
    if (latestModel) {
      setOldModel(latestModel);
    }
  }, [latestModel]);

  return (
    <Row mb={2}>
      <Row align="center" flex={1}>
        <Box
          as="img"
          src={sourceDefinition?.icon}
          sx={{ width: "20px", mr: 2, flexShrink: 0 }}
        />
        <TextWithTooltip message={`Column from ${model?.name}`}>
          <>
            Column from <strong>{model?.name}</strong>
          </>
        </TextWithTooltip>
        <ReloadButton
          isLoading={loadingModel}
          ml={2}
          tooltip="Click to refresh columns available in the source"
          onClick={reloadModel}
        />
      </Row>
      <Box width="48px" />
      <Row flex={1} align="center">
        <Box
          as="img"
          src={destinationDefinition?.icon}
          sx={{ width: "20px", mr: 2, flexShrink: 0 }}
        />
        <TextWithTooltip
          fontWeight="medium"
          message={
            object
              ? `Field from ${_.startCase(object)}`
              : `Field in ${destinationDefinition?.name}`
          }
        >
          {object ? (
            <>
              Field from <strong>{_.startCase(object)}</strong>
            </>
          ) : (
            <>
              Field in <strong>{destinationDefinition?.name}</strong>
            </>
          )}
        </TextWithTooltip>
        {reload && (
          <ReloadButton
            isLoading={loading}
            ml={2}
            tooltip="Click to refresh fields available in the destination"
            onClick={reload}
          />
        )}
      </Row>
      {spacing && <Box sx={{ width: spacing }} />}
    </Row>
  );
};
