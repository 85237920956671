import {
  ConfirmationDialog,
  FormField,
  Paragraph,
  Select,
  Text,
  useToast,
} from "@hightouchio/ui";
import pluralize from "pluralize";
import { FC, useMemo, useState } from "react";
import {
  ListAudiencesWithoutSubsetCategoryQuery,
  SubsetCategoryQuery,
} from "src/graphql/types";
import { useParams } from "src/router";
import {
  useBulkUpdateAudienceSubsetsMutation,
  useUpdateSubsetCategoryMutation,
} from "src/graphql";
import { captureException } from "@sentry/react";

type SubsetValues = NonNullable<
  SubsetCategoryQuery["audience_subset_groups_by_pk"]
>["subset_values"];

export const BackfillRequiredSubsetsModal: FC<{
  isOpen: boolean;
  subsetValues: SubsetValues;
  audiencesWithoutSubsetCategory: NonNullable<
    ListAudiencesWithoutSubsetCategoryQuery["segments_by_pk"]
  >["audiences_for_parent_model"];
  subsetCategoryName: string;
  onClose: () => void;
}> = ({
  isOpen,
  audiencesWithoutSubsetCategory,
  subsetCategoryName,
  subsetValues,
  onClose,
}) => {
  const { subset_category_id: subsetCategoryId } = useParams();
  const bulkUpdateAudienceSubsets = useBulkUpdateAudienceSubsetsMutation();
  const updateSubsetCategory = useUpdateSubsetCategoryMutation();
  const { toast } = useToast();

  const options = useMemo(() => {
    return subsetValues.map((subsetValue) => ({
      label: subsetValue.name,
      value: subsetValue.id,
    }));
  }, [subsetValues]);

  const [selectedBackfillSubset, setSelectedBackfillSubset] = useState(
    options.length >= 1 ? options[0]!.value : undefined,
  );

  const backfillRequiredSubsetCategory = async () => {
    try {
      await bulkUpdateAudienceSubsets.mutateAsync({
        upsert_objects: audiencesWithoutSubsetCategory.map((audience) => ({
          segment_id: audience.id,
          subset_value_id: selectedBackfillSubset,
        })),
      });

      await updateSubsetCategory.mutateAsync({
        id: subsetCategoryId ?? "",
        input: {
          is_required: true,
        },
      });

      toast({
        id: `update-requirement`,
        title: `Successfully set "${subsetCategoryName}" to required, and backfilled ${pluralize(
          "audience",
          audiencesWithoutSubsetCategory.length,
          true,
        )}.`,
        variant: "success",
      });
    } catch (error) {
      toast({
        id: `update-requirement`,
        title: `Could not set "${subsetCategoryName}" to required. Please try again.`,
        variant: "error",
      });
      captureException(error);
    }
  };

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      title={`Require a selection for "${subsetCategoryName}"`}
      confirmButtonText="Apply"
      variant="warning"
      isConfirmButtonDisabled={selectedBackfillSubset === undefined}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={backfillRequiredSubsetCategory}
    >
      <Paragraph>
        Before making <Text fontWeight="medium">"{subsetCategoryName}"</Text> a
        required field, you must select a subset to backfill this field for{" "}
        {pluralize("audience", audiencesWithoutSubsetCategory.length, true)}{" "}
        that {pluralize("is", audiencesWithoutSubsetCategory.length)} missing a
        selection.
        <br />
        <br />
        <FormField label={`"${subsetCategoryName}" subset to backfill`}>
          <Select
            options={options}
            value={selectedBackfillSubset}
            placeholder="Select an option"
            onChange={(subsetId) => {
              setSelectedBackfillSubset(subsetId);
            }}
          />
        </FormField>
      </Paragraph>
    </ConfirmationDialog>
  );
};
