import { FormField, Row, Select } from "@hightouchio/ui";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { EventTypeIcon } from "src/events/contracts/contract/event-schema/icons";
import { eventTypeOptions } from "src/events/contracts/contract/event-schema/options";
import { EventSchemaFormState } from "src/events/contracts/types";

export const TypeField: FC = () => {
  const { control } = useFormContext<EventSchemaFormState>();

  return (
    <Controller
      control={control}
      name="eventType"
      render={({ field, formState, fieldState }) => (
        <FormField
          label="Type"
          error={
            formState.isSubmitted && fieldState.error?.message
              ? fieldState.error.message
              : undefined
          }
        >
          <Select
            options={eventTypeOptions}
            optionAccessory={({ value }) => ({
              type: "icon",
              icon: () => (
                <Row fontSize="16px">
                  <EventTypeIcon eventType={value} />
                </Row>
              ),
            })}
            placeholder="Select the event type"
            value={field.value}
            onChange={field.onChange}
          />
        </FormField>
      )}
    />
  );
};
