import { FC } from "react";

import { Navigate, useOutletContext } from "src/router";
import { OutletContext } from ".";

import { WarehouseSyncArchive } from "src/events/syncs/warehouse-sync/archive";

export const Archive: FC = () => {
  const context = useOutletContext<OutletContext>();

  if (context.type === "forwarding") {
    return <Navigate to={`/events/syncs/${context.sync.id}`} replace />;
  }

  return <WarehouseSyncArchive />;
};
