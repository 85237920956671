import { FC } from "react";

import { CodeSnippet, Heading, Text } from "@hightouchio/ui";

import { Link } from "src/router";

import { CodeParams, SetupProps } from "./types";

function getJSCodeEvents(params: CodeParams): string {
  return `
  import { HtEvents } from "@ht-sdks/events-sdk-js-node";

  // initialize once
  export const htevents = new HtEvents({ 
    writeKey: "${params.writeKey}",
    host: "https://${params.apiHost}",
  });
  `;
}

function getJSCodeApp(): string {
  return `
  // import your already initialized instance into the rest of your app
  import { htevents } from "./events";

  // example usage in an express like framework
  app.post('/login', (req, res) => {
     htevents.identify({
        userId: req.body.userId,
        previousId: req.body.previousId
    })
    res.sendStatus(200)
  })
  `;
}

const npmInstall = "npm install @ht-sdks/events-sdk-js-node";

export const SetupNode: FC<SetupProps> = (props: SetupProps) => {
  return (
    <>
      <Heading>Installing the SDK</Heading>
      <Text>
        This SDK is available via{" "}
        <Link href="https://www.npmjs.com/package/@ht-sdks/events-sdk-js-node">
          NPM
        </Link>
        .
      </Text>
      <CodeSnippet code={npmInstall} />
      <Heading>Using the SDK</Heading>
      <CodeSnippet label="events.js" code={getJSCodeEvents(props)} />
      <CodeSnippet label="app.js" code={getJSCodeApp()} />
    </>
  );
};
