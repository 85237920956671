import { TimeIcon } from "@hightouchio/ui";
import { isPresent } from "ts-extras";

import { JourneyNodeDetails } from "src/pages/journeys/types";
import { TimeDelayConfig } from "src/types/journeys";

import pluralize from "pluralize";
import { DescriptionRow } from "./description-row";
import { EmptyStateDescription } from "./empty-state-description";

export const TimeDelayDescription = ({
  config,
}: JourneyNodeDetails<TimeDelayConfig>) => {
  if (!isPresent(config.delay.quantity)) return <EmptyStateDescription />;

  return (
    <DescriptionRow icon={TimeIcon}>
      {pluralize(config.delay.unit, config.delay.quantity, true)}
    </DescriptionRow>
  );
};
