import { FC, Suspense } from "react";

import {
  Column,
  Spinner,
  ToggleButton,
  ToggleButtonGroup,
} from "@hightouchio/ui";
import { Outlet, useNavigate, useOutletContext, useParams } from "src/router";

import { OutletContext } from "..";

export const ForwardingSyncErrors: FC = () => {
  const navigate = useNavigate();
  const { "*": tab } = useParams<{ "*": "archived" | "retrying" }>();
  const context = useOutletContext<OutletContext>();

  return (
    <Column gap={4} py={6}>
      <ToggleButtonGroup
        value={tab}
        onChange={(value) => {
          navigate(`/events/syncs/${context.sync.id}/errors/${value}`);
        }}
      >
        <ToggleButton label="Retrying" value="retrying" />
        <ToggleButton label="Archived" value="archived" />
      </ToggleButtonGroup>

      <Column gap={6}>
        <Suspense fallback={<Spinner size="lg" m="auto" />}>
          <Outlet context={context} />
        </Suspense>
      </Column>
    </Column>
  );
};
