import {
  EventSourceDefinition as BackendEventSourceDefinition,
  eventSourceDefinitions as backendEventSourceDefinitions,
  EventSourceType,
  EventWarehouseDestinationType,
  eventWarehouseDestinationDefinitions,
  eventForwardingDestinationDefinitions,
  EventForwardingDestinationType,
} from "@hightouch/core/server/resources/events/metadata";
import type { FormkitForm } from "@hightouch/formkit";
import { KafkaForm } from "./sources/definitions/kafka";
import { PubSubForm } from "./sources/definitions/pubsub";

export * from "@hightouch/core/events/types";

export type EventSourceDefinition<T extends EventSourceType = EventSourceType> =
  BackendEventSourceDefinition<T> & { form?: FormkitForm };

export const eventSourceDefinitions: {
  [K in EventSourceType]: EventSourceDefinition<K>;
} = {
  ...backendEventSourceDefinitions,
  [EventSourceType.KAFKA]: {
    ...backendEventSourceDefinitions[EventSourceType.KAFKA],
    form: KafkaForm,
  },
  [EventSourceType.CONFLUENT_KAFKA]: {
    ...backendEventSourceDefinitions[EventSourceType.CONFLUENT_KAFKA],
    form: KafkaForm,
  },
  [EventSourceType.PUBSUB]: {
    ...backendEventSourceDefinitions[EventSourceType.PUBSUB],
    form: PubSubForm,
  },
};

export {
  eventWarehouseDestinationDefinitions,
  EventWarehouseDestinationType,
  eventForwardingDestinationDefinitions,
  EventForwardingDestinationType,
  EventSourceType,
};
