import { FC, useEffect, useState } from "react";

import {
  Box,
  useToast,
  Button,
  Column,
  Row,
  Combobox,
  Text,
  ButtonGroup,
  EditableText,
  ConfirmationDialog,
} from "@hightouchio/ui";
import { captureException } from "@sentry/react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { RouterLink, useNavigate, useParams } from "src/router";

import {
  useAvailableThroughRelationshipsQuery,
  useCreateThroughRelationshipMutation,
  useUpdateThroughRelationship2Mutation,
  ThroughInstance,
  ThroughRelationshipsQuery,
  useDeleteThroughRelationshipMutation,
  SlugResourceType,
} from "src/graphql";
import { useResourceSlug } from "src/utils/slug";

export const ThroughRelationshipForm: FC<
  Readonly<{
    throughRelationshipModels?: ThroughRelationshipsQuery["model_relationships"];
    sourceId: string;
    modelId: string;
    modelSlug: string | null;
  }>
> = ({ modelId, modelSlug, sourceId, throughRelationshipModels }) => {
  const params = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [isDeleting, setIsDeleting] = useState(false);

  const { getSlug } = useResourceSlug(SlugResourceType.ModelRelationships);

  const updateRelationshipMutation = useUpdateThroughRelationship2Mutation();
  const createRelationshipMutation = useCreateThroughRelationshipMutation();
  const deleteRelationshipMutation = useDeleteThroughRelationshipMutation();

  const { data: availableThroughRelationships } =
    useAvailableThroughRelationshipsQuery(
      { modelId, sourceId },
      {
        select: (data) =>
          data.listAvailableThroughRelationships.throughRelationships,
      },
    );

  const currentRelationship = throughRelationshipModels?.find(
    (r) => String(r.id) === String(params?.id),
  );

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm<{
    id: string | undefined;
    name: string | undefined;
    throughId: string | undefined;
  }>();

  const id = watch("id");
  const throughId = watch("throughId");

  const throughOptions = availableThroughRelationships?.find(
    (r) => String(r.id) === String(id),
  )?.through;

  const submit: SubmitHandler<any> = async (data) => {
    const throughInstance = throughOptions?.find(
      (t) => String(t.id) === String(data.throughId),
    ) as ThroughInstance;

    if (currentRelationship) {
      try {
        await updateRelationshipMutation.mutateAsync({
          id: currentRelationship.id,
          object: {
            to_model_id: data.id,
            name: data.name,
          },
          throughRelationships: throughInstance.pathSegments.map((path) => ({
            relationship_id: currentRelationship.id,
            path_segment_id: path.id,
          })),
        });
        toast({
          id: "update-through-relationships",
          title: "Through relationship updated",
          variant: "success",
        });
      } catch (e) {
        toast({
          id: "update-through-relationships",
          title: "Through relationship failed to update",
          variant: "error",
        });
        captureException(e);
      }
    } else {
      try {
        const slug = await getSlug((modelSlug || modelId) + "-" + data.name);
        await createRelationshipMutation.mutateAsync({
          object: {
            slug,
            name: data.name,
            is_direct_relationship: false,
            from_model_id: modelId,
            to_model_id: data.id,
            through_relationships: {
              data: throughInstance.pathSegments.map((path) => ({
                path_segment_id: path.id,
              })),
            },
          },
        });
        toast({
          id: "create-through-relationships",
          title: "Through relationship created",
          variant: "success",
        });
        navigate({
          pathname: "/schema-v2/view/relationships",
          search: window.location.search,
        });
      } catch (e) {
        toast({
          id: "create-through-relationships",
          title: "Through relationship failed to create",
          variant: "error",
        });
        captureException(e);
      }
    }
  };

  const handleDelete = async () => {
    try {
      await deleteRelationshipMutation.mutateAsync({
        id: currentRelationship?.id,
      });
      toast({
        id: "delete-through-relationships",
        title: "Through relationship deleted",
        variant: "success",
      });
      navigate({
        pathname: "/schema-v2/view/relationships",
        search: window.location.search,
      });
    } catch (e) {
      toast({
        id: "delete-through-relationships",
        title: "Through relationship failed to delete",
        variant: "error",
      });
      captureException(e);
    }
  };

  useEffect(() => {
    if (!throughId && throughOptions && throughOptions.length === 1) {
      setValue("throughId", throughOptions[0]?.id, { shouldDirty: true });
    }
  }, [throughId, throughOptions]);

  useEffect(() => {
    if (currentRelationship && availableThroughRelationships?.length) {
      reset({
        id: String(currentRelationship.to_model.id),
        name: currentRelationship.name ?? currentRelationship.to_model.name,
        throughId: availableThroughRelationships
          ?.find(
            (r) => String(r.id) === String(currentRelationship.to_model.id),
          )
          ?.through.find((t) =>
            t.pathSegments.every((path) =>
              currentRelationship?.through_relationships?.some(
                (tr) => String(tr.relationship.id) === String(path.id),
              ),
            ),
          )?.id,
      });
    }
  }, [availableThroughRelationships]);

  return (
    <Column height="100%">
      <Column flex={1} p={6} overflow="auto">
        <Row align="center" mb={4} gap={2}>
          <RouterLink
            to={{
              pathname: "/schema-v2/view/relationships",
              search: window.location.search,
            }}
          >
            <Box
              as={Text}
              color="text.secondary"
              _hover={{ color: "text.primary" }}
            >
              Through relationships
            </Box>
          </RouterLink>
          <Text fontWeight="medium" color="text.secondary">
            /
          </Text>
          <Text color="text.secondary" fontWeight="medium">
            {currentRelationship
              ? currentRelationship.name
              : "New through relationship"}
          </Text>
        </Row>
        <Column gap={4}>
          <Controller
            control={control}
            name="name"
            render={({ field }) =>
              field.value ? (
                <EditableText
                  value={field.value}
                  onChange={field.onChange}
                  fontWeight="medium"
                />
              ) : (
                <Text fontWeight="medium" color="text.secondary">
                  New through relationship
                </Text>
              )
            }
          />
          <Row align="center" gap={2}>
            <Text fontWeight="medium">Access</Text>
            <Controller
              control={control}
              name="id"
              render={({ field }) => (
                <Combobox
                  width="2xs"
                  options={availableThroughRelationships ?? []}
                  value={field.value}
                  onChange={(value) => {
                    field.onChange(value);
                    setValue("throughId", undefined);
                    const option = availableThroughRelationships?.find(
                      (r) => String(r.id) === String(value),
                    );
                    setValue("name", option?.name);
                  }}
                  optionLabel={(option) => `${option.name}`}
                  optionValue={(option) => option.id}
                  placeholder="Select a model..."
                />
              )}
            />
            {id && (
              <>
                <Text fontWeight="medium">through</Text>
                <Controller
                  control={control}
                  name="throughId"
                  render={({ field }) => (
                    <Combobox
                      width="2xs"
                      options={throughOptions ?? []}
                      value={field.value}
                      onChange={field.onChange}
                      optionValue={(option) => option.id}
                      optionLabel={(option) =>
                        option.pathSegments.map((p) => p.name).join(" -> ")
                      }
                      placeholder="Select a relationship..."
                    />
                  )}
                />
              </>
            )}
          </Row>
        </Column>
      </Column>
      <Row
        bg="white"
        borderTop="1px"
        borderColor="base.border"
        p={4}
        justify="space-between"
      >
        <ButtonGroup>
          <Button
            size="lg"
            variant="primary"
            isDisabled={!isDirty}
            onClick={handleSubmit(submit)}
          >
            Save changes
          </Button>
          <Button
            size="lg"
            isDisabled={!isDirty}
            onClick={() => {
              if (currentRelationship) {
                reset();
              } else {
                navigate({
                  pathname: "/schema-v2/view/relationships",
                  search: window.location.search,
                });
              }
            }}
          >
            Cancel
          </Button>
        </ButtonGroup>
        {currentRelationship && (
          <Button
            size="lg"
            variant="warning"
            onClick={() => {
              setIsDeleting(true);
            }}
          >
            Delete
          </Button>
        )}
      </Row>
      <ConfirmationDialog
        confirmButtonText="Delete"
        isOpen={isDeleting}
        title="Delete through relationship"
        variant="danger"
        onClose={() => setIsDeleting(false)}
        onConfirm={handleDelete}
      >
        <Text>Are you sure you want to delete this through relationship?</Text>
      </ConfirmationDialog>
    </Column>
  );
};
