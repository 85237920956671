import { FC, useMemo } from "react";

import { Combobox, FormField, GroupedCombobox, Row } from "@hightouchio/ui";
import get from "lodash/get";
import noop from "lodash/noop";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { Arrow } from "src/ui/arrow";
import {
  DEFAULT_ID_MAPPING_DESCRIPTION,
  DEFAULT_ID_MAPPING_LABEL,
  ID_MAPPING_DESCRIPTION,
  ID_MAPPING_LABEL,
} from "src/utils/destinations";

import { MappingsHeader } from "./mappings-header";

type FromIdFieldProps = {
  fieldName: string;
  path?: string[];
};

export const FromIdField: FC<Readonly<FromIdFieldProps>> = ({
  fieldName,
  path,
}) => {
  const { slug, config, setConfig, hightouchColumns, loadingModel, errors } =
    useDestinationForm();

  const hightouchColumnGroups = useMemo(() => {
    return hightouchColumns.map((group) => ({
      ...group,
      options: group.options ?? [],
    }));
  }, [hightouchColumns]);

  return (
    <FormField
      description={
        (path
          ? get(ID_MAPPING_DESCRIPTION[slug ?? ""], path)
          : ID_MAPPING_DESCRIPTION[slug ?? ""]) ||
        DEFAULT_ID_MAPPING_DESCRIPTION
      }
      error={errors?.fromId}
      label={
        (path
          ? get(ID_MAPPING_LABEL[slug ?? ""], path)
          : ID_MAPPING_LABEL[slug ?? ""]) || DEFAULT_ID_MAPPING_LABEL
      }
    >
      <MappingsHeader object={config?.object} />
      <Row align="center">
        <GroupedCombobox
          isInvalid={Boolean(errors?.fromId)}
          isLoading={loadingModel}
          optionGroups={hightouchColumnGroups}
          placeholder="Select a column..."
          width="100%"
          value={config?.fromId}
          onChange={(value) => {
            setConfig({
              ...config,
              fromId: value,
            });
          }}
        />
        <Arrow />
        <Combobox
          width="100%"
          options={[{ label: fieldName, value: fieldName }]}
          placeholder="Select a field..."
          value={fieldName}
          onChange={noop}
        />
      </Row>
    </FormField>
  );
};
