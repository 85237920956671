import { noop } from "ts-essentials";

import { CumulativeIcon, LinearIcon } from "@hightouchio/ui";
import { AggregationOption } from "src/pages/metrics/constants";
import { AnalyticsFrequency, IntervalUnit } from "src/types/visual";
import { v4 as uuidv4 } from "uuid";

import {
  AnalyticsActions,
  AnalyticsState,
  ChartFormState,
  ChartSchema,
  ConversionWindow,
  FunnelStep,
  GraphType,
  GroupByColumn,
  FunnelMeasurementType,
  MetricSelection,
  SelectedAudience,
  TimeOptions,
  MeasurementScope,
} from "src/pages/analytics/types";

export const SECONDS_IN_ONE_DAY = 86400;

export const TimeMap: Record<
  | TimeOptions.SevenDays
  | TimeOptions.ThirtyDays
  | TimeOptions.SixtyDays
  | TimeOptions.NinetyDays,
  number
> = {
  [TimeOptions.SevenDays]: SECONDS_IN_ONE_DAY * 7,
  [TimeOptions.ThirtyDays]: SECONDS_IN_ONE_DAY * 30,
  [TimeOptions.SixtyDays]: SECONDS_IN_ONE_DAY * 60,
  [TimeOptions.NinetyDays]: SECONDS_IN_ONE_DAY * 90,
};

export const SecondsToTimeOptions = {
  [SECONDS_IN_ONE_DAY * 7]: TimeOptions.SevenDays,
  [SECONDS_IN_ONE_DAY * 30]: TimeOptions.ThirtyDays,
  [SECONDS_IN_ONE_DAY * 60]: TimeOptions.SixtyDays,
  [SECONDS_IN_ONE_DAY * 90]: TimeOptions.NinetyDays,
};

export const LookbackOptions = [
  { label: "7d", value: TimeOptions.SevenDays },
  { label: "30d", value: TimeOptions.ThirtyDays },
  { label: "60d", value: TimeOptions.SixtyDays },
  { label: "90d", value: TimeOptions.NinetyDays },
];

export const AccumulationTypes = [
  {
    label: "Over time",
    description: "Metric is charted over time",
    value: false,
    icon: LinearIcon,
  },
  {
    label: "Cumulative",
    description: "Running total of your metric",
    value: true,
    icon: CumulativeIcon,
  },
];

export const RollupFrequencies = [
  { label: "Daily", value: AnalyticsFrequency.Daily },
  { label: "Weekly", value: AnalyticsFrequency.Weekly },
  { label: "Monthly", value: AnalyticsFrequency.Monthly },
];

export const STATIC_ARRAY = [];
export const PLACEHOLDER_AUDIENCE: SelectedAudience = {
  id: -1,
  name: "",
  splits: [],
};
export const DEFAULT_METRIC_SELECTION: MetricSelection[] = [
  {
    id: "",
    name: "",
    eventModelId: null,
    description: null,
    aggregationMethod: AggregationOption.Count,
    conditions: [],
  },
];

export const getDefaultFunnelSteps: () => FunnelStep[] = () => [
  { id: uuidv4(), eventModelId: "", relationshipId: "", subconditions: [] },
  { id: uuidv4(), eventModelId: "", relationshipId: "", subconditions: [] },
];

export const DEFAULT_SELECTED_AUDIENCES: SelectedAudience[] = [
  PLACEHOLDER_AUDIENCE,
];
export const DEFAULT_GROUP_BY_COLUMNS: (GroupByColumn | undefined)[] = [
  undefined,
];
export const DEFAULT_SELECTED_DATES: string[] = [];

export const DEFAULT_FUNNEL_CONVERSION_WINDOW: ConversionWindow = {
  quantity: 1,
  unit: IntervalUnit.Day,
};

export const defaultSchemaState: ChartSchema = {
  audiences: STATIC_ARRAY,
  audiencesAndMetricsLoading: false,
  events: STATIC_ARRAY,
  metrics: STATIC_ARRAY,
  parent: null,
  parentModelLoading: false,
  parentModelOptions: STATIC_ARRAY,
  parentModelOptionsLoading: false,
  measurementsOptions: STATIC_ARRAY,
  measurementsOptionsLoading: false,
};

export const defaultFormState: ChartFormState = {
  parentModelId: null,
  measuringSelection: {
    id: "",
    scope: MeasurementScope.AllData,
  },
  cumulative: false,
  funnelConversionWindow: DEFAULT_FUNNEL_CONVERSION_WINDOW,
  funnelSteps: getDefaultFunnelSteps(),
  graphType: GraphType.Performance,
  groupByColumns: DEFAULT_GROUP_BY_COLUMNS,
  funnelMeasuringType: FunnelMeasurementType.TotalConversion,
  metricSelection: DEFAULT_METRIC_SELECTION,
  rollupFrequency: AnalyticsFrequency.Daily,
  selectedAudiences: DEFAULT_SELECTED_AUDIENCES,
  selectedDates: [],
  timeValue: TimeOptions.SevenDays,
};

export const defaultState: AnalyticsState = {
  ...defaultSchemaState,
  ...defaultFormState,
};

export const defaultActions: AnalyticsActions = {
  addGroupByColumn: noop,
  addGroupByColumns: noop,
  removeGroupByColumns: noop,
  resetView: noop,
  selectParentModel: noop,
  setLookbackWindow: noop,
  setSelectedDates: noop,
  selectMeasurement: noop,
};

export const defaultPerformanceChartState = {
  cumulative: false,
  rollupFrequency: AnalyticsFrequency.Daily,
  groupByColumns: DEFAULT_GROUP_BY_COLUMNS,
  metricSelection: DEFAULT_METRIC_SELECTION,
  selectedDates: DEFAULT_SELECTED_DATES,
  timeValue: TimeOptions.SevenDays,
};

export const defaultFunnelChartState = {
  funnelConversionWindow: DEFAULT_FUNNEL_CONVERSION_WINDOW,
  funnelSteps: getDefaultFunnelSteps(),
  groupByColumns: DEFAULT_GROUP_BY_COLUMNS,
  funnelMeasuringType: FunnelMeasurementType.TotalConversion,
  selectedDates: DEFAULT_SELECTED_DATES,
  timeValue: TimeOptions.SevenDays,
};
