import { useEffect } from "react";

import { Column, Row, Text } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Outlet, useNavigate, useParams } from "src/router";

import genericPlaceholder from "src/assets/placeholders/generic.svg";
import { Drawer } from "src/components/drawer";
import { Form, useHightouchForm } from "src/components/form";
import { Table, TableColumn } from "src/ui/table";
import { TextWithTooltip } from "src/components/text-with-tooltip";

import { InspectorOutletContext } from "../types";
import { InspectorData } from "./inspector-data";

const placeholder = {
  image: genericPlaceholder,
  title: "No models",
  body: "Select input models to run an identity resolution process on. Use the resulting identity graph to create a golden record of user information.",
  error: "Models failed to load, please try again.",
};

export const Inspector = () => {
  const { enableProfileBuilder } = useFlags();
  const { htId } = useParams<{ htId?: string }>();
  const navigate = useNavigate();

  const closeDrawer = () => navigate("./");

  const form = useHightouchForm({
    onSubmit: () => Promise.resolve(),
    values: {},
  });

  useEffect(() => {
    if (!enableProfileBuilder) {
      navigate("../");
    }
  }, [enableProfileBuilder]);

  const outletContext: InspectorOutletContext = {
    onClose: closeDrawer,
  };

  return (
    <Column pb={24} gap={4}>
      <Table
        scrollable
        placeholder={placeholder}
        data={InspectorData}
        onRowClick={(row) => navigate(`./${row.ht_id}`)}
        columns={[
          {
            min: "200px",
            header: () => (
              <Row justify="space-between" flex={1} minWidth={0}>
                <Text size="sm" color="text.secondary">
                  HT ID
                </Text>
                <Text size="sm" color="text.secondary">
                  Uniques
                </Text>
              </Row>
            ),
            cell: (row) => (
              <Row gap={2} justify="space-between" flex={1} minWidth={0}>
                <TextWithTooltip>{row.ht_id}</TextWithTooltip>
                <TextWithTooltip>1</TextWithTooltip>
              </Row>
            ),
          },
          getTableColumn({
            label: "Email",
            key: "email",
            bg: "base.lightBackground",
          }),
          getTableColumn({ label: "Phone", key: "phone" }),
          getTableColumn({
            label: "MAID",
            key: "maid",
            bg: "base.lightBackground",
          }),
          getTableColumn({ label: "First name", key: "first_name" }),
          getTableColumn({
            label: "Last name",
            key: "last_name",
            bg: "base.lightBackground",
          }),
          getTableColumn({ label: "Last name", key: "last_name" }),
        ]}
      />
      <Drawer
        closeOnEsc
        isOpen={Boolean(htId)}
        showOverlay={false}
        trapFocus={false}
        size="lg"
        contentWrapperStyles={{
          borderLeft: "1px",
          borderTop: "1px",
          borderColor: "base.border",
        }}
        onClose={closeDrawer}
      >
        <Form form={form}>
          <Outlet context={outletContext} />
        </Form>
      </Drawer>
    </Column>
  );
};

const getTableColumn = ({
  label,
  key,
  bg,
}: {
  label: string;
  key: keyof (typeof InspectorData)[0];
  bg?: string;
}): TableColumn<(typeof InspectorData)[0]> => {
  return {
    min: "200px",
    headerSx: { bg: bg, pr: "4 !important" },
    cellSx: { bg: bg },
    header: () => (
      <Row justify="space-between" flex={1} minWidth={0}>
        <Text size="sm" color="text.secondary">
          {label}
        </Text>
        <Text size="sm" color="text.secondary">
          #
        </Text>
      </Row>
    ),
    cell: (row) => (
      <Row gap={2} justify="space-between" flex={1} minWidth={0}>
        <TextWithTooltip>{row[key]?.[0] ?? "--"}</TextWithTooltip>
        <TextWithTooltip>{row[key].length}</TextWithTooltip>
      </Row>
    ),
  };
};
