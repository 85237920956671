import { FC } from "react";

import { exhaustiveCheck } from "@hightouch/lib/util/exhaustive-check";
import { Text } from "@hightouchio/ui";
import { Link } from "src/router";
import pluralize from "pluralize";

import {
  Schedule,
  ScheduleIntervalUnit,
  ScheduleType,
} from "src/components/schedule/types";

const getScheduleText = (schedule: Schedule) => {
  switch (schedule.type) {
    case ScheduleType.INTERVAL:
      return `Every ${pluralize(
        schedule.schedule?.interval.unit ?? ScheduleIntervalUnit.DAY,
        schedule.schedule?.interval.quantity ?? 0,
        true,
      )}`;

    case ScheduleType.CRON:
    case ScheduleType.CUSTOM:
      return "Custom recurrence";
    default:
      // No other schedule types are supported
      return exhaustiveCheck(schedule.type as never);
  }
};

export const ScheduleText: FC<{ href?: string; schedule: Schedule }> = ({
  href,
  schedule,
}) => {
  const text: string | null = getScheduleText(schedule);

  if (href) {
    return (
      <Link href={href}>
        <Text color="inherit" fontWeight="medium">
          {text}
        </Text>
      </Link>
    );
  }

  return <Text fontWeight="medium">{text}</Text>;
};
