import { FC } from "react";

import { capitalize, orderBy } from "lodash";

import { useUser } from "src/contexts/user-context";
import {
  useCanDeployModelsQuery,
  useDeployModelMutation,
  useLinkableWorkspaceResourcesQuery,
} from "src/graphql";
import { ResourceActivityDiffer } from "src/hooks/use-resource-activity";
import { modelActivityMappers } from "src/pages/models/model-activity";

import { ResourceActivityMapper } from "../resource-activity/timeline";
import { Deployment } from "./common";
import { DeploymentWizard } from "./deployment-wizard";
import { getDeploymentDiff } from "./diff";
import { TableIcon } from "@hightouchio/ui";

const modelDeploymentDiffer = ResourceActivityDiffer(
  ["connection_id", "workspace_id", "model_id", "segment_id"],
  ["name"],
);

const modelDeploymentDiffMappers: ResourceActivityMapper[] = [
  ...modelActivityMappers,
  {
    accessor: "name",
    parser: (_, { parsedDiff, oldValue }) => {
      if (parsedDiff.type !== "value") {
        return null;
      }

      return {
        message: `Renamed from \`${oldValue}\` to \`${parsedDiff.value}\``,
        icon: <TableIcon />,
      };
    },
  },
  {
    accessor: "columns",
    parser: (_, { parsedDiff }) => {
      if (parsedDiff.type !== "array") {
        return null;
      }

      const changes: string[] = [];
      for (const column of parsedDiff.array) {
        changes.push(
          `${capitalize(column.operation)} \`${column.value.name}\``,
        );
      }
      return {
        message: `updated columns`,
        changes,
        icon: <TableIcon />,
      };
    },
  },
];

const getModelDeploymentDiff = (
  deployment: Deployment,
  sourceObj: any,
  targetObj: any,
) => {
  sourceObj = { ...sourceObj, columns: orderBy(sourceObj.columns, "name") };
  if (targetObj) {
    targetObj = { ...targetObj, columns: orderBy(targetObj.columns, "name") };
  }
  return getDeploymentDiff(modelDeploymentDiffer, modelDeploymentDiffMappers)(
    deployment,
    sourceObj,
    targetObj,
  );
};

export const ModelDeploymentWizard: FC<
  Readonly<{
    isOpen: boolean;
    deployment: Deployment<{ connectionId: string }>;
    onClose: () => void;
  }>
> = ({ deployment, isOpen, onClose }) => {
  const { user, isLoading: userLoading } = useUser();
  const { mutateAsync: deploy, isLoading: deploying } =
    useDeployModelMutation();

  const { data: deploymentTests, isLoading: deploymentTestsLoading } =
    useCanDeployModelsQuery(
      {
        sourceResourceId: deployment.sourceResourceId,
      },
      {
        enabled: !userLoading,
        refetchOnMount: "always",
        select: (data) => data.canDeploySegments,
      },
    );
  const targetWorkspaces =
    deploymentTests
      ?.map((t) => t.targetWorkspace)
      .filter(
        (workspace) =>
          String(workspace.id) !== String(user?.current_workspace_id),
      ) || [];

  const { data: linkableResources, isLoading: linkableResourcesLoading } =
    useLinkableWorkspaceResourcesQuery(
      {
        sourceResourceId: deployment.metadata?.connectionId || "",
        resourceType: "connections",
      },
      { select: (data) => data.getLinkableResources },
    );

  return (
    <DeploymentWizard
      isOpen={isOpen}
      onClose={onClose}
      deploymentTestsLoading={deploymentTestsLoading}
      isDeploying={deploying}
      getDeploymentDiff={getModelDeploymentDiff}
      linkableResources={linkableResources}
      linkableResourcesLoading={linkableResourcesLoading}
      deployResource={async (resourceId, targetWorkspaceId, draft) => {
        const { deployModel } = await deploy({
          modelId: resourceId,
          targetWorkspaceId,
          draft,
        });

        const success =
          deployModel.__typename === "SuccessfulModelDeploymentResult";
        return {
          success,
          resourceId: success ? deployModel.deployedResourceId : undefined,
        };
      }}
      deployment={deployment}
      deploymentTests={deploymentTests}
      targetWorkspaces={targetWorkspaces}
    />
  );
};
