import { getColumnName } from "src/utils/models";
import { AudienceExplorePageProps } from "src/components/audiences/types";
import { ColumnFragment } from "src/graphql";

export type ParentModelFields = {
  primaryKey: string;
  secondaryLabelKey: string | null;
  primaryLabelKey: string;
  columns: ColumnFragment[];
} | null;

export function useParentModelFields(
  parentModel: AudienceExplorePageProps["parentModel"],
): ParentModelFields {
  const primaryLabelKey = parentModel?.visual_query_primary_label
    ? getColumnName(parentModel.visual_query_primary_label, parentModel.columns)
    : parentModel?.primary_key;

  const primaryKey = parentModel?.primary_key;

  if (!parentModel || !primaryKey || !primaryLabelKey) {
    return null;
  }

  const secondaryLabelKey = parentModel.visual_query_secondary_label
    ? getColumnName(
        parentModel.visual_query_secondary_label,
        parentModel.columns,
      )
    : null;

  return {
    primaryKey,
    primaryLabelKey,
    secondaryLabelKey,
    columns: parentModel.columns.filter((column) => {
      if (
        [
          parentModel.primary_key,
          parentModel.visual_query_primary_label,
          parentModel.visual_query_secondary_label,
        ].includes(column.name)
      ) {
        return false;
      }

      return true;
    }),
  };
}
