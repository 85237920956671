import { MutableRefObject, useEffect, useState } from "react";

// This hook measures an element's height once and falls back
// to a hard-coded height that we can manually get from DevTools.
// It's useful to prevent layout bugs on this page, if someone
// made changes to one of the measured elements and forgot to
// update their hard-coded height in the codebase.
export const useMeasureHeightOnce = <T extends HTMLElement | null | undefined>(
  ref: MutableRefObject<T>,
  defaultHeight: number,
) => {
  const [height, setHeight] = useState(defaultHeight);

  useEffect(() => {
    if (ref.current) {
      const style = window.getComputedStyle(ref.current);
      const yMargin = parseInt(style.marginTop) + parseInt(style.marginBottom);

      setHeight(ref.current.offsetHeight + yMargin);
    }
  }, []);

  return height;
};
