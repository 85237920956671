import { FC, Ref, forwardRef, useRef, useState } from "react";

import {
  Box,
  Button,
  ChakraPopover,
  ChakraPopoverBody,
  ChakraPopoverContent,
  ChakraPopoverTrigger,
  Column,
  FilterIcon,
  Row,
  Select,
  Text,
  useDisclosure,
} from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller } from "react-hook-form";
import * as yup from "yup";

import { ErrorMessage } from "src/components/explore/visual/error-message";
import { PropertyInput } from "src/components/explore/visual/property-input";
import { updateConditionOperator } from "src/components/explore/visual/utils";
import { Form, SaveButton, useHightouchForm } from "src/components/form";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import {
  getNestedValueError,
  getPropertyConditionSchema,
} from "src/pages/audiences/validation";
import {
  CampaignFiltersAllowMultiValue,
  CampaignFiltersAllowPercentile,
} from "src/pages/campaigns/constants";
import {
  AndCondition,
  ColumnType,
  ConditionType,
  Operator,
  OperatorOptions,
  PropertyCondition,
  StringOperator,
} from "src/types/visual";

import { useCampaignsFilterContext } from "./hook";
import { useFetchColumnSuggestions } from "src/hooks/use-column-suggestions";

export const FilterPicker: FC<{
  onAddAssetFilter: (condition: PropertyCondition) => void;
  onAddMetricFilter: (condition: AndCondition<PropertyCondition>) => void;
}> = ({ onAddAssetFilter, onAddMetricFilter }) => {
  const focusRef = useRef<HTMLDivElement>(null);
  const { isOpen, onToggle, onClose } = useDisclosure({
    onOpen: () => setPickedColumn(null),
  });

  const [pickedColumn, setPickedColumn] = useState<{
    type: string;
    name: string;
    columnReference: any;
    goalId?: string;
    attributionMethodId?: string;
  } | null>(null);

  const { assetModelColumns, conversionMetrics, predefinedMetrics } =
    useCampaignsFilterContext();

  return (
    <ChakraPopover
      isOpen={isOpen}
      onOpen={() => setPickedColumn(null)}
      onClose={onClose}
      placement="bottom-start"
      initialFocusRef={focusRef}
      closeOnEsc
    >
      <ChakraPopoverTrigger>
        <Button
          icon={FilterIcon}
          variant="secondary"
          aria-label="filter"
          onClick={onToggle}
        >
          Filter
        </Button>
      </ChakraPopoverTrigger>

      <ChakraPopoverContent w="100%" maxH="60vh" overflowY="auto">
        <ChakraPopoverBody px={0}>
          {!pickedColumn && (
            <Column>
              <Row px={2} pb={2}>
                <Text
                  fontWeight="semibold"
                  color="text.secondary"
                  textTransform="uppercase"
                  onClick={() => focusRef.current?.focus()}
                >
                  Filters
                </Text>
              </Row>

              {assetModelColumns.length === 0 && (
                <Column px={2} pb={2} gap={2} maxWidth="400px">
                  <Text color="text.secondary">
                    No columns are available for filtering
                  </Text>
                </Column>
              )}

              {assetModelColumns.map((column, i) => {
                const props = i == 0 ? { ref: focusRef } : {};

                return (
                  <FilterOption
                    key={column.name}
                    text={column.alias ?? column.name}
                    onClick={() => {
                      setPickedColumn({
                        name: column.name,
                        type: column.type,
                        columnReference: column.column_reference,
                      });
                    }}
                    {...props}
                  />
                );
              })}

              {predefinedMetrics.map(({ name, id }) => (
                <FilterOption
                  key={name}
                  text={name}
                  onClick={() => {
                    setPickedColumn({
                      name: name,
                      type: ColumnType.Number,
                      columnReference: "attribution_value",
                      goalId: id,
                    });
                  }}
                />
              ))}
              {conversionMetrics.map(({ id, name, attributionMethod }) => (
                <FilterOption
                  key={`${name} / ${attributionMethod.name}`}
                  text={`${name} / ${attributionMethod.name}`}
                  onClick={() => {
                    setPickedColumn({
                      name: name,
                      type: ColumnType.Number,
                      columnReference: "attribution_value",
                      goalId: id,
                      attributionMethodId: attributionMethod.id,
                    });
                  }}
                />
              ))}
            </Column>
          )}
          {pickedColumn && (
            <FilterBuilder
              onAddCondition={(condition) => {
                if (!pickedColumn.goalId) {
                  return onAddAssetFilter(condition);
                }

                const attribution: PropertyCondition = {
                  type: ConditionType.Property,
                  property: "attribution_method_id",
                  propertyType: ColumnType.String,
                  operator: StringOperator.Equals,
                  value: pickedColumn.attributionMethodId,
                };

                const conditions: PropertyCondition[] = [
                  {
                    type: ConditionType.Property,
                    property: "goal_id",
                    propertyType: ColumnType.String,
                    operator: StringOperator.Equals,
                    value: pickedColumn.goalId,
                  },
                  ...(pickedColumn.attributionMethodId ? [attribution] : []),
                  condition,
                ];

                onAddMetricFilter({
                  type: ConditionType.And,
                  conditions,
                });
              }}
              onClose={onClose}
              columnName={pickedColumn.name}
              columnType={pickedColumn.type as ColumnType}
              columnReference={pickedColumn.columnReference}
            />
          )}
        </ChakraPopoverBody>
      </ChakraPopoverContent>
    </ChakraPopover>
  );
};

const FilterOption = forwardRef(
  (
    {
      text,
      onClick,
    }: {
      text: string;
      onClick: () => void;
    },
    ref: Ref<HTMLDivElement>,
  ) => (
    <Row
      ref={ref}
      as="button"
      p={2}
      minWidth="300px"
      _hover={{ background: "gray.200", cursor: "pointer" }}
      _focus={{ background: "gray.200", outline: "none" }}
      onClick={onClick}
      color="gray.900"
    >
      {text}
    </Row>
  ),
);
FilterOption.displayName = "FilterMenuOption";

const FilterBuilder: FC<{
  columnName: string;
  columnType: ColumnType;
  columnReference: PropertyCondition["property"];
  onAddCondition: (newCondition: PropertyCondition) => void;
  onClose: () => void;
}> = ({ columnName, columnType, columnReference, onAddCondition, onClose }) => {
  const { assetModel } = useCampaignsFilterContext();
  const modelId = assetModel?.model_id.toString() ?? "";
  const { suggestions, loadingSuggestions, onSearchUpdate } =
    useFetchColumnSuggestions(
      {
        modelId,
        columnName: columnName ?? undefined,
      },
      { enabled: Boolean(assetModel) },
    );

  const form = useHightouchForm({
    onSubmit: () => {
      onAddCondition(condition);
      onClose();

      return Promise.resolve();
    },
    resolver: yupResolver(
      yup.object().shape({
        condition: getPropertyConditionSchema({
          allowsMultiValue: CampaignFiltersAllowMultiValue,
          allowPercentile: CampaignFiltersAllowPercentile,
        }),
      }),
    ),
    values: {
      condition: {
        property: columnReference,
        type: ConditionType.Property,
        propertyType: columnType,
        operator: null,
        value: null,
      } as PropertyCondition,
    },
  });

  const condition: PropertyCondition = form.watch("condition");

  const updateOperator = (value: Operator) => {
    form.setValue("condition", {
      ...condition,
      ...updateConditionOperator(condition, value),
    });
    form.clearErrors("condition.value");
  };

  const updateConditionValue = (valueObject: Partial<PropertyCondition>) => {
    // valueObject is an object with `value` and other keys that constitue the value's configuration
    Object.entries(
      valueObject as Pick<
        PropertyCondition,
        "value" | "timeType" | "propertyOptions" | "conditionOptions"
      >,
    ).forEach(([propertyName, value]) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - TS doesn't know that propertyName is a key of PropertyCondition
      form.setValue(`condition.${propertyName}`, value);
    });
  };

  return (
    <Form form={form}>
      <Column px={2} gap={2}>
        <Row alignItems="start" gap={2} width="100%">
          <Box minWidth="6rem" mt={1.5}>
            <TextWithTooltip fontWeight="semibold">
              {columnName}
            </TextWithTooltip>
          </Box>

          <Controller
            name="condition.operator"
            control={form.control}
            render={({ field: { ref, ...field }, fieldState }) => (
              <Column>
                <Select
                  {...field}
                  isInvalid={Boolean(fieldState.error)}
                  width="3xs"
                  placeholder="Select an operator..."
                  options={OperatorOptions[columnType]}
                  onChange={updateOperator}
                />
                {fieldState.error && (
                  <ErrorMessage>{fieldState.error.message}</ErrorMessage>
                )}
              </Column>
            )}
          />

          {condition.operator && (
            <Column>
              <Row gap={2} flexShrink={1}>
                <PropertyInput
                  allowMultiValue={CampaignFiltersAllowMultiValue}
                  allowPercentile={CampaignFiltersAllowPercentile}
                  columns={undefined}
                  condition={condition}
                  error={getNestedValueError(
                    form.formState.errors?.condition?.value,
                  )}
                  loading={loadingSuggestions}
                  parent={undefined}
                  suggestions={suggestions}
                  onChange={updateConditionValue}
                  onSearchUpdate={onSearchUpdate}
                />
              </Row>
            </Column>
          )}
        </Row>

        <Row justifyContent="end" gap={2}>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <SaveButton enablePristineSubmit size="md">
            Apply
          </SaveButton>
        </Row>
      </Column>
    </Form>
  );
};
