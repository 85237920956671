import { FC, useMemo } from "react";

import {
  Button,
  ButtonGroup,
  Column,
  Dialog,
  Paragraph,
  Text,
  useToast,
} from "@hightouchio/ui";
import { captureException } from "@sentry/react";
import {
  SubsetCategoryQuery,
  useDeleteSubsetCategoryMutation,
} from "src/graphql";
import { DependenciesTable } from "src/components/modals/dependencies-modal";
import { useNavigate } from "src/router";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  subsetCategory: NonNullable<
    SubsetCategoryQuery["audience_subset_groups_by_pk"]
  >;
};

export const DeleteSubsetCategoryModal: FC<Readonly<Props>> = ({
  isOpen,
  onClose,
  subsetCategory,
}) => {
  const { id, name } = subsetCategory;

  const navigate = useNavigate();
  const { toast } = useToast();
  const deleteSubsetCategory = useDeleteSubsetCategoryMutation();

  const audiencesWithSubsetCategory = useMemo(() => {
    return (
      subsetCategory.subset_values
        .flatMap((subset) => subset.audiences_subsets)
        .map(({ audience, created_by_user, created_at }) => ({
          id: audience.id,
          name: audience.name,
          created_at,
          created_by_user: created_by_user,
          url: `/audiences/${audience.id}`,
        })) ?? []
    );
  }, [subsetCategory]);

  const onDelete = async () => {
    try {
      await deleteSubsetCategory.mutateAsync({
        id,
      });
      toast({
        id: "delete-subset-category",
        title: "Successfully deleted subset category.",
        variant: "success",
      });
      navigate("/governance/subset-categories");
    } catch (error) {
      toast({
        id: "delete-subset-category",
        title: "Could not delete subset category. Please try again.",
        message: error.message,
        variant: "error",
      });
      captureException(error);
    }
  };

  return (
    <Dialog
      isOpen={isOpen}
      title={`Delete subset category: "${name}"`}
      variant="info"
      width="xl"
      onClose={onClose}
      actions={
        <ButtonGroup>
          <Button isDisabled={deleteSubsetCategory.isLoading} onClick={onClose}>
            Cancel
          </Button>
          <Button
            isDisabled={deleteSubsetCategory.isLoading}
            isLoading={deleteSubsetCategory.isLoading}
            variant="danger"
            onClick={onDelete}
          >
            Delete
          </Button>
        </ButtonGroup>
      }
    >
      <Column gap={4}>
        <Paragraph textAlign="left">
          Are you sure you want to delete the subset category{" "}
          <Text fontWeight="medium">"{name}"</Text>? This subset category and
          the associated subset selection will be removed from all audiences.
        </Paragraph>

        {audiencesWithSubsetCategory.length > 0 && (
          <Column>
            <Text fontWeight="medium">
              Subset selections for "{name}" will be removed from the following
              audiences:
            </Text>
            <DependenciesTable
              dependencies={[
                {
                  name: "Audiences",
                  resources:
                    subsetCategory?.subset_values
                      .flatMap((subset) => subset.audiences_subsets)
                      .map(({ audience, created_by_user, created_at }) => ({
                        id: audience.id,
                        name: audience.name,
                        created_at,
                        created_by_user: created_by_user,
                        updated_at: null,
                        updated_by_user: null,
                        url: `/audiences/${audience.id}`,
                      })) ?? [],
                },
              ]}
            />
          </Column>
        )}
      </Column>
    </Dialog>
  );
};
