// eslint-disable-next-line no-restricted-imports
import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import { NotFound } from "src/pages/not-found";
import { Unauthorized } from "src/pages/unauthorized";
import { Warning } from "src/components/warning";

export const RouteErrorBoundary = () => {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <NotFound />;
    }
    if (error.status === 401) {
      return <Unauthorized />;
    }
  }

  return (
    <Warning
      title="An error has occurred"
      subtitle="We have been alerted and are looking into it. Please try again later."
    />
  );
};
