import { isEqual } from "lodash";
import { useRef, useState, useEffect, useMemo, useCallback } from "react";
import { QueryType } from "src/types/models";
import { ModelState } from "src/utils/models";
import useUndo from "use-undo";

export type UseModelStateReturn = ReturnType<typeof useModelState>;

export const useModelState = (model?: Partial<ModelState> | null) => {
  const ref = useRef<ModelState | null | undefined>(model as ModelState);
  const [history, options] = useUndo<ModelState>((model ?? {}) as ModelState);
  const state = history.present;
  const [isPreviewFresh, setIsPreviewFresh] = useState(false);

  useEffect(() => {
    setIsPreviewFresh(false);
  }, [
    state?.query_dbt_model_id,
    state?.query_looker_look_id,
    state?.visual_query_filter,
    state?.query_raw_sql,
    state?.query_table_name,
    state?.query_integrations,
    state?.custom_query,
    state?.query_type,
  ]);

  const isDirty = useMemo(
    () => !isEqual(state, ref.current),
    [state, ref.current],
  );

  const isValid = useMemo(() => {
    const type = state?.query_type as QueryType;
    switch (type) {
      case QueryType.RawSql:
        return Boolean(state?.query_raw_sql);
      case QueryType.Table:
        return Boolean(state?.query_table_name);
      case QueryType.DbtModel:
        return Boolean(state?.query_dbt_model_id);
      case QueryType.LookerLook:
        return Boolean(state?.query_looker_look_id);
      case QueryType.Custom:
        return Boolean(state?.custom_query);
      case QueryType.Sigma:
        return Boolean(state?.query_integrations);
      case QueryType.DecisionEngine:
      case QueryType.JourneyNode:
        return true;
      default:
        return false;
    }
  }, [state]);

  const onChange = useCallback(
    (newState: Partial<ModelState>) => {
      options.set({
        ...state,
        ...newState,
      });
    },
    [state, options.set],
  );

  const reset = useCallback(
    (state?: ModelState) => {
      if (state) {
        ref.current = state;
        options.reset(state);
      } else if (ref.current) {
        options.reset(ref.current);
      }
    },
    [options.reset, ref.current],
  );

  useEffect(() => {
    // Reset the state when the source changes, preserving dirty check and any current changes
    if (model && model.connection?.id !== state.connection?.id) {
      options.reset({
        ...state,
        ...model,
        visual_query_filter:
          model.query_type === QueryType.Visual
            ? (model.visual_query_filter ?? { conditions: [] })
            : null,
        query_raw_sql: null,
        query_table_name: null,
        query_dbt_model_id: null,
        query_looker_look_id: null,
        query_integrations: null,
        custom_query: null,
        query_type: model.query_type ?? null,
      });
    }
  }, [model]);

  return useMemo(
    () => ({
      ...options,
      reset,
      state,
      onChange,
      isPreviewFresh,
      setIsPreviewFresh,
      isDirty,
      isValid,
    }),
    [
      state,
      onChange,
      reset,
      options,
      isDirty,
      isValid,
      isPreviewFresh,
      setIsPreviewFresh,
    ],
  );
};
