import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const SyncIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <g clipPath="url(#svg-sync-clip-path)">
        <path d="M9.10485 6.9989C9.10485 8.6558 7.7389 9.99876 6.05243 9.99876C4.36693 9.99876 3 8.6558 3 6.9989C3 5.34296 4.36693 4 6.05243 4C7.7389 4 9.10485 5.34296 9.10485 6.9989Z" />
        <path d="M20.7524 16.7811C20.7524 18.437 19.3865 19.78 17.7 19.78C16.0145 19.78 14.6476 18.437 14.6476 16.7811C14.6476 15.1242 16.0145 13.7812 17.7 13.7812C19.3865 13.7812 20.7524 15.1242 20.7524 16.7811Z" />
        <rect
          fillOpacity="0.3"
          height="2.66254"
          width="8.87514"
          x="11.8751"
          y="5.77539"
        />
        <rect
          fillOpacity="0.3"
          height="2.66254"
          width="8.87514"
          x="3"
          y="15.5381"
        />
      </g>
      <defs>
        <clipPath id="svg-sync-clip-path">
          <rect height="15.78" transform="translate(3 4)" width="17.7523" />
        </clipPath>
      </defs>
    </Icon>
  );
};
