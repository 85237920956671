import { FC } from "react";

import { Box, Column, Row, Text } from "@hightouchio/ui";
import round from "lodash/round";
import { TooltipProps } from "recharts";

import {
  FunnelStepGraphDataPoint,
  HoveredSection,
  FunnelMeasurementType,
} from "src/pages/analytics/types";

type Props = {
  funnelMeasuringType: FunnelMeasurementType;
  hoveredSection: HoveredSection;
} & TooltipProps<number, string>;

const Tooltip: FC<Readonly<Props>> = ({
  hoveredSection,
  funnelMeasuringType,
  payload,
}) => {
  if (!payload || !hoveredSection.dataKey) return null;

  return (
    <Column bg="gray.900" padding={2} borderRadius="md" gap={2}>
      {payload.map(({ payload: funnel, color, name }, index) => {
        const item: FunnelStepGraphDataPoint | undefined =
          funnel.data[hoveredSection.seriesName ?? ""];

        if (!item) return null;

        return hoveredSection.dataKey === name ? (
          <Row gap={2} key={index}>
            <Box width={1} borderRadius="lg" bg={color} />
            <Column gap={1}>
              <Text color="white" fontWeight="semibold">
                {hoveredSection.section === "conversion"
                  ? "Conversion"
                  : "Drop-off"}
              </Text>
              <Text color="text.tertiary" size="sm">
                {hoveredSection.seriesName}
              </Text>
              <Row
                align="center"
                bg="gray.800"
                borderRadius="md"
                px={1}
                gap={1}
                width="fit-content"
              >
                <Text color="white" fontWeight="semibold" size="sm">
                  {hoveredSection.section === "conversion"
                    ? round(item.conversion * 100, 2)
                    : round((1 - item.conversion) * 100, 2)}
                  %
                </Text>
                <Text color="gray.400" size="sm">
                  {hoveredSection.section === "conversion"
                    ? "conversion"
                    : "drop-off"}
                </Text>
              </Row>
              <Row
                align="center"
                bg="gray.800"
                borderRadius="md"
                px={1}
                gap={1}
                width="fit-content"
              >
                <Text color="white" fontWeight="semibold" size="sm">
                  {hoveredSection.section === "conversion"
                    ? item.count
                    : item.conversion === 0
                      ? 0
                      : item.count / item.conversion - item.count}
                </Text>
                <Text color="gray.400" size="sm">
                  {funnelMeasuringType === FunnelMeasurementType.TotalConversion
                    ? "total times"
                    : "unique customers"}
                </Text>
              </Row>
            </Column>
          </Row>
        ) : null;
      })}
    </Column>
  );
};

export default Tooltip;
