import { FC } from "react";

import { CodeSnippet, Heading, Text } from "@hightouchio/ui";

import { CodeParams, SetupProps } from "./types";

function getCurlCode(params: CodeParams): string {
  const basicToken = btoa(`${params.writeKey}:`);
  return `
  curl -X POST \\
    -H 'Authorization: Basic ${basicToken}' \\
    -H "Content-type: application/json" \\
    -d '{
    "userId": "1",
    "event": "Order Completed",
    "properties": {},
    "context": {},
    "timestamp": "${new Date().toISOString()}"
  }' 'https://${params.apiHost}/v1/track'
  `;
}

const snippet1 = "const token = base64(`${writeKey}:`)";
const snippet2 = "const header = `Authorization: Basic ${token}`";

export const SetupHttp: FC<SetupProps> = (props: SetupProps) => {
  return (
    <>
      <Heading>Authentication</Heading>
      <Text>
        HTTP events authenticate with HTTP Basic Auth. You will need to encode
        your write key and send it as a header. See the following pseudocode:
      </Text>
      <CodeSnippet label="HTTP Basic Auth" code={`${snippet1}\n${snippet2}`} />
      <Heading>Sending Events</Heading>
      <CodeSnippet label="cURL" code={getCurlCode(props)} />
    </>
  );
};
