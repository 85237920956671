import { FC, ReactNode } from "react";

import { SearchInput, Column } from "@hightouchio/ui";

import { Pagination, Table, TableColumn } from "src/ui/table";

type Props = {
  label: string;
  search: string;
  loading: boolean;
  data: any;
  disabled?: (row: any) => boolean;
  onSelect: (row: any) => void;
  onSearch: (value: string) => void;
  error: boolean;
  columns: TableColumn[];
  pagination?: Pagination;
  placeholder?: string | ReactNode;
};

type Pagination = {
  page: number;
  limit: number;
  count: number | undefined;
  setPage: (number) => void;
};

export const ResourceSelect: FC<Readonly<Props>> = ({
  data,
  disabled,
  onSearch,
  search,
  loading,
  columns,
  pagination,
  onSelect,
  error,
  label,
  placeholder,
}) => {
  return (
    <Column gap={4}>
      <SearchInput
        width="100%"
        autoFocus
        placeholder="Search by name..."
        value={search}
        size="lg"
        onChange={(event) => onSearch(event.target.value)}
      />
      <Table
        columns={columns}
        data={data}
        disabled={disabled}
        error={error}
        loading={loading}
        placeholder={{
          title: `No ${label}s`,
          body: placeholder,
          error: `${label}s failed to load, please try again.`,
        }}
        onRowClick={onSelect}
      />
      {pagination && (
        <Pagination
          {...pagination}
          label={`${label}s`}
          rowsPerPage={pagination.limit}
        />
      )}
    </Column>
  );
};
