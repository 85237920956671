import { useEffect, useState } from "react";

import { Column, Button, useToast } from "@hightouchio/ui";
import { captureException } from "@sentry/react";
import { useOutletContext } from "src/router";

import { ScheduleManager } from "src/components/schedule";
import { ScheduleType } from "src/components/schedule/types";
import { useUpdateSyncMutation } from "src/graphql";
import { track } from "src/lib/analytics";
import { BottomBar } from "src/partner/bottom-bar";

import { OutletContext } from ".";

export const Schedule = () => {
  const { sync } = useOutletContext<OutletContext>();
  const { toast } = useToast();
  const [schedule, setSchedule] = useState(sync.schedule);

  const updateMutation = useUpdateSyncMutation();

  const reset = () => {
    setSchedule(sync.schedule ? sync.schedule : { type: ScheduleType.MANUAL });
  };

  const update = async () => {
    try {
      await updateMutation.mutateAsync({
        id: sync.id,
        object: {
          schedule: schedule.type === ScheduleType.MANUAL ? null : schedule,
        },
      });
      toast({
        id: "update-schedule",
        title: "Sync schedule updated",
        variant: "success",
      });
      track("Sync Schedule Updated", {
        sync_id: sync.id,
        destination_type: sync.destination?.definition?.name,
        schedule_type: sync.schedule?.type,
        source_type: sync.segment?.connection?.type,
      });
    } catch (error) {
      captureException(error);
      toast({
        id: "update-schedule",
        title: "Couldn't update sync schedule",
        variant: "error",
      });
    }
  };

  useEffect(() => {
    reset();
  }, [sync.schedule]);

  return (
    <>
      <Column flex={1} minH={0} p={4}>
        <ScheduleManager
          schedule={schedule}
          setSchedule={setSchedule}
          types={[
            ScheduleType.CRON,
            ScheduleType.CUSTOM,
            ScheduleType.INTERVAL,
            ScheduleType.MANUAL,
          ]}
        />
      </Column>
      <BottomBar>
        <Button
          variant="primary"
          size="lg"
          onClick={update}
          isLoading={updateMutation.isLoading}
        >
          Save changes
        </Button>
      </BottomBar>
    </>
  );
};
