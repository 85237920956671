import { useState } from "react";

import { ButtonGroup, Column, Row, useToast } from "@hightouchio/ui";
import { captureException } from "@sentry/react";

import { ActionBar } from "src/components/action-bar";
import { useUpdateDestinationV2Mutation } from "src/graphql";

import { SetupForm } from "src/components/destinations/destination-form";
import { ForwardingDestination } from "src/events/destinations/destination/types";
import { SidebarForm } from "src/components/page";
import {
  PermissionProvider,
  PermissionedButton,
} from "src/components/permission";

export const ForwardingConfiguration = ({
  destination,
}: {
  destination: ForwardingDestination;
}) => {
  const { toast } = useToast();

  const [config, setConfig] = useState<Record<string, unknown> | undefined>(
    destination.config,
  );
  const [credentialId, setCredentialId] = useState<string>(
    destination.credential_id,
  );

  const updateMutation = useUpdateDestinationV2Mutation();

  const submit = async () => {
    const updatedConfig = { ...config };

    try {
      await updateMutation.mutateAsync({
        id: destination.id.toString(),
        destination: {
          credential_id:
            credentialId != null ? String(credentialId) : undefined,
          config: updatedConfig,
        },
      });

      toast({
        id: "save-destination",
        title: "Your destination has been saved.",
        variant: "success",
      });
    } catch (error) {
      captureException(error);
      toast({
        id: "save-destination",
        title: "There was a problem saving your destination.",
        variant: "error",
      });
    }
  };

  return (
    <Row>
      <Column flex={1} gap={8} pb={16}>
        <PermissionProvider
          permission={{
            v2: {
              resource: "destination",
              grant: "can_update",
              id: destination.id,
            },
          }}
        >
          <SetupForm
            config={config}
            credentialId={credentialId}
            definition={destination.definition}
            destination={destination}
            isSetup={false}
            setConfig={setConfig}
            setCredentialId={setCredentialId}
            // error={testError}

            // TODO: add back connection testing
            // connectionHandlers={{
            //   onResult: setTestResult,
            //   onTestResult: setTestConnectionResult,
            //   onError: setTestError,
            //   onLoading: setTesting,
            //   testResult,
            //   testConnectionResult,
            //   testError,
            // }}
          />
        </PermissionProvider>
      </Column>
      <ActionBar>
        <ButtonGroup>
          <PermissionedButton
            permission={{
              v2: {
                resource: "destination",
                grant: "can_update",
                id: destination.id,
              },
            }}
            size="lg"
            variant="primary"
            onClick={submit}
            isLoading={updateMutation.isLoading}
          >
            Save changes
          </PermissionedButton>
        </ButtonGroup>
      </ActionBar>
      <SidebarForm
        docsUrl={destination.definition.docs ?? ""}
        name={destination.definition.name ?? ""}
      />
    </Row>
  );
};
