import { FC } from "react";

import { Heading, Text } from "@hightouchio/ui";

import { Link } from "src/router";

import { SetupProps } from "./types";

export const SetupKafka: FC<SetupProps> = () => {
  return (
    <>
      <Heading>Kafka Source</Heading>
      <Text>
        You have successfully setup a Kafka event source that Hightouch will
        continuously poll for events. You can edit the configuration{" "}
        <Link href="../configuration">here</Link>.
      </Text>
    </>
  );
};
