import { FC } from "react";

import { Column, Row, Spinner, Text, useToast } from "@hightouchio/ui";
import * as diff from "diff";
import { isEqual } from "lodash";

import { Diff } from "src/components/diff";
import { ScheduleType } from "src/components/schedule/types";
import { useDraft } from "src/contexts/draft-context";
import { useUser } from "src/contexts/user-context";
import { FormkitSync } from "src/formkit/components/formkit-context";
import {
  useTransformedSyncRunConfigurationQuery,
  useUpdateSyncMutation,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import * as time from "src/utils/time";
import { PermissionedButton } from "src/components/permission";
import { useOutletContext } from "src/router";

import { Context } from ".";

export const ConfigurationPage = () => {
  const { sync, attempt } = useOutletContext<Context>();

  const syncId = sync.id;
  const runId = attempt?.sync_request?.id;
  const model = sync?.segment;
  const destination = sync?.destination;
  const syncRequest = attempt?.sync_request;

  const { data: prevConfig, isLoading } =
    useTransformedSyncRunConfigurationQuery(
      { id: syncId ?? "", runId: runId ?? "" },
      {
        enabled: Boolean(runId && syncId),
        select: (data) => data.getTransformedSyncRunConfiguration,
      },
    );

  if (isLoading) {
    return <Spinner size="lg" m="auto" />;
  }

  return (
    <Configuration
      destination={destination}
      model={model}
      prevConfig={prevConfig}
      prevConfigDate={syncRequest?.created_at}
      sync={sync}
    />
  );
};

type ConfigurationProps = {
  destination?: FormkitSync["destination"];
  model?: FormkitSync["segment"];
  prevConfig: any;
  prevConfigDate?: string;
  sync?: FormkitSync;
};

export const Configuration: FC<Readonly<ConfigurationProps>> = ({
  destination,
  model,
  prevConfig,
  prevConfigDate,
  sync,
}) => {
  const { mutateAsync: updateSync, isLoading: isSyncUpdating } =
    useUpdateSyncMutation();
  const { draft, editingDraft, updateResourceOrDraft } = useDraft();
  const { toast } = useToast();
  const { workspace } = useUser();

  const newResource = draft?.new_resource;
  const draftConfig = newResource?._set?.config;

  const isSameConfig =
    editingDraft && draftConfig
      ? isEqual(prevConfig, draftConfig)
      : isEqual(prevConfig, sync?.config);

  // Same updateConfig logic as used in syncs.tsx
  const updateConfig = async () => {
    if (!sync || !sync?.id) {
      return;
    }

    try {
      const updatePayload = {
        config: { ...prevConfig, configVersion: sync?.config?.configVersion },
        // we null the draft id, it gets added on the backend and we want to be consistent
        // if a workspace turns off approvals again =
        approved_draft_id: null,
      };

      if (updateResourceOrDraft) {
        await updateResourceOrDraft(
          { _set: updatePayload },
          onUpdate,
          () =>
            updateSync({
              id: sync?.id,
              object: updatePayload,
            }),
          // If there is already a sync run, it is always in production state
          false,
        );
      }
    } catch (error) {
      toast({
        id: "update-sync-config",
        title: "Couldn't update config",
        message: error.message,
        variant: "error",
      });
    }
  };

  const onUpdate = () => {
    analytics.track("Sync Edited", {
      sync_id: sync?.id,
      destination_type: destination?.definition?.name,
      schedule_type: sync?.schedule?.type || ScheduleType.MANUAL,
      source_type: model?.connection?.type,
    });

    if (!workspace?.approvals_required) {
      toast({
        id: "update-sync",
        title: "Sync was updated",
        variant: "success",
      });
    }
  };
  return (
    <Column height="100%">
      <Row alignItems="center" mb={2} mt={6} width="100%">
        <Column width="50%">
          <Text fontWeight="medium" size="md">
            At run on {prevConfigDate && time.formatDatetime(prevConfigDate)}
          </Text>
          {!isSameConfig && (
            <Text fontWeight="normal" mt={2} size="md">
              Configuration highlighted in green will be added and configuration
              highlighted in red will be removed when you restore to this
              version.
            </Text>
          )}
        </Column>
        <Row justifyContent="end" width="50%">
          {isSameConfig ? (
            <Text color="text.secondary" size="md">
              Same as current {editingDraft && draft ? "draft" : ""}{" "}
              configuration
            </Text>
          ) : (
            <PermissionedButton
              permission={{
                v2: { resource: "sync", grant: "can_update", id: sync?.id },
              }}
              isLoading={isSyncUpdating}
              onClick={updateConfig}
              size="md"
            >
              Restore this version
            </PermissionedButton>
          )}
        </Row>
      </Row>
      <Diff
        // This is if the sync has not run yet, and the user goes to the Configuration tab. At this point, the prevConfig is undefined.
        diffs={diff.diffJson(sync?.config, prevConfig || sync?.config)}
      />
    </Column>
  );
};
