import {
  BUILT_IN_PERMISSION_SETS,
  BuiltInPermissionSet,
  BuiltInRoleType,
} from "./types";

export const isBuiltInPermissionSet = (
  built_in_permission_set: string | null,
): built_in_permission_set is BuiltInPermissionSet =>
  Boolean(built_in_permission_set) &&
  BUILT_IN_PERMISSION_SETS.includes(
    built_in_permission_set as BuiltInPermissionSet,
  );

export const isDefaultRole = (
  built_in_role_type: string | null,
): built_in_role_type is BuiltInRoleType =>
  built_in_role_type
    ? ["organization_admin", "organization_viewer", "all_users"].includes(
        built_in_role_type,
      )
    : false;
