import { FC } from "react";

import {
  Button,
  ButtonGroup,
  ChakraModal,
  ChakraModalBody,
  ChakraModalContent,
  ChakraModalFooter,
  ChakraModalHeader,
  ChakraModalOverlay,
  Column,
  FormField,
  Heading,
  Textarea,
  TextInput,
} from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import capitalize from "lodash/capitalize";
import { Controller } from "react-hook-form";
import * as Yup from "yup";

import { Form, useHightouchForm } from "src/components/form";

type SaveNameAndDescriptionModalProps = {
  type: "chart" | "view";
  isOpen?: boolean;
  onClose: () => void;
  onSubmit: (data: {
    name: string;
    description: string | null;
  }) => Promise<void>;
};

export type SaveNameAndDescriptionModalForm = {
  name: string;
  description?: string | null;
};

const schema = Yup.object()
  .shape({
    name: Yup.string().trim().required("Name is required"),
    description: Yup.string().trim().nullable(),
  })
  .required();

export const SaveNameAndDescriptionModal: FC<
  SaveNameAndDescriptionModalProps
> = ({ isOpen, type, onClose, onSubmit }) => {
  const form = useHightouchForm({
    onSubmit,
    success: `${capitalize(type)} saved!`,
    error: `Error saving ${type}`,
    defaultValues: {
      name: "",
      description: "",
    },
    resolver: yupResolver(schema),
  });

  const cancel = () => {
    form.reset();
    onClose();
  };

  return (
    <Form form={form}>
      <ChakraModal
        isCentered
        scrollBehavior="inside"
        isOpen={Boolean(isOpen)}
        onClose={onClose}
      >
        <ChakraModalOverlay />
        <ChakraModalContent p={0} my="auto">
          <ChakraModalHeader
            px={5}
            py={6}
            borderBottom="1px solid"
            borderColor="base.border"
          >
            <Heading>Save {type}</Heading>
          </ChakraModalHeader>
          <ChakraModalBody
            mt={0}
            pb={5}
            borderBottom="1px solid"
            borderColor="base.border"
          >
            <Column gap={4}>
              <Controller
                control={form.control}
                name="name"
                render={({ field, fieldState, formState }) => (
                  <FormField
                    label="Name"
                    error={
                      formState.isSubmitted
                        ? fieldState.error?.message
                        : undefined
                    }
                  >
                    <TextInput
                      placeholder="Enter a name"
                      width="100%"
                      value={field.value}
                      onChange={field.onChange}
                    />
                  </FormField>
                )}
              />
              <Controller
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormField isOptional label="Description">
                    <Textarea
                      placeholder="Enter a description"
                      width="100%"
                      value={field.value}
                      onChange={field.onChange}
                    />
                  </FormField>
                )}
              />
            </Column>
          </ChakraModalBody>
          <ChakraModalFooter mt={0} p={5}>
            <ButtonGroup>
              <Button isDisabled={form.formState.isSubmitting} onClick={cancel}>
                Cancel
              </Button>
              <Button
                isDisabled={
                  !form.formState.isDirty || form.formState.isSubmitting
                }
                isLoading={form.formState.isSubmitting}
                onClick={form.submit}
                variant="primary"
              >
                Save {type}
              </Button>
            </ButtonGroup>
          </ChakraModalFooter>
        </ChakraModalContent>
      </ChakraModal>
    </Form>
  );
};
