import { FC } from "react";

import { Heading, Text } from "@hightouchio/ui";

import { Link } from "src/router";

import { SetupProps } from "./types";

export const SetupConfluentKafka: FC<SetupProps> = () => {
  return (
    <>
      <Heading>Confluent Source</Heading>
      <Text>
        You have successfully setup a Confluent event source for Kafka that
        Hightouch will continuously poll for events. You can edit the
        configuration <Link href="../configuration">here</Link>.
      </Text>
    </>
  );
};
