import { EmptyState } from "@hightouchio/ui";
import { Link, useOutletContext } from "src/router";

import { useUser } from "src/contexts/user-context";
import { SyncAlerts } from "src/pages/syncs/sync/alerts";
import { SyncTemplateOutletContext } from "./types";

export const SyncTemplateAlerts = () => {
  const { syncTemplate } = useOutletContext<SyncTemplateOutletContext>();

  const { workspace } = useUser();
  const syncAlerts = syncTemplate?.alert_instances;

  return workspace?.alerting_v2_enabled ? (
    <EmptyState
      message={
        <>
          This sync template inherits the default alert recipients and triggers
          from its destination. To edit those defaults,{" "}
          <Link href="/alerting/recipients">click here.</Link>
        </>
      }
    />
  ) : (
    <SyncAlerts
      permission={{
        v1: { resource: "sync_template", grant: "update" },
        v2: {
          resource: "model",
          grant: "can_update",
          id: syncTemplate.segment.id,
        },
      }}
      alerts={syncAlerts || []}
      rowThresholdAttempted={syncTemplate.row_threshold_attempted}
      rowThresholdTotal={syncTemplate.row_threshold_total}
      templateId={syncTemplate.id}
    />
  );
};
