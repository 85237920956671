import { FC } from "react";

import { Route, Routes } from "src/router";

import { CreateEventSource } from "./create";
import { EventSourceRouter } from "./source";
import { EventSources } from "./sources";

export const EventSourcesRouter: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<EventSources />} />
      <Route path="new" element={<CreateEventSource />} />
      <Route path=":id/*" element={<EventSourceRouter />} />
    </Routes>
  );
};
