import { Alert, Column } from "@hightouchio/ui";
import { Link, useOutletContext } from "src/router";

import { ActionBar, ActionBarHeight } from "src/components/action-bar";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { ScheduleManager } from "src/components/schedule";
import { SyncTemplateOutletContext } from "./types";
import { useEffect } from "react";

export const SyncTemplateSchedule = () => {
  const { syncTemplate, onUpdateSchedule } =
    useOutletContext<SyncTemplateOutletContext>();

  const form = useHightouchForm({
    success: false,
    onSubmit: (values) => {
      onUpdateSchedule(values.schedule);
      return Promise.resolve();
    },
    values: { schedule: syncTemplate.schedule ?? { type: "manual" } },
  });

  useEffect(() => {
    form.reset({ schedule: syncTemplate.schedule ?? { type: "manual" } });
  }, [syncTemplate.schedule]);

  const schedule = form.watch("schedule");

  return (
    <Form form={form}>
      <Column pb={`calc(${ActionBarHeight}px + 8px)`}>
        <ScheduleManager
          schedule={schedule}
          setSchedule={(updatedSchedule) => {
            form.setValue("schedule", updatedSchedule, {
              shouldDirty: true,
            });
          }}
        />

        <Alert
          mt={8}
          variant="inline"
          type="info"
          title="Sync triggers"
          message={
            <>
              You can also trigger this sync via Airflow, Dagster, or Prefect.
              For more information view our{" "}
              <Link
                href={`${import.meta.env.VITE_DOCS_URL}/extensions/airflow`}
              >
                Airflow Operator
              </Link>
              {", "}
              <Link
                href={`${import.meta.env.VITE_DOCS_URL}/extensions/dagster`}
              >
                Dagster
              </Link>
              {", or "}
              <Link
                href={`${import.meta.env.VITE_DOCS_URL}/extensions/prefect`}
              >
                Prefect docs
              </Link>
              . If you need an API key, you can create one in{" "}
              <Link href="/settings/api-keys">Settings.</Link>
            </>
          }
        />
      </Column>

      <ActionBar>
        <FormActions />
      </ActionBar>
    </Form>
  );
};
