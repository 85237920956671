import { Column, Row, Text } from "@hightouchio/ui";
import {
  getSyncAttemptDiff,
  isSyncRunStatus,
  syncStatusIsTerminal,
} from "src/utils/syncs";
import { NumberBadge } from "./number-badge";
import { SyncRun } from "src/pages/syncs/sync/utils/types";

type Props = {
  showPendingRows: boolean;
  statusComputed: string | null;
  error: any;
  plannerType: string | null;
  queryRun: SyncRun["query_run"];
  syncAttempts: SyncRun["sync_attempts"];
  addExecuted: number | null;
  changeExecuted: number | null;
  removeExecuted: number | null;
};

export const RunOperationsColumn = ({
  showPendingRows,
  statusComputed,
  error,
  plannerType,
  queryRun,
  syncAttempts,
  addExecuted,
  changeExecuted,
  removeExecuted,
}: Props) => {
  const attempt = syncAttempts?.[0];
  const diff = getSyncAttemptDiff(attempt);
  const added = (addExecuted || 0) - (diff?.rejected?.add ?? 0);
  const changed = (changeExecuted || 0) - (diff?.rejected?.change ?? 0);
  const removed = (removeExecuted || 0) - (diff?.rejected?.remove ?? 0);
  const successful = added + changed + removed;
  const pending = queryRun?.pending_rows;
  const rejected =
    (diff?.rejected?.add ?? 0) +
    (diff?.rejected?.change ?? 0) +
    (diff?.rejected?.remove ?? 0);

  const noRows =
    successful === 0 &&
    rejected === 0 &&
    (pending === 0 || pending === undefined);

  const completedWithoutError =
    isSyncRunStatus(statusComputed) &&
    syncStatusIsTerminal(statusComputed) &&
    !error;

  if (noRows || (plannerType === "all" && !completedWithoutError)) {
    return (
      <Column align="flex-end" flex={1}>
        <Text>--</Text>
      </Column>
    );
  }

  const successfulOpCount =
    plannerType === "all" && completedWithoutError
      ? queryRun?.size
      : successful;

  const rejectedOpCount =
    plannerType === "all" && completedWithoutError ? 0 : rejected;

  return (
    <Row gap={1} justifyContent="flex-end" flex={1}>
      <NumberBadge
        color="success"
        tooltip={`${successfulOpCount} rows successfully synced to the destination`}
        value={successfulOpCount}
      />
      <NumberBadge
        color="danger"
        tooltip={`${rejectedOpCount} rows failed to sync to the destination`}
        value={rejectedOpCount}
      />
      {showPendingRows && pending !== undefined && (
        <NumberBadge
          color="gray"
          tooltip="Pending operations appear in Match Boosted syncs if they're added to the model but not yet enriched. Rows are only synced after they have been enriched."
          value={pending}
        />
      )}
    </Row>
  );
};
