// eslint-disable-next-line no-restricted-imports
import { Navigate } from "react-router-dom";

import { useUser } from "src/contexts/user-context";

// Redirect to root if user is already logged in
export const UnauthenticatedRoute = ({ children }) => {
  const { user } = useUser();

  if (user) {
    return <Navigate replace to="/" />;
  }

  return <>{children}</>;
};
