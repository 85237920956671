import { FC, useMemo } from "react";
import { useNavigate, useOutletContext } from "src/router";
import { Controller } from "react-hook-form";
import {
  Button,
  ButtonGroup,
  Column,
  Combobox,
  FormField,
  Row,
  Text,
} from "@hightouchio/ui";
import { Form as HightouchForm, useHightouchForm } from "src/components/form";
import {
  DestinationRuleQuery,
  useCreateDestinationRuleMutation,
  useParentModelsAndDestinationsQuery,
  useUpdateDestinationRuleMutation,
} from "src/graphql";
import { ActionBar } from "src/components/action-bar";
import { useUser } from "src/contexts/user-context";
import { Filter } from "./filter";
import { yupResolver } from "@hookform/resolvers/yup";
import { DeleteDestinationRule } from "./delete";
import {
  DEFAULT_CONDITIONS,
  DESTINATION_RULES_BASE_PATH,
  DestinationRuleSchema,
  destinationRuleValidation,
} from "./utils";
import { useFormErrorContext } from "src/contexts/form-error-context";
import { PermissionedButton } from "src/components/permission";
import { OutletContext } from "../layout";

type Props = {
  data: DestinationRuleQuery["destination_rules_by_pk"] | undefined;
};

export const DestinationRuleForm: FC<Readonly<Props>> = ({ data }) => {
  const { hasValidationErrors } = useFormErrorContext();
  const navigate = useNavigate();
  const { workspace } = useUser();

  const parentModelsAndDestinations = useParentModelsAndDestinationsQuery(
    undefined,
    { enabled: Boolean(workspace?.id) },
  );

  const destinationOptions = parentModelsAndDestinations.data?.destinations.map(
    (destination) => ({
      label: destination.name ?? destination.definition.name,
      value: destination.id,
      logo: destination.definition.icon ?? "",
    }),
  );

  const { parentModel } = useOutletContext<OutletContext>();

  const createDestinationRule = useCreateDestinationRuleMutation();
  const updateDestinationRule = useUpdateDestinationRuleMutation();

  const shouldCreate = data?.id == null;
  const shouldUpdate = data?.id != null;

  const form = useHightouchForm<DestinationRuleSchema>({
    onSubmit: async (formData) => {
      if (hasValidationErrors()) {
        throw new Error("Please check the form for errors");
      }

      if (shouldCreate) {
        const res = await createDestinationRule.mutateAsync({
          input: {
            destination_id: formData.destination_id,
            parent_model_id: parentModel.id,
            conditions: formData.conditions,
          },
        });
        navigate({
          pathname: `${DESTINATION_RULES_BASE_PATH}/${res.insert_destination_rules_one?.id}`,
        });
      } else if (shouldUpdate) {
        await updateDestinationRule.mutateAsync({
          id: data?.id ?? "",
          input: {
            conditions: formData.conditions,
          },
        });
      }
    },
    resolver: yupResolver(destinationRuleValidation),
    success: shouldCreate
      ? "Destination rule created"
      : "Destination rule updated",
    values: {
      destination_id: data?.destination.id,
      conditions: data?.conditions ?? DEFAULT_CONDITIONS,
    },
  });

  const {
    control,
    formState: { isDirty, isSubmitting },
    reset,
    submit,
  } = form;

  const selectedParentModel = useMemo(() => {
    return parentModelsAndDestinations.data?.segments.find(
      ({ id }) => String(id) === String(parentModel.id),
    );
  }, [parentModel.id, parentModelsAndDestinations]);

  const createOrSaveButton = (
    <PermissionedButton
      permission={{
        v1: {
          resource: "audience_schema",
          grant: "update",
        },
        v2: {
          resource: "model",
          grant: "can_update",
          id: parentModel.id,
        },
      }}
      isDisabled={!isDirty || isSubmitting}
      isLoading={isSubmitting}
      size="lg"
      variant="primary"
      onClick={submit}
    >
      {shouldCreate ? "Create rule" : "Save changes"}
    </PermissionedButton>
  );

  return (
    <>
      <Column flexGrow={1} justifyContent="space-between" overflow="auto">
        <HightouchForm form={form}>
          <Column gap={4} p={6}>
            {shouldCreate && (
              <>
                <Column>
                  <Text fontWeight="medium" size="lg">
                    Create a new destination rule
                  </Text>
                  <Text color="text.secondary">
                    Define which records are eligible to be synced to the
                    destination
                  </Text>
                </Column>

                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore - Circular reference problem with Column types */}
                <Controller
                  control={control}
                  name="destination_id"
                  render={({ field, fieldState }) => (
                    <FormField
                      label="Destination"
                      tip="Your rule will only apply to this destination."
                      error={fieldState.error?.message}
                    >
                      <Combobox
                        isLoading={parentModelsAndDestinations.isLoading}
                        options={destinationOptions ?? []}
                        optionAccessory={(option) => ({
                          type: "image",
                          url: option.logo,
                        })}
                        placeholder="Select a destination..."
                        width="100%"
                        {...field}
                      />
                    </FormField>
                  )}
                />
              </>
            )}

            <Controller
              control={control}
              name="conditions"
              render={({ field }) => (
                <Filter
                  isLoading={parentModelsAndDestinations.isLoading}
                  parentModel={selectedParentModel}
                  setConditions={field.onChange}
                  conditions={field.value}
                  {...field}
                />
              )}
            />
          </Column>
          <ActionBar fit>
            {shouldUpdate && (
              <Row justifyContent="space-between" width="100%">
                <ButtonGroup>
                  {createOrSaveButton}
                  <Button
                    isDisabled={!isDirty || isSubmitting}
                    size="lg"
                    onClick={() => reset()}
                  >
                    Discard changes
                  </Button>
                </ButtonGroup>
                <DeleteDestinationRule
                  id={data.id}
                  parentModelId={data.parent_model.id}
                />
              </Row>
            )}

            {shouldCreate && (
              <Row justifyContent="right" width="100%">
                {createOrSaveButton}
              </Row>
            )}
          </ActionBar>
        </HightouchForm>
      </Column>
    </>
  );
};
