import { Route, Routes } from "src/router";

import { MetricsLayout } from "./layout";
import { Metrics } from "./metrics";
import { CreateMetric } from "src/pages/metrics/create-metric";
import { Metric } from "src/pages/metrics/metric";
import { AttributionMethods } from "./attribution-methods/attribution-methods";
import { CreateAttributionMethod } from "./attribution-methods/create";
import { ViewAttributionMethod } from "./attribution-methods/view";
import { useEntitlements } from "src/hooks/use-entitlement";
import { Navigate } from "src/router";

export const MetricsRoutes = () => {
  const { data: entitlementsData } = useEntitlements(true);

  const analyticsEnabled = entitlementsData.entitlements.analytics;
  const campaignsEnabled = entitlementsData.entitlements.campaigns;

  if (!analyticsEnabled) {
    return <Navigate to="/" replace />;
  }

  return (
    <Routes>
      <Route path="/new" element={<CreateMetric />} />
      <Route path="/:metric_id" element={<Metric />} />
      <Route path="attributions/new" element={<CreateAttributionMethod />} />
      <Route
        path="attributions/:attribution_method_id"
        element={<ViewAttributionMethod />}
      />

      <Route path="/" element={<MetricsLayout />}>
        {campaignsEnabled && (
          <Route path="attributions" element={<AttributionMethods />} />
        )}

        <Route index element={<Metrics />} />
      </Route>
    </Routes>
  );
};
