import { FC } from "react";

import { CodeSnippet, Heading, Text } from "@hightouchio/ui";

import { Link } from "src/router";

import { CodeParams, SetupProps } from "./types";

function getGoCode(params: CodeParams): string {
  return `
  package main

  import (
    "github.com/ht-sdks/events-sdk-go"
  )

  func main() {
    // initialize client
    client, _ := htevents.NewWithConfig("${params.writeKey}", htevents.Config{
      Endpoint: "https://${params.apiHost}",
    })

    // flushes any queued messages and closes the client
    defer client.Close()

    // example calls
    client.Enqueue(htevents.Track{
      Event:  "Created Account",
      UserId: "123",
      Properties: htevents.Properties{
        "application": "Desktop",
        "version":     "1.2.3",
        "platform":    "osx",
      },
    })
  }
  `;
}

const goInstall = "go get github.com/ht-sdks/events-sdk-go";

export const SetupGo: FC<SetupProps> = (props: SetupProps) => {
  return (
    <>
      <Heading>Installing the SDK</Heading>
      <Text>
        This SDK is available via{" "}
        <Link href="https://github.com/ht-sdks/events-sdk-go">GitHub</Link>.
      </Text>
      <CodeSnippet code={goInstall} />
      <Heading>Using the SDK</Heading>
      <CodeSnippet label="main.go" code={getGoCode(props)} />
    </>
  );
};
