import { FC } from "react";

import {
  Box,
  Button,
  Column,
  DeleteIcon,
  IconButton,
  NumberInput,
  PlusIcon,
  Row,
  SectionHeading,
  Select,
  Text,
  Tooltip,
} from "@hightouchio/ui";
import omit from "lodash/omit";
import { useFieldArray, useFormContext } from "react-hook-form";

import { ErrorMessage } from "src/components/explore/visual/error-message";
import { RulesFormState } from "src/pages/identity-resolution/types";
import { getDefaultBlockRule } from "src/pages/identity-resolution/utils";

import { useRulesFormContext } from "./rules-form-context";

export const BlockRules: FC = () => {
  const { identifiers } = useRulesFormContext();
  const {
    control,
    formState: { errors },
  } = useFormContext<RulesFormState>();
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "block_rules",
  });

  return (
    <Column align="flex-start" gap={4}>
      <Column>
        <SectionHeading>Limit merging when:</SectionHeading>
        <Text>
          Define the limits of allowed identifiers per profile record. If these
          limits are exceeded, these merges will be flagged as conflicts, to be
          resolved in the next step.
        </Text>
      </Column>
      {fields.map((field, index) => {
        const identifierError =
          errors.block_rules?.[index]?.identifier?.message;
        const limitError = errors.block_rules?.[index]?.limit?.message;

        return (
          <Row
            key={field.id}
            gap={4}
            align="center"
            px={4}
            py={2}
            border="1px"
            borderColor="base.border"
            borderRadius="md"
            bg="white"
            width="100%"
            justify="space-between"
          >
            <Row align="baseline" gap={2} sx={{ "> *": { my: 1 } }}>
              <Text color="text.secondary">Limit of</Text>
              <Box width="50px">
                <NumberInput
                  isInvalid={Boolean(limitError)}
                  value={field.limit}
                  onChange={(value) => {
                    if (value !== undefined) {
                      update(index, {
                        ...omit(field, "id"),
                        limit: value,
                      });
                    }
                  }}
                />
              </Box>
              <Column>
                <Select
                  placeholder="Identifier..."
                  width="4xs"
                  variant="heavy"
                  isInvalid={Boolean(identifierError)}
                  options={identifiers.filter(
                    (identifier) =>
                      identifier === field.identifier ||
                      !fields.some(
                        (blockRule) => blockRule.identifier === identifier,
                      ),
                  )}
                  optionValue={(o) => o}
                  optionLabel={(o) => o}
                  value={field.identifier}
                  onChange={(value) => {
                    if (value) {
                      update(index, {
                        ...omit(field, "id"),
                        identifier: value,
                      });
                    }
                  }}
                />
                {identifierError && (
                  <ErrorMessage>{identifierError}</ErrorMessage>
                )}
              </Column>
              <Text color="text.secondary">per record</Text>
            </Row>
            <Tooltip
              message="Remove limit"
              placement="top-end"
              openSpeed="slow"
            >
              <IconButton
                aria-label="Remove"
                icon={DeleteIcon}
                variant="danger"
                onClick={() => {
                  remove(index);
                }}
              />
            </Tooltip>
          </Row>
        );
      })}
      <Button
        icon={PlusIcon}
        onClick={() => {
          append(getDefaultBlockRule());
        }}
      >
        Limit
      </Button>
    </Column>
  );
};
