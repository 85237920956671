import { FC, ReactNode } from "react";

import { Box, EmptyState } from "@hightouchio/ui";

import PlaceholderSrc from "src/assets/placeholders/generic.svg";

import ErrorSrc from "./error.svg";

export type PlaceholderContent = {
  image?: string;
  title?: string;
  body?: string | ReactNode;
  button?: ReactNode;
  error?: string;
  custom?: ReactNode;
  customError?: ReactNode;
  p?: number;
};

type PlaceholderProps = {
  error?: boolean;
  content: PlaceholderContent | undefined;
};

export const Placeholder: FC<Readonly<PlaceholderProps>> = ({
  error,
  content,
}) => {
  if (error) {
    return (
      <EmptyState
        imageUrl={ErrorSrc}
        title="Error"
        message={
          content?.customError || (
            <Box
              textAlign="center"
              maxW="72ch"
              color="text.danger"
              sx={{
                ":first-letter": {
                  textTransform: "capitalize",
                },
              }}
            >
              {content?.error || defaultError}
            </Box>
          )
        }
      />
    );
  }
  if (content?.custom) {
    return <>{content.custom}</>;
  }
  return (
    <EmptyState
      title={content?.title}
      imageUrl={content?.image ?? PlaceholderSrc}
      message={content?.body}
      actions={content?.button}
    />
  );
};

const defaultError = "Results were unable to load, please try again.";
