import { forwardRef, useImperativeHandle, useRef, useState } from "react";

import {
  Box,
  Button,
  Checkbox,
  ChevronDownIcon,
  ChevronUpIcon,
  CloseIcon,
  Column,
  FormField,
  IconButton,
  MultiSelect,
  Radio,
  RadioGroup,
  Row,
  Select,
  Textarea,
  TextInput,
} from "@hightouchio/ui";
import { FormProps as RfjsFormProps, withTheme } from "@rjsf/core";

export interface FormProps
  extends Pick<RfjsFormProps<unknown>, "schema" | "uiSchema"> {
  value: unknown;
  onChange: (value: unknown) => void;
}

const NestedItem = ({ children, isNested }) =>
  isNested ? (
    <Box pl="4" borderLeft="2px solid" borderColor="base.border">
      {children}
    </Box>
  ) : (
    children
  );

const ObjectFieldTemplate = (props) => {
  const {
    idSchema,
    title,
    description,
    properties,
    required,
    onAddClick,
    schema,
    uiSchema,
  } = props;
  const isRoot = idSchema?.$id === "root";

  return (
    <FormField description={description} label={title} isRequired={required}>
      <NestedItem isNested={!isRoot}>
        <Column gap={4}>
          {properties.map((element) => element.content)}
          {schema?.additionalProperties &&
            !uiSchema?.["ui:options"]?.expandable && (
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  onAddClick(props.schema)();
                }}
              >
                Add Property to {title}
              </Button>
            )}
        </Column>
      </NestedItem>
    </FormField>
  );
};

const ArrayFieldTemplate = (props) => {
  const { title, description, items, required } = props;

  return (
    <FormField description={description} label={title} isRequired={required}>
      <NestedItem isNested>
        <Column gap={4}>
          {items.map((element) => (
            <Row key={element.key} alignItems="center">
              <Row sx={{ flex: "1 1 auto" }}>{element.children}</Row>
              <Row sx={{ alignItems: "center", ml: 6, flex: "0 0 auto" }}>
                <IconButton
                  aria-label="Move up"
                  isDisabled={!element.hasMoveUp}
                  icon={ChevronUpIcon}
                  variant="secondary"
                  mr={1}
                  onClick={element.onReorderClick(
                    element.index,
                    element.index - 1,
                  )}
                />
                <IconButton
                  aria-label="Move down"
                  isDisabled={!element.hasMoveDown}
                  icon={ChevronDownIcon}
                  variant="secondary"
                  onClick={element.onReorderClick(
                    element.index,
                    element.index + 1,
                  )}
                />
                <IconButton
                  aria-label="Remove"
                  icon={CloseIcon}
                  variant="secondary"
                  onClick={element.onDropIndexClick(element.index)}
                />
              </Row>
            </Row>
          ))}
          <Row>
            <Button isDisabled={!props.canAdd} onClick={props.onAddClick}>
              Add Item to {title}
            </Button>
          </Row>
        </Column>
      </NestedItem>
    </FormField>
  );
};

const FieldTemplate = (props) => {
  const {
    id,
    displayLabel,
    hidden,
    label,
    rawHelp,
    required,
    rawDescription,
    rawErrors,
    children,
    onDropPropertyClick,
    onKeyChange,
    schema,
  } = props;
  const [propertyKey, setPropertyKey] = useState(label);

  if (hidden) {
    return null;
  }

  const labelComponent =
    onKeyChange && schema?.__additional_property ? (
      <TextInput
        width="sm"
        value={propertyKey}
        onBlur={() => {
          onKeyChange(propertyKey);
        }}
        onChange={(event) => {
          setPropertyKey(event.target.value);
        }}
      />
    ) : null;

  return (
    <FormField
      key={id}
      description={displayLabel && rawDescription}
      error={rawErrors?.join("\n")}
      tip={rawHelp}
      label={
        displayLabel && (
          <>
            {label}
            {labelComponent}
          </>
        )
      }
      isRequired={label && required}
    >
      <Row sx={{ alignItems: "center" }}>
        <Row sx={{ flex: "1 1 auto" }}>{children}</Row>
        {onDropPropertyClick && schema?.__additional_property && (
          <Row sx={{ alignItems: "center", ml: 2, flex: "0 0 auto" }}>
            <IconButton
              aria-label="Remove"
              icon={CloseIcon}
              variant="secondary"
              onClick={(e) => {
                onDropPropertyClick(label)(e);
              }}
            />
          </Row>
        )}
      </Row>
    </FormField>
  );
};

const getBaseProps = (props) => ({
  autoFocus: props.autofocus,
  disabled: props.disabled,
  required: props.required,
  readOnly: props.readonly,
});

const BaseInput = (props) => {
  const { disabled, readOnly, required, autoFocus } = getBaseProps(props);
  return (
    <TextInput
      isDisabled={disabled}
      isReadOnly={readOnly}
      isRequired={required}
      autoFocus={autoFocus}
      placeholder={props.placeholder}
      type={props.type}
      value={props.value}
      onChange={(event) => {
        props.onChange(event.target.value);
      }}
    />
  );
};

const RangeWidget = (props) => (
  <input
    {...getBaseProps(props)}
    type="range"
    value={props.value}
    onChange={(event) => {
      props.onChange(event.target.value);
    }}
  />
);

const FileWidget = (props) => (
  <input
    {...getBaseProps(props)}
    accept={props.uiSchema?.["ui:options"]?.accept}
    type="file"
    value={props.value}
    onChange={(event) => {
      props.onChange(event.target.value);
    }}
  />
);

const TextareaWidget = (props) => (
  <Textarea
    {...getBaseProps(props)}
    placeholder={props.placeholder}
    value={props.value}
    onChange={(event) => {
      props.onChange(event.target.value);
    }}
  />
);

const CheckboxWidget = (props) => {
  const { disabled, readOnly, required, autoFocus } = getBaseProps(props);
  return (
    <Checkbox
      autoFocus={autoFocus}
      required={required}
      readOnly={readOnly}
      isDisabled={disabled}
      label={props.label}
      isChecked={Boolean(props.value)}
      onChange={() => {
        props.onChange(!props?.value);
      }}
    />
  );
};

const RadioWidget = (props) => {
  return (
    <RadioGroup value={props.value} onChange={props.onChange}>
      {props.options?.enumOptions?.map((option) => (
        <Radio
          key={option.value}
          {...getBaseProps(props)}
          label={option.label}
          value={option.value.toString()}
          description={option.description}
        />
      ))}
    </RadioGroup>
  );
};

const SelectWidget = (props) => {
  const { disabled, readOnly } = getBaseProps(props);

  if (props.multiple) {
    return (
      <MultiSelect
        isDisabled={disabled || readOnly}
        options={props.options?.enumOptions ?? []}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
      />
    );
  }

  return (
    <Select
      isDisabled={disabled || readOnly}
      options={props.options?.enumOptions}
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onChange}
    />
  );
};

const theme = {
  fields: {},
  widgets: {
    BaseInput,
    FileWidget,
    SelectWidget,
    CheckboxWidget,
    TextareaWidget,
    RangeWidget,
    RadioWidget,
  },
};
const ThemedForm = withTheme(theme);

export interface FormRef {
  triggerValidation: () => void;
}

export const JsonForm = forwardRef<FormRef, FormProps>(
  ({ schema, uiSchema, value, onChange }, ref) => {
    const submitButtonRef = useRef<HTMLButtonElement>(null);
    useImperativeHandle(ref, () => ({
      triggerValidation() {
        submitButtonRef?.current?.click();
      },
    }));
    return (
      <ThemedForm
        noHtml5Validate
        ArrayFieldTemplate={ArrayFieldTemplate}
        FieldTemplate={FieldTemplate}
        ObjectFieldTemplate={ObjectFieldTemplate}
        formData={value}
        schema={schema}
        showErrorList={false}
        uiSchema={uiSchema}
        onChange={(data) => {
          onChange(data?.formData);
        }}
      >
        <button
          ref={submitButtonRef}
          style={{ display: "none" }}
          type="submit"
        />
      </ThemedForm>
    );
  },
);

JsonForm.displayName = "JsonForm";
