import { ReactRenderer } from "@tiptap/react";
import { SuggestionOptions } from "@tiptap/suggestion";
import tippy, { GetReferenceClientRect } from "tippy.js";

import { PluginHandler, Plugins } from "../../common";
import { OptionsList } from "./options-list";

export const newSuggestion = (props: {
  handler: PluginHandler;
  plugin: Plugins;
}): Omit<SuggestionOptions, "editor"> => {
  const { handler, plugin } = props;

  return {
    items: ({ query }) =>
      handler({ type: "suggestion", plugin, args: { query, limit: 10 } }),

    render: () => {
      let reactRenderer: ReactRenderer<any>;
      let popup;

      return {
        onStart: (props) => {
          reactRenderer = new ReactRenderer(OptionsList, {
            props,
            editor: props.editor,
          });

          if (!props.clientRect) {
            return;
          }

          popup = tippy("body", {
            getReferenceClientRect: props.clientRect as GetReferenceClientRect,
            appendTo: () => document.body,
            content: reactRenderer.element,
            showOnCreate: true,
            interactive: true,
            trigger: "manual",
            placement: "bottom-start",
          });
        },

        onUpdate(props) {
          reactRenderer.updateProps(props);

          if (!props.clientRect) {
            return;
          }

          popup?.[0]?.setProps({
            getReferenceClientRect: props.clientRect,
          });
        },

        onKeyDown(props) {
          if (props.event.key === "Escape") {
            popup?.[0]?.hide();

            return true;
          }

          return reactRenderer?.ref?.onKeyDown(props);
        },

        onExit() {
          popup?.[0]?.destroy();
          reactRenderer?.destroy();
        },
      };
    },
  };
};
