import { FC } from "react";

import { Routes, Route } from "src/router";

import { ContractRouter } from "./contract";
import { EventContracts } from "./contracts";

export const ContractsRouter: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<EventContracts />} />
      <Route path=":id/*" element={<ContractRouter />} />
    </Routes>
  );
};
