import { ModelWrapper } from "./model";
import { ModelDraft } from "src/components/models/draft";
import { ModelQueryWrapper } from "./model/query";
import { CloneModel } from "./model/clone";
import { PermissionedRoute } from "src/components/router/permissioned-route";
import { CreateModel } from "./create-model";
import Models from "./models";
import { RouteObject } from "src/router";

export const routes: Array<RouteObject> = [
  {
    path: "models",
    element: (
      <PermissionedRoute
        permission={{ v1: { resource: "model", grant: "read" } }}
        redirect="/"
      >
        <Models />
      </PermissionedRoute>
    ),
  },
  {
    path: "models/new",
    element: (
      <PermissionedRoute
        permission={{ v1: { resource: "model", grant: "create" } }}
        redirect="/models"
      >
        <CreateModel />
      </PermissionedRoute>
    ),
  },
  {
    path: "models/:model_id/*",
    element: <ModelWrapper />,
  },
  {
    path: "models/:model_id/clone",
    element: (
      <PermissionedRoute
        permission={{ v1: { resource: "model", grant: "create" } }}
        redirect="/models"
      >
        <CloneModel />
      </PermissionedRoute>
    ),
  },
  {
    path: "models/:model_id/query",
    element: <ModelQueryWrapper />,
  },
  {
    path: "models/:model_id/draft",
    element: <ModelDraft />,
  },
];
