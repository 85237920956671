import {
  Button,
  Radio,
  RadioGroup,
  RefreshIcon,
  Spinner,
  Column,
  Box,
  Row,
  SearchInput,
  EmptyState,
} from "@hightouchio/ui";

import { ExtendedOption } from "@hightouch/formkit";
import { useEffect, useMemo, useRef, useState } from "react";
import { useVirtualizer } from "@tanstack/react-virtual";

export type Props = {
  options: ExtendedOption[];
  value: string[];
  loading: any;
  reload?: () => void;
  onChange: (value: string[]) => void;
  isDisabled?: boolean;
};

type FlattenedOption = ExtendedOption & {
  parent?: ExtendedOption["value"];
  depth: number;
  path: ExtendedOption["value"][];
};

const flattenOptions = (
  options: ExtendedOption[],
  parent?: ExtendedOption["value"],
  path?: ExtendedOption["value"][],
  depth = 0,
): FlattenedOption[] => {
  if (!options) {
    return [];
  }

  return options.reduce((acc, option) => {
    const optionValuePath = path ? [...path, option.value] : [option.value];

    acc.push({
      ...option,
      parent,
      path: optionValuePath,
      depth,
    });
    if (option.childrenOptions) {
      acc.push(
        ...flattenOptions(
          option.childrenOptions,
          option.value,
          optionValuePath,
          depth + 1,
        ),
      );
    }
    return acc;
  }, [] as FlattenedOption[]);
};

const searchFlattenedOptions = (
  flattenedOptions: FlattenedOption[],
  search: string,
): FlattenedOption[] => {
  const matchedOptions = flattenedOptions.filter((option) =>
    option.label.toLowerCase().includes(search.toLowerCase()),
  );

  const parentOptions = matchedOptions.flatMap((option) => option.path);

  return flattenedOptions.filter(
    (option) =>
      parentOptions.includes(option.value) ||
      matchedOptions.map((option) => option.value).includes(option.value),
  );
};

export const NestedRadioGroup = ({
  options,
  value,
  loading,
  reload,
  isDisabled,
  onChange,
}: Props) => {
  const [search, setSearch] = useState("");
  const containerRef = useRef<HTMLDivElement>(null);

  const flattenedOptions = useMemo(() => flattenOptions(options), [options]);

  const filteredOptions = useMemo(
    () =>
      search
        ? searchFlattenedOptions(flattenedOptions, search)
        : flattenedOptions,
    [flattenedOptions, search],
  );

  const virtualizer = useVirtualizer<HTMLDivElement, Element>({
    count: filteredOptions.length,
    getScrollElement: () => containerRef.current,
    estimateSize: () => 32,
    overscan: 20,
  });

  const selectedOptionIndex = value
    ? filteredOptions.findIndex(
        (option) => option.path.join(",") === value.join(","),
      )
    : -1;

  useEffect(() => {
    if (virtualizer.scrollToIndex && selectedOptionIndex !== -1) {
      virtualizer.scrollToIndex(selectedOptionIndex, { align: "center" });
    }
  }, [virtualizer.scrollToIndex, selectedOptionIndex !== -1]);

  return (
    <Column maxH="400px">
      <Row
        borderTopLeftRadius="md"
        borderTopRadius="md"
        py={3}
        px={4}
        border="1px"
        borderBottom="none"
        borderColor="base.border"
        gap={4}
        justify="space-between"
      >
        <SearchInput
          width="100%"
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          placeholder="Search..."
        />
        {typeof reload === "function" && (
          <Button icon={RefreshIcon} onClick={reload}>
            Refresh
          </Button>
        )}
      </Row>
      <Box
        border="1px"
        borderColor="base.border"
        borderBottomRightRadius="md"
        borderBottomLeftRadius="md"
        flex={1}
        gap={3}
        overflow="auto"
        ref={containerRef}
      >
        {loading ? (
          <Spinner m={6} />
        ) : filteredOptions?.length === 0 ? (
          <EmptyState
            title="No results"
            message="Refresh your results or try another search."
            m={6}
          />
        ) : (
          <Box
            my={3}
            mx={4}
            height={`${virtualizer.getTotalSize()}px`}
            width="100%"
            position="relative"
          >
            <RadioGroup
              value={value ? value.join(",") : null}
              onChange={(v) => {
                onChange(v?.length ? v.split(",") : []);
              }}
            >
              {virtualizer.getVirtualItems().map((virtualItem) => {
                const option = filteredOptions[virtualItem.index];
                if (!option) {
                  return null;
                }
                return (
                  <Row
                    key={virtualItem.index}
                    position="absolute"
                    top={0}
                    left={0}
                    mt="0 !important"
                    transform={`translateY(${virtualItem.start}px)`}
                    minH="32px"
                    height="32px"
                    whiteSpace="nowrap"
                    alignItems="center"
                  >
                    {[...Array(option.depth).keys()].map((i) => (
                      <Box
                        key={i}
                        ml={2}
                        mr={4}
                        borderLeft="2px"
                        color="base.border"
                        height="100%"
                      />
                    ))}

                    <Radio
                      label={option.label}
                      value={option.path.join(",")}
                      isDisabled={isDisabled || option.disabled}
                    />
                  </Row>
                );
              })}
            </RadioGroup>
          </Box>
        )}
      </Box>
    </Column>
  );
};
