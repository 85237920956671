import { ColumnType } from "./column";

export type Operator = any;

export enum StringOperator {
  Equals = "=",
  DoesNotEqual = "<>",
  Contains = "contains",
  DoesNotContain = "notcontains",
  StartsWith = "startswith",
  DoesNotStartWith = "notstartswith",
  EndsWith = "endswith",
  DoesNotEndWith = "notendswith",
  Exists = "exists",
  ExistsAndIsNotEmpty = "existsandisnotempty",
  DoesNotExist = "notexists",
}

export enum NumberOperator {
  Equals = "=",
  DoesNotEqual = "<>",
  GreaterThan = ">",
  LessThan = "<",
  GreaterThanOrEqualTo = ">=",
  LessThanOrEqualTo = "<=",
  Exists = "exists",
  DoesNotExist = "notexists",
  Between = "between",
}

export enum BooleanOperator {
  Equals = "=",
  DoesNotEqual = "<>",
  Exists = "exists",
  DoesNotExist = "notexists",
}

export enum TimestampOperator {
  Before = "before",
  After = "after",
  Within = "within",
  NotWithin = "notwithin",
  Exists = "exists",
  DoesNotExist = "notexists",
  Anniversary = "anniversary",
  Between = "between",
  BetweenInclusive = "between-inclusive",
}

export enum JsonArrayOperator {
  Contains = "contains",
  // Omit the hyphen for consistency with the not contains operator for strings.
  DoesNotContain = "notcontains",
  ContainsLike = "contains-like",
  DoesNotContainLike = "not-contains-like",
  ContainsNonNull = "contains-non-null",
}

// This list contains operators that do not handle NULL values in a safe manner because they use a literal comparison.
// e.g., NULL <> 'foo' yields null which is falsey.
// This list should not include operators that check for NULL (e.g., StringOperator.Exists)
export const isNullSensitiveNegationOperator = (
  propertyType: ColumnType,
  operator: Operator,
) => {
  switch (propertyType) {
    case ColumnType.String:
      return [
        StringOperator.DoesNotEqual,
        StringOperator.DoesNotContain,
        StringOperator.DoesNotStartWith,
        StringOperator.DoesNotEndWith,
      ].includes(operator);

    case ColumnType.Number:
      return operator === NumberOperator.DoesNotEqual;

    case ColumnType.Boolean:
      return operator === BooleanOperator.DoesNotEqual;

    // Note that Timestamp, Date, JSONArrayStrings, JsonArrayNumbers
    // are not included as cases because the way we evaluate their negation
    // operators (e.g., NotWithin, DoesNotContain, DoesNotContainLike)
    // already _includes_ nulls in the result set

    default:
      return false;
  }
};
