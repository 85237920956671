import { FC, Fragment, ReactElement } from "react";

import {
  Button,
  CloseIcon,
  Column,
  IconButton,
  PlusIcon,
  Row,
  Select,
  Text,
  Tooltip,
  Box,
  TextInput,
  Checkbox,
} from "@hightouchio/ui";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useFormContext,
} from "react-hook-form";

import { ErrorMessage } from "src/components/explore/visual/error-message";
import { TextWithTooltip } from "src/components/text-with-tooltip";

import {
  ContractProperty,
  EventSchemaFormState,
  SchemaPropertyType,
} from "src/events/contracts/types";
import { getDefaultProperty } from "src/events/contracts/utils";
import { typeOptions } from "src/events/contracts/contract/event-schema/options";
import { SchemaPropertyTypeIcon } from "src/events/contracts/contract/event-schema/icons";

export const SimpleContractForm = ({ header }: { header: ReactElement }) => {
  const form = useFormContext<EventSchemaFormState>();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore ContractProperty type is circular
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "editorState.properties",
  });

  const propertyError = form.getFieldState("editorState.properties").error;

  return (
    <FormProvider {...form}>
      <Column align="flex-start" gap={4} flex={1}>
        <Column
          border="1px"
          borderColor="base.border"
          borderRadius="md"
          overflow="hidden"
          width="100%"
        >
          <Box
            px={4}
            py={2}
            borderBottom="1px"
            borderColor="base.border"
            backgroundColor="base.lightBackground"
          >
            {header}
          </Box>
          {fields.map(({ id }, index) => {
            return (
              <PropertyField
                key={id}
                name={`editorState.properties.${index}`}
                onRemove={() => {
                  remove(index);
                }}
              />
            );
          })}
        </Column>

        {form.formState.isSubmitted && propertyError && (
          <ErrorMessage>{propertyError.message}</ErrorMessage>
        )}

        <Button
          onClick={() => {
            append(getDefaultProperty());
          }}
        >
          <Row fontSize="20px" gap={1}>
            <PlusIcon />
            <Text>Add property</Text>
          </Row>
        </Button>
      </Column>
    </FormProvider>
  );
};

type PropertyFieldProps = {
  name: string;
  depth?: number;
  parentIsArrayProperty?: boolean;
  onRemove: () => void;
};

export const PropertyField: FC<PropertyFieldProps> = ({
  name,
  depth = 0,
  parentIsArrayProperty = false,
  onRemove,
}) => {
  const { watch } = useFormContext<EventSchemaFormState>();
  const { fields, append, remove } = useFieldArray({
    name: `${name}.properties`,
  });
  const type: string = watch(`${name}.type` as any);
  const properties: ContractProperty[] = watch(`${name}.properties` as any);

  return (
    <>
      <Box
        display="grid"
        gridTemplateColumns="220px 1fr repeat(2, min-content) 48px"
        sx={{
          "& > div + div": { borderLeft: "1px", borderColor: "base.border" },
          ":last-of-type": { borderBottom: "none" },
        }}
        borderBottom="1px"
        borderColor="base.border"
      >
        <Row
          align={parentIsArrayProperty ? "center" : "flex-start"}
          py={2}
          px={4}
          gap={2}
          pl={4 + depth * 4}
          justify="space-between"
          flex={1}
        >
          {depth === 0 || !parentIsArrayProperty ? (
            <Controller
              name={`${name}.name`}
              render={({ formState, field, fieldState }) => (
                <Column gap={1.5}>
                  <TextInput
                    isInvalid={
                      formState.isSubmitted &&
                      Boolean(fieldState.error?.message)
                    }
                    placeholder="Name"
                    value={field.value}
                    width="100%"
                    onChange={field.onChange}
                  />
                  {formState.isSubmitted && fieldState.error?.message && (
                    <Text color="danger.base" size="sm">
                      {fieldState.error.message}
                    </Text>
                  )}
                </Column>
              )}
            />
          ) : (
            <TextWithTooltip message="Array element">
              Array element
            </TextWithTooltip>
          )}

          <Controller
            name={`${name}.type`}
            render={({ field }) =>
              (field.value === "array" &&
                !parentIsArrayProperty &&
                properties.length < 1) ||
              field.value === "object" ? (
                <Tooltip message="Add property">
                  <IconButton
                    variant="secondary"
                    aria-label="Add property"
                    icon={PlusIcon}
                    onClick={() => {
                      append(getDefaultProperty());
                    }}
                  />
                </Tooltip>
              ) : (
                <Fragment />
              )
            }
          />
        </Row>
        <Row
          align={parentIsArrayProperty ? "center" : "flex-start"}
          py={2}
          px={4}
        >
          {depth === 0 || !parentIsArrayProperty ? (
            <Controller
              name={`${name}.description`}
              render={({ field }) => (
                <TextInput
                  placeholder="Description (optional)"
                  value={field.value ?? ""}
                  width="100%"
                  onChange={field.onChange}
                />
              )}
            />
          ) : (
            <Text color="text.secondary">--</Text>
          )}
        </Row>

        <Controller
          name={`${name}.type`}
          render={({ field }) => (
            <Row align="flex-start" py={2} px={4} width="160px">
              <Select
                width="100%"
                value={field.value}
                optionAccessory={(option) => ({
                  type: "icon",
                  icon: () => (
                    <Row fontSize="16px">
                      <SchemaPropertyTypeIcon type={option.value} />
                    </Row>
                  ),
                })}
                onChange={(value) => {
                  field.onChange(value);

                  if (value !== "object") {
                    remove();
                  }
                }}
                options={
                  parentIsArrayProperty
                    ? typeOptions.filter(
                        ({ value }) => value !== SchemaPropertyType.Array,
                      )
                    : typeOptions
                }
              />
            </Row>
          )}
        />

        <Controller
          name={`${name}.required`}
          render={({ field }) => (
            <Row alignItems="center" py={2} px={4} width="115px">
              <Checkbox
                isDisabled={parentIsArrayProperty}
                width="100%"
                isChecked={field.value}
                onChange={field.onChange}
                label="Required"
              />
            </Row>
          )}
        />

        <Row align="flex-start" p={2} width="fit-content">
          <Tooltip message="Remove property">
            <IconButton
              aria-label="Remove property"
              icon={CloseIcon}
              onClick={onRemove}
            />
          </Tooltip>
        </Row>
      </Box>
      {fields.map(({ id }, index) => {
        return (
          <PropertyField
            key={id}
            depth={depth + 1}
            name={`${name}.properties.${index}`}
            parentIsArrayProperty={type === "array"}
            onRemove={() => {
              remove(index);
            }}
          />
        );
      })}
    </>
  );
};
