import { FC, useState } from "react";

import { Badge, Box, Button, Column, Row, Text } from "@hightouchio/ui";
import cloneDeep from "lodash/cloneDeep";
import { useOutletContext } from "src/router";

import { ErrorJsonModal } from "src/components/modals/error-json-modal";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import {
  IdrRunsOrderBy,
  OrderBy,
  useIdentityResolutionRunsQuery,
} from "src/graphql";
import {
  Pagination,
  SortOption,
  Table,
  useTableConfig,
  useTableSort,
} from "src/ui/table";
import { commaNumber } from "src/utils/numbers";
import * as time from "src/utils/time";
import {
  getLegacySummaryValues,
  getv2SummaryValues,
  GraphVersion,
} from "src/pages/identity-resolution/utils";
import { RunGraphButton } from "src/pages/identity-resolution/components";

import { OutletContext, transformLegacyStatistics } from ".";
import { GraphStatusIndicator } from "./graph-status-indicator";

const initialSort: SortOption<keyof IdrRunsOrderBy> = {
  key: "created_at",
  direction: OrderBy.Desc,
  label: "Newest",
};

const sortOptions: SortOption<keyof IdrRunsOrderBy>[] = [
  initialSort,
  { key: "status", direction: OrderBy.Asc, label: "Status" },
  {
    key: "finished_at",
    direction: OrderBy.DescNullsLast,
    label: "Finished",
  },
];

export const Runs: FC = () => {
  const { graph } = useOutletContext<OutletContext>();
  const [runError, setError] = useState<string>();

  const { limit, offset, page, setPage } = useTableConfig<IdrRunsOrderBy>();
  const orderBy = useTableSort<IdrRunsOrderBy>(initialSort, sortOptions);

  const isIDRv2 = graph.version === GraphVersion.V2;

  const { data, isLoading, error } = useIdentityResolutionRunsQuery(
    {
      limit,
      offset,
      orderBy: orderBy ?? { created_at: "desc" },
      filter: { idr_id: { _eq: graph.id } },
    },
    {
      refetchInterval: 5000,
      // Transform statistics from older IDR runs into the newer format so we don't have special
      // case code all over the place.
      select: (data) => {
        const runs = data.idr_runs.map((_run) => {
          const run = cloneDeep(_run);
          if (!isIDRv2) {
            run.stats = getLegacySummaryValues(
              transformLegacyStatistics(run.stats),
            );
          } else {
            run.stats = getv2SummaryValues(run.stats);
          }

          return run;
        });

        return { count: data.idr_runs_aggregate.aggregate?.count, runs };
      },
    },
  );

  return (
    <>
      <Column gap={4}>
        <Table
          sortOptions={sortOptions}
          columns={[
            {
              name: "Started at",
              cell: ({ started_at }) => {
                if (!started_at) {
                  return <Text fontWeight="medium">--</Text>;
                }

                const startDay = time.formatFriendlyDay(started_at) ?? "";
                const startTime = time.formatTime(started_at) ?? "";

                const startedAt = `${startDay} · ${startTime}`;

                return (
                  <TextWithTooltip message={startedAt}>
                    {startedAt}
                  </TextWithTooltip>
                );
              },
            },
            {
              name: "Run status",
              cellSx: { overflow: "hidden" },
              cell: (run) => {
                return (
                  <Row gap={2}>
                    <GraphStatusIndicator showDuration lastRun={run} />
                    {run?.full_rerun && <Badge size="sm">Full re-run</Badge>}
                  </Row>
                );
              },
            },
            {
              name: "Unique profiles",
              min: "min-content",
              max: "min-content",
              cellSx: { div: { width: "100%", textAlign: "right" } },
              cell: (run) => {
                if (!run?.stats) {
                  return (
                    <TextWithTooltip color="text.secondary" fontWeight="medium">
                      --
                    </TextWithTooltip>
                  );
                }

                const uniqueProfiles = commaNumber(run.stats.totalHtIds);

                return (
                  <TextWithTooltip fontWeight="medium">
                    {uniqueProfiles}
                  </TextWithTooltip>
                );
              },
            },
            {
              name: "Source rows",
              min: "min-content",
              max: "min-content",
              cellSx: { div: { width: "100%", textAlign: "right" } },
              cell: (run) => {
                if (!run?.stats) {
                  return (
                    <TextWithTooltip color="text.secondary" fontWeight="medium">
                      --
                    </TextWithTooltip>
                  );
                }

                const sourceRows = commaNumber(run.stats.totalSrcRows);

                return (
                  <TextWithTooltip fontWeight="medium">
                    {sourceRows}
                  </TextWithTooltip>
                );
              },
            },
            {
              name: "",
              max: "min-content",
              cell: ({ error }) => {
                if (error?.error) {
                  return (
                    <Button
                      size="sm"
                      ml="auto"
                      variant="warning"
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setError(error.error);
                      }}
                    >
                      View error
                    </Button>
                  );
                }

                return null;
              },
            },
          ]}
          data={data?.runs ?? []}
          loading={isLoading}
          error={Boolean(error)}
          placeholder={{
            title: "No runs",
            body: "Run the identity graph to view runs",
            error: "Runs failed to load, please try again.",
            button: <RunGraphButton />,
          }}
        />
        <Pagination
          count={data?.count ?? 0}
          label="runs"
          page={page}
          rowsPerPage={limit}
          setPage={setPage}
        />
      </Column>
      <ErrorJsonModal
        title="Identity graph error"
        error={runError}
        pylonMessage={`I'm experiencing an issue with my identity graph and could use some assistance. The error message I'm receiving is: "${runError}".
                Here's a link to the graph: <a href="${window.location.href}">${window.location.href}</a>.`}
        onClose={() => setError(undefined)}
      />
    </>
  );
};

export const ProfileStats = ({ stats }) => {
  return (
    <Row gap={4}>
      <Column align="flex-end">
        <Text fontWeight="medium">
          {stats?.profiles ? commaNumber(stats.profiles.num_ht_ids) : "--"}
        </Text>
        <Box fontSize="sm" color="text.secondary" textAlign="right">
          Resolved
        </Box>
      </Column>
      <Column align="flex-end">
        <Text fontWeight="medium">
          {stats?.profiles ? commaNumber(stats.profiles.source_rows) : "--"}
        </Text>
        <Box fontSize="sm" color="text.secondary" textAlign="right">
          Total
        </Box>
      </Column>
    </Row>
  );
};

export const EventStats = ({ stats }) => {
  return (
    <Row gap={4}>
      <Column align="flex-end">
        <Text fontWeight="medium">
          {stats?.events ? commaNumber(stats.events.num_ht_ids) : "--"}{" "}
        </Text>
        <Box fontSize="sm" color="text.secondary" textAlign="right">
          Resolved
        </Box>
      </Column>
      <Column align="flex-end">
        <Text fontWeight="medium">
          {stats?.events ? commaNumber(stats.events.source_rows) : "--"}
        </Text>
        <Box fontSize="sm" color="text.secondary" textAlign="right">
          Total
        </Box>
      </Column>
    </Row>
  );
};
