import { FC, useRef } from "react";

import {
  CloseIcon,
  Column,
  DrawerHeader,
  Heading,
  IconButton,
  Row,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@hightouchio/ui";

import { Drawer } from "src/components/drawer";
import { ResourceActivity } from "src/hooks/use-resource-activity";

import { Editor } from "../editor";

interface RawViewProps {
  open: boolean;
  onClose: () => void;
  activity: ResourceActivity;
}

export const ResourceActivityExpandedView: FC<RawViewProps> = ({
  open,
  onClose,
  activity,
}) => {
  const closeButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <Drawer
      initialFocusRef={closeButtonRef}
      isOpen={open}
      size="lg"
      onClose={onClose}
    >
      <DrawerHeader>
        <Row align="center" justify="space-between" flex={1} minWidth={0}>
          <Heading>Details</Heading>
          <IconButton
            // Need `autoFocus` until initialFocusRef works as expected
            // https://www.notion.so/hightouch/Drawer-s-initialFocusRef-not-working-as-expected-d32223b3d25e46c78a422a18833a43c1?pvs=4
            autoFocus
            aria-label="Close drawer"
            ref={closeButtonRef}
            icon={CloseIcon}
            onClick={onClose}
          />
        </Row>
      </DrawerHeader>

      <Column as={Tabs} overflowY="auto">
        <TabList pl={6}>
          <Tab>Diff</Tab>
          <Tab>Raw</Tab>
          {activity.approvedDraft && <Tab>Draft</Tab>}
          {activity.deployment && <Tab>Deployment</Tab>}
        </TabList>
        <Column as={TabPanels} overflowY="auto">
          <TabPanel>
            <Editor
              readOnly
              language="json"
              value={JSON.stringify(activity.diff, null, 2)}
            />
          </TabPanel>
          <TabPanel>
            <Editor
              readOnly
              language="json"
              value={JSON.stringify(activity.metadata.new, null, 2)}
            />
          </TabPanel>
          <TabPanel>
            <Editor
              readOnly
              language="json"
              value={JSON.stringify(activity.approvedDraft, null, 2)}
            />
          </TabPanel>
          <TabPanel>
            <Editor
              readOnly
              language="json"
              value={JSON.stringify(activity.deployment, null, 2)}
            />
          </TabPanel>
        </Column>
      </Column>
    </Drawer>
  );
};
