import {
  Box,
  Button,
  ButtonGroup,
  ChatIcon,
  Column,
  Dialog,
  Text,
} from "@hightouchio/ui";

import { Markdown } from "src/ui/markdown";

import { newPylonMessage } from "src/lib/pylon";

type ErrorJsonModalProps = {
  title: string;
  error: string | undefined;
  pylonMessage: string;
  onClose: () => void;
};

export const ErrorJsonModal = ({
  title,
  error,
  pylonMessage,
  onClose,
}: ErrorJsonModalProps) => {
  const _pylonMessage =
    pylonMessage ??
    `I'm experiencing an issue and could use some assistance. The error message I'm receiving is: "${error}"`;

  return (
    <Dialog
      isOpen={Boolean(error)}
      variant="info"
      width="xl"
      title={title}
      actions={
        <ButtonGroup>
          <Button
            icon={ChatIcon}
            size="md"
            variant="secondary"
            onClick={() => newPylonMessage(_pylonMessage)}
          >
            Chat with support
          </Button>
          <Button variant="primary" onClick={onClose}>
            Close
          </Button>
        </ButtonGroup>
      }
      onClose={onClose}
    >
      {error && (
        <Column flex={1} sx={{ overflow: "auto" }}>
          <Column borderBottom="1px" borderColor="base.border" pb={6}>
            <Box
              bg="gray.50"
              p={2}
              borderRadius="md"
              border="1px"
              borderColor="base.border"
            >
              <Markdown>{error}</Markdown>
            </Box>
          </Column>
          <Column mt={6}>
            <Text fontWeight="medium" size="lg">
              Need more help?
            </Text>
            <Text mt={2}>
              If you feel stuck, please reach out! We want to make sure you have
              all the help you need. Our team is available via chat to help you
              troubleshoot this error.
            </Text>
          </Column>
        </Column>
      )}
    </Dialog>
  );
};
