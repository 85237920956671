import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const IdentityResolutionIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.95959 1.50164C7.13391 1.4875 7.30295 1.56548 7.40535 1.70726L10.6207 6.1593C11.0636 6.05512 11.5253 6 12 6C13.3429 6 14.5827 6.44116 15.5825 7.18646L20.8494 5.52321C21.0074 5.47334 21.1797 5.50491 21.3098 5.60751C21.4398 5.71011 21.5105 5.87043 21.4987 6.03563L20.4987 20.0356C20.4823 20.2661 20.3101 20.4552 20.0822 20.4932L8.08221 22.4932C7.87714 22.5274 7.67232 22.4311 7.5678 22.2514C7.46327 22.0717 7.48085 21.8461 7.61195 21.6847L10.7174 17.8626C8.01997 17.2752 6 14.8735 6 12C6 11.7448 6.01594 11.4932 6.04687 11.2464L2.15057 12.4768C1.9591 12.5373 1.75004 12.4773 1.61969 12.3246C1.48934 12.1719 1.463 11.956 1.55279 11.7764L6.55279 1.7764C6.63101 1.61997 6.78527 1.51578 6.95959 1.50164ZM6.3013 10.1174C6.8401 8.48553 8.06025 7.16361 9.62489 6.48844L7.07802 2.96201L2.97517 11.1677L6.3013 10.1174ZM11.895 17.9991L9.22151 21.2895L19.1227 19.6393L16.4844 15.9863C15.3854 17.2217 13.7836 18 12 18C11.9649 18 11.9299 17.9997 11.895 17.9991ZM17.1105 15.1452L19.5994 18.5913L20.3883 7.54575L17.8516 10.6679C17.9487 11.0963 18 11.5422 18 12C18 13.1534 17.6746 14.2307 17.1105 15.1452ZM17.4761 9.54427L19.5604 6.97897L16.44 7.96433C16.8635 8.43 17.2148 8.96254 17.4761 9.54427ZM17 12C17 14.7614 14.7614 17 12 17C9.23858 17 7 14.7614 7 12C7 9.23858 9.23858 7 12 7C14.7614 7 17 9.23858 17 12Z"
        fillOpacity="0.3"
      />
      <path d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z" />
      <path d="M22 6C22 6.55228 21.5523 7 21 7C20.4477 7 20 6.55228 20 6C20 5.44772 20.4477 5 21 5C21.5523 5 22 5.44772 22 6Z" />
      <path d="M21 20C21 20.5523 20.5523 21 20 21C19.4477 21 19 20.5523 19 20C19 19.4477 19.4477 19 20 19C20.5523 19 21 19.4477 21 20Z" />
      <path d="M8 2C8 2.55228 7.55228 3 7 3C6.44772 3 6 2.55228 6 2C6 1.44772 6.44772 1 7 1C7.55228 1 8 1.44772 8 2Z" />
      <path d="M3 12C3 12.5523 2.55228 13 2 13C1.44772 13 1 12.5523 1 12C1 11.4477 1.44772 11 2 11C2.55228 11 3 11.4477 3 12Z" />
      <path d="M9 22C9 22.5523 8.55228 23 8 23C7.44772 23 7 22.5523 7 22C7 21.4477 7.44772 21 8 21C8.55228 21 9 21.4477 9 22Z" />
    </Icon>
  );
};

// NOTE: This is the same SVG as packages/app/src/pages/schema/assets/event.svg, but it is wrapped
// so it can be used as in Icon rather than an img. Old usages of event.svg. have not been updated.
export const IdrEventIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon width="40" height="40" viewBox="0 0 40 40" {...props}>
      <rect width="40" height="40" rx="6" fill="#3DBFD9" />
      <rect
        width="40"
        height="40"
        rx="6"
        fill="url(#paint0_linear_428_33842)"
      />
      <path
        d="M20 11C15.05 11 11 15.05 11 20C11 24.95 15.05 29 20 29C24.95 29 29 24.95 29 20C29 15.05 24.95 11 20 11ZM20 27.2C16.031 27.2 12.8 23.969 12.8 20C12.8 16.031 16.031 12.8 20 12.8C23.969 12.8 27.2 16.031 27.2 20C27.2 23.969 23.969 27.2 20 27.2ZM20.45 15.5H19.1V20.9L23.78 23.78L24.5 22.61L20.45 20.18V15.5Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_428_33842"
          x1="20"
          y1="0"
          x2="20"
          y2="40"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0" />
          <stop offset="1" stopOpacity="0.1" />
        </linearGradient>
      </defs>
    </Icon>
  );
};

// NOTE: This is the same SVG as packages/app/src/pages/schema/assets/parent.svg, but it is wrapped
// so it can be used as in Icon rather than an img. Old usages of parent.svg. have not been updated.
export const IdrProfileIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon width="40" height="40" viewBox="0 0 40 40" {...props}>
      <rect width="40" height="40" rx="6" fill="#BC85FA" />
      <rect
        width="40"
        height="40"
        rx="6"
        fill="url(#paint0_linear_428_26236)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5144 9.12584C19.8164 8.95805 20.1836 8.95805 20.4856 9.12584L29.4856 14.1258C29.8031 14.3022 30 14.6368 30 15C30 15.3632 29.8031 15.6978 29.4856 15.8742L20.4856 20.8742C20.1836 21.0419 19.8164 21.0419 19.5144 20.8742L10.5144 15.8742C10.1969 15.6978 10 15.3632 10 15C10 14.6368 10.1969 14.3022 10.5144 14.1258L19.5144 9.12584ZM13.0591 15L20 18.856L26.9409 15L20 11.144L13.0591 15Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.5071 14.1381C29.8125 14.3178 30 14.6457 30 15V25C30 25.3632 29.8031 25.6978 29.4856 25.8742L20.4856 30.8742C20.1759 31.0462 19.7983 31.0416 19.4929 30.8619C19.1875 30.6822 19 30.3543 19 30V20C19 19.6368 19.1969 19.3022 19.5144 19.1258L28.5144 14.1258C28.8241 13.9538 29.2017 13.9584 29.5071 14.1381ZM21 20.5884V28.3005L28 24.4116V16.6995L21 20.5884Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4929 14.1381C10.7983 13.9584 11.1759 13.9538 11.4856 14.1258L20.4856 19.1258C20.8031 19.3022 21 19.6368 21 20V30C21 30.3543 20.8125 30.6822 20.5071 30.8619C20.2017 31.0416 19.8241 31.0462 19.5144 30.8742L10.5144 25.8742C10.1969 25.6978 10 25.3632 10 25V15C10 14.6457 10.1875 14.3178 10.4929 14.1381ZM12 16.6995V24.4116L19 28.3005V20.5884L12 16.6995Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_428_26236"
          x1="20"
          y1="0"
          x2="20"
          y2="40"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0" />
          <stop offset="1" stopOpacity="0.1" />
        </linearGradient>
      </defs>
    </Icon>
  );
};
