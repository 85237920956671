import type { SdkErrorTypes } from "@hightouch/sdk/lib/sdk-errors-enum";

export enum PrivateErrorTypes {
  BadRequestError = "BadRequestError",
  CancelError = "CancelError",
  HealthcheckError = "HealthcheckError",
  ReservationLostError = "ReservationLostError",
  RetryWithDelayError = "RetryWithDelayError",
  ShutdownError = "ShutdownError",
  SyncFailedWithRejectedRowsError = "SyncFailedWithRejectedRowsError",
  UnmetPlannerDependencyError = "UnmetPlanerDependencyError",
  UnsupportedExportError = "UnsupportedExportError",
}

export type SerializerErrorTypes = PrivateErrorTypes | SdkErrorTypes;
