import { FC, useState, ReactNode } from "react";

import { Column, Row, Text, ButtonGroup } from "@hightouchio/ui";
import { isEqual } from "lodash";
import { singular } from "pluralize";
import { Schema } from "yup";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { FieldError } from "src/components/field-error";

import airtable from "./airtable";
import makeFileUploadForm from "./file-upload";
import googlesheets from "./google-sheets";
import metabase from "./metabase";
import microsoftexcel from "./microsoft-excel";
import mixpanel from "./mixpanel";
import mongodb from "./mongodb";
import sftp from "./sftp";
import tableau from "./tableau";

type Source = {
  id: string;
  name: string;
  type: string;
  definition: {
    name: string;
    icon: string;
  };
  config: Record<string, any>;
};

export type CustomQuery = {
  type?: string;
  path?: string;
  fieldSeparator?: string;
  fileType?: "automatic" | "json" | "ndjson" | "csv" | "parquet";
  projectId?: number;
  baseId?: string;
  workbookId?: string;
  viewId?: string;
  sheetId?: string;
} & Record<string, unknown>;

export type Query = {
  QueryForm: FC<Readonly<CustomQueryFormProps>>;
  QueryView: FC<Readonly<CustomQueryViewProps>>;
  querySchema: Schema<CustomQuery, object>;
};

export type CustomQueryFormProps = {
  source: Source;
  query: CustomQuery | undefined;
  onChange: (query: CustomQuery) => void;
  error: Error | null;
  setError: (error: Error | null) => void;
};

export type CustomQueryViewProps = {
  source: Source;
  query: CustomQuery;
  error: Error | null;
  setError: (error: Error | null) => void;
};

const QUERIES: { [key: string]: Query } = {
  airtable,
  googlesheets,
  mixpanel,
  metabase,
  mongodb,
  "azure-blob": makeFileUploadForm(),
  s3: makeFileUploadForm({
    objectLabel: "S3 object",
    enableUseLastModified: true,
  }),
  gcs: makeFileUploadForm(),
  sftp,
  tableau,
  microsoftexcel,
};

export const CustomQueryForm: FC<
  Readonly<{
    source: Source;
    query?: CustomQuery;
    onChange: (query: CustomQuery) => void;
  }>
> = ({ source, query, onChange }) => {
  const [error, setError] = useState<Error | null>(null);
  const Form = QUERIES[source.type]?.QueryForm;

  return (
    <Column
      border="1px"
      borderColor="base.border"
      borderRadius="md"
      overflow="hidden"
    >
      <Row
        align="center"
        gap={2}
        borderBottom="1px"
        borderColor="base.border"
        px={6}
        py={4}
      >
        <IntegrationIcon
          src={source?.definition.icon}
          name={source?.definition.name}
        />
        <Text fontWeight="medium" size="lg">
          {source?.name ?? "Private source"}
        </Text>
      </Row>
      <Column gap={6} p={6} overflow="auto">
        {Form && (
          <Form
            error={error}
            query={query}
            setError={setError}
            source={source}
            onChange={onChange}
          />
        )}
        <FieldError error={error} />
      </Column>
    </Column>
  );
};

export const CustomQueryView: FC<
  Readonly<{ source: Source; query: CustomQuery; actions?: ReactNode }>
> = ({ source, query, actions }) => {
  const View = QUERIES[source.type]?.QueryView;
  const [error, setError] = useState<Error | null>(null);

  return (
    <Column
      border="1px"
      borderColor="base.border"
      borderRadius="md"
      flex={1}
      overflow="auto"
    >
      <Row
        flex={1}
        align="center"
        justify="space-between"
        gap={4}
        borderBottom="1px"
        borderColor="base.border"
        px={6}
        py={4}
      >
        <Row align="center" gap={2}>
          <IntegrationIcon
            src={source?.definition?.icon}
            name={source?.definition?.name}
          />
          <Text fontWeight="medium" size="lg">
            {singular(source?.definition?.name) ?? "Private source"}
          </Text>
        </Row>
        {actions && <ButtonGroup>{actions}</ButtonGroup>}
      </Row>
      <Column gap={6} p={6} overflow="auto">
        {View && (
          <View
            error={error}
            query={query}
            setError={setError}
            source={source}
          />
        )}
        <FieldError error={error} />
      </Column>
    </Column>
  );
};

export async function validateCustomQuery(
  source: Source,
  query: CustomQuery | undefined,
): Promise<void> {
  const definition = QUERIES[source.type];

  if (definition) {
    await definition.querySchema.validate(query, { abortEarly: false });
  }
}

export function isCustomQueryDirty(
  state: CustomQuery | undefined,
  stored: CustomQuery | undefined,
): boolean {
  return !isEqual(state, stored);
}
