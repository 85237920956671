import { uniq } from "lodash";

export function getKeyValueArray(tags: Record<string, string> | undefined) {
  if (!tags || Object.keys(tags).length === 0) {
    return [{ key: "", value: "" }];
  }
  return [
    ...Object.entries(tags).map(([key, value]) => ({ key, value })),
    { key: "", value: "" },
  ];
}

export function getKeyValueObject(
  labels: { key: string; value: string }[],
): Record<string, string> {
  const validLabels = labels.filter(
    ({ key, value }) => key !== "" && value !== "",
  );
  const result = {};
  validLabels.forEach((option) => {
    result[option.key] = option.value;
  });
  return result;
}

export function getValuesForKeys(objects: Array<Record<string, any>>): {
  [key: string]: Array<any>;
} {
  const result: { [key: string]: any[] } = objects.reduce(
    (acc, obj) => {
      Object.keys(obj).forEach((key) => {
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj[key]);
      });
      return acc;
    },
    {} as { [key: string]: any[] },
  );

  // Use lodash's uniq function to get unique values for each key
  Object.keys(result).forEach((key) => {
    result[key] = uniq(result[key]);
  });

  return result;
}
