import { FC, ReactNode } from "react";

import {
  Badge,
  Button,
  Box,
  Column,
  Row,
  Heading,
  Text,
} from "@hightouchio/ui";

import { CheckList, CheckListItem } from "src/ui/checklist";

export type PricingCardProps = {
  plan?: string;
  sku: string;
  ctaText: string;
  ctaDisabled?: boolean;
  ctaUrl?: string;
  ctaLoading?: boolean;
  price?: string;
  priceUnit?: string;
  priceHint?: string;
  checkList?: (string | ReactNode)[];
  checkListHeading?: string;
  partnerLogos: { img: string; alt: string }[];
  onCtaClick: (sku: string) => void;
};

export const PricingCard: FC<Readonly<PricingCardProps>> = ({
  plan,
  sku,
  ctaText,
  ctaDisabled,
  ctaLoading,
  partnerLogos,
  price,
  priceHint,
  priceUnit,
  checkList,
  checkListHeading,
  onCtaClick,
}) => {
  function ctaClick() {
    onCtaClick(sku);
  }

  return (
    <Box
      backgroundColor="white"
      border="1px solid"
      borderColor="base.border"
      borderRadius="12px"
      position="relative"
      py={6}
      px={4}
    >
      <Column height="100%" justify="space-between">
        <Column align="start" position="relative">
          <Row alignItems="center" gap={2} mb={6}>
            <Heading>{plan}</Heading>

            {plan === "Enterprise" ? (
              <Badge variant="upsell">Business Tier</Badge>
            ) : (
              <Badge>Self-serve</Badge>
            )}
          </Row>

          <Row align="center" gap={1} mb={4}>
            <Heading
              color={priceUnit ? "text.primary" : "text.secondary"}
              size="md"
            >
              {price}{" "}
              {priceUnit && (
                <Box as="span" color="text.secondary">
                  {priceUnit}
                </Box>
              )}
            </Heading>
          </Row>

          {priceHint && <Text mb={4}>{priceHint}</Text>}

          <Button
            isDisabled={ctaDisabled}
            isLoading={ctaLoading}
            variant="primary"
            onClick={ctaClick}
          >
            {ctaText}
          </Button>

          <CheckList>
            <Text fontWeight="semibold">{checkListHeading}</Text>
            {checkList?.map((listItem, index) => {
              return <CheckListItem key={index}>{listItem}</CheckListItem>;
            })}
          </CheckList>
        </Column>

        <Box p={4} pb={0} textAlign="center">
          <Text color="text.secondary">Powering companies like</Text>

          <Row align="center" justify="center" mt={2}>
            {partnerLogos.map((logo) => {
              return (
                <Box
                  as="img"
                  key={logo.alt}
                  alt={logo.alt}
                  src={logo.img}
                  sx={{
                    filter: "brightness(0)",
                    height: "40px",
                    maxWidth: "160px",
                    width: "50%",
                  }}
                />
              );
            })}
          </Row>
        </Box>
      </Column>
    </Box>
  );
};

PricingCard.displayName = "Pricing card";
