import {
  NumberOperator,
  TraitType,
  ValueType,
  isAggregationTraitConfig,
  isCountDedupedTraitConfig,
  isInlineTraitColumn,
  isNumberRangeType,
  isOrderDedupedTraitConfig,
  isRelatedColumn,
} from "@hightouch/lib/query/visual/types";
import { isPresent } from "ts-extras";

import {
  EventCondition,
  FunnelCondition,
  NumberOfCondition,
  PropertyCondition,
  ReferencedPropertyCondition,
  SegmentSetCondition,
  TimestampOperator,
  ColumnType,
  TimeType,
  Window,
  TimeRangeValue,
  IntervalValue,
  OperatorsWithoutValue,
} from "src/types/visual";

type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
};
type ValidationResult<TCondition> = PartialRecord<
  keyof TCondition,
  string | null
>;
type Validator<TCondition> = (
  condition: TCondition,
  options?: { ignoreValue?: boolean },
) => ValidationResult<TCondition>;

export const requiredErrorMessage = "This field is required";
export const numberRequiredErrorMessage = "This field must contain a number";
export const validNumberMessage = "Please enter a valid number";
export const validTimeTypeMessage = "Please select a valid time type";
export const validDateRangeMessage = "Please enter a valid date range";

const isIntervalValue = (
  value: IntervalValue | TimeRangeValue | string,
): value is IntervalValue => {
  return typeof value !== "string" && "interval" in value;
};

type ValidateDateRangeArguments = {
  timeType: TimeType | undefined;
  value: string | TimeRangeValue | IntervalValue | null;
};
export const validateDateRange = ({
  timeType,
  value,
}: ValidateDateRangeArguments) => {
  if (timeType === undefined || typeof value === "string") {
    return {};
  }

  if (value === undefined) {
    return {
      value: validDateRangeMessage,
    };
  }

  if (value !== null && isIntervalValue(value)) {
    return {
      quantity:
        !isPresent(value.quantity) || isNaN(value.quantity)
          ? validNumberMessage
          : null,
    };
  }

  if (timeType === TimeType.Absolute) {
    return {
      value:
        value === null ||
        typeof value.after !== "string" ||
        typeof value.before !== "string"
          ? validDateRangeMessage
          : null,
    };
  } else if (timeType === TimeType.Relative) {
    if (value === null) {
      return { value: validDateRangeMessage };
    }

    if (
      typeof value === "string" ||
      typeof value.after === "string" ||
      typeof value.before === "string"
    ) {
      return {};
    }

    if (
      !isPresent(value.after?.quantity) ||
      !isPresent(value.before?.quantity)
    ) {
      return { value: validDateRangeMessage };
    }

    return {
      value:
        !isPresent(value?.after?.quantity) ||
        isNaN(value?.after?.quantity) ||
        !isPresent(value?.before?.quantity) ||
        isNaN(value?.before?.quantity)
          ? validDateRangeMessage
          : null,
    };
  }

  return {};
};

export const validatePropertyCondition: Validator<PropertyCondition> = ({
  property,
  propertyType,
  timeType,
  operator,
  value,
  propertyOptions,
}) => {
  if (!property) {
    return {
      property: !property ? requiredErrorMessage : null,
    };
  }

  if (OperatorsWithoutValue.includes(operator)) {
    return {};
  }

  if (propertyType === ColumnType.Timestamp) {
    // 'between' works differently than the rest of the value types
    switch (operator) {
      case TimestampOperator.Between:
        return validateDateRange({
          timeType: timeType || TimeType.Relative,
          value,
        });
      case TimestampOperator.Before:
      case TimestampOperator.After:
      case TimestampOperator.Within:
      case TimestampOperator.NotWithin:
        if (timeType === TimeType.Absolute) {
          return {
            value:
              typeof value !== "string" ? numberRequiredErrorMessage : null,
          };
        } else if (timeType === TimeType.Relative) {
          return {
            value:
              value === null || isNaN(value.quantity) || value.quantity === null
                ? numberRequiredErrorMessage
                : null,
          };
        } else if (value?.type === ValueType.Column) {
          return {
            value:
              value?.property == null ? "Attribute column is required." : null,
          };
        }
        break;
      case TimestampOperator.Exists:
      case TimestampOperator.DoesNotExist:
      case TimestampOperator.Anniversary:
      default:
        break;
    }
  }

  if (propertyType === ColumnType.Number) {
    if (operator === NumberOperator.Between) {
      return {
        value: isNumberRangeType(value)
          ? null
          : "Value must be a valid range of numbers",
      };
    }

    if (!Array.isArray(value) && !Number.isFinite(Number(value))) {
      return {
        value: "Value must be numeric",
      };
    }

    if (propertyOptions?.percentile) {
      return {
        value:
          value < 0 || value > 100
            ? "Percentile value must be between 0 and 100"
            : null,
      };
    }
  }

  // Check if property is a custom trait and validate it
  if (isRelatedColumn(property) && isInlineTraitColumn(property.column)) {
    const { traitType, traitConfig, relationshipId } = property.column;

    if (!traitType) {
      return { property: "Trait aggregation type is required" };
    }

    // Narrowing with `isInlineTraitColumn` does not work correctly.
    // Makes typescript think `TraitType.Formula` is not assignable to `traitType`
    if ((traitType as TraitType) === TraitType.Formula && !relationshipId) {
      return { property: "Related model or event is required" };
    }

    if (isAggregationTraitConfig(traitType, traitConfig)) {
      if (!traitConfig.column) {
        return { property: "Column is required" };
      }
    }

    if (isCountDedupedTraitConfig(traitType, traitConfig)) {
      if (!traitConfig.toSelect) {
        return { property: "Column is required" };
      }
    }

    if (isOrderDedupedTraitConfig(traitType, traitConfig)) {
      if (!traitConfig.toSelect || !traitConfig.orderBy) {
        return {
          property: "Column is required",
        };
      }
    }
  }

  if (operator == null || value == null) {
    return {
      property: null,
      operator: operator == null ? requiredErrorMessage : null,
      value: value == null ? requiredErrorMessage : null,
    };
  }

  return {};
};

export const validateReferencePropertyFilter: Validator<
  ReferencedPropertyCondition
> = ({ property, valueFromColumn }) => {
  if (!property) {
    return {
      property: property == null ? requiredErrorMessage : null,
    };
  }

  if (!valueFromColumn) {
    return {
      property: null,
      valueFromColumn: valueFromColumn == null ? requiredErrorMessage : null,
    };
  }

  return {};
};

export const validateEventCondition: Validator<EventCondition> = (
  { eventModelId, value },
  options = { ignoreValue: false },
) => ({
  eventModelId: eventModelId == null ? requiredErrorMessage : null,
  value: value == null && !options?.ignoreValue ? requiredErrorMessage : null,
});

export const validateNumberOfCondition: Validator<NumberOfCondition> = ({
  relationshipId,
  value,
}) => ({
  relationshipId: relationshipId == null ? requiredErrorMessage : null,
  value: value == null ? requiredErrorMessage : null,
});

export const validateFunnelCondition: Validator<FunnelCondition> = ({
  eventModelId,
}) => {
  return { eventModelId: eventModelId != null ? null : requiredErrorMessage };
};

export const validateSegmentSetCondition: Validator<SegmentSetCondition> = ({
  modelId,
  includes,
}) => ({
  modelId: modelId == null ? requiredErrorMessage : null,
  includes: typeof includes !== "boolean" ? requiredErrorMessage : null,
});

export const validateWindowCondition: Validator<Window | null | undefined> = (
  window,
) => {
  if (window === null || window === undefined) {
    return {};
  }

  return validateDateRange({ timeType: window.timeType, value: window.value });
};
