import {
  CloseIcon,
  Column,
  IconButton,
  Row,
  Skeleton,
  SkeletonBox,
  Text,
} from "@hightouchio/ui";
import { FC, useState } from "react";
import { Card } from "src/components/card";
import type { Flow } from "src/pages/decision-engines/flows";
import { MessageRow } from "src/pages/decision-engines/inspector/components/message-row";
import { InteractionRow } from "src/pages/decision-engines/inspector/components/messages";

import { Drawer } from "src/components/drawer";
import {
  Interaction,
  InteractionHeader,
  useInteractions,
} from "src/pages/decision-engines/inspector/components/interaction";
import { useOutletContext } from "src/router";
import { OutletContext } from "../..";

export const MessageFeed = ({ flow }: { flow: Flow }) => {
  const [selectedInteraction, setSelectedInteraction] =
    useState<InteractionRow>();
  const flowMessages = flow.messages;
  const { demoDefinition } = useOutletContext<OutletContext>();

  const { interactions, isLoading } = useInteractions({
    flowId: flow.id,
    direction: "past",
    selectedUsers: [],
    demoDefinition,
    flowMessages,
  });

  return (
    <Card
      p={0}
      overflow="auto"
      flex="0 1 20%"
      h="100%"
      display={["none", "none", "none", "none", "flex"]}
    >
      {isLoading ? (
        <>
          <Skeleton isLoading>
            <SkeletonBox h="52px" />
            <SkeletonBox h="52px" />
            <SkeletonBox h="52px" />
            <SkeletonBox h="52px" />
            <SkeletonBox h="52px" />
            <SkeletonBox h="52px" />
          </Skeleton>
        </>
      ) : !interactions?.length ? (
        // eslint-disable-next-line react/jsx-no-undef
        <Text color="text.placeholder" m="auto">
          No actions
        </Text>
      ) : (
        interactions.map((interaction) => (
          <MessageRow
            px={2}
            key={interaction.interaction_id}
            interaction={interaction}
            flowMessages={flowMessages}
            isSelected={
              selectedInteraction?.interaction_id === interaction.interaction_id
            }
            onClick={() => {
              setSelectedInteraction(interaction);
            }}
          />
        ))
      )}
      {selectedInteraction && (
        <InteractionDrawer
          flow={flow}
          onClose={() => setSelectedInteraction(undefined)}
          interaction={selectedInteraction}
        />
      )}
    </Card>
  );
};

export const InteractionDrawer: FC<
  Readonly<{
    onClose: () => void;
    interaction: InteractionRow;
    flow: Flow;
  }>
> = ({ onClose, interaction, flow }) => {
  const flowMessage = flow.messages.find(
    (m) => m.message.id === interaction.action_features.message,
  );
  return (
    <Drawer isOpen onClose={onClose} size="lg">
      <Row
        gap={2}
        align="center"
        justify="space-between"
        p={6}
        borderBottom="1px"
        borderColor="base.border"
      >
        <InteractionHeader interaction={interaction} message={flowMessage} />
        <IconButton aria-label="Close" icon={CloseIcon} onClick={onClose} />
      </Row>
      <Column flex={1} py={6} overflow="auto">
        <Interaction
          message={flowMessage!}
          usersByPrimaryKey={{}}
          interaction={interaction}
          error=""
        />
      </Column>
    </Drawer>
  );
};
