import { Controller } from "react-hook-form";
import { Box, NumberInput } from "@hightouchio/ui";

export const NumberInputField = ({ name }) => {
  return (
    <Controller
      name={name}
      render={({ field, fieldState: { error } }) => (
        <Box
          as={NumberInput}
          width="90px"
          {...field}
          isInvalid={Boolean(error)}
          size="sm"
        />
      )}
    />
  );
};
