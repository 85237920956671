import { FC, useEffect, useState } from "react";

import {
  Button,
  ButtonGroup,
  ChakraModal,
  ChakraModalOverlay,
  ChakraModalContent,
  ChakraModalHeader,
  ChakraModalBody,
  ChakraModalFooter,
  Heading,
  FormField,
  RadioGroup,
  Radio,
} from "@hightouchio/ui";
import { PermissionedButton } from "src/components/permission";

type DeleteVersionModalProps = {
  isDefaultVersion?: boolean;
  isOpen: boolean;
  versionName: string;
  onClose: () => void;
  onSubmit: (deleteAllVersions: boolean) => Promise<void>;
};

export const DeleteVersionModal: FC<DeleteVersionModalProps> = ({
  isDefaultVersion = false,
  isOpen,
  versionName,
  onClose,
  onSubmit,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [deleteAll, setDeleteAll] = useState(
    isDefaultVersion ? "true" : "false",
  );

  useEffect(() => {
    setDeleteAll(isDefaultVersion ? "true" : "false");
  }, [isDefaultVersion]);

  const submit = async () => {
    setIsLoading(true);

    await onSubmit(deleteAll === "true");

    setIsLoading(false);
  };

  return (
    <ChakraModal
      closeOnEsc
      closeOnOverlayClick
      isCentered
      scrollBehavior="inside"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ChakraModalOverlay />
      <ChakraModalContent p={0} my="auto">
        <ChakraModalHeader
          p={6}
          borderBottom="1px solid"
          borderColor="base.border"
        >
          <Heading>Delete an event</Heading>
        </ChakraModalHeader>
        <ChakraModalBody mt={0} p={6}>
          <FormField
            label="Select an option"
            description="Should all versions of this event be deleted? This is not reversible."
          >
            <RadioGroup
              orientation="vertical"
              value={deleteAll}
              onChange={setDeleteAll}
            >
              <Radio
                isDisabled={isDefaultVersion}
                label={`Delete the version '${versionName}'`}
                value="false"
              />
              <Radio label="Delete all versions of this event" value="true" />
            </RadioGroup>
          </FormField>
        </ChakraModalBody>
        <ChakraModalFooter
          mt={0}
          px={6}
          py={4}
          borderTop="1px solid"
          borderColor="base.border"
        >
          <ButtonGroup>
            <Button isDisabled={isLoading} onClick={onClose}>
              Cancel
            </Button>
            <PermissionedButton
              permission={{
                v1: { resource: "workspace", grant: "update" },
                v2: {
                  resource: "workspace",
                  grant: "can_update",
                },
              }}
              isLoading={isLoading}
              variant="danger"
              onClick={submit}
            >
              Continue
            </PermissionedButton>
          </ButtonGroup>
        </ChakraModalFooter>
      </ChakraModalContent>
    </ChakraModal>
  );
};
