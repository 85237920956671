import { useCallback } from "react";

import slugify from "@sindresorhus/slugify";
import { customAlphabet } from "nanoid";
import { lowercase, numbers } from "nanoid-dictionary";
import { useQueryClient, QueryClient } from "react-query";

import { useGenerateResourceSlugQuery, SlugResourceType } from "src/graphql";

export { SlugResourceType };

const nanoid = customAlphabet(lowercase + numbers, 5);

export const generateSlug = (prefix: string) =>
  slugify(`${prefix}-${nanoid()}`);

export const useResourceSlug = (type: SlugResourceType) => {
  const client = useQueryClient();
  return {
    getSlug: useCallback(
      async (name: string) => getSlug(client, type, name),
      [type, client],
    ),
  };
};

export const useSlug = () => {
  const client = useQueryClient();
  return {
    getSlug: useCallback(
      async (resourceType: SlugResourceType, name: string) =>
        getSlug(client, resourceType, name),
      [client],
    ),
  };
};

async function getSlug(
  client: QueryClient,
  resourceType: SlugResourceType,
  proposedSlug: string,
) {
  const variables = {
    resourceType,
    slug: slugify(proposedSlug),
  };

  const response = await client.fetchQuery({
    queryFn: useGenerateResourceSlugQuery.fetcher(variables),
    queryKey: useGenerateResourceSlugQuery.getKey(variables),
    staleTime: 0,
    cacheTime: 0,
  });

  return response.generateResourceSlug;
}
