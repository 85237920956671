import type {
  Column,
  RawColumn,
  RelatedColumn,
  TransformedColumn,
} from "./column";
import type { RootCondition } from "./condition";

// TODO[splits-v2]: Delete once we migrate all customers to the new structure
/** @deprecated */
export interface LegacySplitGroup {
  groupValue: string;
  percentage: number;
}

export interface SplitGroup {
  // We *might* want to rename this to `columnValue` to match the DB; it's
  // arguably a bit more reflective of what it is (the value for this group
  // that gets injected as a column). If so, it needs to be reflected in the
  // SQL templates in splits.ts.
  groupValue: string;
  percentage: number;
  maxSize?: number | null;
  /**
   * Syncs that the split should be synced to. If this is omitted or null,
   * e.g. in previews or possibly legacy structures (TBD), this means
   * syncing to all syncs with a matching segment_id.
   */
  destinationInstanceIds?: number[] | null;
}

export interface AudienceSplit {
  id?: string | undefined;
  friendly_name: string;
  column_value?: string;
  percentage: number;
  destination_instance_ids: number[];
  is_holdout_group: boolean;
}

export enum SplitSamplingType {
  NonStratified = "non-stratified",
  Stratified = "stratified",
}

export interface SplitTestDefinition {
  groupColumnName: string;
  samplingType: "non-stratified" | "stratified";
  stratificationVariables: Column[];
}

export interface NewSplitTestDefinition {
  groupColumnName: string;
  samplingType: "non-stratified" | "stratified";
  stratificationVariables: Column[];
  splits: AudienceSplit[];
}

// Allows us to put a cap on the size of the audience. Requires a column to
// sort by, since unordered limits might be unstable and thus aren't useful.
export interface SizeCap {
  limit: number;
  orderByProperty: string | RawColumn | RelatedColumn | TransformedColumn;
  // If true, take the bottom instead of the top (i.e. sort ASC instead of DESC).
  bottom: boolean;
}

export interface VisualQueryFilter {
  // All the conditions must evaluate to `true` for a row to be included.
  conditions: RootCondition[];

  limit?: number;

  // Allows us to put a cap on the size of the audience. Requires a column to
  // sort by, since unordered limits might be unstable and thus aren't useful.
  sizeCap?: SizeCap;

  additionalColumns?: {
    column: RelatedColumn | TransformedColumn;
    alias: string;
  }[];

  splitTestDefinition?: SplitTestDefinition;
}
