import { useMemo } from "react";
import { useUser } from "src/contexts/user-context";
import { useOrganizationPermissionsQuery } from "src/graphql";
import { useResourcePermission } from "./use-resource-permission";

export function useOrganizationPermissions(): {
  isWorkspaceAdmin: (workspaceId: string) => boolean;
  isOrganizationAdmin: boolean;
  isLoading: boolean;
} {
  const { user, isLoading: isUserLoading } = useUser();

  const {
    data: { workspace_members, organization_members } = {},
    isLoading: isOrgPermissionsLoading,
  } = useOrganizationPermissionsQuery(
    { userId: user?.id?.toString() ?? "" },
    {
      enabled: Boolean(user),
    },
  );

  // This is only used to tell whether the user is an upgraded impersonator
  const {
    isPermitted: isWorkspaceAdmin,
    isLoading: isWorkspacePermissionsLoading,
  } = useResourcePermission({
    v2: { resource: "workspace", grant: "can_update" },
  });

  const isLoading =
    isUserLoading || isOrgPermissionsLoading || isWorkspacePermissionsLoading;

  const defaultPermissions = {
    isWorkspaceAdmin: (_workspaceId: string) => false,
    isOrganizationAdmin: false,
    isLoading,
  };

  return useMemo(() => {
    if (!user || isLoading) {
      return defaultPermissions;
    }

    const workspaces = workspace_members
      ?.filter((m) => m.is_workspace_admin)
      ?.map((m) => String(m.workspace_id));

    if (user.is_impersonating && !isWorkspaceAdmin) {
      return defaultPermissions;
    }

    if (user.is_impersonating) {
      return {
        isWorkspaceAdmin: (_workspaceId: string) => true,
        isOrganizationAdmin: true,
        isLoading,
      };
    }

    const isOrganizationAdmin = Boolean(
      organization_members?.some(
        (m) =>
          String(m.organization_id) === String(user?.organization?.id) &&
          m.is_organization_admin,
      ),
    );
    return {
      isWorkspaceAdmin: (workspaceId: string) =>
        Boolean(workspaces?.includes(String(workspaceId))),
      isOrganizationAdmin,
      isLoading,
    };
  }, [
    workspace_members,
    organization_members,
    user,
    isLoading,
    isWorkspaceAdmin,
  ]);
}

export type OrganizationPermissionInput =
  | {
      resource: "organization";
    }
  | {
      resource: "workspace";
      id: string;
    };

export function useOrganizationPermission(
  input: OrganizationPermissionInput | undefined,
): boolean {
  const { isOrganizationAdmin, isWorkspaceAdmin } =
    useOrganizationPermissions();

  if (!input) return false;

  switch (input.resource) {
    case "organization":
      return isOrganizationAdmin;
    case "workspace":
      return Boolean(input.id) && isWorkspaceAdmin(input.id); // all organization admins are already also workspace admins
    default:
      return false;
  }
}
