import {
  type BooleanOperand,
  BooleanType,
  type FormkitBoolean,
  type FormkitNode,
  ModifierType,
  NodeType,
  ReferenceType,
  isFormkitReference,
  isGraphQLReference,
  isStateReference,
} from "../../api";

export type RelationshipNode = {
  /**
   *  Readable name of the node
   */
  heading?: string;
  /**
   *  Children keys
   */
  children: Set<string>;
  /**
   *  Ancestor keys
   */
  ancestors: Set<string>;
};

export type RelationshipHierarchy = {
  [key: string]: RelationshipNode;
};

function getAncestorKeysFromFormkitReference(
  operand: FormkitBoolean | BooleanOperand,
): string[] {
  if (
    typeof operand === "string" ||
    typeof operand === "number" ||
    typeof operand === "boolean"
  ) {
    // These operand types have no parent key
    return [];
  }

  if (operand.type === BooleanType.Unary) {
    return getAncestorKeysFromFormkitReference(operand.operand);
  }

  if (operand.type === BooleanType.Binary) {
    return [
      ...getAncestorKeysFromFormkitReference(operand.leftOperand),
      ...getAncestorKeysFromFormkitReference(operand.rightOperand),
    ];
  }

  if (operand.type === ReferenceType.State) {
    return [operand.key];
  }

  if (operand.type === ReferenceType.GraphQL && operand.variables) {
    // TODO(samuel): Should this recurse or will it always be a flat object?
    // The type `GraphQLVariablesValue` is recursive, so this could be a problem.
    const stateReferenceVariables = Object.entries(operand.variables)
      .filter(([_, variable]) => isStateReference(variable))
      .map(([key]) => key);

    return stateReferenceVariables;
  }

  return [];
}

function initializeKeyInRelationshipHierarchy(
  hierarchy: RelationshipHierarchy,
  key: string,
  heading?: string,
) {
  if (!hierarchy[key]) {
    hierarchy[key] = {
      heading,
      children: new Set(),
      ancestors: new Set(),
    };
  } else {
    hierarchy[key].heading = heading;
  }
}

/**
 * Recursively build a relationship hierarchy with both children and ancestors from the FormkitNode structure.
 *
 * @param node - The recursive form structure.
 * @param hierarchy - The relationship hierarchy being constructed.
 * @param ancestorKeys - The ancestor node key (if any).
 */
export const buildRelationshipHierarchy = ({
  node,
  hierarchy = {},
  ancestorKeys = [],
  heading,
}: {
  node: FormkitNode;
  hierarchy?: RelationshipHierarchy;
  ancestorKeys?: string[];
  heading?: string;
}): RelationshipHierarchy => {
  if (node.type === NodeType.Component) {
    const currentKey = node.key;

    initializeKeyInRelationshipHierarchy(hierarchy, currentKey, heading);

    ancestorKeys.forEach((parentKey) => {
      initializeKeyInRelationshipHierarchy(hierarchy, parentKey);
      hierarchy[currentKey]!.ancestors.add(parentKey);
      hierarchy[parentKey]!.children.add(currentKey);
    });

    if (node.props) {
      Object.values(node.props).forEach((prop) => {
        if (isFormkitReference(prop)) {
          const reference = prop;
          if (isStateReference(reference)) {
            initializeKeyInRelationshipHierarchy(hierarchy, reference.key);
            hierarchy[currentKey]!.ancestors.add(reference.key);
            hierarchy[reference.key]!.children.add(currentKey);
          } else if (isGraphQLReference(reference) && reference.variables) {
            getAncestorKeysFromFormkitReference(reference).forEach(
              (childKey) => {
                initializeKeyInRelationshipHierarchy(hierarchy, childKey);
                hierarchy[currentKey]!.children.add(childKey);
                hierarchy[childKey]!.ancestors.add(currentKey);
              },
            );
          }
        }
      });
    }
  } else if (node.type === NodeType.Modifier) {
    let parentKeysFromModifier = ancestorKeys;

    if (node.modifier === ModifierType.Show) {
      parentKeysFromModifier = getAncestorKeysFromFormkitReference(
        node.condition,
      );
    } else if (
      node.modifier === ModifierType.AsyncReference &&
      node.handler.variables
    ) {
      parentKeysFromModifier = Object.keys(node.handler.variables);
    }

    node.children.forEach((child) =>
      buildRelationshipHierarchy({
        node: child,
        hierarchy,
        ancestorKeys: parentKeysFromModifier,
      }),
    );
  } else if (node.type === NodeType.Layout && node.children) {
    node.children.forEach((child) =>
      buildRelationshipHierarchy({
        node: child,
        hierarchy,
        ancestorKeys,
        heading: "heading" in node ? node.heading : heading,
      }),
    );
  }

  return hierarchy;
};
