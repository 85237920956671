import {
  AudienceAggregationType,
  PerUserAggregationType,
  exhaustiveCheck,
} from "src/types/visual";

import { AggregationOption } from "./constants";

export { getAggregationConfiguration } from "@hightouch/lib/query/visual/util";

export const mapAggregationConfigurationToConfigurationOption = ({
  aggregation,
  audienceAggregation,
}: {
  aggregation: PerUserAggregationType;
  audienceAggregation: AudienceAggregationType;
}): AggregationOption | null => {
  switch (aggregation) {
    case PerUserAggregationType.Count:
      if (audienceAggregation === AudienceAggregationType.Sum) {
        return AggregationOption.Count;
      }
      break;
    case PerUserAggregationType.CountDistinct:
      if (audienceAggregation === AudienceAggregationType.None) {
        return AggregationOption.CountDistinctProperty;
      }
      break;
    case PerUserAggregationType.Sum:
      if (audienceAggregation === AudienceAggregationType.Sum) {
        return AggregationOption.SumOfProperty;
      } else if (audienceAggregation === AudienceAggregationType.Average) {
        return AggregationOption.SumOfPropertyPerUser;
      }
      break;
    case PerUserAggregationType.UniqueUsers:
      if (
        audienceAggregation === AudienceAggregationType.Sum ||
        audienceAggregation === AudienceAggregationType.Cumulative
      ) {
        return AggregationOption.UniqueUsers;
      } else if (
        audienceAggregation === AudienceAggregationType.PercentOfAudience
      ) {
        return AggregationOption.PercentOfAudience;
      }
      break;
    case PerUserAggregationType.Average:
      if (audienceAggregation === AudienceAggregationType.None) {
        return AggregationOption.AverageOfProperty;
      }
      if (audienceAggregation === AudienceAggregationType.Average) {
        return AggregationOption.AverageOfPropertyPerUser;
      }
      break;
    default:
      exhaustiveCheck(aggregation);
  }

  // Should never reach here
  return null;
};
