// Based on: https://github.com/aboveyunhai/chakra-dayzed-datepicker/
import React, { useCallback, useMemo } from "react";

import { Box, Column, Row, Text } from "@hightouchio/ui";
import { useDayzed, Props as DayzedHookProps } from "dayzed";

import { CalendarConfigs, DatepickerProps } from "../utils/common-types";
import { ArrowKeysReact } from "../utils/react-keys-arrow";
import { DatepickerBackBtns, DatepickerForwardBtns } from "./date-nav-buttons";
import { DayOfMonth } from "./day-of-month";

export interface CalendarPanelProps extends DatepickerProps {
  dayzedHookProps: Omit<DayzedHookProps, "children" | "render" | "selected">;
  configs: CalendarConfigs;
  selected: (Date | null)[];
  disabledDates?: Set<number>;
  onMouseEnterHighlight?: (date: Date) => void;
  isInRange?: (date: Date) => boolean | null;
}

export const CalendarPanel: React.FC<CalendarPanelProps> = ({
  dayzedHookProps,
  configs,
  selected,
  disabledDates,
  onMouseEnterHighlight,
  isInRange,
}) => {
  const renderProps = useDayzed(dayzedHookProps);
  const { calendars, getBackProps, getForwardProps } = renderProps;

  const weekdayNames = useMemo(() => {
    const firstDayOfWeek = configs.firstDayOfWeek;
    const dayNames = configs.dayNames;
    if (firstDayOfWeek && firstDayOfWeek > 0) {
      return configs.dayNames
        .slice(firstDayOfWeek, dayNames.length)
        .concat(dayNames.slice(0, firstDayOfWeek));
    }
    return dayNames;
  }, [configs.firstDayOfWeek, configs.dayNames]);

  // looking for a useRef() approach to replace it
  const getKeyOffset = useCallback((num: number) => {
    const e = document.activeElement;
    const buttons = document.querySelectorAll("button");
    buttons.forEach((el, i) => {
      const newNodeKey = i + num;
      if (el === e) {
        if (newNodeKey <= buttons.length - 1 && newNodeKey >= 0) {
          buttons[newNodeKey]?.focus();
        } else {
          buttons[0]?.focus();
        }
      }
    });
  }, []);

  const arrowKeysReact = new ArrowKeysReact({
    left: () => {
      getKeyOffset(-1);
    },
    right: () => {
      getKeyOffset(1);
    },
    up: () => {
      getKeyOffset(-7);
    },
    down: () => {
      getKeyOffset(7);
    },
  });

  if (calendars.length <= 0) {
    return null;
  }

  return (
    <Row px={2} {...arrowKeysReact.getEvents()}>
      {calendars.map((calendar, calendarIdx) => {
        return (
          <Column key={calendarIdx} align="center" gap={4} height="100%" px={2}>
            <Row align="center" justify="space-between" width="100%">
              <DatepickerBackBtns
                calendars={calendars}
                getBackProps={getBackProps}
              />
              <Text size="sm" fontWeight="semibold">
                {configs.monthNames[calendar.month]} {calendar.year}
              </Text>
              <DatepickerForwardBtns
                calendars={calendars}
                getForwardProps={getForwardProps}
              />
            </Row>

            <Box
              display="grid"
              gridTemplateColumns="repeat(7, min-content)"
              rowGap={1}
            >
              {weekdayNames.map((day, dayIdx) => (
                <Row key={dayIdx} justify="center">
                  <Text color="text.secondary" fontWeight="medium" size="sm">
                    {day}
                  </Text>
                </Row>
              ))}
              {calendar.weeks.map((week, weekIdx) => {
                return week.map((dateObj, index) => {
                  const key = `${calendar.month}-${calendar.year}-${weekIdx}-${index}`;
                  if (!dateObj) return <Box key={key} />;
                  const { date } = dateObj;

                  const isFirstSelectedDate =
                    dateObj.selected &&
                    selected?.[0]?.getTime() === dateObj.date.getTime();

                  const isLastSelectedDate =
                    dateObj.selected &&
                    selected?.[1]?.getTime() === dateObj.date.getTime();

                  return (
                    <DayOfMonth
                      key={key}
                      dateObj={dateObj}
                      renderProps={renderProps}
                      isInRange={isInRange && isInRange(date)}
                      isFirstSelectedDate={isFirstSelectedDate}
                      isLastSelectedDate={isLastSelectedDate}
                      disabledDates={disabledDates}
                      onMouseEnter={() => {
                        if (onMouseEnterHighlight) onMouseEnterHighlight(date);
                      }}
                    />
                  );
                });
              })}
            </Box>
          </Column>
        );
      })}
    </Row>
  );
};
