import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const CopilotWithGradientIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        fillRule="evenodd"
        d="M4 12a8 8 0 0 0 8 8v2C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10h-2a8 8 0 1 0-16 0Z"
        clipRule="evenodd"
        fill="url(#path-1)"
      />
      <path
        fillRule="evenodd"
        d="M20 12.002V12h2v.002a1 1 0 0 1-2 0Zm.7 2.517a1 1 0 0 1 .542 1.307l-.002.004a1 1 0 0 1-1.847-.766l.001-.004a1 1 0 0 1 1.307-.54Zm-1.627 3.136a1 1 0 0 1 0 1.414l-.004.004a1 1 0 1 1-1.413-1.415l.002-.003a1 1 0 0 1 1.415 0Zm-2.702 2.278a1 1 0 0 1-.54 1.307l-.005.002a1 1 0 1 1-.766-1.848l.004-.001a1 1 0 0 1 1.307.54ZM12 20h.002a1 1 0 0 1 0 2H12v-2Z"
        clipRule="evenodd"
        fill="url(#path-2)"
      />
      <path d="M7 12a5 5 0 0 0 5 5V7a5 5 0 0 0-5 5Z" fill="url(#path-3)" />

      <defs>
        <linearGradient
          id="path-1"
          x1="6.25008"
          y1="18.3334"
          x2="16.6667"
          y2="4.16669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BC85FA" />
          <stop offset="1" stopColor="#8CDBE9" />
        </linearGradient>
        <linearGradient
          id="path-2"
          x1="6.25008"
          y1="18.3334"
          x2="16.6667"
          y2="4.16669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BC85FA" />
          <stop offset="1" stopColor="#8CDBE9" />
        </linearGradient>
        <linearGradient
          id="path-3"
          x1="6.25008"
          y1="18.3334"
          x2="16.6667"
          y2="4.16669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BC85FA" />
          <stop offset="1" stopColor="#8CDBE9" />
        </linearGradient>
      </defs>
    </Icon>
  );
};
