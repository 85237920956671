import { FC, useCallback, useMemo } from "react";

import {
  Badge,
  Column,
  InformationIcon,
  Row,
  SparkleIcon,
  Text,
  Tooltip,
} from "@hightouchio/ui";
import orderBy from "lodash/orderBy";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import placeholderSource from "src/components/permission/source.svg";
import { ResourceSelect } from "src/components/resource-select";
import { TableColumn } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";

import { useParentModelSelect } from "./use-parent-model-select";

export type ParentModelOption = {
  id: string;
  name: string;
  query_type: string | null;
  created_at: string | null;
  updated_at: string | null;
  connection: {
    id: string;
    audience_snapshotting_enabled: boolean;
    definition: {
      icon: string;
    };
  } | null;
  created_by_user: {
    name: string;
  } | null;
  updated_by_user: {
    name: string;
  } | null;
};

interface Props {
  disableSourcesWithoutAudienceSnapshotting?: boolean;
  isOptionAvailable?: (id: string) => boolean;
  onSelect: (ParentModel: ParentModelOption) => void;
}

export const ParentModelSelect: FC<Readonly<Props>> = ({
  disableSourcesWithoutAudienceSnapshotting = false,
  isOptionAvailable,
  onSelect,
}) => {
  const {
    search,
    error,
    onSearch,
    options: parents,
    isLoading,
  } = useParentModelSelect();

  const disabledRows = useCallback(
    (row: ParentModelOption) => {
      if (disableSourcesWithoutAudienceSnapshotting) {
        return !row?.connection?.audience_snapshotting_enabled;
      }

      return false;
    },
    [disableSourcesWithoutAudienceSnapshotting],
  );

  const sortedData = disableSourcesWithoutAudienceSnapshotting
    ? orderBy(
        parents,
        ["connection.audience_snapshotting_enabled", "name"],
        ["desc", "asc"],
      )
    : orderBy(parents, ["name"], ["asc"]);

  const columns = useMemo(
    (): TableColumn[] => [
      {
        name: "Name",
        cell: ({ name, connection: source, matchboosting_enabled }) => {
          return (
            <Row align="center" gap={2} overflow="hidden">
              <IntegrationIcon
                name={source?.definition?.name ?? "Private source"}
                src={source?.definition?.icon ?? placeholderSource}
              />

              <Column gap={1}>
                <Text isTruncated fontWeight="medium">
                  {name ?? "Private source"}
                </Text>

                {matchboosting_enabled && (
                  <Badge size="sm" icon={SparkleIcon} iconColor="warning.400">
                    Boosted
                  </Badge>
                )}
              </Column>

              {disableSourcesWithoutAudienceSnapshotting &&
                !source?.audience_snapshotting_enabled && (
                  <Tooltip message="Please turn on audience snapshotting in this parent model's source settings.">
                    <InformationIcon />
                  </Tooltip>
                )}
            </Row>
          );
        },
      },
      {
        ...LastUpdatedColumn,
        max: "max-content",
        breakpoint: "sm",
      },
    ],
    [parents],
  );

  return (
    <ResourceSelect
      columns={columns}
      data={sortedData}
      disabled={(row) =>
        disabledRows(row) ||
        Boolean(isOptionAvailable && !isOptionAvailable(row.id))
      }
      error={Boolean(error)}
      label="parent model"
      loading={isLoading}
      search={search}
      onSearch={onSearch}
      onSelect={onSelect}
    />
  );
};
