import { FC, useEffect, useState } from "react";

import { PublishIcon } from "@hightouchio/ui";

import { updatePylonPosition } from "src/lib/pylon";

import { PermissionedIconButton } from "src/components/permission";
import { ResourcePermissionInput } from "src/components/permission/use-resource-permission";
import { Deployment } from "./common";
import { ModelDeploymentWizard } from "./model-deployment-wizard";
import { SyncDeploymentWizard } from "./sync-deployment-wizard";

interface DeployButtonProps {
  isDisabled: boolean;
  deployment: Deployment;
  permission: ResourcePermissionInput<"model", "model">;
}

export const DeployButton: FC<DeployButtonProps> = ({
  deployment,
  isDisabled,
  permission,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      updatePylonPosition(672);
    } else {
      updatePylonPosition(0);
    }
    return () => {
      updatePylonPosition(0);
    };
  }, [isOpen]);

  const resourceName = deployment.resourceName.toLowerCase();

  return (
    <>
      <PermissionedIconButton
        permission={permission}
        tooltip={
          deployment.isDraft
            ? `You cannot deploy a draft ${resourceName}`
            : `Deploy your ${resourceName}`
        }
        variant="secondary"
        aria-label="deploy"
        icon={PublishIcon}
        onClick={() => setIsOpen(true)}
        isDisabled={isDisabled}
      />
      {deployment.resourceType === "segments" ? (
        <ModelDeploymentWizard
          isOpen={isOpen}
          deployment={deployment}
          onClose={onClose}
        />
      ) : (
        <SyncDeploymentWizard
          isOpen={isOpen}
          deployment={deployment}
          onClose={onClose}
        />
      )}
    </>
  );
};
