import {
  LatestSyncStatusQuery,
  SyncHealthQuery,
  SyncMetadataFragment,
} from "src/graphql";
import { MonitorStatus } from "@hightouch/lib/resource-monitoring/types";

export interface SyncHealthFilter {
  sourceTypes: string[];
  destinationTypes: string[];
  sourceIds: string[];
  destinationIds: string[];
}

export type EnrichedSyncMetric<T> = T & {
  sourceName?: string;
  destinationName?: string;
  sourceType?: string;
  destinationType?: string;
  sourceIcon?: string;
  destinationIcon?: string;
  sourceFriendlyType?: string;
  destinationFriendlyType?: string;
  sourceDocs?: string;
  destinationDocs?: string;
};

/**
 * Takes a sync metric and applies a transformation to it. Used for 'zeroing out' metrics that don't match the filter.
 * We need to zero out metrics rather than removing them entirely because we need to fill the chart with empty buckets
 */
function filterSyncMetric<T>(
  metric: T & {
    destination_id?: any;
    source_id?: any;
    source_type?: string;
    destinationType?: string;
  },
  filter: SyncHealthFilter,
  fn: (d: T) => T,
): T {
  let match = false;
  // We allow 'empty buckets' to pass through
  // as they fill the chart
  if (!metric.source_id || !metric.destination_id) match = true;

  // If we have a filter, we only allow metrics that match the filter
  if (
    filter.sourceIds.length > 0 &&
    !filter.sourceIds.includes(metric.source_id)
  )
    match = true;
  if (
    filter.destinationIds.length > 0 &&
    !filter.destinationIds.includes(metric.destination_id)
  )
    match = true;
  if (
    filter.sourceTypes.length > 0 &&
    metric.source_type &&
    !filter.sourceTypes.includes(metric.source_type)
  )
    match = true;
  if (
    filter.destinationTypes.length > 0 &&
    metric.destinationType &&
    !filter.destinationTypes.includes(metric.destinationType)
  )
    match = true;

  if (match) {
    return fn(metric);
  }
  return metric;
}

export function enrichSyncs<T extends SyncMetadataFragment>(
  syncs: T[],
  sourceDefinitions: {
    name: string;
    type: string;
    icon: string;
    docs: string;
  }[],
  destinationDefinitions: {
    name: string;
    type: string;
    icon: string;
    docs: string;
  }[],
): EnrichedSyncMetric<T>[] {
  const sourceLookup: Record<
    string,
    { friendlyType: string; icon?: string; docs: string }
  > = {};
  const destinationLookup: Record<
    string,
    { friendlyType: string; icon?: string; docs: string }
  > = {};
  sourceDefinitions.forEach((c) => {
    sourceLookup[c.type] = {
      friendlyType: c.name,
      icon: c.icon,
      docs: c.docs,
    };
  });

  sourceLookup["sample-data"] = {
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/a81e5fb1a004a6dd9925bc5336d9b953f24249b7-344x344.png",
    friendlyType: "Sample Data",
    docs: `${import.meta.env.VITE_DOCS_URL}/getting-started/concepts#sources`,
  };

  destinationDefinitions.forEach((d) => {
    destinationLookup[d.type] = {
      friendlyType: d.name,
      icon: d.icon,
      docs: d.docs,
    };
  });

  const enriched: EnrichedSyncMetric<T>[] = [];
  for (const sync of syncs) {
    const connectionType = sync.segment?.connection?.type;
    const destinationType = sync.destination?.type;
    if (!connectionType || !destinationType) {
      continue;
    }

    const sourceDefinition = sourceLookup[connectionType];
    const destinationDefinition = destinationLookup[destinationType];
    enriched.push({
      ...sync,
      sourceFriendlyType: sourceDefinition?.friendlyType,
      sourceIcon: sourceDefinition?.icon,
      destinationFriendlyType: destinationDefinition?.friendlyType,
      destinationIcon: destinationDefinition?.icon,
      destinationName:
        sync.destination?.name ||
        destinationDefinition?.friendlyType ||
        destinationType,
      sourceName:
        sync.segment?.connection?.name ||
        sourceDefinition?.friendlyType ||
        connectionType,
      sourceType: connectionType,
      destinationType: destinationType,
      sourceDocs: sourceDefinition?.docs,
      destinationDocs: destinationDefinition?.docs,
    });
  }

  return enriched;
}

export function transformSyncAttempts(
  data: EnrichedSyncMetric<
    NonNullable<SyncHealthQuery["dashboardSyncAttempts"]>[0]
  >[],
  filter: SyncHealthFilter,
): {
  time_bucket: string;
  successful_operations: number;
  failed_operations: number;
}[] {
  if (!data) {
    return [];
  }

  const transformed: {
    time_bucket: string;
    successful_operations: number;
    failed_operations: number;
  }[] = [];
  for (const sr of data.map((metric) =>
    filterSyncMetric(metric, filter, (d) => ({
      ...d,
      successful_operations: 0,
      failed_operations: 0,
    })),
  )) {
    // First, we check if transformed already containst the time bucket
    const existing = transformed.find((t) => t.time_bucket === sr.time_bucket);
    if (!existing) {
      transformed.push({
        time_bucket: sr.time_bucket,
        successful_operations: Number(sr.successful_operations),
        failed_operations: Number(sr.failed_operations),
      });
    } else {
      existing.successful_operations += Number(sr.successful_operations);
      existing.failed_operations += Number(sr.failed_operations);
      transformed[
        transformed.findIndex((t) => t.time_bucket === sr.time_bucket)
      ] = existing;
    }
  }
  return transformed;
}

export function transformSyncRequests(
  data: EnrichedSyncMetric<
    NonNullable<SyncHealthQuery["dashboardSyncRequests"]>[0]
  >[],
  filter: SyncHealthFilter,
): {
  time_bucket: string;
  warning: number;
  succeeded: number;
  failed: number;
}[] {
  if (!data) {
    return [];
  }

  const transformed: {
    time_bucket: string;
    warning: number;
    succeeded: number;
    failed: number;
  }[] = [];
  for (const sr of data.map((metric) =>
    filterSyncMetric(metric, filter, (d) => ({
      ...d,
      counts: { warning: [], succeeded: [], failed: [] },
    })),
  )) {
    // First, we check if transformed already containst the time bucket
    const existing = transformed.find((t) => t.time_bucket === sr.time_bucket);
    if (!existing) {
      transformed.push({
        time_bucket: sr.time_bucket,
        warning: sr.counts.warning.length,
        succeeded: sr.counts.succeeded.length,
        failed: sr.counts.failed.length,
      });
    } else {
      existing.warning += sr.counts.warning.length;
      existing.succeeded += sr.counts.succeeded.length;
      existing.failed += sr.counts.failed.length;
      transformed[
        transformed.findIndex((t) => t.time_bucket === sr.time_bucket)
      ] = existing;
    }
  }
  return transformed;
}

export interface ResourceOperationsItem {
  name: string;
  type: string;
  friendlyType: string;
  icon: string;
  succeeded: number;
  warning: number;
  failed: number;

  health_warning: number;
  healthy: number;
  unhealthy: number;
}

// We need by resource _type_ by resource _id_ here
export interface ResourceOperations {
  source: Record<string, Record<string, ResourceOperationsItem>>;
  destination: Record<string, Record<string, ResourceOperationsItem>>;
}

export function transformSyncs(
  data: EnrichedSyncMetric<NonNullable<LatestSyncStatusQuery["syncs"]>[0]>[],
): ResourceOperations {
  if (!data) {
    return { source: {}, destination: {} };
  }

  // First roll up sync requests by resource id
  const requestsBySourceId: Record<string, ResourceOperationsItem> = {};
  const requestsByDestinationId: Record<string, ResourceOperationsItem> = {};

  for (const sr of data) {
    const destinationId = sr.destination?.id;
    const sourceId = sr.segment?.connection?.id;

    if (!destinationId || !sourceId) continue;
    const warning = sr.status === "warning" ? 1 : 0;
    const succeeded = sr.status === "success" ? 1 : 0;
    const failed = sr.status === "failed" ? 1 : 0;

    const healthy = sr.health === MonitorStatus.Healthy ? 1 : 0;
    const unhealthy = sr.health === MonitorStatus.Unhealthy ? 1 : 0;
    const health_warning = sr.health === MonitorStatus.Warning ? 1 : 0;

    const destinationName = sr.destinationName || "unknown";
    const sourceName = sr.sourceName || "unknown";
    const destinationType = sr.destinationType || "unknown";
    const sourceType = sr.sourceType || "unknown";
    const sourceIcon = sr.sourceIcon || "unknown";
    const destinationIcon = sr.destinationIcon || "unknown";
    const destinationFriendlyType = sr.destinationFriendlyType || "unknown";
    const sourceFriendlyType = sr.sourceFriendlyType || "unknown";

    if (requestsByDestinationId[destinationId]) {
      requestsByDestinationId[destinationId] = {
        friendlyType: destinationFriendlyType,
        icon: destinationIcon,
        name: destinationName,
        type: destinationType,
        succeeded:
          (requestsByDestinationId[destinationId]?.succeeded || 0) + succeeded,
        failed: (requestsByDestinationId[destinationId]?.failed || 0) + failed,
        warning:
          (requestsByDestinationId[destinationId]?.warning || 0) + warning,
        healthy:
          (requestsByDestinationId[destinationId]?.healthy || 0) + healthy,
        unhealthy:
          (requestsByDestinationId[destinationId]?.unhealthy || 0) + unhealthy,
        health_warning:
          (requestsByDestinationId[destinationId]?.health_warning || 0) +
          health_warning,
      };
    } else {
      requestsByDestinationId[destinationId] = {
        friendlyType: destinationFriendlyType,
        icon: destinationIcon,
        succeeded,
        failed,
        warning,
        health_warning,
        healthy,
        unhealthy,
        name: destinationName,
        type: destinationType,
      };
    }

    if (requestsBySourceId[sourceId]) {
      requestsBySourceId[sourceId] = {
        friendlyType: sourceFriendlyType,
        icon: sourceIcon,
        name: sourceName,
        type: sourceType,
        succeeded: (requestsBySourceId[sourceId]?.succeeded || 0) + succeeded,
        failed: (requestsBySourceId[sourceId]?.failed || 0) + failed,
        warning: (requestsBySourceId[sourceId]?.warning || 0) + warning,

        healthy: (requestsBySourceId[sourceId]?.healthy || 0) + healthy,
        unhealthy: (requestsBySourceId[sourceId]?.unhealthy || 0) + unhealthy,
        health_warning:
          (requestsBySourceId[sourceId]?.health_warning || 0) + health_warning,
      };
    } else {
      requestsBySourceId[sourceId] = {
        friendlyType: sourceFriendlyType,
        icon: sourceIcon,
        succeeded,
        failed,
        warning,
        health_warning,
        healthy,
        unhealthy,
        name: sourceName,
        type: sourceType,
      };
    }
  }

  const requestsByDestinationType: Record<
    string,
    Record<string, ResourceOperationsItem>
  > = {};
  const requestsBySourceType: Record<
    string,
    Record<string, ResourceOperationsItem>
  > = {};

  for (const [id, item] of Object.entries(requestsByDestinationId)) {
    if (!requestsByDestinationType[item.type]) {
      requestsByDestinationType[item.type] = {};
    }
    requestsByDestinationType[item.type]![id] = item;
  }

  for (const [id, item] of Object.entries(requestsBySourceId)) {
    if (!requestsBySourceType[item.type]) {
      requestsBySourceType[item.type] = {};
    }
    requestsBySourceType[item.type]![id] = item;
  }

  return {
    source: requestsBySourceType,
    destination: requestsByDestinationType,
  };
}
