import { FC, ReactNode } from "react";

import { Text, Column, Row } from "@hightouchio/ui";

import { IntegrationIcon } from "src/components/integrations/integration-icon";

type Props = {
  source: { definition: { icon: string; name: string } };
  table: string;
  actions?: ReactNode;
};

export const SampleModelQuery: FC<Readonly<Props>> = ({
  source,
  table,
  actions,
}) => (
  <Column
    border="1px"
    borderColor="base.border"
    borderRadius="md"
    overflow="hidden"
    minWidth={0}
    flex={1}
  >
    <Row
      align="center"
      p={4}
      borderBottom="1px"
      borderColor="base.border"
      gap={4}
      justify="space-between"
    >
      <Row align="center" gap={2}>
        <IntegrationIcon
          src={source.definition.icon}
          name={source.definition.name}
        />
        <Text fontWeight="medium" size="lg">
          {source.definition.name}
        </Text>
      </Row>
      {actions}
    </Row>

    <Column p={4}>
      <Text>
        Selecting all rows from <Text fontWeight="semibold">{table}</Text>
      </Text>
    </Column>
  </Column>
);
