import { Navigate, RouteObject } from "src/router";
import { PermissionedRoute } from "src/components/router/permissioned-route";
import * as Create from "./create-destination";
import * as Destination from "./destination";
import * as Destinations from "./destinations";
import * as Grants from "./destination/grants";
import * as Syncs from "./destination/syncs";
import * as DataExtraction from "./destination/data-extraction";
import * as Linking from "./destination/linking";
import * as Configuration from "./destination/configuration";

export const routes: Array<RouteObject> = [
  {
    path: "destinations",
    element: <Destinations.Element />,
  },
  {
    path: "destinations/new",
    element: (
      <PermissionedRoute
        permission={{
          v2: { resource: "destination", grant: "can_create" },
        }}
        redirect="/destinations"
      >
        <Create.Element />
      </PermissionedRoute>
    ),
  },
  {
    path: "destinations/:destination_id/*",
    element: <Destination.Element />,
    children: [
      {
        index: true,
        element: <Navigate to="configuration" replace />,
      },
      {
        path: "configuration",
        element: <Configuration.Element />,
      },
      {
        path: "syncs",
        element: <Syncs.Element />,
      },
      {
        path: "grants",
        element: <Grants.Element />,
      },
      {
        path: "data-extraction",
        element: <DataExtraction.Element />,
      },
      {
        path: "linking",
        element: <Linking.Element />,
      },
    ],
  },
];
