import {
  Box,
  BoxProps,
  Button,
  DeleteIcon,
  Menu,
  MenuActionsButton,
  MenuItem,
  MenuList,
  Row,
  useToast,
  Text,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { useNavigate } from "src/router";

import { useUser } from "src/contexts/user-context";
import {
  DraftOperation,
  DraftsQuery,
  ResourceToPermission,
  useDeleteCreateDraftMutation,
  useDeleteUpdateDraftMutation,
} from "src/graphql";
import { formatDatetime } from "src/utils/time";

import { DraftBadge } from "./draft-badge";
import { SIZES } from "src/ui/box";

interface Props {
  sx?: BoxProps["sx"];
  draft: DraftsQuery["drafts"][0];
  editingDraft: boolean;
  resourceType: ResourceToPermission;
  onViewDraft: () => void;
  setEditingDraft: (boolean) => void;
}

export const EditingDraftWarning = ({
  draft,
  editingDraft,
  resourceType,
  setEditingDraft,
  onViewDraft,
}: Props) => {
  const { user } = useUser();
  const { toast } = useToast();
  const navigate = useNavigate();

  const { mutateAsync: deleteUpdateDraft, isLoading: deletingUpdate } =
    useDeleteUpdateDraftMutation();
  const { mutateAsync: deleteCreateDraft, isLoading: deletingCreate } =
    useDeleteCreateDraftMutation();

  const requestedFrom = draft.created_by_user?.name;

  let message: string;
  let title: string;

  const createdByMe = draft.created_by_user?.id === user?.id;
  const from = createdByMe ? "You" : requestedFrom;

  if (draft.operation === DraftOperation.Create) {
    if (draft.approval_requests.length === 0) {
      message = `${from} last saved a draft of this ${resourceType} on ${formatDatetime(
        draft.created_at!,
      )}.`;
      title = "Currently editing draft";
    } else {
      message = `${from} requested approval to publish this new ${resourceType} on ${formatDatetime(
        draft.created_at!,
      )}.`;
      title = `Approval requested for new ${resourceType}`;
    }
  } else {
    if (draft.approval_requests.length === 0) {
      if (editingDraft) {
        title = "Editing draft changes";
        message = `You are currently editing draft changes for this ${resourceType}.`;
      } else {
        title = "Draft changes available";
        message = `${from} made draft changes to this ${resourceType} on ${formatDatetime(
          draft.created_at!,
        )}.`;
      }
    } else {
      if (editingDraft) {
        title = "Reviewing draft changes";
        message = `You are reviewing draft changes for this ${resourceType}.\n${from} requested approval for changes to this ${resourceType} on ${formatDatetime(
          draft.created_at!,
        )}.`;
      } else {
        title = "Approval requested for draft changes";
        message = `${from} requested approval for changes to this ${resourceType} on ${formatDatetime(
          draft.created_at!,
        )}.`;
      }
    }
  }

  return (
    <Row
      mb={4}
      width="100%"
      borderBottom="1px"
      borderColor="base.border"
      background="base.lightBackground"
    >
      <Box
        maxWidth={SIZES.page}
        flex={1}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        mx="auto"
        px={6}
        py={3}
      >
        <Row alignItems="center" gap={4}>
          <DraftBadge
            text={draft.approval_requests.length === 0 ? "Draft" : "Pending"}
          />
          <Text fontWeight="semibold">{title}</Text>
          <Text color="text.secondary">{message}</Text>
        </Row>

        <Row alignItems="center" gap={4}>
          {!editingDraft && draft.operation !== DraftOperation.Create && (
            <Button onClick={() => setEditingDraft(true)}>Go to draft</Button>
          )}
          {editingDraft && draft.operation !== DraftOperation.Create && (
            <Button onClick={() => setEditingDraft(false)}>
              Go to production
            </Button>
          )}
          <Button variant="primary" onClick={onViewDraft}>
            {draft.approval_requests.length === 0
              ? "Request approval"
              : "View request"}
          </Button>

          <Menu>
            <MenuActionsButton />

            <MenuList>
              <MenuItem
                icon={DeleteIcon}
                isDisabled={deletingUpdate || deletingCreate}
                onClick={async () => {
                  try {
                    if (
                      draft.operation === DraftOperation.Create &&
                      draft.resource_id
                    ) {
                      await deleteCreateDraft({
                        draftId: draft.id,
                        sync: draft.resource_type === ResourceToPermission.Sync,
                        model:
                          draft.resource_type === ResourceToPermission.Model,
                        resourceId: draft.resource_id,
                        stringResourceId: draft.resource_id.toString(),
                      });
                      navigate(
                        `${
                          resourceType === ResourceToPermission.Sync
                            ? "/syncs"
                            : "/models"
                        }`,
                      );
                    }
                    if (draft.operation === DraftOperation.Update) {
                      await deleteUpdateDraft({ draftId: draft.id });
                    }
                    toast({
                      id: "delete-draft",
                      title: "Draft deleted",
                      variant: "success",
                    });
                  } catch (e) {
                    Sentry.captureException(e);
                    toast({
                      id: "delete-draft",
                      title: "Could not delete draft",
                      message: "Please try again later",
                      variant: "error",
                    });
                  }
                }}
              >
                Delete draft
              </MenuItem>
            </MenuList>
          </Menu>
        </Row>
      </Box>
    </Row>
  );
};
