import { FormField, Column, Row, RadioGroup, Radio } from "@hightouchio/ui";
import { Controller } from "react-hook-form";
import {
  OutcomeClassification,
  classifications,
  getOutcomeClassificationFromWeight,
  getOutcomeWeightFromClassificationLabel,
} from "./outcome-classification";

export const ClassificationField = () => {
  return (
    <Controller
      name="weight"
      render={({ field }) => (
        <FormField label="Classification">
          <RadioGroup
            value={getOutcomeClassificationFromWeight(field.value).label}
            onChange={(value) => {
              field.onChange(getOutcomeWeightFromClassificationLabel(value));
            }}
          >
            <Column gap={4}>
              {Object.values(classifications).map((classification) => {
                return (
                  <Row gap={3} align="center" key={classification.label}>
                    <Radio value={classification.label} label="" />
                    <OutcomeClassification
                      weight={{
                        type: classification.type,
                        priority: classification.priority,
                      }}
                      showLabel
                    />
                  </Row>
                );
              })}
            </Column>
          </RadioGroup>
        </FormField>
      )}
    />
  );
};
