import { ExternalLinkIcon, IconButton, Tooltip } from "@hightouchio/ui";
import { FC } from "react";
import { openUrlInNewTab } from "src/utils/urls";

export const ExtentalLink: FC<{ tooltipMessage: string; url: string }> = ({
  tooltipMessage,
  url,
}) => {
  return (
    <Tooltip message={tooltipMessage}>
      <IconButton
        icon={ExternalLinkIcon}
        onClick={() => openUrlInNewTab(url)}
        aria-label={tooltipMessage}
      />
    </Tooltip>
  );
};
