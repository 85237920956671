import { FC } from "react";

import { EventForwardingSync } from "..";
import { AdvancedEventForm } from "./events/advanced-event-form";

type Props = {
  sync: EventForwardingSync;
};

export const ForwardingSyncForm: FC<Readonly<Props>> = ({ sync }) => {
  return <AdvancedEventForm sync={sync} />;
};
