import { FC } from "react";

import * as Yup from "yup";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { useSalesloftColumnsQuery } from "src/graphql";
import { StandardFieldType, COMMON_SCHEMAS } from "src/utils/destinations";

import { Section } from "../../../ui/section";
import { IdMappingField } from "../id-mapping-field";
import { MappingsField } from "../mappings-field";
import { ModeField } from "../mode-field";
import { ObjectField } from "../object-field";

export const validation = Yup.object().shape({
  externalIdMapping: COMMON_SCHEMAS.externalIdMapping,
  mappings: COMMON_SCHEMAS.mappings,
  customMappings: COMMON_SCHEMAS.mappings,
  object: Yup.string().required().default("accounts"),
  mode: Yup.string().required().default("upsert"),
});

const MODES = [
  { label: "Upsert", value: "upsert" },
  { label: "Update", value: "update" },
];

const OBJECTS = [
  { label: "Accounts", value: "accounts" },
  {
    label: "People",
    value: "people",
  },
];

const COLUMNS = {
  accounts: [
    {
      label: "Name",
      value: "name",
      type: StandardFieldType.STRING,
      required: true,
    },
    {
      label: "Domain",
      value: "domain",
      type: StandardFieldType.STRING,
      required: true,
    },
    {
      label: "Conversational Name",
      value: "conversational_name",
      type: StandardFieldType.STRING,
    },
    {
      label: "Description",
      value: "description",
      type: StandardFieldType.STRING,
    },
    { label: "Phone", value: "phone", type: StandardFieldType.STRING },
    { label: "Website", value: "website", type: StandardFieldType.STRING },
    {
      label: "LinkedIn Url",
      value: "linkedin_url",
      type: StandardFieldType.STRING,
    },
    {
      label: "Twitter Handle",
      value: "twitter_handle",
      type: StandardFieldType.STRING,
    },
    { label: "Street", value: "street", type: StandardFieldType.STRING },
    { label: "City", value: "city", type: StandardFieldType.STRING },
    { label: "State", value: "state", type: StandardFieldType.STRING },
    {
      label: "Postal Code",
      value: "postal_code",
      type: StandardFieldType.STRING,
    },
    { label: "Country", value: "country", type: StandardFieldType.STRING },
    { label: "Locale", value: "locale", type: StandardFieldType.STRING },
    { label: "Industry", value: "industry", type: StandardFieldType.STRING },
    {
      label: "Company Type",
      value: "company_type",
      type: StandardFieldType.STRING,
    },
    { label: "Year Founded", value: "founded", type: StandardFieldType.STRING },
    {
      label: "Revenue Range",
      value: "revenue_range",
      type: StandardFieldType.STRING,
    },
    { label: "Company Size", value: "size", type: StandardFieldType.STRING },
    {
      label: "Do Not Contact",
      value: "do_not_contact",
      type: StandardFieldType.BOOLEAN,
    },
    { label: "City", value: "city", type: StandardFieldType.STRING },
    { label: "Owner Id", value: "owner_id", type: StandardFieldType.NUMBER },
    {
      label: "Account Tier ID",
      value: "account_tier_id",
      type: StandardFieldType.STRING,
    },
    {
      label: "CRM ID Type",
      value: "crm_id_type",
      type: StandardFieldType.STRING,
    }, //must be salesforce
    { label: "CRM ID", value: "crm_id", type: StandardFieldType.STRING },
    { label: "Tags", value: "tags", type: StandardFieldType.ARRAY }, //figure out if can populate
  ],
  people: [
    {
      label: "First Name",
      value: "first_name",
      type: StandardFieldType.STRING,
    },
    { label: "Last Name", value: "last_name", type: StandardFieldType.STRING },
    {
      label: "Display Name",
      value: "display_name",
      type: StandardFieldType.STRING,
    },
    { label: "Email", value: "email_address", type: StandardFieldType.STRING },
    {
      label: "Full Email Address",
      value: "full_email_address",
      type: StandardFieldType.STRING,
    },
    {
      label: "Secondary Email Address",
      value: "secondary_email_address",
      type: StandardFieldType.STRING,
    },
    {
      label: "Personal Email Address",
      value: "personal_email_address",
      type: StandardFieldType.STRING,
    },
    { label: "Phone", value: "phone", type: StandardFieldType.STRING },
    {
      label: "Phone Extension",
      value: "phoen_extension",
      type: StandardFieldType.STRING,
    },
    {
      label: "Home Phone",
      value: "home_phone",
      type: StandardFieldType.STRING,
    },
    {
      label: "Mobile Phone",
      value: "mobile_phone",
      type: StandardFieldType.STRING,
    },
    {
      label: "LinkedIn Url",
      value: "linkedin_url",
      type: StandardFieldType.STRING,
    },
    { label: "Job Title", value: "title", type: StandardFieldType.STRING },
    { label: "City", value: "city", type: StandardFieldType.STRING },
    { label: "State", value: "state", type: StandardFieldType.STRING },
    { label: "Country", value: "country", type: StandardFieldType.STRING },
    { label: "Work City", value: "work_city", type: StandardFieldType.STRING },
    {
      label: "Work State",
      value: "work_state",
      type: StandardFieldType.STRING,
    },
    {
      label: "Work Country",
      value: "work_country",
      type: StandardFieldType.STRING,
    },
    { label: "Crm Url", value: "crm_url", type: StandardFieldType.STRING },
    { label: "Crm Id", value: "crm_id", type: StandardFieldType.STRING },
    {
      label: "Crm Object Type",
      value: "crm_object_type",
      type: StandardFieldType.STRING,
    },
    {
      label: "Owner Crm Id",
      value: "owner_crm_id",
      type: StandardFieldType.STRING,
    },
    {
      label: "Person Company Name",
      value: "person_company_name",
      type: StandardFieldType.STRING,
    },
    {
      label: "Person Company Website",
      value: "person_company_website",
      type: StandardFieldType.STRING,
    },
    {
      label: "Person Company Industry",
      value: "person_company_industry",
      type: StandardFieldType.STRING,
    },
    {
      label: "Do Not Contact",
      value: "do_not_contact",
      type: StandardFieldType.BOOLEAN,
    },
    { label: "Locale", value: "locale", type: StandardFieldType.STRING },
    {
      label: "Personal Website",
      value: "personal_website",
      type: StandardFieldType.STRING,
    },
    {
      label: "Twitter Handle",
      value: "twitter_handle",
      type: StandardFieldType.STRING,
    },
    { label: "Tags", value: "tags", type: StandardFieldType.ARRAY },
    {
      label: "Contact Restrictions",
      value: "contact_restrictions",
      type: StandardFieldType.ARRAY,
    },
    { label: "Owner Id", value: "owner_id", type: StandardFieldType.NUMBER },
    {
      label: "Account Id",
      value: "account_id",
      type: StandardFieldType.STRING,
    },
    { label: "Import Id", value: "import_id", type: StandardFieldType.NUMBER },
    {
      label: "Person Stage Id",
      value: "person_stage_id",
      type: StandardFieldType.STRING,
    },
    {
      label: "Crm Id Type",
      value: "crm_id_type",
      type: StandardFieldType.NUMBER,
    },
    { label: "Crm Id", value: "crm_id", type: StandardFieldType.STRING },
  ],
};

export const SalesloftForm: FC = () => {
  const { config, setConfig, destination } = useDestinationForm();

  const {
    data: listCustomFieldsData,
    error: listCustomFieldsError,
    isFetching: loadingCustomListFields,
    refetch: listCustomFields,
  } = useSalesloftColumnsQuery({
    destinationId: String(destination?.id),
  });

  const ID_FIELDS = {
    accounts: {
      update: [{ label: "ID", value: "id" }],
      upsert: [
        { label: "Crm ID", value: "crm_id" },
        { label: "Domain", value: "domain" },
      ],
    },
    people: {
      update: [{ label: "ID", value: "id" }],
      upsert: [
        { label: "Crm ID", value: "crm_id" },
        { label: "Email", value: "email_address" },
      ],
    },
  };

  const customFields = listCustomFieldsData?.salesloftListCustomFields?.fields;

  const customFieldOptions =
    config?.object === "accounts"
      ? customFields
          ?.filter((a) => a.field_type === "company")
          .map((a) => ({
            label: a.name,
            value: a.name,
            type: a.standardType,
          })) || []
      : customFields
          ?.filter((a) => a.field_type === "person")
          .map((a) => ({
            label: a.name,
            value: a.name,
            type: a.standardType,
          })) || [];

  const externalIdFields = ID_FIELDS?.[config?.object]?.[config?.mode];
  const fields = config?.object ? COLUMNS[config?.object] : [];

  return (
    <>
      <ObjectField options={OBJECTS} />
      {config?.object && (
        <ModeField
          options={MODES}
          onChange={(mode) => {
            setConfig({
              object: config?.object,
              mode,
            });
          }}
        />
      )}
      {config?.object && config?.mode && (
        <>
          <Section>
            <IdMappingField options={externalIdFields} />
          </Section>

          <Section>
            <MappingsField options={fields} />
          </Section>

          <Section>
            <MappingsField
              isCustom
              error={listCustomFieldsError?.message}
              loading={loadingCustomListFields}
              options={customFieldOptions}
              reload={listCustomFields}
            />
          </Section>
        </>
      )}
    </>
  );
};

export default {
  form: SalesloftForm,
  validation,
};
