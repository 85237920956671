import { Sync } from "src/pages/syncs/sync/utils/types";
import {
  IntervalBinType,
  useSyncRowsQueriedQuery,
  useSyncRunMetricsQuery,
} from "src/graphql";
import { addHours, format, subHours } from "date-fns";
import {
  Box,
  Column,
  Row,
  SectionHeading,
  Skeleton,
  SkeletonBox,
  Text,
  ToggleButton,
  ToggleButtonGroup,
} from "@hightouchio/ui";
import { Card } from "src/components/card";
import { useState } from "react";
import { isEnum } from "src/types/utils";
import {
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  TooltipProps,
  BarChart,
  Bar,
  ReferenceLine,
  Cell,
  LineChart,
  Line,
} from "recharts";
import {
  ChartGranularity,
  ChartGranularityMetadata,
  padTimeseriesDataWithBuckets,
  quantityTickFormatter,
} from "src/components/charts/chart-utils";
import { ChartTooltip } from "src/components/charts/chart-tooltip";
import { roundToNearestHours } from "date-fns";
import pluralize from "pluralize";
import {
  ChartsContextProvider,
  useChartsContext,
} from "src/hooks/use-charts-context";
import { times } from "lodash";

const COLOR_SUCCESS = {
  hover: "var(--chakra-colors-grass-500)",
  base: "var(--chakra-colors-grass-400)",
};

const COLOR_REJECTED = {
  hover: "var(--chakra-colors-danger-500)",
  base: "var(--chakra-colors-danger-400)",
};

const COLOR_ROWS_QUERIED = {
  hover: "var(--chakra-colors-ocean-500)",
  base: "var(--chakra-colors-ocean-400)",
};

const DIVIDER_COLOR = "var(--chakra-colors-base-divider)";

type Props = {
  sync: Sync;
};

export const SyncRunCharts = ({ sync }: Props) => {
  const [granularity, setGranularity] = useState(ChartGranularity.SevenDays);
  const granularityMetadata = ChartGranularityMetadata[granularity];

  const endTime = roundToNearestHours(new Date(), {
    roundingMethod: "ceil",
  });

  const startTime = subHours(endTime, granularityMetadata.days * 24);

  const isStreamingSync =
    sync.schedule?.type === "streaming" || Boolean(sync.is_streaming);

  const { data: metrics, isLoading: syncMetricsLoading } =
    useSyncRunMetricsQuery(
      {
        input: {
          syncId: sync.id,
          startTime: startTime.valueOf(),
          endTime: endTime.valueOf(),
          interval: {
            binInterval: granularityMetadata.binHours,
            binType: IntervalBinType.Hours,
          },
        },
      },
      {
        select: (res) => {
          return padTimeseriesDataWithBuckets(
            res.getSyncRunMetrics.data.map(
              ({ timestamp, runCount, successOps, rejectedOps }) => ({
                timestamp: new Date(timestamp),
                data: {
                  // These are typed as graphql bigint, which is represented as a string, so convert to JS number
                  successOps: Number(successOps),
                  rejectedOps: Number(rejectedOps),
                  runCount,
                },
              }),
            ),
            {
              successOps: 0,
              rejectedOps: 0,
              runCount: 0,
            },
            granularity,
            startTime,
          );
        },
        enabled: Boolean(sync.id) && !isStreamingSync,
      },
    );

  const { data: rowsQueriedData, isLoading: rowsQueriedLoading } =
    useSyncRowsQueriedQuery(
      {
        input: {
          syncId: sync.id,
          startTime: startTime.valueOf(),
          endTime: endTime.valueOf(),
        },
      },
      {
        select: (data) =>
          data.getSyncRowsQueried.data.map(({ timestamp, value }) => ({
            timestamp: new Date(timestamp),
            value: Number(value),
          })),
        enabled: Boolean(sync.id) && !isStreamingSync,
      },
    );

  const streamingSyncMessage = "Metrics not available for streaming syncs";

  const successMetrics =
    metrics?.map((m) => ({
      timestamp: m.timestamp,
      value: m.data.successOps,
      tooltipMetadata: m.data.runCount,
    })) || [];

  const rejectedMetrics =
    metrics?.map((m) => ({
      timestamp: m.timestamp,
      value: m.data.rejectedOps,
      tooltipMetadata: m.data.runCount,
    })) || [];

  const numTicks =
    (granularityMetadata.days * 24) / granularityMetadata.binHours + 1;
  const ticks = times(numTicks, (i) =>
    addHours(startTime, i * granularityMetadata.binHours).valueOf(),
  );

  const tooltipDescription = (runCount: number) => {
    return `Across ${runCount} sync ${pluralize("run", runCount)}`;
  };

  return (
    <Card gap={6} p={4}>
      <Row alignItems="center" justifyContent="space-between">
        <SectionHeading>Sync metrics</SectionHeading>
        <ToggleButtonGroup
          size="sm"
          value={granularity}
          onChange={(value) => {
            if (isEnum(ChartGranularity)(value)) setGranularity(value);
          }}
        >
          {Object.values(ChartGranularity).map((g) => (
            <ToggleButton
              key={g}
              label={ChartGranularityMetadata[g].label}
              value={g}
            />
          ))}
        </ToggleButtonGroup>
      </Row>
      <ChartsContextProvider>
        <Column gap={1}>
          <Text>Successful operations</Text>
          <Text size="sm" color="text.secondary">
            Total number of rows that were successfully synced to the
            destination.
          </Text>
          <Skeleton isLoading={syncMetricsLoading}>
            <SkeletonBox borderRadius="md">
              <Box width="100%" height="120px" mt={2}>
                <SyncMetricBarChart
                  data={successMetrics}
                  ticks={ticks}
                  granularity={granularity}
                  color={COLOR_SUCCESS}
                  tooltip={{
                    label: "successful operations",
                    description: tooltipDescription,
                  }}
                  noDataMessage={
                    isStreamingSync
                      ? streamingSyncMessage
                      : `No successful operations in the past ${granularityLabel[granularity]}`
                  }
                />
              </Box>
            </SkeletonBox>
          </Skeleton>
        </Column>
        <Column gap={1}>
          <Text>Rejected operations</Text>
          <Text size="sm" color="text.secondary">
            Total number of rows that could not be synced due to row-level
            errors.
          </Text>
          <Skeleton isLoading={syncMetricsLoading}>
            <SkeletonBox borderRadius="md">
              <Box width="100%" height="120px" mt={2}>
                <SyncMetricBarChart
                  data={rejectedMetrics}
                  ticks={ticks}
                  granularity={granularity}
                  color={COLOR_REJECTED}
                  tooltip={{
                    label: "rejected operations",
                    description: tooltipDescription,
                  }}
                  noDataMessage={
                    isStreamingSync
                      ? streamingSyncMessage
                      : `No rejected operations in the past ${granularityLabel[granularity]}`
                  }
                />
              </Box>
            </SkeletonBox>
          </Skeleton>
        </Column>
      </ChartsContextProvider>
      <ChartsContextProvider>
        <Column gap={1}>
          <Text>Model size</Text>
          <Text size="sm" color="text.secondary">
            Number of rows in the model query results, including rows that have
            not changed.
          </Text>
          <Skeleton isLoading={rowsQueriedLoading}>
            <SkeletonBox borderRadius="md">
              <Box width="100%" height="120px" mt={2}>
                <SyncMetricLineChart
                  data={rowsQueriedData || []}
                  ticks={ticks}
                  granularity={granularity}
                  color={COLOR_ROWS_QUERIED}
                  tooltip={{
                    label: "rows queried",
                  }}
                  noDataMessage={
                    isStreamingSync
                      ? streamingSyncMessage
                      : `No rows queried in the past ${granularityLabel[granularity]}`
                  }
                />
              </Box>
            </SkeletonBox>
          </Skeleton>
        </Column>
      </ChartsContextProvider>
    </Card>
  );
};

const granularityLabel = {
  [ChartGranularity.OneDay]: "24 hours",
  [ChartGranularity.ThreeDays]: "3 days",
  [ChartGranularity.SevenDays]: "7 days",
};

type SyncMetricBarChartProps = {
  data: { timestamp: Date; value: number; tooltipMetadata: number }[];
  ticks: number[];
  granularity: ChartGranularity;
  color: {
    base: string;
    hover: string;
  };
  tooltip: {
    label: string;
    description: (tooltipMetadata: number) => string;
  };
  noDataMessage: string;
};

const SyncMetricBarChart = ({
  data,
  ticks,
  granularity,
  color,
  tooltip,
  noDataMessage,
}: SyncMetricBarChartProps) => {
  const hasData = Boolean(data.length) && data.some((d) => d.value > 0);
  const { handleMouseMove, handleMouseLeave, activeIndex } = useChartsContext();

  const granularityMetadata = ChartGranularityMetadata[granularity];

  return (
    <Box width="100%" height="100%" position="relative">
      <ResponsiveContainer>
        <BarChart
          data={data || []}
          margin={{ top: 16, left: 0, right: 24, bottom: 0 }}
          onMouseMove={hasData ? handleMouseMove : undefined}
          onMouseLeave={hasData ? handleMouseLeave : undefined}
        >
          <CartesianGrid vertical={false} stroke={DIVIDER_COLOR} />
          {hasData && (
            <ReferenceLine
              x={data[activeIndex]?.timestamp.valueOf()}
              strokeWidth={
                granularity === ChartGranularity.SevenDays ? "3.6%" : "4.3%"
              }
              stroke={DIVIDER_COLOR}
            />
          )}
          {/* Note: use two x-axis here, one to display bars, the other for labels
              this is to allow labels to be offset from the bars so that they
              align with the points on the line chart below.
           */}
          <XAxis dataKey="timestamp" hide />
          <XAxis
            axisLine={{ stroke: DIVIDER_COLOR }}
            ticks={ticks}
            type="number"
            domain={[ticks[0]!, ticks[ticks.length - 1]!]}
            dataKey="x0"
            xAxisId="ticks"
            interval={granularityMetadata.tickInterval}
            tickLine={false}
            tickFormatter={(t) => timeTickFormatter(t, granularity)}
            padding={{ left: 5, right: 5 }}
          />
          <YAxis
            width={40}
            axisLine={false}
            tickLine={false}
            allowDecimals={false}
            tickFormatter={(t) => (hasData ? quantityTickFormatter(t) : "")}
            domain={hasData ? undefined : [0, 2]}
          />
          <Bar dataKey="value" minPointSize={3}>
            {data.map(({ value }, pointIndex) => (
              <Cell
                key={`cell-${pointIndex}`}
                fill={
                  value === 0
                    ? DIVIDER_COLOR
                    : activeIndex === -1 || activeIndex === pointIndex
                      ? color.hover
                      : color.base
                }
              />
            ))}
          </Bar>
          {hasData && (
            <Tooltip
              content={(props: TooltipProps<number, string>) => {
                const point = props.payload?.[0]?.payload as
                  | SyncMetricBarChartProps["data"][0]
                  | undefined;

                if (!point) return;

                const endOfBucket = addHours(
                  point.timestamp,
                  granularityMetadata.binHours,
                );

                const title = `${format(
                  point.timestamp,
                  "EEEE, MMMM dd h:mm aa",
                )} - ${format(endOfBucket, "h:mm aa")}`;

                return (
                  <ChartTooltip
                    title={title}
                    data={[
                      {
                        value: point.value,
                        color: color.hover,
                        label: tooltip.label,
                      },
                    ]}
                    description={tooltip.description(point.tooltipMetadata)}
                  />
                );
              }}
              cursor={false}
              offset={20}
              position={{ y: -20 }}
              animationDuration={150}
            />
          )}
        </BarChart>
      </ResponsiveContainer>
      {!hasData && <NoDataMessage message={noDataMessage} />}
    </Box>
  );
};

type SyncMetricLineChartProps = {
  data: { timestamp: Date; value: number }[];
  ticks: number[];
  granularity: ChartGranularity;
  color: {
    base: string;
    hover: string;
  };
  tooltip: {
    label: string;
  };
  noDataMessage: string;
};

const SyncMetricLineChart = ({
  data,
  ticks,
  granularity,
  color,
  tooltip,
  noDataMessage,
}: SyncMetricLineChartProps) => {
  const hasData = Boolean(data.length);
  const { handleMouseMove, handleMouseLeave, activeIndex } = useChartsContext();

  const granularityMetadata = ChartGranularityMetadata[granularity];

  return (
    <Box width="100%" height="100%" position="relative">
      <ResponsiveContainer>
        <LineChart
          data={data}
          margin={{ top: 16, left: 0, right: 24, bottom: 0 }}
          onMouseMove={hasData ? handleMouseMove : undefined}
          onMouseLeave={hasData ? handleMouseLeave : undefined}
        >
          <CartesianGrid vertical={false} stroke={DIVIDER_COLOR} />
          {hasData && (
            <ReferenceLine
              x={data[activeIndex]?.timestamp.valueOf()}
              strokeWidth="6px"
              stroke={DIVIDER_COLOR}
            />
          )}
          <XAxis
            axisLine={{ stroke: DIVIDER_COLOR }}
            dataKey="timestamp"
            type="number"
            ticks={ticks}
            domain={[ticks[0]!, ticks[ticks.length - 1]!]}
            interval={granularityMetadata.tickInterval}
            tickLine={false}
            tickFormatter={(t) => timeTickFormatter(t, granularity)}
            padding={{ left: 5, right: 5 }}
          />
          <YAxis
            width={40}
            axisLine={false}
            tickLine={false}
            allowDecimals={false}
            tickFormatter={(t) => (hasData ? quantityTickFormatter(t) : "")}
            domain={hasData ? undefined : [0, 2]}
          />
          <Line
            animationDuration={500}
            dataKey="value"
            type="monotone"
            strokeWidth={3}
            dot={data.length > 40 ? false : { r: 4 }}
            stroke={activeIndex === -1 ? color.hover : color.base}
            activeDot={{ stroke: color.hover, fill: color.hover }}
          />
          {hasData && (
            <Tooltip
              content={(props: TooltipProps<number, string>) => {
                const point = props.payload?.[0]?.payload as
                  | SyncMetricLineChartProps["data"][0]
                  | undefined;

                if (!point) return;

                const title = `${format(
                  point.timestamp,
                  "EEEE, MMMM dd h:mm aa",
                )}`;

                return (
                  <ChartTooltip
                    title={title}
                    data={[
                      {
                        value: point.value,
                        color: color.hover,
                        label: tooltip.label,
                      },
                    ]}
                  />
                );
              }}
              cursor={false}
              offset={20}
              position={{ y: -20 }}
              animationDuration={150}
            />
          )}
        </LineChart>
      </ResponsiveContainer>
      {!hasData && <NoDataMessage message={noDataMessage} />}
    </Box>
  );
};

const NoDataMessage = ({ message }: { message: string }) => (
  <Box
    position="absolute"
    left="50%"
    top="44%"
    backgroundColor="white"
    textAlign="center"
    p={2}
    transform="translate(-50%, -50%)"
  >
    <Text color="text.secondary" fontWeight="medium">
      {message}
    </Text>
  </Box>
);

function timeTickFormatter(
  tick: string | number,
  granularity: ChartGranularity,
): string {
  try {
    const parsed = new Date(tick);

    switch (granularity) {
      case ChartGranularity.OneDay:
        return format(parsed, "h aa");
      case ChartGranularity.ThreeDays:
      case ChartGranularity.SevenDays:
        return format(parsed, "EEE-d");
    }
  } catch {
    return tick.toString();
  }
}
