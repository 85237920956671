import { FC } from "react";

import { Box, Code, StatusBadge, StatusIndicator, Text } from "@hightouchio/ui";

import { TestDestinationStepStatus } from "@hightouch/core/server/graphql/types";
import {
  TestResult,
  TestUpdatedDestinationButton,
} from "src/components/destinations/test-destination";
import { ErrorModal } from "src/components/modals/error-modal";
import { FormkitDestination } from "src/formkit/components/formkit-context";
import { Cell, HeaderCell } from "src/ui/table/cells";
import { ReauthorizeConnection } from "../authorize-connection";

export type ConnectionHandlers = {
  onResult: (result: TestResult) => void;
  onTestResult: (result: any) => any;
  onError: (error: Error | null) => void;
  onLoading: (loading: boolean) => void;
  testResult: TestResult | null;
  testConnectionResult: TestDestinationStepStatus[] | null;
  testError: Error | null;
};

type Props = {
  destinationId: string;
  destinationName: string;
  destinationIcon: string;
  config: Record<string, any> | undefined;
  credentialId: string | undefined;
  destination?: FormkitDestination | null;
  redirectUrl: string;
  onAuthorize: () => void | Promise<void | undefined> | undefined;
  showTestConnection: boolean;
  connectionHandlers: ConnectionHandlers;
};

export const OAuthConnectionTable: FC<Readonly<Props>> = ({
  destinationId,
  destinationIcon,
  destinationName,
  config,
  credentialId,
  onAuthorize,
  redirectUrl,
  showTestConnection,
  connectionHandlers,
}) => {
  const {
    onError,
    onResult,
    onLoading,
    onTestResult,
    testResult,
    testConnectionResult,
    testError,
  } = connectionHandlers;

  const getVariant = (status: TestResult) => {
    switch (status) {
      case TestResult.Success:
        return "success";
      case TestResult.Warning:
        return "warning";
      case TestResult.Failed:
        return "error";
      default:
        return "inactive";
    }
  };

  const getMessage = (status: TestResult) => {
    switch (status) {
      case TestResult.Success:
        return "Connected";
      case TestResult.Warning:
        return "Warning";
      case TestResult.Failed:
        return "Error";
      default:
        return "Unknown";
    }
  };

  return (
    <Box
      borderColor="base.border"
      borderBottom="small"
      flex={1}
      display="grid"
      width="100%"
      gridTemplateColumns="minmax(0, max-content) minmax(min-content, 1fr) minmax(0, 1fr);"
      gridTemplateRows="52px 64px 64px"
    >
      <HeaderCell
        sx={{
          "&:first-of-type": {
            pl: 4,
          },
        }}
        top={0}
      >
        Setup
      </HeaderCell>
      <HeaderCell top={0}>Action</HeaderCell>
      <HeaderCell top={0}>Status</HeaderCell>
      <Cell>
        <Text fontWeight="medium">Login credentials</Text>
      </Cell>
      <Cell>
        <ReauthorizeConnection
          permission={{
            v2: {
              resource: "destination",
              grant: "can_update",
              id: destinationId,
            },
          }}
          href={redirectUrl}
          icon={destinationIcon}
          name={destinationName}
          onAuthorize={onAuthorize}
        />
      </Cell>
      <Cell>
        <StatusIndicator
          variant={getVariant(
            testError ? TestResult.Failed : TestResult.Success,
          )}
        >
          {testError ? "Connection Error" : "Authorized"}
        </StatusIndicator>
      </Cell>
      {showTestConnection && (
        <>
          <Cell>
            <Text fontWeight="medium">Destination connection</Text>
          </Cell>
          <Cell>
            <TestUpdatedDestinationButton
              credentialId={credentialId}
              destinationId={destinationId}
              newConfiguration={config}
              onError={onError}
              onLoading={onLoading}
              onResult={onResult}
              onTestResult={onTestResult}
              testConnectionOnRender={showTestConnection}
            />
          </Cell>
          <Cell>
            <StatusIndicator
              variant={getVariant(testResult || TestResult.Unknown)}
            >
              {getMessage(testResult || TestResult.Unknown)}
            </StatusIndicator>
          </Cell>
        </>
      )}
      {testConnectionResult?.map(({ error, reason, success, scope }) => (
        <>
          <Cell
            justifyContent="center"
            minHeight="64px"
            backgroundColor="base.lightBackground"
          >
            {!success ? (
              <ErrorModal error={error || reason || "Error connecting"} />
            ) : (
              <StatusBadge variant="success">Success</StatusBadge>
            )}
          </Cell>
          {scope ? (
            <>
              <Cell backgroundColor="base.lightBackground">
                <Code>{scope}</Code>
              </Cell>
              <Cell
                backgroundColor="base.lightBackground"
                marginTop="4px"
                marginBottom="4px"
              >
                <Text>
                  {(reason?.length || 0) > 100
                    ? `${reason?.substring(0, 100)}...`
                    : reason}
                </Text>
              </Cell>
            </>
          ) : (
            <>
              <Cell
                backgroundColor="base.lightBackground"
                marginTop="4px"
                marginBottom="4px"
                gridColumn="2/4" // allow cell to expand to full row
              >
                <Text>
                  {(reason?.length || 0) > 200
                    ? `${reason?.substring(0, 200)}...`
                    : reason}
                </Text>
              </Cell>
              {/* empty cell to complete the grid row */}
              <Cell backgroundColor="base.lightBackground"></Cell>
            </>
          )}
        </>
      ))}
    </Box>
  );
};
