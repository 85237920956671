import { FC, useCallback, useMemo, useState } from "react";

import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  Paragraph,
  Row,
  SearchInput,
  Text,
} from "@hightouchio/ui";
import omit from "lodash/omit";
import uniqBy from "lodash/uniqBy";
import pluralize from "pluralize";
import { Link, useParams } from "src/router";
import { isPresent } from "ts-extras";

import {
  AudienceDetailsForPriorityListQuery,
  AudiencesForPriorityListsQuery,
  SegmentsBoolExp,
  SegmentsOrderBy,
  useAudienceDetailsForPriorityListQuery,
} from "src/graphql";
import { SyncsCell } from "src/pages/syncs/sync/components/syncs-cell";
import { Pagination, Table, useTableConfig } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { useRowSelect } from "src/ui/table/use-row-select";
import { abbreviateNumber } from "src/utils/numbers";

type Audience = AudiencesForPriorityListsQuery["segments"][number];
type AudienceDetail = AudienceDetailsForPriorityListQuery["segments"][number];

enum SortKeys {
  Name = "name",
  NumSyncs = "syncs_aggregate.count",
  UpdatedAt = "updated_at",
}

type AudienceSelectorProps = {
  parentModelId: number;
  isOpen: boolean;
  onClose: () => void;
  selectedData: Audience[];
  onSubmit: (audiences: Audience[]) => void;
};

export const AudienceSelector: FC<AudienceSelectorProps> = ({
  parentModelId,
  isOpen,
  selectedData,
  onClose,
  onSubmit,
}) => {
  const { priority_list_id } = useParams();
  const [search, setSearch] = useState("");
  const { selectedRows, onRowSelect } = useRowSelect();

  const { limit, offset, orderBy, page, setPage, onSort } =
    useTableConfig<SegmentsOrderBy>({
      defaultSortKey: "updated_at",
      limit: 10,
      sortOptions: Object.values(SortKeys),
    });

  const filters: SegmentsBoolExp = useMemo(() => {
    const hasuraFilters: SegmentsBoolExp = {
      query_type: { _eq: "visual" },
      // bigint is typed as string, but it's a number
      visual_query_parent_id: { _eq: parentModelId as unknown as string },
    };

    if (search) {
      hasuraFilters.name = { _ilike: `%${search}%` };
    }

    return hasuraFilters;
  }, [priority_list_id, parentModelId, search]);

  const audiencesForPriorityListsQuery = useAudienceDetailsForPriorityListQuery(
    {
      filters,
      limit,
      offset,
      orderBy,
    },
    {
      enabled: Boolean(parentModelId),
    },
  );

  const audiences = audiencesForPriorityListsQuery.data?.segments ?? [];
  const audiencesCount =
    audiencesForPriorityListsQuery.data?.segments_aggregate?.aggregate?.count ??
    0;

  const isRowDisabled = useCallback(
    (data: AudienceDetail) => {
      const membership = data.priority_list_memberships?.[0];
      const isPreviouslySelected = Boolean(
        selectedData.find((audience) => audience.id === data.id),
      );

      return (
        isPreviouslySelected ||
        (membership ? membership.priority_list.id != priority_list_id : false)
      );
    },
    [priority_list_id, selectedData],
  );

  const close = () => {
    setSearch("");
    setPage(0);
    onRowSelect([]);
    onClose();
  };

  const submit = useCallback(() => {
    // Note: modal does not remove audiences that have been de-selected
    const audiencesMap = new Map<string, Audience>(
      audiences.map((audience) => [
        audience.id,
        omit(audience, "priority_list_memberships"),
      ]),
    );

    const newAudiences = selectedRows
      .map((id) => audiencesMap.get(id as string))
      .filter(isPresent);

    onSubmit(uniqBy([...selectedData, ...newAudiences], "id"));
    onClose();
  }, [audiences, onClose, onSubmit, selectedData, selectedRows]);

  return (
    <Dialog
      isOpen={isOpen}
      variant="form"
      width="auto"
      title="Add audiences to priority list"
      actions={
        <ButtonGroup>
          <Button onClick={close}>Cancel</Button>
          <Button
            isDisabled={audiencesCount === 0 || selectedRows.length === 0}
            variant="primary"
            onClick={submit}
          >
            Add audiences
          </Button>
        </ButtonGroup>
      }
      onClose={close}
    >
      <Row mb={4}>
        <Paragraph>
          Select audiences for this priority list. Audiences that are used in
          other priority lists may not be used.
        </Paragraph>
      </Row>
      <Row align="center" justify="space-between" mb={4}>
        <SearchInput
          placeholder="Search audiences..."
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
        {selectedRows?.length > 0 && (
          <Row color="gray.700">
            <Text>
              {selectedRows.length} {pluralize("audience", selectedRows.length)}{" "}
              selected
            </Text>
          </Row>
        )}
      </Row>
      <Table
        columns={[
          {
            name: "Name",
            sortDirection: orderBy?.name,
            onClick: () => onSort(SortKeys.Name),
            cell: ({ name }) => (
              <Text isTruncated fontWeight="medium">
                {name}
              </Text>
            ),
          },
          {
            name: "Priority list",
            cell: ({ priority_list_memberships }) => {
              const membership = priority_list_memberships?.[0];
              const showExternalLink =
                membership && membership?.priority_list.id != priority_list_id;

              return (
                <Row
                  align="center"
                  onClick={(event) => event.stopPropagation()}
                >
                  {showExternalLink ? (
                    <>
                      <Box mr={1}>
                        <Text>#{membership.rank + 1} in</Text>
                      </Box>
                      <Link
                        href={`/priority-lists/${membership.priority_list.id}`}
                      >
                        {membership.priority_list.name}
                      </Link>
                    </>
                  ) : (
                    <Text>-</Text>
                  )}
                </Row>
              );
            },
          },
          {
            name: "Size",
            max: "max-content",
            cell: ({ query_runs }) => {
              const size = query_runs?.[0]?.size;
              return size ? (
                <Text>{abbreviateNumber(size)}</Text>
              ) : (
                <Text color="gray.600">-</Text>
              );
            },
          },
          {
            name: "Syncs",
            sortDirection: orderBy?.syncs_aggregate?.count,
            onClick: () => onSort(SortKeys.NumSyncs),
            max: "max-content",
            min: "232px",
            disabled: ({ syncs }) => Boolean(syncs?.length),
            cell: ({ syncs }) => {
              return <SyncsCell syncs={syncs} />;
            },
          },
          {
            ...LastUpdatedColumn,
            sortDirection: orderBy?.updated_at,
            onClick: () => onSort(SortKeys.UpdatedAt),
            breakpoint: "lg",
          },
        ]}
        data={audiences}
        disabled={isRowDisabled}
        error={Boolean(audiencesForPriorityListsQuery.error)}
        loading={audiencesForPriorityListsQuery.isLoading}
        placeholder={{
          title: "No audiences",
          body: "This parent model may not have any audiences, or all audiences are being used in other priority lists.",
          error: "Priority lists failed to load, please try again.",
        }}
        selectedRows={[...selectedData.map(({ id }) => id), ...selectedRows]}
        onSelect={onRowSelect}
      />
      <Row justify="flex-end" width="100%" mt={4}>
        <Pagination
          count={audiencesCount}
          label="audiences"
          page={page}
          rowsPerPage={limit}
          setPage={setPage}
        />
      </Row>
    </Dialog>
  );
};
