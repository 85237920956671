import { useEffect } from "react";

import { useUser } from "src/contexts/user-context";
import { useFlags } from "launchdarkly-react-client-sdk";

export const Koala = () => {
  const { user } = useUser();
  const { appKoalaDisabled } = useFlags();

  useEffect(() => {
    if (user && !appKoalaDisabled) {
      if (!window.ko) {
        window.ko = [];
        const methods = [
          "identify",
          "track",
          "removeListeners",
          "open",
          "on",
          "off",
          "qualify",
          "ready",
        ];
        methods.forEach((method) => {
          window.ko[method] = function (...args) {
            args.unshift(method);
            window.ko.push(args);
            return window.ko;
          };
        });
        const script = document.createElement("script");
        script.async = true;
        script.src =
          "https://cdn.getkoala.com/v1/pk_4b7deacc464cf7736f29732ef90d8e92a40e/sdk.js";
        (document.body || document.head).appendChild(script);
      }
    }
  }, [!!user, appKoalaDisabled]);

  return null;
};
