import { FC } from "react";

import {
  ChakraListItem,
  ChakraUnorderedList,
  Column,
  Text,
} from "@hightouchio/ui";

import { Link } from "src/router";

import { TraitQuery } from "src/graphql";

type Props = {
  trait: NonNullable<TraitQuery["trait_definitions_by_pk"]>;
};

export const TraitReferences: FC<Readonly<Props>> = ({ trait }) => {
  const traitType = trait.is_template ? "template" : "trait";

  return (
    <Column mt={4} gap={2} alignItems="start">
      <Text>This {traitType} is used in the following:</Text>

      {/* Active traits */}
      {trait.active_traits.length > 0 && (
        <Column alignItems="start">
          <Text fontWeight="medium">Traits:</Text>
          <ChakraUnorderedList>
            {trait.active_traits.map(
              ({ id, name }: { id: string; name: string }) => (
                <ChakraListItem key={id}>
                  <Link href={`/traits/active/${id}`} isExternal>
                    {name}
                  </Link>
                </ChakraListItem>
              ),
            )}
          </ChakraUnorderedList>
        </Column>
      )}

      {/* Audiences */}
      {trait.trait_references.audiences.length > 0 && (
        <Column alignItems="start">
          <Text fontWeight="medium">Audiences:</Text>
          <ChakraUnorderedList>
            {trait.trait_references.audiences.map(
              ({ id, name }: { id: string; name: string }) => (
                <ChakraListItem key={id}>
                  <Link href={`/audiences/${id}`} isExternal>
                    {name}
                  </Link>
                </ChakraListItem>
              ),
            )}
          </ChakraUnorderedList>
        </Column>
      )}

      {/* Syncs */}
      {trait.trait_references.syncs.length > 0 && (
        <Column alignItems="start">
          <Text fontWeight="medium">Syncs:</Text>
          <ChakraUnorderedList>
            {trait.trait_references.syncs.map(({ id }: { id: number }) => (
              <ChakraListItem key={id}>
                <Link href={`/syncs/${id}`} isExternal>
                  Sync #{id}
                </Link>
              </ChakraListItem>
            ))}
          </ChakraUnorderedList>
        </Column>
      )}
    </Column>
  );
};

export const isTraitReferenced = (
  trait: NonNullable<TraitQuery["trait_definitions_by_pk"]>,
) => {
  return Boolean(
    trait.trait_references.audiences.length ||
      trait.trait_references.syncs.length ||
      trait.active_traits.length,
  );
};
