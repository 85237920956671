import { FC } from "react";

import { Column, Text } from "@hightouchio/ui";

import { QueryBuilder } from "src/components/explore";
import { PageSpinner } from "src/components/loading";
import { ParentModel } from "src/pages/audiences/types";
import { AndCondition, AndOrCondition, OrCondition } from "src/types/visual";

type Props = {
  isLoading?: boolean;
  parentModel: ParentModel | null | undefined;
  conditions: AndOrCondition<AndCondition | OrCondition> | undefined;
  setConditions: (filter: AndCondition | OrCondition | null) => void;
};

export const Filter: FC<Readonly<Props>> = ({
  isLoading = false,
  parentModel,
  conditions,
  setConditions,
}) => {
  if (isLoading) {
    return <PageSpinner />;
  }

  return (
    <>
      <Column>
        <Text fontWeight="medium">Rule definition</Text>
        <Text color="text.secondary">
          Define which records are eligible to be synced to the destination
        </Text>
      </Column>

      <QueryBuilder
        parent={parentModel}
        filter={conditions}
        setConditions={setConditions}
      />
    </>
  );
};
