import { FC, useEffect } from "react";

import {
  TraitCondition,
  TraitConfig,
  TraitType,
} from "@hightouch/lib/query/visual/types";
import { Column, Combobox, FormField, Text, TextInput } from "@hightouchio/ui";
import { Controller, useFormContext } from "react-hook-form";
import * as Yup from "yup";

import { CalculationSummary } from "src/components/traits/calculation-summary";
import { PreviewTrait } from "src/components/traits/preview-trait";
import { TraitConditions } from "src/components/traits/trait-conditions";
import { useTraitsQuery } from "src/graphql";
import { RequiredParentModelFieldsForQueryBuilder } from "src/components/audiences/types";

type Props = {
  parentModel: RequiredParentModelFieldsForQueryBuilder;
  editConditions?: boolean;
};

export const traitEnrichmentValidationSchema = Yup.object()
  .shape({
    name: Yup.string().required("Name is required"),
    template_id: Yup.string().required("Please select a template"),
    relationship_id: Yup.number().required(
      "Please select a related model or event",
    ),
    conditions: Yup.array(),
  })
  .required();

export type TraitEnrichmentFormProps = {
  name: string;
  template_id: string;
  relationship_id: string;
  conditions: TraitCondition[];
};

export const TraitEnrichmentForm: FC<Readonly<Props>> = ({
  parentModel,
  editConditions,
}) => {
  const traitTemplatesQuery = useTraitsQuery({
    filter: {
      is_template: { _eq: true },
      archived_at: { _is_null: true },
      parent_model_id: { _eq: parentModel?.id },
    },
  });
  const templateOptions = (
    traitTemplatesQuery.data?.trait_definitions ?? []
  ).map((template) => ({
    label: template.name,
    description: template.description,
    value: template.id,
  }));

  const { control, watch, setValue } = useFormContext();

  const templateId = watch("template_id");
  const template = traitTemplatesQuery?.data?.trait_definitions.find(
    (template) => template.id === templateId,
  );

  const relationship = parentModel?.relationships.find(
    (relationship) => relationship.id === template?.relationship?.id,
  );

  const name = watch("name");

  useEffect(() => {
    setValue("relationship_id", relationship?.id);
  }, [relationship?.id]);

  return (
    <>
      <Column>
        <Text fontWeight="medium">Sync a trait value to a destination</Text>
        <Text>
          An audience trait enrichment allows you to craft a trait value and
          sync it scoped specifically to this audience only.
        </Text>
      </Column>

      <Column gap={2}>
        <Controller
          control={control}
          name="template_id"
          render={({ field, fieldState }) => (
            <FormField
              label="Choose a template"
              error={fieldState.error?.message}
            >
              <Combobox
                options={templateOptions}
                placeholder="Select a template"
                width="100%"
                {...field}
              />
            </FormField>
          )}
        />
        {template && (
          <Column
            borderRadius="6px"
            border="1px solid"
            borderColor="base.border"
            p={4}
            gap={4}
          >
            <CalculationSummary
              parentModel={parentModel}
              relatedModel={template?.relationship?.to_model}
              type={template.type as TraitType}
              config={template.config as TraitConfig}
            />
            {editConditions && relationship && (
              <TraitConditions
                isTraitEnrichment
                parentModel={parentModel!}
                relationship={relationship}
              />
            )}
          </Column>
        )}
      </Column>

      <Controller
        control={control}
        name="name"
        render={({ field, fieldState }) => (
          <FormField label="Name" error={fieldState.error?.message}>
            <TextInput placeholder="Enter a name" width="100%" {...field} />
          </FormField>
        )}
      />

      {parentModel && (
        <PreviewTrait
          isDisabled={!parentModel || !template || !relationship?.id}
          parentModel={parentModel}
          trait={{
            name,
            type: template?.type as TraitType,
            config: template?.config as TraitConfig,
            relationshipId: relationship?.id,
          }}
        />
      )}
    </>
  );
};
