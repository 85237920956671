import { FC } from "react";

import {
  CodeSnippet,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@hightouchio/ui";

import { Link } from "src/router";

import { CodeParams, SetupProps } from "./types";

function getHTMLCode(params: CodeParams, semVersion: string): string {
  const cdnUrl = `https://cdn.hightouch-events.com/browser/release/${semVersion}/events.min.js`;
  return `
  <script type="text/javascript">
  !function(){var e=window.htevents=window.htevents||[];if(!e.initialize)if(e.invoked)window.console&&console.error&&console.error("Hightouch snippet included twice.");else{e.invoked=!0,e.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"],e.factory=function(t){return function(){var n=Array.prototype.slice.call(arguments);return n.unshift(t),e.push(n),e}};for(var t=0;t<e.methods.length;t++){var n=e.methods[t];e[n]=e.factory(n)}e.load=function(t,n){var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src="${cdnUrl}";var r=document.getElementsByTagName("script")[0];r.parentNode.insertBefore(o,r),e._loadOptions=n,e._writeKey=t},e.SNIPPET_VERSION="0.0.1",
  e.load('${params.writeKey}',{apiHost:'${params.apiHost}'}),
  e.page()}}();
  </script>
  `;
}

const npmCode = `npm install @ht-sdks/events-sdk-js-browser`;

function getJSCodeEvents(params: CodeParams): string {
  return `
  import { HtEventsBrowser } from "@ht-sdks/events-sdk-js-browser";

  // you should only initialize once
  export const htevents = HtEventsBrowser.load(
    {
      writeKey: "${params.writeKey}",
    },
    { apiHost: "${params.apiHost}" },
  );
  `;
}

function getJSCodeApp(): string {
  return `
  // import your already initialized instance into the rest of your app
  import { htevents } from "./events";

  htevents.track("example");
  `;
}

export const SetupJavascript: FC<SetupProps> = (props: SetupProps) => {
  const semVersion = "v1-latest";

  return (
    <>
      <Heading>Installing the SDK</Heading>
      <Text>
        This SDK is available via{" "}
        <Link
          href={`https://cdn.hightouch-events.com/browser/release/${semVersion}/events.min.js`}
        >
          CDN
        </Link>{" "}
        or{" "}
        <Link href="https://www.npmjs.com/package/@ht-sdks/events-sdk-js-browser">
          NPM
        </Link>
        .
      </Text>
      <Tabs>
        <TabList>
          <Tab>CDN</Tab>
          <Tab>NPM</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <CodeSnippet
              label="index.html"
              code={getHTMLCode(props, semVersion)}
            />
            <br></br>
            <CodeSnippet
              label="app.js"
              code={`
            // example
            window.htevents.track("eventName");
          `}
            />
          </TabPanel>
          <TabPanel>
            <CodeSnippet label="terminal" code={npmCode} />
            <br></br>
            <CodeSnippet label="events.js" code={getJSCodeEvents(props)} />
            <br></br>
            <CodeSnippet label="app.js" code={getJSCodeApp()} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};
