export const Icon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20px"
    height="20px"
    viewBox="0 0 24 24"
    fill={color}
  >
    <path
      fillRule="evenodd"
      d="M13.823 3H4.167C3.522 3 3 3.522 3 4.167v9.676c0 .644.522 1.166 1.167 1.166h9.656c.645 0 1.167-.522 1.167-1.166V4.167c0-.645-.522-1.167-1.167-1.167ZM4.167 2C2.97 2 2 2.97 2 4.167v9.676c0 1.196.97 2.166 2.167 2.166h9.656c1.197 0 2.167-.97 2.167-2.166V4.167C15.99 2.97 15.02 2 13.823 2H4.167Z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M19.834 8.991h-9.657c-.644 0-1.167.523-1.167 1.167v9.676c0 .644.523 1.166 1.167 1.166h9.657c.644 0 1.166-.522 1.166-1.166v-9.676c0-.644-.522-1.167-1.166-1.167Zm-9.657-1c-1.197 0-2.167.97-2.167 2.167v9.676c0 1.196.97 2.166 2.167 2.166h9.657C21.03 22 22 21.03 22 19.834v-9.676c0-1.197-.97-2.167-2.166-2.167h-9.657Z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="m12.06 15.776-3.825-3.83.707-.708 3.824 3.83-.706.708Zm2.922-.296L8.564 9.051l.707-.707 6.419 6.429-.707.707Zm.283-2.953-3.825-3.83.707-.708 3.824 3.83-.706.708Z"
      clipRule="evenodd"
    />
  </svg>
);
