import { ConfirmationDialog, Paragraph } from "@hightouchio/ui";
import { FC } from "react";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => Promise<void>;
};

export const AudienceFilterNullBehaviorModal: FC<Props> = ({
  isOpen,
  onClose,
  onConfirm,
}) => (
  <ConfirmationDialog
    isOpen={isOpen}
    title="Modify null-handling for existing audiences?"
    confirmButtonText="Save & apply changes"
    variant="danger"
    onClose={onClose}
    onConfirm={onConfirm}
  >
    <Paragraph>
      All existing audiences using “not” filters will be updated to reflect your
      selection for null-handling behavior. Are you sure you want to continue?
    </Paragraph>
  </ConfirmationDialog>
);
