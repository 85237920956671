import * as Sentry from "@sentry/react";
import { DepGraph } from "dependency-graph";
import { useState } from "react";
import { Edge, Node } from "reactflow";
import {
  EdgeType,
  GraphModel,
  GraphRelationship,
  NodeData,
} from "src/pages/schema/types";
import { SchemaModelType } from "src/types/schema";
import { CHILD_NODE_HEIGHT, NODE_HEIGHT, NODE_WIDTH, getGraph } from "./utils";

export const GroupIDPrefix = "Group";
const MinimumChildrenForGroup = 6;

export function useExpandedGroup(
  models: GraphModel[],
  relationships: GraphRelationship[],
  selectedId: string | undefined,
) {
  const { nodes } = getGraph({
    models,
    relationships,
    expandedGroupNodeId: undefined,
    selectedId,
  });

  const selectedGroup = nodes.find((node) => {
    if (!node.data.children) {
      return false;
    }

    return node.data.children.find((nodeData) => nodeData.data.isSelected);
  });

  const [expandedGroup, setExpandedGroup] = useState<
    { nodeId: string; expanded: boolean } | undefined
  >(selectedGroup ? { nodeId: selectedGroup.id, expanded: true } : undefined);

  return { expandedGroup, setExpandedGroup };
}

export const groupNodeId = (parentId: string, cardinality: string) => {
  return `${GroupIDPrefix}:${parentId}-${cardinality}`;
};

type GroupKey = {
  parentId: string;
  parentCardinality: string;
  children: {
    id: string;
    cardinality: string;
  }[];
};

export function applyGroupings(
  nodes: Node[],
  edges: Edge[],
  groups: Record<string, Node<NodeData>[]>,
  expandedGroupNodeId: string | undefined,
): { nodes: Node[]; edges: Edge[] } {
  const nodesToRemove = new Set<string>();
  const edgesToRemove = new Set<string>();
  const nodesToAdd: Node[] = [];
  const edgesToAdd: Edge[] = [];

  for (const groupKey in groups) {
    const groupNodes = groups[groupKey] || [];

    const key = deserializeGroupKey(groupKey);
    if (!key) {
      continue;
    }

    const { parentId, parentCardinality, children } = key;

    groupNodes.forEach((node) => {
      nodesToRemove.add(node.id);
      edges.forEach((edge) => {
        if (edge.target === node.id || edge.source === node.id) {
          edgesToRemove.add(edge.id);
        }
      });
    });

    const groupNode = createGroupNode(
      groupKey,
      expandedGroupNodeId === groupKey,
      groupNodes,
    );
    nodesToAdd.push(groupNode);

    edgesToAdd.push({
      id: `Edge:${parentId}-${groupKey}`,
      type: EdgeType.Custom,
      source: parentId,
      target: groupNode.id,
      data: {
        from: parentId,
        isGroup: true,
        groupTarget: parentId,
        cardinality: parentCardinality,
      },
    });

    children.forEach(({ id, cardinality }) => {
      edgesToAdd.push({
        id: `Edge:${groupNode.id}-${id}`,
        type: EdgeType.Custom,
        source: groupNode.id,
        target: id,
        data: {
          isGroup: true,
          groupTarget: id,
          cardinality,
        },
      });
    });
  }

  return {
    nodes: nodes
      .filter((node) => !nodesToRemove.has(node.id))
      .concat(nodesToAdd),
    edges: edges
      .filter((edge) => !edgesToRemove.has(edge.id))
      .concat(edgesToAdd),
  };
}

function createGroupNode(
  id: string,
  isExpanded: boolean,
  children: Node<NodeData>[],
): Node<NodeData> {
  const childrenToRender = isExpanded
    ? children.length
    : Math.min(children.length, 5);

  return {
    id,
    style: {
      width: NODE_WIDTH,
      height: NODE_HEIGHT + childrenToRender * CHILD_NODE_HEIGHT,
    },
    type: SchemaModelType.Group,
    data: {
      children,
    },
    position: { x: 0, y: 0 },
  };
}

// Serialize each node into a GroupKey, which defines the unique grouping rules.
// If a node e.g. has different children or different cardinality with its parent,
// it won't fall into the same group as it will serialize differently
export function groupNodes({
  nodes,
  edges,
  graph,
}: {
  nodes: Node[];
  edges: Edge[];
  graph: DepGraph<unknown>;
}): Record<string, Node[]> {
  const parentChildMap: Record<string, Node[]> = {};

  nodes.forEach((node) => {
    const parentIds = graph.directDependentsOf(node.id);
    if (parentIds.length > 1) return;
    const parentId = parentIds[0];

    const childIds = graph.directDependenciesOf(node.id);

    const parentEdge = edges.find(
      ({ source, target }) => source === parentId && target === node.id,
    );

    if (!parentId || !parentEdge) return;

    const key = serializeGroupKey({
      parentId,
      parentCardinality: parentEdge.data.cardinality,
      children: childIds.map((childId) => {
        const childEdge = edges.find(
          ({ source, target }) => source === node.id && target === childId,
        );

        return { id: childId, cardinality: childEdge?.data.cardinality };
      }),
    });

    if (!parentChildMap[key]) {
      parentChildMap[key] = [];
    }

    parentChildMap[key]!.push(node);
  });

  for (const groupKey in parentChildMap) {
    if (parentChildMap[groupKey]!.length < MinimumChildrenForGroup) {
      delete parentChildMap[groupKey];
    }
  }

  return parentChildMap;
}

function deserializeGroupKey(key: string): GroupKey | undefined {
  try {
    return JSON.parse(key);
  } catch (error) {
    Sentry.captureException(error);
    return undefined;
  }
}

function serializeGroupKey(key: GroupKey): string {
  return JSON.stringify(key);
}
