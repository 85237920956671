import { GlobeIcon, OrbitIcon } from "@hightouchio/ui";
import { IconBox } from "../../components/icon-box";

export const DecisionEngineFlowIcon = () => (
  <IconBox
    boxSize="20px"
    icon={<OrbitIcon />}
    iconSize="20px"
    iconSx={{ color: "text.secondary" }}
    hasGradient={false}
  />
);

export const BaseGlobeIcon = () => (
  <IconBox
    boxSize="20px"
    hasGradient={false}
    icon={<GlobeIcon />}
    iconSize="20px"
    iconSx={{ color: "text.secondary" }}
  />
);
