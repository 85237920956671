import { useCallback, useState } from "react";

type SetStepType = (args: number | ((previousStep: number) => number)) => void;
type WizardStepper = (
  initialStep: number,
) => [step: number, setStep: SetStepType];

export const useWizardStepper: WizardStepper = (initialStep = 0) => {
  const [step, _setStep] = useState<number>(initialStep);

  const setStep = useCallback<SetStepType>((stepOrStepFn) => {
    if (typeof stepOrStepFn === "function") {
      return _setStep(stepOrStepFn);
    }

    const currentStep = stepOrStepFn;

    if (currentStep < 0) {
      return _setStep(0);
    }

    _setStep(currentStep);
  }, []);

  return [step, setStep];
};
