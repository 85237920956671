import { FormField } from "@hightouchio/ui";
import { Controller } from "react-hook-form";
import { ColumnSelect } from "src/components/models/column-select";
import { useModelQuery } from "src/graphql";

export const WeightField = ({ modelId }) => {
  const modelQuery = useModelQuery(
    { id: modelId },
    {
      select: (data) => data.segments_by_pk,
    },
  );

  return (
    <Controller
      name="weight.column"
      render={({ field }) => (
        <FormField
          isOptional
          label="Weight"
          description="How each event from this outcome is weighted against one another"
          tip="Column that contains the value to use as the outcome weight. E.g. “order_value”"
        >
          <ColumnSelect
            {...field}
            isClearable
            isLoading={modelQuery.isLoading}
            model={modelQuery.data}
          />
        </FormField>
      )}
    />
  );
};
