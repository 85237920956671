import { ChannelType } from "@hightouch/lib/resource-monitoring/types";
import { Box, Combobox, FormField } from "@hightouchio/ui";
import { capitalize } from "lodash";
import { Controller } from "react-hook-form";
import { ChannelDefinition } from "src/components/notification-channels/channel-definitions";

export const ChannelTypeSelect = () => {
  const options = Object.values(ChannelType).map((channelType) => {
    const definition = ChannelDefinition[channelType];
    return {
      label: capitalize(channelType),
      value: channelType,
      icon: definition.icon,
    };
  });

  return (
    <Controller
      name="channelType"
      render={({ field: { onChange, value } }) => (
        <FormField label="Type">
          <Combobox
            width="100%"
            optionAccessory={(option) => ({
              type: "icon",
              icon: () => (
                <Box sx={{ svg: { width: "18px", height: "18px" } }}>
                  <option.icon />
                </Box>
              ),
            })}
            options={options}
            onChange={onChange}
            value={value}
          />
        </FormField>
      )}
    />
  );
};
