import { VisualQueryFilter } from "@hightouch/lib/query/visual/types";
import isEqual from "lodash/isEqual";
import { useState } from "react";

export type Query = {
  filter: VisualQueryFilter;
  subsetIds: string[];
  isFastQuery: boolean;
  destinationId: string | undefined;
};

export const useLastUsedQuery = (initialQuery: Query | null) => {
  return useState<Query | null>(initialQuery);
};

export const hasQueryChanged = (
  query1: Query | null,
  query2: Query | null,
): boolean => !isEqual(query1, query2);
