import { FC, ReactNode, useState } from "react";

import {
  Row,
  Button,
  Column,
  Text,
  ButtonGroup,
  Spinner,
  Dialog,
} from "@hightouchio/ui";

import { Editor } from "src/components/editor";
import { useGetLookerLookQuery } from "src/graphql";
import { LookerIcon } from "src/ui/icons";
import { Strike } from "src/utils/strike";

type Look = {
  id: string;
  title: string;
  // description is optional on a Look
  description: string | null | undefined;
  sql?: string | null;
};

type Props = {
  lookId?: string;
  look?: Look;
  oldLook?: Look;
  actions?: ReactNode;
};

export const LookerQuery: FC<Readonly<Props>> = ({
  lookId,
  look: propsLook,
  oldLook,
  actions,
}) => {
  const [showSql, setShowSql] = useState<boolean>(false);

  const { data, isLoading } = useGetLookerLookQuery(
    {
      id: lookId ?? "",
      withSql: true,
    },
    { enabled: Boolean(lookId), select: (data) => data.getLookerLook?.look },
  );

  const look = data ?? propsLook;

  return (
    <Column border="1px" borderColor="base.border" borderRadius="md" flex={1}>
      <Row
        borderBottom="1px"
        borderColor="base.border"
        p={4}
        justify="space-between"
        align="center"
      >
        <Row gap={2} align="center">
          <LookerIcon />
          <Text fontWeight="medium" size="lg">
            Look
          </Text>
        </Row>
        <ButtonGroup>
          {look?.sql && (
            <Button onClick={() => setShowSql(true)}>View SQL</Button>
          )}
          {actions}
        </ButtonGroup>
      </Row>

      <Column gap={4} p={4}>
        {isLoading ? (
          <Spinner size="lg" m="auto" />
        ) : (
          <>
            <Column>
              <Text fontWeight="medium">Title</Text>
              <Strike _new={look?.title} old={oldLook?.title} />
            </Column>
            <Column>
              <Text fontWeight="medium">ID</Text>
              <Strike _new={look?.id} old={oldLook?.id} />
            </Column>
            {look?.description && (
              <Column>
                <Text fontWeight="medium">Description</Text>
                <Strike
                  _new={look?.description}
                  old={oldLook?.description || ""}
                />
              </Column>
            )}
          </>
        )}
      </Column>

      <Dialog
        isOpen={showSql}
        variant="info"
        title="SQL"
        width="auto"
        actions={<Button onClick={() => setShowSql(false)}>Close</Button>}
        onClose={() => setShowSql(false)}
      >
        <Editor language="sql" value={look?.sql ?? ""} />
      </Dialog>
    </Column>
  );
};
