import {
  Box,
  ChakraPopover,
  ChakraPopoverBody,
  ChakraPopoverContent,
  ChakraPopoverTrigger,
  Column,
  Portal,
  SelectorIcon,
  useDisclosure,
} from "@hightouchio/ui";
import { partition } from "lodash";
import { useMemo } from "react";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { useUser } from "src/contexts/user-context";
import { useWorkspacesQuery } from "src/graphql";
import { navigateToWorkspaces } from "src/utils/navigate";
import { switchWorkspace } from "src/utils/workspaces";
import { isPresent } from "ts-extras";

export const WorkspaceSelector = () => {
  const { workspace, user } = useUser();
  const { isOpen, onClose, onToggle } = useDisclosure();

  const { data: workspaces } = useWorkspacesQuery(
    { userId: String(user?.id) ?? "" },
    { enabled: Boolean(user?.id), select: (data) => data.workspaces },
  );

  const menuOptions = useMemo(() => {
    const [activeWorkspaces, inactiveWorkspaces] = partition(
      workspaces,
      ({ slug }) => slug === workspace?.slug,
    );
    const activeWorkspace = activeWorkspaces?.[0];
    const orderedWorkspaces = [activeWorkspace]
      .concat(inactiveWorkspaces)
      .filter(isPresent);
    const options = orderedWorkspaces?.map(({ name, id, slug }) => ({
      active: slug === workspace?.slug,
      label: name,
      onClick: () => switchWorkspace(id, `/${slug}`),
    }));
    return options ?? [];
  }, [workspaces]);

  return (
    <Box mx={3} cursor="pointer">
      <ChakraPopover
        isLazy
        isOpen={isOpen}
        onClose={onClose}
        placement="bottom-start"
      >
        <ChakraPopoverTrigger>
          <Box
            onClick={onToggle}
            _focus={{
              borderColor: isOpen ? "white" : "rgba(255,255,255,.5)",
              bg: isOpen ? "rgba(255, 255, 255, 0.08)" : "transparent",
            }}
            _focusVisible={{
              borderColor: "transparent",
              boxShadow: "0 0 0 4px rgba(255, 255, 255, 0.7)",
            }}
            _hover={{
              borderColor: isOpen ? "white" : "rgba(255,255,255,.5)",
              bg: "rgba(255, 255, 255, 0.08)",
            }}
            alignItems="center"
            justifyContent="space-between"
            bg="transparent"
            border="1px solid rgba(255,255,255,.3)"
            borderRadius="6px"
            display="flex"
            gap={2}
            outline="none"
            tabIndex={0}
            px={2}
            height="48px"
            transition="150ms all"
          >
            <Box
              flex={1}
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
              color="white"
              fontWeight="semibold"
              display={["none", "none", "none", "block"]}
              title={workspace?.name}
              fontSize="13px"
              fontFamily="'Sharp Sans Display No 1'"
            >
              {workspace?.name}
            </Box>
            <Box
              as={SelectorIcon}
              color="rgba(255,255,255,.7)"
              fontSize="16px"
            />
          </Box>
        </ChakraPopoverTrigger>
        <Portal>
          <ChakraPopoverContent
            border="1px solid"
            borderColor="base.border"
            overflow="hidden"
            width="280px"
          >
            <ChakraPopoverBody p={0} overflow="hidden">
              <Column borderBottom="1px solid" borderColor="base.border">
                <Box
                  as="button"
                  fontWeight="medium"
                  textAlign="left"
                  onClick={navigateToWorkspaces}
                  p={2}
                  py={3}
                  _hover={{ bg: "forest.100" }}
                >
                  View all workspaces
                </Box>
              </Column>
              <Column overflow="auto" maxHeight="70vh">
                {menuOptions.map(({ active, label, onClick }) => (
                  <Column
                    key={label}
                    p={2}
                    as="button"
                    onClick={onClick}
                    pointerEvents={active ? "none" : undefined}
                    _hover={{ bg: "gray.100" }}
                    bg={active ? "forest.100" : undefined}
                  >
                    <TextWithTooltip>{label}</TextWithTooltip>
                  </Column>
                ))}
              </Column>
            </ChakraPopoverBody>
          </ChakraPopoverContent>
        </Portal>
      </ChakraPopover>
    </Box>
  );
};
