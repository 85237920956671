import { createContext, ReactElement, ReactNode } from "react";

import { FieldValues, FormProvider, UseFormReturn } from "react-hook-form";

export type UseHightouchFormReturn<
  T extends FieldValues,
  C extends Record<string, unknown>,
  R,
> = {
  // We need to have a mouse event since it's often used directly in buttons
  submit: (e?: any, ctx?: C) => Promise<R | undefined>;
} & UseFormReturn<T>;

type HightouchFormContextType<C = any, R = any> = {
  // We need to have a mouse event since it's often used directly in buttons
  submit: (e?: any, ctx?: C) => Promise<R>;
};

export const HightouchFormContext = createContext<HightouchFormContextType>(
  {} as any,
);

export const Form = <
  T extends FieldValues,
  C extends Record<string, unknown>,
  R,
>({
  form,
  children,
}: {
  form: UseHightouchFormReturn<T, C, R>;
  children: ReactNode;
}): ReactElement => {
  const { submit, ...context } = form;

  return (
    <HightouchFormContext.Provider value={{ submit }}>
      <FormProvider {...context}>{children}</FormProvider>
    </HightouchFormContext.Provider>
  );
};
