import { Combobox, FormField } from "@hightouchio/ui";
import { FC } from "react";
import { Controller } from "react-hook-form";

import { ColumnSelect } from "src/components/models/column-select";
import { SchemaObjectType } from "src/pages/schema/types";

export const ColumnField: FC<
  Readonly<{
    name: string;
    label: string;
    tip?: string;
    object?: SchemaObjectType;
    columns?: Array<{ label: string; value: string }>;
    isDisabled?: boolean;
    isLoading?: boolean;
    isOptional?: boolean;
    placeholder?: string;
  }>
> = ({
  name,
  label,
  tip,
  object,
  columns = [],
  placeholder = "Select a column...",
  isOptional,
  ...props
}) => {
  return (
    <Controller
      name={name}
      render={({ field, fieldState: { error } }) => (
        <FormField
          label={label}
          tip={tip}
          error={error?.message}
          isOptional={isOptional}
        >
          {object ? (
            <ColumnSelect
              {...field}
              {...props}
              isClearable={isOptional}
              isInvalid={Boolean(error)}
              model={object}
            />
          ) : (
            <Combobox
              {...field}
              {...props}
              isClearable={isOptional}
              isInvalid={Boolean(error)}
              placeholder={placeholder}
              options={columns}
            />
          )}
        </FormField>
      )}
    />
  );
};
