import {
  MonitoredResourceType,
  ParentResourceTypes,
} from "@hightouch/lib/resource-monitoring/types";
import {
  SubscribeableResourcesQuery,
  useAllResourceSubscriptionsQuery,
  useSubscribeableResourcesQuery,
} from "src/graphql";
import { useMemo } from "react";

type ChannelSubscription = Readonly<{
  id: string;
  resource_id: string;
  resource_type: string;
  isTemplate: boolean;
  channel: {
    id: string;
    type: string;
    config: any;
    channel_type: string;
  };
}>;

type DefaultSubscription = {
  id: string;
  parent_resource_type: string;
  parent_resource_id: string;
  channel: {
    id: string;
    type: string;
    config: any;
    channel_type: string;
  };
};

interface Props {
  resourceType?: string;
  resourceId?: string;
  parentResourceType?: string;
  parentResourceId?: string;
}

interface SubscribedResourcesHook {
  resourceSubscriptions: ChannelSubscription[];
  availableResources?: SubscribeableResourcesQuery;
  defaultSubscriptions?: DefaultSubscription[];
}

/**
 * Resources may be publishing to notification channels via a direct subscription
 * or via a subscription on a _template_ parent resource.
 *
 * This hook resolved resource subscriptions to channels.
 */
export const useSubscribedResources = ({
  resourceType,
  resourceId,
  parentResourceId,
  parentResourceType,
}: Props): SubscribedResourcesHook => {
  const { data: subscriptions } = useAllResourceSubscriptionsQuery(
    {
      resourceType,
      resourceId,
      parentResourceId,
      parentResourceType,
    },
    { suspense: true },
  );
  const { data: availableResources } = useSubscribeableResourcesQuery(
    undefined,
    { suspense: true },
  );

  return useMemo(() => {
    const manualResources =
      subscriptions?.resource_notification_channels.map((rnc) => {
        return {
          id: rnc.id,
          resource_id: rnc.resource_id,
          resource_type: rnc.resource_type,
          isTemplate: false,
          channel: {
            id: rnc.channel.id,
            type: rnc.channel.channel_type,
            config: rnc.channel.config,
            channel_type: rnc.channel.channel_type,
          },
        };
      }) ?? [];

    const templateResources: ChannelSubscription[] = [];

    const defaultSubscriptions: DefaultSubscription[] = [];

    for (const templateChannel of subscriptions?.notification_channel_templates ??
      []) {
      // TODO: Cleanup this logic at some future point, but for the foreseeable future
      // syncs are going to be the only resource with template notifications on them.
      if (
        templateChannel.parent_resource_type === ParentResourceTypes.Destination
      ) {
        const destinationSyncs = availableResources?.syncs.filter(
          (sync) => sync.destination?.id === templateChannel.parent_resource_id,
        );
        if (!destinationSyncs) {
          continue;
        }
        for (const sync of destinationSyncs) {
          templateResources.push({
            id: templateChannel.id,
            resource_id: sync.id.toString(), // :(
            resource_type: MonitoredResourceType.Sync,
            isTemplate: true,
            channel: {
              id: templateChannel.channel.id,
              type: templateChannel.channel.channel_type,
              config: templateChannel.channel.config,
              channel_type: templateChannel.channel.channel_type,
            },
          });
        }
      }
      defaultSubscriptions.push({
        id: templateChannel.id,
        parent_resource_type: templateChannel.parent_resource_type,
        parent_resource_id: templateChannel.parent_resource_id,
        channel: {
          id: templateChannel.channel.id,
          type: templateChannel.channel.channel_type,
          config: templateChannel.channel.config,
          channel_type: templateChannel.channel.channel_type,
        },
      });
    }

    return {
      resourceSubscriptions: [...manualResources, ...templateResources],
      defaultSubscriptions,
      availableResources,
    };
  }, [subscriptions, availableResources, parentResourceId, parentResourceType]);
};
