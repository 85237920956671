import { FC } from "react";

import { Box, CopyIcon, IconButton, Row, useToast } from "@hightouchio/ui";

import { useClipboard } from "use-clipboard-copy";
import { TextWithTooltip, TextWithTooltipProps } from "./text-with-tooltip";

export const ClickToCopy: FC<
  Readonly<TextWithTooltipProps & { value: string }>
> = ({ children, value, ...rest }) => {
  const { toast } = useToast();
  const clipboard = useClipboard({
    onSuccess: () => {
      toast({
        variant: "success",
        id: "copy-to-clipboard",
        title: "Copied to clipboard",
      });
    },
  });

  const onClick = () => clipboard.copy(JSON.stringify(value));

  return (
    <Row
      display="flex"
      gap={2}
      alignItems="center"
      overflow="hidden"
      cursor="pointer"
      onClick={onClick}
      _hover={{
        "> span": {
          display: "block",
        },
      }}
    >
      <TextWithTooltip message="message" {...rest}>
        {children}
      </TextWithTooltip>

      <Box as="span" display="none">
        <IconButton
          onClick={onClick}
          icon={CopyIcon}
          aria-label="Copy text to clipboard"
        />
      </Box>
    </Row>
  );
};
