import { FormField, RadioGroup, Radio } from "@hightouchio/ui";
import { Controller } from "react-hook-form";

export const Fallback = () => {
  return (
    <Controller
      name="config.fallback"
      render={({ field }) => (
        <FormField
          label="Fallback"
          description="If an item that was supposed to be recommended falls out of the database just before a scheduled send, decision engine attempts to replace it with the next best item recommendation. In the unlikely scenario that all the next best items have also been removed, what should we do?"
        >
          <RadioGroup {...field} orientation="vertical">
            <Radio value="none" label="Do not trigger the action" />
            <Radio value="random" label="Send random items" />
          </RadioGroup>
        </FormField>
      )}
    />
  );
};
