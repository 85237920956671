import { FC } from "react";

import { Text } from "@hightouchio/ui";
import { useNavigate } from "src/router";

import { TraitQuery } from "src/graphql";
import { Table, TableColumn } from "src/ui/table";

type Props = {
  trait: TraitQuery["trait_definitions_by_pk"];
};

export const UsageTable: FC<Readonly<Props>> = ({ trait }) => {
  const navigate = useNavigate();

  if (!trait || trait?.archived_at) {
    return null;
  }

  if (trait.is_template && trait.active_traits.length === 0) {
    return <Text color="text.secondary">No trait usage yet.</Text>;
  }

  if (!trait.is_template && trait.trait_references.audiences.length === 0) {
    return <Text color="text.secondary">No audience usage yet.</Text>;
  }

  if (trait.is_template) {
    return (
      <Table
        data={trait.active_traits}
        columns={traitTemplateColumns}
        onRowClick={(trait) => navigate(`/traits/active/${trait.id}`)}
      />
    );
  } else {
    return (
      <Table
        data={trait.trait_references.audiences}
        columns={traitColumns}
        onRowClick={(audience) => navigate(`/audiences/${audience.id}`)}
      />
    );
  }
};

const traitTemplateColumns: TableColumn[] = [
  {
    name: "Traits",
    cell: ({ name }) => name,
    cellSx: { fontWeight: "medium", cusror: "pointer" },
  },
];

const traitColumns: TableColumn[] = [
  {
    name: "Audiences",
    cell: ({ name }) => name,
    cellSx: { fontWeight: "medium", cusror: "pointer" },
  },
];
