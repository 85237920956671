import { useMemo } from "react";

import { endOfDay, formatISO, startOfDay } from "date-fns";
import stableStringify from "fast-json-stable-stringify";
import jsonLogic from "json-logic-js";
import { Delta, create } from "jsondiffpatch-rc";
import { isEqual } from "lodash";

import {
  ApprovedResourceDraftsQuery,
  ChangelogItem,
  useApprovedResourceDraftsQuery,
  useChangelogQuery,
  useResourceDeploymentHistoryQuery,
  useWorkspacesQuery,
} from "src/graphql";

export type QueryableChangeLogResource =
  | "Model"
  | "Audience"
  | "Sync"
  | "Audience Schema"
  | "Model Column";
export interface UseResourceActivity {
  resource: QueryableChangeLogResource;
  period: "day" | "week" | "month" | "year" | "all";
  resourceId?: string;
  // Ignore creation and deletion
  ignoreColumns?: string[];
}

interface FormattedDeploymentHistory {
  from_workspace_name: string;
  created_at: string;
  created_by_name: string;
}
export interface ResourceActivity {
  metadata: ChangelogItem;
  diff?: Delta;
  hasDraft?: boolean;
  approvedDraft?: ApprovedResourceDraftsQuery["drafts"][0];

  // Was the resource change the result of a deployment from another workspace?
  deployment?: FormattedDeploymentHistory;
}

export const ResourceActivityDiffer = (
  ignoreColumns: string[],
  additionalObjectHashKeys: string[] = [],
) => {
  const defaultObjectHashAttributes = ["_id", "id", "to"];
  return create({
    objectHash: function (obj: any) {
      const idValues = [
        ...additionalObjectHashKeys,
        ...defaultObjectHashAttributes,
      ].map((key) => obj[key]);
      return idValues.find((key) => key) || stableStringify(obj);
    },
    propertyFilter: function (name: string, context: any) {
      // We explicitly ignore null -> '' changes or '' -> null changes
      if (context.left[name] === null && context.right[name] === "") {
        return false;
      }
      if (context.left[name] === "" && context.right[name] === null) {
        return false;
      }
      return ![
        "created_at",
        "updated_at",
        "updated_by",
        "approved_draft_id",
        "draft",
        "draft_id",
        "schedule_updated_at",
        ...ignoreColumns,
      ].includes(name);
    },
    arrays: {
      detectMove: true,
    },
  });
};

export const useResourceActivity = ({
  resource,
  period,
  resourceId,
  ignoreColumns = [],
}: UseResourceActivity): {
  activity: ResourceActivity[];
  loading: boolean;
  attributionLoading: boolean;
  refetch: () => void;
} => {
  const offsetEndPeriod = new Date(
    new Date().setDate(new Date().getDate() + 1),
  );
  const periodToDates = {
    day: {
      start: new Date(new Date().setDate(new Date().getDate() - 1)),
      end: offsetEndPeriod,
    },
    week: {
      start: new Date(new Date().setDate(new Date().getDate() - 7)),
      end: offsetEndPeriod,
    },
    month: {
      start: new Date(new Date().setDate(new Date().getDate() - 30)),
      end: offsetEndPeriod,
    },
    year: {
      start: new Date(new Date().setDate(new Date().getDate() - 365)),
      end: offsetEndPeriod,
    },
    all: {
      start: new Date(0),
      end: offsetEndPeriod,
    },
  };

  const { data: workspaces } = useWorkspacesQuery(undefined, {
    select: (data) => data.workspaces,
  });

  const {
    isLoading: deploymentHistoryLoading,
    data: changelogDeploymentLookup,
  } = useResourceDeploymentHistoryQuery(
    { resourceId: resourceId || "" },
    {
      // This is hacky but we need resource id to be numeric here
      enabled: !!resourceId && !Number.isNaN(Number(resourceId)),
      select: ({ deployment_history }) => {
        // We get a list of the workspaces the user can 'see'
        const workspaceLookup = (workspaces || []).reduce(
          (acc, workspace) => {
            acc[workspace.id] = workspace.name;
            return acc;
          },
          {} as Record<string, string>,
        );

        // Now we build the lookup of changelog ID to deployment
        const lookup = {};
        for (const deployment of deployment_history) {
          lookup[deployment.changelog_id] = {
            from_workspace_name:
              workspaceLookup[deployment.from_workspace] || "another workspace",
            created_at: deployment.created_at,
            created_by_name: deployment.user.name,
          };
        }
        return lookup;
      },
    },
  );

  const {
    data: resourceChanges,
    isLoading,
    refetch,
    isRefetching,
  } = useChangelogQuery(
    {
      filters: {
        start_date: formatISO(startOfDay(periodToDates[period].start)),
        end_date: formatISO(endOfDay(periodToDates[period].end)),
        offset: 0,
        limit: 400,
        resource_id: resourceId,
        filtered_resources: [resource],
      },
    },
    {
      enabled: !!resourceId,
      select: (data) => {
        return data.auditLog.items.map(
          (item): ResourceActivity => ({
            metadata: {
              ...item,
              user_name:
                item.user_name === "GitSync" ? "Git Sync" : item.user_name,
            },
            diff: ResourceActivityDiffer(ignoreColumns).diff(
              item.old,
              item.new,
            ),
            hasDraft: !!item.new?.approved_draft_id,
          }),
        );
      },
    },
  );

  const { data: approvedDraftLookup, isLoading: draftsLoading } =
    useApprovedResourceDraftsQuery(
      { resourceId: resourceId || "" },
      {
        enabled: !!resourceId,
        select: (data) => {
          const lookup = {};
          for (const draft of data.drafts) {
            lookup[draft.id] = draft;
          }
          return lookup;
        },
      },
    );

  const changesWithAttribution = useMemo(() => {
    if (!resourceChanges) {
      return [];
    }

    return resourceChanges.map((change) => {
      const deployment = changelogDeploymentLookup?.[change.metadata.id];
      const approvedDraft =
        approvedDraftLookup?.[change.metadata.new?.approved_draft_id];
      return {
        ...change,
        deployment,
        approvedDraft,
      };
    });
  }, [isLoading, draftsLoading, deploymentHistoryLoading]);

  return {
    activity: changesWithAttribution || [],
    loading: isLoading || isRefetching,
    attributionLoading: draftsLoading || deploymentHistoryLoading,
    refetch,
  };
};

function constructJsonLogic(accessor: string): any {
  return {
    var: accessor,
  };
}

export type ObjectDiffOperation = "updated" | "removed" | "added";
export type ObjectPropertyDiff = {
  type: "value";
  value: any | null;
  operation: ObjectDiffOperation;
};
export type ArrayDiff = {
  type: "array";
  array: { value: any | null; operation: ObjectDiffOperation }[];
};
export type NestedDiff = {
  type: "nested";
  nested: { [key: string]: ObjectPropertyDiff | ArrayDiff | NestedDiff };
};
export type ParsedDiff = {
  parsedDiff: ObjectPropertyDiff | ArrayDiff | NestedDiff;
  oldValue: any;
  newValue: any;
};
/**
 * Takes an accessor for a diff and returns the value and operation parsing using: https://github.com/benjamine/jsondiffpatch/blob/master/docs/deltas.md
 *
 * Because of the complexity of handling arrays, the JSON differ MUST be configured with detectMove=false and the objecthash function must include
 * the IDs of array items that move.
 *
 * @param diff diff of old and new from changelog using jsondiffpatch
 * @param accessor a string accessor to the value you want to get from the diff e.g. " config.spreadsheet.id"
 * @param overrideDiffAccessor an object to override the accessor e.g. { "config.spreadsheet.id": "config.spreadsheet.id" }
 */
export function parseDiff(
  activity: {
    metadata: { old: ChangelogItem["old"]; new: ChangelogItem["new"] };
    diff?: Delta;
  },
  accessor: string,
  overrideDiffAccessor?: Record<string, unknown>,
): ParsedDiff | null {
  const diff = activity.diff;
  // We may get null diffs if the diff only included ignored terms (e.g. created_at, updated_at)
  if (!diff) {
    return null;
  }

  const value = jsonLogic.apply(
    overrideDiffAccessor ?? constructJsonLogic(accessor),
    diff,
  );
  if (!value) {
    return null;
  }
  const oldValue = jsonLogic.apply(
    constructJsonLogic(accessor),
    activity.metadata.old,
  );
  const newValue = jsonLogic.apply(
    constructJsonLogic(accessor),
    activity.metadata.new,
  );

  return {
    parsedDiff: parseDiffObject(oldValue, newValue, value),
    oldValue,
    newValue,
  };
}

function parseDiffObject(
  oldValue,
  newValue,
  value,
): ObjectPropertyDiff | ArrayDiff | NestedDiff {
  // Deltas on leaves will always produce arrays - the only reason we get an object instead is if we are not on a leaf
  // or the original object leaf is an array.
  if (!Array.isArray(value)) {
    // If the value is an array in both the old and new object then we need to process it differently
    if (Array.isArray(oldValue) && Array.isArray(newValue)) {
      return {
        type: "array",
        array: Object.keys(value)
          .map((key: string) => {
            // jsondiffpatch uses this key to indicate that the array has been changed
            if (key === "_t") {
              return null;
            }
            const arrayValue = value[key];
            // This should represent a deletion
            if (key.startsWith("_") && arrayValue.length === 3) {
              return {
                value: oldValue[parseInt(key.slice(1), 10)],
                operation: "removed",
              };
            }

            // Array value updated - we return the value it was updated to
            if (!Array.isArray(arrayValue)) {
              return {
                value: newValue[parseInt(key, 10)],
                operation: "updated",
              };
            }

            // Item added to array
            if (arrayValue.length === 1) {
              return {
                value: newValue[parseInt(key, 10)],
                operation: "added",
              };
            }
            return null;
          })
          .filter((item) => item) as ArrayDiff["array"],
      };
    }
    // If the value is an object in both the old and new object then we need to process it differently
    if (
      typeof oldValue === "object" &&
      typeof newValue === "object" &&
      oldValue !== null &&
      newValue !== null
    ) {
      return {
        type: "nested",
        nested: Object.keys(value).reduce((acc, key) => {
          const nestedValue = value[key];
          const nestedOldValue = oldValue[key];
          const nestedNewValue = newValue[key];
          acc[key] = parseDiffObject(
            nestedOldValue,
            nestedNewValue,
            nestedValue,
          );
          return acc;
        }, {}),
      };
    }
  }

  // This should represent a deletion
  if (isEqual(value, [oldValue, 0, 0])) {
    return {
      type: "value",
      value: value[0],
      operation: "removed",
    };
  }

  // This should represent an update
  if (isEqual(value, [oldValue, newValue])) {
    return {
      type: "value",
      value: value[1],
      operation: "updated",
    };
  }

  // This should represent an addition
  if (isEqual(value, [newValue]) && !oldValue) {
    return {
      type: "value",
      value: value[0],
      operation: "added",
    };
  }

  return {
    type: "value",
    value: value,
    operation: "updated",
  };
}
