import { FC } from "react";
import { useToast } from "@hightouchio/ui";

import { useOutletContext } from "src/router";
import { Context } from ".";
import { useUpdateSplitSyncAssignmentsMutation } from "src/graphql";
import { SplitsSyncAssignmentForm } from "src/components/splits-v2/splits-sync-assignment-form";

export const Splits: FC = () => {
  const { sync } = useOutletContext<Context>();
  const { toast } = useToast();
  const model = sync?.segment;

  const splits = (sync?.destination_instance_splits ?? []).map(
    ({ split }) => split,
  );

  const updateSplitSyncAssignments = useUpdateSplitSyncAssignmentsMutation();

  const saveSplitSyncAssignments = async (selectedSplitIds) => {
    // In order to do an update, we remove the existing destination_instance-split rows (for this sync)
    // and then insert the selected destination_instance-splits (for this sync)
    const removeObjects = splits.map((split) => ({
      split_id: { _eq: split.id },
      destination_instance_id: { _eq: sync?.id },
    }));

    const addObjects = selectedSplitIds.map((splitId: string) => ({
      split_id: splitId,
      destination_instance_id: sync?.id,
    }));

    try {
      await updateSplitSyncAssignments.mutateAsync({
        addObjects,
        removeObjects,
      });
    } catch (error) {
      toast({
        id: "change-split-sync-assignments",
        title: "There was an error saving your splits configuration.",
        variant: "error",
      });

      throw error;
    }

    toast({
      id: "change-split-sync-assignments",
      title: "Successfully updated split group assignments.",
      variant: "success",
    });
  };

  return (
    <SplitsSyncAssignmentForm
      audienceId={model?.id}
      splitsAssignedToCurrentSync={splits}
      onSubmit={saveSplitSyncAssignments}
    />
  );
};
