import { ColumnType, ConditionType } from "@hightouch/lib/query/visual/types";
import {
  Combobox,
  EmptyState,
  Heading,
  Row,
  Spinner,
  Text,
} from "@hightouchio/ui";
import { LinkButton } from "src/router";
import { useEffect, useMemo, useState } from "react";
import { useDebounce } from "use-debounce";
import { useOutletContext } from "src/router";

import { Page } from "src/components/layout";
import { QueryType } from "src/types/models";
import { useModelRun } from "src/utils/models";
import { OutletContext } from "..";
import { Messages } from "./components/messages";
import { Users } from "./components/users";
import genericPlaceholder from "src/assets/placeholders/generic.svg";
import useQueryState from "src/hooks/use-query-state";
import {
  DecisionEngineFlowQuery,
  useDecisionEngineFlowQuery,
} from "src/graphql";

export const Loader = () => {
  const { engine } = useOutletContext<OutletContext>();
  const [flowId, setFlowId] = useQueryState("flow", engine.flows[0]?.id);

  const { data: flow } = useDecisionEngineFlowQuery(
    {
      id: String(flowId),
    },
    {
      enabled: Boolean(flowId),
      select: (data) => data.decision_engine_flows_by_pk,
    },
  );

  if (!engine.flows.length) {
    return (
      <Page
        title="Flows"
        contain
        fullWidth
        sx={{ bg: "base.lightBackground", p: 0 }}
        header={
          <Row
            justify="space-between"
            py={4}
            bg="white"
            px={6}
            borderBottom="1px"
            borderColor="base.border"
          >
            <Heading size="xl">Inspector</Heading>
          </Row>
        }
      >
        <EmptyState
          imageUrl={genericPlaceholder}
          m={6}
          title="No flows"
          message="Add a flow to start using the inspector"
          actions={
            <LinkButton href="/ai/flows/new" variant="primary">
              Add flow
            </LinkButton>
          }
        />
      </Page>
    );
  }

  return (
    <Page
      title="Flows"
      contain
      fullWidth
      sx={{ bg: "base.lightBackground", p: 0 }}
      header={
        <Row
          align="center"
          py={4}
          gap={4}
          bg="white"
          px={6}
          borderBottom="1px"
          borderColor="base.border"
        >
          <Heading size="xl">Inspector</Heading>
          <Combobox
            width="4xs"
            value={flowId ?? undefined}
            onChange={(value) => setFlowId(value ?? "")}
            options={engine.flows}
            optionValue={(o) => o.id}
            optionLabel={(o) => o.name}
          />
        </Row>
      }
    >
      {flow ? <Inspector flow={flow} /> : <Spinner size="lg" m="auto" />}
    </Page>
  );
};

export const Inspector = ({
  flow,
}: {
  flow: NonNullable<DecisionEngineFlowQuery["decision_engine_flows_by_pk"]>;
}) => {
  const { engine } = useOutletContext<OutletContext>();
  const [userSearch, setUserSearch] = useState("");
  const [debouncedSearch] = useDebounce(userSearch, 1000);

  const primaryKey = String(engine.segment.primary_key);

  const conditions = useMemo(() => {
    return [
      {
        type: ConditionType.Property,
        value: debouncedSearch,
        operator: "contains",
        property: {
          name: primaryKey,
          type: "raw",
          modelId: engine.segment.id,
        },
        propertyType: ColumnType.String,
        propertyOptions: {
          caseSensitive: false,
        },
      },
    ];
  }, [debouncedSearch]);

  const { runQuery, rows, numRowsWithoutLimit, loading, page, setPage } =
    useModelRun(
      {
        query_type: QueryType.Visual,
        visual_query_parent_id: engine.segment.id,
        connection: engine.segment.connection,
        subsets: flow.audience!.subsets.map((s) => ({
          subset_value: {
            id: s.subset_value_id,
          },
        })),
        visual_query_filter: {
          conditions: [
            ...flow.audience!.visual_query_filter.conditions,
            ...conditions,
          ],
        },
      },
      {
        columns: engine.segment.columns,
      },
    );

  const usersByPrimaryKey = useMemo(() => {
    return rows
      ? rows.reduce((acc, row) => {
          return { ...acc, [row[primaryKey]]: row };
        }, {})
      : {};
  }, [rows, primaryKey]);

  useEffect(() => {
    runQuery({ limit: false });
  }, [conditions, flow]);

  return (
    <Row height="100%">
      <Users
        page={page}
        setPage={setPage}
        users={rows}
        loading={loading}
        search={userSearch}
        onSearchChange={setUserSearch}
        numRowsWithoutLimit={numRowsWithoutLimit}
      />
      <Messages
        flowMessages={flow.messages}
        usersByPrimaryKey={usersByPrimaryKey}
      />
    </Row>
  );
};

export const KeyValue = ({ name, value }) => {
  return (
    <Row gap={2}>
      <Text fontWeight="medium" color="text.secondary">
        {name}:
      </Text>
      <Text>{value}</Text>
    </Row>
  );
};
