import { NavSection } from "src/components/layout/nav/nav-section";

import { Icon } from "src/components/layout/nav/icons/settings";
import { ActiveSectionProps } from "../utils";
import { UseSectionHook } from "./types";

export const useSettingsSection: UseSectionHook = () => {
  return {
    title: "Settings",
    isEnabled: true,
    links: [
      {
        href: "/settings",
        label: "Workspace",
      },
      {
        href: "/organization",
        label: "Organization",
      },
    ],
  };
};

export const Settings = (props: ActiveSectionProps) => {
  const section = useSettingsSection();
  return <NavSection isInFooter icon={Icon} {...section} {...props} />;
};
