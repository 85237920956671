import { Row } from "@hightouchio/ui";
import { maxBy } from "lodash";
import { FC, useMemo } from "react";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { AnalyticsCell } from "src/pages/analytics/analytics-table/analytics-cell";
import { Table, TableColumn } from "src/ui/table";
import { accurateCommaNumber } from "src/utils/numbers";
import { HexColors } from "../constants";

function transformTableData(
  data: {
    value: string | boolean;
    count: number;
    percentage?: number;
  }[],
): {
  value: string | boolean;
  count: number;
  percentage?: number;
}[] {
  if (data.length === 0) return [];

  if (data.length === 1) {
    const value = data[0]!.value;

    if (typeof value === "boolean") {
      return [
        ...data,
        {
          value: !value,
          count: 0,
          percentage: 0,
        },
      ];
    }
  }

  return data;
}
export const AudiencePropertyBreakdownTable: FC<{
  data: {
    value: string | boolean;
    count: number;
    percentage?: number;
  }[];
}> = ({ data }) => {
  const maxCount = useMemo(
    () => maxBy(data, (row) => row.count)?.count || 1,
    [data],
  );

  const columns: TableColumn[] = [
    {
      min: "25%",
      max: "25%",
      cell: ({ value }) => (
        <TextWithTooltip message={value}>{String(value)}</TextWithTooltip>
      ),
      cellSx: {
        border: "0 !important",
        padding: "0 !important",
        justifyContent: "right",
      },
    },
    {
      cellSx: { border: "0 !important" },
      min: "75%",
      max: "75%",
      cell: ({ count, value }, index) => {
        const width = 100 * (count / maxCount);
        const color = HexColors[index % HexColors.length]!.base;
        return (
          <AnalyticsCell
            color={color}
            metricName={value}
            width={width}
            value={count}
            tooltipValue={
              count === 1
                ? `${count} user`
                : `${accurateCommaNumber(count)} users`
            }
          />
        );
      },
    },
  ];
  return (
    <Row>
      <Table
        showHeaders={false}
        columns={columns}
        data={transformTableData(data)}
        rowHeight="30px"
        backgroundColor="unset"
      />
    </Row>
  );
};
