import { FC, ReactNode, useEffect, useMemo, useState } from "react";

import {
  CloseIcon,
  Column,
  GroupedCombobox,
  EmptyState,
  Heading,
  IconButton,
  Row,
  Spinner,
} from "@hightouchio/ui";
import { LinkButton } from "src/router";
import { Link } from "src/router";
import noop from "lodash/noop";
import { Helmet } from "react-helmet";
import {
  Outlet,
  Route,
  Routes,
  useNavigate,
  useSearchParams,
} from "src/router";
import { ReactFlowProvider } from "reactflow";

import "reactflow/dist/style.css";
import schemaPlaceholder from "src/assets/placeholders/schema.svg";
import sourcePlaceholder from "src/assets/placeholders/source.svg";
import { Drawer } from "src/components/drawer";
import { PageHeader } from "src/components/layout/header/page-header";
import { PermissionedLinkButton } from "src/components/permission";
import { Warning } from "src/components/warning";
import { useHeaderHeight } from "src/contexts/header-height-context";
import {
  useAudienceSchemaGraphQuery,
  useAudienceSetupQuery,
  useObjectQuery,
  useSourceQuery,
} from "src/graphql";
import { usePersistedState } from "src/hooks/use-persisted-state";
import { SchemaSettings } from "src/pages/schema/settings";
import { QueryType } from "src/types/models";
import { SchemaModelType } from "src/types/schema";
import { OverlaySpinner, PageSpinner } from "src/components/loading";
import { usePylonDrawerOffset } from "src/components/drawer/use-pylon-drawer-offset";

import { CreateSchemaModelWizard } from "./create/create";
import { Graph, GraphBackground } from "./graph/graph";
import { SchemaObject } from "./view/view";

export const Schema: FC = () => {
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const sourceId = searchParams.get("source");
  const parentModelId = searchParams.get("parent");
  const type = searchParams.get("type") as SchemaModelType;
  const selectedId = searchParams.get("id");
  const persistedSource = usePersistedState("schema-source", "");

  const { data } = useAudienceSetupQuery(undefined, {
    refetchOnMount: "always",
    onSuccess: (data) => {
      const sources = data?.connections?.filter((source) =>
        source?.definition?.supportedQueries?.includes(QueryType.Visual),
      );
      if (
        !sourceId ||
        !sources?.find((source) => String(source.id) === String(sourceId))
      ) {
        let id;
        if (data.segments?.length) {
          const counts = data.segments.reduce((acc, segment: any) => {
            const connectionId = segment?.connection?.id;
            acc[connectionId] = (acc[connectionId] || 0) + 1;
            return acc;
          }, {});
          // Find the connection with the highest count and return its ID
          id = Object.entries(counts).reduce(
            (max: any, [connectionId, count]: any) => {
              if (count > max[1]) {
                return [connectionId, count];
              } else {
                return max;
              }
            },
            ["", -1],
          )[0];
        } else {
          id = sources?.[0]?.id;
        }
        setSearchParams({ source: id }, { replace: true });
      }
      setLoading(false);
    },
  });

  const { data: graph, isLoading: isGraphLoading } =
    useAudienceSchemaGraphQuery(
      { sourceId: sourceId ?? "", parentModelId },
      {
        enabled: Boolean(sourceId),
        select: (data) => data.getSchemaGraph,
        refetchOnMount: "always",
      },
    );

  const closeDrawer = () => {
    setSearchParams((oldParams) => {
      oldParams.delete("id");
      return oldParams;
    });
  };

  const allParentModels = data?.segments;
  const sources = data?.connections?.filter((source) =>
    source?.definition?.supportedQueries?.includes(QueryType.Visual),
  );
  const parentModels = allParentModels?.filter(
    (m) => m.connection?.id == sourceId,
  );

  const options = useMemo(() => {
    return [
      {
        label: "Sources",
        options:
          sources?.map((source) => ({
            value: `${source.id}`,
            label: source.name,
            icon: source.definition.icon,
          })) ?? [],
      },
    ];
  }, [sources]);

  const isPlaceholder = !sources?.length || (!parentModels?.length && !type);

  useEffect(() => {
    if (sourceId) {
      if (persistedSource.value !== sourceId) {
        persistedSource.set(sourceId);
      }
    } else if (persistedSource.value) {
      setSearchParams({ source: persistedSource.value }, { replace: true });
    }
  }, [sourceId]);

  if (loading) {
    return <PageSpinner />;
  }

  return (
    <Column width="100%" height="100%">
      <PageHeader />

      <Helmet>
        <title>Schema</title>
      </Helmet>

      <Row
        align="center"
        gap={6}
        height="64px"
        flexShrink={0}
        px={4}
        boxShadow="0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)"
        justify="space-between"
        zIndex={1}
      >
        <Row align="center" gap={6}>
          <Heading size="xl">Schema</Heading>
          {Boolean(sources?.length) && (
            <GroupedCombobox
              optionAccessory={(option: any) => ({
                type: "image",
                url: option.icon,
              })}
              value={sourceId ?? ""}
              optionGroups={options}
              onChange={(value) => {
                if (value) {
                  setSearchParams({ source: value });
                }
              }}
              width="xs"
            />
          )}
        </Row>
        <Row gap={4} align="center">
          <Link href="/schema">Legacy schema</Link>
          {!isPlaceholder && <LinkButton href="settings">Settings</LinkButton>}
        </Row>
      </Row>
      {!sources?.length ? (
        <Column
          pos="relative"
          height="100%"
          width="100%"
          justify="center"
          align="center"
        >
          <Column
            maxWidth="720px"
            width="100%"
            p={10}
            sx={{ "& > div": { boxShadow: "md", zIndex: 1, bg: "white" } }}
          >
            <EmptyState
              imageUrl={sourcePlaceholder}
              title="This workspace contains no sources"
              message="Define a source where your data is stored and then create the schema to describe how your data source will be queried by Audiences."
            />
          </Column>
          <GraphBackground />
        </Column>
      ) : !parentModels?.length && !type ? (
        <Column
          pos="relative"
          height="100%"
          width="100%"
          justify="center"
          align="center"
        >
          <Column
            maxWidth="720px"
            width="100%"
            p={10}
            sx={{ "& > div": { boxShadow: "md", zIndex: 1, bg: "white" } }}
          >
            <EmptyState
              imageUrl={schemaPlaceholder}
              title="This source contains no parent models"
              message="A parent model describes how your data source will be queried, allowing you to select the specific source data you want to send to your destination."
              actions={
                <PermissionedLinkButton
                  variant="primary"
                  href={`/schema-v2/new?source=${sourceId}&type=parent`}
                  permission={{
                    v1: {
                      resource: "audience_schema",
                      grant: "create",
                    },
                    v2: {
                      resource: "model",
                      grant: "can_create",
                      creationOptions: {
                        type: "schema",
                        sourceId: sourceId?.toString() ?? "",
                      },
                    },
                  }}
                >
                  Create parent model
                </PermissionedLinkButton>
              }
            />
          </Column>
          <GraphBackground />
        </Column>
      ) : (
        <>
          <Routes>
            <Route
              element={
                isGraphLoading ? (
                  <Column justify="center" align="center" height="100%">
                    <Spinner size="lg" />
                  </Column>
                ) : (
                  <>
                    <ReactFlowProvider>
                      {graph && (
                        <Graph
                          relationships={graph.relationships}
                          models={graph.models}
                          sourceId={sourceId}
                          selectedId={selectedId}
                          type={type}
                        />
                      )}
                    </ReactFlowProvider>
                    <Outlet />
                  </>
                )
              }
            >
              <Route path="/" element={<></>} />
              <Route
                path="/new"
                element={
                  <CreateModel
                    selectedId={selectedId}
                    sourceId={sourceId}
                    parentModelId={parentModelId}
                    type={type}
                  />
                }
              />
              <Route
                path="/view/*"
                element={<ViewModel id={selectedId} onClose={closeDrawer} />}
              />
            </Route>
          </Routes>
        </>
      )}
    </Column>
  );
};

export const SchemaPage: FC = () => {
  return (
    <Routes>
      <Route path="/*" element={<Schema />} />
      <Route path="/settings/*" element={<SchemaSettings />} />
    </Routes>
  );
};

const CreateModel: FC<
  Readonly<{
    sourceId: string | null | undefined;
    parentModelId: string | null | undefined;
    selectedId: string | null | undefined;
    type: SchemaModelType | null;
  }>
> = ({ sourceId, parentModelId, selectedId, type }) => {
  const navigate = useNavigate();

  const { data: source } = useSourceQuery(
    { id: String(sourceId) },
    {
      select: (data) => data.connections_by_pk,

      enabled: Boolean(sourceId),
    },
  );

  if (!type) {
    return null;
  }

  return source ? (
    <CreateSchemaModelWizard
      fromModelId={selectedId ?? ""}
      type={type}
      source={source}
      onCreated={() => {
        if (type === SchemaModelType.Parent) {
          navigate(`/schema-v2?source=${sourceId}`);
        } else {
          navigate(
            `/schema-v2?source=${sourceId}${
              parentModelId ? `&parent=${parentModelId}` : ""
            }`,
          );
        }
      }}
    />
  ) : (
    <SchemaDrawer>
      <OverlaySpinner />
    </SchemaDrawer>
  );
};

const ViewModel: FC<Readonly<{ id: string | null; onClose: () => void }>> = ({
  id,
  onClose,
}) => {
  const { data, isLoading } = useObjectQuery(
    {
      id: id ?? "",
    },
    {
      enabled: Boolean(id),
      notifyOnChangeProps: "tracked",
    },
  );

  if (!id) {
    return null;
  }

  return (
    <SchemaDrawer>
      {isLoading ? (
        <OverlaySpinner />
      ) : data?.segments_by_pk ? (
        <SchemaObject
          object={data.segments_by_pk}
          audienceCount={data.audienceAggregate.aggregate?.count}
        />
      ) : (
        <>
          <Row justify="flex-end" mx={4} mt={4}>
            <IconButton
              aria-label="Close drawer."
              icon={CloseIcon}
              onClick={onClose}
            />
          </Row>
          <Warning
            subtitle="You may not have permissions to view this model or it may have been deleted."
            title="Model not found"
          />
        </>
      )}
    </SchemaDrawer>
  );
};

const SchemaDrawer: FC<{ children: ReactNode }> = ({ children }) => {
  const { headerHeight } = useHeaderHeight();

  usePylonDrawerOffset({ isOpen: true, size: "lg" });

  return (
    <Drawer
      isOpen
      showOverlay={false}
      blockBackgroundInteraction={false}
      contentWrapperStyles={{
        mt: `${64 + headerHeight}px`,
      }}
      closeOnEsc={false}
      size="lg"
      trapFocus={false}
      onClose={noop}
    >
      {children}
    </Drawer>
  );
};
