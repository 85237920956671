import type { MetricDisplayFormatting } from "@hightouch/lib/query/visual/types/goals";
import { AggregationOption } from "../constants";

export type NumericType = "percentage" | "number" | "currency";

export const DefaultsPerType: Record<
  MetricDisplayFormatting["type"],
  { config: MetricDisplayFormatting; defaultValue: number }
> = {
  percentage: {
    defaultValue: 0.1567123123,
    config: {
      type: "percentage",
      fractionDigits: 2,
    },
  },
  number: {
    defaultValue: 12345.6789,
    config: {
      type: "number",
      fractionDigits: 0,
    },
  },
  currency: {
    defaultValue: 1435.67,
    config: {
      type: "currency",
      fractionDigits: 0,
      countryCode: "USD",
    },
  },
};

export const MAX_FRACTION_DIGITS = 4;

export const SupportedOptionsPerAggregationType: Record<
  AggregationOption,
  NumericType[]
> = {
  [AggregationOption.Count]: ["number"],
  [AggregationOption.SumOfProperty]: ["number", "currency", "percentage"],
  [AggregationOption.UniqueUsers]: ["number"],
  [AggregationOption.CountDistinctProperty]: ["number"],
  [AggregationOption.PercentOfAudience]: ["percentage"],
  [AggregationOption.SumOfPropertyPerUser]: [
    "number",
    "currency",
    "percentage",
  ],
  [AggregationOption.AverageOfProperty]: ["number", "currency", "percentage"],
  [AggregationOption.AverageOfPropertyPerUser]: [
    "number",
    "currency",
    "percentage",
  ],
} as const;

export const DefaultDisplayFormatting: MetricDisplayFormatting = {
  type: "number",
  fractionDigits: 0,
};

export function getDefaultFormatting(
  aggregationType: AggregationOption,
): MetricDisplayFormatting {
  const firstSupported =
    SupportedOptionsPerAggregationType[aggregationType][0] || "number";

  return DefaultsPerType[firstSupported].config;
}

export function formatNumericMetric(
  number: number | undefined,
  displayFormatting: MetricDisplayFormatting | undefined,
): string {
  const formatting = displayFormatting || DefaultDisplayFormatting;
  const defaultConfig = DefaultsPerType[formatting.type];

  const numberToFormat = number ?? defaultConfig.defaultValue;
  const fractionDigits =
    formatting.fractionDigits ?? defaultConfig.config.fractionDigits;

  switch (formatting.type) {
    case "currency":
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: formatting.countryCode || "USD",
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
        trailingZeroDisplay: "auto",
      }).format(numberToFormat);

    case "percentage":
      return new Intl.NumberFormat("en-US", {
        style: "percent",
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
      }).format(numberToFormat);

    case "number":
      return new Intl.NumberFormat("en-US", {
        style: "decimal",
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
      }).format(numberToFormat);
  }
}
