import {
  Box,
  ChakraPopover,
  ChakraPopoverBody,
  ChakraPopoverContent,
  ChakraPopoverTrigger,
  Column,
  Portal,
  CheckIcon,
} from "@hightouchio/ui";
import { FC, forwardRef, useState } from "react";
import { RouterLink } from "src/router";
import { NavItem, PermissionedNavItem } from "./nav-item";
import { withPermission } from "src/components/permission";
import { ActiveSectionProps, useActiveLink } from "./utils";

export type NavSectionLink = {
  href: string;
  label: string;
  icon?: any;
  permission?: any;
  isSub?: boolean;
};

export type NavSectionProps = {
  isEnabled?: boolean;
  isSub?: boolean;
  isInFooter?: boolean;
  icon: any;
  title: string;
  links: Array<NavSectionLink>;
  onChange?: (name: string | null) => void;
} & ActiveSectionProps;

export const NavSection: FC<Readonly<NavSectionProps>> = ({
  isEnabled,
  isSub,
  isInFooter,
  activeSection,
  activeLinkIndex,
  openSection,
  icon,
  title,
  links,
  onChange,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const validLinks = links.filter(Boolean);
  const defaultLink = validLinks[0];

  // Footer sections still toggle open / closed, even if "all" are open in the primary nav.
  const isOpen = isInFooter
    ? activeSection === title
    : openSection === "all" || openSection === title;
  const isActiveSection = activeSection === title;

  const close = () => {
    setIsMenuOpen(false);
  };

  if (!isEnabled) {
    return null;
  }

  return (
    <Box>
      <Box display={{ base: "block", lg: "none" }}>
        <ChakraPopover
          isLazy
          isOpen={isMenuOpen}
          placement="right-start"
          offset={[0, 16]}
          onClose={close}
        >
          <ChakraPopoverTrigger>
            <NavItem
              isActive={isOpen && isActiveSection}
              icon={icon}
              label={title}
              onClick={() => {
                setIsMenuOpen(!isMenuOpen);
              }}
            />
          </ChakraPopoverTrigger>

          <Portal>
            <ChakraPopoverContent
              border="1px solid"
              borderColor="base.border"
              overflow="hidden"
              width="200px"
            >
              <ChakraPopoverBody
                p={0}
                overflow="hidden"
                display="flex"
                flexDir="column"
              >
                <Box
                  p={2}
                  textTransform="uppercase"
                  fontWeight="semibold"
                  color="text.secondary"
                  fontSize="sm"
                >
                  {title}
                </Box>
                {validLinks.map((link) => (
                  <PermissionedMenuLink
                    key={link.href}
                    {...link}
                    onClick={() => {
                      onChange?.(title);
                      close();
                    }}
                  />
                ))}
              </ChakraPopoverBody>
            </ChakraPopoverContent>
          </Portal>
        </ChakraPopover>
      </Box>
      <Box display={{ base: "none", lg: "block" }}>
        <NavItem
          href={defaultLink?.href}
          isOpen={isOpen}
          isActiveSection={isActiveSection}
          icon={icon}
          label={title}
          onClick={() => {
            if (!isOpen) {
              onChange?.(title);
            }
          }}
        />
        <Column
          aria-hidden={!isOpen}
          display={{ base: "none", lg: "flex" }}
          maxHeight={isOpen ? `${32 * validLinks.length}px` : 0}
          overflowY="hidden"
          transition="200ms max-height ease-in"
          visibility={isOpen ? "visible" : "hidden"}
          _focusWithin={{
            overflowY: "visible !important",
          }}
        >
          <Column pl={3} pos="relative">
            <Box
              bg="#40E398"
              pos="absolute"
              width="2px"
              height="32px"
              top={0}
              left="17px" // 4 + 1px offset
              transform={`translateY(${
                isActiveSection ? activeLinkIndex * 32 : 0
              }px)`}
              opacity={isActiveSection ? 1 : 0}
              transition="transform 200ms ease-in-out, opacity 200ms ease-in-out"
            />
            {validLinks.map((link) => (
              <PermissionedNavItem
                key={link.href}
                isNested
                isSub={isSub}
                {...link}
              />
            ))}
          </Column>
        </Column>
      </Box>
    </Box>
  );
};

type MenuLinkProps = NavSectionLink & {
  isDisabled?: boolean;
  onClick: () => void;
};

const MenuLink = forwardRef<HTMLDivElement, MenuLinkProps>((props, ref) => {
  const isActive = useActiveLink(props);

  return (
    <Box
      ref={ref}
      as={props.isDisabled ? "button" : RouterLink}
      // @ts-expect-error - `as` prop
      to={props.href}
      display="flex"
      alignItems="center"
      bg={isActive ? "forest.200" : "white"}
      color={isActive ? "forest.800" : "text.primary"}
      fontWeight="normal"
      justifyContent="space-between"
      p={2}
      opacity={props.isDisabled ? 0.5 : 1}
      pointerEvents={isActive || props.isDisabled ? "none" : "auto"}
      _hover={{
        bg: "gray.100",
      }}
      _focusVisible={{
        bg: "gray.100",
      }}
      onClick={props.onClick}
    >
      {props.label}
      {isActive && <Box as={CheckIcon} color="forest.600" fontSize="lg" />}
    </Box>
  );
});

MenuLink.displayName = "MenuLink";

const PermissionedMenuLink = withPermission(MenuLink);
