import { FC } from "react";
import { Column, Row } from "@hightouchio/ui";

import { useOutletContext } from "src/router";
import { RecentRunsTable } from "./recent-runs-table";
import { AlertsAndWarnings } from "./alerts-and-warnings";
import { MetadataSidebar } from "./metadata-sidebar";
import { SyncRunCharts } from "./sync-run-charts";
import { Context } from "..";

export const Overview: FC = () => {
  const { sync } = useOutletContext<Context>();

  return (
    <Row gap={6}>
      <Column gap={6} flexGrow={1} minWidth={0}>
        <AlertsAndWarnings sync={sync} />
        <RecentRunsTable sync={sync} />
        <SyncRunCharts sync={sync} />
      </Column>
      <MetadataSidebar sync={sync} />
    </Row>
  );
};
