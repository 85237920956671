import { FC, useState } from "react";

import { RelatedColumn, TraitColumn } from "@hightouch/lib/query/visual/types";
import {
  Button,
  CloseIcon,
  Column,
  Heading,
  IconButton,
  Row,
  useToast,
} from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useOutletContext, useParams } from "src/router";

import { Drawer } from "src/components/drawer";
import { AdditionalColumn } from "src/types/visual";

import { AudiencePageOutletState } from "src/pages/audiences/types";
import {
  TraitEnrichmentForm,
  TraitEnrichmentFormProps,
  traitEnrichmentValidationSchema,
} from "./trait-enrichment-form";
import { useAddAdditionalColumn } from "./utils";

export const CreateTraitEnrichment: FC = () => {
  const { audience, parentModel } = useOutletContext<AudiencePageOutletState>();

  const navigate = useNavigate();
  const { id: audienceId } = useParams();

  const { toast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const form = useForm<TraitEnrichmentFormProps>({
    resolver: yupResolver(traitEnrichmentValidationSchema),
  });

  const addAdditionalColumn = useAddAdditionalColumn();

  const onSubmit = async (data: TraitEnrichmentFormProps) => {
    setIsSubmitting(true);
    try {
      const traitColumn: TraitColumn = {
        type: "trait",
        traitDefinitionId: data.template_id,
        conditions: data.conditions || [],
      };

      const relatedColumn: RelatedColumn = {
        type: "related",
        path: [String(data.relationship_id)],
        column: traitColumn,
      };

      const additionalColumn: AdditionalColumn = {
        alias: data.name,
        column: relatedColumn,
      };
      await addAdditionalColumn({
        audience,
        additionalColumn,
        onSuccess: onClose,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const onError = () => {
    toast({
      id: "create-trait-enrichment-validation",
      variant: "error",
      title: "Trait enrichment failed to be saved",
      message: "There were errors in your submission",
    });
  };

  const onClose = () => navigate(`/audiences/${audienceId}/traits`);

  return (
    <Drawer isLoading={false} isOpen size="xl" onClose={onClose}>
      <Row
        p={6}
        borderBottom="1px solid"
        borderBottomColor="base.border"
        alignItems="center"
        justifyContent="space-between"
      >
        <Heading>New trait enrichment</Heading>
        <IconButton aria-label="Close" icon={CloseIcon} onClick={onClose} />
      </Row>
      <Column flex={1} p={6} gap={6} overflowY="auto">
        <FormProvider {...form}>
          <TraitEnrichmentForm parentModel={parentModel} editConditions />
        </FormProvider>
      </Column>

      <Row
        px={6}
        py={4}
        borderTop="solid 1px"
        borderTopColor="base.border"
        justifyContent="space-between"
      >
        <Button onClick={onClose} size="lg">
          Cancel
        </Button>
        <Button
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
          variant="primary"
          onClick={form.handleSubmit(onSubmit, onError)}
          size="lg"
        >
          Save trait
        </Button>
      </Row>
    </Drawer>
  );
};
