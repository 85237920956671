import { Box, FormField, Row } from "@hightouchio/ui";
import { Controller, useFormContext } from "react-hook-form";

import { DateTimeSelect } from "src/ui/datetime-select";

export const Timeline = () => {
  const { watch } = useFormContext();
  const start = watch("flowMessageConfig.firstEligibleSendDate");
  const end = watch("flowMessageConfig.lastEligibleSendDate");

  return (
    <FormField
      label="Timeline"
      isOptional
      description="Set a start and end date for this message."
      tip="If not provided, this message is eligible to be sent whenever it's enabled"
    >
      <Row align="center" gap={2}>
        <Controller
          name="flowMessageConfig.firstEligibleSendDate"
          render={({ field }) => (
            <DateTimeSelect
              hideTime
              placeholder="Start date..."
              maxDate={end}
              minDate={new Date()}
              {...field}
              value={field.value}
            />
          )}
        />
        <Box>-</Box>
        <Controller
          name="flowMessageConfig.lastEligibleSendDate"
          render={({ field }) => (
            <DateTimeSelect
              hideTime
              placeholder="End date..."
              minDate={start}
              {...field}
              value={field.value}
            />
          )}
        />
      </Row>
    </FormField>
  );
};
