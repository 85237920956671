import { Fragment, useEffect, useState } from "react";

import {
  Box,
  Row,
  Column,
  SectionHeading,
  Paragraph,
  Button,
  Menu,
  MenuList,
  MenuItem,
  MenuDivider,
  StatusIndicator,
  MenuActionsButton,
  EmptyState,
  EditIcon,
  DeleteIcon,
  Dialog,
} from "@hightouchio/ui";
import { Link } from "src/router";
import moment from "moment";
import {
  Outlet,
  Route,
  Routes,
  useNavigate,
  useOutletContext,
} from "src/router";

import {
  CreateNormalTunnelForm,
  CreateReverseTunnelForm,
} from "src/components/create-tunnel";
import {
  NormalTunnel,
  ReverseTunnel,
  TunnelsQuery,
  useTestTunnelQuery,
  useTunnelsQuery,
} from "src/graphql";
import { DeleteTunnel } from "src/pages/settings/tunnels/delete";
import { EditTunnelForm } from "src/pages/settings/tunnels/edit";
import { Table } from "src/ui/table";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { useRegionConfig } from "src/utils/use-region-config";

enum TunnelType {
  REVERSE = "reverse",
  NORMAL = "normal",
}

type GenericTunnel = Omit<TunnelsQuery["getTunnels"][0], "tunnel">;

export type TunnelsOutletContext = {
  tunnels: Array<GenericTunnel & { tunnel: NormalTunnel }>;
  reverseTunnels: Array<
    GenericTunnel & {
      tunnel: ReverseTunnel;
    }
  >;
  refetch: () => void;
};

export const Tunnels = () => {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route element={<Loader />}>
        <Route element={<Layout />}>
          <Route path="/*" element={<Fragment />} />
          <Route
            path="/create"
            element={
              <CreateNormalTunnelForm
                onClose={() => {
                  navigate("/settings/tunnels");
                }}
              />
            }
          />
          <Route
            path="/create-reverse"
            element={
              <CreateReverseTunnelForm
                onClose={() => {
                  navigate("/settings/tunnels");
                }}
              />
            }
          />
          <Route path="/:id/delete" element={<DeleteTunnel />} />
          <Route path="/:id/edit" element={<EditTunnelForm />} />
        </Route>
      </Route>
    </Routes>
  );
};

const Loader = () => {
  const {
    tunnels: { enabled: tunnelsEnabled },
  } = useRegionConfig();

  const { data, refetch } = useTunnelsQuery(undefined, {
    suspense: true,
    enabled: tunnelsEnabled,
  });

  if (!tunnelsEnabled) {
    return (
      <EmptyState
        message="SSH tunneling support is currently in private beta in this region. Please contact us if you would like to request access."
        title="Tunneling not available"
      />
    );
  }

  const tunnels = data?.getTunnels?.filter(
    ({ type }) => type === TunnelType.NORMAL,
  );
  const reverseTunnels = data?.getTunnels?.filter(
    ({ type }) => type === TunnelType.REVERSE,
  );

  return <Outlet context={{ tunnels, reverseTunnels, refetch }} />;
};

const Layout = () => {
  const context = useOutletContext<TunnelsOutletContext>();
  const { tunnels, reverseTunnels } = context;
  const navigate = useNavigate();
  const [tunnelError, setTunnelError] = useState<string | undefined | null>();

  return (
    <>
      <Column flex={1} mb={10}>
        <Row justify="space-between" mb={8}>
          <Box maxWidth="2xl">
            <SectionHeading>Standard tunnels</SectionHeading>
            <Paragraph mt={1}>
              Access services within a private network by configuring Hightouch
              to open an SSH connection to your bastion, then open a port
              forwarding connection from your bastion to the private service.
              Learn more in our{" "}
              <Link href="https://hightouch.com/docs/security/ssh-tunneling">
                docs
              </Link>
              .
            </Paragraph>
          </Box>

          <Button
            variant="primary"
            onClick={() => {
              navigate("create");
            }}
          >
            Add standard tunnel
          </Button>
        </Row>

        <Table
          columns={[
            {
              name: "Name",
              cell: ({ tunnel: { name } }) => (
                <TextWithTooltip fontWeight="medium">
                  {name ?? "--"}
                </TextWithTooltip>
              ),
            },
            {
              name: "Status",
              cell: ({ tunnel: { id } }) => {
                const [polling, setPolling] = useState<boolean>(false);

                const { data, isLoading } = useTestTunnelQuery(
                  { id: id ?? "" },
                  { refetchInterval: polling ? 3000 : undefined },
                );

                const error = data?.checkTunnel?.error;

                useEffect(() => {
                  if (error) {
                    setPolling(true);
                  }
                }, [error]);

                if (isLoading) {
                  return null;
                }

                return (
                  <StatusIndicator
                    variant={data?.checkTunnel?.success ? "success" : "error"}
                  >
                    {data?.checkTunnel?.success ? "Connected" : "Error"}
                  </StatusIndicator>
                );
              },
            },
            {
              name: "SSH host",
              cell: ({ tunnel: { sshHost, sshPort } }) =>
                `${sshHost}:${sshPort}`,
            },
            {
              name: "Service host",
              cell: ({ tunnel: { serviceHost, servicePort } }) =>
                `${serviceHost}:${servicePort}`,
            },
            {
              name: "Created at",
              cell: ({ tunnel: { createdAt } }) => moment(createdAt).calendar(),
              breakpoint: "md" as const,
            },
            {
              max: "60px",
              cell: ({ tunnel }) => {
                return (
                  <Menu>
                    <MenuActionsButton />

                    <MenuList>
                      <MenuItem
                        icon={EditIcon}
                        onClick={() => {
                          navigate(`${tunnel.id}/edit`);
                        }}
                      >
                        Edit
                      </MenuItem>
                      <MenuDivider />
                      <MenuItem
                        icon={DeleteIcon}
                        variant="danger"
                        onClick={() => {
                          navigate(`${tunnel.id}/delete`);
                        }}
                      >
                        Delete
                      </MenuItem>
                    </MenuList>
                  </Menu>
                );
              },
            },
          ]}
          data={tunnels}
          placeholder={{
            title: "No tunnels",
            error: "Tunnels failed to load, please try again.",
          }}
        />
      </Column>

      <Column flex={1}>
        <Row justify="space-between" mb={8}>
          <Box maxWidth="2xl">
            <SectionHeading>Reverse tunnels</SectionHeading>
            <Paragraph mt={1}>
              Access services within a private network by connecting as a client
              to an SSH server managed by Hightouch, then forwarding a port.
              Learn more in our{" "}
              <Link href="https://hightouch.com/docs/security/ssh-tunneling">
                docs
              </Link>
              .
            </Paragraph>
          </Box>

          <Button
            variant="primary"
            onClick={() => {
              navigate("create-reverse");
            }}
          >
            Add reverse tunnel
          </Button>
        </Row>

        <Table
          columns={[
            {
              name: "Name",
              cell: ({ tunnel: { name } }) => (
                <TextWithTooltip fontWeight="medium">
                  {name ?? "--"}
                </TextWithTooltip>
              ),
            },
            {
              name: "Status",
              cell: ({ tunnel: { id } }) => {
                const [polling, setPolling] = useState<boolean>(false);

                const { data, isLoading } = useTestTunnelQuery(
                  { id: id ?? "" },
                  { refetchInterval: polling ? 3000 : undefined },
                );

                const error = data?.checkTunnel?.error;

                useEffect(() => {
                  if (error) {
                    setPolling(true);
                  }
                }, [error]);

                if (isLoading) {
                  return null;
                }

                return (
                  <StatusIndicator
                    variant={data?.checkTunnel?.success ? "success" : "error"}
                  >
                    {data?.checkTunnel?.success ? "Connected" : "Error"}
                  </StatusIndicator>
                );
              },
            },
            {
              name: "Tunnel port",
              cell: ({ tunnel: { tunnelPort } }) => tunnelPort,
            },
            {
              name: "Created at",
              cell: ({ tunnel: { createdAt } }) => moment(createdAt).calendar(),
              breakpoint: "md" as const,
            },
            {
              max: "60px",
              cell: ({ tunnel }) => {
                return (
                  <Menu>
                    <MenuActionsButton />

                    <MenuList>
                      <MenuItem
                        icon={EditIcon}
                        onClick={() => {
                          navigate(`${tunnel.id}/edit`);
                        }}
                      >
                        Edit
                      </MenuItem>
                      <MenuDivider />
                      <MenuItem
                        icon={DeleteIcon}
                        variant="danger"
                        onClick={() => {
                          navigate(`${tunnel.id}/delete`);
                        }}
                      >
                        Delete
                      </MenuItem>
                    </MenuList>
                  </Menu>
                );
              },
            },
          ]}
          data={reverseTunnels}
          placeholder={{
            title: "No reverse tunnels",
            error: "Tunnels failed to load, please try again.",
          }}
        />
      </Column>

      <Outlet context={context} />

      <Dialog
        isOpen={tunnelError ? true : false}
        variant="info"
        width="xl"
        title="Error"
        actions={<Button onClick={() => setTunnelError("")}>Close</Button>}
        onClose={() => setTunnelError("")}
      >
        <Box maxWidth="100%">
          <Paragraph>{tunnelError}</Paragraph>
        </Box>
      </Dialog>
    </>
  );
};
