import { FC, useEffect } from "react";

import {
  Row,
  Spinner,
  FormField,
  Checkbox,
  IconButton,
  RefreshIcon,
  Combobox,
} from "@hightouchio/ui";
import * as Yup from "yup";

import {
  useMixpanelListCohortsQuery,
  useMixpanelListProjectsQuery,
} from "src/graphql";

import { CustomQueryViewProps, CustomQueryFormProps } from "../custom-query";

export const QueryForm: FC<Readonly<CustomQueryFormProps>> = ({
  source,
  query,
  onChange,
  setError,
}) => {
  const {
    data: projects,
    isLoading: projectsLoading,
    error: projectsError,
    refetch: listProjects,
  } = useMixpanelListProjectsQuery({ connectionId: String(source.id) });

  const {
    data: cohorts,
    isLoading: cohortsLoading,
    error: cohortsError,
    refetch: listCohorts,
  } = useMixpanelListCohortsQuery(
    { connectionId: String(source.id), projectId: Number(query?.projectId) },
    { enabled: Boolean(query?.projectId) },
  );

  useEffect(() => {
    if (projectsError || cohortsError) {
      setError(projectsError || cohortsError);
    }
  }, [projectsError, cohortsError]);

  return (
    <>
      <FormField label="Project" error={projectsError?.message}>
        <Row gap={2}>
          <Combobox
            isLoading={projectsLoading}
            options={
              projects?.mixpanelListProjects?.map((project) => ({
                label: project.name,
                value: project.id,
              })) ?? []
            }
            placeholder="Select a project..."
            value={query?.projectId}
            onChange={(projectId) => onChange({ ...query, projectId })}
          />
          <IconButton
            aria-label="Refresh projects"
            icon={RefreshIcon}
            isDisabled={projectsLoading}
            variant="secondary"
            onClick={() => listProjects()}
          />
        </Row>
      </FormField>
      <FormField label="Cohort" error={cohortsError?.message}>
        <Row gap={2}>
          <Combobox
            isLoading={cohortsLoading}
            options={
              cohorts?.mixpanelListCohorts?.map((cohort) => {
                return { label: cohort.name, value: cohort.id };
              }) ?? []
            }
            placeholder="Select a cohort..."
            value={query?.cohortId}
            onChange={(cohortId) => onChange({ ...query, cohortId })}
          />
          <IconButton
            aria-label="Refresh cohorts"
            icon={RefreshIcon}
            isDisabled={cohortsLoading}
            variant="secondary"
            onClick={() => listCohorts()}
          />
        </Row>
      </FormField>
      <FormField label="Should we include users without profile data?">
        <Checkbox
          label="Include all users"
          isChecked={Boolean(query?.includeAllUsers)}
          onChange={(event) =>
            onChange({ ...query, includeAllUsers: event.target.checked })
          }
        />
      </FormField>
    </>
  );
};

export const QueryView: FC<Readonly<CustomQueryViewProps>> = ({
  source,
  query,
  setError,
}) => {
  const {
    data: projects,
    isLoading: projectsLoading,
    error: projectsError,
  } = useMixpanelListProjectsQuery({ connectionId: String(source.id) });

  const {
    data: cohorts,
    isLoading: cohortsLoading,
    error: cohortsError,
  } = useMixpanelListCohortsQuery({
    connectionId: String(source.id),
    projectId: query.projectId as number,
  });

  useEffect(() => {
    if (projectsError || cohortsError) {
      setError(projectsError || cohortsError);
    }
  }, [projectsError, cohortsError]);

  if (projectsLoading || cohortsLoading) {
    return <Spinner />;
  }

  const project = projects?.mixpanelListProjects?.find(
    (project) => project.id === query.projectId,
  );
  const projectName = project
    ? `${project.name} (${project.id})`
    : `Unknown project with id ${query.projectId}`;
  const cohort = cohorts?.mixpanelListCohorts?.find(
    (cohort) => cohort.id === query?.cohortId,
  );
  const cohortName = cohort
    ? `${cohort.name} (${cohort.id}) with ${cohort.count} users`
    : `Unknown cohort with id ${query.cohortId}`;

  // This only gets rendered when the loading has finished.
  return (
    <>
      <FormField label="Project">{projectName}</FormField>
      <FormField label="Cohort">{cohortName}</FormField>
      <FormField label="Include users without profile data">
        {query.includeAllUsers ? "Yes" : "No"}
      </FormField>
    </>
  );
};

export const querySchema = Yup.object().shape({
  type: Yup.string().required().equals(["cohort"]),
  projectId: Yup.number().required(),
  cohortId: Yup.number().required(),
  includeAllUsers: Yup.boolean().required().default(false),
});
