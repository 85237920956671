import { FC } from "react";

import { Row } from "@hightouchio/ui";
import { useNavigate } from "src/router";
import { getBezierPath, EdgeProps, EdgeLabelRenderer } from "reactflow";

import { getParams } from "src/pages/schema/graph/utils";
import { useGraphContext } from "./context";

export const CustomEdge: FC<EdgeProps> = ({
  id,
  source,
  target,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
}) => {
  const navigate = useNavigate();
  const { highlight, highlightedNodes } = useGraphContext();
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const isDimmed = (() => {
    if (highlight) {
      if (source && target) {
        return (
          !highlightedNodes.includes(source) ||
          !highlightedNodes.includes(target)
        );
      } else if (source) {
        return !highlightedNodes.includes(source);
      } else if (target) {
        return !highlightedNodes.includes(target);
      }
      return true;
    }
    return false;
  })();

  return (
    <>
      <path
        fill="none"
        stroke="var(--chakra-colors-teal-300)"
        id={id}
        d={edgePath}
        strokeWidth="2px"
        opacity={isDimmed ? 0.25 : 1}
      />
      {data.cardinality && (
        <EdgeLabelRenderer>
          <Row
            pointerEvents="all"
            align="center"
            position="absolute"
            transform={`translate(-50%, -50%) translate(${labelX}px,${labelY}px)`}
            borderRadius="36px"
            bg="white"
            border="1.5px solid"
            borderColor="teal.300"
            fontSize="12px"
            height="24px"
            px={2}
            color="text.secondary"
            opacity={isDimmed ? 0.25 : 1}
            transition="opacity 0.15s ease-in-out, background-color 0.15s ease-in-out"
            cursor="pointer"
            onClick={() => {
              const params = getParams();
              if (data.isGroup) {
                navigate({
                  pathname: `/schema-v2/view/relationships`,
                  search: `?source=${params.source}&id=${data.groupTarget}`,
                });
              } else {
                navigate({
                  pathname: `/schema-v2/view/relationships/${data.edgeId}-${data.reverseEdgeId}`,
                  search: `?source=${params.source}&id=${data.from}`,
                });
              }
            }}
            _hover={{
              bg: "teal.100",
            }}
          >
            {getCardinalityLabel(data.cardinality)}
          </Row>
        </EdgeLabelRenderer>
      )}
    </>
  );
};

const getCardinalityLabel = (cardinality: string) => {
  switch (cardinality) {
    case "one-to-many":
      return "1:many";
    case "many-to-one":
      return "many:1";
    case "one-to-one":
      return "1:1";
    default:
      return "";
  }
};

export const ConnectionLine: FC<any> = ({ fromX, fromY, toX, toY }) => {
  return (
    <g>
      <path
        fill="none"
        stroke="black"
        strokeWidth={2}
        d={`M${fromX},${fromY} C ${fromX} ${toY} ${fromX} ${toY} ${toX},${toY}`}
      />
    </g>
  );
};
