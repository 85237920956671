import {
  ConditionFriendlyNames,
  MonitorConditionTypes,
  MonitoredResourceType,
  SupportedConditionsForResourceType,
} from "@hightouch/lib/resource-monitoring/types";
import {
  ArrowRightIcon,
  Box,
  Button,
  Column,
  Dialog,
  Heading,
  Menu,
  MenuActionsButton,
  MenuItem,
  MenuList,
  Row,
  SectionHeading,
  Spinner,
  StatsItemTitle,
  Text,
} from "@hightouchio/ui";
import { FC, useMemo } from "react";
import { useNavigate, useSearchParams } from "src/router";
import {
  DefaultSyncMonitorConditionOverridesQuery,
  useClearMonitorConditionMutation,
  useDefaultSyncMonitorConditionOverridesQuery,
  useSyncsToDestinationQuery,
} from "src/graphql";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { SyncSequentialFailuresCondition } from "./monitor-condition-forms/sequential-sync-failures";
import { SyncRejectedRowsCondition } from "./monitor-condition-forms/sync-rejected-rows";
import { SlowSyncsMonitorCondition } from "./monitor-condition-forms/slow-syncs";
import { UnscheduledSyncCondition } from "./monitor-condition-forms/unscheduled-syncs";
import { SyncThroughputMonitorCondition } from "./monitor-condition-forms/sync-throughput";
import { ModelSizeMonitorCondition } from "./monitor-condition-forms/model-size";
import { lowerCase } from "lodash";
import { Table } from "src/ui/table";
import { getMinimalCondition, isOverride } from "./utils";
import { BulkEditableResourceTypes } from "./monitor-condition-forms/monitor-condition-form";

const ConditionToElement: Record<
  SupportedConditionsForResourceType<BulkEditableResourceTypes>,
  ({ condition }: any) => JSX.Element[]
> = {
  [MonitorConditionTypes.SyncRejectedRows]: SyncRejectedRowsCondition,
  [MonitorConditionTypes.SyncSequentialFailures]:
    SyncSequentialFailuresCondition,
  [MonitorConditionTypes.SyncDuration]: SlowSyncsMonitorCondition,
  [MonitorConditionTypes.SyncMissedSchedules]: UnscheduledSyncCondition,
  [MonitorConditionTypes.SyncThroughput]: SyncThroughputMonitorCondition,
  [MonitorConditionTypes.ModelSize]: ModelSizeMonitorCondition,
} as const;

const ConditionDisplay = ({ condition }) => {
  const elements = ConditionToElement[condition.type]({ condition });
  return elements.map((element) => (
    <>
      <Text color="text.secondary">Trigger:</Text>
      {element}
    </>
  ));
};

export const DefaultTriggerOverrides: FC = () => {
  const [search] = useSearchParams();

  const destinationId = search.get("forDestinationId");
  const monitorConditionType = search.get("forMonitorConditionType");
  const resourceType = MonitoredResourceType.Sync;

  const { data: syncs, isLoading: loadingSyncs } = useSyncsToDestinationQuery(
    {
      destinationId: destinationId ?? "",
    },
    {
      enabled: !!destinationId,
      select: (data) => data.syncs,
    },
  );

  const overridingSyncIds = syncs?.map((sync) => sync.id.toString());

  const { data: conditions, isLoading: loadingConditions } =
    useDefaultSyncMonitorConditionOverridesQuery(
      {
        destinationId: destinationId ?? "",
        monitorConditionType: monitorConditionType ?? "",
        resourceType: resourceType ?? "",
        syncIds: overridingSyncIds ?? [],
      },
      {
        enabled:
          !!destinationId && !!monitorConditionType && !!overridingSyncIds,
      },
    );

  const { mutateAsync: clearSyncTrigger } = useClearMonitorConditionMutation();

  const defaultCondition = conditions?.monitor_condition_templates?.[0];
  const destination = syncs?.[0]?.destination;

  const syncToOverride = useMemo(() => {
    const overrides: Record<
      string,
      DefaultSyncMonitorConditionOverridesQuery["resource_monitor_conditions"][0]
    > = {};

    if (!defaultCondition) return overrides;

    for (const syncId of overridingSyncIds ?? []) {
      const syncOverride = conditions?.resource_monitor_conditions.find(
        (condition) =>
          condition.resource_id === syncId.toString() &&
          isOverride(
            getMinimalCondition(condition),
            getMinimalCondition(defaultCondition),
          ),
      );
      if (!syncOverride) continue;
      overrides[syncId] = syncOverride;
    }
    return overrides;
  }, [conditions, loadingConditions, defaultCondition]);

  const navigate = useNavigate();

  const onClose = () => {
    navigate(-1);
  };

  const overrideData = Object.entries(syncToOverride ?? {}).flatMap(
    ([syncId, condition]) => {
      const targetSync = syncs?.find((sync) => sync.id.toString() === syncId);
      if (!targetSync) return [];
      const segmentName =
        targetSync.segment?.name ||
        targetSync.segment?.connection?.name ||
        targetSync.segment?.connection?.definition.name;

      const conditionElement = <ConditionDisplay condition={condition} />;

      if (!conditionElement) return [];

      return [
        {
          segmentName,
          condition,
          sync: targetSync,
          icon: targetSync.segment?.connection?.definition.icon,
          id: syncId,
        },
      ];
    },
  );

  return (
    <Dialog
      isOpen={true}
      title={`Sync-level overrides for “${lowerCase(
        ConditionFriendlyNames[monitorConditionType as MonitorConditionTypes],
      )}” trigger`}
      onClose={onClose}
      actions={
        <Button onClick={onClose} variant="primary">
          Done
        </Button>
      }
      variant="info"
      width={"5xl" as any}
    >
      {loadingConditions || loadingSyncs ? (
        <Spinner size="lg" m="auto" />
      ) : (
        <>
          <Column gap={4}>
            <Box
              display="grid"
              gap={8}
              gridTemplateColumns={["repeat(4, 1fr)", "1fr 1fr 3fr 1fr"]}
            >
              <Column gap={2}>
                <StatsItemTitle>Destination</StatsItemTitle>
                <Row gap={2} alignItems="center">
                  <IntegrationIcon
                    size={6}
                    src={destination?.definition?.icon}
                    name={destination?.name || destination?.definition.name}
                  />
                  <Text>{destination?.name}</Text>
                </Row>
              </Column>
              <Column gap={2}>
                <StatsItemTitle>Trigger</StatsItemTitle>
                <Text>
                  {
                    ConditionFriendlyNames[
                      monitorConditionType as MonitorConditionTypes
                    ]
                  }
                </Text>
              </Column>
              <Column gap={2}>
                <StatsItemTitle>Default trigger configuration</StatsItemTitle>
                {defaultCondition ? (
                  <Box
                    display="grid"
                    gridTemplateColumns={["repeat(2, 1fr)", "1fr 6fr"]}
                  >
                    <Text color="text.secondary">Status:</Text>{" "}
                    <Text>
                      {defaultCondition.enabled ? "Enabled" : "Disabled"}
                    </Text>
                    <ConditionDisplay condition={defaultCondition} />
                  </Box>
                ) : (
                  <Text>No default set</Text>
                )}
              </Column>
            </Box>
            <Column gap={4}>
              <Row justifyContent="space-between" w="100%">
                <SectionHeading>Overridden syncs</SectionHeading>

                <Button
                  size="sm"
                  onClick={async () => {
                    await clearSyncTrigger({
                      syncIds: overridingSyncIds ?? [],
                      conditionType: monitorConditionType ?? "",
                      resourceType: resourceType ?? "",
                    });
                  }}
                  variant="danger"
                  isDisabled={!overrideData.length}
                >
                  Clear all overrides
                </Button>
              </Row>
              {overrideData.length > 0 ? (
                <Box>
                  <Table
                    data={overrideData}
                    allowWrap
                    columns={[
                      {
                        header: () => <StatsItemTitle>Sync</StatsItemTitle>,
                        max: "180px",
                        whitespace: "unset",
                        cellSx: { "&:last-of-type": { borderBottom: "none" } },
                        cell: (sync) => (
                          <Row gap={2} alignItems="center">
                            <IntegrationIcon
                              src={sync.icon}
                              name={sync.segmentName}
                            />
                            {sync.segmentName}
                          </Row>
                        ),
                      },
                      {
                        max: "50px",
                        cell: () => (
                          <Row w="100%" justifyContent="center">
                            <Heading size="lg" color="text.tertiary">
                              <ArrowRightIcon />{" "}
                            </Heading>
                          </Row>
                        ),
                      },
                      {
                        max: "180px",
                        whitespace: "unset",
                        cellSx: { "&:last-of-type": { borderBottom: "none" } },
                        cell: () => (
                          <Row gap={2} alignItems="center">
                            <IntegrationIcon
                              src={destination?.definition.icon}
                              name={
                                destination?.name ||
                                destination?.definition.name
                              }
                            />
                            {destination?.name || destination?.definition.name}
                          </Row>
                        ),
                      },
                      {
                        header: () => (
                          <StatsItemTitle>Trigger configuration</StatsItemTitle>
                        ),
                        whitespace: "unset",
                        cellSx: { paddingY: 2 },
                        cell: (sync) => (
                          <Box
                            display="grid"
                            gap={2}
                            gridTemplateColumns={["repeat(2, 1fr)", "1fr 6fr"]}
                          >
                            <Text color="text.secondary">Status:</Text>{" "}
                            <Text>
                              {sync.condition.enabled ? "Enabled" : "Disabled"}
                            </Text>
                            <ConditionDisplay condition={sync.condition} />
                          </Box>
                        ),
                      },
                      {
                        max: "50px",
                        cell: (sync) => (
                          <Row
                            display="flex"
                            flexDir="column"
                            alignItems="end"
                            w="100%"
                          >
                            <Menu>
                              <MenuActionsButton />
                              <MenuList>
                                <MenuItem
                                  onClick={() => navigate(`/syncs/${sync.id}`)}
                                >
                                  View sync
                                </MenuItem>
                                <MenuItem
                                  onClick={async () => {
                                    await clearSyncTrigger({
                                      syncIds: [sync.id],
                                      conditionType: monitorConditionType ?? "",
                                      resourceType: resourceType ?? "",
                                    });
                                  }}
                                  variant="danger"
                                >
                                  Clear override
                                </MenuItem>
                              </MenuList>
                            </Menu>
                          </Row>
                        ),
                      },
                    ]}
                  />

                  <Box
                    /**
                     * Sorry, this is completely deranged, but there's no good way to conditionally
                     * disable the bottom border of the table because each cell has its own bottom border
                     * and it's rendered with display: "contents" so we can't just add a border to the row.
                     * If you have a better idea please be my guest 🙏
                     */
                    background="white"
                    mt="-1px"
                    w="100%"
                    h="1px"
                    position="relative"
                  />
                </Box>
              ) : (
                <Text>No overridden syncs for this trigger.</Text>
              )}
            </Column>
          </Column>
        </>
      )}
    </Dialog>
  );
};
