import { FC } from "react";

import { useSearchParams } from "src/router";

import { CreateTemplatedTrait } from "./create-templated-trait";
import { SelectTemplateModal } from "./select-template-modal";

export const CreateTraitFromTemplate: FC = () => {
  const [searchParams, _setSearchParams] = useSearchParams();
  const templateId = searchParams.get("template_id");

  const showModal = templateId == undefined;

  return showModal ? <SelectTemplateModal /> : <CreateTemplatedTrait />;
};
