import {
  ArrowRightIcon,
  Badge,
  Box,
  Button,
  Column,
  ConfirmationDialog,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Paragraph,
  Row,
  SectionHeading,
  Text,
  Tooltip,
  useToast,
} from "@hightouchio/ui";
import { FC, useMemo, useState } from "react";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import {
  InsertManualMonitorConditionsMutationVariables,
  useClearMonitorConditionMutation,
  useDeleteAllTriggerOverridesMutation,
  useInsertManualMonitorConditionsMutation,
  useSyncMonitorConditionsQuery,
} from "src/graphql";
import {
  ConditionFriendlyNames,
  MonitorConditionEvaluationProperties,
  SupportedConditionsForResourceType,
  SupportedConditionsForResourceTypes,
} from "@hightouch/lib/resource-monitoring/types";
import { assertedConditionType } from "@hightouch/lib/resource-monitoring/assertions";
import { ActionBar } from "src/components/action-bar";
import {
  ManualMonitorConditionFormValue,
  MonitorConditionForm,
  SyncResourceType,
  defaultValueForSyncConditionType,
  isBulkEditableResourceType,
  isMandatoryCondition,
  makeConditionMap,
} from "./monitor-condition-forms/monitor-condition-form";
import { BulkManagementTable, monitorConditionSchema } from "./bulk-management";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, createSearchParams } from "src/router";
import { isOverride } from "./utils";
import { isEqual } from "lodash";

interface Props<T extends SyncResourceType> {
  syncId: string;
  destinationId?: string;
  resourceType: T;
}

export type SyncAlertTriggerFormState<T extends SyncResourceType> = Record<
  T,
  ManualMonitorConditionFormValue
>;

export const ConditionLabel: FC<{
  isOverride?: boolean;
  conditionType: string;
  resourceType: SyncResourceType;
  resourceId: string;
  onClearOverride?: () => void;
}> = ({
  isOverride,
  conditionType,
  resourceId,
  onClearOverride,
  resourceType,
}) => {
  const mutation = useClearMonitorConditionMutation();
  const { toast } = useToast();

  return (
    <Column gap={1} w="100%">
      <Row gap={1} w="100%" justifyContent="space-between">
        <Text fontWeight="medium">{ConditionFriendlyNames[conditionType]}</Text>
        {isOverride && (
          <Box minW="fit-content">
            <Tooltip message="This condition overrides a destination default">
              <Badge variant="warning" size="sm">
                Override
              </Badge>
            </Tooltip>
          </Box>
        )}
      </Row>
      {isOverride && (
        <Button
          isLoading={mutation.isLoading}
          size="sm"
          onClick={async () => {
            await mutation.mutateAsync({
              syncIds: [resourceId],
              conditionType,
              resourceType,
            });
            if (onClearOverride) onClearOverride();
            toast({
              id: "override-cleared",
              title: "Override cleared",
              variant: "success",
            });
          }}
        >
          Restore default
        </Button>
      )}
    </Column>
  );
};

export const SyncAlertTriggerManagement = <T extends SyncResourceType>({
  syncId,
  destinationId,
  resourceType,
}: Props<T>) => {
  const navigate = useNavigate();

  const conditionTypes = SupportedConditionsForResourceTypes[resourceType];

  const { data } = useSyncMonitorConditionsQuery(
    {
      syncId: syncId.toString(),
      destinationId: destinationId?.toString() ?? "-1",
      conditionTypes: [...conditionTypes],
      resourceType,
    },
    { suspense: true },
  );

  const { mutateAsync: createMonitorConditions } =
    useInsertManualMonitorConditionsMutation();

  const isBulkEditable = isBulkEditableResourceType(resourceType);

  const inheritedConditionsFromDestination =
    isBulkEditable && data?.monitor_condition_templates
      ? makeConditionMap(data?.monitor_condition_templates)
      : {};

  const existingConditions = useMemo(() => {
    const syncLevelTriggers = data?.resource_monitor_conditions
      ? makeConditionMap(data?.resource_monitor_conditions)
      : {};

    const defaultFormValues = Object.fromEntries(
      conditionTypes.map(
        (
          conditionType,
        ): [
          SupportedConditionsForResourceType<T>,
          ManualMonitorConditionFormValue,
        ] => [
          conditionType,
          {
            enabled: isMandatoryCondition({ conditionType, resourceType }),
            error_value: defaultValueForSyncConditionType[conditionType],
            warning_value: null,
          },
        ],
      ),
    );

    const existing = {
      ...defaultFormValues,
      ...inheritedConditionsFromDestination,
      ...syncLevelTriggers,
    };

    return existing;
  }, [data]);

  (SyncAlertTriggerManagement as FC<Props<SyncResourceType>>).displayName =
    SyncAlertTriggerManagement.name;

  const form = useHightouchForm({
    values: existingConditions,
    resolver: yupResolver(monitorConditionSchema(false)),
    onSubmit: async (values) => {
      const args: InsertManualMonitorConditionsMutationVariables["objects"] =
        Object.entries(values).flatMap(([conditionType, conditionValue]) => {
          const ConditionType = assertedConditionType(conditionType);

          // Make the submission step a last line of defense against accidentally disabling mandatory monitors
          const coercedConditionValue =
            isMandatoryCondition({
              conditionType: ConditionType,
              resourceType,
            }) && !conditionValue.enabled
              ? {
                  enabled: true,
                  error_value:
                    conditionValue.error_value ||
                    defaultValueForSyncConditionType[ConditionType],
                  warning_value: conditionValue.warning_value,
                }
              : conditionValue;

          const equals = isEqual(
            existingConditions[conditionType],
            coercedConditionValue,
          );
          if (equals) return [];
          else
            return [
              {
                resource_id: syncId,
                resource_type: resourceType,
                error_value: coercedConditionValue?.error_value,
                warning_value: coercedConditionValue?.warning_value,
                enabled: coercedConditionValue?.enabled,
                type: ConditionType,
                evaluation_trigger:
                  MonitorConditionEvaluationProperties[ConditionType]
                    .EvaluationTrigger,
                evaluation_type:
                  MonitorConditionEvaluationProperties[ConditionType]
                    .EvaluationType,
              },
            ];
        });

      await createMonitorConditions({ objects: args });
    },
  });

  const formData = form.watch();
  const overrides = useMemo(
    () =>
      conditionTypes.filter((conditionType) => {
        const syncCondition = form.watch(conditionType);
        const defaultCondition =
          inheritedConditionsFromDestination[conditionType];

        return isOverride(syncCondition, defaultCondition);
      }),
    [formData],
  );

  const [overrideConfirmationOpen, setOverrideConfirmationOpen] =
    useState(false);

  const { mutateAsync: removeSyncOverrides } =
    useDeleteAllTriggerOverridesMutation();

  return (
    <Form form={form}>
      <Row mt={4} justifyContent="space-between">
        <Column width="70%" gap={2}>
          <SectionHeading>Alert triggers</SectionHeading>
          <Paragraph color="text.secondary">
            {isBulkEditable &&
              "By default, this sync inherited some alert triggers from its destination. "}
            These triggers are the conditions under which the sync becomes
            unhealthy, displays warnings, and/or sends alerts.
            {isBulkEditable &&
              " If needed, you can override the default triggers in the form below."}
          </Paragraph>
        </Column>

        {isBulkEditable && (
          <Menu>
            <MenuButton>Options</MenuButton>
            <MenuList>
              <MenuItem
                onClick={() => {
                  navigate(
                    `/alerting/alerts?${createSearchParams({
                      destinations: destinationId ?? "",
                    }).toString()}`,
                  );
                }}
              >
                Manage default triggers for this destination
                <ArrowRightIcon ml={2} />
              </MenuItem>
              {overrides.length > 0 && (
                <MenuItem
                  onClick={() => {
                    setOverrideConfirmationOpen(true);
                  }}
                >
                  Clear all alert trigger overrides for this sync
                </MenuItem>
              )}
            </MenuList>
          </Menu>
        )}
      </Row>
      <BulkManagementTable>
        {conditionTypes.map((conditionType) => {
          return (
            <MonitorConditionForm
              key={conditionType}
              conditionType={conditionType}
              resourceType={resourceType}
              title={
                <ConditionLabel
                  resourceId={syncId}
                  conditionType={conditionType}
                  resourceType={resourceType}
                  onClearOverride={() => {
                    const defaultCondition = inheritedConditionsFromDestination[
                      conditionType
                    ] || {
                      enabled: isMandatoryCondition({
                        conditionType,
                        resourceType,
                      }),
                      error_value:
                        defaultValueForSyncConditionType[conditionType],
                    };
                    form.setValue(conditionType, defaultCondition);
                  }}
                  isOverride={overrides.includes(conditionType)}
                />
              }
            />
          );
        })}
      </BulkManagementTable>

      <ActionBar>
        <FormActions />
      </ActionBar>
      <ConfirmationDialog
        isOpen={!!overrideConfirmationOpen}
        title="Clear overrides"
        confirmButtonText="Proceed"
        variant="warning"
        onClose={() => setOverrideConfirmationOpen(false)}
        onConfirm={async () => {
          overrides.forEach((conditionType) => {
            const defaultCondition =
              inheritedConditionsFromDestination[conditionType];
            if (defaultCondition)
              form.setValue(conditionType, defaultCondition); // optimistically set to default
          });
          await removeSyncOverrides({
            resourceIds: [syncId],
            resourceType,
          });

          setOverrideConfirmationOpen(false);
        }}
      >
        Are you sure you want to clear all alert trigger overrides for this
        sync?
      </ConfirmationDialog>
    </Form>
  );
};
