import { FC } from "react";

import {
  IconButton,
  IconButtonProps,
  RefreshIcon,
  Tooltip,
} from "@hightouchio/ui";

export type ReloadButtonProps = {
  tooltip?: string;
};

export const ReloadButton: FC<
  Readonly<ReloadButtonProps & Omit<IconButtonProps, "icon" | "aria-label">>
> = ({ isLoading, isDisabled, tooltip, onClick, ...props }) => {
  const ButtonElement = (
    <IconButton
      aria-label={tooltip ?? "Reload"}
      icon={RefreshIcon}
      isDisabled={isDisabled}
      isLoading={isLoading}
      variant="secondary"
      onClick={onClick}
      {...props}
    />
  );

  if (tooltip) {
    return <Tooltip message={tooltip}>{ButtonElement}</Tooltip>;
  }

  return ButtonElement;
};
