import { IntervalUnit } from "src/types/visual";

export const TIME_OPTIONS = [
  { value: IntervalUnit.Minute, label: "Minute(s)" },
  { value: IntervalUnit.Hour, label: "Hour(s)" },
  { value: IntervalUnit.Day, label: "Day(s)" },
  { value: IntervalUnit.Week, label: "Week(s)" },
  { value: IntervalUnit.Month, label: "Month(s)" },
  { value: IntervalUnit.Year, label: "Year(s)" },
];
