import { FC } from "react";

import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  CodeSnippet,
  Heading,
  Text,
  Row,
  TextInput,
  ClipboardButton,
  FormField,
  Code,
} from "@hightouchio/ui";

import { CodeParams, SetupProps } from "./types";

function getSwiftCode(params: CodeParams): string {
  return `
  import UIKit
  import Hightouch
  
  @main
  class AppDelegate: UIResponder, UIApplicationDelegate {
      var analytics: Analytics? = nil
  
      func application(_ application: UIApplication, didFinishLaunchingWithOptions launchOptions: [UIApplication.LaunchOptionsKey: Any]?) -> Bool {

          let configuration = Configuration(writeKey: "${params.writeKey}")
              .apiHost("${params.apiHost}/v1")
              .cdnHost("${params.apiHost}/v1")
              .trackApplicationLifecycleEvents(true)
          
          analytics = Analytics(configuration: configuration)

          analytics?.track(name:"test track event")
          analytics?.screen(title: "home")
          analytics?.track(name: "track with traits", properties:[
              "key_1" : "value_1",
              "key_2" : "value_2"
          ])
          
          return true
      }
  }
  `;
}

function getObjcCode(params: CodeParams): string {
  return `
  #import "AppDelegate.h"
  #import "ObjCExample-Swift.h"

  @import Hightouch;

  @interface AppDelegate ()

  @end

  @implementation AppDelegate


  - (BOOL)application:(UIApplication *)application didFinishLaunchingWithOptions:(NSDictionary *)launchOptions {

      HTConfiguration *config = [[HTConfiguration alloc] initWithWriteKey:@"${params.writeKey}"];
      config.apiHost = "${params.apiHost}/v1";
      config.cdnHost = "${params.apiHost}/v1";
      config.trackApplicationLifecycleEvents = YES;
      config.flushAt = 1;
      
      _analytics = [[HTAnalytics alloc] initWithConfiguration: config];
      
      [self.analytics track:@"test"];
      [self.analytics track:@"testProps" properties:@{@"email": @"blah@blah.com"}];
      
      [self.analytics flush];
  `;
}

export const SetupIOS: FC<SetupProps> = (props: SetupProps) => {
  const libraryIdentifier = "git@github.com:ht-sdks/events-sdk-swift.git";
  return (
    <>
      <Heading>Installing the SDK</Heading>
      <FormField label="Swift Package">
        <Row align="center" gap={2}>
          <TextInput width="lg" isReadOnly value={libraryIdentifier} />
          <ClipboardButton text={libraryIdentifier} />
        </Row>
      </FormField>
      <Text fontWeight="semibold" size="md">
        Option 1: XCode
      </Text>
      <ol>
        <li>
          Xcode 12:{" "}
          <Text isMonospace={true} fontWeight="semibold">
            File {">"} Swift Packages {">"} Add Package Dependency
          </Text>
        </li>
        <li>
          Xcode 13:{" "}
          <Text isMonospace={true} fontWeight="semibold">
            File {">"} Add Packages…
          </Text>
        </li>
        <li>
          Search <Code>{libraryIdentifier}</Code>
        </li>
        <li>
          Click{" "}
          <Text isMonospace={true} fontWeight="semibold">
            Add Package.
          </Text>
        </li>
      </ol>
      <Text fontWeight="semibold" size="md">
        Option 2: Package.swift
      </Text>
      <p>
        Add <Code>{libraryIdentifier}</Code> to your <Code>package.swift</Code>{" "}
        file.
      </p>
      <Heading>Using the SDK</Heading>
      <Tabs>
        <TabList>
          <Tab>Swift</Tab>
          <Tab>Objective C</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <CodeSnippet label="AppDelegate.swift" code={getSwiftCode(props)} />
          </TabPanel>
          <TabPanel>
            <CodeSnippet label="AppDelegate.m" code={getObjcCode(props)} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};
