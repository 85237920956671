import { useCallback, useEffect, useState } from "react";

import store from "store2";

import { UnsavedValue } from "./use-unsaved-value";

export const usePersistedState = <TValue>(
  key: string,
  initialValue: TValue,
): UnsavedValue<TValue> => {
  const [value, setValue] = useState<TValue | undefined>(store.get(key));

  useEffect(() => {
    const stored = store.get(key);
    setValue(stored == undefined ? initialValue : stored);
  }, []);

  const set = useCallback(
    (value: TValue) => {
      store.set(key, value);
      setValue(value);
    },
    [key],
  );

  const restore = useCallback(() => {
    setValue(undefined);
  }, []);

  const clear = useCallback(() => {
    setValue(undefined);
    store.remove(key);
  }, [key]);

  return { value, set, restore, clear };
};
