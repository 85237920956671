import { FC, useEffect, useMemo, useState } from "react";
import {
  Column,
  DrawerBody,
  ExternalLinkIcon,
  Heading,
  Row,
  StatsItem,
  StatsItemTitle,
  StatsItemValue,
  StatusIndicator,
  SparkleIcon,
  Text,
  Box,
  Paragraph,
  IconButton,
  CloseIcon,
  Alert,
} from "@hightouchio/ui";

import { Link } from "src/router";

import { useFlags } from "launchdarkly-react-client-sdk";
import { round, startCase } from "lodash";

import { Drawer } from "src/components/drawer";
import { abbreviateNumber } from "src/utils/numbers";

import {
  SyncQuery,
  SyncRunsQuery,
  useSupportsMatchboostingQuery,
} from "src/graphql";
import { isSyncMatchBoosted } from "src/pages/syncs/sync/matchbooster";
import { useUser } from "src/contexts/user-context";
import {
  isAudienceStatusProcessing,
  isMatchedUsersCountSettling,
} from "src/utils/match-boosting";
import { load, save } from "src/utils/storage";

import {
  LearnMoreToMarketingLink,
  ModelSetUpLink,
} from "./shared-match-booster";

type Model = SyncQuery["syncs"][0]["segment"] | null | undefined;
type Props = {
  onClose: () => void;
  isOpen: boolean;
  model: Model;
  sync: SyncQuery["syncs"][0] | null | undefined;
  metadata: Metadata;
  syncConfigTabCallBack?: () => void;
  firstSyncRun: SyncRunsQuery["sync_requests"][0] | null | undefined;
};

type Metadata =
  | {
      status: string;
      audienceSize: number;
      matchRate: number;
      audience: {
        name: string;
        url: string;
      };
    }
  | null
  | undefined;

export const DestinationMetadataDrawer: FC<Props> = ({
  onClose,
  isOpen,
  model,
  sync,
  metadata,
  syncConfigTabCallBack,
  firstSyncRun,
}) => {
  const { workspace } = useUser();
  const { appMatchBoosting } = useFlags();
  const [showMatchBoosterUpSell, setShowMatchBoosterUpSell] = useState(false);

  const syncConfig = sync?.config;
  const destination = sync?.destination;

  const destinationName = destination?.definition?.name ?? "";
  const useExistingSegment = Boolean(syncConfig?.useExistingSegment);

  const { data } = useSupportsMatchboostingQuery(
    {
      config: syncConfig,
      destinationType: destination?.type ?? "",
    },
    {
      enabled: !!syncConfig,
    },
  );

  const mbEnabledForWorkspace =
    appMatchBoosting || workspace?.organization?.entitlements?.matchbooster;
  const mbEnabledForDestination = data?.supportsMatchboosting;
  const mbEnabledForModel = Boolean(model?.matchboosting_enabled);
  const mbEnabledForSync = syncConfig && isSyncMatchBoosted(syncConfig);

  const indicatorVariant = useMemo(() => {
    switch (metadata?.status) {
      case "healthy":
        return "success";
      case "processing":
        return "processing";
      case "warning":
        return "warning";
      default:
        return "inactive";
    }
  }, [metadata?.status]);

  const clearedUpSellBoxKey = `ht-mb-cleared-up-sell-${workspace?.id}`;
  const userClearedUpSellBox = load(clearedUpSellBoxKey);

  // Show up sell component when we want users to use MB but they haven't set it up
  useEffect(() => {
    if (!userClearedUpSellBox && !mbEnabledForSync && mbEnabledForDestination) {
      setShowMatchBoosterUpSell(true);
    }
  }, [data]);

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="md">
      <Row
        p={6}
        borderBottom="1px solid"
        borderColor="base.border"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Heading>{metadata?.audience?.name}</Heading>
        <IconButton aria-label="Close" icon={CloseIcon} onClick={onClose} />
      </Row>

      <DrawerBody>
        <Column gap={6}>
          <Column gap={1}>
            <StatsItemTitle>Status</StatsItemTitle>
            <StatusIndicator variant={indicatorVariant}>
              {startCase(metadata?.status)}
            </StatusIndicator>
            <Text color="text.secondary">
              Monitoring for errors with this {destinationName} audience
            </Text>
          </Column>

          <Column>
            <StatsItem>
              <StatsItemTitle>Matched users</StatsItemTitle>
              <StatsItemValue>
                <Row alignItems="center" gap={1}>
                  <Column>
                    {isAudienceStatusProcessing(metadata)
                      ? "--"
                      : processAudienceSizeComponent(
                          metadata?.audienceSize,
                          metadata?.matchRate,
                          useExistingSegment,
                          syncConfig?.deleteMode,
                        )}
                  </Column>
                  {mbEnabledForModel && mbEnabledForSync && (
                    <Column fontSize="18px">
                      <SparkleIcon color="warning.400" />
                    </Column>
                  )}
                </Row>
              </StatsItemValue>
            </StatsItem>
            <Text color="text.secondary">
              The number of targetable matched users in your {destinationName}{" "}
              audience.{" "}
              {useExistingSegment ? (
                <>
                  No match rate is calculated when syncing to an existing
                  segment.
                </>
              ) : !syncConfig?.deleteMode ? (
                <>
                  No match rate is calculated when removed users are not
                  configured to be removed in {destinationName}.
                </>
              ) : (
                <>
                  This should reflect what is in {destinationName}. The
                  estimated percent is calculated by dividing this number over
                  the total number of queried rows from your latest run.
                </>
              )}{" "}
              Hightouch pulls this number live, so it's subject to change.{" "}
              <Link
                href={`${import.meta.env.VITE_DOCS_URL}/${
                  destination?.definition?.docs
                }#matched-users-count`}
                isExternal
              >
                Read the docs to learn more
              </Link>
            </Text>

            {isMatchedUsersCountSettling(
              firstSyncRun?.created_at,
              metadata,
              useExistingSegment,
            ) && (
              <Alert
                title="Matched users count may still be updating"
                type="warning"
                variant="inline"
                message="It's been less than 72 hours since the first sync ran and the numbers can still fluctuate."
                mt={6}
              />
            )}
          </Column>

          {showMatchBoosterUpSell && (
            <Column>
              <UpSellMatchBooster
                workspaceEnabled={mbEnabledForWorkspace}
                modelEnabled={mbEnabledForModel}
                syncEnabled={mbEnabledForSync}
                syncConfigTabCallBack={syncConfigTabCallBack}
                workspaceId={workspace?.id}
                model={model}
                onClose={() => {
                  setShowMatchBoosterUpSell(false);
                  save(clearedUpSellBoxKey, "true");
                }}
              />
            </Column>
          )}

          {metadata?.audience?.url && (
            <Column>
              <StatsItem>
                <StatsItemValue>
                  <Link href={metadata?.audience?.url || ""}>
                    <Row gap={1}>
                      View your audience in {destinationName}
                      <ExternalLinkIcon />
                    </Row>
                  </Link>
                </StatsItemValue>
              </StatsItem>
            </Column>
          )}
        </Column>
      </DrawerBody>
    </Drawer>
  );
};

export const processAudienceSizeComponent = (
  matchedUsers: number | undefined,
  matchRate: number | undefined,
  useExistingSegment: boolean,
  deleteMode: string | undefined,
) => {
  const { matchedUserCount, matchPercent } = calculateAudienceSizeValues(
    matchedUsers,
    matchRate,
    useExistingSegment,
    deleteMode,
  );

  return (
    <Row gap={1}>
      <Text>{matchedUserCount}</Text>
      {matchPercent && (
        <Text color="text.secondary">{` (${matchPercent})`}</Text>
      )}
    </Row>
  );
};

export const calculateAudienceSizeValues = (
  matchedUsers: number | undefined,
  matchRate: number | undefined,
  useExistingSegment: boolean,
  deleteMode: string | undefined,
) => {
  if (
    matchedUsers === -1 ||
    matchedUsers === undefined ||
    matchedUsers === null
  ) {
    return {
      matchedUserCount: "--",
      matchPercent: "",
    };
  }

  const matchedUserCount = `~${abbreviateNumber(matchedUsers)}`;
  const matchPercent =
    !useExistingSegment && deleteMode != null && matchRate != undefined
      ? `${round(matchRate * 100)}%`
      : "";

  return {
    matchedUserCount,
    matchPercent,
  };
};

export const processAudienceSize = (
  matchedUsers: number | undefined,
  matchRate: number | undefined,
  useExistingSegment: boolean,
  deleteMode: string | undefined,
): string => {
  const { matchedUserCount, matchPercent } = calculateAudienceSizeValues(
    matchedUsers,
    matchRate,
    useExistingSegment,
    deleteMode,
  );

  return `${matchedUserCount} ${matchPercent ? `(${matchPercent})` : ""}`;
};

const UpSellMatchBooster = ({
  workspaceEnabled,
  modelEnabled,
  syncEnabled,
  syncConfigTabCallBack,
  model,
  workspaceId,
  onClose,
}: {
  workspaceEnabled: boolean | undefined;
  modelEnabled: boolean | undefined;
  syncEnabled: boolean | undefined;
  syncConfigTabCallBack: (() => void) | undefined;
  model: Model;
  workspaceId: number | undefined;
  onClose: () => void;
}) => {
  if (syncEnabled) return null;

  return (
    <Row
      display="flex"
      border="1px"
      borderStyle="solid"
      borderRadius="6px"
      borderColor="gray.300"
      p={4}
      backgroundPosition="-5px -5px"
      backgroundImage="https://cdn.sanity.io/images/pwmfmi47/production/21e171f659bc3c109d910a400e2fd22cc5ae4cbf-288x112.png"
      backgroundRepeat="no-repeat"
    >
      <Column gap={2}>
        <Row display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" gap={1} alignItems="center">
            <Box as={SparkleIcon} color="warning.400" fontSize="20px" />
            <Text fontWeight="medium">Increase your audience size!</Text>
          </Box>
          <IconButton
            size="sm"
            aria-label="Close"
            icon={CloseIcon}
            onClick={onClose}
          />
        </Row>
        <Paragraph>
          <Text>
            Drive incremental revenue from broader targeting and reduce wasted
            spend with better suppression with Hightouch’s cookieless identity
            graph.
          </Text>{" "}
          {!workspaceEnabled ? (
            <LearnMoreToMarketingLink
              modelId={model?.id}
              workspaceId={workspaceId}
              appComponent="DestinationMetadataDrawer"
            />
          ) : !modelEnabled ? (
            <ModelSetUpLink model={model} />
          ) : (
            <Text
              color={syncConfigTabCallBack ? "link.default" : "default"}
              style={{ cursor: syncConfigTabCallBack ? "pointer" : "text" }}
              onClick={() => {
                if (syncConfigTabCallBack != null) {
                  syncConfigTabCallBack();
                }

                return null;
              }}
            >
              Enable Match Booster on this sync
            </Text>
          )}
        </Paragraph>
      </Column>
    </Row>
  );
};
