import * as yup from "yup";
import type { IntervalUnit } from ".";

export type CampaignAnalyticsRequest = {
  goalId: string;
  attributionMethodId: string | null;
  workspaceId: string;
  region: string;
};

export type AttributionMethod = {
  id: string;
  type: AttributionMethodType;
  interactions: {
    id: number;
    lookbackWindow?: InteractionLookbackWindow;
  }[];
};

export enum AttributionMethodType {
  FirstTouch = "first_touch",
  LastTouch = "last_touch",
  Participation = "participation",
}

export enum AssetType {
  Ad = "ad",
  Email = "email",
  Sms = "sms",
}

export enum InteractionType {
  // Emails
  EmailDelivered = "email_delivered",
  EmailOpened = "email_opened",
  EmailClicked = "email_clicked",
  Unsubscribe = "unsubscribe",

  // Ads
  Sessions = "sessions",

  // SMS
  SmsSent = "sms_sent",
  SmsDelivered = "sms_delivered",
  SmsClicked = "sms_clicked",
  SmsReplied = "sms_replied",
  SmsOptedOut = "sms_opted_out",
}

export type InteractionLookbackWindow = {
  quantity: number;
  unit: IntervalUnit.Day | IntervalUnit.Week | IntervalUnit.Month;
};

export enum AttributionTimeWindow {
  SevenDays = "past-7-days",
  ThirtyDays = "past-30-days",
  SixtyDays = "past-60-days",
  NinetyDays = "past-90-days",
}

export type MetricAttribution = {
  goalId: string;
  attributionMethodId?: string;
  attributionValue: number;
  modelId: string;
  modelRowId: string;
  timeWindow: AttributionTimeWindow;
};

export function getRawColumnValidator() {
  return yup.object({
    type: yup.string().equals(["raw"], "must be a raw column").required(),
    modelId: yup.string().required(),
    name: yup.string().required(),
  });
}
export type TimeWindow = {
  start?: Date;
  end: Date;
};

// The column aliases that should be returned by a campaign analytics query
export type QueryOutputColumns = {
  modelId: string;
  modelRowId: string;
  attributionValue: string;
};
