import { FC } from "react";

import { Column } from "@hightouchio/ui";

import { BlockRules } from "./block-rules";
import { IdentifierMergeRules } from "./identifier-merge-rules";
import { MergeRules } from "./merge-rules";
import { RulesFormContext } from "./rules-form-context";

type RulesFormProps = {
  identifiers: string[];
  showVersion2Rules: boolean;
  sourceType: string;
};

export const RulesForm: FC<RulesFormProps> = ({
  identifiers,
  showVersion2Rules,
  sourceType,
}) => {
  return (
    <RulesFormContext.Provider value={{ identifiers, sourceType }}>
      <Column gap={6} pb={32}>
        {showVersion2Rules ? <IdentifierMergeRules /> : <MergeRules />}
        <BlockRules />
      </Column>
    </RulesFormContext.Provider>
  );
};
