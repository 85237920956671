import { Box, ExternalLinkIcon, Text } from "@hightouchio/ui";
import { Link } from "src/router";
import * as analytics from "src/lib/analytics";

import { FormkitModel } from "src/formkit/components/formkit-context";
import { SyncQuery } from "src/graphql/types";
import { getModelToBoostLink } from "src/utils/match-boosting";

export const ModelSetUpLink = ({
  model,
}: {
  model: SyncQuery["syncs"][0]["segment"] | FormkitModel | null | undefined;
}) => {
  const segmentTypeToBoost = model?.visual_query_parent_id
    ? "parent model"
    : "model";

  return (
    <Link href={getModelToBoostLink(model)} isExternal>
      Configure Match Booster on this {segmentTypeToBoost}
      <Box as={ExternalLinkIcon} ml={1} mb="1px" />
    </Link>
  );
};

export const LearnMoreToDocsLink = ({
  workspaceId,
  modelId,
  appComponent,
}: {
  workspaceId: string | number | undefined;
  modelId: string | number | undefined;
  appComponent: string;
}) => {
  return (
    <Text
      onClick={() => {
        analytics.track("Match Booster Docs Learn More Link Clicked In-App", {
          workspace_id: workspaceId?.toString(),
          model_id: modelId?.toString(),
          app_component: appComponent,
        });
      }}
    >
      <Link href="https://hightouch.com/docs/match-booster/overview" isExternal>
        Learn more about Match Booster
        <Box as={ExternalLinkIcon} ml={1} mb="1px" />
      </Link>
    </Text>
  );
};

export const LearnMoreToMarketingLink = ({
  workspaceId,
  modelId,
  appComponent,
}: {
  workspaceId: string | number | undefined;
  modelId: string | number | undefined;
  appComponent: string;
}) => {
  return (
    <Text
      onClick={() => {
        analytics.track(
          "Match Booster Marketing Learn More Link Clicked In-App",
          {
            workspace_id: workspaceId?.toString(),
            model_id: modelId?.toString(),
            app_component: appComponent,
          },
        );
      }}
    >
      <Link href="https://hightouch.com/platform/match-booster" isExternal>
        Learn more about Match Booster
        <Box as={ExternalLinkIcon} ml={1} mb="1px" />
      </Link>
    </Text>
  );
};
