import {
  AudienceIcon,
  Avatar,
  Box,
  CloseIcon,
  Column,
  Drawer,
  Heading,
  IconButton,
  QuestionIcon,
  Row,
  SearchInput,
  Skeleton,
  SkeletonBox,
  Text,
} from "@hightouchio/ui";
import { FC, PropsWithChildren, useMemo, useState } from "react";
import { AudienceExplorePageProps } from "src/components/audiences/types";
import { IconBox } from "src/components/icon-box";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { ColumnType } from "src/types/visual";
import { Pagination, Table, useTableConfig } from "src/ui/table";
import { TableColumn } from "src/ui/table/table";
import { useRowSelect } from "src/ui/table/use-row-select";
import { commaNumber } from "src/utils/numbers";
import { UseCalculateAudienceSizeOutput } from "src/utils/use-calculate-audience-size";
import { ALL_COLUMN_OPTIONS } from "../constants";
import { DataRow } from "./data-row";
import { ParentModelFields, useParentModelFields } from "./fields";

export const MembersDrawer: FC<{
  audienceSize: UseCalculateAudienceSizeOutput["audienceSize"];
  audienceRows: any[];
  parentModel: AudienceExplorePageProps["parentModel"];
  sizeLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
}> = ({
  audienceRows,
  audienceSize,
  isOpen,
  parentModel,
  sizeLoading,
  onClose,
}) => {
  const parentModelFields = useParentModelFields(parentModel);
  const { selectedRows, onRowSelect } = useRowSelect();

  if (!parentModel || !parentModelFields) {
    return null;
  }

  const { primaryKey, secondaryLabelKey, primaryLabelKey, columns } =
    parentModelFields;

  const highlight =
    selectedRows.length === 1 ? selectedRows[0]![primaryKey] : undefined;

  return (
    <>
      <Drawer isOpen={isOpen} size="2xl" onClose={onClose} closeOnEsc trapFocus>
        <Box p={6} borderBottom="1px solid" borderBottomColor="base.border">
          <DrawerHeader onClose={onClose}>
            <IconBox
              bg="ocean.400"
              boxSize="20px"
              icon={<AudienceIcon />}
              iconSize="14px"
            />
            <Heading>
              {sizeLoading
                ? "Calculating..."
                : audienceSize !== null
                  ? `${audienceSize.isEstimate ? "~" : ""}${commaNumber(
                      audienceSize.count,
                    )} Members`
                  : "--"}
            </Heading>
          </DrawerHeader>
        </Box>

        <Column overflowY="auto" position="relative" m={6}>
          <Table
            scrollable
            onRowClick={(row) => onRowSelect([row])}
            highlight={highlight}
            primaryKey={primaryKey}
            placeholder={{
              custom: sizeLoading ? <SkeletonTable /> : <EmptyTable />,
            }}
            columns={[
              {
                name: primaryKey,
                headerSx: { pl: "16px !important" },
                cellSx: { pl: "16px !important" },
                cell: (row) => (
                  <TextWithTooltip color="text.secondary" isTruncated>
                    {row[primaryKey]}
                  </TextWithTooltip>
                ),
              },
              {
                name: parentModel.visual_query_primary_label || "",
                cell: (row) => (
                  <Text color="text.primary" fontWeight="medium">
                    {row[primaryLabelKey]}
                  </Text>
                ),
                max: "max-content",
              },
              ...(secondaryLabelKey
                ? [
                    {
                      name: parentModel.visual_query_secondary_label || "",
                      cell: (row) => (
                        <Text color="text.primary" fontWeight="medium">
                          {row[secondaryLabelKey]}
                        </Text>
                      ),
                      max: "max-content",
                    },
                  ]
                : []),
              ...columns
                .filter(
                  (column) =>
                    ![primaryKey, secondaryLabelKey, primaryLabelKey].includes(
                      column.name,
                    ),
                )
                .map((column) => {
                  const isNumeric = column.type === "number";
                  return {
                    name: column.name,
                    cellSx: {
                      justifyContent: isNumeric ? "right" : "unset",
                    },
                    headerSx: {
                      justifyContent: isNumeric ? "right" : "unset",
                    },
                    cell: (row) => (
                      <DataRow
                        value={row[column.name]}
                        type={column.type as ColumnType}
                        copyable={false}
                      />
                    ),
                  };
                }),
            ]}
            data={audienceRows}
          />
          <Box
            position="absolute"
            display={audienceRows.length > 0 ? undefined : "none"}
            borderRightRadius="md"
            height="100%"
            width="12px"
            right={0}
            bg="linear-gradient(270deg, rgba(0, 0, 0, 0.04) 0%, rgba(0, 0, 0, 0.00) 100%)"
          />
        </Column>
      </Drawer>

      <Drawer
        isOpen={selectedRows.length !== 0}
        size="md"
        onClose={() => onRowSelect([])}
        closeOnOverlayClick={false}
        closeOnEsc
        trapFocus
        blockBackgroundInteraction={false}
      >
        {selectedRows.length >= 1 && (
          <MemberDetailsDrawerContent
            // @ts-expect-error the row is basically an any
            selectedMember={selectedRows[0]!}
            onClose={() => onRowSelect([])}
            parentModelFields={parentModelFields}
          />
        )}
      </Drawer>
    </>
  );
};

const DrawerHeader: FC<PropsWithChildren<{ onClose: () => void }>> = ({
  onClose,
  children,
}) => {
  return (
    <Row alignItems="center" justifyContent="space-between">
      <Row gap={4} alignItems="center">
        {children}
      </Row>
      <IconButton aria-label="Close" icon={CloseIcon} onClick={onClose} />
    </Row>
  );
};

function lowercaseCompare(val1: unknown, val2: string) {
  return val1 && String(val1).toLowerCase().includes(val2.toLowerCase());
}

const MemberDetailsDrawerContent: FC<{
  selectedMember: Record<string, string>;
  onClose: () => void;
  parentModelFields: NonNullable<ParentModelFields>;
}> = ({ selectedMember, parentModelFields, onClose }) => {
  const { primaryLabelKey, secondaryLabelKey, columns } = parentModelFields;
  const [searchValue, setSearchValue] = useState("");

  const columnOptions = useMemo(() => {
    if (searchValue === "") {
      return columns;
    }

    return columns.filter(
      (col) =>
        lowercaseCompare(col.alias, searchValue) ||
        lowercaseCompare(col.name, searchValue) ||
        lowercaseCompare(selectedMember[col.name], searchValue),
    );
  }, [searchValue, columns, selectedMember]);

  const { limit, offset, page, setPage } = useTableConfig({
    limit: 10,
  });

  const currentPage = useMemo(
    () => columnOptions.slice(offset, offset + limit),
    [columnOptions, offset, limit],
  );

  return (
    <Column gap={6} px={6} py={4}>
      <DrawerHeader onClose={onClose}>
        <Avatar name={String(selectedMember[primaryLabelKey])} />

        <Column>
          <Heading>{selectedMember[primaryLabelKey]}</Heading>
          {secondaryLabelKey && (
            <Text>{selectedMember[secondaryLabelKey]}</Text>
          )}
        </Column>
      </DrawerHeader>
      <Row justifyContent="space-between">
        <Text size="lg" fontWeight="medium">
          Attributes
        </Text>
        <Row gap={4} alignItems="center">
          <Box width="200px">
            <SearchInput
              width="100%"
              placeholder="Search attributes..."
              size="sm"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </Box>
          <Pagination
            alwaysShow
            compact
            count={columnOptions.length}
            page={page}
            rowsPerPage={10}
            setPage={setPage}
          />
        </Row>
      </Row>

      <Table
        data={currentPage}
        columns={[
          {
            name: "Name",
            cell: ({ name, type }) => (
              <Row gap={2} mr={4} maxWidth="200px">
                <Row color="text.secondary" fontSize="xl">
                  <IconForColumnType columnType={type} />
                </Row>
                <TextWithTooltip color="text.secondary" isMonospace>
                  {name.toUpperCase()}
                </TextWithTooltip>
              </Row>
            ),
          },
          {
            name: "Value",
            cell: ({ name, type }) => {
              const value = selectedMember[name];
              return (
                <DataRow value={value} type={type as ColumnType} copyable />
              );
            },
          },
        ]}
      />
    </Column>
  );
};

const SkeletonTable = () => (
  <Skeleton isLoading>
    <Column overflowY="auto" pb={6} px={6}>
      <Table
        data={Array.from({ length: 100 }).map((_, index) => ({ id: index }))}
        columns={Array.from({ length: 5 }).map(() => SkeletonColumn)}
      />
    </Column>
  </Skeleton>
);

const SkeletonColumn: TableColumn = {
  cell: () => <SkeletonBox width="100%" height="20px" borderRadius="sm" />,
};

const IconForColumnType: FC<{ columnType: string }> = ({ columnType }) => {
  const match = ALL_COLUMN_OPTIONS.find(({ value }) => value === columnType);

  if (match) {
    const Icon = match.icon;
    return <Icon />;
  }

  return <QuestionIcon />;
};

const EmptyTable = () => (
  <Column sx={{ alignItems: "center", pt: 24, px: 4, overflowY: "auto" }}>
    <Text size="lg" fontWeight="semibold" mb={1}>
      No rows found
    </Text>
    <Box color="text.secondary">
      There are no rows with a primary label matching your search.
    </Box>
  </Column>
);
