import { useDecisionEngineInteractionFeatureColumnsQuery } from "src/graphql";
import { decisionEngineFlowSyntheticColumns } from "../decision-engine-utils";
import {
  MeasuringMode,
  MeasurementScope,
  MeasuringSelection,
  MeasuringSelectionColumn,
  MeasurementColumnOptions,
} from "../types";

/**
 * Hook for fetching available measurement columns based on the measuring selection
 */
export const useMeasurementColumns = (
  measuringSelection: MeasuringSelection | undefined,
  measuringMode: MeasuringMode | undefined,
): {
  columnOptions: MeasurementColumnOptions | undefined;
  additionalUserColumns: MeasuringSelectionColumn[];
  isLoading: boolean;
} => {
  const {
    data: interactionColumnsResult,
    isLoading: isInteractionColumnsLoading,
  } = useDecisionEngineInteractionFeatureColumnsQuery(
    {
      flowId: measuringSelection?.id?.toString() ?? "",
    },
    {
      enabled: Boolean(
        measuringSelection?.scope === MeasurementScope.DecisionEngineFlow,
      ),
      select: (data) => data.decision_engines[0],
    },
  );

  switch (measuringSelection?.scope) {
    case MeasurementScope.DecisionEngineFlow: {
      const { columns, additionalUserColumns } =
        decisionEngineFlowSyntheticColumns(
          measuringMode,
          interactionColumnsResult,
        );
      return {
        columnOptions: { label: "Interaction properties", options: columns },
        additionalUserColumns,
        isLoading: isInteractionColumnsLoading,
      };
    }
    case MeasurementScope.AllData:
    default:
      return {
        columnOptions: undefined,
        additionalUserColumns: [],
        isLoading: false,
      };
  }
};
