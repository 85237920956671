import { BoxProps } from "@hightouchio/ui";
import { FC } from "react";

import { Circle } from "src/ui/circle";

type DraftCircleProps = { sx?: BoxProps["sx"] };

export const DraftCircle: FC<DraftCircleProps> = ({ sx = {} }) => {
  return <Circle bg="warning.base" radius="8px" sx={sx} />;
};
