import { FC } from "react";

import { ExtensionHealthCheckStatus } from "@hightouch/core/server/graphql/types";
import {
  Box,
  ErrorIcon,
  Heading,
  PauseIcon,
  Row,
  Skeleton,
  SuccessIcon,
  Text,
} from "@hightouchio/ui";

import { Page } from "src/components/layout";
import {
  ExtensionHealthCheckNames,
  ExtensionHealthQuery,
  useExtensionHealthQuery,
} from "src/graphql";
import { Card } from "src/components/card";
import {
  AlertingIcon,
  AzureIcon,
  BitbucketIcon,
  DatadogIcon,
  DBTIcon,
  FaradayIcon,
  FivetranIcon,
  GitHubIcon,
  GitIcon,
  GitlabIcon,
  LookerIcon,
  MonitoringIcon,
  PagerDutyIcon,
  SigmaIcon,
  SlackIcon,
} from "src/ui/icons";
import { IconProps } from "src/ui/icons/icon";
import { PageSpinner } from "src/components/loading";
import { useUser } from "src/contexts/user-context";

const ExtensionNameToProps: Record<
  string,
  {
    icon: FC<Readonly<IconProps>>;
    title: string;
    description: string;
    route: string;
    integrations?: FC<Readonly<IconProps>>[];
  }
> = {
  [ExtensionHealthCheckNames.SyncAlerts]: {
    description: "Get notified about sync errors",
    icon: AlertingIcon,
    integrations: [SlackIcon, PagerDutyIcon],
    route: "alerting",
    title: "Alerting",
  },
  [ExtensionHealthCheckNames.DbtModelSync]: {
    description: "Import models and analyses from dbt",
    icon: DBTIcon,
    route: "dbt-models",
    title: "dbt models",
  },
  [ExtensionHealthCheckNames.DbtCloud]: {
    description: "Schedule syncs to run after dbt jobs",
    icon: DBTIcon,
    route: "dbt-cloud",
    title: "dbt Cloud",
  },

  [ExtensionHealthCheckNames.Fivetran]: {
    description: "Schedule syncs to run after Fivetran jobs",
    icon: FivetranIcon,
    route: "fivetran",
    title: "Fivetran",
  },
  [ExtensionHealthCheckNames.Looker]: {
    description: "Import models from Looker",
    icon: LookerIcon,
    route: "looker",
    title: "Looker",
  },
  [ExtensionHealthCheckNames.Datadog]: {
    description: "Send metrics and events to other tools",
    icon: MonitoringIcon,
    integrations: [DatadogIcon],
    route: "monitoring",
    title: "Monitoring",
  },
  [ExtensionHealthCheckNames.Sigma]: {
    description: "Import models from Sigma",
    icon: SigmaIcon,
    route: "sigma",
    title: "Sigma",
  },
  [ExtensionHealthCheckNames.GitSync]: {
    description: "Manage syncs and models in a Git repo",
    icon: GitIcon,
    integrations: [GitHubIcon, BitbucketIcon, GitlabIcon, AzureIcon],
    route: "git-sync",
    title: "Version control with Git",
  },
};

export const Extensions: FC = () => {
  const { workspace } = useUser();
  const { data, isLoading: loading } = useExtensionHealthQuery(undefined, {
    select(data) {
      // We roll up extensions with multiple results (like dbt) to return any that are failing
      const rolledExtensions: NonNullable<
        ExtensionHealthQuery["getWorkspaceExtensionHealth"]
      >[0][] = [];
      for (const extension of data?.getWorkspaceExtensionHealth ?? []) {
        if (extension.extension === ExtensionHealthCheckNames.DbtExposures) {
          // We want dbt exposures to 'count' as the dbt model sync extension
          extension.extension = ExtensionHealthCheckNames.DbtModelSync;
        }
        const existing = rolledExtensions.find(
          (e) => e.extension === extension.extension,
        );
        if (existing) {
          if (extension.status === ExtensionHealthCheckStatus.Failing) {
            existing.status = extension.status;
          }
        } else {
          rolledExtensions.push(extension);
        }
      }
      return rolledExtensions;
    },
  });

  if (loading) {
    return <PageSpinner />;
  }

  return (
    <Page title="Extensions">
      <Heading mb={2} size="xl">
        Extensions
      </Heading>
      <Text color="text.secondary" mb={10} size="lg">
        Connect your favorite tools to get more out of Hightouch
      </Text>

      <Box display="grid" gridTemplateColumns="repeat(3,1fr)" gap={6}>
        {data
          ? data.map((extension, i) => {
              const formatted = ExtensionNameToProps[extension.extension];
              if (!formatted) {
                return null;
              }

              if (
                extension.extension === "SyncAlerts" &&
                workspace?.alerting_v2_enabled
              )
                return null;

              return (
                <ExtensionCard
                  key={i}
                  status={extension.status}
                  title={formatted.title}
                  description={formatted.description}
                  integrations={formatted.integrations}
                  icon={formatted.icon}
                  route={formatted.route}
                />
              );
            })
          : Object.keys(ExtensionNameToProps).map((_name, idx) => (
              <Skeleton key={idx} isLoading={loading}>
                <Box height={300} />
              </Skeleton>
            ))}

        {Boolean(data) && (
          <ExtensionCard
            key="faraday"
            status={ExtensionHealthCheckStatus.Unconfigured}
            title="Faraday"
            description="Embed AI in your marketing stack"
            icon={FaradayIcon}
            route=""
            comingSoon
          />
        )}
      </Box>
    </Page>
  );
};

export const ExtensionCard: FC<{
  title: string;

  icon: FC<IconProps>;
  description: string;
  route: string;
  status: ExtensionHealthCheckStatus;
  integrations?: FC<IconProps>[];
  comingSoon?: boolean;
}> = ({
  title,
  icon: Icon,
  description,
  route,
  status,
  integrations,
  comingSoon,
}) => {
  const statusCircle = {
    [ExtensionHealthCheckStatus.Disabled]: (
      <Row
        align="center"
        justify="center"
        fontSize="16px"
        boxSize="24px"
        border="2px solid"
        borderColor="text.secondary"
        borderRadius="full"
      >
        <PauseIcon color="text.secondary" />
      </Row>
    ),
    [ExtensionHealthCheckStatus.Unconfigured]: (
      <Box
        width="28px"
        height="28px"
        borderRadius="100%"
        border="1px"
        borderColor="base.border"
      />
    ),
    [ExtensionHealthCheckStatus.Healthy]: (
      <Box fontSize="28px">
        <SuccessIcon color="success.base" />
      </Box>
    ),
    [ExtensionHealthCheckStatus.Failing]: (
      <Box fontSize="28px">
        <ErrorIcon color="danger.base" />
      </Box>
    ),
  }[status];
  return (
    <Card
      href={
        comingSoon
          ? undefined
          : status === ExtensionHealthCheckStatus.Unconfigured
            ? `/extensions/${route}`
            : `/extensions/${route}/configuration`
      }
      footer={
        <Row align="center" justify="space-between" width="100%">
          <Row align="center">
            {integrations?.map((Icon, i) => (
              <Icon key={i} width="28px" sx={{ mr: 4 }} />
            ))}
          </Row>
          <Text color={comingSoon ? "text.secondary" : "ocean.500"}>
            {comingSoon
              ? "Coming soon"
              : status !== ExtensionHealthCheckStatus.Unconfigured
                ? "Manage"
                : "Set up extension"}
          </Text>
        </Row>
      }
      opacity={comingSoon ? 0.5 : 1}
    >
      <Row align="center" justify="space-between" mb={3} h="40px">
        <Icon width="28px" />
        {statusCircle}
      </Row>
      <Text mb={2} fontWeight="semibold" size="lg">
        {title}
      </Text>
      <Text color="text.secondary">{description}</Text>
    </Card>
  );
};
