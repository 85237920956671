import { FC } from "react";

import {
  Box,
  Button,
  CloseIcon,
  Column,
  Combobox,
  FormField,
  IconButton,
  PlusIcon,
  Row,
  Select,
  TextInput,
  Tooltip,
} from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Controller, useFieldArray, UseFormReturn } from "react-hook-form";

import { RequiredParentModelFieldsForQueryBuilder } from "src/components/audiences/types";
import { QueryBuilderProvider } from "src/components/explore/context/query-builder-context";
import { EventFilter } from "src/components/explore/visual/event-filter";
import {
  Audience,
  ConditionType,
  EventCondition,
  initialEventCondition,
} from "src/types/visual";

import { useEntitlements } from "src/hooks/use-entitlement";
import { SchemaModelType } from "src/types/schema";
import {
  AggregationOption,
  AggregationOptions,
  AggregationOptionsWithAttributionMethods,
  AggregationOptionsWithColumns,
  ATTRIBUTION_BASIS_OPTIONS,
  TIME_OPTIONS,
} from "./constants";
import { MetricFormattingForm } from "./formatting/metric-formatting-form";
import { AttributionMethod } from "./types";
import { MetricFormData } from "./use-metric-form";
import { getDefaultFormatting } from "./formatting/format-metric";

type MetricFormProps = {
  isCreate?: boolean;
  metricFormProps: Omit<UseFormReturn<MetricFormData>, "handleSubmit">;
  parentModel?: // TODO: this doesn't need _all_ of the fields on the parent model, but the query builder requires them
  | (RequiredParentModelFieldsForQueryBuilder & {
        attribution_methods: AttributionMethod[];
      })
    | null;
};

export const MetricForm: FC<MetricFormProps> = ({
  metricFormProps,
  parentModel,
}) => {
  const { appMetricParameterization, enableMetricFormatting } = useFlags();
  const { data: entitlementsData } = useEntitlements(true, true);
  const campaignsEnabled = entitlementsData.entitlements.campaigns;

  const relationships = parentModel?.relationships;
  const events =
    relationships?.filter(
      ({ to_model: { event, type } }) =>
        Boolean(event) && type !== SchemaModelType.Interaction,
    ) ?? [];

  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = metricFormProps;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - Circular type problem with Condition[]
  const config = watch("config");
  const aggregationType = watch("aggregationType");

  const eventOptions = events?.map(
    ({
      id: relationshipId,
      name: relationshipName,
      to_model: { id: eventModelId, name: eventModelName },
    }) => ({
      label: eventModelName || relationshipName,
      value: eventModelId,
      relationshipId: relationshipId,
    }),
  );

  const filterableColumnOptions =
    events
      ?.find(({ id }) => id === Number(config?.relationshipId))
      ?.to_model?.filterable_audience_columns.map(
        ({ alias, name, column_reference }) => ({
          value: column_reference,
          label: alias || name,
        }),
      ) ?? [];

  const filterableNumericColumnOptions =
    events
      ?.find(({ id }) => id === Number(config?.relationshipId))
      ?.to_model?.filterable_audience_columns.filter(
        ({ type }) => type === "number",
      )
      .map(({ alias, name, column_reference }) => ({
        value: column_reference,
        label: alias || name,
      })) ?? [];

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - Circular type problem with Condition[]
  const { fields, append, remove } = useFieldArray({
    control,
    name: "attributionMethods",
  });

  const showAttributionMethod = campaignsEnabled;
  const selectedAggregationName = AggregationOptions.find(
    (option) => option.value === aggregationType,
  )?.label;
  const attributionMethodsEnabled =
    AggregationOptionsWithAttributionMethods.includes(aggregationType);

  return (
    <QueryBuilderProvider parentModel={parentModel}>
      <Column gap={4}>
        <Controller
          control={control}
          name="config"
          render={({ field, fieldState: { error } }) => (
            <FormField
              label="Event"
              description="The event to be tracked"
              error={error?.message}
              tip="If you don't see the event you're looking for, make sure it is related to the parent model."
            >
              <Select
                {...field}
                isInvalid={!!error}
                placeholder="Select an event..."
                value={
                  field.value?.eventModelId
                    ? Number(field.value.eventModelId)
                    : undefined
                }
                options={eventOptions}
                onChange={(eventId: number | undefined) => {
                  const event = eventOptions.find(
                    ({ value }) => value === eventId,
                  );

                  if (event) {
                    field.onChange({
                      ...config,
                      type: ConditionType.Event,
                      eventModelId: event.value,
                      relationshipId: event.relationshipId,
                    });
                  }
                }}
              />
            </FormField>
          )}
        />

        <Controller
          control={control}
          name="aggregationType"
          render={({ field }) => (
            <FormField
              label="Aggregation"
              description="Determines how this metric will be calculated"
            >
              <Select
                {...field}
                options={AggregationOptions}
                onChange={(value) => {
                  if (!value) return;

                  if (!AggregationOptionsWithColumns.includes(value)) {
                    setValue("column", null);
                  }

                  if (
                    !AggregationOptionsWithAttributionMethods.includes(value)
                  ) {
                    setValue("attributionMethods", []);
                  }

                  if (enableMetricFormatting) {
                    setValue(
                      "config.displayFormatting.type",
                      getDefaultFormatting(value).type,
                      { shouldDirty: true },
                    );
                  }

                  field.onChange(value);
                }}
              />
            </FormField>
          )}
        />
        {enableMetricFormatting && <MetricFormattingForm />}

        {AggregationOptionsWithColumns.includes(aggregationType) && (
          <Controller
            control={control}
            name="column"
            render={({ field, fieldState: { error } }) => (
              <FormField
                label={
                  aggregationType === AggregationOption.CountDistinctProperty
                    ? "Count by"
                    : "Sum by"
                }
                description={`The column that will be ${
                  aggregationType === AggregationOption.CountDistinctProperty
                    ? "counted"
                    : "summed"
                } for all applicable events`}
                error={error?.message}
              >
                <Combobox
                  {...field}
                  isInvalid={Boolean(error?.message)}
                  options={
                    aggregationType === AggregationOption.CountDistinctProperty
                      ? filterableColumnOptions
                      : filterableNumericColumnOptions
                  }
                />
              </FormField>
            )}
          />
        )}

        <Controller
          control={control}
          name="config"
          render={({ field }) => (
            <FormField
              isOptional
              label="Filter conditions"
              description={`Filter  ${
                eventOptions.find(
                  ({ value }) => Number(config?.eventModelId) === value,
                )?.label ?? "event"
              } to be counted in this metric`}
            >
              <Column
                bg="gray.50"
                borderRadius="6px"
                flexBasis="fit-content"
                maxWidth="530px"
                p={4}
                position="relative"
                sx={{
                  "& > :not(:last-child)": { mb: 4 },
                }}
              >
                <EventFilter
                  allowParameterization={appMetricParameterization}
                  disableEventSelect
                  hideFunnelCondition
                  hideOperatorFilter
                  hideWindowCondition
                  audience={{} as Audience}
                  condition={
                    field.value
                      ? ({
                          ...field.value.filter,
                          type: ConditionType.Event,
                          eventModelId: Number(field.value.eventModelId),
                          relationshipId: Number(field.value.relationshipId),
                        } as unknown as EventCondition)
                      : initialEventCondition
                  }
                  parent={parentModel}
                  onChange={(value) =>
                    field.onChange({ ...config, filter: value })
                  }
                />
              </Column>
            </FormField>
          )}
        />

        {showAttributionMethod ? (
          <>
            <FormField label="Select attribution method" isOptional>
              <Column gap={2}>
                {fields.map((field, index) => (
                  <Controller
                    key={field.id}
                    control={control}
                    name={`attributionMethods.${index}.id`}
                    render={({ field, fieldState: { error } }) => (
                      <Row gap={2}>
                        <Select
                          isDisabled={!attributionMethodsEnabled}
                          placeholder="Select an attribution method..."
                          emptyOptionsMessage="No attribution methods available. Please create an attribution method."
                          isInvalid={!!error}
                          options={parentModel?.attribution_methods || []}
                          optionLabel={({ name }) => name}
                          optionValue={({ id }) => id}
                          {...field}
                        />

                        <IconButton
                          icon={CloseIcon}
                          aria-label="Remove attribution method"
                          onClick={() => remove(index)}
                        />
                      </Row>
                    )}
                  />
                ))}
                <Box flexShrink={1}>
                  <Tooltip
                    isDisabled={attributionMethodsEnabled}
                    message={`'${selectedAggregationName}' aggregation does not support attribution methods`}
                  >
                    <Button
                      isDisabled={!attributionMethodsEnabled}
                      icon={PlusIcon}
                      onClick={() => append({})}
                    >
                      Add an attribution method
                    </Button>
                  </Tooltip>
                </Box>
              </Column>
            </FormField>
          </>
        ) : (
          <FormField
            label="Attribution window"
            description="During what period should customer actions be attributed back to an audience? Default is 0 days from audience exit."
            error={errors.attributionWindow?.message}
          >
            <Row gap={4}>
              <Box maxWidth="70px">
                <Controller
                  control={control}
                  name="attributionWindow.quantity"
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      isInvalid={Boolean(errors?.attributionWindow?.message)}
                      value={field.value.toString()}
                    />
                  )}
                />
              </Box>
              <Box maxWidth="150px">
                <Controller
                  control={control}
                  name="attributionWindow.unit"
                  render={({ field }) => (
                    <Select options={TIME_OPTIONS} {...field} />
                  )}
                />
              </Box>
              <Box maxWidth="200px">
                <Controller
                  control={control}
                  name="attributionWindow.basis"
                  render={({ field }) => (
                    <Select options={ATTRIBUTION_BASIS_OPTIONS} {...field} />
                  )}
                />
              </Box>
            </Row>
          </FormField>
        )}
      </Column>
    </QueryBuilderProvider>
  );
};
