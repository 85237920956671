import { FC } from "react";

import {
  AudienceIcon,
  CloseIcon,
  Column,
  Combobox,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  ExternalLinkIcon,
  FormField,
  IconButton,
  Radio,
  RadioGroup,
  Row,
  Tooltip,
} from "@hightouchio/ui";
import { Link } from "src/router";
import { yupResolver } from "@hookform/resolvers/yup";
import noop from "lodash/noop";
import { Controller } from "react-hook-form";

import { ErrorMessage } from "src/components/explore/visual/error-message";
import { Form, SaveButton, useHightouchForm } from "src/components/form";
import { IconBox } from "src/components/icon-box";
import { PermissionedEditableHeading } from "src/components/permission";
import { JOURNEY_UPDATE_PERMISSION } from "src/pages/journeys/constants";
import { useGraphContext } from "src/pages/journeys/graph";
import {
  JourneyNodeDetails,
  NodeDetailFormProps,
} from "src/pages/journeys/types";
import { EntryCohortConfig } from "src/types/journeys";

import { StartFormSchema } from "./validation-schemas";

export const StartForm: FC<NodeDetailFormProps<EntryCohortConfig>> = ({
  id, // id techincally lives in data too, redundant to have both :/
  data,
  onClose,
}) => {
  const { audienceOptions, audiencesLoading, isEditMode, onUpdateNode } =
    useGraphContext();

  const form = useHightouchForm<JourneyNodeDetails<EntryCohortConfig>>({
    onSubmit: (entryNodeData) => {
      onUpdateNode(id, entryNodeData);
      onClose();

      return Promise.resolve();
    },
    success: "Tile was saved",
    values: data,
    resolver: yupResolver(StartFormSchema),
  });

  return (
    <Form form={form}>
      <DrawerHeader>
        <Row align="center" justify="space-between" flex={1} minWidth={0}>
          <Controller
            name="name"
            control={form.control}
            render={({ field, fieldState }) => (
              <Column>
                <PermissionedEditableHeading
                  isDisabled={!isEditMode}
                  permission={JOURNEY_UPDATE_PERMISSION}
                  value={field.value}
                  onChange={field.onChange}
                />
                {fieldState.error?.message && (
                  <ErrorMessage fontWeight="normal">
                    {fieldState.error.message}
                  </ErrorMessage>
                )}
              </Column>
            )}
          />
          <IconButton
            aria-label="Close drawer."
            icon={CloseIcon}
            onClick={onClose}
          />
        </Row>
      </DrawerHeader>

      <DrawerBody>
        <Column minHeight={0} flex={1} gap={6} pb={4}>
          {/* TODO: enable when backend types are updated for entry cohort */}
          <Controller
            name="segment_id"
            control={form.control}
            render={({ field, fieldState }) => (
              <FormField
                label="Entry audience"
                description="Users will enter the journey as soon as they enter this audience"
                error={fieldState.error?.message}
              >
                <Row gap={2}>
                  <Combobox
                    isDisabled={!isEditMode}
                    isInvalid={Boolean(fieldState.error?.message)}
                    placeholder="Select an audience..."
                    isLoading={audiencesLoading}
                    optionAccessory={() => ({
                      type: "icon",
                      icon: () => (
                        <IconBox
                          bg="ocean.400"
                          boxSize="20px"
                          icon={<AudienceIcon />}
                          iconSize="14px"
                        />
                      ),
                    })}
                    options={audienceOptions}
                    value={field.value?.toString()}
                    width="100%"
                    optionValue={(option) => option.id.toString()}
                    optionLabel={(option) => option.name}
                    onChange={field.onChange}
                  />
                  {field.value && (
                    <Link href={`/audiences/${field.value}`} isExternal>
                      <Tooltip message="View audience">
                        <IconButton
                          icon={ExternalLinkIcon}
                          aria-label="Link to selected audience"
                          variant="tertiary"
                          onClick={noop}
                        />
                      </Tooltip>
                    </Link>
                  )}
                </Row>
              </FormField>
            )}
          />

          <Controller
            control={form.control}
            name="config.max_num_entries"
            render={({ field, fieldState }) => (
              <FormField
                label="Re-entry"
                description="Can users enter the journey more than once?"
                error={fieldState.error?.message && "Re-entry is required"}
              >
                <RadioGroup
                  isDisabled={!isEditMode}
                  orientation="vertical"
                  value={field.value}
                  onChange={field.onChange}
                >
                  <Radio label="Only one entry" value={1} />
                  <Radio label="Unlimited entries" value={-1} />
                </RadioGroup>
              </FormField>
            )}
          />
        </Column>
      </DrawerBody>

      {isEditMode && (
        <DrawerFooter>
          <SaveButton permission={JOURNEY_UPDATE_PERMISSION}>Update</SaveButton>
        </DrawerFooter>
      )}
    </Form>
  );
};
