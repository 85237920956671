import { useEffect, useState, FC, useMemo } from "react";

import {
  Row,
  Column,
  Text,
  Button,
  Tooltip,
  Alert,
  Combobox,
  FormField,
  Dialog,
} from "@hightouchio/ui";

import { Link } from "src/router";

import { Editor } from "src/components/editor";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import {
  useGetSigmaWorkbooksQuery,
  useGetSigmaPagesQuery,
  useGetSigmaElementsQuery,
  useGetSigmaSqlQuery,
} from "src/graphql";
import { QueryType, Sigma } from "src/types/models";

import { formatSourceSql } from "src/components/sql-editor";

type Props = {
  queryError?: string;
  sigma?: Sigma;
  onChange: (sigmaModel: Sigma) => void;
  source: {
    id: string;
    name: string;
    type: string;
    definition: {
      name: string;
      icon: string;
      isSampleDataSource: boolean;
    };
  };
};

export const SigmaSelector: FC<Readonly<Props>> = ({
  sigma,
  onChange,
  source,
  queryError,
}) => {
  const sigmaQuery = sigma?.query;
  const [workbookId, setWorkbookId] = useState<string | undefined>(
    sigmaQuery?.workbookId,
  );
  const [pageId, setPageId] = useState<string | undefined>(sigmaQuery?.pageId);
  const [elementId, setElementId] = useState<string | undefined>(
    sigmaQuery?.elementId,
  );
  const [showSql, setShowSql] = useState(false);
  const [autoFillSelector, setAutoFillSelector] = useState<boolean>(true);

  const {
    data: workbooks,
    error: workbooksError,
    isLoading: loadingWorkbooks,
  } = useGetSigmaWorkbooksQuery(undefined, {
    select: (data) => data.getSigmaWorkbooks.workbooks,
  });

  const {
    data: pages,
    error: pagesError,
    isLoading: loadingPages,
  } = useGetSigmaPagesQuery(
    { workbookId: String(workbookId) },
    {
      enabled: Boolean(workbookId),
      select: (data) => data.getSigmaPages.pages,
    },
  );

  const {
    data: elements,
    error: elementsError,
    isLoading: loadingElements,
  } = useGetSigmaElementsQuery(
    { workbookId: String(workbookId), pageId: String(pageId) },
    {
      enabled: Boolean(workbookId) && Boolean(pageId),
      select: (data) => data.getSigmaElements.elements,
    },
  );

  const {
    data: elementSql,
    error: elementSqlError,
    isLoading: loadingElementSql,
  } = useGetSigmaSqlQuery(
    { workbookId: String(workbookId), elementId: String(elementId) },
    {
      enabled: Boolean(workbookId) && Boolean(elementId),
      select: (data) => data.getSigmaQuery?.sql,
    },
  );

  const formattedSql = useMemo(
    () => (elementSql ? formatSourceSql(source, elementSql) : ""),
    [elementSql, source],
  );

  useEffect(() => {
    if (workbookId && pageId && elementId) {
      const sigmaModel: Sigma = {
        query: {
          queryType: QueryType.Sigma,
          elementId,
          pageId,
          workbookId,
        },
      };
      onChange(sigmaModel);
    }
  }, [elementId, pageId, workbookId]);

  useEffect(() => {
    if (autoFillSelector && sigma?.query?.workbookId) {
      setAutoFillSelector(false);
      setWorkbookId(sigma?.query?.workbookId);
      setPageId(sigma?.query?.pageId);
      setElementId(sigma?.query?.elementId);
    }
  }, [sigma, autoFillSelector]);

  if (workbooksError) {
    return (
      <Column
        sx={{
          pt: 10,
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Text color="danger.base">
          We were not able to connect to your Sigma instance.
        </Text>
        <Text color="danger.base">
          Please check that your Sigma credentials are configured correctly in{" "}
          <Link href="/extensions/sigma">settings</Link>.
        </Text>
      </Column>
    );
  }

  if (!loadingWorkbooks && workbooks?.length === 0) {
    return (
      <Column
        sx={{
          pt: 10,
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Text color="danger.base">
          It looks like your Sigma account doesn&apos;t have any workbooks. Did
          you remember to publish your workbook?
        </Text>
        <Text color="danger.base">
          Please check that your Sigma credentials are configured correctly in{" "}
          <Link href="/extensions/sigma">settings</Link>.
        </Text>
      </Column>
    );
  }

  if (pagesError || elementsError || elementSqlError) {
    return (
      <Text color="danger.base">
        An error occured. Please check that your Sigma credentials are
        configured correctly in <Link href="/extensions/sigma">settings</Link>.
      </Text>
    );
  }

  let wrongDbWarning;
  const wrongDBErrors = [
    "cross-database references are not implemented",
    // Database FOO does not exist
    // Schema 'foo'."public" does not exist
    "does not exist or not authorized",
  ];
  if (
    queryError &&
    wrongDBErrors.some((errorString) => queryError.includes(errorString))
  ) {
    wrongDbWarning =
      "Are you sure the workbook's source connection in Sigma is the same as this model's source in Hightouch?";
  }

  return (
    <>
      {wrongDbWarning && (
        <Alert
          variant="inline"
          message={wrongDbWarning}
          title="Wrong database?"
          type="warning"
        />
      )}
      <Column
        width="100%"
        border="1px"
        borderColor="base.border"
        borderRadius="md"
        overflow="hidden"
        minWidth={0}
      >
        <Row
          align="center"
          px={4}
          py={4}
          borderBottom="1px"
          borderColor="base.border"
          gap={4}
          justify="space-between"
        >
          <Row align="center" gap={2}>
            <IntegrationIcon
              src={source?.definition.icon}
              name={source?.definition.name}
            />
            <Text fontWeight="medium" size="lg">
              {source?.name ?? "Private source"}
            </Text>
          </Row>
          <Tooltip isDisabled={!!elementId} message="Please select an element">
            <Button
              isDisabled={!pageId || !elementId}
              isLoading={loadingElementSql}
              onClick={() => {
                setShowSql(true);
              }}
            >
              View SQL
            </Button>
          </Tooltip>
        </Row>
        <Column gap={6} p={6} overflow="auto">
          <FormField label="Workbook">
            <Combobox
              isLoading={loadingWorkbooks}
              options={
                workbooks?.map((workbook) => ({
                  value: workbook?.workbookId,
                  label: workbook?.name,
                })) ?? []
              }
              placeholder="Select a workbook..."
              value={workbookId}
              onChange={(workbookId) => {
                // Changing the workbook should clear the page and element drop downs.
                setElementId(undefined);
                setPageId(undefined);
                setWorkbookId(workbookId);
              }}
            />
          </FormField>

          <FormField label="Page">
            <Combobox
              isDisabled={!workbookId}
              isLoading={loadingPages}
              options={
                pages?.map((page) => ({
                  value: page?.pageId,
                  label: page?.name,
                })) ?? []
              }
              placeholder="Select a page..."
              value={pageId}
              onChange={(pageId) => {
                // Changing the page should clear the elements drop down.
                setElementId(undefined);
                setPageId(pageId);
              }}
            />
          </FormField>

          <FormField label="Element">
            <Combobox
              isDisabled={!pageId}
              isLoading={loadingElements}
              options={
                elements?.map((element) => ({
                  value: element?.elementId,
                  label: element?.name,
                })) ?? []
              }
              placeholder="Select an element..."
              value={elementId}
              onChange={setElementId}
            />
          </FormField>
        </Column>

        <Dialog
          isOpen={showSql}
          variant="info"
          width="auto"
          title="SQL"
          actions={<Button onClick={() => setShowSql(false)}>Close</Button>}
          onClose={() => setShowSql(false)}
        >
          <Editor readOnly language="sql" value={formattedSql} />
        </Dialog>
      </Column>
    </>
  );
};
