import { FC } from "react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  Row,
  Text,
  IconButton,
  RowProps,
} from "@hightouchio/ui";

export type SimplePaginationProps = {
  onNext: () => void;
  onPrevious: () => void;
  nextLoading?: boolean;
  previousLoading?: boolean;
  page: number;
  pages?: number;
};

export const SimplePagination: FC<
  Readonly<SimplePaginationProps & RowProps>
> = ({
  page,
  pages,
  onNext,
  onPrevious,
  nextLoading,
  previousLoading,
  ...props
}) => {
  if (!pages || pages <= 1) {
    return null;
  }

  return (
    <Row alignSelf="flex-end" mt={4} alignItems="center" {...props}>
      <IconButton
        aria-label="Previous page."
        isDisabled={page === 0}
        isLoading={previousLoading}
        size="sm"
        variant="secondary"
        icon={ChevronLeftIcon}
        onClick={onPrevious}
      />

      <Text color="text.secondary" mx={2}>
        {pages ? `${page + 1} / ${pages}` : page + 1}
      </Text>

      <IconButton
        aria-label="Next page."
        isDisabled={page === pages - 1}
        isLoading={nextLoading}
        size="sm"
        variant="secondary"
        icon={ChevronRightIcon}
        onClick={onNext}
      />
    </Row>
  );
};
