import * as Sentry from "@sentry/react";
import immutableUpdate from "immutability-helper";

import {
  AndCondition,
  AndOrCondition,
  Condition,
  ConditionType,
  EventCondition,
  FunnelCondition,
  NumberOfCondition,
  OrCondition,
  PropertyCondition,
  ReferencedPropertyCondition,
} from "src/types/visual";

import {
  ConditionWithSubconditions,
  formatSubconditions,
} from "./format-subconditions";
import {
  isAndOrCondition,
  isPropertyOrReferencePropertyCondition,
} from "./type-guards";

export type ParentCondition =
  | AndCondition<AndOrCondition<Condition>>
  | OrCondition<AndOrCondition<Condition>>;

type BaseEventOrNumberOfCondition = {
  type: ConditionType.Event | ConditionType.NumberOf;
  subconditions?: AndOrCondition<PropertyCondition>[];
};

export const addSubcondition = (
  condition: ParentCondition,
  newSubcondition: Condition,
) => ({
  ...condition,
  conditions: [...condition.conditions, newSubcondition],
});

export const updateSubcondition = (
  index: number,
  condition: ParentCondition,
  updatedSubcondition: Condition,
) => {
  if (condition.conditions.length < index || !condition.conditions[index]) {
    return condition;
  }

  const newConditions = immutableUpdate(condition.conditions, {
    [index]: { $set: updatedSubcondition },
  });

  return { ...condition, conditions: newConditions };
};

export const updateConditionAtIndex = (
  index: number,
  conditions: PropertyCondition[],
  updates: Partial<PropertyCondition>,
) => {
  if (conditions.length < index || !conditions[index]) {
    return conditions;
  }

  return immutableUpdate(conditions, { [index]: { $merge: updates } });
};

export const removeSubcondition = (
  index: number,
  condition: ParentCondition,
) => {
  const newConditions = immutableUpdate(condition.conditions, {
    $splice: [[index, 1]],
  });

  return { ...condition, conditions: newConditions };
};

export const groupSubcondition = (
  index: number,
  condition: ParentCondition,
) => {
  const subcondition = condition.conditions[index];
  if (index > condition.conditions.length || !subcondition) {
    return condition;
  }

  const newCondition: AndCondition | OrCondition = {
    type:
      condition.type === ConditionType.And
        ? ConditionType.Or
        : ConditionType.And,
    conditions: [subcondition],
  };

  const newConditions = [
    ...condition.conditions.slice(0, index),
    newCondition,
    ...condition.conditions.slice(index + 1, condition.conditions.length),
  ];

  return { ...condition, conditions: newConditions };
};

export const ungroupSubcondition = (
  index: number,
  condition: ParentCondition,
) => {
  const conditionToUnwrap = condition.conditions[index];

  if (!isAndOrCondition(conditionToUnwrap)) {
    return condition;
  }

  // take out conditions
  // put them into the array where the old condition existed
  const newConditions = [
    ...condition.conditions.slice(0, index),
    ...(conditionToUnwrap.conditions ?? []),
    ...condition.conditions.slice(index + 1),
  ];

  return { ...condition, conditions: newConditions };
};

export function updatePropertySubcondition(
  index: number,
  condition: NumberOfCondition,
  updates: Partial<PropertyCondition>,
): NumberOfCondition;
export function updatePropertySubcondition(
  index: number,
  condition: EventCondition,
  updates: Partial<PropertyCondition>,
): EventCondition;
export function updatePropertySubcondition(
  index: number,
  condition: BaseEventOrNumberOfCondition,
  updates: Partial<PropertyCondition>,
): BaseEventOrNumberOfCondition {
  let newSubconditions: AndOrCondition<PropertyCondition>[] = [];

  const topLevelSubcondition = condition.subconditions?.[0];

  if (!isAndOrCondition(topLevelSubcondition)) {
    // No wrapping and/or condition
    if (!condition.subconditions || index >= condition.subconditions.length) {
      return condition;
    }

    // Add wrapping
    newSubconditions = [
      {
        type: ConditionType.And,
        conditions:
          immutableUpdate(condition.subconditions, {
            [index]: { $merge: updates },
          }) ?? [],
      },
    ];
  } else {
    // With wrapped and/or condition
    if (index >= topLevelSubcondition.conditions.length) {
      return condition;
    }

    newSubconditions =
      immutableUpdate(condition.subconditions, {
        [0]: { conditions: { [index]: { $merge: updates } } },
      }) ?? [];
  }

  return immutableUpdate(condition, {
    subconditions: { $set: newSubconditions },
  });
}

export function updateFunnelPropertySubcondition(
  index: number,
  condition: FunnelCondition,
  updates: Partial<PropertyCondition | ReferencedPropertyCondition>,
): FunnelCondition {
  // make sure wrapping condition is an and/or
  const formattedSubconditions = formatSubconditions(condition).subconditions;
  const topLevelSubcondition = formattedSubconditions?.[0];

  // With wrapped and/or condition
  if (
    !topLevelSubcondition ||
    !isAndOrCondition(topLevelSubcondition) ||
    index >= topLevelSubcondition.conditions.length
  ) {
    return condition;
  }

  const updatedCondition = {
    ...topLevelSubcondition.conditions[index],
    ...updates,
  };
  if (!isPropertyOrReferencePropertyCondition(updatedCondition)) {
    return condition;
  }

  const newSubconditions = [
    {
      ...topLevelSubcondition,
      conditions: immutableUpdate(topLevelSubcondition.conditions, {
        $splice: [[index, 1, updatedCondition]],
      }),
    },
  ];

  return immutableUpdate(condition, {
    subconditions: { $set: newSubconditions },
  });
}

export function removePropertySubcondition(
  index: number,
  condition: EventCondition,
): EventCondition;
export function removePropertySubcondition(
  index: number,
  condition: NumberOfCondition,
): NumberOfCondition;
export function removePropertySubcondition(
  index: number,
  condition: FunnelCondition,
): FunnelCondition;
export function removePropertySubcondition(
  index: number,
  condition: ConditionWithSubconditions,
): ConditionWithSubconditions {
  if (
    !condition.subconditions ||
    !isAndOrCondition(condition.subconditions?.[0])
  ) {
    // No wrapping and/or condition
    return {
      ...condition,
      subconditions: [
        {
          type: ConditionType.And,
          conditions: (condition.subconditions ?? []).filter(
            (_, i) => i !== index,
          ),
        },
      ],
    };
  }

  if (condition.subconditions.length > 1) {
    // There are multiple and/or conditions. This should not happen.
    const error = new Error(
      "Event condition with invalid subconditions detected",
    );

    Sentry.captureException(error, {
      extra: {
        condition: JSON.stringify(condition),
      },
    });

    return condition;
  }

  const topLevelSubcondition = condition.subconditions[0];

  return {
    ...condition,
    subconditions: [
      {
        type: topLevelSubcondition.type,
        conditions: topLevelSubcondition.conditions.filter(
          (_, i) => i !== index,
        ),
      },
    ],
  };
}
