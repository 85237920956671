import { FC } from "react";

import { Alert, Column } from "@hightouchio/ui";

import { LinkButton } from "src/router";
import { useOutletContext } from "src/router";
import { SyncRuns } from "src/pages/syncs/sync/runs";
import { DuplicateKeyWarning } from "src/pages/syncs/sync/components/duplicate-key-warnings";

import { OutletContext } from ".";

export const Runs: FC = () => {
  const { sync, startRun } = useOutletContext<OutletContext>();

  const duplicatePrimaryKeysCount =
    sync.segment?.last_run_duplicate_primary_keys;
  const plannerType = sync.last_run_planner_type;
  const hasPrimaryKeyIssue = Boolean(
    sync.segment?.columns?.length &&
      !sync.segment?.columns.some((c) => c.name === sync.segment?.primary_key),
  );

  return (
    <>
      <Column flex={1} minH={0} p={4}>
        {!!duplicatePrimaryKeysCount &&
          plannerType !== "all" &&
          plannerType !== null && (
            <DuplicateKeyWarning
              count={duplicatePrimaryKeysCount}
              primaryKey={sync.segment?.primary_key || ""}
              parentModelUrl="/model"
            />
          )}
        {hasPrimaryKeyIssue && (
          <Alert
            variant="inline"
            type="warning"
            title="Undefined primary key"
            message="Without a model primary key, your syncs may fail, or undefined behavior may occur. Please check to make sure that your primary key is set to a valid column."
            actions={<LinkButton href="/model">Go to your model</LinkButton>}
          />
        )}
        <SyncRuns
          redirectPrefix="/partner"
          sync={sync}
          onRun={() => {
            startRun({ resync: false });
          }}
        />
      </Column>
    </>
  );
};
