import { Route, Routes } from "src/router";

import { CreatePriorityList } from "./create-priority-list";
import { PriorityList } from "./priority-list";
import { PriorityLists } from "./priority-lists";

export const PriorityListsRouter = () => {
  return (
    <Routes>
      <Route index element={<PriorityLists />} />
      <Route path="new" element={<CreatePriorityList />} />
      <Route path=":priority_list_id" element={<PriorityList />} />
    </Routes>
  );
};
