import { FC, useEffect } from "react";

import { Alert } from "@hightouchio/ui";

import { LinkButton } from "src/router";

import { useUser } from "src/contexts/user-context";
import * as analytics from "src/lib/analytics";

export const IncidentBanner: FC<{
  title: string;
  message: string;
  onDismiss: () => void;
}> = ({ title, message, onDismiss }) => {
  const { workspace } = useUser();

  useEffect(() => {
    analytics.track("Incident Banner Shown", {
      workspace_id: workspace?.id,
    });
  }, []);

  return (
    <Alert
      variant="banner"
      type="warning"
      title={title}
      message={message}
      actions={
        <LinkButton
          target="_blank"
          href={import.meta.env.VITE_STATUS_PAGE_URL}
          onClick={() => {
            analytics.track("Incident Banner Status Page Clicked", {
              workspace_id: workspace?.id,
            });
          }}
        >
          View status page
        </LinkButton>
      }
      onDismiss={onDismiss}
    />
  );
};
