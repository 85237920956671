import { FC } from "react";

import {
  Box,
  Column,
  InformationIcon,
  Row,
  Switch,
  Text,
  Tooltip,
} from "@hightouchio/ui";
import { useFormContext } from "react-hook-form";
import { useDecisionEngineFlowQuery } from "src/graphql";
import { useAnalyticsContext } from "../../state";
import { DEFAULT_GROUP_BY_COLUMNS } from "../../state/constants";
import {
  ChartFormState,
  MeasurementScope,
  MeasuringMode,
  MeasuringSelection,
  TimeOptions,
} from "../../types";

type MeasureIncrementalityProps = {
  measuringSelection: MeasuringSelection | undefined;
};

export const MeasureIncrementality: FC<MeasureIncrementalityProps> = ({
  measuringSelection,
}) => {
  const isDecisionEngineScope =
    measuringSelection?.scope === MeasurementScope.DecisionEngineFlow;

  const { data: decisionEngineFlow } = useDecisionEngineFlowQuery(
    { id: measuringSelection?.id ?? "" },
    {
      select: (data) => data.decision_engine_flows_by_pk,
      enabled: Boolean(isDecisionEngineScope && measuringSelection?.id),
    },
  );

  const flowConfig = decisionEngineFlow?.config;

  const form = useFormContext<ChartFormState>();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: Suppress circular reference error
  const measuringMode = form.watch("measuringMode");

  const { setSelectedDates, setLookbackWindow } = useAnalyticsContext();

  const isIncrementalityMode = measuringMode === MeasuringMode.Incrementality;

  // Expect holdout in config to be a decimal value
  const holdoutPercent = Math.round((flowConfig?.holdout ?? 0) * 100);
  const treatmentPercent = 100 - holdoutPercent;

  const onToggle = () => {
    if (isIncrementalityMode) {
      form.setValue("measuringMode", MeasuringMode.Attribution);
      setSelectedDates([]);
      setLookbackWindow(TimeOptions.SevenDays);
    } else {
      form.setValue("measuringMode", MeasuringMode.Incrementality);

      // Set cumulative to true by default for incrementality mode
      form.setValue("cumulative", true);

      // Set the default date range to the flow start date if available
      const flowStartDate = flowConfig?.flow_start_date;
      if (flowStartDate) {
        setSelectedDates([new Date(flowStartDate), new Date()]);
      } else {
        setSelectedDates([]);
        setLookbackWindow(TimeOptions.SevenDays);
      }
    }

    // Reset these values since these options depend on the mode
    form.setValue("groupByColumns", DEFAULT_GROUP_BY_COLUMNS);
  };

  if (!measuringSelection || !isDecisionEngineScope) {
    return null;
  }

  return (
    <Column
      display="flex"
      p={4}
      backgroundColor="gray.100"
      borderRadius="md"
      gap={2}
    >
      <Text color="text.primary">
        This flow has{" "}
        <Tooltip
          message={
            <Column>
              <Text color="white">Holdout ({holdoutPercent}%)</Text>
              <Text color="white">Treatment ({treatmentPercent}%)</Text>
            </Column>
          }
        >
          <Box as="span" textDecoration="underline">
            2
          </Box>
        </Tooltip>{" "}
        experiment groups
      </Text>
      <Row gap={2} alignItems="center">
        <Switch
          size="sm"
          isChecked={isIncrementalityMode}
          onChange={onToggle}
        />
        <Row gap={1}>
          <Text color="text.primary" fontWeight="medium">
            Measure incremental lift
          </Text>

          <Tooltip
            message={
              <Box width="225px">
                <Text color="white">
                  Measure the incremental lift of the treatment group over the
                  holdout
                </Text>
              </Box>
            }
          >
            <InformationIcon color="text.tertiary" />
          </Tooltip>
        </Row>
      </Row>
    </Column>
  );
};
