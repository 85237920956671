import { useEffect, useState, FC } from "react";

import { Combobox, IconButton, RefreshIcon, Row } from "@hightouchio/ui";

import { useModelRun, useUpdateQuery, ModelState } from "src/utils/models";
import { useModelState } from "src/hooks/use-model-state";

type BaseProps = {
  model: ModelState | null | undefined;
  value: string | null | undefined;
  columns?: { name: string }[];
  creatable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isInvalid?: boolean;
};

type ClearableProps = BaseProps & {
  isClearable: true;
  onChange: (value: string | null) => void;
};

type NonClearableProps = BaseProps & {
  isClearable?: false;
  onChange: (value: string) => void;
};

export const ColumnSelect: FC<Readonly<ClearableProps | NonClearableProps>> = ({
  model,
  columns,
  creatable,
  onChange,
  value,
  isDisabled,
  isLoading,
  isClearable,
  isInvalid,
}) => {
  const modelState = useModelState(model);
  const [loading, setLoading] = useState<boolean>(false);

  const cols = columns || model?.columns;
  const columnOptions: { value: string; label: string }[] = cols
    ? cols.map(({ name }) => ({ value: name, label: name }))
    : [];

  const update = useUpdateQuery();

  const { runQuery } = useModelRun(modelState.state, {
    onCompleted: async ({ columns }, error) => {
      if (!error) {
        await update({ model: modelState.state, columns });
      }
      setLoading(false);
    },
  });

  useEffect(() => {
    if (model) {
      modelState.set(model);
    }
  }, [model]);

  return (
    <Row gap={2} align="center">
      <Combobox
        isInvalid={isInvalid}
        isClearable={isClearable}
        isDisabled={isDisabled}
        supportsCreatableOptions={creatable}
        isLoading={loading || isLoading}
        options={columnOptions}
        value={value ?? ""}
        onChange={(value) => {
          if (isClearable) {
            onChange(value ?? null);
          } else {
            if (value) {
              onChange(value);
            }
          }
        }}
        onCreateOption={(newValue) => {
          onChange(newValue);
        }}
        placeholder="Select a column..."
      />
      {!isDisabled && (
        <IconButton
          isDisabled={loading}
          variant="secondary"
          aria-label="Refresh columns"
          icon={RefreshIcon}
          onClick={async () => {
            setLoading(true);
            await runQuery({
              limit: true,
              // No reason to include this here, since it only increases the
              // complexity of the query and doesn't affect the results.
              disableRowCounter: true,
            });
          }}
        />
      )}
    </Row>
  );
};
