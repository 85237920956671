import { useEffect, useState } from "react";

import {
  Column,
  Box,
  Heading,
  Textarea,
  FormField,
  TextInput,
  Combobox,
  Button,
} from "@hightouchio/ui";
import { FormProvider } from "react-hook-form";
import { useLocation, useSearchParams } from "src/router";

import { AccordionSection } from "src/components/accordion-section";
import { Explore } from "src/components/explore/explore";
import { QueryTypeSelect } from "src/components/explore/query-type-select";
import { AddFolder } from "src/components/folders/add-folder";
import { useFolders } from "src/components/folders/use-folders";
import { getTagsFromLabels, LabelForm } from "src/components/labels/label-form";
import { ResourceType } from "src/components/labels/use-labels";
import { SourceSelect } from "src/components/sources/source-select";
import { useUser } from "src/contexts/user-context";
import { useCreateModelMutation, useSourceQuery } from "src/graphql";
import * as analytics from "src/lib/analytics";
import { QueryableSource, QueryType } from "src/types/models";
import { WizardStep } from "src/components/wizard";
import { getModelInputFromState, useModelRun } from "src/utils/models";
import { SlugResourceType, useResourceSlug } from "src/utils/slug";
import { useQueryString } from "src/utils/use-query-string";
import { useWizardStepper } from "src/utils/use-wizard-stepper";
import { useHightouchForm } from "src/components/form";
import { useModelCreationPermission } from "src/components/permission/creation/model";
import { useModelState } from "src/hooks/use-model-state";

export const useCreateModelWizard = () => {
  const location = useLocation();
  const { workspace } = useUser();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [source, setSource] = useState<QueryableSource | undefined | null>();
  const modelState = useModelState(source ? { connection: source } : undefined);
  const { data: params } = useQueryString();
  const [step, setStep] = useWizardStepper(0);
  const { getSlug } = useResourceSlug(SlugResourceType.Segments);
  const [searchParams] = useSearchParams();
  const {
    state: { flattenedFolders },
    refetchFolders,
  } = useFolders({ folderType: "models", viewType: "models" });
  const [selectedFolder, setSelectedFolder] = useState<
    string | undefined | null
  >(searchParams.get("folder"));
  const [addFolderOpen, setAddFolderOpen] = useState(false);

  useSourceQuery(
    { id: String(params?.source) },
    {
      select: (data) => data.connections_by_pk,
      onSuccess: (data) => {
        if (data) {
          setSource(data);
        }
      },
      enabled: Boolean(params?.source),
    },
  );

  const { mutateAsync: createModel } = useCreateModelMutation();

  const {
    runQuery,
    cancelQuery,
    getSchema,
    resetRunState,
    rows,
    numRowsWithoutLimit,
    isResultTruncated,
    columns,
    loading: queryLoading,
    error: queryError,
    errorAtLine: queryErrorAtLine,
    rowsCount,
    asyncPagination,
    page,
  } = useModelRun(modelState.state);

  const form = useHightouchForm({
    onSubmit: async ({ labels }) => {
      const slug = await getSlug(name);

      const data = await createModel({
        input: {
          name,
          description,
          ...getModelInputFromState(modelState.state),
          slug,
          columns: { data: columns },
          draft: workspace?.approvals_required,
          folder_id: selectedFolder,
          tags: getTagsFromLabels(labels),
        },
      });

      if (!data) {
        return;
      }

      const id = data.insert_segments_one?.id;

      analytics.track("Model Created", {
        workspace_id: workspace?.id,
        workspace_slug: workspace?.slug,
        model_id: id,
        model_name: name,
        source_id: source?.id,
        source_type: source?.type,
        query_type: modelState.state.query_type,
        origin_page: location.pathname,
      });

      return { id, type: modelState.state.query_type };
    },
    success: workspace?.approvals_required
      ? `Draft created`
      : `Model "${name}" was created`,
    defaultValues: {
      labels: [{ key: "", value: "" }],
    },
  });

  const { sourceFilter } = useModelCreationPermission();

  const steps: WizardStep[] = [
    {
      title: "Select source",
      continue: "Click on a source to continue",
      header: <Heading>Select a data source</Heading>,
      render: () => (
        <SourceSelect onSelect={setSource} isOptionAvailable={sourceFilter} />
      ),
    },
    {
      title: "Define model",
      disabled:
        // if query is undefined, or we don't support result schema,
        // users have to preview or fix their errors
        !modelState.isValid ||
        (source?.definition?.supportsResultSchema
          ? false
          : !modelState.isPreviewFresh || Boolean(queryError)),
      onContinue: async () => {
        if (
          source?.definition?.supportsResultSchema &&
          !modelState.isPreviewFresh
        ) {
          const { error } = (await getSchema()) || {};
          if (error) {
            return;
          }
        }
        setStep((step) => step + 1);
      },
      header: modelState.state.query_type ? null : (
        <Heading>Select a modeling method</Heading>
      ),
      continue: modelState.state.query_type
        ? ""
        : "Click on a modeling method to continue",
      continueTooltip:
        // if query is defined and we don't support result schema,
        // ask them to either fix their error or preview before continuing
        modelState.isValid &&
        !source?.definition?.supportsResultSchema &&
        !modelState.isPreviewFresh
          ? queryError
            ? "Fix the error and preview again to continue"
            : "Preview query to continue"
          : undefined,
      render: () =>
        modelState.state.query_type ? (
          <Explore
            modelState={modelState}
            asyncPagination={asyncPagination}
            cancelQuery={cancelQuery}
            columns={columns}
            isResultTruncated={Boolean(isResultTruncated)}
            numRowsWithoutLimit={numRowsWithoutLimit}
            rows={rows}
            rowsCount={rowsCount}
            runQuery={runQuery}
            source={source}
            error={queryError}
            errorAtLine={queryErrorAtLine}
            loading={queryLoading}
            page={page}
            rowsPerPage={100}
          />
        ) : (
          <QueryTypeSelect
            selected={modelState.state.query_type as QueryType}
            source={source}
            onChange={(query_type) => {
              modelState.onChange({ query_type });
            }}
          />
        ),
    },
    {
      title: "Finalize model",
      disabled: !modelState.state.primary_key || !name,
      header: <Heading>Finalize settings for this model</Heading>,
      render: () => {
        const columnOptions =
          columns?.map(({ name }) => ({ value: name, label: name })) ?? [];

        return (
          <Column gap={6}>
            <FormField
              tip="Including details about the model's contents and business purpose"
              label="Model name"
            >
              <TextInput
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </FormField>
            <FormField isOptional label="Description">
              <Textarea
                placeholder="Enter a description..."
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormField>
            <FormField
              tip="This is the column that uniquely identifies each row (e.g., customer ID, email address, invoice number)"
              label="Primary key"
            >
              <Combobox
                optionLabel={(co) => co.label}
                optionValue={(co) => co.value}
                options={columnOptions as { value: string; label: string }[]}
                placeholder="Select a column..."
                value={
                  columnOptions?.find(
                    (co) => co.value === modelState.state.primary_key,
                  )?.value
                }
                onChange={(primary_key) => {
                  modelState.onChange({ primary_key });
                }}
              />
            </FormField>
            <FormField isOptional label="Move to folder">
              <Combobox
                isClearable
                optionLabel={(folder) => folder.path.replaceAll("/", " / ")}
                optionValue={(folder) => folder.id}
                options={flattenedFolders || []}
                placeholder="Select a folder..."
                value={selectedFolder}
                onChange={(folder) => {
                  setSelectedFolder(folder);
                }}
              />
              <Box mt="2">
                <Button size="sm" onClick={() => setAddFolderOpen(true)}>
                  New Folder
                </Button>
              </Box>
              {addFolderOpen && (
                <AddFolder
                  toggleDisabled
                  folderType="models"
                  viewType="models"
                  onSave={(folder) => {
                    setSelectedFolder(folder);
                    refetchFolders();
                  }}
                  onClose={() => {
                    setAddFolderOpen(false);
                  }}
                />
              )}
            </FormField>

            <FormProvider {...form}>
              <AccordionSection label="Advanced configuration (optional)">
                <LabelForm
                  heading="Add labels"
                  hint="Example keys: team, project, region, env."
                  resourceType={ResourceType.Model}
                />
              </AccordionSection>
            </FormProvider>
          </Column>
        );
      },
    },
  ];

  useEffect(() => {
    resetRunState();
    if (source) {
      setStep(1);
    }
  }, [source]);

  useEffect(() => {
    if (step === 0) {
      setSource(undefined);
    }
  }, [step]);

  useEffect(() => {
    if (source) {
      analytics.track("Add Model Source Selected", {
        workspace_id: workspace?.id,
        workspace_slug: workspace?.slug,
        source_type: source?.type,
        source_name: source?.name,
      });
    }
  }, [source]);

  useEffect(() => {
    if (columns?.length && !queryError) {
      modelState.setIsPreviewFresh(true);
    }
  }, [rows, columns]);

  return {
    createModel: form.submit,
    setStep,
    step,
    steps,
    selectedSourceId: source?.id,
  };
};
