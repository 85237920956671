import {
  createContext,
  useContext,
  FC,
  useState,
  SetStateAction,
  Dispatch,
  ReactNode,
} from "react";

export type HeaderHeightContextType = {
  headerHeight: number;
  setHeaderHeight: Dispatch<SetStateAction<number>>;
};

const defaultContextValue = {
  headerHeight: 0,
  setHeaderHeight: () => {},
};

export const HeaderHeightContext =
  createContext<HeaderHeightContextType>(defaultContextValue);

export const useHeaderHeight = () => useContext(HeaderHeightContext);

interface Props {
  children: ReactNode;
}

export const HeaderHeightProvider: FC<Props> = ({ children }) => {
  const [headerHeight, setHeaderHeight] = useState(0);

  return (
    <HeaderHeightContext.Provider value={{ headerHeight, setHeaderHeight }}>
      {children}
    </HeaderHeightContext.Provider>
  );
};
