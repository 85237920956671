import { FC, ReactNode } from "react";
import { Alert } from "@hightouchio/ui";

import { QueryType } from "src/types/models";

type Props = {
  type: QueryType.JourneyNode | QueryType.DecisionEngine;
  actions?: ReactNode;
  overriden?: boolean;
};

const getTitle = (type: QueryType.JourneyNode | QueryType.DecisionEngine) => {
  switch (type) {
    case QueryType.JourneyNode:
      return {
        title: "Journey node managed model",
        message:
          "This query is managed by Hightouch and associated with a journey node",
      };
    case QueryType.DecisionEngine:
      return {
        title: "AI Decisioning managed model",
        message:
          "This query is managed by Hightouch and associated with a flow message",
      };
  }
};

export const ManagedQuery: FC<Readonly<Props>> = ({
  actions,
  type,
  overriden,
}) => {
  const { title, message } = getTitle(type);
  return (
    <Alert
      actions={actions}
      title={title}
      message={overriden ? `This managed query has been overridden` : message}
      variant="inline"
      type={overriden ? "warning" : "info"}
    />
  );
};
