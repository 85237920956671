export const SPACE_TINT = "#2E447D";

export const GRADIENT =
  "linear-gradient(135deg, rgba(236,251,255,1) 10%, rgba(213,227,255,1) 100%)";

export const featureGateProps = {
  borderColor: "ocean",
  borderWidth: 1,
  borderRadius: "lg",
  bg: "gray.100",
};
