import { useEffect, useState } from "react";

import {
  UseQueryOptions,
  UseQueryResult,
  useQuery as useReactQuery,
} from "react-query";

import { fetcher } from "src/utils/fetcher";

import {
  generateKey,
  HandlerCache,
  newLocalStorageHandlerCache,
} from "./cache";

export type GraphQLFetchProps = {
  destinationId: number;
  isRefetch?: boolean;
  modelId: number;
  query: any;
  variables: any;
};

const handlerCache: HandlerCache = newLocalStorageHandlerCache();

export const graphQLFetch = async ({
  destinationId,
  isRefetch,
  modelId,
  query,
  variables,
}: GraphQLFetchProps) => {
  const enableCache: boolean = variables?.input?.variables?.enableCache;
  const handler: string = variables?.input?.handler;
  const handlerVariables: Record<string, unknown> = variables?.input?.variables;
  if (query) {
    const key = generateKey(destinationId, modelId, handler, handlerVariables);
    // Access cache when component supports caching and user is not refetching data
    if (enableCache && !isRefetch) {
      const cachedData = await handlerCache.get(key);
      if (cachedData) {
        return cachedData;
      }
    }

    const fetch = fetcher(query, {
      ...variables,
      input: {
        ...(variables.input ?? {}),
        variables: {
          ...(handlerVariables ?? {}),
          isRefetch,
        },
      },
    });
    const response = await fetch();
    const queryData = response
      ? Object.values(response as Record<string, unknown>)?.[0]
      : undefined;
    if (queryData && enableCache) {
      await handlerCache.set(key, queryData);
    }
    return queryData;
  }
};

type UseQueryWrapperOptions<TResult, TError> = Omit<
  UseQueryOptions<TResult, TError>,
  "queryFn"
> & {
  fetchProps: GraphQLFetchProps;
};

type UseQueryWrapperResult<TResult, TError> = Omit<
  UseQueryResult<TResult, TError>,
  "refetch"
> & {
  refetch: () => void;
};

export function useQuery<TResult = unknown, TError = unknown>(
  key: string,
  options: UseQueryWrapperOptions<TResult, TError>,
): UseQueryWrapperResult<TResult, TError> {
  const [isRefetch, setIsRefetch] = useState(false);
  const { fetchProps, ...restProps } = options;
  const result = useReactQuery<TResult, TError>(key, {
    ...restProps,
    queryFn: () =>
      graphQLFetch({
        ...fetchProps,
        isRefetch,
      }),
  });

  /**
   * We use handleRefetch and useEffect to make sure that the isRefetch parameter passed to graphQLFetch is updated when the refetch
   * function is called. When refetching the data, we want to bypass the cache and useQuery's refetch does not accept parameters
   */
  useEffect(() => {
    if (isRefetch) {
      result.refetch();
      setIsRefetch(false);
    }
  }, [isRefetch]);

  const handleRefetch = () => {
    setIsRefetch(true);
  };

  return {
    ...result,
    refetch: handleRefetch,
  };
}
