import { Paragraph } from "@hightouchio/ui";

export const ResyncWarning = () => (
  <Paragraph>
    By triggering a full resync, you are instructing Hightouch to reprocess your
    entire model as if the sync is running for the first time. In other words,
    all rows—including those that were already processed during previous sync
    runs—will be sent to the destination again. A full resync does not delete or
    clear records. Be sure to understand your sync configuration and the nature
    of your destination before proceeding with a full resync. Generally, this
    action can be safe for syncs that perform updates or upserts. However, in
    some other cases (like insert-only configurations), a full resync may
    produce duplicate records in the destination.
  </Paragraph>
);
