import { FC, useMemo, useRef } from "react";

import {
  AudienceIcon,
  Box,
  Button,
  ChakraPopover,
  ChakraPopoverBody,
  ChakraPopoverContent,
  Portal,
  useOutsideClick,
} from "@hightouchio/ui";

type FunnelGraphDropdownProps = {
  isOpen: boolean;
  label: string;
  position: { x: number; y: number };
  onClick: () => void;
  onClose: () => void;
};

export const FunnelGraphDropdown: FC<FunnelGraphDropdownProps> = ({
  isOpen,
  label,
  position,
  onClick,
  onClose,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const { x, y } = useMemo(() => {
    return {
      x: position.x,
      y: position.y,
    };
  }, [position.x, position.y]);

  useOutsideClick({
    enabled: isOpen,
    ref: containerRef,
    handler: onClose,
  });

  return (
    <Portal>
      <Box ref={containerRef} position="absolute" left={x} top={y}>
        <ChakraPopover isOpen={isOpen} initialFocusRef={buttonRef}>
          <ChakraPopoverContent width="xs">
            <ChakraPopoverBody px={0}>
              <Box
                sx={{
                  button: {
                    fontWeight: "normal",
                    borderRadius: 0,
                    justifyContent: "start",
                    width: "100%",
                  },
                }}
              >
                <Button
                  ref={buttonRef}
                  icon={AudienceIcon}
                  variant="tertiary"
                  onClick={onClick}
                >
                  {label}
                </Button>
              </Box>
            </ChakraPopoverBody>
          </ChakraPopoverContent>
        </ChakraPopover>
      </Box>
    </Portal>
  );
};
