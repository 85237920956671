/**
 * Retreive unresolved incidents on our StatusPage
 * This endpoint will only return incidents in the Investigating, Identified, or Monitoring state.
 *
 * @source https://metastatuspage.com/api#incidents-unresolved
 */

export type StatusPageResponse = {
  message?: string;
  page?: {
    id: string;
    name: string;
    url: string;
    updated_at: string;
    time_zone: string;
  };
  incidents?: Array<{
    id: string;
    name: string;
    impact: "none" | "minor" | "major" | "critical";
    status: "investigating" | "identified" | "monitoring";
    updated_at: string;
    incident_updates: Array<{
      id: string;
      status: "investigating" | "identified" | "monitoring";
      body: string;
      created_at: string;
      updated_at: string;
      incident_id: string;
    }>;
  }>;
};

export async function queryStatusPage(): Promise<
  StatusPageResponse | undefined
> {
  try {
    const res = await fetch(
      "https://status.hightouch.io/api/v2/incidents/unresolved.json",
    );
    const json = await res.json();

    if (json.errors) {
      const { message } = json.errors?.[0] || "Error";
      throw new Error(message);
    }

    return json;
  } catch (error) {
    return undefined;
  }
}
