import { FC, useEffect, useRef, useState } from "react";

import {
  Box,
  Button,
  Column,
  InformationIcon,
  Pill,
  PlayIcon,
  PauseIcon,
  Row,
  SectionHeading,
  Text,
  Tooltip,
  ResetIcon,
  SearchInput,
  ClipboardButton,
  WarningIcon,
  IgnoreIcon,
  ChakraOrderedList,
  ChakraListItem,
  Badge,
} from "@hightouchio/ui";
import { Link } from "src/router";
import { motion } from "framer-motion";
import { useOutletContext } from "src/router";
import { useTimer } from "react-timer-hook";
import { capitalize } from "lodash";
import jsonSourceMap from "json-source-map";
import { linter } from "@codemirror/lint";

import DebuggerEmptyState from "./assets/debugger-empty-state.svg";
import { DebuggerEvent } from "src/events/types";
import { useEventDebuggerQuery } from "src/graphql";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { commaNumber } from "src/utils/numbers";
import { formatDatetime, formatTime } from "src/utils/time";
import { OutletContext } from ".";
import { VirtualItem, useVirtualizer } from "@tanstack/react-virtual";
import { Editor } from "src/components/editor";
import { EventDisplay, getEventTitle } from "./utils";

export const Debugger: FC = () => {
  const debuggerDuration = 60 * 60 * 1000; // 1 hour in milliseconds
  const { source } = useOutletContext<OutletContext>();
  // Events are returned in reverse chronological order - newest first.
  const [events, setEvents] = useState<DebuggerEvent[]>([]);
  const [filteredEvents, setFilteredEvents] = useState<DebuggerEvent[]>([]);
  const [event, setEvent] = useState<DebuggerEvent>();
  const [lastSeenEventId, setLastSeenEventId] = useState<string>();
  const { isRunning, pause, restart } = useTimer({
    expiryTimestamp: new Date(Date.now() + debuggerDuration),
    autoStart: true,
  });
  const [search, setSearch] = useState("");

  // Percentage of events received out of all events sent to the router since
  // the debugger has been active or last cleared, truncated to 0 decimal
  // places.
  const percentEventsReceived: string =
    events.length === 0
      ? "100"
      : (
          100 *
          (events.length /
            (events.length +
              // Most recent ignored count minus the first ignored count. For
              // safety, we coalesce to 0 and take the abs value but these
              // should never happen as we've already checked that there are
              // events and the ignored count should monotonically increase.
              Math.abs(
                (events[0]?.rateLimitIgnoredCount ?? 0) -
                  (events[events.length - 1]?.rateLimitIgnoredCount ?? 0),
              )))
        ).toFixed(0);

  useEventDebuggerQuery(
    { input: { sourceId: source.id, lastSeenEventId } },
    {
      refetchInterval: isRunning ? 2000 : 0,
      // Continue fetching new events even when the tab is in the background, so
      // that events triggered in another tab appear when switching back to
      // this tab. If we don't do this, then the events may get expired from the
      // backend before the frontend has a chance to fetch them.
      refetchIntervalInBackground: true,
      select: (data) => data.getLiveSourceEvents,
      onSuccess: (data) => {
        if (data) {
          if (data.latestEventId) {
            setLastSeenEventId(data.latestEventId);
          }
          const castEvents = data.events as DebuggerEvent[]; // This was already validated on the server, but it'd be good to have a shared schema that the FE can validate against

          setEvents((events) => [...castEvents, ...events]);
        }
      },
    },
  );

  useEffect(() => {
    if (search === "") {
      setFilteredEvents([]);
    } else {
      setFilteredEvents(
        events.filter((event) =>
          JSON.stringify(event.payload)
            .toLowerCase()
            .includes(search.toLowerCase()),
        ),
      );
    }
  }, [search, events]);

  return (
    <Column width="100%" height="70vh" gap="4px">
      <Row gap={4} justify="space-between" height="48px" align="center">
        <Row gap={2}>
          <SectionHeading>Events</SectionHeading>
          <Pill>{commaNumber(events.length)}</Pill>
        </Row>
        <Row gap={2} align="center">
          <Button
            icon={ResetIcon}
            aria-label="Clear events"
            onClick={() => {
              setEvent(undefined);
              setEvents([]);
            }}
          >
            Clear events
          </Button>
          <Box width="105px">
            <Button
              isJustified
              icon={isRunning ? PauseIcon : PlayIcon}
              onClick={() => {
                isRunning
                  ? pause()
                  : restart(new Date(Date.now() + debuggerDuration), true);
              }}
            >
              {isRunning ? "Pause" : "Resume"}
            </Button>
          </Box>
        </Row>
      </Row>

      <Row width="100%">
        <SearchInput
          placeholder="Search for events by name, type, or payload content..."
          value={search}
          width="100%"
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </Row>

      <Row padding="12px 0px" align="center" overflow="hidden">
        {search !== "" ? (
          <Text color="text.placeholder" whiteSpace="pre-wrap">
            {`${filteredEvents.length} results for "${search}" · `}
          </Text>
        ) : null}
        <Tooltip
          placement="right"
          message="When event volume is high, the events displayed are automatically sampled."
        >
          <Row gap={1} align="center">
            <Box as={InformationIcon} color="text.placeholder" fontSize="xl" />
            <Text color="text.placeholder" fontWeight="medium">
              Displaying{" "}
              {percentEventsReceived === "100"
                ? `all`
                : `${percentEventsReceived}% of`}{" "}
              received event data
            </Text>
          </Row>
        </Tooltip>
      </Row>

      <Row
        display="grid"
        gridTemplateColumns="minmax(300px, 2fr) 3fr"
        gap="16px"
        overflow="auto"
        height="100%"
      >
        <EventList
          event={event}
          search={search}
          clearSearch={() => setSearch("")}
          setEvent={setEvent}
          events={search !== "" ? filteredEvents : events}
        />

        <Column overflow="hidden" flex={1}>
          {event ? (
            <EventDetail event={event} />
          ) : (
            <Column height="100%" borderColor="base.border" borderRadius="md">
              <Box color="text.secondary" m="auto" p={4}>
                <Box as="img" src={DebuggerEmptyState} width="230px" />
                Click on an event to view its payload
              </Box>
            </Column>
          )}
        </Column>
      </Row>
    </Column>
  );
};

const EventDetail: FC<{ event: DebuggerEvent }> = ({ event }) => {
  const status = getEventStatus(event);
  const title = getEventTitle(event.payload)?.toString();

  const payloadSourceMap = jsonSourceMap.stringify(event.payload, null, 4);
  const payloadJson = payloadSourceMap.json;

  const violationLinter = linter(
    () => {
      const violations = event.validation.violations.filter(
        // filter out root-level violations since they underline the entire payload which is ugly
        (v) => v.dataPath != null && v.field !== "$root",
      );

      return violations.map((violation) => {
        const pointer = payloadSourceMap.pointers[violation.dataPath];
        return {
          from: (pointer.key ?? pointer.value).pos,
          to: pointer.valueEnd.pos,
          severity: status.status === "blocked" ? "error" : "warning",
          message: formatViolation(violation),
        };
      });
    },
    {
      delay: 0, // no delay since it's read-only
    },
  );

  return (
    <Box
      border="1px"
      borderColor="base.border"
      borderRadius="md"
      color="text-primary"
      position="relative"
      display="flex"
      flexDir="column"
      h="100%"
      overflow="hidden"
    >
      {/* header */}
      <Box
        px={3}
        py={2}
        gap={4}
        minH="56px"
        h="56px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        borderBottom="1px"
        borderColor="base.border"
      >
        <Box overflow="auto">
          <TextWithTooltip fontWeight="medium">{title}</TextWithTooltip>
          <Text color="text.secondary">{capitalize(event.payload.type)}</Text>
        </Box>
        <Box display="flex" alignItems="center" gap={2} flexShrink={0}>
          {status.status === "warning" ? (
            <Tooltip message={status.message}>
              <Badge icon={WarningIcon} variant="warning" size="md">
                Warning
              </Badge>
            </Tooltip>
          ) : status.status === "blocked" ? (
            <Tooltip message={status.message}>
              <Badge icon={IgnoreIcon} variant="danger" size="md">
                Blocked
              </Badge>
            </Tooltip>
          ) : null}
          <ClipboardButton text={payloadJson} />
        </Box>
      </Box>

      {/* editor */}
      <Box flex={1} overflow="auto">
        <Editor
          key={event.id} // needed to re-render the editor when event changes
          bg="transparent"
          language="json"
          value={payloadJson}
          readOnly
          extensions={[violationLinter]}
        />
      </Box>

      {/* footer */}
      {event.validation.violations.length > 0 && (
        <Box>
          <ChakraOrderedList
            ml={0}
            px={3}
            py={2}
            spacing={2}
            bg="base.lightBackground"
            borderTop="1px"
            borderColor="base.border"
          >
            {event.validation.violations
              .map(formatViolation)
              .sort()
              .map((message, idx) => (
                <ChakraListItem key={idx} display="flex" alignItems="center">
                  <Box sx={{ svg: { h: 5, w: 5 } }}>
                    {status.status === "blocked" ? (
                      <IgnoreIcon color="danger.base" />
                    ) : (
                      <WarningIcon color="warning.base" />
                    )}
                  </Box>
                  <Text ml={3} isMonospace>
                    {message}
                  </Text>
                </ChakraListItem>
              ))}
          </ChakraOrderedList>
        </Box>
      )}
    </Box>
  );
};

const EventList: FC<{
  event: DebuggerEvent | undefined;
  events: DebuggerEvent[];
  search: string;
  setEvent: (event: DebuggerEvent) => void;
  clearSearch: () => void;
}> = ({ event, events, search, setEvent, clearSearch }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const virtualizer = useVirtualizer<HTMLDivElement, Element>({
    count: events.length,
    getScrollElement: () => containerRef.current,
    estimateSize: () => 56,
  });

  const emptyState = search ? (
    <>
      <Text whiteSpace="pre-wrap" color="text.secondary">
        No events found for search <Text fontWeight="medium">"{search}"</Text>
      </Text>
      <Button mt={1} variant="primary" size="md" onClick={() => clearSearch()}>
        Clear search
      </Button>
    </>
  ) : (
    <Text color="text.secondary">
      Not seeing anything? Make sure you’ve installed the SDK correctly. Check
      out our{" "}
      <Link href="https://hightouch.com/docs/events/overview">
        Events documentation
      </Link>{" "}
      to learn more.
    </Text>
  );

  return (
    <Box
      as="ul"
      ref={containerRef}
      overflow="auto"
      height="100%"
      flex={1}
      border="1px"
      borderColor="base.border"
      borderRadius="md"
      p={0}
      listStyleType="none"
    >
      {events?.length ? (
        <Box
          height={`${virtualizer.getTotalSize()}px`}
          width="100%"
          position="relative"
        >
          {virtualizer.getVirtualItems().map((virtualItem) => {
            const e = events[virtualItem.index];
            if (!e) {
              return null;
            }
            return (
              <EventItem
                virtualItem={virtualItem}
                key={virtualItem.key}
                event={e}
                isSelected={Boolean(event && event.id === e.id)}
                onClick={() => {
                  setEvent(e);
                }}
              />
            );
          })}
        </Box>
      ) : (
        <Column
          m="auto"
          h="100%"
          p={4}
          align="center"
          justify="center"
          gap={2}
          textAlign="center"
        >
          <Box display="flex" alignItems="center" gap={2}>
            <Box
              as={motion.div}
              boxSize={2}
              borderRadius="full"
              bg="grass.500"
              initial={{ scale: 1, opacity: 1 }}
              animate={{
                opacity: [1, 0.4, 1],
                transition: {
                  duration: 2,
                  ease: [0.4, 0, 0.6, 1],
                  repeat: Infinity,
                },
              }}
            />
            <Text color="text.secondary" fontWeight="medium">
              Waiting for events...
            </Text>
          </Box>
          {emptyState}
        </Column>
      )}
    </Box>
  );
};

const EventItem: FC<{
  event: DebuggerEvent;
  onClick: () => void;
  isSelected: boolean;
  virtualItem: VirtualItem;
}> = ({ event, onClick, isSelected, virtualItem }) => {
  const status = getEventStatus(event);

  return (
    <Row
      as="li"
      position="absolute"
      top={0}
      left={0}
      transform={`translateY(${virtualItem.start}px)`}
      display="flex"
      alignItems="center"
      gap={2}
      px={2}
      minH="56px"
      height="56px"
      w="100%"
      sx={{
        ":not(:last-of-type)": {
          borderBottom: "1px",
          borderColor: "base.border",
        },
      }}
      cursor="pointer"
      bg={isSelected ? "forest.100" : "white"}
      _hover={{ bg: "forest.50" }}
      transition="150ms background-color"
      onClick={onClick}
    >
      <EventDisplay
        eventType={event.payload.type}
        title={getEventTitle(event.payload)}
      />
      <Row gap={3} alignItems="center" justifyContent="end" shrink={0}>
        {status.status === "warning" ? (
          <Tooltip message={status.message}>
            <Badge icon={WarningIcon} variant="warning" size="sm">
              Warning
            </Badge>
          </Tooltip>
        ) : status.status === "blocked" ? (
          <Tooltip message={status.message}>
            <Badge icon={IgnoreIcon} variant="danger" size="sm">
              Blocked
            </Badge>
          </Tooltip>
        ) : null}

        {event.payload.timestamp && (
          <Tooltip message={formatDatetime(event.payload.timestamp)!}>
            <Text color="text.tertiary" size="sm">
              {formatTime(event.payload.timestamp)}
            </Text>
          </Tooltip>
        )}
      </Row>
    </Row>
  );
};

const getEventStatus = ({
  validation,
}: DebuggerEvent):
  | { status: "ok" }
  | { status: "warning" | "blocked"; message: string } => {
  if (validation.isBlockedViolation) {
    const reasons = validation.blockReasons ?? [];

    if (reasons.includes("schema_violation")) {
      return {
        status: "blocked",
        message:
          "This event has schema violation(s) and is blocked due to your contracts settings",
      };
    }

    if (reasons.includes("undeclared_schema")) {
      return {
        status: "blocked",
        message: "Undeclared events are blocked in your contracts settings",
      };
    }

    if (reasons.includes("undeclared_fields")) {
      return {
        status: "blocked",
        message:
          "Events with undeclared fields are blocked in your contracts settings",
      };
    }

    return {
      status: "blocked",
      message: "This event is blocked due to an invalid schema",
    };
  }

  if (validation.violations.length > 0) {
    return {
      status: "warning",
      message:
        "This event has schema violation(s) but is still allowed due to your contracts settings",
    };
  }

  return { status: "ok" };
};

/**
 * Transform a violation into a single string message
 * TODO(ec) use the extra path information to highlight fields inside JSON view
 */
function formatViolation(
  violation: DebuggerEvent["validation"]["violations"][0],
): string {
  if (violation.field && violation.undeclaredField) {
    return `${violation.field} ${violation.message}: ${violation.undeclaredField}`;
  }

  if (violation.field) {
    return `${violation.field} ${violation.message}`;
  }

  return violation.message;
}
