import { FC, useState } from "react";

import {
  Box,
  Button,
  ButtonGroup,
  ChakraListItem,
  ChakraUnorderedList,
  Column,
  ConfirmationDialog,
  DeleteIcon,
  ResetIcon,
  Dialog,
  ExternalLinkIcon,
  FormField,
  Menu,
  MenuActionsButton,
  MenuList,
  Paragraph,
  PauseIcon,
  PlayIcon,
  Radio,
  RadioGroup,
  Row,
  Spinner,
} from "@hightouchio/ui";
import { Link } from "src/router";
import capitalize from "lodash/capitalize";
import { useFormContext } from "react-hook-form";
import { isPresent } from "ts-extras";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import {
  PermissionedButton,
  PermissionedMenuItem,
} from "src/components/permission";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { useJourneySyncsQuery } from "src/graphql";
import {
  JOURNEY_DELETE_PERMISSION,
  JOURNEY_UPDATE_PERMISSION,
} from "src/pages/journeys/constants";
import { useGraphContext } from "src/pages/journeys/graph";
import { JourneyGraph } from "src/pages/journeys/types";
import { getJourneyState } from "src/pages/journeys/utils";
import { JourneyStatus } from "src/types/journeys";

type JourneyActionButtonProps = {
  isLoading?: boolean;
  numberOfPreviousRuns: number;
  numberOfUsersInJourney: number;
  reset: boolean;
  status: JourneyStatus;
};

export const JourneyActionButtons: FC<JourneyActionButtonProps> = ({
  isLoading = false,
  numberOfPreviousRuns,
  numberOfUsersInJourney,
  reset,
  status,
}) => {
  const form = useFormContext<JourneyGraph>();
  const {
    onDeleteJourney,
    onRunJourney,
    onUpdateJourneyStatus,
    onResetJourney,
  } = useGraphContext();

  const journeyState = getJourneyState({
    reset,
    status,
    numberOfPreviousRuns,
    numberOfUsersInJourney,
  });

  const [showJourneyTurnedOffDialog, setShowJourneyTurnedOffDialog] =
    useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showConfirmReset, setShowConfirmReset] = useState(false);
  const [showConfirmManualRun, setShowConfirmManualRun] = useState(false);

  const syncsToDelete = form
    .watch("nodes")
    .flatMap((node) => node.data.sync_configs)
    .filter(isPresent);

  const journeySyncsQuery = useJourneySyncsQuery(
    {
      ids:
        syncsToDelete?.map(({ destination_instance_id }) =>
          destination_instance_id.toString(),
        ) ?? [],
    },
    {
      enabled: showJourneyTurnedOffDialog && Boolean(syncsToDelete.length > 0),
      select: (data) =>
        data.syncs.filter(
          (sync) => sync.status !== null && sync.destination !== null,
        ),
    },
  );

  const deleteJourney = async () => {
    // Navigates away on finish, so no need to close dialog
    await onDeleteJourney();
  };

  const resetJourney = async () => {
    // Navigates away on finish, so no need to close dialog
    await onResetJourney();
  };

  const buttons = ["draft", "draining", "off"].includes(journeyState) ? (
    <JourneyStartButton
      isLoading={isLoading}
      onUpdateJourneyStatus={onUpdateJourneyStatus}
      type="start"
    />
  ) : journeyState === "resetting" ? null : (
    <ButtonGroup>
      <JourneyTurnOffButton
        isLoading={isLoading}
        onUpdateJourneyStatus={onUpdateJourneyStatus}
      />
      {journeyState === "live" ? (
        <JourneyPauseButton
          isLoading={isLoading}
          numberOfUsersInJourney={numberOfUsersInJourney}
          onTurnJourneyOff={() => setShowJourneyTurnedOffDialog(true)}
          onUpdateJourneyStatus={onUpdateJourneyStatus}
        />
      ) : (
        <JourneyStartButton
          isLoading={isLoading}
          type="resume"
          onUpdateJourneyStatus={onUpdateJourneyStatus}
        />
      )}
    </ButtonGroup>
  );

  return (
    <>
      <Row gap={2}>
        <Menu>
          <MenuActionsButton />
          <MenuList>
            {journeyState === "live" && (
              <PermissionedMenuItem
                permission={JOURNEY_UPDATE_PERMISSION}
                onClick={() => setShowConfirmManualRun(true)}
                icon={PlayIcon}
              >
                Trigger manual run
              </PermissionedMenuItem>
            )}
            {journeyState !== "live" && (
              <>
                <PermissionedMenuItem
                  icon={ResetIcon}
                  isDisabled={journeyState === "resetting"}
                  tooltip={
                    journeyState === "resetting" && "Journey is resetting"
                  }
                  permission={JOURNEY_DELETE_PERMISSION}
                  onClick={() => setShowConfirmReset(true)}
                >
                  Reset journey
                </PermissionedMenuItem>
                <PermissionedMenuItem
                  icon={DeleteIcon}
                  permission={JOURNEY_DELETE_PERMISSION}
                  onClick={() => setShowConfirmDelete(true)}
                  variant="danger"
                >
                  Delete journey
                </PermissionedMenuItem>
              </>
            )}
          </MenuList>
        </Menu>

        {buttons}
      </Row>

      <ConfirmationDialog
        isOpen={showJourneyTurnedOffDialog}
        title="Journey has been turned off"
        confirmButtonText="Ok"
        variant="warning"
        onClose={() => setShowJourneyTurnedOffDialog(false)}
        onConfirm={() => setShowJourneyTurnedOffDialog(false)}
      >
        <Paragraph>
          Nobody is currently in the journey so we’ve turned it off.
        </Paragraph>
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={showConfirmManualRun}
        title="Trigger manual run"
        confirmButtonText="Confirm"
        variant="warning"
        onClose={() => setShowConfirmManualRun(false)}
        onConfirm={onRunJourney}
      >
        <Paragraph>
          Are you sure you want to kick off a manual run? During a run, all
          syncs are triggered which could cause syncs to run outside of their
          expected interval or schedule.
        </Paragraph>
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={showConfirmDelete}
        title="Delete journey"
        confirmButtonText="Delete"
        variant="danger"
        onClose={() => setShowConfirmDelete(false)}
        onConfirm={deleteJourney}
      >
        <Column gap={6}>
          <Paragraph>
            Are you sure you want to delete this journey? You will not be able
            to undo this.
          </Paragraph>
          {journeySyncsQuery.isLoading && (
            <Column align="center" justify="center">
              <Spinner />
            </Column>
          )}
          {!journeySyncsQuery.isLoading &&
            journeySyncsQuery.data &&
            journeySyncsQuery.data.length > 0 && (
              <>
                <Paragraph>These syncs will also be deleted.</Paragraph>
                <ChakraUnorderedList pl={0} ml={0}>
                  {journeySyncsQuery.data.map(({ id, destination }) => (
                    <Row
                      as={ChakraListItem}
                      key={id}
                      align="center"
                      gap={2}
                      overflowX="hidden"
                      mb={3}
                    >
                      {destination && (
                        <IntegrationIcon
                          name={destination.definition.name}
                          src={destination.definition.icon}
                          size={4}
                        />
                      )}

                      <Row
                        gap={2}
                        overflowX="hidden"
                        sx={{
                          a: {
                            overflowX: "hidden",
                          },
                        }}
                      >
                        <Link href={`/syncs/${id}`} isExternal>
                          <TextWithTooltip
                            color="inherit"
                            message={destination?.definition.name}
                          >
                            {`${destination?.definition.name} #${id}`}
                          </TextWithTooltip>
                        </Link>
                        <Box as={ExternalLinkIcon} flexShrink={0} />
                      </Row>
                    </Row>
                  ))}
                </ChakraUnorderedList>
              </>
            )}
        </Column>
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={showConfirmReset}
        title="Reset journey"
        confirmButtonText="Reset"
        variant="warning"
        onClose={() => setShowConfirmReset(false)}
        onConfirm={resetJourney}
      >
        <Column gap={6}>
          <Paragraph>
            Resetting this journey will make it behave as if you've never ran
            the journey before. All entry history will be cleared and all syncs
            will be reset. Are you sure you want to reset this journey?
          </Paragraph>
        </Column>
      </ConfirmationDialog>
    </>
  );
};

type JourneyStartButtonProps = {
  isLoading?: boolean;
  type: "start" | "resume";
  numberOfPreviousRuns?: number;
  onUpdateJourneyStatus: (
    status: JourneyStatus,
    hardStop?: boolean,
  ) => Promise<void>;
};

const JourneyStartButton: FC<JourneyStartButtonProps> = ({
  isLoading = false,
  type,
  numberOfPreviousRuns = 0,
  onUpdateJourneyStatus,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const capitalizedButtonText = capitalize(type);

  const startJourney = async () => {
    await onUpdateJourneyStatus(JourneyStatus.Enabled);

    setShowConfirmation(false);
  };

  return (
    <>
      <PermissionedButton
        isLoading={isLoading}
        icon={PlayIcon}
        variant="primary"
        tooltip="Journey will begin running"
        permission={JOURNEY_UPDATE_PERMISSION}
        onClick={() => setShowConfirmation(true)}
      >
        {capitalizedButtonText}
      </PermissionedButton>

      <ConfirmationDialog
        isOpen={showConfirmation}
        confirmButtonText={capitalizedButtonText}
        title={`${capitalizedButtonText} journey`}
        variant="warning"
        onCancel={() => setShowConfirmation(false)}
        onClose={() => setShowConfirmation(false)}
        onConfirm={startJourney}
      >
        <Paragraph>
          {type === "start" && "Are you sure you want to start this journey?"}
          {type === "resume" &&
            (numberOfPreviousRuns === 0
              ? "Are you sure you want to resume this journey?"
              : "Existing members will continue on in the new version of the journey. Are you sure you want to resume this journey?")}
        </Paragraph>
      </ConfirmationDialog>
    </>
  );
};

type JourneyTurnOffButtonProps = {
  isLoading?: boolean;
  onUpdateJourneyStatus: (
    status: JourneyStatus,
    hardStop?: boolean,
  ) => Promise<void>;
};

const JourneyTurnOffButton: FC<JourneyTurnOffButtonProps> = ({
  isLoading = false,
  onUpdateJourneyStatus,
}) => {
  // Custom dialog so loading state must be set manually
  const [turningOff, setTurningOff] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [hardStop, setHardStop] = useState(false);

  const updateStatus = async () => {
    setTurningOff(true);

    await onUpdateJourneyStatus(
      hardStop ? JourneyStatus.Disabled : JourneyStatus.Draining,
      hardStop,
    );

    setTurningOff(false);
    setShowConfirmation(false);
  };

  return (
    <>
      <PermissionedButton
        isLoading={isLoading}
        permission={JOURNEY_UPDATE_PERMISSION}
        tooltip="Journey will stop accepting new entries"
        variant="danger"
        onClick={() => setShowConfirmation(true)}
      >
        Turn off
      </PermissionedButton>

      <Dialog
        isOpen={showConfirmation}
        title="Turn off journey"
        variant="info"
        actions={
          <ButtonGroup>
            <Button
              isLoading={turningOff}
              isDisabled={false}
              onClick={() => setShowConfirmation(false)}
            >
              Cancel
            </Button>
            <PermissionedButton
              isLoading={turningOff}
              isDisabled={false}
              permission={JOURNEY_UPDATE_PERMISSION}
              variant="danger"
              onClick={updateStatus}
            >
              Turn off
            </PermissionedButton>
          </ButtonGroup>
        }
        onClose={() => setShowConfirmation(false)}
      >
        <FormField label="Should existing members complete their journey?">
          <RadioGroup
            orientation="vertical"
            value={hardStop}
            onChange={setHardStop}
          >
            <Radio
              value={false}
              label="Existing members should complete the journey first"
              description="All active members will complete the journey and no new entries will occur"
            />
            <Radio
              value={true}
              label="Hard stop"
              description="All existing members exit the journey and no new entries will occur"
            />
          </RadioGroup>
        </FormField>
      </Dialog>
    </>
  );
};

type JourneyPauseButtonProps = {
  isLoading?: boolean;
  numberOfUsersInJourney: number;
  onTurnJourneyOff: () => void;
  onUpdateJourneyStatus: (
    status: JourneyStatus,
    hardStop?: boolean,
  ) => Promise<void>;
};

const JourneyPauseButton: FC<JourneyPauseButtonProps> = ({
  isLoading = false,
  numberOfUsersInJourney,
  onUpdateJourneyStatus,
  onTurnJourneyOff,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const pauseJourney = async () => {
    await onUpdateJourneyStatus(JourneyStatus.Disabled);

    setShowConfirmation(false);

    if (numberOfUsersInJourney === 0) {
      onTurnJourneyOff();
    }
  };

  return (
    <>
      <PermissionedButton
        isLoading={isLoading}
        icon={PauseIcon}
        permission={JOURNEY_UPDATE_PERMISSION}
        tooltip="Journey will stop accepting new entries and existing members will remain in their current step"
        onClick={() => setShowConfirmation(true)}
      >
        Pause
      </PermissionedButton>

      <ConfirmationDialog
        isOpen={showConfirmation}
        title="Pause journey"
        confirmButtonText="Pause"
        variant="warning"
        onClose={() => setShowConfirmation(false)}
        onCancel={() => setShowConfirmation(false)}
        onConfirm={pauseJourney}
      >
        <Paragraph>
          The journey will be paused and all existing members will remain in
          their current tile. Are you sure you want to pause this journey?
        </Paragraph>
      </ConfirmationDialog>
    </>
  );
};
