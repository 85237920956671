import { Row } from "@hightouchio/ui";

import { FullResourceLinkForm } from "src/components/deployments/linking";
import { SidebarForm } from "src/components/page";
import { useParams } from "src/router";

export const Element = () => {
  const { destination_id: id } = useParams<{ destination_id: string }>();

  return (
    <Row justifyContent="space-between">
      <FullResourceLinkForm
        sourceResourceId={String(id)}
        resourceType="destinations"
      />
      <SidebarForm name="destination linking" docsUrl="" />
    </Row>
  );
};
