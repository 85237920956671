import { PlusIcon, Row, SectionHeading } from "@hightouchio/ui";
import * as yup from "yup";
import { DecisionEngineChannelType } from "@hightouch/lib/customer-data/decision-engine/types";
import { useFormContext } from "react-hook-form";

import { DecisionEngineChannelsInsertInput } from "src/graphql";
import { Card } from "src/components/card";
import { getChannelDefinition } from "src/pages/decision-engines/definitions";
import { LinkButton } from "src/router";
import { Channel } from "./channel";

export const channelsSchema = yup.array().of(
  yup.object().shape({
    type: yup.string().required(),
    enabled: yup.boolean().required(),
    destination_id: yup.string().when("enabled", {
      is: true,
      then: yup.string().required("Destination is required"),
      otherwise: yup.string().notRequired(),
    }),
    config: yup.object(),
  }),
);

export const formatChannel = (channel): DecisionEngineChannelsInsertInput => ({
  id: channel.id,
  type: channel.type,
  enabled: channel.enabled,
  destination_id: channel.destination?.id,
  config: getChannelDefinition(channel.destination?.type).getChannelConfig(
    channel.config,
  ),
});

export const Channels = () => {
  const { watch } = useFormContext();
  const channels = watch("channels");

  return (
    <Card gap={6}>
      <Row justify="space-between" align="center" gap={4}>
        <SectionHeading>Channels</SectionHeading>
        <LinkButton href="add-channel" icon={PlusIcon}>
          Add channel
        </LinkButton>
      </Row>
      {channels.map(({ id, type }, index) => {
        return (
          <Channel
            key={id}
            index={index}
            type={type as DecisionEngineChannelType}
          />
        );
      })}
    </Card>
  );
};
