import { FC } from "react";

import { Box, Column, Row, Text } from "@hightouchio/ui";

type Value =
  | string
  | number
  | { value: string | number; description?: string | null };

type GraphTooltipProps = {
  color?: string;
  title?: string;
  subtitles?: (string | undefined)[];
  value: Value[];
};

export const GraphTooltip: FC<GraphTooltipProps> = ({
  color,
  title,
  subtitles = [],
  value,
}) => {
  return (
    <Row gap={2}>
      <Box width={1} borderRadius="lg" bg={color} />
      <Column align="flex-start" gap={1} sx={{ span: { textAlign: "left" } }}>
        {title && (
          <Text color="white" fontWeight="semibold">
            {title}
          </Text>
        )}
        {subtitles.map((subtitle, index) => (
          <Text key={index} color="gray.400" size="sm">
            {subtitle}
          </Text>
        ))}
        {value.map((val) => {
          const primaryLabel = getPrimaryLabel(val);
          const secondaryLabel = getSecondaryLabel(val);

          return (
            <Box
              key={primaryLabel}
              bg="gray.800"
              borderRadius="md"
              px={1}
              gap={1}
              width="fit-content"
            >
              <Text color="white" fontWeight="semibold">
                {primaryLabel}
              </Text>
              {secondaryLabel && (
                <Text color="text.tertiary"> {secondaryLabel}</Text>
              )}
            </Box>
          );
        })}
      </Column>
    </Row>
  );
};

const getPrimaryLabel = (value: Value) => {
  if (typeof value === "string" || typeof value === "number") {
    return value;
  }

  return value.value;
};

const getSecondaryLabel = (value: Value) => {
  if (typeof value !== "string" && typeof value !== "number") {
    return value.description ?? null;
  }

  return null;
};
