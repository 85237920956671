import { DecisionEngineStatus } from "@hightouch/lib/customer-data/decision-engine/types";
import {
  Tooltip,
  Button,
  useToast,
  useDisclosure,
  ConfirmationDialog,
} from "@hightouchio/ui";
import { captureException } from "@sentry/react";
import { FC } from "react";
import { useUpdateDecisionEngineFlowMutation } from "src/graphql";

export const FlowActionButton: FC<
  Readonly<{
    id: string;
    status: DecisionEngineStatus;
    enabled: boolean;
    isValid: boolean;
  }>
> = ({ id, status, enabled, isValid }) => {
  if (status === DecisionEngineStatus.TRAINING) {
    return (
      <Tooltip message="Start will be enabled once the flow is initialized">
        <Button isDisabled>Start</Button>
      </Tooltip>
    );
  }

  if (status === DecisionEngineStatus.READY) {
    if (enabled) {
      return <Pause id={id} />;
    } else {
      return <Start id={id} />;
    }
  }

  return <Initialize id={id} isValid={isValid} />;
};

const Start = ({ id }: { id: string }) => {
  const { toast } = useToast();
  const updateMutation = useUpdateDecisionEngineFlowMutation();

  return (
    <Button
      onClick={async () => {
        try {
          await updateMutation.mutateAsync({
            id,
            input: {
              enabled: true,
            },
          });
          toast({
            id: "start-flow",
            title: "Flow started",
            variant: "success",
          });
        } catch (e) {
          captureException(e);
          toast({
            id: "start-flow",
            title: "Failed to start Flow",
            variant: "error",
          });
        }
      }}
    >
      Start
    </Button>
  );
};

const Pause = ({ id }: { id: string }) => {
  const { toast } = useToast();
  const updateMutation = useUpdateDecisionEngineFlowMutation();

  return (
    <Button
      onClick={async () => {
        try {
          await updateMutation.mutateAsync({
            id,
            input: {
              enabled: false,
            },
          });
          toast({
            id: "pause-flow",
            title: "Flow paused",
            variant: "success",
          });
        } catch (e) {
          captureException(e);
          toast({
            id: "pause-flow",
            title: "Failed to pause Flow",
            variant: "error",
          });
        }
      }}
    >
      Pause
    </Button>
  );
};

export const Initialize = ({
  id,
  isValid,
}: {
  id: string;
  isValid: boolean;
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const updateMutation = useUpdateDecisionEngineFlowMutation();

  return (
    <>
      <Tooltip
        isDisabled={isValid}
        message="You must complete the setup tasks before initializing"
      >
        <Button isDisabled={!isValid} onClick={onOpen}>
          {updateMutation.isLoading ? "Initializing..." : "Initialize"}
        </Button>
      </Tooltip>
      <ConfirmationDialog
        variant="warning"
        isOpen={isOpen}
        title="Initialize"
        confirmButtonText="Initialize"
        onClose={onClose}
        onConfirm={async () => {
          await updateMutation.mutateAsync({
            id,
            input: {
              status: DecisionEngineStatus.TRAINING,
            },
          });
        }}
      >
        Are you sure you want to initialize? Once initialization begins you will
        not be able to edit the configuration. You will still be able to edit,
        add, and remove messages.
      </ConfirmationDialog>
    </>
  );
};
