import { forwardRef } from "react";

import { Column, Text, TextInput } from "@hightouchio/ui";

import { FormProps } from "../types";

interface OnChangeValueProp {
  from: string;
  type: "standard";
}

const FieldInput = forwardRef<
  HTMLInputElement,
  Readonly<
    FormProps & {
      onChange: (onClose: () => void, value: OnChangeValueProp) => void;
      onClose: () => void;
    }
  >
>(({ onChange, value }, ref) => {
  return (
    <Column flex={1} pl={3} pr={3} pt={1}>
      <TextInput
        autoFocus
        ref={ref}
        placeholder="Enter a field path"
        type="text"
        value={value.from ?? ""}
        width="lg"
        onChange={(event) => {
          onChange({
            ...value,
            from: event.target.value,
          });
        }}
      />
      <Text color="text.secondary" fontWeight="normal" size="md">
        Enter the full path to the field that should be mapped.
      </Text>
    </Column>
  );
});

FieldInput.displayName = "FieldInput";
export { FieldInput };
