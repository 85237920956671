import { FC } from "react";

import { Text, Row, Column } from "@hightouchio/ui";

import * as analytics from "src/lib/analytics";
import { Selectable } from "src/ui/selectable";

type Props = {
  currentCategory: string;
  query: string;
  refine: (value: string) => void;
  clear: () => void;
  items: any;
};

export const CategoryMenu: FC<Props> = ({
  currentCategory,
  query,
  clear,
  refine,
  items,
}) => {
  return (
    <Column gap={1}>
      <Row
        width="100%"
        justify="stretch"
        sx={{
          button: {
            flex: 1,
            borderColor: currentCategory === "All" ? undefined : "transparent",
          },
        }}
      >
        <Selectable
          selected={currentCategory === "All"}
          onSelect={() => {
            clear();
            analytics.track("Destination Catalog Category Clicked", {
              current_category: "All",
            });
          }}
        >
          <Row px={2} py={1}>
            <Text fontWeight="medium">All</Text>
          </Row>
        </Selectable>
      </Row>

      {items?.map((filter) => {
        const selected = currentCategory === filter.label;
        return (
          <Row
            width="100%"
            key={filter.label}
            sx={{
              button: {
                flex: 1,
                borderColor: selected ? undefined : "transparent",
              },
            }}
          >
            <Selectable
              selected={selected}
              onSelect={() => {
                refine(filter.value);
                analytics.track("Destination Catalog Category Clicked", {
                  current_category: filter.label,
                });
              }}
            >
              <Row
                align="center"
                fontWeight="medium"
                px={2}
                py={1}
                gap={2}
                textAlign="left"
              >
                {filter.label}
                {query !== "" && <Text>{`(${filter.count})`}</Text>}
              </Row>
            </Selectable>
          </Row>
        );
      })}
    </Column>
  );
};
