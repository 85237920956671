// Based on: https://github.com/aboveyunhai/chakra-dayzed-datepicker/
import React from "react";

import { ChevronLeftIcon, ChevronRightIcon, IconButton } from "@hightouchio/ui";
import { Calendar, GetBackForwardPropsOptions } from "dayzed";
import noop from "lodash/noop";

import { DatepickerProps } from "../utils/common-types";

export interface DatepickerBackBtnsProps extends DatepickerProps {
  calendars: Calendar[];
  getBackProps: (data: GetBackForwardPropsOptions) => Record<string, any>;
}

export const DatepickerBackBtns: React.FC<DatepickerBackBtnsProps> = (
  props,
) => {
  const { calendars, getBackProps } = props;
  const iconButtonProps = getBackProps({ calendars });

  return (
    <IconButton
      aria-label={iconButtonProps["aria-label"] ?? "Go back 1 month"}
      icon={ChevronLeftIcon}
      onClick={iconButtonProps.onClick ?? noop}
    />
  );
};

export interface DatepickerForwardBtnsProps extends DatepickerProps {
  calendars: Calendar[];
  getForwardProps: (data: GetBackForwardPropsOptions) => Record<string, any>;
}

export const DatepickerForwardBtns: React.FC<DatepickerForwardBtnsProps> = (
  props,
) => {
  const { calendars, getForwardProps } = props;
  const iconButtonProps = getForwardProps({ calendars });

  return (
    <IconButton
      aria-label={iconButtonProps["aria-label"] ?? "Go back 1 month"}
      icon={ChevronRightIcon}
      onClick={iconButtonProps.onClick ?? noop}
    />
  );
};
