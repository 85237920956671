import { useEntitlements } from "src/hooks/use-entitlement";
import { NavSection } from "src/components/layout/nav/nav-section";

import { Icon } from "src/components/layout/nav/icons/event-collection";
import { ActiveSectionProps } from "src/components/layout/nav/utils";
import { UseSectionHook } from "./types";

export const useEventCollectionSection: UseSectionHook = () => {
  const { data: entitlementsData } = useEntitlements(false);

  return {
    title: "Event Collection",
    isEnabled: entitlementsData.entitlements.events,
    links: [
      {
        isSub: true,
        href: "/events/syncs",
        label: "Event syncs",
      },
      {
        isSub: true,
        href: "/events/sources",
        label: "Event sources",
      },
      {
        isSub: true,
        href: "/events/destinations",
        label: "Event destinations",
      },
      {
        isSub: true,
        href: "/events/contracts",
        label: "Contracts",
      },
      {
        isSub: true,
        href: "/events/functions",
        label: "Functions",
      },
    ],
  };
};

export const EventCollection = (props: ActiveSectionProps) => {
  const section = useEventCollectionSection();
  return <NavSection icon={Icon} {...section} {...props} />;
};
